import { Modal } from "@shopify/polaris";
import React from "react";

const NewPersonVideo = ({getStartShow,currentStart,setGetStartShow,videoLink,videoTitle}) => {
  return (
    <div>
      <Modal
        size="large"
        open={getStartShow}
        onClose={() => setGetStartShow(false)}
        title={
            videoTitle
        }
      >
        <div className="plan_grade_content">
          <div className="novice_video_title">
            
          </div>
          <div className="novice_video_modal_content">
            <iframe
              style={{ borderRadius: "20px" }}
              title="dropshipman"
              src={videoLink}
              width="880"
              height="500"
              frameBorder="0"
              allowFullScreen="allowfullscreen"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NewPersonVideo;
