import {
  BlockStack,
  // Box,
  // InlineStack,
  // ProgressBar,
} from "@shopify/polaris";
import React from "react";
import CommonTableFilterSkeletonList from "./CommonTableFilterSkeletonList";
const Index = () => {
  return (
      <BlockStack gap="300" direction="cloumn">
        {/* <InlineStack gap={100} align="space-between">
          <Box style={{width:"200px"}}>
          <ProgressBar size="large"/>
          </Box>
          <Box>
          <InlineStack gap={200}>
            <Box style={{width:'100px'}}>
          <ProgressBar size="large"/>
            </Box>
            <Box style={{width:'100px'}}>
          <ProgressBar size="large"/>
            </Box>
          </InlineStack>
          </Box>
        </InlineStack> */}
        <CommonTableFilterSkeletonList />
      </BlockStack>
  );
};

export default Index;
