/**
 * 搜索loading
 * 使用方式 ↓
 * import ChangeLoading from '@/components/ChangeLoading
 * <ChangeLoading />
 */
import React from 'react';
import "./index.css";
const ChangeLoading = () => {
  return (
    <div className="Search_Loading">
      <div className="Search_Loading_Image">
        <img width={32} src={'/loading.gif'} alt="loading" />
      </div>
    </div>
  );
};

export default ChangeLoading;
