import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {AppProvider,  Layout , Page, List ,Banner} from '@shopify/polaris';

import translations from '@shopify/polaris/locales/en.json';
import utils from '../../../utils/utils'
class CookieError extends Component {

  render() {
    return (
      <AppProvider i18n={translations}>
        <Page>
          <Layout>
            <Layout.Section>
              <Banner
                title="Enable cookies"
                action={{external:true,content: 'Enable cookies',url:"https://www.nap.edu/content/enabling-cookies-in-your-browser"}}
                secondaryAction={{content: 'Go to Homepage', onAction: () =>{ utils.routeListen('/admin/dashboard')
                utils.routeJump(this,'/admin/dashboard')
                }}}
                tone="warning"
              >
                <div className="space"></div>

                <List>
                  <List.Item>To use our app, you need to enable website cookies</List.Item>
                  <List.Item>Enable cookies to let the website use your website url within a certain period of time (30 days)</List.Item>
                  <List.Item>After enabling cookie settings, click "Go to Homepage"</List.Item>
                  <List.Item>Please make sure that you NOT Select 'Prevent cross-site tracking' for IOS system</List.Item>
                </List>
              </Banner>
            </Layout.Section>
          </Layout>
        </Page>
      </AppProvider>
    );
  }
}

export default withRouter(CookieError);
