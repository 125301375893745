import React, { memo, useEffect } from "react";
import { Modal, Toast } from "@shopify/polaris";
import { useGetState } from "ahooks";
import intl from "../../../../../../i18n/intl";
import { useState } from "react";
import { reqGetOrderSalesInfo } from "../../../../../../api";

const RefundTipsModal = memo(({ datas, open, setIsShowRefundTipsModal }) => {
  const [refundData, setRefundData, getRefundData] = useGetState({
    saleInfoData: {},
    saleInfoLoading: false,
    refundOrderTip: false,
  });
  const getSaleInfo = async (data) => {
    let content = await reqGetOrderSalesInfo();
    if (content.code === "200") {
      setRefundData((prevValue) => ({
        ...prevValue,
        saleInfoData: content.data,
        saleInfoLoading: false,
      }));
    } else {
      setRefundData((prevValue) => ({
        ...prevValue,
        refundOrderTip: false,
        saleInfoLoading: false,
      }));

      let sendMsg =
        "Hi, my order " + data.dsm_number + ",needs to be refunded.";

      try {
        // 填充信息并激活对话弹窗
        // window.Intercom('showNewMessage', sendMsg)
        window.Willdesk.show();
        window.Willdesk.chat(sendMsg);
      } catch (error) {
        toggleToast(
          "Intercom is not loaded. Please check your network and refresh the page.",
          true
        );
        console.info(error);
      }
    }
  };
  const handleRefund = (data) => {
    setRefundData((prevValue) => ({
      ...prevValue,
      refundOrderTip: true,
      saleInfoLoading: true,
    }));
    getSaleInfo(data);
  };
  // 订单退款提示
  const refundOrderTipSave = () => {
    setRefundData((prevValue) => ({
      ...prevValue,
      refundOrderTip: false,
    }));
    setIsShowRefundTipsModal();
  };
  // 复制按钮
  const onSelectCopy = (id) => {
    let text = document.getElementById(`${id}`);
    text.select();
    document.execCommand("Copy");
    toggleToast("Copy successfully");
  };

  useEffect(() => {
    let isMount = false;
    if (!isMount && Object.values(datas).length > 0 && open) {
      handleRefund(datas);
    }
    return () => (isMount = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas,open]); //数据初始化

  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;

  return (
    <div>
      {toastMarkup}
      <Modal
        open={open}
        loading={getRefundData()?.saleInfoLoading}
        onClose={() => setIsShowRefundTipsModal()}
        title={intl.get("Refund Tips")}
        primaryAction={{
          content: intl.get("Okay"),
          onAction: () => refundOrderTipSave(),
        }}
      >
        <Modal.Section>
          <div style={{ paddingLeft: "100px" }}>
            <div style={{ fontSize: "16px" }}>
              {intl.get(
                "Please contact your personal agent to process your refund."
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "16px 0",
              }}
            >
              <div
                style={{
                  border: "1px solid rgb(243 242 242)",
                  borderRadius: "50%",
                  overflow: "hidden",
                  lineHeight:1,
                  width: "60px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    bordeRadius: "50%",
                    height: "100%",
                  }}
                  src={
                    refundData.saleInfoData.avatar_url
                      ? refundData.saleInfoData.avatar_url
                      : "/customer_service.png"
                  }
                  alt="dropshipman"
                />
              </div>
              <p
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {refundData.saleInfoData.name}
              </p>
            </div>
            {refundData.saleInfoData.email !== "" && (
              <div className="refund_copy_item">
                <span>
                  <img
                    width={20}
                    height={20}
                    src="/refund_email.png"
                    alt="dropshipman"
                  />
                </span>
                <span style={{ margin: "0 10px", fontSize: "16px" }}>
                  {refundData.saleInfoData.email}
                </span>
                <span className="refund_tip_copy">
                  <input
                    onChange={() => console.log("")}
                    type="text"
                    id="copy_one"
                    value={refundData.saleInfoData.email}
                  />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => onSelectCopy("copy_one")}
                >
                  <img
                    width={18}
                    height={18}
                    src="/refund_copy.png"
                    alt="dropshipman"
                  />
                </span>
              </div>
            )}
            {refundData.saleInfoData.whatsapp !== "" && (
              <div className="refund_copy_item">
                <span>
                  <img
                    width={20}
                    height={20}
                    src={"/refund_phone.png"}
                    alt="dropshipman"
                  />
                </span>
                <span style={{ margin: "0 10px", fontSize: "16px" }}>
                  {refundData.saleInfoData.whatsapp}
                </span>
                <span className="refund_tip_copy">
                  <input
                    onChange={() => console.log("")}
                    type="text"
                    id="copy_two"
                    value={refundData.saleInfoData.whatsapp}
                  />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => onSelectCopy("copy_two")}
                >
                  <img
                    width={18}
                    height={18}
                    src="/refund_copy.png"
                    alt="dropshipman"
                  />
                </span>
              </div>
            )}
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
});

export default RefundTipsModal;
