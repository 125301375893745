import {
  Page,
  Card,
  Layout,
  IndexTable,
  Thumbnail,
  TextField,
  BlockStack,
  Box,
  Link,
  Button,
  InlineStack,
  Text,
  EmptyState,
  SkeletonPage,
  SkeletonBodyText,
  DropZone,
  Toast,
  Banner,
  useIndexResourceState,
  Icon,
} from "@shopify/polaris";
import {DeleteIcon} from "@shopify/polaris-icons";
import Zmage from 'react-zmage';
import 'react-zmage/lib/zmage.css';
import Switch from "react-switch";
import React, { useEffect, useState } from 'react';
import './index.css';
import intl from '../../../i18n/intl';

import utils from '../../../utils/utils';
import { useHistory, withRouter } from "react-router-dom";
import { reqPostCustomPackagingPurchase, reqPostCustomPackagingPurchaseCreateOrder, reqPostCustomPackagingPurchaseUpload } from "../../../api";
// import AppBridge from "../../../utils/appBridge";

const Index = ()=> {
  const router = useHistory();
  // const [allResourcesSelected,setAllResourcesSelected] = useState(false)
  // const [selectedResources,setSelectedResources] = useState([])
  const [initPageData,setInitPageData] = useState({
    isInit:true,
    pageLoading:false
  })
  const [uploadImageList,setUploadImageList] = useState({
    files: [],  // 正在上传的图片数组
    rejectedFiles: [],
    successImages: [],
    modalShow: false,
    nextUrl: '',// 成功上传的数组
    isPreview:false, //预览状态
    imageUrlErr:'',
    imageUrl:'',
    switchLogo:true,
  });

  const [purchasePageData,setPurchaseData] = useState({});

  const getPurchasePage = async ()=>{
    let url = new URLSearchParams(window.location.search);
    let record_id = url.get("record_id")
    let params = {
      record_id:record_id
    }
    try {
      let res = await reqPostCustomPackagingPurchase(params);
      if (res.code === '200') {
        setPurchaseData(res.data);
        setUploadImageList((prevValue)=>({
          ...prevValue,
          successImages:res.data.detail.logo_url
        }))
      }
      setInitPageData({
        isInit:false,
        pageLoading:false
      })
    } catch (error) {
      
    }
    
  }
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      // if(AppBridge && AppBridge.exist()) {
      //   AppBridge.setTitleBar('Custom Packaging','/admin/customService')
      // }
      getPurchasePage()
    }
    return () => {
      isMount = true;
    };
  }, []);


  // 总数量
  const quantityFun = (data)=>{
    let num = 0;
    let variants =[];
    if (data) {
      data.forEach((item)=>{
        if (selectedResources.includes(item.id)) {
          variants.push(item)
        } 
      })
    }
    if (variants.length > 0) {
      variants.forEach((item)=>{
        num += Number(item.quantity)
      })
    }
    return num;
  }

  // 总金额
  const totalPriceFun = (data)=>{
    let num = 0;
    let variants =[];
    if (data) {
      data?.forEach((item)=>{
        if (selectedResources.includes(item.id)) {
          variants.push(item)
        } 
      })
    }
    
    if (variants.length > 0) {
      variants?.forEach((item)=>{
        num += Number(item.price) * Number(item.quantity)
    })
    }
   
    return num.toFixed(2);
  }


  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;

 // 图片上传处理
 const handleDrop = (droppedFiles, acceptedFiles, rejectedFiles) => {
  try {
    const res = utils.sizeFilter(acceptedFiles[0].size)
    
    const fileType = acceptedFiles[0].name
    //判断文件上传规则
    if (res > 5 || fileType.indexOf('jpg') === -1 & fileType.indexOf('png') === -1) {
      setUploadImageList((prevValue)=>({
        ...prevValue,
        imageUrlErr: 'Logo must be jpg or png, up to 5M',
      }))
      return false
    }
  } catch (error) {
  }

  // 正在上传图片时
  if (uploadImageList.files.length > 0) {
    toggleToast(intl.get("Image is uploading"), false);
    setUploadImageList((prevValue)=>({
      ...prevValue,
      imageUrlErr: '',
    }))
    return;
  }

  setUploadImageList((prevValue)=>({
    ...prevValue,
    files: [...uploadImageList.files, ...acceptedFiles],
    rejectedFiles: rejectedFiles
  }))
   // 上传图片
   if (acceptedFiles.length > 0) {
    var imageData = [];
    for (var i = 0; i < acceptedFiles.length; i++) {
      const filesImg = acceptedFiles[i];
      // 开启FileReader
      var reader = new FileReader();
      reader.readAsDataURL(filesImg); // 读取文件保存在result中
      reader.onload = (e) => {
        var getRes = e.target.result; // 读取的结果在result中
        imageData.push(
          {
            'name': filesImg.name,
            'image': getRes,
            'type': filesImg.type,
          }
        );
      }
    }

    // 延时请求，预防拿不到图片数据
    setTimeout(() => {
      uploadImages(imageData);
    }, 1000);
  }
 
}
const uploadImages = async (imageData) => {
  const params = {
    file_data: imageData,
  }
  const response = await reqPostCustomPackagingPurchaseUpload(params)
 
  if (response.code === '200') {
    
    let successArr = uploadImageList.successImages;
    let getData = response.data;
    if (getData.length > 0) {
      for (let index = 0; index < getData.length; index++) {
        successArr.push(getData[index]);
      }
      setUploadImageList((prevValue)=>({
        ...prevValue,
        successImages: successArr,
        files: [],
        rejectedFiles: [],
        imageUrlErr: '',
      }))
    }
    setUploadImageList((prevValue)=>({
      ...prevValue,
      imageUrlErr: '',
    }))
    // this.toggleToast(response.msg, false);
  } else {
  
    // this.toggleToast(response.msg);
    setUploadImageList((prevValue)=>({
      ...prevValue,
      files: [],
      rejectedFiles: [],
      imageUrlErr: response.msg,
    }))
  }
}
    // 移除图片链接
 const removeImage = (recordKey) => {
    let data = uploadImageList.successImages;
    data.splice(recordKey, 1);
    setUploadImageList((prevValue)=>({
      ...prevValue,
      successImages: data,
    }))
  }

  const {selectedResources, allResourcesSelected, handleSelectionChange} =
  useIndexResourceState(purchasePageData?.detail?.variants);
  // 页面更新

  // 修改库存数量
  const onChangeQuantity = (value, recordKey) => {
    let data = purchasePageData.detail.variants;
    let num = Number(value);
    data[recordKey].quantity = num;
    setPurchaseData((prevValue)=>({
      ...prevValue,
      detail:{
        ...prevValue.detail,
        variants:data
      }
    }))
  }
  const onChangeBulrQuantity = (value, recordKey) => {
    let data = purchasePageData.detail.variants;
    let num = Number(value);
    if (num < purchasePageData?.minimum_quantity) {
      num = Number(purchasePageData?.minimum_quantity)
    }
    if (num > 99999) {
      num = 99999;
    }
    data[recordKey].quantity = num;
    setPurchaseData((prevValue)=>({
      ...prevValue,
      detail:{
        ...prevValue.detail,
        variants:data
      }
    }))
  }

  // 创建订单
  const handleCreateOrder = async () => {
    let list = purchasePageData?.detail?.variants;
   let variants = [];
   if (list) {
    list?.forEach((item)=>{
      if (selectedResources.includes(item.id)) {
        item.selected = 1;
        variants.push(item)
      }
     })
   }
   

  //  如果勾选了logo，但是没有上传图片
  if (uploadImageList.switchLogo && uploadImageList.successImages.length === 0) {
    setUploadImageList((prevValue)=>({
      ...prevValue,
      imageUrlErr: 'Please upload at least one logo.',
    }))
    return;
  }
    let params = {
      record_id: purchasePageData.detail.record_id,
      variants: variants,
      images:uploadImageList.switchLogo ? uploadImageList.successImages : ''
    }
    let res = await reqPostCustomPackagingPurchaseCreateOrder(params);
    if (res.code === "200") {
      utils.routeListen('/admin/customService/custom-packaging')
      utils.routeJump1(router,'/admin/customService/custom-packaging?selected=1')
    }
  }
 
  const EmptyProductList = (
    <div className="emptyWithImg">
      <EmptyState
        heading={intl.get("Sorry, no results found.")}
        image="/empty.svg"
      >
      </EmptyState>
    </div>
  );
  

  const skeletonPage =  <SkeletonPage fullWidth={false}>
  <Layout>
   <Layout.Section>
   <Card>
   <BlockStack gap={400}>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   </BlockStack>
   </Card>
 </Layout.Section>
   </Layout>
 </SkeletonPage>
    // 列表数据
    const rowMarkup = Object.values(purchasePageData).length > 0 && (purchasePageData?.detail?.variants.map((item, index) => {
      let imageUrl = '/default_image.png';
      if (item.sku_image !== '' && (typeof item.sku_image) !== 'object') {
        imageUrl = item.sku_image;
      }
     
      return (
        <IndexTable.Row
          id={item.id}
          key={item.id}
          selected={selectedResources.includes(item.id)}
          position={index}
        >
          <IndexTable.Cell>
          <Link
            dataPrimaryLink
            url={''}
            onClick={() => console.log()}
          >
            <Thumbnail source={imageUrl} alt={purchasePageData?.detail.title} />
          </Link>
         
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div className="Inventory_Product">
              <div className="Inventory_Product__Title shopping_cart" title={purchasePageData?.detail.title}><span>{purchasePageData?.detail.title}</span></div>
              <div className="Inventory_Variant_Title">{item.option_one}</div>
              <div>{item.option_two}</div>
              <div>{item.option_three}</div>
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div>{'$' + (item.price).toFixed(2) + ' USD'}</div>
          </IndexTable.Cell>

          <IndexTable.Cell>
            <div>
              <BlockStack>
                <span>{'$' + (Number(item.price) * Number(item.quantity)).toFixed(2) + ' USD  *'}</span>
                <TextField
                  type='number'
                  onChange={(value) => {onChangeQuantity(value,index)}}
                  onBlur={(e) => {onChangeBulrQuantity(e.target.value, index)}}
                  value={String(item.quantity)}
                  min={purchasePageData?.minimum_quantity}
                />
              </BlockStack>
            </div>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    },
    ));

      const fileUpload = !uploadImageList.files.length && (
        <DropZone.FileUpload actionTitle="Upload" actionHint="jpg, and .png Up to 5MB" />
      );
    
     // 图片正在上传状态
    const uploadedFiles = uploadImageList.files.length > 0 && (
      <div className="config_logo">
        {uploadImageList.files.map((file, index) => (
          <div style={{ position: 'relative' }} key={index}>
            <Thumbnail
              alt={'Dropshipman'}
              source={window.URL.createObjectURL(file)}
              size="large"
            />
           
            <div className="Product__Loading">
              <div className="Sourcing_Request__Loading_Image">
                <img style={{ width: '25px', height: '25px', verticalAlign: 'middle' }} src='/loading.gif' alt="loading" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );

    // 图片logo上传成功展示
    const successImagesFiles = uploadImageList.successImages.length > 0 && (
      <div className="config_logo">
        { uploadImageList.successImages.map((it, kk) => (
          <div className="Sourcing_Image_Item" style={{ position: 'relative' }} key={kk}>
           <div className='imagePreview'>
              <Zmage src={it} alt='' />
            </div>
            <div className="Sourcing__Loading purchase_Config_Logo__Image">
              <div className="Config_Logo__Image">
                <div className="image_remove__button" >
                  <span onClick={() => removeImage(kk)}><Icon source={DeleteIcon} ></Icon></span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
    const resourceName = {
      singular: 'id',
      plural: 'orders',
    };
    // const promotedBulkActions = [
    //   {
    //     content: 'Remove',
    //     onAction: () => console.log('Todo: implement bulk edit'),
    //   },
    // ];
    const pageConteent = initPageData.isInit ? skeletonPage : (  <Page
      backAction={
        false ? undefined : {
        content:'',
        onAction: () => {
          utils.routeListen('/admin/customService/custom-packaging')
          utils.routeJump1(router,'/admin/customService/custom-packaging')
        }
      }}
      title={<div style={{fontSize:20}}>{intl.get('Purchase')}</div>}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap={400}>
          
        <Box>
          <Card>
        {Object.values(purchasePageData).length > 0 ? <Box style={{maxHeight:'380px',overflowY:'auto',overflowX:'hidden'}}>
            <IndexTable
                  resourceName={resourceName}
                  itemCount={purchasePageData?.detail?.variants.length}
                  selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  // promotedBulkActions={promotedBulkActions}
                  headings={[
                    { title: '' },
                    { title: 'Sku' },
                    { title: 'Price' },
                    { title: 'Quantity' },
                  ]}
                >
                {rowMarkup}
                  
                </IndexTable>
            </Box>:EmptyProductList}
            </Card>
        </Box>
        <Box>
          <Card>
            <Box className="packing_logo_content">
              <Box className="packing_logo_content_left"> 
              <Box className="packing_logo_content_left_title">
                <strong>Add logo to packaging</strong>
                <Switch
              onChange={(nextChecked) =>{setUploadImageList({...uploadImageList,switchLogo:nextChecked,imageUrlErr:''})}}
              checked={uploadImageList.switchLogo}
              width={50}
              height={20}
              onColor='#303030'
              offColor="#DADADA"
              uncheckedIcon={<div className="switchBtn"></div>}
            />
              </Box>
              <Box className="packing_logo_content_left_desc">
                <p>The logo will print on the packaging.For special requests, contact your Personal Agent.</p>
              </Box>
              </Box>
              {uploadImageList.switchLogo && <Box className="packing_logo_content_right">
                {( uploadImageList.successImages.length > 0) && <div>
                {( uploadImageList.successImages.length > 0) ? (
                  successImagesFiles
                ) : (
                  <DropZone
                    allowMultiple={false}
                    accept="image/*"
                    type="image"
                    onDrop={handleDrop}
                  >
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                )}
              </div>}
              {( uploadImageList.successImages.length < 2 ) && <div style={{ width: 112, height: 100 }}>
                  <DropZone
                    accept="image/*"
                    type="image"
                    onDrop={handleDrop}
                  >
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
              </div>}
              </Box>}
            </Box>
            {uploadImageList.imageUrlErr !== '' && <Banner
                title={intl.get("Upload image")}
                tone="warning"
                onDismiss={() => {
                 setUploadImageList({...uploadImageList,imageUrlErr:''})
                }}
              >
                <p>
                  {uploadImageList.imageUrlErr}
                </p>
              </Banner>}
          </Card>
        </Box>
        <Box>
        <Card>
           <Text variant="headingMd" as="h2">{intl.get("Order")}</Text>
           <BlockStack>
              <InlineStack align='space-between'>
              <Box>
                <Text variant="headingMd" as="h2">{intl.get("Quantity")}:</Text>
              </Box>
              <Box>
                <div className='prepaid_order_total'>{quantityFun(purchasePageData?.detail?.variants)}</div>
              </Box>
              </InlineStack>
            </BlockStack>
            <BlockStack>
              <InlineStack align='space-between'>
              <Box>
                <Text variant="headingMd" as="h2">{intl.get("Subtotal")}:</Text>
              </Box>
              <Box>
                <div className='prepaid_order_total'>{'$ ' + totalPriceFun(purchasePageData?.detail?.variants) + ' USD'}</div>
              </Box>
              </InlineStack>
              <Box style={{ marginTop: '10px' }}>
              <InlineStack align='end'>
              <Button variant='primary' disabled={selectedResources.length === 0} onClick={()=>{handleCreateOrder()}}>{intl.get('Continue')}</Button>
              </InlineStack>
              </Box>
            </BlockStack>
          </Card>
        </Box>
        </BlockStack>
        </Layout.Section>
        </Layout>
        </Page>
    )
    return (
        <div className='purchase_list'>
         {pageConteent}
         {toastMarkup}
        </div>
    );
}


export default withRouter(Index);
