import React, { memo } from "react";
import { Modal } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";
const OrderNoteModal = memo(
  ({
    active,
    handleChange,
    invoicesLoading,
    onInvoices,
    invoicesData,
    invoicesForm,
    onInvoicesSubmit,
  }) => {
    return (
      <div>
        <Modal
          size="large"
          open={active}
          onClose={handleChange}
          title={intl.get("Invoices")}
          primaryAction={{
            content: intl.get("Download"),
            onAction: onInvoicesSubmit,
            disabled: invoicesLoading,
          }}
          secondaryActions={[
            {
              content: intl.get("Close"),
              onAction: handleChange,
              disabled: invoicesLoading,
            },
          ]}
          loading={invoicesLoading}
        >
          <div className="invoices_modal">
            <div className="invoices_modal_title">
              <img src="/dropshipman-logo.png" alt="dropshipman"></img>
              {intl.get("Dropshipman Limited")}{" "}
            </div>
            <div className="invoices_modal_box">
              <div className="invoices_modal_top">
                <div className="invoices_modal_number">
                {intl.get("INVOICE Number")}: {invoicesData.invoice_no}
                </div>
                <div className="invoices_modal_date">
                {intl.get("REVISED DATE")}: {invoicesData.revised_date}
                </div>
              </div>
              <div className="invoices_modal_mid">
                <div style={{ position: "relative" }}>
                  <span>{intl.get("Company Name")}: </span>
                  <input
                    maxLength={100}
                    style={{
                      borderColor: invoicesForm.inputNameTip
                        ? "#f00"
                        : "rgba(190, 187, 187,.8)",
                    }}
                    value={invoicesForm.companyName}
                    disabled={invoicesData.id ? true : false}
                    onChange={(e) => onInvoices(e, "name")}
                  />
                  {invoicesForm.inputNameTip && (
                    <p
                      style={{
                        position: "absolute",
                        left: "20%",
                        bottom: "-5px",
                        color: "#f00",
                      }}
                    >
                      {intl.get("Please fill in the required fields.")}
                    </p>
                  )}
                </div>
                <div style={{ position: "relative" }}>
                  <span>{intl.get("Company Number")}:</span>{" "}
                  <input
                    maxLength={100}
                    style={{
                      borderColor: invoicesForm.inputNumberTip
                        ? "#f00"
                        : "rgba(190, 187, 187,.8)",
                    }}
                    value={invoicesForm.companyNumber}
                    disabled={invoicesData.id ? true : false}
                    onChange={(e) => onInvoices(e, "number")}
                  />
                  {invoicesForm.inputNumberTip && (
                    <p
                      style={{
                        position: "absolute",
                        left: "20%",
                        bottom: "-5px",
                        color: "#f00",
                      }}
                    >
                      {intl.get("Please fill in the required fields.")}
                    </p>
                  )}
                </div>
                <div style={{ position: "relative" }}>
                  <span>{intl.get("Address")}:</span>{" "}
                  <input
                    maxLength={200}
                    style={{
                      borderColor: invoicesForm.inputAddressTip
                        ? "#f00"
                        : "rgba(190, 187, 187,.8)",
                    }}
                    value={invoicesForm.companyAddress}
                    disabled={invoicesData.id ? true : false}
                    onChange={(e) => onInvoices(e, "address")}
                  />
                  {invoicesForm.inputAddressTip && (
                    <p
                      style={{
                        position: "absolute",
                        left: "20%",
                        bottom: "-5px",
                        color: "#f00",
                      }}
                    >
                      {intl.get("Please fill in the required fields.")}
                    </p>
                  )}
                </div>
              </div>
              <div className="invoices_modal_bottom">
                <div>
                  <div>{intl.get("Name of Commodity & Specification")}</div>
                  <div>{intl.get("Quantity")}</div>
                  <div>{intl.get("Product Cost")}</div>
                  <div>{intl.get("Cost 20% VAT IOSS")}</div>
                  <div>{intl.get("Order Amount")}</div>
                </div>
                {invoicesData.order_detail &&
                  invoicesData.order_detail.map((item, ind) => (
                    <div key={ind}>
                      <div style={{ textAlign: "left" }}>
                        <p className="invoices_title_table">{item.title}</p>
                        <p>{item.sku}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.quantity}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        USD {item.product_cost}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.cost_vat}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        USD {item.order_amount}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="invoices_total_price">
              <div>
                <span>{intl.get("Shipping")}:</span>USD {invoicesData.shipping_cost}
              </div>
              <div>
                <span>{intl.get("Total")}:</span>USD {invoicesData.total_order_amount}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default OrderNoteModal;
