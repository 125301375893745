import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

// import utils from '../utils/utils'

const resources = {
    en: {
      translation: require('./locales/en.js').default
    },
    fr: {
      translation: require('./locales/fr.js').default
    },
    de: {
      translation: require('./locales/de.js').default
    },
    es: {
      translation: require('./locales/es.js').default
    },
    it: {
      translation: require('./locales/it.js').default
    },
    pt: {
      translation: require('./locales/pt-pt.js').default
    },
    ptB: {
      translation: require('./locales/pt-br.js').default
    },
    nl: {
      translation: require('./locales/nl.js').default
    },
    // zh: {
    //   translation: require('./locales/zh.json')
    // },
  };
var language = window.language; // 获取语言设置
if (!language) {
    const lang = (navigator.languages && navigator.languages[0]) || navigator.language; // 获取浏览器语言
    language = lang.split('-')[0]; // 语言（简写）
}

// const language = (navigator.languages && navigator.languages[0]) || navigator.language;
// var lang = language.split('-')[0]; // 语言（简写）
i18n.use(initReactI18next) 
    .init({
        resources,
        lng: language,
        nsSeparator: false, // key的空间分割，不分割
        keySeparator:false,// key的分割字符 不分割
        fallbackLng:'en', // resources中匹配不到时的语言
        interpolation: {
            escapeValue: false, 
        }
    });

  var get = function(key,v1,v2,v3,v4) { 
    // 不传入变量
    // 调取语言包，翻译
    // console.log("key=",key,v1);
    // console.log("value=",value);
    // if (value === key) { // 语言包中没有相应的key
    //     // console.log(key,"未找到(get)");
    //     var keyArray = key.split('::');
    //     value = keyArray[keyArray.length-1];
    // }

    return i18n.t(key, {v1,v2,v3,v4});
  }
  let obj = {
    get:get
  }
export default obj;
