import React, { Component } from "react";

import {
  Layout,
  Toast,
  TextField,
  Thumbnail,
  Select,
  Button,
  Popover,
  Card,
  Banner,
  FormLayout,
  DropZone,
  BlockStack,
  List,
  InlineError,
  // ChoiceList,
  // Checkbox,
  // InlineStack,
  Box,
  InlineStack,
  Page,
  SkeletonPage,
  SkeletonBodyText,
  FullscreenBar,
  Modal,
  Icon,
  // ChoiceList,
} from "@shopify/polaris";
import {
  ImageAddIcon,
  SelectIcon
} from "@shopify/polaris-icons";
import utils from "../../../../utils/utils";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  ckeditorConfig,
  reqPostSourcingRequestSave,
  reqPostUploadImage,
} from "../../../../api";

import "./index.css";
import intl from "../../../../i18n/intl";
import AppBridge from "../../../../utils/appBridge";
import { Fullscreen } from "@shopify/app-bridge/actions";
import CountrySelectAllList from "../component/CountrySelectAllList"
class SourcingRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalShow: true,
      loadingPage: false,
      saveLoading: false,
      addMediaLoading: false,
      isFullscreen:true,
      productTitle: "",
      productTitleError: "",
      descValue: "",
      productUrl: "",
      productUrlError: "",
      productType: "",
      productTypeError: "",
      targetPriceError: "",
      imageError: false,
      purchasingType: "Dropshipping",
      targetPrice: "",
      mainCountryCode: "US",
      mainCountryLabel:"United States",
      showCountry:false,
      showCountryLoading:false,
      isPoints:false,
      files: [], // 正在上传的图片数组
      rejectedFiles: [], //
      successImages: [], // 成功上传的数组(包括手动添加的图片链接)
      checked: true,
      addImageActive: false,
      addImageLink: "",
      addImageError: "",
      imageUrlErr: intl.get("Please upload a product image"),
      leaveModal:false,
      isInput:false,
      productTypeConfig: [
        { label: intl.get("Select"), value: "0" },
        { label: intl.get("Women's Fashion"), value: "Women's Fashion" },
        { label: intl.get("Man's Fashion"), value: "Man's Fashion" },
        { label: intl.get("Computer & Office"), value: "Computer & Office" },
        { label: intl.get("Bags & Shoes"), value: "Bags & Shoes" },
        { label: intl.get("Jewelry & Watches"), value: "Jewelry & Watches" },
        {
          label: intl.get("Health & Beauty, Hair"),
          value: "Health & Beauty, Hair",
        },
        { label: intl.get("Sports & Outdoors"), value: "Sports & Outdoors" },
        {
          label: intl.get("Home & Garden, Furniture"),
          value: "Home & Garden, Furniture",
        },
        { label: intl.get("Home improvement"), value: "Home improvement" },
        {
          label: intl.get("Automobiles & Motorcycles"),
          value: "Automobiles & Motorcycles",
        },
        { label: intl.get("Toys, Kids & Baby"), value: "Toys, Kids & Baby" },
        {
          label: intl.get("Consmuer Electronics"),
          value: "Consmuer Electronics",
        },
        {
          label: intl.get("Phones & Accessories"),
          value: "Phones & Accessories",
        },
        { label: intl.get("Other"), value: "Other" },
      ],
    };
    this.wrapperRef = React.createRef(); // 用于引用特定 DOM 元素
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      {
        ModalShow: nextProps.show === undefined ? false : nextProps.show,
        loadingPage: false,
        saveLoading: false,
        addMediaLoading: false,

        productTitle: this.props?.history.location.state?.productTitle ? this.props?.history?.location?.state?.productTitle : "",
        isPoints: this.props?.history?.location?.state?.isPoints ? this.props?.history?.location?.state?.isPoints : false,
        productTitleError: "",
        descValue: "",
        productUrl: "",
        productUrlError: "",
        productType: "",
        productTypeError: "",
        targetPriceError: "",
        imageError: false,
        purchasingType: "Dropshipping",
        targetPrice: "",
        mainCountryCode: "US",

        files: [], // 正在上传的图片数组
        rejectedFiles: [], //
        successImages: [], // 成功上传的数组(包括手动添加的图片链接)

        addImageActive: false,
        addImageLink: "",
        addImageError: "",
      },
      () => {
       
      }
    );
  }

  //进入页面调用接口
  componentDidMount() {
      // 添加全局点击事件监听
      document.addEventListener("mousedown", this.handleClickOutside);
    const app = window.DSMAppAuth;
    if(app) {
        this.FullExitListen = app.subscribe(Fullscreen.Action.EXIT, () => {// 监听全屏退出
            if(this.closeFullscreen) {
                this.closeFullscreen();
            }
        })
    }
    if (AppBridge.exist()) {
        AppBridge.SetFullscreen(true)
        if (this.props?.history?.location?.state) {
            this.setState({
              isPoints: this.props?.history?.location?.state?.isPoints ? this.props?.history?.location?.state?.isPoints : false,
              productTitle: this.props?.history?.location?.state?.productTitle ? this.props?.history?.location?.state?.productTitle : '',
            })
        }
    }else {
        this.props.history.push('/404')
    }
  }
  
  componentWillUnmount() {// 销毁监听
   if (this.FullExitListen) {
    this.FullExitListen();
   }
    // 移除全局点击事件监听
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    // 检查点击是否发生在指定元素外部
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showCountry:false
      })
      this.props.onOutsideClick && this.props.onOutsideClick(); // 执行外部点击的回调
    }
  };
  closeFullscreen = ()=>{
    if (this.state.isInput) {
      this.setState({
        leaveModal:!this.state.leaveModal
      })
      if (AppBridge.exist()) {
        AppBridge.SetFullscreen(true)
        if (this.props?.history.location.state) {
            this.setState({isPoints: this.props?.history?.location?.state.isPoints})
        }
    }
    }else {
      this.setState({isFullscreen: !this.state.isFullscreen})
      utils.routeListen("/admin/product_selection?select_tab=2")
      utils.routeJump(this,"/admin/product_selection?select_tab=2")
      if (AppBridge.exist()) {
        AppBridge.SetFullscreen(false)
    }
    }
   
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    }));
  };

  

  // 弹窗控制
  handleChangeModal = () => {
    this.setState({
      ModalShow: !this.state.ModalShow,
      files: [],
      rejectedFiles: [],
      successImages: [],
    });
  };

  // input change
  handleInputChange = (field, value) => {
    this.setState(
      {
        [field]: value,
        isInput:true
      },
      () => {
        if (field === "productTitle" && value !== "") {
          if (this.state.productTitleError !== "") {
            this.setState({
              productTitleError: "",
            });
          }
        }
        if (field === "productUrl" && value !== "") {
          if (this.state.productUrlError !== "") {
            this.setState({
              productUrlError: "",
            });
          }
        }
        if (field === "targetPrice" && value !== "") {
          if (this.state.targetPriceError !== "") {
            this.setState({
              targetPriceError: "",
            });
          }
        }
      }
    );
  };

  // select change
  handleSelectChange = (field, value) => {
    
    this.setState(
      {
        [field]: value,
        isInput:true
      },
      () => {
        if (field === "productType" && value !== "") {
          if (this.state.productTypeError !== "") {
            this.setState({
              productTypeError: "",
            });
          }
        }
      }
    );
  };

  // sourcing 确认请求
  handleSourcingRequest = async () => {
    const {
      productTitle,
      descValue,
      productUrl,
      productType,
      targetPrice,
      mainCountryCode,
      successImages,
      checked,
    } = this.state;

    let isAllow = true;
    let productTitleError = "";
    let productUrlError = "";
    let productTypeError = "";
    let targetPriceError = "";
    let isEmptyImage = false;
    if (productTitle === "") {
      productTitleError = intl.get("Please enter a product title");
      isAllow = false;
    }

    if (productUrl !== "") {
      var re =
        /(http|ftp|https):\/\/[\w\-_]+(.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/;
      if (re.test(productUrl) === false) {
        productUrlError = intl.get("URL is invalid");
        isAllow = false;
      }
      // if(productUrl.length >= 500){
      //   productUrlError = intl.get('The link must be between 1 and 500 characters');
      //   isAllow = false;
      // }
    } else {
      productUrlError = intl.get("Please enter a product URL");
      isAllow = false;
    }

    if (productType === "") {
      productTypeError = intl.get("Please select a product type");
      isAllow = false;
    }
    if (targetPrice === "" || targetPrice === 0 || targetPrice === "0") {
      targetPriceError = intl.get("Please enter a valid target price");
      isAllow = false;
    }
    if (successImages.length === 0) {
      isAllow = false;
      isEmptyImage = true;
    }

    this.setState({
      productTitleError: productTitleError,
      productUrlError: productUrlError,
      productTypeError: productTypeError,
      targetPriceError: targetPriceError,
      imageError: isEmptyImage,
    });

    if (!isAllow) {
      return false;
    }

    this.setState({ saveLoading: true });
    const params = {
      title: productTitle,
      description: descValue,
      link: productUrl.slice(0, 498),
      product_type: productType,
      // purchasingType: purchasingType,
      price: targetPrice,
      main_country: mainCountryCode, 
      is_public: checked ? 1 : 0,
      is_point: this.state.isPoints ? 1 : 0,
      images: successImages.join(","),
      // images: 'https://cf.cjdropshipping.com/quick/product/9367d0ad-c922-4f31-a766-5c366f825cb0.jpg?x-oss-process=image/format,webp,image/resize,m_fill,m_pad,w_60,h_60',
    };
    

    const response = await reqPostSourcingRequestSave(params);
    this.setState({
      saveLoading: false,
    });
    if (response.code === "200") {
      // 更新父组件页面
      if (this.state.isPoints) {
        this.toggleToast("Successfully Redeemed", false);
      }else {
        this.toggleToast("Submit successfully", false);
      }
      setTimeout(() => {
        this.setState({isFullscreen: !this.state.isFullscreen})
        utils.routeListen("/admin/product_selection?select_tab=2")
        utils.routeJump(this,"/admin/product_selection?select_tab=2")
        if (AppBridge.exist()) {
          AppBridge.SetFullscreen(false)
      }
      }, 2000);
      


    //   this.props.updateCallBackFun();
    //   this.props.handlePointsRedeemLess(this.state.isPoints);
      this.setState(
        {
          productTitle: "",
          descValue: "",
          productUrl: "",
          productType: "",
          purchasingType: "Dropshipping",
          targetPrice: "",
          mainCountryCode: "US",
          successImages: [],
        },
        () => {
          // 关闭弹窗
          this.handleChangeModal();
        }
      );
    } else {
      this.toggleToast(response.msg);
    }

  };

  // 图片上传处理
  handleDrop = (droppedFiles, acceptedFiles, rejectedFiles) => {
    // 正在上传图片时
    const res = utils.sizeFilter(acceptedFiles[0].size);
    if (res > 5) {
      this.setState({
        imageError: true,
        imageUrlErr: intl.get(
          "The image size should not exceed 5M, and must a JPG or PNG"
        ),
      });
      return false;
    }
    if (this.state.files.length > 0) {
      this.toggleToast("Image is uploading", false);
      return;
    }
    if (acceptedFiles[0].name.indexOf(".gif") !== -1) {
      // this.toggleToast("Do not allow uploading gif images", true);
      this.setState({
        imageError: true,
        imageUrlErr: intl.get("Do not allow uploading gif images"),
      });
      return;
    }
    this.setState(
      {
        files: [...this.state.files, ...acceptedFiles],
        rejectedFiles: rejectedFiles,
        isInput:true
      },
      () => {
        // 上传图片
        if (acceptedFiles.length > 0) {
          var imageData = [];
          for (var i = 0; i < acceptedFiles.length; i++) {
            const filesImg = acceptedFiles[i];
            // 开启FileReader
            var reader = new FileReader();
            reader.readAsDataURL(filesImg); // 读取文件保存在result中
            reader.onload = (e) => {
              var getRes = e.target.result; // 读取的结果在result中
              imageData.push({
                name: filesImg.name,
                image: getRes,
                type: filesImg.type,
              });
            };
          }

          // 延时请求，预防拿不到图片数据
          setTimeout(() => {
            this.uploadImages(imageData);
          }, 2000);
        }
      }
    );
  };

  // 图片上传
  uploadImages = async (imageData) => {
    const params = {
      data: imageData,
    };

    const response = await reqPostUploadImage(params);
    if (response.code === "200") {
      let successArr = this.state.successImages;
      let getData = response.data;
      if (getData.length > 0) {
        for (let index = 0; index < getData.length; index++) {
          successArr.push(getData[index]);
        }
        this.setState({
          successImages: successArr,
          files: [],
          rejectedFiles: [],
          imageError: false,
        });
      }
      // this.toggleToast(response.msg, false);
    } else {
      // this.toggleToast(response.msg);
      this.setState({
        files: [],
        rejectedFiles: [],
        imageError: true,
        imageUrlErr: response.msg,
      });
    }
  };

  // 移除图片链接
  removeImage = (recordKey) => {
    let data = this.state.successImages;
    data.splice(recordKey, 1);
    this.setState({
      successImages: data,
    });
  };

  // 添加图片链接弹窗
  togglePopoverActive = () => {
    this.setState({
      addImageActive: !this.state.addImageActive,
      addImageError: "",
    });
  };

  // 检测图片的有效性
  checkImgExists = (imgurl) => {
    return new Promise(function (resolve, reject) {
      var ImgObj = new Image();
      ImgObj.src = imgurl;
      ImgObj.onload = function (res) {
        resolve(res);
      };
      ImgObj.onerror = function (err) {
        reject(err);
      };
    });
  };
  // 手动添加图片链接
  handAddImageLink = () => {
    let imageUrl = this.state.addImageLink;
    if (imageUrl !== "") {
      this.setState({ addMediaLoading: true });

      // 检测图片的有效性
      if (imageUrl.indexOf("http") === -1) {
        this.setState({
          addImageError: intl.get("Image URL is invalid"),
          addMediaLoading: false,
        });
        return;
      }
      if (imageUrl.indexOf(".gif") !== -1) {
        this.setState({
          addImageError: intl.get("Image URL is invalid"),
          addMediaLoading: false,
        });
        return;
      }
      // if (!/\.(gif|jpg|jpeg|png|GIF|JPG|JPEG|PNG)$/.test(imageUrl)) {
      //   this.setState({
      //     addImageError: 'Image URL is invalid',
      //     addMediaLoading: false
      //   });
      //   return;
      // }

      try {
        this.checkImgExists(imageUrl)
          .then(() => {
            let data = this.state.successImages;
            data.push(imageUrl);

            this.setState({
              successImages: data,
              addMediaLoading: false,
              addImageLink: "",
              addImageError: "",
              imageError: false,
            });
            this.togglePopoverActive();
          })
          .catch(() => {
            this.setState({
              addImageError: intl.get("Image URL is invalid"),
              addMediaLoading: false,
            });
          });
      } catch (error) {
        this.setState({
          addImageError: intl.get("Image URL is invalid"),
          addMediaLoading: false,
        });
      }
    }
  };

  // 产品详情编辑
  editorContentChange = (data) => {
    this.setState({
      descValue: data,
      isInput:true
    });
  };

  handleChangeCheck = (value) => {
    this.setState({
      checked: value,
    });
  };
  handleActionClick =() => {
    this.closeFullscreen()
  };
  handleCloseLeaveModal = ()=>{
    this.closeFullscreen()
  }
  handleSaveLeaveModal =()=>{
    this.setState({isFullscreen: !this.state.isFullscreen})
      utils.routeListen("/admin/product_selection?select_tab=2")
      utils.routeJump(this,"/admin/product_selection?select_tab=2")
      if (AppBridge.exist()) {
        AppBridge.SetFullscreen(false)
    }
  }
  handleCountrySelect = (val,lab)=>{
    this.setState({
      mainCountryCode:val,
      mainCountryLabel:lab,
      showCountry:false
    })
  }
  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={() => this.toggleToast("")}
        error={this.state.showToastIserror}
      />
    ) : null;
    const {
      state: {
        saveLoading,
        files,
        rejectedFiles,
        successImages,
        descValue,
        productTypeConfig,
        addImageActive,
        addImageError,
        addImageLink,
        imageError,
        productTitle,
        productTitleError,
        productUrl,
        productType,
        productTypeError,
        targetPrice,
        targetPriceError,
        mainCountryCode,
        mainCountryLabel,
        showCountry,
        showCountryLoading,
        addMediaLoading,
        productUrlError,
        // checked
        imageUrlErr,
      },
    } = this;
    const hasError = rejectedFiles.length > 0;
    const fileUpload = !files.length && (
      <Box className="sourcing_product__add_image">
      <Icon
      source={ImageAddIcon}
      tone="base"
    />
    </Box>
    );

    const uploadedFiles = files.length > 0 && (
      <InlineStack>
        {files.map((file, index) => (
          <Box alignment="center" key={index}>
            <div style={{ position: "relative" }}>
              <Thumbnail
                alt={file.name}
                source={window.URL.createObjectURL(file)}
              />
              {/* <div>
              {file.name} <Caption>{file.size} bytes</Caption>
            </div> */}
              <div className="Product__Loading">
                <div className="Sourcing_Request__Loading_Image">
                  <img
                    style={{
                      width: "25px",
                      height: "25px",
                      verticalAlign: "middle",
                    }}
                    src="/loading.gif"
                    alt="loading"
                  />
                </div>
              </div>
            </div>
          </Box>
        ))}
      </InlineStack>
    );

    const successImagesFiles = successImages.length > 0 && (
      <div className="media_box">
        <InlineStack>
          {successImages.map((it, kk) => (
            <div
              className="Sourcing_Image_Item"
              style={{ position: "relative", margin: "5px 8px 0 0" }}
              key={kk}
            >
              <Thumbnail alt="product image" source={it} />

              <div
                className="Sourcing__Loading"
                style={{ display: "block" }}
              >
                <div className="Sourcing_Request__Image">
                  <div
                    className="image_remove__button"
                    onClick={() => this.removeImage(kk)
                    }
                  >
                    <svg
                      fillRule="evenodd"
                      viewBox="0 0 20 20"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M17 4h-3V2c0-1.103-.897-2-2-2H8C6.897 0 6 .897 6 2v2H3a1 1 0 100 2v13a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 100-2zM8 4h4V2H8v2zm0 12a1 1 0 001-1V9a1 1 0 10-2 0v6a1 1 0 001 1zm4 0a1 1 0 001-1V9a1 1 0 10-2 0v6a1 1 0 001 1z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </InlineStack>
      </div>
    );

    const errorMessage = hasError && (
      <Banner
        title={intl.get("The following images couldn’t be uploaded:")}
        tone="warning"
      >
        <List type="bullet">
          {rejectedFiles.map((file, index) => (
            <List.Item key={index}>
              {intl.get(
                `{{file}} is not supported. File type must be .jpg, .jpeg, .png.`,
                file.name
              )}
            </List.Item>
          ))}
        </List>
      </Banner>
    );

    const ModalContent = (
      <section style={{ minHeight: "420px" }}>
        <Card>
        <Banner
          tone="info"
          title={intl.get(
            "After sending a sourcing request, Dropshipman will search for products based on your requirements."
          )}
        ></Banner>
        <div style={{marginTop:'16px'}}>
              <FormLayout style={{padding: '0px 10px'}}>
                <TextField
                  label={intl.get("Product Title")}
                  onChange={this.handleInputChange.bind(this, "productTitle")}
                  value={productTitle}
                  placeholder={intl.get("")}
                  error={productTitleError}
                  maxLength={255}
                  showCharacterCount
                />
                <div className="sourcing_modal_list">
                  <div className="sourcing_modal_item">
                    <TextField
                      label={intl.get("Product URL")}
                      onChange={this.handleInputChange.bind(this, "productUrl")}
                      value={productUrl}
                      placeholder="https://..."
                      error={productUrlError}
                    />
                  </div>
                  <div className="sourcing_modal_item">
                    <Select
                      label={intl.get("Product type")}
                      options={productTypeConfig}
                      onChange={this.handleSelectChange.bind(
                        this,
                        "productType"
                      )}
                      value={productType}
                      error={productTypeError}
                    />
                  </div>
                  <div className="sourcing_modal_item">
                    <TextField
                      label={intl.get(
                        "Target price (Shipping fee included, USD only)"
                      )}
                      onChange={this.handleInputChange.bind(
                        this,
                        "targetPrice"
                      )}
                      value={String(targetPrice)}
                      placeholder="10.00"
                      type={"number"}
                      prefix={"$"}
                      error={targetPriceError}
                    />
                  </div>
                  <div className="sourcing_modal_item">
                    <p style={{marginBottom:'4px'}}>Destination Country</p>
                  <Box className="btn_currency">
                <Popover
                  active={showCountry}
                  activator={
                    <Button
                      loading={showCountryLoading}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showCountry) {
                         this.setState({ showCountry: false });
                        } else {
                          this.setState({ showCountry: true });
                        }
                      }}
                    >
                      <InlineStack align="space-between">
                        <p style={{ marginRight: "10px",color:"rgba(48, 48, 48, 1)",fontSize:'13px',fontWeight:'550' }}>
                        
                          <img
                            src={`https://flagcdn.com/w20/${mainCountryCode.toLowerCase()}.png`}
                            alt="..."
                          />
                          <span style={{ marginLeft: "5px" }}>{mainCountryLabel}</span>
                        </p>
                        <span>
                          <Icon source={SelectIcon}></Icon>
                        </span>
                      </InlineStack>
                    </Button>
                  }
                  autofocusTarget="first-node"
                  onClose={() => {}}
                >
                  <CountrySelectAllList
                    containerRef={this.wrapperRef}
                    setShowId={()=>{this.setState({showCountry:false})}}
                    handleCountrySelect={this.handleCountrySelect}
                    country={mainCountryCode}
                  />
                </Popover>
              </Box>
                  </div>
                </div>
                <div>
                  <InlineStack wrap={false} blockAlign="center">
                <div>
                    <DropZone
                      accept="image/*"
                      type="image"
                      onDrop={this.handleDrop}
                    >
                      {uploadedFiles}
                      {fileUpload}
                    </DropZone>
                    
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginBottom: "4px",
                      marginLeft:'10px'
                    }}
                  >
                    <BlockStack>
                      <BlockStack gap={300}>
                        <div style={{fontWeight:650,color:"rgba(48, 48, 48, 1)"}}>{intl.get("Media")}</div>
                        <div style={{color:"rgba(97, 97, 97, 1)"}}>HEIC, WEBP, SVG, PNG, or JPG. </div>
                        <div>
                         <InlineStack gap={100}> <span>or</span> <BlockStack>
                          <Popover
                            active={addImageActive}
                            preferredAlignment="left"
                            activator={
                              <div className="">
                                <Button
                                  variant="plain"
                                  disclosure
                                  textAlign="left"
                                  onClick={this.togglePopoverActive}
                                >
                                  {intl.get("Add media from URL")}
                                </Button>
                              </div>
                            }
                            onClose={this.togglePopoverActive}
                          >
                            <Card>
                              <TextField
                                label={intl.get("Paste image URL")}
                                onChange={this.handleInputChange.bind(
                                  this,
                                  "addImageLink"
                                )}
                                value={addImageLink}
                                placeholder="https://"
                                error={addImageError}
                              />

                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "8px",
                                }}
                              >
                                <Button onClick={this.togglePopoverActive}>
                                  {intl.get("Close")}
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={this.handAddImageLink}
                                  loading={addMediaLoading}
                                  disabled={addImageLink ? false : true}
                                >
                                  {intl.get("Add media")}
                                </Button>
                              </Box>
                            </Card>
                          </Popover>
                        </BlockStack></InlineStack></div>
                      </BlockStack>
                       
                    </BlockStack>
                  </div>
                  </InlineStack>
                  <div
                      style={{
                        margin: "4px 0",
                        display: imageError ? "block" : "none",
                      }}
                    >
                      <InlineError
                        message={imageUrlErr}
                        fieldID="myFieldID"
                      />
                    </div>
                  {errorMessage}
                  

                  {/* 上传成功的图片列表 */}
                  {successImagesFiles}
                  
                </div>

                <div className="sourcing_product_description">
                  <div
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    {intl.get("Description")}
                  </div>
                  <CKEditor
                    editor={ClassicEditor}
                    config={ckeditorConfig}
                    data={descValue}
                    onInit={(editor) => {
                      //   editor.plugins.get( 'FileRepository' ).createUploadAdapter = function( loader ) {
                      //    return new UploadAdapter( loader );
                      //   };
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.editorContentChange(data);
                    }}
                    onBlur={(editor) => {
                      // console.log( 'Blur.', editor );
                      // this.handleContentChange();
                    }}
                    onFocus={(editor) => {
                      // console.log( 'Focus.', editor );
                    }}
                  />
                </div>
              </FormLayout>
        </div>
        </Card>
        <div className="agreement_accept_sourcing" style={{justifyContent:'flex-end'}}>
          <div className="agreement_accept_button">
            {(
              <Button
                variant="primary"
                onClick={this.handleSourcingRequest}
                loading={saveLoading}
              >
                {intl.get("Submit a souring request")}
              </Button>
            )}
          </div>
        </div>
      </section>
    );

    const initSkeletonPage = (
      <div>
        <SkeletonPage fullWidth={false}>
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap={400}>
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      </div>
    );

 

  const fullscreenBarMarkup = (
    <FullscreenBar onAction={this.handleActionClick}>
     
    </FullscreenBar>
  );
  const leaveModal =(
    <Modal 
    title={"You have unsaved changes"}
    open={this.state.leaveModal}
    onClose={this.handleCloseLeaveModal}
    primaryAction={{content: "Leave edit", onAction: this.handleSaveLeaveModal}}
    secondaryActions={[{content: "Cancel", onAction: this.handleCloseLeaveModal}]}
    >
      <Modal.Section>
        <div>
        If you leave this page, all unsaved changes will be lost.
        </div>
      </Modal.Section>
    </Modal>
  )
    return (
      <div>
        {this.state.isFullscreen && fullscreenBarMarkup}
          {this.state.loadingPage ? (
            initSkeletonPage
          ) : (
            <Page
              title={intl.get("Sourcing Request")}
              fullWidth={false}
            >
              {ModalContent}
              {toastMarkup}
              {leaveModal}
            </Page>
          )}
          </div>
    );
  }
}

export default SourcingRequestModal;
