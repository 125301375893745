import {Modal} from "@shopify/polaris";
import React, { useState } from "react";
import { reqPostRedeemCancelOrder } from "../../../../../api";
const Index = ({removeData,setRemoveData,resetCallback}) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const handleSave = async () => {
        setSaveLoading(true);
        let res = await reqPostRedeemCancelOrder({dsm_order_id:removeData.id})
        if (res.code === '200') {
          setRemoveData((prevValue)=>({...prevValue,open:false}))
          resetCallback()
        }
      };
  return (
    <div className="upgrade_new_feature">
      <Modal
        open={removeData.open}
        onClose={() => 
          {
            setRemoveData((prevValue)=>({...prevValue,open:false}))
            setSaveLoading(false)
          }}
        title={'Cancel Product Redemption'}
        primaryAction={{
          content: "Yes",
          loading:saveLoading,
          onAction: () => {
            handleSave()
          },
        }}
        secondaryActions={[
          {
            content: "No",
            onAction: () => {
              setRemoveData((prevValue)=>({...prevValue,open:false}))
              setSaveLoading(false)
            }
          },
        ]}
      >
        <Modal.Section>
        <p style={{fontSize:'14px'}}>{'After canceling, this product redemption will be removed, and the points will be refunded. Are you sure you want to cancel?'}</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
