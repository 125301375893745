import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './index.css';
import utils from '../../../../utils/utils'
import intl from "../../../../i18n/intl";
import {
  Button,
  Card,
  Layout,
  Modal,
  EmptyState,
  Page,
  BlockStack,
  Thumbnail,
  FormLayout,
  Link,
  ButtonGroup,
  Tabs,
  SkeletonPage,
  SkeletonBodyText,
  Spinner,
  SkeletonDisplayText,
  Toast,
  Text,
} from "@shopify/polaris";
import {ChevronLeftIcon} from "@shopify/polaris-icons";
import LazyLoad from "react-lazyload";
import {
  reqFileMapSearchDetail,
  reqFileMapSearchImport,
  reqGoogleAnalytics,
} from "../../../../api";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation, Thumbs} from "swiper";
import StartRating from "stars-rating";
import ShippingMethodsModal from "../ProductDetail/components/ShippingMethodsModal";
import ReviewsList from "../ProductDetail/components/ReviewsList";


class SearchImage extends Component {
  state = {
    ImageList : [],
    ShowCountNumber :0,
    OssUrl:'',
    DetailModal:false,
    loadingPage:false,
    // 数据
    pageData: '',
    options: [],
    search_product_id: 0,  // 要查询的产品详情的产品ID
    fromPage: '', // 从哪个页面跳转过来的
    addLoading: false,
    load:false,
    chargeError:false,

    disableAddToCart: true,
    addToCartLoading: false,
    // 选中添加购物车的sku信息
    addCartItem: {
      id: 0,
      vid: 0
    },
    reviewCount: 0,  // DSM产品评论数量
    selectTab: 0,
    skuImage:"",//主视角图片
    defaultImage:'',//商品默认图片
    defaultPrice:0,//商品默认价格
    indexPrice:0,//商品价格
    thumbsSwiper:null,
    magnifierOff:false,
    skuData:[],//sku列表数据
    variantData:{},//变体链接数据
    addToProduct:[]
  };

  componentDidMount() {
    window.onscroll = this.onScroll;
    document.documentElement.style.scrollBehavior="smooth";
    if(this.props.location?.state){
      let result = this.props.location.state.result;
      let oss_url = this.props.location.state.url;
      this.setState({
        ImageList: result ? result : [],
        OssUrl:oss_url ? oss_url : '',
      }, () => {
        this.updatePageDetail();
      })
    }else{
        utils.routeListen('/admin/find_products')
        utils.routeJump(this,'/admin/find_products')
    }

  }

  onScroll = () => {
    let obj = document.getElementById('showTopButton');
    if (obj) {
      if (document.documentElement.scrollTop > 250 ) {
        obj.style.display = 'block';
      }
      if (document.documentElement.scrollTop < 250) {
        obj.style.display = 'none';
      }
    }
  }

  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 页面操作
  handleToSourcingList = async (num_iid) => {
      //Search Product页面Add to products list按钮点击gtag
      reqGoogleAnalytics('click', 'searchproduct_importproduct_click');
      this.setState({addLoading:true});

      if(this.state.addToProduct.indexOf(num_iid) !== -1){
        this.toggleToast('Repeat import');
      }
      let params = {
        'num_iid' : num_iid
      }
      const response = await reqFileMapSearchImport(params);
      if(response.code === '200'){
        this.setState({
          addToProduct:this.state.addToProduct.concat(num_iid),
          addLoading:false
        });
        this.toggleToast('Success',false);
      }else{
        this.toggleToast(response.msg);
      }
  }


  // 更新列表信息
  updatePageDetail(){
    let data = this.state.ImageList;
    let listHtmlContent = <div></div>;

    if(data.length > 0){
      listHtmlContent = data.map((item,key) => {
        return (
          <div className="Product_Card_Tkabk find_product" key={key}>
            <div className={"buttonHover "} onClick={()=>{
              console.log(this.state.addToProduct)
              this.openDetailModal(item.num_iid,item.rating)
            }}>
              <Card>
                <div className="Product_Image_Wrapper Product_Cover_Tkabk" style={{width:'246px',height:'246px'}}>
                  <div style={{cursor:'pointer',width:'100%',height:'100%'}}>
                    <LazyLoad once>
                      <img src={item.pic_url} alt="" onClick={()=>{
                        //Search Product页面产品封面点击gtag
                        reqGoogleAnalytics('click', 'searchproduct_productpicture_click');
                      }}/>
                    </LazyLoad>
                  </div>

                </div>
                  <BlockStack vertical={true} spacing="extraTight">
                    <div className="Search_Title_tkabk" title={item.title}>
                      {item.title}
                    </div>
                    <BlockStack distribution="equalSpacing">
                      <span className="Product_Rating_tkabk">
                        <svg viewBox="0 0 20 20">
                          <path d="M5.2 18a.8.8 0 0 1-.792-.914l.743-5.203-2.917-2.917a.8.8 0 0 1 .434-1.355l4.398-.733 2.218-4.435a.8.8 0 0 1 1.435.008l2.123 4.361 4.498.801a.8.8 0 0 1 .425 1.353l-2.917 2.917.744 5.203a.8.8 0 0 1-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 0 1 5.2 18z"></path>
                        </svg>
                        {item.rating}{'('+item.sales+')'}
                      </span>
                      <span className="Product_Orders_tkabk">
                      <svg viewBox="0 0 20 20">
                        <path d="M11 1a1 1 0 1 0-2 0v7.586L7.707 7.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414L11 8.586V1z"></path>
                        <path d="M3 14V3h4V1H2.5A1.5 1.5 0 0 0 1 2.5v15A1.5 1.5 0 0 0 2.5 19h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 17.5 1H13v2h4v11h-3.5c-.775 0-1.388.662-1.926 1.244l-.11.12A1.994 1.994 0 0 1 10 16a1.994 1.994 0 0 1-1.463-.637l-.111-.12C7.888 14.664 7.275 14 6.5 14H3z"></path>
                      </svg>
                        {item.sales}
                    </span>
                    </BlockStack>
                    <div className='Product_Price_Value'>
                      <div className='Product_Title_Money'>US${item.price}</div>
                    </div>
                  </BlockStack>

                  <BlockStack vertical={true} spacing="extraTight">
                    <div className="Place_Order_Button" >
                      <Button
                          id={"btn1"}
                          fullWidth
                          variant='primary'
                      >
                        Product details
                      </Button>
                    </div>
                  </BlockStack>
                   

              </Card>
            </div>
          </div>
        );
      });
    }

    this.setState({
      ListDivHtml: listHtmlContent,
      ShowCountNumber: data.length,
    });
  }

  callDetailModalClosed = () =>{
    this.setState({
      DetailModal: false,
    });
  }

  handleThumbSwiper = ()=>{
    this.setState({skuImage:''});
  }


  openDetailModal = async (num_iid,rating) =>{
    this.setState({
      loadingPage: true,
      load:true,
    });


    if(this.state.load === true){
      return;
    }

    const params = {
      num_iid:num_iid,
      rating:rating
    };
    // const response = await reqGetFindProductsDetail(params);
    const response = await reqFileMapSearchDetail(params);
    if(response.code==='200'){
      let max_price = response.data.max_price;
      let min_price = response.data.min_price;
      let price = response.data.max_price;
      if(parseFloat(max_price) > parseFloat(min_price)){
          price =  min_price + ' - ' + max_price
      }

      this.setState({
        loadingPage: false,
        load:false,
        pageData: response.data,
        options: response.data.options,
        reviewCount: response.data.review,
        skuImage:response.data.image_list[0],
        default:response.data.image_url,
        skuData:response.data.options,
        indexPrice:price,
        defaultPrice:min_price,
        variantData:response.data.variantTitle,
        DetailModal: true,
      });

    }else{
      this.setState({
        load:false,
        loadingPage: false,
        pageData: '',
      });
      this.toggleToast(response.msg);

    }
  }

  mouseEnter=()=>{
    this.setState({
      magnifierOff:true,
    })
    let big = document.querySelector(".picture_big");
    let mask = document.querySelector('.detail_mask');
    mask.style.display = 'block';
    big.style.display = 'block';
  };
// mouse remove
  mouseLeave =()=>{
    this.setState({
      magnifierOff:false,
    })
    let  big = document.querySelector(".picture_big");
    let mask = document.querySelector('.detail_mask');
    mask.style.display = 'none';
    big.style.display = 'none';
  }
// mouse move
  mouseMove = event =>{
    let e = event.nativeEvent;
    // console.log('e x y',e.nativeEvent);
    this.calculationBlock(e.offsetX,e.offsetY);
  }

  calculationBlock(offsetx,offsety){
    let pre_img = document.querySelector('.product_info_swiper');
    let  bigImg = document.querySelector(".picture_big img");
    let mask = document.querySelector('.detail_mask');
    let offsetX =  offsetx - 50;  // 鼠标在盒子中的位置 - mask宽的一半
    let offsetY = offsety - 50; // 鼠标在盒子中的位置 - mask高的一半
    /*block position*/
    // 防止鼠标移动过快导致计算失误，只要小于或大于对应值，直接设置偏移量等于最小或最大值
    // left 取值为 大于 0, 小于 盒子的宽 - mask的宽
    var maskMax = pre_img.offsetWidth - mask.offsetWidth;
    if(offsetX <= 0){
      offsetX = 0;
    }
    if(offsetX >= maskMax){
      offsetX = maskMax;
    }
    if(offsetY <= 0){
      offsetY = 0;
    }
    if(offsetY >= maskMax){
      // offsetY = small.offsetHeight - mask.offsetHeight;
      offsetY = maskMax;
    }
    // 移动mask
    mask.style.left = offsetX + 'px';
    mask.style.top = offsetY + 'px';
    /*计算图片放大位置*/
    // 右侧大图片，等比例移动
    // 大图片走的距离 / mask 走的距离 = 大图片 / 小图片
    var bigX = offsetX  * 900 / 300;//大图/小图
    var bigY= offsetY * 900 / 300;
    //大图的移动(方向相反，取负值)
    bigImg.style.left = -bigX + 'px';
    bigImg.style.top = -bigY + 'px';
  }
  handleSelect = (val)=>{
    let data = this.state.skuData;
    let variant = this.state.variantData;
    let isDisable = true;
    // 匹配购物车
    let setAddItem = this.state.addCartItem;
    let variantIndex = '';
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].value.length >0 ) {
          if (data[i].selected.includes(val)) {
            data[i].selected = '';
            this.setState({
              skuData:data,
              indexPrice:this.state.defaultPrice,
              disableAddToCart:true,
              skuImage:this.state.defaultImage
            })
            // https://cc-west-usa.oss-accelerate.aliyuncs.com/214ffd62-070f-4218-8203-83da7b710b02.jpg?x-oss-process=image/resize,m_pad,w_400,h_400
            return;
          }
          for (let j = 0; j < data[i].value.length; j++) {
            if (data[i].value.includes(val)) {
              if (!data[i].selected.includes(val)) {
                data[i].selected = val;
              }
            }
          }
        }
        // variant[data[i].selected]
        variantIndex += data[i]?.selected + '-';
      }
    }

    if (variant[variantIndex.slice(0,variantIndex.length-1)]) {
      isDisable = false;
      setAddItem.id = this.state.pageData.id;
      setAddItem.vid = variant[variantIndex.slice(0,variantIndex.length-1)].id;
      this.setState({
        skuImage:variant[variantIndex.slice(0,variantIndex.length-1)]?.image_url !== '' ? variant[variantIndex.slice(0,variantIndex.length-1)]?.image_url  : this.state.defaultImage ,
        indexPrice:variant[variantIndex.slice(0,variantIndex.length-1)]?.price,
        disableAddToCart:isDisable,
      });
    }
    this.setState({
      skuData:data,
      setAddItem:setAddItem
    })
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    const {
      ShowCountNumber, ListDivHtml, OssUrl, pageData, reviewCount, selectTab,skuImage,thumbsSwiper ,skuData,indexPrice
    } = this.state;

    // 控制列表两个产品之间间距显示
    let smallSpacingClass = (ShowCountNumber <= 4) ? 'small_spacing' : '';

    // 空数据显示
    const EmptyProductList = (ShowCountNumber === 0) ? (
        <div className="Search_emptyWithImg" >
          <EmptyState
              image="/empty.svg"
          >
          </EmptyState>
          <div style={{textAlign:"center"}}>
             <p style={{fontSize:'20px',opacity:'1'}}>{intl.get("Sorry, we were unable to search for an item matching the image!")}</p>
             <p style={{fontSize:'20px',opacity:'1',marginBottom:'0'}}>You can <span className="Polaris-Link" onClick={()=>{
              utils.routeListen('/admin/sourcing')
              utils.routeJump(this,'/admin/sourcing')
             }}
                  style={{textDecoration:"none"}}> send a sourcing request.</span></p>
          </div>
        </div>
    ) : '';

    const pageContent = (
        <Page fullWidth>
          <div className='space'></div>
          <Layout>
            <Layout.Section>
              <Card>
                <div className='recommended_category_Tab'>
                  <div className='left'>
                    <div className='icon'>
                      <Button onClick={()=> {
                        utils.routeListen('/admin/find_products')
                        utils.routeJump(this,'/admin/find_products')
                      }} icon={ChevronLeftIcon} variant='plain'></Button>
                    </div>
                    <div className='map_search_title'>
                      <Thumbnail
                          source={OssUrl}
                          size="large"
                          alt=""
                      />
                      {ShowCountNumber > 0 && <span className='map_search_title_text'>{ShowCountNumber + ' search results'}</span>}
                    </div>
                  </div>
                </div>
              </Card>

              {/* 空数据显示 */}
              <div style={{position:'relative'}}>
                {EmptyProductList}
              </div>

              {/* Start: 列表 */}
              <div style={{position:'relative', paddingTop:'2rem'}}>
                <section className="Product_Resource_Wrapper">
                  <div className="Border_Mask Left"></div>
                  <div className="Border_Mask Right"></div>
                  <div className="Border_Mask Bottom"></div>

                  <div className={"Product_Resource_Container "  + smallSpacingClass}>
                    {/* 列表 div 对象 */}
                    {ListDivHtml}
                  </div>
                </section>

                {/*<div className="Product__Loading" style={{display: (isSearching === '1' && ShowCountNumber > 0)? 'block' : 'none'}}>*/}
                {/*  <div className="Product__Loading_Image">*/}
                {/*    <img style={{width:'32px',height:'32px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
              {/* End: 列表 */}


            </Layout.Section>
          </Layout>
        </Page>
    )

    const initSkeletonPage = (
        <div>
          <SkeletonPage breadcrumbs={true}>
            <Layout>
              <Layout.Section>
                <Card>
                     
                     
                      <SkeletonBodyText lines={5} />
                     
                     
                </Card>
              </Layout.Section>
            </Layout>
            <div className="space"></div>
            <Layout>
              <Layout.Section>
                <Card>
                     
                     
                      <SkeletonDisplayText size="small" />
                      <SkeletonBodyText />
                     
                     
                </Card>
              </Layout.Section>
              <Layout.Section>
                <Card>
                     
                     
                      <SkeletonDisplayText size="small" />
                      <SkeletonBodyText />
                     
                     
                </Card>
              </Layout.Section>
            </Layout>
            <div className="space"></div>
          </SkeletonPage>
        </div>
    )

    let skuOptionSelect = '' ;
    if (skuData.length > 0) {
      // 如果是颜色
      skuOptionSelect = <div className='product_sku_attr_box'>
        <div className='product_sku_attr_list'>
          {skuData.map((item,ind)=><div className='product_sku_attr_item' key={ind}>
            <span className='product_sku_attr_title'>{item.name}:</span>
            {item.value.length >0 &&<div className='product_sku_attr_item_list'>
              {item.value.map((it,k)=><span className={item.selected === it ? 'item_value item_value_select' : 'item_value'}  onClick={()=>this.handleSelect(it)} key={k}>{it}</span>)}
            </div>}
          </div>)}
        </div>
      </div>
    }

    // DSM 描述+评论显示
    let showTabs = [
      {
        id: "all-description",
        content: "Description",
        accessibilityLabel: "All description",
        panelID: "all-description-content",
      }
    ];

    const DetailContent = this.state.loadingPage ? initSkeletonPage : (
        <section>

            <Layout>
              <Layout.Section>
                <div style={{"paddingTop":"1rem"}}></div>
                {(this.state.pageData === '') ? (
                    <Card>
                      {EmptyProductList}
                    </Card>
                ) : (
                    <section>
                      <Card>
                           
                          <section className="Product_Detail_Content">

                            <div className="Product_Detail_Img_Map detail_image">
                              <div className="product_info_swiper"
                                   onMouseEnter={this.mouseEnter}
                                   onMouseLeave={this.mouseLeave}
                                   onMouseMove={this.mouseMove}>
                                <Swiper
                                    style={{
                                      "--swiper-navigation-color": "#FFF",
                                      "--swiper-pagination-color": "#FFF",
                                    }}
                                    spaceBetween={20}
                                    navigation={false}
                                    onNavigationNext={()=>this.setState({skuImage:""})}
                                    onNavigationPrev={()=>this.setState({skuImage:""})}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper2 swiper-no-swiping"
                                >
                                  <img  src={skuImage !=='' ? skuImage : pageData.image_list && pageData.image_list[0]} alt="dropshipman"/>
                                  <div className="detail_mask"></div>
                                </Swiper>
                              </div>
                              <div className="picture_big">
                                <img src={skuImage !=='' ? skuImage : pageData.image_list && pageData.image_list[0]} alt="dropshipman" />
                              </div>
                              <Swiper
                                  onSwiper={()=>this.setState({thumbsSwiper:null})}
                                  onClick={()=>this.handleThumbSwiper()}
                                  spaceBetween={20}
                                  freeMode={true}
                                  observer={true}
                                  observeParents={true}
                                  slidesPerView={'auto'}
                                  watchSlidesProgress={true}
                                  modules={[FreeMode, Navigation, Thumbs]}
                                  className="mySwiper"
                              >
                                {pageData.image_list && pageData.image_list.slice(0, 20).map((image_url,ind)=><SwiperSlide onClick={()=>this.setState({skuImage: image_url})} className={ image_url === skuImage ? "product_swiper_thumbs_hover" : "product_swiper_thumbs" } key={ind}>
                                  <img  src={image_url} alt="dropshipman"/>
                                </SwiperSlide>)}
                              </Swiper>
                            </div>

                            <div className="Product_Detail_Box detail">
                              <FormLayout>
                                {pageData.product_link ? (
                                    <Link url={pageData.product_link} external removeUnderline>
                                      <Text variant="headingMd" as="h2">{pageData.title}</Text>
                                    </Link>
                                ) : (
                                    <Text variant="headingMd" as="h2">{pageData.title}</Text>
                                )}
                                <div>
                                  <BlockStack>
                                    <div>
                                      <StartRating
                                          value={pageData.rating}
                                          color1='gray'
                                          color2='#ffd700'
                                          half={true}
                                          count={5}
                                          edit={false}
                                          size={25}
                                      />
                                    </div>
                                    <div className="Product__Rating">
                                      {pageData.rating}
                                    </div>
                                    {/* <div className="Product__Reviews">
                                  {pageData.evaluation_count}
                                </div> */}
                                    <div></div>
                                    <div className="Product_Order_Count">
                                      <div style={{padding:'3px'}}>
                                        <svg viewBox="0 0 20 20">
                                          <path d="M11 1a1 1 0 1 0-2 0v7.586L7.707 7.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414L11 8.586V1z"></path>
                                          <path d="M3 14V3h4V1H2.5A1.5 1.5 0 0 0 1 2.5v15A1.5 1.5 0 0 0 2.5 19h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 17.5 1H13v2h4v11h-3.5c-.775 0-1.388.662-1.926 1.244l-.11.12A1.994 1.994 0 0 1 10 16a1.994 1.994 0 0 1-1.463-.637l-.111-.12C7.888 14.664 7.275 14 6.5 14H3z"></path>
                                        </svg>
                                      </div>
                                      <div style={{padding:'2px 2px 2px 5px'}}>
                                        {pageData.order_count}
                                      </div>
                                    </div>
                                  </BlockStack>
                                </div>

                                {/* 价格显示 */}
                                <div className="Product__Attributes price">
                                  {/*{indexPrice.includes('$') ? indexPrice : 'US $' + indexPrice }*/}
                                  {'US $' + indexPrice }
                                </div>

                                {/* 属性列表 */}
                                {/* {optionsList} */}

                                {/* 属性列表选择 */}
                                {/* {optionsListSelect} */}

                                {/* sku属性 */}
                                {skuOptionSelect}

                                {/* <div className="Product__Attributes">
                              <div className="Product__Attributes_Name">
                               Stock
                              </div>
                              <div className="Product__Attributes_Content">
                                {pageData.stock + ' in stock'}
                              </div>
                            </div> */}

                                {/* Ship from China */}
                                <div className="Product__Attributes">
                                  <div className="Product__Attributes_Name">
                                    Ship from
                                  </div>

                                  <div className="Product__Attributes_Content Label">
                                <span className='location'>
                                  <img src='/location.png' alt='' />
                                </span>
                                    <span className='title'>China</span>
                                  </div>
                                </div>

                                <div className="Product__Attributes">
                                  <div className="Product__Attributes_Name shipping">
                                    Ship to
                                  </div>
                                  <div className="Product__Attributes_Content">
                                    <ShippingMethodsModal id={pageData.id} onebound={'onebound'} countryCode={pageData.country_code} show={false} />
                                    {/* <div>$9.15 to Brazil via AliExpress Standard Shipping</div>
                                <div>Estimated delivery time 31-31 days</div> */}
                                  </div>
                                </div>

                                <div style={{float:"right"}}>
                                  <ButtonGroup>
                                    { this.state.addToProduct.indexOf(pageData.id) !== -1 ? (
                                        <Button
                                            id={"btn2"}
                                            fullWidth
                                          
                                            disabled={true}
                                        >
                                          Imported
                                        </Button>
                                    ) : (
                                        <Button
                                        id={"btn1"}
                                        onClick={()=>this.handleToSourcingList(pageData.id)}
                                        fullWidth
                                        variant='primary'
                                        loading={this.state.addLoading}
                                        >
                                        Add to products list
                                        </Button>
                                    ) }
                                  </ButtonGroup>
                                </div>
                              </FormLayout>
                            </div>
                          </section>

                           
                      </Card>

                      <Card>
                        <Tabs
                            tabs={showTabs}
                            selected={selectTab}
                            onSelect={(index)=> this.setState({ selectTab: index })}
                        >
                             
                            { (selectTab === 0) ? (
                                <div dangerouslySetInnerHTML={{__html:pageData.description}}>

                                </div>
                            ) : (
                                <ReviewsList show={(reviewCount > 0) ? true : false} pid={pageData.id} />
                            ) }
                             
                        </Tabs>
                      </Card>
                    </section>
                )}

              </Layout.Section>
            </Layout>

        </section>
    )


    const DetailModal =
        (
            <Modal
                size='large'
                title={'Product Detail'}
                open={this.state.DetailModal}
                onClose={this.callDetailModalClosed}
                footer={false}
            >
              {DetailContent}

            </Modal>
        )

    return (
        <div className='_my_product'>
          {toastMarkup}
          {pageContent}
          {this.state.load && <div className={'loading_mask'}></div>}
          {this.state.load && <div className='spinner'><Spinner accessibilityLabel="Spinner example" size="large" /></div> }

          {DetailModal}
        </div>
    );
  }
}

export default withRouter(SearchImage);