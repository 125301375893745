import { Modal } from '@shopify/polaris';
import React from 'react';
const BlackRaffleRuleModal = ({open,handleBlackRuleModalOff})=> {
    return (
        <div>
        <Modal
            size='middle'
            open={open}
            onClose={() => handleBlackRuleModalOff()}>
                <div className='raffle_rules_modal' style={{minHeight:"250px",cursor:'pointer'}}>
                    <div className='raffle_rules_top'>
                        <img src="/blackFriday/raffle_rules.png" alt="Dropshiman" />
                        <span>Raffle Rules</span>
                    </div>
                    <div className='raffle_rules_content'>
                        <p>1. Each raffle entry costs <strong>100 points</strong>, which are non-refundable once used.</p>
                        <p>2. Coupons won in the raffle can be applied toward order payments and are valid for <strong>2 months</strong>.</p>
                        <p>3. Free packages won in the raffle must be activated to take effect. If not activated, they will be considered forfeited. Once activated, they are valid for <strong>1 month</strong>. If canceled mid-term, the free benefits will be void.</p>
                        <p>4. Physical items won in the raffle are free, but the winner is responsible for shipping fees and taxes.</p>
                        <p>5. Dropshipman reserves the right to the final interpretation of this event.</p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default BlackRaffleRuleModal;
