import React, { Component } from "react";

import {
  Button,
  Toast,
  Spinner,
  Modal,
  FormLayout,
  TextField,
  Select
} from "@shopify/polaris";

import ShopifyCountriesConfig from "../../../../../assets/ShopifyCountries";

import { reqPostGetOrderAddress, reqPostUpdateOrderAddress, reqGetShopifyProvinceData } from '../../../../../api';

import "./index.css";

import intl from "../../../../../i18n/intl";

class ShippingAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: false,
      activeModal: false,
      saveDataLoading: false,
      disableSave: true,
      successTipsActive: false,
      loadingProvinceData: false,

      provinceLabelText: "Province",
      provinceData: [],

      firstName: "",
      lastName: "",
      lastNameError: "",
      company: "",
      address1: "",
      address1Error: "",
      address2: "",
      country: "",
      countryError: "",
      countryCode: "",
      province: "",
      provinceCode: "",
      city: "",
      cityError: "",
      zipCode: "",
      zipCodeError: "",
      phone: "",
      phoneError: '',
      email: '',
      emailError: '',
      option1: '',
      option1Error: ''
    };
  }

  componentDidMount() {}

  // 组件渲染完成，再次修改渲染内容
  UNSAFE_componentWillReceiveProps(nextProps) {}

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    }));
  };

  // 激活地址弹窗
  handleActiveModal = (isActive) => {
    this.setState({
      activeModal: isActive
    }, () => {
      if (isActive) {
        this.getOrderShippingAddress();
      }
    });
  };

  // 获取DSM订单地址详情
  getOrderShippingAddress = async () => {
    const { id, sid, type } = this.props;
    const params = { id, sid, type };

    this.setState({
      loadingData: true,
      disableSave: true
    });
    const response = await reqPostGetOrderAddress(params);

    if (response.code === "200") {
      let getData = response.data;
      this.setState({
        loadingData: false,
        firstName: getData.first_name,
        lastName: getData.last_name,
        company: getData.company,
        address1: getData.address1,
        address2: getData.address2,
        country: getData.country,
        countryCode: getData.country_code,
        province: getData.province,
        provinceCode: getData.province_code,
        city: getData.city,
        zipCode: getData.zip,
        phone: getData.phone,
        email: getData.email,
        option1: getData.option1,

        lastNameError: getData.last_name_error,
        address1Error: getData.address1_error,
        countryError: getData.country_error,
        cityError: getData.city_error,
        phoneError: getData.phone_error,
        zipCodeError: getData.zip_error,
        // emailError: getData.email_error,
        option1Error: getData.option1_error
      }, () => {
        // 初始化省份数据
        this.getShopifyProvinceData(getData.country_code);
      });
    } else {
      this.setState({ loadingData: false });
      this.toggleToast(response.msg);
    }
  }

  saveData = async () => {
    const {
      firstName, lastName, address1, address2, phone, city, province, provinceCode, country, countryCode, company, zipCode, email, option1
    } = this.state;

    let isAllow = true;
    
    let shippingAddress = {
      first_name: firstName,
      last_name: lastName,
      address1: address1,
      address2: address2,
      phone: phone,
      city: city,
      province: province,
      province_code: provinceCode,
      country: country,
      country_code: countryCode,
      company: company,
      zip: zipCode,
      email: email,
      option1: option1,
    };

    // 检验地址
    isAllow = this.verifyAddress(shippingAddress);

    if (!isAllow) {
      return false;
    }

    const params = {
      id: this.props.id,
      sid: this.props.sid,
      type: this.props.type,
      address: shippingAddress
    }

    this.setState({ saveDataLoading: true });
    const response = await reqPostUpdateOrderAddress(params);

    if (response.code === '200') {
      /**
       * 如果需要刷新物流方式，弹窗提醒，否则直接关闭弹窗
       */
      if (response.data === 1) {
        this.handleActiveModal(false);

        this.handleTipsReloadOrderData(true);

      } else {
        this.toggleToast(response.msg, false);
        this.setState({ saveDataLoading: false });

        setTimeout(() => {
          this.handleActiveModal(false);
          this.props.reloadData();
        }, 500);
      }

    } else if (response.code === '505') {  // 反馈地址检测错误
      let getData = response.data;
      this.setState({
        saveDataLoading: false,
        lastNameError: getData?.last_name,
        address1Error: getData?.address1,
        countryError: getData?.country,
        cityError: getData?.city,
        phoneError: getData?.phone,
        zipCodeError: getData?.zip,
        emailError: getData?.email,
        option1Error: getData?.option1
      });
      this.toggleToast(response.msg);

    } else {
      this.setState({ saveDataLoading: false });
      this.toggleToast(response.msg);
    }
  }

  /**
   * 校验收货地址信息
   * @param {*} address 
   * @returns 
   */
  verifyAddress(address){
    if (address) {
      let errorObj = {};
      if (!address.last_name) {
        errorObj.lastNameError = 'Last name is required';
      }
      if (!address.address1) {
        errorObj.address1Error = 'Address is required';
      }
      if (!address.country) {
        errorObj.countryError = 'Please select a country';
      }
      if (!address.zip) {
        errorObj.zipCodeError = 'Postal code is required';
      }
      if (!address.city) {
        errorObj.cityError = 'City is required';
      }
      // if (address.email) {
      //   let reg = /^[.a-zA-Z0-9_-]+@[.a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      //   if(!address.email.match(reg)){
      //     errorObj.emailError = 'Wrong email number format';
      //   }
      // }
      // if (!address.phone) {
      //   errorObj.phoneError = 'Phone number is required';
      // }
      // if (!address.email) {
      //   errorObj.emailError = 'Email is required';
      // }

      // 巴西税号
      if (address.country_code === 'BR' && !address.option1) {
        errorObj.option1Error = 'CPF required';
      }
      // 挪威税号
      if (address.country_code === 'NO' && !address.option1) {
        errorObj.option1Error = 'VOEC required';
      }

      let isTrue = true;

      if (Object.keys(errorObj).length !== 0) {
        isTrue = false;
        this.setState(errorObj);
      }

      return isTrue;

    } else {
      this.setState({
        lastNameError: 'Last name is required',
        address1Error: 'Address is required',
        countryError: 'Please select a country',
        zipCodeError: 'Postal code is required',
        cityError: 'City is required',
        // emailError: 'Email is required',
        // phoneError: 'Phone number is required'
      });

      return false;
    }
  }

  // 获取shopify省份数据
  getShopifyProvinceData = async (code = "") => {
    const params = {
      c: code,
    };

    this.setState({ loadingProvinceData: true });
    const response = await reqGetShopifyProvinceData(params);
    if (response.code === "200") {
      let getData = response.data;

      // 修改地址时，初始化省份
      let init_province = this.state.province ? this.state.province : "";
      let init_provinceCode = this.state.provinceCode
        ? this.state.provinceCode
        : "";

      if (getData.length > 0) {
        if (init_province === "") {
          init_province = getData[0].label;
        }

        if (init_provinceCode === "") {
          init_provinceCode = getData[0].value;
        }
      } else {
        init_province = "";
        init_provinceCode = "";
      }

      this.setState({
        loadingProvinceData: false,
        provinceData: getData,
        province: init_province,
        provinceCode: init_provinceCode,
      });
    } else {
      this.toggleToast(response.msg);
    }
  }

  handleAddressInputChange = (value, field) => {
    if (field === "countryCode") {
      let countryName = '';
      let provinceKeyText = '';
      for (let i = 0; i < ShopifyCountriesConfig.length; i++) {
        if (ShopifyCountriesConfig[i].value === value) {
          countryName = ShopifyCountriesConfig[i].label;
          provinceKeyText = ShopifyCountriesConfig[i].province_label;
          break;
        }
      }
      if (value !== this.state.countryCode) {
        // 当前国家与之前不一样，获取省份数据
        this.getShopifyProvinceData(value);
      }

      this.setState({
        [field]: value,
        country: countryName,
        provinceLabelText: provinceKeyText,
        province: '',
        provinceCode: '',
        countryError: '',
        city: '',
        cityError: '',
        disableSave: false
      });

    } else if (field === "provinceCode") {
      let array = this.state.provinceData;
      let provinceText = '';
      for (let j = 0; j < array.length; j++) {
        if (array[j].value === value) {
          provinceText = array[j].label;
          break;
        }
      }
      this.setState({
        [field]: value,
        province: provinceText,
        disableSave: false
      })

    } else if (field === 'lastName') {
      this.setState({
        [field]: value,
        lastNameError: '',
        disableSave: false
      })

    } else if (field === 'address1') {
      this.setState({
        [field]: value,
        address1Error: '',
        disableSave: false
      })

    } else if (field === 'city') {
      this.setState({
        [field]: value,
        cityError: '',
        disableSave: false
      })

    } else if (field === 'zipCode') {
      this.setState({
        [field]: value,
        zipCodeError: '',
        disableSave: false
      })

    } else if (field === 'email') {
      this.setState({
        [field]: value,
        emailError: '',
        disableSave: false
      })

    } else if (field === 'phone') {
      this.setState({
        [field]: value,
        phoneError: '',
        disableSave: false
      })

    } else if (field === 'option1') {
      let err_msg = '';
      // 挪威的税号检测
      if (this.state.countryCode === 'NO') {
        if (value !== '') {
          // 只支持输入以2或3开头的7位纯数字
          let regex = /^([23]\d{6})$/;
          if (!regex.test(value)) {
            err_msg = 'Only supports input of 7-digit plain numbers starting with 2 or 3';
          }
        } else {
          err_msg = 'VOEC is required for orders destined to Norway';
        }
      }
      this.setState({
        [field]: value,
        option1Error: err_msg,
        disableSave: false
      })

    } else {
      this.setState({
        [field]: value,
        disableSave: false
      })
    }
  }

  /**
   * 提醒刷新列表弹窗
   * 
   * @param {*} bool 
   */
  handleTipsReloadOrderData = (bool) => {
    this.setState({
      successTipsActive: bool,
      saveDataLoading: false
    }, () => {
      if (!bool) {
        this.props.reloadData();
      }
    })
  }

  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={() => this.toggleToast("")}
        error={this.state.showToastIserror}
      />
    ) : null;

    const {
      loadingData,
      saveDataLoading,
      disableSave,
      loadingProvinceData,
      activeModal,
      firstName,
      lastName,
      company,
      address1,
      address2,
      countryCode,
      provinceCode,
      city,
      zipCode,
      phone,
      email,
      option1,
      provinceLabelText,
      provinceData,
      lastNameError,
      address1Error,
      countryError,
      zipCodeError,
      cityError,
      emailError,
      successTipsActive,
      phoneError,
      option1Error
    } = this.state;

    const SuccessTipsModal = (
      <Modal
        open={successTipsActive}
        onClose={() => this.handleTipsReloadOrderData(false)}
        title={intl.get('Update success')}
        primaryAction={
          {
            content: intl.get('Get it'),
            onAction: () => this.handleTipsReloadOrderData(false),
          }
        }
      >
        <Modal.Section>
          <div>The order shipping method will be refreshed, please refresh order list later.</div>
        </Modal.Section>
      </Modal>
    )

    // 订单当前状态，是否可以编辑
    const canBeEdit = this.props.paymentStatus === 'Unpaid' || this.props.paymentStatus === 'Partially paid';
    // 订单是否异常
    const addressError = this.props.addressStatus ? true : false;
    
    // 是否需要检测税费
    let isNeedCheckTaxCode = false;

    // 税号文本
    let taxCodeText = 'Tax code';
    switch (countryCode) {
      case 'BR':
        // 巴西税号特称
        taxCodeText = 'CPF';
        isNeedCheckTaxCode = true;
        break;
      case 'NO':
        // 挪威税号特称
        taxCodeText = 'VOEC';
        isNeedCheckTaxCode = true;
        break;
      case 'CL':
        isNeedCheckTaxCode = true;
        break;
      default:
    }

    const ShippingAddressModal = (
      <Modal
        open={activeModal}
        onClose={() => this.handleActiveModal(false)}
        title={intl.get('Customer Information')}
        loading={loadingData}
        primaryAction={
          canBeEdit && (
            {
              content: intl.get('Save'),
              onAction: () => this.saveData(),
              loading: saveDataLoading,
              disabled: disableSave
            }
          )
        }
        secondaryActions={[
          {
            content: intl.get('Close'),
            onAction: () => this.handleActiveModal(false),
          }
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <FormLayout.Group>
              <TextField id="firstName" label={intl.get("First name")} onChange={this.handleAddressInputChange} value={firstName} maxLength={50} readOnly={!canBeEdit} />
              <TextField id="lastName" label={intl.get("Last name")} onChange={this.handleAddressInputChange} value={lastName} error={lastNameError} maxLength={50} readOnly={!canBeEdit} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="company" label={intl.get("Company")} onChange={this.handleAddressInputChange} value={company} maxLength={255} readOnly={!canBeEdit} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="address1" label={intl.get("Address")} onChange={this.handleAddressInputChange} value={address1} error={address1Error} maxLength={255} readOnly={!canBeEdit} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="address2" label={intl.get("Apartment, suite, etc. (optional)")} onChange={this.handleAddressInputChange} value={address2} maxLength={255} readOnly={!canBeEdit} />
            </FormLayout.Group>

            <FormLayout.Group>
              <Select
                id="countryCode"
                label={intl.get("Country/region")}
                options={ShopifyCountriesConfig}
                onChange={this.handleAddressInputChange}
                value={countryCode}
                error={countryError}
                disabled={!canBeEdit}
              />
              {(provinceData.length > 0 && !loadingProvinceData) && (
                <Select
                  id="provinceCode"
                  label={provinceLabelText}
                  options={provinceData}
                  onChange={this.handleAddressInputChange}
                  value={provinceCode}
                  disabled={!canBeEdit}
                />
              )}
              {
                loadingProvinceData && <div style={{ textAlign: 'center', marginTop: '30px'}}><Spinner size="small" /></div>
              }
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="city" label={intl.get("City")} onChange={this.handleAddressInputChange} value={city} error={cityError} maxLength={50} readOnly={!canBeEdit} />
              <TextField id="zipCode" label={intl.get("ZIP/Postal code")} onChange={this.handleAddressInputChange} value={zipCode} error={zipCodeError} maxLength={30} readOnly={!canBeEdit} />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField id="phone" label={intl.get("Phone")} onChange={this.handleAddressInputChange} value={phone} error={phoneError} maxLength={30} readOnly={!canBeEdit} />
              <TextField id="email" type="email" label={intl.get("Email")} onChange={this.handleAddressInputChange} value={email} error={emailError} maxLength={50} readOnly={!canBeEdit} />
            </FormLayout.Group>

            {/* 巴西税费 */}
            {isNeedCheckTaxCode && (
              <FormLayout.Group>
                <TextField
                  id="option1"
                  label={taxCodeText}
                  onChange={this.handleAddressInputChange}
                  value={option1}
                  error={option1Error}
                  maxLength={30}
                  readOnly={!canBeEdit}
                />
              </FormLayout.Group>
            )}

          </FormLayout>
        </Modal.Section>
      </Modal>
    )

    return (
      <>
        <div className="order_customer_info">
          <Button onClick={() => this.handleActiveModal(true)}>
            {"Customer(" + this.props.countryCode + ")"}
          </Button>
          <div className="customer_info_error" style={{display: addressError ? 'block' : 'none'}}>
            <img src="/alert_error.svg" alt="dropshipman" />
          </div>
        </div>
        
        {toastMarkup}
        {ShippingAddressModal}
        {SuccessTipsModal}
      </>
    );
  }
}

export default ShippingAddress;
