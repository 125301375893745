import React, { memo } from "react";
import { Modal } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";

const ArchiveOrderModal = memo(
  ({ archivedData ,setArchivedData}) => {
    return (
      <div>
        <Modal
          open={archivedData.sourcingTip}
          onClose={() => setArchivedData((prevValue)=>({
            ...prevValue,
            sourcingTip:false,
            isBluk:true
          }))}
          title={intl.get("Sourcing Request Sent")}
          secondaryActions={[
            {
              content: intl.get("Got it"),
              onAction: () =>  setArchivedData((prevValue)=>({
                ...prevValue,
                sourcingTip:false,
                isBluk:true
              })),
            },
          ]}
        >
          <Modal.Section>
            {`The sourcing request for ${archivedData.isBluk ? "these orders" : "this order"}  has already been initiated, no need to send ${archivedData.isBluk ? "" : "it"} again.`}
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default ArchiveOrderModal;
