import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  ChoiceList,
  EmptyState,
  Filters,
  Icon,
  Layout,
  Page,
  Pagination,
  Select,
  SkeletonBodyText,
  SkeletonPage,
  TextField,
  Toast,
} from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { SearchIcon } from "@shopify/polaris-icons";
import "./index.css";
import ListItemOne from "./Common/ListItemOne";
import ListItemTwo from "./Common/ListItemTwo";
import BulkPaymentModal from "../../../../components/BulkPaymentModal";
import utils from "../../../../utils/utils";
import intl from "../../../../i18n/intl";
import _ from "lodash";
import NoviceOrderModal from "./Modal/NoviceOrderModal";
import { useHistory, withRouter } from "react-router-dom";
import {
  reqGetRedeemProducts,
  reqGetRedeemOrderList,
  reqPostConnectOrderId,
  reqPostPaymentProcess,
  reqWalletTransaction,
  reqGetUserPoints,
} from "../../../../api";
import ChangeLoading from "../../../../components/ChangeLoading";
import PayoneerBalancesConnect from "../../../../components/PayoneerBalancesConnect";
import PayoneerBalancesOrderSelect from "../../../../components/PayoneerBalancesOrderSelect";
import PayoneerBalancesOrderConfirm from "../../../../components/PayoneerBalancesOrderConfirm";
import PayoneerBalancesAgain from "../../../../components/PayoneerBalancesAgain";
import PayForOrderModal from "../../../../components/PayForOrderModal";
import WalletForOrderModal from "../../../../components/WalletForOrderModal";
import DlocalPayTipModal from "../../../../components/DlocalPayTipModal";
// import AppBridge from "../../../../utils/appBridge";

function disambiguateLabel(key, value) {
  switch (key) {
    case "payment_status":
      return `${value}`;
    case "fulfillment_status":
      return `${value}`;
    default:
      return value.toString();
  }
}
function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}
const Index = () => {
  const router = useHistory();
 //切换tab
  const [pageLoading, setPageLoading] = useState(false); //加载页面
  const [toPage, setToPage] = useState(""); //页码
  const [isInit, setIsInit] = useState(false); //初始化页面
  const [noviceModal,setNoviceModal] = useState(false);//引导视频
  const [searchParam, setSearchParam] = useState({
    selected: 0,
    payment_status: [],
    fulfillment_status: [],
    search_type: "keyword",
    type: [""],
    query: "",
    start: "",
    end: "",
    page: 1,
    size: 20,
    sort_by: "",
    searchLoading: false,
  }); //请求参数

  const [paymentPopData, setPaymentPopData] = useState(
    {
      ids: [], //支付id
      paymentModal: false, //支付弹窗打开
      payoneerAuth: 0, //payonner是否授权 0未授权 1已授权
      walletMoney: 0, //钱包余额
      orderName: "",
      isWalletModal: false, //钱包弹窗
      isPaypalModal: false, //paypal弹窗
      createPayPalOrderId: 0,
      addressFailedModal: false, // 地址信息异常
      addressFailedLoading: false, //地址信息异常加载
      addressFailedOrder: "", //订单id 异常
      noFreeShippingOrder: "", //订单id 物流异常
      noFreeShippingTipsActive: false, // 物流异常提示
      paymentMoney: 0, // 支付总金额
      couponId: "", //优惠券id
      couponMoney: 0, //优惠券金额
      toCheckoutLoading: false, //钱包弹窗loading
      toWalletButtonLoading: false, //钱包弹窗loading
      user_cpf: "",
      is_br:0,
      handleWalletActiveLoading: false, //钱包确定支付按钮
      payLogId: 0, // 执行支付记录ID
      operationNumber: 0,
      currentExecutionsNumber: 0,
    } // 支付弹窗数据
  );
  const [dlocalTip, setDlocalTip] = useState(false);
  const [payoneerConnectOpen, setPayoneerConnectOpen] = useState(false); //payoneer 连接弹窗
  const [payoneerAgainOpen, setPayoneerAgainOpen] = useState(false); //payoneer 再次打开弹窗
  const [payoneerBalances, setPayoneerBalances] = useState({
    //payoneer余额
    data: {},
    open: false,
    ids: [],
    coupon_id: 0,
  });
  const [payoneerConfirm, setPayoneerConfirm] = useState({
    //支付数据
    data: {},
    open: false,
  });
  const [pageData, setPageData] = useState({}); //页面数据

  // 获取页面数据
  const getDataList = async (param) => {
    let params = {};
    let res = {};
    switch (param.selected) {
      case 0:
        params = {
          page: param.page,
          size: 50,

        };
        setSearchParam((prevValue)=>({...prevValue}))
        res = await reqGetRedeemProducts(params);
        break;
      case 1:
        params = {
          page: param.page,
          size: param.size,
          search_type: param.search_type,
          query: param.query,
          payment_status:
            param.payment_status.length !== 0 ? param.payment_status : "",
          fulfillment_status:
            param.fulfillment_status.length !== 0
              ? param.fulfillment_status
              : "",
        };
        setSearchParam((prevValue)=>({...prevValue,size:param.size}))
        res = await reqGetRedeemOrderList(params);
        let isList = res.data.list.map((item,ind)=>{
          return {...item,open:true}
        })
        res = {...res,data:{...res.data,list:isList}}
        break;
        

      default:
        break;
    }

    try {
      if (res.code === "200") {
        setPageData(res.data);
        setPaymentPopData((prevValue) => ({
          ...prevValue,
          payoneerAuth: res.data?.payoneer_auth,
          walletMoney: res.data?.wallet_money,
          user_cpf:res.data.user_cpf,
          is_br:res.data.is_br
        }));
      }
      setIsInit(false);
      setPageLoading(false);
      setSearchParam((prevValue) => ({
        ...prevValue,
        searchLoading: false,
      }));
    } catch (error) {}
  };
  const [userPoints,setUserPoints] = useState({})
  const getUserPoints = async ()=>{
    let res = await reqGetUserPoints()
    if(res?.code === '200') {
      setUserPoints(res?.data)
    }
  }
  const formatNumber = (value) => {
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }

    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  };

  useEffect(() => {
    let isMount = false;
    let url = new URLSearchParams(window.location.search);
    let selected = url.get("selected")
    let keyword = url.get("keyword")
    setSearchParam((prevValue) => ({
      ...prevValue,
      selected: selected ? Number(selected) : 0,
      query: keyword ? keyword : "",
      search_type:keyword ? 'dsm_order' : 'keyword'
    }));

    if (!isMount) {
      getUserPoints()
      setIsInit(true);
      // if(AppBridge && AppBridge.exist()) {
      //   AppBridge.setTitleBar('Points','/admin/points')
      // }
      if (selected) {
        getDataList({...searchParam,selected: Number(selected),query: keyword ? keyword : "",search_type:keyword ? 'dsm_order' : 'keyword'});
      }else {
        getDataList(searchParam);
      }
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line
  }, []);


  // 清除筛选
  const handleClearAll = () => {
    setPageLoading(true);
    let data = {
      ...searchParam,
      payment_status: [],
      fulfillment_status: [],
      type: [""],
    };
    setSearchParam(data);
    getDataList(data);
  };
  // 支付状态筛选 平台 dsm shopify
  const handleChangePaymentStatus = (value) => {
    setPageLoading(true);
    let data = { ...searchParam, payment_status: value };
    setSearchParam(data);
    getDataList(data);
  };
  // 完成状态筛选
  const handleChangeFulfillmentStatus = (value) => {
    setPageLoading(true);
    let data = { ...searchParam, fulfillment_status: value };
    setSearchParam(data);
    getDataList(data);
  };
  // tab切换
  const handleTabChange = (val) => {
    setPageLoading(true);
    let data = {
      ...searchParam,
      selected: val,
      payment_status: [],
      fulfillment_status: [],
      search_type: "keyword",
      type: [""],
      query: "",
      start: "",
      end: "",
      page: 1,
      size: 20,
      sort_by: "",
      searchLoading: false,
    };
    setPageData({});
    setSearchParam(data);
    getDataList(data);
  };
  // 搜索关键词
  const handleSearchValue = (value) => {
    if (value.length > 255) {
      return;
    }
    let data = { ...searchParam, query: value };
    setSearchParam(data);
  };
  // 搜索按钮点击
  const handleSearchBtn = () => {
    let data = { ...searchParam, searchLoading: true };
    setPageLoading(true);
    setSearchParam(data);
    handleDebouncedSave(data);
  };

  // 清除搜索
  const handleClearSearch = () => {
    setPageLoading(true);
    let data = { ...searchParam, query: "" };
    setSearchParam(data);
    getDataList(data);
  };

  const handleDebouncedSave = _.debounce((data) => {
    getDataList(data);
  }, 500);

  // 批量操作
  /**
   * 全选按钮状态
   * true：全选 / indeterminate: 部分选中 / false：未选择
   * @returns {Object} { checked: 选中状态, num: 选中数量 ids:选中数据}
   */
  const checkStatus = useMemo(() => {
    const checkStatusList = _.filter(
      pageData?.list,
      (item) => item.checked === 1
    );

    if (_.isEmpty(checkStatusList)) {
      return {
        checked: 0,
      };
    } else if (
      checkStatusList.length > 0 &&
      checkStatusList.length === pageData?.list.length
    ) {
      return {
        checked: 1,
      };
    }
    return {
      checked: "indeterminate",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.list]);

  // 全选列表
  const handleOrderSelect = (val) => {
    let list = pageData.list.map((item, ind) => {
      if (val && item.dsm_payment_status === 'Partially paid') {
        item.checked = 1;
      } else {
        item.checked = 0;
      }
      return item;
    });
    setPageData((prevValue) => ({
      ...prevValue,
      list: list,
    }));
  };
  // 批量支付
  const handlePaymentButton = (ids) => {
    // 未付款订单
    let isNoPayment = [];
    let list = pageData.list;
    list.map((item) => {
      if (item.dsm_payment_status === "Unpaid" || item.dsm_payment_status === "Partially paid") {
        if (ids.includes(item.dsm_record)) {
          isNoPayment.push(item.dsm_record);
        }
      }
      return item;
    });
    if (isNoPayment.length === 0) {
      toggleToast("No payable orders");
      return;
    }

    setPaymentPopData((prevValue) => ({
      ...prevValue,
      ids: isNoPayment,
      paymentModal: true,
    }));
  };
  // 关闭支付选择弹窗
  const handlePaymentButtonClose = () => {
    setPaymentPopData((prevValue) => ({
      ...prevValue,
      paymentModal: false,
    }));
  };

  // 支付选择确认
  const handlePayForOrderModal = (type, ids, name, price, coupon_id) => {
    switch (type) {
      // paypal支付
      case 1:
        setPaymentPopData((prevValue) => ({
          ...prevValue,
          ids: ids,
          orderName: name,
          paymentMoney: price,
          couponId: coupon_id,
          isPaypalModal: true,
          paymentModal: false,
          toCheckoutLoading: true,
        }));

        handleCheckoutOrder(ids, coupon_id);

        break;
      // 钱包支付
      // 如果钱包余额充足
      // 如果钱包余额不足则提示钱不够
      case 2:
        setPaymentPopData((prevValue) => ({
          ...prevValue,
          ids: ids,
          orderName: name,
          paymentMoney: price,
          couponId: coupon_id,
          isWalletModal: true,
          paymentModal: false,
          toCheckoutLoading: true,
        }));

        setTimeout(() => {
          setPaymentPopData((prevValue) => ({
            ...prevValue,
            ids: ids,
            orderName: name,
            paymentMoney: price,
            couponId: coupon_id,
            isWalletModal: true,
            paymentModal: false,
            toCheckoutLoading: false,
          }));
        }, 1000);

        break;
      default:
        break;
    }
  };

  // 钱包支付确认
  const handleWalletActive = async () => {
    let paymentData = paymentPopData;
    setPaymentPopData((prevValue) => ({
      ...prevValue,
      toWalletButtonLoading: true,
    }));

    let params = {
      id: JSON.stringify(paymentData.ids),
      b: paymentData.ids.length > 1 ? 1 : 0,
      coupon_id: paymentData.couponId,
    };
    const response = await reqWalletTransaction(params);

    if (response.code === "200") {
      toggleToast("Payment processing");
      getDataList(searchParam);
      // 启动定时器查询支付状态
      if (response.data) {
        setPaymentPopData((prevValue) => ({
          ...prevValue,
          payLogId: response.data,
        }));
        var importTimer = setInterval(() => {
          GetPaymentProcess().then(function (response) {
            if (response === 200) {
              clearInterval(importTimer);
            }
          });
        }, 3000);
      }
    } else {
      toggleToast(response.msg);
    }
    // 创建PayPal订单有误，关闭弹窗
    setPaymentPopData((prevValue) => ({
      ...prevValue,
      toWalletButtonLoading: false,
      isWalletModal: false,
    }));
  };

  // 查询支付的状态
  const GetPaymentProcess = async () => {
    const params = { id: paymentPopData.payLogId };
    const response = await reqPostPaymentProcess(params);
    if (response.code === "200") {
      let getData = response.data;
      setPaymentPopData((prevValue) => ({
        ...prevValue,
        operationNumber: getData.job === 0 ? getData.total : 0,
        currentExecutionsNumber: getData.job === 0 ? getData.success : 0,
      }));

      if (getData.job === 1) {
        // 结束
        setTimeout(() => {
          // 刷新列表
          // this.getDropshipmanOrderList()
          getDataList(searchParam);
        }, 1000);

        return 200;
      }
      return 201;
    } else {
      setPaymentPopData((prevValue) => ({
        ...prevValue,
        payLogId: 0,
        operationNumber: 0,
        currentExecutionsNumber: 0,
      }));

      return 200;
    }
  };

  // 关闭钱包支付
  const handleWalletClose = () => {
    setPaymentPopData((prevValue) => ({
      ...prevValue,
      isWalletModal: false,
      toWalletButtonLoading: false,
      toCheckoutLoading: false,
    }));
  };
  // checkout order
  const handleCheckoutOrder = async (ids, coupon_id) => {
    let params = {
      id: JSON.stringify(ids),
      b: ids.length > 1 ? 1 : 0,
      coupon_id: coupon_id,
    };

    const response = await reqPostConnectOrderId(params);

    if (response.code === "200") {
      setPaymentPopData((prevValue) => ({
        ...prevValue,
        toCheckoutLoading: false,
        createPayPalOrderId: response.data,
      }));
    } else {
      toggleToast("Fail to activate");
      handlePaypalClose();
    }
  };

  // 关闭paypal支付
  const handlePaypalClose = () => {
    setPaymentPopData((prevValue) => ({
      ...prevValue,
      isPaypalModal: false,
      toPaypalButtonLoading: false,
      toCheckoutLoading: false,
    }));
  };

  // 未授权弹窗 payoneer
  const callPayoneerConnectClosed = () => {
    setPayoneerConnectOpen(false);
  };

  // 获取确认支付数据
  const handlePayoneerConfirm = (data) => {
    setPayoneerConfirm((prevValue) => ({
      ...prevValue,
      data: data,
      open: true,
    }));
  };

  //关闭确认支付弹窗
  const handlePayoneerConfirmClose = () => {
    getDataList(searchParam);
  };

  // 刷新数据
  const callPayoneerAgainClosed = () => {
    getDataList(searchParam);
    setPayoneerAgainOpen(false);
  };

  // 静态保存数据
  const handleSaveData = (data)=>{
    setPageData(data)
  }
  const handleProductKeyWordsChange = (value) => {
    setSearchParam({ ...searchParam, search_type: value });
  }; //订单搜索类型change

  const filters = [
    {
      key: "payment_status",
      label: "Payment status",
      filter: (
        <ChoiceList
          title={"Payment status"}
          titleHidden
          choices={[
            {
              label: "Partially paid(DSM)",
              value: "Partially paid(DSM)",
            },
            {
              label: "Paid(DSM)",
              value: "Paid(DSM)",
            },
            {
              label: "Refunded(DSM)",
              value: "Refunded(DSM)",
            },
            {
              label: "Partially Refunded(DSM)",
              value: "Partially Refunded(DSM)",
            },
          ]}
          selected={searchParam.payment_status || []}
          onChange={handleChangePaymentStatus}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "fulfillment_status",
      label: "Fulfillment status",
      filter: (
        <ChoiceList
          title={"Fulfillment status"}
          titleHidden
          choices={[
            {
              label: "Unfulfilled(DSM)",
              value: "Unfulfilled(DSM)",
            },
            {
              label: "In Processing(DSM)",
              value: "In processing(DSM)",
            },
            {
              label: "Fulfilled(DSM)",
              value: "Fulfilled(DSM)",
            },
          ]}
          selected={searchParam.fulfillment_status || []}
          onChange={handleChangeFulfillmentStatus}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ]; //状态搜索Filters
  const appliedFilters = [];
  if (!isEmpty(searchParam.payment_status)) {
    const key = "payment_status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, searchParam.payment_status),
      onRemove: () => handleChangePaymentStatus([]),
    });
  }

  if (!isEmpty(searchParam.fulfillment_status)) {
    const key = "fulfillment_status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, searchParam.fulfillment_status),
      onRemove: () => handleChangeFulfillmentStatus([]),
    });
  }

  // 总条数
  const pageMsg = () => {
    let total = pageData?.count ? pageData?.count : 0;
    const { page, size } = searchParam;
    const startNum = (page - 1) * size + 1;
    const endNum = page * size > total ? total : page * size;
    return intl.get(
      "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
      startNum,
      endNum,
      total,
      Math.ceil(total / size)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (page > 1 && page <= maxPageNum) {
      return pageLoading ? false : true;
    } else if (page === 1) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, pageLoading]);

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (_.inRange(page, 1, maxPageNum)) {
      return pageLoading ? false : true;
    } else if (page === maxPageNum) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, pageLoading]);

  // 上一页
  const handlePrev = () => {
    setToPage("");
    setPageLoading(true);
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) - 1;
      getDataList(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 下一页
  const handleNext = () => {
    setToPage("");
    setPageLoading(true);
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) + 1;
      getDataList(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  // 支付进度显示
  let paymentTips = <div></div>;
  if (paymentPopData.operationNumber !== 0) {
    paymentTips = (
      <div style={{ marginBottom: "2rem" }}>
        <Banner
          title={
           paymentPopData.currentExecutionsNumber +
            "/" +
            paymentPopData.operationNumber +
            " orders are being paid."
          }
          tone="info"
          // onDismiss={() => {}}
        ></Banner>
      </div>
    );
  }
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;

  const EmptyProductList = (
    <div className="emptyWithImg">
       <EmptyState
        heading={intl.get("Sorry, no results found.")}
        image="/empty.svg"
      >
      </EmptyState> 
    </div>
  );
  const ids =
    Object.values(pageData).length > 0
      ? pageData?.list
          .filter((item) => item.checked === 1)
          .map((item) => item.dsm_record)
      : [];
  const pageContent = isInit ? (
    <SkeletonPage fullWidth={true}>
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap={400}>
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  ) : (
    <Page
      title={<div style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}> <div style={{fontSize:'20px',marginRight:20}} >{intl.get("Redeem Products")}</div><span style={{fontSize:14,fontWeight:400}}>Remaining points: {formatNumber(userPoints?.available_points)}</span></div>}
      fullWidth={true}
      backAction={{
        content: "Points",
        onAction: () => {
          utils.routeListen("/admin/points");
          utils.routeJump1(router, "/admin/points");
        },
      }}
    >
      <Box className="redeeem_product_page">
        <Box onClick={()=>handleTabChange(0)} className={searchParam.selected === 0 ? "redeeem_checked redeeem_product_page_title" : "redeeem_product_page_title"}>
          <span>Redemption Zone</span>
          <p></p>
        </Box>
        <Box onClick={()=>handleTabChange(1)} className={searchParam.selected === 1 ? "redeeem_checked redeeem_product_page_title" : "redeeem_product_page_title"}>
          <span>Redemption Record</span>
          <p></p>
          </Box>
      </Box>
      <Layout>
        <Layout.Section>
        {searchParam.selected === 1 &&  <Box className="tab_container">
              <Box>
                  {paymentTips}
                </Box>
              {searchParam.selected === 1 && (
                <Box>
                  <Box
                    className="brand_search_container"
                    style={{ flexDirection: "column" }}
                  >
                    <Box className="search_container_filter">
                      {/* Product keyWords 下拉搜索框 */}
                      <Box className="search_type">
                        <Select
                          labelHidden
                          options={[
                            { label: "Product Tittle", value: "keyword" },
                            {
                              label: "DSM order number",
                              value: "dsm_order",
                            },
                          ]}
                          onChange={handleProductKeyWordsChange}
                          value={searchParam.search_type}
                        />
                      </Box>

                      {/* Filter items 搜索框 */}
                      <Box className="search_container_input">
                        <TextField
                          prefix={<Icon source={SearchIcon} />}
                          placeholder={
                            searchParam.search_type === "keyword"
                              ? "Please enter product key words"
                              : "Please enter Dropshipman order number"
                          }
                          autoComplete="off"
                          onChange={handleSearchValue}
                          value={searchParam.query}
                        />
                      </Box>
                      <Box className="search_container_order_btn">
                        <Button
                          variant="primary"
                          loading={searchParam.searchLoading}
                          onClick={() => {
                            handleSearchBtn();
                          }}
                        >
                          Search
                        </Button>
                        <Button onClick={() => handleClearSearch()}>
                          Clear
                        </Button>
                      </Box>
                    </Box>
                    <div className="search_status">
                      <Filters
                        filters={filters}
                        hideQueryField
                        appliedFilters={appliedFilters}
                        onClearAll={handleClearAll}
                      />
                    </div>
                  </Box>
                  <Box className="list_content_tab_select">
                    <Checkbox
                      label={
                        ids.length > 0
                          ? ids.length + " selected"
                          : "Select orders"
                      }
                      checked={checkStatus.checked}
                      onChange={(val) => {
                        handleOrderSelect(val);
                      }}
                    />
                    {ids.length > 0 ? (
                      <Box className="list_content_tab_select_button">
                        <Button
                          variant="primary"
                          onClick={() => {
                            handlePaymentButton(ids);
                          }}
                        >
                          Bulk Payment
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              )}
          </Box>}
          {Object.values(pageData).length > 0 && pageData?.list.length > 0 ? (
            <Box>
              {searchParam.selected === 0 && (
                <Box className="page_container">
                  <ListItemOne listData={pageData.list} />
                </Box>
              )}
              {searchParam.selected === 1 && (
                <Box className="page_container">
                  <ListItemTwo
                    listData={pageData.list}
                    pageData={pageData}
                    getSearchParam={searchParam}
                    setPageData={setPageData}
                    setListLoading={setPageLoading}
                    getOrderListData={getDataList}
                    getUserPoints={getUserPoints}
                    handleSaveData={handleSaveData}
                    handlePaymentButton={handlePaymentButton}
                  />
                </Box>
              )}
             
             {searchParam.selected === 1 && <div className="pageination_container">
                <Pagination
                  label={pageMsg()}
                  hasPrevious={hasPrevious}
                  hasNext={hasNext}
                  onPrevious={handlePrev}
                  onNext={handleNext}
                />
                <div className="Page_Jump_input">
                  <input
                    className="Page_input"
                    type="text"
                    value={toPage}
                    maxLength={"8"}
                    onChange={(e) => {
                      if (Number.isInteger(Number(e.target.value))) {
                        if (
                          (e.target.value > Math.ceil(pageData?.count / 50) ||
                            e.target.value <= 0) &&
                          e.target.value !== ""
                        ) {
                          setToPage(1);
                        } else {
                          setToPage(e.target.value);
                        }
                      }
                    }}
                  />
                  <span
                    className="Page_Jump"
                    onClick={() => {
                      if (Math.ceil(pageData?.count / 50) === 1) return false;
                      let page = 1;
                      if (Math.ceil(pageData?.count / 50) >= toPage) {
                        page = toPage;
                      }
                      if (
                        Math.ceil(pageData?.count / 50) > 1 &&
                        Math.ceil(pageData?.count / 50) <= toPage
                      ) {
                        page = toPage;
                      }
                      if (page === "") {
                        return;
                      }
                      setPageLoading(true);
                      setSearchParam((prevValue) => {
                        const params = { ...prevValue, page: page };
                        getDataList(params);
                        return params;
                      });
                      if (document) {
                        if (document?.documentElement || document?.body) {
                          document.documentElement.scrollTop =
                            document.body.scrollTop = 0;
                        }
                      }
                    }}
                  >
                    {intl.get("Go to")}
                  </span>
                </div>
              </div>}
            </Box>
          ) : (
            <Box  className="page_container">
            <Card>
            {EmptyProductList}
            </Card>
            </Box>
          )}
        </Layout.Section>
      </Layout>
      {/* 批量支付弹窗 */}
      <BulkPaymentModal
        isPointsPayment={true}
        paymentData={paymentPopData}
        handlePayForOrderModal={handlePayForOrderModal}
        setPayoneerBalances={setPayoneerBalances}
        handlePaymentButtonClose={handlePaymentButtonClose}
        setDlocalTip={setDlocalTip}
        setPayoneerConnectOpen={setPayoneerConnectOpen}
      />
      {/* payoneer未授权 */}
      <PayoneerBalancesConnect
        open={payoneerConnectOpen}
        callPayoneerConnectClosed={callPayoneerConnectClosed}
      />
      {/* 选择payoneer支付账户 */}
      <PayoneerBalancesOrderSelect
        datas={payoneerBalances}
        setPayoneerBalances={setPayoneerBalances}
        handlePayoneerConfirm={handlePayoneerConfirm}
      />
      {/* 确认付款 */}
      <PayoneerBalancesOrderConfirm
        handlePayoneerConfirmClose={handlePayoneerConfirmClose}
        data={payoneerConfirm.data}
        open={payoneerConfirm.open}
        setPayoneerAgainOpen={setPayoneerAgainOpen}
        setPayoneerConfirm={setPayoneerConfirm}
      />
      <PayoneerBalancesAgain
        open={payoneerAgainOpen}
        callPayoneerAgainClosed={callPayoneerAgainClosed}
      />

      <PayForOrderModal
        paymentData={paymentPopData}
        handlePaypalClose={handlePaypalClose}
        getOrderListData={getDataList} //获取页面数据
        getSearchParam={searchParam} //当前页面tabdata
      />
      {/* 钱包支付 */}
      <WalletForOrderModal
        paymentData={paymentPopData}
        handleWalletActive={handleWalletActive}
        handlePaymentButtonClose={handleWalletClose}
      />
       {/* dlocal支付完成提示 */}
       <DlocalPayTipModal setDlocalTip={setDlocalTip} dlocalTip={dlocalTip} getOrderListData={()=>getDataList(searchParam)} />
        {/* 功能视频介绍 */}
      <NoviceOrderModal upgradeOpen={noviceModal} setUpgradeOpen={()=>setNoviceModal(false)}/>
    </Page>
  );
  return (
    <div className="redeem_products_list" style={{ position: "relative",minHeight:1000 }}>
      {pageContent}
      {pageLoading && <ChangeLoading />}
      {toastMarkup}
    </div>
  );
};

export default withRouter(Index);
