import React, { Component } from 'react';
import FrameComponents from "../FrameComponents"; 
import "./index.css";

class GlassesStore extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan - Black Friday';
  }

  render() {
    return (
      <div>
        <FrameComponents name="black_friday"/> 
      </div>
    )
  }
}

export default GlassesStore;