import {
  Box,
  Button,
  Card,
  InlineStack,
  OptionList,
  Popover,
  Tabs,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { PlayCircleIcon } from "@shopify/polaris-icons";
import "./index.css";
import SourcingFilterBox from "./component/SourcingFilterBox";
import SourcingTableBox from "./component/SourcingTableBox";
import SourcingRequestModal from "./component/SourcingRequestModal";
import EnoughQuotaModal from "./component/EnoughQuotaModal";
import EnoughPointsModal from "./component/EnoughPointsModal";
import NoviceSourcingModal from "../../../../components/NoviceGuidance/NoviceSourcingModal";
import CommonTableFilterSkeleton from "./component/CommonTableFilterSkeleton"
import MobileTableBox from "./component/MobileTableBox";
import { reqGetSourcingListData, reqGoogleAnalytics } from "../../../../api";
import { useSelector } from "react-redux";
import AppBridge from "../../../../utils/appBridge";
import { store } from "../../../../store";
import { changeSourcingData } from "../../../../slice";
import utils from "../../../../utils/utils";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom";
import SearchLoading from "../../../../components/SearchLoading";
const SourcingRequestBox = ({
  planData,
  setPlanData,
  userPoints,
  setUserPoints,
  setPremiumData,
  handleMageUpgrade,
  subList,
  toastFun
}) => {
  const router = useHistory()
  const [active, setActive] = useState(false); // 是否打开source类型选择
  const [selectedType, setSelectedType] = useState(["My quote"]); // 默认常规找货
  const [isPopupOpen, setIsPopupOpen] = useState(false); // 是否打开popup 创建找货
  const [loading,setLoading] = useState(false);// 页面状态加载
  const [noviceModal,setNoviceModal] = useState(false);
  const [initPage,setInitPage] = useState(true)// 初始化骨架屏
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {// 关闭弹窗
    setIsPopupOpen(false);
  };

  const toggleActive = useCallback(() => setActive((active) => !active), []); // 打开source类型选择

  const [tabs, setTabs] = useState([
    {
      id: "all-customers-1",
      content: "All(0)",
      accessibilityLabel: "All customers",
      panelID: "all-customers-content-1",
    },
    {
      id: "accepts-marketing-1",
      content: "Pending(0)",
      panelID: "accepts-marketing-content-1",
    },
    {
      id: "repeat-customers-1",
      content: "Failed(0)",
      panelID: "repeat-customers-content-1",
    },
    {
      id: "prospects-1",
      content: "Success(0)",
      panelID: "prospects-content-1",
    },
  ]);
  // 请求参数
  const [requestParams, setRequestParams] = useState({
    page: 1,
    size: 20,
    search_from:1,
    q:'',
    sourcing_status:[],
    platform:1
  });

  // 次数不足
  const [sourcingLimitModal,setSourcingLimitModal] = useState(false)

  // 达到上限
  const [enoughPoint,setEnoughPoint] = useState(false);
  // 列表数据
  const [listData, setListData] = useState({
    list:[]
  });

 
  // 获取列表数据
  const getListData = async(data) => {
    try {
      let params = {...requestParams,...data}
      setRequestParams((prevValue)=>({
        ...prevValue,
        ...params
      }))
      if (params.sourcing_status.length === 0) {
        params.sourcing_status = 'All'
      }
      if (data) {
        setLoading(true)
      }
      if (params.sourcing_status.length !== 0 && typeof params.sourcing_status === "object") {
        params.sourcing_status = params.sourcing_status[0]
      }
      let res = await reqGetSourcingListData(params)
      // res = deData
      if (res.code === '200') {
        let pageData = res.data;
           setUserPoints((prevValue)=>({// 创建成功重置积分使用规则
          ...prevValue,
          userPointsRedeem:{
            open:false,
            id:0,
            isDetail:false,
            is_new:0,
            isPoints:false
          }
        }))
        setTabs([
          {
            id: "all-customers-1",
            content: "All("+pageData.summary.All+")",
            accessibilityLabel: "All customers",
            panelID: "all-customers-content-1",
          },
          {
            id: "accepts-marketing-1",
            content: "Pending("+pageData.summary.Pending+")",
            panelID: "accepts-marketing-content-1",
          },
          {
            id: "repeat-customers-1",
            content: "Failed("+pageData.summary.Failed+")",
            panelID: "repeat-customers-content-1",
          },
          {
            id: "prospects-1",
            content: "Success("+pageData.summary.Success+")",
            panelID: "prospects-content-1",
          },
        ])
        let list = pageData.list.map((item, index) => {
          item.checked = 0;
          item.failed_active = 0
          return item;
        });
        setListData({...pageData,list});
      }else {
        setListData({
          list:[],
          summary:{
            All:0,
            Pending:0,
            Failed:0,
            Success:0
          }
        })
      }
      setInitPage(false)
      setLoading(false)
    } catch (error) {
      
    }
  };
  const plan_level = useSelector(
    (state) => state.languageReducer.subScriptData
  );
   // 打开sourcing弹窗，详情弹窗
  const handleSourcingModal = () => {
    //Sourcing页面sourcing按钮点击gtag
    reqGoogleAnalytics('click', 'sourcing_sourcing_click');
    // 查看sourcing详情
    
    try {
    
    if (planData.sourcingLimit >= planData.sourcingDay) {
      if ((plan_level.bill_level === 5 || plan_level.level === 5) && userPoints.userPointsData.available_points < 200) {
        setEnoughPoint(true)
        return
      }else {
        setSourcingLimitModal(true)
      }
      
    }else {
      if (AppBridge.exist()) {
        utils.routeListen('/admin/product_selection/sourcing-detail')
        utils.routeJump1(router,'/admin/product_selection/sourcing-detail')
        router.push("/admin/product_selection/sourcing-detail",{isPoints:false})
        store.dispatch(changeSourcingData({sourcing:{
          show:true,
        }}));
      }else {
        openPopup()
      }
    }
  } catch (error) {
      
  }
  }
 

  const handleSubmit = (val) => {
    if (val) {
        if (val === 5) {
            setPremiumData((prevValue) => ({
                ...prevValue,
                open: true,
              }));
            setSourcingLimitModal(false)
        }
        if (val === 6) {
          setUserPoints((prevValue)=>({
            ...prevValue,
            userPointsRedeem:{
              ...prevValue.userPointsRedeem,
              isPoints:true
            }
          }))
          setSourcingLimitModal(false)
          if (AppBridge.exist()) {
            utils.routeListen('/admin/product_selection/sourcing-detail')
            utils.routeJump1(router,'/admin/product_selection/sourcing-detail',{isPoints:true})
            // router.push("/admin/product_selection/sourcing-detail",{isPoints:true})
            store.dispatch(changeSourcingData({sourcing:{
              show:true,
            }}));
            
          }else {
            openPopup()
          }
        }
        if (val <5) {
          handleMageUpgrade(val)
        }
    }
  };



  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getListData();
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line
  }, []);

  //   tab选择
  const [selected, setSelected] = useState(0);
  //   tab切换
  const handleTabChange = (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      let params = {page: 1,
        size: 20,
        search_from:1,
        q:'',
        sourcing_status:[]
      }
      switch (selectedTabIndex) {
        case 0:
          getListData(params)
          break;
        case 1:
          getListData({...params,sourcing_status:['Pending']})
          break;
        case 2:
          getListData({...params,sourcing_status:['Failed']})
          break;
        case 3:
          getListData({...params,sourcing_status:['Success']})
          break;
      
        default:
          getListData({...params,sourcing_status:[]})
          break;
      }
    }
  // 切换按钮
  const activator = (
    <Button variant="tertiary" onClick={toggleActive} disclosure>
      <strong>{selectedType}</strong>
    </Button>
  );
  const widthPage = useScreenWidth();
  
  return (
    <div style={{position:'relative'}}>
    {initPage ? <CommonTableFilterSkeleton /> :
    <Box className="sourcing_request_box">
      <Box className="sourcing_request_head">
        <InlineStack gap={200} blockAlign="center" align="space-between">
          <Box className="sourcing_request_head_title">
            <InlineStack wrap={false} blockAlign="center">
              <strong>Sourcing:</strong>
              <Popover
                active={active}
                activator={activator}
                autofocusTarget="first-node"
                onClose={toggleActive}
              >
                <OptionList
                  onChange={(value)=>{
                    if (value[0] === "Shopify order") {
                      getListData({
                        page: 1,
                        size: 20,
                        search_from:1,
                        q:'',
                        sourcing_status:[],
                        platform:2})
                    }else {
                      getListData({page: 1,
                        size: 20,
                        search_from:1,
                        q:'',
                        sourcing_status:[],
                        platform:1})
                    }
                    setSelected(0)
                    setSelectedType(value)
                    
                  }}
                  options={[
                    { value: "My quote", label: "My quote" },
                    { value: "Shopify order", label: "Shopify order" },
                  ]}
                  selected={selectedType}
                />
              </Popover>
            </InlineStack>
          </Box>
          {selectedType[0] === "My quote" ? (
            <Box className="sourcing_request_head_button">
              <InlineStack blockAlign="center" gap={200}>
                <Button icon={PlayCircleIcon} onClick={()=>setNoviceModal(true)}>Watch Tutorial</Button>
                <Button variant="primary" onClick={() => handleSourcingModal()}>
                  Sourcing Request ({planData.sourcingLimit}/{planData.sourcingDay})
                </Button>
              </InlineStack>
            </Box>
          ) : (
            ""
          )}
        </InlineStack>
      </Box>
      <Box className="sourcing_request_body">
        <Card padding={0}>
          <Tabs
            tabs={tabs}
            selected={selected}
            onSelect={handleTabChange}
          ></Tabs>
          <Box>
            <SourcingFilterBox selectedType={selectedType} selected={selected} requestParams={requestParams} setRequestParams={setRequestParams} getListData={getListData}/>
          </Box>
          {widthPage >= 420 ? <Box>
            <SourcingTableBox selectedType={selectedType} toastFun={toastFun} listData={listData} setListData={setListData} loading={loading} requestParams={requestParams} setRequestParams={setRequestParams} getListData={getListData}/>
          </Box> : <Box>
            <MobileTableBox selectedType={selectedType} toastFun={toastFun} listData={listData} setListData={setListData} loading={loading} requestParams={requestParams} setRequestParams={setRequestParams} getListData={getListData}/>
          </Box>}
        </Card>
        {/* 创建source */}
        <SourcingRequestModal
          isOpen={isPopupOpen}
          onClose={closePopup}
          show={true}
          updateCallBackFun={() => {
            setPlanData((prevValue)=>({
              ...prevValue,
              sourcingLimit: prevValue.sourcingLimit + 1,
            }))
            if (userPoints.userPointsRedeem.isPoints) {
              setUserPoints((prevValue)=>({
                ...prevValue,
                userPointsData:{
                  ...prevValue.userPointsData,
                  available_points:Number(prevValue.userPointsData.available_points) - 200
                }
              }))
            }
            getListData()
          }}
          requestCreateCallBack={() => {}}
          handlePointsRedeemLess={() => {}}
          id={0}
          isPoints={userPoints.userPointsRedeem.isPoints}
          type={1}
          is_new={true}
          isDetail={false}
        />
        {/* 次数不够 */}
        <EnoughQuotaModal type={'sourcing'} handleSubmit={handleSubmit}  sourcingLimitModal={sourcingLimitModal} userPoints={userPoints} subList={subList} setSourcingLimitModal={setSourcingLimitModal}/>
        {/* 会员上限且积分不够 */}
        <EnoughPointsModal type={'sourcing'} open={enoughPoint} setOpen={setEnoughPoint}/>
        {/* 引导视频 */}
        <NoviceSourcingModal upgradeOpen={noviceModal} setUpgradeOpen={()=>setNoviceModal(false)}/>
        
      </Box>
    </Box>}

    {loading && <SearchLoading />}
    </div>
  );
};

const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
};

// const deData = {
//   "code": "200",
//   "msg": "Success",
//   "data": {
//       "count": 14,
//       "list": [
//           {
//               "id": 787,
//               "show_id": 1032,
//               "status": 3,
//               "product_url": "https:\/\/admin.shopify.com\/store\/liou-test\/apps\/dsm-person-test\/admin\/product_selection\/sourcing-detail",
//               "target_price": "1.00",
//               "fail_reason": "test",
//               "created_at": 1734578635,
//               "title": "test",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/17346650316764e347a46875373_66552_2522.png",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 0
//           },
//           {
//               "id": 782,
//               "show_id": 1029,
//               "status": 1,
//               "product_url": "https:\/\/asds.com",
//               "target_price": "1.00",
//               "fail_reason": "",
//               "created_at": 1734512018,
//               "title": "test",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/173451198967628d758b65b484_66552_8329.png",
//               "request_type": 2,
//               "product_type": "Phones & Accessories",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 1
//           },
//           {
//               "id": 781,
//               "show_id": 1028,
//               "status": 1,
//               "product_url": "https:\/\/admin.shopify.com\/store\/liou-test\/apps\/dsm-person-test\/admin\/product_selection\/sourcing-detail",
//               "target_price": "10.00",
//               "fail_reason": "",
//               "created_at": 1734422082,
//               "title": "test",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/173450847867627fbe582ef2079_66552_2108.png",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 1
//           },
//           {
//               "id": 780,
//               "show_id": 1027,
//               "status": 99,
//               "product_url": "https:\/\/www.baidu.com",
//               "target_price": "10.00",
//               "fail_reason": "",
//               "created_at": 1734492791,
//               "title": "jsjisjsjjs",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/173449275367624251c4c051178_66552_6047.jpg",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "TkRjME16Zz1TSnZRUFU=",
//               "user_pro_id": 3112,
//               "publish_status": 0,
//               "expedite_sign": 0
//           },
//           {
//               "id": 778,
//               "show_id": 1025,
//               "status": 3,
//               "product_url": "https:\/\/liou-test.myshopify.com\/products\/test?id=7095896342661",
//               "target_price": "10.00",
//               "fail_reason": "test",
//               "created_at": 1734487893,
//               "title": "test",
//               "all_images": "",
//               "request_type": 1,
//               "product_type": "Other",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 0
//           },
//           {
//               "id": 777,
//               "show_id": 1024,
//               "status": 1,
//               "product_url": "https:\/\/admin.shopify.com\/store\/liou-test\/apps\/dsm-person-test\/admin\/product_selection\/sourcing-detail",
//               "target_price": "12.00",
//               "fail_reason": "",
//               "created_at": 1734487279,
//               "title": "21332321",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/173448727167622ce777f6299_66552_5790.png",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 1
//           },
//           {
//               "id": 775,
//               "show_id": 1023,
//               "status": 99,
//               "product_url": "https:\/\/liou-test.myshopify.com\/products\/luxury-split-toe-oxford-shoes-for-men-lace-up-coffee-black-dress-shoes-with-suede-patchwork-and-crocodile-prints?id=7101592174725",
//               "target_price": "227.30",
//               "fail_reason": "",
//               "created_at": 1734427748,
//               "title": "Luxury Split Toe Oxford Shoes for Men - Lace Up Coffee Black Dress Shoes with Suede Patchwork and Crocodile Prints",
//               "all_images": "https:\/\/cdn.shopify.com\/s\/files\/1\/0574\/8614\/7717\/files\/Sc0829e6de14747f7b3b7fd59dd379b4fm.webp?v=1734053476",
//               "request_type": 1,
//               "product_type": "Other",
//               "main_country": "US",
//               "originalId": "TXpnPXpJeVBHbQ==",
//               "user_pro_id": 3105,
//               "publish_status": 99,
//               "expedite_sign": 0
//           },
//           {
//               "id": 774,
//               "show_id": 1022,
//               "status": 4,
//               "product_url": "https:\/\/admin.shopify.com\/store\/liou-test\/apps\/dsm-person-test\/admin\/product_selection\/sourcing-detail",
//               "target_price": "10.00",
//               "fail_reason": "",
//               "created_at": 1734427108,
//               "title": "test-re",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/1734427103676141df858ed6718_66552_7308.png",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 2
//           },
//           {
//               "id": 773,
//               "show_id": 1021,
//               "status": 4,
//               "product_url": "https:\/\/liou-test.myshopify.com\/products\/adult-sleeping-bed-camping-bed-6-adjustable-positions-foldable-folding-bed-with-blanket-550-pound-heavy-sleeping-bed-suitable-for-camping-travel-room-office?id=7101592207493",
//               "target_price": "399.10",
//               "fail_reason": "",
//               "created_at": 1734340059,
//               "title": "Adult Sleeping Bed\/Camping Bed - 6 Adjustable Positions, Foldable Folding Bed, with Blanket, 550 Pound Heavy Sleeping Bed, Suitable for Camping, Travel, Room, Office",
//               "all_images": "https:\/\/cdn.shopify.com\/s\/files\/1\/0574\/8614\/7717\/files\/8d855f78-9747-438e-ac00-d4ec1c9bf218.jpg?v=1734053476",
//               "request_type": 1,
//               "product_type": "Other",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 1
//           },
//           {
//               "id": 772,
//               "show_id": 1020,
//               "status": 3,
//               "product_url": "https:\/\/liou-test.myshopify.com\/products\/new-rhombus-home-slippers-summer-non-slip-floor-bathroom-slipper-lightweight-simple-house-shoes-for-women-men?id=7094306832517",
//               "target_price": "23.32",
//               "fail_reason": "ces",
//               "created_at": 1734425408,
//               "title": "New Rhombus Home Slippers Summer Non-slip Floor Bathroom Slipper Lightweight Simple House Shoes For Women Men",
//               "all_images": "https:\/\/cdn.shopify.com\/s\/files\/1\/0574\/8614\/7717\/files\/34771d89-ded8-4c43-9752-aad14006a0ca.jpg?v=1732086928",
//               "request_type": 1,
//               "product_type": "Other",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 0
//           },
//           {
//               "id": 769,
//               "show_id": 1018,
//               "status": 1,
//               "product_url": "https:\/\/admin.shopify.com\/store\/liou-test\/apps\/dsm-person-test\/admin\/product_selection\/sourcing-detail",
//               "target_price": "10.00",
//               "fail_reason": "",
//               "created_at": 1734250000,
//               "title": "test",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/1734422852676131443651d3840_66552_5600.png",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 1
//           },
//           {
//               "id": 765,
//               "show_id": 1015,
//               "status": 3,
//               "product_url": "https:\/\/admin.shopify.com\/store\/liou-test\/apps\/dsm-person-test\/admin\/product_selection\/sourcing-detail",
//               "target_price": "12.00",
//               "fail_reason": "ces ",
//               "created_at": 1734335093,
//               "title": "FFFF",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/1734335089675fda71ec5bb7040_66552_332.png",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 0
//           },
//           {
//               "id": 764,
//               "show_id": 1014,
//               "status": 99,
//               "product_url": "https:\/\/admin.shopify.com\/store\/liou-test\/apps\/dsm-person-test\/admin\/product_selection\/sourcing-detail",
//               "target_price": "10.00",
//               "fail_reason": "",
//               "created_at": 1734334911,
//               "title": "su",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/1734334894675fd9ae12775219_66552_2526.png",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "TXpBPWNtTkpORA==",
//               "user_pro_id": 3113,
//               "publish_status": 0,
//               "expedite_sign": 0
//           },
//           {
//               "id": 763,
//               "show_id": 1013,
//               "status": 1,
//               "product_url": "https:\/\/admin.shopify.com\/store\/liou-test\/apps\/dsm-person-test\/admin\/product_selection\/sourcing-detail",
//               "target_price": "10.00",
//               "fail_reason": "",
//               "created_at": 1734162741,
//               "title": "test-test",
//               "all_images": "https:\/\/dropshipman-test.oss-cn-shenzhen.aliyuncs.com\/app\/photo\/66552\/1734334670675fd8ce810f11383_66552_7790.png",
//               "request_type": 2,
//               "product_type": "Women's Fashion",
//               "main_country": "US",
//               "originalId": "",
//               "user_pro_id": 0,
//               "publish_status": 0,
//               "expedite_sign": 1
//           }
//       ],
//       "show_guide": 0,
//       "summary": {
//           "Pending": 7,
//           "Failed": 4,
//           "Success": 3,
//           "All": 14
//       }
//   }
// }
export default withRouter(SourcingRequestBox);
