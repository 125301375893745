import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Modal, Toast ,Banner , Thumbnail,Link } from '@shopify/polaris';

import { reqGetNotifyProductVariants,reqPostUpdateAutoSettings } from '../../../../api';
import utils from '../../../../utils/utils'
import intl from '../../../../i18n/intl'

class UpdateInventoryModal extends Component {

    constructor(props) {
      super(props);
      this.state = {
        ModalShow: (props.show===undefined)?false:props.show,
        fatherKey: props.fatherKey,
        productTitle: props.title,
        productImage: props.image,
        EditOnShopify: props.EditOnShopify,
        productStockVariants: props.stockVariants,
        productVariants: (props.variants===undefined)?[]:props.variants,
        isSettingAutoUpdate: props.isSettingAutoUpdate,
        isUpdateSuccess: props.isUpdateSuccess,

        //弹窗
        showToast: false,  //弹窗显示
        showToastText:'',  //弹窗文字
        showToastIserror:false, //弹窗是否错误

        autoButtonloading: false,
        autoButtonSelected: false,
        doNotButtonLoading: false,
        doNotButtonSelected: true,

        loadingData: false,
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        ModalShow: (nextProps.show===undefined)?false:nextProps.show,
        fatherKey: nextProps.fatherKey,
        productTitle: nextProps.title,
        productImage: nextProps.image,
        EditOnShopify: nextProps.EditOnShopify,
        productStockVariants: nextProps.stockVariants,
        productVariants: (nextProps.variants===undefined)?[]:nextProps.variants,
        isSettingAutoUpdate: nextProps.isSettingAutoUpdate,
        isUpdateSuccess: nextProps.isUpdateSuccess,

        //弹窗
        showToast: false,  //弹窗显示
        showToastText:'',  //弹窗文字
        showToastIserror:false, //弹窗是否错误

        autoButtonloading: false,
        autoButtonSelected: false,
        doNotButtonLoading: false,
        doNotButtonSelected: true,

        loadingData: false,
      },()=>{
        this.getVariantsData();
      });
    }

    //进入页面调用接口
    componentDidMount () {
      this.getVariantsData();
    }

    // 获取variants列表
    getVariantsData = async() =>{
      this.setState({ loadingData: true });
      const params = {
        nid: this.props.notificationProductTableId,
        sid: this.props.shopifyProductTableId
      };
      const response = await reqGetNotifyProductVariants(params);
      this.setState({ loadingData: false });

      if(response.code === '200'){
        let getData = response.data;
        this.setState({
          productStockVariants: getData.VariantStock,
          productVariants: getData.productVariants,
        });

      }else{
        this.toggleToast(response.msg);
      }
    }

    //提示弹窗
    toggleToast = (showToastText, showToastIserror=false) => {
      this.setState(({ showToast }) => ({
        showToast: !showToast,
        showToastText: showToastText,
        showToastIserror: showToastIserror
      }));
    };

    
    // 弹窗控制
    handleChangeModal = () =>{
      this.setState({
        ModalShow:!this.state.ModalShow,
      });
    }

    // 更新自动更新的配置
    updateAutoSetting = async (type,selected) =>{
      if(selected === 1){
        this.setState({
          doNotButtonLoading: true,
          doNotButtonSelected: true,
          autoButtonSelected: false
        });

      }else{
        this.setState({
          autoButtonloading: true,
          autoButtonSelected: true,
          doNotButtonSelected: false
        });
      }
      const params = {type: type,selected: selected};
      const response = await reqPostUpdateAutoSettings(params);
      if (response.code === "200") {
        this.setState({
          autoButtonloading: false,
          doNotButtonLoading: false
        },()=>{
          // 更新父级状态
          this.props.childUpdate({childType:'inventory',recordKey: this.state.fatherKey,data:{selected:selected}});
        });

        this.toggleToast(response.msg,false);
        
      } else{
        this.setState({
          autoButtonloading: false,
          doNotButtonLoading: false,
          doNotButtonSelected: false,
          autoButtonSelected: false
        });

        this.toggleToast("please refresh network");
      }
    }

    render() {
        const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
        const {
            state: { ModalShow,productTitle,productImage,productStockVariants,productVariants,isUpdateSuccess,EditOnShopify,loadingData,
              // autoButtonloading,autoButtonSelected,doNotButtonLoading,doNotButtonSelected
            },
        } = this;

        // 显示库存字符串
        let _productStockVariants = '';
        try {
          if(productStockVariants !== undefined || productStockVariants !== ''){
            if(productStockVariants.variantCount > 0){
              // let _variantText = (productStockVariants.variantCount > 1)?' variants':' variant';
              // _productStockVariants = productStockVariants.stockCount + ' in stock for '+ productStockVariants.variantCount + _variantText;
              _productStockVariants = intl.get("{{productStockVariants.stockCount}} in stock for {{productStockVariants.variantCount}} variant", productStockVariants.stockCount, productStockVariants.variantCount);
            }
            
          }
        } catch (error) {}

        // 显示该记录时候已执行自动更新 banner
        let BannerTip = '';
        if(isUpdateSuccess){
          BannerTip = (
            <Banner tone="success">
               
                <p style={{whiteSpace:'pre-wrap'}}>
                  {intl.get("You have set")} <Link onClick={()=>
                  {
                    utils.routeListen('/admin/settings/notification')
                    utils.routeJump(this,'/admin/settings/notification')
                    }
                } monochrome>{intl.get("UPDATE INVENTROY QUANTITY")}</Link> {intl.get("when the product inventory quantity changes.")}
                  <br />We've already changed the INVENTROY QUANTITY for this product.
                </p>
               
            </Banner>
          );
        }else{
          BannerTip = (
            <Banner tone="warning">
               
                <p style={{whiteSpace:'pre-wrap'}}>
                  {intl.get("You have set")} <Link onClick={()=>{
                    utils.routeListen('/admin/settings/notification')
                    utils.routeJump(this,'/admin/settings/notification')
                    }} monochrome>{intl.get("DO NOTHING")}</Link> {intl.get("when the product inventory quantity changes.")}
                </p>
               
            </Banner>
          );
          // BannerTip = (
          //   <Banner
          //     // title=""
          //     status="warning"
          //   >
          //      
          //       <p style={{whiteSpace:'pre-wrap'}}>
          //         Setting for inventory quantity change
          //       </p>
          //      
              
          //     <div style={{marginTop:'13px'}}>
          //       <ButtonGroup>
          //         <Button 
          //           size="slim" 
          //           onClick={this.updateAutoSetting.bind(this,'inventory',1)} 
          //           primary={doNotButtonSelected} 
          //           loading={doNotButtonLoading}
          //           disabled={autoButtonloading}
          //         >
          //           Do Nothing
          //         </Button>
          //         <Button 
          //           size="slim" 
          //           onClick={this.updateAutoSetting.bind(this,'inventory',2)} 
          //           primary={autoButtonSelected} 
          //           loading={autoButtonloading}
          //           disabled={doNotButtonLoading}
          //         >
          //           Update automatically
          //         </Button>
          //       </ButtonGroup>
          //     </div>
          //   </Banner>
          // );
        }

        // 显示 variants 列表
        let tableTbodyTrContent = <tr></tr>;
        if(productVariants.length>0){
          tableTbodyTrContent = productVariants.map((item,key) =>{
            let trHtml = '';
            let inventoryChange = Number(item.newInventoryQuantity) - Number(item.oldInventoryQuantity);
            trHtml = (
              <tr key={key}>
                <td className="Variant_Sku">{item.shopifySku}</td>
                <td className="Variant_Old_Inventory_Quantity">{item.oldInventoryQuantity}</td>
                <td className="Variant_New_Inventory_Quantity">{item.newInventoryQuantity}</td>
                <td className={(inventoryChange > 0)?"Inventory_Quantity_Increase":"Inventory_Quantity_Reduction"}>
                  {(inventoryChange > 0)?'+'+inventoryChange:inventoryChange}
                </td>
              </tr>
            );
            return (
              trHtml
            );
          });

        }else{
          tableTbodyTrContent = (
            <tr>
              <td colSpan="4">{intl.get("The list of product variants is empty")}</td>
            </tr>
          );
        }
        
        return (
            <div>
              <Modal 
                open={ModalShow} 
                onClose={this.handleChangeModal} 
                title={intl.get("Check updated")}
                secondaryActions={[{
                  content: 'OK',
                  onAction: this.handleChangeModal
                }]}
                loading={loadingData}
                size='large'
              >
                <Modal.Section>
                  {BannerTip}

                  <div className="Original_Product_Box">
                    <div className="Original_Product_Image">
                      <Thumbnail
                        source={productImage}
                        alt="product"
                      />
                    </div>
                    <div className="Original_Product_Title">
                      <a href={EditOnShopify} target="_blank" className="Polaris-Link" rel="noopener noreferrer">
                        {productTitle}
                      </a>
                    </div>
                    <div className="Original_Product_Inventory">
                      {_productStockVariants}
                    </div>
                  </div>

                  <div className="Variant_Modal_Table">
                    <table style={{width:'100%'}}>
                      <thead>
                        <tr>
                          <th>{intl.get("Shopify Sku")}</th>
                          <th>{intl.get("Old Inventory Quantity")}</th>
                          <th>{intl.get("New Inventory Quantity")}</th>
                          <th>{intl.get("Changes")}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {tableTbodyTrContent}

                      </tbody>
                    </table>
                  </div>
                </Modal.Section>
              </Modal>

              { toastMarkup }
            </div>
        );
    }
}

export default withRouter(UpdateInventoryModal);