import React, { memo, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Icon,
  InlineStack,
  Modal,
  RadioButton,
} from "@shopify/polaris";
import {
  getDlocalAuthBalancesOrder,
  getPayonnerAuthBalancesOrderDetails,
  reqPostSetOrderPaymentData,
} from "../../../../../../api";
import intl from "../../../../../../i18n/intl";
import AppBridge from "../../../../../../utils/appBridge";
import PointsIcon from "../../../../PointsPage/component/PointsIcon";
import CouponPaymentIcon from "../../../../SubscriptionPaid/components/icons/CouponPaymentIcon";
import DraggableScroll from "../../../../../../components/DraggableScroll";
import SymbolTake from "../../../../../../components/SymbolTake";

const BulkPaymentModal = memo(
  ({
    paymentData,
    setIsShowBulkPaymentModal,
    handlePayForOrderModal,
    orderIds,
    walletMoney,
    payoneerAuth,
    setPayoneerConnectOpen,
    setPayoneerBalances,
    setDlocalTip,
  }) => {
    const [value, setValue] = useState("dsm");
    const [type, setType] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [paymentDataList, setPaymentDataList] = useState({
      coupon_list: [],
      order_ids: [],
      order_number: "",
      deductible_amount: 0,
      total_order_amount: 0,
    });
    const handleRadioChange = (_, newValue) => {
      //单选按钮点击事件
      setValue(newValue);
      switch (newValue) {
        case "dsm":
          setType(2);
          break;
        case "pal":
          setType(1);
          break;
        case "pn":
          setType(3);
          break;
        case "pix":
          setType(4);
          break;
        case "cc":
          setType(5);
          break;
        default:
          break;
      }
    };

    useEffect(() => {
      let isMount = false;
      if (!isMount) {
        handleGetPaymentData();
      }
      return () => {
        isMount = true;
      };
      // eslint-disable-next-line
    }, [orderIds, paymentData]);
    const handleGetPaymentData = async () => {
      try {
        setLoadingModal(true);
        if (paymentData.isShowBulkPaymentModal && orderIds.length > 0) {
          // 获取付款方式
          let ids = orderIds.map((item) => {
            return item.dsm_record;
          });
          let res = await reqPostSetOrderPaymentData({
            ids,
          });
          if (res.code === "200") {
            setCpfValue(paymentData.user_cpf);
            setPaymentDataList(res.data);
            setLoadingModal(false);
          }
        }
      } catch (error) {}
    };
    const [CouponData, setCouponData] = useState({
      coupon_id: 0,
      coupon_money: 0,
      usePoints: false,
      useConpon: false,
    });

    // 使用优惠券
    const ChangeCoupon = (money, id, status, val) => {
      if (val === 2) {
        setCouponData((prevValue) => ({
          ...prevValue,
          coupon_id: 0,
          coupon_money: 0,
          useConpon: false,
          usePoints: true,
        }));
      } else {
        setCouponData((prevValue) => ({
          ...prevValue,
          coupon_id: id,
          coupon_money: money,
          useConpon: val === 1 ? false : status,
          usePoints: false,
        }));
      }
    };

    const handlePayoneerDetails = async (ids) => {
      // 打开付款方式选择弹窗
      let params = {
        ids,
        coupon_id: CouponData.coupon_id,
        is_point: CouponData.usePoints ? 1 : 0,
      };
      let res = await getPayonnerAuthBalancesOrderDetails(params);
      if (res.code === "200") {
        setPayoneerBalances((prevValue) => ({
          ...prevValue,
          data: res.data,
          open: true,
          ids,
          coupon_id: CouponData.coupon_id,
          isPoints: CouponData.usePoints,
        }));
        handleOff();
      }
      setLoading(false);
    };
    // 选中付款方式，确认付款
    const handleSelectPaymentToPay = () => {
      setLoading(true);
      let ids = paymentDataList.order_ids;
      let payment_dsm_number = [paymentDataList?.order_number];
      // for (let i = 0; i < orderIds.length; i++) {
      //   for (let j = 0; j < paymentData?.batchPayOrderIds.length; j++) {
      //     if (orderIds[i].dsm_record === paymentData?.batchPayOrderIds[j]) {
      //       payment_dsm_number.push(orderIds[i].dsm_order_name);
      //     }
      //   }
      // }
      // 获取选择付款的方式

      let payment_select_value = Number(type);
      let payment_allTotalPrice = CouponData?.usePoints
        ? parseFloat(paymentDataList.total_order_amount) -
          parseFloat(paymentDataList.deductible_amount).toFixed(2)
        : Number(paymentDataList.total_order_amount - CouponData.coupon_money);
      let payment_allShippingCost = Number(paymentData.totalShippingCost);
      // return
      // 付款
      if (Number(type) === 3 && payoneerAuth === 0) {
        setPayoneerConnectOpen(true);
        handleOff();
        return;
      }
      if (Number(type) === 3 && payoneerAuth === 1) {
        handlePayoneerDetails(ids);
        return;
      }
      if (Number(type) === 4 || Number(type) === 5) {
        if (cpfValue === "") {
          setCpfError(true);
          setLoading(false);
          return;
        }
        if (cpfValue.length < 11) {
          setCpfError(true);
          setLoading(false);
          setCpfErrorText("CPF is between 11 to 14 digits.");
          return;
        }
        handleDlocalPay(ids);
        return;
      }
      handlePayForOrderModal(
        0,
        ids,
        payment_dsm_number,
        0,
        true,
        payment_select_value,
        payment_allTotalPrice,
        payment_allShippingCost,
        CouponData.coupon_id,
        CouponData.coupon_money,
        CouponData.usePoints
      );
      // 关闭付款方式选择弹窗
      handleOff();
      setLoading(false);
    };

    // dlocal支付
    const [cpfError, setCpfError] = useState(false); // 是否输入错误
    const [cpfErrorText, setCpfErrorText] = useState("CPF is required.");
    const [cpfValue, setCpfValue] = useState("");
    const onChangeCpfValue = (val) => {
      setCpfValue(val);
      setCpfError(false);
      setCpfErrorText("CPF is required.");
    };
    const handleDlocalPay = async (ids) => {
      let params = {
        ids,
        coupon_id: CouponData.coupon_id,
        is_point: CouponData.usePoints ? 1 : 0,
        payment_method_id: Number(type) === 4 ? "PQ" : "CARD",
        user_cpf: cpfValue,
        from_type: AppBridge.exist() ? 1 : 2,
      };
      let res = await getDlocalAuthBalancesOrder(params);
      if (res.code === "200") {
        handleOff();
        setDlocalTip(true);
        try {
          const w = window.open("about:blank");
          w.location.href = res.data.redirect_url;
        } catch (error) {
          window.open(res.data.redirect_url, "_blank");
        }
      } else {
        setCpfErrorText(res.msg);
        setCpfError(true);
        setLoading(false);
      }
    };

    const handleOff = () => {
      //恢复默认
      setValue("dsm");
      setLoading(false);
      setType(0);
      setIsShowBulkPaymentModal();
      setLoadingModal(false);
      setCouponData((prevValue) => ({
        ...prevValue,
        coupon_id: 0,
        coupon_money: 0,
        useConpon: 0,
        usePoints: false,
      }));
      setCpfValue("");
      setCpfErrorText("CPF is required.");
      setCpfError(false);
      setPaymentDataList((prevValue) => ({
        ...prevValue,
        coupon_list: [],
        order_ids: [],
        order_number: "",
        total_order_amount: 0,
      }));
    };

    const totalPrice = (data) => {
      let num = 0;
      if (data.usePoints) {
        num =
          parseFloat(paymentDataList.total_order_amount) -
          parseFloat(paymentDataList.deductible_amount).toFixed(2);
      } else {
        num =
          parseFloat(paymentDataList.total_order_amount) -
          parseFloat(data.coupon_money).toFixed(2);
      }

      return num;
    };
    
    const contentNode = () => {
      return (
        <div>
          <Modal.Section>
            <Box>
              <div className="order_banner">
                {/* <Banner
                  icon={""}
                  tone="info"
                  title={paymentDataList?.order_number}
                ></Banner> */}
                 {paymentDataList?.order_number.split(',').map((item,ind)=>
                  <span key={ind} className="order_banner_span"><Badge  tone="info">{item}</Badge></span>)}
              </div>
              <div
                className="pay_sp"
                style={{ lineHeight: "28px", marginTop: 5 }}
              >
                {/*开辟新Modal框内容新增优惠券*/}
                <InlineStack align="space-between">
                  <span style={{ fontWeight: "700" }}>
                    {intl.get("Orders")}
                  </span>
                  <span className="sp_clo">
                    {paymentDataList?.order_ids.length}
                  </span>
                </InlineStack>
                <InlineStack align="space-between">
                  <span style={{ fontWeight: "700" }}>
                    {intl.get("Total cost")}
                  </span>
                  <span className="sp_clo">
                  <SymbolTake price={paymentDataList?.total_order_amount} /> USD
                    {/* {"$" + paymentDataList?.total_order_amount + " USD"} */}
                  </span>
                </InlineStack>

                <InlineStack align="space-between">
                  <span style={{ fontWeight: "700" }}>
                    {intl.get("Discount")}
                  </span>
                  <span className="sp_clo">
                  -<SymbolTake price={(CouponData.usePoints
                        ? paymentDataList?.deductible_amount
                        : Number(CouponData.coupon_money).toFixed(2))} /> USD
                    {/* {"-$" +
                      (CouponData.usePoints
                        ? paymentDataList?.deductible_amount
                        : Number(CouponData.coupon_money).toFixed(2)) +
                      " USD"} */}
                  </span>
                </InlineStack>

                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {(paymentDataList.coupon_list.length > 0 ||
                    Number(paymentDataList.deductible_amount) > 0) && (
                    <RadioButton
                      id={"optional_not"}
                      name="optional_not"
                      checked={!CouponData.useConpon && !CouponData.usePoints}
                      label={intl.get("Do not use coupons")}
                      value={"0"}
                      onChange={() => {
                        ChangeCoupon(0, 0, true, 1);
                      }}
                    />
                  )}
                  {Number(paymentDataList.deductible_amount) > 0 && (
                    <Box style={{ display: "flex" }}>
                      <RadioButton
                        id={"deductible_amount"}
                        name="deductible_amount"
                        checked={CouponData.usePoints}
                        label={``}
                        value={"0"}
                        onChange={() => {
                          ChangeCoupon(0, 0, true, 2);
                        }}
                      />
                      <p
                        style={{
                          fontSize: 13,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="points_item_icon"
                          style={{ marginRight: 5 }}
                        >
                          <Icon source={PointsIcon} tone="base" />
                        </span>{" "}
                        Points can be used to deduct{" "}
                        <strong>
                          &nbsp;${paymentDataList.deductible_amount} USD{" "}
                        </strong>
                      </p>
                    </Box>
                  )}
                  {paymentDataList.coupon_list.length > 0 && (
                    <Box style={{ display: "flex" }}>
                      <RadioButton
                        id={"Coupon"}
                        name="Coupon"
                        checked={CouponData.useConpon && !CouponData.usePoints}
                        value={"0"}
                        onChange={() => {
                          ChangeCoupon(0, 0, true, 3);
                        }}
                      />
                      <p
                        style={{
                          fontSize: 15,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="points_item_icon"
                          style={{ marginRight: 5 }}
                        >
                          <Icon source={CouponPaymentIcon} tone="base" />
                        </span>
                        Coupon
                      </p>
                    </Box>
                  )}
                </Box>
                {paymentDataList.coupon_list.length > 0 && (
                  <InlineStack>
                    <DraggableScroll children={<div className="pay_coupon">
                      {paymentDataList.coupon_list.map((item, key) => {
                        return (
                          <div
                            className={Number(CouponData.coupon_id) === Number(item.id) ? "pay_coupon_list pay_coupon_lists dsm_container_border" : "pay_coupon_list pay_coupon_lists"}
                            key={"pay_coupon" + key}
                          >
                           <div className="pay_coupon_info" >
                              <div className="pay_coupon_text" style={{backgroundImage:'url(/payoneer/coupon_payment_bg.png)'}}>
                              <Box className="line"></Box>
                                <span className="pay_coupon_text_money">
                                  ${item.coupon_money} USD
                                </span>
                                <span className="pay_coupon_text_explain">
                                  {intl.get("Orders over")} ${item.full_money}{" "}
                                </span>
                              </div>
                            </div>
                            
                            <RadioButton
                              id={"optional" + key}
                              name={"accounts" + key}
                              checked={
                                Number(CouponData.coupon_id) === Number(item.id)
                              }
                              onChange={() => {
                                ChangeCoupon(
                                  item.coupon_money,
                                  item.id,
                                  true,
                                  3
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>} />
                    
                  </InlineStack>
                )}
                
              </div>
            </Box>
            <Box>
              <div>
                <span style={{ fontWeight: "550" }}>
                  {intl.get("Payment method")}
                </span>
              </div>
              <div className="radio_container_box">
              <div className="radio_container">
                <div
                  className={`${
                    type === 2
                      ? "dsm_container_border dsm_container"
                      : "dsm_container"
                  } `}
                >
                  <div className="dsm_radioLabel">
                        <div>
                          <img src="/dsm_pay.png" alt="dropshipman" />
                        </div>
                        <div>
                          <div style={{ fontWeight: "550" }}>
                            {intl.get("Dropshipman Credits")}
                          </div>
                          <div style={{ color: "rgba(12, 81, 50, 1)" }}>
                            ${walletMoney} USD
                          </div>
                        </div>
                      </div>
                  <RadioButton
                    id="dsm"
                    name="dsm_pay"
                    disabled={walletMoney <= 0}
                    checked={type !== 0 && value === "dsm"}
                    onChange={handleRadioChange}
                  />
                </div>
                <div
                  className={`${
                    type === 3
                      ? "pal_container_border pal_container"
                      : "pal_container"
                  } `}
                >
                  <div className="pay_radioLabel">
                        <div>
                          <img src="/payoneer/pay_icon.png" alt="dropshipman" />
                        </div>
                        <div style={{ fontWeight: "550" }}>
                          {intl.get("Payoneer")}
                        </div>
                      </div>
                  <RadioButton
                    
                    id="pn"
                    name="pn_pay"
                    checked={type !== 0 && value === "pn"}
                    onChange={handleRadioChange}
                  />
                </div>
                <div
                  className={`${
                    type === 1
                      ? "dsm_container_border dsm_container"
                      : "dsm_container"
                  } `}
                >
                  <div className="pay_radioLabel">
                        <div>
                          <img src="/pay_pal.png" alt="dropshipman" />
                        </div>
                        <div style={{ fontWeight: "550" }}>
                          {intl.get("PayPal")}
                        </div>
                      </div>
                  <RadioButton
                   
                    id="pal"
                    name="pal_pay"
                    checked={type !== 0 && value === "pal"}
                    onChange={handleRadioChange}
                  />
                </div>
                {paymentData?.is_br !== 0 && (
                  <div
                    className={`${
                      type === 4
                        ? "dsm_container_border dsm_container"
                        : "dsm_container"
                    } `}
                  >
                    <div className="pay_radioLabel">
                          <div>
                            <img
                              src="/payoneer/pix_checkout.png"
                              alt="dropshipman"
                            />
                          </div>
                          <div style={{ fontWeight: "550" }}>
                            {intl.get("Pix Checkout")}
                          </div>
                        </div>
                    <RadioButton
                     
                      id="pix"
                      name="pix_pay"
                      checked={type !== 0 && value === "pix"}
                      onChange={handleRadioChange}
                    />
                  </div>
                )}
                {paymentData?.is_br !== 0 && (
                  <div
                    className={`${
                      type === 5
                        ? "dsm_container_border dsm_container"
                        : "dsm_container"
                    } `}
                  >
                    <div className="pay_radioLabel">
                          <div>
                            <img
                              style={{ paddingTop: "5px" }}
                              src="/payoneer/cc_pay.png"
                              alt="dropshipman"
                            />
                          </div>
                          <div style={{ fontWeight: "550" }}>
                            {intl.get("Card Checkout")}
                          </div>
                        </div>
                    <RadioButton
                      
                      id="cc"
                      name="cc_pay"
                      checked={type !== 0 && value === "cc"}
                      onChange={handleRadioChange}
                    />
                  </div>
                )}

                {(type === 4 || type === 5) && paymentData?.is_br !== 0 && (
                  <div
                    className="dsm_container_cpf"
                    style={{
                      border: 0,
                      padding: 0,
                      display: "flex",
                      flexDirection: "column",
                      marginBottom:30,
                    }}
                  >
                    <span>CPF</span>
                    <input
                      type="text"
                      placeholder="e.g.  2938091283"
                      value={cpfValue}
                      minLength={11}
                      maxLength={14}
                      onChange={(e) => {
                        if (Number.isInteger(Number(e.target.value))) {
                          onChangeCpfValue(e.target.value);
                        }
                      }}
                    />
                    {cpfError && <p>{cpfErrorText}</p>}
                  </div>
                )}
              </div>
              </div>
            </Box>
            <InlineStack align="space-between">
                  <span style={{ fontWeight: "700" }}>
                    {intl.get("Actual payment")}
                  </span>
                  <span style={{ fontWeight: "700" }}>
                  <SymbolTake price={totalPrice(CouponData).toFixed(2)} /> USD
                  </span>
                  {/* <span style={{ fontWeight: "700" }}>
                    {"$" + totalPrice(CouponData).toFixed(2) + " USD"}
                  </span> */}
                </InlineStack>
               
          </Modal.Section>
        </div>
      );
    };

    return (
      <div>
        <Modal
          open={paymentData.isShowBulkPaymentModal}
          // open={true}
          onClose={() => handleOff()}
          title={paymentDataList?.order_ids.length > 1 ? "Bulk Payment" : "Order Payment"}
          primaryAction={{
            content: intl.get("Pay"),
            onAction: handleSelectPaymentToPay,
            loading: loading,
            disabled: type === 0 ? true : false,
          }}
          footer={<InlineStack>
            <img style={{marginRight:8}} width={20} src="/sub/points_icon.png" alt="Dropshipman" /> 
            <span>
              You'll receive <strong>{totalPrice(CouponData).toFixed(0)} points </strong>
              once you complete this payment.
            </span>
          </InlineStack>}
          loading={loadingModal}
        >
          {contentNode()}
        </Modal>
      </div>
    );
  }
);

export default BulkPaymentModal;
