import { EmptyState } from '@shopify/polaris';
import React from 'react';
import { Box, Button, InlineStack } from '@shopify/polaris';
import {
    PlayCircleIcon
  } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from "../../../../utils/utils"
const InitPageContent = ({selected,setNoviceModal})=> {
    const router = useHistory();
    const handleGoToProduct = ()=>{
        utils.routeListen('/admin/product_selection?select_tab=0')
        utils.routeJump1(router,'/admin/product_selection?select_tab=0')
    }
    
    return (
        <Box className="management_no_product_container">
            <EmptyState
                  heading=""
                  image="/product_management/init_page.png"
                >
                 {Number(selected) ===2 ?  <Box className="management_no_product">
                    <Box className="management_no_product_text"><p>No store product can sourcing</p></Box>
                    <Box className="management_no_product_button">
                        <InlineStack gap={200} align='center'>
                        <Button variant='primary' onClick={()=>handleGoToProduct()}>Go to product selection</Button>
                        </InlineStack>
                    </Box>
                 </Box> : Number(selected) ===3 ? <Box className="management_no_product">
                    <Box className="management_no_product_text"><p>No deleted Shopify products. Products deleted from Shopify can be restored here.</p></Box>
                 </Box>:  <Box className="management_no_product">
                    <Box className="management_no_product_text"><p>No products added yet. Please add products using our various methods.</p></Box>
                    <Box className="management_no_product_button">
                        <InlineStack gap={200} align='center'>
                        <Button icon={PlayCircleIcon} onClick={()=>{setNoviceModal(true)}}>Watch Tutorial</Button>
                        <Button variant='primary' onClick={()=>handleGoToProduct()}>Add Products Now</Button>
                        </InlineStack>
                    </Box>
                 </Box>}
                </EmptyState>
        </Box>
    );
}

export default InitPageContent;
