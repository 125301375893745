import React, { Component } from 'react';
import { Card } from '@shopify/polaris';
import CryptoJS from 'crypto-js'
import './index.css'

/**
 * 调用中台Api参数
 */
const DSM_SECRET_KEY = 'FJMKRGFW1PWYZIZLKHDFQWV5Q01CQGDM6XE432QQ';
const ACCESS_TYPE    = 'DSMAPP';

class AppPartners extends Component {
    constructor(props) {
      super(props);
      this.state = {
        show: (props.show === undefined) ? false : props.show,
        appList: list,  // 推荐App列表数组
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        show: (nextProps.show === undefined) ? false : nextProps.show
      });
    }

    // 进入页面调用接口
    componentDidMount () {
      if (this.props.show) {
        setTimeout(() => {
          this.getDropshipmanAds();
        }, 800);
      }
    }

    // 获取App推荐列表
    getDropshipmanAds = async() => {
      let getDataError = true;

      try {
        // 配置请求头
        let timestamp      = String(new Date().valueOf());
        timestamp          = timestamp.slice(0, 10);
        let DSM_TOKEN      = CryptoJS.MD5(DSM_SECRET_KEY + timestamp);
        
        var setHeaders = new Headers();
        setHeaders.append("ACCESS-TYPE", ACCESS_TYPE);
        setHeaders.append("ACCESS-TOKEN", DSM_TOKEN);
        setHeaders.append("ACCESS-TIME", timestamp);
        setHeaders.append("Content-Type", "application/json");

        await fetch('https://mpapi.channelwill.com/api/v1/middleground/getRecommend',{
          method: 'GET',
          headers: setHeaders
        })
        .then(response => response.json())
        .then(response => {
          if (!response.data) return false;

          let ResData = response.data;
          let data    = [];
          if (ResData.length > 0 && ResData[0]) {
            data = ResData[0].appInfo ? ResData[0].appInfo : [];
          }

          if (data.length > 0) {
            // 数据获取成功
            getDataError = false;

            let list = [];
            for (let i = 0; i < data.length; i++) {
              if (!data[i]) continue;
              list.push(data[i]);
            }

            this.setState({ appList: list });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      } catch (err) {
        console.warn('Error:',err);
      }

      try {
        if (typeof(this.handleEvil(this.props.callBackFun)) === "function") {
          this.props.callBackFun({ result: getDataError });
        }
      } catch (error) {}
    }

    /**
     * 计算表达式的值
     * @param {*} fn 
     * @returns 
     */
    handleEvil (fn ){
      let Fn = Function;
      return new Fn('return ' + fn)();
    }

    render() {
        const {
            state: { show, appList }
        } = this;

        let AppList = '';
        if (appList.length > 0) {
          AppList = appList.map((item, key) => {
              return (
                <div className='Dropshipman_App_Partners_Item' key={key}>
                  <a href={item.link} target='_blank' rel="noopener noreferrer">
                    <div className='App_Box'>
                      <div className='Top'>
                        <img src={item.logo} alt='DropShipMan' />
                        <div className='App_Name'>
                            <div className='title' title={item.title}>{item.title}</div>

                            <div className='star_box'>
                                <span>{(item.score).toFixed(1)}{' ('+item.comments+')'}</span>
                                <img src='/start.png?v=123456' alt='DropShipMan' className='star' />
                                <img src='/start.png?v=123456' alt='DropShipMan' className='star' />
                                <img src='/start.png?v=123456' alt='DropShipMan' className='star' />
                                <img src='/start.png?v=123456' alt='DropShipMan' className='star' />
                                <img src='/start.png?v=123456' alt='DropShipMan' className='star' />
                            </div>
                        </div>
                      </div>

                      <div className='Bottom'>
                        <span title={item.recommend}>
                          {item.recommend}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              )
          });
        }

        const pageContent = (show && AppList) && (
          <Card title="" sectioned>
            <section className='Dropshipman_App_Partners'>
              {AppList}
            </section>
          </Card>
        )
        
        return (
          <>
            {pageContent}
          </>
        );
    }
}
const list = [
  {
      "type": 2,
      "name": "PageFly Page Buider",
      "title": "PageFly Landing Page Builder",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/wL1OeCUcqbtRbYpKFCXBuwTbXWSQeqbgqZJABcZd.png",
      "reviews": "5 stars",
      "recommend": "Get more website conversion with this dropshipping page builder. Check now",
      "comments": 8999,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start FREE",
      "discount": "",
      "link": " https://pagef.ly/16q24x"
  },
  {
      "type": 2,
      "name": "Tapita SEO & Speed Optimizer",
      "title": "Tapita SEO & Speed Optimizer",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/IKGOffol7K31RPhGX6PvvqgynSkxWJO5AxrQd7dX.png",
      "reviews": "5 stars",
      "recommend": "One-click optimize SEO & page speed for both Google and customers for better conversion and sales.",
      "comments": 1336,
      "score": 5,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/google-seo-schema-meta-data?utm_source=dropshipman&utm_medium=referral&utm_campaign=in-app-recommendation"
  },
  {
      "type": 2,
      "name": "Pre-order & Notify Me Timesact",
      "title": "Pre-order & Notify Me Timesact",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/6ResSrCBHkteTI7Primbekt4EGK5YpjMCFlZ6heI.png",
      "reviews": "5.0 reviews over 1000+",
      "recommend": "With Timesact you can transform sold out and coming soon products into preorders and earn 15%+ more",
      "comments": 1042,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/timesact-discount-pre-order?utm_medium=affiliate&utm_source=dropshipman&utm_campaign=dropshipman"
  },
  {
      "type": 2,
      "name": "Avada SEO & Image Optimizer",
      "title": "Avada SEO & Image Optimizer",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/wdrxzsBrP3jUKA0yCWO0wUVFYsrhse6n2SQydQKc.png",
      "reviews": "5 stars",
      "recommend": "Experience instant page loading, smart speed enhancements, and image optimization.",
      "comments": 5916,
      "score": 4.9,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/avada-seo-suite?utm_source=Dropshipman&utm_medium=linkexchange&utm_campaign=seoSuite_partnership&utm_term=inapp"
  },
  {
      "type": 2,
      "name": "Fordeer:Product Labels\u2011Badges",
      "title": "Fordeer:Product Labels\u2011Badges",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/Rf0ZShSKPeIGO24QnV2HqZ8QjZnhu0EKI3cbDkNR.jpg",
      "reviews": "5 stars",
      "recommend": "Draw customers' attention to products and make sales by using eye-catching product badges and labels",
      "comments": 57,
      "score": 4.9,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": " https://apps.shopify.com/product-badges-label-design?utm_source=dropshipman&utm_medium=inapp "
  },
  {
      "type": 2,
      "name": "GemPages1",
      "title": "GemPages",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/rlQZ68AxRoES66Q1kQyaY4kS9vKOippQvgAoG9hC.png",
      "reviews": "5 star Reviews",
      "recommend": "Empower you to create, customize, and optimize your Shopify store pages with ease. Now with AI.",
      "comments": 3667,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://gempages.net/?ref=43vf4Etf"
  },
  {
      "type": 2,
      "name": "Ako Loyalty & Retargeting Ads",
      "title": "Loyalty & Retargeting Ads",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/rPfOpV9BPZXlB2J8RzcjkqghzJH6NPkNeZfx63wM.png",
      "reviews": "4.6 stars over 170",
      "recommend": "Akohub provides the all-in-one marketing app that grows your Shopify stores.",
      "comments": 170,
      "score": 4.6,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/ako-retargeting?utm_campaign=partnership&utm_medium=referral&utm_source=dropshippingman"
  },
  {
      "type": 2,
      "name": "Tapita Landing Page Builder",
      "title": "Tapita Landing Page Builder",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/riLW0QoOXRUsFbDzoGWQWoM9jsgPFJ31ZUbSpryY.jpg",
      "reviews": "5 stars over 700+",
      "recommend": "Easily create Shopify homepage, landing pages, blog pages, about pages, sections, popups, top bars",
      "comments": 0,
      "score": 0,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/tapita-page-builder?utm_source=dropshipman&utm_medium=referral&utm_campaign=in_app"
  },
  {
      "type": 2,
      "name": "Adoriu0441: Upsell & Email Pop Ups",
      "title": "Adoriu0441: Upsell & Email Pop Ups",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/4WXtBVOKQYKkjvda8r8tKMDKiPai8LXHZn48hFXu.png",
      "reviews": "5 stars over 245",
      "recommend": "Boost your AOV with Smart & Easy Product Recommendations & Popups App.",
      "comments": 245,
      "score": 5,
      "is_recommend": 0,
      "call_action": "",
      "discount": "",
      "link": " https://bit.ly/3Rcn0wF"
  },
  {
      "type": 1,
      "name": "Trustoo Product & Ali Reviews",
      "title": "Trustoo",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/JyS5G15es2ZgxFMuNf6aSV9wlAUtAg5DYUZB4oOr.png",
      "reviews": "5 stars",
      "recommend": "The #1 Shopify review app. Turn customers into your best marketers!",
      "comments": 1823,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "Exclusive offer - 70% off",
      "link": "https://share.channelwill.com/app/2164b768bc16a06HZH"
  },
  {
      "type": 2,
      "name": "Foxify - Smart Page Builder",
      "title": "Foxify - Smart Page Builder",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/pVV8Hv0viQt6LcxwHweK6GVVoalTDqfW1f0g65dG.png",
      "reviews": "5 stars over 34",
      "recommend": "Impact sales, costs, & profit all at once with 80+ high-converting designs & advanced analytics",
      "comments": 34,
      "score": 5,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://foxecom.com/products/foxify-shopify-app?utm_source=Dropshipman&utm_medium=In-appPartnerSection&utm_campaign=partnership  "
  },
  {
      "type": 2,
      "name": "FoxKit - All\u2011in\u2011one Sales Boos",
      "title": "FoxKit - All\u2011in\u2011one Sales Boos",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/5GDFSvZKDB987aHMyTAau1Yuv4Nc5VRjkJ5DCZjx.png",
      "reviews": "5 stars over 133",
      "recommend": "Maximize sales with tempting upsells & cross-sells, engaging alerts, & advanced analytics report",
      "comments": 133,
      "score": 5,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://foxecom.com/products/foxkit-shopify-app?utm_source=Dropshipman&utm_medium=In-appPartnerSection&utm_campaign=partnership"
  },
  {
      "type": 1,
      "name": "Channelwill Shopmate",
      "title": "Channelwill Shopmate",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/ARpxJKESwsLYSlTxZEeq04WgUT2MJKitM4SUzLtu.png",
      "reviews": "5 Stars over 1000",
      "recommend": "Apps Hurting Sales? Let our experts diagnose app overload issues and prescribe solutions Free today!",
      "comments": 1687,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Free Level Up Todayuff01",
      "discount": "",
      "link": "https://www.channelwill.com/pricing?utm_source=DSM&utm_medium=referpartner&utm_campaign=AIOMvp"
  },
  {
      "type": 2,
      "name": "Tepo Product Options Variants",
      "title": "Tepo Product Options Variants",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/0uKqO3pafHfQMNAx7K7tdKEYKtKLasALL3bnC7U2.png",
      "reviews": "4.9 stars over 212",
      "recommend": "Expand your product choices and increase sales by offering more options with add-on price",
      "comments": 212,
      "score": 4.9,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/teeinblue-product-options?utm_source=dropshipman&utm_medium=in-app&utm_campaign=partnership"
  },
  {
      "type": 1,
      "name": "Return",
      "title": "Parcel Panel Returns &Exchange",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/dn0qWIf67dNfy9CQllCtLd0s6G9vcZosiW6KTSSn.png",
      "reviews": "4.9 over 130",
      "recommend": "Make returns and exchanges hassle-free to reduce costs, retain customers and recapture revenue.",
      "comments": 130,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/parcel-panel-returns-exchanges?utm_source=DSM&utm_medium=inapp&utm_campaign=partner"
  },
  {
      "type": 2,
      "name": "Fastr: SMS, Email & Web Push",
      "title": "Fastr: SMS, Email & Web Push",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/K2NlVkcd8ST4d1ZGnwZLW9dLkdKq2JfX2j8fSGs8.png",
      "reviews": "4.6 stars",
      "recommend": "#1 abandoned cart recovery app: Send SMS, emails & push notification and boost your revenue!",
      "comments": 121,
      "score": 4.6,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/fastr?utm_campaign=partner&utm_medium=inapp&utm_source=dropshipman "
  },
  {
      "type": 1,
      "name": "Parcel Panel Order Tracking",
      "title": "Parcel Panel Order Tracking",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/ZkmRjzZIMQapIHgNpfcPxEKY4KzH0e99MqhZHcJK.png",
      "reviews": "5 Star",
      "recommend": 'Get free from answering "Where is my order?"',
      "comments": 2182,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://parcelpanel.com?ref=eW9qhcDe&DSM_Gifts"
  },
  {
      "type": 2,
      "name": "Boost AI Search & Discovery",
      "title": "Boost AI Search & Discovery",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/VKtIMczjfyecq0EpUHdaMdhRKOYXVj3NXC7UVdw3.png",
      "reviews": "5 stars",
      "recommend": "Boost AI Search & Discovery is a complete product search and discovery suite committed",
      "comments": 1726,
      "score": 4.8,
      "is_recommend": 0,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/product-filter-search?&utm_source=DropShipMan&utm_medium=inapp_banner&utm_campaign=Partnership23"
  },
  {
      "type": 2,
      "name": "ShipZip: Shipping & Delivery",
      "title": "ShipZip: Shipping & Delivery",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/W5SYflbI6CIBmsIYfkWLYxrmMw7uTwtHvbunPb1o.png",
      "reviews": "5 stars",
      "recommend": "Create advance shipping rules by zip code, products, cart, etc. with local delivery date, store pick",
      "comments": 286,
      "score": 5,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/shipping-based-on-zipcode?utm_source=partnerpage&utm_medium=dropshipman&utm_campaign=partnership"
  },
  {
      "type": 2,
      "name": "PreOrder Now PreSale \u2011 Kaktus",
      "title": "PreOrder Now PreSale \u2011 Kaktus",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/b8J2pGXkUIhf7Zlv4xC51xXvNCludXryle4QLlDu.jpg",
      "reviews": "4.9 star over 538+",
      "recommend": "Boost sales: Pre-book or pre-order products for out-of-stock items.Discount code: DROPSHIPMAN50",
      "comments": 538,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/kaktus-preorder?utm_medium=affiliate&utm_source=Dropshipman "
  },
  {
      "type": 1,
      "name": "RecomSale: Affiliate Marketing",
      "title": "RecomSale: Affiliate Marketing",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/a5VIgqXjvJJf7V8rALcclAtXncL3lDsupxAl94k1.png",
      "reviews": "5 stars",
      "recommend": "Top-notch affiliate program, referral program, ambassador program and influencer marketing app",
      "comments": 400,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "Exclusive offer - 50% off",
      "link": "https://apps.shopify.com/recomsale-affiliate-marketing?&utm_source=dsm&utm_medium=marketing&utm_campaign=marketing"
  },
  {
      "type": 2,
      "name": "SearchPie: SEO Booster & Speed",
      "title": "SearchPie: SEO Booster & Speed",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/S41zF9j5RWW07UZGnKg4upx7m8yLyV8KT3MkRxe9.png",
      "reviews": "5 stars over 3423",
      "recommend": "Top Google rankings, high speed, & strong online local visibility to bring more website traffic. 24/",
      "comments": 3423,
      "score": 4.9,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://partners.secomapp.com/apps/20off/searchpie/Dropshipman"
  },
  {
      "type": 2,
      "name": "Growave: Loyalty & Wishlist",
      "title": "Growave: Loyalty & Wishlist",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/g1QMGmZ1LVLpgPn4n3f8jC8kM0OU8bGiajlDasO8.png",
      "reviews": "5 stars",
      "recommend": "Boost Retention & Sales with Your All-In-One Marketing App.",
      "comments": 2003,
      "score": 4.8,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://admin.growave.io/trackRedirectInstall?utm_source=partner&utm_medium=in-app&utm_campaign=dropshipman"
  },
  {
      "type": 2,
      "name": "Fera",
      "title": "Fera",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/sdw5hS5E0TeLpQcHcsQMRoZorwtFfCPTDLUuRdNv.png",
      "reviews": "4.9 stars over 5,000+",
      "recommend": "Show beautiful, simple product reviews with Fera. Reviews help visitors trust your store and can boo",
      "comments": 5229,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": " www.fera.ai/?r=dropshipman"
  },
  {
      "type": 2,
      "name": "EasySell COD Form & Upsells",
      "title": "EasySell COD Form & Upsells",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/FHB9aDNuFmjBwSmHQUcoGyrRJNl113pDVTScEjeP.png",
      "reviews": "EasySell",
      "recommend": "Create a 1-click COD form and boost your conversion rate and AOV with upsells and quantity offers.",
      "comments": 706,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "Free Plan available",
      "link": "https://apps.shopify.com/easy-order-form?utm_source=Dropshipman&utm_medium=in-app&utm_campaign=easysell-partnership"
  },
  {
      "type": 2,
      "name": "Sales Pop up & Social Proof",
      "title": "Sales Pop up & Social Proof",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/4Kt3zzxEScmtNTgsfU4qn7nBp8sZOAplxDcX5IJW.png",
      "reviews": "4.9 stars over 1000+",
      "recommend": "The ultimate toolkit to convert visitors to customers with pop-up, social proof, urgency and FOMO no",
      "comments": 1009,
      "score": 0,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/sale-kit?utm_source=shopidevs&utm_medium=banner&utm_campaign=partnership"
  },
  {
      "type": 2,
      "name": "Koala Inspector",
      "title": "Koala Inspector",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/ujT5jHxBCqdhdbwZfIuXUX5k4g1MDxQPtBtI2GU6.png",
      "reviews": "5 stars over 163+",
      "recommend": "The Best E-commerce Tool: Spy Tool For Dropshipping & Shopify Secrets Revealed",
      "comments": 163,
      "score": 4.3,
      "is_recommend": 1,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://koala-apps.io/"
  },
  {
      "type": 2,
      "name": "Hextom: Translate My Store",
      "title": "Hextom: Translate My Store",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/b3gYKDpgNhzPawG64Io1B02VRjxA34oRT2P2rFZR.png",
      "reviews": "5 stars",
      "recommend": "Currency conversion and language translation for your store to reach global market, boost BFCM sales",
      "comments": 1417,
      "score": 4.8,
      "is_recommend": 1,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/translate-my-store?utm_source=dropshipman&utm_medium=in_app&utm_campaign=partnership  "
  },
  {
      "type": 2,
      "name": "Vitals: Reviews, Upsells & 40+",
      "title": "Vitals: Reviews, Upsells & 40+",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/eRANGo8Vqk8e5jXgbgnwVnYg0ULfgZ5tYU3EpepT.png",
      "reviews": "5 stars 5600+",
      "recommend": "Collect reviews, offer upsells, record visits, and more.",
      "comments": 5650,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://vitals.co/shopify/8739793?utm_source=dropshipman&utm_medium=referral&utm_campaign=partnerships"
  },
  {
      "type": 2,
      "name": "Rivyo Product Reviews & QA",
      "title": "Rivyo Product Reviews & QA",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/EnrRDisJ6TE3XPpC1QXQHiVXpmkI6xYxOznZ6LEb.png",
      "reviews": "Product Reviews, Photos, Amazo",
      "recommend": "Product Reviews, Photos, Amazo",
      "comments": 1976,
      "score": 0,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": " https://bit.ly/3QOmSn4"
  },
  {
      "type": 2,
      "name": "ToastiBar \u2011 Sales Popup",
      "title": "ToastiBar \u2011 Sales Popup",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/BRQnAmGdJCNRfzqDfCM0aGUPE8waKq8TGIiMbU9g.png",
      "reviews": "4.8 stars over 1600",
      "recommend": "Boost Sales with Recent Sales, Add To Cart, Reviews, Visitors Notifications & more...",
      "comments": 0,
      "score": 0,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://makeprosimp.com/partnership/dropshipman"
  },
  {
      "type": 2,
      "name": "UpPromote: Affiliate&Referral1",
      "title": "UpPromote: Affiliate&Referral",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/dD7lBroZ9vTbd8OFuH7tYPYWq2WP8moAt3g58ddG.png",
      "reviews": "4.9 stars over 2700+",
      "recommend": "UpPromote is the top recommended affiliate marketing/referral app on Shopify App Store. With this al",
      "comments": 2763,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://partners.secomapp.com/apps/affiliate/DSM_DROPSHIPPING_APP"
  },
  {
      "type": 2,
      "name": "BSS: Product Labels & Badges",
      "title": "BSS: Product Labels & Badges",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/N308A4eWieMBsPjZxGOmIWN46WgHXQinFnNxrDus.png",
      "reviews": "4.9 stars over 898+",
      "recommend": "Get customers' attention with labels/badges/banners and promote your products to boost your sales",
      "comments": 898,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/product-labels-by-bss?surface_detail=in-app-banner&surface_type=DSM"
  },
  {
      "type": 2,
      "name": "Smart SEO",
      "title": "Smart SEO",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/TmlIJsmOVN6UpQ42s8bwLnPF8xsoJkL4YPTxoxvN.png",
      "reviews": "Drive traffic and sales",
      "recommend": "Automate the daunting SEO tasks, improve rankings, and drive more organic traffic.",
      "comments": 1202,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": " https://apps.shopify.com/smart-seo?utm_source=dropshipman&utm_medium=recommended+apps&utm_campaign=partnerships"
  },
  {
      "type": 2,
      "name": "EComposer Landing Page Builder",
      "title": "EComposer Landing Page Builder",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/eGTQ5YDSKyFviEP67FMpisw04YZfnHOwp9kgW2CZ.png",
      "reviews": "5.0",
      "recommend": "Build any store pages & sections with drag drop editor. Free install.",
      "comments": 1188,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/ecomposer?utm_source=dropshipman&utm_medium=in-app-partner-section"
  },
  {
      "type": 1,
      "name": "Back in Stock - Restock Alerts",
      "title": "Back in Stock - Restock Alerts",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/GHWXNksIVQ4OeVSGdJ6qHc7t6S3lODLAlqPo8W4H.png",
      "reviews": "5 star reviews",
      "recommend": "Recover your empty-handed customers by sending them back-in-stock emails.",
      "comments": 349,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/email-1?utm_source=dsm&utm_medium=marketing&utm_campaign=marketing"
  },
  {
      "type": 2,
      "name": "Cart X - Post Purchase Upsell",
      "title": "Cart X - Post Purchase Upsell",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/sVdvUvcAp6AUeRKM0psExp0fe2ekYnH60eMD29qd.png",
      "reviews": "5 star over 6",
      "recommend": "The best Upsell app. 3 in 1: Boost your profit with One Click Upsell",
      "comments": 6,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Learn More",
      "discount": "",
      "link": " https://apps.shopify.com/cart-x?utm_source=dropshipman&utm_medium=inapp&utm_campaign=partner"
  },
  {
      "type": 1,
      "name": "Willdesk\u2011Live Chat, Helpdesk",
      "title": "Willdesk\u2011Live Chat, Helpdesk",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/zUlMW5irJ2VWqqU3kl5HDhXvXIi2DGgJetbXqJiX.png",
      "reviews": "5 stars",
      "recommend": "Unify Live Chat, Email, Messenger, Instagram and multi-store, order tracking in one view.",
      "comments": 590,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://share.channelwill.com/app/1264b77a7772ef8uY2"
  },
  {
      "type": 2,
      "name": "BOGOS: Free gift & Buy X Get Y",
      "title": "BOGOS: Free gift & Buy X Get Y",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/F9ekmwkA8Kurt77xxVikgHyfJ8vIVj4vTmuhHfp8.png",
      "reviews": "BOGOS: Free gift & Buy X Get Y",
      "recommend": "Your fully-customized gifting solution: free gift, bogo, gift with purchase. Auto add gift to cart.",
      "comments": 0,
      "score": 0,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": " https://apps.shopify.com/freegifts?utm_source=dsm&utm_medium=partner_section "
  },
  {
      "type": 1,
      "name": "Loloyal Loyalty Program",
      "title": "Loyalty, Rewards & Referrals",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/WphMSnOy1S7h4acc1CQuJir1hSShGCm89mYhOOdg.png",
      "reviews": "5 stars over 46",
      "recommend": "Loloyal: One click grow your sales with loyalty rewards & referrals program that actually work.",
      "comments": 500,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://share.channelwill.com/app/3364b75d51e6aa5vlM"
  },
  {
      "type": 2,
      "name": "Appstle Memberships",
      "title": "Appstle Memberships",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/KmESA5e8843YL6h0hCPAo76iK51aJiyaePg7pYBW.jpg",
      "reviews": "5 stars",
      "recommend": "Discover the most powerful memberships",
      "comments": 0,
      "score": 0,
      "is_recommend": 0,
      "call_action": "",
      "discount": "",
      "link": " https://apps.shopify.com/appstle-memberships?utm_source=dropshipman"
  },
  {
      "type": 1,
      "name": "SEOAnt \\u2011 404 Link Redirect",
      "title": "SEOAnt \\u2011 404 Link Redirect",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/JnvlXYRjMGBGQUdgcw32uoFoFOSxgOLvLfTEb8QF.png",
      "reviews": "5 stars over 102",
      "recommend": "SEO/Url Redirect, Auto-Fix 404 Errors, Bulk 301 Redirect",
      "comments": 128,
      "score": 4.6,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "20% OFF",
      "link": "https://apps.shopify.com/broken-link-manager"
  },
  {
      "type": 1,
      "name": "EcomSend Popups & Email Pop Up",
      "title": "EcomSend Popups & Email Pop Up",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/LyqkHwsIZNlt2K8HJl5JFs6oHKelvUrFPy88SPJE.png",
      "reviews": "5 Stars",
      "recommend": "Grow an email marketing list with opt-in or exit intent popups to boost your sales.",
      "comments": 4396,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://apps.shopify.com/ecomsend?utm_campaign=marketing&utm_medium=marketing&utm_source=dsm"
  },
  {
      "type": 1,
      "name": "TrackingMore \\u2011 Order Tracking",
      "title": "TrackingMore \\u2011 Order Tracking",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/Acs4WXBHiWAH3Aet1Xlzp02HztQO1AxFDwLM7qR3.png",
      "reviews": "5 stars",
      "recommend": "Get tracking info from most couriers worldwide with unmatched accuracy and speed.",
      "comments": 203,
      "score": 4.8,
      "is_recommend": 1,
      "call_action": "Start free",
      "discount": "",
      "link": "https://share.channelwill.com/app/1564b77ae8dc7a3w23"
  },
  {
      "type": 1,
      "name": "SEOAnt \\u2011 AI SEO Optimizer",
      "title": "SEOAnt \\u2011 AI SEO Optimizer",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/j2u8qJDkHiQ0cbI1vN051pCbdoL93dAfOs5wnUxQ.png",
      "reviews": "5 star Reviews",
      "recommend": "SEO booster with speed, Chatgpt AI writer, SEO image optimizer",
      "comments": 905,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/seo-master?utm_source=DSM&utm_medium=affiliate&utm_campaign=share&utm_content=29TjthsO4dS"
  },
  {
      "type": 2,
      "name": "Appstle Subscriptions",
      "title": "Appstle Subscriptions",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/TDjWr8tL1tRdvHQhaygMED2dxYIfVGsSSpPvESCG.jpg",
      "reviews": "4.9 stars over 2300",
      "recommend": "Discover the most powerful memberships",
      "comments": 0,
      "score": 0,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/subscriptions-by-appstle?utm_source=dropshipman"
  },
  {
      "type": 1,
      "name": "Etranslate",
      "title": "ET Language Translate\\u2011Currency",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/DnOP3hAnv20s15bGuNAkNdPDS8XTIthOuAWFNZka.png",
      "reviews": "5 stars over 750",
      "recommend": "Improve shopping experience with localozation.",
      "comments": 965,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://apps.shopify.com/localiser?from=DSM_gifts&utm_source=marketing&utm_medium=gifts&utm_campaign=marketing"
  },
  {
      "type": 1,
      "name": "Shipping Protection",
      "title": "Captain Shipping Protection",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/8OcZvEmnSvdtjI2uOhonky6NL3T5YIyUgxWKs0Bz.jpg",
      "reviews": "5 Stars",
      "recommend": "Boost revenue with your shipping protection solution, reduce the risk of damage, loss, and theft.",
      "comments": 90,
      "score": 5,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/shipwill-shipping-insurance?from=DSM_gifts&utm_source=DSM&utm_medium=gifts&utm_campaign=referpartner"
  },
  {
      "type": 2,
      "name": "Translate Lab1",
      "title": "Translate Lab",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/DYl9rX4pyPVK6HqKETtqO4lEFDDK9kaTsKKUnBey.png",
      "reviews": "4.9 Star over 860",
      "recommend": "Translate your store into multiple languages, reach global markets, drive traffic, and boost sales",
      "comments": 910,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start Free",
      "discount": "",
      "link": "https://apps.shopify.com/content-translation?utm_source=dropshipman&utm_medium=in+app&utm_campaign=partnerships"
  },
  {
      "type": 2,
      "name": "Appstle Loyalty",
      "title": "Appstle Loyalty",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/obWdGbPPMemGxXOlETrZLiDZP7iS3rnvdoyXQrJ0.jpg",
      "reviews": "5 stars",
      "recommend": "Appstle Loyalty",
      "comments": 0,
      "score": 0,
      "is_recommend": 0,
      "call_action": "",
      "discount": "",
      "link": "https://apps.shopify.com/appstle-loyalty?utm_source=dropshipman"
  },
  {
      "type": 1,
      "name": "SEOAnt \u2011 Sticky Add To Cart",
      "title": "SEOAnt \u2011 Sticky Add To Cart",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/mJz7ChHOZa8caMO4DTkYjUi1zzoYuW5pOJ7hZhoi.png",
      "reviews": "5 stars over 150",
      "recommend": "Sticky Cart, Quick Buy, Fast Checkout, Cart Slider, Buy Now",
      "comments": 301,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://apps.shopify.com/sticky-cart-and-sticky-add-to-cart-button?from=dsm_gifts&utm_source=dsm&utm_medium=gifts&utm_campaign=referpartner"
  },
  {
      "type": 1,
      "name": "SEOAnt \u2011 Trust Badges",
      "title": "SEOAnt \u2011 Trust Badges & Icon",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/AOs73bcIbg3BrjzOvzfpAya9Vhn8BwLxwlCo4iov.png",
      "reviews": "5 stars",
      "recommend": "Security, Checkout & Payment Trust Badges/Icons - All Free",
      "comments": 889,
      "score": 4.9,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://apps.shopify.com/trust-badge-by-giraffly?from=dsm_gifts&utm_source=dsm&utm_medium=gifts&utm_campaign=referpartner"
  },
  {
      "type": 1,
      "name": "SEOAnt \u2011 Image Optimizer&Speed",
      "title": "SEOAnt \u2011 Image Optimizer&Speed",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/NZJKKEHwEfIDIkYbEfOTb5jg34gyN7CZODBhfwtt.png",
      "reviews": "5 stars over 80",
      "recommend": "Optimize images without quality decreasing",
      "comments": 132,
      "score": 4.8,
      "is_recommend": 0,
      "call_action": "Start free",
      "discount": "",
      "link": "https://apps.shopify.com/page-speed-boost?utm_source=dsm&utm_medium=affiliate&utm_campaign=share&utm_content=28RTUlOpBy0"
  },
  {
      "type": 1,
      "name": "Printing.one: Print on Demand",
      "title": "Printing.one: Print on Demand",
      "logo": "https://cw-middleground.oss-us-west-1.aliyuncs.com/xkhGAg3WPEQUUeXP3uT6w6YLLyEUe85AeDrJzdZk.png",
      "reviews": "5 stars",
      "recommend": "Design and sell custom products online with print-on-demand dropshipping.",
      "comments": 0,
      "score": 0,
      "is_recommend": 0,
      "call_action": "Start free!",
      "discount": "",
      "link": "https://apps.shopify.com/printing-one-print-on-demand?from=DSM_gifts&utm_source=DSM&utm_medium=gifts&utm_campaign=referpartner"
  }
]
export default AppPartners;