import { Modal } from '@shopify/polaris';
import React from 'react';
const BlackRaffleRuleModal = ({open,handleOff})=> {
    return (
        <div>
        <Modal
            size='small'
            open={open}
            onClose={() => handleOff()}>
                <div className='raffle_rules_modal' style={{margin:'40px'}}>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        <img src="/blackFriday/raffle_not_emo.png" alt="Dropshiman" />
                        <span style={{fontWeight:'600',fontSize:'20px',padding:'10px 0'}}>Sorry, you don’t win</span>
                    </div>
                    <div>
                    Unfortunately, you didn't win this time. Try again to improve your chances!
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default BlackRaffleRuleModal;
