import React, { Component } from 'react';

import FrameComponents from "../../FrameComponents"; 

class dashboard extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-settings-shipping';
  }
 
  render() {
   
    return (
      
      <div>
        <FrameComponents name="settings_shipping" /> 
      </div>
    );
    
  }
}

export default dashboard;
