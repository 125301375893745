import CryptoJS from 'crypto-js'
import AppBridge from './appBridge';
import { History } from '@shopify/app-bridge/actions';
import { store } from '../store';
import { changeBannerData } from '../slice';

const AesKey = '4f3d5e90d5a6fbf5'; // AES 秘钥
const AesVi = '0fd09fa03ec122f7'; // AES 秘钥偏移量

/**
 * CryptoJS加密
 */
var Encrypt = function (data) { //加密

    let encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(AesKey), {
        iv: CryptoJS.enc.Utf8.parse(AesVi),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
}

/**
 * CryptoJS解密
 */
var Decrypt = function (data) {//解密

    let decrypt = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(AesKey), {
        iv: CryptoJS.enc.Utf8.parse(AesVi),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypt.toString(CryptoJS.enc.Utf8);
}

var getSign = function (domain, timeStr) {//解密

    let sign = Encrypt(domain + '-' + timeStr);

    return sign;
}
// 路由监听
let routeListen = (listenUrl) => {
    let curUrl = window.location.pathname;
    // console.log(listenUrl,curUrl);
    
    // console.log(listenUrl,['/admin/customService'].includes(listenUrl),['/admin/customService/'].includes(listenUrl));
    if(listenUrl === curUrl) {
        return false;
    } else {
        let urlFormat
        if(listenUrl.includes('/admin/dashboard')) {
            urlFormat = '/admin/dashboard'
        } else if(['/admin/find_products'].includes(listenUrl)) {
            urlFormat = '/admin/find_products'
        } else if(listenUrl.includes('/admin/find_products/new-products')) {
            urlFormat = listenUrl
        }else if (listenUrl.includes('/admin/find_products/trendy-products/trendy')) {
            urlFormat = listenUrl
        }else if (listenUrl.includes('/admin/find_products/recommended-category/winning')) {
            urlFormat = listenUrl
        }else if (listenUrl.includes('/admin/find_products/product-info')) {
            urlFormat = listenUrl
        }else if (listenUrl.includes('/admin/find_products/trendy/product-info')) {
            urlFormat = listenUrl
        } else if(listenUrl.includes('/admin/find_products/recommended-category')) {
            urlFormat = listenUrl
        } else if(listenUrl.includes('/admin/find_products?')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/sourcing')) {
            urlFormat = '/admin/sourcing'
        } else if(listenUrl.includes('/admin/import-list')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/order_list')) {
            urlFormat = '/admin/order_list'
        }else if(['/admin/customService'].includes(listenUrl) || ['/admin/customService/'].includes(listenUrl)) {
            urlFormat = '/admin/customService'
        }else if(listenUrl.includes('/admin/customService/purchase')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/customService/inventory')) {
            urlFormat = '/admin/customService/inventory'
        }else if(listenUrl.includes('/admin/customService/pur_inventory')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/customService/history_log')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/customService/invent_history_log')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/customService/custom-packaging')) {
            urlFormat = '/admin/customService/custom-packaging'
        }else if(listenUrl.includes('/admin/customService/custom-packaging/packaging-info')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/customService/inventory')) {
            urlFormat = '/admin/customService/inventory'
        }else if(listenUrl.includes('/admin/customService/print-on-demand')) {
            urlFormat = '/admin/customService/print-on-demand?source=DSM'
        }else if(listenUrl.includes('/admin/customService/landing-page-build')) {
            urlFormat = '/admin/customService/landing-page-build'
        }else if(listenUrl.includes('/admin/customService/calculate-shipping')) {
            urlFormat = '/admin/customService/calculate-shipping'
        // } else if(['/admin/websiteOptimization'].includes(listenUrl)) {
        //     urlFormat = '/admin/websiteOptimization'
        // }else if(listenUrl.includes('/admin/websiteOptimization/website-build')) {
        //     urlFormat = '/admin/websiteOptimization/website-build'
        // }else if(listenUrl.includes('/admin/websiteOptimization/website-speed')) {
        //     urlFormat = '/admin/websiteOptimization/website-speed'
        }else if(['/admin/partnerShip'].includes(listenUrl)) {
            urlFormat = '/admin/partnerShip'
        }else if(listenUrl.includes('/admin/partnerShip/partners')) {
            urlFormat = '/admin/partnerShip/partners'
        }else if(listenUrl.includes('/admin/partnerShip/affiliate')) {
            urlFormat = '/admin/partnerShip/affiliate'
        // }else if(listenUrl.includes('/admin/partnerShip/feature-requests')) {
        //     urlFormat = '/admin/partnerShip/feature-requests'
        }else if(listenUrl.includes('/admin/subscribe')) {
            urlFormat = '/admin/subscribe'
        }else if(listenUrl.includes('/admin/recommended-apps')) {
            urlFormat = '/admin/recommended-apps'
        }else if(['/admin/settings'].includes(listenUrl)) {
            urlFormat = '/admin/settings/profile'
        } else if(listenUrl.includes('/admin/settings/profile')) {
            urlFormat = '/admin/settings/profile'
        }else if(listenUrl.includes('/admin/settings/notification')) {
            urlFormat = '/admin/settings/notification'
        }else if(listenUrl.includes('/admin/settings/import')) {
            urlFormat = '/admin/settings/import'
        }else if(listenUrl.includes('/admin/settings/price')) {
            urlFormat = '/admin/settings/price'
        }else if(listenUrl.includes('/admin/settings/config')) {
            urlFormat = '/admin/settings/config'
        }else if(listenUrl.includes('/admin/settings/address')) {
            urlFormat = '/admin/settings/address'
        }else if(listenUrl.includes('/admin/settings/payment')) {
            urlFormat = '/admin/settings/payment'
        }else if(listenUrl.includes('/admin/settings/billing')) {
            urlFormat = '/admin/settings/billing'
        }else if(listenUrl.includes('/admin/settings/language')) {
            urlFormat = '/admin/settings/language'
        }else if(listenUrl.includes('/admin/pricing')) {
            urlFormat = '/admin/pricing'
        }else if(listenUrl.includes('/admin/wallet')) {
            urlFormat = '/admin/wallet'
        } else if(listenUrl.includes('/admin/coupon')) {
            urlFormat = '/admin/coupon'
        }else if(listenUrl.includes('/admin/shopping_cart')) {
            urlFormat = '/admin/shopping_cart'
        }else if(listenUrl.includes('/admin/messages')) {
            urlFormat = '/admin/messages'
        }else if(listenUrl.includes('/admin/points')) {
            urlFormat = '/admin/points'
        }else if(listenUrl.includes('/admin/points_log')) {
            urlFormat = '/admin/points_log'
        }else if(listenUrl.includes('/admin/points_coupon')) {
            urlFormat = '/admin/points_coupon'
        }else if(listenUrl.includes('/admin/referrals_log')) {
            urlFormat = '/admin/referrals_log'
        }else if(listenUrl.includes('/admin/points_purchase')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/black_points_purchase')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/black-friday')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/redeem_products')) {
            urlFormat = '/admin/redeem_products'
        }else if(listenUrl.includes('/admin/redeem_products/product-info')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/product_selection')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/product_selection/product-info')) {
            urlFormat = listenUrl
        }else if(listenUrl.includes('/admin/product_selection/sourcing-detail')) {
            urlFormat = listenUrl
        }else  if(listenUrl.includes('/admin/product_management')) {
            urlFormat = listenUrl
        }else  if(listenUrl.includes('/admin/product_management/product/')) {
            urlFormat = listenUrl
        } else {    
            urlFormat = '/admin/dashboard'
        }
        
        const history = AppBridge.app ? History.create(AppBridge.app) : undefined
        if(history !== undefined) {
          history.dispatch(History.Action.PUSH, urlFormat)
        }
    }
}
// 路由跳转
let routeJump1 = (history,to,state=undefined, query=undefined) => {
    let curUrl = window.location.pathname;
    if(to === curUrl && state === undefined) {
        return false
    } else {
        let params = {
            pathname: to,
        }
        if(state !== undefined) {
            params['state'] = state
        }
        if(query !== undefined) {
            params['query'] = query
        }
        history.push(to,state)
    }
    store.dispatch(changeBannerData({bannerData:{
        show:false,
    }}));
}
let routeJump = (_this, to, state=undefined, query=undefined) => {
    let curUrl = window.location.pathname;
    if(to === curUrl) {
        return false
    } else {
        let params = {
            pathname: to,
        }
        if(state !== undefined) {
            params['state'] = state
        }
        if(query !== undefined) {
            params['query'] = query
        }
        store.dispatch(changeBannerData({bannerData:{
            show:false,
          }}));
        _this.props.history.push(to)
    }
}
/**
 * 存储localStorage
 */
var setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    try {
        window.localStorage.setItem(name, content);
    } catch (error) {
        console.warn(error);
    }
}

/**
 * 获取localStorage
 */
var getStore = name => {
    if (!name) return;
    try {
        return window.localStorage.getItem(name);
    } catch (error) {
        console.warn(error);
    }
}

/**
 * 删除localStorage
 */
var removeStore = name => {
    if (!name) return;
    try {
        window.localStorage.removeItem(name);
    } catch (error) {
        console.warn(error);
    }
}

/**
 * bytes --> mb
 * @param {*} bytes 
 * @returns 
 */
var sizeFilter = (bytes) => {
    let size = "";
    size = (bytes / (1024 * 1024)).toFixed(2) + "MB"
    // size=size.replace("MB","")
    return parseFloat(size);
}

/**
 * 防止频繁发起api请求
 * @param {*} fn 传入的fun
 * @param {*} delay 延时time
 * @param {*} immediate 是否立即执行
 * @param {*} resultCallback callBack
 * @returns 
 */
var debounce = (fn, delay = 500, immediate = false, resultCallback) => {

    // 1.定义一个定时器, 保存上一次的定时器
    let timer = null
    let isInvoke = false

    const _debounce = function (...args) {
        return new Promise((resolve, reject) => {
            // 取消上一次的定时器
            if (timer) clearTimeout(timer)

            // 判断是否需要立即执行
            if (immediate && !isInvoke) {
                const result = fn.apply(this, args)
                if (resultCallback) resultCallback(result)
                resolve(result)
                isInvoke = true
            } else {
                // 延迟执行
                timer = setTimeout(() => {
                    // 外部传入的真正要执行的函数
                    const result = fn.apply(this, args)
                    if (resultCallback) resultCallback(result)
                    resolve(result)
                    isInvoke = false
                    timer = null
                }, delay)
            }
        })
    }
    return _debounce
}

/**
 * 获取图片: base64
 * @param {String} img 图片标签（<img src='' />）
 * @returns
 */
var getBase64Image = (img) => {
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    let dataURL = canvas.toDataURL("image/" + ext);
    return dataURL;
}

const exportedObject  = {
    Encrypt:Encrypt,
    Decrypt:Decrypt,
    getSign:getSign,
    setStore:setStore,
    getStore:getStore,
    removeStore:removeStore,
    sizeFilter:sizeFilter,
    debounce:debounce,
    getBase64Image:getBase64Image,
    routeListen:routeListen,
    routeJump1:routeJump1,
    routeJump:routeJump,
}
export default exportedObject;