import {
  Card,
  Collapsible,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonPage,
  Toast,
  Button,
  BlockStack,
  Icon
} from "@shopify/polaris";
import {
  DuplicateIcon
} from '@shopify/polaris-icons';
import React, { useState, useEffect,useMemo } from "react";
// import defalutData from "./subData";
import { reqGetAffiliateData } from "../../../../api";
import utils from '../../../../utils/utils'
import intl from "../../../../i18n/intl";
import { useSelector} from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AppBridge from "../../../../utils/appBridge";
// const copyIcon = <svg t="1673518699666" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13437" width="20" height="20">
//   <path d="M394.666667 106.666667h448a74.666667 74.666667 0 0 1 74.666666 74.666666v448a74.666667 74.666667 0 0 1-74.666666 74.666667H394.666667a74.666667 74.666667 0 0 1-74.666667-74.666667V181.333333a74.666667 74.666667 0 0 1 74.666667-74.666666z m0 64a10.666667 10.666667 0 0 0-10.666667 10.666666v448a10.666667 10.666667 0 0 0 10.666667 10.666667h448a10.666667 10.666667 0 0 0 10.666666-10.666667V181.333333a10.666667 10.666667 0 0 0-10.666666-10.666666H394.666667z m245.333333 597.333333a32 32 0 0 1 64 0v74.666667a74.666667 74.666667 0 0 1-74.666667 74.666666H181.333333a74.666667 74.666667 0 0 1-74.666666-74.666666V394.666667a74.666667 74.666667 0 0 1 74.666666-74.666667h74.666667a32 32 0 0 1 0 64h-74.666667a10.666667 10.666667 0 0 0-10.666666 10.666667v448a10.666667 10.666667 0 0 0 10.666666 10.666666h448a10.666667 10.666667 0 0 0 10.666667-10.666666v-74.666667z" fill="#1296db" p-id="13438"></path></svg>

const SubscriptionPaidClass = () => {
  const [loading, setLoading] = useState(false);

  const language = useSelector(state => state.languageReducer.language);
  const defalutData = useMemo(()=>{
    let data = {
      faq: {
        title: intl.get("FAQ"),
        list1: [
          {
            title: intl.get("Under what circumstances can I receive commission?"),
            slide_down: "/sub/slide_down.png",
            slide_up: "/sub/slide_up.png",
            show:false,
            desc: intl.get("You'll earn a 30% commission on the subscription fee when someone installs Dropshipman through your shared link, subscribes to a plan, and completes the 7-day trial without cancelling."),
          },
          {
            title: intl.get("How long will it take for me to receive the commission?"),
            slide_down: "/sub/slide_down.png",
            slide_up: "/sub/slide_up.png",
            show:false,
            desc: intl.get("Any commissions earned during the month will be paid on the 13th - 15th of next month. And we only process the payout if the accumulated commission is $25 or above."),
          },
        ],
        list2: [
          {
            title: intl.get("Is it possible to permanently receive a 30% commission?"),
            slide_down: "/sub/slide_down.png",
            slide_up: "/sub/slide_up.png",
            show:false,
            desc: intl.get("The 30% commission is permanent, which means that as long as users who installed Dropshipman through your link continue to subscribe to Dropshipman's plans, you will continue to receive a 30% commission."),
          },
          {
            title: intl.get("How can I withdraw my commission?"),
            slide_down: "/sub/slide_down.png",
            slide_up: "/sub/slide_up.png",
            show:false,
            desc: intl.get("Each month, we will create a statement, and if your total commission reaches $25, we will automatically transfer the commission to your provided PayPal account without requiring you to request a withdrawal."),
          },
        ],
      },
    }
    if (language) {
      data = {
        faq: {
          title: intl.get("FAQ"),
          list1: [
            {
              title: intl.get("Under what circumstances can I receive commission?"),
              slide_down: "/sub/slide_down.png",
              slide_up: "/sub/slide_up.png",
              show:false,
              desc: intl.get("You'll earn a 30% commission on the subscription fee when someone installs Dropshipman through your shared link, subscribes to a plan, and completes the 7-day trial without cancelling."),
            },
            {
              title: intl.get("How long will it take for me to receive the commission?"),
              slide_down: "/sub/slide_down.png",
              slide_up: "/sub/slide_up.png",
              show:false,
              desc: intl.get("Any commissions earned during the month will be paid on the 13th - 15th of next month. And we only process the payout if the accumulated commission is $25 or above."),
            },
          ],
          list2: [
            {
              title: intl.get("Is it possible to permanently receive a 30% commission?"),
              slide_down: "/sub/slide_down.png",
              slide_up: "/sub/slide_up.png",
              show:false,
              desc: intl.get("The 30% commission is permanent, which means that as long as users who installed Dropshipman through your link continue to subscribe to Dropshipman's plans, you will continue to receive a 30% commission."),
            },
            {
              title: intl.get("How can I withdraw my commission?"),
              slide_down: "/sub/slide_down.png",
              slide_up: "/sub/slide_up.png",
              show:false,
              desc: intl.get("Each month, we will create a statement, and if your total commission reaches $25, we will automatically transfer the commission to your provided PayPal account without requiring you to request a withdrawal."),
            },
          ],
        },
      }
    }
    return data;
  },[language]);

  const [subFaq, setSubFaq] = useState(defalutData.faq); //faq列表
  const router = useHistory();
  // faq折叠
  const handleToggleFaq = (k, type) => {
    switch (type) {
      case "list1":
        let list1 = subFaq.list1.map((item, ind) => {
          if (ind === k) {
            return { ...item, show: !item.show };
          } else {
            return item;
          }
        });
        setSubFaq({ ...subFaq, list1 });
        break;
      case "list2":
        let list2 = subFaq.list2.map((item, j) => {
          if (j === k) {
            return { ...item, show: !item.show };
          } else {
            return item;
          }
        });
        setSubFaq({ ...subFaq, list2 });
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      // if(AppBridge && AppBridge.exist()) {
      //   AppBridge.setTitleBar('Affiliate Program','/admin/partnerShip')
      // }
      // console.log(store.getState().languageReducer.subScriptData);
      getAffiliateData()
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line
  }, []);
  const getDefalutData = (data)=>{
    setSubFaq(data.faq)
  }
    useEffect(() => {
      let isMount = false;
      if (!isMount) {
        getDefalutData(defalutData);
      }
      return () => {
        isMount = true;
      };
      // eslint-disable-next-line
    },[defalutData]);
  const [affiliateLink,setAffiliateLink] = useState({
    referral_links:"",
    url: ""
  })
  const getAffiliateData = async ()=>{
    setLoading(true)
    let res = await reqGetAffiliateData({type:1});
    if (res.code === '200') {
      setAffiliateLink(res.data)
    }
    setLoading(false)
  }
  const getAffiliateDataLink = async ()=>{
    let res = await reqGetAffiliateData({type:2});
    if (res.code === '200') {
      setAffiliateLink(res.data);
      window.open(res.data.url,'_blank');
    }else{
      toggleToast(res.msg)
    }
  }
  
  // 复制按钮
  const onSelectCopy = (text) => {
    let innerText = text;
    var myTagContent = document.createElement("input");
    document.body.appendChild(myTagContent);
    myTagContent.value = innerText;
    myTagContent.select();
    document.execCommand("copy");
    myTagContent.remove();
    toggleToast("Copy successfully", false);
  };
  // 跳转
  const handleLink =()=>{
    if ((affiliateLink.url !== '')) {
      window.open(affiliateLink.url,'_blank')
    }else{
     getAffiliateDataLink()
    }
  }
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
  const initSkeletonPage = (
    <div>
      <SkeletonPage breadcrumbs={true}>
      <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
      </SkeletonPage>
    </div>
  );
  const pageContent = (
    <Page
      // breadcrumbs={[{ onAction: () => history.push("/admin/dashboard") }]}
      title={AppBridge.exist() ? "" : <div style={{fontSize:'20px'}} >{intl.get("Affiliate Program")}</div>}
      backAction={AppBridge.exist() ? undefined : {content: 'Affiliate Program', onAction: () =>{
        utils.routeListen('/admin/partnerShip')
      utils.routeJump1(router,'/admin/partnerShip')
    }}}
      fullWidth={false}
      // fullWidth
    >
      <Layout>
        <Layout.Section>
          <section>
            <div className='affiliate_top'>
            <div className='affiliate_top_left'><img src='/affiliate/trend_img.png' alt='dropshipman'/></div>
            <div className='affiliate_top_right'>
            <div className='affiliate_top_right_detail'>{intl.get("Join the Dropshipman affiliate program, create your exclusive link, and share it with other merchants through your website, social media, and blog. When other merchants join Dropshipman and subscribe to a package using your link, you will receive a permanent commission of 30%!")}</div>
            <div className='affiliate_top_right_button'>
              <Button variant="primary"  onClick={()=>handleLink()}> {(affiliateLink.url !== '') ? intl.get('My Account') : intl.get('Apply Now')}</Button>

             {(affiliateLink.url !== '') ? <div style={{position:'relative',flex:1}}>
              <div className="affiliate_copy_link">
              <span className="refund_tip_copy" style={{display:'none'}}>
                  <input
                    onChange={() => console.log("")}
                    type="text"
                    id="copy_one"
                    value={affiliateLink.referral_links}
                  />
                </span>
              {intl.get("Affiliate link")} : <a href={affiliateLink.referral_links} target="_blank" rel="noopener noreferrer">{affiliateLink.referral_links}</a>
              </div>
              <span style={{position:"absolute",top:0,right:0,cursor: "pointer"}}  onClick={() => onSelectCopy(affiliateLink.referral_links)}>
              <Icon
                source={DuplicateIcon}
                tone="base"
              />
              </span>
              </div>:''}
            </div>
            </div>
            </div>
          </section>
          <section>
            <div className='affiliate_mid'>
            <h3>{intl.get("How it works?")}</h3>
            <div className='affiliate_mid_detail'>   
            <div className='affiliate_mid_item'>
            <div className='affiliate_mid_item_img'><img src='/affiliate/image_1.png' alt='dropshipman'/></div>
            <div className='affiliate_mid_item_desc'>{intl.get("Apply to become a Dropshipman affiliate.")}</div>
            <div className='affiliate_mid_item_arrow'><img src='/affiliate/partner_arrow_right_circle.png' alt='dropshipman'/></div>
            </div>
            <div className='affiliate_mid_item'>
            <div className='affiliate_mid_item_img'><img src='/affiliate/image_2.png' alt='dropshipman' /></div>
            <div className='affiliate_mid_item_desc'>{intl.get("Get promoted links and share with your audience.")}</div>
            <div className='affiliate_mid_item_arrow'><img src='/affiliate/partner_arrow_right_circle.png' alt='dropshipman'/></div>
            </div>
            <div className='affiliate_mid_item'>
            <div className='affiliate_mid_item_img'><img src='/affiliate/image_3.png' alt='dropshipman'/></div>
            <div className='affiliate_mid_item_desc'>{intl.get("Earn a 30% commission when someone subscribes to the package through the link you share.")}</div>
            </div>
            </div>
            </div>
          </section>
          <section>
            <div className="subscript_faq affiliate_faq">
              <h3 style={{textAlign:'center'}}>{intl.get("Frequently asked questions")}</h3>
              <div className="subscript_faq_content">
                <div className="subscript_faq_list">
                <BlockStack gap={400}>
                  {subFaq.list1.map((item, ind) => (
                    <Card key={ind}>
                      <div className="subscript_faq_item">
                        <div className="subscript_faq_item_title">
                          <p>{item.title}</p>
                          {item.show ? (
                            <img
                              src={item.slide_up}
                              alt="dropshipman"
                              onClick={() => handleToggleFaq(ind, "list1")}
                            />
                          ) : (
                            <img
                              src={item.slide_down}
                              alt="dropshipman"
                              onClick={() => handleToggleFaq(ind, "list1")}
                            />
                          )}
                        </div>
                        <Collapsible
                          open={item.show}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                           
                            <p className="subscript_faq_item_desc">
                              {item.desc}
                            </p>
                           
                        </Collapsible>
                      </div>
                    </Card>
                  ))}
                  </BlockStack>
                </div>
                <div className="subscript_faq_list">
                  <BlockStack gap={400}>
                  {subFaq.list2.map((item, ind) => (
                    <Card key={ind}>
                      <div className="subscript_faq_item">
                        <div className="subscript_faq_item_title">
                          <p>{item.title}</p>
                          {item.show ? (
                            <img
                              src={item.slide_up}
                              alt="dropshipman"
                              onClick={() => handleToggleFaq(ind, "list2")}
                            />
                          ) : (
                            <img
                              src={item.slide_down}
                              alt="dropshipman"
                              onClick={() => handleToggleFaq(ind, "list2")}
                            />
                          )}
                        </div>
                        <Collapsible
                          open={item.show}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                           
                            <p className="subscript_faq_item_desc">
                              {item.desc}
                            </p>
                           
                        </Collapsible>
                      </div>
                    </Card>
                  ))}
                  </BlockStack>
                </div>
              </div>
            </div>
          </section>
        </Layout.Section>
      </Layout>
    </Page>
  );
  return (
    <div>
      {loading ? initSkeletonPage : pageContent}
      {/* 提示 */}
      {toastMarkup}
    </div>
  );
};

export default  SubscriptionPaidClass;
