import { Box, Modal } from "@shopify/polaris";
import React from "react";

const Index = ({open,setFeedModal}) => {
    const handleOff = ()=>{
        let sendMsg = `Hello, I'm considering the 7-day trial but have some questions. Could you give me a quick overview of Dropshipman's benefits and how it can assist my dropshipping operations? Thanks!`

        try {
            // 填充信息并激活对话弹窗
            // window.Intercom('showNewMessage', sendMsg)
            window.Willdesk.show();
            window.Willdesk.chat(sendMsg);
        } catch (error) {
            console.info(error)
        }

        setFeedModal(false)
    }
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {setFeedModal(false)}}
        title={"Some benefits for you"}
        primaryAction={{
            content:'I’d Like to Know More About Plan',
            onAction:()=>{handleOff()}
        }}
      >
       <Modal.Section>
        <Box style={{fontSize:"16px",marginBottom:'16px',fontWeight:600}}>WAIT! Still Trying To Decide If Dropshipman Will Work For You?</Box>
        <p>We want to give you a detailed walk through of what Dropshipman can do, and answer your questions! Send us a message.</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
