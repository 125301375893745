import React from 'react';

const Index =()=> {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.872192 4.36905C0.000244141 6.08035 0.000244141 8.32056 0.000244141 12.801V19.201C0.000244141 23.6814 0.000244141 25.9216 0.872192 27.6329C1.63918 29.1382 2.86302 30.362 4.36832 31.129C6.07961 32.001 8.31982 32.001 12.8002 32.001H19.2002C23.6807 32.001 25.9209 32.001 27.6322 31.129C29.1375 30.362 30.3613 29.1382 31.1283 27.6329C32.0002 25.9216 32.0002 23.6814 32.0002 19.201V12.801C32.0002 8.32056 32.0002 6.08035 31.1283 4.36905C30.3613 2.86376 29.1375 1.63991 27.6322 0.872924C25.9209 0.000976562 23.6807 0.000976562 19.2002 0.000976562H12.8002C8.31982 0.000976562 6.07961 0.000976562 4.36832 0.872924C2.86302 1.63991 1.63918 2.86376 0.872192 4.36905ZM7.99829 11.9973H11.1973V23.9937H7.99829V11.9973ZM9.58821 10.3978H9.57062C8.6157 10.3978 7.99829 9.68598 7.99829 8.79745C7.99829 7.88972 8.6349 7.19873 9.60661 7.19873C10.5799 7.19873 11.1789 7.88972 11.1973 8.79745C11.1973 9.68598 10.5799 10.3978 9.58821 10.3978ZM24.7933 16.396C24.7933 13.9663 22.8242 11.9973 20.3946 11.9973C18.9054 11.9973 17.5914 12.7395 16.7957 13.8719V11.9973H13.5966V23.9937H16.7957V17.5956C16.7957 16.2704 17.8697 15.1963 19.1949 15.1963C20.5201 15.1963 21.5942 16.2704 21.5942 17.5956V23.9937H24.7933V16.396Z" fill="#1B1F26" fillOpacity="0.72"/>
            </svg>
           )}

export default Index;
