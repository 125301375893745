import { EmptyState, Modal } from "@shopify/polaris";
import React from "react";
import intl from "../../../../../../i18n/intl";
import "./index.css";
import { reqGetOrderTracking } from "../../../../../../api";
import { useState } from "react";
import { useEffect } from "react";
const Index = ({ show, orderId, handleShowTracking }) => {
  const [orderTrackingLoading, setOrderTrackingLoading] = useState(false); //物流追踪弹窗加载状态
  const [orderTrackingData, setOrderTrackingData] = useState({}); //物流追踪数据

  const handleTracking = (id) => {
    if (id) {
      setOrderTrackingLoading(true);
      getTrackingData(id);
    }
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount && show) {
      handleTracking(orderId);
    }
    return () => (isMount = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  const getTrackingData = async (id) => {
    let res = await reqGetOrderTracking({ id });
    if (res.code === "200") {
      setOrderTrackingData(res.data);
      setOrderTrackingLoading(false);
    } else {
      setOrderTrackingLoading(false);
    }
  };
  return (
    <Modal
      open={show}
      onClose={() => handleShowTracking()}
      title={intl.get("Order Tracking")}
      size="large"
      loading={orderTrackingLoading}
      primaryAction={[
        {
          content: intl.get("Close"),
          onAction: () => handleShowTracking(),
          disabled: orderTrackingLoading,
        },
      ]}
    >
      {Object.values(orderTrackingData).length > 0 &&
      orderTrackingData.tracking.length > 0 ? (
        <div className="order_tracking_content">
          <div className="order_tracking_list">
            {orderTrackingData &&
              orderTrackingData.tracking.map((item, ind) => (
                <div key={ind} className="order_tracking_item">
                  <div className="order_tracking_box">
                    <div className="order_tracking_icon">
                      {ind + 1 <= orderTrackingData.state ? (
                        <img src={"/ready_over_green.png"} alt="dropshipman" />
                      ) : (
                        <img src={"/unready_over.png"} alt="dropshipman" />
                      )}
                    </div>
                    <div
                      className="order_tracking_title"
                      style={{
                        color: ind + 1 <= orderTrackingData.state && "#0aa780",
                      }}
                    >
                      {item.title}
                    </div>
                    <div className="order_tracking_day">{item.day}</div>
                  </div>
                  {ind + 1 < orderTrackingData.state ? (
                    <div
                      className="order_tracking_line"
                      style={{ background: "#0aa780" }}
                    ></div>
                  ) : (
                    <div className="order_tracking_line"></div>
                  )}
                  {ind + 1 === orderTrackingData.state && (
                    <div
                      className="order_tracking_line"
                      style={{ background: "#0aa780", width: "37%" }}
                    ></div>
                  )}
                  {ind + 1 === orderTrackingData.state && (
                    <div
                      className="order_tracking_process_text"
                      title={item.process}
                    >
                      {item.process}
                    </div>
                  )}
                </div>
              ))}
          </div>

          <div className="order_tracking_process_detail">
            <h3>{intl.get("Detail")}</h3>
            {orderTrackingData &&
              orderTrackingData.tracking_detail.map((item, key) => (
                <div
                  key={key}
                  className="order_tracking_process_detail_content"
                >
                  <div className="order_tracking_process_detail_day">
                    {key + 1 === 1 && "Latest Updated "}
                    {item.title}
                  </div>
                  <div className="order_tracking_process_detail_list">
                    {item?.list.map((day, inds) => (
                      <div
                        key={inds}
                        className="order_tracking_process_detail_item"
                      >
                        <div className="order_tracking_process_detail_time">
                          {day?.time}
                        </div>
                        <div
                          className="order_tracking_process_detail_dsc"
                          title={day?.desc}
                        >
                          {(day?.is_tracking_number && day?.state === 5 ) && intl.get('Products have been collected by logistics.Tracking number:') }
                          {(day?.is_tracking_number && day?.state === 2 ) && day?.desc + intl.get('Tracking number')+ ':' }
                          {(!day?.is_tracking_number) && day?.desc}
                        </div>
                        <div style={{ color: "#4584FE" }}><a style={{color: "#4584FE",display:'flex'}} href={'https://www.trackingmore.com/track/en/'+ (day?.is_tracking_number ? (day?.brand_tracking_number ? day?.brand_tracking_number : day?.tracking_num) : day?.tracking_num)} target='_blank' rel="noopener noreferrer">{day?.is_tracking_number ? (day?.brand_tracking_number ? day?.brand_tracking_number : day?.tracking_num) : ''} {day?.is_tracking_number && (day?.brand_tracking_number !== '' || day?.tracking_num) ?  <img width={20} style={{marginLeft:"8px"}} alt='dsmshipman' src='/share_icon.png'></img> : ''}  </a> </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div
          className="order_tracking_content_empty"
          style={{
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="emptyWithImg" style={{ textAlign: "center" }}>
            <EmptyState image="/empty.svg">
              <p style={{ fontSize: "14px", marginBottom: "0" }}>
                {intl.get(
                  "The order tracking feature goes live on November 28, 2022, and orders generated before then are not supported for tracking."
                )}
              </p>
            </EmptyState>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Index;
