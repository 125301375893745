import { Box, Icon } from "@shopify/polaris";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./index.css";
import { FreeMode, Navigation, Thumbs,Scrollbar  } from "swiper";
import { ChevronLeftIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import LazyLoad from "react-lazyload";
const Index = ({ pageData,detailData,setDetailData }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null); // 小图
  // 鼠标移入
  const mouseEnter = (e) => {
    
    let big = document.querySelector(".picture_big");
    let mask = document.querySelector(".detail_mask");
    mask.style.display = "block";
    big.style.display = "block";
  };
  // mouse remove
  const mouseLeave = () => {
    let big = document.querySelector(".picture_big");
    let mask = document.querySelector(".detail_mask");
    mask.style.display = "none";
    big.style.display = "none";
  };
  // mouse move
  const mouseMove = (event) => {
    let e = event.nativeEvent;
    if (event.target.className === "swiper-button-next swiper-button-white" || event.target.className === "swiper-button-prev swiper-button-white") {
      let big = document.querySelector(".picture_big");
      let mask = document.querySelector(".detail_mask");
      mask.style.display = "none";
      big.style.display = "none";
      return
    }
    // console.log('e x y',e.nativeEvent);
    calculationBlock(e.offsetX, e.offsetY);
  };
  // calculation params
  const calculationBlock = (offsetx, offsety) => {
    let pre_img = document.querySelector(".product_info_swiper_detail");
    let bigImg = document.querySelector(".picture_big img");
    let mask = document.querySelector(".detail_mask");
    let offsetX = offsetx - 90; // 鼠标在盒子中的位置 - mask宽的一半
    let offsetY = offsety - 90; // 鼠标在盒子中的位置 - mask高的一半
    /*block position*/
    // 防止鼠标移动过快导致计算失误，只要小于或大于对应值，直接设置偏移量等于最小或最大值
    // left 取值为 大于 0, 小于 盒子的宽 - mask的宽
    var maskMax = pre_img.offsetWidth - mask.offsetWidth;
    if (offsetX <= 0) {
      offsetX = 0;
    }
    if (offsetX >= maskMax) {
      offsetX = maskMax;
    }
    if (offsetY <= 0) {
      offsetY = 0;
    }
    if (offsetY >= maskMax) {
      // offsetY = small.offsetHeight - mask.offsetHeight;
      offsetY = maskMax;
    }
    // 移动mask
    mask.style.left = offsetX + "px";
    mask.style.top = offsetY + "px";
    /*计算图片放大位置*/
    // 右侧大图片，等比例移动
    // 大图片走的距离 / mask 走的距离 = 大图片 / 小图片
    var bigX = (offsetX * 1200) / 1000; //大图/小图
    var bigY = (offsetY * 1200) / 1000;
    //大图的移动(方向相反，取负值)
    bigImg.style.left = -bigX + "px";
    bigImg.style.top = -bigY + "px";
  };

  const mainSwiperRef = useRef(null);
  const handleSlideChange = (swiper) => {
    const newIndex = swiper.activeIndex;
    setDetailData((prevValue)=>({
      ...prevValue,
      skuImage:pageData?.image_list[newIndex]
    }))
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount && detailData.skuImage) {
      let list = pageData.image_list;
      let list_length = list.length;
      let index = 0;
      for (let i = 0; i < list_length; i++) {
        if (list[i] === detailData.skuImage) {
          index = i;
          break;
        }
      }
      setDetailData((prevValue)=>({
        ...prevValue,
        skuImage:list[index]
      }))
      mainSwiperRef.current.slideTo(index);
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData.skuImage]);
  return (
    <Box>
      <Box className="product_info_image_box">
        <Box>
          <Box className="details_product">
         {pageData.image_list &&
                pageData.image_list.length >=6 &&  <Box className="prev">
              <Icon source={ChevronLeftIcon} tone="base" />
            </Box>}
            {pageData.image_list &&
                pageData.image_list.length >=6 &&  <Box className="next">
              <Icon source={ChevronRightIcon} tone="base" />
            </Box>}

            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={2}
              freeMode={true}
              observer={true}
              observeParents={true}
              slidesPerView={"auto"}
              direction="vertical"
              onSlideChange={handleSlideChange}
              navigation={{ prevEl: `.prev`, nextEl: `.next` }}
              modules={[Navigation, Thumbs, Scrollbar]}
              watchSlidesProgress={true}
              className="mySwiper"
            >
              {pageData.image_list &&
                pageData.image_list.map((image_url, ind) => (
                  <SwiperSlide
                    className="product_swiper_thumbs"
                    key={ind}
                  >
                    <img src={image_url} alt="dropshipman" />
                  </SwiperSlide>
                ))}
            </Swiper>
          </Box>
        </Box>
        <Box  className="product_info_swiper_big">
          <Box
            className="product_info_swiper_detail"
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onMouseMove={mouseMove}
          >
            <Swiper
              style={{
                "--swiper-navigation-color": "#FFF",
                "--swiper-pagination-color": "#FFF",
              }}
              spaceBetween={20}
              navigation={{ prevEl: `.swiper-button-prev`, nextEl: `.swiper-button-next` }}
              onNavigationNext={() => {
              }}
              onNavigationPrev={() => {
              }}
              onSlideChange={handleSlideChange}
              onSwiper={(swiper) => (mainSwiperRef.current = swiper)}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2 swiper-no-swiping"
            >
              {pageData.image_list &&
                pageData.image_list.map((image_url, ind) => (
                  <SwiperSlide
                    onClick={() =>setDetailData((prevValue)=>({
                      ...prevValue,
                      skuImage:image_url
                    })) }
                    key={ind}
                  >
                    <LazyLoad once> <img
                    className="lazy-load"
                src={
                  detailData.skuImage !== ""
                    ? detailData.skuImage
                    : image_url
                }
                alt="dropshipman"
              />
              </LazyLoad>
                  </SwiperSlide>
                ))}
              
              <Box className="detail_mask"></Box>
              <div className="swiper-button-prev swiper-button-white">
                <Icon source={ChevronLeftIcon} tone="base" />
              </div>
              <div className="swiper-button-next swiper-button-white">
                <Icon source={ChevronRightIcon} tone="base" />
              </div>
            </Swiper>
          </Box>
          <Box className="picture_big">
            <img
            className="lazy-load"
              src={
                detailData.skuImage !== ""
                  ? detailData.skuImage
                  : pageData.image_list && pageData.image_list[0]
              }
              alt="dropshipman"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// 使用 Intersection Observer 进行懒加载
const lazyImages = document.querySelectorAll('.lazy-load');
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const img = entry.target;
      img.src = img.dataset.src;
      observer.unobserve(img);
    }
  });
});

lazyImages.forEach(img => observer.observe(img));

export default Index;
