import React, { useState, memo } from "react";
import { Modal,  TextField, Toast } from "@shopify/polaris";
import { reqPostCustomPackagingPurchaseOrderNote } from "../../api";
import { useEffect } from "react";

const OrderNoteModal = memo(
  ({ itemData,setOrderNote,content,setPageData }) => {
    const [noteValue, setNoteValue] = useState(itemData.orderNote);
    const [loading, setLoading] = useState(false); // 加载

    useEffect(() => {
      let isMount = false;
      if (!isMount && itemData.open) {
        setNoteValue(itemData.orderNote);
      }
      return () => {
        isMount = true;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemData.open]);
    // 保存
    const handleSaveChange = async () => {
      setLoading(true);
      let res = await reqPostCustomPackagingPurchaseOrderNote({ id: itemData.currentOrderId, note: noteValue });
      if (res.code === "200") {
        
        toggleToast('Saved successfully');
        let list = content.map((item)=>{
          if (item.dsm_record === itemData.currentOrderId) {
            return {...item,dsm_note:noteValue}
          }else{
            return item
          }
        })
        setPageData((prevValue)=>({
            ...prevValue,
            list:list
        }))
        handleOff();
      } else {
        
      }
      setLoading(false);
    };
    // 取消
    const handleOff = () => {
      setNoteValue("");
      setOrderNote((prevValue)=>({...prevValue,open:false,note:''}))
      setLoading(false);
    };
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };
    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
        duration={1500}
      />
    ) : null;
    return (
      <div style={{ margin: "0 0" }}>
        {toastMarkup}
        <Modal
          open={itemData.open}
          onClose={() => handleOff()}
          title={"Edit Order note"}
          primaryAction={{
            content: "Save",
            onAction: handleSaveChange,
            loading: loading,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: ()=>{handleOff()},
            },
          ]}
        >
          <Modal.Section>
             
              <TextField
                label={"Order note"}
                value={noteValue}
                maxLength={200}
                onChange={(newValue) => setNoteValue(newValue)}
                autoComplete="off"
              />
              <p style={{ color: "rgb(89 89 89)" }}>
                {"This note only display on Dropshipman"}
              </p>
             
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default OrderNoteModal;
