import React, { Component } from 'react';

import FrameComponents from "../FrameComponents"; 

class dashboard extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-import-list';
  }
 
  render() {
   
    return (
      <div>
        <FrameComponents name="import_list" /> 
      </div>
    );
    
  }
}

export default dashboard;
