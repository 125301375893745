import React, { Component } from 'react';
import './index.css'
import { Box } from '@shopify/polaris';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditorConfig} from '../../../../../api';
class Index extends Component {
    state = {
        isChange:false,//是否修改
    }
    
    render() {
        const {item} = this.props;
        return (
            <Box className="list_product_description">
                <Box>
                    <CKEditor
                    editor={ClassicEditor}
                    config={ckeditorConfig}
                    data={item.description}
                    disabled={true}
                    onChange={(event, editor) => {
                    }}
                    />
                </Box>
            </Box>
        );
    }
}

export default Index;
