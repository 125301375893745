import {
  Page,
  Card,
  Layout,
  IndexTable,
  BlockStack,
  Box,
  Text,
  useIndexResourceState,
  Pagination,
  SkeletonPage,
  SkeletonBodyText,
  EmptyState,
  Tooltip,
  Icon,
  InlineStack
} from "@shopify/polaris";
import {QuestionCircleIcon} from "@shopify/polaris-icons";
import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import intl from "../../../i18n/intl";
import _ from "lodash";
import utils from "../../../utils/utils";
import { useHistory, withRouter } from "react-router-dom";
import { reqGetPointsLogListExport, reqGetPointsLogListData, reqGetPointsLogFreezeListData } from "../../../api";
import ChangeLoading from "../../../components/ChangeLoading";
// import AppBridge from "../../../utils/appBridge";

const Index = () => {
  const router = useHistory();
  
  const [initPageData,setInitPageData] = useState({
    isInit:true,
    pageLoading:false,
  })
  const [searchParam,setSearchParam] = useState({
    page: 1,
    size: 20,
  });
  const [selected,setSelected] = useState(0);
  const [pageData, setPageData] = useState({
    count: 0,
    list: [],
  })
  const getHistoryPage = async (param,val)=>{
    setSelected(val)
    let params = {
      ...param,
      type:val
    }
    try {
      let res = await reqGetPointsLogListData(params);
      if (res.code === '200') {
        setPageData(res.data);
      }
      setInitPageData({
        isInit:false,
        pageLoading:false,
        
      })
    } catch (error) {
      }
    
  }

  const [freezeData,setFreezeData] = useState([])
  const getFreezeData = async ()=>{
    let res = await reqGetPointsLogFreezeListData()
    if (res.code === '200') {
      setFreezeData(res.data.list)
    }
    setInitPageData((prevValue)=>({...prevValue,pageLoading:false}))
  }
  const handleClick = (val)=>{
    setSelected(val)
    setInitPageData((prevValue)=>({...prevValue,pageLoading:true}))
    if (Number(val) === 3) {
      getFreezeData()
    }else {
      getHistoryPage(searchParam,val)
    }
  }
  useEffect(() => {
    let isMount = false;
    let url = new URLSearchParams(window.location.search);
    let record_id = url.get("id")
    setSearchParam((prevValue)=>({...prevValue,inventory_id:record_id}))
    // if(AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar('Points','/admin/points')
    // }
    if (!isMount) {
      getHistoryPage(searchParam,0)
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   // 总条数
   const pageMsg = () => {
    let total = pageData?.count ? pageData?.count : 0;
    const { page, size } = searchParam;
    const startNum = (page - 1) * size + 1;
    const endNum = page * size > total ? total : page * size;
    return intl.get(
      "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
      startNum,
      endNum,
      total,
      Math.ceil(total / 50)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (page > 1 && page <= maxPageNum) {
      return initPageData.pageLoading ? false : true;
    } else if (page === 1) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, initPageData]);

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (_.inRange(page, 1, maxPageNum)) {
      return initPageData.pageLoading ? false : true;
    } else if (page === maxPageNum) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, initPageData]);

  // 上一页
  const handlePrev = () => {
    setInitPageData((prevValue)=>({...prevValue,pageLoading:true}))
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) - 1;
      getHistoryPage(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 下一页
  const handleNext = () => {
    setInitPageData((prevValue)=>({...prevValue,pageLoading:true}))
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) + 1;
      getHistoryPage(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };
  const formatNumber = (value) => {
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }

    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  };
  const {selectedResources, allResourcesSelected, handleSelectionChange} =
  useIndexResourceState([]);
  // 列表数据
  const rowMarkup = pageData?.list.map((item, index) => {
    return (
      <IndexTable.Row
        id={index}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Box className="history_table_item">
            <Text alignment="center">{item.date}</Text>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Box className="history_table_item">
        <Tooltip content={item.event}>
          <div style={{display:'flex',justifyContent:'center'}}>
        <p>{item.event.slice(0,50)}</p>{item.event.length > 50 ? '...':''}
        </div>
        </Tooltip>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Box className={item.type > 0 ? "history_table_item" : 'history_table_item history_table_items'}>
            <Text alignment="center">{item.type}</Text>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Box className={item.changes > 0 ? "history_table_item" : 'history_table_item history_table_items'}>
            <Text alignment="center">{item.changes}</Text>
          </Box>
        </IndexTable.Cell>

        <IndexTable.Cell>
        <Box className="history_table_item">
            <Text alignment="center">{formatNumber(item.remaining)}</Text>
          </Box>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });
  const rowMarkup2 = freezeData.map((item, index) => {
    return (
      <IndexTable.Row
        id={index}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Box className="history_table_item">
            <Text alignment="center">{item.date}</Text>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Box className="history_table_item">
        <Tooltip content={item.event}>
          <div style={{display:'flex',justifyContent:'center'}}>
        <p>{item.event.slice(0,50)}</p>{item.event.length > 50 ? '...':''}
        </div>
        </Tooltip>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Box className={item.type > 0 ? "history_table_item" : 'history_table_item history_table_items'}>
            <Text alignment="center">{item.type}</Text>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Box className={item.point > 0 ? "history_table_item" : 'history_table_item history_table_items'}>
            <Text alignment="center">{item.point}</Text>
          </Box>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  // csv 导出
  const handleExport = async ()=>{
    let params = {
      type: selected,
    };
    let res = await reqGetPointsLogListExport(params)
    if (res.code === '200') {
      var csv = convertToCSV(res.data.list);
      downloadCSV(csv);
    }
  }
  const convertToCSV = (data) => {
    var csv = '';
    for (var i = 0; i < data.length; i++) {
      var row = data[i];
      for (var j = 0; j < row.length; j++) {
        var val = row[j] === null ? '' : row[j].toString();
        if (val.indexOf(',') !== -1 || val.indexOf('"') !== -1 || val.indexOf('\n') !== -1) {
          val = '"' + val.replace(/"/g, '""') + '"';
        }
        if (j > 0) {
          csv += ',';
        }
        csv += val;
      }
      csv += '\n';
    }
    return csv;
  }

  const downloadCSV = (csv) => {
    try {
    var link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    link.download = 'Points_History_'+ new Date().getTime() +'.csv';
    document.body.appendChild(link);
    link.click();
    document?.body?.removeChild(link);
  } catch (error) {
      
  }
  }

  
  const EmptyProductList = (
    <div className="emptyWithImg">
      <EmptyState
        heading={intl.get("Sorry, no results found.")}
        image="/empty.svg"
      >
      </EmptyState>
    </div>
  );
  const skeletonPage =  <SkeletonPage fullWidth={false}>
  <Layout>
   <Layout.Section>
   <Card>
   <BlockStack gap={400}>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   </BlockStack>
   </Card>
 </Layout.Section>
   </Layout>
 </SkeletonPage>
  return (
    <div className="history_log" style={{position:'relative'}}>
      {initPageData.isInit ? skeletonPage : <Page
        backAction={
          false
            ? undefined
            : {
                content: "",
                onAction: () => {
                  utils.routeListen("/admin/points");
                  utils.routeJump1(
                    router,
                    "/admin/points"
                  );
                },
              }
        }
        title={<div style={{ fontSize: 20 }}>{intl.get("History")}</div>}
      >
        <Layout>
          <Layout.Section>
            <BlockStack gap={400}>
              <Box>
                <Card>
                  <Box className="history_table_top">
                    <Box className="history_table_title">Points History</Box>
                    <Box className="history_table_export">
                    {(pageData?.list.length > 0 && selected < 3) && <span onClick={()=>handleExport()}> <img src="/novice/download_export.png" alt="/dropshipman" /> {intl.get("Export")}</span>}
                      </Box>
                  </Box>
                  <Box className="points_log_tab">
                  <InlineStack wrap={false} direction={"row"}>
                    <Box onClick={()=>handleClick(0)} className={selected === 0 ? "checked points_log_tab_item" : "points_log_tab_item"}>
                      <span>All</span>
                      <p></p>
                    </Box>
                    <Box  onClick={()=>handleClick(1)}   className={selected === 1 ? "checked points_log_tab_item" : "points_log_tab_item"}>
                      <span>Earn Points</span>
                      <p></p>
                    </Box>
                    <Box  onClick={()=>handleClick(2)}   className={selected === 2 ? "checked points_log_tab_item" : "points_log_tab_item"}>
                      <span>Redeem Points</span>
                      <p></p>
                    </Box>
                    <Box  onClick={()=>handleClick(3)}   className={selected === 3 ? "checked points_log_tab_item" : "points_log_tab_item"} style={{position:'relative'}}>
                    <span>Freeze Points</span>
                    <span style={{position:'absolute',right:'-8px',top:0}}>
                    <Tooltip content={'Points were used for order payment but are now frozen since the payment isn’t completed. If the order is canceled, points will be returned within an hour.'}>
                      <Icon source={QuestionCircleIcon} color="base" />
                      </Tooltip>  
                    </span>
                      <p>
                      </p>
                    </Box>
                  </InlineStack>
                  </Box>
                  {(pageData?.list.length > 0 || freezeData.length > 0) ? <Box className="history_table points_log_table" style={{position:'relative'}}>
                    {initPageData.pageLoading && <ChangeLoading />}
                    {selected < 3 ? <IndexTable
                      itemCount={pageData?.list?.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      selectable={false}
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Date" },
                        { title: "Event" },
                        { title: "Type" },
                        { title: "Point Changes" },
                        { title: "Remaining Quantity" },
                      ]}
                      // pagination={{
                      //   hasNext: true,
                      //   onNext: () => {},
                      // }}
                    >
                      {rowMarkup}
                    </IndexTable> : <IndexTable
                      itemCount={freezeData.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      selectable={false}
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Date" },
                        { title: "Event" },
                        { title: "Type" },
                        { title: "Points" },
                      ]}
                     
                    >
                      {rowMarkup2}
                    </IndexTable>}
                  </Box>:<Box>
                {/* <Card> */}
                  {EmptyProductList}
                {/* </Card> */}
                </Box>}
                  
                </Card>
                { selected < 3 && pageData?.list.length > 0 && <div className="pageination_container">
                <Pagination
                  label={pageMsg()}
                  hasPrevious={hasPrevious}
                  hasNext={hasNext}
                  onPrevious={handlePrev}
                  onNext={handleNext}
                />
               </div>}
              </Box>
            </BlockStack>
          </Layout.Section>
        </Layout>
      </Page>}
      
    </div>
  );
};


export default withRouter(Index);
