import {
  Page,
  Button,
  BlockStack,
  Box,
  Text,
  Icon,
  List
} from "@shopify/polaris";
import {
  DesktopIcon
} from '@shopify/polaris-icons';
import React, { useEffect} from "react";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import utils from '../../../../utils/utils'
import intl from '../../../../i18n/intl';
import AppBridge from "../../../../utils/appBridge";
const CustomServiceClass = () => {
  const router = useHistory();
  useEffect(() => {
  }, []);

  const Custom_Packaging_Route = '/admin/customService/custom-packaging';
  const Private_Storage_Route = '/admin/customService/inventory';
  const Landing_Page_Builder_Route = '/admin/customService/landing-page-build';

  const pageContent = (
    <div className="Custom_Service_Page">
      <Page
        title={AppBridge.exist() ? "" : intl.get("Custom Service")}
      >
        <Box className="Custom_Service_Content">
          <div className="custom_packaging_card">
            <div className="item left-item">
              <LazyLoad once>
                <img src="/customService/custom_packaging.png" alt="" className="responsive-image" />
              </LazyLoad>
              <img src="/customService/overlay.png" alt="" className="overlay-image" />
            </div>

            <div className="item right-item">
              <BlockStack gap="400">
                <div className="subject">
                Customize your brand with personalized packaging, delivery notes, thank-you cards, and gift cards
                </div>

                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">Benefits:</Text>
                  <div className="ul-list">
                    <List type="bullet">
                      <List.Item>All-in-one branding solutions</List.Item>
                      <List.Item>Professional customization services</List.Item>
                      <List.Item>Create a distinctive brand identity</List.Item>
                    </List>
                  </div>

                </BlockStack>
              </BlockStack>
              <div className="button">
                <Button
                  variant="primary"
                  onClick={() => {
                    utils.routeListen(Custom_Packaging_Route)
                    utils.routeJump1(router, Custom_Packaging_Route)
                  }}
                >
                  Start Customizing
                </Button>
              </div>
            </div>
          </div>

          <div className="private_storage_box">
            <div className="left-side">
              <div className="private_storage_card">
                <div className="side _left">
                  <BlockStack gap="400">
                    <div className="subject">
                    Dedicated warehouses stocked with your best-selling products ensure 100% fulfillment reliability
                    </div>
                  </BlockStack>
                  <div className="button">
                    <Button
                      variant="primary"
                      onClick={() => {
                        utils.routeListen(Private_Storage_Route)
                        utils.routeJump1(router, Private_Storage_Route)
                      }}
                    >
                     Get Private Storage
                    </Button>
                  </div>
                </div>
                <div className="side _right">
                  <LazyLoad once>
                    <img src="/customService/private_storage.png" alt="" className="private-storage-image" />
                  </LazyLoad>
                </div>
              </div>
              
            </div>

            <div className="right-side">
              <div className="icon">
                <Icon source={DesktopIcon} />
              </div>
              <div className="title">
              {AppBridge.exist() ? 'Boost organic traffic effortlessly with SearchPie – the ultimate SEO solution' : 'Landing Page Builder - Create professional, high-converting Shopify pages with ease.'} 
              </div>
              <div className="button">
                <Button
                  onClick={() => {
                    utils.routeListen(Landing_Page_Builder_Route)
                    utils.routeJump1(router, Landing_Page_Builder_Route)
                  }}
                >
                  View more
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Page>
    </div>
  )

  return (
    <div>
      {pageContent}
    </div>
  );
};

export default CustomServiceClass;
