import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@shopify/polaris";
import './index.css'
const Index = ({ price,shopifyPrice}) => {
  const userData = useSelector((state) => state.languageReducer.subScriptData);
  if (shopifyPrice) {
    if (userData.current === "USD" || price <= 0) {
      return (<span style={{ fontWeight: 700 }}>$ {price}</span>);
    }else {
      return (
        <span style={{ fontWeight: 700 }} className="symbol_take">
        <Tooltip
          content={
            userData.symbol + " " + price + " "+ userData.current
          }
        >
        <span> $ {(price * userData.current_rate).toFixed(2)}</span>
        </Tooltip>
        </span>
    );
    }
  }else if (userData.current === "USD" || price <= 0) {
    return (<span style={{ fontWeight: 700 }}>$ {price}</span>);
  } else
    return (
        <span style={{ fontWeight: 700 }} className="symbol_take">
        <Tooltip
          content={
            userData.symbol + " " + (price / userData.current_rate).toFixed(2) + " "+ userData.current
          }
        >
        <span> $ {price}</span>
        </Tooltip>
        </span>
    );
};

export default Index;
