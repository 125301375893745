import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { 
  Modal, Toast ,Banner  , Thumbnail, Button,TextField,Popover,FormLayout,Select,Link
} from '@shopify/polaris';
import utils from '../../../../utils/utils'
import { reqGetNotifyProductVariants,reqPostUpdateAutoSettings,reqPostNotifyAction } from '../../../../api';

import intl from '../../../../i18n/intl'

class UpdatePriceModal extends Component {

    constructor(props) {
      super(props);
      this.state = {
        ModalShow: (props.show===undefined)?false:props.show,

        fatherKey: props.fatherKey,
        productTitle: props.title,
        productImage: props.image,
        EditOnShopify: props.EditOnShopify,
        productStockVariants: props.stockVariants,
        productVariants: (props.variants===undefined)?[]:props.variants,
        isSettingAutoUpdate: props.isSettingAutoUpdate,
        isUpdateSuccess: props.isUpdateSuccess,
        isCurrencyConvert: props.isCurrencyConvert,
        symbol: props.symbol,

        popoverPriceActive: false,
        popoverComparePriceActive: false,
        SetPriceValue: '', 
        SetComparePriceValue: '',
        isChangeValue: false,

        //弹窗
        showToast: false,  //弹窗显示
        showToastText:'',  //弹窗文字
        showToastIserror:false, //弹窗是否错误

        doNotButtonLoading: false,
        doNotButtonSelected: true,
        autoPriceAndCompareLoading: false,
        autoPriceAndCompareSelected: false,
        autoOnlyPriceButtonLoading: false,
        autoOnlyPriceButtonSelected: false,
        updateButtonLoading: false,
        // 是否批量设置价格 price / Compared At Price
        isBulkSettingPrice: true,
        BulkSettingPriceType: '1', // 设置类型1：统一价格值,2:价格倍数因子c ,price*c

        loadingData: false,
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        ModalShow: (nextProps.show===undefined)?false:nextProps.show,
        fatherKey: nextProps.fatherKey,
        productTitle: nextProps.title,
        productImage: nextProps.image,
        EditOnShopify: nextProps.EditOnShopify,
        productStockVariants: nextProps.stockVariants,
        productVariants: (nextProps.variants===undefined)?[]:nextProps.variants,
        isSettingAutoUpdate: nextProps.isSettingAutoUpdate,
        isUpdateSuccess: nextProps.isUpdateSuccess,
        isCurrencyConvert: nextProps.isCurrencyConvert,
        symbol: nextProps.symbol,

        popoverPriceActive: false,
        popoverComparePriceActive: false,
        SetPriceValue: '', 
        SetComparePriceValue: '',
        isChangeValue: false,

        //弹窗
        showToast: false,  //弹窗显示
        showToastText:'',  //弹窗文字
        showToastIserror:false, //弹窗是否错误

        doNotButtonLoading: false,
        doNotButtonSelected: true,
        autoPriceAndCompareLoading: false,
        autoPriceAndCompareSelected: false,
        autoOnlyPriceButtonLoading: false,
        autoOnlyPriceButtonSelected: false,
        updateButtonLoading: false,
        // 是否批量设置价格 price / Compared At Price
        isBulkSettingPrice: true,
        BulkSettingPriceType: '1', // 设置类型1：统一价格值,2:价格倍数因子c ,price*c

        loadingData: false,
      },()=>{
        this.getVariantsData();
      });
    }

    //进入页面调用接口
    componentDidMount () {
      this.getVariantsData();
    }

    // 获取variants列表
    getVariantsData = async() =>{
      this.setState({ loadingData: true });
      const params = {
        nid: this.props.notificationProductTableId,
        sid: this.props.shopifyProductTableId
      };
      const response = await reqGetNotifyProductVariants(params);
      this.setState({ loadingData: false });

      if(response.code === '200'){
        let getData = response.data;
        this.setState({
          productStockVariants: getData.VariantStock,
          productVariants: getData.productVariants,
        });

      }else{
        this.toggleToast(response.msg);
      }
    }

    //提示弹窗
    toggleToast = (showToastText, showToastIserror=false) => {
      this.setState(({ showToast }) => ({
        showToast: !showToast,
        showToastText: showToastText,
        showToastIserror: showToastIserror
      }));
    };

    // 价格修改
    handleInputChange = (childKey,type,value) =>{
      let data = this.state.productVariants;
      data[childKey][type] = value;
      this.setState({
        productVariants: data,
        isChangeValue: true,
      });
    }

    // 弹窗控制
    handleChangeModal = () =>{
      this.setState({
        ModalShow:!this.state.ModalShow,
      });
    }

    // 批量修改价格-[现价]弹窗
    togglePopoverPriceActive = () =>{
      this.setState(({ popoverPriceActive }) => ({ popoverPriceActive: !popoverPriceActive, BulkSettingPriceType: '1'}));
    }

    // 批量修改原价弹窗
    togglePopoverComparePriceActive = () =>{
      this.setState(({ popoverComparePriceActive }) => ({ popoverComparePriceActive: !popoverComparePriceActive, BulkSettingPriceType: '1'}));
    }

    // 选择批量修改类型
    handleSelectChange = (type,value) =>{
      let isSetPrice = true;
      if(type === 'compareAtPrice'){
        isSetPrice = false;
      }
      this.setState({
        isBulkSettingPrice: isSetPrice,
        BulkSettingPriceType: value
      });
    }

    // 设置批量赋值变量
    handleBatchEditChange = (type,value) =>{
      if(type === 'price'){
        this.setState({
          SetPriceValue: value,
        });
      }

      if(type === 'compareAtPrice'){
        this.setState({
          SetComparePriceValue: value,
        });
      }
    }

    // 批量修改[价格、原价]的值
    handleBatchEditDone = (type) =>{
      let setValue = '';
      if(type === 'price'){
        setValue = this.state.SetPriceValue;
      }
      if(type === 'compareAtPrice'){
        setValue = this.state.SetComparePriceValue;
      }
      if(setValue !== ''){
        let data = this.state.productVariants;
        for(let i=0; i<data.length; i++){
          if(this.state.BulkSettingPriceType === '1'){  // 批量修改类型 1: Set Value 2: Multiply By
            data[i][type] = parseFloat(setValue).toFixed(2);
          }else{
            data[i][type] = (parseFloat(data[i].newCost) * parseFloat(setValue)).toFixed(2);
          }
        }
        this.setState({
          productVariants: data,
          SetPriceValue: '',
          SetComparePriceValue: '',
          popoverPriceActive: false,
          popoverComparePriceActive: false,
          isChangeValue: true,
        });
      }
    }

    // 更新自动更新的配置
    updateAutoSetting = async (type,selected) =>{
      if(selected === 1){
        this.setState({
          doNotButtonLoading: true,
          doNotButtonSelected: true,
          autoPriceAndCompareLoading: false,
          autoPriceAndCompareSelected: false,
          autoOnlyPriceButtonLoading: false,
          autoOnlyPriceButtonSelected: false,
        });
      }
      if(selected === 2){
        this.setState({
          doNotButtonLoading: false,
          doNotButtonSelected: false,
          autoPriceAndCompareLoading: true,
          autoPriceAndCompareSelected: true,
          autoOnlyPriceButtonLoading: false,
          autoOnlyPriceButtonSelected: false,
        });
      }
      if(selected === 3){
        this.setState({
          doNotButtonLoading: false,
          doNotButtonSelected: false,
          autoPriceAndCompareLoading: false,
          autoPriceAndCompareSelected: false,
          autoOnlyPriceButtonLoading: true,
          autoOnlyPriceButtonSelected: true,
        });
      }

      const params = {type: type,selected: selected};
      const response = await reqPostUpdateAutoSettings(params);
      if (response.code === "200") {
        this.setState({
          doNotButtonLoading: false,
          autoPriceAndCompareLoading: false,
          autoOnlyPriceButtonLoading: false,
        },()=>{
          // 更新父级状态
          this.props.childUpdate({childType:'cost',recordKey: this.state.fatherKey,data:{selected:selected}});
        });

        this.toggleToast(response.msg,false);

      } else{
        this.setState({
          doNotButtonLoading: false,
          doNotButtonSelected: false,
          autoPriceAndCompareLoading: false,
          autoPriceAndCompareSelected: false,
          autoOnlyPriceButtonLoading: false,
          autoOnlyPriceButtonSelected: false,
        });
        this.toggleToast("please refresh network");
      }
    }

    // 更新价格操作
    handleUpdatePriceDone = async() =>{
      this.setState({updateButtonLoading: true});
      const params = {
        type: 'updateCost',
        isBatch: 0,
        data: JSON.stringify(this.state.productVariants)
      };
      const response = await reqPostNotifyAction(params);
      this.setState({updateButtonLoading: false});
      if (response.code === "200") {

        this.handleChangeModal();

        // 父级重新加载数据
        this.props.childUpdate({childType:'reload',recordKey: this.state.fatherKey,data:{}});

        this.toggleToast(response.msg, false);
        
      }else{
        this.toggleToast(response.msg);
      }
    }

    render() {
        const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
        const {
            state: { 
              ModalShow,productTitle,productImage,productStockVariants,productVariants,updateButtonLoading,EditOnShopify,isChangeValue,BulkSettingPriceType,isUpdateSuccess,
              // doNotButtonLoading,doNotButtonSelected,autoPriceAndCompareLoading,autoPriceAndCompareSelected,autoOnlyPriceButtonLoading,autoOnlyPriceButtonSelected,
              isCurrencyConvert,symbol,SetPriceValue, SetComparePriceValue,loadingData },
        } = this;

        // 显示库存字符串
        let _productStockVariants = '';
        try {
          if(productStockVariants !== undefined || productStockVariants !== ''){
            if(productStockVariants.variantCount > 0){
              // let _variantText = (productStockVariants.variantCount > 1)?' variants':' variant';
              // _productStockVariants = productStockVariants.stockCount + ' in stock for '+ productStockVariants.variantCount + _variantText;
              _productStockVariants = intl.get("{{productStockVariants.stockCount}} in stock for {{productStockVariants.variantCount}} variant", productStockVariants.stockCount, productStockVariants.variantCount);
            }
          }
        } catch (error) {}

        // 显示该记录时候已执行自动更新 banner
        let BannerTip = '';
        if(isUpdateSuccess){
          BannerTip = (
            <Banner tone="success">
               
                <p style={{whiteSpace:'pre-wrap'}}>
                  {intl.get("You have set")} <Link onClick={()=>{
                    utils.routeListen('/admin/settings/notification')
                    utils.routeJump(this,'/admin/settings/notification')
                    }} monochrome>{intl.get("UPDATE PRICE AND COMPARED AT PRICE")}</Link> {intl.get("when the product cost changes.")} 
                  <br />{intl.get("We've already changed the Price & Compared at Price for this product, calculated using your pricing rules.")}
                </p>
               
            </Banner>
          );
        }else{
          BannerTip = (
            <Banner tone="warning" >
               
                <p style={{whiteSpace:'pre-wrap'}}>
                  {intl.get("You have set")} <Link onClick={()=>{
                    utils.routeListen('/admin/settings/notification')
                    utils.routeJump(this,'/admin/settings/notification')
                    }} monochrome>{intl.get("DO NOTHING")}</Link> {intl.get("when the product cost changes.")} 
                </p>
               
            </Banner>
          );

          // BannerTip = (
          //   <Banner
          //     // title=""
          //     status="warning"
          //   >
          //      
          //       <p style={{whiteSpace:'pre-wrap'}}>
          //         Setting for price change
          //       </p>
          //      
              
          //     <div style={{marginTop:'13px'}}>
          //       <ButtonGroup>
          //         <Button 
          //           size="slim" 
          //           onClick={this.updateAutoSetting.bind(this,'cost',1)} 
          //           primary={doNotButtonSelected}
          //           loading={doNotButtonLoading}
          //           disabled={(autoPriceAndCompareLoading || autoOnlyPriceButtonLoading)?true: false}
          //         >
          //           Do Nothing
          //         </Button>
          //         <Button 
          //           size="slim" 
          //           onClick={this.updateAutoSetting.bind(this,'cost',2)}
          //           primary={autoPriceAndCompareSelected}
          //           loading={autoPriceAndCompareLoading}
          //           disabled={(doNotButtonLoading || autoOnlyPriceButtonLoading)? true: false}
          //         >
          //           Update Price and compare at Price
          //         </Button>
          //         <Button 
          //           size="slim" 
          //           onClick={this.updateAutoSetting.bind(this,'cost',3)}
          //           primary={autoOnlyPriceButtonSelected}
          //           loading={autoOnlyPriceButtonLoading}
          //           disabled={(doNotButtonLoading || autoPriceAndCompareLoading)? true: false}
          //         >
          //           Update Price Only
          //         </Button>
          //       </ButtonGroup>
          //     </div>
          //   </Banner>
          // );
        }

        // 显示 variants 列表
        let tableTbodyTrContent = <tr></tr>;
        if(productVariants.length>0){
          tableTbodyTrContent = productVariants.map((item,key) =>{
            let trHtml = '';
            let priceChange = (Number(item.newCost) - Number(item.oldCost)).toFixed(2);
            let priceChangeString = String(priceChange).replace('-','');
            let priceChangeConventBefore = (Number(item.newCostConventBefore) - Number(item.oldCostConventBefore)).toFixed(2);
            let priceChangeConventBeforeString = String(priceChangeConventBefore).replace('-','');

            let symbolString = (priceChange > 0) ? '+'+symbol : '-'+symbol;
            let symbolStringConventBefore = (priceChangeConventBefore > 0) ? '+US$'+priceChangeConventBeforeString:'-US$'+priceChangeConventBeforeString;
            
            trHtml = (
              <tr key={key}>
                <td className="Variant_Sku">{item.shopifySku}</td>

                <td className="Variant_Old_Cost">
                  <div className="Product_Cost_View">
                    <span className="Conversion_Price"><span style={{display:'inline-block'}}>{symbol}</span>{item.oldCost}</span>
                    <span className="Original_Price">{(isCurrencyConvert===1)?'US$'+item.oldCostConventBefore:''}</span>
                  </div>
                </td>

                <td className="Variant_New_Cost">
                  <div className="Product_Cost_View">
                    <span className="Conversion_Price"><span style={{display:'inline-block'}}>{symbol}</span>{item.newCost}</span>
                    <span className="Original_Price">{(isCurrencyConvert===1)?'US$'+item.newCostConventBefore:''}</span>
                  </div>
                </td>

                <td className={(priceChange > 0)?"Price_Increase":"Price_Reduction"}>
                  <div className="Product_Cost_View">
                    <span className="Conversion_Price"><span style={{display:'inline-block'}}>{symbolString}</span>{priceChangeString}</span>
                    <span className="Original_Price">{(isCurrencyConvert===1)?symbolStringConventBefore:''}</span>
                  </div>
                </td>

                <td className="Variant_Price">
                  <div className="Product_Cost_View">
                    <TextField 
                      label="Price"
                      labelHidden
                      type="number"
                      step="0.01"
                      prefix={symbol}
                      value={String(item.price)}
                      onChange={this.handleInputChange.bind(this,key,'price')}
                    />
                    {/* <span className="Original_Price">{(isCurrencyConvert===1)?symbol+item.oldPrice:''}</span> */}
                  </div>
                </td>

                <td className="Variant_Compare_Price">
                  <div className="Product_Cost_View">
                    <TextField 
                      label="Price"
                      labelHidden
                      type="number"
                      step="0.01"
                      prefix={symbol}
                      value={String(item.compareAtPrice)}
                      onChange={this.handleInputChange.bind(this,key,'compareAtPrice')}
                    />
                    {/* <span className="Original_Price">{(isCurrencyConvert===1)?symbol+item.oldCompareAtPrice:''}</span> */}
                  </div>
                </td>
              </tr>
            );
            return (
              trHtml
            );
          });

        }else{
          tableTbodyTrContent = (
            <tr>
              <td colSpan="6">{intl.get("The list of product variants is empty")}</td>
            </tr>
          );
        }
        
        return (
            <div>
              <Modal 
                open={ModalShow} 
                onClose={this.handleChangeModal} 
                title={intl.get("Update Price")}
                primaryAction={{
                  content: intl.get("Update"),
                  onAction: this.handleUpdatePriceDone,
                  disabled: (productVariants.length>0 && isChangeValue)?false:true,
                  loading: updateButtonLoading
                }} 
                secondaryActions={[{
                  content: intl.get("Cancel"),
                  onAction: this.handleChangeModal
                }]}
                loading={loadingData}
                size='large'
              >
                <Modal.Section>

                  {BannerTip}

                  <div className="Original_Product_Box">
                    <div className="Original_Product_Image">
                      <Thumbnail
                        source={productImage}
                        alt="product"
                      />
                    </div>
                    <div className="Original_Product_Title">
                      <a href={EditOnShopify} target="_blank" className="Polaris-Link" rel="noopener noreferrer">
                        {productTitle}
                      </a>
                    </div>
                    <div className="Original_Product_Inventory">
                      {_productStockVariants}
                    </div>
                  </div>

                  <div className="Variant_Modal_Table">
                    <table style={{width:'100%'}}>
                      <thead>
                        <tr>
                          <th>{intl.get("Shopify Sku")}</th>
                          <th>{intl.get("Old Cost")}</th>
                          <th>{intl.get("New Cost")}</th>
                          <th>{intl.get("Changes")}</th>
                          <th>{intl.get("Price")}</th>
                          <th>{intl.get("Compare At Price")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="4"></td>
                          <td>
                            <Popover 
                              active={this.state.popoverPriceActive} 
                              activator={
                                <Button 
                                  onClick={this.togglePopoverPriceActive}
                                  disclosure
                                  disabled={(productVariants.length>0)?false:true}
                                >
                                  {intl.get("Change All Price")}
                                </Button>
                              } 
                              onClose={this.togglePopoverPriceActive}
                            >
                              <div style={{padding:'10px'}}>
                                <FormLayout>
                                  <Select
                                    label="Select Type"
                                    labelHidden
                                    options={[{label: intl.get("Set New Price"), value: '1'},{label: intl.get("Multiply By"), value: '2'}]}
                                    onChange={this.handleSelectChange.bind(this,'price')}
                                    value={BulkSettingPriceType}
                                  />
                                  <TextField
                                    label="Set Value"
                                    labelHidden
                                    type="number"
                                    step="0.01"
                                    value={SetPriceValue}
                                    onChange={this.handleBatchEditChange.bind(this,'price')}
                                  />
                                  <Button 
                                    size="slim" 
                                    onClick={this.handleBatchEditDone.bind(this,'price')} 
                                    disabled={SetPriceValue?false:true}
                                    variant='primary'
                                  >
                                    {intl.get("Apply")}
                                  </Button>
                                </FormLayout>
                              </div>
                            </Popover>
                          </td>
                          <td>
                            <Popover 
                              active={this.state.popoverComparePriceActive} 
                              activator={
                                <Button 
                                  onClick={this.togglePopoverComparePriceActive} 
                                  disclosure
                                  disabled={(productVariants.length>0)?false:true}
                                >
                                  {intl.get("Change All Price")}
                                </Button>
                              } 
                              onClose={this.togglePopoverComparePriceActive}
                            >
                              <div style={{padding:'10px'}}>
                                <FormLayout>
                                  <Select
                                    label="Select Type"
                                    labelHidden
                                    options={[{label: intl.get("Set New Price"), value: '1'},{label: intl.get("Multiply By"), value: '2'}]}
                                    onChange={this.handleSelectChange.bind(this,'compareAtPrice')}
                                    value={BulkSettingPriceType}
                                  />
                                  <TextField
                                    label="Set Price"
                                    labelHidden
                                    type="number"
                                    step="0.01"
                                    value={SetComparePriceValue}
                                    onChange={this.handleBatchEditChange.bind(this,'compareAtPrice')}
                                  />
                                  <Button 
                                    size="slim" 
                                    onClick={this.handleBatchEditDone.bind(this,'compareAtPrice')} 
                                    disabled={SetComparePriceValue?false:true}
                                    variant='primary'
                                  >
                                    {intl.get("Apply")}
                                  </Button>
                                </FormLayout>
                              </div>
                            </Popover>
                          </td>
                        </tr>

                        {tableTbodyTrContent}

                      </tbody>
                    </table>
                  </div>
                </Modal.Section>
              </Modal>

              { toastMarkup }
            </div>
        );
    }
}

export default withRouter(UpdatePriceModal);