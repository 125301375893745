import {
  Page,
  Card,
  Layout,
  BlockStack,
  Box,
  SkeletonPage,
  SkeletonBodyText,
  EmptyState,
  Button,
  Tabs,
  Icon
} from "@shopify/polaris";
import {ChevronRightIcon} from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react";
import "./index.css";
import intl from "../../../i18n/intl";
import utils from "../../../utils/utils";
import { useHistory, withRouter } from "react-router-dom";
import {reqGetPointsListData } from "../../../api";
import ChangeLoading from "../../../components/ChangeLoading";
// import AppBridge from "../../../utils/appBridge";
import ReferralsModal from "./component/ReferralsModal"
import FollowModal from "./component/FollowModal"
import TiktokIcon from "./component/TiktokIcon"
import YoutubeIcon from "./component/YoutubeIcon"
import FacebookIcon from "./component/FacebookIcon"
import InstagramIcon from "./component/InstagramIcon"
import LinkedInIcon from "./component/LinkedInIcon"
import PointsIcon from "./component/PointsIcon"
import BookOpenIcon from "./component/BookOpenIcon"
import HistoryTimeIcon from "./component/HistoryTimeIcon"

const Index = () => {
  const router = useHistory();
  
  const [initPageData,setInitPageData] = useState({
    isInit:true,
    pageLoading:false,
  })
  const [selected,setSelected] = useState(0);
  const [isNewer,setIsNewer] = useState(false)
  const [data,setData] = useState([
    {
      icon:'',
      title:'Referrals',
      content:'Refer others to use Dropshipman and earn 500 points for each successful referral. Referred users will also receive a 50% discount on their package.',
      button:'To Complete',
      link:'Record',
      isShow:true,
      points_text:'500 Points',
      img:'/sub/ponits_referrals_bg.png'
    },
    {
      icon:'',
      title:'Complete Beginner Tasks',
      content:'Completing beginner tasks earns you 700 points.',
      button:'Complete Now',
      link:'',
      points_text:'700 Points',
      isShow:true,
      img:''
    },
    {
      icon:'',
      title:'Order Payments',
      content:'Earn 1 point for every $1 USD spent on orders through Dropshipman.',
      button:'Pay Order',
      link:'',
      points_text:'1 Points / $1 USD spent',
      isShow:true,
      img:''
    },
    {
      icon:'',
      title:'Credit Recharge',
      content:'Top up $1 to Dropshipman credits and earn 1 point.',
      button:'Top Up Credits',
      link:'',
      points_text:'1 point / $1 USD Recharge',
      isShow:true,
      img:''
    },
    {
      icon:'',
      title:'Subscription Plans',
      content:'Subscribe to a plan to earn points: Basic Plan (99 points), Pro Plan (299 points), Plus Plan  (499 points), Premium Plan (1999 points).',
      button:'Subscribe to Plan',
      link:'',
      points_text:'Up to 1999 Points',
      isShow:true,
      img:'/sub/ponits_plan_bg.png'
    },
    {
      icon:'',
      title:'Social Media Following',
      content:`Follow Dropshipman's social media accounts to earn 100 points per follow.`,
      button:'Follow our Social Media',
      link:'',
      points_text:'100 Points',
      isShow:true,
      img:'/sub/ponits_social_bg.png'
    },
  ])
  const handleSelected = (ind)=>{
    setSelected(ind)
    if (ind === 0) {
      let list = data1;
      if (isNewer) {
          list = list.map((item,ind)=>{
            if (item.title === 'Complete Beginner Tasks') {
              return {...item,content:'Completing beginner tasks earns you 1000 points.',points_text:'1000 Points'}
            }else {
              return item
            }
          })
        setData(list)
      }else {
        setData(list)
      }
      getHistoryPage(0)
    }else {
      setData(data2)
    }
  }
  
  const [pageData, setPageData] = useState({})
  const getHistoryPage = async (val)=>{
    try {
      let res = await reqGetPointsListData();
      if (res.code === '200') {
        setIsNewer(res.data.new_guide === 1)
        let list = data1;
        if (res.data.new_guide === 1) {
          list = list.map((item,ind)=>{
            if (item.title === 'Complete Beginner Tasks') {
              return {...item,content:'Completing beginner tasks earns you 1000 points.',points_text:'1000 Points'}
            }else {
              return item
            }
          })
        }
        setData(list)
        setPageData(res.data);
      }
      setInitPageData({
        isInit:false,
        pageLoading:false,
        
      })
    } catch (error) {
      }
    
  }
  useEffect(() => {
    let isMount = false;
    // if(AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar('Dashboard','/admin/dashboard')
    // }
    if (!isMount) {
      getHistoryPage(1)
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [referralsData,setReferrlasData] = useState({
    isOpen:false,
    link:''
  })
  const [follow,setFollow] = useState({
    isOpen:false,
    data:[]
  })
  const handleCkick = (title)=>{
    if (title === 'Complete Beginner Tasks') {
      utils.routeListen("/admin/dashboard?points=1");
      utils.routeJump1(router,"/admin/dashboard?points=1");
    }
    
    if (title === 'Referrals') {
      setReferrlasData({
        isOpen:true,
        link:pageData?.share_url
      })
    }
    if (title === "Referrals_log") {
      utils.routeListen("/admin/referrals_log");
      utils.routeJump1(router,"/admin/referrals_log");
    }
    if (title === "ponits_log") {
      utils.routeListen("/admin/points_log");
      utils.routeJump1(router,"/admin/points_log");
    }
    if (title === 'Social Media Following') {
      let list = pageData?.media?.map((item)=>{
        if (item.name === 'tiktok') {
          item.icon = TiktokIcon
        }
        if (item.name === 'youtube') {
          item.icon = YoutubeIcon
        }
        if (item.name === 'facebook') {
          item.icon = FacebookIcon
        }
        if (item.name === 'instagram') {
          item.icon = InstagramIcon
        }
        if (item.name === 'linkedin') {
          item.icon = LinkedInIcon
        }
        return item
      })
      setFollow({
        isOpen:true,
        data:list
      })
    }
    if (title === 'Order Payments') {
      utils.routeListen("/admin/order_list");
      utils.routeJump1(router,"/admin/order_list");
    }
    
    if (title === 'Subscription Plans') {
      utils.routeListen("/admin/subscribe");
      utils.routeJump1(router,"/admin/subscribe");
    }
    if (title === 'Credit Recharge') {
      utils.routeListen("/admin/wallet");
      utils.routeJump1(router,"/admin/wallet");
    }
    if (title === 'Redeem Products') {
      utils.routeListen("/admin/redeem_products");
      utils.routeJump1(router,"/admin/redeem_products");
    }

    if (title === 'Deduct Order Payment') {
      utils.routeListen("/admin/order_list");
      utils.routeJump1(router,"/admin/order_list");
    }
    if (title === 'Redeem Coupons') {
      utils.routeListen("/admin/points_coupon");
      utils.routeJump1(router,"/admin/points_coupon");
    }
    if (title === 'Ai Optimization') {
      utils.routeListen("/admin/product_management?selectedView=0");
      utils.routeJump1(router,"/admin/product_management?selectedView=0");
    }
    if (title === 'Import Products') {
      utils.routeListen("/admin/product_selection?select_tab=0");
      utils.routeJump1(router,"/admin/product_selection?select_tab=0");
    }
    
    if (title === 'Post Sourcing Request') {
      utils.routeListen("/admin/sourcing");
      utils.routeJump1(router,"/admin/sourcing");
    }
    
  }
  const formatNumber = (value) => {
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }

    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  };
  const EmptyProductList = (
    <div className="emptyWithImg">
      <EmptyState
        heading={intl.get("Sorry, no results found.")}
        image="/empty.svg"
      >
      </EmptyState>
    </div>
  );
  const skeletonPage =  <SkeletonPage fullWidth={false}>
  <Layout>
   <Layout.Section>
   <Card>
   <BlockStack gap={400}>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   </BlockStack>
   </Card>
 </Layout.Section>
   </Layout>
 </SkeletonPage>
 const tabs = [
  {
    id: 'all-customers-1',
    content: 'Earn Points',
    accessibilityLabel: 'Earn Points',
    panelID: 'all-customers-content-1',
  },
  {
    id: 'accepts-marketing-1',
    content: 'Redeem Points',
    panelID: 'accepts-marketing-content-1',
  },
  
];
  return (
    <div className="points_page" style={{position:'relative'}}>
      {initPageData.isInit ? skeletonPage : <Page
        backAction={
          false
            ? undefined
            : {
                content: "",
                onAction: () => {
                  utils.routeListen("/admin/dashboard");
                  utils.routeJump1(
                    router,
                    "/admin/dashboard"
                  );
                },
              }
        }
        title={<div style={{ fontSize: 20 }}>{intl.get("Points")}</div>}
      >
        <Layout>
          <Layout.Section>
            <BlockStack gap={400}>
              <Box>
                <Card>
                  <Box className="points_top">
                   <Box className="points_top_left">
                   <Box>
                    <span>Available Points</span>
                    <strong onClick={()=>handleCkick('ponits_log')}>{formatNumber(pageData?.available_points)} <span><Icon source={ChevronRightIcon} tone="base" /></span></strong> 
                   </Box>
                   <Box>
                   <span>Points Value</span>
                   <strong>${formatNumber((Number(pageData.available_points) / 100).toFixed(2))}</strong>
                   </Box>
                   </Box>
                   <Box className="points_top_right">
                    <Box onClick={()=>{
                      window.open('https://www.dropshipman.com/blog/dropshipman-points-rules-and-terms',"_blank")
                    }}>
                    <Icon
                      source={BookOpenIcon}
                      tone="base"
                    />
                      <span>Policy</span>
                      </Box>
                    <Box onClick={() => {
                  utils.routeListen("/admin/points_log");
                  utils.routeJump1(
                    router,
                    "/admin/points_log"
                  );
                }}>
                  <Icon
                      source={HistoryTimeIcon}
                      tone="base"
                    /><span>History</span></Box>
                   </Box>
                  </Box>
                </Card>
              </Box>
              <Card>
              <Box className="points_tab">
              <Tabs tabs={tabs} selected={selected} onSelect={handleSelected}>
      
              </Tabs>
                   {/* <Box  className={selected === 1 ? "cheked points_tab_item" : "points_tab_item"} onClick={()=>handleSelected(1)}>
                    <span>Earn Points</span>
                   </Box>
                   <Box  className={selected === 2 ? "cheked points_tab_item" : "points_tab_item"} onClick={()=>handleSelected(2)}>
                   <span>Redeem Points</span>
                   </Box> */}
                  </Box>
              {(Object.values(pageData)?.length > 0 && selected === 0) ? <Box className="points_list_1">
                {data.slice(0,1).map((item,ind)=> <Box key={ind} className="points_item_1 ">
                  <Box className="points_item_1_left">
                  <Box className="points_icon_box">
                    <span className="points_item_icon"><Icon
                      source={PointsIcon}
                      tone="base"
                    /></span>
                    <span className="points_num">{item.points_text}</span>
                  
                  </Box>
                    <Box className="points_item_title_1">
                      <h2>{item.title}</h2>
                    </Box>
                    <Box className="points_item_refer">
                      <strong>{pageData?.referral_count}</strong>
                      <span style={{marginRight:20}}>Successful {Number(pageData?.referral_count) > 1 ? 'referrals' : 'referral'}</span>
                      <Button variant="plain" onClick={()=>handleCkick('Referrals_log')}>{item.link}</Button>
                    </Box>
                    <Box className="points_item_desc">
                      {item.content}
                    </Box>
                    <Box className="points_item_button">
                      <Button onClick={()=>handleCkick(item.title)}>Copy Link to refer</Button>
                    </Box>
                    {/* <Box className="points_item_link"><span onClick={()=>handleCkick('Referrals_log')}>{item.link}</span></Box> */}
                    </Box>
                    <Box className="points_item_right">
                      <Box className="points_item_right_img">
                        <img src={item?.img} alt="Dropshipman_points" />
                      </Box>
                    </Box>
                  </Box>)}
                  <Box className="points_list_item_1">
                  {data.slice(1,4).map((item,ind)=> <Box key={ind} className="points_list_item_1_item">
                    
                  <Box className="points_icon_box">
                    <span className="points_item_icon"><Icon
                      source={PointsIcon}
                      tone="base"
                    /></span>
                    <span className="points_num">{item.points_text}</span>
                  </Box>
                    <Box className="points_item_title_1">
                      <h2>{item.title}</h2>
                    </Box>
                    <Box className="points_item_desc">
                      {item.content}
                    </Box>
                    <Box className="points_item_button">
                      <Button onClick={()=>handleCkick(item.title)}>{(item.title === 'Complete Beginner Tasks' && pageData?.is_guide) ? 'View' : item.button}</Button>
                    </Box>
                    <Box className="points_item_link"><span onClick={()=>handleCkick('Referrals_log')}>{item.link}</span></Box>
                  </Box>)}
                  </Box>
                  {data.slice(4,6).map((item,ind)=> <Box key={ind} className="points_item_1 points_item_1_box">
                  <Box className="points_item_1_left">
                  <Box className="points_icon_box">
                    <span className="points_item_icon"><Icon
                      source={PointsIcon}
                      tone="base"
                    /></span>
                    <span className="points_num">{item.points_text}</span>
                  </Box>
                    <Box className="points_item_title_1">
                      <span></span>
                      <h2>{item.title}</h2>
                    </Box>
                    <Box className="points_item_desc">
                      {item.content}
                    </Box>
                    <Box className="points_item_button">
                      <Button onClick={()=>handleCkick(item.title)}>{item.button}</Button>
                    </Box>
                    </Box>
                    <Box className="points_item_right">
                      <Box className="points_item_right_img">
                        <img src={item?.img} alt="Dropshipman_points" />
                      </Box>
                    </Box>
                  </Box>)}
                  
              </Box>: selected === 1 ? <Box className="points_list">
                  {data.map((item,ind)=> <Box key={ind} className="points_item">
                    <Box className="points_item_img" style={{backgroundImage: `url(${item?.img})`}}>
                    {/* <img src={item?.img} alt="Dropshipman_points" /> */}
                    </Box>
                    <Box className="points_item_content">
                    <Box className="points_item_title">
                      <h2>{item.title}</h2>
                    </Box>
                    <Box className="points_item_desc">
                      {item.content}
                    </Box>
                    <Box className="points_item_button">
                      <Button onClick={()=>handleCkick(item.title)}>{item.button}</Button>
                    </Box>
                    </Box>
                  </Box>)}
              </Box>  : <Box>
                <Card>
                  {EmptyProductList}
                </Card>
                </Box>}
                </Card>
            </BlockStack>
            <ReferralsModal referralsData={referralsData} setReferrlasData={setReferrlasData}/>
            <FollowModal follow={follow} setFollow={setFollow}/>
          </Layout.Section>
        </Layout>
      </Page>}
      {initPageData.pageLoading && <ChangeLoading />}
    </div>
  );
};
const data1 = [
  {
    icon:'',
    title:'Referrals',
    content:'Refer others to use Dropshipman and earn 500 points for each successful referral. Referred users will also receive a 50% discount on their package.',
    button:'To Complete',
    link:'Record',
    isShow:true,
    points_text:'500 Points',
    img:'/sub/ponits_referrals_bg.png'
  },
  {
    icon:'',
    title:'Complete Beginner Tasks',
    content:'Completing beginner tasks earns you 700 points.',
    button:'Complete Now',
    link:'',
    points_text:'700 Points',
    isShow:true,
    img:''
  },
  {
    icon:'',
    title:'Order Payments',
    content:'Earn 1 point for every $1 USD spent on orders through Dropshipman.',
    button:'Pay Order',
    link:'',
    points_text:'1 Points / $1 USD spent',
    isShow:true,
    img:''
  },
  {
    icon:'',
    title:'Credit Recharge',
    content:'Top up $1 to Dropshipman credits and earn 1 point.',
    button:'Top Up Credits',
    link:'',
    points_text:'1 point / $1 USD Recharge',
    isShow:true,
    img:''
  },
  {
    icon:'',
    title:'Subscription Plans',
    content:'Subscribe to a plan to earn points: Basic Plan (99 points), Pro Plan (299 points), Plus Plan  (499 points), Premium Plan (1999 points).',
    button:'Subscribe to Plan',
    link:'',
    points_text:'Up to 1999 Points',
    isShow:true,
    img:'/sub/ponits_plan_bg.png'
  },
  {
    icon:'',
    title:'Social Media Following',
    content:`Follow Dropshipman's social media accounts to earn 100 points per follow.`,
    button:'Follow our Social Media',
    link:'',
    points_text:'100 Points',
    isShow:true,
    img:'/sub/ponits_social_bg.png'
  },
]
const data2 = [
  {
    icon:'',
    title:'Deduct Order Payment',
    content:'1 point can be redeemed for $0.01 off your order, up to a maximum of 50% of the total order amount.',
    button:'Pay for Order',
    isShow:true,
    img:'/sub/points_payment.png'
  },
  {
    icon:'',
    title:'Redeem Coupons',
    content:'Redeem points for coupons that can be used for order payments.',
    button:'Redeem Coupon',
    isShow:true,
    img:'/sub/points_coupon.png'
  },
  {
    icon:'',
    title:'Redeem Products',
    content:`Points can be used to redeem products, you'll only need to cover the shipping costs and VAT.`,
    button:'Redeem Product',
    isShow:true,
    img:'/sub/points_product.png'
  },
  {
    icon:'',
    title:'Import Products',
    content:'If you haven’t upgraded your plan, 150 points can be used to import 1 Dropshipman product.',
    button:'Import Product',
    isShow:true,
    img:'/sub/points_import.png'
  },
  {
    icon:'',
    title:'Post Sourcing Request',
    content:'When your sourcing limit is insufficient, use 200 points to gain an additional sourcing attempt.',
    button:'Sourcing Request',
    isShow:true,
    img:'/sub/points_sourcing.png'
  },
  {
    icon:'',
    title:'Ai Optimization',
    content:'Redeem 20 points for an AI-powered optimization of product titles or descriptions.',
    button:'Optimize Now',
    isShow:true,
    img:'/sub/points_ai.png'
  },
]

export default withRouter(Index);
