import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Page, Tabs, Toast } from "@shopify/polaris";
import CommonFilterBox from "./component/CommonFilterBox";
import CommonWinningFilterBox from "./component/CommonWinningFilterBox";
import SourcingRequestBox from "./SourcingRequest/SourcingRequestBox";
import ImportProductBox from "./ImportProduct/Index";
import FirstTimeTips from "./component/FirstTimeTips";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom";
import PersonalAgentModal from "../SubscriptionPaid/components/PersonalAgentModal";
import intl from "../../../i18n/intl"
import { reqGetSubcribeData, reqGetUserPoints, reqGetUserSubcribe, reqGoogleAnalytics, reqPostSubcribeData } from "../../../api";
import SourcingRequestModal from "./SourcingRequest/component/SourcingRequestModal";
import NoviceSourcingModal from "../../../components/NoviceGuidance/NoviceSourcingModal";
import { store } from "../../../store";
import { changeSourcingData } from "../../../slice";
import AppBridge from "../../../utils/appBridge";
import EnoughQuotaModal from "./SourcingRequest/component/EnoughQuotaModal";
import EnoughPointsModal from "./SourcingRequest/component/EnoughPointsModal";
import { useSelector } from "react-redux";
import utils from "../../../utils/utils";
const ProductSelectionClass = () => {
  const tabs = [
    {
      id: "all-customers-1",
      content: "Marketplace",
      accessibilityLabel: "Marketplace",
      panelID: "all-customers-content-1",
    },
    {
      id: "accepts-marketing-1",
      content: "Winning Product",
      panelID: "accepts-marketing-content-1",
    },
    {
      id: "repeat-customers-1",
      content: "Sourcing Request",
      panelID: "repeat-customers-content-1",
    },
    {
      id: "prospects-1",
      content: "Product Importer",
      panelID: "prospects-content-1",
    },
  ];
  const targetRef = useRef(null); // 用于获取目标元素的引用
  const [showPopup, setShowPopup] = useState(false); // 控制弹出框显示
  const [typeTarget, setTypeTarget] = useState("collect");
  const [popupStyle, setPopupStyle] = useState({}); // 动态弹出框样式
  const handleTogglePopup = (val) => {
    const targetElement = targetRef.current;

    if (targetElement && !val) {
      const rect = targetElement.getBoundingClientRect();
      setTypeTarget('collect')
      // 计算弹出框的位置
      const style = {
        left: rect.left + window.scrollX + "px", // 加上滚动偏移
        top: rect.bottom + window.scrollY + "px", // 在目标元素下方
      };

      setPopupStyle(style); // 设置弹出框位置
      setShowPopup((prev) => !prev); // 显示或隐藏弹出框
    }else {
      let  style = {
        left: 250 + "px", // 加上滚动偏移
        top: 130 + "px", // 在目标元素下方
      };
      if (AppBridge.exist()) {
        style = {
          left: 10 + "px", // 加上滚动偏移
          top: 390 + "px", // 在目标元素下方
        };
      }
      setTypeTarget("add_product")
      setPopupStyle(style); // 设置弹出框位置
      setShowPopup((prev) => !prev); // 显示或隐藏弹出框
    }
  };

  useEffect(()=>{
    let isMount = false;
    if (!isMount && showPopup) {
      setTimeout(() => {
        setShowPopup(false)
      }, 4000);
    }
    return () => {
      isMount = true;
    };
  },[showPopup])
  const handleTabChange = (val) => {
    // 切换tab
    setSelected(val);
    setShowPopup(false);
    if (AppBridge.exist()) {
      if (Number(val) === 0) {
        utils.routeListen(`/admin/product_selection?select_tab=0`);
        utils.routeJump1(router,`/admin/product_selection?select_tab=0`)
      }else {
        utils.routeListen(`/admin/product_selection?select_tab=${val}`);
        utils.routeJump1(router,`/admin/product_selection?select_tab=${val}`)
      }
    }else {
      window.history.pushState(null, "", `?select_tab=${val}`);
    }
    
  };
  const router = useHistory();

  const getDefaultSelected = () => {
    const { search } = router.location;
    const urlParams = new URLSearchParams(search);
    const selectTab = Number(urlParams.get("select_tab"));
    const num = !isNaN(selectTab) && selectTab > 0 && selectTab <= 3 ? selectTab : 0;
    // 验证 select_tab 是否有效
    return num;
  };

  const [selected, setSelected] = useState(getDefaultSelected);
  // 初始化时运行副作用
  useEffect(() => {
    let isMount = false;
    const { search } = router.location;
    let url = new URLSearchParams(search);
    let select_tab = url.get("select_tab");
    if (!isMount) {
      if (select_tab && !isNaN(Number(select_tab))) {
        // 获取select_tab参数 重新设置tab
        if (Number(select_tab) <= 0 || Number(select_tab) > 3) {
          setSelected(0);
          window.history.replaceState(null, "", window.location.pathname);
        } else {
          setSelected(Number(select_tab));
          window.history.pushState(null, "", `?select_tab=${select_tab}`);
        }
      } else {
        if (select_tab && (Number(select_tab) <= 0 || Number(select_tab) > 3)) {
          setSelected(0);
          window.history.replaceState(null, "", window.location.pathname);
        }
      }
    
      getSubscribeList(); // 获取订阅列表
      getUserPoints(); // 获取用户积分
      getUserPlan(); // 获取套餐权益次数
    }
    return () => {
      isMount = true;
    };
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    // 会员限制
    const [planData,setPlanData] = useState({
        sourcingLimit: 0, // 当前sourcing次数
        sourcingDay: 3, // sourcing限制次数
        productLimit:0,// 当前产品次数
        productDay: 10,// 日产品次数
        aliexpressLimit: 0,// 当前速卖通导入次数
        aliexpressDay: 10,// 速卖通x限制导入次数
      })
      // 积分剩余
      const [userPoints,setUserPoints] = useState({
        userPointsData:{
          available_points:0,
          total_points:0
        },
        userPointsRedeem:{
          open:false,
          id:0,
          isDetail:false,
          is_new:0,
          isPoints:false
        },
      })
  
  // 获取积分
  const getUserPoints = async () => {
    try {
      let res = await reqGetUserPoints();
      if (res.code === "200") {
        setUserPoints((prevValue) => ({
          ...prevValue,
          userPointsData: res.data,
        }));
      }
    } catch (error) {}
  };

//   获取套餐功能次数
  const getUserPlan = async ()=>{
    let response = await reqGetUserSubcribe()
        if (response.code === "200") {
          setPlanData((prevValue)=>({
            ...prevValue,
            sourcingLimit: Object.values(response?.data?.limit).length > 0 ?  response?.data?.limit?.sourcing + response?.data?.limit?.sourcing_job : 0,
            sourcingDay: response?.data?.day?.sourcing_num,
            productLimit: Number(response?.data?.limit?.dsm),
            productDay: Number(response?.data?.day?.dsm_product_num),
            aliexpressLimit: Object.values(response?.data?.limit).length > 0 ?  Number(response?.data?.limit?.aliexpress + response?.data?.limit?.aliexpress_job) : 10,
            aliexpressDay: response?.data?.day?.ali_product_num
          }))
        }
  }

  // 获取当前时间
  const getCurrentTime = () => {
    const timestamp = Date.parse(new Date()) / 1000;
    return timestamp;
  };
  const getTimeData = (userData, data) => {
    // 1. 全员套餐存在 闹钟无
    // 2. 全员套餐存在 闹钟套餐存在
    let flag = false;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        // 无活动
        if (
          getCurrentTime() > data[i].discount_time &&
          userData.event_active_time === ""
        ) {
          flag = false;
        }
        // 无闹钟
        if (
          getCurrentTime() < data[i].discount_time &&
          userData.event_active_time === ""
        ) {
          flag = false;
        }

        // 同时存在
        if (
          getCurrentTime() < data[i].discount_time &&
          getCurrentTime() < userData.event_active_time &&
          userData.event_active_time !== ""
        ) {
          if (data[i].discount >= userData.event_discounts) {
            flag = true;
          } else {
            flag = false;
          }
        }
        // 闹钟套餐
        if (
          getCurrentTime() > data[i].discount_time &&
          getCurrentTime() < userData.event_active_time &&
          userData.event_active_time !== ""
        ) {
          flag = true;
        }
        // 黑五活动
        // if (getCurrentTime() < data[i].discount_time || getCurrentTime() < userData.event_active_time) {
        //   if (data[i].discount || userData.event_discounts) {
        //     black = true
        //   }
        // }
      }
    }
    return flag;
  };
  // 高级套餐
  const [premiumData, setPremiumData] = useState({
    open: false,
    agent_list: [],
  });
  const handlePremiumOff = () => {
    setPremiumData((prevValue) => ({
      ...prevValue,
      open: false,
    }));
  };
  // 升级套餐
  const handleMageUpgrade = async (
    id,
    val,
    email = "",
    social = "",
    am_id = ""
  ) => {
    try {
      let param = {
        package_id: id,
        email: email,
        social: social,
        am_id: am_id,
      };
      if (val && val !== "") {
        param.remark = val;
      }
      let res = await reqPostSubcribeData(param);
      if (res.code === "200") {
        if (res?.data?.id !== "") {
          if (id !== 0) {
            window.top.location.href = res.data.url;
            toastFun(res.msg);
          }
        }
      } else {
        toastFun(intl.get("Something wrong"));
      }
    } catch (error) {}
  };
  const [subList, setSubList] = useState(defalutData.subList); //套餐列表

  const getSubscribeList = async () => {
    let res = await reqGetSubcribeData();
    // res = subData;
    if (res.code === "200") {
      setPremiumData((prevValue) => ({
        ...prevValue,
        agent_list: res.data.agent_list,
      }));
      let currentSub = subList.map((it, k) => {
        let discount = it;
        for (let j = 0; j < res.data.package.length; j++) {
          if (k === j) {
            discount = { ...discount, ...res.data.package[j] };
          }
        }
        getTimeData(res.data.user_discounts, res.data.package);
        return {
          ...discount,
          user_discounts: res.data.user_discounts,
          level: res.data.level,
          pay_level: res.data.bill_level,
          first_purchase: res.data.first_purchase,
          is_zt: res.data.is_zt,
        };
      });

      switch (res.data.bill_level) {
        case 0:
          let list0 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;
            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (res.data.trial_days > 0 && ind !== 3) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list0);
          break;
        case 1:
          let list1 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;

            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                ((ind >= 2 && ind < 3) || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (
                res.data.trial_days <= 0 &&
                ((ind >= 2 && ind < 3) || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list1);
          break;
        case 2:
          let list3 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;
           
            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (res.data.trial_days > 0 && (ind === 1 || ind === 4)) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (res.data.trial_days <= 0 && ind === 4) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list3);
          break;
        case 4:
          let list4 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;

            if (ind === 3) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                (ind === 1 || ind === 2 || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (res.data.trial_days <= 0 && ind >= 1 && ind !== 3) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list4);
          break;
        case 5:
          let list5 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;

            if (ind === 4) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                (ind === 1 || ind === 2 || ind === 3)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list5);
          break;
        default:
          break;
      }
    //   setLoading(false);
    } else {
    //   setLoading(false);
    }
  };

  const plan_level = useSelector(
    (state) => state.languageReducer.subScriptData
  );
// 次数不足
const [sourcingLimitModal,setSourcingLimitModal] = useState(false)

// 达到上限
const [enoughPoint,setEnoughPoint] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [noviceModal,setNoviceModal] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {// 关闭弹窗
    setIsPopupOpen(false);
  };

   // 打开sourcing弹窗，详情弹窗
   const handleSourcingModal = () => {
    //Sourcing页面sourcing按钮点击gtag
    reqGoogleAnalytics('click', 'sourcing_sourcing_click');
    // 查看sourcing详情
    
    try {
    
    if (planData.sourcingLimit >= planData.sourcingDay) {
      if ((plan_level.bill_level === 5 || plan_level.level === 5) && userPoints.userPointsData.available_points < 200) {
          setEnoughPoint(true)
          return
      }else {
        setSourcingLimitModal(true)
      }
      
    }else {
      if (AppBridge.exist()) {
        utils.routeListen('/admin/product_selection/sourcing-detail')
        utils.routeJump1(router,'/admin/product_selection/sourcing-detail',{isPoints:false,productTitle})
        store.dispatch(changeSourcingData({sourcing:{
          show:true,
        }}));
      }else {
        openPopup()
      }
    }
  } catch (error) {
      
  }
  }
  const [productTitle,setProductTitle] = useState('')
  const handleSubmit = (val) => {
    if (val) {
        if (val === 5) {
            setPremiumData((prevValue) => ({
                ...prevValue,
                open: true,
              }));
            setSourcingLimitModal(false)
        }
        if (val === 6) {
          setUserPoints((prevValue)=>({
            ...prevValue,
            userPointsRedeem:{
              ...prevValue.userPointsRedeem,
              isPoints:true
            }
          }))
          setSourcingLimitModal(false)
          if (AppBridge.exist()) {
            utils.routeListen('/admin/product_selection/sourcing-detail')
            utils.routeJump1(router,'/admin/product_selection/sourcing-detail')
            router.push("/admin/product_selection/sourcing-detail",{isPoints:true})
            store.dispatch(changeSourcingData({sourcing:{
              show:true,
            }}));
            
          }else {
            openPopup()
          }
        }
        if (val <5) {
          handleMageUpgrade(val)
        }
    }
  };
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  const toggleToast = useCallback(() => setToast((toast) => !toast), []);

  const toastMarkup = toast ? (
    <Toast content={toastMsg} onDismiss={toggleToast} error={toastError} />
  ) : null;

  const toastFun = (msg, error) => {
    setToastError(error);
    setToastMsg(msg);
    setToast(false);
    toggleToast();
  };
  
  const pageContent = (
    <div className="product_management_container">
      <Page fullWidth={true}>
        <Box className="product_selection_container_tab">
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
        </Box>
        {selected === 0 && (
          <CommonFilterBox
            handleTogglePopup={handleTogglePopup}
            targetRef={targetRef}
            planData={planData}
            setPlanData={setPlanData}
            userPoints={userPoints}
            setUserPoints={setUserPoints}
            premiumData={premiumData}
            setPremiumData={setPremiumData}
            handlePremiumOff={handlePremiumOff}
            handleMageUpgrade={handleMageUpgrade}
            subList={subList}
            toastFun={toastFun}
            handleSourcingModal={handleSourcingModal}
            setNoviceModal={setNoviceModal}
            setProductTitle={setProductTitle}
          />
        )}
        {selected === 1 && (
          <CommonWinningFilterBox
          handleTogglePopup={handleTogglePopup}
          targetRef={targetRef}
          planData={planData}
          setPlanData={setPlanData}
          userPoints={userPoints}
          setUserPoints={setUserPoints}
          premiumData={premiumData}
          setPremiumData={setPremiumData}
          handlePremiumOff={handlePremiumOff}
          handleMageUpgrade={handleMageUpgrade}
          subList={subList}
          toastFun={toastFun}
          handleSourcingModal={handleSourcingModal}
          setNoviceModal={setNoviceModal}
          setProductTitle={setProductTitle}
          />
        )}
        {selected === 2 && <SourcingRequestBox 
        planData={planData}
        setPlanData={setPlanData}
        userPoints={userPoints}
        setUserPoints={setUserPoints}
        premiumData={premiumData}
        setPremiumData={setPremiumData}
        handlePremiumOff={handlePremiumOff}
        handleMageUpgrade={handleMageUpgrade}
        subList={subList}
        toastFun={toastFun} />}
        {selected === 3 && <ImportProductBox  
        planData={planData}
        subList={subList}
        userPoints={userPoints} 
        setPlanData={setPlanData}
        setUserPoints={setUserPoints}
        premiumData={premiumData}
        setPremiumData={setPremiumData}
        handlePremiumOff={handlePremiumOff}
        handleMageUpgrade={handleMageUpgrade}/>}

        {/* 创建source */}
      {isPopupOpen && <SourcingRequestModal
          isOpen={isPopupOpen}
          onClose={closePopup}
          show={true}
          title={productTitle}
          updateCallBackFun={() => {
            setProductTitle("")
            setPlanData((prevValue)=>({
              ...prevValue,
              sourcingLimit: prevValue.sourcingLimit + 1,
            }))
            if (userPoints.userPointsRedeem.isPoints) {
              setUserPoints((prevValue)=>({
                ...prevValue,
                userPointsData:{
                  ...prevValue.userPointsData,
                  available_points:Number(prevValue.userPointsData.available_points) - 200
                }
              }))
              toastFun("Successfully Redeemed")
            }
          }}
          requestCreateCallBack={() => {}}
          handlePointsRedeemLess={() => {}}
          id={0}
          isPoints={userPoints.userPointsRedeem.isPoints}
          type={1}
          is_new={true}
          isDetail={false}
        />}
         {/* 次数不够 */}
        {sourcingLimitModal && <EnoughQuotaModal type={'sourcing'} handleSubmit={handleSubmit}  sourcingLimitModal={sourcingLimitModal} userPoints={userPoints} subList={subList} setSourcingLimitModal={setSourcingLimitModal}/>}
        {/* 会员上限且积分不够 */}
        {enoughPoint && <EnoughPointsModal type={'sourcing'} open={enoughPoint} setOpen={setEnoughPoint}/>}
        {/* 引导视频 */}
        {noviceModal && <NoviceSourcingModal upgradeOpen={noviceModal} setUpgradeOpen={()=>setNoviceModal(false)}/>}
        {/* agent选择 */}
        {premiumData.open && <PersonalAgentModal
          premiumData={premiumData}
          handlePremiumOff={handlePremiumOff}
          handleMageUpgrade={handleMageUpgrade}
        />}
        {/* 弹出框 */}
        {showPopup && (
          <FirstTimeTips typeTarget={typeTarget} popupStyle={popupStyle} setShowPopup={setShowPopup} />
        )}
      </Page>
    </div>
  );
  return (
    <div className="product_selection_container">
      {pageContent}
      {toastMarkup}
    </div>
  );
};


const defalutData = {
  subList: [
    {
      name: "FREE",
      dsc: intl.get("All essential startup features"),
      symbol: "$",
      title_icon: "/sub/free_cart.png",
      popular: "",
      price: "0.00",
      oldPrice: "",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      button: "Downgrade",
      link: "",
      id: 0,
      source: 3,
      aliexpress_product: 10,
      dsm_product:10,
      discount: 0,
      discount_time: 0,
      money: 0,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
    },
    {
      name: "PRO",
      dsc: intl.get("All necessary features for growing business"),
      symbol: "$",
      title_icon: "/sub/fly.png",
      popular: "",
      price: "29.9",
      oldPrice: "49",
      date: intl.get("Monthly"),
      isFree: false,
      type: 1,
      button: "7-day free trial",
      link: "",
      id: 1,
      discount: 0,
      dsm_product:1000,
      aliexpress_product: 1000,
      discount_time: 0,
      money: 29.9,
      source: 30,
      loading: false,
      disabled: false,
      level: 1,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
    },
    {
      name: "PLUS",
      dsc: intl.get("Powerful features for scaling your business"),
      symbol: "$",
      title_icon: "/sub/huojian.png",
      popular: intl.get("Highly Recommend"),
      price: "49.9",
      oldPrice: "79",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      id: 2,
      source: 60,
      dsm_product:5000,
      aliexpress_product: 5000,
      button: "7-day free trial",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 49.9,
      loading: false,
      disabled: false,
      level: 2,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
    },
    {
      name: "Basic",
      dsc: intl.get("All the basics for you to start a new business"),
      symbol: "$",
      title_icon: "/sub/cart.png",
      popular: "",
      price: "9.9",
      oldPrice: "19",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      source: 10,
      dsm_product:300,
      aliexpress_product: 300,
      id: 4,
      button: "Downgrade",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 9.9,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
    },
    {
      name: "PREMIUM",
      dsc: intl.get(
        "All Plus features, unlimited product imports, 1999 points, and Personal Agent VIP Services"
      ),
      symbol: "$",
      title_icon: "/sub/ufo.png",
      popular: "",
      price: "149",
      oldPrice: "199",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      source: 300,
      dsm_product:100000,
      aliexpress_product: 100000,
      id: 5,
      button: "Downgrade",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 149,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
      agent: [
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
      ],
    },
  ],
};
// const subData = {
//   "code": "200",
//   "msg": "success",
//   "data": {
//       "level": 0,
//       "bill_level": 0,
//       "bill_time": 0,
//       "trial_days": 7,
//       "trial_time": 0,
//       "package": [
//           {
//               "name": "FREE",
//               "money": 0,
//               "discount": 1,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 3,
//                   "ali_product_num": 10,
//                   "dsm_product_num": 10,
//                   "temu_product_num": 10,
//                   "coupon_num": 0,
//                   "salesman": false
//               }
//           },
//           {
//               "name": "PRO",
//               "money": 29.9,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 30,
//                   "ali_product_num": 1000,
//                   "dsm_product_num": 1000,
//                   "temu_product_num": 1000,
//                   "coupon_num": 0,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "PLUS",
//               "money": 49.9,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 60,
//                   "ali_product_num": 5000,
//                   "dsm_product_num": 5000,
//                   "temu_product_num": 5000,
//                   "coupon_num": 5,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "BASIC",
//               "money": 9.9,
//               "discount": 1,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 10,
//                   "ali_product_num": 300,
//                   "dsm_product_num": 300,
//                   "temu_product_num": 300,
//                   "coupon_num": 0,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "PREMIUM",
//               "money": 199,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 300,
//                   "ali_product_num": 100000,
//                   "dsm_product_num": 100000,
//                   "temu_product_num": 100000,
//                   "coupon_num": 5,
//                   "salesman": true
//               }
//           }
//       ],
//       "first_purchase": true,
//       "is_zt": false,
//       "user_discounts": {
//           "event_discounts": 0.8,
//           "event_plus_discounts": 0.8,
//           "event_basic_discounts": "",
//           "event_premium_discounts": "",
//           "event_active_time": 1734485766
//       },
//       "agent_list": [
//           {
//               "record_id": 21,
//               "name": "Alice Huang",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-16\/1723776339-22b99d484ee2ea4d5d41676b5397c369.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Professional",
//                   "Supportive"
//               ]
//           },
//           {
//               "record_id": 22,
//               "name": "Jenny Lin",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-13\/1723541851-18801030041b67ba4a09ad2387af21f9.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Experienced",
//                   "Trustworthy"
//               ]
//           },
//           {
//               "record_id": 24,
//               "name": "Sphoia Liu",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-11-20\/1732087667-c76ed97c1e228856ec2bb89fb2ac9080.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Reliable",
//                   "Attentive"
//               ]
//           },
//           {
//               "record_id": 26,
//               "name": "Amy Zhang",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-13\/1723541472-f557a5dd26c593311db6be5eac619689.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Dedicated",
//                   "Patient"
//               ]
//           }
//       ]
//   }
// }


export default withRouter(ProductSelectionClass);
