import React, { Component } from 'react';
import './index.css'
class LevelAction extends Component {
    render() {
        const {borderRadius ,font,img,bg} = this.props;
        return (
            <div className='level_action' onClick={(e)=> {
                e.preventDefault();
                this.props.callBack()
            }} style={{position:'absolute',top:0,left:'50%',transform:'translateX(-50%)',maxWidth:'563px',height:'100%',width:'100%',cursor:'pointer',background:bg?bg:'rgba(0,0,0,.3)',borderRadius:borderRadius}}>
                <div style={{position:'absolute',right:0,top:'-15px', display:'flex',alignItems:'center',padding:"2px 6px",background:"#202333",color:"#fff",borderRadius:"16px"}}>
                    <span style={{lineHeight:1}}><img style={{width:img}} src="/crown.png" alt="Dropshipman" /></span>
                    <span style={{fontSize:font}}>Advanced plan</span>
                </div>
            </div>
        );
    }
}

export default LevelAction;
