import React, { Component } from "react";
import intl from "../../../../i18n/intl";
import { withRouter } from "react-router-dom";
import utils from '../../../../utils/utils'
import {
  Page,
  Button,
  Card,
  Tabs,
  List,
  Pagination,
  EmptyState,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  Box,
  BlockStack,
  Toast,
} from "@shopify/polaris";
import moment from "moment";
import { reqCouponList, reqPostCouponFourthDraw } from "../../../../api";
// import FourthCouponBannerEvent from "../../../../components/FourthCouponBannerEvent";
// import { FOURTH_TEST_TIME } from "../../../../utils/constants";
class CouponList extends Component {
  state = {
    //页面动作
    selected: 0,
    loadingPage: true,
    ShowCountNumber: 0,
    hasPrevious: false,
    hasNext: true,
    page: 1,
    size: 10,
    total: 0,
    coupon: [],
    isRefreshPage: true,
    showToast: false,
    showToastText: '',
    showToastIserror: '',
    listData:{
      open:false,
      list:[
        {
          slelect:0,
          bg:'/sub/coupon_modal_item_bg.png',
          price:1,
          loading:false,
          num:10,
          title:'Available for order fulfillment',
          over:'Orders over $10 USD',
          time:'June 20, 2024 - Sep 20, 2024'
        },
        {
          slelect:0,
          bg:'/sub/coupon_modal_item_bg.png',
          price:2,
          loading:false,
          num:5,
          title:'Available for order fulfillment',
          over:'Orders over $20 USD',
          time:'June 20, 2024 - Sep 20, 2024'
        },
        {
          slelect:0,
          bg:'/sub/coupon_modal_item_bg.png',
          price:4,
          loading:false,
          num:5,
          title:'Available for order fulfillment',
          over:'Orders over $40 USD',
          time:'June 20, 2024 - Sep 20, 2024'
        }
      ],
      loading:false
    }
  };

  //进入页面调用接口
  componentDidMount() {
    setTimeout(() => {
      this.handleTabChange();
    }, 100);
  }

  handleTabChange = async (value = 0) => {
    this.setState({
      isRefreshPage: true,
    });

    let response = await reqCouponList({
      status: value === 1 ? 99 : value,
      page: this.state.page,
      size: this.state.size,
    });
    

    const maxPage = Math.ceil(response.num / this.state.size);
    let hasPrevious = this.state.page > 1;
    let hasNext = this.state.page < maxPage;

    this.setState({
      selected: value,
      ShowCountNumber: response.num,
      total: response.num,
      hasPrevious: hasPrevious,
      hasNext: hasNext,
      coupon: response.data,
      isRefreshPage: false,
      loadingPage:false
    });
    
  };

  
 

  handleSelect = async(val)=>{
    let list = this.state.listData.list;
      if (val === 0) {
        list[0].loading = true;
      }
      if (val === 1) {
        list[1].loading = true;
      }
      if (val === 2) {
        list[2].loading = true;
      }
      this.setState({
        listData:{
          ...this.state.listData,
          list:list,
        }
      })
    let res = await reqPostCouponFourthDraw({type:val});
    if (res.code === '200') {
      let list = this.state.listData.list;
      if (val === 0) {
        list[0].slelect = 1;
        list[0].loading = false;
      }
      if (val === 1) {
        list[1].slelect = 1;
        list[1].loading = false;
      }
      if (val === 2) {
        list[2].slelect = 1;
        list[2].loading = false;
      }
      this.toggleToast('Claim Successfully')
      this.setState({
        listData:{
          ...this.state.listData,
          list:list,
        }
      })
      
     
    }else {
      let list = this.state.listData.list;
      if (val === 0) {
        list[0].loading = false;
      }
      if (val === 1) {
        list[1].loading = false;
      }
      if (val === 2) {
        list[2].loading = false;
      }
      this.toggleToast('Claim Failed')
      this.setState({
        listData:{
          ...this.state.listData,
          list:list,
        }
      })
    }
  }
  setListData = (data)=>{
    this.setState({
      listData:data()
    })
  }

  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };
  // 获取当前时间
  getCurrentTime = () => {
    const timestamp = Date.parse(new Date()) / 1000;
    return timestamp;
  };

  render() {
    const DefaultRemarks =
      "1. The coupon can only be used for Dropshipman orders, not for other orders\n2. Only one coupon can be used for one order and cannot be used in combination\n3. When paying in batches for multiple orders, coupons are not supported\n4. The coupon can only be used within the validity period\n";

    const tabs = [
      {
        id: "tab-sourcing-all",
        content: intl.get("Available"),
      },
      {
        id: "tab-sourcing-pending",
        content: intl.get("Expired"),
      },
    ];

    var footerContentMsg = "";

    if (this.state.total > 0) {
      const startNum = (this.state.page - 1) * this.state.size + 1;
      const endNum =
        this.state.page * this.state.size > this.state.total
          ? this.state.ShowCountNumber
          : this.state.page * this.state.size;
      footerContentMsg = (
        <div>
          {intl.get(
            "Showing {{startNum}} - {{endNum}} of {{total}} results",
            startNum,
            endNum,
            this.state.total
          )}
        </div>
      );
    }

    const PaginationSettings = this.state.loadingPage ? (
      ""
    ) : this.state.ShowCountNumber !== 0 ? (
      <div className="Polaris-New-Pagination">
        <Pagination
          label={footerContentMsg}
          hasPrevious={this.state.hasPrevious}
          onPrevious={() => {
            this.setState({ page: this.state.page - 1 }, () => {
              this.handleTabChange(this.state.selected);
            });
          }}
          hasNext={this.state.hasNext}
          onNext={() => {
            this.setState({ page: this.state.page + 1 }, () => {
              this.handleTabChange(this.state.selected);
            });
          }}
        />
      </div>
    ) : (
      ""
    );

    const EmptyProductList =
      this.state.ShowCountNumber === 0 ? (
        <div className="emptyWithImg">
          <EmptyState image="/empty.svg">
            <div style={{ width: "430px" }}></div>
          </EmptyState>
        </div>
      ) : (
        ""
      );
    let skeletonPage = (
      <SkeletonPage>
        <Layout>
      <Layout.Section>
      <Card>
      <BlockStack gap={400}>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      </BlockStack>
      </Card>
    </Layout.Section>
      </Layout>
      </SkeletonPage>
    );
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    return (
      <div className="Coupon_list">
          <Page 
          backAction={
            this.state.loadingPage ? undefined : {
            content:'',
            onAction: () => {
              utils.routeListen('/admin/dashboard')
              utils.routeJump(this,'/admin/dashboard')
            }
          }}
          title={<div style={{fontSize: "20px"}}>{intl.get("Coupons")}</div>} fullWidth={false}>
            {/* {(this.getCurrentTime() >=FOURTH_TEST_TIME && this.getCurrentTime() <= 1724083200) && <FourthCouponBannerEvent listData={this.state.listData} setListData={this.setListData} handleSelect={this.handleSelect} handleGetList={this.handleGetList} handleTabChange={()=>this.handleTabChange(0)} toggleToast={this.toggleToast}/>} */}
            {toastMarkup}
          <Box className="content_list_tab" style={{ background: "#fff"}}>
            <Tabs
              tabs={tabs}
              selected={this.state.selected}
              onSelect={this.handleTabChange}
            >
              <Box style={{position:'relative'}}>
                <div
                  className="Product__Loading"
                  style={{
                    display: this.state.isRefreshPage ? "block" : "none",
                  }}
                >
                  <div
                    className="Product__Loading_Image"
                    style={{ top: "50%" }}
                  >
                    <img
                      style={{
                        width: "32px",
                        height: "32px",
                        verticalAlign: "middle",
                      }}
                      src="/loading.gif"
                      alt="loading"
                    />
                  </div>
                </div>
                {this.state.coupon.length > 0 && this.state.coupon?.map((item, key) => {
                  return (
                    <div className="coupon" key={"coupon" + key}>
                      <div
                        className="coupon_info"
                        style={
                          this.state.selected === 1
                            ? {
                                backgroundImage:
                                  "url(/blackFriday/coupon_bg_new.png)",
                              }
                            : {
                                backgroundImage:
                                  "url(/blackFriday/coupon_bg_new.png)",
                              }
                        }
                      >
                        <div className="coupon_info_content">
                          <div className="coupon_left">
                            <div className="coupon_text">
                              <span
                                className="coupon_text_money"
                                style={{
                                  color:
                                    this.state.selected === 1
                                      ? "#8C9196"
                                      : "#303030",
                                }}
                              >
                                ${item.coupon_money} USD{" "}
                              </span>
                              <span
                                className="coupon_text_explain"
                                style={{
                                  color:
                                    this.state.selected === 1
                                      ? "#8C9196"
                                      : "#303030",
                                }}
                              >
                                Available for Dropshipman orders
                              </span>
                            </div>
                            <div
                              className="coupon_text_time"
                              style={{
                                color:
                                  this.state.selected === 1
                                    ? "#8C9196"
                                    : "#303030",
                              }}
                            >
                              Orders over ${item.full_money} USD |{" "}
                              {moment(item.start_time * 1000)
                                .locale("en")
                                .format("LL") +
                                " - " +
                                moment(item.end_time * 1000)
                                  .locale("en")
                                  .format("LL")}{" "}
                            </div>
                          </div>

                          <div className="coupon_right">
                            {this.state.selected === 1 ? (
                              <div className="uncoupon_img">
                                <img
                                  width={"100%"}
                                  src="/blackFriday/expired_img.png"
                                  alt="dropshipman"
                                ></img>
                              </div>
                            ) : (
                              <Button
                                id="coupon_pop_button1"
                                variant="primary"
                                onClick={() =>{
                                  utils.routeListen('/admin/order_list?type=&num=1')
                                  utils.routeJump(this,'/admin/order_list?type=&num=1')
                              }
                                  // this.props.history.push({
                                  //   pathname: "/admin/order_list",
                                  //   query: {
                                  //     type: "",
                                  //     num: 1,
                                  //   },
                                  // })
                                }
                              >
                                Use Now
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="coupon_right_tips">
                        <div className="coupon_right_tips_title">Tips</div>

                        <List type="number">
                          {item.remarks === "dsm"
                            ? DefaultRemarks.split("\n").map((i, k) => {
                                return (
                                  <List.Item key={k + "list"}>{i}</List.Item>
                                );
                              })
                            : JSON.parse(item.remarks)
                                .split("\n")
                                .map((i, k) => {
                                  return (
                                    <List.Item key={k + "list"}>{i}</List.Item>
                                  );
                                })}
                          {/*{ DefaultRemarks.split('\n').map((i,k)=>{*/}
                          {/*    return  <List.Item key={k + 'list'}>{i}</List.Item>*/}
                          {/*})}*/}
                        </List>
                      </div>
                    </div>
                  );
                })}
                {this.state.loadingPage ? skeletonPage : EmptyProductList}
              </Box>
            </Tabs>
          </Box>
          {PaginationSettings}
          </Page>
      </div>
    );
  }
}

export default withRouter(CouponList);
