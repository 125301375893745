import React, { Component } from "react";
import { Modal, Toast } from "@shopify/polaris";
import { reqPostUpdateMessageRead } from "../../../../api";
import intl from "../../../../i18n/intl";

class PopMessagesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalShow: props.show === undefined ? false : props.show,
      messageList: props.messageList,
      handleTitleAction: props.handleTitleAction,
      //弹窗
      showToast: false, //弹窗显示
      showToastText: "", //弹窗文字
      showToastIserror: false, //弹窗是否错误
    };
  }

  //进入页面调用接口
  componentDidMount() {
    // this.getMessagesData();
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    }));
  };

  // 弹窗控制
  handleChangeModal = () => {
    let list = this.state.messageList;
    let msg_ids = []; // 站内信记录id数组
    let key_arr = []; // 父级列表的key数组
    for (let index = 0; index < list.length; index++) {
      msg_ids.push(list[index].id);
      key_arr.push(list[index].props_id);
    }

    // 更新站内信（已读）
    const params = {
      id: msg_ids,
    };
    // 更新为已读
    reqPostUpdateMessageRead(params);

    // 更新父级列表的已读状态
    key_arr.map((item) => {
      this.state.handleTitleAction(item, 1);
      return item;
    });

    this.setState({
      ModalShow: !this.state.ModalShow,
    });
  };

  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={() => this.toggleToast("")}
        error={this.state.showToastIserror}
      />
    ) : null;
    const {
      state: { ModalShow },
    } = this;

    return (
      <div>
        <Modal
          open={ModalShow}
          onClose={this.handleChangeModal}
          secondaryActions={[
            {
              content: intl.get("Close"),
              onAction: this.handleChangeModal,
            },
          ]}
          size="large"
        >
          <Modal.Section>
            {this.state.messageList.map((item, key) => {
              return [
                <Modal.Section key={key}>
                  <p
                    style={{
                      fontSize: "2.3rem",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#000",
                      lineHeight: "2rem",
                      margin: "5px 0 30px",
                    }}
                  >
                    {item?.title}
                  </p>
                  <p
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      textAlign: "right",
                      color: "#637381",
                      lineHeight: "1.6rem",
                      margin: "5px 0",
                      marginBottom: "15px",
                    }}
                  >
                    {item.show_at}
                  </p>
                  <div
                    style={{ fontSize: "2rem", lineHeight: 1.5 }}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                </Modal.Section>,
              ];
            })}
          </Modal.Section>
        </Modal>

        {toastMarkup}
      </div>
    );
  }
}

export default PopMessagesModal;
