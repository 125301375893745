import { Modal, TextField } from "@shopify/polaris";
import React, { useState } from "react";

const Index = ({ 
    attributeData,
    setAttributeData,
    selectedResources,
    updateData,
}) => {
  
  const [value,setValue] = useState('');
  const [error,setError] = useState(false)
  const handleClose = () => {
    setValue('')
    setError(false)
    setAttributeData({
        open:false,
        attr:''
      })
  };
  const handleChange = (value) => {
    if (value.length > 15) {
        return;
    }
    setError(false)
    setValue(value)
  };
  const handleSubmit = () => {
    if (value === '') {
      setError(true)
      return
    }
      updateData(selectedResources,{[attributeData.attr]:value})
      handleClose()
  };
  
  return (
    <div>
      <Modal
        size="small"
        open={attributeData.open}
        onClose={handleClose}
        title="Change variant"
        secondaryActions={{
          onAction: () => {
            handleClose();
          },
          content: "Cancel",
        }}
        primaryAction={{
          onAction: () => {handleSubmit()},
          content: "Change",
        }}
      >
        <Modal.Section>
        <TextField
        label="variant"
        value={value}
        error={error ? 'Please enter a variant' : ''}
        onChange={handleChange}
        autoComplete="off"
        />
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
