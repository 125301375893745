import React, { useState, memo } from "react";
import { Modal, Toast } from "@shopify/polaris";
const OrderNoteModal = memo(
  ({
    paymentData,
    handlePaymentButtonClose,
    handleWalletActive,
  }) => {
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };
    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
      />
    ) : null;
    return (
      <div style={{ margin: "0 0" }}>
        {toastMarkup}
        <Modal
          open={paymentData.isWalletModal}
          onClose={() => handlePaymentButtonClose(paymentData)}
          title={"Order: " + paymentData.orderName}
          primaryAction={{
            content: "Yes",
            onAction: () => handleWalletActive(),
            loading: paymentData.toWalletButtonLoading,
          }}
          loading={paymentData.toCheckoutLoading}
        >
          <Modal.Section>
            <p>
              {"Current credit balance"}{" "}
              {"$" + parseFloat(paymentData.walletMoney) + " USD"},{" "}
              {"order total price"}{" "}
              {"$" +
                (
                  parseFloat(paymentData.paymentMoney)).toFixed(2) +
                " USD"}
              . {"Are you sure to pay by credit balance?"}
            </p>
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default OrderNoteModal;
