import React, { Component } from 'react';

import FrameComponents from "../../FrameComponents"; 

import "../index.css";

class dashboard extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-products_import';
  }
  render() {
   
    return (
      
      <div>
        <FrameComponents name="products_import"/> 
      </div>
    );
    
  }
}

export default dashboard;
