import React, { useState,useMemo } from 'react'
import { withRouter } from 'react-router-dom';
import intl from '../../../../i18n/intl'
import {Card, OptionList} from '@shopify/polaris';
import { useEffect } from 'react';
// import AppBridge from '../../../../utils/appBridge';
import utils from '../../../../utils/utils'
import { store } from '../../../../store';
import { connect ,useSelector} from "react-redux";
const PageContent = (props) => {
  var selected = props.selected;
  const [selecteds, setSelecteds] = useState(['']);
  const language = useSelector(state => state.languageReducer.language);
  let defalutData = useMemo(()=>{
    let data = [
      {
        id: 1,
        value: '/admin/settings/profile',
        label: intl.get("Profile"),
        matches: 'Profile',
      },
      {
        id: 2,
        value: '/admin/settings/notification',
        label: intl.get("Notification"),
        matches: 'Notification'
      },
      {
        id: 3,
        value: '/admin/settings/import',
        label: intl.get("Import Setting"),
        matches: 'Importing rules'
      },
      {
        id: 4,
        value: '/admin/settings/price',
        label: intl.get("Pricing Setting"),
        matches: 'Pricing rules'
      },
      // {
      //   id: 5,
      //   value: '/admin/settings/shipping',
      //   label: intl.get("Shipping"),
      //   matches: 'Shipping'
      // },
      // {
      //   id: 6,
      //   value: '/admin/settings/affiliate',
      //   label: intl.get("AliExpress affiliate"),
      //   matches: 'AliExpress affiliate',
      //   new: ((new Date()).valueOf() < 1633060800000) ? true : false // 2021-10-01 12:00:00
      // },
      {
        id: 7,
        value: '/admin/settings/config',
        label: intl.get("Upload Logo"),
        matches: 'Other config'
      },
      {
        id: 8,
        value: '/admin/settings/address',
        label: intl.get("Address Management"),
        matches: 'Address management'
      },
      // {
      //   id: 9,
      //   value: '/admin/settings/contact_way',
      //   label: intl.get("Contact information"),
      //   matches: 'Contact way'
      // },
      {
        id: 10,
        value: '/admin/settings/payment',
        label: intl.get("Auto Payment"),
        matches: 'Order payment',
        img:'/sub/sub_head_light.png'
      },
      {
        id: 11,
        value: '/admin/settings/billing',
        label: intl.get("Billing"),
        matches: 'Billing',
      },
      {
        id: 12,
        value: '/admin/settings/language',
        label: intl.get("Language"),
        matches: 'Language',
      }
    ];
    if (language) {
      data = [
        {
          id: 1,
          value: '/admin/settings/profile',
          label: intl.get("Profile"),
          matches: 'Profile',
        },
        {
          id: 2,
          value: '/admin/settings/notification',
          label: intl.get("Notification"),
          matches: 'Notification'
        },
        {
          id: 3,
          value: '/admin/settings/import',
          label: intl.get("Import Setting"),
          matches: 'Importing rules'
        },
        {
          id: 4,
          value: '/admin/settings/price',
          label: intl.get("Pricing Setting"),
          matches: 'Pricing rules'
        },
        // {
        //   id: 5,
        //   value: '/admin/settings/shipping',
        //   label: intl.get("Shipping"),
        //   matches: 'Shipping'
        // },
        // {
        //   id: 6,
        //   value: '/admin/settings/affiliate',
        //   label: intl.get("AliExpress affiliate"),
        //   matches: 'AliExpress affiliate',
        //   new: ((new Date()).valueOf() < 1633060800000) ? true : false // 2021-10-01 12:00:00
        // },
        {
          id: 7,
          value: '/admin/settings/config',
          label: intl.get("Upload Logo"),
          matches: 'Other config'
        },
        {
          id: 8,
          value: '/admin/settings/address',
          label: intl.get("Address Management"),
          matches: 'Address management'
        },
        // {
        //   id: 9,
        //   value: '/admin/settings/contact_way',
        //   label: intl.get("Contact information"),
        //   matches: 'Contact way'
        // },
        {
          id: 10,
          value: '/admin/settings/payment',
          label: intl.get("Auto Payment"),
          matches: 'Order payment',
          img:'/sub/sub_head_light.png'
        },
        {
          id: 11,
          value: '/admin/settings/billing',
          label: intl.get("Billing"),
          matches: 'Billing',
        },
        {
          id: 12,
          value: '/admin/settings/language',
          label: intl.get("Language"),
          matches: 'Language',
        }
      ];
    }
    return data;
  },[language]);
  const [listArray,setListArray] = useState(defalutData)
  
  let person = useSelector(state => state.languageReducer.subScriptData);
  
  
  const getDefalutData = (data)=>{
    setListArray([
      {
        id: 1,
        value: '/admin/settings/profile',
        label: intl.get("Profile"),
        matches: 'Profile',
      },
      {
        id: 2,
        value: '/admin/settings/notification',
        label: intl.get("Notification"),
        matches: 'Notification'
      },
      {
        id: 3,
        value: '/admin/settings/import',
        label: intl.get("Import Setting"),
        matches: 'Importing rules'
      },
      {
        id: 4,
        value: '/admin/settings/price',
        label: intl.get("Pricing Setting"),
        matches: 'Pricing rules'
      },
      // {
      //   id: 5,
      //   value: '/admin/settings/shipping',
      //   label: intl.get("Shipping"),
      //   matches: 'Shipping'
      // },
      // {
      //   id: 6,
      //   value: '/admin/settings/affiliate',
      //   label: intl.get("AliExpress affiliate"),
      //   matches: 'AliExpress affiliate',
      //   new: ((new Date()).valueOf() < 1633060800000) ? true : false // 2021-10-01 12:00:00
      // },
      {
        id: 7,
        value: '/admin/settings/config',
        label: intl.get("Upload Logo"),
        matches: 'Other config'
      },
      {
        id: 8,
        value: '/admin/settings/address',
        label: intl.get("Address Management"),
        matches: 'Address management'
      },
      // {
      //   id: 9,
      //   value: '/admin/settings/contact_way',
      //   label: intl.get("Contact information"),
      //   matches: 'Contact way'
      // },
      {
        id: 10,
        value: '/admin/settings/payment',
        label: intl.get("Auto Payment"),
        matches: 'Order payment',
        img:'/sub/sub_head_light.png'
      },
      {
        id: 11,
        value: '/admin/settings/billing',
        label: intl.get("Billing"),
        matches: 'Billing',
      },
      {
        id: 12,
        value: '/admin/settings/language',
        label: intl.get("Language"),
        matches: 'Language',
      }
    ])
}
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getDefalutData(defalutData);
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line
  },[defalutData]);
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      for (let i = 0; i < listArray.length; i++) {
        if (listArray[i].matches === selected) {
          setSelecteds(listArray[i].value)
        }
      }
      setPageTitle('Settings')
    }
    return () => {
      isMount = true
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      let persons = Object.values(person).length > 0 ? person : store.getState().languageReducer.subScriptData;
      if (persons.shopify_level !== 'Development' && persons.shopify_level !== 'Developer Preview' && persons.shopify_level !== 'staff' && persons.shopify_level !== 'staff_business') {
    setListArray([
      {
        id: 1,
        value: '/admin/settings/profile',
        label: intl.get("Profile"),
        matches: 'Profile',
      },
      {
        id: 2,
        value: '/admin/settings/notification',
        label: intl.get("Notification"),
        matches: 'Notification'
      },
      {
        id: 3,
        value: '/admin/settings/import',
        label: intl.get("Import Setting"),
        matches: 'Importing rules'
      },
      {
        id: 4,
        value: '/admin/settings/price',
        label: intl.get("Pricing Setting"),
        matches: 'Pricing rules'
      },
      // {
      //   id: 5,
      //   value: '/admin/settings/shipping',
      //   label: intl.get("Shipping"),
      //   matches: 'Shipping'
      // },
      // {
      //   id: 6,
      //   value: '/admin/settings/affiliate',
      //   label: intl.get("AliExpress affiliate"),
      //   matches: 'AliExpress affiliate',
      //   new: ((new Date()).valueOf() < 1633060800000) ? true : false // 2021-10-01 12:00:00
      // },
      {
        id: 7,
        value: '/admin/settings/config',
        label: intl.get("Upload Logo"),
        matches: 'Other config'
      },
      {
        id: 8,
        value: '/admin/settings/address',
        label: intl.get("Address Management"),
        matches: 'Address management'
      },
      // {
      //   id: 9,
      //   value: '/admin/settings/contact_way',
      //   label: intl.get("Contact information"),
      //   matches: 'Contact way'
      // },
      {
        id: 10,
        value: '/admin/settings/payment',
        label: intl.get("Auto Payment"),
        matches: 'Order payment',
        img:'/sub/sub_head_light.png'
      },
      {
        id: 11,
        value: '/admin/settings/billing',
        label: intl.get("Billing"),
        matches: 'Billing',
      },
      {
        id: 12,
        value: '/admin/settings/language',
        label: intl.get("Language"),
        matches: 'Language',
      }
    ]);
  }else {
    setListArray([
      {
        id: 1,
        value: '/admin/settings/profile',
        label: intl.get("Profile"),
        matches: 'Profile',
      },
      {
        id: 2,
        value: '/admin/settings/notification',
        label: intl.get("Notification"),
        matches: 'Notification'
      },
      {
        id: 3,
        value: '/admin/settings/import',
        label: intl.get("Import Setting"),
        matches: 'Importing rules'
      },
      {
        id: 4,
        value: '/admin/settings/price',
        label: intl.get("Pricing Setting"),
        matches: 'Pricing rules'
      },
      // {
      //   id: 5,
      //   value: '/admin/settings/shipping',
      //   label: intl.get("Shipping"),
      //   matches: 'Shipping'
      // },
      // {
      //   id: 6,
      //   value: '/admin/settings/affiliate',
      //   label: intl.get("AliExpress affiliate"),
      //   matches: 'AliExpress affiliate',
      //   new: ((new Date()).valueOf() < 1633060800000) ? true : false // 2021-10-01 12:00:00
      // },
      {
        id: 7,
        value: '/admin/settings/config',
        label: intl.get("Upload Logo"),
        matches: 'Other config'
      },
      {
        id: 8,
        value: '/admin/settings/address',
        label: intl.get("Address Management"),
        matches: 'Address management'
      },
      // {
      //   id: 9,
      //   value: '/admin/settings/contact_way',
      //   label: intl.get("Contact information"),
      //   matches: 'Contact way'
      // },
      {
        id: 10,
        value: '/admin/settings/payment',
        label: intl.get("Auto Payment"),
        matches: 'Order payment',
        img:'/sub/sub_head_light.png'
      },
      {
        id: 11,
        value: '/admin/settings/billing',
        label: intl.get("Billing"),
        matches: 'Billing',
      },
      {
        id: 12,
        value: '/admin/settings/language',
        label: intl.get("Language"),
        matches: 'Language',
      }
    ]);
  }
    }
    return () => {
      isMount = true
    };
  }, [person]);
  // 设置标题
  const setPageTitle = (title) => {
    // if(AppBridge && AppBridge.exist()) {
    // AppBridge.setTitleBar(title,'',props.saveData,props.loading,props.disabled)
    // }
  }
  
  const onChangeSelected = (selected) => {
    setSelecteds(selected);
    // props.history.push(selected[0]);
    utils.routeListen(selected[0])
    utils.routeJump1(props.history,selected[0])
  }
  // const LiItems = ListArray.map((item) =>
  //   <li key={item.id} className={(selected === item.matches) ? 'active' : ''}>
  //     <span style={{ cursor: 'pointer',display:'flex',justifyContent:'space-between' }} onClick={() => props.history.push(item.url)} rel="noopener noreferrer">
  //       {item.title} {item?.img && <img style={{verticalAlign:'baseline',width:"20px",height:'20px'}} src={item?.img} alt={item.title} />}
  //       {item.new ? (
  //         <sup className="sup_text">new</sup>
  //       ) : ''}
  //     </span>
  //   </li>
  // );

  

  return (
    <nav className="SettingsPage__Nav">
      {/* <ul className="Nav_Left_Menu setting_left_menu">
        {LiItems}
      </ul> */}
      <Card>
      <OptionList
        title=""
        onChange={onChangeSelected}
        options={listArray}
        selected={selecteds}
      />
      </Card>
    </nav>
  );

}

export default connect()(withRouter(PageContent))