import React, { useState,useEffect } from 'react';
import { BlockStack, Card, Layout, Page, SkeletonBodyText, SkeletonPage } from "@shopify/polaris";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from '../../../../utils/utils'
import AppBridge from '../../../../utils/appBridge';
import intl from '../../../../i18n/intl';
import { Carousel } from 'react-responsive-carousel';

let icon1 = <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.92248 0.169138C8.84245 0.00697546 8.61121 0.00697558 8.53118 0.169138L6.21271 4.86686C6.18093 4.93126 6.1195 4.97589 6.04843 4.98622L0.864189 5.73953C0.685232 5.76554 0.613775 5.98546 0.743269 6.11168L4.49462 9.76835C4.54605 9.81847 4.56951 9.89069 4.55737 9.96147L3.6718 15.1248C3.64123 15.303 3.82831 15.4389 3.98837 15.3548L8.6253 12.917C8.68886 12.8836 8.7648 12.8836 8.82836 12.917L13.4653 15.3548C13.6254 15.4389 13.8124 15.303 13.7819 15.1248L12.8963 9.96147C12.8841 9.89069 12.9076 9.81847 12.959 9.76835L16.7104 6.11168C16.8399 5.98546 16.7684 5.76554 16.5895 5.73953L11.4052 4.98622C11.3342 4.97589 11.2727 4.93126 11.2409 4.86686L8.92248 0.169138Z" fill="#FFBB1C"/>
</svg>
const LandingPageClass = () => {

  /**
   * 跳转 POD安装页面
   */
  const handleLink = (link) => {
    let podAppInstall = 'https://api-partners.secomapp.com/apps/search_pie/DSM_SectionPage';
    if (link) {
      podAppInstall = link;

    }
    try {
      const w = window.open('about:blank');
      w.location.href = podAppInstall;

    } catch (error) {
      window.open(podAppInstall, '_blank')
    }
  }
const router = useHistory();
const [loading,setLoading] = useState(false);
const [data,setData] = useState({
  toggleData:[
    {
      title:'SEO manager',
      desc1:'Detect all SEO issues via smart reports for your store & landing pages – so you always know what to fix.',
      desc2:'',
      strong:'',
      select:1,
      button:'Try Demo App',
      link:'https://mawta.myshopify.com/pages/searchpie-demo',
      image:'/SearchPie/pie_img1.png'
    },
    {
      title:'SEO Content Generator',
      desc1:'Our AI feature, powered by Chat GPT, helps you create unique product descriptions effortlessly with AI to save time and improve content quality.',
      desc2:'',
      strong:'',
      select:0,
      button:'Try Demo App',
      link:'https://mawta.myshopify.com/pages/searchpie-demo',
      image:'/SearchPie/pie_img2.png'
    },
    {
      title:'SEO & Traffic Booster',
      desc1:'Optimize Meta Tags, Image ALT Tags, Rich Snippets  JSON-LD, and drive more organic traffic.',
      desc2:'',
      strong:'',
      select:0,
      button:'Try Demo App',
      link:'https://mawta.myshopify.com/pages/searchpie-demo',
      image:'/SearchPie/pie_img3.png'
    },
    {
      title:'Website Credibility Protector',
      desc1:'Auto-detect Broken Links & GSC issues with AI, cleans them up, and enhance your GSC performance.',
      desc2:'',
      strong:'',
      select:0,
      button:'Try Demo App',
      link:'https://mawta.myshopify.com/pages/searchpie-demo',
      image:'/SearchPie/pie_img4.png'
    },
  ]
})
const [currentIndex,setCurrentIndex] = useState(0)
const handleToggleImage = (key)=>{
      let list = data.toggleData.map((item,ind)=>{
        if(ind === key) {
          item.select = 1
        } else {
          item.select = 0
        }
        return item
      })
      setCurrentIndex(key)
      setData((prevValue)=>({
        ...prevValue,
        toggleData:list
      }))
}
useEffect(() => {
  let isMount = false;
  if (!isMount) {
    setLoading(true)
    // if(AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar('Landing Page Builder','/admin/customService')
    // }
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }
  return () => {
    isMount = true
  };
}, []);

const initSkeletonPage = (
  <div>
    <SkeletonPage fullWidth={false}>
    <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
    </SkeletonPage>
  </div>
);
const globalConfig2 = {
  showArrows: false,
  autoPlay: true,
  interval: 5000,
  showIndicators:false,
  showStatus: false,
  infiniteLoop: true,
  showThumbs: false,
};
  return loading ? initSkeletonPage :  (
    <div className='pie_page'>
    <Page
      title={AppBridge.exist() ? "" : <div style={{fontSize:'20px'}} >{intl.get("SearchPie SEO & Speed Optimize")}</div>}
      backAction={AppBridge.exist() ? undefined : {content: 'SearchPie SEO & Speed Optimize', onAction: () =>{utils.routeListen('/admin/customService')
      utils.routeJump1(router,'/admin/customService')}}}
      fullWidth={false}
    >
       <div className='land_content'>
        <div className='land_top'> 
          <div className='land_top_t'>
          <div className='land_top_t_l'> <img src={'/SearchPie/logo_icon.png'} alt="Dropshipman" /></div>
          {/* <div className='land_top_t_r' onClick={()=>handleLink()}>Start for Free</div> */}
          </div>
          <div className='land_top_m'>
          <div className='land_top_m_l'>
          <div className='land_top_m_l_star'>
            <span>{icon1}</span>
            <span className='white'>4000+ </span>
            5-Star Reviews
          </div>
          <div className='land_top_m_l_yellow'>
            <p>Top-Ranked Shopify SEO</p>
          </div>
          <div className='land_top_m_l_title'>
            <h1>AI & automation app helps maximize SEO performance and 
           <br /> boost potential customers by 200%
            </h1>
          </div>
          <div className='land_top_m_l_p'>
            <p>SearchPie helps you optimize, enhance and monitor your SEO performance that boosts organic traffic and sales.
            </p>
          </div>
          <div className='land_top_m_l_button' onClick={()=>handleLink()}>
          Start For Free
          </div>
          </div>
          <div className='land_top_m_r'><img src={'/SearchPie/top_banner.png'} alt="Dropshipman" /></div>
          </div>
          <div className='land_top_b'>
            <div className='land_top_b_title'>
              <p>SearchPie empowers <strong>70K+</strong> Shopify merchants across <strong>65</strong> countries to serve <strong>500,000+</strong> stores.</p>
            </div>
            
          </div>
        </div>
        <div className='land_mid1'>
          <div className='land_mid1_t'>
            <h2>Enhance <strong>SEO performance</strong> with SearchPie smart features</h2>
          </div>
          <div className='land_mid1_b'>
            <div className='land_mid1_b_l'>
            <img src={data.toggleData[currentIndex].image} alt="Dropshipman" />
            <div style={{display:'flex',justifyContent:'center'}}>
            <div className='land_top_m_l_button app_button' onClick={()=>handleLink(data.toggleData[currentIndex].link)}>
            {data.toggleData[currentIndex].button}
            </div>
            </div>
            </div>
            <div className='land_mid1_b_r'>
              
              <div className='land_mid1_b_list'>
              {data.toggleData.map((item,ind)=><div className='land_mid1_b_list_item' key={ind}>
                {item.select === 1 ? <div className='land_mid1_b_list_item_li land_mid1_b_list_item_lis'>
                <div className='land_mid1_b_list_item_title'>
                  {item.title}
                </div>
                {ind === 0 &&<p>{item.desc1} <strong>{item.strong}</strong></p>}
                {ind === 1 &&<p>{item.desc1} <strong>{item.strong}</strong> {item.desc2}</p>}
                {ind === 2 &&<p><strong>{item.strong}</strong> {item.desc1} </p>}
                {ind === 3 &&<p>{item.desc1} <strong>{item.strong}</strong></p>}
                </div>:<div className='land_mid1_b_list_item_li' onClick={()=>{handleToggleImage(ind)}}>
                <div className='land_mid1_b_list_item_title'>
                  {item.title}
                </div></div>}
              </div>)}
              </div>
              
            </div>
          </div>
          <Carousel {...globalConfig2}>
          {data.toggleData.map((item,ind)=><div key={ind} className='land_mid1_b land_mid1_b_c'>
            <div className='land_mid1_b_l'>
            <img src={data.toggleData[ind].image} alt="Dropshipman" />
            </div>
            <div className='land_mid1_b_r'>
              <div className='land_mid1_b_list'>
              <div className='land_mid1_b_list_item' >
                <div className='land_mid1_b_list_item_li land_mid1_b_list_item_lis'>
                <div className='land_mid1_b_list_item_title'>
                  {item.title}
                </div>
                {ind === 0 &&<p>{item.desc1} <strong>{item.strong}</strong></p>}
                {ind === 1 &&<p>{item.desc1} <strong>{item.strong}</strong> {item.desc2}</p>}
                {ind === 2 &&<p><strong>{item.strong}</strong> {item.desc1} </p>}
                {ind === 3 &&<p><strong>{item.strong}</strong> {item.desc1} </p>}
                </div>
              </div>
              </div>
            </div>
          </div>)}
          </Carousel>
        </div>
        <div className='land_bottom'>
          <div className='land_bottom_h2'>
          <h2>Your traffic deserves better. Turn it into revenue with SearchPie.</h2>
          </div>
          <div className='land_bottom_p'>
          Boost your professional SEO with SearchPie — start converting today
          </div>
          <div className='land_bottom_botton' onClick={()=>handleLink()}>
          Start For Free
          </div>
        </div>
       </div>
    </Page>
    </div>
  );
}

export default LandingPageClass;