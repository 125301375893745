import React from 'react';

const index =()=> {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0L10.3359 2.36064L13.6569 2.34315L13.6394 5.6641L16 8L13.6394 10.3359L13.6569 13.6569L10.3359 13.6394L8 16L5.6641 13.6394L2.34315 13.6569L2.36064 10.3359L0 8L2.36064 5.6641L2.34315 2.34315L5.6641 2.36064L8 0Z" fill="#303030"/>
            <path d="M5.89453 5.87356C5.89453 5.65272 6.07356 5.47369 6.29439 5.47369H7.74935C7.74935 5.47369 7.39441 5.58821 7.31427 5.88594C7.23412 6.18367 7.24557 6.61882 7.24557 6.61882V10.9474H5.89453V5.87356Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.24658 10.9388H7.94471C8.51604 10.9388 9.01725 10.8248 9.44835 10.5968C9.88464 10.3688 10.2196 10.0476 10.4534 9.63305C10.6923 9.21854 10.8118 8.74443 10.8118 8.21074C10.8118 7.67187 10.6923 7.19777 10.4534 6.78843C10.2196 6.37392 9.88724 6.05267 9.45614 5.82468C9.02504 5.5967 8.52123 5.47369 7.94471 5.47369H7.24658V6.64457L7.24674 6.61745H7.85901C8.36282 6.61745 8.75496 6.75735 9.03543 7.03714C9.3159 7.31694 9.45614 7.70814 9.45614 8.21074C9.45614 8.71334 9.3159 9.10195 9.03543 9.37657C8.75496 9.65119 8.36282 9.7885 7.85901 9.7885H7.24658V10.9388Z" fill="url(#paint0_linear_616_3030)"/>
            <defs>
            <linearGradient id="paint0_linear_616_3030" x1="7.24658" y1="5.47369" x2="7.24658" y2="10.9474" gradientUnits="userSpaceOnUse">
            <stop offset="0.00407736" stopColor="white" stopOpacity="0.5"/>
            <stop offset="1" stopColor="white"/>
            </linearGradient>
            </defs>
            </svg>
            
            )}

export default index;
