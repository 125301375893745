import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import utils from '../../../../utils/utils'
import {
  Layout, Page, Card, SkeletonBodyText, Modal,
  Toast, EmptyState, Collapsible, Icon, Button, BlockStack
} from "@shopify/polaris";
import {
  ChevronDownIcon, ChevronUpIcon
} from '@shopify/polaris-icons';
import PopMessagesModalComponet from './PopMessagesModal';
import { reqGetMessageList, reqPostUpdateMessageRead, reqPostDeleteMessage } from '../../../../api';
import "../index.css";
import intl from '../../../../i18n/intl'

class MessagesClass extends Component {
  state = {
    messagesListData: [], // 站内信数据
    loadingPage: false, // 加载页面
    // 提示弹窗
    showToast: false, // 提示弹窗是否显示
    showToastText: '', // 提示内容
    showToastIserror: false, // 是否为错误提示    

    DeleteTipsActive: false,  // 确认删除弹窗
    deleteButtonLoading: false, // 确认删除按钮状态
    deleteMessageId: 0, // 待删除的站内信ID

    // Modal
    PopMessagesModal: ''
  };

  //进入页面调用接口
  componentDidMount() {
    this.getMessageList();
  }

  // 获取站内信数据
  getMessageList = async () => {
    this.setState({
      loadingPage: true,
    });

    const params = {};
    const response = await reqGetMessageList(params)
    if (response.code === '200') {
      let PopList = [];
      response.data.result.map((item, key) => {
        if (item.is_read === 0 && item.pop_reminder === 1) {
          item.props_id = key
          PopList.push(item)
        }
        return item.id;
      })
      //更新状态
      this.setState({
        loadingPage: false,
        messagesListData: response.data.result,
        PopMessagesModal: (
          <PopMessagesModalComponet
            show={PopList.length >= 1}
            messageList={PopList}
            handleTitleAction={this.handleTitleAction}
          />
        )
      }, () => {
        this.updatePageList();
      });

    } else {
      this.toggleToast(response.msg);
      this.setState({
        loadingPage: false,
        productsData: []
      });
    }
  }

  // 删除站内信
  handleDeleteDone = async () => {
    const id = this.state.deleteMessageId;

    this.setState({
      deleteButtonLoading: true,
    })

    const params = {
      id: id,
    };
    const response = await reqPostDeleteMessage(params)
    if (response.code === '200') {
      //更新状态
      this.toggleToast(response.msg);
      this.setState({
        deleteButtonLoading: false,
        DeleteTipsActive: false,
        deleteMessageId: 0,
      }, () => {
        this.getMessageList();
      });

    } else {
      this.toggleToast(response.msg);
      this.setState({
        deleteButtonLoading: false,
        DeleteTipsActive: false,
        deleteMessageId: 0,
      });
    }

  }

  // 更新站内信（已读）
  updateMessage = async (id) => {
    const params = {
      id: id,
    };
    // 更新为已读
    await reqPostUpdateMessageRead(params)
  }

  // 更新页面站内信列表
  updatePageList = () => {
    let data = this.state.messagesListData;
    let pageList = '';
    if (data.length > 0) {
      pageList = data.map((item, key) => {
        let singleMessage = '';

        let adjust_now_button = '';
        if (item.dsm_order_number) {
          adjust_now_button = (
            <Button
              variant='plain'
              onClick={() => this.handleJumpToOrders(item.dsm_order_number)}
            >
              {intl.get("Adjust now")}
            </Button>
          )
        }

        singleMessage = (
          <Card sectioned key={key}>
            <div
              className="message_list_item_parent"
              onClick={() => this.handleTitleAction(key)}
            >

              <div className="message_list_item_left">
                <div className={item.is_read ? "message-icon" : "message-icon-no-read"}></div>
              </div>

              <div className="message_list_item_center">
                <div className={item.is_read ? "message-title" : "message-title message-no-read"}>
                  {item.title}
                </div>
                <div className={item.is_read ? "message-time" : "message-time message-no-read"}>
                  {item.show_at}
                </div>
                <Collapsible
                  open={item.show}
                  id={"content-collapsible_" + key}
                  transition={{ duration: '150ms', timingFunction: 'ease-in-out' }}
                  expandOnPrint
                >
                  <div
                    style={{
                      display: (item.dsm_order_number !== '') ? 'flex' : 'inherit',
                      alignItems: (item.dsm_order_number !== '') ? 'center' : 'inherit',
                      marginBottom: '10px'
                    }}
                  >
                    <div className="message_content" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    <div style={{ marginLeft: '5px', marginTop: '0px' }}>
                      {adjust_now_button}
                    </div>
                  </div>
                  <Button
                    variant='plain'
                    onClick={() => this.handleDeleteAction(item.id)}
                  >
                    {intl.get("Remove")}
                  </Button>
                </Collapsible>
              </div>

              <div className="message_list_item_right">
                <div><Icon source={item.show ? ChevronUpIcon : ChevronDownIcon} color="subdued" /></div>
              </div>

            </div>
          </Card>
        );

        return (
          singleMessage
        );
      });

    } else { // 空数据

      pageList = (
        <Card>
          <div className="message_empty">
            <EmptyState
              heading={intl.get("No messages found")}
              image="/empty.svg"
            >
              <p style={{ fontSize: 14 }}>{intl.get("The message display here comes from dropshipman")}</p>
            </EmptyState>
          </div>
        </Card>

      )
    }

    this.setState({
      messageListHml: <BlockStack gap={400}>{pageList}</BlockStack>,
    })
  }

  // 站内信操作：点击标题，展开详情，更新为已读
  handleTitleAction = (recordKey, is_pop_model = 0) => {
    let data = this.state.messagesListData;

    let is_read = data[recordKey].is_read;
    if (is_read === 0) { // 未读消息改为已读
      if (is_pop_model === 0) {
        this.updateMessage(data[recordKey].id);
      }
      data[recordKey].is_read = 1;
    }

    let status = data[recordKey].show;
    data[recordKey].show = status ? 0 : 1;

    this.setState({
      messagesListData: data
    }, () => {
      this.updatePageList();
    });
  }

  // 删除处理
  handleDeleteAction = (id) => {
    this.setState({
      DeleteTipsActive: true,
      deleteMessageId: id,
    })
  }

  // 确认删除弹窗开关
  handleDeleteTipsModal = () => {
    this.setState({
      DeleteTipsActive: !this.state.DeleteTipsActive,
    })
  }

  // 提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // DSM订单跳转
  handleJumpToOrders = (dsm_order_number = 0) => {
    if (!dsm_order_number) {
      return;
    }
    utils.routeListen('/admin/order_list?order_number=' + dsm_order_number + '&from=message')
    utils.routeJump(this, '/admin/order_list?order_number=' + dsm_order_number + '&from=message')
  }

  render() {
    // 提示弹窗
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const {
      PopMessagesModal, DeleteTipsActive, deleteButtonLoading, messageListHml
    } = this.state;

    // 加载页面
    const initSkeletonPage = <div>
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap={400}>
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout></div>

    // 页面主内容
    const pageContent = this.state.loadingPage ? initSkeletonPage : messageListHml;

    // 删除消息弹窗提示
    const DeleteTipsModal = (
      <Modal
        open={DeleteTipsActive}
        onClose={this.handleDeleteTipsModal}
        title={intl.get("Delete Messages")}
        primaryAction={{
          content: intl.get("Done"),
          onAction: this.handleDeleteDone,
          loading: deleteButtonLoading
        }}
        secondaryActions={[{
          content: intl.get("Cancel"),
          onAction: this.handleDeleteTipsModal
        }]}
      >
        <Modal.Section>

          <p style={{ whiteSpace: 'pre-wrap' }}>
            {intl.get("Are you sure you want to delete this message?")}
          </p>

        </Modal.Section>
      </Modal>)

    return (
      <div>
        <Page
          backAction={
            this.state.loadingPage ? undefined : {
              content: '',
              onAction: () => {
                utils.routeListen('/admin/dashboard')
                utils.routeJump(this, '/admin/dashboard')
              }
            }}
          title={this.state.loadingPage ? '' : intl.get("Messages")}
        >
          {/* <div style={{"paddingTop":"1rem"}}></div> */}
          {/* 页面内容 */}
          <div className="massage_page_list">
            {pageContent}
          </div>
          {/* 删除消息弹窗提示 */}
          {DeleteTipsModal}

          {/* 提示弹窗 */}
          {toastMarkup}

          {/* 消息提示弹窗 */}
          {PopMessagesModal}

        </Page>
      </div>
    );

  }

}

export default withRouter(MessagesClass);
