import React, { useState } from "react";
import { Modal,  Toast } from "@shopify/polaris";
import { reqPostRemoveOrderInventory } from "../../../../../../api";
import { store } from "../../../../../../store";
import { changeBannerData } from "../../../../../../slice";
import intl from "../../../../../../i18n/intl";
const Index = ({cancelInventory,setCancelInventory,getOrderListData,getSearchParam  }) => {
    const [loading, setLoading] = useState(false); // 加载

    // 保存
    const handleSaveChange = async () => {
      setLoading(true);
      let res = await reqPostRemoveOrderInventory({ id: cancelInventory.id, detail_id:cancelInventory.detail_id });
      if (res.code === "200") {
        toggleToast('Canceled Successfully');
        setTimeout(() => {
          getOrderListData(getSearchParam())
          handleOff();
        }, 2000);
      } else {
        store.dispatch(changeBannerData({bannerData:{
          show:true,
          desc:res.msg
        }}));
      }
      setLoading(false);
    };
    // 取消
    const handleOff = () => {
      setCancelInventory({
        id:'',
        detail_id:'',
        open:false
      })
      setLoading(false);
    };
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };
    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
      />
    ) : null;
    return (
      <div style={{ margin: "0 0" }}>
        {toastMarkup}
        <Modal
          open={cancelInventory.open}
          onClose={() => handleOff()}
          title={intl.get("Cancel Inventory Deduction")}
          primaryAction={{
            content: intl.get("Apply"),
            onAction: ()=>handleSaveChange(),
            loading: loading,
          }}
          secondaryActions={[
            {
              content: intl.get("Cancel"),
              onAction: ()=>handleOff(),
            },
          ]}
        >
          <Modal.Section>
              <p style={{ color: "rgb(89 89 89)" }}>
                {intl.get("Are you sure you want to cancel the inventory deduction for this SKU? If you proceed, the deducted inventory will be released, and you’ll need to pay for this SKU.")}
              </p>
             
          </Modal.Section>
        </Modal>
      </div>
    );
  }
;

export default Index;
