import React, { memo, useState } from "react";
import { Modal, RadioButton, Toast } from "@shopify/polaris";
import { reqPostRemoveOrderSku } from "../../../../../../api";
import { useGetState } from "ahooks";
import intl from "../../../../../../i18n/intl";

const CancelSkuModal = memo(
  ({
    currentOrderData,
    setCurrentOrderData,
    getOrderListData,
    getSearchParam,
    setListLoading
  }) => {
    const [delData, setDelData, getDelData] = useGetState({
      orderDeleteModalLoading: false,
      allSelect: false,
      partSelect: false,
    });
    // 退款订单sku删除
    const handleOrderSkuDelete = () => {
      setDelData((prevValue) => ({
        ...prevValue,
        orderDeleteModalLoading: true,
      }));
      handleDeleteOver(
        currentOrderData.orderData.dsm_record,
        currentOrderData.orderSkuData.id,
        currentOrderData.removeCount
      );
    };
    // 确认删除
    const handleDeleteOver = async (id, order_details_id, remove_count) => {
      let params = {
        id,
        detail_id: order_details_id,
        quantity: remove_count,
      };
      let res = await reqPostRemoveOrderSku(params);
      if (res.code === "200") {
        setListLoading(true)
        setDelData((prevValue) => ({
          ...prevValue,
          orderDeleteModal: false,
          orderDeleteModalLoading: false,
          allSelect: true,
          partSelect: false,
        }));
        setCurrentOrderData((prevValue) => ({
          ...prevValue,
          orderDeleteModal: false,
        }));
        // 刷新列表
        toggleToast('Removed successfully');
        setTimeout(() => {
          getOrderListData(getSearchParam());
        }, 3000);
      } else {
        setDelData((prevValue) => ({
          ...prevValue,
          orderDeleteModalLoading: false,
        }));

        toggleToast('Retain one item');
      }
    };

    // 移除sku数量
    const onSelectRemoveSku = (e, select) => {
      switch (select) {
        case "all":
          setDelData((prevValue) => ({
            ...prevValue,
            allSelect: true,
            partSelect: false,
          }));
          setCurrentOrderData((prevValue) => ({
            ...prevValue,
            removeCount: currentOrderData.orderSkuData.quantity,
          }));

          break;
        case "part":
          setDelData((prevValue) => ({
            ...prevValue,
            allSelect: false,
            partSelect: true,
          }));
          setCurrentOrderData((prevValue) => ({
            ...prevValue,
            removeCount:currentOrderData.orderSkuData.quantity,
          }));
          break;
        default:
          break;
      }
    };
    // 移除数量修改
    const onChangeRemoveCount = (e) => {
      if (Number(e.target.value) > Number(currentOrderData.orderSkuData.quantity)) {
        setCurrentOrderData((prevValue) => ({
          ...prevValue,
          removeCount: currentOrderData.orderSkuData.quantity,
        }));
      }else{
      setCurrentOrderData((prevValue) => ({
        ...prevValue,
        removeCount: e.target.value,
      }))
    }
    };
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };

    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
      />
    ) : null;
    return (
      <div>
        {toastMarkup}
        <Modal
          open={currentOrderData.orderDeleteModal}
          onClose={() => {
            setDelData((prevValue) => ({
              ...prevValue,
              orderDeleteModalLoading: false,
            }));
            setCurrentOrderData((prevValue) => ({
              ...prevValue,
              orderDeleteModal: false,
            }));
          }}
          title={intl.get("Cancel sku fulfillment")}
          primaryAction={{
            content: intl.get(
              currentOrderData.orderSkuData.quantity > 1 ? "Done" : "Yes"
            ),
            onAction: () => handleOrderSkuDelete(),
            loading: getDelData()?.orderDeleteModalLoading,
          }}
          secondaryActions={{
            content:
              currentOrderData.orderSkuData.quantity > 1 ? "Close" : "No",
            onAction: () => {
              setDelData((prevValue) => ({
                ...prevValue,
                allSelect: true,
                partSelect: false,
              }));
              setCurrentOrderData((prevValue) => ({
                ...prevValue,
                orderDeleteModal: false,
              }));
            },
          }}
        >
          <Modal.Section>
            {currentOrderData.orderSkuData.quantity > 1 ? (
              <div>
                <p style={{ paddingLeft: "60px",fontSize:"16px" }}>
                 {intl.get("After canceling the fulfillment of the sku, Dropshipman will no longer fulfill the canceled sku for you.")}
                </p>
                <div className="reomve_sku_content">
                  <div className="reomve_sku_content_item_l">
                    <RadioButton
                      label={intl.get("Cancel all sku fulfillment.")}
                      checked={delData.allSelect}
                      name="accounts"
                      onChange={(e) => onSelectRemoveSku(e, "all")}
                    />
                  </div>
                  <div className="reomve_sku_content_item_r">
                    <RadioButton
                      label={intl.get("Cancel part of sku fulfillment.")}
                      name="accounts"
                      checked={delData.partSelect}
                      onChange={(e) => onSelectRemoveSku(e, "part")}
                    />
                    {delData.partSelect && (
                      <input
                        type="number"
                        value={currentOrderData.removeCount}
                        min={1}
                        max={currentOrderData.orderSkuData.quantity}
                        onChange={(e) => onChangeRemoveCount(e)}
                        autoComplete="off"
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p style={{ fontSize: "16px" }}>
                {intl.get(`After canceling the fulfillment of the sku, Dropshipman will no
                longer fulfill the canceled sku for you, are you sure to cancel
                it?`)}
              </p>
            )}
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default CancelSkuModal;
