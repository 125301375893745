import { Box } from "@shopify/polaris";
import React, { useState } from "react";
import StartRating from "stars-rating";
import CommonPremiumModal from "../../../../components/CommonPremiumModal";
const PersonalAgentModal = ({
  premiumData,
  handlePremiumOff,
  handleMageUpgrade,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0); //当前选择的agent
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [socialMediaError, setSocialMediaError] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  // email 格式验证
  const checkInputEmail = (email) => {
    let errorMsg = "";
    let bool = false;
    if (email === undefined || email === "") {
      errorMsg = "Please enter your email";
    }

    if (errorMsg === "") {
      let reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      bool = reg.test(email);
      errorMsg = bool ? "" : "Invalid email address";
    }
    setEmailError(errorMsg);
    return bool;
  };
  const handleSelect = (val) => {
    setCurrentIndex(val);
  };
  // 邮箱
  const handleEmailChange = (val) => {
    setEmail(val);
    setEmailError("");
  };

  // 账户
  const handleSocialMediaChange = (val) => {
    setSocialMedia(val);
    setSocialMediaError("");
  };
  // 取消
  const handleCancel = () => {
    setCurrentIndex(0);
    setEmail("");
    setSocialMedia("");
    setSaveLoading(false);
    setEmailError("");
    setSocialMediaError("");
    handlePremiumOff();
  };
  // 保存
  const handleSave = () => {
    let emialBool = checkInputEmail(email);
    if (!emialBool) {
      return;
    }
    if (socialMedia === "") {
      setSocialMediaError("Social Media is required.");
      return;
    }
    setSaveLoading(true);
    if (premiumData?.agent_list.length > 0) {
      handleMageUpgrade(
        5,
        "",
        email,
        socialMedia,
        premiumData?.agent_list[currentIndex].record_id
      );
    } else {
      handleMageUpgrade(5, "", email, socialMedia, "");
    }

    handleCancel();
  };
  return (
    <div>
      <CommonPremiumModal
        open={premiumData.open}
        button={true}
        width={710}
        cancel={"Cancel"}
        save={"Continue"}
        onSave={handleSave}
        buttonLoading={saveLoading}
        classN="agent_modal"
        onClose={handleCancel}
        title="Subscribe to Premium Plan"
        content={
          <Box className="agent_content">
            <Box className="agent_title">1. Select Personal Agent</Box>
            <Box className="agent_content_list">
              {premiumData.agent_list.map((item, ind) => (
                <Box
                  className={
                    currentIndex === ind
                      ? "agent_content_item checked"
                      : "agent_content_item"
                  }
                  onClick={() => handleSelect(ind)}
                  key={ind}
                >
                  {currentIndex === ind && (
                    <Box className="agent_content_item_select">
                      <img src="/sub/agent_select.png" alt="Dropshipman" />
                    </Box>
                  )}
                  <Box className="agent_content_person">
                    <img src={item.avatar} alt="Dropshipman" />
                  </Box>
                  <Box className="agent_content_box">
                    <Box className="agent_content_box_name">{item.name}</Box>

                    {item.customer_rating > 0 && (
                      <Box
                        className="agent_content_box_rating"
                        style={{ position: "relative" }}
                      >
                        <StartRating
                          value={item.customer_rating}
                          color1="gray"
                          color2="#ffd700"
                          half={true}
                          count={5}
                          edit={false}
                          size={16}
                        />
                        <span
                          className="agent_right_rating_span"
                          style={{
                            position: "absolute",
                            left: "107px",
                            top: "1px",
                          }}
                        >
                          {item.customer_rating}{" "}
                        </span>
                      </Box>
                    )}
                    <Box className="agent_content_box_year">
                      <span>
                        {item.service_years}{" "}
                        {item.service_years < 1 ? " year" : " years"} of service
                        experience
                      </span>
                    </Box>
                    <Box className="agent_content_box_tag">
                      {item.service_tags.map((it, k) => (
                        <span key={k}>{it}</span>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className="agent_title">
              2. Contact Information{" "}
              <span style={{ fontWeight: 400 }}>
                (For your personal agent's easy access to you)
              </span>{" "}
            </Box>
            <Box className="agent_content_input_box">
              <Box className="agent_content_input">
                <span>
                  <strong>*</strong>Email:
                </span>
                <Box>
                  <input
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    placeholder="Please input your email"
                    type="text"
                    maxLength={100}
                  />
                  {emailError && <p>{emailError}</p>}
                </Box>
              </Box>
              <Box className="agent_content_input">
                <span>
                  <strong>*</strong>Socail Media:
                </span>
                <Box>
                  <input
                    type="text"
                    value={socialMedia}
                    onChange={(e) => handleSocialMediaChange(e.target.value)}
                    placeholder="Social media and account"
                    maxLength={200}
                  />
                  {socialMediaError && <p>{socialMediaError}</p>}
                </Box>
              </Box>
            </Box>
          </Box>
        }
      />
    </div>
  );
};

export default PersonalAgentModal;
