import React, { Fragment, useMemo } from "react";
import {
  ChoiceList,
  Tag,
  Box,
  Modal,
  InlineStack,
  Autocomplete,
  BlockStack,
} from "@shopify/polaris";
import { useState, useEffect } from "react";
import { PlusCircleIcon } from "@shopify/polaris-icons";
import intl from "../../../../i18n/intl";
import "./index.css";
import _ from "lodash";
export default function NotImportedBulkEdit(props) {
  // 重置键值对
  function modifiedFilter(data) {
    let modifiedData = [];
    if (data && data?.length > 0) {
      modifiedData = data.map((item) => ({
        label: item?.title ? item?.title : item?.label,
        value: item?.id ? item?.id : item?.value,
      }));
    }
    return modifiedData;
  }
  const {
    open,
    type,
    option,
    getOption,
    onCloseBulk,
    handleSaveBulk,
    // handleSelectionChange,
    attributeData,
    setAttributeData,
  } = props;
  const [selected, setSelected] = useState([]); // 选中多选框的值
  const [selectedTags, setSelectedTags] = useState(
    Boolean(option?.length) ? option : []
  ); // 选中的批量编辑标签的值
  const [productTypeValue, setProductTypeValue] = useState(""); // Product type 输入值
  const [doneBtnLoading, setDoneBtnLoading] = useState(false);
  const handleChange = (value) => {
    setSelected(value);
  };

  // 保存
  const handleSave = () => {
    if (type === "collections") {
      if (selected.length === 0) {
        return;
      }
      handleSaveBulk("collections", selected);
    } else {
      if (selectedTags.length === 0) {
        return;
      }
      handleSaveBulk(type, selectedTags);
    }
    setDoneBtnLoading(true);
    setTimeout(() => {
      setDoneBtnLoading(false);
      // handleSelectionChange("page", false, 0);
      onCloseBulk();
    }, 1000);
  };
  const [tagsValue, setTagsValue] = useState(""); // Tags 输入值
  const [flagTags, setFlagTags] = useState(false);
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      type === "collections" ? getOption(selected) : getOption(selectedTags);
    }
    return () => {
      isMount = true;
    };
  }, [selected, selectedTags, getOption, type]);

  const activeModalData = useMemo(() => {
    switch (type) {
      case "collections":
        return {
          title: intl.get("Bulk Edit Product Collections"),
        };
      case "tags":
        return {
          title: intl.get("Bulk Edit Product Tags"),
        };
      case "type":
        return {
          title: intl.get("Bulk Edit Product Types"),
        };
      default:
    }
    // eslint-disable-next-line
  }, [type]);

  // Tags 下拉列表数据
  const tagsList = useMemo(() => {
    if (tagsValue) {
      return modifiedFilter(attributeData.tags).filter((item) => {
        return item.label.toLowerCase().indexOf(tagsValue.toLowerCase()) > -1;
      });
    }
    return modifiedFilter(attributeData.tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsValue, attributeData]);
  // 添加 tag
  const addTag = async () => {
    const tagsList = attributeData.tags;
    if (flagTags) {
      return;
    }
    tagsList.push({
      title: tagsValue,
      id: tagsValue,
    });
    let list = [...selectedTags, tagsValue];
    const uniqueArr = list.filter(
      (item, index) => list.indexOf(item) === index
    );
    setSelectedTags(uniqueArr);
    setAttributeData((prev) => ({
      ...prev,
      tags: tagsList,
    }));
    setTagsValue("");
  };

  // 删除 Tag
  const removeTagList = (item) => {
    const selected = _.filter(selectedTags, (id) => {
      return id !== item;
    });
    setSelectedTags(selected);
  };

  // ProductType 下拉列表数据
  const productTypeList = useMemo(() => {
    if (productTypeValue) {
      return modifiedFilter(attributeData.type).filter((item) => {
        return (
          item.label.toLowerCase().indexOf(productTypeValue.toLowerCase()) > -1
        );
      });
    }
    return modifiedFilter(attributeData.type)
      ? modifiedFilter(attributeData.type)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTypeValue, attributeData]);
  const [flagType, setFlagType] = useState(false);
  // 添加 Product type
  const addProductType = async () => {
    if (flagType) {
      return;
    }
    const productTypeList = attributeData.type;
    productTypeList.push({
      title: productTypeValue,
      id: productTypeValue,
    });
    // let list = [...selectedTags,productTypeValue];
    // const uniqueArr = list.filter((item, index) => list.indexOf(item) === index);
    setSelectedTags([productTypeValue]);
    setAttributeData((prev) => ({
      ...prev,
      type: productTypeList,
    }));
    setProductTypeValue("");
  };

  // 删除 Product Type
  const removeProductType = (item) => {
    const selected = _.filter(selectedTags, (id) => {
      return id !== item.value;
    });
    setSelectedTags(selected);
  };

  return (
    <Modal
      title={activeModalData.title}
      open={open}
      onClose={onCloseBulk}
      primaryAction={{
        content: intl.get("Confirm"),
        onAction: () => handleSave(),
        loading: doneBtnLoading,
      }}
      secondaryActions={[
        {
          content: intl.get("Cancel"),
          onAction: () => onCloseBulk(),
        },
      ]}
    >
      <Modal.Section>
        {type === "collections" ? (
          <ChoiceList
            allowMultiple
            title={intl.get("Please select collections.")}
            choices={option}
            selected={selected}
            onChange={handleChange}
          />
        ) : (
          <Fragment>
            <div className="tag_title">
              {type === "tags"
                ? intl.get("Please Add tags")
                : intl.get("Please add product type")}
            </div>
            <div className={`tag_content_once`}>
              {type === "tags" ? (
                <BlockStack gap={100}>
                  <Autocomplete
                    listTitle={"Suggested Tags"}
                    allowMultiple
                    options={tagsList}
                    selected={selectedTags}
                    onSelect={(options) => {
                      setSelectedTags(options);
                    }}
                    textField={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <Autocomplete.TextField
                            onChange={(value) => {
                              if (value.length > 50) {
                                return;
                              }
                              setTagsValue(value.trim());
                              let flag = false;
                              const list = modifiedFilter(attributeData.tags);
                              for (let i = 0; i < list.length; i++) {
                                if (list[i].value === value) {
                                  flag = true;
                                }
                              }
                              if (flag) {
                                setFlagTags(true);
                              } else {
                                setFlagTags(false);
                              }
                            }}
                            value={tagsValue}
                            placeholder={""}
                          />
                        </div>
                      </div>
                    }
                    actionBefore={
                      tagsValue &&
                      !flagTags && {
                        accessibilityLabel: "",
                        badge: {
                          tone: "new",
                          content: "New!",
                        },
                        content: "Add " + tagsValue,
                        helpText: "",
                        icon: PlusCircleIcon,
                        wrapOverflow: true,
                        onAction: () => addTag(),
                      }
                    }
                  />
                  <InlineStack wrap="wrap" gap={100}>
                    {_.map(selectedTags, (item) => {
                      return (
                        <div
                          key={item}
                          className="inline-block mr-3 mb-2 current_span"
                        >
                          <Tag
                            onRemove={() => {
                              removeTagList(item);
                              setTagsValue("");
                            }}
                          >
                            {item}
                          </Tag>
                        </div>
                      );
                    })}
                  </InlineStack>
                </BlockStack>
              ) : (
                <Box>
                  <BlockStack gap={100}>
                    <Autocomplete
                      options={productTypeList}
                      selected={selectedTags}
                      onSelect={(options) => {
                        setSelectedTags(options);
                      }}
                      textField={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ flex: 1 }}>
                            <Autocomplete.TextField
                              onChange={(value) => {
                                if (value.length > 50) {
                                  return;
                                }
                                setProductTypeValue(value);
                                const list = modifiedFilter(attributeData.type);
                                let flag = false;
                                for (let i = 0; i < list.length; i++) {
                                  if (list[i].value === value) {
                                    flag = true;
                                  }
                                }
                                if (flag) {
                                  setFlagType(true);
                                } else {
                                  setFlagType(false);
                                }
                              }}
                              // label={

                              // }
                              value={productTypeValue}
                              placeholder={"Search product type"}
                            />
                          </div>
                        </div>
                      }
                      actionBefore={
                        productTypeValue &&
                        !flagType && {
                          accessibilityLabel: "",
                          badge: {
                            tone: "new",
                            content: "New!",
                          },
                          content: "Add " + productTypeValue,
                          helpText: "",
                          icon: PlusCircleIcon,
                          wrapOverflow: true,
                          onAction: () => addProductType(),
                        }
                      }
                    />
                    <InlineStack wrap="wrap" gap={100}>
                      {_.map(selectedTags, (item) => {
                        return (
                          <div
                            key={item}
                            className="inline-block mr-3 current_span"
                          >
                            <Tag
                              onRemove={() => {
                                removeProductType(item);
                                setProductTypeValue("");
                              }}
                              // disabled={data?.status}
                            >
                              {item}
                            </Tag>
                          </div>
                        );
                      })}
                    </InlineStack>
                  </BlockStack>
                </Box>
              )}
            </div>
          </Fragment>
        )}
      </Modal.Section>
    </Modal>
  );
}
