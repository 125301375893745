import React, { Component } from 'react';

import FrameComponents from "../../FrameComponents"; 


class dashboard extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-mapping';
  }

  render() {

    return (
      <div>
        <FrameComponents name="mapping" shopifyProductId={this.props.match.params.id} /> 
      </div>
    );
    
  }
}

export default dashboard;
