import {
  Card,
  Filters,
  Button,
  Text,
  Box,
  InlineStack,
  Icon,
  BlockStack,
  Popover,
  Scrollable,
  Spinner,
} from "@shopify/polaris";
import { useState, useCallback, useEffect, useRef } from "react";
import {
  CaretDownIcon,
  XSmallIcon,
  MinusIcon,
  SearchIcon,
} from "@shopify/polaris-icons";
import ProductItem from "./ProductItem";
import "./common.css";
import ProductCateList from "./ProductCateList";
import CateFirstSelect from "./CateFirstSelect";
import FavoriteIconHover from "../Icon/FavoriteIconHover";
import FavoriteIcon from "../Icon/FavoriteIcon";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  reqGetFindProductCateListData,
  reqGetProductManagementProductsQuestionnaire,
  reqGetProductSelectionData,
  reqPostFindProductAdd,
} from "../../../../api";
import { useHistory,  withRouter } from "react-router-dom/cjs/react-router-dom";
import EnoughQuotaModal from "../SourcingRequest/component/EnoughQuotaModal";
import EnoughPointsModal from "../SourcingRequest/component/EnoughPointsModal";
import MarketEmpty from "./MarketEmpty";
import CollectEmpty from "./CollectEmpty";
import CommonFilterSkeleton from "./CommonFilterSkeleton";
import SearchLoading from "../../../../components/SearchLoading";
import CommonFilterSkeletonList from "./CommonFilterSkeletonList";
import MobileCateSelectModal from "./MobileCateSelectModal";
import utils from "../../../../utils/utils";
import { useSelector } from "react-redux";
const Index = ({
  planData,
  setPlanData,
  userPoints,
  setUserPoints,
  setPremiumData,
  handleMageUpgrade,
  subList,
  toastFun,
  handleSourcingModal,
  setNoviceModal,
  setProductTitle,
  handleTogglePopup,
  targetRef }) => {
    const router = useHistory()
  const [collectFirst,setCollectFirst] = useState(true)//仅在触发收藏时展示
  const [showId, setShowId] = useState("");
  const [firstCate,setFirstCate] = useState(false)
  const [cateShow, setCateShow] = useState(false);
  const [itemSkeleton, setItemSkeleton] = useState(false);
  const [initPage,setInitPage] = useState(true)// 初始化骨架屏
  const [filterLoading,setFilterLoading] = useState(false) // 搜索、筛选加载中
  const [priceRange, setPriceRange] = useState({
    priceRangeActive: false, //是否展示价格范围
    minPrice: "", //最小价格
    maxPrice: "", //最大价格
    currentMinPrice: "", //当前展示最小价格
    currentMaxPrice: "", //当前展示最大价格
  });
  const handleCateShow = useCallback(() => setCateShow(!cateShow), [cateShow]);

  const togglePriceRangeActive = () => {
    //否展示价格范围
    setPriceRange((preValue) => ({
      ...preValue,
      priceRangeActive: !priceRange.priceRangeActive,
      priceError: false,
    }));
  };

  const toggleClearPriceRangeActive = () => {
    // 清空价格范围
    setPriceRange((preValue) => ({
      ...preValue,
      priceRangeActive: !priceRange.priceRangeActive,
      minPrice: "",
      maxPrice: "",
      priceError: false,
    }));

    if (
      priceRange.currentMinPrice !== "" &&
      priceRange.currentMaxPrice !== ""
    ) {
      // 清空价格范围 重新请求列表
      setPriceRange((preValue) => ({
        ...preValue,
        currentMinPrice: "",
        currentMaxPrice: "",
      }));
      // getPageData(true)
      getListData({page:1,size:20,min_price: "",max_price: ""})
      
    }
  };
  const handlePriceRange = (val, type) => {
    const isNumber = /^\d*(\.\d{0,2})?$/.test(val);
    if (!isNumber) {
      return;
    }
    // 输入价格
    setPriceRange((preValue) => ({
      ...preValue,
      [type]: val,
      priceError: false,
    }));
  };
  const handlePriceRangeBlur = (val, type) => {
    // 失焦价格范围重置

    setPriceRange((preValue) => ({
      ...preValue,
      [type]: val,
      priceError: false,
    }));
    if (type === "maxPrice") {
      if (priceRange.minPrice && priceRange.minPrice >= val) {
        setPriceRange((preValue) => ({
          ...preValue,
          [type]: Math.round((Number(priceRange.minPrice) + 1) * 100) / 100,
        }));
        return;
      }
      setPriceRange((preValue) => ({
        ...preValue,
        [type]: val <= 0 ? 0.1 : Math.round(Number(val) * 100) / 100,
      }));
    } else {
      if (
        Number(val) >= Number(priceRange.maxPrice) &&
        priceRange.maxPrice !== ""
      ) {
        setPriceRange((preValue) => ({
          ...preValue,
          [type]: Number(val),
          maxPrice:Number(val)+1
        }));
      } else {
        setPriceRange((preValue) => ({
          ...preValue,
          [type]: val < 0 ? 0.1 : Math.round(Number(val) * 100) / 100,
        }));
      }
    }
  };
  const handlePriceRangeApply = () => {
    
    
    // 价格范围确定
    if (priceRange.minPrice !== "" && priceRange.maxPrice !== "") {
      setPriceRange((preValue) => ({
        ...preValue,
        currentMinPrice: priceRange.minPrice,
        currentMaxPrice: priceRange.maxPrice,
      }));
      getListData({page:1,size:20,min_price: priceRange.minPrice,max_price: priceRange.maxPrice})
      
      togglePriceRangeActive();
    } else {
      setPriceRange((preValue) => ({
        ...preValue,
        priceError: true,
      }));
    }
  };
   const plan_level = useSelector(
      (state) => state.languageReducer.subScriptData
    );
  // 用户满意度调查

    const getSurveyData = async () => {
      try {
        let res = await reqGetProductManagementProductsQuestionnaire({event:'product_selection'});
        if (res.code === "200") {
          if (!res.data) {
            window.onSurveyLoad(plan_level)
          }
        }
      } catch (error) {}
    };


  const [more, setMore] = useState(true); // 是否加载更多数据
  const [listData, setListData] = useState({
    // 列表数据
    list: [],
    fav_count: 0,
    first_favorite: 0,
    first_import: 0,
  });
  const [requestParams, setRequestParams] = useState({
    // 请求参数
    page: 1,
    size: 20,
    product_name: "",
    winning:0,
    category_id: "",
    min_price: "",
    max_price: "",
    favorite: 0,
  });
  const [currentPath, setCurrentPath] = useState("");
  // 商品数据获取
  const getListData = async (data) => {
    const { search, pathname } = router.location;
    const urlParams = new URLSearchParams(search);
    
    // 获取浏览器参数
    const category = Number(urlParams.get("category")) || "";
    const minPrice = urlParams.get("minPrice") || "";
    const maxPrice = urlParams.get("maxPrice") || "";
    const favorite = Number(urlParams.get("favorite")) || 0;
    try {
      // 合并参数，优先使用 `data` 参数
      let param = {...requestParams, ...data}
      let params = {
        ...param,
        category_id: (param?.category_id || data?.category_id === "") ? param.category_id : category,
        min_price: (param?.min_price || data?.min_price === '') ? param.min_price : minPrice,
        max_price: (param?.max_price || data?.max_price === '') ? param.max_price : maxPrice,
        favorite: (param?.favorite || data?.favorite === 0) ? param.favorite : favorite,
      };
      
      // 检查价格参数是否需要清空
      if (!params.min_price || !params.max_price) {
        params.min_price = "";
        params.max_price = "";
  
        // 删除 URL 参数
        urlParams.delete("minPrice");
        urlParams.delete("maxPrice");
  
        // 更新状态
        setPriceRange((prevValue) => ({
          ...prevValue,
          minPrice: "",
          maxPrice: "",
          currentMinPrice: "",
          currentMaxPrice: "",
        }));
      } else {
        // 同步价格参数到 URL
        urlParams.set("minPrice", params.min_price);
        urlParams.set("maxPrice", params.max_price);
  
        // 更新状态
        setPriceRange((prevValue) => ({
          ...prevValue,
          minPrice: params.min_price,
          maxPrice: params.max_price,
          currentMinPrice: params.min_price,
          currentMaxPrice: params.max_price,
        }));
      }
      
      if (params.favorite === 0) {
        urlParams.delete("favorite");
      }else {
        urlParams.set("favorite", params.favorite);
        setCollectFirst(false)
      }
      
      // 检查是否需要更新加载状态
      if (data) {
        setFilterLoading(true);
        setMore(true);
        handleBackToTop();
        setItemSkeleton(true);
      }
  
      // 设置产品标题
      if (params.product_name) {
        setProductTitle(params.product_name);
      }
  
      // 更新请求参数
      setRequestParams(params);
  
      // 发起数据请求
      let res = await reqGetProductSelectionData(params);
      if (res.code === "200") {
        setFirstCate(Number(res.data.select_cate) === 1);
  
        // 更新数据列表
        setListData((preValue) => ({
          ...preValue,
          ...res.data,
          list: res.data.list,
        }));
  
        // 处理分类逻辑
        if (res.data.category) {
          setCateName(res.data.category.name || "All Category");
  
          setRequestParams((prevValue) => ({
            ...prevValue,
            category_id: res.data.category.id,
          }));
  
          if (res.data.category.id) {
            urlParams.set("category", res.data.category.id);
          } else {
            urlParams.delete("category");
          }
        }
      } else {
        // 清空列表数据
        setListData((preValue) => ({
          ...preValue,
          list: [],
        }));
      }
  
      // 判断是否加载更多数据
      if (res.data.count === res.data.list.length) {
        setMore(false);
      }
      urlParams.set("select_tab", "0")
      // 更新浏览器地址栏 URL
      const newPath = urlParams.toString() ? `${pathname}?${urlParams}` : pathname;
      setCurrentPath(newPath)
      utils.routeListen(newPath);
      window.history.replaceState(null, "", newPath);
  
      // 恢复加载状态
      setSearchLoading(false);
      setFilterLoading(false);
      setItemSkeleton(false);
      setInitPage(false);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  


  const [cateData, setCateData] = useState(cateList);// 默认分类
  const [cateName, setCateName] = useState("All Category");
  // 分类数据获取
  const getCateData = async () => {
    let params = new URLSearchParams(window.location.search);
    let cateId = Number(params.get("cateId"));
    const response = await reqGetFindProductCateListData();
    if ((response.code = "200")) {
      let data = response.data;
      setCateData(data);
      if (cateId) {
        setRequestParams((preValue) => ({
          ...preValue,
          category_id: cateId,
        }));
        let id = Number(cateId);
        // 遍历顶级分类
        for (let i = 0; i < data.length; i++) {
          const category = data[i];
          // 如果匹配到顶级分类
          if (category.category_id === id) {
            setCateName(category.category_name);
            return;
          }

          // 遍历子分类
          for (let j = 0; j < category.children.length; j++) {
            const subCategory = category.children[j];
            // 如果匹配到子分类
            if (subCategory.category_id === id) {
              setCateName(subCategory.category_name);
              return;
            }

            // 遍历三级分类
            for (let k = 0; k < subCategory.children.length; k++) {
              const thirdCategory = subCategory.children[k];
              // 如果匹配到三级分类
              if (thirdCategory.category_id === id) {
                setCateName(thirdCategory.category_name);
                return;
              }
            }
          }
        }
      }
    }
  };

  const handleSelectCate = (id, name) => {
    setCateName(name);
    getListData({ page: 1, size: 20, category_id: id, all: 1 });
    setCateShow(false);
    setMobileCateSelect(false)
  };

  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getListData();
      getCateData();
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 滚动加载
  const fetchMoreData = () => {
    let params = { ...requestParams, page: requestParams.page + 1 };
    setTimeout(async () => {
      const res = await reqGetProductSelectionData(params);
      if (res.code === "200") {
        setListData((preValue) => ({
          ...preValue,
          list: preValue.list.concat(res.data.list),
        }));
        setRequestParams((preValue) => ({
          ...preValue,
          page: preValue.page + 1,
        }));
        if (res.data.list.length === 0) {
          setMore(false);
        }
      } else {
        setMore(false);
      }
    }, 1500);
  };
  const useCollectNum = (favCount) => {
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      if (favCount) {
        setIsVisible(true);
  
        const timer = setTimeout(() => {
          setIsVisible(false); // 3 秒后变为 false
        }, 2000);
  
        return () => clearTimeout(timer); // 清除定时器
      }
    }, [favCount]); // 监听 favCount 变化
  
    return isVisible;
  };
  const collectNum = useCollectNum(listData?.fav_count);

  const [searchLoading,setSearchLoading] = useState(false);
  const handleFiltersQueryChange = useCallback(
    (value) => {
      if (value.trim().length > 200) {
        return
      }
      setRequestParams((prev) => ({ ...prev, product_name: value.replace(/^\s+/, "") }));
    },
    [],
  );

  // 次数不足
  const [sourcingLimitModal,setSourcingLimitModal] = useState(false)
  // 达到上限
  const [enoughPoint,setEnoughPoint] = useState(false);
  const handleSubmit = (val) => {
    if (val) {
      if (val === 5) {
        setPremiumData((prevValue) => ({
            ...prevValue,
            open: true,
          }));
        setSourcingLimitModal(false)
    }
    if (val === 6) {
      handleProductImport(userPoints.userPointsRedeem.record_id,userPoints.userPointsRedeem.pid,userPoints.userPointsRedeem.tag,userPoints.userPointsRedeem.keys)
      setSourcingLimitModal(false)
    }
        if (val <5) {
          handleMageUpgrade(val)
        }
    }
  };
  // 产品导入 使用积分
  const handleProductImport = async (productId, cj_pid, tag,keys) => {
    try {
      let params = {
        record_id: productId,
        pid: cj_pid,
        tag,
        is_point: 1,
      };
      let res = await reqPostFindProductAdd(params);
      if (res.code === "200") {
        getSurveyData();
        let list = listData.list;
        list[keys].dsm_pid = res.data.id;
        list[keys].is_green = 1;
        setPlanData((prev)=>({
          ...prev,
          productLimit:planData.productLimit + 1
        }))
        setUserPoints((prevValue)=>({
          ...prevValue,
          userPointsData:{
            ...prevValue.userPointsData,
            available_points:Number(prevValue.userPointsData.available_points) - 150
          }
        }))
        toastFun("Added successfully")
      }
      
      
    } catch (error) {}
  };

  const boxRef = useRef(null);

  const handleClickOutside = (event) => {
    // 检查点击是否发生在盒子之外
    if (boxRef.current && boxRef.current.contains(event.target)) {
      return;
    }else {
      setCateShow(false)
    }
  };

  useEffect(() => {
    // 添加全局点击事件监听
    document.addEventListener("mousedown", handleClickOutside);

    // 清除监听事件
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
     if (requestParams.product_name !== "" && !searchLoading) {
      setSearchLoading(true)
      getListData({page:1,size:20,product_name: requestParams?.product_name.trim()})
     }
   }
   
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);
  const scrollableRef = useRef(null); // 引用滚动容器
  const handleBackToTop = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const widthPage = useScreenWidth();

  const [mobileCateSelect,setMobileCateSelect] = useState(false)// 移动端分类选择
  return (
    <div style={{ marginTop: "16px",position: "relative" }}>
     {initPage ? <CommonFilterSkeleton type="markplace"/> : <Card roundedAbove="sm" padding="0">
        <Box>
          <Filters
            queryValue={requestParams.product_name}
            queryPlaceholder={widthPage >420 ? "Find products in Dropshipman" : "Find products"}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={() => {getListData({page:1,size:20,product_name: ''})}}
            onClearAll={() => {}}
          >
            <Box paddingInlineStart="0">
              <Button
                // disabled={requestParams.product_name === ''}
                onClick={() => {
                  getListData({page:1,size:20,product_name: requestParams?.product_name.trim()})
                  setSearchLoading(true)
                }}
                // size="micro"
                loading={searchLoading}
                icon={SearchIcon}
              >
                Search
              </Button>
            </Box>
          </Filters>
        </Box>
        <Box className="product_commone_filter_box">
          <Box className="product_commone_filter_button">
            <InlineStack wrap={false} gap={100} direction={"row"}>
              <Box
              ref={boxRef}
                className={
                  true
                    ? "product_commone_filter_button_item"
                    : "product_commone_filter_button_item product_commone_filter_button_item_active"
                }
              >
                <Button onClick={() => {
                  if (widthPage > 420) {
                    handleCateShow()
                  }else {
                    setMobileCateSelect(true)
                  }
                } }>
                  <InlineStack wrap={false} gap={0} direction={"row"}>
                    <Box>
                      {" "}
                      <Text as="span">
                        {cateName === "All Category"
                          ? "All Category"
                          : cateName}
                      </Text>
                    </Box>
                    <Box>
                      {cateName === "All Category" ? (
                        <span>
                          <Icon source={CaretDownIcon} tone="base" />
                        </span>
                      ) : (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectCate("", "All Category");
                            
                          }}
                        >
                          <Icon source={XSmallIcon} tone="base" />
                        </span>
                      )}{" "}
                    </Box>
                  </InlineStack>
                </Button>
              </Box>
              <Box
                className={
                  true
                    ? "product_commone_filter_button_item"
                    : "product_commone_filter_button_item product_commone_filter_button_item_active"
                }
              >
                <div className="price_range_find">
                  <Popover
                    active={priceRange.priceRangeActive}
                    activator={
                      <Button onClick={() => togglePriceRangeActive()}>
                        <InlineStack wrap={false} gap={0} direction={"row"}>
                          <Box>
                            {" "}
                            <span>
                              {priceRange.currentMinPrice !== "" &&
                              priceRange.currentMaxPrice !== ""
                                ? `$${priceRange.currentMinPrice} - $${priceRange.currentMaxPrice}`
                                : "Price Range"}
                            </span>
                          </Box>
                          <Box>
                            {true ? (
                              <span>
                                <Icon source={CaretDownIcon} tone="base" />
                              </span>
                            ) : (
                              <span>
                                <Icon source={XSmallIcon} tone="base" />
                              </span>
                            )}{" "}
                          </Box>
                        </InlineStack>
                      </Button>
                    }
                    autofocusTarget="container"
                    onClose={() => togglePriceRangeActive()}
                  >
                    <div className="price_range_find_content">
                      <div style={{ fontSize: 16, fontWeight: 600 }}>
                        Price Range
                      </div>
                      <div className="price_range_find_input">
                        <div>
                          <input
                            type="text"
                            className={
                              priceRange.priceError &&
                              priceRange.minPrice === ""
                                ? "border_input_price"
                                : ""
                            }
                            onBlur={(e) => {
                              if (!isNaN(parseFloat(e.target.value))) {
                                handlePriceRangeBlur(
                                  Number(e.target.value),
                                  "minPrice"
                                );
                              }
                            }}
                            onChange={(e) => {
                              if (
                                !isNaN(parseFloat(e.target.value)) ||
                                e.target.value === ""
                              ) {
                                handlePriceRange(e.target.value, "minPrice");
                              }
                            }}
                            maxLength={6}
                            value={priceRange.minPrice}
                            placeholder="$ Min"
                          />
                        </div>
                        <div>
                          <Icon source={MinusIcon} tone="base" />
                        </div>
                        <div>
                          <input
                            type="text"
                            maxLength={6}
                            className={
                              priceRange.priceError &&
                              priceRange.maxPrice === ""
                                ? "border_input_price"
                                : ""
                            }
                            onBlur={(e) => {
                              if (!isNaN(parseFloat(e.target.value))) {
                                handlePriceRangeBlur(
                                  Number(e.target.value),
                                  "maxPrice"
                                );
                              }
                            }}
                            onChange={(e) => {
                              if (
                                !isNaN(parseFloat(e.target.value)) ||
                                e.target.value === ""
                              ) {
                                handlePriceRange(e.target.value, "maxPrice");
                              }
                            }}
                            value={priceRange.maxPrice}
                            placeholder="$ Max"
                          />
                        </div>
                      </div>
                      <p
                        style={{
                          visibility: priceRange.priceError
                            ? "visible"
                            : "hidden",
                          margin: "5px 0",
                          color: "red",
                        }}
                      >
                        Price is required.
                      </p>
                      <div className="price_range_find_button">
                        <Button onClick={() => toggleClearPriceRangeActive()}>
                          Clear
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => handlePriceRangeApply()}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>
              </Box>
              <Box
                className={requestParams.favorite === 1 ? "product_commone_filter_button_item_collect item_collect_active" :"product_commone_filter_button_item_collect"}
                ref={targetRef}
                onClick={()=>{
                  if (requestParams.favorite === 1) {
                    return
                  }
                  getListData({page: 1,
                  size: 20,
                  product_name: "",
                  winning:0,
                  category_id: "",
                  min_price: "",
                  max_price: "",
                  favorite:1})
                  setCateName("All Category");
                  setCollectFirst(false)
                  setPriceRange({
                    priceRangeActive: false, //是否展示价格范围
                    minPrice: "", //最小价格
                    maxPrice: "", //最大价格
                    currentMinPrice: "", //当前展示最小价格
                    currentMaxPrice: "",
                  })
                }
              }
              >
                <InlineStack wrap={false} gap={0} direction={"row"}>
                  <Box>
                    {" "}
                    <Text as="span">Favorites</Text>
                  </Box>
                  {collectFirst ? <Box
                    className={
                      "product_commone_filter_button_item_collect_icon"
                    }
                  >
                      <span className="item_collect_icon_none">
                        <Icon source={FavoriteIcon} tone="base" />
                      </span>
                  </Box> : <Box
                    className={
                      "product_commone_filter_button_item_collect_icon"
                    }
                  >
                    {((listData?.fav_count === 0 && requestParams.favorite !== 1) || (!collectNum && requestParams.favorite !== 1) ) ? (
                      <span className="item_collect_icon_none">
                        <Icon source={FavoriteIcon} tone="base" />
                      </span>
                    ) : (
                      <span className={((requestParams.favorite === 1 || collectNum) ) ? "item_collect_icon_show" : "item_collect_icon_show_none"}>
                        <Icon source={FavoriteIconHover} tone="base" />
                      </span>
                    )}
                  </Box>}
                  {(requestParams.favorite === 1 || listData?.fav_count > 0) ? (
                    <span className={collectFirst ?  "" : (collectNum ) ? "item_collect_count" : ""}>{listData?.fav_count}</span>
                  ) : (
                    ""
                  )}
                  {requestParams.favorite === 1 ? <span
                          onClick={(e) => {
                            e.stopPropagation();
                            getListData({page: 1,
                              size: 20,
                              product_name: "",
                              winning:0,
                              category_id: "",
                              min_price: "",
                              max_price: "",
                              favorite:0})
                              setCateName("All Category")
                              setPriceRange({
                                priceRangeActive: false, //是否展示价格范围
                                minPrice: "", //最小价格
                                maxPrice: "", //最大价格
                                currentMinPrice: "", //当前展示最小价格
                                currentMaxPrice: "",
                              })
                          }}
                        >
                          <Icon source={XSmallIcon} tone="base" />
                        </span> :''}
                </InlineStack>
              </Box>
            </InlineStack>
          </Box>
          {cateShow && (
            <ProductCateList
              cateData={cateData}
              requestParams={requestParams}
              getListData={getListData}
              boxRef={boxRef}
              handleSelectCate={handleSelectCate}
            />
          )}
        </Box>
        {itemSkeleton ?  <Box style={{height:"700px"}}><CommonFilterSkeletonList /></Box> : <Box style={{height:"700px"}}>
        {listData.list.length > 0 ? <Scrollable className="scrollableBox" id="scrollableDiv" ref={scrollableRef} style={{ height: "700px" }} focusable>
          <InfiniteScroll
            dataLength={listData.list.length}
            next={(e) => fetchMoreData()} 
            hasMore={more}
            scrollableTarget="scrollableDiv"
            loader={more && <h4 style={{textAlign:'center'}}><Spinner accessibilityLabel="Small spinner example" size="small" /></h4>}
            endMessage={
              (requestParams?.favorite === 1 || listData.list.length === 0) ? "" : <Box style={{ textAlign: "center",marginBottom:"16px" }}>
                <Box style={{fontWeight:"650",fontSize:'13px'}}>No more products</Box>
                <Box style={{color:"rgba(97, 97, 97, 1)",margin:"10px 0"}}>Get more products you want through sourcing request</Box>
                <Box><Button variant="primary" onClick={()=>{handleSourcingModal()}}>Submit a sourcing request</Button></Box>
              </Box>
            }
            refreshFunction={(e) => fetchMoreData()}
            pullDownToRefresh
          >
            <BlockStack gap="300" direction="cloumn">
              <Box className="product_commone_content_list">
                {listData.list.map((item, ind) => (
                  <Box className="product_commone_content_list_item" key={ind}>
                    <Card padding={0}>
                      <ProductItem
                        getSurveyData={getSurveyData}
                        requestParams={requestParams}
                        listData={listData}
                        setListData={setListData}
                        getListData={getListData}
                        setSourcingLimitModal={setSourcingLimitModal}
                        showId={showId}
                        planData={planData}
                        userPoints={userPoints}
                        setUserPoints={setUserPoints}
                        setPlanData={setPlanData}
                        setEnoughPoint={setEnoughPoint}
                        handleTogglePopup={handleTogglePopup}
                        setShowId={setShowId}
                        item={item}
                        keys={ind}
                        toastFun={toastFun}
                        setCollectFirst={setCollectFirst}
                        currentPath={currentPath}
                      />
                    </Card>
                  </Box>
                ))}
              </Box>
            </BlockStack>
          </InfiniteScroll>
        </Scrollable> : requestParams?.favorite === 1 ? <CollectEmpty /> : <MarketEmpty 
        requestParams={requestParams} 
        priceRange={priceRange} 
        cateName={cateName}
        handleSourcingModal={handleSourcingModal}
        setNoviceModal={setNoviceModal}
        />} 
        </Box>}
      </Card> }
      {filterLoading && <SearchLoading />}
       {firstCate && <CateFirstSelect open={firstCate} setOpen={setFirstCate} toastFun={toastFun}  getListData={getListData} />}
       {/* 次数不够 */}
       {sourcingLimitModal && <EnoughQuotaModal type={'product'} handleSubmit={handleSubmit} sourcingLimitModal={sourcingLimitModal} userPoints={userPoints} subList={subList} setSourcingLimitModal={setSourcingLimitModal}/>}
       {/* 会员上限且积分不够 */}
       {enoughPoint && <EnoughPointsModal type={'product'}  open={enoughPoint} setOpen={setEnoughPoint}/>}
        {/* 移动端分类选择弹窗 */}
        {widthPage > 420 ? "" :<MobileCateSelectModal 
          open={mobileCateSelect} 
          setOpen={setMobileCateSelect}  
          cateData={cateData}
          requestParams={requestParams}
          getListData={getListData}
          boxRef={boxRef}
          handleSelectCate={handleSelectCate}/>}
    </div>
  );
};
const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
};

const cateList = [
  {
    category_id: 1,
    pid: 0,
    level: 1,
    category_name: "Computer & Office",
    seo_category_name: "computer-office",
    children: [
      {
        category_id: 2,
        pid: 1,
        level: 2,
        category_name: "Office Electronics",
        seo_category_name: "office-electronics",
        children: [
          {
            category_id: 3,
            pid: 2,
            level: 3,
            category_name: "Office & School Supplies",
            seo_category_name: "office-school-supplies",
            children: [],
          },
          {
            category_id: 4,
            pid: 2,
            level: 3,
            category_name: "Printer Supplies",
            seo_category_name: "printer-supplies",
            children: [],
          },
          {
            category_id: 5,
            pid: 2,
            level: 3,
            category_name: "3D Printers",
            seo_category_name: "3d-printers",
            children: [],
          },
          {
            category_id: 6,
            pid: 2,
            level: 3,
            category_name: "3D Pens",
            seo_category_name: "3d-pens",
            children: [],
          },
          {
            category_id: 7,
            pid: 2,
            level: 3,
            category_name: "Printers",
            seo_category_name: "printers",
            children: [],
          },
          {
            category_id: 8,
            pid: 2,
            level: 3,
            category_name: "Scanners",
            seo_category_name: "scanners",
            children: [],
          },
        ],
      },
      {
        category_id: 9,
        pid: 1,
        level: 2,
        category_name: "Security & Protection",
        seo_category_name: "security-protection",
        children: [
          {
            category_id: 10,
            pid: 9,
            level: 3,
            category_name: "Alarm & Sensor",
            seo_category_name: "alarm-sensor",
            children: [],
          },
          {
            category_id: 11,
            pid: 9,
            level: 3,
            category_name: "Fire Protection",
            seo_category_name: "fire-protection",
            children: [],
          },
          {
            category_id: 12,
            pid: 9,
            level: 3,
            category_name: "Workplace Safety Supplies",
            seo_category_name: "workplace-safety-supplies",
            children: [],
          },
          {
            category_id: 13,
            pid: 9,
            level: 3,
            category_name: "Door Intercom",
            seo_category_name: "door-intercom",
            children: [],
          },
          {
            category_id: 14,
            pid: 9,
            level: 3,
            category_name: "Surveillance Products",
            seo_category_name: "surveillance-products",
            children: [],
          },
        ],
      },
      {
        category_id: 15,
        pid: 1,
        level: 2,
        category_name: "Storage Devices",
        seo_category_name: "storage-devices",
        children: [
          {
            category_id: 16,
            pid: 15,
            level: 3,
            category_name: "SSD",
            seo_category_name: "ssd",
            children: [],
          },
          {
            category_id: 17,
            pid: 15,
            level: 3,
            category_name: "USB Flash Drives",
            seo_category_name: "usb-flash-drives",
            children: [],
          },
          {
            category_id: 18,
            pid: 15,
            level: 3,
            category_name: "HDD Enclosures",
            seo_category_name: "hdd-enclosures",
            children: [],
          },
          {
            category_id: 19,
            pid: 15,
            level: 3,
            category_name: "Memory Cards",
            seo_category_name: "memory-cards",
            children: [],
          },
          {
            category_id: 20,
            pid: 15,
            level: 3,
            category_name: "External Hard Drives",
            seo_category_name: "external-hard-drives",
            children: [],
          },
        ],
      },
      {
        category_id: 21,
        pid: 1,
        level: 2,
        category_name: "Tablet & Laptop Accessories",
        seo_category_name: "tablet-laptop-accessories",
        children: [
          {
            category_id: 22,
            pid: 21,
            level: 3,
            category_name: "Tablet LCD Screens",
            seo_category_name: "tablet-lcd-screens",
            children: [],
          },
          {
            category_id: 23,
            pid: 21,
            level: 3,
            category_name: "Laptop Batteries",
            seo_category_name: "laptop-batteries",
            children: [],
          },
          {
            category_id: 24,
            pid: 21,
            level: 3,
            category_name: "Laptop Bags & Cases",
            seo_category_name: "laptop-bags-cases",
            children: [],
          },
          {
            category_id: 25,
            pid: 21,
            level: 3,
            category_name: "Tablet Cases",
            seo_category_name: "tablet-cases",
            children: [],
          },
          {
            category_id: 26,
            pid: 21,
            level: 3,
            category_name: "Tablet Accessories",
            seo_category_name: "tablet-accessories",
            children: [],
          },
        ],
      },
      {
        category_id: 27,
        pid: 1,
        level: 2,
        category_name: "Laptop & Tablets",
        seo_category_name: "laptop-tablets",
        children: [
          {
            category_id: 28,
            pid: 27,
            level: 3,
            category_name: "Phone Call Tablets",
            seo_category_name: "phone-call-tablets",
            children: [],
          },
          {
            category_id: 29,
            pid: 27,
            level: 3,
            category_name: "2 in 1 Tablets",
            seo_category_name: "2-in-1-tablets",
            children: [],
          },
          {
            category_id: 30,
            pid: 27,
            level: 3,
            category_name: "Laptops",
            seo_category_name: "laptops",
            children: [],
          },
          {
            category_id: 31,
            pid: 27,
            level: 3,
            category_name: "Tablets",
            seo_category_name: "tablets",
            children: [],
          },
          {
            category_id: 32,
            pid: 27,
            level: 3,
            category_name: "Gaming Laptops",
            seo_category_name: "gaming-laptops",
            children: [],
          },
        ],
      },
      {
        category_id: 33,
        pid: 1,
        level: 2,
        category_name: "Networking",
        seo_category_name: "networking",
        children: [
          {
            category_id: 34,
            pid: 33,
            level: 3,
            category_name: "Modem-Router Combos",
            seo_category_name: "modemrouter-combos",
            children: [],
          },
          {
            category_id: 35,
            pid: 33,
            level: 3,
            category_name: "Wireless Routers",
            seo_category_name: "wireless-routers",
            children: [],
          },
          {
            category_id: 36,
            pid: 33,
            level: 3,
            category_name: "Networking Tools",
            seo_category_name: "networking-tools",
            children: [],
          },
          {
            category_id: 37,
            pid: 33,
            level: 3,
            category_name: "3G Modems",
            seo_category_name: "3g-modems",
            children: [],
          },
          {
            category_id: 38,
            pid: 33,
            level: 3,
            category_name: "Network Cards",
            seo_category_name: "network-cards",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 39,
    pid: 0,
    level: 1,
    category_name: "Bags & Shoes",
    seo_category_name: "bags-shoes",
    children: [
      {
        category_id: 40,
        pid: 39,
        level: 2,
        category_name: "Men's Luggage & Bags",
        seo_category_name: "mens-luggage-bags",
        children: [
          {
            category_id: 41,
            pid: 40,
            level: 3,
            category_name: "Briefcases",
            seo_category_name: "briefcases",
            children: [],
          },
          {
            category_id: 42,
            pid: 40,
            level: 3,
            category_name: "Waist Bags",
            seo_category_name: "waist-bags",
            children: [],
          },
          {
            category_id: 43,
            pid: 40,
            level: 3,
            category_name: "Girls Bags",
            seo_category_name: "girls-bags",
            children: [],
          },
          {
            category_id: 44,
            pid: 40,
            level: 3,
            category_name: "Men's Backpacks",
            seo_category_name: "mens-backpacks",
            children: [],
          },
          {
            category_id: 45,
            pid: 40,
            level: 3,
            category_name: "Luggage & Travel Bags",
            seo_category_name: "luggage-travel-bags",
            children: [],
          },
          {
            category_id: 46,
            pid: 40,
            level: 3,
            category_name: "Crossbody Bags",
            seo_category_name: "crossbody-bags",
            children: [],
          },
          {
            category_id: 47,
            pid: 40,
            level: 3,
            category_name: "Man Wallets",
            seo_category_name: "man-wallets",
            children: [],
          },
        ],
      },
      {
        category_id: 48,
        pid: 39,
        level: 2,
        category_name: "Women's Shoes",
        seo_category_name: "womens-shoes",
        children: [
          {
            category_id: 49,
            pid: 48,
            level: 3,
            category_name: "Woman Boots",
            seo_category_name: "woman-boots",
            children: [],
          },
          {
            category_id: 50,
            pid: 48,
            level: 3,
            category_name: "Vulcanize Shoes",
            seo_category_name: "vulcanize-shoes",
            children: [],
          },
          {
            category_id: 51,
            pid: 48,
            level: 3,
            category_name: "Pumps",
            seo_category_name: "pumps",
            children: [],
          },
          {
            category_id: 52,
            pid: 48,
            level: 3,
            category_name: "Woman Slippers",
            seo_category_name: "woman-slippers",
            children: [],
          },
          {
            category_id: 53,
            pid: 48,
            level: 3,
            category_name: "Woman Sandals",
            seo_category_name: "woman-sandals",
            children: [],
          },
          {
            category_id: 54,
            pid: 48,
            level: 3,
            category_name: "Flats",
            seo_category_name: "flats",
            children: [],
          },
        ],
      },
      {
        category_id: 55,
        pid: 39,
        level: 2,
        category_name: "Women's Luggage & Bags",
        seo_category_name: "womens-luggage-bags",
        children: [
          {
            category_id: 56,
            pid: 55,
            level: 3,
            category_name: "Woman Wallets",
            seo_category_name: "woman-wallets",
            children: [],
          },
          {
            category_id: 57,
            pid: 55,
            level: 3,
            category_name: "Evening Bags",
            seo_category_name: "evening-bags",
            children: [],
          },
          {
            category_id: 58,
            pid: 55,
            level: 3,
            category_name: "Fashion Backpacks",
            seo_category_name: "fashion-backpacks",
            children: [],
          },
          {
            category_id: 59,
            pid: 55,
            level: 3,
            category_name: "Shoulder Bags",
            seo_category_name: "shoulder-bags",
            children: [],
          },
          {
            category_id: 60,
            pid: 55,
            level: 3,
            category_name: "Totes",
            seo_category_name: "totes",
            children: [],
          },
          {
            category_id: 61,
            pid: 55,
            level: 3,
            category_name: "Boys Bags",
            seo_category_name: "boys-bags",
            children: [],
          },
          {
            category_id: 62,
            pid: 55,
            level: 3,
            category_name: "Clutches",
            seo_category_name: "clutches",
            children: [],
          },
          {
            category_id: 610,
            pid: 55,
            level: 3,
            category_name: "Women's Crossbody Bags",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 63,
        pid: 39,
        level: 2,
        category_name: "Men's Shoes",
        seo_category_name: "mens-shoes",
        children: [
          {
            category_id: 64,
            pid: 63,
            level: 3,
            category_name: "Man Boots",
            seo_category_name: "man-boots",
            children: [],
          },
          {
            category_id: 65,
            pid: 63,
            level: 3,
            category_name: "Formal Shoes",
            seo_category_name: "formal-shoes",
            children: [],
          },
          {
            category_id: 66,
            pid: 63,
            level: 3,
            category_name: "Man Slippers",
            seo_category_name: "man-slippers",
            children: [],
          },
          {
            category_id: 67,
            pid: 63,
            level: 3,
            category_name: "Vulcanize Shoe",
            seo_category_name: "vulcanize-shoe",
            children: [],
          },
          {
            category_id: 68,
            pid: 63,
            level: 3,
            category_name: "Man Sandals",
            seo_category_name: "man-sandals",
            children: [],
          },
          {
            category_id: 69,
            pid: 63,
            level: 3,
            category_name: "Casual Shoes",
            seo_category_name: "casual-shoes",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 70,
    pid: 0,
    level: 1,
    category_name: "Jewelry & Watches",
    seo_category_name: "jewelry-watches",
    children: [
      {
        category_id: 71,
        pid: 70,
        level: 2,
        category_name: "Wedding & Engagement",
        seo_category_name: "wedding-engagement",
        children: [
          {
            category_id: 72,
            pid: 71,
            level: 3,
            category_name: "Bridal Jewelry Sets",
            seo_category_name: "bridal-jewelry-sets",
            children: [],
          },
          {
            category_id: 73,
            pid: 71,
            level: 3,
            category_name: "Wedding Hair Jewelry",
            seo_category_name: "wedding-hair-jewelry",
            children: [],
          },
          {
            category_id: 74,
            pid: 71,
            level: 3,
            category_name: "Engagement Rings",
            seo_category_name: "engagement-rings",
            children: [],
          },
          {
            category_id: 75,
            pid: 71,
            level: 3,
            category_name: "Wedding & Engagement",
            seo_category_name: "wedding-engagement",
            children: [],
          },
        ],
      },
      {
        category_id: 76,
        pid: 70,
        level: 2,
        category_name: "Fashion Jewelry",
        seo_category_name: "fashion-jewelry",
        children: [
          {
            category_id: 77,
            pid: 76,
            level: 3,
            category_name: "Bracelets & Bangles",
            seo_category_name: "bracelets-bangles",
            children: [],
          },
          {
            category_id: 78,
            pid: 76,
            level: 3,
            category_name: "Brooches",
            seo_category_name: "brooches",
            children: [],
          },
          {
            category_id: 79,
            pid: 76,
            level: 3,
            category_name: "Keychains",
            seo_category_name: "keychains",
            children: [],
          },
          {
            category_id: 80,
            pid: 76,
            level: 3,
            category_name: "Charms",
            seo_category_name: "charms",
            children: [],
          },
          {
            category_id: 81,
            pid: 76,
            level: 3,
            category_name: "Rings",
            seo_category_name: "rings",
            children: [],
          },
          {
            category_id: 82,
            pid: 76,
            level: 3,
            category_name: "Body Jewelry",
            seo_category_name: "body-jewelry",
            children: [],
          },
          {
            category_id: 83,
            pid: 76,
            level: 3,
            category_name: "Fashion Jewelry Sets",
            seo_category_name: "fashion-jewelry-sets",
            children: [],
          },
          {
            category_id: 84,
            pid: 76,
            level: 3,
            category_name: "Necklace & Pendants",
            seo_category_name: "necklace-pendants",
            children: [],
          },
          {
            category_id: 85,
            pid: 76,
            level: 3,
            category_name: "Men's Cuff Links",
            seo_category_name: "mens-cuff-links",
            children: [],
          },
          {
            category_id: 86,
            pid: 76,
            level: 3,
            category_name: "Earrings",
            seo_category_name: "earrings",
            children: [],
          },
        ],
      },
      {
        category_id: 87,
        pid: 70,
        level: 2,
        category_name: "Fine Jewelry",
        seo_category_name: "fine-jewelry",
        children: [
          {
            category_id: 88,
            pid: 87,
            level: 3,
            category_name: "Various Gemstones",
            seo_category_name: "various-gemstones",
            children: [],
          },
          {
            category_id: 89,
            pid: 87,
            level: 3,
            category_name: "925 Silver Jewelry",
            seo_category_name: "925-silver-jewelry",
            children: [],
          },
          {
            category_id: 90,
            pid: 87,
            level: 3,
            category_name: "K-Gold",
            seo_category_name: "kgold",
            children: [],
          },
          {
            category_id: 91,
            pid: 87,
            level: 3,
            category_name: "Pearls Jewelry",
            seo_category_name: "pearls-jewelry",
            children: [],
          },
          {
            category_id: 92,
            pid: 87,
            level: 3,
            category_name: "Fine Earrings",
            seo_category_name: "fine-earrings",
            children: [],
          },
          {
            category_id: 93,
            pid: 87,
            level: 3,
            category_name: "Men's Fine Jewelry",
            seo_category_name: "mens-fine-jewelry",
            children: [],
          },
          {
            category_id: 94,
            pid: 87,
            level: 3,
            category_name: "Fine Jewelry Sets",
            seo_category_name: "fine-jewelry-sets",
            children: [],
          },
        ],
      },
      {
        category_id: 95,
        pid: 70,
        level: 2,
        category_name: "Men's Watches",
        seo_category_name: "mens-watches",
        children: [
          {
            category_id: 96,
            pid: 95,
            level: 3,
            category_name: "Quartz Watches",
            seo_category_name: "quartz-watches",
            children: [],
          },
          {
            category_id: 97,
            pid: 95,
            level: 3,
            category_name: "Mechanical Watches",
            seo_category_name: "mechanical-watches",
            children: [],
          },
          {
            category_id: 98,
            pid: 95,
            level: 3,
            category_name: "Digital Watches",
            seo_category_name: "digital-watches",
            children: [],
          },
          {
            category_id: 99,
            pid: 95,
            level: 3,
            category_name: "Dual Display Watches",
            seo_category_name: "dual-display-watches",
            children: [],
          },
          {
            category_id: 100,
            pid: 95,
            level: 3,
            category_name: "Men Sports Watches",
            seo_category_name: "men-sports-watches",
            children: [],
          },
        ],
      },
      {
        category_id: 101,
        pid: 70,
        level: 2,
        category_name: "Women's Watches",
        seo_category_name: "womens-watches",
        children: [
          {
            category_id: 102,
            pid: 101,
            level: 3,
            category_name: "Women Sports Watches",
            seo_category_name: "women-sports-watches",
            children: [],
          },
          {
            category_id: 103,
            pid: 101,
            level: 3,
            category_name: "Dress Watches",
            seo_category_name: "dress-watches",
            children: [],
          },
          {
            category_id: 104,
            pid: 101,
            level: 3,
            category_name: "Creative Watches",
            seo_category_name: "creative-watches",
            children: [],
          },
          {
            category_id: 105,
            pid: 101,
            level: 3,
            category_name: "Lovers' Watches",
            seo_category_name: "lovers-watches",
            children: [],
          },
          {
            category_id: 106,
            pid: 101,
            level: 3,
            category_name: "Women's Bracelet Watches",
            seo_category_name: "womens-bracelet-watches",
            children: [],
          },
          {
            category_id: 107,
            pid: 101,
            level: 3,
            category_name: "Children's Watches",
            seo_category_name: "childrens-watches",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 108,
    pid: 0,
    level: 1,
    category_name: "Health, Beauty & Hair",
    seo_category_name: "health-beauty-hair",
    children: [
      {
        category_id: 109,
        pid: 108,
        level: 2,
        category_name: "Nail Art & Tools",
        seo_category_name: "nail-art-tools",
        children: [
          {
            category_id: 110,
            pid: 109,
            level: 3,
            category_name: "Nail Glitters",
            seo_category_name: "nail-glitters",
            children: [],
          },
          {
            category_id: 111,
            pid: 109,
            level: 3,
            category_name: "Stickers & Decals",
            seo_category_name: "stickers-decals",
            children: [],
          },
          {
            category_id: 112,
            pid: 109,
            level: 3,
            category_name: "Nail Decorations",
            seo_category_name: "nail-decorations",
            children: [],
          },
          {
            category_id: 113,
            pid: 109,
            level: 3,
            category_name: "Nail Gel",
            seo_category_name: "nail-gel",
            children: [],
          },
          {
            category_id: 114,
            pid: 109,
            level: 3,
            category_name: "Nail Dryers",
            seo_category_name: "nail-dryers",
            children: [],
          },
          {
            category_id: 115,
            pid: 109,
            level: 3,
            category_name: "Nail Art Kits",
            seo_category_name: "nail-art-kits",
            children: [],
          },
        ],
      },
      {
        category_id: 116,
        pid: 108,
        level: 2,
        category_name: "Hair & Accessories",
        seo_category_name: "hair-accessories",
        children: [
          {
            category_id: 117,
            pid: 116,
            level: 3,
            category_name: "Human Hair",
            seo_category_name: "human-hair",
            children: [],
          },
        ],
      },
      {
        category_id: 118,
        pid: 108,
        level: 2,
        category_name: "Synthetic Hair",
        seo_category_name: "synthetic-hair",
        children: [
          {
            category_id: 119,
            pid: 118,
            level: 3,
            category_name: "Cosplay Wigs",
            seo_category_name: "cosplay-wigs",
            children: [],
          },
        ],
      },
      {
        category_id: 120,
        pid: 108,
        level: 2,
        category_name: "Skin Care",
        seo_category_name: "skin-care",
        children: [
          {
            category_id: 121,
            pid: 120,
            level: 3,
            category_name: "Razor",
            seo_category_name: "razor",
            children: [],
          },
          {
            category_id: 122,
            pid: 120,
            level: 3,
            category_name: "Face Masks",
            seo_category_name: "face-masks",
            children: [],
          },
          {
            category_id: 123,
            pid: 120,
            level: 3,
            category_name: "Sun Care",
            seo_category_name: "sun-care",
            children: [],
          },
          {
            category_id: 124,
            pid: 120,
            level: 3,
            category_name: "Essential Oil",
            seo_category_name: "essential-oil",
            children: [],
          },
          {
            category_id: 125,
            pid: 120,
            level: 3,
            category_name: "Body Care",
            seo_category_name: "body-care",
            children: [],
          },
          {
            category_id: 126,
            pid: 120,
            level: 3,
            category_name: "Facial Care",
            seo_category_name: "facial-care",
            children: [],
          },
        ],
      },
      {
        category_id: 127,
        pid: 108,
        level: 2,
        category_name: "Hair Weaves",
        seo_category_name: "hair-weaves",
        children: [
          {
            category_id: 128,
            pid: 127,
            level: 3,
            category_name: "Pre-Colored One Pack",
            seo_category_name: "precolored-one-pack",
            children: [],
          },
          {
            category_id: 129,
            pid: 127,
            level: 3,
            category_name: "Hair Weaving",
            seo_category_name: "hair-weaving",
            children: [],
          },
          {
            category_id: 130,
            pid: 127,
            level: 3,
            category_name: "Hair Styling",
            seo_category_name: "hair-styling",
            children: [],
          },
          {
            category_id: 131,
            pid: 127,
            level: 3,
            category_name: "Salon Bundle Hair",
            seo_category_name: "salon-bundle-hair",
            children: [],
          },
          {
            category_id: 132,
            pid: 127,
            level: 3,
            category_name: "Pre-Colored Hair Weave",
            seo_category_name: "precolored-hair-weave",
            children: [],
          },
        ],
      },
      {
        category_id: 133,
        pid: 108,
        level: 2,
        category_name: "Makeup",
        seo_category_name: "makeup",
        children: [
          {
            category_id: 134,
            pid: 133,
            level: 3,
            category_name: "Makeup Set",
            seo_category_name: "makeup-set",
            children: [],
          },
          {
            category_id: 135,
            pid: 133,
            level: 3,
            category_name: "Eyeshadow",
            seo_category_name: "eyeshadow",
            children: [],
          },
          {
            category_id: 136,
            pid: 133,
            level: 3,
            category_name: "Makeup Brushes",
            seo_category_name: "makeup-brushes",
            children: [],
          },
          {
            category_id: 137,
            pid: 133,
            level: 3,
            category_name: "Lipstick",
            seo_category_name: "lipstick",
            children: [],
          },
          {
            category_id: 138,
            pid: 133,
            level: 3,
            category_name: "False Eyelashes",
            seo_category_name: "false-eyelashes",
            children: [],
          },
        ],
      },
      {
        category_id: 139,
        pid: 108,
        level: 2,
        category_name: "Wigs & Extensions",
        seo_category_name: "wigs-extensions",
        children: [
          {
            category_id: 140,
            pid: 139,
            level: 3,
            category_name: "Human Hair Wigs",
            seo_category_name: "human-hair-wigs",
            children: [],
          },
          {
            category_id: 141,
            pid: 139,
            level: 3,
            category_name: "Synthetic Hair Pieces",
            seo_category_name: "synthetic-hair-pieces",
            children: [],
          },
          {
            category_id: 142,
            pid: 139,
            level: 3,
            category_name: "Synthetic Lace Wigs",
            seo_category_name: "synthetic-lace-wigs",
            children: [],
          },
          {
            category_id: 143,
            pid: 139,
            level: 3,
            category_name: "Human Hair Lace Wigs",
            seo_category_name: "human-hair-lace-wigs",
            children: [],
          },
          {
            category_id: 144,
            pid: 139,
            level: 3,
            category_name: "Hair Braids",
            seo_category_name: "hair-braids",
            children: [],
          },
          {
            category_id: 145,
            pid: 139,
            level: 3,
            category_name: "Synthetic Wigs",
            seo_category_name: "synthetic-wigs",
            children: [],
          },
        ],
      },
      {
        category_id: 146,
        pid: 108,
        level: 2,
        category_name: "Beauty Tools",
        seo_category_name: "beauty-tools",
        children: [
          {
            category_id: 147,
            pid: 146,
            level: 3,
            category_name: "Straightening Irons",
            seo_category_name: "straightening-irons",
            children: [],
          },
          {
            category_id: 148,
            pid: 146,
            level: 3,
            category_name: "Electric Face Cleanser",
            seo_category_name: "electric-face-cleanser",
            children: [],
          },
          {
            category_id: 149,
            pid: 146,
            level: 3,
            category_name: "Face Skin Care Tools",
            seo_category_name: "face-skin-care-tools",
            children: [],
          },
          {
            category_id: 150,
            pid: 146,
            level: 3,
            category_name: "Curling Iron",
            seo_category_name: "curling-iron",
            children: [],
          },
          {
            category_id: 151,
            pid: 146,
            level: 3,
            category_name: "Facial Steamer",
            seo_category_name: "facial-steamer",
            children: [],
          },
        ],
      },
      {
        category_id: 605,
        pid: 108,
        level: 2,
        category_name: "Food & Health",
        seo_category_name: "",
        children: [
          {
            category_id: 606,
            pid: 605,
            level: 3,
            category_name: "Health Care Products",
            seo_category_name: "",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 152,
    pid: 0,
    level: 1,
    category_name: "Women's Clothing",
    seo_category_name: "womens-clothing",
    children: [
      {
        category_id: 153,
        pid: 152,
        level: 2,
        category_name: "Accessories",
        seo_category_name: "accessories",
        children: [
          {
            category_id: 154,
            pid: 153,
            level: 3,
            category_name: "Scarves & Wraps",
            seo_category_name: "scarves-wraps",
            children: [],
          },
          {
            category_id: 155,
            pid: 153,
            level: 3,
            category_name: "Face Masks",
            seo_category_name: "face-masks",
            children: [],
          },
          {
            category_id: 156,
            pid: 153,
            level: 3,
            category_name: "Belts & Cummerbunds",
            seo_category_name: "belts-cummerbunds",
            children: [],
          },
          {
            category_id: 157,
            pid: 153,
            level: 3,
            category_name: "Woman Prescription Glasses",
            seo_category_name: "woman-prescription-glasses",
            children: [],
          },
          {
            category_id: 158,
            pid: 153,
            level: 3,
            category_name: "Eyewear & Accessories",
            seo_category_name: "eyewear-accessories",
            children: [],
          },
          {
            category_id: 159,
            pid: 153,
            level: 3,
            category_name: "Woman Gloves & Mittens",
            seo_category_name: "woman-gloves-mittens",
            children: [],
          },
          {
            category_id: 160,
            pid: 153,
            level: 3,
            category_name: "Woman Socks",
            seo_category_name: "woman-socks",
            children: [],
          },
          {
            category_id: 161,
            pid: 153,
            level: 3,
            category_name: "Woman Hats & Caps",
            seo_category_name: "woman-hats-caps",
            children: [],
          },
        ],
      },
      {
        category_id: 162,
        pid: 152,
        level: 2,
        category_name: "Tops & Sets",
        seo_category_name: "tops-sets",
        children: [
          {
            category_id: 163,
            pid: 162,
            level: 3,
            category_name: "Ladies Short Sleeve",
            seo_category_name: "ladies-short-sleeve",
            children: [],
          },
          {
            category_id: 164,
            pid: 162,
            level: 3,
            category_name: "Blouses & Shirts",
            seo_category_name: "blouses-shirts",
            children: [],
          },
          {
            category_id: 165,
            pid: 162,
            level: 3,
            category_name: "Woman Hoodies & Sweatshirts",
            seo_category_name: "woman-hoodies-sweatshirts",
            children: [],
          },
          {
            category_id: 166,
            pid: 162,
            level: 3,
            category_name: "Jumpsuits",
            seo_category_name: "jumpsuits",
            children: [],
          },
          {
            category_id: 167,
            pid: 162,
            level: 3,
            category_name: "Rompers",
            seo_category_name: "rompers",
            children: [],
          },
          {
            category_id: 168,
            pid: 162,
            level: 3,
            category_name: "Lady Dresses",
            seo_category_name: "lady-dresses",
            children: [],
          },
          {
            category_id: 169,
            pid: 162,
            level: 3,
            category_name: "Sweaters",
            seo_category_name: "sweaters",
            children: [],
          },
          {
            category_id: 170,
            pid: 162,
            level: 3,
            category_name: "Suits & Sets",
            seo_category_name: "suits-sets",
            children: [],
          },
          {
            category_id: 521,
            pid: 162,
            level: 3,
            category_name: "Women's Camis",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 171,
        pid: 152,
        level: 2,
        category_name: "Bottoms",
        seo_category_name: "bottoms",
        children: [
          {
            category_id: 172,
            pid: 171,
            level: 3,
            category_name: "Leggings",
            seo_category_name: "leggings",
            children: [],
          },
          {
            category_id: 173,
            pid: 171,
            level: 3,
            category_name: "Skirts",
            seo_category_name: "skirts",
            children: [],
          },
          {
            category_id: 174,
            pid: 171,
            level: 3,
            category_name: "Woman Jeans",
            seo_category_name: "woman-jeans",
            children: [],
          },
          {
            category_id: 175,
            pid: 171,
            level: 3,
            category_name: "Woman Shorts",
            seo_category_name: "woman-shorts",
            children: [],
          },
          {
            category_id: 176,
            pid: 171,
            level: 3,
            category_name: "Pants & Capris",
            seo_category_name: "pants-capris",
            children: [],
          },
          {
            category_id: 177,
            pid: 171,
            level: 3,
            category_name: "Wide Leg Pants",
            seo_category_name: "wide-leg-pants",
            children: [],
          },
        ],
      },
      {
        category_id: 178,
        pid: 152,
        level: 2,
        category_name: "Outerwear & Jackets",
        seo_category_name: "outerwear-jackets",
        children: [
          {
            category_id: 179,
            pid: 178,
            level: 3,
            category_name: "Blazers",
            seo_category_name: "blazers",
            children: [],
          },
          {
            category_id: 180,
            pid: 178,
            level: 3,
            category_name: "Wool & Blend",
            seo_category_name: "wool-blend",
            children: [],
          },
          {
            category_id: 181,
            pid: 178,
            level: 3,
            category_name: "Woman Trench",
            seo_category_name: "woman-trench",
            children: [],
          },
          {
            category_id: 182,
            pid: 178,
            level: 3,
            category_name: "Basic Jacket",
            seo_category_name: "basic-jacket",
            children: [],
          },
          {
            category_id: 183,
            pid: 178,
            level: 3,
            category_name: "Leather & Suede",
            seo_category_name: "leather-suede",
            children: [],
          },
          {
            category_id: 184,
            pid: 178,
            level: 3,
            category_name: "Real Fur",
            seo_category_name: "real-fur",
            children: [],
          },
          {
            category_id: 522,
            pid: 178,
            level: 3,
            category_name: "Women's Padded Jackets",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 185,
        pid: 152,
        level: 2,
        category_name: "Weddings & Events",
        seo_category_name: "weddings-events",
        children: [
          {
            category_id: 186,
            pid: 185,
            level: 3,
            category_name: "Cocktail Dresses",
            seo_category_name: "cocktail-dresses",
            children: [],
          },
          {
            category_id: 187,
            pid: 185,
            level: 3,
            category_name: "Evening Dresses",
            seo_category_name: "evening-dresses",
            children: [],
          },
          {
            category_id: 188,
            pid: 185,
            level: 3,
            category_name: "Bridesmaid Dresses",
            seo_category_name: "bridesmaid-dresses",
            children: [],
          },
          {
            category_id: 189,
            pid: 185,
            level: 3,
            category_name: "Prom Dresses",
            seo_category_name: "prom-dresses",
            children: [],
          },
          {
            category_id: 190,
            pid: 185,
            level: 3,
            category_name: "Wedding Dresses",
            seo_category_name: "wedding-dresses",
            children: [],
          },
          {
            category_id: 191,
            pid: 185,
            level: 3,
            category_name: "Flower Girl Dresses",
            seo_category_name: "flower-girl-dresses",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 192,
    pid: 0,
    level: 1,
    category_name: "Sports & Outdoors",
    seo_category_name: "sports-outdoors",
    children: [
      {
        category_id: 193,
        pid: 192,
        level: 2,
        category_name: "Sneakers",
        seo_category_name: "sneakers",
        children: [
          {
            category_id: 194,
            pid: 193,
            level: 3,
            category_name: "Running Shoes",
            seo_category_name: "running-shoes",
            children: [],
          },
          {
            category_id: 195,
            pid: 193,
            level: 3,
            category_name: "Dance Shoes",
            seo_category_name: "dance-shoes",
            children: [],
          },
          {
            category_id: 196,
            pid: 193,
            level: 3,
            category_name: "Skateboarding Shoes",
            seo_category_name: "skateboarding-shoes",
            children: [],
          },
          {
            category_id: 197,
            pid: 193,
            level: 3,
            category_name: "Hiking Shoes",
            seo_category_name: "hiking-shoes",
            children: [],
          },
          {
            category_id: 198,
            pid: 193,
            level: 3,
            category_name: "Soccer Shoes",
            seo_category_name: "soccer-shoes",
            children: [],
          },
          {
            category_id: 199,
            pid: 193,
            level: 3,
            category_name: "Basketball Shoes",
            seo_category_name: "basketball-shoes",
            children: [],
          },
        ],
      },
      {
        category_id: 200,
        pid: 192,
        level: 2,
        category_name: "Other Sports Equipment",
        seo_category_name: "other-sports-equipment",
        children: [
          {
            category_id: 201,
            pid: 200,
            level: 3,
            category_name: "Musical Instruments",
            seo_category_name: "musical-instruments",
            children: [],
          },
          {
            category_id: 202,
            pid: 200,
            level: 3,
            category_name: "Hunting",
            seo_category_name: "hunting",
            children: [],
          },
          {
            category_id: 203,
            pid: 200,
            level: 3,
            category_name: "Skiing & Snowboarding",
            seo_category_name: "skiing-snowboarding",
            children: [],
          },
          {
            category_id: 204,
            pid: 200,
            level: 3,
            category_name: "Fitness & Bodybuilding",
            seo_category_name: "fitness-bodybuilding",
            children: [],
          },
          {
            category_id: 205,
            pid: 200,
            level: 3,
            category_name: "Camping & Hiking",
            seo_category_name: "camping-hiking",
            children: [],
          },
          {
            category_id: 206,
            pid: 200,
            level: 3,
            category_name: "Golf",
            seo_category_name: "golf",
            children: [],
          },
        ],
      },
      {
        category_id: 207,
        pid: 192,
        level: 2,
        category_name: "Swimming",
        seo_category_name: "swimming",
        children: [
          {
            category_id: 208,
            pid: 207,
            level: 3,
            category_name: "One-Piece Suits",
            seo_category_name: "onepiece-suits",
            children: [],
          },
          {
            category_id: 209,
            pid: 207,
            level: 3,
            category_name: "Bikini Sets",
            seo_category_name: "bikini-sets",
            children: [],
          },
          {
            category_id: 210,
            pid: 207,
            level: 3,
            category_name: "Two-Piece Suits",
            seo_category_name: "twopiece-suits",
            children: [],
          },
          {
            category_id: 211,
            pid: 207,
            level: 3,
            category_name: "Men's Swimwear",
            seo_category_name: "mens-swimwear",
            children: [],
          },
          {
            category_id: 212,
            pid: 207,
            level: 3,
            category_name: "Cover-Ups",
            seo_category_name: "coverups",
            children: [],
          },
          {
            category_id: 213,
            pid: 207,
            level: 3,
            category_name: "Children's Swimwear",
            seo_category_name: "childrens-swimwear",
            children: [],
          },
        ],
      },
      {
        category_id: 214,
        pid: 192,
        level: 2,
        category_name: "Cycling",
        seo_category_name: "cycling",
        children: [
          {
            category_id: 215,
            pid: 214,
            level: 3,
            category_name: "Bicycle Lights",
            seo_category_name: "bicycle-lights",
            children: [],
          },
          {
            category_id: 216,
            pid: 214,
            level: 3,
            category_name: "Bicycle Helmets",
            seo_category_name: "bicycle-helmets",
            children: [],
          },
          {
            category_id: 217,
            pid: 214,
            level: 3,
            category_name: "Cycling Eyewear",
            seo_category_name: "cycling-eyewear",
            children: [],
          },
          {
            category_id: 218,
            pid: 214,
            level: 3,
            category_name: "Bicycle Frames",
            seo_category_name: "bicycle-frames",
            children: [],
          },
          {
            category_id: 219,
            pid: 214,
            level: 3,
            category_name: "Cycling Jerseys",
            seo_category_name: "cycling-jerseys",
            children: [],
          },
          {
            category_id: 220,
            pid: 214,
            level: 3,
            category_name: "Bicycles",
            seo_category_name: "bicycles",
            children: [],
          },
          {
            category_id: 611,
            pid: 214,
            level: 3,
            category_name: "Scooters",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 221,
        pid: 192,
        level: 2,
        category_name: "Sportswear",
        seo_category_name: "sportswear",
        children: [
          {
            category_id: 222,
            pid: 221,
            level: 3,
            category_name: "Jerseys",
            seo_category_name: "jerseys",
            children: [],
          },
          {
            category_id: 223,
            pid: 221,
            level: 3,
            category_name: "Sports Accessories",
            seo_category_name: "sports-accessories",
            children: [],
          },
          {
            category_id: 224,
            pid: 221,
            level: 3,
            category_name: "Outdoor Shorts",
            seo_category_name: "outdoor-shorts",
            children: [],
          },
          {
            category_id: 225,
            pid: 221,
            level: 3,
            category_name: "Sports Bags",
            seo_category_name: "sports-bags",
            children: [],
          },
          {
            category_id: 226,
            pid: 221,
            level: 3,
            category_name: "Hiking Jackets",
            seo_category_name: "hiking-jackets",
            children: [],
          },
          {
            category_id: 227,
            pid: 221,
            level: 3,
            category_name: "Pants",
            seo_category_name: "pants",
            children: [],
          },
        ],
      },
      {
        category_id: 228,
        pid: 192,
        level: 2,
        category_name: "Fishing",
        seo_category_name: "fishing",
        children: [
          {
            category_id: 229,
            pid: 228,
            level: 3,
            category_name: "Fishing Reels",
            seo_category_name: "fishing-reels",
            children: [],
          },
          {
            category_id: 230,
            pid: 228,
            level: 3,
            category_name: "Rod Combos",
            seo_category_name: "rod-combos",
            children: [],
          },
          {
            category_id: 231,
            pid: 228,
            level: 3,
            category_name: "Fishing Tackle Boxes",
            seo_category_name: "fishing-tackle-boxes",
            children: [],
          },
          {
            category_id: 232,
            pid: 228,
            level: 3,
            category_name: "Fishing Lures",
            seo_category_name: "fishing-lures",
            children: [],
          },
          {
            category_id: 233,
            pid: 228,
            level: 3,
            category_name: "Fishing Rods",
            seo_category_name: "fishing-rods",
            children: [],
          },
          {
            category_id: 234,
            pid: 228,
            level: 3,
            category_name: "Fishing Lines",
            seo_category_name: "fishing-lines",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 235,
    pid: 0,
    level: 1,
    category_name: "Home, Garden & Furniture",
    seo_category_name: "home-garden-furniture",
    children: [
      {
        category_id: 236,
        pid: 235,
        level: 2,
        category_name: "Home Textiles",
        seo_category_name: "home-textiles",
        children: [
          {
            category_id: 237,
            pid: 236,
            level: 3,
            category_name: "Curtains",
            seo_category_name: "curtains",
            children: [],
          },
          {
            category_id: 238,
            pid: 236,
            level: 3,
            category_name: "Cushion Covers",
            seo_category_name: "cushion-covers",
            children: [],
          },
          {
            category_id: 239,
            pid: 236,
            level: 3,
            category_name: "Towels",
            seo_category_name: "towels",
            children: [],
          },
          {
            category_id: 240,
            pid: 236,
            level: 3,
            category_name: "Comforters",
            seo_category_name: "comforters",
            children: [],
          },
          {
            category_id: 241,
            pid: 236,
            level: 3,
            category_name: "Bedding Sets",
            seo_category_name: "bedding-sets",
            children: [],
          },
          {
            category_id: 242,
            pid: 236,
            level: 3,
            category_name: "Pillows",
            seo_category_name: "pillows",
            children: [],
          },
        ],
      },
      {
        category_id: 243,
        pid: 235,
        level: 2,
        category_name: "Arts, Crafts & Sewing",
        seo_category_name: "arts-crafts-sewing",
        children: [
          {
            category_id: 244,
            pid: 243,
            level: 3,
            category_name: "Lace",
            seo_category_name: "lace",
            children: [],
          },
          {
            category_id: 245,
            pid: 243,
            level: 3,
            category_name: "Apparel Sewing & Fabric",
            seo_category_name: "apparel-sewing-fabric",
            children: [],
          },
          {
            category_id: 246,
            pid: 243,
            level: 3,
            category_name: "Cross-Stitch",
            seo_category_name: "crossstitch",
            children: [],
          },
          {
            category_id: 247,
            pid: 243,
            level: 3,
            category_name: "Ribbons",
            seo_category_name: "ribbons",
            children: [],
          },
          {
            category_id: 248,
            pid: 243,
            level: 3,
            category_name: "Diamond Painting Cross Stitch",
            seo_category_name: "diamond-painting-cross-stitch",
            children: [],
          },
          {
            category_id: 249,
            pid: 243,
            level: 3,
            category_name: "Fabric",
            seo_category_name: "fabric",
            children: [],
          },
          {
            category_id: 602,
            pid: 243,
            level: 3,
            category_name: "Decor Paintings",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 250,
        pid: 235,
        level: 2,
        category_name: "Festive & Party Supplies",
        seo_category_name: "festive-party-supplies",
        children: [
          {
            category_id: 251,
            pid: 250,
            level: 3,
            category_name: "Christmas Decoration Supplies",
            seo_category_name: "christmas-decoration-supplies",
            children: [],
          },
          {
            category_id: 252,
            pid: 250,
            level: 3,
            category_name: "Invitation Cards",
            seo_category_name: "invitation-cards",
            children: [],
          },
          {
            category_id: 253,
            pid: 250,
            level: 3,
            category_name: "Cake Decorating Supplies",
            seo_category_name: "cake-decorating-supplies",
            children: [],
          },
          {
            category_id: 254,
            pid: 250,
            level: 3,
            category_name: "Decorative Flowers & Wreaths",
            seo_category_name: "decorative-flowers-wreaths",
            children: [],
          },
          {
            category_id: 255,
            pid: 250,
            level: 3,
            category_name: "Party Masks",
            seo_category_name: "party-masks",
            children: [],
          },
          {
            category_id: 256,
            pid: 250,
            level: 3,
            category_name: "Event & Party Supplies",
            seo_category_name: "event-party-supplies",
            children: [],
          },
        ],
      },
      {
        category_id: 257,
        pid: 235,
        level: 2,
        category_name: "Pet Products",
        seo_category_name: "pet-products",
        children: [
          {
            category_id: 258,
            pid: 257,
            level: 3,
            category_name: "Dog Supplies",
            seo_category_name: "dog-supplies",
            children: [],
          },
          {
            category_id: 259,
            pid: 257,
            level: 3,
            category_name: "Bird Supplies",
            seo_category_name: "bird-supplies",
            children: [],
          },
          {
            category_id: 260,
            pid: 257,
            level: 3,
            category_name: "Cat Supplies",
            seo_category_name: "cat-supplies",
            children: [],
          },
          {
            category_id: 261,
            pid: 257,
            level: 3,
            category_name: "Fish & Aquatic Pets",
            seo_category_name: "fish-aquatic-pets",
            children: [],
          },
          {
            category_id: 262,
            pid: 257,
            level: 3,
            category_name: "Dog Clothing & Shoes",
            seo_category_name: "dog-clothing-shoes",
            children: [],
          },
          {
            category_id: 263,
            pid: 257,
            level: 3,
            category_name: "Dog Collars, Harnesses & Leads",
            seo_category_name: "dog-collars-harnesses-leads",
            children: [],
          },
        ],
      },
      {
        category_id: 264,
        pid: 235,
        level: 2,
        category_name: "Kitchen, Dining & Bar",
        seo_category_name: "kitchen-dining-bar",
        children: [
          {
            category_id: 265,
            pid: 264,
            level: 3,
            category_name: "Dinnerware",
            seo_category_name: "dinnerware",
            children: [],
          },
          {
            category_id: 266,
            pid: 264,
            level: 3,
            category_name: "Kitchen Knives & Accessories",
            seo_category_name: "kitchen-knives-accessories",
            children: [],
          },
          {
            category_id: 267,
            pid: 264,
            level: 3,
            category_name: "Bakeware",
            seo_category_name: "bakeware",
            children: [],
          },
          {
            category_id: 268,
            pid: 264,
            level: 3,
            category_name: "Barware",
            seo_category_name: "barware",
            children: [],
          },
          {
            category_id: 269,
            pid: 264,
            level: 3,
            category_name: "Drinkware",
            seo_category_name: "drinkware",
            children: [],
          },
          {
            category_id: 270,
            pid: 264,
            level: 3,
            category_name: "Cooking Tools",
            seo_category_name: "cooking-tools",
            children: [],
          },
        ],
      },
      {
        category_id: 271,
        pid: 235,
        level: 2,
        category_name: "Home Storage",
        seo_category_name: "home-storage",
        children: [
          {
            category_id: 272,
            pid: 271,
            level: 3,
            category_name: "Adult Wellness",
            seo_category_name: "adult-wellness",
            children: [],
          },
          {
            category_id: 273,
            pid: 271,
            level: 3,
            category_name: "Seasonal products",
            seo_category_name: "seasonal-products",
            children: [],
          },
          {
            category_id: 274,
            pid: 271,
            level: 3,
            category_name: "Kitchen Storage",
            seo_category_name: "kitchen-storage",
            children: [],
          },
          {
            category_id: 275,
            pid: 271,
            level: 3,
            category_name: "Home Office Storage",
            seo_category_name: "home-office-storage",
            children: [],
          },
          {
            category_id: 276,
            pid: 271,
            level: 3,
            category_name: "Clothing & Wardrobe Storage",
            seo_category_name: "clothing-wardrobe-storage",
            children: [],
          },
          {
            category_id: 277,
            pid: 271,
            level: 3,
            category_name: "Bathroom Storage",
            seo_category_name: "bathroom-storage",
            children: [],
          },
          {
            category_id: 278,
            pid: 271,
            level: 3,
            category_name: "Storage Bottles & Jars",
            seo_category_name: "storage-bottles-jars",
            children: [],
          },
          {
            category_id: 603,
            pid: 271,
            level: 3,
            category_name: "Stationeries",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 604,
            pid: 271,
            level: 3,
            category_name: "Furniture",
            seo_category_name: "",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 279,
    pid: 0,
    level: 1,
    category_name: "Home Improvement",
    seo_category_name: "home-improvement",
    children: [
      {
        category_id: 280,
        pid: 279,
        level: 2,
        category_name: "LED Lighting",
        seo_category_name: "led-lighting",
        children: [
          {
            category_id: 281,
            pid: 280,
            level: 3,
            category_name: "LED Spotlights",
            seo_category_name: "led-spotlights",
            children: [],
          },
        ],
      },
      {
        category_id: 282,
        pid: 279,
        level: 2,
        category_name: "Outdoor Lighting",
        seo_category_name: "outdoor-lighting",
        children: [
          {
            category_id: 283,
            pid: 282,
            level: 3,
            category_name: "Flashlights & Torches",
            seo_category_name: "flashlights-torches",
            children: [],
          },
          {
            category_id: 284,
            pid: 282,
            level: 3,
            category_name: "Floodlights",
            seo_category_name: "floodlights",
            children: [],
          },
          {
            category_id: 285,
            pid: 282,
            level: 3,
            category_name: "Underwater Lights",
            seo_category_name: "underwater-lights",
            children: [],
          },
          {
            category_id: 286,
            pid: 282,
            level: 3,
            category_name: "String Lights",
            seo_category_name: "string-lights",
            children: [],
          },
          {
            category_id: 287,
            pid: 282,
            level: 3,
            category_name: "Solar Lamps",
            seo_category_name: "solar-lamps",
            children: [],
          },
        ],
      },
      {
        category_id: 288,
        pid: 279,
        level: 2,
        category_name: "Tools",
        seo_category_name: "tools",
        children: [
          {
            category_id: 289,
            pid: 288,
            level: 3,
            category_name: "Measurement & Analysis",
            seo_category_name: "measurement-analysis",
            children: [],
          },
          {
            category_id: 290,
            pid: 288,
            level: 3,
            category_name: "Welding & Soldering Supplies",
            seo_category_name: "welding-soldering-supplies",
            children: [],
          },
          {
            category_id: 291,
            pid: 288,
            level: 3,
            category_name: "Welding Equipment",
            seo_category_name: "welding-equipment",
            children: [],
          },
          {
            category_id: 292,
            pid: 288,
            level: 3,
            category_name: "Hand Tools",
            seo_category_name: "hand-tools",
            children: [],
          },
          {
            category_id: 293,
            pid: 288,
            level: 3,
            category_name: "Tool Sets",
            seo_category_name: "tool-sets",
            children: [],
          },
          {
            category_id: 294,
            pid: 288,
            level: 3,
            category_name: "Tools Storage",
            seo_category_name: "tools-storage",
            children: [],
          },
          {
            category_id: 295,
            pid: 288,
            level: 3,
            category_name: "Machine Tools & Accessories",
            seo_category_name: "machine-tools-accessories",
            children: [],
          },
          {
            category_id: 296,
            pid: 288,
            level: 3,
            category_name: "Power Tools",
            seo_category_name: "power-tools",
            children: [],
          },
          {
            category_id: 297,
            pid: 288,
            level: 3,
            category_name: "Woodworking Machinery",
            seo_category_name: "woodworking-machinery",
            children: [],
          },
          {
            category_id: 298,
            pid: 288,
            level: 3,
            category_name: "Garden Tools",
            seo_category_name: "garden-tools",
            children: [],
          },
        ],
      },
      {
        category_id: 299,
        pid: 279,
        level: 2,
        category_name: "Home Appliances",
        seo_category_name: "home-appliances",
        children: [
          {
            category_id: 300,
            pid: 299,
            level: 3,
            category_name: "Personal Care Appliances",
            seo_category_name: "personal-care-appliances",
            children: [],
          },
          {
            category_id: 301,
            pid: 299,
            level: 3,
            category_name: "Cleaning Appliances",
            seo_category_name: "cleaning-appliances",
            children: [],
          },
          {
            category_id: 302,
            pid: 299,
            level: 3,
            category_name: "Air Conditioning Appliances",
            seo_category_name: "air-conditioning-appliances",
            children: [],
          },
          {
            category_id: 303,
            pid: 299,
            level: 3,
            category_name: "Home Appliance Parts",
            seo_category_name: "home-appliance-parts",
            children: [],
          },
          {
            category_id: 304,
            pid: 299,
            level: 3,
            category_name: "Kitchen Appliances",
            seo_category_name: "kitchen-appliances",
            children: [],
          },
        ],
      },
      {
        category_id: 305,
        pid: 279,
        level: 2,
        category_name: "Indoor Lighting",
        seo_category_name: "indoor-lighting",
        children: [
          {
            category_id: 306,
            pid: 305,
            level: 3,
            category_name: "Chandeliers",
            seo_category_name: "chandeliers",
            children: [],
          },
          {
            category_id: 307,
            pid: 305,
            level: 3,
            category_name: "Pendant Lights",
            seo_category_name: "pendant-lights",
            children: [],
          },
          {
            category_id: 308,
            pid: 305,
            level: 3,
            category_name: "Downlights",
            seo_category_name: "downlights",
            children: [],
          },
          {
            category_id: 309,
            pid: 305,
            level: 3,
            category_name: "Night Lights",
            seo_category_name: "night-lights",
            children: [],
          },
          {
            category_id: 310,
            pid: 305,
            level: 3,
            category_name: "Wall Lamps",
            seo_category_name: "wall-lamps",
            children: [],
          },
          {
            category_id: 311,
            pid: 305,
            level: 3,
            category_name: "Ceiling Lights",
            seo_category_name: "ceiling-lights",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 312,
    pid: 0,
    level: 1,
    category_name: "Automobiles & Motorcycles",
    seo_category_name: "automobiles-motorcycles",
    children: [
      {
        category_id: 313,
        pid: 312,
        level: 2,
        category_name: "Exterior Accessories",
        seo_category_name: "exterior-accessories",
        children: [
          {
            category_id: 314,
            pid: 313,
            level: 3,
            category_name: "Car Stickers",
            seo_category_name: "car-stickers",
            children: [],
          },
          {
            category_id: 315,
            pid: 313,
            level: 3,
            category_name: "Other Exterior Accessories",
            seo_category_name: "other-exterior-accessories",
            children: [],
          },
          {
            category_id: 316,
            pid: 313,
            level: 3,
            category_name: "Car Covers",
            seo_category_name: "car-covers",
            children: [],
          },
        ],
      },
      {
        category_id: 317,
        pid: 312,
        level: 2,
        category_name: "Interior Accessories",
        seo_category_name: "interior-accessories",
        children: [
          {
            category_id: 318,
            pid: 317,
            level: 3,
            category_name: "Floor Mats",
            seo_category_name: "floor-mats",
            children: [],
          },
          {
            category_id: 319,
            pid: 317,
            level: 3,
            category_name: "Key Case for Car",
            seo_category_name: "key-case-for-car",
            children: [],
          },
          {
            category_id: 320,
            pid: 317,
            level: 3,
            category_name: "Steering Covers",
            seo_category_name: "steering-covers",
            children: [],
          },
          {
            category_id: 321,
            pid: 317,
            level: 3,
            category_name: "Automobiles Seat Covers",
            seo_category_name: "automobiles-seat-covers",
            children: [],
          },
          {
            category_id: 322,
            pid: 317,
            level: 3,
            category_name: "Stowing Tidying",
            seo_category_name: "stowing-tidying",
            children: [],
          },
        ],
      },
      {
        category_id: 323,
        pid: 312,
        level: 2,
        category_name: "Tools, Maintenance & Care",
        seo_category_name: "tools-maintenance-care",
        children: [
          {
            category_id: 324,
            pid: 323,
            level: 3,
            category_name: "Car Washer",
            seo_category_name: "car-washer",
            children: [],
          },
          {
            category_id: 325,
            pid: 323,
            level: 3,
            category_name: "Diagnostic Tools",
            seo_category_name: "diagnostic-tools",
            children: [],
          },
          {
            category_id: 326,
            pid: 323,
            level: 3,
            category_name: "Paint Care",
            seo_category_name: "paint-care",
            children: [],
          },
          {
            category_id: 327,
            pid: 323,
            level: 3,
            category_name: "Other Maintenance Products",
            seo_category_name: "other-maintenance-products",
            children: [],
          },
        ],
      },
      {
        category_id: 328,
        pid: 312,
        level: 2,
        category_name: "Car Electronics",
        seo_category_name: "car-electronics",
        children: [
          {
            category_id: 329,
            pid: 328,
            level: 3,
            category_name: "Vehicle Camera",
            seo_category_name: "vehicle-camera",
            children: [],
          },
          {
            category_id: 330,
            pid: 328,
            level: 3,
            category_name: "DVR & Dash Camera",
            seo_category_name: "dvr-dash-camera",
            children: [],
          },
          {
            category_id: 331,
            pid: 328,
            level: 3,
            category_name: "Car Monitors",
            seo_category_name: "car-monitors",
            children: [],
          },
          {
            category_id: 332,
            pid: 328,
            level: 3,
            category_name: "Vehicle GPS",
            seo_category_name: "vehicle-gps",
            children: [],
          },
          {
            category_id: 333,
            pid: 328,
            level: 3,
            category_name: "Car Mirror Video",
            seo_category_name: "car-mirror-video",
            children: [],
          },
          {
            category_id: 334,
            pid: 328,
            level: 3,
            category_name: "Car Radios",
            seo_category_name: "car-radios",
            children: [],
          },
          {
            category_id: 335,
            pid: 328,
            level: 3,
            category_name: "GPS Trackers",
            seo_category_name: "gps-trackers",
            children: [],
          },
          {
            category_id: 336,
            pid: 328,
            level: 3,
            category_name: "Car Multimedia Player",
            seo_category_name: "car-multimedia-player",
            children: [],
          },
          {
            category_id: 337,
            pid: 328,
            level: 3,
            category_name: "Alarm Systems & Security",
            seo_category_name: "alarm-systems-security",
            children: [],
          },
          {
            category_id: 338,
            pid: 328,
            level: 3,
            category_name: "Jump Starter",
            seo_category_name: "jump-starter",
            children: [],
          },
        ],
      },
      {
        category_id: 339,
        pid: 312,
        level: 2,
        category_name: "Motorcycle Accessories & Parts",
        seo_category_name: "motorcycle-accessories-parts",
        children: [
          {
            category_id: 340,
            pid: 339,
            level: 3,
            category_name: "Lighting",
            seo_category_name: "lighting",
            children: [],
          },
          {
            category_id: 341,
            pid: 339,
            level: 3,
            category_name: "Exhaust & Exhaust Systems",
            seo_category_name: "exhaust-exhaust-systems",
            children: [],
          },
          {
            category_id: 342,
            pid: 339,
            level: 3,
            category_name: "Motor Brake System",
            seo_category_name: "motor-brake-system",
            children: [],
          },
          {
            category_id: 343,
            pid: 339,
            level: 3,
            category_name: "Motorcycle Seat Covers",
            seo_category_name: "motorcycle-seat-covers",
            children: [],
          },
          {
            category_id: 344,
            pid: 339,
            level: 3,
            category_name: "Other Motorcycle Accessories",
            seo_category_name: "other-motorcycle-accessories",
            children: [],
          },
          {
            category_id: 345,
            pid: 339,
            level: 3,
            category_name: "Helmet Headset",
            seo_category_name: "helmet-headset",
            children: [],
          },
          {
            category_id: 346,
            pid: 339,
            level: 3,
            category_name: "Body & Frame",
            seo_category_name: "body-frame",
            children: [],
          },
        ],
      },
      {
        category_id: 347,
        pid: 312,
        level: 2,
        category_name: "Auto Replacement Parts",
        seo_category_name: "auto-replacement-parts",
        children: [
          {
            category_id: 348,
            pid: 347,
            level: 3,
            category_name: "Interior Parts",
            seo_category_name: "interior-parts",
            children: [],
          },
          {
            category_id: 349,
            pid: 347,
            level: 3,
            category_name: "Car Brake System",
            seo_category_name: "car-brake-system",
            children: [],
          },
          {
            category_id: 350,
            pid: 347,
            level: 3,
            category_name: "Spark Plugs & Ignition System",
            seo_category_name: "spark-plugs-ignition-system",
            children: [],
          },
          {
            category_id: 351,
            pid: 347,
            level: 3,
            category_name: "Automobiles Sensors",
            seo_category_name: "automobiles-sensors",
            children: [],
          },
          {
            category_id: 352,
            pid: 347,
            level: 3,
            category_name: "Exterior Parts",
            seo_category_name: "exterior-parts",
            children: [],
          },
          {
            category_id: 353,
            pid: 347,
            level: 3,
            category_name: "Other Replacement Parts",
            seo_category_name: "other-replacement-parts",
            children: [],
          },
          {
            category_id: 354,
            pid: 347,
            level: 3,
            category_name: "Car Lights",
            seo_category_name: "car-lights",
            children: [],
          },
          {
            category_id: 355,
            pid: 347,
            level: 3,
            category_name: "Windscreen Wipers & Windows",
            seo_category_name: "windscreen-wipers-windows",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 356,
    pid: 0,
    level: 1,
    category_name: "Toys, Kids & Babies",
    seo_category_name: "toys-kids-babies",
    children: [
      {
        category_id: 357,
        pid: 356,
        level: 2,
        category_name: "Toys & Hobbies",
        seo_category_name: "toys-hobbies",
        children: [
          {
            category_id: 358,
            pid: 357,
            level: 3,
            category_name: "Electronic Pets",
            seo_category_name: "electronic-pets",
            children: [],
          },
          {
            category_id: 359,
            pid: 357,
            level: 3,
            category_name: "Blocks",
            seo_category_name: "blocks",
            children: [],
          },
          {
            category_id: 360,
            pid: 357,
            level: 3,
            category_name: "RC Helicopters",
            seo_category_name: "rc-helicopters",
            children: [],
          },
          {
            category_id: 361,
            pid: 357,
            level: 3,
            category_name: "Stuffed & Plush Animals",
            seo_category_name: "stuffed-plush-animals",
            children: [],
          },
          {
            category_id: 362,
            pid: 357,
            level: 3,
            category_name: "Action & Toy Figures",
            seo_category_name: "action-toy-figures",
            children: [],
          },
        ],
      },
      {
        category_id: 363,
        pid: 356,
        level: 2,
        category_name: "Shoes & Bags",
        seo_category_name: "shoes-bags",
        children: [
          {
            category_id: 364,
            pid: 363,
            level: 3,
            category_name: "Boys Shoes",
            seo_category_name: "boys-shoes",
            children: [],
          },
          {
            category_id: 365,
            pid: 363,
            level: 3,
            category_name: "School Bags",
            seo_category_name: "school-bags",
            children: [],
          },
          {
            category_id: 366,
            pid: 363,
            level: 3,
            category_name: "Kids Wallets",
            seo_category_name: "kids-wallets",
            children: [],
          },
          {
            category_id: 367,
            pid: 363,
            level: 3,
            category_name: "Girls Shoes",
            seo_category_name: "girls-shoes",
            children: [],
          },
          {
            category_id: 368,
            pid: 363,
            level: 3,
            category_name: "Baby's First Walkers",
            seo_category_name: "babys-first-walkers",
            children: [],
          },
        ],
      },
      {
        category_id: 369,
        pid: 356,
        level: 2,
        category_name: "Boys Clothing",
        seo_category_name: "boys-clothing",
        children: [
          {
            category_id: 370,
            pid: 369,
            level: 3,
            category_name: "Boy Jeans",
            seo_category_name: "boy-jeans",
            children: [],
          },
          {
            category_id: 371,
            pid: 369,
            level: 3,
            category_name: "Boy T-Shirts",
            seo_category_name: "boy-tshirts",
            children: [],
          },
          {
            category_id: 372,
            pid: 369,
            level: 3,
            category_name: "Boy Hoodies & Sweatshirts",
            seo_category_name: "boy-hoodies-sweatshirts",
            children: [],
          },
          {
            category_id: 373,
            pid: 369,
            level: 3,
            category_name: "Outerwear & Coats",
            seo_category_name: "outerwear-coats",
            children: [],
          },
          {
            category_id: 374,
            pid: 369,
            level: 3,
            category_name: "Boy Clothing Sets",
            seo_category_name: "boy-clothing-sets",
            children: [],
          },
          {
            category_id: 375,
            pid: 369,
            level: 3,
            category_name: "Boy Accessories",
            seo_category_name: "boy-accessories",
            children: [],
          },
        ],
      },
      {
        category_id: 376,
        pid: 356,
        level: 2,
        category_name: "Baby Clothing",
        seo_category_name: "baby-clothing",
        children: [
          {
            category_id: 377,
            pid: 376,
            level: 3,
            category_name: "Baby Clothing Sets",
            seo_category_name: "baby-clothing-sets",
            children: [],
          },
          {
            category_id: 378,
            pid: 376,
            level: 3,
            category_name: "Baby Rompers",
            seo_category_name: "baby-rompers",
            children: [],
          },
          {
            category_id: 379,
            pid: 376,
            level: 3,
            category_name: "Baby Accessories",
            seo_category_name: "baby-accessories",
            children: [],
          },
          {
            category_id: 380,
            pid: 376,
            level: 3,
            category_name: "Baby Outerwear",
            seo_category_name: "baby-outerwear",
            children: [],
          },
          {
            category_id: 381,
            pid: 376,
            level: 3,
            category_name: "Baby Dresses",
            seo_category_name: "baby-dresses",
            children: [],
          },
          {
            category_id: 382,
            pid: 376,
            level: 3,
            category_name: "Baby Pants",
            seo_category_name: "baby-pants",
            children: [],
          },
        ],
      },
      {
        category_id: 383,
        pid: 356,
        level: 2,
        category_name: "Baby & Mother",
        seo_category_name: "baby-mother",
        children: [
          {
            category_id: 384,
            pid: 383,
            level: 3,
            category_name: "Nappy Changing",
            seo_category_name: "nappy-changing",
            children: [],
          },
          {
            category_id: 385,
            pid: 383,
            level: 3,
            category_name: "Activity & Gear",
            seo_category_name: "activity-gear",
            children: [],
          },
          {
            category_id: 386,
            pid: 383,
            level: 3,
            category_name: "Backpacks & Carriers",
            seo_category_name: "backpacks-carriers",
            children: [],
          },
          {
            category_id: 387,
            pid: 383,
            level: 3,
            category_name: "Baby Care",
            seo_category_name: "baby-care",
            children: [],
          },
          {
            category_id: 388,
            pid: 383,
            level: 3,
            category_name: "Maternity",
            seo_category_name: "maternity",
            children: [],
          },
        ],
      },
      {
        category_id: 389,
        pid: 356,
        level: 2,
        category_name: "Girls Clothing",
        seo_category_name: "girls-clothing",
        children: [
          {
            category_id: 390,
            pid: 389,
            level: 3,
            category_name: "Girls Underwear",
            seo_category_name: "girls-underwear",
            children: [],
          },
          {
            category_id: 391,
            pid: 389,
            level: 3,
            category_name: "Family Matching Outfits",
            seo_category_name: "family-matching-outfits",
            children: [],
          },
          {
            category_id: 392,
            pid: 389,
            level: 3,
            category_name: "Sleepwear & Robes",
            seo_category_name: "sleepwear-robes",
            children: [],
          },
          {
            category_id: 393,
            pid: 389,
            level: 3,
            category_name: "Tops & Tees",
            seo_category_name: "tops-tees",
            children: [],
          },
          {
            category_id: 394,
            pid: 389,
            level: 3,
            category_name: "Girl Clothing Sets",
            seo_category_name: "girl-clothing-sets",
            children: [],
          },
          {
            category_id: 395,
            pid: 389,
            level: 3,
            category_name: "Girl Accessories",
            seo_category_name: "girl-accessories",
            children: [],
          },
          {
            category_id: 396,
            pid: 389,
            level: 3,
            category_name: "Girl Dresses",
            seo_category_name: "girl-dresses",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 397,
    pid: 0,
    level: 1,
    category_name: "Men's Clothing",
    seo_category_name: "mens-clothing",
    children: [
      {
        category_id: 398,
        pid: 397,
        level: 2,
        category_name: "Underwear & Loungewear",
        seo_category_name: "underwear-loungewear",
        children: [
          {
            category_id: 399,
            pid: 398,
            level: 3,
            category_name: "Men's Sleep & Lounge",
            seo_category_name: "mens-sleep-lounge",
            children: [],
          },
          {
            category_id: 400,
            pid: 398,
            level: 3,
            category_name: "Shorts",
            seo_category_name: "shorts",
            children: [],
          },
          {
            category_id: 401,
            pid: 398,
            level: 3,
            category_name: "Briefs",
            seo_category_name: "briefs",
            children: [],
          },
          {
            category_id: 402,
            pid: 398,
            level: 3,
            category_name: "Robes",
            seo_category_name: "robes",
            children: [],
          },
          {
            category_id: 403,
            pid: 398,
            level: 3,
            category_name: "Man Pajama Sets",
            seo_category_name: "man-pajama-sets",
            children: [],
          },
          {
            category_id: 404,
            pid: 398,
            level: 3,
            category_name: "Boxers",
            seo_category_name: "boxers",
            children: [],
          },
          {
            category_id: 405,
            pid: 398,
            level: 3,
            category_name: "Long Johns",
            seo_category_name: "long-johns",
            children: [],
          },
        ],
      },
      {
        category_id: 406,
        pid: 397,
        level: 2,
        category_name: "Outerwear & Jackets",
        seo_category_name: "outerwear-jackets",
        children: [
          {
            category_id: 407,
            pid: 406,
            level: 3,
            category_name: "Suits & Blazer",
            seo_category_name: "suits-blazer",
            children: [],
          },
          {
            category_id: 408,
            pid: 406,
            level: 3,
            category_name: "Men's Sweaters",
            seo_category_name: "mens-sweaters",
            children: [],
          },
          {
            category_id: 409,
            pid: 406,
            level: 3,
            category_name: "Genuine Leather",
            seo_category_name: "genuine-leather",
            children: [],
          },
          {
            category_id: 410,
            pid: 406,
            level: 3,
            category_name: "Man Trench",
            seo_category_name: "man-trench",
            children: [],
          },
          {
            category_id: 411,
            pid: 406,
            level: 3,
            category_name: "Man Hoodies & Sweatshirts",
            seo_category_name: "man-hoodies-sweatshirts",
            children: [],
          },
          {
            category_id: 412,
            pid: 406,
            level: 3,
            category_name: "Wool & Blends",
            seo_category_name: "wool-blends",
            children: [],
          },
          {
            category_id: 413,
            pid: 406,
            level: 3,
            category_name: "Parkas",
            seo_category_name: "parkas",
            children: [],
          },
          {
            category_id: 414,
            pid: 406,
            level: 3,
            category_name: "Down Jackets",
            seo_category_name: "down-jackets",
            children: [],
          },
          {
            category_id: 607,
            pid: 406,
            level: 3,
            category_name: "Men's Shirts",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 608,
            pid: 406,
            level: 3,
            category_name: "Men's Jackets",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 415,
        pid: 397,
        level: 2,
        category_name: "Accessories",
        seo_category_name: "accessories",
        children: [
          {
            category_id: 416,
            pid: 415,
            level: 3,
            category_name: "Socks",
            seo_category_name: "socks",
            children: [],
          },
          {
            category_id: 417,
            pid: 415,
            level: 3,
            category_name: "Scarves",
            seo_category_name: "scarves",
            children: [],
          },
          {
            category_id: 418,
            pid: 415,
            level: 3,
            category_name: "Man Gloves & Mittens",
            seo_category_name: "man-gloves-mittens",
            children: [],
          },
          {
            category_id: 419,
            pid: 415,
            level: 3,
            category_name: "Skullies & Beanies",
            seo_category_name: "skullies-beanies",
            children: [],
          },
          {
            category_id: 420,
            pid: 415,
            level: 3,
            category_name: "Belts",
            seo_category_name: "belts",
            children: [],
          },
          {
            category_id: 421,
            pid: 415,
            level: 3,
            category_name: "Man Prescription Glasses",
            seo_category_name: "man-prescription-glasses",
            children: [],
          },
          {
            category_id: 609,
            pid: 415,
            level: 3,
            category_name: "Men's Ties",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 422,
        pid: 397,
        level: 2,
        category_name: "Bottoms",
        seo_category_name: "bottoms",
        children: [
          {
            category_id: 423,
            pid: 422,
            level: 3,
            category_name: "Pajama Sets",
            seo_category_name: "pajama-sets",
            children: [],
          },
          {
            category_id: 424,
            pid: 422,
            level: 3,
            category_name: "Man Shorts",
            seo_category_name: "man-shorts",
            children: [],
          },
          {
            category_id: 425,
            pid: 422,
            level: 3,
            category_name: "Cargo Pants",
            seo_category_name: "cargo-pants",
            children: [],
          },
          {
            category_id: 426,
            pid: 422,
            level: 3,
            category_name: "Man Jeans",
            seo_category_name: "man-jeans",
            children: [],
          },
          {
            category_id: 427,
            pid: 422,
            level: 3,
            category_name: "Harem Pants",
            seo_category_name: "harem-pants",
            children: [],
          },
          {
            category_id: 428,
            pid: 422,
            level: 3,
            category_name: "Casual Pants",
            seo_category_name: "casual-pants",
            children: [],
          },
          {
            category_id: 429,
            pid: 422,
            level: 3,
            category_name: "Sweatpants",
            seo_category_name: "sweatpants",
            children: [],
          },
        ],
      },
      {
        category_id: 430,
        pid: 397,
        level: 2,
        category_name: "T-Shirts",
        seo_category_name: "tshirts",
        children: [
          {
            category_id: 431,
            pid: 430,
            level: 3,
            category_name: "Geometric",
            seo_category_name: "geometric",
            children: [],
          },
          {
            category_id: 432,
            pid: 430,
            level: 3,
            category_name: "Striped",
            seo_category_name: "striped",
            children: [],
          },
          {
            category_id: 433,
            pid: 430,
            level: 3,
            category_name: "Solid",
            seo_category_name: "solid",
            children: [],
          },
          {
            category_id: 434,
            pid: 430,
            level: 3,
            category_name: "3D",
            seo_category_name: "3d",
            children: [],
          },
          {
            category_id: 435,
            pid: 430,
            level: 3,
            category_name: "Print",
            seo_category_name: "print",
            children: [],
          },
        ],
      },
      {
        category_id: 436,
        pid: 397,
        level: 2,
        category_name: "Hats & Caps",
        seo_category_name: "hats-caps",
        children: [
          {
            category_id: 437,
            pid: 436,
            level: 3,
            category_name: "Baseball Caps",
            seo_category_name: "baseball-caps",
            children: [],
          },
          {
            category_id: 438,
            pid: 436,
            level: 3,
            category_name: "Bomber Hats",
            seo_category_name: "bomber-hats",
            children: [],
          },
          {
            category_id: 439,
            pid: 436,
            level: 3,
            category_name: "Berets",
            seo_category_name: "berets",
            children: [],
          },
          {
            category_id: 440,
            pid: 436,
            level: 3,
            category_name: "Fedoras",
            seo_category_name: "fedoras",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 441,
    pid: 0,
    level: 1,
    category_name: "Consumer Electronics",
    seo_category_name: "consumer-electronics",
    children: [
      {
        category_id: 442,
        pid: 441,
        level: 2,
        category_name: "Accessories & Parts",
        seo_category_name: "accessories-parts",
        children: [
          {
            category_id: 443,
            pid: 442,
            level: 3,
            category_name: "Digital Cables",
            seo_category_name: "digital-cables",
            children: [],
          },
          {
            category_id: 444,
            pid: 442,
            level: 3,
            category_name: "Home Electronic Accessories",
            seo_category_name: "home-electronic-accessories",
            children: [],
          },
          {
            category_id: 445,
            pid: 442,
            level: 3,
            category_name: "Audio & Video Cables",
            seo_category_name: "audio-video-cables",
            children: [],
          },
          {
            category_id: 446,
            pid: 442,
            level: 3,
            category_name: "Charger",
            seo_category_name: "charger",
            children: [],
          },
          {
            category_id: 447,
            pid: 442,
            level: 3,
            category_name: "Batteries",
            seo_category_name: "batteries",
            children: [],
          },
          {
            category_id: 448,
            pid: 442,
            level: 3,
            category_name: "Digital Gear Bags",
            seo_category_name: "digital-gear-bags",
            children: [],
          },
        ],
      },
      {
        category_id: 449,
        pid: 441,
        level: 2,
        category_name: "Home Audio & Video",
        seo_category_name: "home-audio-video",
        children: [
          {
            category_id: 450,
            pid: 449,
            level: 3,
            category_name: "Projectors",
            seo_category_name: "projectors",
            children: [],
          },
          {
            category_id: 451,
            pid: 449,
            level: 3,
            category_name: "Television",
            seo_category_name: "television",
            children: [],
          },
          {
            category_id: 452,
            pid: 449,
            level: 3,
            category_name: "TV Receivers",
            seo_category_name: "tv-receivers",
            children: [],
          },
          {
            category_id: 453,
            pid: 449,
            level: 3,
            category_name: "Audio Amplifiers",
            seo_category_name: "audio-amplifiers",
            children: [],
          },
          {
            category_id: 454,
            pid: 449,
            level: 3,
            category_name: "Projectors & Acessories",
            seo_category_name: "projectors-acessories",
            children: [],
          },
          {
            category_id: 455,
            pid: 449,
            level: 3,
            category_name: "Home Audio & Video",
            seo_category_name: "home-audio-video",
            children: [],
          },
          {
            category_id: 456,
            pid: 449,
            level: 3,
            category_name: "TV Sticks",
            seo_category_name: "tv-sticks",
            children: [],
          },
        ],
      },
      {
        category_id: 457,
        pid: 441,
        level: 2,
        category_name: "Smart Electronics",
        seo_category_name: "smart-electronics",
        children: [
          {
            category_id: 458,
            pid: 457,
            level: 3,
            category_name: "Wearable Devices",
            seo_category_name: "wearable-devices",
            children: [],
          },
          {
            category_id: 459,
            pid: 457,
            level: 3,
            category_name: "Smart Home Appliances",
            seo_category_name: "smart-home-appliances",
            children: [],
          },
          {
            category_id: 460,
            pid: 457,
            level: 3,
            category_name: "Smart Wearable Accessories",
            seo_category_name: "smart-wearable-accessories",
            children: [],
          },
          {
            category_id: 461,
            pid: 457,
            level: 3,
            category_name: "Smart Wristbands",
            seo_category_name: "smart-wristbands",
            children: [],
          },
          {
            category_id: 462,
            pid: 457,
            level: 3,
            category_name: "Smart Watches",
            seo_category_name: "smart-watches",
            children: [],
          },
          {
            category_id: 463,
            pid: 457,
            level: 3,
            category_name: "Smart Remote Controls",
            seo_category_name: "smart-remote-controls",
            children: [],
          },
        ],
      },
      {
        category_id: 464,
        pid: 441,
        level: 2,
        category_name: "Camera & Photo",
        seo_category_name: "camera-photo",
        children: [
          {
            category_id: 465,
            pid: 464,
            level: 3,
            category_name: "Photo Studio",
            seo_category_name: "photo-studio",
            children: [],
          },
          {
            category_id: 466,
            pid: 464,
            level: 3,
            category_name: "Camera Drones",
            seo_category_name: "camera-drones",
            children: [],
          },
          {
            category_id: 467,
            pid: 464,
            level: 3,
            category_name: "Camera & Photo Accessories",
            seo_category_name: "camera-photo-accessories",
            children: [],
          },
          {
            category_id: 468,
            pid: 464,
            level: 3,
            category_name: "Digital Cameras",
            seo_category_name: "digital-cameras",
            children: [],
          },
          {
            category_id: 469,
            pid: 464,
            level: 3,
            category_name: "Action Cameras",
            seo_category_name: "action-cameras",
            children: [],
          },
          {
            category_id: 470,
            pid: 464,
            level: 3,
            category_name: "Camcorders",
            seo_category_name: "camcorders",
            children: [],
          },
        ],
      },
      {
        category_id: 471,
        pid: 441,
        level: 2,
        category_name: "Video Games",
        seo_category_name: "video-games",
        children: [
          {
            category_id: 472,
            pid: 471,
            level: 3,
            category_name: "Gamepads",
            seo_category_name: "gamepads",
            children: [],
          },
          {
            category_id: 473,
            pid: 471,
            level: 3,
            category_name: "Handheld Game Players",
            seo_category_name: "handheld-game-players",
            children: [],
          },
          {
            category_id: 474,
            pid: 471,
            level: 3,
            category_name: "Video Game Consoles",
            seo_category_name: "video-game-consoles",
            children: [],
          },
          {
            category_id: 475,
            pid: 471,
            level: 3,
            category_name: "Stickers",
            seo_category_name: "stickers",
            children: [],
          },
          {
            category_id: 476,
            pid: 471,
            level: 3,
            category_name: "Joysticks",
            seo_category_name: "joysticks",
            children: [],
          },
        ],
      },
      {
        category_id: 477,
        pid: 441,
        level: 2,
        category_name: "Portable Audio & Video",
        seo_category_name: "portable-audio-video",
        children: [
          {
            category_id: 478,
            pid: 477,
            level: 3,
            category_name: "Microphones",
            seo_category_name: "microphones",
            children: [],
          },
          {
            category_id: 479,
            pid: 477,
            level: 3,
            category_name: "Speakers",
            seo_category_name: "speakers",
            children: [],
          },
          {
            category_id: 480,
            pid: 477,
            level: 3,
            category_name: "Earphones & Headphones",
            seo_category_name: "earphones-headphones",
            children: [],
          },
          {
            category_id: 481,
            pid: 477,
            level: 3,
            category_name: "VR & AR Devices",
            seo_category_name: "vr-ar-devices",
            children: [],
          },
          {
            category_id: 482,
            pid: 477,
            level: 3,
            category_name: "MP3 Players",
            seo_category_name: "mp3-players",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 483,
    pid: 0,
    level: 1,
    category_name: "Phones & Accessories",
    seo_category_name: "phones-accessories",
    children: [
      {
        category_id: 484,
        pid: 483,
        level: 2,
        category_name: "Mobile Phone Accessories",
        seo_category_name: "mobile-phone-accessories",
        children: [
          {
            category_id: 485,
            pid: 484,
            level: 3,
            category_name: "Cables",
            seo_category_name: "cables",
            children: [],
          },
          {
            category_id: 486,
            pid: 484,
            level: 3,
            category_name: "Power Bank",
            seo_category_name: "power-bank",
            children: [],
          },
          {
            category_id: 487,
            pid: 484,
            level: 3,
            category_name: "Screen Protectors",
            seo_category_name: "screen-protectors",
            children: [],
          },
          {
            category_id: 488,
            pid: 484,
            level: 3,
            category_name: "Lenses",
            seo_category_name: "lenses",
            children: [],
          },
          {
            category_id: 489,
            pid: 484,
            level: 3,
            category_name: "Holders & Stands",
            seo_category_name: "holders-stands",
            children: [],
          },
          {
            category_id: 490,
            pid: 484,
            level: 3,
            category_name: "Chargers",
            seo_category_name: "chargers",
            children: [],
          },
        ],
      },
      {
        category_id: 491,
        pid: 483,
        level: 2,
        category_name: "Cases & Covers",
        seo_category_name: "cases-covers",
        children: [
          {
            category_id: 492,
            pid: 491,
            level: 3,
            category_name: "Huawei Cases",
            seo_category_name: "huawei-cases",
            children: [],
          },
          {
            category_id: 493,
            pid: 491,
            level: 3,
            category_name: "Patterned Cases",
            seo_category_name: "patterned-cases",
            children: [],
          },
          {
            category_id: 494,
            pid: 491,
            level: 3,
            category_name: "Cases For iPhone 6 & 6 Plus",
            seo_category_name: "cases-for-iphone-6-6-plus",
            children: [],
          },
          {
            category_id: 495,
            pid: 491,
            level: 3,
            category_name: "Wallet Cases",
            seo_category_name: "wallet-cases",
            children: [],
          },
          {
            category_id: 496,
            pid: 491,
            level: 3,
            category_name: "Cases For iPhone 7 & 7 Plus",
            seo_category_name: "cases-for-iphone-7-7-plus",
            children: [],
          },
          {
            category_id: 497,
            pid: 491,
            level: 3,
            category_name: "Galaxy S8 Cases",
            seo_category_name: "galaxy-s8-cases",
            children: [],
          },
          {
            category_id: 498,
            pid: 491,
            level: 3,
            category_name: "IPhone X Cases",
            seo_category_name: "iphone-x-cases",
            children: [],
          },
          {
            category_id: 499,
            pid: 491,
            level: 3,
            category_name: "Galaxy S7 Cases",
            seo_category_name: "galaxy-s7-cases",
            children: [],
          },
          {
            category_id: 500,
            pid: 491,
            level: 3,
            category_name: "Cases For iPhone 8 & 8 Plus",
            seo_category_name: "cases-for-iphone-8-8-plus",
            children: [],
          },
          {
            category_id: 501,
            pid: 491,
            level: 3,
            category_name: "Xiaomi Cases",
            seo_category_name: "xiaomi-cases",
            children: [],
          },
          {
            category_id: 502,
            pid: 491,
            level: 3,
            category_name: "Silicone Cases",
            seo_category_name: "silicone-cases",
            children: [],
          },
          {
            category_id: 503,
            pid: 491,
            level: 3,
            category_name: "Flip Cases",
            seo_category_name: "flip-cases",
            children: [],
          },
          {
            category_id: 504,
            pid: 491,
            level: 3,
            category_name: "Waterptoof Cases",
            seo_category_name: "waterptoof-cases",
            children: [],
          },
          {
            category_id: 505,
            pid: 491,
            level: 3,
            category_name: "Leather Cases",
            seo_category_name: "leather-cases",
            children: [],
          },
        ],
      },
      {
        category_id: 506,
        pid: 483,
        level: 2,
        category_name: "Mobile Phone Parts",
        seo_category_name: "mobile-phone-parts",
        children: [
          {
            category_id: 507,
            pid: 506,
            level: 3,
            category_name: "SIM Card & Tools",
            seo_category_name: "sim-card-tools",
            children: [],
          },
          {
            category_id: 508,
            pid: 506,
            level: 3,
            category_name: "Mobile Batteries",
            seo_category_name: "mobile-batteries",
            children: [],
          },
          {
            category_id: 509,
            pid: 506,
            level: 3,
            category_name: "Housings",
            seo_category_name: "housings",
            children: [],
          },
          {
            category_id: 510,
            pid: 506,
            level: 3,
            category_name: "LCDs",
            seo_category_name: "lcds",
            children: [],
          },
          {
            category_id: 511,
            pid: 506,
            level: 3,
            category_name: "Flex Cables",
            seo_category_name: "flex-cables",
            children: [],
          },
          {
            category_id: 512,
            pid: 506,
            level: 3,
            category_name: "Touch Panel",
            seo_category_name: "touch-panel",
            children: [],
          },
        ],
      },
      {
        category_id: 513,
        pid: 483,
        level: 2,
        category_name: "Mobile Phones",
        seo_category_name: "mobile-phones",
        children: [
          {
            category_id: 514,
            pid: 513,
            level: 3,
            category_name: "Quad Core",
            seo_category_name: "quad-core",
            children: [],
          },
          {
            category_id: 515,
            pid: 513,
            level: 3,
            category_name: "Single SIM Card",
            seo_category_name: "single-sim-card",
            children: [],
          },
          {
            category_id: 516,
            pid: 513,
            level: 3,
            category_name: "Dual SIM Card",
            seo_category_name: "dual-sim-card",
            children: [],
          },
          {
            category_id: 517,
            pid: 513,
            level: 3,
            category_name: "3GB RAM",
            seo_category_name: "3gb-ram",
            children: [],
          },
          {
            category_id: 518,
            pid: 513,
            level: 3,
            category_name: "Octa Core",
            seo_category_name: "octa-core",
            children: [],
          },
          {
            category_id: 519,
            pid: 513,
            level: 3,
            category_name: "5-inch Display",
            seo_category_name: "5inch-display",
            children: [],
          },
        ],
      },
    ],
  },
  {
    category_id: 523,
    pid: 0,
    level: 1,
    category_name: "Pet Supplies",
    seo_category_name: "",
    children: [
      {
        category_id: 524,
        pid: 523,
        level: 2,
        category_name: "Pet Toys",
        seo_category_name: "",
        children: [
          {
            category_id: 525,
            pid: 524,
            level: 3,
            category_name: "Pet Chase Toys",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 526,
            pid: 524,
            level: 3,
            category_name: "Pet Chew Toys",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 527,
            pid: 524,
            level: 3,
            category_name: "Pet Training and Educational Toys",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 528,
            pid: 524,
            level: 3,
            category_name: "Pet Sound Toys",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 529,
            pid: 524,
            level: 3,
            category_name: "Pet Tunnel Toys",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 530,
            pid: 524,
            level: 3,
            category_name: "Pet Toy Set",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 531,
            pid: 524,
            level: 3,
            category_name: "Pet Plush Toys",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 532,
        pid: 523,
        level: 2,
        category_name: "Pet Drinking & Feeding",
        seo_category_name: "",
        children: [
          {
            category_id: 533,
            pid: 532,
            level: 3,
            category_name: "Pet Bowls",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 534,
            pid: 532,
            level: 3,
            category_name: "Pet Drinking Tools",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 535,
            pid: 532,
            level: 3,
            category_name: "Pet Feeding Tools",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 536,
        pid: 523,
        level: 2,
        category_name: "Pet Outdoor Supplies",
        seo_category_name: "",
        children: [
          {
            category_id: 537,
            pid: 536,
            level: 3,
            category_name: "Barking Control Equipments",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 538,
            pid: 536,
            level: 3,
            category_name: "Trainers",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 539,
            pid: 536,
            level: 3,
            category_name: "Dog Training Pads & Diapers",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 540,
            pid: 536,
            level: 3,
            category_name: "Pet Snacks",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 541,
            pid: 536,
            level: 3,
            category_name: "Pet Bags",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 542,
            pid: 536,
            level: 3,
            category_name: "Pet Seat Belts",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 543,
            pid: 536,
            level: 3,
            category_name: "Pet Car Mats",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 544,
            pid: 536,
            level: 3,
            category_name: "Pet Guardrails",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 545,
        pid: 523,
        level: 2,
        category_name: "Bird Supplies",
        seo_category_name: "",
        children: [
          {
            category_id: 546,
            pid: 545,
            level: 3,
            category_name: "Bird Feeders",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 547,
            pid: 545,
            level: 3,
            category_name: "Bird Cages",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 548,
            pid: 545,
            level: 3,
            category_name: "Bird Swings",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 549,
            pid: 545,
            level: 3,
            category_name: "Bird Toys",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 550,
            pid: 545,
            level: 3,
            category_name: "Bird Travel Bags",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 551,
            pid: 545,
            level: 3,
            category_name: "Bird Accessories",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 552,
        pid: 523,
        level: 2,
        category_name: "Fish & Aquatic Pets",
        seo_category_name: "",
        children: [
          {
            category_id: 553,
            pid: 552,
            level: 3,
            category_name: "Fish Tanks",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 554,
            pid: 552,
            level: 3,
            category_name: "Fish Tank Decorations",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 555,
            pid: 552,
            level: 3,
            category_name: "Fish Tank Cleaning Supplies",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 556,
        pid: 523,
        level: 2,
        category_name: "Pet Apparels",
        seo_category_name: "",
        children: [
          {
            category_id: 557,
            pid: 556,
            level: 3,
            category_name: "Pet Dresses",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 558,
            pid: 556,
            level: 3,
            category_name: "Pet Tops",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 559,
            pid: 556,
            level: 3,
            category_name: "Pet Sweaters",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 560,
            pid: 556,
            level: 3,
            category_name: "Pet Sweatshirts & Hoodies",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 561,
            pid: 556,
            level: 3,
            category_name: "Pet Coats & Jackets",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 562,
            pid: 556,
            level: 3,
            category_name: "Pet Jumpsuits",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 563,
            pid: 556,
            level: 3,
            category_name: "Pet Pajamas",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 564,
            pid: 556,
            level: 3,
            category_name: "Pet Clothings",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 565,
            pid: 556,
            level: 3,
            category_name: "Pet Functional Clothings",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 566,
            pid: 556,
            level: 3,
            category_name: "Pet Clothing Sets",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 567,
            pid: 556,
            level: 3,
            category_name: "Pet Down & Parkas",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 568,
            pid: 556,
            level: 3,
            category_name: "Pet Shoes & Socks",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 569,
            pid: 556,
            level: 3,
            category_name: "Pet Scarves",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 570,
            pid: 556,
            level: 3,
            category_name: "Pet Bags",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 571,
        pid: 523,
        level: 2,
        category_name: "Pet Collars, Harnesses & Accessories",
        seo_category_name: "",
        children: [
          {
            category_id: 572,
            pid: 571,
            level: 3,
            category_name: "Pet Hair Accessories",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 573,
            pid: 571,
            level: 3,
            category_name: "Pet Bows & Ties",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 574,
            pid: 571,
            level: 3,
            category_name: "Pet Necklaces",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 575,
            pid: 571,
            level: 3,
            category_name: "Pet Headwears",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 576,
            pid: 571,
            level: 3,
            category_name: "Pet Glasses",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 577,
            pid: 571,
            level: 3,
            category_name: "Pet Collars",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 578,
            pid: 571,
            level: 3,
            category_name: "Pet Leashes",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 579,
            pid: 571,
            level: 3,
            category_name: "Pet Harnesses",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 580,
            pid: 571,
            level: 3,
            category_name: "Pet Muzzles",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 581,
            pid: 571,
            level: 3,
            category_name: "Pet Collar, Leash & Harness Sets",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 582,
            pid: 571,
            level: 3,
            category_name: "Custom Pet tags, Collars, Leashes & Harnesses",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 583,
        pid: 523,
        level: 2,
        category_name: "Pet Groomings",
        seo_category_name: "",
        children: [
          {
            category_id: 584,
            pid: 583,
            level: 3,
            category_name: "Pet Hair Removers & Combs",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 585,
            pid: 583,
            level: 3,
            category_name: "Pet Nail Polishers",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 586,
            pid: 583,
            level: 3,
            category_name: "Pet Shower Products",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 587,
            pid: 583,
            level: 3,
            category_name: "Pet Towels",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 588,
        pid: 523,
        level: 2,
        category_name: "Pet Furnitures",
        seo_category_name: "",
        children: [
          {
            category_id: 589,
            pid: 588,
            level: 3,
            category_name: "Cat Scratching Posts",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 590,
            pid: 588,
            level: 3,
            category_name: "Pet Furniture Protectors",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 591,
            pid: 588,
            level: 3,
            category_name: "Cat Trees & Condos",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 592,
            pid: 588,
            level: 3,
            category_name: "Pet Furniture Tools",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 593,
            pid: 588,
            level: 3,
            category_name: "Pet Houses & Cages",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 594,
            pid: 588,
            level: 3,
            category_name: "Dog Stairs & Steps",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 595,
            pid: 588,
            level: 3,
            category_name: "Pet Tents",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 596,
            pid: 588,
            level: 3,
            category_name: "Pet Hammocks",
            seo_category_name: "",
            children: [],
          },
        ],
      },
      {
        category_id: 597,
        pid: 523,
        level: 2,
        category_name: "Pet Bedding",
        seo_category_name: "",
        children: [
          {
            category_id: 598,
            pid: 597,
            level: 3,
            category_name: "Pet Mats",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 599,
            pid: 597,
            level: 3,
            category_name: "Pet Nests",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 600,
            pid: 597,
            level: 3,
            category_name: "Pet Beds",
            seo_category_name: "",
            children: [],
          },
          {
            category_id: 601,
            pid: 597,
            level: 3,
            category_name: "Pet Blankets & Quilts",
            seo_category_name: "",
            children: [],
          },
        ],
      },
    ],
  },
];
export default withRouter(Index);
