import React, { Component } from 'react';

import {AppProvider, Frame, FormLayout, TextField, Button, Icon, Toast, Banner, Modal, BlockStack, Select } from '@shopify/polaris';

import {
  ViewIcon,EyeCheckMarkIcon
} from '@shopify/polaris-icons';

import { reqPostAuthUser,reqPostResetPasswordLink } from '../../../api'

import utils from '../../../utils/utils';

import './index.css';
import translations from '@shopify/polaris/locales/en.json';

class Authentication extends Component {

  state = {
    isLoginPage: true,  // 当前页面：/login 或者 /register
    hidePassword: true,
    emailError: '',
    passwordError: '',
    requestLoading: false,
    returnErrorMsg: '',
    recommendResetPassword: false,

    redirectTo: '',  // 登录重定向链接

    // 店铺选择数组
    userStores: [],
    storesOptions: [],
    selectStoreName: '',
    noSelectError: false,

    // 重置密码弹窗
    ResetEmail: '',
    resetPasswordActive: false,
    resetPasswordError: '',
    resetRequestLoading: false,
    resetSendLinkSuccess: false,

    // 提示弹窗
    showToast: false, // 提示弹窗是否显示
    showToastText: '', // 提示内容
    showToastIserror: false, // 是否为错误提示

    fromGoogleAds: '',  // 是否从谷歌广告跳转链接注册登录的标识
  };

  //进入页面调用接口
  componentDidMount() {
    // 获取谷歌参数
    const windowParams = new URLSearchParams(window.location.search);
    let surface_detail = windowParams.get("surface_detail") ? windowParams.get("surface_detail") : '';
    
    if (surface_detail) {
      this.setState({fromGoogleAds: surface_detail});
    }
  window.hideLoadingState()
    try {
      let urlPath = window.location.pathname;
      let arr = urlPath.split('/',2);
      if (arr[1] !== undefined) {
        let bool = (arr[1] === 'login') ? true : false;
        this.setState({
          isLoginPage: bool
        },()=>{
          let auth = utils.getStore('S_AUTHORIZATION');
          let domain    = utils.getStore("S_SHOP_DOMAIN");
          let usertoken = utils.getStore("S_USER_TOKEN");
          if (auth && domain && usertoken) {
            window.location.href= '/admin/dashboard';
          }
        });
      }

    } catch (error) {
      console.warn(error);
    }
  }

  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 用户注册，登录验证
  AuthUserRequest = async() =>{
    let isAllow = true;
    // email 校验
    if(!this.checkInputEmail(this.state.Email)){
      isAllow = false;
    }
    // password 校验
    if(!this.checkInputPassword(this.state.Password)){
      isAllow = false;
    }

    if(!isAllow){
      return false;
    }

    const params = {
      email: this.state.Email,
      password: this.state.Password,
      surface_detail: this.state.fromGoogleAds,  // 获取谷歌参数
      t: this.state.isLoginPage ? 'l' : 'r',
      c: '',
      f: 1
    };

    this.setState({ requestLoading: true });
    const response = await reqPostAuthUser(params)
    if (response.code === '200') {
      
      // 注册状态 且 广告信息有值
      if (!this.state.isLoginPage && this.state.fromGoogleAds) {
        //调用谷歌转化后跳转
        var GooleTag = this.handleGooleTag();
        if(GooleTag === 200){
          let getData = response.data;
          let storeArray = getData.stores;
          let redirectLink = getData.redirect;
        
          if (storeArray.length === 0) {
            // 重定向链接
            window.location.href = redirectLink;
          } else {
            // 直接进入默认店铺
            window.location.href = redirectLink;
            return;

            // 如果返回的店铺数组不为空，则需要用户选择一个进入
            // let array = [];
            // for (let i=0; i<storeArray.length; i++) {
            //   array.push(storeArray[i].name)
            // }
            // this.setState({
            //   requestLoading: false,
            //   userStores: storeArray,
            //   storesOptions: array,
            //   redirectTo: redirectLink
            // });
          }
        }
      }else{
        let getData = response.data;
        let storeArray = getData.stores;
        let redirectLink = getData.redirect;
      
        if (storeArray.length === 0) {
          // 重定向链接
          window.location.href = redirectLink;
        } else {
          // 直接进入默认店铺
          window.location.href = redirectLink;
          return;

          // 如果返回的店铺数组不为空，则需要用户选择一个进入
          // let array = [];
          // for (let i=0; i<storeArray.length; i++) {
          //   array.push(storeArray[i].name)
          // }
          // this.setState({
          //   requestLoading: false,
          //   userStores: storeArray,
          //   storesOptions: array,
          //   redirectTo: redirectLink
          // });
        }
      }
      
    } else {
      let state_param = {};
      if (response.code === '507') {
        state_param.recommendResetPassword = true;
      }
      state_param.requestLoading = false;
      state_param.returnErrorMsg = response.msg;
      this.setState(state_param);
    }
  }

   // 谷歌分析
   handleGooleTag = () => {
    try {
      window.gtag('event', 'conversion', {'send_to': 'AW-749999345/y-k4CIuay4sDEPGp0OUC'});
    } catch(e) {
      console.log(e)
    }
    return 200
  }

  // email 格式验证
  checkInputEmail(email){
    let errorMsg = '';
    let bool = false;
    if(email === undefined || email === ''){
      errorMsg = this.state.isLoginPage ? 'Email is required' : 'Please enter a valid email';
    }

    if(errorMsg === ''){
      let reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      bool = reg.test(email);
      errorMsg = bool ? '' : 'Invalid email address';
    }
    this.setState({ emailError: errorMsg });
    return bool;
  }

  // reset password email 格式验证
  checkResetPasswordEmail(email){
    let errorMsg = '';
    let bool = false;
    if(email === undefined || email === ''){
      errorMsg = 'Please enter your log in email';
    }

    if(errorMsg === ''){
      let reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      bool = reg.test(email);
      errorMsg = bool ? '' : 'Invalid email address';
    }
    this.setState({ resetPasswordError: errorMsg });
    return bool;
  }

  // password 密码校验
  checkInputPassword(password){
    let errorMsg = '';
    let bool = true;
    if(password === undefined || password === ''){
      bool = false;
      errorMsg = this.state.isLoginPage ? 'Password is required' : 'Please enter the password';
    }

    // 注册时校验密码格式
    if(errorMsg === '' && !this.state.isLoginPage){
      // 1.The password must be 8 - 24 characters long
      // 2.The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number

      if(password.length < 8 || password.length > 24){
        errorMsg = 'The password must be 8 - 24 characters long';
        bool = false;
      } else {
        let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
        bool = reg.test(password);
        errorMsg = bool ? '' : 'The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number';
      }
    }

    this.setState({ passwordError: errorMsg });
    return bool;
  }


  handleInputChange = (value,field) =>{
    this.setState({
      [field]: value
    },()=>{
      setTimeout(()=>{
        if(field === 'Email'){
          this.checkInputEmail(value);
        }
        if(field === 'Password'){
          this.checkInputPassword(value);
        }
        if(field === 'ResetEmail'){
          this.checkResetPasswordEmail(value)
        }
      },600);
    });
  }

  // 注册密码显示与隐藏
  handleDisplaySwitch = () =>{
    this.setState(({ hidePassword }) => ({ hidePassword: !hidePassword }));
  }

  // 重置密码弹窗
  handleResetPasswordModal = () => {
    let set_param = {};
    let active_flag = this.state.resetPasswordActive;
    if (!active_flag ) {
      if (this.state.recommendResetPassword && this.checkInputEmail(this.state.Email)) {
        set_param.ResetEmail = this.state.Email;
      } else {
        set_param.ResetEmail = '';
      }
      set_param.resetPasswordActive = true;
    } else {
      set_param.resetPasswordActive = false;
      set_param.ResetEmail = '';
    }
    set_param.resetPasswordError = '';
    this.setState(set_param);
  }

  // 发送重置密码链接
  ResetLinkRequest = async() =>{
    if(!this.checkResetPasswordEmail(this.state.ResetEmail)){
      return false;
    }

    const params = {
      email: this.state.ResetEmail,
    };

    this.setState({ resetRequestLoading: true });
    const response = await reqPostResetPasswordLink(params)
    if (response.code==='200') {
      this.setState({ 
        resetRequestLoading: false,
        resetSendLinkSuccess: true,
      });
    } else {
      this.setState({
        resetRequestLoading: false,
        resetPasswordError: response.msg,
      });
      // this.toggleToast(response.msg,true);
    }
  }

  // 店铺选择
  handleSelectChange = (value) =>{
    this.setState({
      selectStoreName: value,
      noSelectError: false
    });
  }

  // 确认进入店铺
  enterDropshipmanApp = () =>{
    if(this.state.selectStoreName === ''){
      this.setState({ noSelectError: true });
      return
    }

    let redirectLink = this.state.redirectTo;
    let theRequestParam = [];
    let MainDomain = '';
    if (redirectLink.indexOf("?") !== -1) {
      let domainArray = redirectLink.split('?');
      MainDomain = domainArray[0];
			let strs = domainArray[1].split("&");
      for (let j = 0; j < strs.length; j++) {
        let paramsName = strs[j].split("=")[0];
        if(paramsName !== 'domain' && paramsName !== 'usertoken' && paramsName !=='shop_owner' && paramsName !== 'store_name'){
          theRequestParam.push(strs[j]);
        }
      }
		}

    let userStores = this.state.userStores;
    for (let i = 0; i < userStores.length; i++) {
      if (userStores[i].name === this.state.selectStoreName) {
        theRequestParam.push('store_name=' + userStores[i].store_name);
        theRequestParam.push('domain=' + userStores[i].name);
        theRequestParam.push('usertoken=' + userStores[i].id);

        let shopOwner = userStores[i].owner;
        if(shopOwner !== ''){
          shopOwner = shopOwner.replace(' ','+');
        }

        theRequestParam.push('shop_owner=' + shopOwner);
        break;
      }
    }

    // 跳转到指定店铺
    if (MainDomain !== '') {
      //   alert(MainDomain + '?' + theRequestParam.join('&'));
      window.location.href = MainDomain + '?' + theRequestParam.join('&');
    } else {
      this.toggleToast('Something wrong');
    }
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    const { 
      isLoginPage, hidePassword, Email, Password, emailError, passwordError, requestLoading, returnErrorMsg,resetPasswordActive,
      ResetEmail,resetPasswordError,resetRequestLoading,resetSendLinkSuccess,storesOptions,selectStoreName,noSelectError, recommendResetPassword
    } = this.state;

    // 请求错误显示
    const RequestErrorMSG = (!recommendResetPassword && returnErrorMsg) && (
      <div className="auth_error">
        <Banner tone="critical"><p>{returnErrorMsg}</p></Banner>
      </div>
    );

    const ResetPasswordMSG = (recommendResetPassword && returnErrorMsg) && (
      <Banner
        title="Reset Password"
        action={{content: 'Reset', onAction: this.handleResetPasswordModal}}
        tone="warning"
      >
        {returnErrorMsg}
      </Banner>
    )

    const ResetPasswordModal = (
      <Modal
        open={resetPasswordActive}
        onClose={this.handleResetPasswordModal}
        title="Reset password"
        primaryAction={{
          content: 'Send Reset Link',
          onAction: this.ResetLinkRequest,
          loading: resetRequestLoading,
          disabled: resetSendLinkSuccess
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: this.handleResetPasswordModal,
          },
        ]}
      >
        <Modal.Section>
          { resetSendLinkSuccess ? (
            <Banner tone="success" title="Instructions sent!">
              <p>Instructions for resetting your password have been sent.</p>
            </Banner>
          ) : (
            <FormLayout>
              <p>
                Enter the email address you used for registration, we will send a reset link to your email to verify your identity.
              </p>
              <TextField
                id="ResetEmail"
                type="email" 
                label="E-mail"
                onChange={this.handleInputChange} 
                value={ResetEmail}
                error={resetPasswordError}
              />
              <p>
                After receiving the email, please follow the instructions to reset the password.
              </p>
            </FormLayout>
          )}
          <div className="space"></div>
        </Modal.Section>
      </Modal>
    )

    // 登录后，如果有店铺数组大于0，则选择再进入页面 / 未登录时，显示为登录页面(注册)
    const pageContent = (storesOptions.length > 0) ? (
      <div className="auth_box">
        <h1 className="auth_title">Shopify store selection</h1>
        <div className="space"></div>
        <FormLayout>
          <Select
            label="Shopify store selection"
            labelHidden
            placeholder="Select a store"
            options={storesOptions}
            onChange={this.handleSelectChange}
            value={selectStoreName}
            error={noSelectError}
          />

          <div style={{paddingTop:'20px'}}>
            <BlockStack>
              <Button variant='primary' onClick={this.enterDropshipmanApp}>Enter Dropshipman</Button>
              <Button url="/login">Log out</Button>
            </BlockStack>
          </div>
        </FormLayout>
      </div>
    ) : (
      <div className="auth_box">
        <FormLayout>
          <h1 className="auth_title">{ isLoginPage ? 'Log in to Dropshipman' : 'Join in Dropshipman'}</h1>
          
          {RequestErrorMSG}
          {ResetPasswordMSG}
          
          <TextField
            id="Email"
            type="email" 
            label="Email"
            onChange={this.handleInputChange} 
            value={Email}
            error={emailError}
          />
          <TextField
            id="Password"
            type={hidePassword ? "password" : "text"}
            label="Password"
            onChange={this.handleInputChange}
            value={Password}
            minLength={8}
            maxLength={24}
            suffix={
              <div className="auth_password" onClick={this.handleDisplaySwitch}>
                <Icon source={hidePassword ? ViewIcon : EyeCheckMarkIcon} />
              </div>
            }
            error={passwordError}
          />

          <div className="auth_button">
            <Button 
              fullWidth
              size="large"
              variant='primary'
              onClick={this.AuthUserRequest}
              loading={requestLoading}
            >
              {isLoginPage ? 'Log in' : 'Join Dropshipman'}
            </Button>
          </div>
            
        </FormLayout>

        <div className="space"></div>

        { isLoginPage ? (
          <div className="auth_help">
            <div className="auth_text">
              <Button onClick={this.handleResetPasswordModal} variant='plain'>Forgot Password?</Button>
            </div>
            <div>
              {'Do not have an account? '}<a href="/register" >Sign up</a>
            </div>
          </div>
        ) : (
          <div className="auth_help">
            <div className="auth_text">
              {'By joining Dropshipman,  you agree to our '}
              <a href="https://www.dropshipman.com/policies/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of service</a>
              {' and '}
              <a href="https://www.dropshipman.com/blog/DropShipMan-Privacy-Policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </div>
            <div>
              {'Already have an account? '}<a href="/login" >Log in</a>
            </div>
          </div>
        )}

      </div>
    )

    return (
      <AppProvider i18n={translations}>
        <Frame>
          <section className="dropshipman_auth_main">
            <a className="auth_log" href="https://www.dropshipman.com/" target="_blank" rel="noopener noreferrer">
              <img src="/logo.png?v=123" alt="dropshipman logo" />
            </a>

            {pageContent}
          </section>

          {toastMarkup}
          {/* 重置密码弹窗 */}
          {ResetPasswordModal}
        </Frame>
      </AppProvider>
    );
  }
}

export default Authentication;
