import {
  Card,
  Page,
  Layout,
  BlockStack,
  Toast,
  PageActions,
  Modal,
  Box,
  Icon,
} from "@shopify/polaris";
import { ProductCostIcon,
  AlertBubbleIcon } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect, useRef } from "react";
import { store } from "../../../../../store";
import { setHasReturnedOnce, setInitialPath } from "../../../../../slice";
import { useSelector } from "react-redux";
import utils from "../../../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ProductVariant from "./ProductVariant";
// import ProductShippingInfo from "./ProductShippingInfo";
import ProductOrganization from "./ProductOrganization";
import ProductInfo from "./ProductInfo";
import NewPersonProductModal from "../../common/NewPersonProductModal";
import "./index.css";
import {
  reqGetProductManagementProductsAttributes,
  reqGetProductManagementProductsFreight,
  reqGetProductManagementProductsVariants,
  reqGetUserPoints,
  reqPostProductManagementProductsUploadShopify,
  reqPostProductManagementVariantsSave,
} from "../../../../../api";
import { Fullscreen } from "@shopify/app-bridge/actions";
import AppBridge from "../../../../../utils/appBridge";
import RemoveProductModal from "../../modal/RemoveProductModal";
import ErrorIdPage from "./ErrorIdPage";
import DetailSheletonPage from "./DetailSheletonPage";

// 一个简单的 diff 函数，用于比较两个对象，返回发生变化的键及变化类型
// 简单的深度比较函数：仅适用于不包含函数和循环引用的对象
const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * 对数组进行 diff，仅返回新数组中有变化或新增的项。
 * 如果提供 idField，则使用 idField 对象进行匹配比较。
 * 对于 variants 数组，忽略 shipping_fee 字段的差异。
 */
function diffArray(oldArr = [], newArr = [], idField) {
  const diffs = [];
  if (idField) {
    // 建立旧数组的映射
    const oldMap = new Map(oldArr.map((item) => [item[idField], item]));
    newArr.forEach((newItem) => {
      const oldItem = oldMap.get(newItem[idField]);
      // 如果是 variants 数组则忽略 shipping_fee 字段的差异
      if (oldItem) {
        const oldCompared = cloneWithoutShippingFee(oldItem);
        const newCompared = cloneWithoutShippingFee(newItem);
        if (!isEqual(oldCompared, newCompared)) {
          diffs.push(newItem);
        }
      } else {
        // 新增项
        diffs.push(newItem);
      }
    });
  } else {
    // 如果数组中的是原始数据或不需要 id 比较，按顺序比较
    newArr.forEach((newItem, index) => {
      if (!isEqual(oldArr[index], newItem)) {
        diffs.push(newItem);
      }
    });
  }
  return diffs;
}
/**
 * 返回一个克隆对象，该对象不包含指定的字段（这里是 shipping_fee ）
 */
function cloneWithoutShippingFee(variant) {
  const { shipping_fee, ...rest } = variant;
  return rest;
}
/**
 * 获取自定义 diff 对象，只记录下列字段的变化：
 * 1. product: { title, all_images, collections, options, product_type, tags }
 * 2. shipping
 * 3. variants
 *
 * 对于数组字段（all_images, collections, tags, variants），只返回变化的子项（或新增项）。
 * 对于 options，对象内逐项比较。
 */
const getCustomDiff = (oldData, newData) => {
  const diff = {};

  // --- product 部分 ---
  const productDiff = {};

  // 1. title
  if (!isEqual(oldData.product.title, newData.product.title)) {
    productDiff.title = newData.product.title;
  }

  // description
  if (!isEqual(oldData.product.description, newData.product.description)) {
    productDiff.description = newData.product.description;
  }
  // 2. all_images（数组，根据 id 比较）
  if (!isEqual(oldData.product.all_images, newData.product.all_images)) {
    productDiff.all_images = newData.product.all_images;
    // const changedAllImages = diffArray(
    //   oldData.product.all_images,
    //   newData.product.all_images,
    //   "id"
    // );
    // if (changedAllImages.length > 0) {
    //   productDiff.all_images = changedAllImages;
    // }
  }

  // 3. collections（假设为数组）
  if (!isEqual(oldData.product.collections, newData.product.collections)) {
    const changedCollections = diffArray(
      oldData.product.collections,
      newData.product.collections
    );
    if (changedCollections.length > 0) {
      productDiff.collections = changedCollections;
    }
  }

  // 4. options（对象，逐项比较）
  const optionsDiff = {};
  for (const key in oldData.product.options) {
    if (!isEqual(oldData.product.options[key], newData.product.options[key])) {
      optionsDiff[key] = newData.product.options[key];
    }
  }
  // 如果 newData 里有 options 中新增的 key，也处理
  for (const key in newData.product.options) {
    if (!(key in oldData.product.options)) {
      optionsDiff[key] = newData.product.options[key];
    }
  }
  if (Object.keys(optionsDiff).length > 0) {
    productDiff.options = optionsDiff;
  }

  // 5. product_type（标量或数组，如果为数组，按 diffArray 处理；这里假设为标量）
  if (!isEqual(oldData.product.product_type, newData.product.product_type)) {
    productDiff.product_type = newData.product.product_type
      ? newData.product.product_type[0]
      : "";
  }

  // 6. tags（假设为数组）
  if (!isEqual(oldData.product.tags, newData.product.tags)) {
    productDiff.tag = newData.product.tags;
  }

  if (Object.keys(productDiff).length > 0) {
    diff.product = productDiff;
  }

  // --- shipping ---
  if (!isEqual(oldData.shipping, newData.shipping)) {
    diff.shipping = newData.shipping;
  }

  // --- variants ---
  const changedVariants = diffArray(oldData.variants, newData.variants, "id");
  if (changedVariants.length > 0) {
    diff.variants = changedVariants;
  }
  // 新增：检测被删除的 variants（del_variant）
  const oldVariantIds = oldData.variants.map(v => v.id);
  const newVariantIds = newData.variants.map(v => v.id);
  const delVariantIds = oldVariantIds.filter(id => !newVariantIds.includes(id));
  if (delVariantIds.length > 0) {
    diff.del_variant = delVariantIds;  // 将删除的 ID 数组存入 diff
  }

  return diff;
};

const Index = () => {
  const router = useHistory();

  const [initPage, setInitPage] = useState(true); // 初始化页面
  const [detailData, setDetailData] = useState({}); // 详情页数据
  const [listData, setListData] = useState({ list: [] });
  const initialPath = useSelector((state) => state.languageReducer.initialPath);
  const [leaveModal, setLeaveModal] = useState(false); // 离开确认弹窗
  const [isInput, setIsInput] = useState(false); // 记录是否编辑页面
  const [errorPage, setErrorPage] = useState(false);
  // 积分剩余
  const [userPoints, setUserPoints] = useState({
    userPointsData: {
      available_points: 0,
      total_points: 0,
    },
    userPointsRedeem: {
      open: false,
      id: 0,
      isDetail: false,
      is_new: 0,
      isPoints: false,
    },
  });
  const [shippingMethodData, setShippingMethodData] = useState([]);
      // 获取物流运输方式
      const getShippingMethod = async (value,record_id,send_goods_country,currencyCode,select_service_name) => {
        try {
          let params = {
            record_id:record_id,
            ship_to_country:value,
            send_to_country:send_goods_country
          };
          let res = await reqGetProductManagementProductsFreight(params);
          if (res.code === "200") {
            let ship_list = res.data ? currencyCode === 'USD' ? res.data.filter(item=>item.selected)[0]?.BeforeConvertCost : res.data.filter(item=>item.selected)[0]?.Cost : '';
            let ship_service = res.data ? res.data.filter(item=>item.selected)[0]?.service_name : '';
           if (select_service_name === '') {
            setDetailData((prevValue) => {
              let list = prevValue.variants.map(item=>{
                return {
                  ...item,
                  shipping_fee: ship_list
                }
              });
              return {
                ...prevValue,
                shipping:{...prevValue.shipping,service_name:ship_service,shipping_to_country:value,shipping_fee:ship_list},
                variants:list
              }
            });
            }
           }
           setShippingMethodData(res.data);
        } catch (error) {}
      };
  // const hasReturnedOnce = useSelector((state) => state.languageReducer.hasReturnedOnce);
  const [attributeData, setAttributeData] = useState({}); // 获取属性值、标签
  const getAttributeList = async () => {
    try {
      let res = await reqGetProductManagementProductsAttributes();
      if (res.code === "200") {
        setAttributeData(res.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    // 如果没有记录初始路径，存储来源路径
    if (!initialPath) {
      store.dispatch(
        setInitialPath("/admin/product_management?selectedView=0")
      );
    }
  }, [initialPath]);
  // useRef 保存上一次的 mockData 状态
  const prevMockDataRef = useRef(null);
  const getPageData = async () => {
    setInitPage(true);
    setIsInput(false);
    const { pathname } = router.location;
    let productId = extractId(pathname);
    try {
      let res = await reqGetProductManagementProductsVariants({
        record_id: productId,
      });
      if (res.code === "200") {
        setDetailData((prev) => ({
          ...prev,
          ...res.data,
          original_description:res.data.product.description,
          record_id: productId,
        }));
        prevMockDataRef.current = {
          ...res.data,
          record_id: productId,
        };
        setTimeout(() => {
          let shipping_to_country = res.data.shipping.shipping_to_country ? res.data.shipping.shipping_to_country : 'US'
          getShippingMethod(shipping_to_country,productId,res.data.shipping.send_goods_country,res.data.store.currencyCode,res.data.shipping.select_service_name)
        }, 10);
        setInitPage(false);
        setErrorPage(false);
      } else {
        setErrorPage(true);
      }
    } catch (error) {}
  };

  const extractId = (url) => {
    const match = url.match(/product\/(\d+)/);
    return match ? match[1] : null;
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getPageData();
      getUserPoints(); // 获取用户积分
      getAttributeList();
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 记录变化的 diff 对象
  const [changeLog, setChangeLog] = useState({});

  // 监听 mockData 变化，计算 diff
  useEffect(() => {
    // 计算整个对象的 diff，如果只关注特定部分，可以自行指定
    let isMount = false;
    if (!isMount && !initPage && Object.values(detailData).length > 0) {
      const diffResult = getCustomDiff(prevMockDataRef.current, detailData);
      if (Object.keys(diffResult).length > 0) {
        setChangeLog(diffResult);
        // setIsInput(true)
      }
    }
    return () => {
      isMount = true;
    };
  }, [detailData, initPage]);

  
  const closeFullscreen = () => {
    if (isInput) {
      setLeaveModal(!leaveModal);
    } else {
      if (AppBridge.exist()) {
        utils.routeListen(initialPath);
        utils.routeJump1(router, initialPath);
        AppBridge.SetFullscreen(false);
      }
    }
  };
  useEffect(() => {
    // 挂载时：如果 AppBridge 存在，则开启全屏
    if (AppBridge.exist()) {
      AppBridge.SetFullscreen(true);
      const app = window.DSMAppAuth;
      if (app) {
        // 订阅全屏退出事件，并获得 unsubscribe 方法
        const unsubscribe = app.subscribe(Fullscreen.Action.EXIT, () => {
          if (isInput) {
            setLeaveModal(!leaveModal);
            if (AppBridge.exist()) {
              AppBridge.SetFullscreen(true);
            }
          } else {
            utils.routeListen(initialPath);
            utils.routeJump1(router, initialPath);
            AppBridge.SetFullscreen(false);
          }
        });
        // 卸载时取消订阅
        return () => {
          unsubscribe();
        };
      }
    }
  }, [isInput, leaveModal, setLeaveModal, router, initialPath]);
  const handleGoBack = () => {
    if (isInput) {
      setLeaveModal(!leaveModal);
      return;
    }
    // 已返回过一次，直接跳转到初始路径
    store.dispatch(setHasReturnedOnce(false));
    utils.routeListen(initialPath);
    utils.routeJump1(router, initialPath);
  };

  // 产品上传店铺
  const [productStoreData, setProductStoreData] = useState({
    open: false,
    type: 0,
    toStore: [],
  });
  // 保存编辑
  const paramsFun = () => {
    let variantData = [];
    if (changeLog?.variants) {
      for (let i = 0; i < changeLog.variants.length; i++) {
        variantData.push({
          id: changeLog.variants[i].id,
          price: changeLog.variants[i].price,
          option_one: changeLog.variants[i].option_one,
          option_two: changeLog.variants[i].option_two,
          option_three: changeLog.variants[i].option_three,
          compared_price: changeLog.variants[i].compared_price,
        });
      }
    }
    let optionData = {
      option_one_name: "",
      option_two_name: "",
      option_three_name: "",
    };
    if (detailData.product.options) {
      let list = detailData.product.options;
      if (list.option_one.name !== "") {
        optionData.option_one_name = list.option_one.name;
      }
      if (list.option_two.name !== "") {
        optionData.option_two_name = list.option_two.name;
      }
      if (list.option_three.name !== "") {
        optionData.option_three_name = list.option_three.name;
      }
    }
    let images = [];
    if (detailData.product.all_images) {
      images = detailData.product.all_images;
      // for (let i = 0; i < image_list.length; i++) {
      //   if (image_list[i].selected) {
      //     images.push(image_list[i]);
      //   }
      // }
    }
    let shipping = {
      service_name: detailData.shipping?.service_name ? detailData.shipping.service_name : "",
      shipping_to_country: detailData.shipping.shipping_to_country,
      shipping_fee: detailData.shipping?.shipping_fee ? detailData.shipping.shipping_fee : "",
    };
    let params = {
      record_id: [Number(detailData.record_id)],
      information: {
        ...changeLog.product,
        description: detailData.product.description,
        shipping,
        option: optionData,
      },
    };
    if (variantData.length > 0) {
      params.information.variants = variantData;
    }
    if (images.length > 0) {
      params.information.images = images;
    }
    if (detailData?.delTag) {
      params.information.del_tag = detailData.delTag;
    }
    if (detailData?.delType) {
      params.information.del_type = detailData.delType;
    }
    if (changeLog?.del_variant?.length > 0) {
      params.information.del_variant = changeLog.del_variant;
    }
    return params;
  };
  // 上架产品
  const [listLoading, setProductLoading] = useState(false);
  const handleListSubmit = async () => {
    let flag = false;
    let opt = detailData.product.options;
    let variants = detailData.variants; // 判断变体是否为空
    if (opt.option_one.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_one === "") {
          flag = true;
        }
      }
    }
    if (opt.option_two.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_two === "") {
          flag = true;
        }
      }
    }
    if (opt.option_three.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_three === "") {
          flag = true;
        }
      }
    }
    if (flag) {
      toastFun("Option is empty");
      return;
    }
    if (variants.length ===0) {
      return
    }
    if (listLoading) return;
    setProductLoading(true);
    try {
      let params1 = paramsFun();

      const response = await reqPostProductManagementVariantsSave(params1);
      if (response.code === "200") {
        let params2 = {
          type: 0,
          ids: JSON.stringify([Number(detailData.record_id)]),
          auto_publish: detailData.setting.auto_publish ? 1 : 0,
          is_inventory: detailData.setting.auto_inventory ? 1 : 0,
          upload_reminder: detailData.setting.upload_reminder ? 1 : 0,
        };
        
        let res = await reqPostProductManagementProductsUploadShopify(params2);
        if (res.code === "200") {
          toastFun("Saved Successfully");
          utils.routeListen(initialPath);
          utils.routeJump1(router, initialPath);
          if (AppBridge.exist()) {
            AppBridge.SetFullscreen(false);
          }
        }
      }
    } catch (error) {}
  };
  const handleListSubmitDetail = async (auto_publish,auto_inventory,upload_reminder) => {
    let flag = false;
    let opt = detailData.product.options;
    let variants = detailData.variants; // 判断变体是否为空
    if (opt.option_one.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_one === "") {
          flag = true;
        }
      }
    }
    if (opt.option_two.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_two === "") {
          flag = true;
        }
      }
    }
    if (opt.option_three.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_three === "") {
          flag = true;
        }
      }
    }
    if (flag) {
      toastFun("Option is empty");
      return;
    }
    if (variants.length ===0) {
      return
    }
    if (listLoading) return;
    setProductLoading(true);
    try {
      let params1 = paramsFun();

      const response = await reqPostProductManagementVariantsSave(params1);
      if (response.code === "200") {
        let params2 = {
          type: 0,
          ids: JSON.stringify([Number(detailData.record_id)]),
          auto_publish: auto_publish ? 1 : 0,
          is_inventory: auto_inventory ? 1 : 0,
          upload_reminder: upload_reminder ? 1 : 0,
        };
        
        let res = await reqPostProductManagementProductsUploadShopify(params2);
        if (res.code === "200") {
          toastFun("Saved Successfully");
          utils.routeListen(initialPath);
          utils.routeJump1(router, initialPath);
          if (AppBridge.exist()) {
            AppBridge.SetFullscreen(false);
          }
        }
      }
    } catch (error) {}
  };

  // 获取积分
  const getUserPoints = async () => {
    try {
      let res = await reqGetUserPoints();
      if (res.code === "200") {
        setUserPoints((prevValue) => ({
          ...prevValue,
          userPointsData: res.data,
        }));
      }
    } catch (error) {}
  };
  // 保存编辑后的产品数据
  const [detailSaveLoading, setDetailSaveLoading] = useState(false);
  const handleSave = async () => {
    let params = paramsFun();

    let flag = false;
    let opt = detailData.product.options;
    let variants = detailData.variants; // 判断变体是否为空
    if (opt.option_one.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_one === "") {
          flag = true;
        }
      }
    }
    if (opt.option_two.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_two === "") {
          flag = true;
        }
      }
    }
    if (opt.option_three.name !== "") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].option_three === "") {
          flag = true;
        }
      }
    }
    if (flag) {
      toastFun("Option is empty");
      return;
    }
    if (variants.length ===0) {
      return
    }
    if (detailSaveLoading) return;
    setDetailSaveLoading(true);
    try {
      const res = await reqPostProductManagementVariantsSave(params);
      if (res.code === "200") {
        toastFun("Saved Successfully");
        setTimeout(() => {
          utils.routeListen(initialPath);
          utils.routeJump1(router, initialPath);
          if (AppBridge.exist()) {
            AppBridge.SetFullscreen(false);
          }
        }, 1000);
      }
      setDetailSaveLoading(false);
    } catch (error) {}
  };

  const handleCloseLeaveModal = () => {
    closeFullscreen();
  };
  const handleSaveLeaveModal = () => {
    utils.routeListen(initialPath);
    utils.routeJump1(router, initialPath);
    if (AppBridge.exist()) {
      AppBridge.SetFullscreen(false);
    }
  };

  //  删除产品
  const [removeProduct, setRemoveProduct] = useState({
    openKey: "",
    open: false,
    import_status: 0,
    type: false,
    modal: false,
    action: false,
    id: "",
  });

  const [toast, setToast] = useState(false); // 提示
  const [toastError, setToastError] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  const toggleToast = useCallback(() => setToast((toast) => !toast), []);

  const toastMarkup = toast ? (
    <Toast content={toastMsg} onDismiss={toggleToast} error={toastError} />
  ) : null;

  const toastFun = (msg, error) => {
    setToastError(error);
    setToastMsg(msg);
    setToast(false);
    toggleToast();
  };
  const sourceFrom = (val) => {
    let source = "Dropshipman";
    if (val === 1) {
      source = "AliExpress";
    }
    if (val === 2) {
      source = "Dropshipman";
    }
    if (val === 3) {
      source = "Woocommerce";
    }
    if (val === 4) {
      source = "Temu";
    }
    if (val === 5) {
      source = "Dropshipman";
    }
    return source;
  };
    // 创建目标元素的 ref
    const targetRef = useRef(null);

    // 滚动到指定元素
    const scrollToElement = () => {
      targetRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start' // 'start', 'center', 'end' 或 'nearest'
      });
    };
  
    // 滚动到顶部
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

  const rightRef = useRef(null);
  const [position, setPosition] = useState({ left: 1569, top: 246, width: 0 });
  
  useEffect(() => {
    const updatePosition = () => {
      if (rightRef.current && !initPage) {
        const rect = rightRef.current.getBoundingClientRect();
        setPosition({
          left: rect.left,
          top: rect.top,
          width: rect.width + 20, // Ensure this adjustment is necessary
        });
      }
    };
  
    // Initial update
    updatePosition();
  
    // Handle window resize
    window.addEventListener('resize', updatePosition);
  
    // Optional: Use ResizeObserver for element-specific resizes
    let resizeObserver;
    if (rightRef.current && !initPage) {
      resizeObserver = new ResizeObserver(updatePosition);
      resizeObserver.observe(rightRef.current);
    }
  
    return () => {
      window.removeEventListener('resize', updatePosition);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [initPage]); // Ensure rightRef.current is stable or consider callback ref
  
  const pageConent = (
    <Page
      backAction={
        AppBridge.exist()
          ? undefined
          : { content: "Products", onAction: handleGoBack }
      }
      title={
        <Box
          onClick={(e) => {
            e.stopPropagation();
            if (detailData.product?.original_url !== "") {
              if (
                detailData.product?.product_source_from === 2 ||
                detailData.product.product_source_from === 5
              ) {
                // utils.routeListen(
                //   "/admin/product_management/product/" + item.id
                // );
                // utils.routeJump1(
                //   router,
                //   "/admin/product_management/product/" + item.id
                // );
                // store.dispatch(
                //   setInitialPath(
                //     currentPath || "/admin/product_management?selectedView=0"
                //   )
                // );
                if (AppBridge.exist()) {
                  AppBridge.SetFullscreen(false);
                }
                utils.routeListen(
                  `/admin/product_selection/product-info?filter=${
                    detailData.product?.original_id
                  }&tag=home&category=&management=list&source=${
                    detailData.product?.so_id ? "list" : ""
                  }&favorite=0`
                );
                utils.routeJump1(
                  router,
                  `/admin/product_selection/product-info?filter=${
                    detailData.product?.original_id
                  }&tag=home&category=&management=list&source=${
                    detailData.product?.so_id ? "list" : ""
                  }&favorite=0`
                );
                store.dispatch(
                  setInitialPath( `/admin/product_selection/product-info?filter=${
                    detailData.product?.original_id
                  }&tag=home&category=&management=list&source=${
                    detailData.product?.so_id ? "list" : ""
                  }&favorite=0`)
                );
                return;
              }
              window.open(detailData.product.original_url, "_blank");
            }
          }}
          className={"detail_hover_link"}
        >
          {detailData.product?.title}
        </Box>
      }
      secondaryActions={[
        {
        content: "Save",
        disabled: listLoading || detailSaveLoading,
        onAction: () => handleSave(),
        loading: detailSaveLoading,
        }
      ]}
      subtitle={
        formatTimestamp(detailData.product?.create_time) +
        ". Import from " +
        sourceFrom(detailData.product?.product_source_from)
      }
      // compactTitle
      actionGroups={
        detailData.record_id === "" || detailData?.product?.is_publish
          ? []
          : [
              {
                title: "More actions",
                actions: [
                  {
                    content: "Remove Product",
                    accessibilityLabel: "Individual action label",
                    onAction: () => {
                      setRemoveProduct((prevValue) => ({
                        ...prevValue,
                        id: [Number(detailData.record_id)],
                        import_status: 1,
                        type: 0,
                        modal: true,
                      }));
                    },
                  },
                ],
              },
            ]
      }
      pagination={{
        hasPrevious: detailData.product?.back_id !== "",
        onNext: () => {
          utils.routeListen(
            `/admin/product_management/product/${detailData.product?.next_id}`
          );
          utils.routeJump1(
            router,
            `/admin/product_management/product/${detailData.product?.next_id}`
          );
        },
        onPrevious: () => {
          utils.routeListen(
            `/admin/product_management/product/${detailData.product?.back_id}`
          );
          utils.routeJump1(
            router,
            `/admin/product_management/product/${detailData.product?.back_id}`
          );
        },
        // previousTooltip: "(J)",
        // nextTooltip: "(K)",
        hasNext: detailData.product?.next_id !== "",
      }}
    >
      {Object.values(detailData).length > 0 ? (
        <Layout>
          <Box className="Polaris-Layout__Section" ref={rightRef}>
            <BlockStack gap={400}>
              <Card padding={0}>
                <ProductVariant
                  setIsInput={setIsInput}
                  detailData={detailData}
                  setDetailData={setDetailData}
                  shippingMethodData={shippingMethodData}
                  setShippingMethodData={setShippingMethodData}
                  toastFun={toastFun}
                />
              </Card>
            </BlockStack>
            {detailData?.variants && detailData?.variants?.length >= 18 ? <div className="scroll_button" style={{left: `${position.left + position.width}px`,
            top: `${position.top}px`,}}>
                    <div onClick={scrollToTop} className="scroll_button_variant">
                      <div className="scroll_button_variant_tips">
                        Jump to Price & Variants
                      </div>
                    <Icon source={ProductCostIcon} tone='base' />
                    </div>
                    <div onClick={scrollToElement} className="scroll_button_Information">
                    <div className="scroll_button_variant_tips">
                    Jump to Product Information
                      </div>
                    <Icon source={AlertBubbleIcon} tone='base' />
                    </div>
                  </div>:''}
          </Box>
          
          <Layout.Section>
            <Box ref={targetRef}>
            <Card>
              <ProductInfo
               toastFun={toastFun}
                detailData={detailData}
                userPoints={userPoints}
                setIsInput={setIsInput}
                setDetailData={setDetailData}
              />
            </Card>
            </Box>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <BlockStack gap={400}>
              {/* <Card>
                <ProductShippingInfo
                  setIsInput={setIsInput}
                  detailData={detailData}
                  setDetailData={setDetailData}
                />
              </Card> */}
              <Card>
                <ProductOrganization
                  setIsInput={setIsInput}
                  detailData={detailData}
                  setDetailData={setDetailData}
                  attributeData={attributeData}
                  setAttributeData={setAttributeData}
                  toastFun={toastFun}
                />
              </Card>
            </BlockStack>
          </Layout.Section>
          
        </Layout>
      ) : (
        ""
      )}
      {detailData?.product?.is_publish === 0 ? (
        <PageActions
          primaryAction={{
            content: "Save",
            disabled: listLoading || detailSaveLoading,
            onAction: () => handleSave(),
            loading: detailSaveLoading,
          }}
          secondaryActions={[
            {
              content: "List Product",
              disabled:
                detailData?.product?.product_status !== 1 ||
                listLoading ||
                detailSaveLoading,
              onAction: () => {
                if (detailData.setting.upload_reminder) {
                  handleListSubmit();
                } else {
                  setProductStoreData((prev) => ({
                    ...prev,
                    toStore: [Number(detailData.record_id)],
                    open: true,
                    type: 0,
                  }));
                }
              },
              loading: listLoading,
            },
          ]}
        />
      ) : (
        ""
      )}
      {/* 产品上架提示、 */}
      <NewPersonProductModal
        restartPolling={() => {}}
        listData={listData}
        detail={true}
        handleListSubmitDetail={handleListSubmitDetail}
        setListData={setListData}
        productStoreData={productStoreData}
        setProductStoreData={setProductStoreData}
      />
      {/* 产品移除 */}
      <RemoveProductModal
        toastFun={toastFun}
        removeProduct={removeProduct}
        setRemoveProduct={setRemoveProduct}
        import_status={0}
        callBack={() => {
          utils.routeListen(initialPath);
          utils.routeJump1(router, initialPath);
          if (AppBridge.exist()) {
            AppBridge.SetFullscreen(false);
          }
        }}
        handleSelectionChange={() => {}}
        detail={true}
      />
    </Page>
  );
  const leaveEditModal = (
    <Modal
      title={"You have unsaved changes"}
      open={leaveModal}
      onClose={handleCloseLeaveModal}
      primaryAction={{
        content: "Leave edit",
        destructive: true,
        onAction: () => handleSaveLeaveModal(),
      }}
      secondaryActions={[
        { content: "Cancel", onAction: () => handleCloseLeaveModal() },
      ]}
    >
      <Modal.Section>
        <div>If you leave this page, all unsaved changes will be lost.</div>
      </Modal.Section>
    </Modal>
  );
  return (
    <div
      className={
        AppBridge.exist()
          ? "product_management_page product_management_page_fullscreen"
          : "product_management_page"
      }
    >
      {initPage ? (
        errorPage ? (
          <ErrorIdPage />
        ) : (
          <DetailSheletonPage />
        )
      ) : (
        pageConent
      )}
      {leaveEditModal}
      {toastMarkup}
      
    </div>
  );
};
function formatTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const year = date.getUTCFullYear();
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  let hours = date.getUTCHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // 转换为12小时制，0转为12
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  return `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
}

export default Index;
