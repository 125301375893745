import React, { useState, memo } from "react";
import { Modal, Toast } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";
const OrderNoteModal = memo(
  ({
    paymentData,
    walletMoney,
    handlePayForOrderModal,
    handleWalletActive,
  }) => {
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };
    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
      />
    ) : null;
    return (
      <div style={{ margin: "0 0" }}>
        {toastMarkup}
        <Modal
          open={paymentData.toWalletActive}
          onClose={() => handlePayForOrderModal(0, 0, "", 0, false, 2)}
          title={intl.get("Order") + ": " + paymentData.toPayOrderName}
          primaryAction={{
            content: intl.get("Yes"),
            onAction: () => handleWalletActive(),
            loading: paymentData.handleWalletActiveLoading,
          }}
          loading={paymentData.toCheckoutLoading}
        >
          <Modal.Section>
            <p>
              {intl.get("Current credit balance")}{" "}
              {"$" + parseFloat(walletMoney) + " USD"},{" "}
              {intl.get("order total price")}{" "}
              {"$" +
                (
                  parseFloat(paymentData.totalPriceWallet) -
                  parseFloat(paymentData.toPayCouponMoney)
                ).toFixed(2) +
                " USD"}
              . {intl.get("Are you sure to pay by credit balance?")}
            </p>
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default OrderNoteModal;
