import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Page, Card, Layout,  SkeletonBodyText, FormLayout, TextField, BlockStack, Select, Checkbox, Icon, Banner, Tooltip, Toast,
  Collapsible, Link, InlineStack, Box, Button,
  // Modal
} from "@shopify/polaris";
import utils from '../../../../utils/utils'
import {
  // MobilePlusMajor,XSmallIcon,
  QuestionCircleIcon, WalletIcon
} from '@shopify/polaris-icons';
// import Switch from "react-switch";
import CostRangeRows from "./components/CostRangeRows";
import ApplyRules from "./components/ApplyRules";
import NavTabs from "../components/NavTabs";

import { reqGetUserSettings, reqPostUpdateUserPriceSettings } from '../../../../api';

import './index.css'

import intl from '../../../../i18n/intl'
import SettingPrompt from '../../../../components/SettingPrompt';
import UpgradePlanModal from '../../SubscriptionPaid/components/UpgradePlanModal';
import { store } from '../../../../store';
import AppBridge from '../../../../utils/appBridge';
import { changeBannerData } from '../../../../slice';
import { connect } from 'react-redux';
import LevelAction from '../../../../components/LevelAction';
class PricingRulesClass extends Component {

  state = {
    PriceFactor: '1',
    PriceFactorMark: '1',
    ComparedPriceOn: false,
    ComparedPriceFactor: '1',
    ComparedPriceFactorMark: '1',

    ClosedCustomPriceRule: false,
    AdvancedPriceRuleOn: false,

    // PriceAddShippingFee: false,
    // ComparedPriceAddShippingFee: false,
    // AdvancedPriceAllAddShippingFee: false,

    // cost range data
    AdvancedTableData: [],
    // cost range table tr
    AdvancedTableTrRow: <tr></tr>,

    // Rest of the price ranges
    RestRange_Factor_Value: 2,
    RestRange_Factor_Mark: '1',
    RestRange_ComparedPrice_Factor_Value: '',
    RestRange_ComparedPrice_Factor_Mark: '1',

    // cost range Compared at price Checkbox
    AdvancedComparedPriceChecked: true,

    // cost range error
    CostRangeError: false,
    CostRangeErrorMsg: '',

    AssignCentsChecked: false,
    AssignCents: '',
    disabledAssignCents: true,

    AssignComparedCentsChecked: false,
    AssignComparedCents: '',
    disabledAssignComparedCents: true,

    //弹窗
    showToast: false,  //弹窗显示
    showToastText: '',  //弹窗文字
    showToastIserror: false, //弹窗是否错误

    loadingPage: true,
    saveDataLoading: false,
    disabledSaveData: true,

    // 价格规则应用所有产品弹窗
    ApplyRulesModal: '',
    disableApplyRules: false,
    showApplyRulesCountBanner: false,
    ApplyRulesCount: 0,  // 修改数量

    isUserFreePlan: true,  // 用户是否是免费套餐
    proPlanTipsActive: false,
    modalShow: false,
    nextUrl: '',
    upgradeModal:false,//升级付费弹窗
  };

  //进入页面调用接口
  componentDidMount() {
    this.getUserSettingsData();
  }
  componentDidUpdate() {
    let mutation;
    let callback;
    let setting = document.querySelector('#settings_class');
    if (setting) {
      callback = (mutations) => {
        mutations.forEach((item, index) => {

          if (item.attributeName === 'aria-checked') {
            this.setState({
              disabledSaveData: false
            })
            return
          } else if (item.type === 'characterData') {
            // this.setState({
            //   disabledSaveData: false
            // })
          }
        })
      }
    }
    const config = {
      attributes: true,
      attributeOldValue: true,
      characterData: true,
      characterDataOldValue: true,
      childList: true,
      subtree: true,
      // attributeFilter:['t']
    }
    mutation = new MutationObserver(callback)
    // 开始检测
    mutation.observe(setting, config)
    // 停止检测
    if (!this.state.disabledSaveData) {
      mutation.disconnect()
    }
  }
  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  getUserSettingsData = async () => {
    const params = { type: 'price' };
    const response = await reqGetUserSettings(params)
    if (response.code === '200') {

      var result = response.data.userSetting;

      this.setState({
        PriceFactor: String(result.price_factor),
        PriceFactorMark: String(result.price_mark),

        ComparedPriceOn: (result.compared_price_on === 1) ? true : false,
        ComparedPriceFactor: result.compared_price_factor,
        ComparedPriceFactorMark: String(result.compared_price_mark),

        AdvancedPriceRuleOn: (result.advanced_price_rule_on === 1) ? true : false,
        ClosedCustomPriceRule: (result.advanced_price_rule_on === 1) ? true : false,
        AdvancedTableData: result.CostRange,

        AdvancedComparedPriceChecked: (result.RestRange.isComparedPriceMarkup === 1) ? true : false,
        RestRange_Factor_Value: result.RestRange.Factor_Value,
        RestRange_Factor_Mark: String(result.RestRange.Factor_Mark),
        RestRange_ComparedPrice_Factor_Value: result.RestRange.ComparedPrice_Factor_Value,
        RestRange_ComparedPrice_Factor_Mark: String(result.RestRange.ComparedPrice_Factor_Mark),

        AssignCentsChecked: (result.assign_cents_on === 1) ? true : false,
        AssignCents: result.assign_cents_value,
        disabledAssignCents: (result.assign_cents_on === 1) ? false : true,

        AssignComparedCentsChecked: (result.assign_compared_cents_on === 1) ? true : false,
        AssignComparedCents: result.assign_compared_cents_value,
        disabledAssignComparedCents: (result.assign_compared_cents_on === 1) ? false : true,

        PriceAddShippingFee: (result.price_fee === 1) ? true : false,
        // ComparedPriceAddShippingFee: (result.compare_price_fee === 1) ? true : false,
        AdvancedPriceAllAddShippingFee: (result.advanced_price_fee === 1) ? true : false,

        loadingPage: false,
        // disabledSaveData: false,

        showApplyRulesCountBanner: (result.applyRulesCount > 0) ? true : false,
        disableApplyRules: (result.applyRulesCount > 0) ? true : false,
        ApplyRulesCount: result.applyRulesCount,
        isUserFreePlan: (result.level > 0) ? false : true
      }, () => {
        // cost range init
        this.showCostRangeRows();
      });

    } else {
      this.toggleToast(response.msg);
    }

  }
 
  // save setting
  saveData = async () => {
    let person = store.getState().languageReducer.subScriptData;
    if ((this.state.AdvancedPriceRuleOn && person?.level < 2) || (this.state.AdvancedPriceRuleOn && person?.level === 4)) {
      this.setState({upgradeModal:true})
      return
    }
    if (!this.state.AdvancedPriceRuleOn && person?.level === 0 && this.state.PriceAddShippingFee) {
      this.setState({upgradeModal:true})
      return
    }

    this.setState({
      saveDataLoading: true
    });
    const params = {
      type: 'price',
      PriceFactor: this.state.PriceFactor,
      PriceFactorMark: this.state.PriceFactorMark,
      ComparedPriceOn: this.state.ComparedPriceOn ? 1 : 0,
      ComparedPriceFactor: this.state.ComparedPriceFactor,
      ComparedPriceFactorMark: this.state.ComparedPriceFactorMark,

      AdvancedPriceRuleOn: this.state.AdvancedPriceRuleOn ? 1 : 0,
      AdvancedTableData: JSON.stringify(this.state.AdvancedTableData),
      AdvancedComparedPriceChecked: this.state.AdvancedComparedPriceChecked ? 1 : 0,

      RestRange_Factor_Value: this.state.RestRange_Factor_Value,
      RestRange_Factor_Mark: this.state.RestRange_Factor_Mark,
      RestRange_ComparedPrice_Factor_Value: this.state.RestRange_ComparedPrice_Factor_Value,
      RestRange_ComparedPrice_Factor_Mark: this.state.RestRange_ComparedPrice_Factor_Mark,

      AssignCentsChecked: this.state.AssignCentsChecked ? 1 : 0,
      AssignCents: this.state.AssignCents,

      AssignComparedCentsChecked: this.state.AssignComparedCentsChecked ? 1 : 0,
      AssignComparedCents: this.state.AssignComparedCents,

      PriceAddShippingFee: this.state.PriceAddShippingFee ? 1 : 0,
      // ComparedPriceAddShippingFee: this.state.ComparedPriceAddShippingFee ? 1 : 0,
      AdvancedPriceAllAddShippingFee: this.state.AdvancedPriceAllAddShippingFee ? 1 : 0,
    };
    const responseData = await reqPostUpdateUserPriceSettings(params);
    if (responseData.code === "200") {
      this.setState({
        disabledSaveData: true
      })
      this.setState({
        saveDataLoading: false,
        disableApplyRules: false,
      });
      this.toggleToast('Saved successfully');
    } else {
      // this.toggleToast(responseData.msg);
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:responseData.msg
      }}));
    }
  };

  // Switch 开关
  SetSwitch = (value) => {
    // const state = this.state;
    // var status = state.AdvancedPriceRuleOn ? false : true;
    var status = value
    this.setState({
      disabledSaveData: false
    })
    this.setState({
      ClosedCustomPriceRule: status,
      disableApplyRules: true,
    }, () => {
      this.setState({ AdvancedPriceRuleOn: status });
    });
  };

  // checkbox change
  handleCheckboxChange = (value, id) => {
    this.setState({
      disabledSaveData: false
    })
    if (id === "AssignCentsChecked") {
      this.setState({
        [id]: value,
        disabledAssignCents: !value,
        disableApplyRules: true,
      });

    } else if (id === "AssignComparedCentsChecked") {
      this.setState({
        [id]: value,
        disabledAssignComparedCents: !value,
        disableApplyRules: true,
      });

    } else if (id === "ComparedPriceOn") {
      this.setState({
        [id]: value,
        disableApplyRules: true,
      });

    } else if (id === 'AdvancedComparedPriceChecked') {
      this.setState({
        [id]: value,
        disableApplyRules: true,
      }, () => {
        // 重新渲染更新 cost range 组件
        this.showCostRangeRows();
      });

    } else if (id === 'PriceAddShippingFee' || id === 'ComparedPriceAddShippingFee' || id === 'AdvancedPriceAllAddShippingFee') {
      // 免费版用户提示，增加运费功能为付费功能
      // 选择添加运费和高级规则的时候不触发开通会员提示
      // if (this.state.isUserFreePlan) {
      //   this.handleCloseTipsModal();
      //   return false;
      // }
      if (id=== 'PriceAddShippingFee') {
        let person = store.getState().languageReducer.subScriptData;
        if ((!this.state.AdvancedPriceRuleOn && person?.level === 0 && !this.state.PriceAddShippingFee) || (!this.state.AdvancedPriceRuleOn && person?.level === 4 && !this.state.PriceAddShippingFee) || (!this.state.AdvancedPriceRuleOn && person?.level === 1 && !this.state.PriceAddShippingFee)) {
          this.setState({upgradeModal:true})
          return
        }else{
          this.setState({
            [id]: value,
            disableApplyRules: true,
          });
        }
      }else {
        this.setState({
          [id]: value,
          disableApplyRules: true,
        });
      }
      

      

    } else {
      this.setState({
        [id]: value,
      });
    }

  }

  // select change
  handleSelectChange = (value, id) => {
    this.setState({
      [id]: value,
      disableApplyRules: true,
    });
  }

  // input change
  handleInputChange = (value, id) => {
    this.setState({
      disabledSaveData: false
    })
    if (id === 'PriceFactor' || id === 'ComparedPriceFactor' || id === 'RestRange_Factor_Value' || id === 'RestRange_ComparedPrice_Factor_Value') {
      value = (value === '' || value === '0') ? 1 : value;
    }
    if (id === 'AssignComparedCents' || id === 'AssignCents') {
      if (Number(value) < 0) {
        value = 0;
      }
      if (Number(value) > 99) {
        value = 99;
      }
    }
    this.setState({
      [id]: value,
      disableApplyRules: true,
    });
  }

  // cost range show 展示内容
  showCostRangeRows = () => {

    var tableTrRow = this.state.AdvancedTableData.map((item, index) => {
      return (
        <CostRangeRows
          key={index}
          recordKey={index}
          Start_Range_Value={item.Start_Range_Value}
          End_Range_Value={item.End_Range_Value}
          Factor_Value={item.Factor_Value}
          Factor_Mark={item.Factor_Mark}
          isComparedPriceMarkup={this.state.AdvancedComparedPriceChecked}
          ComparedPrice_Factor_Value={item.ComparedPrice_Factor_Value}
          ComparedPrice_Factor_Mark={item.ComparedPrice_Factor_Mark}
          isEndRow={item.isEndRow}

          changeCostRangeData={this.changeCostRangeData}
          deleteTableTrRow={this.deleteTableTrRow}
          handleCostRangeError={this.handleCostRangeError}
        />
      );
    });
    this.setState({
      AdvancedTableTrRow: tableTrRow,
    });
  };


  // cost range 子组件内容修改后回调渲染
  changeCostRangeData = (recordData) => {
    // console.info(recordData);
    this.setState({
      disabledSaveData: false
    })
    let newItem = this.state.AdvancedTableData;
    let arrayLength = newItem.length;
    let showError = false;
    let errorMsg = '';
    let isToPush = false;
    let pushItem = {
      'Start_Range_Value': 0,
      'End_Range_Value': '',
      'Factor_Value': 1,
      'Factor_Mark': '1',
      'isComparedPriceMarkup': this.state.AdvancedComparedPriceChecked,
      'ComparedPrice_Factor_Value': '',
      'ComparedPrice_Factor_Mark': '1',
      'isEndRow': 1
    }

    newItem.forEach((item, key) => {
      if (key === recordData.recordKey) {
        newItem[key].Start_Range_Value = Number(recordData.Start_Range_Value);
        newItem[key].End_Range_Value = Number(recordData.End_Range_Value);
        newItem[key].Factor_Value = Number(recordData.Factor_Value);
        newItem[key].Factor_Mark = recordData.Factor_Mark;
        newItem[key].ComparedPrice_Factor_Value = recordData.ComparedPrice_Factor_Value;
        newItem[key].ComparedPrice_Factor_Mark = recordData.ComparedPrice_Factor_Mark;

        if (recordData.isEndRow) {
          isToPush = true;
        }
      }

      // 判断范围值是否正确
      if (arrayLength === key + 1) {  // 最后一行
        if (item.End_Range_Value !== '') {
          if (Number(item.Start_Range_Value) >= Number(item.End_Range_Value)) {
            showError = true;
            errorMsg = intl.get('Cost range end value must be greater than the starting value.');
          }
          pushItem.Start_Range_Value = item.End_Range_Value + 0.01;
        }

      } else {
        if (Number(item.Start_Range_Value) >= Number(item.End_Range_Value)) {
          showError = true;
          errorMsg = intl.get('Cost range end value must be greater than the starting value.');
        }

        if (Number(item.End_Range_Value) >= Number(newItem[key + 1].Start_Range_Value)) {
          showError = true;
          errorMsg = intl.get('Your ranges overlap.');
        }

        if (key !== 0) {
          if (Number(item.Start_Range_Value) <= Number(newItem[key - 1].End_Range_Value)) {
            showError = true;
            errorMsg = intl.get('Your ranges overlap.');
          }
        }

      }

      if (showError) {
        isToPush = false;
      } else {
        if (recordData.isEndRow) {
          isToPush = true;
        }
      }

    });

    // 显示错误信息
    if (showError) {
      this.handleCostRangeError(errorMsg, true);
    } else {
      this.handleCostRangeError('', false);
    }

    // 没有错误的情况下，如果是最后一行，数组追加一行
    if (isToPush) {
      newItem[recordData.recordKey].isEndRow = 0;
      newItem.push(pushItem);
    }

    this.setState({
      AdvancedTableData: newItem,
      disableApplyRules: true,
    }, () => {
      this.showCostRangeRows()
    });

  }


  // 移除范围记录
  deleteTableTrRow = (recordKey) => {
    // 默认数量为2，删除记录相当于关闭
    if (this.state.AdvancedTableData.length === 2) {
      this.setState({
        AdvancedPriceRuleOn: false,
        ClosedCustomPriceRule: false,
        disableApplyRules: true,
      });
    } else {

      let array = this.state.AdvancedTableData;
      array.splice(recordKey, 1);

      this.setState({
        AdvancedTableData: array,
        disableApplyRules: true,
      }, () => {
        this.handleCostRangeError('', false);
        this.showCostRangeRows()
      });
    }
  }

  // cost range error message
  handleCostRangeError = (errorMessage, isTrue) => {
    this.setState({
      CostRangeError: isTrue,
      CostRangeErrorMsg: errorMessage,
    });
  }

  // 价格规则应用到产品数据中
  handleApplyRules = () => {
    let content = <ApplyRules show={true} callBackFun={this.handleCallbackApplyRule} />
    this.setState({
      ApplyRulesModal: content
    });
  }

  // 价格规则应用确认回调（显示需要更新记录数）
  handleCallbackApplyRule = (data) => {
    if (data !== '') {
      this.setState({
        showApplyRulesCountBanner: true,
        disableApplyRules: true,
        ApplyRulesCount: data.count
      });
    }
  }

  // 付费弹窗提示
  handleCloseTipsModal = () => {
    this.setState({
      proPlanTipsActive: !this.state.proPlanTipsActive,
    });
  }

  // 激活聊天弹窗
  handleActiveMessageBox = () => {
    let sendMsg = 'How to set up price rules?';

    try {
      // 填充信息并激活对话弹窗
      // window.Intercom('showNewMessage', sendMsg);
      window.Willdesk.show();
      window.Willdesk.chat(sendMsg);
    } catch (error) {
      console.info(error);
    }
  }
  handleUrlChange = () => {
    if (this.state.nextUrl !== '') {
      this.saveData();
      setTimeout(() => {
        utils.routeListen(this.state.nextUrl)
      }, 200);
    }
  };
  handleUrlClose = () => {
    if (this.state.nextUrl !== '') {
           utils.routeJump(this,this.state.nextUrl)
    }
  }
  handleClose = () => {
    this.setState({
      modalShow: false
    })
  }
  shopifyLevel = ()=>{
    let shopify_level = store.getState().languageReducer.subScriptData.shopify_level;
    let level = store.getState().languageReducer.subScriptData.level;
    let flag = true;
    if ((level !== 2 && level !== 3 && level !== 5) && ( shopify_level === 'Development' || shopify_level === 'Developer Preview' || shopify_level === 'staff' || shopify_level === 'staff_business')) {
      flag = false
    }
    return flag;
  }
  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const { PriceFactor, PriceFactorMark, AssignCentsChecked, AssignCents,
      ClosedCustomPriceRule, AdvancedPriceRuleOn, AdvancedTableTrRow, AdvancedComparedPriceChecked,
      CostRangeError, CostRangeErrorMsg,
      RestRange_Factor_Value, RestRange_Factor_Mark, RestRange_ComparedPrice_Factor_Value, RestRange_ComparedPrice_Factor_Mark,
      ComparedPriceOn, ComparedPriceFactor, ComparedPriceFactorMark,
      disabledAssignCents, AssignComparedCentsChecked, AssignComparedCents, disabledAssignComparedCents,
      saveDataLoading, disabledSaveData, ApplyRulesModal,
      PriceAddShippingFee, AdvancedPriceAllAddShippingFee 
      // ComparedPriceAddShippingFee, AdvancedPriceAllAddShippingFee,proPlanTipsActive
    } = this.state;
    let language = store.getState().languageReducer.language;
    var PriceFactorSign = '';
    var PriceFactorTooltip = '';
    var PriceFactorExampleResult = '';

    var ComparedPriceFactorSign = '';
    var ComparedPriceFactorTooltip = '';
    var ComparedPriceExampleResult = '';

    if (PriceFactorMark === '1') {
      PriceFactorSign = 'x';
      PriceFactorExampleResult = parseFloat(10 * Number(PriceFactor)).toFixed(2);
      PriceFactorTooltip = intl.get("E.g., A product that costs 10 USD would have its price set to 20 USD (10 x 2 = 20).")
    } else {
      PriceFactorSign = '+';
      PriceFactorExampleResult = parseFloat(10 + Number(PriceFactor)).toFixed(2);
      PriceFactorTooltip = intl.get("E.g., A product that costs 10 USD would have its price set to 12 USD (10 + 2 = 12).")
    }

    if (ComparedPriceFactorMark === '1') {

      ComparedPriceFactorSign = 'x';
      ComparedPriceExampleResult = parseFloat(10 * Number(ComparedPriceFactor)).toFixed(2);
      ComparedPriceFactorTooltip = intl.get("If you import a product that costs 10 USD, we'll set its compared at price to 50 USD (10 x 5 = 50).")
    } else {

      ComparedPriceFactorSign = '+';
      ComparedPriceExampleResult = parseFloat(10 + Number(ComparedPriceFactor)).toFixed(2);
      ComparedPriceFactorTooltip = intl.get("If you import a product that costs 10 USD, we'll set its compared at price to 15 USD (10 + 5 = 15).")
    }

    // 原价设置不能小于现价的提示
    const compareAtPriceRuleTips = (
      <div className="Intercom_Information_fill" style={{ marginTop: '5px' }}>
        <Banner tone="warning">
          <Link onClick={this.handleActiveMessageBox} monochrome={false} removeUnderline={true}>
            {intl.get("If the comparison price is lower than the price, we will leave the comparison price empty.")}
          </Link>
        </Banner>
      </div>
    )

    // 付费功能提示 (运费功能)
    // const proPlanTipsModal = (
    //   <Modal
    //     open={proPlanTipsActive}
    //     onClose={this.handleCloseTipsModal}
    //     title="Pro plan feature"
    //     secondaryActions={[
    //       {
    //         content: 'Closed',
    //         onAction: this.handleCloseTipsModal,
    //       },
    //     ]}
    //   >
    //     <Modal.Section>
    //        
    //         <p>
    //           <b>{intl.get("Add shipping fee")}</b> {intl.get("is a paid feature")} <Link onClick={()=>this.props.history.push('/admin/pricing')}>more plan</Link>
    //         </p>
    //        
    //     </Modal.Section>
    //   </Modal>
    // )

    const initSkeletonPage = <div className="SettingsPage__Content">
     <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
      </div>

    const CostRangeErrorMessage = CostRangeError && <div className="PriceRule_Error">
      <Banner tone="warning" title="">
        {CostRangeErrorMsg}
      </Banner>
    </div>

    const applyRulesCountBanner = this.state.showApplyRulesCountBanner && <div style={{ marginBottom: '20px' }}>
      <Banner tone='warning'>
        {intl.get("We are updateing your prices. Products left to update:") + ' ' + this.state.ApplyRulesCount}
      </Banner>
    </div>

    const pageContent = this.state.loadingPage ? initSkeletonPage : <div className="SettingsPage__Content">
      {/* 价格规则应用 显示数量 */}
      {applyRulesCountBanner}

      <section className="Panel_Content">
          <BlockStack gap={400}>
        <div id='my-element4'>
          <Card
          //   sectioned
          //   title={<div className="PricingRules__Label">
          //   <div style={{ display: 'inline-block', fontSize: '15px', fontWeight: '500' }}>{intl.get("Product pricing rules")}</div>
          //   <div style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'sub' }} >
          //     <Tooltip content={'When importing products, the system will automatically set prices based on pricing rules.'} preferredPosition="above"><Icon source={QuestionCircleIcon} backdrop={true} /></Tooltip>
          //   </div>
          // </div>}
          //   actions={[
          //     {
          //       content: this.state.disableApplyRules ? (this.state.ApplyRulesCount > 0) ? '' : intl.get("You have unsaved changes") : intl.get("Apply for existing products"),
          //       onAction: this.handleApplyRules,
          //       disabled: this.state.disableApplyRules,
          //     },
          //   ]}
          >
            <Box className="PricingRules__Box">
            <div className="PricingRules__Label">
            <div style={{ display: 'inline-block', fontSize: '15px', fontWeight: '500' }}>{intl.get("Product pricing rules")}</div>
            <div style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'sub' }} >
              <Tooltip content={intl.get('When importing products, the system will automatically set prices based on pricing rules.')} preferredPosition="above"><Icon source={QuestionCircleIcon} backdrop={true} /></Tooltip>
            </div>
          </div>
          {this.state.disableApplyRules ? (!this.state.ApplyRulesCount > 0) ? '' : 
          <Button variant='plain' onClick={()=>this.handleApplyRules()} disabled={this.state.disableApplyRules}>{intl.get("You have unsaved changes")}</Button>
            : 
            <Button variant='plain' onClick={()=>this.handleApplyRules()} disabled={this.state.disableApplyRules}>{intl.get("Apply for existing products")}</Button>
            }
            </Box>
            {/* <div className="space"></div> */}
            <BlockStack>
              <div style={{ display: 'flex', fontSize: '15px',marginBottom: '10px' }}>
                {/* <div style={{ fontSize: '1.5rem', fontWeight: '500', marginRight: '15px' }}>
                  {intl.get("Advanced price rules")} 
                </div> */}
                {/* <div style={{ lineHeight: '0' }}>
                  <Switch
                    onChange={() => this.SetSwitch()}
                    checked={AdvancedPriceRuleOn}
                    height={32}
                    width={150}
                    // uncheckedIcon={true} 
                    checkedIcon={<div className="switchBtn">Advanced Rules</div>}
                    uncheckedIcon={<div className="switchBtn">Basic Rules</div>}
                    // checkedIcon={true} 
                    onColor="#6371c7"
                    aria-controls="basic-collapsible"
                  />
                </div>
                <img height={20} style={{verticalAlign:'bottom',marginLeft:"10px"}} src='/sub/sub_head_light.png' alt={'Advanced price rules'} /> */}
                <div className='price_rule'>
                <div className='price_rule_list' style={{background:!this.shopifyLevel() ? 'rgba(0, 0, 0, 0.1)' : ''}}>
                  <div className={!AdvancedPriceRuleOn ? `price_rule_items price_rule_item price_rule_item-${language}` : `price_rule_item price_rule_item-${language}`} onClick={() => {!this.shopifyLevel() ? this.setState({upgradeModal:true}) : this.SetSwitch(false)}}>{intl.get("Basic Rules")}</div>
                  {!this.shopifyLevel() ? <div className={AdvancedPriceRuleOn ? `price_rule_items price_rule_item price_rule_item-${language}` : `price_rule_item price_rule_item-${language}`} style={{position:'relative'}}>
                    {intl.get("Advanced Rules")} <img height={20} style={{verticalAlign:'bottom',marginLeft:"6px"}} src='/sub/sub_head_light.png' alt={'Advanced price rules'} />
                    {<LevelAction bg={'none'} callBack={()=>{
                      this.setState({upgradeModal:true})
                    }} img={'16px'} borderRadius={'16px'} font={'12px'}/> }
                    </div> : <div className={AdvancedPriceRuleOn ? `price_rule_items price_rule_item price_rule_item-${language}` : `price_rule_item price_rule_item-${language}`} onClick={() => this.SetSwitch(true)} style={{position:'relative'}}>
                    {intl.get("Advanced Rules")} <img height={20} style={{verticalAlign:'bottom',marginLeft:"6px"}} src='/sub/sub_head_light.png' alt={'Advanced price rules'} />
                    </div>}
                </div>
                </div>
              </div>
              <Collapsible
                open={AdvancedPriceRuleOn}
                id="basic-collapsible"
                transition={{ duration: '200ms', timingFunction: 'ease' }}
              >

                <div className="PriceRule_Table">
                  <table>
                    <thead>
                      <tr>
                        <th>{intl.get("Cost range")}</th>
                        <th>
                          
                        {/* 价格是否加上运费设置 */}
                  <div style={{ display:'inline-flex',alignItems:'center' }}>
                  <span style={{marginRight:'5px'}}>{intl.get("Price markup")}</span>
                    <Checkbox
                      id="AdvancedPriceAllAddShippingFee"
                      label={
                        <div style={{ display: 'flex' }} className="PricingRules__Label">
                          <div style={{ fontSize: '14px', }}>{intl.get("Add shipping fee")}</div>
                        </div>
                      }
                      checked={AdvancedPriceAllAddShippingFee}
                      onChange={this.handleCheckboxChange}
                    />
                  </div></th>
                       
                        <th colSpan="2" style={{ textAlign: 'left' }}>
                          <Checkbox
                            id="AdvancedComparedPriceChecked"
                            label={intl.get("Compared at price Markup")}
                            checked={AdvancedComparedPriceChecked}
                            onChange={this.handleCheckboxChange}
                          />
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr><td colSpan="4" style={{ padding: '5px', borderTop: '1px solid rgb(204, 204, 204)' }}></td></tr>

                      {AdvancedTableTrRow}

                      <tr><td colSpan="4" style={{ borderBottom: '1px solid #ccc' }}></td></tr>

                      <tr>
                        <td>
                          <div className="PriceRule_Cost_Range_Item">

                            <div className="Cost_Range_Connector">
                              {intl.get("Rest of the price ranges")}
                              <div style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'sub' }} >
                                <Tooltip content={intl.get("These markups will be used for the rest of the price ranges")} preferredPosition="above"><Icon source={QuestionCircleIcon} backdrop={true} /></Tooltip>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="PriceRule_Cost_Range_Item Mark_Number">
                            {/* <div className="showMark" style={{padding:'7px 4px 7px 0'}}>
                            <Icon source={(RestRange_Factor_Mark==="1")?XSmallIcon:MobilePlusMajor} />
                          </div> */}
                            <TextField
                              id="RestRange_Factor_Value"
                              type="number"
                              label={intl.get("Price Factor")}
                              labelHidden
                              onChange={this.handleInputChange}
                              value={String(RestRange_Factor_Value)}
                              step={0.01}
                              connectedLeft={
                                <Select
                                  id="RestRange_Factor_Mark"
                                  label={intl.get("Price Factor Mark")}
                                  labelHidden
                                  options={[{ label: '✖', value: '1' }, { label: '✚', value: '2' }]}
                                  onChange={this.handleSelectChange}
                                  value={RestRange_Factor_Mark}
                                />
                              }
                            />
                          </div>

                        </td>
                        <td colSpan="2">
                          <div className="PriceRule_Cost_Range_Item Mark_Number" style={{ opacity: AdvancedComparedPriceChecked ? '1' : '0' }}>
                            <TextField
                              id="RestRange_ComparedPrice_Factor_Value"
                              type="number"
                              label={intl.get("Compared Price Factor")}
                              labelHidden
                              onChange={this.handleInputChange}
                              value={String(RestRange_ComparedPrice_Factor_Value)}
                              step={0.01}
                              connectedLeft={
                                <Select
                                  id="RestRange_ComparedPrice_Factor_Mark"
                                  label={intl.get("Compared Price Factor Mark")}
                                  labelHidden
                                  options={[{ label: '✖', value: '1' }, { label: '✚', value: '2' }]}
                                  onChange={this.handleSelectChange}
                                  value={RestRange_ComparedPrice_Factor_Mark}
                                />
                              }
                            />
                          </div>

                        </td>
                      </tr>

                    </tbody>
                  </table>

                 

                  <div className="space"></div>

                  {/* 原价不能小于现价的规则提示 */}
                  {compareAtPriceRuleTips}

                </div>

                {/* 规则设置有误提示信息 */}
                {CostRangeErrorMessage}

              </Collapsible>
            </BlockStack>
            <div style={{ display: ClosedCustomPriceRule ? 'none' : 'block' }}>
              <section className="PricingRules__Item">
                <FormLayout>
                  <div className="PricingRules__Label">
                    <div style={{ display: 'inline-block', fontSize: '15px', fontWeight: '500' }}>{intl.get("Your product price")}</div>
                    <div style={{ display: 'inline-block', cursor: 'pointer',verticalAlign: 'sub' }} >
                      <Tooltip content={PriceFactorTooltip} preferredPosition="above"><Icon source={QuestionCircleIcon} backdrop={true} /></Tooltip>
                    </div>
                  </div>

                  <InlineStack blockAlign="center">
                    <div className="PricingRules__Product_Cost">
                      <Banner icon={WalletIcon}>
                        <p style={{ fontSize: '15px' }}>
                          {intl.get("Product cost")}
                        </p>
                      </Banner>
                    </div>
                    {/* <div className="showMark" style={{padding:'1rem 0'}}>
                    <Icon source={(PriceFactorMark==="1")?XSmallIcon:MobilePlusMajor} />
                  </div> */}
                    <TextField
                      id="PriceFactor"
                      type="number"
                      label={intl.get("Price Factor")}
                      labelHidden
                      onChange={this.handleInputChange}
                      value={String(PriceFactor)}
                      step={0.01}
                      connectedLeft={
                        <Select
                          id="PriceFactorMark"
                          label={intl.get("Price Factor Mark")}
                          labelHidden
                          options={[{ label: '✖', value: '1' }, { label: '✚', value: '2' }]}
                          onChange={this.handleSelectChange}
                          value={PriceFactorMark}
                        />
                      }
                    />
                    <span style={{marginLeft:'10px'}}></span>
                    {!this.shopifyLevel() ? <div className='PriceAddShippingFee_level' style={{position:'relative',display:'flex'}}>
                    <Checkbox
                      id="PriceAddShippingFee"
                      label={
                        <div style={{ display: 'flex' }} className="PricingRules__Label">
                          <div style={{ fontSize: '14px', }}>{intl.get("Add shipping fee")}</div>
                          <div style={{ cursor: 'pointer', width: '16px', marginTop: '-4px' }} >
                          </div>
                        </div>
                      }
                      checked={PriceAddShippingFee}
                      onChange={()=>{this.setState({upgradeModal:true})}}
                    />
                    <div style={{display:'flex',alignItems:'center',padding:"2px 6px",background:"#202333",color:"#fff",borderRadius:"16px"}}>
                    <span style={{lineHeight:1}}><img style={{width:16}} src="/crown.png" alt="Dropshipman" /></span>
                    <span style={{fontSize:12}}>Advanced plan</span>
                </div>
                    </div>: <Checkbox
                      id="PriceAddShippingFee"
                      label={
                        <div style={{ display: 'flex' }} className="PricingRules__Label">
                          <div style={{ fontSize: '14px', }}>{intl.get("Add shipping fee")}</div>
                          <div style={{ cursor: 'pointer', width: '16px', marginTop: '-4px' }} >
                          <img height={20} style={{verticalAlign:'bottom',marginLeft:"6px"}} src='/sub/sub_head_light.png' alt={'Advanced price rules'} />
                          </div>
                        </div>
                      }
                      checked={PriceAddShippingFee}
                      onChange={this.handleCheckboxChange}
                    />
                    }
                    
                  </InlineStack>

                  <div style={{ padding: '0 10px 20px 20px', color: 'rgb(129, 141, 154)' }}>
                    {intl.get("Example:") + ' $10 ' + PriceFactorSign + ' ' + PriceFactor + ' = $' + PriceFactorExampleResult}
                  </div>
                </FormLayout>
              </section>

              <section className="PricingRules__Item">
                <FormLayout>
                  <div className="PricingRules__Label">
                    <Checkbox
                      id="ComparedPriceOn"
                      label={intl.get("Your product compared at price")}
                      checked={ComparedPriceOn}
                      onChange={this.handleCheckboxChange}
                    />
                    <div style={{ display: 'inline-block', cursor: 'pointer', width: '16px' }} >
                      <Tooltip content={ComparedPriceFactorTooltip} preferredPosition="above"><Icon source={QuestionCircleIcon} backdrop={true} /></Tooltip>
                    </div>
                  </div>

                  <div>
                    <InlineStack blockAlign="center">
                      <div className="PricingRules__Product_Cost">
                        <Banner icon={WalletIcon}>
                          <p style={{ fontSize: '15px' }}>
                            {intl.get("Product cost")}
                          </p>
                        </Banner>
                      </div>
                      {/* <div className="showMark" style={{padding:'1rem 0'}}>
                      <Icon source={(ComparedPriceFactorMark==="1")?XSmallIcon:MobilePlusMajor} />
                    </div> */}
                      <TextField
                        id="ComparedPriceFactor"
                        type="number"
                        label={intl.get("Compared Price Factor")}
                        labelHidden
                        onChange={this.handleInputChange}
                        value={String(ComparedPriceFactor)}
                        step={0.01}
                        disabled={!ComparedPriceOn}
                        connectedLeft={
                          <Select
                            id="ComparedPriceFactorMark"
                            label={intl.get("Compared Price Factor Mark")}
                            labelHidden
                            options={[{ label: '✖', value: '1' }, { label: '✚', value: '2' }]}
                            onChange={this.handleSelectChange}
                            value={ComparedPriceFactorMark}
                            disabled={!ComparedPriceOn}
                          />
                        }
                      />
                      {/* <Checkbox
                        id="ComparedPriceAddShippingFee"
                        label={
                          <div style={{ display: 'flex' }} className="PricingRules__Label">
                            <div style={{ fontSize: '1.4rem', }}>{intl.get("Add shipping fee")}</div>
                            <div style={{ cursor: 'pointer', width: '1.6rem', marginTop: '-4px' }} >
                              <Tooltip content='Please set up a shipping rule to apply shipping fee. Note that this is a paid feature. Please check pricing list.' preferredPosition="above">
                                <Icon source={QuestionCircleIcon} backdrop={true} />
                              </Tooltip>
                            </div>
                          </div>
                        }
                        checked={ComparedPriceAddShippingFee}
                        onChange={this.handleCheckboxChange}
                      /> */}
                    </InlineStack>
                  </div>

                  <div style={{ padding: '0 10px  10px 20px', color: 'rgb(129, 141, 154)' }}>
                    {intl.get("Example:") + ' $10 ' + ComparedPriceFactorSign + ' ' + ComparedPriceFactor + ' = $' + ComparedPriceExampleResult}
                  </div>
                </FormLayout>

                {/* 原价不能小于现价的规则提示 */}
                {compareAtPriceRuleTips}

              </section>
              <div style={{ padding: '5px', borderBottom: '.5px solid #ccc' }}></div>
            </div>


           
          </Card>
        </div>
        <Card title={intl.get("Assign cents")} sectioned>
          <p style={{ color: '#818d9a', marginBottom: '30px', }}>
            {intl.get("You can set a specific cent value for your retail price. We will use this value when forming the final price for your items (e.g., if you want the cost of your product to be XX.99 then add 99 to the fields below).")}
          </p>

          <FormLayout>
            <FormLayout.Group>
              <div className="Assign_Cents_Item">
                <Checkbox
                  id="AssignCentsChecked"
                  label={intl.get("Assign cents")}
                  checked={AssignCentsChecked}
                  onChange={this.handleCheckboxChange}
                />
                <TextField
                  id="AssignCents"
                  type="number"
                  label={intl.get("Assign cents")}
                  labelHidden
                  disabled={disabledAssignCents}
                  value={String(AssignCents)}
                  min={0}
                  max={99}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="Assign_Cents_Item">
                <Checkbox
                  id="AssignComparedCentsChecked"
                  label={intl.get("Assign compared at cents")}
                  checked={AssignComparedCentsChecked}
                  onChange={this.handleCheckboxChange}
                />
                <TextField
                  id="AssignComparedCents"
                  type="number"
                  label={intl.get("Assign compared at cents")}
                  labelHidden
                  disabled={disabledAssignComparedCents}
                  value={String(AssignComparedCents)}
                  min={0}
                  max={99}
                  onChange={this.handleInputChange}
                />
              </div>
            </FormLayout.Group>
          </FormLayout>
        </Card>
        </BlockStack>
      </section>

    </div>



    return (

      <div className={AppBridge.exist() ? "Setting__Page_Content Content_within" : "Setting__Page_Content Content_outside"} id='settings_class'>

        {/* <div id="my-element" style={{top:'50%',left:'50%'}}></div>
            <div id="my-element2" style={{top:'50%',left:'50%'}}></div>
            <div id="my-element3" style={{top:'50%',left:'50%'}}></div>
            <div id="my-element5" style={{ 
            position: 'fixed', 
            top: '10%',
            }}></div>
            <div id="my-element6" style={{ 
            position: 'fixed', 
            top: '10%',
            }}></div>
            <div id="my-element7" style={{ 
            position: 'fixed', 
            top: '10%',
            }}></div> */}



        <Page
          // breadcrumbs={[{content: 'Settings', url: '/admin/settings/index'}]}
          title={AppBridge.exist() ? '' : intl.get("Settings")}
          primaryAction={
            // AppBridge.exist() ? '' : 
            {
              content: intl.get("Save"),
              onAction: this.saveData,
              loading: saveDataLoading,
              disabled: disabledSaveData
            }
          }
          separator
        >


          <section className="SettingsPage__Box">

            <NavTabs selected="Pricing rules" saveData={this.saveData} loading={saveDataLoading} disabled={disabledSaveData}/>

            {pageContent}

          </section>

          {toastMarkup}

          {/* 价格规则应用弹窗 */}
          {ApplyRulesModal}

          {/* 付费功能提示弹窗 */}
          {/* {proPlanTipsModal} */}
          {/* 升级为会员弹窗 */}
          <UpgradePlanModal upgradeOpen={this.state.upgradeModal} setUpgradeOpen={()=>this.setState({upgradeModal:false})}/>
        </Page>
        {/* 未保存拦截弹窗 */}
        <SettingPrompt disabledSaveData={disabledSaveData} url='/admin/settings/price' saveData={this.saveData}/>
      </div>



    );

  }
}

export default connect()(withRouter(PricingRulesClass));
