import { Box, Button, InlineStack } from '@shopify/polaris';
import React from 'react';

const FirstTimeTips = ({popupStyle,setShowPopup,typeTarget})=> {
    
    return (
        <Box className={typeTarget === "collect" ? "first_time_popup_box" : "first_time_popup_box first_time_popup_box_add_product"}
        style={{
          position: "fixed",
          color: "#fff",
          borderRadius: "5px",
          zIndex: 1000,
          whiteSpace: "nowrap",
          ...popupStyle, // 动态位置样式
        }}>
           {typeTarget === "collect" ?  <InlineStack gap={300}>
            <Box>🎉 Added to Favorites!<br />
            View all favorites here.</Box>
            <Button onClick={()=>setShowPopup(false)}>Okay, I see</Button>
            </InlineStack> : <InlineStack gap={300}>
            <Box>🎉 Added successfully!! <br />
            All added products are shown in Product Center.</Box>
            <Button onClick={()=>setShowPopup(false)}>Okay, I see</Button>
            </InlineStack>}
        </Box>
    );
}

export default FirstTimeTips;
