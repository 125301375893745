import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./index.css";
import { Button, Checkbox, Modal, Toast } from "@shopify/polaris";
import {getPayonnerAuthBalancesRechargeCommit } from "../../api";
import AppBridge from "../../utils/appBridge";
import intl from "../../i18n/intl";
const PayoneerSelect = ({ open,data, callPayoneerConfirmClosed,callPayoneerConfirmClosed2 }) => {
  const [selectLoading, setSelectLoading] = useState(false); //选择余额类型加载状态
  const handleOff = () => {
    callPayoneerConfirmClosed();
  };

  const handlePayoneerConfirm = async()=>{
    let params = {
        from_type:AppBridge.exist() ? 1 : 2,
        commit_id:data.commit_id,
        client_reference_id:data.client_reference_id
    }
    setSelectLoading(true)
    let res = await getPayonnerAuthBalancesRechargeCommit(params);
    if (res.code === '200') {
        handleOff()
        callPayoneerConfirmClosed2()
    }else if (res.code === '201') {
        handleOff()
        callPayoneerConfirmClosed2()
        window.open(res.data.url,'_blank')
    }else {
        toggleToast(res.msg)
    }
    setSelectLoading(false)
  }
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChange = (newChecked) => {
    setChecked(newChecked);
  };
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
  return (
    <div>
      <Modal open={open} onClose={() => handleOff()}>
      {data && <div className="payoneer_select_modal payoneer_confirm_modal">
          <div className="payoneer_select_icon">
          {intl.get("Pay With")} <img src="/payoneer/pay_icon.png" alt="dropshipman" />{" "}
          {intl.get("Payoneer")}
          </div>
          <div style={{ textAlign: "center", margin: "10px 0" }}>
          {intl.get("Confirm Payment Details")}
          </div>
          <div className="payoneer_confirm_o_amount">
            <span>{intl.get("Order amount")}:</span>
            <span style={{ fontWeight: "500", fontSize: 18 }}>{data?.target?.currency + ' ' + (data?.target?.amount).toFixed(2)}</span>
          </div>
         {Object.values(data?.fx).length > 0 && <div className="payoneer_confirm_fx_rate">
            <span>{intl.get("FX Rate")}:</span>
            <span style={{ fontWeight: "500", fontSize: 18 }}>{data?.fx?.source_currency + " 1.00 = "+data?.fx?.target_currency + " " + data?.fx?.rate}</span>
          </div>}
          <div className="payoneer_confirm_amount payoneer_confirm_border">
            <span>{intl.get("Payment amount")}:</span>
            <span style={{ fontWeight: "500", fontSize: 18 }}>
            {data?.charged?.currency + ' ' + (data?.charged?.amount).toFixed(2)}
            </span>
          </div>
          <div className="payoneer_confirm_checkbox">
            <Checkbox
              label={data?.agreement}
              checked={checked}
              onChange={handleChange}
            />
          </div>
          <div className="payoneer_confirm_button">
            <Button onClick={() => handleOff()}>{intl.get("Cancel")}</Button>
            <Button variant="primary" disabled={!checked} loading={selectLoading} onClick={() => handlePayoneerConfirm()}>
            {intl.get("Confirm")}
            </Button>
          </div>
        </div>}
      </Modal>
      {toastMarkup}
    </div>
  );
};

export default withRouter(PayoneerSelect);
