import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import utils from '../../../../../utils/utils'
import { 
  Layout,Modal, Toast ,TextField,  Button,FormLayout, Thumbnail,Popover, InlineStack,
  // Card,Banner,Icon,Heading, Select,
} from '@shopify/polaris';
import intl from '../../../../../i18n/intl';
import { reqGetSkuDetails,reqPostAddInventory } from '../../../../../api';

import "./index.css";

class AddInventoryModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ModalShow: (props.show===undefined)?false:props.show,
        loadingPage: false,
        handleLoading: false,
        
        // 购物车 / 下单数组
        pageData: [],
        cartViewHtml: <tr></tr>,
        Subtotal: 0,
        leavingModalActive: false,
        SetQuantityValue: '',
        popoverActive: false,
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        ModalShow: (nextProps.show===undefined)?false:nextProps.show,
        loadingPage: false,
        handleLoading: false,

        // 购物车 / 下单数组
        pageData: [],
        cartViewHtml: <tr></tr>,
        Subtotal: 0,
        leavingModalActive: false,
        SetQuantityValue: '',
        popoverActive: false,

      },()=>{
        this.initData();
      });
    }

    //进入页面调用接口
    componentDidMount () {
      this.initData();
    }

    //提示弹窗
    toggleToast = (showToastText, showToastIserror=false) => {
      this.setState(({ showToast }) => ({
        showToast: !showToast,
        showToastText: showToastText,
        showToastIserror: showToastIserror
      }));
    };

    // 初始化数据
    initData = async() => {
      this.setState({ loadingPage: true });
      const params = {
        ids: JSON.stringify(this.props.ids),
        type: this.props.type,
      }
      const response = await reqGetSkuDetails(params)
      if(response.code==='200'){
        let getData = response.data;
        // console.info(getData);

        this.setState({
          loadingPage: false,
          pageData: getData,
        },()=>{
          this.updateCartList();
        });
        
      }else{
        this.setState({ loadingPage: false });
        this.toggleToast(response.msg);
        // 关闭弹窗
        this.handleChangeModal();
      }
    }

    // 更新购物车数据列表
    updateCartList(){
      let data = this.state.pageData;
      let trHtmlContent = <tr></tr>;
      let total = 0;
      if(data.length > 0){
        trHtmlContent = data.map((item,key) => {
          let single = '';
          // 价格计算
          let singleTotal = Number(item.price) * Number(item.quantity);
          total += singleTotal;

          let imageUrl = '/default_image.png';
          if(item.sku_image !== '' && (typeof item.sku_image) !=='object'){
            imageUrl = item.sku_image;
          }

          single = (
            <tr className="border-top border-bottom" key={key}>
              <td>
                <div style={{paddingRight:'5px'}}>
                  <Thumbnail source={imageUrl} alt={item.title} />
                </div>
              </td>
              <td>
                <div className="Inventory_Product">
                  <div className="Add_Variant__Title">{item.title}</div>
                  <div className="Add_Variant_Sku">{item.sku}</div>
                </div>
              </td>
              <td>
                <div style={{minWidth:'54px'}}>{'$'+item.price} *</div>
              </td>
              <td>
                <div className="Set_Quantity">
                  <TextField
                    type="number"
                    value={String(item.quantity)}
                    onChange={this.handleInputChange.bind(this,key)}
                    step={5}
                    min={item.minQuantity}
                  />
                </div>
              </td>

              <td className="text-right padding-right-10">{'$'+this.handleFormatMoney(singleTotal,2)}</td>
            </tr>
          );

          return (
            single
          )
        });
      }

      this.setState({
        Subtotal: total,
        cartViewHtml: trHtmlContent
      });
    }

    // 弹窗控制
    handleChangeModal = () =>{
      this.setState({
        ModalShow:!this.state.ModalShow,
      });
    }

    // input change
    handleInputChange = (recordKey,values) =>{
      // 批量更新库存值
      let value = values
      if (value >1000) {
        value = 1000
      }
      if(recordKey === 'set'){
        this.setState({
          SetQuantityValue: value,
        });
        return;
      }

      let data = this.state.pageData;
      // 最小起订量控制
      if(data[recordKey].minQuantity > value){
        value = data[recordKey].minQuantity;
      }

      data[recordKey].quantity = value;
      
      this.setState({
        pageData: data
      },()=>{
        this.updateCartList();
      });
    }

    // 批量更新增加库存值
    handleBatchEditDone = () =>{
      let value = this.state.SetQuantityValue;
      if(value !== ''){
        let data = this.state.pageData;
        for (let i = 0; i < data.length; i++) {
          if (data[i].minQuantity <= Number(value)) {
            data[i].quantity = value;
          }
        }
        this.setState({
          SetQuantityValue: '',
          pageData: data
        },()=>{
          this.togglePopoverActive();
          this.updateCartList();
        });
      }
    }

    // 设置批量值弹窗
    togglePopoverActive = () =>{
      this.setState(({ popoverActive }) => ({ popoverActive: !popoverActive }));
    }

    // 格式化金额显示
    handleFormatMoney(s, type) {
      if (/[^0-9.]/.test(s))
        return "0";
      if (s === null || s === "")
        return "0";
      s = s.toString().replace(/^(\d*)$/, "$1.");
      s = (s + "00").replace(/(\d*.\d\d)\d*/, "$1");
      s = s.replace(".", ",");
      var re = /(\d)(\d{3},)/;
      while (re.test(s))
        s = s.replace(re, "$1,$2");
      s = s.replace(/,(\d\d)$/, ".$1");
      if (type === 0) {  // 不带小数位(默认是有小数位)
        var a = s.split(".");
        if (a[1] === "00") {
          s = a[0];
        }
      }
      return s;
    }

    // 提示跳转弹窗
    handleLeavingModal = () =>{
      this.setState({
        leavingModalActive:!this.state.leavingModalActive,
      });
    }

    // create order 确认生成订单
    handleCreateOrder = async () =>{
      this.setState({handleLoading: true});
      const params = {
        ids: JSON.stringify(this.props.ids),
        cart: JSON.stringify(this.state.pageData),
        type: this.props.type
      }

      const response = await reqPostAddInventory(params)
      this.setState({
        handleLoading: false,
      });
      if(response.code==='200'){

        // this.toggleToast(response.msg, false);
        // 关闭弹窗
        this.handleChangeModal();

        // 提示跳转弹窗
        this.handleLeavingModal();
        
      }else{
        this.toggleToast(response.msg);
      }
    }

    render() {
      const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
      const {
          state: { 
            ModalShow,handleLoading,SetQuantityValue,cartViewHtml,Subtotal,leavingModalActive
          },
      } = this;

      const isLeaveingModal = (
        <Modal
          open={leavingModalActive}
          onClose={this.handleLeavingModal}
          title={intl.get("Create Order")}
          primaryAction={{
            content: intl.get('Go to order page'),
            // url:'/admin/order_list',
            onAction: () =>{
              utils.routeListen('/admin/order_list?num=1&type='+['Wait to pay'])
              utils.routeJump(this,'/admin/order_list?num=1&type='+['Wait to pay'])
              }
          }}
          secondaryActions={[
            {
              content: intl.get('Cancel'),
              onAction: this.handleLeavingModal,
            },
          ]}
        >
          <Modal.Section>
             
              <p>
                {intl.get('You have successfully created an order, please pay at Order > Order List > Unpaid.')}
              </p>
             
          </Modal.Section>
        </Modal>
      );

      const ModalContent = (
        <section className="Cart_Modal_Content">
          <div className="cart__subtotal_header">
            <span className="cart__subtotal-title">{intl.get("Subtotal")}</span>
            <span className="cart__subtotal">
              <span className="money">
                {'$'+FormatMoney(Subtotal,2)+' USD'}
              </span>
            </span>
          </div>

          <Layout>
            <Layout.Section>
              <div className="Cart_Box Add_Quantity">
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2">{intl.get("Products")}</th>
                        <th colSpan="2">
                          <InlineStack blockAlign='center'>
                            <span style={{marginRight:'10px',minWidth:'54px'}}>{intl.get("Set quantity")}</span>
                            <div>
                              <Popover 
                                active={this.state.popoverActive} 
                                activator={
                                  <Button 
                                    size="slim" 
                                    onClick={this.togglePopoverActive} 
                                  >
                                    {intl.get("Change all")}
                                  </Button>
                                } 
                                onClose={this.togglePopoverActive}
                              >
                                <div style={{padding:'10px'}}>
                                  <FormLayout>
                                    <TextField
                                      label={intl.get("Set new quantity")}
                                      type="number"
                                      step={5}
                                      value={SetQuantityValue}
                                      onChange={this.handleInputChange.bind(this,'set')}
                                    />
                                    <Button 
                                      size="slim" 
                                      onClick={this.handleBatchEditDone} 
                                      disabled={SetQuantityValue?false:true}
                                      variant='primary'
                                    >
                                      {intl.get("Apply")}
                                    </Button>
                                  </FormLayout>
                                </div>
                              </Popover>
                            </div>
                          </InlineStack>
                        </th>
                        <th className="text-right">{intl.get("Total")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* 购物车列表 */}
                      {cartViewHtml}
                    </tbody>
                  </table>
                </div>

                <div className="Cart__Footer">
                  <div className="Cart__Subtotal text-right padding-right-10">
                    <div>
                      <span className="cart__subtotal-title">{intl.get("Subtotal")}</span>
                      <span className="cart__subtotal">
                        <span className="money">
                          {'$'+FormatMoney(Subtotal,2)+' USD'}
                        </span>
                      </span>
                    </div>
                  </div>

                </div>
                <div style={{clear: 'both'}}></div>

              </div>

            </Layout.Section>
          </Layout>
        </section>
      );
    
      return (
          <div>
            <Modal
              open={ModalShow} 
              onClose={this.handleChangeModal} 
              title={intl.get("Add inventory")}
              primaryAction={
                {
                  content: intl.get("Create order"),
                  onAction: this.handleCreateOrder,
                  loading: handleLoading,
                  disabled: (Subtotal > 0) ? false : true
                }
              } 
              secondaryActions={[
                {
                  content: intl.get("Close"),
                  onAction: this.handleChangeModal
                }
              ]}
              loading={this.state.loadingPage}
              size='large'
            >
              {/* 内容 */}
              {ModalContent}

            </Modal>

            {/* 下单成功后，是否跳转到订单页面 */}
            {isLeaveingModal}

            { toastMarkup }
          </div>
      );

      // 格式化金额显示
      function FormatMoney(s, type) {
        if (/[^0-9.]/.test(s))
          return "0";
        if (s === null || s === "")
          return "0";
        s = s.toString().replace(/^(\d*)$/, "$1.");
        s = (s + "00").replace(/(\d*.\d\d)\d*/, "$1");
        s = s.replace(".", ",");
        var re = /(\d)(\d{3},)/;
        while (re.test(s))
          s = s.replace(re, "$1,$2");
        s = s.replace(/,(\d\d)$/, ".$1");
        if (type === 0) {  // 不带小数位(默认是有小数位)
          var a = s.split(".");
          if (a[1] === "00") {
            s = a[0];
          }
        }
        return s;
      }
    }
}

export default withRouter(AddInventoryModal);