import React, { useState, memo } from "react";
import { Modal,  TextField, Toast } from "@shopify/polaris";
import { reqPostupdateOrderNote } from "../../../../../../api";
import { useEffect } from "react";
import { store } from "../../../../../../store";
import { changeBannerData } from "../../../../../../slice";
import intl from "../../../../../../i18n/intl";
const OrderNoteModal = memo(
  ({ open, setIsShowOrderNoteModal, orderId, note, setOrderNote,content,setOrderPageData }) => {
    const [noteValue, setNoteValue] = useState(note);
    const [loading, setLoading] = useState(false); // 加载

    useEffect(() => {
      let isMount = false;
      if (!isMount && open) {
        setNoteValue(note);
      }
      return () => {
        isMount = true;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    // 保存
    const handleSaveChange = async () => {
      setLoading(true);
      let res = await reqPostupdateOrderNote({ id: orderId, note: noteValue });
      if (res.code === "200") {
        
        toggleToast('Saved successfully');
        let list = content.map((item)=>{
          if (item.dsm_record === orderId) {
            return {...item,dsm_note:noteValue}
          }else{
            return item
          }
        })
        setOrderPageData((prevValue)=>({
          ...prevValue,
          list,
        }))
        handleOff();
      } else {
        store.dispatch(changeBannerData({bannerData:{
          show:true,
          desc:res.msg
        }}));
      }
      setLoading(false);
    };
    // 取消
    const handleOff = () => {
      setNoteValue("");
      setIsShowOrderNoteModal();
      setOrderNote("");
      setLoading(false);
    };
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };
    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
      />
    ) : null;
    return (
      <div style={{ margin: "0 0" }}>
        {toastMarkup}
        <Modal
          open={open}
          onClose={() => handleOff()}
          title={intl.get("Edit Order note")}
          primaryAction={{
            content: intl.get("Save"),
            onAction: handleSaveChange,
            loading: loading,
          }}
          secondaryActions={[
            {
              content: intl.get("Cancel"),
              onAction: setIsShowOrderNoteModal,
            },
          ]}
        >
          <Modal.Section>
             
              <TextField
                label={intl.get("Order note")}
                value={noteValue}
                maxLength={300}
                onChange={(newValue) => setNoteValue(newValue)}
                autoComplete="off"
              />
              <p style={{ color: "rgb(89 89 89)" }}>
                {intl.get("This note only display on Dropshipman")}
              </p>
             
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default OrderNoteModal;
