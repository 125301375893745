import {
  Box,
  Button,
  Icon,
  InlineStack,
  Popover,
  Spinner,
  Tooltip,
} from "@shopify/polaris";
import React, { useEffect, useRef, useState } from "react";
import FavoriteIcon from "../../Icon/FavoriteIcon";
import FavoriteIconHover from "../../Icon/FavoriteIconHover";
import {
  SelectIcon,
  PlusCircleIcon,
  EditIcon,
  QuestionCircleIcon,
  ClockIcon,
  DeliveryIcon,
  CashDollarIcon,
} from "@shopify/polaris-icons";
import ShippingMethodsModal from "./ShippingMethodsModal";
import {
  reqPostFavorite,
  reqPostFindProductAdd,
  reqPostProductFreight,
} from "../../../../../api";
import CountrySelectAllList from "../../component/CountrySelectAllList";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import utils from "../../../../../utils/utils";
import { useSelector } from "react-redux";
const Index = ({
  pageData,
  setDetailData,
  detailParams,
  planData,
  setPlanData,
  userPoints,
  setUserPoints,
  setEnoughPoint,
  setSourcingLimitModal,
  toastFun,
  detailVariant,
  setDetailVariant,
  handleSelect,
  handleAddToShoppingCart,
}) => {
  const router = useHistory();
  const [country, setCountry] = useState("US");

  const plan_level = useSelector(
    (state) => state.languageReducer.subScriptData
  );
  const [addLoaing, setAddLoading] = useState(false);
  const [shippingMethodData, setShippingMethodData] = useState([]);
  // 获取物流运输方式
  const getShippingMethod = async (value) => {
    try {
      let params = {
        receiving_country: value,
        pid: pageData.pid,
      };
      let res = await reqPostProductFreight(params);
      if (res.code === "200") {
        setDetailVariant((prevValue) => ({
          ...prevValue,
          currentProfit: res.data.estimated_profit,
        }));
        setShippingMethodData(res.data.logistic_list);
      }
      setisFirstSearch(false);
    } catch (error) {}
  };
  const [showId, setShowId] = useState("");
  const [countryLoading, setCountryLoading] = useState(false);
  const [isFirstSearch, setisFirstSearch] = useState(true);
  // 物流方式选择
  const handleCountrySelect = async (value) => {
    if (countryLoading) {
      return;
    }
    setCountryLoading(true);
    try {
      let params = {
        receiving_country: value,
        pid: pageData.pid,
      };
      let res = await reqPostProductFreight(params);
      if (res.code === "200") {
        setCountry(value);
        setDetailVariant((prevValue) => ({
          ...prevValue,
          currentProfit: res.data.estimated_profit,
        }));
        setShippingMethodData(res.data.logistic_list);
      }
      setShowId("");
      setCountryLoading(false);
      setisFirstSearch(false);
    } catch (error) {
      setCountryLoading(false);
    }
  };

  const [isFavorite, setIsFavorite] = useState(false);
  const handleCollection = async (id, fav) => {
    // 收藏商品
    if (isFavorite) {
      return;
    }
    try {
      setIsFavorite(true);
      let params = {
        record_id: detailParams?.record_id,
        tag:
          detailParams.winning === 0 || pageData?.aa === ""
            ? "home"
            : "winning",
        is_favorite: fav === 0 ? 1 : 0,
      };
      let res = await reqPostFavorite(params);
      if (res.code === "200") {
        pageData.is_favorite = fav === 0 ? 1 : 0;
        setDetailData((prevValue) => ({
          ...prevValue,
          data: pageData,
        }));
        toastFun(fav === 0 ? "Added to Favorites" : "Removed from Favorites");
      }
      setTimeout(() => {
        setIsFavorite(false);
      }, 1500);
    } catch (error) {
      setTimeout(() => {
        setIsFavorite(false);
      }, 1500);
    }
  };

  // const handleCredit = () => {
  //   utils.routeListen("/admin/wallet");
  //   utils.routeJump1(router, "/admin/wallet");
  // };

  const [shippingModal, setShippingModal] = useState({
    open: false,
    loading: false,
  });
  useEffect(() => {
    let isMount = false;
    if (!isMount && Object.values(pageData).length > 0) {
      getShippingMethod(pageData?.country_code);
      setCountry(pageData?.country_code);
    }

    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  const shippingSelect = shippingMethodData.filter((item) => item.selected);

  const handleEditProduct = (dsm_pid) => {
    utils.routeListen("/admin/product_management?selectedView=0");
    utils.routeJump1(router, "/admin/product_management?selectedView=0&ids=" + dsm_pid);
  };

  // 产品导入
  const handleProductImport = async (productId, cj_pid, isPoint) => {
    if (plan_level.bill_level === 5 || plan_level.level === 5) {
      if (
        userPoints.userPointsData.available_points < 150 &&
        planData.productLimit >= planData.productDay
      ) {
        setEnoughPoint(true);
        return;
      }
    }
    if (planData.productLimit >= planData.productDay) {
      setUserPoints((prevValue) => ({
        ...prevValue,
        userPointsRedeem: {
          ...prevValue.userPointsRedeem,
          record_id: productId,
          pid: cj_pid,
          tag: detailParams.winning === 0 ? "home" : "winning",
        },
      }));
      setSourcingLimitModal(true);
      return;
    }
    try {
      setAddLoading(true);
      let params = {
        record_id: productId,
        pid: cj_pid,
        tag: detailParams.winning === 0 ? "home" : "winning",
        is_point: isPoint,
      };
      let res = await reqPostFindProductAdd(params);
      if (res.code === "200") {
        toastFun("Added successfully");
        pageData.dsm_pid = res.data.id;
        setDetailData((prevValue) => ({
          ...prevValue,
          data: pageData,
        }));
        setPlanData((prevValue) => ({
          ...prevValue,
          productLimit: prevValue.productLimit + 1,
        }));
      }
      // setPlanData((prev) => ({
      //   ...prev,
      //   productLimit: planData.productLimit + 1,
      // }));

      setTimeout(() => {
        setAddLoading(false);
      }, 1500);
    } catch (error) {}
  };
  const planCurrent2 = () => {
    let flag = false;
    if (
      detailParams?.winning === 1 ||
      plan_level.bill_level === 2 ||
      plan_level.bill_level === 3 ||
      plan_level.bill_level === 5 ||
      plan_level.level === 2 ||
      plan_level.level === 3 ||
      plan_level.level === 5
    ) {
      flag = true;
    }
    return flag;
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // 如果点击的目标不包含在指定类名中
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !event.target.classList.contains("product_selection_country_content")
      ) {
        // 在这里执行你的逻辑，比如关闭弹窗或菜单
        setShowId("");
      } else {
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box className="product_detail_variant">
      <Box className="product_detail_variant_box">
        <Box className="product_detail_variant_title">
          <h1>{pageData.title}</h1>
        </Box>
        <Box className="product_detail_variant_invent">
          <InlineStack blockAlign="center" gap={100}>
            {planCurrent2() && (
              <span className="product_detail_variant_invent_span product_detail_variant_invent_span_l">
                Lists: {pageData.listed_num}
              </span>
            )}
            <Tooltip content="Supplier stock levels fluctuate with market changes. If you need to confirm quantities, please contact your dedicated manager">
              <span className="product_detail_variant_invent_span">
                <Icon source={QuestionCircleIcon} tone="base" /> Inventory:{" "}
                {detailVariant.currentInventory !== ""
                  ? detailVariant.currentInventory
                  : pageData.inventory}
              </span>
            </Tooltip>
            {planCurrent2() && (
              <span className="product_detail_variant_invent_span product_detail_variant_invent_span_r">
                Expected profit: ${" "}
                {detailVariant.currentProfit !== ""
                  ? detailVariant.currentProfit
                  : pageData.estimated_profit}
              </span>
            )}
          </InlineStack>
        </Box>
        <Box className="product_detail_variant_price">
          <InlineStack
            wrap={false}
            direction={"row"}
            blockAlign="center"
            align="space-between"
          >
            <strong>
              $
              {detailVariant?.indexPrice !== ""
                ? detailVariant?.indexPrice
                : detailVariant?.defaultPrice}
            </strong>
            {detailParams?.source ? (
              ""
            ) : (
              <Box
                className="product_detail_variant_price_fav_btn"
                onClick={() =>
                  handleCollection(
                    detailParams?.record_id,
                    pageData?.is_favorite
                  )
                }
              >
                <Tooltip
                  content={
                    pageData?.is_favorite === 0
                      ? "Add to Favorites"
                      : "Remove from  Favorites"
                  }
                >
                  {pageData?.is_favorite === 0 ? (
                    <span className="product_detail_variant_price_fav">
                      <span className="product_detail_variant_price_fav_show">
                        <Icon source={FavoriteIcon}></Icon>
                      </span>
                      <span className="product_detail_variant_price_fav_hover">
                        <Icon source={FavoriteIconHover}></Icon>
                      </span>
                    </span>
                  ) : (
                    <span className="product_detail_variant_price_fav_active">
                      <Icon source={FavoriteIconHover}></Icon>
                    </span>
                  )}
                </Tooltip>
              </Box>
            )}
          </InlineStack>
        </Box>
        <Box className="product_detail_variant_sku">
          <div className="product_sku_attr_box">
            <div className="product_sku_attr_list">
              {detailVariant.skuData.slice(0, 1).map((item, ind) => (
                <div className="product_sku_attr_item" key={ind}>
                  <span className="product_sku_attr_title">
                    {item.name}&nbsp;{" "}
                    <span style={{ fontWeight: 400 }}>
                      {item.selected ? "(" + item.selected + ")" : ""}
                    </span>
                  </span>
                  {item.value.length > 0 && (
                    <div className="product_sku_attr_item_list">
                      {item.value.map((it, k) => (
                        <span
                          className={
                            item.selected === it
                              ? "item_value item_value_select"
                              : "item_value"
                          }
                          onClick={() => handleSelect(it, ind)}
                          key={k}
                        >
                          {it}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              {detailVariant.skuData.slice(1, 3).map((item, ind) => (
                <div className="product_sku_attr_item" key={ind}>
                  <span className="product_sku_attr_title">
                    {item.name}&nbsp;{" "}
                    <span style={{ fontWeight: 400 }}>
                      {item.selected ? "(" + item.selected + ")" : ""}
                    </span>
                  </span>
                  {item.value.length > 0 && (
                    <div className="product_sku_attr_item_list">
                      {item.value.map((it, k) => (
                        <span
                          className={
                            item.selected === it
                              ? "item_value item_value_select"
                              : "item_value"
                          }
                          style={{
                            color: "rgb(32, 34, 35)",

                            pointerEvents: "auto",
                          }}
                          onClick={() => handleSelect(it, ind + 1)}
                          key={k}
                        >
                          {it}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Box>
        <Box className="product_detail_variant_cart_list">
          <InlineStack direction={"row"} blockAlign="center" gap={200}>
            <Button
              disabled={detailVariant.disableAddToCart}
              loading={detailVariant.addToCartLoading}
              onClick={() => handleAddToShoppingCart()}
            >
              Add to cart
            </Button>
            {pageData.dsm_pid ? (
              <Button
                variant="plain"
                icon={EditIcon}
                onClick={() => {
                  handleEditProduct(pageData.pid);
                }}
              >
                Edit on Product Center
              </Button>
            ) : detailParams?.source ? (
              ""
            ) : (
              <Button
                variant="primary"
                icon={PlusCircleIcon}
                loading={addLoaing}
                onClick={() => {
                  handleProductImport(detailParams?.record_id, pageData.pid, 0);
                }}
              >
                Add to product list
              </Button>
            )}
          </InlineStack>
        </Box>
        <Box className="product_detail_variant_shipping">
          <Box className="product_detail_variant_shipping_ware">
            <strong>Warehouse:</strong>
            <span> China | </span>
            <strong>Weight:</strong> <span>{pageData?.product_weight}g</span>
          </Box>
          <Box className="product_detail_variant_shipping_country">
            <InlineStack blockAlign="center" direction={"row"} gap={200}>
              <Box className="btn_currency">
                <Popover
                  active={showId === pageData.record_id}
                  activator={
                    <Button
                      loading={countryLoading}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showId === pageData.record_id) {
                          setShowId("");
                        } else {
                          setShowId(pageData.record_id);
                        }
                      }}
                    >
                      <InlineStack align="space-between">
                        <p
                          style={{
                            marginRight: "10px",
                            color: "rgba(48, 48, 48, 1)",
                            fontSize: "13px",
                            fontWeight: "550",
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>Ship to</span>
                          <img
                            src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`}
                            alt="..."
                          />
                          <span style={{ marginLeft: "5px" }}>{country}</span>
                        </p>
                        <span>
                          <Icon source={SelectIcon}></Icon>
                        </span>
                      </InlineStack>
                    </Button>
                  }
                  autofocusTarget="first-node"
                  onClose={() => {}}
                >
                  <CountrySelectAllList
                    containerRef={containerRef}
                    setShowId={setShowId}
                    handleCountrySelect={handleCountrySelect}
                    setCountry={setCountry}
                    country={country}
                  />
                </Popover>
              </Box>
              {shippingSelect.length > 0 && (
                <Button
                  onClick={() =>
                    setShippingModal({ open: true, loading: false })
                  }
                >
                  <InlineStack align="space-between">
                    {shippingSelect[0].ShippingMethod}
                    <span>
                      <Icon source={SelectIcon}></Icon>
                    </span>
                  </InlineStack>
                </Button>
              )}
            </InlineStack>
          </Box>
          {shippingSelect.length > 0 ? (
            <Box className="product_detail_variant_shipping_day">
              <InlineStack>
                <Icon source={ClockIcon} tone="base" />{" "}
                <p>Estimated Processing Time: 1-5 days for 80% orders</p>
              </InlineStack>
              <InlineStack>
                <Icon source={DeliveryIcon} tone="base" />
                <p>Estimated Delivery Time: {shippingSelect[0].DeliveryTime}</p>
              </InlineStack>
              <InlineStack>
                <Icon source={CashDollarIcon} tone="base" />{" "}
                <p>Shipping Fee: ${shippingSelect[0].BeforeConvertCost}</p>
              </InlineStack>
            </Box>
          ) : isFirstSearch ? (
            <Spinner accessibilityLabel="Small spinner example" size="small" />
          ) : (
            <Box className="shipping_none">
              {
                "This Supplier/Shipping Company does not deliver to your selected Country/Region."
              }
            </Box>
          )}
        </Box>
        {/* <Box className="product_detail_variant_credit">
          <InlineStack wrap={false} blockAlign="center" align="space-between">
            <img height={22} src="/dsm_pay.png" alt="Credit" />
            <p>
              {pageData?.is_recharge
                ? "$ "+pageData?.wallet_money
                : "Dropshipman Credit"}
            </p>
            <Button onClick={() => handleCredit(pageData.is_recharge)}>
              {pageData.is_recharge ? "Top Up" : "Learn more"}
            </Button>
          </InlineStack>
        </Box> */}
      </Box>
      <ShippingMethodsModal
        shippingModal={shippingModal}
        setShippingModal={setShippingModal}
        shippingMethodData={shippingMethodData}
        setShippingMethodData={setShippingMethodData}
      />
    </Box>
  );
};

export default Index;
