import { Box, Button, Modal } from '@shopify/polaris';
import React  from 'react';
const Index = ({open,setAchiveShow,handleToPoints})=> {
    const archiveList= {
      cost: 'Cost Down',
      costList:[
        {
          title:'Dropshipman credits',
          dsc:'Pay with Dropshipman Credits for quicker, safer transactions and enjoy a minimum of 5% savings on payment fees. Each top-up also earns you bonus points.',
          isAction:false,
          icon:'/dashboard/property_icon1.png',
          button:'Claim',
          loading:false
        },
        {
          title:'Point',
          dsc:'Every spend on Dropshipman earns you points, which can be used to discount your order total, reducing fulfillment costs by a maximum of 50%.',
          isAction:false,
          icon:'/dashboard/property_icon2.png',
          button:'Claim',
          loading:false
        },
        {
          title:'Coupons',
          dsc:'Using coupons offers greater savings, with various denominations to meet different payment needs. Applying a coupon reduces fulfillment costs by at least 20%.',
          isAction:false,
          icon:'/dashboard/property_icon3.png',
          button:'Claim',
          loading:false
        }
      ],
      study:"Study for Dropship Business",
      studyList:[
        {
          title:'Dropshipman Discord Community',
          dsc:'Join the Dropshipman Discord community to experience supportive service and connect with over 50,000 dropshipping merchants to share success stories and grow together.',
          isAction:false,
          icon:'/dashboard/study_icon1.png',
          button:'Join Dropshipman Discord',
          loading:false
        }
      ]
    };
    return (
        <div>
            <Modal
            size='large'
            open={open}
            onClose={()=>setAchiveShow(false)}
            title="Archive dropshipping success with Dropshipman"
            >
           <Box className="achive_plan_content">
            <Box className="achive_plan_title">{archiveList.cost}</Box>
            <Box className="achive_plan_list">
              {archiveList.costList.map((item,ind)=><Box key={ind} className="achive_plan_list_item">
                <Box className="achive_plan_list_item_title">{item.title}</Box>
                <Box className='achive_plan_list_item_dsc'>{item.dsc}</Box>
                <Box className="achive_plan_list_item_button">
                  <img width={36} src={item.icon} alt='Dropshipman'/>
                  <Button onClick={()=>handleToPoints(item.title)} size='slim'>{item.button}</Button>
                </Box>
              </Box>)}
            </Box>
            <Box className="achive_plan_title">{archiveList.study}</Box>
            <Box className="achive_plan_list">
              {archiveList.studyList.map((item,ind)=><Box key={ind} className="achive_plan_list_item">
                <Box className="achive_plan_list_item_title">{item.title}</Box>
                <Box className='achive_plan_list_item_dsc'>{item.dsc}</Box>
                <Box className="achive_plan_list_item_button">
                  <img width={36} src={item.icon} alt='Dropshipman'/>
                  <Button onClick={()=>handleToPoints(item.title)} size='slim'>{item.button}</Button>
                </Box>
              </Box>)}
            </Box>
           </Box>
            </Modal>
        </div>
    );
}

export default Index;
