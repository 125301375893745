import { Badge, Box, Checkbox, ChoiceList,  InlineStack, Modal } from '@shopify/polaris';
import React, { useState } from 'react';
import { reqPostProductManagementProductsUploadShopify } from '../../../../api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from "../../../../utils/utils"
import './index.css'

  // 新人上架产品 包含
  // 发布产品、下次不再提示、跳转设置页

const Index = ({productStoreData,setProductStoreData,listData,setListData,restartPolling,detail,handleListSubmitDetail})=> {
  const router = useHistory()
    const handleOff = ()=>{
      setLoading(false)
      setProductStoreData({
        open:false,
        type:0,
        toStore:[]
      })
    }
    // 产品发布状态
    const [productStatus,setProductStatus] = useState(1);
    // 产品提示状态
    const [remindStatus,setRemindStatus] = useState(true);
    // 当产品库存不足时，产品继续上架
    const [isStckout,setIsStckout] = useState(true);
    const [loading,setLoading] = useState(false);
    const handleSubmit = async ()=>{
      setLoading(true)
      if (detail) {
        handleListSubmitDetail(productStatus,isStckout,remindStatus)
        setTimeout(() => {
          setLoading(false)
        }, 2000);
        return
      }
      let offline = [];
    for (let i = 0; i < listData.list.length; i++) {
      if (listData.list[i].product_status !== 1) {
        offline.push(Number(listData.list[i].id))
      }
    }
    const commonElements = productStoreData.toStore.filter(item => !offline.includes(item))
      try {
        let params = {
          type:productStoreData.type,
          ids:JSON.stringify(commonElements),
          auto_publish:productStatus === 1 ? 1 : 0,
          is_inventory:isStckout ? 1 : 0,
          upload_reminder:remindStatus ? 1 : 0
        }
        let res = await reqPostProductManagementProductsUploadShopify(params);
        if(res.code==='200'){
         
          let list = listData?.list.map((item)=>{
            if (commonElements.includes(item.id)) {
              return {
                ...item,
                is_publish:2
              }
            }else {
              return item
            }
          })
           // 导入、上传产品轮询
        const needImportShopifyIdsArray = list?.filter(item => item.is_publish === 2)?.map(item => item.id);
        const needImportAliIdsArray = list?.filter(item => item.product_status === 0)?.map(item => item.id);
        // console.log('needImportShopifyIdsArray',needImportShopifyIdsArray,needImportAliIdsArray);
        if (needImportShopifyIdsArray.length > 0 || needImportAliIdsArray.length > 0) {
          restartPolling({shopify_ids:JSON.stringify(needImportShopifyIdsArray),product_ids:JSON.stringify(needImportAliIdsArray)})
        }
          setListData({
            ...listData,
            list,
            auto_inventory:isStckout,
            upload_reminder:remindStatus,
            auto_publish:productStatus ? true : false
          })
        }
        if (productStoreData?.fun) {
          productStoreData?.fun('page',false,0)
        }
        handleOff()
      } catch (error) {
        
      }
      
    }

    return (
        <Modal
        open={productStoreData.open}
        onClose={handleOff}
        title="Confirm Product Listing"
        primaryAction={{
          content: 'Confirm',
          onAction: ()=>{handleSubmit()},
          loading:loading
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: ()=>{handleOff()},
          },
        ]}
      >
        <Modal.Section>
          <Box className="new_person_product_box">
            <Box>
              <InlineStack gap={200}>
                <Box className="list_as_product">
                  <InlineStack align='space-between' blockAlign='center' wrap={false}>
                  <Box className="list_as_product_left">
                    <Box className="list_as_product_left_title">List as Published Product</Box>
                    <Box className="list_as_product_left_content">
                      <InlineStack gap={200}>
                      <p>Status in Shopify</p>
                      <Badge tone="success">Active</Badge>
                      </InlineStack>
                    </Box>
                  </Box>
                  <Box  className="list_as_product_right">
                  <ChoiceList
                choices={[
                  {label: '', value: 1},
                ]}
                selected={[productStatus]}
                onChange={(val)=>{
                  setProductStatus(val[0])
                }}
              />
                  </Box>
                  </InlineStack>
                </Box>
                <Box className="list_as_product">
                <InlineStack  align='space-between' blockAlign='center' wrap={false}>
                  <Box className="list_as_product_left">
                    <Box className="list_as_product_left_title">List as Draft Product</Box>
                    <Box className="list_as_product_left_content">
                      <InlineStack gap={200}>
                      <p>Status in Shopify</p>
                      <Badge tone="info">Draft</Badge>
                      </InlineStack>
                    </Box>
                  </Box>
                  <Box  className="list_as_product_right">
                  <ChoiceList
                choices={[
                  {label: '', value: 2},
                ]}
                selected={[productStatus]}
                onChange={(val)=>{
                  setProductStatus(val[0])
                }}
              />
                  </Box>
                  </InlineStack>
                </Box>
              </InlineStack>
            </Box>
            <Box className="list_as_product_remind">
              <InlineStack blockAlign='center' wrap={false}>
              <Checkbox
              checked={remindStatus}
              onChange={(val)=>{setRemindStatus(val)}}
            />
            <p>Keep this selection saved and do not remind me next time.</p>
              </InlineStack>
            
            </Box>
            <Box className="list_as_product_stock">
            <InlineStack blockAlign='center' wrap={false}>
              <Checkbox
              checked={isStckout}
              onChange={(val)=>{setIsStckout(val)}}
            />
            <p>Continue selling even when products are out of stock.</p>
              </InlineStack>
            </Box>
            <Box className="list_as_product_stock_link">
            To modify this selection later, go to <span
            onClick={()=>{
              utils.routeListen('/admin/settings/import')
              utils.routeJump1(router,'/admin/settings/import')
            }} 
            >Settings</span> {'>'} General.
            </Box>
          </Box>
          
        </Modal.Section>
      </Modal>
    );
}

export default Index;
