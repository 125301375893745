import React, { Component } from 'react';

import {
	Page, Card, Layout,  SkeletonBodyText, Checkbox, Toast, BlockStack,
	// FormLayout, Collapsible,
} from "@shopify/polaris";
import NavTabs from "../components/NavTabs";
import { reqGetUserSettings, reqPostUpdateUserSettings } from '../../../../api';

import './index.css'
import utils from '../../../../utils/utils'
import intl from '../../../../i18n/intl'
import SettingPrompt from '../../../../components/SettingPrompt';
import AppBridge from '../../../../utils/appBridge';
import { store } from '../../../../store';
import { changeBannerData } from '../../../../slice';
class ImportingRulesClass extends Component {
	state = {
		// SetProductsWarehouses: false,
		SetProductsPublished: true,
		SetProductsTaxable: false,
		SetProductInventory:false,
		// SelectAliExpressDesc: false,

		// 仓库的国家数组
		// WarehouseCountriesArray: [],

		//弹窗
		showToast: false,  //弹窗显示
		showToastText: '',  //弹窗文字
		showToastIserror: false, //弹窗是否错误

		loadingPage: true,
		saveDataLoading: false,
		disabledSaveData: true,
		modalShow: false,
		nextUrl: '',
		time:60
	}

	//进入页面调用接口
	componentDidMount() {
		this.getUserSettingsData();
	}
	componentDidUpdate() {
		let mutation;
		let callback;
		let setting = document.querySelector('#settings_class');
		if (setting) {
			callback = (mutations) => {
				mutations.forEach((item, index) => {

					if (item.attributeName === 'aria-checked') {
						this.setState({
							disabledSaveData: false
						})
						return
					} else if (item.type === 'characterData') {
						// this.setState({
						// 	disabledSaveData: false
						// })
					}
				})
			}
		}
		const config = {
			attributes: true,
			attributeOldValue: true,
			characterData: true,
			characterDataOldValue: true,
			childList: true,
			subtree: true,
			// attributeFilter:['t']
		}
		mutation = new MutationObserver(callback)
		// 开始检测
		mutation.observe(setting, config)
		// 停止检测
		if (!this.state.disabledSaveData) {
			mutation.disconnect()
		}
	}
	//提示弹窗
	toggleToast = (showToastText, showToastIserror = false) => {
		this.setState(({ showToast }) => ({
			showToast: !showToast,
			showToastText: showToastText,
			showToastIserror: showToastIserror
		}));
	};

	getUserSettingsData = async () => {
		const params = { type: 'import' };
		const response = await reqGetUserSettings(params)
		if (response.code === '200') {

			var result = response.data.userSetting;
			this.setState({
				SetProductsPublished: (result.auto_publish_product === 1) ? true : false,
				SetProductsTaxable: (result.set_product_taxable === 1) ? true : false,
				SetProductInventory: (result.set_product_inventory === 1) ? true : false,
				// SelectAliExpressDesc: (result.select_aliexpress_desc === 1) ? true : false,
				// SetProductsWarehouses: (result.keep_different_warehouse === 1) ? true : false,

				// WarehouseCountriesArray: result.warehouse_countries,

				loadingPage: false,
				//   disabledSaveData: false
			});

		} else {
			this.toggleToast(response.msg);
		}

	}

	// save setting
	saveData = async () => {
		this.setState({
			saveDataLoading: true
		});
		const params = {
			type: 'import',
			SetProductsPublished: this.state.SetProductsPublished ? 1 : 0,
			SetProductsTaxable: this.state.SetProductsTaxable ? 1 : 0,
			SetProductInventory:this.state.SetProductInventory ? 1 :0
			// SelectAliExpressDesc: this.state.SelectAliExpressDesc ? 1 : 0,
			// SetProductsWarehouses: this.state.SetProductsWarehouses ? 1 : 0,
			// WarehouseCountriesArray: JSON.stringify(this.state.WarehouseCountriesArray)
		};
		const responseData = await reqPostUpdateUserSettings(params);
		this.setState({
			saveDataLoading: false
		});
		if (responseData.code === "200") {
			this.setState({
				disabledSaveData: true
			})
			this.toggleToast('Saved successfully');
		} else {
			// this.toggleToast(responseData.msg);
			store.dispatch(changeBannerData({bannerData:{
				show:true,
				desc:responseData.msg
			  }}));
		}
	}

	// checkbox change
	handleCheckboxChange = (value, id) => {
		this.setState({
			[id]: value
		});
	}

	// checkbox change【仓库国家复选框】
	// handleWarehouseCheckboxChange = (index, value) => {
	// 	let data = this.state.WarehouseCountriesArray;
	// 	data[index].checked = value ? 1 : 0;
	// 	this.setState({
	// 		WarehouseCountriesArray: data
	// 	});
	// }
	handleUrlChange = () => {
		if (this.state.nextUrl !== '') {
			this.saveData();
			setTimeout(() => {
				utils.routeListen(this.state.nextUrl)
				utils.routeJump(this,this.state.nextUrl)
			}, 200);
		}
	};
	handleUrlClose = () => {
		if (this.state.nextUrl !== '') {
			utils.routeListen(this.state.nextUrl)
			utils.routeJump(this,this.state.nextUrl)
		}
	}
	handleClose = () => {
		this.setState({
			modalShow: false
		})
	}

	render() {
		const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;

		const { loadingPage, saveDataLoading, disabledSaveData, SetProductsPublished, SetProductsTaxable,SetProductInventory 
			// SetProductsWarehouses, SelectAliExpressDesc, WarehouseCountriesArray
		} = this.state;

		// var leftItem = ''; // 保存一行复选框中的左边
		// var rowItem = ''; // 保存一行复选框
		// let length = WarehouseCountriesArray.length; // 所有复选框的长度

		// 遍历显示复选框
		// const LiItems = WarehouseCountriesArray.map((item, index) => {
		// 	// 单个复选框显示（遍历，每一个）
		// 	let singleItem = (
		// 		<div className="Adding_Product_Item">
		// 			<Checkbox
		// 				id={item.id}
		// 				label={item.name}
		// 				checked={item.checked}
		// 				// onChange={this.handleWarehouseCheckboxChange.bind(this, index)}
		// 			/>
		// 		</div>
		// 	)

		// 	if (index % 2 === 0) { // 遍历到复选框组合左边的复选框
		// 		leftItem = singleItem; // 保存左边的复选框内容
		// 		if (index !== length - 1) { // 不是最后一个
		// 			return '';
		// 		}
		// 		rowItem = (
		// 			<div key={index}>
		// 				<FormLayout>
		// 					<FormLayout.Group>
		// 						{singleItem}
		// 					</FormLayout.Group>
		// 				</FormLayout>
		// 			</div>
		// 		)
		// 	} else { // 遍历到复选框组合右边的复选框，封装
		// 		rowItem = (
		// 			<div key={index}>
		// 				<FormLayout>
		// 					<FormLayout.Group>
		// 						{leftItem}
		// 						{singleItem}
		// 					</FormLayout.Group>
		// 				</FormLayout>
		// 			</div>
		// 		)
		// 	}
		// 	return rowItem;
		// });

		// 页面加载中显示
		const initSkeletonPage = <div className="SettingsPage__Content">
			<Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout></div>

		// 最终页面显示
		const pageContent = loadingPage ? initSkeletonPage : <div className="SettingsPage__Content">
			<section className="Panel_Content">
				{/* <Card
					sectioned
					title={intl.get("Adding product")}
				>
					<section className="Import_Checkbox">
						<Checkbox
							id="SelectAliExpressDesc"
							label={intl.get("Use AliExpress products description")}
							checked={SelectAliExpressDesc}
							onChange={this.handleCheckboxChange}
						/>

						<div className='Import_Checkbox_Note'>
							{intl.get("Please note that the description of AliExpress may affect the SEO")}
						</div>
					</section>

					<section className="Import_Checkbox">
						<div style={{ marginBottom: '10px' }}>
							<Checkbox
								id='SetProductsWarehouses'
								label={intl.get("Keep the warehouse if there are different warehouses")}
								checked={SetProductsWarehouses}
								onChange={this.handleCheckboxChange}
							/>
						</div>
					</section>

					<Collapsible
						open={SetProductsWarehouses}
						id="basic-collapsible"
						transition={{ duration: '200ms', timingFunction: 'ease' }}
					>
						{LiItems}
					</Collapsible>
				</Card> */}

				<Card
					// sectioned
					// title={intl.get("Importing product")}
				>
					<section className="Import_Checkbox">
						<Checkbox
							id="SetProductsPublished"
							label={intl.get("Set new products as published")}
							checked={SetProductsPublished}
							onChange={this.handleCheckboxChange}
						/>

						<div className='Import_Checkbox_Note'>
							{intl.get("Automatically publish products after pushing them to your store")}
						</div>
					</section>

					<section className="Import_Checkbox">
						<Checkbox
							id="SetProductsTaxable"
							label={intl.get("Set products as taxable")}
							checked={SetProductsTaxable}
							onChange={this.handleCheckboxChange}
						/>

						<div className='Import_Checkbox_Note'>
							{intl.get("Specifies whether or not a tax is charged when the product variant is sold")}
						</div>
					</section>
					<section className="Import_Checkbox">
						<Checkbox
							id="SetProductInventory"
							label={intl.get("Continue selling when products out of stock")}
							checked={SetProductInventory}
							onChange={this.handleCheckboxChange}
						/>

						<div className='Import_Checkbox_Note'>
							{intl.get("Customers can order even when products are out of stock on your website.")}
						</div>
					</section>
				</Card>
			</section>
		</div>

		return (
			<div className={AppBridge.exist() ? "Setting__Page_Content Content_within" : "Setting__Page_Content Content_outside"} id='settings_class'>
				<Page
					// breadcrumbs={[{content: 'Settings', url: '/admin/settings/index'}]}
					title={AppBridge.exist() ? '' : intl.get("Settings")}
					primaryAction={
						// AppBridge.exist() ? '' : 
						{
							content: intl.get("Save"),
							onAction: this.saveData,
							loading: saveDataLoading,
							disabled: disabledSaveData
						}
					}
					separator
				>

					<section className="SettingsPage__Box setting_clearfix">

						<NavTabs selected="Importing rules" saveData={this.saveData} loading={saveDataLoading} disabled={disabledSaveData}/>

						{pageContent}

					</section>

					{toastMarkup}

				</Page>
				{/* 未保存拦截弹窗 */}
				<SettingPrompt disabledSaveData={disabledSaveData} url='/admin/settings/import' saveData={this.saveData} />

			</div>

		);
	}

}

export default ImportingRulesClass;