import {
  BlockStack,
  Box,
  Icon,
  InlineStack,
  Spinner,
  Text,
  TextField,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { WandIcon } from "@shopify/polaris-icons";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  ckeditorConfig,
  reqPostProductManagementProductsAiDescription,
  reqPostProductManagementProductsAiTitle,
} from "../../../../../api";
import { Cards } from "./Cards";
import update from "immutability-helper";
import PreviewImage from "../../../../../components/PreviewImage";
import _ from "lodash";
import AiPointsLessModal from '../../modal/AiPointsLessModal'
const Index = ({ detailData, setDetailData ,userPoints,setIsInput,toastFun}) => {


   // ai优化积分不足
    const [enoughPointsData,setEnoughPointsData] = useState(false);
    const [moreNum,setMoreNum] = useState(8);
  const moveCard = (dragIndex, hoverIndex) => {
    if (detailData?.product?.is_publish) {
      return
    }
    setIsInput(true);
    setDetailData((prev) => {
      let list = prev.product;
      const updatedImages = update(list, {
        all_images: {
          $splice: [
            [dragIndex, 1], // 从 `dragIndex` 移除 1 个元素
            [hoverIndex, 0, list.all_images[dragIndex]], // 在 `hoverIndex` 位置插入拖拽的元素
          ],
        },
      });

      return { ...prev, product: updatedImages };
    });
  };
  const renderCard = (item, index) => {
    return (
      <Cards
        key={index}
        index={index}
        data={item}
        id={index}
        handleMediaSelect={handleMediaSelect}
        handlePreview={handlePreview}
        imgUrl={item.src}
        detailData={detailData}
        moveCard={moveCard}
        deleImage={() => {}}
      />
    );
  };
  const onChangeTitle = (value) => {
    if (detailData?.product?.is_publish) {
      return
    }
    // 标题修改
    if (value.length > 255) {
      return;
    }
    setIsInput(true);
    setAiOptimize((prev)=>({
      ...prev,
      title: value,
    }))
    setDetailData((prev) => {
      return { ...prev, product: { ...prev.product, title: value } };
    });
  };
  const onChangeDescription = (value) => {
    if (detailData?.product?.is_publish) {
      return
    }
    // 描述修改
    // if (value.length > 2000) {
    //   return;
    // }
    setAiOptimize((prev)=>({
      ...prev,
      description: value,
    }))
    
    setDetailData((prev) => {
      return { ...prev, product: { ...prev.product, description: value } };
    });
  };

  //  媒体图片选择状态
  const handleMediaSelect = (ind, val) => {
    if (detailData?.product?.is_publish) {
      return
    }
    let list = detailData.product.all_images;
    let filter = list.filter((item, ind) => item.selected === 1);
    if (val === false && filter.length === 1) {
      toastFun('Retain one item')
      return
    }
    
    list[ind].selected = val ? 1 : 0;
    setIsInput(true);
    setDetailData((prev) => {
      return { ...prev, product: { ...prev.product, all_images: list } };
    });
  };

  // 图片预览
  const [showPreview, setShowPreview] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [previewList, setPreviewList] = useState([]);

  // 图片预览方法
  const handlePreview = (item, index) => {
    setShowPreview(true);
    setPreviewIndex(index);
    let me_list = detailData.product.all_images;
    const list = _.map(me_list, (item, index) => {
      return {
        keyIndex: index,
        src: item.src,
      };
    });
    setPreviewList(list);
  };

  // ai优化
  const [aiOptimize, setAiOptimize] = useState({
    title: "",
    description: "",
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const [descLoading,setDescLoading] = useState(false);
  const handleAiTitleOptimize = async (isPoint) => {
    if (detailData?.product?.is_publish) {
      return
    }
    if (userPoints.userPointsData.available_points < 20 && !isPoint) {
      setEnoughPointsData(true)
      return
    }
    setIsInput(true);
    if (saveLoading || descLoading) return;
    setSaveLoading(true);
  
      const params = {
        title: detailData.product.title,
        record_id: detailData.record_id,
      };
      try {
        const res = await reqPostProductManagementProductsAiTitle(params);
        if (res.code === "200") {
          const text = res.data.optimized_title.slice(0,255);
          let index = 0;
          let currentTitle = "";
          setDetailData((prev) => ({
            ...prev,
            product: { ...prev.product, title: text,is_gpt:true },
          }));
          const intervalId = setInterval(() => {
            if (index < text.length) {
              currentTitle += text.charAt(index);
              setAiOptimize((prev)=>({
                ...prev,
                title: currentTitle,
              }))
              index++;
            } else {
              clearInterval(intervalId);
              setSaveLoading(false);
              toastFun('Optimization Completed')
            }
          }, 100);
        
        } else {
          setSaveLoading(false);
        }
      } catch (error) {
        setSaveLoading(false);
      }
  };// 标题
  const [isShow,setIsShow] = useState(false);
  const handleAiDescriptionOptimize = async (isPoint) => {
    if (detailData?.product?.is_publish) {
      return
    }
    if (userPoints.userPointsData.available_points < 20 && !isPoint) {
      setEnoughPointsData(true)
      return
    }
    setIsInput(true);
    if (saveLoading || descLoading) return;
    setDescLoading(true);
      const params = { record_id: detailData.record_id };
      try {
      
      let response = await reqPostProductManagementProductsAiDescription(params)
      if (response.code === "200") {
        setIsShow(true)
        const text = response.data.optimized_description;
        setDetailData((prev) => ({
          ...prev,
          product: { ...prev.product, description: text,is_gpt:true },
        }));
        setAiOptimize((prev)=>({
          ...prev,
          description: text,
        }))
        setDescLoading(false);
        toastFun('Optimization Completed')
      } else {
        setDescLoading(false);
      }
        
    } catch (error) {
        
    }
  };// 描述

  // 重置标题
  const handleResetTitle = () => {
    if (detailData?.product?.is_publish) {
      return
    }
    setIsInput(true);
    setSaveLoading(true);
    setTimeout(() => {
      setSaveLoading(false);
      setDetailData((prev) => {
        return {
          ...prev,
          product: {
            ...prev.product,
            title: prev.product.original_title,
          },
        };
      });

    }, 2000);
    
  };
  // 重置描述
  
  const handleResetDescription = () => {
    if (detailData?.product?.is_publish) {
      return
    }
    setIsShow(false)
    setIsInput(true);
    setDescLoading(true);
    setTimeout(() => {
      setDescLoading(false);
      setDetailData((prev) => {
        return {
          ...prev,
          product: {
            ...prev.product,
            description: prev.original_description,
          },
        };
      });

    }, 2000);
    
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount && detailData && !saveLoading) {
      setAiOptimize((prev)=>({
        ...prev,
        title: detailData.product.title,
        description: detailData.product.description,
      }))
    }
    return () => {
      isMount = true;
    };
  }, [detailData,saveLoading]);
  return (
    <Box>
      <Text variant="headingSm" as="h6">
        Product Information
      </Text>
      <Box>
        <BlockStack gap={200}>
          <Box className="product_info_title">
            <Box className="product_info_title_label product_info_clo">
              <InlineStack align="space-between" blockAlign="center">
                <span>Title</span>
                <Box className={descLoading ? 'product_info_clo_ai_disabled':"product_info_clo_ai"}>
                  {saveLoading ?  <Spinner  size="small" />: <InlineStack blockAlign="center">
                    {(detailData.product.original_title !== detailData.product.title) && detailData.product.is_gpt ? <span style={{color:"rgba(48, 48, 48, 1)",marginRight:'5px'}}  onClick={()=>{
                      if (descLoading || saveLoading || detailData?.product?.is_publish) {
                        return
                      }
                      handleResetTitle()
                    }}>Restore</span>:''}
                    <Box onClick={() => handleAiTitleOptimize(detailData.product.is_gpt)}>
                    <InlineStack>

                      <Icon source={WandIcon} tone="base" />
                      <p>AI Title Optimization</p>
                      </InlineStack>
                    </Box>
                  </InlineStack>}
                </Box>
              </InlineStack>
            </Box>
            <TextField
              disabled={saveLoading || descLoading || detailData?.product?.is_publish}
              error={aiOptimize.title === ''}
              value={aiOptimize.title}
              onChange={onChangeTitle}
              autoComplete="off"
            />
          </Box>
          <Box className="product_info_description">
            <Box className="product_info_clo">
              <InlineStack align="space-between" blockAlign="center">
                <span>Description</span>
                <Box className={saveLoading ? 'product_info_clo_ai_disabled':"product_info_clo_ai"}>
                  {descLoading ?  <Spinner  size="small" />: <InlineStack blockAlign="center">
                    {(detailData.product.is_gpt && isShow) ? <span style={{color:"rgba(48, 48, 48, 1)",marginRight:'5px'}}  onClick={()=>{
                      if (descLoading || saveLoading || detailData?.product?.is_publish) {
                        return
                      }
                      handleResetDescription()
                    }}>Restore</span>:''}
                    <Box onClick={() => handleAiDescriptionOptimize(detailData.product.is_gpt)}>
                    <InlineStack>
                      <Icon source={WandIcon} tone="base" />
                      <p>AI Description Optimization</p>
                      </InlineStack>
                    </Box>
                  </InlineStack>}
                </Box>
                
              </InlineStack>
            </Box>
            <CKEditor
              editor={ClassicEditor}
              config={ckeditorConfig}
              data={
                aiOptimize.description
                  ? aiOptimize.description
                  : ""
              }
              disabled={descLoading || saveLoading || detailData?.product?.is_publish}
              onChange={(event, editor) => {
                const datas = editor.getData();
                onChangeDescription(datas);
              }}
            />
          </Box>
          <Box>
            <Box className="product_info_clo">Media</Box>
           {(Object.values(detailData).length > 0 && detailData?.product?.all_images.length > 0) ? <Box style={{minHeight:'280px'}}>
              
                <Box className="product_info_media_content">
                  {/* <Box className="product_info_media_box product_info_media_box_bg">
                    {detailData?.product?.all_images
                      .slice(0, 8)
                      .map((item, index) => (
                        <Box
                          key={index}
                          className="product_info_media_box_item"
                        ></Box>
                      ))}
                  </Box> */}
                  <Box className="product_info_media_box">
                    {detailData?.product?.all_images
                      .slice(0, moreNum)
                      .map((item, index) => renderCard(item, index))}
                       {detailData?.product?.all_images?.length > moreNum ? <Box
                      className="product_info_media_box_item product_info_media_box_item_view"
                      onClick={()=>{setMoreNum(detailData?.product?.all_images?.length)}}
                      >
                        <span>View More({detailData?.product?.all_images.length - 8})</span>
                      </Box>:''}
                  </Box>
                 
                </Box>
             
            </Box>:''}
          </Box>
        </BlockStack>
      </Box>
      {/* 图片预览 */}
      <PreviewImage
        isShow={showPreview}
        index={previewIndex}
        imageList={previewList}
        onClose={() => setShowPreview(false)}
      />
      {/* ai优化积分不足 */}
      <AiPointsLessModal userPoints={userPoints} enoughPointsData={enoughPointsData} onClose={()=>setEnoughPointsData(false)}/>
    </Box>
  );
};

export default Index;
