import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Collapsible,
  Toast,
  Tooltip,
} from "@shopify/polaris";
import React, { useState } from "react";
import PreviewImage from "../../../../../components/PreviewImage";
import ToolTag from "../../../../../components/ToolTag";
import SymbolTake from "../../../../../components/SymbolTake";
import "./index.css";
import OrderNoteModal from "../../../../../components/OrderNoteModal";
const OrderItm = ({listData,setPageData,handlePaymentButton,handleSaveData}) => {

    const [previewData,setPreviewData] = useState({
        showPreview:false,
        previewIndex:0,
        previewList: [],
    });
    const handleSelectImage = ()=>{}
 const handlePreview = (item, ind) => {
    const list = [
      {
        keyIndex: ind,
        src: item.image_url,
      },
    ];
    setPreviewData((prevValue)=>
        ({ ...prevValue,showPreview: true, previewIndex: ind, previewList: list }));
  };
  // 总费用
  const total = (product, discount) => {
    let count =
      parseFloat(product)-
      parseFloat(discount);
    return count.toFixed(2);
  };

  // 单选
  const handleItemSelect = (val, id) => {
    let newList = listData.map((item, ind) => {
      if (item.dsm_record === id) {
        return { ...item, checked: val ? 1 : 0 };
      } else {
        return item;
      }
    });
    setPageData((prevValue)=>({
      ...prevValue,
      list: newList,
    })) //回调存储
  };

  const [itemData,setItemData] = useState({
    currentOrderId: '',
    orderNote: '',
    open: false
  })
  // 备注操作
 const handleOrderNote = (id,note)=>{
  setItemData((prevValue)=>({
    ...prevValue,
    currentOrderId: id,
    orderNote: note,
    open: true
  }))
  }

  const handlePreOrderOpen = (id)=>{
    let newList = listData.map((item, ind) => {
      if (item.dsm_record === id) {
        return { ...item, open: !item.open };
      } else {
        return item;
      }
    });
    setPageData((prevValue)=>({
      ...prevValue,
      list: newList,
    })) //回调存储
  }
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;
  
  
  return (
    <Box className="order_item_content">
      <BlockStack gap="500">
        {listData.map((item, ind) => (
          <Card key={ind}>
            <Box className="order_item_list">
              <Box className="order_item_list_box">
                <Box className="order_item_checkbox">
                  <Checkbox
                    checked={item.checked}
                    onChange={(val) => {
                      handleItemSelect(val, item.dsm_record);
                    }}
                  />
                </Box>
                <Box className="order_item_brand">
                  <Box className="order_item_brand_box">
                    <Box className="order_item_brand_id" onClick={()=>handlePreOrderOpen(item.dsm_record)}>
                      <img width={20} src={"/dropshipman-logo.png"} alt="Dropshipman" />
                      <span className="Polaris-Link">{item?.dsm_order_name}</span>
                    </Box>
                    <Box className="order_item_brand_date">
                      {item?.dsm_date}
                    </Box>
                    <Box className="order_item_brand_status">
                      {item?.dsm_payment_status !== "" && (
                        <ToolTag content={item?.dsm_payment_status} />
                      )}
                      {item?.dsm_shipment_status !== "" &&
                        (
                          <ToolTag content={item?.dsm_shipment_status} />
                        )}
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="order_item_customer"
                  style={{ position: "relative" }}
                ></Box>
                <Box className="order_item_shipping"></Box>
                <Box className="order_item_button">
                    <div
                      onClick={() => {
                        handleOrderNote(item.dsm_record, item.dsm_note);
                      }}
                    >
                      <img
                        src={"/flow.png"}
                        alt="dropshipman"
                        height={28}
                        style={{ marginTop: "4px", cursor: "pointer" }}
                      />
                    </div>
                </Box>
              </Box>
              <Box className={item?.open && "order_item_collapsible"}>
                <Collapsible
                  open={item.open}
                  id="basic-collapsible"
                  transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                  }}
                  expandOnPrint
                >
                  <Box className={"order_item_collapsible_box"}>
                    {item?.order_detail?.length > 0 &&
                      item?.order_detail.map((it, k) => (
                        <Box
                          key={k}
                          className="order_item_collapsible_box_item"
                        >
                          <Box className="collapsible_box_item_img">
                            <div
                              className="image_box_overlay "
                              onClick={() => handleSelectImage(it)}
                            >
                              <div className="image_preview_action">
                                <div
                                  className="image_preview__svg"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handlePreview(it, k);
                                  }}
                                >
                                  <svg
                                    viewBox="64 64 896 896"
                                    data-icon="eye"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    focusable="false"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                                    ></path>
                                  </svg>
                                </div>
                                <img
                                  src={it.image_url}
                                  className="list_item_image"
                                  alt="product"
                                />
                              </div>
                            </div>
                          </Box>
                          <Box className="collapsible_box_item_title">
                            <Box title={it?.title}>{it?.title}</Box>
                            <Box>{it?.variant_title}</Box>
                          </Box>
                          <Box className="collapsible_box_item_cost">
                            Cost: ${Number(it.price)} x {Number(it.quantity)}
                          </Box>
                          <Box className="collapsible_box_item_total">
                            Total: <SymbolTake price={(Number(it.price) * Number(it.quantity)).toFixed(
                              2
                            )} /> 
                            {/* $
                            {(Number(it.price) * Number(it.quantity)).toFixed(
                              2
                            )} */}
                          </Box>
                          <Box
                            className="collapsible_box_item_refund"
                            style={{ marginRight: "6px" }}
                          >
                            <Box style={{ marginTop: "5px" }}>
                              {it.dsm_refund_count > 0 && (
                                <Tooltip
                                  dismissOnMouseOut
                                  content={
                                    it.quantity > it.dsm_refund_count
                                      ? `Partially Refund (DSM): ${it.dsm_refund_count}`
                                      : "Refund (DSM)"
                                  }
                                >
                                  <Badge tone={"critical"}>
                                    {it.quantity > it.dsm_refund_count
                                      ? `Partially Refund (DSM): ${it.dsm_refund_count}`
                                      : "Refund (DSM)"}
                                  </Badge>
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                         
                        </Box>
                      ))}
                  </Box>
                  <Box className="collapsible_box_item_bottom">
                    <Box className="collapsible_box_item_bottom_clo">
                      <strong>Product cost:</strong> 
                      <SymbolTake price={item?.product_cost} /> 
                      {/* ${item?.product_cost} */}
                    </Box>
                    <Box className="collapsible_box_item_bottom_clo">
                      <strong>Discount:</strong> 
                      <SymbolTake price={item?.coupon_money} />
                      {/* ${item?.coupon_money} */}
                    </Box>
                    <Box className="collapsible_box_item_bottom_clo">
                      <strong>Total cost:</strong> 
                      <SymbolTake price={total(
                        item.product_cost,
                        item.coupon_money
                      )} />
                      {/* $
                      {total(
                        item.product_cost,
                        item.coupon_money
                      )} */}
                    </Box>

                    <Box>
                      {(item.dsm_payment_status === "Unpaid" ||
                        item.dsm_payment_status === "Partially paid") && (
                        <Button
                          variant="primary"
                          onClick={()=>handlePaymentButton([item.dsm_record])
                          }
                        >
                          Payment
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Collapsible>
              </Box>
            </Box>
          </Card>
        ))}
      </BlockStack>
      <PreviewImage
        isShow={previewData.showPreview}
        index={previewData.previewIndex}
        imageList={previewData.previewList}
        onClose={() => {setPreviewData((prevValue)=>({...prevValue,showPreview:false}))
        }}
      />
      

      {/* 订单备注弹窗 */}
      <OrderNoteModal
          itemData={itemData}
          content={listData}
          setPageData={setPageData}
          setOrderNote={setItemData}
        />

      {/* sku移除弹窗 */}
      {/* <CancelSkuModal
          currentOrderData={currentOrderData}
          getOrderListData={getListData}
          setCurrentOrderData={this.setCurrentOrderData}
          skuCount={2} 
        /> */}
      {/* 物流追踪弹窗 */}
      {/* <OrderTracking
          show={trackModal}
          orderId={currentOrderId}
          handleShowTracking={this.handleShowTracking}
        /> */}

      {/* 退款详情弹窗 销售绑定信息 */}
      {/* <RefundTipsModal
          open={isShowRefundTipsModal}
          datas={currentOrderData.data}
          setIsShowRefundTipsModal={() => this.setIsShowRefundTipsModal(false)}
        /> */}
      {toastMarkup}
    </Box>
  );
};


export default OrderItm;
