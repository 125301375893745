import React, { memo } from "react";
import { Modal } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";

const ArchiveOrderModal = memo(
  ({ setDlocalTip,dlocalTip,getOrderListData}) => {
    return (
      <div>
        <Modal
          open={dlocalTip}
          onClose={() => {
            setDlocalTip(false);
            getOrderListData();
          }}
          title={intl.get("Payment Confirm")}
          primaryAction={[
            {
              content: intl.get("Okay"),
              onAction: () => {
                setDlocalTip(false);
                getOrderListData();
              },
            },
          ]}
        >
          <Modal.Section>
            {`Please ensure that you have completed the payment for your order and confirmed that the Dropshipman order status has changed to "Paid" before the order can be fulfilled.`}
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default ArchiveOrderModal;
