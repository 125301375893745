import { Box, Button, InlineStack } from '@shopify/polaris';
import React from 'react';

const FirstTimeTips = ({popupStyle,setShowPopup,typeTarget})=> {
    console.log("popupStyle",typeTarget);
    
    return (
        <Box className={"first_time_popup_box"}
        style={{
          position: "fixed",
          color: "#fff",
          borderRadius: "5px",
          zIndex: 1000,
          whiteSpace: "nowrap",
          ...popupStyle, // 动态位置样式
        }}>
           {typeTarget === "list" ?  <InlineStack gap={300}>
            <Box>🎉 Successfully listed!!<br />
            The listed item is shown in the 'Listed' tab.</Box>
            <Button onClick={()=>setShowPopup(false)}>Okay, I see</Button>
            </InlineStack> : <InlineStack gap={300}>
            <Box>🎉 Successfully Restored!!<br />
            The restored item is now in 'Not Listed' and can be re-listed.</Box>
            <Button onClick={()=>setShowPopup(false)}>Okay, I see</Button>
            </InlineStack>}
        </Box>
    );
}

export default FirstTimeTips;
