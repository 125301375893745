import {
  Badge,
  Box,
  Button,
  Checkbox,
  InlineStack,
  Modal,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import utils from "../../../../../utils/utils"
// soucing次数不足弹窗
const Index = ({
  sourcingLimitModal,
  setSourcingLimitModal,
  subList,
  userPoints,
  handleSubmit,
  type,
  sourceLess
}) => {
  
  
  // 获取当前时间
  const getCurrentTime = () => {
    const timestamp = Date.parse(new Date()) / 1000;
    return timestamp;
  };
  const [selectId,setSelectId] = useState(0);
  const [isBulkSourcing,setIsBulkSourcing] = useState(false);
  const plan_level = useSelector(
    (state) => state.languageReducer.subScriptData
  );
  const router = useHistory();
  const formatNumber = (num) => {
    return parseFloat(num.toFixed(2)).toString();
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount && sourcingLimitModal) {
      if (sourceLess) {
        let num = Number(sourceLess.sourceBulk.length * 200) > Number(userPoints.userPointsData.available_points);
        if (sourceLess?.user_product_id === '') {
          setIsBulkSourcing(true)
        }else {
          setIsBulkSourcing(false)
        }
        if (sourceLess?.user_product_id === '' && (plan_level.bill_level  === 0  && plan_level.level === 0)) {
          setSelectId(1)
        }else if (sourceLess?.user_product_id === '' && num && (plan_level.bill_level  === 4  || plan_level.level === 4)) {
          setSelectId(1)
        }else if (sourceLess?.user_product_id === '' && num && (plan_level.bill_level  === 1  || plan_level.level === 1)) {
          setSelectId(2)
        }else if (sourceLess?.user_product_id === '' && num && (plan_level.bill_level  === 2  || plan_level.level === 2)) {
          setSelectId(5)
        }else {
          setSelectId(0)
        }
      }else {
        setSelectId(0)
      }
    }
    return () => {
      isMount = true;
    };
  }, [sourcingLimitModal,sourceLess,plan_level,userPoints])
  
  // sourcing 非会员、basic会员 展示升级套餐
  let isNotProMore = ()=>{
    let flag = false;
    if (sourceLess && sourceLess?.user_product_id ==='' && (plan_level.bill_level === 0 || plan_level.bill_level === 4 || plan_level.level === 0 || plan_level.level === 4)) {
      flag = true;
    }
    return flag;
  }
  
  // sourcing pro会员、plus会员 展示升级套餐
  return (
    <Box>
      <Modal
        open={sourcingLimitModal}
        onClose={() => {
          setSourcingLimitModal(false);
        }}
      >
        {type === 'sourcing' && <Box className="sourcing_enough_quota">
          <InlineStack wrap={false}>
            <Box className="sourcing_enough_quota_left">
              <Box>
                <img src="/new-product/source_product_upgrade.png" alt="sourcing" />
              </Box>
            </Box>
            <Box className="sourcing_enough_quota_right">
              <Box className="sourcing_enough_quota_title">
             {(isNotProMore()) ? 'Bulk sourcing requires the Pro package or above' : 'Sourcing request quota exceeded. Increase quota limit'}
              </Box>
              {(isNotProMore()) ? '': <Box className="sourcing_enough_quota_subtitle">
                Souring Benefits
              </Box>}
              {(isNotProMore()) ? <Box style={{marginBottom:'16px'}}>Select the sourcing plan that best suits your needs</Box>: <Box className="sourcing_enough_quota_subtitle_li">
                <ul>
                  <li>
                  Personalized Product Sourcing
                  </li>
                  <li>
                  Competitive Pricing
                  </li>
                </ul>
              </Box>}
              <Box className="sourcing_enough_quota_list">
                <InlineStack gap={200}>
                  {!isNotProMore() && (plan_level.bill_level  === 0  || plan_level.level === 0) && subList.slice(3,4).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.source} Sourcing/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_basic_discounts < 1 && item.user_discounts.event_basic_discounts !== '' &&
                                getCurrentTime() <
                                  item.user_discounts.basic_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(
                                      item.money * item.user_discounts.event_basic_discounts)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(item.user_discounts.event_basic_discounts < 1 &&
                              getCurrentTime() <
                                item.user_discounts.basic_active_time && item.user_discounts.event_basic_discounts !=='') ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 -  item.user_discounts.event_basic_discounts ) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                   {(plan_level.bill_level  < 1 || plan_level.bill_level === 4 || plan_level.level===4  || plan_level.level < 1) &&subList.slice(1,2).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.source} Sourcing/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                                getCurrentTime() <
                                  item.user_discounts.event_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money * item.user_discounts
                                              .event_discounts *
                                        100
                                    ) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start && item.discount <1 &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                              getCurrentTime() <
                                item.user_discounts.event_active_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.user_discounts.event_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {(plan_level.bill_level  < 2 || plan_level.bill_level === 4 || plan_level.level===4   || plan_level.level < 2) && subList.slice(2,3).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.source} Sourcing/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {
                                getCurrentTime() <
                                  item.user_discounts.plus_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money * item.user_discounts.event_plus_discounts*100) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time && item.discount <1 ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {(item.money * item.discount).toFixed(2)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(item.user_discounts.event_plus_discounts !=='') &&
                              getCurrentTime() <
                                item.user_discounts.plus_active_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 -  item.user_discounts.event_plus_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) :item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time && item.discount <1 ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {(plan_level.bill_level  !== 5  || plan_level.level !== 5) && subList.slice(4,5).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.source} Sourcing/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_premium_discounts < 1 && item.user_discounts.event_premium_discounts !=='' &&
                                getCurrentTime() <
                                  item.user_discounts.premium_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money *
                                        (item.user_discounts
                                              .event_premium_discounts
                                          ) *
                                        100
                                    ) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(
                              getCurrentTime() <
                                item.user_discounts.premium_active_time && item.user_discounts.event_premium_discounts !=='') ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.user_discounts.event_premium_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) :item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {!isBulkSourcing && userPoints.userPointsData.available_points >= 200 ? (
                    <Box className={
                        selectId === 6
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }>
                      <InlineStack align="space-between" blockAlign="center">
                        <Box className="sourcing_enough_quota_item_content">
                        <InlineStack wrap={false} gap={200}>
                          <strong>1 Sourcing </strong>
                          <p>200 Points each sourcing</p>
                          </InlineStack>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === 6}  onChange={()=>setSelectId(6)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ) : (
                    ""
                  )}
                </InlineStack>
              </Box>
              <Box className="sourcing_enough_quota_item_link" onClick={()=>{
                utils.routeListen('/admin/subscribe');
                utils.routeJump1(router,'/admin/subscribe')
              }}>
              Learn more about pricing
              </Box>
              <Box className="sourcing_enough_quota_item_button">
               <Button variant="primary" onClick={()=>handleSubmit(selectId)}>{selectId === 6 || (plan_level.level === 5 || plan_level.bill_level === 5) ? "Redeem with Points" : "Upgrade Now"}</Button>
              </Box>
            </Box>
          </InlineStack>
        </Box>}
        {type === 'product' && <Box className="sourcing_enough_quota">
          <InlineStack wrap={false}>
            <Box className="sourcing_enough_quota_left">
            <Box>
                <img src="/new-product/add_product_upgrade.png" alt="sourcing" />
              </Box>
            </Box>
            <Box className="sourcing_enough_quota_right">
              <Box className="sourcing_enough_quota_title">
              Insufficient quota. Upgrade quota limit
              </Box>
              
              <Box>
               
              </Box>
              <Box className="sourcing_enough_quota_list">
                <InlineStack gap={200}>
                  {(plan_level.bill_level  === 0  || plan_level.level === 0) && subList.slice(3,4).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.dsm_product} product/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_basic_discounts < 1 && item.user_discounts.event_basic_discounts !== '' &&
                                getCurrentTime() <
                                  item.user_discounts.basic_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(
                                      item.money * item.user_discounts.event_basic_discounts)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(item.user_discounts.event_basic_discounts < 1 &&
                              getCurrentTime() <
                                item.user_discounts.basic_active_time && item.user_discounts.event_basic_discounts !=='') ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.user_discounts.event_basic_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                   {(plan_level.bill_level  < 1 || plan_level.bill_level === 4 || plan_level.level===4  || plan_level.level < 1) &&subList.slice(1,2).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.dsm_product} product/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                                getCurrentTime() <
                                  item.user_discounts.event_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money * item.user_discounts
                                              .event_discounts *
                                        100
                                    ) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start && item.discount <1 &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                              getCurrentTime() <
                                item.user_discounts.event_active_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.user_discounts.event_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {(plan_level.bill_level  < 2 || plan_level.bill_level === 4 || plan_level.level===4   || plan_level.level < 2) && subList.slice(2,3).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.dsm_product} product/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {
                                  item.user_discounts.event_plus_discounts !== '' &&
                                getCurrentTime() <
                                  item.user_discounts.plus_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money * item.user_discounts.event_plus_discounts *
                                        100
                                      ) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time && item.discount <1 ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {(item.money * item.discount).toFixed(2)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(item.user_discounts.event_plus_discounts !=='') &&
                              getCurrentTime() <
                                item.user_discounts.plus_active_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 -  item.user_discounts.event_plus_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) :item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time && item.discount <1 ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {(plan_level.bill_level  !== 5  || plan_level.level !== 5) && subList.slice(4,5).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.dsm_product} product/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_premium_discounts < 1 && item.user_discounts.event_premium_discounts !=='' &&
                                getCurrentTime() <
                                  item.user_discounts.premium_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money *
                                        (item.user_discounts
                                              .event_premium_discounts
                                          ) *
                                        100
                                    ) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(
                              getCurrentTime() <
                                item.user_discounts.premium_active_time && item.user_discounts.event_premium_discounts !=='') ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1- item.user_discounts.event_premium_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) :item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {userPoints.userPointsData.available_points >= 150 ? (
                    <Box className={
                        selectId === 6
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }>
                      <InlineStack align="space-between" blockAlign="center">
                        <Box className="sourcing_enough_quota_item_content">
                        <InlineStack wrap={false} gap={200}>
                          <strong>1 Import </strong>
                          <p>150 Points each Import</p>
                          </InlineStack>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === 6}  onChange={()=>setSelectId(6)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ) : (
                    ""
                  )}
                </InlineStack>
              </Box>
              <Box className="sourcing_enough_quota_item_link" onClick={()=>{
                utils.routeListen('/admin/subscribe');
                utils.routeJump1(router,'/admin/subscribe')
              }}>
              Learn more about pricing
              </Box>
              <Box className="sourcing_enough_quota_item_button">
               <Button variant="primary" onClick={()=>handleSubmit(selectId)}>{selectId === 6 || (plan_level.level === 5 || plan_level.bill_level === 5) ? "Redeem with Points" : "Upgrade Now"}</Button>
              </Box>
            </Box>
          </InlineStack>
        </Box>}
        {type === 'aliexpress' && <Box className="sourcing_enough_quota">
          <InlineStack wrap={false}>
            <Box className="sourcing_enough_quota_left">
            <Box>
                <img src="/new-product/ali_product_upgrade.png" alt="sourcing" />
              </Box>
            </Box>
            <Box className="sourcing_enough_quota_right">
              <Box className="sourcing_enough_quota_title">
              Insufficient quota. Upgrade quota limit
              </Box>
              <Box className="sourcing_enough_quota_subtitle">
              Import AliExpress product feature Benefits
              </Box>
              <Box className="sourcing_enough_quota_subtitle_li">
                <ul>
                  <li>
                  One-Click AliExpress Product Import
                  </li>
                  <li>
                  Complete AliExpress Data Collection
                  </li>
                </ul>
                
              </Box>
              <Box className="sourcing_enough_quota_list">
                <InlineStack gap={200}>
                  {(plan_level.bill_level  === 0  || plan_level.level === 0) && subList.slice(3,4).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.aliexpress_product} product/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_basic_discounts < 1 && item.user_discounts.event_basic_discounts !== ''&&
                                getCurrentTime() <
                                  item.user_discounts.basic_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(
                                      item.money * item.user_discounts.event_basic_discounts)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(item.user_discounts.event_basic_discounts < 1 &&
                              getCurrentTime() <
                                item.user_discounts.basic_active_time && item.user_discounts.event_basic_discounts !=='') ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1- item.user_discounts.event_basic_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                   {(plan_level.bill_level  < 1 || plan_level.bill_level === 4 || plan_level.level===4  || plan_level.level < 1) &&subList.slice(1,2).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.aliexpress_product} product/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                                getCurrentTime() <
                                  item.user_discounts.event_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money * item.user_discounts
                                              .event_discounts *
                                        100
                                    ) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start && item.discount <1 &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !=='' &&
                              getCurrentTime() <
                                item.user_discounts.event_active_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.user_discounts.event_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {(plan_level.bill_level  < 2 || plan_level.bill_level === 4 || plan_level.level===4   || plan_level.level < 2) && subList.slice(2,3).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.aliexpress_product} product/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_plus_discounts < 1 && item.user_discounts.event_plus_discounts !=='' &&
                                getCurrentTime() <
                                  item.user_discounts.plus_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money *
                                        (item.user_discounts
                                              .event_plus_discounts) *
                                        100
                                      ) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time && item.discount <1 ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {(item.money * item.discount).toFixed(2)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(item.user_discounts.event_plus_discounts !=='') &&
                              getCurrentTime() <
                                item.user_discounts.plus_active_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 -  item.user_discounts.event_plus_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) :item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time && item.discount <1 ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {(plan_level.bill_level  !== 5  || plan_level.level !== 5) && subList.slice(4,5).map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        selectId === item.id
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }
                    >
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                      >
                        <Box className="sourcing_enough_quota_item_content">
                          <strong>{item.aliexpress_product} product/month </strong>
                          <Box>
                            <InlineStack wrap={false} blockAlign="center">
                              <p>
                                {item.user_discounts.event_premium_discounts < 1 &&
                                getCurrentTime() <
                                  item.user_discounts.plus_active_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber((
                                      item.money *
                                        (item.user_discounts
                                              .event_premium_discounts
                                          ) *
                                        100
                                    ) / 100)}
                                    /month
                                  </span>
                                ) : getCurrentTime() >= item.discount_start &&
                                  getCurrentTime() <= item.discount_time ? (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} $
                                    {formatNumber(item.money * item.discount)}
                                    /month
                                  </span>
                                ) : (
                                  <span style={{ margin: "0 5px" }}>
                                    {item.name} ${item.money}/month
                                  </span>
                                )}
                              </p>
                              {(
                              getCurrentTime() <
                                item.user_discounts.plus_active_time && item.user_discounts.event_premium_discounts !=='') ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.user_discounts.event_premium_discounts) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) :item.discount <1 && getCurrentTime() >= item.discount_start &&
                                getCurrentTime() <= item.discount_time ? (
                                <Badge tone="success">
                                  {parseFloat(
                                    (1 - item.discount) * 100
                                  ).toFixed(0)}
                                  % OFF
                                </Badge>
                              ) : (
                                ""
                              )}
                            </InlineStack>
                          </Box>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === item.id}  onChange={()=>setSelectId(item.id)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ))}
                  {userPoints.userPointsData.available_points >= 150 ? (
                    <Box className={
                        selectId === 6
                          ? "sourcing_enough_quota_item sourcing_enough_quota_item_active"
                          : "sourcing_enough_quota_item"
                      }>
                      <InlineStack align="space-between" blockAlign="center">
                        <Box className="sourcing_enough_quota_item_content">
                        <InlineStack wrap={false} gap={200}>
                          <strong>1 Import </strong>
                          <p>150 Points each Import</p>
                          </InlineStack>
                        </Box>
                        <Box className="sourcing_enough_quota_item_checkbox">
                          <Checkbox checked={selectId === 6}  onChange={()=>setSelectId(6)}/>
                        </Box>
                      </InlineStack>
                    </Box>
                  ) : (
                    ""
                  )}
                </InlineStack>
              </Box>
              <Box className="sourcing_enough_quota_item_link" onClick={()=>{
                utils.routeListen('/admin/subscribe');
                utils.routeJump1(router,'/admin/subscribe')
              }}>
              Learn more about pricing
              </Box>
              <Box className="sourcing_enough_quota_item_button">
               <Button variant="primary" onClick={()=>{
                if (selectId === 0) {
                  return
                }
                 handleSubmit(selectId)
                 }}>{selectId === 6 || (plan_level.level === 5 || plan_level.bill_level === 5) ? "Redeem with Points" : "Upgrade Now"}</Button>
              </Box>
            </Box>
          </InlineStack>
        </Box>}
      </Modal>
    </Box>
  );
};

export default Index;
