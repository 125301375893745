import { InlineStack, Box, Card, Scrollable, Icon,TextField, Autocomplete } from "@shopify/polaris";
import React, { useEffect, useState,useCallback } from "react";
import { CheckIcon, ChevronRightIcon,SearchIcon } from "@shopify/polaris-icons";
const ProductCateList = ({cateData,requestParams,handleSelectCate,boxRef}) => {
        const [selectId,setSelectId] = useState({
            id1:'All Category',
            id2:'',
            id3:''
        })
        const [selectName,setSelectName] = useState({
            name1:'',
            name2:'',
            name3:''
        })
        const handleSelectName = (id1,id2,id3)=>{
            setSelectId((prevValue)=>({
                ...prevValue,
                id1:id1,
                id2:id2,
                id3:id3
            }))
        }
        const getCurrentSelect = () => {
          let id = requestParams.category_id;
          
          // 遍历顶级分类
          for (let i = 0; i < cateData.length; i++) {
            const category = cateData[i];
        
            // 如果匹配到顶级分类
            if (category.category_id === Number(id)) {
              setSelectId((prevValue) => ({
                ...prevValue,
                id1: category.category_id,
              }));
              setSelectName((prevValue)=>({
                ...prevValue,
                name1:i
            }))
              return;
            }
        
            // 遍历子分类
            for (let j = 0; j < category.children.length; j++) {
              const subCategory = category.children[j];
        
              // 如果匹配到子分类
              if (subCategory.category_id === Number(id)) {
                setSelectId((prevValue) => ({
                  ...prevValue,
                  id1: category.category_id,
                  id2: subCategory.category_id,
                }));
                setSelectName((prevValue)=>({
                  ...prevValue,
                  name1:i,
                  name2:j
              }))
                return;
              }
        
              // 遍历三级分类
              for (let k = 0; k < subCategory.children.length; k++) {
                const thirdCategory = subCategory.children[k];
        
                // 如果匹配到三级分类
                if (thirdCategory.category_id === Number(id)) {
                  setSelectId((prevValue) => ({
                    ...prevValue,
                    id1: category.category_id,
                    id2: subCategory.category_id,
                    id3: thirdCategory.category_id,
                  }));
                  setSelectName((prevValue)=>({
                    ...prevValue,
                    name1:i,
                    name2:j,
                    name3:k
                }))
                  return;
                }
              }
            }
          }
        };
        
        useEffect(()=>{
          let isMount = false;
          if (!isMount) {
            getCurrentSelect()
          }
          return ()=>{
            isMount = true;
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        const [selectedOptions, setSelectedOptions] = useState([]);
        const [inputValue, setInputValue] = useState('');
        const [options, setOptions] = useState([]);
      
        
        const getOpt = useCallback((val) => {
          if (!val) return [];
        
          const list = [];
          const searchCategory = (categories) => {
            categories.forEach((category) => {
              if (category.category_name.includes(val)) {
                list.push({ label: category.category_name, value: category.category_id });
              }
              if (category.children?.length) searchCategory(category.children);
            });
          };
        
          searchCategory(cateData);
          return list;
        }, [cateData]);// 输入框值变化时，调用函数生成选项 
        // 输入框输入
        const updateText = useCallback(
          (value) => {
            setInputValue(value);
        
            // 如果输入为空，直接设置空选项
            if (!value.trim()) {
              setOptions([]);
              return;
            }
        
            // 调用 getOpt 一次，避免重复调用
            const filteredOptions = getOpt(value);
            setOptions(filteredOptions);
          },
          [getOpt] 
        );
        // 选择值变化时
        const updateSelection = useCallback(
          (selected) => {
            const selectedValue = selected.map((selectedItem) => {
              const matchedOption = options.find((option) => {
                return option.value.match(selectedItem);
              });
              return matchedOption && matchedOption.label;
            });
            
            setSelectedOptions(selected);
            setInputValue(selectedValue[0] || '');
          },
          [options],
        );
        
        const textField = (
          <TextField
          placeholder="Search segments"
          type="text"
          value={inputValue}
          onChange={updateText}
          prefix={<Icon source={SearchIcon} tone="base" />}
          autoComplete="off"
          clearButton="auto"
          onClearButtonClick={()=>updateText('')}
          />
        );
  return (
    <Box className="product_selection_cate_content" ref={boxRef}>
      <InlineStack wrap={false} direction={'row'}>
        <Box className="product_selection_cate_content_box" style={{ width: "300px", height: "300px" }}>
          <Card padding={0}>
            <Box className="product_selection_cate_content_search">
            <Autocomplete
                options={options}
                selected={selectedOptions}
                onSelect={updateSelection}
                textField={textField}
            />
            </Box>
            <Scrollable style={{ height: "250px",background:'#fff' }}>
             {options.length > 0 ? <Box  className="product_selection_cate_content_list">
                {options.map((item, ind) => (
                  <Box key={ind}  className={item.value === requestParams.category_id ? "product_selection_cate_content_item current_active" : "product_selection_cate_content_item"} onClick={()=>{
                    if (item.value === requestParams.category_id) {
                      return;
                    }
                    handleSelectName(item.value,'','')
                    handleSelectCate(item.value,item.label)
                  }}>
                    <InlineStack align="space-between" blockAlign="center">
                      <Box>{item.label}</Box>
                      <Box>
                        {item.value === requestParams.category_id ? (
                          <span>
                            <Icon source={CheckIcon} tone="base" />
                          </span>
                        ) : (
                          <span>
                           
                          </span>
                        )}
                      </Box>
                    </InlineStack>
                  </Box>
                ))}
              </Box> : <Box  className="product_selection_cate_content_list">
              <Box className={selectId.id1 === "All Category" ? "product_selection_cate_content_item current_active" : (selectName.name1 === "" && selectId.id1 === "All Category") ? "product_selection_cate_content_item cat_active" : "product_selection_cate_content_item"} onMouseEnter={()=>{
                    setSelectName((prevValue)=>({
                        ...prevValue,
                        name1:''
                    }))
                  }} onClick={()=>{
                    handleSelectName('All Category','','')
                    handleSelectCate('','All Category')
                  }}>
                    <InlineStack align="space-between" blockAlign="center">
                      <Box>{'All Category'}</Box>
                      <Box>
                        {selectId.id1 === 'All Category' ? (
                          <span>
                            <Icon source={CheckIcon} tone="base" />
                          </span>
                        ) : (
                          <span>
                          </span>
                        )}
                      </Box>
                    </InlineStack>
                  </Box>
                {cateData.map((item, ind) => (
                  <Box key={ind}  className={selectId.id1 === item.category_id ? "product_selection_cate_content_item current_active" : selectName.name1 === ind ? "product_selection_cate_content_item cat_active" : "product_selection_cate_content_item"} onMouseEnter={()=>{
                    setSelectName((prevValue)=>({
                        ...prevValue,
                        name1:ind,
                        name2:'',
                        name3:''
                    }))
                  }} onClick={()=>{
                    handleSelectName(item.category_id,'','')
                    handleSelectCate(item.category_id,item.category_name)
                  }}>
                    <InlineStack align="space-between" blockAlign="center">
                      <Box>{item.category_name}</Box>
                      <Box>
                        {selectId.id1 === item.category_id ? (
                          <span>
                            <Icon source={CheckIcon} tone="base" />
                          </span>
                        ) : (
                          <span onClick={(e)=>{
                            e.stopPropagation();
                            setSelectName((prevValue)=>({
                              ...prevValue,
                              name1:ind,
                              name2:'',
                              name3:''
                          }))
                          }}>
                            <Icon source={ChevronRightIcon} tone="base" />
                          </span>
                        )}
                      </Box>
                    </InlineStack>
                  </Box>
                ))}
              </Box>}
            </Scrollable>
          </Card>
        </Box>
        {options.length === 0 && selectName?.name1 !=='' && <Box className="product_selection_cate_content_box" style={{ width: "300px", height: "300px",margin:'0 5px'}}>
          <Card padding={0}>
            <Scrollable style={{ height: "300px",background:'#fff' }}>
            <Box  className="product_selection_cate_content_list">
                {cateData[selectName?.name1 ? selectName?.name1 : 0].children.map((item, ind) => (
                  <Box key={ind}  className={selectId.id2 === item.category_id ? "product_selection_cate_content_item current_active" : selectName.name2 === ind ? "product_selection_cate_content_item cat_active" : "product_selection_cate_content_item"} onMouseEnter={()=>{
                    setSelectName((prevValue)=>({
                        ...prevValue,
                        name2:ind,
                        name3:''
                    }))
                  }} onClick={()=>{
                    handleSelectName(cateData[selectName?.name1 ? selectName?.name1 : 0].category_id,item.category_id,'')
                    handleSelectCate(item.category_id,item.category_name)
                  }}>
                    <InlineStack align="space-between" blockAlign="center">
                      <Box>{item.category_name}</Box>
                      <Box>
                        {selectId.id2 === item.category_id ? (
                          <span>
                            <Icon source={CheckIcon} tone="base" />
                          </span>
                        ) : (
                          <span onClick={(e)=>{
                            e.stopPropagation();
                            setSelectName((prevValue)=>({
                              ...prevValue,
                              name2:ind,
                              name3:''
                          }))
                          }}>
                            <Icon source={ChevronRightIcon} tone="base" />
                          </span>
                        )}
                      </Box>
                    </InlineStack>
                  </Box>
                ))}
              </Box>
            </Scrollable>
          </Card>
        </Box>}
        {options.length === 0 && selectName?.name2 !=='' && <Box className="product_selection_cate_content_box" style={{ width: "300px", height: "300px" }}>
          <Card padding={0}>
            <Scrollable style={{ height: "300px",background:'#fff' }}>
            <Box  className="product_selection_cate_content_list">
                {cateData[selectName?.name1 ? selectName?.name1 : 0].children[selectName?.name2 ? selectName?.name2 : 0].children.map((item, ind) => (
                  <Box key={ind}  className={selectId.id3 === item.category_id ? "product_selection_cate_content_item current_active" :  "product_selection_cate_content_item"} onMouseEnter={()=>{
                    setSelectName((prevValue)=>({
                        ...prevValue,
                        name3:ind
                    }))
                  }} onClick={()=>{
                    handleSelectName(cateData[selectName?.name1 ? selectName?.name1 : 0].category_id,cateData[selectName?.name1 ? selectName?.name1 : 0].children[selectName?.name2 ? selectName?.name2 : 0].category_id,item.category_id)
                    handleSelectCate(item.category_id,item.category_name)
                  }}>
                    <InlineStack align="space-between" blockAlign="center">
                      <Box>{item.category_name}</Box>
                      <Box>
                        {selectId.id3 === item.category_id ? (
                          <span>
                            <Icon source={CheckIcon} tone="base" />
                          </span>
                        ) : (
                          <span>
                            {/* <Icon source={ChevronRightIcon} tone="base" /> */}
                          </span>
                        )}
                      </Box>
                    </InlineStack>
                  </Box>
                ))}
              </Box>
            </Scrollable>
          </Card>
        </Box>}
      </InlineStack>
    </Box>
  );
};



export default ProductCateList;
