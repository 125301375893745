import React, { Component } from 'react';

import {
  Page, Card, Layout, SkeletonBodyText, Modal, FormLayout, Toast, TextField,Badge, Select, Spinner, BlockStack, Button, Box, Text
} from "@shopify/polaris";

import NavTabs from "../components/NavTabs"; 
import ShopifyCountriesConfig from '../../../../assets/ShopifyCountries';
import intl from '../../../../i18n/intl';
import { reqGetShopifyProvinceData,reqGetUserAddress, reqPostUpdateUserAddress } from '../../../../api';

import './index.css'
import AppBridge from '../../../../utils/appBridge';
import { changeBannerData } from '../../../../slice';
import { store } from '../../../../store';

class AddressManagementClass extends Component {
  state = {
    //弹窗
    showToast: false,  //弹窗显示
    showToastText:'',  //弹窗文字
    showToastIserror:false, //弹窗是否错误

    loadingPage: true,
    loadingProvinceData: false,
    saveDataLoading: false,
    // disabledSaveData: true,
    addressData: [],
    allowCount: 5,  // 允许创建地址数量
    provinceLabelText: 'Province',
    provinceData: [],

    editAddressModalActive: false,
    operateAddressModalActive: false,
    operateType: '',  // 操作类型 del 删除 set 设置默认地址
    editRecordId: 0,  // 列表记录ID
    
    firstName: '',
    lastName: '',
    lastNameError: '',
    company: '',
    address1: '',
    address1Error: '',
    address2: '',
    country: '',
    countryError: '',
    countryCode: '',
    province: '',
    provinceCode: '',
    city: '',
    cityError: '',
    zipCode: '',
    zipCodeError: '',
    phone: '',
  };

  //进入页面调用接口
  componentDidMount () {
    this.getUserSettingsData();
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 初始化国家，身份数据
  initShopifyCountriesData = (shopifyCountryCode) => {
    let countryName = '';
    let provinceKeyText = '';
    for(let i = 0; i < ShopifyCountriesConfig.length; i++){
      if(ShopifyCountriesConfig[i].value === shopifyCountryCode){
        countryName = ShopifyCountriesConfig[i].label;
        provinceKeyText = ShopifyCountriesConfig[i].province_label;
        break;
      }
    }
    this.setState({
      countryCode: shopifyCountryCode,
      country: countryName,
      provinceLabelText: provinceKeyText,
    },()=>{
      // 获取是否有省份数据
      this.getShopifyProvinceData(shopifyCountryCode);
    });
  }

  getUserSettingsData = async () =>{
    const params = {};
    const response = await reqGetUserAddress(params);
    let defaultCountry = 'US';

    if (response.code === '200') {
      this.setState({
        loadingPage: false,
        addressData: response.data.list,
        allowCount: response.data.count
      });
      defaultCountry = response.data.country_code;

    } else {
      this.setState({ loadingPage: false });
      this.toggleToast(response.msg);
    }

    // 初始化国家信息
    this.initShopifyCountriesData(defaultCountry);
  }

  // save setting
  saveData = async (type) => {
    if (type === '') {
      return false;
    }
    const {
      firstName, lastName, address1, address2, phone, city, province, provinceCode, country, countryCode, company, zipCode, email, option1
    } = this.state;

    let params = {
      type: type
    };

    if(type === 'add' || type === 'update'){
      let isAllow = true;
      let shippingAddress = {
        first_name: firstName,
        last_name: lastName,
        address1: address1,
        address2: address2,
        phone: phone,
        city: city,
        province: province,
        province_code: provinceCode,
        country: country,
        country_code: countryCode,
        company: company,
        zip: zipCode,
        email: email,
        option1: option1,
      };

      // 检验地址
      isAllow = this.verifyAddress(shippingAddress);

      if (!isAllow) {
        return false;
      }

      params.id = this.state.editRecordId;
      params.address = shippingAddress;

    } else {
      params.id = this.state.editRecordId;
    }

    this.setState({ saveDataLoading: true });
    const responseData = await reqPostUpdateUserAddress(params);

    this.setState({ 
      saveDataLoading: false,
      editRecordId: 0
    });

    if (responseData.code === "200") {
      if (type === 'del') {
        this.toggleToast('Deleted successfully');
      }else if (type === 'add') {
        this.toggleToast('Add successfully');
      }else if (type === 'set' ) {
        this.toggleToast('Set up successfully');
      }else if (type === 'update' ) {
        this.toggleToast('Saved successfully');
      }else {
        this.toggleToast('Saved successfully');
      }
      
      // 关闭弹窗
      if(type === 'add' || type === 'update'){
        this.handleEditAddress(0,1,false);
      }
      if(type === 'del' || type === 'set'){
        this.handleOperatePrompt(0,false,'');
      }
      // 重新获取列表
      this.getUserSettingsData();
    }  else if (responseData.code === '505') {  // 反馈地址检测错误
      let getData = responseData.data;
      this.setState({
        saveDataLoading: false,
        lastNameError: getData?.last_name,
        address1Error: getData?.address1,
        countryError: getData?.country,
        cityError: getData?.city,
        phoneError: getData?.phone,
        zipCodeError: getData?.zip,
        emailError: getData?.email,
        option1Error: getData?.option1
      });
      


    }else {
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:responseData.msg
      }}));
      // this.toggleToast(responseData.msg);
    }
  };

  // 获取shopify省份数据
  getShopifyProvinceData = async(code='') =>{
    const params = {
      c: code,
    }
    this.setState({ loadingProvinceData: true });
    const response = await reqGetShopifyProvinceData(params)
    if(response.code === '200'){
      let getData = response.data;

      // 修改地址时，初始化省份
      let init_province = this.state.province ? this.state.province : '';
      let init_provinceCode = this.state.provinceCode ? this.state.provinceCode : '';

      if(getData.length > 0){
        if(init_province === ''){
          init_province = getData[0].label;
        }

        if(init_provinceCode === ''){
          init_provinceCode = getData[0].value;
        }
      }else{
        init_province = '';
        init_provinceCode = '';
      }

      this.setState({
        loadingProvinceData: false,
        provinceData: getData,
        province: init_province,
        provinceCode: init_provinceCode,
      });
    }else{
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:response.msg
      }}));
      // this.toggleToast(response.msg);
    }
  }

  // 新增、修改地址信息
  handleEditAddress = (recordKey=0,recordId,bool) => {
    if(recordId === 0) { // 新增
      this.setState({
        editAddressModalActive: bool
      });

    } else {
      let data = this.state.addressData;
      this.setState({
        editAddressModalActive: bool,
        editRecordId: bool ? data[recordKey].id  : 0,
        firstName: bool ? data[recordKey].first_name : '',
        lastName: bool ? data[recordKey].last_name : '',
        lastNameError: '',
        company: bool ? data[recordKey].company : '',
        address1: bool ? data[recordKey].address1 : '',
        address1Error: '',
        address2: bool ? data[recordKey].address2 : '',
        country: bool ? data[recordKey].country : '',
        countryError: '',
        countryCode: bool ? data[recordKey].country_code : '',
        province: bool ? data[recordKey].province : '',
        provinceCode: bool ? data[recordKey].province_code : '',
        city: bool ? data[recordKey].city : '',
        cityError: '',
        zipCode: bool ? data[recordKey].zip : '',
        zipCodeError: '',
        phone: bool ? data[recordKey].phone : '',
        email: bool ? data[recordKey].email : '',
        option1: bool ? data[recordKey].option1 : '',
        phoneError: '',
        emailError: '',
        option1Error: ''
      },()=>{
        if (bool) {
          // 获取是否有省份数据
          this.getShopifyProvinceData(data[recordKey].country_code);
        }
      });
    }
  }

  // 校验收货地址信息
  verifyAddress(address){
    if (address) {
      let errorObj = {};
      if (!address.last_name) {
        errorObj.lastNameError = intl.get('Last name is required');
      }
      if (!address.address1) {
        errorObj.address1Error = intl.get('Address is required');
      }
      if (!address.country) {
        errorObj.countryError = intl.get('Please select a country');
      }
      if (!address.zip) {
        errorObj.zipCodeError = intl.get('Postal code is required');
      }
      if (!address.city) {
        errorObj.cityError = intl.get('City is required');
      }
      if (address.email) {
        let reg = /^[.a-zA-Z0-9_-]+@[.a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if(!address.email.match(reg)){
          errorObj.emailError = intl.get('Wrong email number format');
        }
      }
      // if (!address.phone) {
      //   errorObj.phoneError = 'Phone number is required';
      // }
      // if (!address.email) {
      //   errorObj.emailError = 'Email is required';
      // }
      // 巴西税号
      if (address.country_code === 'BR' && !address.option1) {
        errorObj.option1Error = intl.get('CPF required');
      }
      // 挪威税号
      if (address.country_code === 'NO' && !address.option1) {
        errorObj.option1Error = intl.get('VOEC required');
      }

      let isTrue = true;

      if (Object.keys(errorObj).length !== 0) {
        isTrue = false;
        this.setState(errorObj);
      }

      return isTrue;

    } else {
      this.setState({
        lastNameError: intl.get('Last name is required'),
        address1Error: intl.get('Address is required'),
        countryError: intl.get('Please select a country'),
        zipCodeError: intl.get('Postal code is required'),
        cityError: intl.get('City is required'),
        // emailError: 'Email is required',
        // phoneError: 'Phone number is required'
      });

      return false;
    }
  }

  // 操作地址提示弹窗
  handleOperatePrompt = (recordId,bool,type) => {
    this.setState({
      editRecordId: recordId,
      operateAddressModalActive: bool,
      operateType: type
    })
  }

  handleAddressInputChange = (value,field) => {
    if (field === "countryCode") {
      let countryName = '';
      let provinceKeyText = '';
      for(let i = 0; i < ShopifyCountriesConfig.length; i++){
        if(ShopifyCountriesConfig[i].value === value){
          countryName = ShopifyCountriesConfig[i].label;
          provinceKeyText = ShopifyCountriesConfig[i].province_label;
          break;
        }
      }
      if(value !== this.state.countryCode){
        // 当前国家与之前不一样，获取省份数据
        this.getShopifyProvinceData(value);
      }

      this.setState({
        [field]: value,
        country: countryName,
        provinceLabelText: provinceKeyText,
        province: '',
        provinceCode: '',
        countryError: '',
        city: '',
        cityError: ''
      });

    } else if(field === "provinceCode"){
      let array = this.state.provinceData;
      let provinceText = '';
      for (let j = 0; j < array.length; j++) {
        if (array[j].value === value) {
          provinceText = array[j].label;
          break;
        }
      }
      this.setState({
        [field]: value,
        province: provinceText
      })

    } else if(field === 'lastName'){
      this.setState({
        [field]: value,
        lastNameError: ''
      })

    } else if(field === 'address1'){
      this.setState({
        [field]: value,
        address1Error: ''
      })
      
    } else if(field === 'city'){
      this.setState({
        [field]: value,
        cityError: ''
      })
      
    } else if(field === 'zipCode'){
      this.setState({
        [field]: value,
        zipCodeError: ''
      })
      
    } else if (field === 'email') {
      this.setState({
        [field]: value,
        emailError: '',
      })

    } else if (field === 'phone') {
      this.setState({
        [field]: value,
        phoneError: '',
      })

    } else if (field === 'option1') {
      let err_msg = '';
      // 挪威的税号检测
      if (this.state.countryCode === 'NO') {
        if (value !== '') {
          // 只支持输入以2或3开头的7位纯数字
          let regex = /^([23]\d{6})$/;
          if (!regex.test(value)) {
            err_msg = intl.get('Only supports input of 7-digit plain numbers starting with 2 or 3');
          }
        } else {
          err_msg = intl.get('VOEC is required for orders destined to Norway');
        }
      }
      this.setState({
        [field]: value,
        option1Error: err_msg,
      })

    } else {
      this.setState({
        [field]: value
      },()=>{
        if (field === 'isWithAddress'){
          this.handleToggleAddress();
        }
      })
    }
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const { 
      saveDataLoading,addressData, allowCount,operateAddressModalActive,operateType,loadingProvinceData,
      firstName,lastName,company,address1,address2,countryCode,provinceCode,city,zipCode,phone,provinceLabelText,provinceData,
      lastNameError,address1Error,countryError,zipCodeError,cityError,editAddressModalActive,editRecordId,
      phoneError, email, emailError, option1, option1Error
    } = this.state;

    const initSkeletonPage = (
      <div className="SettingsPage__Content">
       <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
     </div>
    );

    // 操作提示弹窗
    let operateTipsModal = (
      <Modal
        open={operateAddressModalActive}
        onClose={() => this.handleOperatePrompt(0,false,'')}
        title={(operateType === 'del') ? intl.get('Remove address') : intl.get('Mark as default')}
        primaryAction={{
          content: (operateType === 'del') ? intl.get('Delete') : intl.get('Mark as default'),
          onAction: () => this.saveData(operateType),
          destructive: (operateType === 'del'),
          loading: saveDataLoading
        }}
        secondaryActions={[
          {
            content: intl.get('Cancel'),
            onAction: () => this.handleOperatePrompt(0,false,''),
          }
        ]}
      >
        <Modal.Section>
          <p>{(operateType === 'del') ? intl.get('Are you sure to remove this address?') : intl.get('Are you sure to set this address as default?')}</p>
        </Modal.Section>
      </Modal>
    )

    let addressList = '';
    if (addressData.length > 0) {
      addressList = addressData.map((item,key) => {
        let defaultStatus = (item.is_default === 1) ? (
          <div style={{marginTop:'7px'}}>
            <Badge tone='info' progress="complete">{intl.get('Default address')}</Badge>
          </div>
        ) : '';

        let addressButtons = [
          {
            content: intl.get('Edit'),
            onAction: () => this.handleEditAddress(key,item.id,true)
          }
        ];
        if (item.is_default === 0) {
          addressButtons.push(
            {
              content: intl.get('Mark as default'),
              onAction: () => this.handleOperatePrompt(item.id,true,'set'),
            },
            {
              content:intl.get('Delete'),
              onAction: () => this.handleOperatePrompt(item.id,true,'del'),
              destructive: true
            }
          )
        }

        let singleArress = (
          <Box
            key={key}
            // title={"Address " + (key+1)}
            // actions={addressButtons}
            className="Panel_Content_Box_clo"
          >
            <Box className="Panel_Content_Box">
          <Text variant="headingMd" as="h6">
          {intl.get("Address") + (key+1)}
      </Text>
      <Box className={"Panel_Content_Box_Row"}>
      {addressButtons && Object.values(addressButtons).map((item,ind)=>
          <Button key={ind} variant='plain' onClick={item?.onAction}><span style={{color : item?.destructive?'red' : ''}}>{item?.content}</span></Button>
          )}
          </Box>
          </Box>
            {/* <div>Maha Ahmend, +1 716-563-5088</div>
            <div>69 Norfred Drive</div>
            <div>Buffalo, New York, United States, 14218</div> */}
            <div>{item.first_name + ' ' + item.last_name}</div>
            <div>{item.address1 + ' ' + item.address2}</div>
            <div>{item.city + ' ' + item.province + ' ' + item.country}</div>
            <div>{item.zip}</div>
            <div>{item.phone}</div>
            <div>{item.email}</div>
            <div>{item.company}</div>
            {defaultStatus}
          </Box>
        );

        return [
          singleArress
        ];
      })
    }

    const pageContent = this.state.loadingPage ? initSkeletonPage : <div className="SettingsPage__Content">
      <section className="Panel_Content" style={{minHeight:'700px'}}>
        <Card>
          <Box className="Panel_Content_Box Panel_Content_Box_Row">
          <Text variant="headingMd" as="h6">
          {intl.get("Address management")}
      </Text>
          <Button variant='plain' onClick={() => this.handleEditAddress(0,0,true)} disabled={(addressData.length === allowCount)}>{intl.get('Add new address')}</Button>
          </Box>
          <BlockStack gap={400}> 
          <div>{intl.get('Allow to set {{allowCount}} addresses.',allowCount)}</div>

          {/* 地址列表 */}
          {addressList}
          </BlockStack>
        </Card>
      </section>
    </div>

    // 是否需要检测税费
    let isNeedCheckTaxCode = false;

    // 税号文本
    let taxCodeText = 'Tax code';
    switch (countryCode) {
      case 'BR':
        // 巴西税号特称
        taxCodeText = 'CPF';
        isNeedCheckTaxCode = true;
        break;
      case 'NO':
        // 挪威税号特称
        taxCodeText = 'VOEC';
        isNeedCheckTaxCode = true;
        break;
      case 'CL':
        isNeedCheckTaxCode = true;
        break;
      default:
    }

    let addOrEditAddressModal = (
      <Modal
        open={editAddressModalActive}
        onClose={() => this.handleEditAddress(0,editRecordId,false)}
        title={(editRecordId === 0) ? intl.get('Add new address') : intl.get('Edit address')}
        primaryAction={{
          content: intl.get('Save'),
          onAction: () => this.saveData((editRecordId === 0) ? 'add' : 'update'),
          loading: saveDataLoading
        }}
        secondaryActions={[
          {
            content: intl.get('Cancel'),
            onAction: () => this.handleEditAddress(0,editRecordId,false),
          }
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <FormLayout.Group>
              <TextField id="firstName" label={intl.get("First name")} onChange={this.handleAddressInputChange} value={firstName} maxLength={50} />
              <TextField id="lastName" label={intl.get("Last name")} onChange={this.handleAddressInputChange} value={lastName} error={lastNameError} maxLength={50} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="company" label={intl.get("Company")} onChange={this.handleAddressInputChange} value={company} maxLength={255} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="address1" label={intl.get("Address")} onChange={this.handleAddressInputChange} value={address1} error={address1Error} maxLength={255} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="address2" label={intl.get("Apartment, suite, etc. (optional)")} onChange={this.handleAddressInputChange} value={address2} maxLength={255} />
            </FormLayout.Group>
            
            <FormLayout.Group>
              <Select
                id="countryCode"
                label={intl.get("Country/region")}
                options={ShopifyCountriesConfig}
                onChange={this.handleAddressInputChange}
                value={countryCode}
                error={countryError}
              />
              {(provinceData.length > 0 && !loadingProvinceData) && (
                <Select
                  id="provinceCode"
                  label={provinceLabelText}
                  options={provinceData}
                  onChange={this.handleAddressInputChange}
                  value={provinceCode}
                />
              )}
              {
                loadingProvinceData && <div style={{ textAlign: 'center', marginTop: '30px'}}><Spinner size="small" /></div>
              }
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="city" label={intl.get("City")} onChange={this.handleAddressInputChange} value={city} error={cityError} maxLength={50} />
              <TextField id="zipCode" label={intl.get("ZIP/Postal code")} onChange={this.handleAddressInputChange} value={zipCode} error={zipCodeError} maxLength={30} />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField id="phone" label={intl.get("Phone")} onChange={this.handleAddressInputChange} value={phone} error={phoneError} maxLength={30} />
              <TextField id="email" type="email" label={intl.get("Email")} onChange={this.handleAddressInputChange} value={email} error={emailError} maxLength={50} />
            </FormLayout.Group>

            {/* 巴西税费 */}
            {isNeedCheckTaxCode && (
              <FormLayout.Group>
                <TextField
                  id="option1"
                  label={taxCodeText}
                  onChange={this.handleAddressInputChange}
                  value={option1}
                  error={option1Error}
                  maxLength={30}
                />
              </FormLayout.Group>
            )}
          </FormLayout>
        </Modal.Section>
      </Modal>
    );

    return (
      <div className={AppBridge.exist() ? "Setting__Page_Content Content_within" : "Setting__Page_Content Content_outside"}>
        <Page
           title={AppBridge.exist() ? '' : intl.get("Settings")}
          // primaryAction={
          //   {
          //     content: intl.get("Save"), 
          //     onAction: this.saveData,
          //     loading: saveDataLoading,
          //     disabled: disabledSaveData
          //   }
          // }
          separator
        >
          <section className="SettingsPage__Box">

            <NavTabs selected="Address management" />

            {pageContent}

            {/* 新增、更新地址信息 */}
            {addOrEditAddressModal}

            {/* 操作提示 */}
            {operateTipsModal}
          </section>

          { toastMarkup }
          <div className="space"></div>
        </Page>
      </div>
    )
  }
}

export default AddressManagementClass;