import React, { Component } from "react";

import {
  Page,
  Card,
  Layout,
  SkeletonBodyText,
  Button,
  FormLayout,
  Toast,
  SkeletonPage,
  EmptyState,
  BlockStack,
  Link,
  // Select,
  ButtonGroup,
  Modal,
  // Checkbox,Popover,ActionList,ExceptionList,Badge,Modal
  Tabs,
  Text,
  Icon,
  Checkbox,
  Box,
} from "@shopify/polaris";
import utils from "../../../../../utils/utils";
import { withRouter } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import {
  getGetProductAddSuccess,
  reqGetFindProductDetailsData,
  reqGetUserSubcribe,
  reqPostCartProductAdd,
  reqGetFindProductDetailsReviews,
} from "../../../../../api";

import ShippingMethodsModal from "../../../FindProducts/ProductDetail/components/ShippingMethodsModal";


import "../index.css";
import intl from "../../../../../i18n/intl";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { store} from "../../../../../store";
// import { connect} from "react-redux";
import ImportDsmModal from "../../../SubscriptionPaid/components/ImportDsmModal";
import { changeBannerData, changePersonLevel } from "../../../../../slice";
import { connect } from "react-redux";
// import AppBridge from "../../../../utils/appBridge";
class ProductDetail extends Component {
  state = {
    //页面动作
    loadingPage: false,

    // 数据
    pageData: "",
    options: [],
    search_product_id: 0, // 要查询的产品详情的产品ID
    tag:'home',
    fromPage: "", // 从哪个页面跳转过来的
    addLoading: false,
    chargeError: false,

    disableAddToCart: true,
    addToCartLoading: false,
    // 选中添加购物车的sku信息
    addCartItem: {
      id: 0,
      vid: 0,
    },
    reviewCount: 0, // DSM产品评论数量
    selectTab: 0,
    skuImage: "", //主视角图片
    defaultImage: "", //商品默认图片
    defaultPoint: "", //商品默认价格
    indexPoint: "", //商品价格
    thumbsSwiper: null,
    magnifierOff: false,
    skuData: [], //sku列表数据
    variantData: {}, //变体链接数据
    combined: [], //组合变体
    isCombined: false, //是否为组合商品
    commonVariantData: [], //变体属性选择
    importantLimit: 0, //商品导入限制
    importantDsmModal: false, //dsm产品导入限制
    importantDay: 3, //每天限定导入
    holidayLink: false, // 是否节日商品
    productAddSuccess:false, // 是否不再显示导入成功弹窗
    selectAgain:false, // 选择不再弹窗
    reviewData:{}, //评论数据
    reviewReq:false,// 是否已经调起评论
    linkPage:'', //链接页面
    cateId: '', //分类ID
    minPrice:'',
    maxPrice:'',
    listProduct:'',//来源列表页面
    listSourcing:'',//来源列表页面
  };

  //进入页面调用接口
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let id = params.get("filter");
    let fromType = params.get("type");
    let holiday = params.get("holiday");
    let linkPage = params.get("linkPage");
    let cateId  = params.get("cateId");
    let minPrice = params.get('minPrice');
    let maxPrice = params.get('maxPrice');
    let listProduct = params.get('listProduct');
    let listSourcing = params.get('listSourcing');
    if (id) {
      this.setState(
        {
          search_product_id: id,
          tag: fromType ? fromType : "home",
          fromPage: fromType ? fromType : "",
          holidayLink: holiday ? true : false,
          linkPage: linkPage ? linkPage : "",
          cateId: cateId ? cateId : "",
          minPrice: minPrice ? minPrice : '',
          maxPrice: maxPrice ? maxPrice : '',
          listProduct: listProduct ? listProduct : '',
          listSourcing: listSourcing ? listSourcing : '',
        },
        () => {
          // let text = this.state.holidayLink ? "Event Products" : "Find Products"
          // let url = this.state.holidayLink ? "/admin/find_products/new-products" : "/admin/find_products"
          // if(AppBridge && AppBridge.exist()) {
          //   AppBridge.setTitleBar(text,url)
          // }
          this.getPageData();

          // 清除浏览器链接上的参数
          //   try {
          //     var currentUrl = window.location.href;
          //     if (currentUrl.indexOf("?") !== -1) {
          //       currentUrl = currentUrl.replace(/(\?|#)[^'"]*/, '');
          //       if (!!(window.history && window.history.pushState)){
          //         window.history.pushState({}, 0, currentUrl);
          //       }
          //     }
          //   } catch (error) {
          //     // console.warn(error);
          //   }
        }
      );
    } else {
      this.getPageData();
    }
  }

  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    }));
  };
  shopifyLevel = ()=>{
    let shopify_level = store.getState().languageReducer.subScriptData.shopify_level;
    let flag = false;
    if (shopify_level === 'Development' || shopify_level === 'Developer Preview' || shopify_level === 'staff' || shopify_level === 'staff_business') {
      flag = true
    }
    return flag;
  }

  // 获取列表数据
  getPageData = async () => {
    this.setState({
      loadingPage: true,
    });
    const params = {
      record_id: this.state.search_product_id,
      tag:this.state.tag
    };
    const response = await reqGetFindProductDetailsData(params);
    if (response.code === "200") {
      let person = store.getState().languageReducer.subScriptData;
      if (Object.values(person).length > 0) {
        this.setState({
          productAddSuccess:person?.bfs_pop?.is_bfs_import_product ? person?.bfs_pop?.is_bfs_import_product : false
        })
      }
      let res = await reqGetUserSubcribe();
      if (res.code === "200") {
        this.setState({
          importantLimit: Number(res?.data?.limit?.dsm),
          importantDay: Number(res?.data?.day?.dsm_product_num),
        });
      }

      this.setState({
        loadingPage: false,
        pageData: response.data,
        options: response.data.options,
        reviewCount: 0,
        skuImage: response.data.image_list[0],
        default: response.data.image_url,
        skuData: response.data.options,
        indexPoint: response.data.sell_point,
        defaultPoint: response.data.sell_point,
        defaultImage: response.data.image_list[0],
        variantData: response.data.variants,
        combined: response.data.combined,
      });
    } else {
      this.setState({
        loadingPage: false,
        pageData: "",
      });
      // this.toggleToast(response.msg,true);
    }
  };

  // 页面操作
  handleDetail = (record_id)=>{
    utils.routeListen('/admin/points_purchase?record_id='+record_id)
    utils.routeJump(this,'/admin/points_purchase?record_id='+record_id)
}

  // option 选择
  handleChangeOptionSelect = (optionKey, value) => {
    let data = this.state.options;
    // option 选中赋值
    data[optionKey].selected = value;

    let isDisable = true;
    let selectCount = 0;
    let selectVariantTitleArr = [];

    for (let index = 0; index < data.length; index++) {
      if (data[index].selected) {
        selectCount++;
        selectVariantTitleArr.push(data[index].selected);
      }
    }

    // 全部选项都选择好了，并匹配到sku的ID，允许点击添加购物车按钮
    let setAddItem = this.state.addCartItem;
    if (data.length === selectCount) {
      let pageData = this.state.pageData;
      let variantTitleData = pageData.variantTitle;
      // 选择的属性拼接起来
      let singleVariantTitle = selectVariantTitleArr.join("-");

      // 获取需要加入购物车的变体ID
      if (variantTitleData[singleVariantTitle]) {
        setAddItem.dsm_vid = variantTitleData[singleVariantTitle].id;
        setAddItem.record_id = pageData.record_id;
        isDisable = false;
      }
    }
    this.setState({
      options: data,
      addCartItem: setAddItem,
      disableAddToCart: isDisable,
    });
  };

  // 添加产品到购物车
  handleAddToShoppingCart = async () => {
    if (!this.state.disableAddToCart) {
      let data = this.state.addCartItem;
      let pageData = this.state.pageData;

      if (!data.dsm_vid || !data.record_id) {
        this.toggleToast("Something wrong");
      }

      this.setState({ addToCartLoading: true });
      const params = {
        record_id: pageData.record_id,
        dsm_vid:data.dsm_vid
        // cart: JSON.stringify(data),
        // type: "custom",
      };

      const response = await reqPostCartProductAdd(params);
      this.setState({ addToCartLoading: false });

      if (response.code === "200") {
        this.toggleToast('Added successfully');
        // 回调更新悬浮购物车按钮
        if (response.data !== 0) {
          this.props.callBackFun({ number: response.data.number });
        }
      } else {
        // this.toggleToast(response.msg);
        store.dispatch(changeBannerData({bannerData:{
          show:true,
          desc:response.msg
        }}));
      }
    }
  };

  callChargeErrorClosed = () => {
    this.setState({
      chargeError: false,
    });
    if (!this.state.productAddSuccess && this.state.selectAgain) {
      this.selectAgainClose()
    }
  };
   // 不再弹出导入成功弹窗
   selectAgainClose = async () => {
    try {
      let res = await getGetProductAddSuccess()
      if (res.code === '200') {
        this.setState({
          productAddSuccess:true
        })
        let getData = store.getState().languageReducer.subScriptData;
        this.props.dispatch(changePersonLevel({infoData:{...getData,bfs_pop:{...getData.bfs_pop,is_bfs_import_product:true}}},true))
      }
    } catch (error) {
      
    }
  };
  // 获取评价
  getProductDetailReviews = async (page,size)=>{
    let params = {
      pid: this.state.pageData.pid,
      page: page,
      size: size,
    }
    let res = await reqGetFindProductDetailsReviews(params);
    if (res.code === '200') {
      this.setState({
        reviewData:res.data,
        reviewReq:true,
        reviewCount:res.data.count
      })
    }
  }

  // 返回指定列表
  handleBackToList = () => {
    if (this.state.listProduct !== '') {
      utils.routeListen("/admin/product_management?selectedView=0&ids="+this.state.listProduct);
      utils.routeJump(this, "/admin/product_management?selectedView=0&ids="+this.state.listProduct);
      return;
    }
    if (this.state.listSourcing !== '') {
      utils.routeListen("/admin/sourcing");
      utils.routeJump(this, "/admin/sourcing");
      return;
    }
    
    if (this.state.holidayLink) {
      utils.routeListen("/admin/product_selection?select_tab=0");
      utils.routeJump(this, "/admin/product_selection?select_tab=0");
      return;
    }
    if (this.state.fromPage) {
      utils.routeListen("/admin/product_selection?select_tab=0");
      utils.routeJump(
        this,
        "/admin/product_selection?select_tab=0"
      );
      // this.props.history.push('/admin/find_products/recommended-category/' + this.state.fromPage);
    } else {
      utils.routeListen("/admin/redeem_products");
      utils.routeJump(this, "/admin/redeem_products");
    }
  };

  handleThumbSwiper = () => {
    this.setState({ skuImage: "" });
  };

  // 鼠标移入
  mouseEnter = () => {
    this.setState({
      magnifierOff: true,
    });
    let big = document.querySelector(".picture_big");
    let mask = document.querySelector(".detail_mask");
    mask.style.display = "block";
    big.style.display = "block";
  };
  // mouse remove
  mouseLeave = () => {
    this.setState({
      magnifierOff: false,
    });
    let big = document.querySelector(".picture_big");
    let mask = document.querySelector(".detail_mask");
    mask.style.display = "none";
    big.style.display = "none";
  };
  // mouse move
  mouseMove = (event) => {
    let e = event.nativeEvent;
    // console.log('e x y',e.nativeEvent);
    this.calculationBlock(e.offsetX, e.offsetY);
  };
  // calculation params
  calculationBlock(offsetx, offsety) {
    let pre_img = document.querySelector(".product_info_swiper");
    let bigImg = document.querySelector(".picture_big img");
    let mask = document.querySelector(".detail_mask");
    let offsetX = offsetx - 50; // 鼠标在盒子中的位置 - mask宽的一半
    let offsetY = offsety - 50; // 鼠标在盒子中的位置 - mask高的一半
    /*block position*/
    // 防止鼠标移动过快导致计算失误，只要小于或大于对应值，直接设置偏移量等于最小或最大值
    // left 取值为 大于 0, 小于 盒子的宽 - mask的宽
    var maskMax = pre_img.offsetWidth - mask.offsetWidth;
    if (offsetX <= 0) {
      offsetX = 0;
    }
    if (offsetX >= maskMax) {
      offsetX = maskMax;
    }
    if (offsetY <= 0) {
      offsetY = 0;
    }
    if (offsetY >= maskMax) {
      // offsetY = small.offsetHeight - mask.offsetHeight;
      offsetY = maskMax;
    }
    // 移动mask
    mask.style.left = offsetX + "px";
    mask.style.top = offsetY + "px";
    /*计算图片放大位置*/
    // 右侧大图片，等比例移动
    // 大图片走的距离 / mask 走的距离 = 大图片 / 小图片
    var bigX = (offsetX * 900) / 300; //大图/小图
    var bigY = (offsetY * 900) / 300;
    //大图的移动(方向相反，取负值)
    bigImg.style.left = -bigX + "px";
    bigImg.style.top = -bigY + "px";
  }
  handleSelect = (val, ind) => {
    let data = this.state.skuData;
    let variant = this.state.variantData;
    let isDisable = true;
    // 匹配购物车
    let setAddItem = this.state.addCartItem;
    let variantIndex = "";
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].value.length > 0) {
          if (data[i].selected === val && i === ind) {
            data[i].selected = "";
            this.setState({
              skuData: data,
              indexPoint: this.state.defaultPoint,
              disableAddToCart: true,
              skuImage: this.state.defaultImage,
              isCombined: false,
              commonVariantData: {},
            });
            // https://cc-west-usa.oss-accelerate.aliyuncs.com/214ffd62-070f-4218-8203-83da7b710b02.jpg?x-oss-process=image/resize,m_pad,w_400,h_400
            return;
          }
          for (let j = 0; j < data[i].value.length; j++) {
            if (data[i].value.includes(val)) {
              if (val && i === ind) {
                data[i].selected = val;
                this.setState({
                  disableAddToCart: true,
                  commonVariantData: {},
                });
              }
            }
          }
        }
        // variant[data[i].selected]
        //是否全选了
        if (variant[data[0].selected]?.id) {
          variantIndex = data[0]?.selected + "-";
          this.setState({
            isCombined: true,
            commonVariantData: {},
          });
        } else {
          variantIndex += data[i]?.selected + "-";
          this.setState({
            isCombined: false,
          });
        }
      }
    }
    
    if (variant[variantIndex.slice(0, variantIndex.length - 1)]) {
      isDisable = false;
      setAddItem.record_id = this.state.pageData.record_id;
      setAddItem.dsm_vid =
        variant[variantIndex.slice(0, variantIndex.length - 1)]?.id;
      this.setState({
        skuImage:
          variant[variantIndex.slice(0, variantIndex.length - 1)]?.sku_image !==
            "" &&
          variant[variantIndex.slice(0, variantIndex.length - 1)]?.sku_image !==
            null
            ? variant[variantIndex.slice(0, variantIndex.length - 1)]?.sku_image
            : this.state.defaultImage,
        indexPoint:
          variant[variantIndex.slice(0, variantIndex.length - 1)]?.point,
        disableAddToCart: isDisable,
      });
    }
    this.setState({
      skuData: data,
      setAddItem: setAddItem,
      commonVariantData:[],
    });
  };
  handleToggleCombined = (ind, val) => {
    let commonVariantData = this.state.commonVariantData;
    let data = this.state.skuData.slice(1, 3);
    let flag = false;
    if (commonVariantData.length > 0) {
      for (let j = 0; j < data[ind].value.length; j++) {
        for (let i = 0; i < commonVariantData[ind].length; i++) {
          if (commonVariantData[ind][i] !== val) {
            flag = true;
          } else {
            flag = false;
            break;
          }
        }
      }
    }
    return flag;
  };
  formatNumber = (value) => {
    value += "";
    const list = value.split(".");
    const prefix = list[0].charAt(0) === "-" ? "-" : "";
    let num = prefix ? list[0].slice(1) : list[0];
    let result = "";
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }

    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
  };
  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={() => this.toggleToast("")}
        error={this.state.showToastIserror}
      />
    ) : null;
    const {
      pageData,
      selectTab,
      skuImage,
      thumbsSwiper,
      skuData,
      indexPoint,
      isCombined,
    } = this.state;
    const initSkeletonPage = (
      <div>
        <SkeletonPage>
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap={400}>
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      </div>
    );

    const EmptyProductList = (
      <div style={{ minHeight: "600px" }}>
        <EmptyState image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
          <p>{intl.get("Get no detail")}</p>
        </EmptyState>
      </div>
    );
    let skuOptionSelect = "";
    if (skuData.length > 0) {
      // 如果是颜色
      skuOptionSelect = (
        <div className="product_sku_attr_box">
          <div className="product_sku_attr_list">
            {skuData.slice(0, 1).map((item, ind) => (
              <div className="product_sku_attr_item" key={ind}>
                <span className="product_sku_attr_title">{item.name}:</span>
                {item.value.length > 0 && (
                  <div className="product_sku_attr_item_list">
                    {item.value.map((it, k) => (
                      <span
                        className={
                          item.selected === it
                            ? "item_value item_value_select"
                            : "item_value"
                        }
                        onClick={() => this.handleSelect(it, ind)}
                        key={k}
                      >
                        {it}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            ))}
            {skuData.slice(1, 3).map((item, ind) => (
              <div className="product_sku_attr_item" key={ind}>
                <span className="product_sku_attr_title">{item.name}:</span>
                {item.value.length > 0 && (
                  <div className="product_sku_attr_item_list">
                    {item.value.map((it, k) => (
                      <span
                        className={
                          item.selected === it
                            ? "item_value item_value_select"
                            : "item_value"
                        }
                        style={{
                          background:
                            this.handleToggleCombined(ind, it) || isCombined
                              ? "#f5f5f5"
                              : "",
                          color:
                            this.handleToggleCombined(ind, it) || isCombined
                              ? "#666"
                              : "rgb(32, 34, 35)",
                          borderColor:
                            (this.handleToggleCombined(ind, it) ||
                              isCombined) &&
                            "transparent",
                          pointerEvents:
                            this.handleToggleCombined(ind, it) || isCombined
                              ? "none"
                              : "auto",
                        }}
                        onClick={() =>
                          this.handleToggleCombined(ind, it) || isCombined
                            ? ""
                            : this.handleSelect(it, ind + 1)
                        }
                        key={k}
                      >
                        {it}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      );
    }

    // DSM 描述+评论显示
    let showTabs = [
      {
        id: "all-description",
        content: intl.get("Description"),
        accessibilityLabel: "All description",
        panelID: "all-description-content",
      },
    ];
    
    const pageContent = this.state.loadingPage ? (
      initSkeletonPage
    ) : (
      <section>
        <Page
          backAction={{
            content: "Redeem Products",
            // url: '/admin/find_products',
            onAction: () => this.handleBackToList(),
          }}
          title={'Redeem Products'}
        >
          <Layout>
            <Layout.Section>
              <div style={{ paddingTop: "1rem" }}></div>
              {this.state.pageData === "" ? (
                <Card>{EmptyProductList}</Card>
              ) : (
                <section>
                  <BlockStack gap={400}>
                    <Card>
                      <section className="Product_Detail_Content">
                        <div className="Product_Detail_Img detail_image">
                          <div
                            className="product_info_swiper"
                            onMouseEnter={this.mouseEnter}
                            onMouseLeave={this.mouseLeave}
                            onMouseMove={this.mouseMove}
                          >
                            <Swiper
                              style={{
                                "--swiper-navigation-color": "#FFF",
                                "--swiper-pagination-color": "#FFF",
                              }}
                              spaceBetween={20}
                              navigation={false}
                              onNavigationNext={() =>
                                this.setState({ skuImage: "" })
                              }
                              onNavigationPrev={() =>
                                this.setState({ skuImage: "" })
                              }
                              thumbs={{ swiper: thumbsSwiper }}
                              modules={[FreeMode, Navigation, Thumbs]}
                              className="mySwiper2 swiper-no-swiping"
                            >
                              <img
                                src={
                                  skuImage !== ""
                                    ? skuImage
                                    : pageData.image_list &&
                                      pageData.image_list[0]
                                }
                                alt="dropshipman"
                              />
                              <div className="detail_mask"></div>
                            </Swiper>
                          </div>
                          <div className="picture_big">
                            <img
                              src={
                                skuImage !== ""
                                  ? skuImage
                                  : pageData.image_list &&
                                    pageData.image_list[0]
                              }
                              alt="dropshipman"
                            />
                          </div>
                          <div className="details_product">
                            <div className="prev">
                              <Icon source={ChevronLeftIcon} tone="base" />
                            </div>
                            <div className="next">
                              <Icon source={ChevronRightIcon} tone="base" />
                            </div>
                            <Swiper
                              onSwiper={() =>
                                this.setState({ thumbsSwiper: null })
                              }
                              onClick={() => this.handleThumbSwiper()}
                              spaceBetween={20}
                              freeMode={true}
                              observer={true}
                              observeParents={true}
                              slidesPerView={"auto"}
                              watchSlidesProgress={true}
                              navigation={{ prevEl: `.prev`, nextEl: `.next` }}
                              modules={[FreeMode, Navigation, Thumbs]}
                              className="mySwiper"
                            >
                              {pageData.image_list &&
                                pageData.image_list
                                  .slice(0, 20)
                                  .map((image_url, ind) => (
                                    <SwiperSlide
                                      onClick={() =>
                                        this.setState({ skuImage: image_url })
                                      }
                                      className={
                                        image_url === skuImage
                                          ? "product_swiper_thumbs_hover"
                                          : "product_swiper_thumbs"
                                      }
                                      key={ind}
                                    >
                                      <img src={image_url} alt="dropshipman" />
                                    </SwiperSlide>
                                  ))}
                            </Swiper>
                          </div>
                        </div>

                        <div className="Product_Detail_Box detail">
                          <FormLayout>
                            {pageData.product_link ? (
                              <Link
                                url={pageData.product_link}
                                external
                                removeUnderline
                              >
                                <Text variant="headingMd" as="h2">
                                  {pageData.title}
                                </Text>
                              </Link>
                            ) : (
                              <Text variant="headingMd" as="h2">
                                {pageData.title}
                              </Text>
                            )}

                            {/* 积分显示 */}
                            
                              {this.shopifyLevel() ? <div className="Product__Attributes price" style={{color:'#202333',fontWeight:500}}>
                                {indexPoint !== ''
                                  ? indexPoint
                                  : pageData.sell_point}
                                  &nbsp;Points
                              </div> : <div className="Product__Attributes price" style={{fontWeight:500}}>
                                {indexPoint !== ''
                                  ? indexPoint
                                  : pageData.sell_point}
                                  &nbsp;Points
                              </div>}
                           

                            {/* 属性列表 */}
                            {/* {optionsList} */}

                            {/* 属性列表选择 */}
                            {/* {optionsListSelect} */}

                            {/* sku属性 */}
                            {skuOptionSelect}

                            {/* <div className="Product__Attributes">
                              <div className="Product__Attributes_Name">
                               Stock
                              </div>
                              <div className="Product__Attributes_Content">
                                {pageData.stock + ' in stock'}
                              </div>
                            </div> */}

                            {/* Ship from China */}
                            <div className="Product__Attributes">
                              <div className="Product__Attributes_Name">
                              {intl.get("Ship from")}
                              </div>

                              <div className="Product__Attributes_Content Label">
                                <span className="location">
                                  <img src="/location.png" alt="" />
                                </span>
                                <span className="title">China</span>
                              </div>
                            </div>

                            <div className="Product__Attributes">
                              <div className="Product__Attributes_Name shipping">
                              {intl.get("Ship to")}
                              </div>
                              <div className="Product__Attributes_Content">
                                <ShippingMethodsModal
                                  pid={pageData.pid}
                                  countryCode={pageData.country_code}
                                  vidArr={pageData.vid_arr}
                                  show={false}
                                  reviewReq={this.state.reviewReq}
                                  getProductDetailReviews={this.getProductDetailReviews}
                                />
                                {/* <div>$9.15 to Brazil via AliExpress Standard Shipping</div>
                                <div>Estimated delivery time 31-31 days</div> */}
                              </div>
                            </div>

                            <div className="redeem_details_btn" style={{float:'right'}}>
                              
                                <ButtonGroup>
                                 <Button
                                    onClick={() =>
                                      this.handleDetail(this.state.search_product_id)
                                    }
                                    variant="primary"
                                  >
                                    {intl.get("Redeem")}
                                  </Button>
                                </ButtonGroup>
                             
                            </div>
                          </FormLayout>
                        </div>
                      </section>
                    </Card>

                    <Card>
                      <Tabs
                        tabs={showTabs}
                        selected={selectTab}
                        onSelect={(index) =>
                          this.setState({ selectTab: index })
                        }
                      >
                        
                        <div className="detail_product_cj"
                          dangerouslySetInnerHTML={{
                            __html: pageData.description,
                          }}
                        ></div>
                      </Tabs>
                    </Card>
                  </BlockStack>
                </section>
              )}
            </Layout.Section>
          </Layout>
        </Page>
      </section>
    );
    
    const SingleOperationTipsModal = (
      <Modal
      open={this.state.chargeError}
      onClose={this.callChargeErrorClosed}
      title={intl.get("Product imported successfully")}
    >
      <Box className='product_success_content'>
        <div style={{ fontSize: "16px", color: "#202223",padding:"16px" }}>
        {intl.get("You can go to the")}{" "}
          <span style={{ fontSize: "16px",fontWeight:700, color: "#303030" }}>
            {intl.get("List Products > Not Listed")}
          </span>{" "}
          {intl.get("to view and edit the product.")}
        </div>
        
        <Box className='product_success_button'>
        <Checkbox
              checked={this.state.selectAgain}
              label={intl.get("Don't remind me again")}
              onChange={()=>this.setState(({
                selectAgain:!this.state.selectAgain
              }))}
            />
          <Button variant="primary" onClick={this.callChargeErrorClosed}>
          {intl.get("OK")}
          </Button>
        </Box>
      </Box>
    </Modal>
    );

    return (
      <div style={{ minHeight: "1000px" }}>
        {/* 页面内容 */}
        {pageContent}
        {/* dsm产品导入限制 */}
        <ImportDsmModal
          importOpen={this.state.importantDsmModal}
          num={this.state.importantDay}
          setImportOpen={() => this.setState({ importantDsmModal: false })}
        />

        {toastMarkup}
        {!this.state.productAddSuccess && SingleOperationTipsModal}
      </div>
    );
  }
}
// const mapStateToProps = state => {
//   return {
//     subScriptData: state.languageReducer.subScriptData
//   }
// }
export default connect()(withRouter(ProductDetail));
