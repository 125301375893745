import { Box, Card, Spinner } from '@shopify/polaris';
import React, { useState }  from 'react';
import ProductItem from '../../component/ProductItem';
import { reqGetProductSelectionRelatedDetail } from '../../../../../api';
import InfiniteScroll from 'react-infinite-scroll-component';

const SimilarProduct = ({
    setListData,
  listData,
  requestParams,
  handleTogglePopup,
  planData,
  setPlanData,
  userPoints,
  setUserPoints,
  setSourcingLimitModal,
  setEnoughPoint,
  toastFun,
  setRequestParams,
  setDetailItemParams,
  getSurveyData
})=> {
    const [showId,setShowId] = useState("");
    const [more, setMore] = useState(true); // 是否加载更多数据
    // 滚动加载
  const fetchMoreData = () => {
    let params = { ...requestParams, page: requestParams.page + 1 };
   
    if (listData.list.length === listData.count) {
      setMore(false)
      return
    }
    setTimeout(async () => {
      const res = await reqGetProductSelectionRelatedDetail(params);
      if (res.code === "200") {
        setListData((preValue) => ({
          ...preValue,
          list: preValue.list.concat(res.data.list),
        }));
        setRequestParams((preValue) => ({
          ...preValue,
          page: preValue.page + 1,
        }));
        setDetailItemParams((preValue) => ({
          ...preValue,
          page: preValue.page + 1,
        }))
        if (res.data.list.length === 0 || res.data.list.length  === res.data.count) {
          setMore(false);
        }
      } else {
        setMore(false);
      }
    }, 1500);
  };
    return (
        <Box className="product_similar_content">
           {listData.list.length >0 && <Card  padding={0}>
                <Box >
                    <Box className="product_similar_content_head"><strong>Similar items you may like</strong></Box>
                    <InfiniteScroll
                        dataLength={listData.list.length}
                        next={(e) => fetchMoreData()}
                        hasMore={more}
                        scrollableTarget="scrollableDiv"
                        loader={more && <h4 style={{textAlign:'center'}}><Spinner accessibilityLabel="Small spinner example" size="small" /></h4>}
                        refreshFunction={(e) => fetchMoreData()}
                        pullDownToRefresh
                    >
                    <Box className="product_commone_content_list">
                        {listData.list.map((item,ind)=><Box className="product_commone_content_list_item" key={ind}>
                        <Card padding={0}>
                        <ProductItem 
                        getSurveyData={getSurveyData}
                        isSmilar={true}
                        requestParams={requestParams}
                        listData={listData}
                        setListData={setListData}
                        getListData={()=>{}}
                        setSourcingLimitModal={setSourcingLimitModal}
                        showId={showId}
                        planData={planData}
                        userPoints={userPoints}
                        setUserPoints={setUserPoints}
                        setPlanData={setPlanData}
                        setEnoughPoint={setEnoughPoint}
                        handleTogglePopup={handleTogglePopup}
                        setShowId={setShowId}
                        item={item}
                        keys={ind}
                        toastFun={toastFun}/>
                        </Card>
                    </Box>)}
                    </Box>
                    </InfiniteScroll>
                </Box>
            </Card>}
        </Box>
    );
}

export default SimilarProduct;
