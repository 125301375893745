import {Button, Modal} from "@shopify/polaris";
import React from "react";
import { reqPostExposureSummary } from "../../../../api";
import intl from "../../../../i18n/intl";
const UpgradePlanModal = ({upgradeOpen,setUpgradeOpen}) => {
    // 弹窗点击人数
    const handlePageStatistics = async(type) => {
        try {
            const params = { type: type };
            await reqPostExposureSummary(params);
             // 关闭弹窗
             setUpgradeOpen();
             // 跳转到pricing页面
             let sendMsg =
        "Hello, I would like to activate PLUS plan for free.";

        // 填充信息并激活对话弹窗
        // window.Intercom('showNewMessage', sendMsg)
        window.Willdesk.show();
        window.Willdesk.chat(sendMsg);
        } catch (error) {
          console.log(error);
        }
    }
   
  return (
    <div className="discount_upgrade_modal ">
      <Modal
        open={upgradeOpen}
        onClose={() => setUpgradeOpen()}
      >
        <Modal.Section>
       <div className="plan_grade_content">
       <div className="plan_upgrade_head">
           <img style={{verticalAlign:'bottom'}} src="/emojis_hua.png" alt="dropshipman"/>
           <p><strong>$100</strong> {intl.get("reward for new users")}</p>
       </div>
       <div className="white_dsc">
       <p>Your <strong>$50</strong> {intl.get("coupon is now available! Please check your coupon list. And activate your PLUS plan")} (<strong>$50</strong>) {intl.get("for free by contacting us.")}</p>
       </div>
       <div className="plan_upgrade_conetent_button white_dsc_modal_button">
           <Button onClick={()=>handlePageStatistics('upgrade_button')}><img style={{verticalAlign:'bottom',marginRight:"6px"}} src="/emojis_plan.png" alt="dropshipman"/><span style={{fontSize:'20px'}}>{intl.get("ACTIVATE PLUS PLAN")}</span></Button>
       </div>
       </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default UpgradePlanModal;
