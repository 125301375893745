import { Box, Button, InlineStack, Modal, ProgressBar } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import "./index.css";
import { reqPostSubcribeData } from "../../api";
// soucing次数不足弹窗
const Index = ({
  open,
  onClose,
  toastFun,
  userDiscounts,
  retentionPlanData,
  handleBaseAction
}) => {
  const [timers, setTimers] = useState([]); // 定时器数组
  const [loadingModal, setLoadingModal] = useState(true);
  const [timeData, setTimeData] = useState({
    hour: "23",
    min: "59",
    sec: "59",
  });
  const handleDis = (time) => {
    if (time) {
      const timer = setInterval(() => {
        setTimeData((prevValue) => ({
          ...prevValue,
          ...discountSubTime(time),
        }));
      }, 1000);
      let timerArr = timers;
      timerArr.push(timer);
      setTimers(timerArr);
    }
  };
  // 清空所有定时器
  const clearAllTimers = () => {
    timers.forEach((timer) => {
      clearInterval(timer);
    });
    setTimers([]);
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount && open) {
      handleDis(userDiscounts?.event_active_time);
    }
    return () => {
      isMount = true;
      clearAllTimers();
    };
    // eslint-disable-next-line
  }, [open, userDiscounts]);
  const discountSubTime = (time) => {
    setLoadingModal(false);
    var nowTime = new Date(); //现在时间
    var lastTime = parseInt((time * 1000 - nowTime) / 1000); //剩余时间=未来时间-现在时间（未来时间距离现在的秒数）
    if (lastTime <= 0) {
      clearAllTimers(); // 清除时间
      return {
        hour: "00",
        min: "00",
        sec: "00",
      };
    }
    const hour = String(Math.floor((lastTime % 86400) / 3600)).padStart(2, "0");
    const min = String(Math.floor((lastTime % 3600) / 60)).padStart(2, "0");
    const sec = String(lastTime % 60).padStart(2, "0");

    return { hour, min, sec };
  };
  // 升级套餐
  const handleMageUpgrade = async (
    id,
    val,
    email = "",
    social = "",
    am_id = ""
  ) => {
    try {
      let param = {
        package_id: id,
        email: email,
        social: social,
        am_id: am_id,
      };
      setLoading(true);
      if (val && val !== "") {
        param.remark = val;
      }
      let res = await reqPostSubcribeData(param);
      if (res.code === "200") {
        if (res?.data?.id !== "") {
          if (id !== 0) {
            window.top.location.href = res.data.url;
            toastFun(res.msg);
          }
        }
        handleOff();
      } else {
        toastFun("Something wrong");
      }
      setLoading(false);
    } catch (error) {}
  };
  const [loading, setLoading] = useState(false);
  const handleOff = () => {
    let type = '';
    if (retentionPlanData.is_reinstall_pop) {
      type = 'reinstall_pop';
    }
    if (retentionPlanData.is_notify_pop) {
      type = 'notify_pop';
    }
    if (retentionPlanData.is_ordinary_pop) {
      type = 'ordinary_pop';
    }
    if (retentionPlanData.is_newbie_pop) {
      type = 'newbie_pop';
    }
    handleBaseAction(type)
    onClose();
    setLoading(false);
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {
          handleOff();
        }}
      >
        <Box className="sourcing_enough_quota retention_plan">
          <InlineStack wrap={false}>
            <Box className="sourcing_enough_quota_left">
              <Box>
                <img src={`/dashboard/exclusive_off${retentionPlanData?.is_reinstall_pop ? 4 : retentionPlanData?.is_newbie_pop ? 2 : retentionPlanData?.is_ordinary_pop ? 1 : 3}.png`} alt="logo" />
              </Box>
            </Box>
            <Box className="sourcing_enough_quota_right">
              <Box className="sourcing_enough_quota_title">
              {(retentionPlanData?.is_ordinary_pop || retentionPlanData?.is_newbie_pop) && <InlineStack gap={200} blockAlign="center">
                  <img height={24} src="/emojis_hua.png" alt="logo" />
                  <span>Exclusive Welcome Offer</span>
                </InlineStack>}
                {retentionPlanData?.is_reinstall_pop && (
                  <InlineStack>👏🏻 Welcome Back to Dropshipman!</InlineStack>
                )}
                {retentionPlanData?.is_notify_pop && <InlineStack>
                  <span style={{maxWidth:'90%'}}>
                 Your <span style={{color:"#f5520c",margin:'0 5px'}}>{userDiscounts.use_days}-Day</span> Anniversary Gift
                 <img style={{marginLeft:'5px'}} height={24} src="/emojis_hua.png" alt="logo" />
                 </span>
                </InlineStack>}
              </Box>
              {retentionPlanData?.is_reinstall_pop && (
                <Box className="retention_plan_desc">
                  We've missed you! As a token of our appreciation, we're
                  offering you an exclusive welcome-back gift.
                </Box>
              )}
              {retentionPlanData?.is_ordinary_pop ? (
                <Box className="retention_plan_price">
                <InlineStack gap={200} blockAlign="center">
                  <Box className="retention_plan_price_off">Save {parseFloat(
                        (1 - userDiscounts.event_discounts) * 100
                      ).toFixed(0)}%</Box>
                  <Box className="retention_plan_name">{"PLUS"}</Box>
                </InlineStack>
                <InlineStack gap={200} blockAlign="center">
                  <Box className="retention_plan_price_text">
                    ${" "}
                    {(49.9 * (userDiscounts.event_discounts)).toFixed(2)}{" "}
                    / month
                  </Box>
                  <Box className="retention_plan_price_text_off">
                    $ 49.9 / month
                  </Box>
                </InlineStack>
              </Box>
              ) : (
                <Box className="retention_plan_price">
                  {retentionPlanData?.is_newbie_pop && <InlineStack gap={200} blockAlign="center">
                    <Box className="retention_plan_price_off">
                      {parseFloat(
                        (1 - userDiscounts.event_discounts) * 100
                      ).toFixed(0)}
                      % off <span style={{ fontSize: "13px" }}>your first 2 months</span>
                    </Box>
                    <Box className="retention_plan_name">{"Basic"}</Box>
                  </InlineStack>}
                  {retentionPlanData?.is_newbie_pop && <InlineStack gap={200} blockAlign="center">
                    <Box className="retention_plan_price_text">
                      $ {(9.9 * (userDiscounts.event_discounts)).toFixed(2)} / month
                    </Box>
                    <Box className="retention_plan_price_text_off">
                      $ 9.9 / month
                    </Box>
                  </InlineStack>}

                  {(retentionPlanData?.is_notify_pop || retentionPlanData?.is_reinstall_pop) && <InlineStack gap={200} blockAlign="center">
                    <Box className="retention_plan_price_off">
                      {parseFloat(
                        (1 - userDiscounts.event_discounts) * 100
                      ).toFixed(0)}
                      % off <span style={{ fontSize: "13px" }}>plan</span>
                    </Box>
                    <Box className="retention_plan_name">{"PLUS"}</Box>
                  </InlineStack>}
                  {(retentionPlanData?.is_notify_pop || retentionPlanData?.is_reinstall_pop) && <InlineStack gap={200} blockAlign="center">
                    <Box className="retention_plan_price_text">
                      $ {(49.9 * (userDiscounts.event_discounts)).toFixed(2)} / month
                    </Box>
                    <Box className="retention_plan_price_text_off">
                      $ 49.9 / month
                    </Box>
                  </InlineStack>}
                  
                </Box>
              )}

              <Box className="retention_plan_timeout">
                {!loadingModal ? (
                  <InlineStack blockAlign="center">
                    <Box className="retention_plan_timeout_box">
                      <Box className="retention_plan_timeout_box_time">
                        <InlineStack gap={100} blockAlign="center">
                          <span>{String(timeData.hour).slice(0, 1)}</span>
                          <span>{String(timeData.hour).slice(1, 2)}</span>
                        </InlineStack>
                      </Box>
                      <Box className="retention_plan_timeout_box_time_dot">
                        :
                      </Box>
                    </Box>
                    <Box className="retention_plan_timeout_box">
                      <Box className="retention_plan_timeout_box_time">
                        <InlineStack gap={100} blockAlign="center">
                          <span>{String(timeData.min).slice(0, 1)}</span>
                          <span>{String(timeData.min).slice(1, 2)}</span>
                        </InlineStack>
                      </Box>
                      <Box className="retention_plan_timeout_box_time_dot">
                        :
                      </Box>
                    </Box>
                    <Box className="retention_plan_timeout_box">
                      <Box className="retention_plan_timeout_box_time">
                        <InlineStack gap={100} blockAlign="center">
                          <span>{String(timeData.sec).slice(0, 1)}</span>
                          <span>{String(timeData.sec).slice(1, 2)}</span>
                        </InlineStack>
                      </Box>
                    </Box>
                  </InlineStack>
                ) : (
                  <ProgressBar />
                )}
              </Box>
              <Box className="sourcing_enough_quota_subtitle">
                {retentionPlanData.is_newbie_pop ? planData.basic.name : planData.plus.name}
              </Box>
              <Box className="sourcing_enough_quota_subtitle_li">
              {retentionPlanData.is_newbie_pop ? <ul>
                  {planData.basic.benefits.map((it, ind) => (
                    <li key={ind}>{it}</li>
                  ))}
                </ul> : <ul>
                  {planData.plus.benefits.map((it, ind) => (
                    <li key={ind}>{it}</li>
                  ))}
                </ul>}
              </Box>

              <Box className="sourcing_enough_quota_item_button">
                {retentionPlanData?.is_reinstall_pop ? (
                  <Button
                    variant="primary"
                    loading={loading}
                    onClick={() => {
                      handleMageUpgrade(2);
                    }}
                  >
                    Claim Your{" "}
                    {parseFloat(
                      (1 - userDiscounts.event_discounts) * 100
                    ).toFixed(0)}
                    % Offer Now
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    loading={loading}
                    onClick={() => {
                      handleMageUpgrade(retentionPlanData?.is_newbie_pop ? 4 : 2);
                    }}
                  >
                    {"Upgrade Now"}
                  </Button>
                )}
              </Box>
            </Box>
          </InlineStack>
        </Box>
      </Modal>
    </Box>
  );
};
const planData = {
  plus: {
    name: "PLUS Plan Benefits",
    price: 32.9,
    discount: 0.8,
    discountPrice: 49.9,
    benefits: [
      "Access to Top-selling Products",
      "15000 Product Imports",
      "Automatic Product Pricing, Order Sourcing, and Order Fulfillment",
      "Vip Shipping Methods",
      "Dedicated Manager Services",
    ],
  },
  basic: {
    name: "Basic Plan Benefits",
    price: 19.9,
    discount: 0,
    discountPrice: 19.9,
    benefits: [
      "Access to 1M+ Products",
      "10 Sourcing Requests",
      "900 Product Imports",
      "Unlimited Orders Fulfillment",
      "24/7 Support",
    ],
  },
};

export default Index;
