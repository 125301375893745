import { ajax, ajaxWithoutAuth } from './ajax';
import { API_DOMAIN } from '../utils/constants';

/** 登录模块 */

export const ckeditorConfig = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "insertTable",
    "undo",
    "redo"
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      // { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
      // { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
    ]
  },
  //   ckfinder: {
  //       uploadUrl: API_DOMAIN + '/api/v1/ckUpload'
  //   }
};

/** 用户从Shopify 管理后台进入app授权验证模块 */
export const reqGetUserAuthInstall = (params) => ajax(API_DOMAIN + '/shopify/app', params, 'GET');

export const reqPostManualUninstall = (params) => ajax(API_DOMAIN + '/api/v1/manualUninstall', params, 'POST'); // 用户手动卸载APP

/** Dashboard 模块 */
export const reqGetAppDashboardData = (params) => ajax(API_DOMAIN + '/api/v1/getAppDashboardData', params, 'GET');
export const reqGetUserBindSaleAndWallet = (params) => ajax(API_DOMAIN + '/api/v1/getUserBindSaleAndWallet', params, 'GET'); // 获取用户是否绑定销售，并返回销售信息
export const reqPostUserBindSale = (params) => ajax(API_DOMAIN + '/api/v1/userBindSale', params, 'POST'); // 用户绑定销售
export const reqPostExposureSummary = (params) => ajax(API_DOMAIN + '/api/v1/exposure', params, 'POST'); // 页面数据埋点
export const reqPostBaseAction = (params) => ajax(API_DOMAIN + '/api/v1/baseAction', params, 'POST'); // 基础数据记录操作
export const reqGetCurrentStoreInfo = (params) => ajax(API_DOMAIN + '/api/v1/userInfo', params, 'GET');  // 获取当前用户的信息
export const reqPostGuideDismiss = (params) => ajax(API_DOMAIN + '/api/v1/guide/done', params, 'POST');  // 关闭新手引导

export const reqGetNewDashboardData = (params) => ajax(API_DOMAIN + '/api/v2/dashboard/info', params, 'GET'); //UI 升级 首页数据
export const reqPostCardDismiss = (params) => ajax(API_DOMAIN + '/api/v2/dashboard/close', params, 'POST');  //页面卡片关闭
export const reqPostGuidePoints = (params) => ajax(API_DOMAIN + '/api/v2/dashboard/guide/points', params, 'POST');  //观看视频领取积分
export const reqPostGuideList = (params) => ajax(API_DOMAIN + '/api/v2/dashboard/guide/list', params, 'GET');  //获取新人引导数据



/** 获取Shopify重新授权链接 */
export const reqGetShopifyReauthorizeLinks = (params) => ajax(API_DOMAIN + '/api/v1/reauthorize', params, 'GET');

export const reqGetAppCategories = (params) => ajax(API_DOMAIN + '/api/v1/getCategories', params, 'GET');  // 获取Dropshipman产品分类列表（一级类目）
export const reqPostSaveUserPreferences = (params) => ajax(API_DOMAIN + '/api/v1/saveUserPreferences', params, 'POST'); // 更新用户产品分类爱好信息
export const reqUpdateAllotSaleModalVisible = (params) => ajax(API_DOMAIN + '/api/v1/updateAllotSaleModalVisible', params, 'POST'); // 更新 （关闭）随机分配业务员状态
export const reqGetUserBindSaleInfo = (params) => ajax(API_DOMAIN + '/api/v1/getUserBindSaleInfo', params, 'GET'); // 用户绑定销售的信息
export const reqGetStatisticsInfo = (params) => ajax(API_DOMAIN + '/api/v1/getUserBindSaleInfo', params, 'GET'); // 获取Statistics绑定数据
export const reqPostPageEventReport = (params) => ajax(API_DOMAIN + '/api/v1/pageEventReport', params, 'POST'); // 首页用户点击事件上报

/** Analysis 模块 */
export const reqGetSideBarCount = (params) => ajax(API_DOMAIN + '/api/v1/sidebarCount', params, 'GET');  // 获取左侧导航栏数据
export const reqGetAppAnalysisData = (params) => ajax(API_DOMAIN + '/api/v1/getAppAnalysisData', params, 'POST');
export const reqGetAppProductsImportData = (params) => ajax(API_DOMAIN + '/api/v1/detailedShow', params, 'POST'); //获取报告详情
export const reqUpdateOnBoardingStatus = (params) => ajax(API_DOMAIN + '/api/v1/updateOnBoardingStatus', params, 'POST'); // 更新 onboard 客户指引状态

/** Order 模块 */
export const reqGetShopifyOrderData = (params) => ajax(API_DOMAIN + '/api/v1/getShopifyOrderData', params, 'GET'); //获取订单列表
export const reqGetSearchSaved = (params) => ajax(API_DOMAIN + '/api/v1/getSearchSaved', params, 'GET'); //获取搜索收藏
export const reqAddSearchSaved = (params) => ajax(API_DOMAIN + '/api/v1/addSearchSaved', params, 'POST'); //添加搜索收藏
export const reqDelSearchSaved = (params) => ajax(API_DOMAIN + '/api/v1/delSearchSaved', params, 'POST'); //删除搜索收藏
export const reqUpdateSearchSaved = (params) => ajax(API_DOMAIN + '/api/v1/updateSearchSaved', params, 'POST'); //修改搜索收藏
export const reqAddAliExpressOrderNumber = (params) => ajax(API_DOMAIN + '/api/v1/addAliExpressOrderNumber', params, 'POST'); //修改搜索收藏
export const reqOrderFulfilled = (params) => ajax(API_DOMAIN + '/api/v1/orderFulfilled', params, 'POST'); //订单发货
export const reqAddAliExpressBoteValue = (params) => ajax(API_DOMAIN + '/api/v1/addAliExpressBoteValue', params, 'POST'); //订单发货
export const reqUpdateCustomer = (params) => ajax(API_DOMAIN + '/api/v1/updateCustomer', params, 'POST'); //修改联系方式
export const reqPostSaveShipping = (params) => ajax(API_DOMAIN + '/api/v1/saveShipping', params, 'POST'); // 保存产品物流方式
export const reqDeliverAliexpress = (params) => ajax(API_DOMAIN + '/api/v1/deliverAliexpress', params, 'POST'); // 速卖通发货
export const reqGetOrderProductAllCount = (params) => ajax(API_DOMAIN + '/api/v1/getOrderProductAllCount', params, 'POST'); // 获取可以发货的订单数量
export const reqDeliverAliexpressBatch = (params) => ajax(API_DOMAIN + '/api/v1/deliverAliexpressBatch', params, 'POST'); // 速卖通批量发货
export const reqGetOrderProductStatus = (params) => ajax(API_DOMAIN + '/api/v1/getOrderProductStatus', params, 'POST'); // 获取订单发货状态
export const reqGetProCityData = (params) => ajax(API_DOMAIN + '/api/v1/proCityData', params, 'GET'); // 获取某个国家的省份城市数据
export const reqGetUpdateShippingInfo = (params) => ajax(API_DOMAIN + '/api/v1/updateShippingInfo', params, 'GET'); // 更新某个订单产品的运输方式
export const reqGetRefreshShippingInfo = (params) => ajax(API_DOMAIN + '/api/v1/refreshShippingInfo', params, 'GET'); // 刷新单个订单产品SKU的运输方式
export const reqSourcingOrderRequest = (params) => ajax(API_DOMAIN + '/api/v1/sourcingOrderRequest', params, 'POST'); // 用户申请订单sourcing请求

// cls
export const reqGetCls = (params) => ajax(API_DOMAIN + '/api/v2/black/cls', params, 'GET'); // 获取 cls详情信息

// 获取collections
export const reqGetUpdateCollection = (params) => ajax(API_DOMAIN + '/api/v1/new/updateCollection', params, 'GET'); // 获取 product Collection信息


export const reqGetMappingOrderInfo = (params) => ajax(API_DOMAIN + '/api/v1/getMappingOrder', params, 'GET'); // 获取 mapping 订单详情信息
export const reqAddMappingOrderNumber = (params) => ajax(API_DOMAIN + '/api/v1/addMappingOrderNumber', params, 'POST'); // 添加、更新mapping 订单号
export const reqPostSourcingOrderBatch = (params) => ajax(API_DOMAIN + '/api/v1/sourcingOrderBatch', params, 'POST'); // unknown订单的sku批量生成找货请求

// 订单重构
export const reqGetOrderListData = (params) => ajax(API_DOMAIN + '/api/v1/orderList', params, 'GET'); //订单列表获取

export const reqGetCanny = (params) => ajax(API_DOMAIN + '/api/v1/canny', params, 'GET'); //canny
export const reqPostCannyToken = (params) => ajax(API_DOMAIN + '/api/v1/cannyToken', params, 'POST'); //canny

export const reqGetUnknownOrder = (params) => ajax(API_DOMAIN + '/api/v1/unknownOrder', params, 'GET'); //获取订单列表
export const reqPostageOrderList = (params) => ajax(API_DOMAIN + '/api/v1/postageOrderList', params, 'GET'); //获取邮费订单列表
export const reqEditPostageOrderNote = (params) => ajax(API_DOMAIN + '/api/v1/editPostageOrderNote', params, 'POST'); //修改邮费订单备注
export const reqPostageOrderWallet = (params) => ajax(API_DOMAIN + '/api/v1/postageOrderWallet', params, 'POST'); //邮费订单钱包支付
export const reqPostageOrderPayPal = (params) => ajax(API_DOMAIN + '/api/v1/postageOrderPayPal', params, 'POST'); //邮费订单PayPal支付
export const reqPostSyncShopifyOrders = (params) => ajax(API_DOMAIN + '/api/v1/syncShopifyOrders', params, 'POST'); //同步用户指定时间范围的Shopify订单
export const reqPostSyncProcess = (params) => ajax(API_DOMAIN + '/api/v1/syncProcess', params, 'POST'); //订单同步轮询接口
export const reqPostUpdateSyncProcess = (params) => ajax(API_DOMAIN + '/api/v1/updateSyncProcess', params, 'POST'); //更新用户查看结果接口
export const reqGetOrderShippingMethods = (params) => ajax(API_DOMAIN + '/api/v1/orderShippingMethods', params, 'GET'); //获取物流方式
export const reqPostUpdateOrderShippingMethods = (params) => ajax(API_DOMAIN + '/api/v1/updateOrderShippingMethods', params, 'POST'); //更新物流方式
export const reqPostupdateOrderNote = (params) => ajax(API_DOMAIN + '/api/v1/updateOrderNote', params, 'POST'); //修改订单备注
export const reqGetOrderAddress = (params) => ajax(API_DOMAIN + '/api/v1/orderShippingAddress', params, 'GET'); //获取地址
export const reqPostOrderShippingAddress = (params) => ajax(API_DOMAIN + '/api/v1/updateOrderShippingAddress', params, 'POST'); //更新地址
export const reqPostSetOrderArchivedConfig = (params) => ajax(API_DOMAIN + '/api/v1/setOrderArchivedConfig', params, 'POST'); // DSM订单存档设置
export const reqPostSetOrderPaymentData = (params) => ajax(API_DOMAIN + '/api/v1/order/amount', params, 'POST'); // DSM支付前数据

/** Settings 模块 */
export const reqGetUserSettings = (params) => ajax(API_DOMAIN + '/api/v1/getUserSettings', params, 'GET'); // 根据类型 获取用户设置信息
export const reqPostUpdateUserSettings = (params) => ajax(API_DOMAIN + '/api/v1/updateUserSettings', params, 'POST'); // 根据类型 修改用户设置信息
export const reqPostApplyRules = (params) => ajax(API_DOMAIN + '/api/v1/applyRules', params, 'POST'); // 根据价格规则 应用到指定所有产品
export const reqGetDisconnect = (params) => ajax(API_DOMAIN + '/api/v1/disconnect', params, 'GET'); // 速卖通取消授权
export const reqGetCarrierAuthUrl = (params) => ajax(API_DOMAIN + '/api/v1/carrierService', params, 'GET');//生成运费授权链接
export const reqPostCreateOrUpdateService = (params) => ajax(API_DOMAIN + '/api/v1/createOrUpdateService', params, 'POST');//创建或更新服务
export const reqGetUserAddress = (params) => ajax(API_DOMAIN + '/api/v1/getUserAddress', params, 'GET'); // 获取用户收货地址列表
export const reqPostUpdateUserAddress = (params) => ajax(API_DOMAIN + '/api/v1/updateUserAddress', params, 'POST'); // 添加、修改用户地址
export const reqGetUserContactWay = (params) => ajax(API_DOMAIN + '/api/v1/getUserContactWay', params, 'GET'); // 获取用户联系方式
export const reqPostUpdateUserContactWay = (params) => ajax(API_DOMAIN + '/api/v1/updateUserContactWay', params, 'POST'); // 添加、修改用户地址//保存用户联系方式
// export const reqGetOrderPaymentConfig = (params) => ajax(API_DOMAIN + '/api/v1/getPaymentConfig', params, 'GET'); // 获取用户DSM订单支付设置
// export const reqPostUpdateOrderPaymentConfig = (params) => ajax(API_DOMAIN + '/api/v1/updatePaymentConfig', params, 'POST'); // 保存用户DSM订单配置信息




// 礼品包装模块
export const reqGetCustomPackagingListData = (params) => ajax(API_DOMAIN + '/api/v2/custom-packaging/list', params, 'GET'); //品牌包装列表
export const reqGetCustomPackagingDetail = (params) => ajax(API_DOMAIN + '/api/v2/custom-packaging/detail', params, 'GET'); //品牌包装详情
export const reqPostCustomPackagingPurchase = (params) => ajax(API_DOMAIN + '/api/v2/custom-packaging/pre-order', params, 'POST'); //品牌包装预下单
export const reqPostCustomPackagingPurchaseUpload = (params) => ajax(API_DOMAIN + '/api/v2/custom-packaging/upload', params, 'POST'); //品牌包装预下单logo上传
export const reqPostCustomPackagingPurchaseCreateOrder = (params) => ajax(API_DOMAIN + '/api/v2/custom-packaging/create-order', params, 'POST');//品牌包装创建订单
export const reqGetCustomPackagingOrderListData = (params) => ajax(API_DOMAIN + '/api/v2/custom-packaging/order-list', params, 'GET'); //品牌包装订单列表
export const reqPostCustomPackagingPurchaseOrderNote = (params) => ajax(API_DOMAIN + '/api/v2/custom-packaging/order-note', params, 'POST');//品牌包装订单备注
export const reqGetCustomPackagingInventoryListData = (params) => ajax(API_DOMAIN + '/api/v2/custom-packaging/inventory/list', params, 'GET');//品牌包装库存列表 


// 私人仓储模块
export const reqGetPersonInventoryProductListData = (params) => ajax(API_DOMAIN + '/api/v2/wholesale/order/products', params, 'GET');//私人库存产品列表
export const reqGetPersonInventoryOrderListVariants = (params) => ajax(API_DOMAIN + '/api/v2/wholesale/order/variants', params, 'GET');//私人库存产品变体列表
export const reqGetPersonInventoryOrderListData = (params) => ajax(API_DOMAIN + '/api/v2/wholesale/order/list', params, 'GET');//私人库存订单列表
export const reqGetPersonInventoryOrderPurchase = (params) => ajax(API_DOMAIN + '/api/v2/wholesale/order/create', params, 'POST'); //私人库存订单下单
export const reqGetPersonInventoryListData = (params) => ajax(API_DOMAIN + '/api/v2/inventory/list', params, 'GET');//私人仓储库存列表
export const reqGetPersonInventoryListDataRemove = (params) => ajax(API_DOMAIN + '/api/v2/inventory/remove', params, 'POST');//私人仓储库存列表移除
export const reqGetPersonInventoryHistoryListData = (params) => ajax(API_DOMAIN + '/api/v2/inventory/detail', params, 'GET');//库存记录列表
export const reqGetPersonInventoryListDataExport = (params) => ajax(API_DOMAIN + '/api/v2/inventory/export', params, 'POST');//库存记录列表导出


// 积分体系
export const reqGetPointsListData = (params) => ajax(API_DOMAIN + '/api/v2/points/list', params, 'GET');//积分获取列表
export const reqGetPointsLogListData = (params) => ajax(API_DOMAIN + '/api/v2/points/history', params, 'GET');//积分记录列表
export const reqGetPointsLogListExport = (params) => ajax(API_DOMAIN + '/api/v2/points/export', params, 'POST');//导出积分记录列表

export const reqGetPointsLogInvitationList = (params) => ajax(API_DOMAIN + '/api/v2/points/invitation', params, 'GET');//邀请好友记录
export const reqGetPointsLogInvitationExport = (params) => ajax(API_DOMAIN + '/api/v2/points/invitation/export', params, 'POST');//导出邀请记录列表
export const reqGetUserPoints = (params) => ajax(API_DOMAIN + '/api/v2/points/user', params, 'GET');//获取用户积分
export const reqGetUserPointsCoupon = (params) => ajax(API_DOMAIN + '/api/v2/points/hand/coupon', params, 'POST');//用户积分兑换优惠券
export const reqGetRedeemProducts = (params) => ajax(API_DOMAIN + '/api/v2/points/product', params, 'GET');//获取积分产品列表
export const reqGetRedeemVariants = (params) => ajax(API_DOMAIN + '/api/v2/points/variants', params, 'GET');//积分变体列表
export const reqPostRedeemCreateOrder = (params) => ajax(API_DOMAIN + '/api/v2/cart/order', params, 'POST'); //礼品订单创建
export const reqGetRedeemOrderList = (params) => ajax(API_DOMAIN + '/api/v2/points/order/list', params, 'GET');//积分订单列表
export const reqPostRedeemCancelOrder = (params) => ajax(API_DOMAIN + '/api/v2/points/order/cancel', params, 'POST');//积分订单取消

export const reqGetPointsLogFreezeListData = (params) => ajax(API_DOMAIN + '/api/v2/points/freeze/history', params, 'GET');//积分冻结记录列表

export const reqGetBlackExtraLog = (params) => ajax(API_DOMAIN + '/api/v2/black/extra/log', params, 'POST');//挽留弹窗选项
export const reqGetBlackExtraActivity = (params) => ajax(API_DOMAIN + '/api/v2/black/extra/activity', params, 'POST');//挽留弹窗订阅套餐选项





/** 提现模块 **/
export const reqWithdrawal = (params) => ajax(API_DOMAIN + '/api/v1/withdrawal', params, 'POST'); // 发起提现申请
export const reqWithdrawalCancel = (params) => ajax(API_DOMAIN + '/api/v1/withdrawal/cancel', params, 'POST'); // 取消提现申请

/** 钱包充值 */
export const reqWalletRecharge = (params) => ajax(API_DOMAIN + '/api/v1/walletRecharge', params, 'POST'); // 钱包发起充值

// 错误信息收集
export const reqPostException = (params) => ajax(API_DOMAIN + '/api/v1/exception', params, 'POST'); 

/** ContactUs 联系我们模板 */
export const reqGetThisUserEmail = (params) => ajax(API_DOMAIN + '/api/v1/getThisUserEmail', params, 'GET'); //获取Settings数据
export const reqInsertContactUs = (params) => ajax(API_DOMAIN + '/api/v1/insertContactUs', params, 'POST'); //修改Settings数据

/** My Products 模块 */
export const reqGetProductList = (params) => ajax(API_DOMAIN + '/api/v1/getProductList', params, 'GET'); // 获取product List数据
export const reqPostQueueProcess = (params) => ajax(API_DOMAIN + '/api/v1/queueProcess', params, 'POST'); // 添加产品搜索状态查询
export const reqPostQueryProduct = (params) => ajax(API_DOMAIN + '/api/v1/queryProduct', params, 'POST'); // 搜索产品并入库
export const reqPostImportProduct = (params) => ajax(API_DOMAIN + '/api/v1/importProduct', params, 'POST'); // 上传产品到Shopify平台
export const reqPostImportProcess = (params) => ajax(API_DOMAIN + '/api/v1/importProcess', params, 'POST'); // 产品上传Shopify状态更新查询
export const reqPostDeleteProduct = (params) => ajax(API_DOMAIN + '/api/v1/deleteProduct', params, 'POST'); // 删除产品
export const reqPostProductAction = (params) => ajax(API_DOMAIN + '/api/v1/productAction', params, 'POST'); // 产品修改控制器
export const reqPostGetShippingInfo = (params) => ajax(API_DOMAIN + '/api/v1/getShippingInfo', params, 'POST'); // 根据收货国家计算运费
export const reqPostSpiltProduct = (params) => ajax(API_DOMAIN + '/api/v1/spiltProduct', params, 'POST'); // 产品分割
export const reqGetBatchProcess = (params) => ajax(API_DOMAIN + '/api/v1/batchProcess', params, 'GET'); // 获取批量操作状态 [产品分割]
export const reqPostOverrideImportProduct = (params) => ajax(API_DOMAIN + '/api/v1/overrideImport', params, 'POST'); // 未完成订单产品override保存sku映射配置 + 产品上传到Shopify

/** My Products -- Mapping模块 */
export const reqGetMappingInfo = (params) => ajax(API_DOMAIN + '/api/v1/mappingInfo', params, 'GET'); // 获取（已上传的Shopify）产品设置的Mapping设置
export const reqPostUpdateMappingInfo = (params) => ajax(API_DOMAIN + '/api/v1/updateMappingInfo', params, 'POST');  // Mapping设置 数据更新
export const reqPostRemoveMappingProduct = (params) => ajax(API_DOMAIN + '/api/v1/removeMappingProduct', params, 'POST');  // 移除Mapping产品
export const reqPostImportMappingProduct = (params) => ajax(API_DOMAIN + '/api/v1/mappingProduct', params, 'POST');  // 搜索Mapping产品入库
export const reqPostImportInfo = (params) => ajax(API_DOMAIN + '/api/v1/getImportInfo', params, 'POST');  // 搜索Mapping产品入库

export const reqGetDefaultShipping = (params) => ajax(API_DOMAIN + '/api/v1/defaultShipping', params, 'GET'); // [Default Shipping] 获取产品的默认运输方式设置
export const reqPostDefaultShipAction = (params) => ajax(API_DOMAIN + '/api/v1/defaultShipAction', params, 'POST'); // [Default Shipping] (已上传Shopify或者Mapping产品)产品的默认运输方式 Action


/** My Products --> Shopify product */
export const reqGetShopifyProductList = (params) => ajax(API_DOMAIN + '/api/v1/getShopifyProductList', params, 'GET'); // 获取shopifyProduct List数据
export const reqGetLinkingInfo = (params) => ajax(API_DOMAIN + '/api/v1/linking', params, 'GET'); // [Linking速卖通产品] 获取shopify产品详情 (或者修改状态)
export const reqGetAliExpressData = (params) => ajax(API_DOMAIN + '/api/v1/aliExpressData', params, 'GET'); // [Linking速卖通产品] 获取速卖通产品详情
export const reqPostLinkingData = (params) => ajax(API_DOMAIN + '/api/v1/linkingSave', params, 'POST'); // [Linking速卖通产品] 保存匹配信息入库
export const reqPostAgreement = (params) => ajax(API_DOMAIN + '/api/v1/agreement', params, 'POST'); // 
export const reqPostSourcingProductBatch = (params) => ajax(API_DOMAIN + '/api/v1/sourcingProductBatch', params, 'POST'); // shopify产品批量生成找货请求

/** 速卖通产品自动sourcing模块 */
export const reqGetDropshipmanSupplier = (params) => ajax(API_DOMAIN + '/api/v1/getDropshipmanSupplier', params, 'GET');  // 获取速卖通产品sourcing匹配成功的产品配置信息
export const reqPostSupplierToDropshipman = (params) => ajax(API_DOMAIN + '/api/v1/supplierToDropshipman', params, 'POST'); // 用户速卖通产品替换为DSM产品
export const reqGetOrderSupplier = (params) => ajax(API_DOMAIN + '/api/v1/getOrderSupplier', params, 'GET');  // 获取用户速卖通订单对应sourcing匹配成功的产品sku信息
export const reqPostOrderToDropshipman = (params) => ajax(API_DOMAIN + '/api/v1/OrderToDropshipman', params, 'POST');  // 用户速卖通订单替换为DSM（速卖通产品替换为DSM产品）
export const reqPostOrderFreight = (params) => ajax(API_DOMAIN + '/api/v1/Order/freight', params, 'POST');  // // 速卖通订单获取DSM产品物流方式


// affiliate
export const reqGetAffiliateData = (params) => ajax(API_DOMAIN + '/api/v1/get-affiliate', params, 'GET');  // // affiliate用户链接

/** Notifications 模块 */
export const reqGetNotificationList = (params) => ajax(API_DOMAIN + '/api/v1/getNotificationList', params, 'GET'); // 获取Notifications List数据
export const reqGetNotifyProductVariants = (params) => ajax(API_DOMAIN + '/api/v1/getNotifyInfo', params, 'GET'); // 获取[指定产品]更新变体数据
export const reqPostUpdateAutoSettings = (params) => ajax(API_DOMAIN + '/api/v1/updateAutoSetting', params, 'POST'); // 更新用户设置 [价格变动设置，库存变动设置]
export const reqPostOverrideByAnother = (params) => ajax(API_DOMAIN + '/api/v1/overrideByAnother', params, 'POST'); // Notification 产品覆盖 [速卖通URL、速卖通ID]
export const reqPostNotifyAction = (params) => ajax(API_DOMAIN + '/api/v1/notifyAction', params, 'POST'); // 通知更新 操作

export const reqGetDropshipmanNotificationList = (params) => ajax(API_DOMAIN + '/api/v1/getDsmNotificationList', params, 'GET'); // 获取用户DSM产品 Notifications List数据
export const reqPostDropshipmanNotifyAction = (params) => ajax(API_DOMAIN + '/api/v1/dsmNotifyAction', params, 'POST'); // 用户DSM产品通知记录更新 操作

/** Partners 模块 */
export const reqGetPartnerBannersList = (params) => ajax(API_DOMAIN + '/api/v1/getBanners', params, 'GET'); // 获取Partner页面banner列表


/** Branding 模块 [Sourcing progress] */
export const reqGetSourcingList = (params) => ajax(API_DOMAIN + '/api/v1/sourcingList', params, 'GET'); // 获取Sourcing progress List数据
export const reqPostSourcingRequest = (params) => ajax(API_DOMAIN + '/api/v1/sourcingRequest', params, 'POST'); // [用户申请sourcing请求] 新增
export const reqPostSourcingAction = (params) => ajax(API_DOMAIN + '/api/v1/sourcingAction', params, 'POSTFORM'); // [单个sourcing请求] 获取详情 、删除(单个或批量)
export const reqPostUploadImage = (params) => ajax(API_DOMAIN + '/api/v1/uploadImage', params, 'POST'); // [sourcing请求] 图片上传
export const reqPostSourcingSourcinConfirmList = (params) => ajax(API_DOMAIN + '/api/v1/sourcingAwaiting', params, 'POST') // SourcinConfirmList 
export const reqPostSourcingConfirmDone = (params) => ajax(API_DOMAIN + '/api/v1/sourcingAwaitingConfirm ', params, 'POST') // SourcingConfirmDone

/** Branding 模块 [Sourcing Square] */
export const reqGetSourcingSquareRequest = (params) => ajax(API_DOMAIN + '/api/v1/sourcingSquare', params, 'GET');//Sourcing Square
export const reqPostSourcingSquareAddToMine = (params) => ajax(API_DOMAIN + '/api/v1/addToMine', params, 'POST')


/** Branding 模块 [Dropshipman列表] */
export const reqGetDropshipmanProductList = (params) => ajax(API_DOMAIN + '/api/v1/dropshipmanList', params, 'GET'); // 获取dropshipman用户产品列表
export const reqPostImportDropshipmanProduct = (params) => ajax(API_DOMAIN + '/api/v1/dropshipmanProductImport', params, 'POST'); // 上传dropshipman产品到Shopify平台
export const reqPostDropshipmanImportProcess = (params) => ajax(API_DOMAIN + '/api/v1/dropshipmanImportProcess', params, 'POST'); // dropshipman产品上传Shopify状态更新查询
export const reqPostDelDropshipmanProduct = (params) => ajax(API_DOMAIN + '/api/v1/delDropshipmanProduct', params, 'POST'); // 删除dropshipman产品
export const reqPostDropshipmanProductAction = (params) => ajax(API_DOMAIN + '/api/v1/dropshipmanProductAction', params, 'POST'); // dropshipman产品控制器
export const reqPostDropshipmanLinkingInfo = (params) => ajax(API_DOMAIN + '/api/v1/dropshipmanLinkingInfo', params, 'POST'); // dropshipman 获取Linking数据
export const reqPostDropshipmanLinkingSave = (params) => ajax(API_DOMAIN + '/api/v1/dropshipmanLinkingSave', params, 'POST'); // dropshipman Linking数据保存
export const reqPostDropshipmanOverride = (params) => ajax(API_DOMAIN + '/api/v1/dropshipmanOverride', params, 'POST'); // dropshipman 产品Override
export const reqPostDropshipmanShippingCosts = (params) => ajax(API_DOMAIN + '/api/v1/shippingCosts', params, 'POST'); // dropshipman 产品 计算运费
export const reqPostImportProductFrom1688 = (params) => ajax(API_DOMAIN +'/api/v1/importProductFrom1688',params, 'POST'); // 1688产品链接导入
export const reqGetWholesaleProductDetails = (params) => ajax(API_DOMAIN + '/api/v1/wholesaleProductDetails', params, 'GET'); // [DSM批发产品] 获取指定产品变体详情数据
export const reqPostWholesaleProductOrder = (params) => ajax(API_DOMAIN + '/api/v1/wholesaleOrder', params, 'POST'); // [DSM批发产品] 生成订单
/** 产品列表重构接口 */
export const reqGetNewMyProductCount = (params) => ajax(API_DOMAIN +'/api/v1/new/myProductCount',params, 'GET'); // 获取产品列表tab数量
export const reqGetNewProductList = (params) => ajax(API_DOMAIN +'/api/v1/new/getProductList',params, 'GET'); // 获取产品列表
export const reqGetNewProductListInfo = (params) => ajax(API_DOMAIN +'/api/v1/new/getInfo',params, 'POST'); // 获取列表产品详情
export const reqPostNewImportProduct = (params) => ajax(API_DOMAIN +'/api/v1/new/smt/importProduct',params, 'POST'); // 速卖通导入
export const reqPostNewBaImportProduct = (params) => ajax(API_DOMAIN +'/api/v1/new/1688/importProduct',params, 'POST'); // 1688导入
export const reqPostDropshipmanNewProductAction = (params) => ajax(API_DOMAIN + '/api/v1/new/productAction', params, 'POST'); // dropshipman产品控制器
export const reqGetShopifyProvinceData = (params) => ajax(API_DOMAIN + '/api/v1/getProvinceData', params, 'GET'); // [DSM批发产品] 获取shopify国家城市数据
export const reqGetDsmRecommend = (params) => ajax(API_DOMAIN + '/api/v1/dsmRecommend', params, 'GET'); // 是否展示推荐模块 recommend大于0 代表有推荐商品，0代表没有
export const reqPostDropshipmanNewShippingCosts = (params) => ajax(API_DOMAIN + '/api/v1/new/shippingCosts', params, 'POST'); // dropshipman 产品 计算运费
export const reqPostDropshipmanNewShippingInfo = (params) => ajax(API_DOMAIN + '/api/v1/new/getShippingInfo', params, 'POST'); // dropshipman 产品 计算运费
export const reqPostNewQueueProcess = (params) => ajax(API_DOMAIN + '/api/v1/new/queueProcess', params, 'POST'); // 添加产品搜索状态查询
export const reqPostNewDeleteProduct = (params) => ajax(API_DOMAIN + '/api/v1/new/deleteProduct', params, 'POST'); // 删除产品
export const reqPostNewRestoreProduct = (params) => ajax(API_DOMAIN + '/api/v1/new/restore', params, 'POST'); // 恢复产品
export const reqPostImportProductStore = (params) => ajax(API_DOMAIN + '/api/v1/new/importProductToShopify', params, 'POST'); // 上传dropshipman产品到Shopify平台
export const reqGetWholesaleNewProductDetails = (params) => ajax(API_DOMAIN + '/api/v1/new/wholesaleProductDetails', params, 'GET'); // [DSM批发产品]
export const reqPostWholesaleNewProductOrder = (params) => ajax(API_DOMAIN + '/api/v1/new/wholesaleOrder', params, 'POST'); // [DSM批发产品] 生成订单


/** Branding 模块 [Custom Packaging 列表] */
export const reqGetCustomPackagingList = (params) => ajax(API_DOMAIN + '/api/v1/packagingList', params, 'GET'); // 获取custom packaging列表
export const reqGetPackageDetail = (params) => ajax(API_DOMAIN + '/api/v1/packagingDetail', params, 'GET'); // 获取packaging详情
export const reqPostCreatePackageOrder = (params) => ajax(API_DOMAIN + '/api/v1/createPackageOrder', params, 'POST'); // 生成礼品包装订单

/** Inventory 模块 */
export const reqGetInventoryList = (params) => ajax(API_DOMAIN + '/api/v1/inventoryList', params, 'GET'); // 获取库存列表 [礼品包装 / 批发产品]

export const reqGetSkuDetails = (params) => ajax(API_DOMAIN + '/api/v1/skuDetails', params, 'GET'); // 获取需要增加库存的sku的详情
export const reqPostAddInventory = (params) => ajax(API_DOMAIN + '/api/v1/addInventory', params, 'POST'); // 指定sku增加库存生成订单
export const reqPostRemoveInventory = (params) => ajax(API_DOMAIN + '/api/v1/removeInventory', params, 'POST'); // 移除指定sku库存记录

/** Dropshipman orders 模块 */
export const reqGetDropshipmanOrderList = (params) => ajax(API_DOMAIN + '/api/v1/dropshipmanOrders', params, 'GET'); // 获取dropshipman订单列表
export const reqPostEditDropshipmanOrderNote = (params) => ajax(API_DOMAIN + '/api/v1/editOrderNote', params, 'POST'); // 修改dropshipman订单备注
export const reqPostPayForOrder = (params) => ajax(API_DOMAIN + '/api/v1/payPal', params, 'POST'); // PayPal支付
export const reqWalletTransaction = (params) => ajax(API_DOMAIN + '/api/v1/walletTransaction', params, 'POST'); // 钱包支付交易
export const reqCouponPay = (params) => ajax(API_DOMAIN + '/api/v1/coupon/pay', params, 'POST'); //支付弹窗获取符合优惠券

export const reqPostConnectOrderId = (params) => ajax(API_DOMAIN + '/api/v1/connectOrderId', params, 'POST'); // DSM订单创建PayPal付款订单ID（点击checkout 按钮）
export const reqPostSelectedShipping = (params) => ajax(API_DOMAIN + '/api/v1/selectedShipping', params, 'POST'); // DSM订单保存用户选择的运输方式
export const reqPostSetArchivedConfig = (params) => ajax(API_DOMAIN + '/api/v1/archivedConfig', params, 'POST'); // DSM订单存档设置
export const reqPostGetOrderAddress = (params) => ajax(API_DOMAIN + '/api/v1/orderAddress', params, 'POST'); // 动态获取DSM订单的地址信息
export const reqPostUpdateOrderAddress = (params) => ajax(API_DOMAIN + '/api/v1/updateOrderAddress', params, 'POST'); // 更新DSM订单的地址信息
export const reqPostPaymentProcess = (params) => ajax(API_DOMAIN + '/api/v1/paymentProcess', params, 'POST'); // DSM订单付款状态
export const reqPostSetOrderConfig = (params) => ajax(API_DOMAIN + '/api/v1/setOrderConfig', params, 'POST'); // DSM订单修改 getNotCompleteSku
export const reqGetNotCompleteSku = (params) => ajax(API_DOMAIN + '/api/v1/getNotCompleteSku', params, 'GET'); // 获取Shopify订单未sourcing完成的sku信息
export const reqGetPackagingInventory = (params) => ajax(API_DOMAIN + '/api/v1/order/packaging', params, 'GET'); // 获取订单包装信息
export const reqPostUpdateOrderPackaging = (params) => ajax(API_DOMAIN + '/api/v1/order/packaging/update', params, 'POST'); // 保存订单包装信息
export const reqGetOrderTracking = (params) => ajax(API_DOMAIN + '/api/v1/getOrderTracking', params, 'GET'); // 获取物流详情
export const reqPostRemoveOrderSku = (params) => ajax(API_DOMAIN + '/api/v1/removeOrderSku', params, 'POST'); // 移除订单详情sku

// 库存释放
export const reqPostRemoveOrderInventory = (params) => ajax(API_DOMAIN + '/api/v1/release/inventory', params, 'POST'); 


/**订单追踪 模块 */
export const reqGetOrderTrackingList = (params) => ajax(API_DOMAIN + '/api/v1/order/tracking/list', params, 'GET') // 获取订单追踪列表
export const reqGetOrderTrackingGoodInfos = (params) => ajax(API_DOMAIN + '/api/v1/order/detail', params, 'GET') // 获取订单追踪列表详情
export const reqGetOrderTrackingMessages = (params) => ajax(API_DOMAIN + '/api/v1/order/message', params, 'GET') // 获取订单追踪留言列表
export const reqGetOrderTrackingAddress = (params) => ajax(API_DOMAIN + '/api/v1/orderAddress', params, 'GET') // 获取订单追踪地址信息
export const reqGetOrderTrackingMessageUpdate = (params) => ajax(API_DOMAIN + '/api/v1/order/message/update', params, 'POST') // 更新订单留言提醒
export const reqGetOrderSalesInfo = (params) => ajax(API_DOMAIN + '/api/v1/salesInfo', params, 'GET') // 订单绑定销售


/** Dropshipman 购物车 模块 */
export const reqGetShoppingCartList = (params) => ajax(API_DOMAIN + '/api/v1/getShoppingCart', params, 'GET'); // 获取[批发产品/礼品包装]购物车列表
export const reqPostAddShoppingCart = (params) => ajax(API_DOMAIN + '/api/v1/addShoppingCart', params, 'POST'); // [批发产品/礼品包装] 添加购物车
export const reqPostShoppingCartAction = (params) => ajax(API_DOMAIN + '/api/v1/shoppingCartAction', params, 'POST'); // 购物车操作: 更新、移除
export const reqPostCreateOrderByCart = (params) => ajax(API_DOMAIN + '/api/v1/createOrderByCart', params, 'POST'); // [指定类型] 通过购物车创建订单
export const reqPostPreOrderShippingMethods = (params) => ajax(API_DOMAIN + '/api/v1/getOrderShipping', params, 'POST'); // [自定义订单] 获取运输方式

/** Find Products 模块  */
export const reqGetFindProductsList = (params) => ajax(API_DOMAIN + '/api/v1/getFindProductsList', params, 'GET'); //[Find Products列表] 获取
export const reqGetFindProductsDetail = (params) => ajax(API_DOMAIN + '/api/v1/getFindProductsDetail', params, 'GET'); // [具体Find Products详情] 获取
export const reqPostAddProductToList = (params) => ajax(API_DOMAIN + '/api/v1/addToList', params, 'POST'); // [具体Find Products详情] 获取
export const reqPostCalculateFreight = (params) => ajax(API_DOMAIN + '/api/v1/calculateFreight', params, 'POST'); // [Find Products] DSM订单运费估算
export const reqGetFindProductsMenu = (params) => ajax(API_DOMAIN + '/api/v1/getProductClassify', params, 'GET'); //获取类别列表
export const reqPreference = (params) => ajax(API_DOMAIN + '/api/v1/preference', params, 'POST'); //获取类别列表 
export const reqGetProductReviews = (params) => ajax(API_DOMAIN + '/api/v1/getProductReviews', params, 'GET');  //获取 DSM产品好评列表
export const reqGetCoupon = (params) => ajax(API_DOMAIN + '/api/v1/coupon/popup', params, 'POST'); //获取优惠券弹窗
export const reqReceiveCoupon = (params) => ajax(API_DOMAIN + '/api/v1/coupon/receive', params, 'POST'); //领取优惠券
export const reqCouponList = (params) => ajax(API_DOMAIN + '/api/v1/coupon/list', params, 'POST'); //优惠券列表

/** Messages 模块 */
export const reqGetMessageList = (params) => ajax(API_DOMAIN + '/api/v1/getMessageList', params, 'GET'); // 获取Messages List数据
export const reqPostUpdateMessageRead = (params) => ajax(API_DOMAIN + '/api/v1/updateMessageRead', params, 'POST'); // 修改Message为已读
export const reqPostDeleteMessage = (params) => ajax(API_DOMAIN + '/api/v1/deleteMessage', params, 'POST'); // 删除Message

/** Pricing 模块 */
export const reqGetPricingPlan = (params) => ajax(API_DOMAIN + '/api/v1/getPricingPlan', params, 'GET'); // 获取 价格页面套餐版本
export const reqPostUpdateUserPlan = (params) => ajax(API_DOMAIN + '/api/v1/updatePlan', params, 'POST'); // 用户更换套餐版本

/** 授权更新 */
export const reqGetAliAuthor = (params) => ajax(API_DOMAIN + '/api/v1/author', params, 'GET'); // 获取速卖通授权Url

/* 订阅 */
export const reqPostUserPrivate = (params) => ajax(API_DOMAIN + '/api/v1/setUserPrivate', params, 'POST'); // 产品私有化开关
export const reqGetUserSubcribe = (params) => ajax(API_DOMAIN + '/api/v1/subscribe/limit', params, 'GET'); // 用户会员权限
export const reqPostSubcribeData = (params) => ajax(API_DOMAIN + '/api/v1/subscribe', params, 'POST'); // 用户会员升级
export const reqGetSubcribeData = (params) => ajax(API_DOMAIN + '/api/v1/getSubscribe', params, 'GET'); //获取升级页面
export const reqPostUpdateUserPriceSettings = (params) => ajax(API_DOMAIN + '/api/v1/setUserPrice', params, 'POST'); // 根据类型 修改用户设置信息
export const reqGetFindSubProductsList = (params) => ajax(API_DOMAIN + '/api/v1/trendy/list', params, 'POST'); //[Find Products列表] 获取
export const reqGetFindHolidayProductsList = (params) => ajax(API_DOMAIN + '/api/v1/holiday/list', params, 'GET'); //[Find Products列表] 获取节日商品
export const reqGetFindSubProductsDetail = (params) => ajax(API_DOMAIN + '/api/v1/trendy/detail', params, 'GET'); // [具体Find Products详情] 获取
export const reqPostAddSubProductToList = (params) => ajax(API_DOMAIN + '/api/v1/trendy/add', params, 'POST'); // [具体Find Products详情] 导入商品
export const reqPostNoviceRedeem = (params) => ajax(API_DOMAIN + '/api/v2/points/guide', params, 'POST'); //新手引导步骤 满足条件获取优惠券
export const reqPostNoviceStep = (params) => ajax(API_DOMAIN + '/api/v1/guide/step', params, 'POST'); //新手引导步骤 记录当前步骤
export const reqPostDsmPopup = (params) => ajax(API_DOMAIN + '/api/v1/global/popup', params, 'GET'); //弹窗步骤
export const reqGetOrderStatistics = (params) => ajax(API_DOMAIN + '/api/v2/dashboard/order-statistics', params, 'GET'); //订单数据统计表
export const getOrderStatisticsCsv = (params) => ajax(API_DOMAIN + '/api/v2/dashboard/order-statistics-export', params, 'GET'); //订单数据导出
export const getPageSpeedData = (params) => ajax(API_DOMAIN + '/api/v1/pagespeed/detail', params, 'GET'); //pagespeed服务数据
export const getPageSpeedPay = (params) => ajax(API_DOMAIN + '/api/v1/pagespeed/pay', params, 'POST'); //pagespeed服务支付数据
export const getPageSpeedPayStatus = (params) => ajax(API_DOMAIN + '/api/v1/pagespeed/webhook', params, 'POST'); //pagespeed服务支付数据
export const getPostCateSelect = (params) => ajax(API_DOMAIN + '/api/v1/guide/category', params, 'POST'); //保存偏好选择
export const getGetProductAddSuccess = (params) => ajax(API_DOMAIN + '/api/v1/bfs/click/importProduct', params, 'GET'); //关闭导入商品弹窗
export const getGetAdvertisementOff = (params) => ajax(API_DOMAIN + '/api/v1/bfs/click/advertisement', params, 'GET'); //关闭广告banner
// export const reqGetSourcingListData = (params) => ajax(API_DOMAIN + '/api/v1/new/sourcing/list', params, 'POST'); //获取sourcing列表数据
export const getGetSourcingListCount = (params) => ajax(API_DOMAIN + '/api/v1/new/sourcing/count', params, 'GET'); //获取sourcing数量
export const getPostSourcingCancel = (params) => ajax(API_DOMAIN + '/api/v1/new/sourcing/cancel', params, 'POST'); //取消sourcing状态


// cj
// sourcing
export const reqGetSourcingListData = (params) => ajax(API_DOMAIN + '/api/v2/goods/sourcing/list', params, 'GET');//获取sourcing列表数据
export const reqGetSourcingListDataDetalis = (params) => ajax(API_DOMAIN + '/api/v2/goods/sourcing/info', params, 'GET');//常规找货详情
export const reqGetSourcingListDataDelete = (params) => ajax(API_DOMAIN + '/api/v2/goods/sourcing/del', params, 'GET');//常规找货详情
export const reqPostSourcingRequestSave = (params) => ajax(API_DOMAIN + '/api/v2/goods/no/sourcing', params, 'POST'); //无货源找货请求
export const reqPostSourcingGoodsRequestSave = (params) => ajax(API_DOMAIN + '/api/v2/goods/sourcing', params, 'GET'); //有货源找货请求
export const getGetSourcingListCjCount = (params) => ajax(API_DOMAIN + '/api/v2/goods/sourcing/count', params, 'GET'); //获取sourcing数量
export const getGetSourcingListCjInfo = (params) => ajax(API_DOMAIN + '/api/v2/goods/sourcing/info', params, 'GET'); //详情
export const getGetSourcingListCjDelete = (params) => ajax(API_DOMAIN + '/api/v2/goods/sourcing/del', params, 'POST'); //删除sourcing
export const reqGetShopifyNewProductList = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/shopify', params, 'GET'); // 获取newshopifyProduct List数据
export const reqShopifyNewStoreBatchSourcing = (params) => ajax(API_DOMAIN + '/api/v2/goods/batch/sourcing', params, 'POST'); // 店铺产品批量sourcing


export const reqGetSourcingExpedite = (params) => ajax(API_DOMAIN + '/api/v2/goods/sourcing/expedite', params, 'POST');// sourcing加急提醒

// 购物车
export const reqGetCartListData = (params) => ajax(API_DOMAIN + '/api/v2/cart/list', params, 'GET');//获取购物车列表数据
export const reqPostCartUpdateSave = (params) => ajax(API_DOMAIN + '/api/v2/cart/update', params, 'POST'); //无货源找货请求
export const reqPostCartShoppigData = (params) => ajax(API_DOMAIN + '/api/v2/cart/shopping', params, 'POST'); //获取预下单物流运输方式
export const reqPostCartCreateOrder = (params) => ajax(API_DOMAIN + '/api/v2/cart/order', params, 'POST'); //购物车创建订单
export const reqPostCartProductAdd = (params) => ajax(API_DOMAIN + '/api/v2/cart/add', params, 'POST'); //添加购物车


// find product
export const reqGetFindProductListData = (params) => ajax(API_DOMAIN + '/api/v2/find-product/list', params, 'GET');//获取product列表数据
export const reqPostFindProductAdd = (params) => ajax(API_DOMAIN + '/api/v2/find-product/add', params, 'POST'); //添加产品进产品列表
export const reqGetFindProductDetailsData = (params) => ajax(API_DOMAIN + '/api/v2/find-product/detail', params, 'GET');//获取产品详情数据
export const reqPostFindProductDetailsFreight = (params) => ajax(API_DOMAIN + '/api/v2/find-product/freight', params, 'POST'); //物流方式计算 (运输方式，运费，时效)
export const reqGetFindProductDetailsReviews = (params) => ajax(API_DOMAIN + '/api/v2/find-product/reviews', params, 'GET'); //商品详情评论
export const reqGetFindProductCateListData = (params) => ajax(API_DOMAIN + '/api/v2/category/list', params, 'GET');//获取product列表分类数据
export const reqGetFindProductListCateData = (params) => ajax(API_DOMAIN + '/api/v2/find-product/recommended', params, 'GET');//获取分类product列表数据


// setting
export const reqGetOrderPaymentConfig = (params) => ajax(API_DOMAIN + '/api/v2/setting/payment', params, 'GET'); // 获取用户DSM订单支付设置
export const reqPostUpdateOrderPaymentConfig = (params) => ajax(API_DOMAIN + '/api/v2/setting/payment/update', params, 'POST'); // 保存用户DSM订单配置信息


// order
export const reqPostUpdateOrderSourcingConfig = (params) => ajax(API_DOMAIN + '/api/v1/setUserAuto', params, 'POST'); // 自动找货设置
export const reqPostUpdateOrderBatchSourcing = (params) => ajax(API_DOMAIN + '/api/v2/order/batch/sourcing', params, 'POST'); // 批量发起找货
export const reqGetOrderBatchSourcingReason = (params) => ajax(API_DOMAIN + '/api/v2/order/sourcing/reason', params, 'GET'); // 发起找货失败原因



// payonner 支付模块
export const getPayonnerAuth = (params) => ajax(API_DOMAIN + '/api/v1/payoneer/auth', params, 'GET'); //获取授权链接
export const getPayonnerAuthOff = (params) => ajax(API_DOMAIN + '/api/v1/payoneer/disconnect', params, 'POST'); //取消授权
export const getPayonnerAuthBalances = (params) => ajax(API_DOMAIN + '/api/v1/payoneer/balances', params, 'GET'); //获取账户余额
export const getPayonnerAuthBalancesRecharge = (params) => ajax(API_DOMAIN + '/api/v1/payoneer/recharge', params, 'POST'); //发起转账请求
export const getPayonnerAuthBalancesRechargeCommit = (params) => ajax(API_DOMAIN + '/api/v1/payoneer/recharge/commit', params, 'POST'); //转账确认请求
export const getPayonnerAuthBalancesOrder = (params) => ajax(API_DOMAIN + '/api/v1/payoneer/order', params, 'POST'); //订单付款请求
export const getPayonnerAuthBalancesOrderDetails = (params) => ajax(API_DOMAIN + '/api/v1/payoneer/prepaidOrder', params, 'POST'); //获取订单金额以及用户Payoneer余额账户信息
export const getPayonnerAuthBalancesOrderCommit = (params) => ajax(API_DOMAIN + '/api/v1/payoneer/order/commit', params, 'POST'); //订单转账交易确认


// dlocal 支付模块

export const getDlocalAuthBalancesOrder = (params) => ajax(API_DOMAIN + '/api/v1/dlocal/order', params, 'POST'); //订单付款请求


/** App用户登录模块 */
export const reqPostAuthUser = (params) => ajaxWithoutAuth(API_DOMAIN + '/api/v1/authUser', params, 'POST'); // [用户验证] 登录或者注册
export const reqPostResetPasswordLink = (params) => ajaxWithoutAuth(API_DOMAIN + '/api/v1/reset', params, 'POST'); // [重置密码] 发送重置链接
export const reqPostVerifyLink = (params) => ajaxWithoutAuth(API_DOMAIN + '/api/v1/verifyLink', params, 'POST'); // [重置密码] 校验重置密码链接
export const reqPostResetPasswordSave = (params) => ajaxWithoutAuth(API_DOMAIN + '/api/v1/resetPassword', params, 'POST'); // [重置密码] 重置密码保存

export const reqPostBindingStore = (params) => ajax(API_DOMAIN + '/api/v1/bindingStore', params, 'POST'); // [店铺绑定] shopify店铺绑定
export const reqPostAppAccountAction = (params) => ajax(API_DOMAIN + '/api/v1/accountAction', params, 'POST'); // [平台用户] 信息更新操作

export const reqPostSwitchVersionOrDomain = (params) => ajax(API_DOMAIN + '/api/v1/authorization', params, 'POST'); // 模拟登录 (Shopify站内跳转站外，多店铺切换)

/** 速卖通联盟推广模块 */
export const reqGetAliExpressAffiliateLink = (params) => ajax(API_DOMAIN + '/api/v1/source', params, 'POST'); // 生成速卖通推广链接

/** App公共页面模块 */
export const reqGetDropshipmanProductLibraryList = (params) => ajaxWithoutAuth(API_DOMAIN + '/api/v1/product-list', params, 'GET'); // 获取dropshipman产品库列表
export const reqGetDropshipmanProductDetail = (params) => ajaxWithoutAuth(API_DOMAIN + '/api/v1/product-detail', params, 'GET'); // 获取dropshipman产品详情信息
export const reqPostDropshipmanProductShipping = (params) => ajaxWithoutAuth(API_DOMAIN + '/api/v1/product-shipping', params, 'POST'); // 获取dropshipman产品运输方式

/** PayPal授权模块 */
export const reqPostDisConnetPayPal = (params) => ajax(API_DOMAIN + '/api/v1/disconnect-paypal', params, 'POST'); // 取消PayPal授权信息

// seo建站服务模块
export const reqGetSeoExperts = (params) => ajax(API_DOMAIN + '/api/v1/seo/plan', params, 'GET'); // 获取套餐
export const reqPostSeoExperts = (params) => ajax(API_DOMAIN + '/api/v1/seo/pay', params, 'POST'); // 支付套餐链接
export const reqGetPayStatus = (params) => ajax(API_DOMAIN + '/api/v1/seo/notice', params, 'GET'); // 支付套餐状态

// 订单开票模块
export const reqGetOrderInvoice = (params) => ajax(API_DOMAIN + '/api/v1/getOrderInvoice', params, 'GET'); // 支付套餐状态
// 开票下载
export const reqPostOrderInvoice = (params) => ajax(API_DOMAIN + '/api/v1/addOrderInvoice', params, 'POST'); // 支付套餐状态
// 黑色星期五模块
export const reqGetBlackFridayProduct = (params) => ajax(API_DOMAIN + '/api/v1/black/friday', params, 'GET'); // 折扣商品
export const reqGetBlackFridayCoupon = (params) => ajax(API_DOMAIN + '/api/v1/black/friday/coupon', params, 'GET'); // 优惠券数据
export const reqPostCouponDraw = (params) => ajax(API_DOMAIN + '/api/v1/black/friday/coupon/draw', params, 'POST'); // 优惠券领取
export const reqPostCouponPop = (params) => ajax(API_DOMAIN + '/api/v1/black/friday/pop', params, 'GET'); // 优惠券领取
export const reqPostCouponFourthPop = (params) => ajax(API_DOMAIN + '/api/v1/fourth/coupon/draw/log', params, 'GET'); // 优惠券列表
export const reqPostCouponFourthDraw = (params) => ajax(API_DOMAIN + '/api/v1/fourth/coupon/draw', params, 'POST'); // 优惠券领取


// 2024
export const reqGetBlackFridayRaffle = (params) => ajax(API_DOMAIN + '/api/v2/black/turntable', params, 'GET'); // 抽奖
export const reqGetBlackFridayRaffleLog = (params) => ajax(API_DOMAIN + '/api/v2/black/turntable/log', params, 'GET'); // 抽奖记录
export const reqGetBlackFridayRafflePlan = (params) => ajax(API_DOMAIN + '/api/v2/black/lottery/members', params, 'POST'); // 兑换会员
export const reqGetBlackFridayRaffleProduct = (params) => ajax(API_DOMAIN + '/api/v2/black/lottery/product', params, 'POST'); // 兑换商品


// chatgpt
export const reqGetOptimizeProductTitle = (params) => ajax(API_DOMAIN + '/api/v1/optimizeProductTitle', params, 'GET'); // ai优化商品标题
export const reqPostOptimizeProductTitleSave = (params) => ajax(API_DOMAIN + '/api/v1/updateUserProductTitle', params, 'POST'); // ai优化商品标题保存
export const reqGetOptimizeProductDescription = (params) => ajax(API_DOMAIN + '/api/v1/optimizeProductDescription', params, 'GET'); // ai优化商品描述
export const reqPostOptimizeProductDescriptionSave = (params) => ajax(API_DOMAIN + '/api/v1/updateUserProductDescription', params, 'POST'); // ai优化商品描述保存

/** App已图搜图 */
export const reqFileMapSearch = (params) => ajax(API_DOMAIN + '/api/v1/map/search/image', params, 'FILE'); // 用户上传图片
export const reqFileMapSearchDetail = (params) => ajax(API_DOMAIN + '/api/v1/map/search/detail', params, 'POST'); // 1688商品详情
export const reqFileMapSearchImport = (params) => ajax(API_DOMAIN + '/api/v1/map/search/import', params, 'POST'); // 1688导入

// markpalce
export const reqGetProductSelectionData = (params) => ajax(API_DOMAIN + '/api/v2/product-selection/list', params, 'GET'); // 获取产品列表
export const reqPostFavorite = (params) => ajax(API_DOMAIN + '/api/v2/product-selection/favorite', params, 'POST'); // 收藏商品
export const reqPostProductFreight = (params) => ajax(API_DOMAIN + '/api/v2/product-selection/freight', params, 'POST');// 获取运费
export const reqGetProductSelectionDetail = (params) => ajax(API_DOMAIN + '/api/v2/product-selection/detail', params, 'GET'); // 获取产品详情
export const reqGetProductSelectionRelatedDetail = (params) => ajax(API_DOMAIN + '/api/v2/product-selection/related', params, 'GET'); // 获取产品详情 相关产品列表
export const reqGetProductSelectionReviewsDetail = (params) => ajax(API_DOMAIN + '/api/v2/product-selection/reviews', params, 'GET'); // 获取产品详情 评论

// 用户满意度调查
export const reqGetProductSelectionSurvey = (params) => ajax(API_DOMAIN + '/api/v2/questionnaire/product-selection', params, 'GET');// 


// management
export const reqGetProductManagementProducts = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/count', params, 'GET');// 产品列表tab数量
export const reqGetProductManagementProductsList = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/query', params, 'POST');// 产品列表数据

// 属性集合
export const reqGetProductManagementProductsAttributes = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/attributes', params, 'GET');
// 保存变体、属性、物流方式
export const reqPostProductManagementVariantsSave = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/save', params, 'PUT');
// 产品上传
export const reqPostProductManagementProductsUploadShopify = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/upload-to-shopify', params, 'POST');
// 产品上传/导入监听
export const reqGetProductManagementProductsUploadStatus = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/sync-status', params, 'POST');

// 速卖通导入
export const reqPostProductManagementProductsAliexpressImport = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/aliexpress/import', params, 'POST');

// 删除产品
export const reqPostProductManagementProductsDelete = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/delete', params, 'DELETE');

// 恢复产品
export const reqPostProductManagementProductsRestore = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/restore', params, 'PUT');

// 产品详情获取
export const reqGetProductManagementProductsDetail = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/purchase/detail', params, 'GET');
// 变体列表获取
export const reqGetProductManagementProductsVariants = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/variant', params, 'GET');

// ai标题优化
export const reqPostProductManagementProductsAiTitle = (params) => ajax(API_DOMAIN + '/api/v2/ai/optimize/title', params, 'POST');

export const reqPostProductManagementProductsAiDescription = (params) => ajax(API_DOMAIN + '/api/v2/ai/optimize/description', params, 'POST');

// 计算运费
export const reqGetProductManagementProductsFreight = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/freight', params, 'GET');

// 同步产品集合
export const reqPostProductManagementProductsCollectionsSync = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/collections/sync', params, 'PUT');

// 更新用户产品标题
export const reqPostProductManagementProductsTitle = (params) => ajax(API_DOMAIN + '/api/v2/ai/products/title', params, 'PUT');

// 获取异常导入数据
export const reqGetProductManagementProductsException = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/fail-count', params, 'GET');

// 获取问卷状态
export const reqGetProductManagementProductsQuestionnaire = (params) => ajax(API_DOMAIN + '/api/v2/questionnaire/pop', params, 'GET');
// 保存提交状态
export const reqPostProductManagementSubmitQuestionnaire = (params) => ajax(API_DOMAIN + '/api/v2/questionnaire/submit', params, 'GET');

// 跳转shopify 产品详情
export const reqGetProductManagementProductsShopifyDetail = (params) => ajax(API_DOMAIN + '/api/v2/product-management/products/shopify/store', params, 'GET');


/** google 分析发送 */
export const reqGoogleAnalytics = (event, event_id, params = {}) => {
  try {
    window.gtag(event, event_id, params);
    // console.info("gtag success...");
  } catch (e) {
    console.log(e);
    return 400;
  }
  return 200;
}