import React, { memo } from "react";
import { Modal } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";

const RefundTipsModal = memo(({ open,saveLoading,setSwitchSourcingOpen, handleSourcingConfigOff }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setSwitchSourcingOpen(false)}
        title={intl.get("Auto order sourcing")}
        primaryAction={{
          content: intl.get("Done"),
          loading: saveLoading,
          onAction: () => {
            handleSourcingConfigOff(true)
          },
        }}
        secondaryActions={[
          {
            content: intl.get("Cancel"),
            onAction: () => {
              setSwitchSourcingOpen(false)
            },
          },
        ]}
      >
        <Modal.Section>
          <div style={{ padding: "16px" }}>
          {intl.get("Once you activate Auto Order Sourcing, if your store's orders sync with Dropshipman but don't match any products, the system will auto-initiate sourcing requests. Confirm if you want to enable this.")}
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
});

export default RefundTipsModal;
