import React, { memo } from "react";
import { Modal, Toast, Icon } from "@shopify/polaris";
import { useState } from "react";
import { DuplicateIcon } from "@shopify/polaris-icons";
const RefundTipsModal = memo(({ referralsData, setReferrlasData }) => {
  
   // 复制按钮
   const copyClick = (id) => {
    let text = document.getElementById(`${id}`);
    text.select();
    document.execCommand("Copy");
    toggleToast("Copy successfully");
  };
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;

  return (
    <div>
      {toastMarkup}
      <Modal
        open={referralsData.isOpen}
        loading={false}
        onClose={() => {
          setReferrlasData((prevValue) => ({ ...prevValue, isOpen: false }));
        }}
        title={"Referrals"}
        primaryAction={{
          content: "Close",
          onAction: () => {
            setReferrlasData((prevValue) => ({ ...prevValue, isOpen: false }));
          },
        }}
      >
        <Modal.Section>
          <p>
            Copy this link and share it with your friends. If a friend installs
            Dropshipman successfully through your link and doesn't uninstall it
            within 24 hours, you'll earn 500 points. Your friend will get a 50%
            discount on all Dropshipman packages. Each friend can claim this
            offer once, and reinstallations count only once.
          </p>
          <p style={{ display: "flex" ,marginTop:16}}>
          <span className="refund_tip_copy">
          <input
            onChange={() => console.log("")}
            type="text"
            id="referralsLink"
            value={referralsData.link}
          />
          </span>
          <a style={{color:'rgba(0, 122, 255, 1)'}} href={referralsData.link} target="_blank" rel="noopener noreferrer">{referralsData.link}</a>
            <span
              onClick={() =>
                copyClick('referralsLink')
              }
              className="pf5"
            >
              <Icon source={DuplicateIcon} tone="base" />
            </span>
          </p>
        </Modal.Section>
      </Modal>
    </div>
  );
});

export default RefundTipsModal;
