import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {
  Page,
  Card,
  Layout,
  SkeletonBodyText,
  Pagination,
  Toast,
  Tabs,
  Filters,
  SkeletonPage,
  IndexTable,
  Thumbnail,
  Modal,
  TextField,
  BlockStack,
  DropZone,
  Banner,
  List,
  // TextStyle,ChoiceList,
  Button,
  Text,
  Box,
  InlineStack,
  Link,
} from "@shopify/polaris";
import {
  DeleteIcon
} from '@shopify/polaris-icons';
import Zmage from 'react-zmage';
import 'react-zmage/lib/zmage.css';

import PreOrderClass from './PreOrder';
import _ from "lodash";
import {
  reqGetCartListData, reqPostCartUpdateSave
} from '../../../../api';

import '../index.css';
import intl from '../../../../i18n/intl';

import utils from '../../../../utils/utils';

import { reqPostUploadImage,reqPostUpdateUserSettings } from '../../../../api'
import { store } from '../../../../store';

class ShoppingCartClass extends Component {

  state = {
    //页面动作
    loadingPage: false,
    isSearching: false,
    page: 1,
    size: 50,
    hasPrevious: false,
    hasNext: true,
    ShowCountNumber: 0,
    userBalance: 0,  // 用户余额
    logoUrl: '',

    tabSelected: 0,
    queryValue: '',  // 关键词内容搜索
    sortBy: '',

    filterValue: '',
    filterIsSearch: false,

    // 列表原始数据
    pageData: [],

    moreDataSwitch: false,   // 开关，是否允许开启更多数据选择
    isAllowSelectAllItems: false,  // 是否选择更多的数据 (未显示的)
    allResourcesSelected: false,  // (当前页面,并且包括未显示的) 全选
    selectedResources: [],

    // 移除购物车记录ID集合
    RemoveTipsActive: false,
    removeIds: [],
    removeActionLoading: false,
    updateQuantityLoading: false,

    selectSkuCount: 0,
    selectSkuPrice: '0.00',
    preOrderActive: false,  // 跳转到预下单页面
    preOrderData: [],

    isOpenLogoModal: false, //logomodal状态

    files: [],  // 正在上传的图片数组
    rejectedFiles: [],
    successImages: [],  // 成功上传的数组
    isPreview:false, //预览状态
    userData:{}
  };

  //进入页面调用接口
  componentDidMount() {
    setTimeout(() => {
      this.getPageData();
    }, 10);
  }

  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,

      showToastIserror: showToastIserror
    }));
  };

  // 预下单页面回调
  preOrderPageCallBack = (data) => {
    if (!data) return false;

    if (data.type !== 0) {
      this.setState({
        preOrderActive: false,
      })
    } else {
      // window.location.href = "/admin/shopping_cart";

      // 重新获取列表数据
      this.setState({
        preOrderActive: false,
        selectedResources: []
      }, () => {
        this.getPageData();
      })
    }
  }
  handleFiltersQueryChangeDebounce = _.debounce(() =>{
    this.getPageData(true);
  },500)
  // 获取列表数据
  getPageData = async (isSearch = false) => {
    if (isSearch) {
      this.setState({
        isSearching: true,
        hasPrevious: false,
        hasNext: false,
        selectSkuCount: 0,
        selectSkuPrice: '0.00',
        preOrderData: []
      });
    } else {
      this.setState({
        loadingPage: true,
        hasPrevious: false,
        hasNext: false,
        selectSkuCount: 0,
        selectSkuPrice: '0.00',
        preOrderData: []
      });
    }

    // 类型设置
    // let selectType = 0;
    // switch (this.state.tabSelected) {
    //   case 0:
    //     selectType = 4;
    //     break;
    //   case 1:
    //     selectType = 3;
    //     break;
    //   case 2:
    //     selectType = 2;
    //     break;
    //   default:
    //     selectType = 4;
    // }

    const params = {
      title: this.state.queryValue,
      page: this.state.page,
      size: this.state.size,
      type: 4,
    };
    const response = await reqGetCartListData(params);
    if (response.code === '200') {
      let getData = response.data;
      let person = store.getState().languageReducer.subScriptData;
      this.setState({
        userData:person
      })
      this.setState({
        loadingPage: false,
        pageData: getData.result,
        total: getData.count,
        ShowCountNumber: getData.count,
        userBalance: getData.balance,
        isSearching: false,
        logoUrl: getData.logo
      });
      this.props.callBackFun({number:getData.count})
      // 更新分页数据
      if (this.state.page > 1) {
        this.setState({
          hasPrevious: true
        });
      } else {
        this.setState({
          hasPrevious: false
        });
      }

      const maxPage = Math.ceil(response.data.count / this.state.size);
      if (this.state.page < maxPage) {
        this.setState({
          hasNext: true
        });
      } else {
        this.setState({
          hasNext: false
        });
      }

    } else {
      this.toggleToast(response.msg);
      this.setState({
        loadingPage: false,
        pageData: [],
        isSearching: false,
      });
    }
  }

  // tabs 切换
  handleTabChange = (index) => {
    this.setState({
      tabSelected: index,
      selectedResources: [],
      filterValue: '',
      filterIsSearch: false,
    }, () => {
      this.handleFiltersQueryChangeDebounce()
    });
  }


  // filter 清除所有选项选择
  handleFiltersClearAll = () => {
    this.setState({
      queryValue: '',
      selectedResources: [],
      filterValue: '',
      filterIsSearch: false,
      page: 1,
    }, () => {
      this.handleFiltersQueryChangeDebounce()
    });
  }

  // 搜索输入
  handleFiltersQueryChange = (value) => {
    this.setState({
      queryValue: value,
      selectedResources: [],
      page: 1,
    }, () => {
      this.handleFiltersQueryChangeDebounce()
    });
  }

  // 清空搜索内容
  handleQueryValueRemove = () => {
    this.setState({
      queryValue: '',
      selectedResources: [],
      page: 1,
    }, () => {
      this.handleFiltersQueryChangeDebounce()
    });
  }

  // Input change
  handleInputChange = (recordKey, value) => {
    let data = this.state.pageData;
    // 数量不能少于起订量
    value = (value >= data[recordKey].min_quantity) ? value : data[recordKey].min_quantity;
    data[recordKey].quantity = value;
    this.setState({
      pageData: data
    }, () => {
      // 更新购买数量
      setTimeout(() => {
        this.handleUpdateSkuQuantity(recordKey);
        this.handleCalculatePrice();
      }, 1000);
    });
  }


  // [SelectionType] checkbox类型：all (选择所有数据，包括未展示的) / multi (范围选择) / page (全选当前页面数据) / single (单个)
  // [toggleType] 动作：true / false 
  // [selection] 当前选中行的vid值
  handleSelectionChange = (SelectionType, toggleType, selection = 0) => {
    let data = this.state.pageData;
    let selectedData = this.state.selectedResources;
    let isCheckedAllMore = false;
    let isAllowSelectAll = false;

    switch (SelectionType) {
      case 'single':
        if (toggleType) {
          selectedData.push(selection);

          // 判断时候已选中当前页面所有数据
          if (this.state.moreDataSwitch) {
            if (selectedData.length === data.length) {
              isAllowSelectAll = true;
            }
          }

        } else {
          let index = selectedData.indexOf(selection);
          if (index > -1) {
            selectedData.splice(index, 1);
          }
        }

        break;

      case 'page':
        if (!toggleType) {
          // 重置数组
          selectedData.length = 0;

        } else {
          selectedData.length = 0;
          for (let i = 0; i < data.length; i++) {
            selectedData.push(data[i].vid);
          }

          // 判断时候已选中当前页面所有数据
          if (this.state.moreDataSwitch) {
            if (selectedData.length === data.length) {
              isAllowSelectAll = true;
            }
          }
        }
        break;

      case 'multi':
        // 此时 selection 返回的是一个数组 [startKey,endKey]，数组的范围，开始的key和结束的key
        if (selection.length !== undefined) {
          for (let i = selection[0]; i <= selection[1]; i++) {
            let inArrayKey = selectedData.indexOf(data[i].vid);
            if (inArrayKey === -1) {
              selectedData.push(data[i].vid);
            }
          }

          // 判断时候已选中当前页面所有数据
          if (this.state.moreDataSwitch) {
            if (selectedData.length === data.length) {
              isAllowSelectAll = true;
            }
          }
        }
        break;

      case 'all':
        isCheckedAllMore = toggleType;
        if (this.state.moreDataSwitch && toggleType) {
          isAllowSelectAll = true;
        }
        break;

      default:
    }

    this.setState({
      selectedResources: selectedData,
      allResourcesSelected: isCheckedAllMore,
      isAllowSelectAllItems: isAllowSelectAll
    }, () => {
      // 更新选中的sku
      this.handleCalculatePrice();
    });
  }

  // 选中sku计算价格
  handleCalculatePrice = () => {
    let data = this.state.pageData;
    let selectedData = this.state.selectedResources;

    let count = 0;
    let allPrice = 0;
    let orderData = [];
    if (selectedData.length > 0) {
      for (let i = 0; i < selectedData.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (selectedData[i] === data[j].vid) {
            count++;
            allPrice += (data[j].price * Number(data[j].quantity));
            orderData.push(data[j]);
          }
        }
      }
    }

    this.setState({
      selectSkuCount: count,
      selectSkuPrice: allPrice.toFixed(2),
      preOrderData: orderData
    });
  }

  // 移除购物车sku
  handleRemoveSku = () => {
    let data = this.state.pageData;
    let selectedData = this.state.selectedResources;  // vid集合
    let ids = [];
    if (selectedData.length > 0) {
      for (let i = 0; i < selectedData.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (selectedData[i] === data[j].vid) {
            ids.push(data[j].id);
          }
        }
      }
    }

    this.setState({
      removeIds: ids,
    }, () => {
      // 弹窗提示
      this.handleRemoveTips();
    });
  }

  // 移除记录提示弹窗
  handleRemoveTips = () => {
    this.setState(({ RemoveTipsActive }) => ({ RemoveTipsActive: !RemoveTipsActive }));
  }

  // 移除购物车确认
  handleRemoveDone = async () => {
    let ids = this.state.removeIds;
    if (ids.length > 0) {
      this.setState({ removeActionLoading: true });
      const params = {
        type: 'remove',
        ids: ids,
        quantity: 1
      };
      const response = await reqPostCartUpdateSave(params);
      this.setState({ removeActionLoading: false });

      if (response.code === '200') {
        // if (response.data > 0) {  // 有修改,刷新列表,并取消选择
          this.setState({
            selectedResources: []
          }, () => {
            setTimeout(() => {
              this.getPageData(true);
            }, 600);
          })
        // }
        this.toggleToast('Removed successfully');
        this.handleRemoveTips();
      } else {
        this.toggleToast(response.msg);
      }

    } else {
      this.toggleToast('No select', false);
    }
  }

  // 更新购物车sku数量
  handleUpdateSkuQuantity = async (recordKey) => {
    if (!this.state.removeActionLoading) {
      // 正在更新中...
      if (this.state.updateQuantityLoading) {
        return false;
      }

      let data = this.state.pageData;
      let recordId = data[recordKey].id;
      let quantity = data[recordKey].quantity;

      this.setState({ updateQuantityLoading: true });
      const params = {
        type: 'update',
        ids: [recordId],
        quantity: quantity
      };
      const response = await reqPostCartUpdateSave(params);
      this.setState({ updateQuantityLoading: false });

      if (response.code === '200') { }

    } else {
      this.toggleToast('Updating, please wait', false);
    }
  }

  // 跳转到预下单组件
  handlePreOrder = () => {
    //判断是否上传过logo图
    const isExistsLogoUrl = this.state.logoUrl;
    if(!isExistsLogoUrl && this.state.tabSelected === 2) {
      this.setState({
        isOpenLogoModal: true
      })
      return false;
    }

    this.setState({
      preOrderActive: true
    })
  }

  // 关闭logo上传弹窗
  handleCloseLogoModal = () => {
    this.setState({
      successImages:[],
      isOpenLogoModal: false
    })
  }
  
  //save
  saveData = async (isUpdate=false) => {
    if (!isUpdate) {
      this.setState({
        saveDataLoading: true
      });
    }
    const params = {
      type:'config',
      url: JSON.stringify(this.state.successImages),
    };
    const responseData = await reqPostUpdateUserSettings(params);

    this.setState({
      saveDataLoading: false
    });
    if (responseData.code === "200") {
      if (!isUpdate) {
        this.toggleToast(responseData.msg, false);
      }
    } else {
      this.toggleToast(responseData.msg);
    }
  };

  //图片上传处理
  handleDrop = (droppedFiles, acceptedFiles, rejectedFiles) => {
    try {
      const res = utils.sizeFilter(acceptedFiles[0].size)
      // const fileType = acceptedFiles[0].type
      const fileType = acceptedFiles[0].name
      //判断文件上传规则
      if(res>5 || fileType.indexOf('jpg') ===-1 & fileType.indexOf('png') ===-1 ){
        // this.toggleToast('The image size should not exceed 5M, and must a JPG or PNG',false)
        return false
      }
    } catch (error) {
      // this.toggleToast('The image size should not exceed 5M, and must a JPG or PNG',false)
      return false
    }

    // 正在上传图片时
    if (this.state.files.length > 0) {
      this.toggleToast("Image is uploading", false);
      return;
    }
    this.setState({
      files: [...this.state.files, ...acceptedFiles],
      rejectedFiles: rejectedFiles
    }, () => {
      if (acceptedFiles.length > 0) {
        var imageData = []
        for (var i = 0; i < acceptedFiles.length; i++) {
          const filesImg = acceptedFiles[i];
          // 开启FileReader
          var reader = new FileReader();
          reader.readAsDataURL(filesImg); // 读取文件保存在result中
          reader.onload = (e) => {
            var getRes = e.target.result; // 读取的结果在result中
            imageData.push(
              {
                'name': filesImg.name,
                'image': getRes,
                'type': filesImg.type,
              }
            );
          }
        }

        // 延时请求，预防拿不到图片数据
        setTimeout(() => {
          this.uploadImages(imageData);
        }, 2000);

      }
    })
  }

  //图片上传
  uploadImages = async (imageData) => {
    const params = {
      data: imageData
    }
    const response = await reqPostUploadImage(params)
    if (response.code === '200') {
      // this.toggleToast('Success',false);

      let successArray = this.state.successImages;
      const getData = response.data

      if (getData.length > 0) {
        for (let index = 0; index < getData.length; index++) {
          successArray.push(getData[index]);
        }
        this.setState({
          successImages: successArray,
          files: [],
          rejectedFiles: [],
          logoUrl: getData[0]
        }, () => {
          // 更新到数据库
          setTimeout(() => {
            this.saveData(true);
          }, 600);
        })
      } else {
        this.toggleToast(response.msg);
        this.setState({
          files: [],
          rejectedFiles: []
        });
      }
    }
  }

  // 移除图片链接
  removeImage = (recordKey) =>{
    let data = this.state.successImages;
    data.splice(recordKey, 1);
    this.setState({
      successImages: data
    });
  }
  
  //图片预览
  handlePreviewImageChange=()=>{
    
    this.setState({
      isOpenLogoModal:false,
      isPreview : true
    })
  }

  // 判断logo是否上传成功
  handleDoneLogo = () => { 
    const logoUrl = this.state.logoUrl;
    if (logoUrl) { 
      this.toggleToast('success',false)
      this.setState({isOpenLogoModal: false})
    } else {  //未上传
      this.toggleToast('Please upload image',false);
    }
  }

  handleClosePreviewImage=() =>{
    this.setState({
      isOpenLogoModal:true,
      isPreview: false
    })
  }
  
  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} duration={3000} /> : null;

    const {
      isSearching, ShowCountNumber, pageData, allResourcesSelected, selectedResources, isAllowSelectAllItems, RemoveTipsActive, removeIds, selectSkuCount, selectSkuPrice,
      preOrderActive, preOrderData, userBalance, successImages, files, rejectedFiles
    } = this.state;

    const initSkeletonPage = (
      <div>
        <SkeletonPage>
        <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
        </SkeletonPage>
      </div>
    );

    const RemoveTipsModal = (
      <Modal
        open={RemoveTipsActive}
        onClose={this.handleRemoveTips}
        title={intl.get("Remove product sku")}
        primaryAction={{
          content: intl.get('Remove'),
          onAction: this.handleRemoveDone,
          disabled: (removeIds.length > 0) ? false : true,
          loading: this.state.removeActionLoading
        }}
        secondaryActions={[
          {
            content: intl.get('Cancel'),
            onAction: this.handleRemoveTips,
          },
        ]}
      >
        <Modal.Section>
           
            <p>
              {intl.get('Are you sure to remove {{removeIds}} product sku items.',removeIds.length)}
            </p>
           
        </Modal.Section>
      </Modal>
    )

    // const TabSelectArray = [
    //   {
    //     id: "tab-product-buy",
    //     content: intl.get("My Purchases"),
    //     accessibilityLabel: "All product buy",
    //   },
    //   {
    //     id: "tab-product-cart",
    //     content: intl.get("Inventory"),
    //     accessibilityLabel: "All product cart",
    //   },
    //   {
    //     id: "tab-packaging-cart",
    //     content: intl.get("Packaging"),
    //     accessibilityLabel: "All packaging cart",
    //   }
    // ];

    var footerContentMsg = '';
    if (this.state.total > 0) {
      const startNum = (((this.state.page - 1) * this.state.size) + 1);
      const endNum = (this.state.page * this.state.size > this.state.total) ? this.state.total : this.state.page * this.state.size;
      footerContentMsg = <div>{intl.get("Showing {{startNum}} - {{endNum}} of {{total}} results", startNum, endNum, this.state.total)}</div>;
    }

    const PaginationSettings = this.state.loadingPage ? '' :
      (ShowCountNumber !== 0) ?
        <div className="Polaris-New-Pagination">
          <Pagination
            label={footerContentMsg} 
            hasPrevious={this.state.hasPrevious}
            onPrevious={() => {
              this.setState(({ page: this.state.page - 1 }), () => {
                this.getPageData(true);
              });
            }}
            hasNext={this.state.hasNext}
            onNext={() => {
              this.setState(({ page: this.state.page + 1 }), () => {
                this.getPageData(true);
              });
            }}
          />
        </div> : '';

    // 单复数 显示设置
    const resourceName = (this.state.tabSelected === 0 || this.state.tabSelected === 1) ? (
      {
        singular: 'product',
        plural: 'products',
      }
    ) : (
      {
        singular: 'packaging',
        plural: 'packagings',
      }
    );

    // 批量操作
    const promotedBulkActions = [
      {
        content: intl.get('Remove'),
        onAction: this.handleRemoveSku,
      },
    ];
    // 批量操作 more actions 数组
    const bulkActions = [];

    // 列表数据
    const rowMarkup = pageData.map((item, index) => {
      let imageUrl = '/default_image.png';
      if (item.sku_image !== '' && (typeof item.sku_image) !== 'object') {
        imageUrl = item.sku_image;
      }

      return (
        <IndexTable.Row
          id={item.vid}
          key={item.vid}
          selected={selectedResources.includes(item.vid)}
          position={index}
        >
          <IndexTable.Cell>
          <Link
            dataPrimaryLink
            url={''}
            onClick={() => console.log()}
          >
            <Thumbnail source={imageUrl} alt={item.title} />
          </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div className="Inventory_Product">
              <div className="Inventory_Product__Title shopping_cart" title={item.title}><span>{item.title}</span></div>
              <div className="Inventory_Variant_Title">{item.variant_title}</div>
              <div>{item.sku}</div>
            </div>
          </IndexTable.Cell>

          <IndexTable.Cell>
            <div>
              <BlockStack>
                <span>{'$' + (item.price).toFixed(2) + ' USD  *'}</span>
                <TextField
                  type='number'
                  onChange={(value) => this.handleInputChange(index, value)}
                  value={String(item.quantity)}
                  min={item.min_quantity}
                />
              </BlockStack>
            </div>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    },
    );

    const hasError = rejectedFiles.length > 0;
    const fileUpload = !files.length && <DropZone.FileUpload actionTitle="Add logo" />;

    // 图片正在上传状态
    const uploadedFiles = files.length > 0 && (
      <div className="config_logo">
        {files.map((file, index) => (
          <div style={{ position: 'relative' }} key={index}>
            <Thumbnail
              alt={file.name}
              source={window.URL.createObjectURL(file)}
              size="large"
            />
            {/* <div>
              {file.name} <Caption>{file.size} bytes</Caption>
            </div> */}
            <div className="Product__Loading">
              <div className="Sourcing_Request__Loading_Image">
                <img style={{ width: '25px', height: '25px', verticalAlign: 'middle' }} src='/loading.gif' alt="loading" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );


    // 图片logo上传成功展示
    const successImagesFiles = successImages.length > 0 && (
      <div className="config_logo">
        {successImages.map((it, kk) => (
          
          <div className="Sourcing_Image_Item" style={{ position: 'relative' }} key={kk}>

            <div className='imagePreview'>
              {/* <img
                src={ it }
                alt="brand_logo"
                onClick={this.handlePreviewImageChange}
              /> */}
              <Zmage src={it} alt='' />
            </div>

            <div className="Sourcing__Loading">
              <div className="Config_Logo__Image">
                <div className="image_remove__button" >
                  <Button icon={DeleteIcon}
                    onClick={()=>this.removeImage(kk)}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );

    
    //图片上传成功点击预览
    const previewImagesFiles = (
      <Modal
        open={this.state.isPreview}
        onClose={this.handleClosePreviewImage}
      >
        <Modal.Section>
           
            {
              successImages.map((img,key)=>(
                <div className='imagePreview' key={key} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <img
                    src={img}
                    alt="brand_logo"
                    preset="auto"
                  />
                </div>
              ))
            }
           
        </Modal.Section>
      </Modal>
  )

    // 图片上传错误
    const errorMessage = hasError && (
      <Banner
        title={intl.get("The following images couldn't be uploaded:")}
        tone="warning"
      >
        <List type="bullet">
          {rejectedFiles.map((file, index) => (
            <List.Item key={index}>
              {intl.get(`{{file}} is not supported. File type must be .jpg, .jpeg, .png.`,file.name)}
            </List.Item>
          ))}
        </List>
      </Banner>
    );

    // Logo modal 
    const initLogoModal = (
      <div>
        <Modal
          title={intl.get("Logo not uploaded yet")}
          open={this.state.isOpenLogoModal}
          onClose={this.handleCloseLogoModal}
          primaryAction={{
            content: intl.get('Done'),
            onAction: () => { this.handleDoneLogo() },
          }}
          secondaryActions={[
            {
              content: intl.get('Cancel'),
              onAction: () => {
                this.setState({
                  isOpenLogoModal: false,
                  successImages: []
                });
              },
            },
          ]}
        >
          <Modal.Section>
             
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>{intl.get("You have not uploaded the logo, please upload the logo first and then continue to place order.")}</p>
             
            {/* <Stack> */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: 114, height: 114 }}>
                {(successImages.length > 0) ? (
                  successImagesFiles

                ) : (
                  <DropZone
                    accept="image/*"
                    type="image"
                    onDrop={this.handleDrop}
                    disabled={(files.length > 0) ? true : false}
                  >
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                )}
              </div>
              <div style={{ color: '#8D8989', marginLeft: '1em', marginTop: '2.5em' }}>
                <p>{intl.get("Dimensions: 1200px by 1200px.")}</p>
                <p>{intl.get("Must be a .jpg or .png")}</p>

                <div className="space">
                  {errorMessage}
                </div>
              </div>
            </div>
            {/* </Stack> */}
          </Modal.Section>
        </Modal>
      </div>
    )

    const pageShoppingCartContent = this.state.loadingPage ? initSkeletonPage : (
      <section>
        <Page
          backAction={
            this.state.loadingPage ? undefined : {
            content:'',
            onAction: () => {
              utils.routeListen('/admin/dashboard')
              utils.routeJump(this,'/admin/dashboard')
            }
          }}
          title={<div style={{fontSize:20}}>{intl.get('My Spendings')}</div>}
        >
          <Layout>
            <Layout.Section>
              <BlockStack gap={400}>
              
              <Card>
                <Tabs
                  tabs={
                    this.state.userData.domain === '4cd062.myshopify.com' ? [
                      {
                        id: "tab-product-buy",
                        content: intl.get("My Purchases"),
                        accessibilityLabel: "All product buy",
                      },
                     {
                        id: "tab-product-cart",
                        content: intl.get("Inventory"),
                        accessibilityLabel: "All product cart",
                      },
                    ] :[
                      {
                        id: "tab-product-buy",
                        content: intl.get("My Purchases"),
                        accessibilityLabel: "All product buy",
                      },
                     
                    ]
                  }
                  selected={this.state.tabSelected}
                  onSelect={this.handleTabChange}
                >

                  <div style={{ padding: '16px', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      <Filters
                        queryValue={this.state.queryValue}
                        queryPlaceholder={intl.get('Search by Product Title')}
                        filters={[]}
                        appliedFilters={[]}
                        onQueryChange={this.handleFiltersQueryChange}
                        onQueryClear={this.handleQueryValueRemove}
                        onClearAll={this.handleFiltersClearAll}
                      />
                    </div>
                  </div>

                  <div>
                  <Box style={{maxHeight:'380px',overflowY:'auto',overflowX:'hidden'}}>
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={pageData.length}
                      selectedItemsCount={
                        allResourcesSelected ? intl.get('All') : selectedResources.length
                      }
                      onSelectionChange={this.handleSelectionChange}
                      hasMoreItems={isAllowSelectAllItems}
                      bulkActions={bulkActions}
                      promotedBulkActions={promotedBulkActions}
                      loading={isSearching}
                      headings={[
                        { title: '' },
                        { title: intl.get('Product') },
                        { title: intl.get('Quantity') },
                      ]}
                    >
                      {rowMarkup}
                    </IndexTable>
                    </Box>
                  </div>

                </Tabs>
              </Card>
              <Card>
               <Text variant="headingMd" as="h2">{intl.get("Order")}</Text>
                <BlockStack>
                  <InlineStack align='space-between'>
                  <Box>
                    <Text variant="headingMd" as="h2">{intl.get("Subtotal")}:</Text>
                  </Box>
                  <Box>
                    <div className='prepaid_order_total'>{'$' + selectSkuPrice + ' USD'}</div>
                  </Box>
                  </InlineStack>
                  <Box style={{ marginTop: '10px' }}>
                  <InlineStack align='end'>
                  <Button variant='primary' disabled={!selectSkuCount} onClick={()=>this.handlePreOrder()}>{selectSkuCount ? intl.get('Continue({{selectSkuCount}})',selectSkuCount) : intl.get('Continue')}</Button>
                  </InlineStack>
                  </Box>
                </BlockStack>
              </Card>
              {/* 提示上传logo区域 */}
              { initLogoModal }
              </BlockStack>

              {/* 分页 */}
              {PaginationSettings}

            </Layout.Section>

            {/* <Layout.Section>
              <div style={{ "paddingTop": "10px" }}></div>
            </Layout.Section> */}
          </Layout>
        </Page>
      </section>
    )

    const pageContent = !preOrderActive ? pageShoppingCartContent : (
      <PreOrderClass
        type={this.state.tabSelected}
        orderData={preOrderData}
        userBalance={userBalance}
        callBackFun={this.preOrderPageCallBack}
      />
    )
    return (
      <div style={{minHeight:'800px'}}>
        {/* 页面内容 */}
        {pageContent}
        {/* 移除购物车记录提示弹窗 */}
        {RemoveTipsModal}

        {toastMarkup}

        {/* 图片预览弹窗 */}
        {previewImagesFiles}

      </div>
    );
  }
}

export default withRouter(ShoppingCartClass);
