import { BlockStack, Box, Button, Card, InlineStack } from '@shopify/polaris';
import React, { useState }  from 'react';
import "./index.css";
import ImportAliModal from './ImportAliModal';
import ImportTemuModal from './ImportTemuModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from "../../../../utils/utils";
import EnoughQuotaModal from "../SourcingRequest/component/EnoughQuotaModal";
import EnoughPointsModal from "../SourcingRequest/component/EnoughPointsModal";
import { useSelector } from 'react-redux';
const Index  = ({
    planData,
  userPoints,
  setPremiumData,
  handleMageUpgrade,
  subList,
})=> {
    const [aliModal,setAliModal] = useState(false)
    const [temuModal,setTemuModal] = useState(false);
    const router = useHistory();
     // 次数不足
      const [sourcingLimitModal,setSourcingLimitModal] = useState(false)
      // 达到上限
      const [enoughPoint,setEnoughPoint] = useState(false);
      const handleSubmit = (val) => {
        if (val) {
          if (val === 5) {
            setPremiumData((prevValue) => ({
                ...prevValue,
                open: true,
              }));
            setSourcingLimitModal(false)
        }
        if (val === 6) {
          handleProductImport()
          setSourcingLimitModal(false)
        }
            if (val <5) {
              handleMageUpgrade(val)
            }
        }
      };
      const plan_level = useSelector(
          (state) => state.languageReducer.subScriptData
        );
      // 产品导入 使用积分
      const handleProductImport = async () => {
        utils.routeListen("/admin/product_management?selectedView=0&import=1&isPoints=1")
        utils.routeJump1(router,"/admin/product_management?selectedView=0&import=1&isPoints=1")
      };

    return (
        <Box className="import_product_content">
            <BlockStack gap={400}>
            <Box>
                <Card padding={0}>
                    <InlineStack  align='space-between' blockAlign='center'>
                    <Box className="import_product_content_box">
                        <Box className="import_product_content_box_title">Seamless Import from AliExpress</Box>
                        <Box className="import_product_content_box_desc">One-click to capture comprehensive AliExpress product data via URL or Chrome extension.</Box>
                        <Box className="import_product_content_box_button">
                        <InlineStack wrap={false} gap={200} blockAlign='center' align='flex-start'>
                        <Button variant='primary' onClick={()=>setAliModal(true)} >Import from AliExpress</Button>
                        <Button onClick={()=>{
                           if (plan_level.level === 5 || plan_level.bill_level === 5) {
      
                            if (userPoints.userPointsData.available_points < 150 && (planData.aliexpressLimit >= planData.aliexpressDay)) {
                              setEnoughPoint(true);
                              return;
                            }
                          }
                          if (planData.aliexpressLimit >= planData.aliexpressDay) {
                            setSourcingLimitModal(true);
                            return;
                          }
                          utils.routeListen("/admin/product_management?selectedView=0&import=1&isPoints=0")
                          utils.routeJump1(router,"/admin/product_management?selectedView=0&import=1&isPoints=0")
                        }}>Add AliExpress Products</Button>
                        </InlineStack>
                        </Box>
                    </Box>
                    <Box className="import_product_content_box_image">
                        <img src="/new-product/ali_image.png" alt="Dropshipman" />
                    </Box>
                    </InlineStack>
                </Card>
            </Box>
            <Box>
                <Card padding={0}>
                <InlineStack align='space-between' blockAlign='center'>
                <Box  className="import_product_content_box">
                    <Box  className="import_product_content_box_title">One-click Import Products from Temu</Box>
                        <Box  className="import_product_content_box_desc">One-click to capture comprehensive Temu product data via Chrome extension.</Box>
                        <Box className="import_product_content_box_button">
                        <Button onClick={()=>setTemuModal(true)}>Import from Temu</Button>
                        </Box>
                    </Box>
                    <Box className="import_product_content_box_image">
                        <img src="/new-product/temu_image.png" alt="Dropshipman" />
                    </Box>
                    </InlineStack>
                </Card>
            </Box>
            </BlockStack>
            <ImportAliModal open={aliModal} setOpen={setAliModal}/>
            <ImportTemuModal open={temuModal} setOpen={setTemuModal}/>

                {/* 次数不够 */}
            <EnoughQuotaModal type={'aliexpress'} handleSubmit={handleSubmit}  sourcingLimitModal={sourcingLimitModal} userPoints={userPoints} subList={subList} setSourcingLimitModal={setSourcingLimitModal}/>
            {/* 会员上限且积分不够 */}
            <EnoughPointsModal type={'aliexpress'} open={enoughPoint} setOpen={setEnoughPoint}/>
        </Box>
    );
}

export default Index;
