import React, { memo } from "react";
import { Modal } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";

const RefundTipsModal = memo(
  ({
    orderData,
    orderPaymentData,
    setOrderPaymentData,
    handlePaymentMethodSelect,
  }) => {
    return (
      <div>
        <Modal
          open={orderPaymentData.orderRefundModal}
          onClose={() =>
            setOrderPaymentData((prevValue) => ({
              ...prevValue,
              orderRefundModal: false,
            }))
          }
          title={intl.get("Payment confirmation")}
          primaryAction={{
            content: intl.get("Continue to pay"),
            onAction: () =>
              handlePaymentMethodSelect(
                orderData.recordKey,
                orderData.id,
                orderData.name,
                orderData.type,
                orderData.totalPrice,
                orderData.shippingCost
              ),
          }}
          secondaryActions={{
            content: "Cancel",
            onAction: () =>
              setOrderPaymentData((prevValue) => ({
                ...prevValue,
                orderRefundModal: false,
              })),
          }}
        >
          <Modal.Section>
            {orderPaymentData.fullRefund ? (
              <p>
               {intl.get("This order has been refunded by the customer at Shopify, please confirm whether you want to continue to pay.")}
              </p>
            ) : (
              <p>
                {intl.get("Some items of this order have been refunded by the customer at Shopify, please confirm whether you want to continue to pay.")}
              </p>
            )}
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default RefundTipsModal;
