import React, { Component } from 'react';

import {AppProvider , Layout , Page , Frame} from '@shopify/polaris';

import './index.css';
import translations from '@shopify/polaris/locales/en.json';

class PrivacyPolicy extends Component {

  state = {};

  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan';
  }

  render() {

    return (
      <div className="home_loading_page">
      <AppProvider i18n={translations}>
        <Frame >
          <Page
            // breadcrumbs={[{content: 'Dashboard', url: '/admin/dashboard'}]}
            // title={intl.get("Dropshipman")}
          >
            <Layout>
              {/* <div style={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
              <Spinner accessibilityLabel="Spinner example" size="large" />
              </div> */}
              {/* <div id="loading-state" role="alert" aria-live="assertive" class="hideLs">
      <div class="message"><img src="/dropshipman-logo.png" alt="EcomSend Splash screen" width="120" height="120" />
        <p>Welcome to Dropshipman, please wait patiently for the loading to complete</p>
        <div class="content"><div class="spinner_page"><div></div></div></div></div></div> */}
       <div id="loading-state" role="alert" aria-live="assertive">
      <div className="message">
        <img src="/new-product/loadingDsmText.svg" alt="EcomSend Splash screen" width="120" height="120" />
        <p>
        Welcome to Dropshipman, please wait patiently for the loading to complete
        </p>
        <div className="content">
          <div className="spinner_page"><div></div></div>
        </div>
      </div>
    </div>
              {/* <Layout.Section>
                <div style={{paddingTop:"60px", margin:'0 auto',textAlign:'center'}}>
                <div>
                  <img width={120} height={120} src='/dropshipman-logo.png' alt='dropshipman' />
                </div>
               <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <img src='/home_loading.gif' alt='dropshipman' />
               </div>
               <p style={{fontSize:"20px"}}>Welcome to Dropshipman, Loading...</p>
               </div>
              </Layout.Section> */}
            </Layout>
          </Page>
        </Frame>
      </AppProvider>
      </div>
    );
  }
}

export default PrivacyPolicy;
