import PageDetail from "./component/PageDetail"
  const Index =()=> {
   
    return (
      <div>
      <PageDetail />
      </div>
    );
  }

  export default Index;