import {
    Box,
  } from '@shopify/polaris';
  import React from 'react';
  import './index.css'
  const  Index = ({data,listData})=> {
   
    return (
      <div>
         <Box className="variants_contents">
         <Box className="variants_content">
        <Box className="inventer_variant_head">
            <Box className="inventer_variant_sku">SKU</Box>
            {data.product.option_one_name !== '' && <Box className="inventer_variant_attr">{data.product.option_one_name}</Box>}
            {data.product.option_two_name !== '' && <Box className="inventer_variant_attr">{data.product.option_two_name}</Box>}
            {data.product.option_three_name !== '' && <Box className="inventer_variant_attr">{data.product.option_three_name}</Box>}
            <Box className="inventer_variant_cost">Cost</Box>
            <Box className="inventer_variant_price">Selling Price</Box>
            {/* <Box className="inventer_variant_inventory">Inventory</Box> */}
        </Box>
        <Box className="inventer_variant_body">
            {data.variants.map((item,ind)=><Box key={ind} className='inventer_variant_body_list'>
            <Box className="inventer_variant_sku inventer_variant_skus">
                <Box className="inventer_variant_sku_img"><img src={item.image_url} alt="Dropshipman" /></Box>
                <Box className="inventer_variant_sku_sku">{item.sku}</Box>
            </Box>
            {data.product.option_one_name !== '' && <Box className="inventer_variant_attr">{item.option_one}</Box>}
            {data.product.option_two_name !== '' && <Box className="inventer_variant_attr">{item.option_two}</Box>}
            {data.product.option_three_name !== '' && <Box className="inventer_variant_attr">{item.option_three}</Box>}
            <Box  className="inventer_variant_cost">${item.before_convert_cost}</Box>
            <Box  className="inventer_variant_price">${item.price}</Box>
            {/* <Box  className="inventer_variant_inventory">{item.inventory}</Box> */}
            </Box>)}
        </Box>
        </Box>
        </Box>
      </div>
    );
  }
  export default Index;