import {Button, ChoiceList, Modal, TextField, Toast} from "@shopify/polaris";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
import intl from "../../../../i18n/intl";
import { reqGetBlackExtraLog } from "../../../../api";
import CommonPremiumModal from "../../../../components/CommonPremiumModal";
const Index = ({upgradeOpen,setUpgradeOpen,handleMageUpgrade,handleBlackFeedbackModal}) => {
    // const router  = useHistory()
    const [loading,setLoading] = useState(false)
    const [selected, setSelected] = useState(['']);
    const handleChange = useCallback((value) => setSelected(value), []);
    const [textareaValue,setTextareaValue] = useState('')
    const [textErr,setTextErr] = useState(false)
    const [textareaValueErr,setTextareaValueErr] = useState('feed_other')
    const onChangeValue = (val)=>{
      setTextareaValue(val)
      setTextErr(false)
      if (val === '') {
        setTextareaValueErr('feed_other feed_other_err')
      }else{
        setTextareaValueErr('feed_other')
      }
    }
    
    const handleSubmit = async()=>{
      let text = selected[0];
      
      
      if (text === '') {
        toggleToast('Select a reason',false)
        return
      }

      if (text === 5 && textareaValue === '') {
        // toggleToast('Please provide your feedback',true)
        setTextErr(true)
        setTextareaValueErr('feed_other feed_other_err')
        return
      }
      let params = {
        type:text
      };
      if (text === 5 && textareaValue !== '') {
        params.remark = textareaValue
      }
      
      let res = await reqGetBlackExtraLog(params)
      if (res.code === '200') {
        if (text === 1) {
            handleBlackFeedbackModal(2)
          }
          if (text === 2) {
            handleBlackFeedbackModal(3)
          }
          if (text === 3) {
            handleBlackFeedbackModal(4)
          }
          if (text === 4) {
            handleBlackFeedbackModal(5)
          }
          if (text === 5) {
            handleBlackFeedbackModal(6)
          }
      }else {
        toggleToast('Submitted failed')
      }
    }
    const handleOff = ()=>{
      setLoading(false)
      setTextareaValue('')
      setSelected([''])
      setTextErr(false)
      handleBlackFeedbackModal(7)
      setTextareaValueErr('feed_other')
    }
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;
  return (
    <div className="upgrade_new_feature upgrade_new_feature_black">
        <CommonPremiumModal
        open={upgradeOpen}
        button={false}
        width={600}
        cancel={""}
        save={""}
        classN="agent_modal"
        title=""
        content={
            <Modal.Section>
            <div className="feed_option_modal">
              <div className="feed_option_emajo">
              <img src="/sub/feed_emajo.png" alt="dropshipman"/>
              </div>
              <div className="feed_option_dsc">
             {intl.get("We’re sorry our super discount didn’t impress you. Could you tell us why? We’re committed to improving for your satisfaction.")}
              </div>
              <div className="feed_option_select">
                <div className="feed_option_option">
                <ChoiceList
                  choices={[
                    {label: intl.get('The price is still too expensive for me.'), value: 1},
                    {label: intl.get(`I'm not satisfied with your features or services.`), value: 2},
                    {label: intl.get(`I'm not yet familiar with your platform.`), value: 3},
                    {label: intl.get(`This plan doesn’t meet my needs.`), value: 4},
                    {label: intl.get('Other'), value:5},
                  ]}
                  selected={selected}
                  onChange={handleChange}
                />
                {selected.indexOf(5) !== -1 && <div className={textareaValueErr}>
                <TextField
                label={intl.get("We'd greatly appreciate your feedback here.")}
                value={textareaValue}
                error={textErr && intl.get('Please provide your feedback')}
                onChange={onChangeValue}
                multiline={4}
                maxLength={300}
                autoComplete="off"
              />
                  {/* <textarea name="feed" value={textareaValue} onChange={(e)=>onChangeValue(e.target.value)} cols="30" maxLength={300} rows="5" placeholder="Please enter your feedback here, we would greatly appreciate it."></textarea> */}
                  <span className="feed_num">{textareaValue.length}/300</span>
                </div>}
                </div>
              </div>
              <div className="feed_option_button">
              <Button onClick={()=>handleOff()}>{intl.get("Back")}</Button>
              <Button loading={loading} disabled={selected[0] === ''} onClick={()=>handleSubmit()}  variant="primary">{intl.get("Submit")}</Button>
              </div>
            </div>
            </Modal.Section>
        }
      />
      
      {toastMarkup}
    </div>
  );
};

export default Index;
