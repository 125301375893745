import { Modal } from "@shopify/polaris";
import React, { useState } from "react";
import { reqPostProductManagementProductsDelete } from "../../../../api";
const RemoveProductModal = ({ removeProduct, setRemoveProduct,handleSelectionChange,getPageData,getTabData,import_status,detail,toastFun,callBack }) => {
  const handleClose = () => {
    setRemoveProduct((prev) => ({
      ...prev,
      modal: false,
      action: false,
    }));
    setLoading(false)
  };
   
    const [loading,setLoading] = useState(false);
    const handleProductDelete = async()=>{
      setLoading(true)
      let params = {
        ids:removeProduct.id,
        import_status:removeProduct?.import_status,
        type:removeProduct?.type ? 1 : 0
      }
      try {
      let res = await reqPostProductManagementProductsDelete(params);
      if(res.code === '200'){
        setLoading(false)
        handleSelectionChange('page',false,0)
        toastFun('Removed Successfully')
        if (detail) {
          callBack()
        }else {
          getPageData(import_status)
          getTabData(import_status)
          setRemoveProduct((prev)=>({
            ...prev,
            open:false,
            modal: false,
            action: false,
          }))
        }
        
      }
    } catch (error) {
        
    }
    }
  return (
    <div>
      <Modal
        // size="small"
        open={removeProduct.modal}
        onClose={handleClose}
        title="Confirm to Remove Product"
        secondaryActions={{
          onAction: () => {
            handleClose();
          },
          content: "Cancel",
        }}
        primaryAction={{
          destructive: true,
          loading: loading,
          onAction: () => {handleProductDelete()},
          content: "Remove",
        }}
      >
        <Modal.Section>
          <p>
            The product cannot be restored once removed. Are you sure you want
            to continue?
          </p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default RemoveProductModal;
