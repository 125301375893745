import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Page, Card, Layout, SkeletonBodyText, BlockStack, Toast, Button, DataTable, Badge, Modal, TextField, FormLayout,
  ButtonGroup, EmptyState, Pagination, Spinner,
  //  Banner
  Text,
  Box,
  InlineStack,
  RadioButton,
} from "@shopify/polaris";

// import NavTabs from "../components/NavTabs";

import { getPayonnerAuthBalances, reqGetUserSettings, reqGoogleAnalytics, reqPostUpdateUserSettings, reqWalletRecharge, reqWithdrawal, reqWithdrawalCancel } from '../../../../api';

import intl from '../../../../i18n/intl'
// import utils from '../../../../utils/utils'

import './index.css'
import utils from '../../../../utils/utils';
import { isNumber } from "recharts/lib/util/DataUtils";
import PayoneerBalancesSelect from '../../../../components/PayoneerBalancesSelect';
import PayoneerBalancesConfirm from '../../../../components/PayoneerBalancesConfirm';
import PayoneerBalancesConnect from '../../../../components/PayoneerBalancesConnect';
import PayoneerBalancesAgain from '../../../../components/PayoneerBalancesAgain';

class WalletClass extends Component {

  state = {
    //弹窗
    showToast: false,  //弹窗显示
    showToastText: '',  //弹窗文字
    showToastIserror: false, //弹窗是否错误

    loadingPage: true,
    saveDataLoading: false,
    disabledSaveData: true,

    selected: 'en',
    isLanguageChange: false,

    //充值弹窗
    chargeActive: false,
    //充值金额
    rechargeAmount: "",
    //提现金额
    WithAmount: "",

    //提现地址
    AmountAddress: "",
    AmountAddressError: "",

    //备注
    Remarks: "",
    //金额提示
    rechargeAmountError: "",
    //金额提示
    WithAmountError: "",

    //提现取消提交id
    Confirm_Id: "",

    //选择自动扣费
    SetProductsPublished: false,
    //支付方式 (1:PayPal 2:stripe,3:payoneer)
    PaymentMethod: 3,
    //钱包金额
    walletMoney: 0.00,
    // 累计充值金额
    totalRecharge: 0.00,
    // 累计使用金额
    totalUseCount: 0.00,
    //点击充值按钮
    AuthButtonDisable: false,
    //点击提现按钮
    WithButtonDisable: false,

    chargeError: false,

    //点击提现取消
    WithCancelButtonDisable: false,

    WithSuccessButton: false,
    //分页
    page: 1,
    size: 10,
    //总数
    total: 0,

    //数据是否为空
    products: [],
    isEmpty: false,
    typeValue:'pn',//默认是payoneer 
    payoneerBalances:[],//payoneer余额
    payoneerSelectOpen: false,//payoneer选择弹窗
    payoneerAmount:'',//payoneer选择金额
    payoneerConfirm:'',//payoneer确认支付数据
    payoneerConfirmOpen: false,//payoneer确认支付弹窗
    payoneerConnectOpen:false,//payoneer连接弹窗
    payoneer_auth:false,//payoneer授权状态
    payoneerAgainOpen:false,//payoneer支付后刷新数据弹窗
  };



  //进入页面调用接口
  componentDidMount () {
    this.getUserSettingsData();
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  //获取数据
  getUserSettingsData = async () => {

    const params = {
      type: 'wallet',
      page: this.state.page,
      size: this.state.size,
    };
    const response = await reqGetUserSettings(params);

    if (response.code === '200') {

      var result = response.data.userSetting;
      if (result.payoneer_auth) {
        this.setState({
          payoneer_auth: true
        })
      }
      const maxPage = Math.ceil(result.count / this.state.size);
      var hasPrevious = ""
      var hasNext = ""
      //如果当前页面大于1 且 小于 总分页   上下都为开启
      if (this.state.page > 1 && this.state.page < maxPage) {
        hasPrevious = true
        hasNext = true
        //如果当前页面小于等于1 且 小于总分页 下关闭 上开启
      } else if (this.state.page <= 1 && this.state.page < maxPage) {
        hasPrevious = false
        hasNext = true
        //如果当前页面大于1 且 大于等于 总分页   下开启 上关闭
      } else if (this.state.page > 1 && this.state.page >= maxPage) {
        hasPrevious = true
        hasNext = false
        // 否则都关闭
      } else if (this.state.page <= 1 && this.state.page >= maxPage) {
        hasPrevious = false
        hasNext = false
      }
      this.setState({
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        maxPage: maxPage,

        //数据表数据
        table: result.lest,
        //总数
        total: result.count,
        //钱包
        walletMoney: result.wallet_money,
        // 累计充值金额
        totalRecharge: result.recharge_count,
        // 累计使用金额
        totalUseCount: result.use_count,

        ShopOwner: result.shop_owner,
        UserEmail: result.email,

        //是否自动扣费
        SetProductsPublished: (result.auto_pay === 1) ? true : false,

        loadingPage: false,
        disabledSaveData: false
      }, () => {
        //加载列表数据
        this.updateTableContent();
      });

    } else {
      this.toggleToast(response.msg);
    }

  }

  //更新列表数据
  updateTableContent () {
    const products = this.state.table.map((item, key) => {

      var DateRes = "";
      var DescriptionRes = "";
      var TypeRes = "";
      var StatusRes = "";
      var AmoutRes = "";
      var Operate = "";

      //时间
      DateRes = item.created_date
      //描述
      DescriptionRes = item.description_data
      //类型
      switch (item.order_type) {
        case 1:
          TypeRes = "Order"
          break;
        case 2:
          TypeRes = "Charge"
          break;
        case 3:
          TypeRes = "Refunded"
          break;
        case 4:
          TypeRes = "Charge refunded"
          break;
        case 5:
          TypeRes = "Withdraw"
          break;
        case 6:
          TypeRes = "Partial refund"
          break;
        default:
          break;
      }
      //状态
      switch (item.order_status) {
        case 1:
          StatusRes = <Badge tone="">{intl.get("Paid")}</Badge>
          break;
        case 2:
          StatusRes = <Badge tone="warning">{intl.get("Failed")}</Badge>
          break;
        case 3:
          StatusRes = <Badge tone="info">{intl.get("Processing")}</Badge>
          break;
        default:
          break;
      }

      if (item.order_type === 5) {
        switch (item.order_status) {
          case 1:
            StatusRes = <Badge tone="">{intl.get("Completed")}</Badge>
            break;
          case 2:
            StatusRes = <Badge tone="warning">{intl.get("Failed")}</Badge>
            break;
          case 4:
            StatusRes = <Badge tone="info">{intl.get("Reviewing")}</Badge>
            Operate = <Button onClick={() => this.handleConfirmActiveChange(item.id)}><span style={{ color: "gray" }}>{intl.get("cancel")}</span></Button>
            break;
          default:
            break;
        }
      }

      AmoutRes = item.positive_and_negative + " " + item.money_number + " USD"
      return [
        DateRes,
        DescriptionRes,
        TypeRes,
        StatusRes,
        AmoutRes,
        Operate
      ];
    });
    // 更新图片列表数据
    if (products.length > 0) {
      this.setState({
        products: products,
        isEmpty: false
      });
    } else {
      this.setState({
        products: products,
        isEmpty: true
      });
    }
  }

  //上下页请求
  hasPreviousRequest = (type, maxPage) => {
    var page = this.state.page
    switch (type) {
      case "Previous":
        page = page - 1
        break;
      case "Next":
        page = page + 1
        break;
      default:
        break;
    }
    var hasPrevious = ""
    var hasNext = ""
    //如果当前页面大于1 且 小于 总分页   上下都为开启
    if (page > 1 && page < maxPage) {
      hasPrevious = true
      hasNext = true
      //如果当前页面小于等于1 且 小于总分页 下关闭 上开启
    } else if (page <= 1 && page < maxPage) {
      hasPrevious = false
      hasNext = true
      //如果当前页面大于1 且 大于等于 总分页   下开启 上关闭
    } else if (page > 1 && page >= maxPage) {
      hasPrevious = true
      hasNext = false
      // 否则都关闭
    } else if (page <= 1 && page >= maxPage) {
      hasPrevious = false
      hasNext = false
    }
    this.setState({
      page: page,
      hasPrevious: hasPrevious,
      hasNext: hasNext,
    }, () => {
      this.getTabelContent();
    });
  }


  // 获取列表数据
  getTabelContent = async () => {
    this.setState({
      getTabelContentLoading: true,
    });

    const params = {
      type: 'wallet',
      page: this.state.page,
      size: this.state.size,
    };
    const response = await reqGetUserSettings(params);
    if (response.code === "200") {

      var result = response.data.userSetting;

      const maxPage = Math.ceil(result.count / this.state.size);
      var hasPrevious = ""
      var hasNext = ""
      //如果当前页面大于1 且 小于 总分页   上下都为开启
      if (this.state.page > 1 && this.state.page < maxPage) {
        hasPrevious = true
        hasNext = true
        //如果当前页面小于等于1 且 小于总分页 下关闭 上开启
      } else if (this.state.page <= 1 && this.state.page < maxPage) {
        hasPrevious = false
        hasNext = true
        //如果当前页面大于1 且 大于等于 总分页   下开启 上关闭
      } else if (this.state.page > 1 && this.state.page >= maxPage) {
        hasPrevious = true
        hasNext = false
        // 否则都关闭
      } else if (this.state.page <= 1 && this.state.page >= maxPage) {
        hasPrevious = false
        hasNext = false
      }

      this.setState({
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        maxPage: maxPage,

        //数据表数据
        table: result.lest,
        //总数
        total: result.count,
        // 请求完成
        getTabelContentLoading: false
      }, () => {
        this.updateTableContent();
      });
    } else {
      this.toggleToast(response.msg);
    }

  };


  // save setting
  saveData = async () => {
    this.setState({
      saveDataLoading: true
    });

    const params = {
      type: 'wallet',
      auto_pay: this.state.SetProductsPublished ? 1 : 0
    };
    const responseData = await reqPostUpdateUserSettings(params);

    this.setState({
      saveDataLoading: false
    });
    if (responseData.code === "200") {
      this.toggleToast(responseData.msg, false);
    } else {
      this.toggleToast(responseData.msg);
    }
  };
  // 发送谷歌分析数据
  handleTrackGoogleManager = (sign, data) => {
    try {
      window.gtag('event', sign, data);
    } catch (e) {
      console.log(e)
    }
  }
  // let seoExpertsWindow;//新窗口的window对象
   openWin = (url) => {
    var name;                           //网页名称，可为空;
    var iWidth = 500;                         //弹出窗口的宽度;
    var iHeight = 570;                        //弹出窗口的高度;
    //window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
    var iTop = (window.screen.height - 30 - iHeight) / 2;       //获得窗口的垂直位置;
    var iLeft = (window.screen.width - 10 - iWidth) / 2;        //获得窗口的水平位置;
    // const newTab = window.open('', '_blank',name, 'height=' + iHeight + ',innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no');
    // newTab.location = url;
    setTimeout(() => {
      window.open(url, name, 'height=' + iHeight + ',innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no');
    }, 0);
  }
  //点击充值
  handlechargeAuth = async () => {
    //判断金额小于10 就不进入了
    if (Number(this.state.rechargeAmount) < 10) {
      this.setState({
        rechargeAmountError: "Min 10USD"
      });
      return false;
    }
    this.setState({
      AuthButtonDisable: true
    });
    let text = 'PayPal';
    if (this.state.typeValue === 'pal') {
      text = 'PayPal'
    }
    if (this.state.typeValue === 'dsm') {
      text = 'Stripe'
    }
    if (this.state.typeValue === 'pn') {
      text = 'Payoneer'
    }
    // 谷歌分析函数
    this.handleTrackGoogleManager('Dropshipman pay charge', {
      'domain': utils.getStore('S_SHOP_DOMAIN') || window.S_SHOP_DOMAIN,
      'value': Number(this.state.rechargeAmount),
      'method': text
    });
    if (this.state.typeValue === 'pn' && this.state.payoneer_auth === false) {
     this.handlePayoneerOff()
      return
    }
    if (this.state.typeValue === 'pn' && this.state.payoneer_auth === true) {
      this.handlePayoneerBalances()
      return
    }

    //类型 1 PayPal 2 Stripe
    const params = {
      type: this.state.PaymentMethod,
      money: Number(this.state.rechargeAmount)
    };
    const responseData = await reqWalletRecharge(params);

    if (responseData.code === "200") {
      //类型 1 PayPal 2 Stripe
      
      if (this.state.PaymentMethod === 1) {
        // window.location.href = responseData.data.url;
        this.openWin(responseData.data.url)
      } else {
        // window.location.href = responseData.data.url;
        this.openWin(responseData.data.url)
      }
      this.callChargeActiveClosed();
      // this.toggleToast(responseData.msg, false);
    } else {

      this.setState({
        AuthButtonDisable: false
      });
      this.toggleToast(responseData.msg);
    }
  };

  // payoneer未授权
  handlePayoneerOff = ()=>{
    this.setState({
      payoneerConnectOpen: true,
      // payoneer_auth: false,
      AuthButtonDisable: false,
      rechargeAmount:'',
      chargeActive: false,
      WithdrawActive: false,
      WithButtonDisable: false,
      AmountAddress: '',
      Remarks: '',
    })
  }
  // checkbox change
  handleCheckboxChange = (value, id) => {
    this.setState({
      [id]: value

    }, () => {
      // 更新设置
      if (!this.state.saveDataLoading) {
        this.saveData();
      } else {
        setTimeout(() => {
          this.saveData();
        }, 800);
      }
    });
  }

  // input change
  handleInputChange = (field, value) => {
    let values = Number(value)

    if (field === 'WithAmount') {
      if (values >= 1 && values <= this.state.walletMoney) {
        this.setState({
          WithAmountError: ""
        });
      }

      if (values < 1) {
        this.setState({
          WithAmountError: "Min 1USD"
        });
      }

      if (values > this.state.walletMoney) {
        this.setState({
          WithAmountError: intl.get("The entered amount cannot exceed the wallet balance!")
        });
      }
    } else {
      this.setState({
        WithAmountError: ""
      });
    }

    if (isNumber(values) && values > 0) {
      this.setState({
        [field]: Math.floor(value * 100) / 100,
        payoneerAmount: Math.floor(value * 100) / 100
      });
    } else {
      this.setState({
        [field]: ''
      });
    }
  }

  handleInputAddressChange = (field, value) => {
    this.setState({
      AmountAddressError: "",
      [field]: value
    });
  }

  // 充值弹窗 关闭充值弹窗
  handleChargeActiveChange = () => {
    //Wallet页面Charge按钮点击gtag
    reqGoogleAnalytics('click', 'wallet_charge_click');

    // 谷歌分析函数
    this.handleTrackGoogleManager('Dropshipman charge', { 'domain': utils.getStore('S_SHOP_DOMAIN') || window.S_SHOP_DOMAIN });

    this.setState({
      chargeActive: true,
    });
  };

  // 关闭关闭弹窗
  callChargeActiveClosed = () => {
    this.setState({
      chargeActive: false,
      rechargeAmount: '',
      payoneerAmount:'',
      rechargeAmountError: "",
      typeValue:'pn',
      PaymentMethod: 3,
      AuthButtonDisable:false,
      WithButtonDisable: false
    });
  };


  callChargeErrorClosed = () => {
    this.setState({
      chargeError: false,
    });
  }

  callSuccessClosed = () => {
    this.setState({
      WithSuccessButton: false,
    });
  }

  //支付方式
  handleFirstButtonClick = (value) => {
    this.setState({
      PaymentMethod: value
    });
  }

  // 提现弹窗
  handleWithdrawActiveChange = (money) => {
    //Wallet页面Withdraw按钮点击gtag
    reqGoogleAnalytics('click', 'wallet_withdraw_click');

    // 谷歌分析函数
    this.handleTrackGoogleManager('Dropshipman Withdraw', { 'domain': utils.getStore('S_SHOP_DOMAIN') || window.S_SHOP_DOMAIN });

    if (this.state.walletMoney <= 1) {
      this.setState({
        chargeError: true,
      });
      return
    }
    this.setState({
      WithdrawActive: true,
    });
  };

  // 关闭关闭弹窗
  callWithdrawActiveClosed = () => {
    this.setState({
      WithdrawActive: false,
      WithAmount: '',
      WithAmountError: "",
      PaymentMethod: 3,
      typeValue:'pn',
      AmountAddress: '',
      Remarks: '',
      AmountAddressError: "",
      AuthButtonDisable:false,
      WithButtonDisable: false
    });
  };


  handleWithdrawAuth = async () => {
    if (Number(this.state.WithAmount) < 1) {
      this.setState({
        WithAmountError: "Min 1USD"
      });
      return false;
    }

    if (this.state.AmountAddress === "" || this.state.PaymentMethod === "") {
      this.setState({
        AmountAddressError: "is requested!"
      });
      return false;
    }

    let reg = /^[.a-zA-Z0-9_-]+@[.a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    // maxwell.landmesser.manzi01.@gmail.com
    if (!this.state.AmountAddress.match(reg)) {
      this.setState({
        AmountAddressError: intl.get("Please enter the associated email account.")
      });
      return false;
    }
    // if (this.state.typeValue === 'pn') {
    //   this.handlePayoneerBalances()
    //   return
    // }
    this.setState({
      WithButtonDisable: true
    });

    await reqWithdrawal({
      'amount': this.state.WithAmount,
      'account': this.state.AmountAddress,
      'pay_method': this.state.PaymentMethod,
      'content': this.state.Remarks
    }).then(results => {
      this.setState({
        WithButtonDisable: false
      });
      if (results.code === 399) {
        this.setState({
          WithAmountError: intl.get("The entered amount cannot exceed the wallet balance!")
        });
        return false;
      }

      if (results.code === 200) {
        this.setState({
          WithAmount: '',
          WithAmountError: "",
          PaymentMethod: 3,
          typeValue:'pn',
          AmountAddress: "",
          Remarks: "",
          WithdrawActive: false,
          WithSuccessButton: true,
        });

        // this.toggleToast("Success!as the review is passed,the withdraw will complete", false);
        this.getUserSettingsData()

      } else {
        this.toggleToast(results.msg);
        return false;
      }

    })

  }

  // 提现确认取消弹窗
  handleConfirmActiveChange = (id) => {
    this.setState({
      ConfirmActive: true,
    });

    this.setState({
      Confirm_Id: id,
    });

  };

  // 关闭确认取消弹窗
  callConfirmActiveClosed = () => {
    this.setState({
      ConfirmActive: false,
    });

    this.setState({
      Confirm_Id: ""
    });

  };

  //提现取消按钮 提交数据
  handleConfirm = async () => {

    if (!isNumber(this.state.Confirm_Id)) {
      this.toggleToast("Submit failed", false);
      return false;
    }

    this.setState({ WithCancelButtonDisable: true })

    await reqWithdrawalCancel({
      'confirm_id': this.state.Confirm_Id,
    }).then(results => {

      this.setState({ WithCancelButtonDisable: false });

      if (results.code === 200) {

        this.setState({ ConfirmActive: false });

        this.toggleToast("Successfully canceled");

        this.getUserSettingsData()
      } else {
        this.toggleToast(results.msg);
        return false;
      }

    })

  }

  handleRadioChange = (_, newValue) => {
    //单选按钮点击事件
    switch (newValue) {
      case "dsm":
        this.setState({
          typeValue: "dsm",
          PaymentMethod:2,
        });
        break;
      case "pal":
        this.setState({
          typeValue: "pal",
          PaymentMethod:1,
        });
        break;
      case "pn":
        this.setState({
          typeValue: "pn",
          PaymentMethod:3,
        });
        break;
      default:
        break;
    }
  };
  // 获取授权账户余额
  handlePayoneerBalances = async ()=>{
    let res = await getPayonnerAuthBalances({money:Number(this.state.rechargeAmount)})
    if (res.code === '200') {
      this.setState({
        chargeActive:false,
        WithdrawActive:false,
        rechargeAmount:'',
        WithAmount:'',
        payoneerBalances:res.data,
        payoneerSelectOpen:true
      })
    }else {
      this.toggleToast(res.msg)
    }
    this.setState({
      AuthButtonDisable: false,
      WithButtonDisable:false
    });
  }
  // 关闭选择支付账户
  handlePayoneerSelect = ()=>{
    this.setState({
      payoneerSelectOpen:false
    })
  }
  // 获取确认支付数据
  handlePayoneerConfirm = (data)=>{
    this.setState({
      payoneerConfirm:data,
      payoneerConfirmOpen:true
    })
  }
  // 关闭确认支付弹窗
  callPayoneerConfirmClosed = ()=>{
    this.setState({
      payoneerConfirmOpen:false
    })
  }
  callPayoneerConfirmClosed2 = ()=>{
    this.setState({
      payoneerAgainOpen:true
    })
  }
  // 关闭确认授权弹窗
  callPayoneerConnectClosed = ()=>{
    this.setState({
      payoneerConnectOpen:false
    })
  }

  // 刷新数据
  callPayoneerAgainClosed = ()=>{
    this.getUserSettingsData()
    this.setState({
      payoneerAgainOpen:false
    })
  }

  
  render () {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;

    const {
      typeValue
    } = this.state;


    const initSkeletonPage = <div className="">
     <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout></div>

    //表格数据
    var columnContentTypes = [
      'text',
      'text',
      'text',
      'text',
      'text',
      'text',
    ]
    var headings = [
      intl.get('Date'),
      intl.get('Description'),
      intl.get('Type'),
      intl.get('Status'),
      intl.get('Amount'),
      intl.get('Operate')
    ]
    var footerContentMsg = "";
    //超过数量
    if (this.state.total > 0) {
      const startNum = (this.state.page - 1) * this.state.size + 1;
      const endNum =
        this.state.page * this.state.size > this.state.total
          ? this.state.total
          : this.state.page * this.state.size;
      footerContentMsg =
        intl.get("Showing {{startNum}} - {{endNum}} of {{total}} results", startNum, endNum, this.state.total);
    }

    const tableContent = !this.state.loadingPage ? (
      //判断是否上下页面
      !this.state.getTabelContentLoading ? (
        //判断是否有数据 没数据显示没数据
        this.state.isEmpty ? (
          <div>
            <DataTable
              columnContentTypes={columnContentTypes}
              headings={headings}
              rows={[]}
              verticalAlign="middle"
            />
            <div className="space"></div>
            <div className="emptyWithImg">
              <EmptyState heading={intl.get("No data to show here")} image="/empty.svg"> </EmptyState>
            </div>
          </div>
        ) : (
          <div className='wallet_table_content'>
            <DataTable
              columnContentTypes={columnContentTypes}
              headings={headings}
              rows={this.state.products}
              verticalAlign="middle"
            />
            <div className="Polaris-DataTable__Footer">
              <Pagination
                label={footerContentMsg}
                hasPrevious={this.state.hasPrevious}
                onPrevious={
                  () => this.hasPreviousRequest("Previous", this.state.maxPage)
                }
                hasNext={this.state.hasNext}
                onNext={
                  () => this.hasPreviousRequest("Next", this.state.maxPage)
                }
              />
            </div>
          </div>
        )
      ) :
        <div>
          <DataTable
            columnContentTypes={columnContentTypes}
            headings={headings}
            rows={[]}
            verticalAlign="middle"
          />
          <div className="space"></div>
          <div className="emptyWithImg">
            <EmptyState><Spinner accessibilityLabel="Spinner example" size="large" /></EmptyState>
          </div>
          <div className="Polaris-DataTable__Footer">
            <Pagination
              label={footerContentMsg}
              hasPrevious={this.state.hasPrevious}
              onPrevious={
                () => this.hasPreviousRequest("Previous", this.state.maxPage)
              }
              hasNext={this.state.hasNext}
              onNext={
                () => this.hasPreviousRequest("Next", this.state.maxPage)
              }
            />
          </div>
        </div>
    ) : (
      ""
    );

    const pageContent = this.state.loadingPage ? initSkeletonPage : <div className=" SettingsPage__datatable">
      <section className="Panel_Content">
        {/* <Banner
          title="Pre-Pay and Go plan"
          status="info"
          // onDismiss={() => {}}
        >
          <div style={{padding: '5px 0',lineHeight:'1.8'}}>
            <p>
              If you wish to lower your product cost, enjoy the convenience of auto fulfillment, try out our Pre-pay and Go plan.
            </p>
            <div className="space"></div>

            <div style={{fontSize:'1em',fontWeight:'600',lineHeight:'2.4rem'}}>Who is this plan for?</div>
            <p>1. Merchants who wish to set up their supply chain before the store is launched. </p>
            <p>2. Merchants just started a new store that has lower than 30 orders monthly but wishes to automated fulfillment. </p>
            <p>3. Merchants who wish to scale fast but don't meet the MOQ of 30 yet.</p>

            <div className="space"></div>
            <div style={{fontSize:'1em',fontWeight:'600',lineHeight:'2.4rem'}}>Here is how it works?</div>

            <p>Once you charge 30 USD into your wallet, the sourcing feature will be activated. The more you charge the more sourcing tasks you can request daily. </p>

            <div className="space"></div>
            <div style={{fontSize:'1em',fontWeight:'600',lineHeight:'2.4rem'}}>The amount of money you pre-paid can be used for paying the cost of your fulfillment orders.</div>

            <p>{'-> '}Charge 30 USD = 5 sourcing task/day</p>
            <p>{'-> '}Charge 60 USD = 10 sourcing task/day </p>
            <p>{'-> '}......etc</p>

            <p style={{color:'#bd5b49',marginTop:'10px'}}>Note: This plan is for those who just started dropshipping and do not have 30 monthly orders.</p>
          </div>
        </Banner>

        <div style={{marginTop:'1.6rem'}}></div> */}
        <BlockStack gap={400}>
        <Card>
          <Text variant="headingMd" as="h2">{intl.get("Balance")}</Text>
          <InlineStack align='space-between'>
            <Box>
              <Text variant="heading2xl" as="p">${this.state.walletMoney}USD</Text>
            </Box>
            <Box className='wallet_top_button'>
              <Button variant='primary' onClick={() => this.handleChargeActiveChange()}>{intl.get("Top Up")}</Button>
                <Button onClick={() => this.handleWithdrawActiveChange()}>
                <span style={{ color: "gray" }}>{intl.get("Withdraw")}</span>
              </Button>
            </Box>
          </InlineStack>
          <section style={{ paddingTop: "1rem" }}>
            <div style={{ marginTop: '5px', color: 'rgba(128, 128, 128, 1)' }}>
              {intl.get("Total top-up amount")} : {'$' + this.state.totalRecharge + ' USD'}, {intl.get("used amount")}: {'$' + this.state.totalUseCount + ' USD'}
            </div>
          </section>
          {/* <section style={{ paddingTop: "1rem" }}>
            <Checkbox
              id="SetProductsPublished"
              label={intl.get("Automatically pay for new order which are sourced and fulfilled by dropshipman.")}
              checked={this.state.SetProductsPublished}
              onChange={this.handleCheckboxChange}
              disabled={this.state.saveDataLoading}
              helpText={intl.get("You can pay in adavance and we will purchase and fulfill the order for you.")}
            />
          </section> */}
        </Card>
        <Card>
           <Text variant="headingMd" as="h2">{intl.get("Transactions")}</Text>
          {tableContent}
        </Card>
        </BlockStack>
      </section>

    </div>


    const disconnectModal = (
      <Modal
        open={this.state.chargeActive}
        onClose={this.callChargeActiveClosed}
        title={intl.get("Top Up Dropshipman Credits")}
        primaryAction={{
          content: intl.get("Pay"),
          onAction: this.handlechargeAuth,
          loading: this.state.AuthButtonDisable
        }}
        footer={<InlineStack>
          <img style={{marginRight:8}} width={20} src="/sub/points_icon.png" alt="Dropshipman" /> 
          <span>You'll receive <strong> {Number(this.state.rechargeAmount).toFixed(0)} points </strong> once your recharge is complete. </span>
          </InlineStack>
        }
      >
        <Modal.Section>
           
            <FormLayout>
              <TextField
                label={intl.get("Amount") + '(USD)'}
                onChange={this.handleInputChange.bind(this, 'rechargeAmount')}
                value={String(this.state.rechargeAmount)}
                placeholder='10.00'
                type={'number'}
                prefix={'$'}
                error={this.state.rechargeAmountError}
              />
            </FormLayout>
            <div style={{ marginTop: '10px' }}>
              <div className="Polaris-Labelled__LabelWrapper"><div className="Polaris-Label"><label id="PolarisTextField1Label" htmlFor="PolarisTextField1" className="Polaris-Label__Text">{intl.get("Payment account")}</label></div></div>
              <ButtonGroup segmented>
              <div className='balance_recharge_wallet_button'>
              <div
                className={`${
                  typeValue === "pn"
                    ? "dsm_container_border dsm_container"
                    : "dsm_container"
                } `}
              >
                <RadioButton
                  label={
                    <div className="pay_radioLabel">
                      <div>
                        <img src="/payoneer/pay_icon.png" alt="dropshipman" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>{intl.get("Payoneer")}</div>
                    </div>
                  }
                  id="pn"
                  name="pn_pay"
                  checked={typeValue === "pn"}
                  onChange={this.handleRadioChange}
                />
              </div>
              <div
                className={`${
                  typeValue === "pal"
                    ? "dsm_container_border dsm_container"
                    : "dsm_container"
                } `}
              >
                <RadioButton
                  label={
                    <div className="dsm_radioLabel">
                      <div>
                        <img src="/pay_pal.png" alt="dropshipman" />
                      </div>
                      <div>
                        <div style={{ fontWeight: "bold" }}>
                        {intl.get("PayPal")}
                        </div>
                      </div>
                    </div>
                  }
                  id="pal"
                  name="pal_pay"
                  checked={typeValue === "pal"}
                  onChange={this.handleRadioChange}
                />
              </div>
              <div
                className={`${
                  typeValue === "dsm"
                    ? "dsm_container_border dsm_container"
                    : "dsm_container"
                } `}
              >
                <RadioButton
                  label={
                    <div className="dsm_radioLabel">
                      <div>
                        <img src="/dsm_pay.png" alt="dropshipman" />
                      </div>
                      <div>
                        <div style={{ fontWeight: "bold" }}>
                        {intl.get("Stripe")}
                        </div>
                      </div>
                    </div>
                  }
                  id="dsm"
                  name="dsm_pay"
                  checked={typeValue === "dsm"}
                  onChange={this.handleRadioChange}
                />
              </div>
              </div>
                {/* <Button pressed={this.state.PaymentMethod === 1 ? true : false} onClick={() => this.handleFirstButtonClick(1)}>
                  {intl.get("PayPal")}
                </Button>
                <Button pressed={this.state.PaymentMethod === 2 ? true : false} onClick={() => this.handleFirstButtonClick(2)}>
                  {intl.get("Stripe")}
                </Button> */}
              </ButtonGroup>
              
            </div>
           
        </Modal.Section>
      </Modal>
    )


    const WithdrawModal = (

      <div style={{ maxHeight: 'calc(100vh - 0px) !important' }}>

        <Modal
          open={this.state.WithdrawActive}
          onClose={this.callWithdrawActiveClosed}
          title={intl.get("Dropshipman: Withdraw from wallet")}
          primaryAction={{
            content: intl.get("Done"),
            onAction: this.handleWithdrawAuth,
            loading: this.state.WithButtonDisable
          }}
          secondaryActions={[
            {
              content: intl.get('Cancel'),
              onAction: this.callWithdrawActiveClosed,
            },
          ]}
          noScroll={true}
        >
          <Modal.Section >
             
              <FormLayout>
                <TextField
                  label={intl.get("Amount") + '(USD)'}
                  onChange={this.handleInputChange.bind(this, 'WithAmount')}
                  value={String(this.state.WithAmount)}
                  placeholder='1.00'
                  type={'number'}
                  prefix={'$'}
                  error={this.state.WithAmountError}
                  autoComplete="off"
                />
              </FormLayout>

              <div style={{padding:"10px 0"}}>
                <div className="Polaris-Labelled__LabelWrapper"><div className="Polaris-Label"><label id="PolarisTextField1Label" htmlFor="PolarisTextField1" className="Polaris-Label__Text">{intl.get("Return account")}</label></div></div>
                <ButtonGroup segmented>
                <div className='balance_recharge_wallet_button'>
              <div
                className={`${
                  typeValue === "pn"
                    ? "dsm_container_border dsm_container"
                    : "dsm_container"
                } `}
              >
                <RadioButton
                  label={
                    <div className="pay_radioLabel">
                      <div>
                        <img src="/payoneer/pay_icon.png" alt="dropshipman" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>{intl.get("Payoneer")}</div>
                    </div>
                  }
                  id="pn"
                  name="pn_pay"
                  checked={typeValue === "pn"}
                  onChange={this.handleRadioChange}
                />
              </div>
              <div
                className={`${
                  typeValue === "pal"
                    ? "dsm_container_border dsm_container"
                    : "dsm_container"
                } `}
              >
                <RadioButton
                  label={
                    <div className="dsm_radioLabel">
                      <div>
                        <img src="/pay_pal.png" alt="dropshipman" />
                      </div>
                      <div>
                        <div style={{ fontWeight: "bold" }}>
                        {intl.get("PayPal")}
                        </div>
                      </div>
                    </div>
                  }
                  id="pal"
                  name="pal_pay"
                  checked={typeValue === "pal"}
                  onChange={this.handleRadioChange}
                />
              </div>
              <div
                className={`${
                  typeValue === "dsm"
                    ? "dsm_container_border dsm_container"
                    : "dsm_container"
                } `}
              >
                <RadioButton
                  label={
                    <div className="dsm_radioLabel">
                      <div>
                        <img src="/dsm_pay.png" alt="dropshipman" />
                      </div>
                      <div>
                        <div style={{ fontWeight: "bold" }}>
                        {intl.get("Stripe")}
                        </div>
                      </div>
                    </div>
                  }
                  id="dsm"
                  name="dsm_pay"
                  checked={typeValue === "dsm"}
                  onChange={this.handleRadioChange}
                />
              </div>
              </div>
                  {/* <Button pressed={this.state.PaymentMethod === 1 ? true : false} onClick={() => this.handleFirstButtonClick(1)}>
                    {intl.get("PayPal")}
                  </Button>
                  <Button pressed={this.state.PaymentMethod === 2 ? true : false} onClick={() => this.handleFirstButtonClick(2)}>
                    {intl.get("Stripe")}
                  </Button> */}
                </ButtonGroup>
              </div>

              <FormLayout>
                <TextField
                  // label="Quantity"
                  onChange={this.handleInputAddressChange.bind(this, 'AmountAddress')}
                  value={String(this.state.AmountAddress)}
                  placeholder='*'
                  type={'text'}
                  error={this.state.AmountAddressError}
                  maxLength={100}
                />
                <div style={{ marginTop: "-10px", color: "gray" }}>{intl.get("Please enter the same account as the charge account.")}</div>

              </FormLayout>

              <FormLayout>
                <TextField
                  label={intl.get("Explanation")}
                  onChange={this.handleInputAddressChange.bind(this, 'Remarks')}
                  value={String(this.state.Remarks)}
                  type={'text'}
                  maxLength={200}
                  multiline={3}
                />
              </FormLayout>

             
          </Modal.Section>
        </Modal>
      </div>

    )
    const ConfirmModel = (
      <Modal
        open={this.state.ConfirmActive}
        onClose={this.callConfirmActiveClosed}
        title={intl.get("Cancel withdraw")}
        primaryAction={{
          content: intl.get('Yes'),
          onAction: this.handleConfirm,
          loading: this.state.WithCancelButtonDisable
        }}
        secondaryActions={[
          {
            content: intl.get('No'),
            onAction: this.callConfirmActiveClosed,
          },
        ]}
      >
        <Modal.Section>
            <span style={{ fontSize: "16px", color: "#202223" }}>{intl.get("Are you sure to cancel the withdraw?")}</span>
        </Modal.Section>
      </Modal>
    )


    const errorModel = (
      <Modal
        open={this.state.chargeError}
        onClose={this.callChargeErrorClosed}
        title={intl.get("Insufficient balance")}
        primaryAction={{
          content: intl.get("OK"),
          onAction: this.callChargeErrorClosed,
        }}
      >

        <Modal.Section>
          <span style={{ fontSize: "16px", color: "#202223" }}>{intl.get("Insufficient balance,withdraw is not supported!")}</span>
        </Modal.Section>

      </Modal>

    )


    const SubmitModel = (
      <Modal
        open={this.state.WithSuccessButton}
        onClose={this.callSuccessClosed}
        title={intl.get("Submit successfully")}
        primaryAction={{
          content: intl.get("OK"),
          onAction: this.callSuccessClosed,
        }}
      >

        <Modal.Section>
          <span style={{ fontSize: "16px", color: "#202223" }}>{intl.get("We will review uniformly on Tuesday, U.S.time. As the review is passed, the withdraw is completed.")}</span>
        </Modal.Section>

      </Modal>

    )

    return (

      <div className="">
        <Page
         backAction={
          this.state.loadingPage ? undefined : {
          content:'',
          onAction: () => {
            utils.routeListen('/admin/dashboard')
            utils.routeJump(this,'/admin/dashboard')
          }
        }}
          title={<div style={{fontSize:20}}>{intl.get("Dropshipman Credits")}</div>}
          // primaryAction={
          //   {
          //     content: intl.get("Save"),
          //     onAction: this.saveData,
          //     loading: saveDataLoading,
          //     disabled: disabledSaveData
          //   }
          // }
          fullWidth={false}
        >

          {/* <section className="SettingsPage__Box">
            <NavTabs selected="Wallet" />
          </section> */}

          {pageContent}

          {toastMarkup}
          {/* 充值弹窗 */}
          {disconnectModal}

          {/*提现弹窗*/}
          {WithdrawModal}

          {/*取消提现弹窗*/}
          {ConfirmModel}

          {/* 选择payoneer支付账户 */}
          <PayoneerBalancesSelect 
          open={this.state.payoneerSelectOpen} 
          num={this.state.payoneerAmount} 
          data={this.state.payoneerBalances} 
          handlePayoneerSelect={this.handlePayoneerSelect}
          handlePayoneerConfirm={this.handlePayoneerConfirm}
          />
          {/* 确认付款 */}
          <PayoneerBalancesConfirm 
          open={this.state.payoneerConfirmOpen} 
          data={this.state.payoneerConfirm}
          callPayoneerConfirmClosed={this.callPayoneerConfirmClosed}
          callPayoneerConfirmClosed2={this.callPayoneerConfirmClosed2}
          />
          {/* payoneer未授权 */}
          <PayoneerBalancesConnect 
          open={this.state.payoneerConnectOpen} 
          callPayoneerConnectClosed={this.callPayoneerConnectClosed}
          />
          <PayoneerBalancesAgain 
          open={this.state.payoneerAgainOpen} 
          callPayoneerAgainClosed={this.callPayoneerAgainClosed}
          />
          {errorModel}

          {SubmitModel}

          <div className="space"></div>

        </Page>
      </div>
    );

  }

}
export default withRouter(WalletClass);