import {
  Badge,
  InlineStack,
  Box,
  Button,
  EmptyState,
  BlockStack,
  Popover,
  Card,
  Icon,
} from "@shopify/polaris";
import { ChatIcon, MegaphoneFilledIcon ,QuestionCircleIcon} from "@shopify/polaris-icons";
import React, { useState } from "react";
import ReminderSuccessModal from "./ReminderSuccessModal";
import "./index.css";
import intl from "../../../../../i18n/intl";
import utils from "../../../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { store } from "../../../../../store";
import { reqGetSourcingExpedite } from "../../../../../api";
import DeleteSourcingModal from "./DeleteSourcingModal";
const MobileTableBox = ({
  listData,
  setListData,
  requestParams,
  getListData,
  toastFun,
  selectedType,
}) => {
  const router = useHistory();
  // 加急成功提醒弹窗
  const [reminderSuccessModal, setReminderSuccessModal] = useState({
    open: false,
  });
  const [deleteSourcingModal, setDeleteSourcingModal] = useState({
    // 移除source
    open: false,
    id: [],
    platform: "",
    loading: false,
    count: 0,
  });
  const handleSourcingDelete = (item) => {
    setDeleteSourcingModal((prevValue) => ({
      ...prevValue,
      open: true,
      id: [item.id],
      platform: requestParams.platform,
      count: [item.id].length,
    }));
  };

  const handleReminder = async (item) => {
    let getData = store.getState().languageReducer.subScriptData;
    let params = {
      id: item.id,
      platform: requestParams.platform,
    };

    let res = await reqGetSourcingExpedite(params);
    if (res.code === "200") {
      if (getData.bfs_pop.expedite_sourcing_pop) {
        setReminderSuccessModal((prevValue) => ({
          ...prevValue,
          open: true,
        }));
      } else {
        toastFun("Expedited successfully");
      }
      let list = listData.list;
      list.map((it, i) => {
        if (it.id === item.id) {
          it.expedite_sign = 2;
          return it;
        } else {
          return it;
        }
      });

      setListData((prevValue) => ({
        ...prevValue,
        list,
      }));
    } else {
      toastFun("Network error");
    }
  };
  const handleGoList = (type, item) => {
    if (type === "contact") {
      let sendPreMsg =
        "Hi, I've got a question about sourcing request " + item?.show_id;
      if (item?.show_id === "") {
        sendPreMsg =
          "Hello, I would like to get more sourcing times. Can you help me?";
      }
      try {
        // 填充信息并激活对话弹窗
        window.Willdesk.show();
        window.Willdesk.chat(sendPreMsg);
      } catch (error) {
        console.info(error);
      }
    }
    if (type === "") {
      let importStatus = 1;
      if (item.publish_status === 0) {
        importStatus = 1;
      }
      if (item.publish_status === 1) {
        importStatus = 2;
      }
      if (item.publish_status === 3) {
        importStatus = 3;
      }
      utils.routeListen("/admin/product_management?selectedView=0");
      utils.routeJump1(
        router,
        "/admin/product_management?selectedView=0&product_id=" +
          item.user_pro_id +
          "&import_status=" +
          importStatus
      );
    }
  };
  const showFailedReasonActive = (key)=>{
    let data = listData.list;
    let old = data[key].failed_active;
    data[key].failed_active = old ? 0 : 1;
    setListData((prevValue)=>({
      ...prevValue,
      list:data
    }))
  }
 // 状态重置
 const resetStatus = (item,ind) => {
  let text =  ""
  if (selectedType[0] === "My quote") {
    switch (item.status) {
      case 1:
        text =  <Badge tone="info">{"Pending"}</Badge>
        break;
      case 2:
        text =  <Badge tone="info">{"Pending"}</Badge>
        break;
      case 4:
        text =  <Badge tone="info">{"Pending"}</Badge>
        break;
      case 3:
        text =   <Popover
        active={(item.failed_active === 1) ? true : false}
        preferredAlignment="left"
        activator={
          <InlineStack>
          <Button
            variant='plain'
            disclosure={(item?.failed === 1) ? true : false}
            onClick={()=>{showFailedReasonActive(ind)}}
          >
            <Badge tone="critical">
              <InlineStack blockAlign="center">
              {intl.get("Failed")}
              {item.fail_reason !== '' && <span className='reset_source_status' onClick={()=>{showFailedReasonActive(ind)}}>
            <Icon
              source={QuestionCircleIcon}
              tone="base"
            />
          </span>}
              </InlineStack>
              
              </Badge>
          </Button>
         
        </InlineStack>
        }
        onClose={()=>{showFailedReasonActive(ind)}}
      >
        <Card>
          <p style={{ maxWidth: '200px',wordBreak: 'break-all' }}>
            {item.fail_reason}
          </p>
        </Card>
      </Popover>
      break;
      
      case 99:
        text =  (
          <Popover
            active={(item.failed_active === 1) ? true : false}
            preferredAlignment="left"
            activator={
              <div className="">
                <Button
                  variant='plain'
                  disclosure={(item.failed === 1) ? true : false}
                  onClick={()=>{}}
                >
                  <Badge tone="success">{intl.get("Success")}</Badge>
                </Button>
              </div>
            }
            onClose={()=>{}}
          >
            <Card>
              <p style={{ maxWidth: '200px' }}>
                {intl.get('Please check the product in List Products.')}
              </p>
            </Card>
          </Popover>
        );
        break;
      default:
        break;
    }
  }else {
    switch (item.status) {
      case 0:
        text =  <Badge tone="info">{"Pending"}</Badge>
        break;
      case 3:
        text =  <Badge tone="info">{"Pending"}</Badge>
        break;
      case 4:
        text =  <Badge tone="info">{"Pending"}</Badge>
        break;
      case 2:
        text =   <Popover
        active={(item.failed_active === 1) ? true : false}
        preferredAlignment="left"
        activator={
          <InlineStack>
          <Button
            variant='plain'
            disclosure={(item?.failed === 1) ? true : false}
            onClick={()=>{showFailedReasonActive(ind)}}
          >
            <Badge tone="critical"><InlineStack blockAlign="center">
              {intl.get("Failed")}
              {item.fail_reason !== '' && <span className='reset_source_status' onClick={()=>{showFailedReasonActive(ind)}}>
            <Icon
              source={QuestionCircleIcon}
              tone="base"
            />
          </span>}
              </InlineStack></Badge>
          </Button>
         
        </InlineStack>
        }
        onClose={()=>{showFailedReasonActive(ind)}}
      >
        <Card>
          <p style={{ maxWidth: '200px',wordBreak: 'break-all' }}>
            {item.fail_reason}
          </p>
        </Card>
      </Popover>
      break;
      
      case 1:
        text =  (
          <Popover
            active={(item.failed_active === 1) ? true : false}
            preferredAlignment="left"
            activator={
              <div className="">
                <Button
                  variant='plain'
                  disclosure={(item.failed === 1) ? true : false}
                  onClick={()=>{}}
                >
                  <Badge tone="success">{intl.get("Success")}</Badge>
                </Button>
              </div>
            }
            onClose={()=>{}}
          >
            <Card>
              <p style={{ maxWidth: '200px' }}>
                {intl.get('Please check the product in List Products.')}
              </p>
            </Card>
          </Popover>
        );
        break;
      default:
        break;
    }
  }
  
  return text;
};
  const getYMDStart = (timeStamp) => {
    const dt = new Date(timeStamp * 1000);

    // 月份名称
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Spt",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = months[dt.getMonth()];
    const day = dt.getDate();
    const year = dt.getFullYear();

    // 返回格式为 "Month Day, Year"
    return `${month} ${day}, ${year}`;
  };

 // 按钮状态重置
   const buttonGroup = (item) => {
     let btn = <Box>
       <InlineStack gap={100}>
       
       {requestParams.platform === 1 && <Box className="action_btn_delete">
       <Button variant='tertiary' onClick={()=>handleSourcingDelete(item)}>Delete</Button>
       </Box>}
       </InlineStack>
     </Box>
     if (selectedType[0] === "My quote") {
       if (Number(item.status) === 3) {
         btn = <Box>
         <InlineStack gap={100}>
         <Box className="action_btn_go">
         <Button variant='tertiary' icon={ChatIcon} onClick={()=>handleGoList('contact',item)}>Contact us</Button>
         </Box>
         {requestParams.platform === 1 && <Box className="action_btn_delete">
         <Button variant='tertiary' onClick={()=>handleSourcingDelete(item)}>Delete</Button>
         </Box>}
         </InlineStack>
       </Box>
       }
       if (Number(item.status)  === 99) {
         btn = <Box>
         <InlineStack gap={100}>
         {item.request_type === 2 && <Box className="action_btn_go action_btn_view">
         <Button onClick={()=>handleGoList('',item)}>View in List</Button>
         </Box>}
         {requestParams.platform === 1 && <Box className="action_btn_delete">
         <Button variant='tertiary' onClick={()=>handleSourcingDelete(item)}>Delete</Button>
         </Box>}
         </InlineStack>
       </Box>
       }
       if ((Number(item.status)  !== 99 || Number(item.status)  !== 3 ) && item.expedite_sign) {
         btn = <Box>
         <InlineStack gap={100}>
         <Box className="action_btn_go">
         <Button variant='tertiary' icon={MegaphoneFilledIcon} disabled={item.expedite_sign === 2} onClick={()=>{
           handleReminder(item)}}>
         {item.expedite_sign === 2 ? "Expedited" : "Expedite Sourcing"}  
         </Button>
         </Box>
         {requestParams.platform === 1 && <Box className="action_btn_delete">
         <Button variant='tertiary' onClick={()=>handleSourcingDelete(item)}>Delete</Button>
         </Box>}
         </InlineStack>
       </Box>
       }
     }else {
       if (Number(item.status)  === 2) {
         btn = <Box>
         <InlineStack gap={100}>
         <Box className="action_btn_go">
         <Button variant='tertiary' icon={ChatIcon} onClick={()=>handleGoList('contact',item)}>Contact us</Button>
         </Box>
         </InlineStack>
       </Box>
       }
       if (item.status === 1) {
         btn = <Box>
         <InlineStack gap={100}>
         </InlineStack>
       </Box>
       }
       if ((item.status !== 1 || item.status !== 2 ) && item.expedite_sign) {
         btn = <Box>
         <InlineStack gap={100}>
         <Box className="action_btn_go">
         <Button variant='tertiary' icon={MegaphoneFilledIcon} disabled={item.expedite_sign === 2} onClick={()=>{
           handleReminder(item)}}>
         {item.expedite_sign === 2 ? "Expedited" : "Expedite Sourcing"}  
         </Button>
         </Box>
         </InlineStack>
       </Box>
       }
     }
     
     
 
     return btn;
   };

  let content1 = listData?.list.map((item, ind) => (
    <Box key={ind} className="source_mobile_item_box">
      <InlineStack wrap={false} gap={200}>
        <Box className="source_mobile_item_left">
          <Box className="sourcing_table_title_img">
            <img
              src={
                item.all_images
                  ? item.all_images.split(",")[0]
                  : "/default_image.png"
              }
              alt={item.title}
            />
          </Box>
        </Box>
        <Box className="source_mobile_item_right">
          <BlockStack gap={100}>
            <Box className="source_mobile_item_right_title" title={item.title}>
              {item?.originalId &&
              item?.originalId !== "" &&
              item?.originalId !== 0 ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    utils.routeListen("/admin/product_selection/product-info");
                    utils.routeJump1(
                      router,
                      "/admin/product_selection/product-info",
                      {
                        product_name: "",
                        winning: 0,
                        category_id: "",
                        min_price: "",
                        max_price: "",
                        favorite: 0,
                        record_id: item?.originalId,
                        source: "list",
                      }
                    );
                  }}
                  className="ml-6 ml-al-6"
                >
                  {item.title}
                </span>
              ) : (
                <span title={item.title}>{item.title}</span>
              )}
            </Box>
            <Box className="source_mobile_item_right_type">
              <InlineStack gap={100}>
                <Box>ID:&nbsp;{item.show_id}</Box>
                <Box>{getYMDStart(item.created_at)}</Box>
              </InlineStack>
            </Box>
            <Box className="source_mobile_item_right_type">
            <InlineStack wrap={false} gap={100} blockAlign="center">
              <InlineStack  blockAlign="center">
                  <span style={{ color: "rgba(97, 97, 97, 1)" }}>Type:</span>
                  &nbsp;
                  <Badge>
                    {item.request_type === 1
                      ? intl.get("Exist")
                      : intl.get("Explore")}
                  </Badge>
               </InlineStack>
               <InlineStack blockAlign="center">
                  <span style={{ color: "rgba(97, 97, 97, 1)" }}>Status:</span>
                  &nbsp;{resetStatus(item,ind)}
                </InlineStack>
              </InlineStack>
            </Box>
            <Box className="source_mobile_item_right_button">
              {buttonGroup(item)}
            </Box>
          </BlockStack>
        </Box>
      </InlineStack>
    </Box>
  ));
  let content2 = listData?.list.map((item, ind) => (
    <Box key={ind} className="source_mobile_item_box">
      <InlineStack wrap={false} gap={200}>
        <Box className="source_mobile_item_left">
          <Box className="sourcing_table_title_img">
            <img
              src={item.image_url ? item.image_url : "/default_image.png"}
              alt={item.product_title}
            />
          </Box>
        </Box>
        <Box className="source_mobile_item_right">
          <BlockStack gap={100}>
            <Box
              className="source_mobile_item_right_title"
              title={item.product_title}
            >
              {item?.originalId &&
              item?.originalId !== "" &&
              item?.originalId !== 0 ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    utils.routeListen("/admin/product_selection/product-info");
                    utils.routeJump1(
                      router,
                      "/admin/product_selection/product-info",
                      {
                        product_name: "",
                        winning: 0,
                        category_id: "",
                        min_price: "",
                        max_price: "",
                        favorite: 0,
                        record_id: item?.originalId,
                        source: "list",
                      }
                    );
                  }}
                  className="ml-6 ml-al-6"
                >
                  {item.product_title}
                </span>
              ) : (
                <span product_title={item.product_title}>
                  {item.product_title}
                </span>
              )}
            </Box>
            <Box className="source_mobile_item_right_type">
              <InlineStack gap={100}>
                <Box>Order:&nbsp;{item.plat_order_name}</Box>
                <Box>ID:&nbsp;{item.show_id}</Box>
                <Box>{getYMDStart(item.created_at)}</Box>
              </InlineStack>
            </Box>
            <Box className="source_mobile_item_right_type">
              <InlineStack wrap={false} gap={100} blockAlign="center">
              <InlineStack blockAlign="center">
                  <span style={{ color: "rgba(97, 97, 97, 1)" }}>Type:</span>
                  &nbsp;
                  <Badge>
                    {item.request_type === 1
                      ? intl.get("Exist")
                      : intl.get("Explore")}
                  </Badge>
               </InlineStack>
               <InlineStack blockAlign="center">
                  <span style={{ color: "rgba(97, 97, 97, 1)" }}>Status:</span>
                  &nbsp;{resetStatus(item)}
                </InlineStack>
              </InlineStack>
            </Box>
            <Box className="source_mobile_item_right_button source_mobile_item_right_button_shopify">
              {buttonGroup(item)}
            </Box>
          </BlockStack>
        </Box>
      </InlineStack>
    </Box>
  ));
  const emptyStateMarkup = !listData.list.length ? (
    <EmptyState
      heading="There is no sourcing history at the moment"
      image="/new-product/source_table_empty.png"
    >
      <p style={{ color: "rgba(97, 97, 97, 1)" }}>
        Get any products you want through sourcing request
      </p>
    </EmptyState>
  ) : requestParams.platform === 1 ? (
    content1
  ) : (
    content2
  );
  return (
    <div>
      {emptyStateMarkup}
      <ReminderSuccessModal
        reminderSuccessModal={reminderSuccessModal}
        setReminderSuccessModal={setReminderSuccessModal}
      />
      
      {/* 移除source */}
      <DeleteSourcingModal
        deleteSourcingModal={deleteSourcingModal}
        getListData={getListData}
        toastFun={toastFun}
        setDeleteSourcingModal={setDeleteSourcingModal}
        handleSelectionChange={() => {}}
      />
    </div>
  );
};

export default MobileTableBox;
