import { Button, Modal } from "@shopify/polaris";
import React from "react";
import { reqGetBlackExtraActivity, reqPostBaseAction } from "../../../../api";
import { useEffect, useState } from "react";
var lastTime = 600;
const Index = ({
  upgradeOpen,
  handleBlackFeedbackModal
}) => {
    const closeModal = async (val)=>{
        const params = { type:'black_first_pop' };
       let res =  await reqPostBaseAction(params);
       if (res.code ==='200') {
           handleBlackFeedbackModal(val)
       }
    }
  // 弹窗点击跳转
  const handlePageStatistics = async () => {
    try {
      
    let res =   await reqGetBlackExtraActivity();
    if (res.code === '200') {
        closeModal(8)
        window.top.location.href = res.data.url;
    }
     
    } catch (error) {}
  };
  const [timers, setTimers] = useState([]); // 定时器数组

  const [timeData,setTimeData] = useState({
    hour: "00",
    min: "09",
    sec: "59",
  })
  const handleDis = (time) => {
    
    
    if (upgradeOpen) {
      const timer = setInterval(() => {
        setTimeData((prevValue)=>({
            ...prevValue,
            ...discountSubTime(time)
        }))
      }, 1000);
      let timerArr = timers;
      timerArr.push(timer);
      setTimers(timerArr);
    }
  };
  // 清空所有定时器
  const clearAllTimers = () => {
   
    timers.forEach((timer) => {
      clearInterval(timer);
    });
    setTimers([]);
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount && upgradeOpen) {
      handleDis(600);
    }
    return () => {
      isMount = true;
      clearAllTimers();
    };
    // eslint-disable-next-line
  }, [upgradeOpen]);
  const discountSubTime = () => {
    var day;
    var hour;
    var min;
    var sec;
     //剩余时间=未来时间-现在时间（未来时间距离现在的秒数）
    lastTime--;
    if (lastTime > 0) {
      day = parseInt(lastTime / 60 / 60 / 24); //换算成天数，一天有86400秒
      hour = parseInt(lastTime / 60 / 60); //余数代表剩下的小时
      min = parseInt((lastTime / 60) % 60); //代表分钟
      sec = parseInt(lastTime % 60); //代表秒数
      //给不足10的数前面添加一个0
      if (day < 10) {
        day = "0" + day;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      //  console.log(hour);
    } else {
        closeModal(8)
      clearAllTimers(); //清除时间
    }
    return {
      hour,
      min,
      sec,
    };
  };
 
  
  return (
    <div className="discount_upgrade_modal">
      <Modal open={upgradeOpen} onClose={() => {closeModal(1)}}>
          <div className="plan_grade_content" style={{ padding: "20px 25px",background:"#000" }}>
            <div className="plan_upgrade_head plan_upgrade_head_type">
              <span role="img" aria-label="emojis" style={{ fontSize: "36px" }}>
                <img height={80} src="/blackFriday/bfcm_2024_top_icon.png" alt="dropshipman" />
              </span>
            </div>
            <div className="black_discount_modal_desc">
            Congrats! You’re a BFCM winner with a <strong>80%</strong> off PRO plan!
            </div>
            <div className="black_discount_modal_price">
                <strong>$ 5.98</strong>
                <strong style={{fontWeight:400}}>/</strong>
                <span style={{fontSize:'30px'}}>mo</span>
                <span style={{fontSize:'18px',marginLeft:"10px", textDecoration:'line-through'}}>$29.9</span>
                <span className="black_discount_modal_price_inline">
                <span style={{fontSize:'12px'}}>THE FIRST MONTH</span>
                </span>
            </div>
            
           
              <div style={{ padding: "8px 0" }}>
                  <div className="sub_paid_time_detail">
                    <div className="sub_paid_time_detail_content">
                      <div className="sub_paid_time_detail_list_modal">
                        {/* <span className="sub_paid_time_detail_item_modal black_discount_modal_time">
                          {
                            timeData.hour
                          }
                        </span>
                        <span style={{ color: "#fff", fontSize: 18 }}>︰</span> */}
                        <span className="sub_paid_time_detail_item_modal black_discount_modal_time">
                          {
                            timeData.min
                          }
                        </span>
                        <span style={{ color: "#fff", fontSize: 18 }}>︰</span>
                        <span className="sub_paid_time_detail_item_modal black_discount_modal_time">
                          {
                            timeData.sec
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                
              </div>
            <div
              className="plan_upgrade_conetent_button black_discount_modal_conetent_button"
              style={{ margin: "24px 0 20px" }}
            >
              <Button onClick={() => handlePageStatistics()}>
               {" "}
                <span style={{ fontSize: "16px" }}>
                Claim My Offer
                </span>
                <span
                  role="img"
                  aria-label="emojis"
                  style={{ fontSize: "20px",marginLeft: "16px",lineHeight:1 }}
                >
                   <img style={{verticalAlign:'sub'}} height={24} src="/sub/top_emjo.png" alt="dropshipman" />
                </span>
              </Button>
            </div>
          </div>
      </Modal>
    </div>
  );
};

export default Index;
