import React,{Component} from 'react'
import FrameComponents from '../FrameComponents';
import './index.css'
class dashboard extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-affiliate-program';
  }
 
  render() {
   
    return (
      <div>
        <FrameComponents name="affiliate-program" /> 
      </div>
    );
    
  }
}

export default dashboard;

