import { Button, Modal } from '@shopify/polaris';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from "../../../../utils/utils"
const TopDrawerItem = ({open,handleOff})=>{
    const router = useHistory()
    return (
        <Modal
            size='middle'
            open={open}
            onClose={() => handleOff()}>
        <div className='black5_top_banner black5_top_banner_show' style={{minHeight:'400px'}}>
            <div className='black5_top_banner_item'>
           
            <div className='top_drawer_content_title'>
                <div className='top_drawer_content_logo'>
                <img className='box box_1' src="/blackFriday/bfcm_1.png" alt="dropshipman" />
                <img className='box box_2' src="/blackFriday/bfcm_2.png" alt="dropshipman" />
                </div>
                <div className='top_drawer_content_off'>
            <img src="/blackFriday/top_fixed_off.png" alt="dropshipman" />
            </div>
            </div>
            <div className='top_drawer_content_desc'>
                <p>
                Boost your Black Friday sales success! 
                </p>
                <p>Valid: Nov 1 - Dec 5</p>
            </div>
            <div className=''>
                <img width={226} height={226} src="/blackFriday/raffle_bg_top_round.png" alt="dropshipman" />
            </div>
            <div className='black5_top_banner_item_button black5_top_banner_item_button_show'>
                <Button onClick={()=>{
                    utils.routeListen('/admin/black-friday')
                    utils.routeJump1(router,'/admin/black-friday')
                }}>Claim Now</Button>
            </div>
            </div>
        </div>
        </Modal>
    );
}

export default TopDrawerItem;
