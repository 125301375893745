import { Modal, TextField } from "@shopify/polaris";
import React, { useState } from "react";

const Index = ({ 
  comparedPriceData,
  setComparedPriceData,
  selectedResources,
  updateData,
  detailData
 }) => {
  
    const [value,setValue] = useState('')
    const [error,setError] = useState(false);
    const handleClose = () => {
      setValue('')
      setError(false)
      setComparedPriceData({
          open:false,
          attr:''
        })
    };
    const handleChange = (value) => {
      if (value.length > 15) {
          return;
      }
      setError(false)
      const regex = /^$|^\d+(\.\d{0,2})?$/;

      // 仅允许符合正则的值更新状态
      if (!(regex.test(value) || value === "")) {
        return
      }
      setValue(value)
    };
    const handleSubmit = () => {
      if (value === '') {
        setError(true)
        return
      }
        updateData(selectedResources,{[comparedPriceData.attr]:value})
        handleClose()
    };
  return (
    <div>
      <Modal
        size="small"
        open={comparedPriceData.open}
        onClose={handleClose}
        title="Change Compared price"
        secondaryActions={{
          onAction: () => {
            handleClose();
          },
          content: "Cancel",
        }}
        primaryAction={{
          onAction: () => {handleSubmit()},
          content: "Change",
        }}
      >
        <Modal.Section>
        <TextField
        prefix={detailData.store.symbol}
        label="Compared price"
        value={value}
        error={error ? 'Please enter a value' : ''}
        onChange={handleChange}
        autoComplete="off"
        />
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
