import React, { Component } from 'react';

import { 
  Toast, Spinner, Pagination
} from "@shopify/polaris";

import StartRating from "stars-rating";
import Zmage from 'react-zmage';
import 'react-zmage/lib/zmage.css';
import BackgroundImagePosition from '../../../../../../components/BackgroundImagePosition';

// import { 
//   reqGetProductReviews
// } from '../../../../../../api';

import "./index.css";
import intl from '../../../../../../i18n/intl';

class ReviewsList extends Component {
  state = {
    show: this.props.show,
    reviewsData: this.props.reviewData.list,
    loadingPage: false,
    page: 1,
    size: 10,
    hasPrevious : false,
    hasNext : true,
    total: 0,
    ShowCountNumber: 0,
    isSearch: '0'
  }

  // 组件渲染完成，再次修改渲染内容
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show
    });
  }

  //进入页面调用接口
  componentDidMount() {
    // this.getPageData(false);
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 获取列表数据
  getPageData = (isSearch=false) =>{
    if(isSearch){
      this.setState({
        isSearching:'1',
        hasPrevious : false,
        hasNext : false,
      });
    }else{
      this.setState({
        loadingPage: true,
        hasPrevious : false,
        hasNext : false,
      });
    }
    
    this.props.getProductDetailReviews(this.state.page,this.state.size)
      let getData = this.props.reviewData;

      this.setState({
        loadingPage: false,
        reviewsData: getData.list,
        total: getData.count,
        ShowCountNumber: getData.count,
        isSearching: '0',
      });
      // 更新分页数据
      let setparams = {};
      const maxPage = Math.ceil(getData.count / this.state.size);
      setparams.hasPrevious = (this.state.page > 1) ? true : false;
      setparams.hasNext     = (this.state.page < maxPage) ? true : false;
      this.setState(setparams);
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const {
      show, loadingPage, reviewsData, isSearching, ShowCountNumber
    } = this.state;

    let reviewsLists = '';
    if (reviewsData.length > 0) {
      reviewsLists = reviewsData.map((item,key) => {
        let imageData = item.commentUrls ? item.commentUrls : [];
        let getName = item.commentUser ? item.commentUser.split(' ') : [];
        let buyUsername = (getName.length === 2) ? 'A***r' : item.commentUser;

        return (
          <div className='ReviewsItem' key={key}>
            <div className='left'>
              <div className='name'>{buyUsername}</div>
              <div className='country'>
                <BackgroundImagePosition country={item.countryCode} />
                <div className='code'> {item.countryCode}</div>
              </div>
            </div>

            <div className='right'>
              <div className='star'>
                <StartRating
                  value={item.score}
                  color1='gray'
                  color2='#ffd700'
                  half={true}
                  count={5}
                  edit={false}
                  size={25}
                />
              </div>
              <div className='feedback'>
                {item.feed_back_trans}
              </div>

              <div className='img_list'>
                { imageData.length > 0 && imageData.map((ik,kk) => {
                  return <Zmage src={ik} alt='Dropshipman' key={kk} />
                })}
              </div>
            </div>
          </div>
        )
      })
    }

    // 产品没有产品好评显示默认内容
    if (!loadingPage && (reviewsData.length === 0)) {
      reviewsLists = (
        <div className='ReviewsItem_Empty'>
         {intl.get("The product has no feedback yet.")} 
        </div>
      )
    }

    var footerContentMsg ='';
    if(this.state.total > 0) {
      const startNum = (((this.state.page - 1) * this.state.size) + 1);
      const endNum   = (this.state.page * this.state.size>this.state.total)?this.state.total:this.state.page * this.state.size;
      footerContentMsg = <div>{intl.get("Showing {{startNum}} - {{endNum}} of {{total}} results", startNum, endNum, this.state.total)}</div>;
    }

    const PaginationSettings = loadingPage ? '' :
      (ShowCountNumber !== 0) ?
        <div className="Polaris-New-Pagination">
          <Pagination
            label={footerContentMsg}
            hasPrevious = {this.state.hasPrevious}
            onPrevious={() => {
              this.setState(({page:this.state.page - 1}),()=>{
                  this.getPageData(true);
              });
            }}
            hasNext = {this.state.hasNext}
            onNext={() => {
              this.setState(({page:this.state.page + 1}),()=>{
                  this.getPageData(true);
              });
            }}
          />
        </div> : ''

    const pageContnent = (!loadingPage && show) ? reviewsLists : (
      <div className='spinner_center'>
        <Spinner accessibilityLabel="loading" size="large" />;
      </div>
    )

    return (
      <div>
        <div style={{position:'relative'}}>

          {/* 页面内容 */}
          {pageContnent}

          <div className="Product__Loading" style={{display: (isSearching==='1' && ShowCountNumber>0) ? 'block' : 'none'}}>
            <div className="Product__Loading_Image">
              <img style={{width:'32px',height:'32px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />
            </div>
          </div>
        </div>
       
        {/* 分页 */}
        {PaginationSettings}

        {toastMarkup}
      </div>
    );
    
  }
}

export default ReviewsList;
