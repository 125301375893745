import { Button, EmptyState, Modal, Pagination } from '@shopify/polaris';
import React, { useState ,useEffect,useMemo} from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from "../../../../utils/utils"
import intl from "../../../../i18n/intl";
import _ from "lodash";
import { reqGetBlackFridayRaffleLog, reqGetBlackFridayRafflePlan } from '../../../../api';
import ChangeLoading from '../../../../components/ChangeLoading';
const TopDrawerItem = ({open,handleOff})=>{
    const router = useHistory();
    // A:2美金,B:5美金,C:10美金,D:PRO,E:PLUS,F:鼠标,G:背包,H:无
    const [raffleData,setRaffleData] = useState([]);
    const [searchParam,setSearchParam] = useState({
        page: 1,
        size: 10,
    })
    const [pageData, setPageData] = useState({
        list: [],
      });
    const [initPageData, setInitPageData] = useState({
        isInit: true,
        pageLoading: false,
      });
    const initData = async(param)=>{
        let params = {...searchParam,...param}
        let res = await reqGetBlackFridayRaffleLog(params)
        if (res.code === '200') {
            setRaffleData(res.data.list)
            setPageData(res.data)
        }
        setTimeout(() => {
            setInitPageData((prevValue)=>({...prevValue,pageLoading: false,}))
        }, 500);
    }
    useEffect(() => {
        let isMount = false;
        if (!isMount && open) {
            initData(searchParam)
        }
        return () => {
            isMount = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
   
      // 总条数
  const pageMsg = () => {
    let total = pageData?.count ? pageData?.count : 0;
    const { page, size } = searchParam;
    const startNum = (page - 1) * size + 1;
    const endNum = page * size > total ? total : page * size;
    return intl.get(
      "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
      startNum,
      endNum,
      total,
      Math.ceil(total / 10)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (page > 1 && page <= maxPageNum) {
      return initPageData.pageLoading ? false : true;
    } else if (page === 1) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, initPageData]);

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (_.inRange(page, 1, maxPageNum)) {
      return initPageData.pageLoading ? false : true;
    } else if (page === maxPageNum) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, initPageData]);

  // 上一页
  const handlePrev = () => {
    setInitPageData((prevValue) => ({ ...prevValue, pageLoading: true }));
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) - 1;
      initData(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 下一页
  const handleNext = () => {
    setInitPageData((prevValue) => ({ ...prevValue, pageLoading: true }));
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) + 1;
      initData(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };
    const handlePlan = async(val)=>{
        let res = await reqGetBlackFridayRafflePlan({lottery_id:val})
        if (res.code ==='200') {
            window.top.location.href = res.data.url;
        }
        
    }
    const handleReedem = (item,type)=>{
        if (type === 1) {
            utils.routeListen('/admin/black_points_purchase?record_id='+item.id)
            utils.routeJump1(router,'/admin/black_points_purchase?record_id='+item.id)
        }else {
            utils.routeListen('/admin/redeem_products?selected=1&keyword='+item.remark_id)
            utils.routeJump1(router,'/admin/redeem_products?selected=1&keyword='+item.remark_id)
        }
        
    }
    const EmptyProductList = (
        <div className="emptyWithImg">
          <EmptyState
            heading={"Sorry, no results found."}
            image="/empty.svg"
          >
          </EmptyState>
        </div>
      );
    return (
        <Modal
            size='middle'
            title='Prize Records'
            open={open}
            onClose={() => handleOff()}
            >
            <div className='raffle_log_table'>
            <div className='raffle_log_head'>
                <div className='raffle_log_date'>Date</div>
                <div className='raffle_log_prize'>Prize</div>
                <div className='raffle_log_action'>Action</div>
            </div>
            {raffleData.length > 0 ? <div className='raffle_log_content'>
            {raffleData.map((item,ind)=><div key={ind} className='raffle_log_content_item'>
            <div className='raffle_log_date'>
               <span>{item.date}</span>
               </div>
            <div className='raffle_log_prize'>
                {(item.reward_key === 'A' || item.reward_key === 'B' || item.reward_key === 'C') &&<div className='raffle_log_coupon_img'>
                <img src="/blackFriday/black5_coupon.png" alt="Dropshiman" />
                <span>{item.reward_key === 'A' ? '$2' : item.reward_key === 'B' ? '$5' : item.reward_key === 'C' ? '$10' : ''}</span>
                </div>}
                {(item.reward_key === 'D' || item.reward_key === 'E') && <div className='raffle_log_plan_img'>
                    <img src="/blackFriday/black5_light_big.png" alt="Dropshiman" />
                </div>}
                {(item.reward_key === 'F' || item.reward_key === 'G') && <div className='raffle_log_mouse_img'>
                <img src={(item.reward_key=== 'F' ? "/blackFriday/black5_mouse.png" : "/blackFriday/black5_bag.png")} alt="Dropshiman" />
                </div>}
                <span style={{display:'inline-block',width:'115px'}}>{item.log}</span>
                </div>
            <div className='raffle_log_action'>
                {(item.reward_key === 'A' || item.reward_key === 'B' || item.reward_key === 'C') && 
                <div>
                   <Button onClick={()=>{
                     utils.routeListen('/admin/coupon')
                     utils.routeJump1(router,'/admin/coupon')
                   }}>View</Button>
                </div> 
                }
                {(item.reward_key === 'D' || item.reward_key === 'E') && 
                <div>
                   {item.is_used === 0 ? <Button onClick={()=>{handlePlan(item.id)}}>Activate</Button> : ''}
                </div> 
                }
                {(item.reward_key === 'F' || item.reward_key === 'G') && 
                <div>
                   {item.is_used === 0 ? <Button onClick={()=>{handleReedem(item,1)}}>Claim</Button> : <Button onClick={()=>{handleReedem(item,2)}}>View</Button>}
                </div> 
                }
            </div>
            </div>)}
            <div className="pageination_container pageination_container_black">
                      <Pagination
                        label={pageMsg()}
                        hasPrevious={hasPrevious}
                        hasNext={hasNext}
                        onPrevious={handlePrev}
                        onNext={handleNext}
                      />
                    </div>
            </div>:
            EmptyProductList}
            </div> 
           {initPageData.pageLoading && <ChangeLoading />}
        </Modal>
    );
}

export default TopDrawerItem;
