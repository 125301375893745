import React, { Component } from 'react';

import {
  Page,
  Card,
  Layout,
  SkeletonBodyText,
  Button,
  FormLayout,
  Toast,
  Badge,
  SkeletonPage,
  EmptyState,
  BlockStack,
  Text,
} from "@shopify/polaris";

import StartRating from "stars-rating";

import { 
  reqGetDropshipmanProductDetail
} from '../../../../../api';

import ShippingMethodsModal from '../../../../Admin/FindProducts/ProductDetail/components/ShippingMethodsModal';

import ImageGalleryShow from '../../../../../components/ImageGalleryShow';

import './index.css';

class DetailClass extends Component {

  state = {
    //页面动作
    loadingPage: false,

    // 数据
    pageData: '',
    options: [],
    search_product_id: 0,  // 要查询的产品详情的产品ID
    addLoading: false,
  };

  //进入页面调用接口
  componentDidMount() {
    if(this.props.id){
      this.setState({
        search_product_id: this.props.id,
      },()=>{
        this.getPageData();
      })

    } else {
      this.getPageData();
    }
  }

  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 获取列表数据
  getPageData = async() =>{
    this.setState({
      loadingPage: true,
    });
    const params = {
      id: this.state.search_product_id
    };
    const response = await reqGetDropshipmanProductDetail(params);
    if(response.code==='200'){
      this.setState({
        loadingPage: false,
        pageData: response.data,
        options: response.data.options,
      });

    }else{
      this.setState({
        loadingPage: false,
        pageData: '',
      });
      // this.toggleToast(response.msg,true);
    }
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    const {
      pageData,options
    } = this.state;

    const initSkeletonPage = (
      <div>
        <SkeletonPage breadcrumbs={true}>
        <Layout>
          <Layout.Section>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
        </Layout.Section>
          </Layout>
          <div className="space"></div>
        </SkeletonPage>
      </div>
    )

    const EmptyProductList = (
      <div>
        <EmptyState
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <p>Get no detail</p>
        </EmptyState>
      </div>
    );

    let optionsList = '';
    if (options.length > 0) {
      optionsList = options.map((item,key) => {
        let valueList = item.value.map((it,key1) =>{
          return (
            <Badge key={key1}>{it}</Badge>
          )
        });

        return (
          <div className="Product__Attributes" key={key}>
            <div className="Product__Attributes_Name">{item.name}</div>
            <div className="Product__Attributes_Content">
              {valueList}
            </div>
          </div>
        )
      });
    }

    let shipFromShowContent = '';
    if (pageData.ship_from) {
      shipFromShowContent = (
        <div className="Product__Attributes">
          <div className="Product__Attributes_Name">
            Ship from
          </div>
          <div className="Product__Attributes_Content">
            <Badge>{pageData.ship_from}</Badge>
          </div>
        </div>
      );
    }

    const appInstallUrl = 'https://apps.shopify.com/aliexpress-dropshipping-master?surface_detail=dropshipman&surface_inter_position=1&surface_intra_position=3&surface_type=search'

    const pageContent = this.state.loadingPage ? initSkeletonPage : (
      <section>
        <Page 
          breadcrumbs={[{content: 'Products', url: '/products'}]}
          title=''
        >
          <Layout>
            <Layout.Section>
              <div style={{"paddingTop":"1rem"}}></div>
              {(this.state.pageData === '') ? (
                <Card>
                  {EmptyProductList}
                </Card>
              ) : (
                <section>
                  <Card>
                       
                      <section className="Product_Detail_Content">

                        <div className="Product_Detail_Img">
                          {/* <img src={(pageData.image_url === '') ? '/default_image.png' : pageData.image_url} alt="" /> */}
                          <ImageGalleryShow images={pageData.image_list} showThumbnails={true} isshowFullscreenButton={true}/>
                        </div>

                        <div className="Product_Detail_Box detail">
                          <FormLayout>
                            <Text variant="headingMd" as="h2">{pageData.title}</Text>
                            <div>
                              <BlockStack>
                                <div>
                                  <StartRating
                                    value={pageData.rating}
                                    color1='gray'
                                    color2='#ffd700'
                                    half={true}
                                    count={5}
                                    edit={false}
                                    size={25}
                                  />
                                </div>
                                <div className="Product__Rating">
                                  {pageData.rating}
                                </div>
                                <div className="Product__Reviews">
                                  {pageData.evaluation_count} 
                                </div>
                                <div></div>
                                <div className="Product_Order_Count">
                                  <div style={{padding:'3px'}}>
                                    <svg viewBox="0 0 20 20">
                                      <path d="M11 1a1 1 0 1 0-2 0v7.586L7.707 7.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414L11 8.586V1z"></path>
                                      <path d="M3 14V3h4V1H2.5A1.5 1.5 0 0 0 1 2.5v15A1.5 1.5 0 0 0 2.5 19h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 17.5 1H13v2h4v11h-3.5c-.775 0-1.388.662-1.926 1.244l-.11.12A1.994 1.994 0 0 1 10 16a1.994 1.994 0 0 1-1.463-.637l-.111-.12C7.888 14.664 7.275 14 6.5 14H3z"></path>
                                    </svg> 
                                  </div>
                                  <div style={{padding:'2px 2px 2px 5px'}}>
                                    {pageData.order_count}
                                  </div>
                                </div>
                              </BlockStack>
                            </div>

                            <div className="Product__Attributes">
                              <div className="Product__Attributes_Name">
                                Cost
                              </div>
                              <div className="Product__Attributes_Content">
                                {'US ' + pageData.price + ' USD'}
                              </div>
                            </div>

                            {/* 属性列表 */}
                            {optionsList}

                            {/* ship from */}
                            {shipFromShowContent}

                            <div className="Product__Attributes">
                              <div className="Product__Attributes_Name">
                               Stock
                              </div>
                              <div className="Product__Attributes_Content">
                                {pageData.stock + ' in stock'}
                              </div>
                            </div>

                            <div className="Product__Attributes">
                              <div className="Product__Attributes_Name">
                                Shipping
                              </div>
                              <div className="Product__Attributes_Content">
                                <ShippingMethodsModal id={pageData.id} countryCode={pageData.country_code} show={false} public={true} />
                                {/* <div>$9.15 to Brazil via AliExpress Standard Shipping</div>
                                <div>Estimated delivery time 31-31 days</div> */}
                              </div>
                            </div>

                            <div>
                              <Button 
                                url={appInstallUrl}
                                variant='primary'
                                external
                              >
                                Import to Shopify
                              </Button>
                            </div>
                          </FormLayout>
                        </div>
                      </section>
                    
                       
                  </Card>

                  <Card>
                       
                      <div dangerouslySetInnerHTML={{__html:pageData.description}}>

                      </div>
                       
                  </Card>
                </section>
              )}
              
            </Layout.Section>
          </Layout>
        </Page>
      </section>
    )

    return (
      <div>
        {/* 页面内容 */}
        {pageContent}
        {toastMarkup}
      </div>
    );
  }
}

export default DetailClass;