import {
  Box,
  Collapsible,
  Icon,
  InlineStack,
  BlockStack,
  InlineGrid,
  Button,
  Card,
  Text,
  ProgressBar,
  Tooltip,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XIcon,
  PlayCircleIcon
} from "@shopify/polaris-icons";
import { store } from "../../../../store";
import NewStartNone from "../../SubscriptionPaid/components/icons/NewStartNone";
import NewStartBlack from "../../SubscriptionPaid/components/icons/NewStartBlack";
import PlanNewModal from "./PlanNewModal";
import AchievementNewPersonModal from "./AchievementNewPersonModal";
import NewPersonVideo from "./NewPersonVideo";
import intl from "../../../../i18n/intl";
import { reqPostCardDismiss, reqPostGuidePoints, reqPostNoviceRedeem } from "../../../../api";
import utils from "../../../../utils/utils";

const NewPersonBox = ({setNewPerson,data,toastFun,router,setPageData}) => {
  const list1 = [
    {
      title: "About Dropshipman",
      dsc: `Watch the video to learn about Dropshipman. Get <strong>100 points</strong> reward for completing the task.`,
      video:
        "https://www.youtube.com/embed/bArgTh6VFtU?rel=0&autoplay=1&loop=1",
      isAction: true,
      stepMask: "/buildShopify/new1.png",
      coupon: false,
      button: "",
      button2:'',
      vd: 0,
      task: 0,
    },
    {
      title: "Find products from Dropshipman",
      dsc: "Earn <strong>100 points</strong> for watching a video on how to pick from Dropshipman, and another <strong>150 points</strong> for successfully picking from Dropshipman and importing an item into your store.",
      video:
        "https://www.youtube.com/embed/nSWr8lEwlVI?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/buildShopify/new2.png",
      coupon: false,
      button: "Find Product",
      button2:'',
      vd: 0,
      task: 0,
    },
    {
      title: "How to request a sourcing",
      dsc: "Earn <strong>100 points</strong> for watching a video on how to post a sourcing request at Dropshipman, and another <strong>150 points</strong> for successfully posting a souring request at Dropshipman.",
      video:
        "https://www.youtube.com/embed/kNUnbKidLNU?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/buildShopify/new3.png",
      coupon: false,
      button: "Sourcing Request",
      button2:'',
      vd: 0,
      task: 0,
    },
    {
      title: "How Dropshipman fulfills your orders",
      dsc: "Earn <strong>100 points</strong> for watching a video on how to fulfill orders at Dropshipman.",
      video:
        "https://www.youtube.com/embed/1OiyRfrMF74?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/buildShopify/new4.png",
      coupon: false,
      button: "",
      button2:'',
      vd: 0,
      task: 0,
    },
  ];

  const list2 = [
    {
      title: "Choose a plan to get started",
      dsc: `Try Dropshipman free for 7 days to get started, and earn 200 points for completing this task.`,
      video: "",
      isAction: true,
      stepMask: "/dashboard/new_24_image1.png",
      coupon: false,
      button: "Choose a plan to start",
      button2: "",
      icon:'',
      vd: 0,
      task: 0,
      points: 200,
    },
    {
      title: "Find Products & List Products through Dropshipman",
      dsc: "Watch the video on finding products with Dropshipman to earn 100 points, and list a product from Dropshipman to your store to earn an extra 200 points.",
      video:
        "https://www.youtube.com/embed/nSWr8lEwlVI?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/dashboard/new_24_image2.png",
      coupon: false,
      button: "Watch the video",
      button2: "Find Product",
      icon:PlayCircleIcon,
      vd: 0,
      task: 0,
      points: 300,
    },
    {
      title: "Submit sourcing request with Dropshipman",
      dsc: "Watch the video on how to submit a sourcing request on Dropshipman and earn 150 points.",
      video:
        "https://www.youtube.com/embed/kNUnbKidLNU?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/dashboard/new_24_image3.png",
      coupon: false,
      button: "Watch the video",
      button2: "",
      icon:PlayCircleIcon,
      vd: 0,
      task: 0,
      points: 150,
    },
    {
      title: "Orders fulfilled by Dropshipman",
      dsc: "Watch the video on how Dropshipman fulfills your orders to earn 150 points.",
      video:
        "https://www.youtube.com/embed/1OiyRfrMF74?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/dashboard/new_24_image4.png",
      coupon: false,
      button: "Watch the video",
      button2: "",
      icon:PlayCircleIcon,
      vd: 0,
      task: 0,
      points: 150,
    },
    {
      title: "Archive dropshipping success with Dropshipman ",
      dsc: "Join Dropshipman’s dropshipping success program to earn 200 points.",
      video:
        "https://www.youtube.com/embed/1OiyRfrMF74?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/dashboard/new_24_image5.png",
      coupon: false,
      button: "Join Program",
      button2: "",
      icon:'',
      vd: 0,
      task: 0,
      points: 200,
    },
  ];

  const [newerStartData, setNewerStartData] = useState(list1);// 新人引导数据
  const [getStartClo, setGetStartClo] = useState(true);//引导是否关闭
  const [currentStart, setCurrentStart] = useState(0);//当前的引导步骤
  const [newPersonPlan,setNewPersonPlan] = useState(false);// 新人引导订阅弹窗
  const [getStartShow,setGetStartShow] = useState(false); //引导视频是否显示
  const [videoLink,setVideoLink] = useState("");// 视频链接
  const [videoTitle,setVideoTitle] = useState("");// 视频步骤标题
  const [achiveShow,setAchiveShow] = useState(false);//成就弹窗开关
  useEffect(() => {
    let isMount = false;
    if (!isMount && data && Object.values(data).length > 0) {
      if(Object.values(data.step_info).length === 4){
        let list = list1;
        list = list1.map((item, ind) => {
          if (
            Object.values(data).length > 0 &&
            data.step_info[ind + 1].task === 1 &&
            data.step_info[ind + 1].video === 1
          ) {
            return { ...item, coupon: true,task:data.step_info[ind + 1].task,vd:data.step_info[ind + 1].video };
          } else {
            return { ...item, task:data.step_info[ind + 1].task,vd:data.step_info[ind + 1].video };
          }
        });
        setNewerStartData(list)
      }else{
        let lists = list2;
        lists = list2.map((item, ind) => {
          if (
            Object.values(data).length > 0 &&
            data.step_info[ind + 1].task === 1 &&
            data.step_info[ind + 1].video === 1
          ) {
            return { ...item, coupon: true,task:data.step_info[ind + 1].task,vd:data.step_info[ind + 1].video };
          } else {
            return { ...item, task:data.step_info[ind + 1].task,vd:data.step_info[ind + 1].video };
          }
        });
        setNewerStartData(lists)
      }
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const handleCloseGuide = async () => {
    let res = await reqPostCardDismiss({type:2})
    if (res.code === '200') {
      setNewPerson(false)
      toastFun('Saved successfully', false)
    }else {
      toastFun('Saved failed')
    }
  };
  const handleItemToggle = (ind) => {
    setCurrentStart(ind);
  };
  
  const planShow = () => {
    let person = store.getState().languageReducer.subScriptData;
    let flag = false;
    if (
      person.shopify_level !== "Development" &&
      person.shopify_level !== "Developer Preview" &&
      person.shopify_level !== "staff" &&
      person.shopify_level !== "staff_business"
    ) {
      flag = true;
    }
    return flag;
  };
  const handleToPoints = (title) => {
    if (title === 'Find Product') {
      utils.routeListen("/admin/product_selection?select_tab=0");
      utils.routeJump1(router, "/admin/product_selection?select_tab=0");
    }
    if (title === 'Sourcing Request') {
      utils.routeListen("/admin/product_selection?select_tab=2");
      utils.routeJump1(router, "/admin/product_selection?select_tab=2");
    }
    
    
    if (title === "Choose a plan to start") {
      setNewPersonPlan(true)
    }
    if (title === "Join Program") {
      setAchiveShow(true)
    }
    if (title === "Dropshipman credits") {
      if (newerStartData[currentStart].coupon) {
        utils.routeListen("/admin/wallet");
        utils.routeJump1(router, "/admin/wallet");
      }else {
        openCoupon(4,"/admin/wallet");
      }
    }
    if (title === "Point") {
      if (newerStartData[currentStart].coupon) {
        utils.routeListen("/admin/points");
        utils.routeJump1(router, "/admin/points");
      }else {
        openCoupon(4,"/admin/points");
      }
    }
    if (title === "Coupons") {
      if (newerStartData[currentStart].coupon) {
        utils.routeListen("/admin/coupon");
        utils.routeJump1(router, "/admin/coupon");
      }else {
        openCoupon(4,"/admin/coupon");
      }
    }
    if (title === "Dropshipman Discord Community") {
      if (newerStartData[currentStart].coupon) {
       window.open('https://discord.com/invite/d5kEscjueC','_blank')
      }else {
        openCoupon(4,"https://discord.com/invite/d5kEscjueC",'_blank');
      }
    }
  };

  const handleOffPlanModal = ()=>{
    setNewPersonPlan(false)
  }
   // 打开引导视频并调用接口
  const openGuideVideo = (ind, url, vd,title) => {
    
    setGetStartShow(true)
    setVideoLink(url)
    setCurrentStart(ind)
    setVideoTitle(title)
    // 如果没有领取优惠券则调用接口
    if (vd === 0) {
      openCoupon(ind);
    }
  };
 const openCoupon = async (ind,link,blank='') => {
    let params = {
      step: ind + 1,
    };

    let res;
    if (Object.values(data.step_info).length === 4) {
      res = await reqPostNoviceRedeem(params);
    }else {
      res = await reqPostGuidePoints(params);
    }
    if (res.code === "200") {
      toastFun(res.msg);
      if (res?.data?.video) {
        // this.setState({
        //   availablePoints:Number(this.state.availablePoints) + res?.data?.points
        // })
        setPageData((prevValue)=>({
          ...prevValue,
          available_points:Number(prevValue.available_points) + res?.data?.points
        }))
      }
      let list = newerStartData.map((item, k) => {
        if (k === ind) {
          return { ...item, coupon: res?.data?.task && res?.data?.video ,vd:1};
        } else {
          return item;
        }
      });
      setNewerStartData(list)
      if (link && ind === 4 && !blank) {
        utils.routeListen(link);
        utils.routeJump1(router, link);
      }
      if (link && ind === 4 && blank) {
        window.open('https://discord.com/invite/d5kEscjueC','_blank')
      }
    }
  };

  
  return (
    <div>
      <Box className="new_person_box">
        {newerStartData.length === 4 ? (
          <Card>
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    display: "inline-block",
                    minHeight: "32px",
                  }}
                >
                  {intl.get("Get started with Dropshipman and earn 700 points")}
                </span>
                <Box className="new_person_box_right_button">
                  <InlineStack align="space-between">
                    <Box>
                    <Button
                            variant="tertiary"
                            onClick={
                              () => {
                                handleCloseGuide()
                              }
                            }
                          >
                            <Tooltip content="Dismiss">
                            <Icon source={XIcon} tone="base" />
                            </Tooltip>
                          </Button>
                    </Box>
                    <Box>
                      {getStartClo ? (
                        <Button
                          variant="tertiary"
                          onClick={() =>
                            {setGetStartClo(false)}
                          }
                        >
                          <Icon source={ChevronUpIcon} tone="base" />
                        </Button>
                      ) : (
                        <Button
                          variant="tertiary"
                          onClick={() =>
                            {setGetStartClo(true)}
                          }
                        >
                          <Icon source={ChevronDownIcon} tone="base" />
                        </Button>
                      )}
                    </Box>
                  </InlineStack>
                </Box>
              </InlineGrid>
              <Box>
                <InlineStack blockAlign="center">
                  <Text as="p" variant="bodyMd">
                    {intl.get(
                      "{{tasks}} of 4 tasks completed",
                      newerStartData.filter((item) => item.coupon).length
                    )}
                  </Text>
                  <div
                    className="progressbar"
                    style={{
                      flex: 1,
                      marginLeft: "16px",
                      display: "inline-block",
                    }}
                  >
                    <ProgressBar
                      progress={
                        (newerStartData.filter((item) => item.coupon).length /
                          4) *
                        100
                      }
                      size="small"
                      tone="primary"
                    />
                  </div>
                </InlineStack>
              </Box>
              <Collapsible
                open={getStartClo}
                id="basic-collapsible"
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
                <Box className="get_start_list">
                  {newerStartData.map((item, ind) => (
                    <Box
                      key={ind}
                      className={
                        currentStart === ind
                          ? "get_start_items get_start_item"
                          : "get_start_item"
                      }
                    >
                      <Box
                        className="get_start_item_p"
                        style={{
                          padding: "0 16px",
                          display: "flex",
                          alignItems: "center",
                          
                        }}
                        onClick={() => handleItemToggle(ind)}
                      >
                        {/* <RadioButton label={""} checked={item.coupon} /> */}
                        {item.coupon ? <NewStartBlack /> : <NewStartNone />}
                        <Text as="p" variant="bodyMd">
                          {item.title}
                        </Text>
                      </Box>
                      <Collapsible
                        open={Number(currentStart) === Number(ind)}
                        id="basic-collapsible"
                        transition={{
                          duration: "200ms",
                          timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                      >
                        <Box className="get_start_item_text">
                          <Box
                            className={
                              planShow()
                                ? `get_start_item_text_title get_start_item_text_title_plan`
                                : `get_start_item_text_title `
                            }
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.dsc,
                              }}
                            ></span>
                            {item.button !== "" && (
                              <p className="get_start_item_text_btn">
                                <Button
                                  variant="primary"
                                  onClick={() => handleToPoints(item.button)}
                                >
                                  {item.button}
                                </Button>
                              </p>
                            )}
                          </Box>
                          <Box
                            className="get_start_item_text_img"
                            onClick={() =>
                              openGuideVideo(ind, item.video, item.vd,item.title)
                            }
                          >
                            <img src={item.stepMask} alt="dropshipman" />
                          </Box>
                        </Box>
                      </Collapsible>
                    </Box>
                  ))}
                </Box>
              </Collapsible>
            </BlockStack>
          </Card>
        ) : (
          <Card>
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 650,
                    display: "inline-block",
                    minHeight: "32px",
                    paddingTop:'3px'
                  }}
                >
                  {intl.get("Get Started with Dropshipman")}
                </span>
                <Box className="new_person_box_right_button">
                  <InlineStack align="space-between">
                    <Box>
                    <Button
                            variant="tertiary"
                            onClick={
                              () => {
                                handleCloseGuide()
                              }
                            }
                          >
                            <Tooltip content="Dismiss">
                            <Icon source={XIcon} tone="base" />
                            </Tooltip>
                          </Button>
                    </Box>
                    <Box>
                      {getStartClo ? (
                        <Button
                          variant="tertiary"
                          onClick={() =>
                            {setGetStartClo(false)}
                          }
                        >
                          <Icon source={ChevronUpIcon} tone="base" />
                        </Button>
                      ) : (
                        <Button
                          variant="tertiary"
                          onClick={() =>
                            {setGetStartClo(true)}
                          }
                        >
                          <Icon source={ChevronDownIcon} tone="base" />
                        </Button>
                      )}
                    </Box>
                  </InlineStack>
                </Box>
              </InlineGrid>
              <Box className="new_person_box_text_n">
                <Box className="new_person_box_text_n_desc">Quickly start your journey with a personalized guide.</Box>
                <Box className="new_person_box_text_n_step">
                    <InlineStack blockAlign="center">
                      <strong style={{lineHeight:'20px'}}>
                        {newerStartData.filter((item) => item.coupon).length}/5 Completed.&nbsp;
                      </strong>
                      <strong style={{color:'#f5520c',lineHeight:'20px'}}><img height={18} src="/blackFriday/icon_points.png" alt="Dropshipman" /> Earn 1000 Points worth $10 </strong>
                      <span style={{lineHeight:'20px'}} className="new_person_box_text_n_step_mobile_close">&nbsp;for completing all the Quick start task</span>
                    </InlineStack>
                </Box>
              </Box>
              <Collapsible
                open={getStartClo}
                id="basic-collapsible"
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
                <Box className="get_start_list get_start_list_n">
                  {newerStartData.map((item, ind) => (
                    <Box
                      key={ind}
                      style={{ paddingBottom: "10px" }}
                      className={
                        currentStart === ind
                          ? "get_start_items get_start_item get_start_item_n"
                          : "get_start_item"
                      }
                    >
                        <Box className="get_start_item_n_box" style={{width:Number(currentStart) !== Number(ind) ? "100%" : '65%'}}>
                      <Box
                        className="get_start_item_p"
                        style={{
                          padding: "0 16px",
                          display: "flex",
                          alignItems: "center",
                          marginTop:'10px'
                        }}
                        onClick={() => handleItemToggle(ind)}
                      >
                        {/* <RadioButton label={""} checked={item.coupon} /> */}
                        {item.coupon ? <NewStartBlack /> : <NewStartNone />}
                        <Box className="get_start_item_p_text">
                        <Text as="p" variant="bodyMd">
                          {item.title}
                        </Text>
                        <span>
                            <img width={16} src="/sub/points_icon.png" alt="Dropshipman" />
                            {item.points} Points
                        </span>
                        </Box>
                        
                      </Box>
                      <Collapsible
                        open={Number(currentStart) === Number(ind)}
                        id="basic-collapsible"
                        transition={{
                          duration: "200ms",
                          timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                      >
                        <Box className="get_start_item_text">
                          <Box
                            className={
                              planShow()
                                ? `get_start_item_text_title get_start_item_text_title_plan`
                                : `get_start_item_text_title `
                            }
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.dsc,
                              }}
                            ></span>
                            {(item.button !== "" && !(Number(ind) === 0 && item.coupon)) && (
                              <p className="get_start_item_text_btn">
                                {item.icon ? <Button
                                  variant="primary"
                                  icon={item.icon}
                                  onClick={() =>{
                                    if (item.button === 'Watch the video') {
                                    openGuideVideo(ind, item.video, item.vd,item.title)
                                  }else { handleToPoints(item.button)
                                  }
                                }
                                  }
                                >
                                  {item.button}
                                </Button>:<Button
                                  variant="primary"
                                  onClick={() =>{
                                    if (item.button === 'Watch the video') {
                                    openGuideVideo(ind, item.video, item.vd,item.title)
                                  }else { handleToPoints(item.button)
                                  }
                                }
                                  }
                                >
                                  {item.button}
                                </Button>}
                                {item?.button2 && <Button
                                  onClick={() => 
                                    {
                                        handleToPoints(item.button2)
                                    }
                                  }
                                >
                                  {item.button2}
                                </Button>}
                              </p>
                            )}
                          </Box>
                          
                        </Box>
                      </Collapsible>
                      </Box>
                      {Number(currentStart) === Number(ind) && <Box
                            className="get_start_item_n_box_img"
                          >
                            <img src={item.stepMask} alt="dropshipman" />
                          </Box>}
                    </Box>
                  ))}
                </Box>
              </Collapsible>
            </BlockStack>
          </Card>
        )}
      </Box>
      {/* 新人订阅弹窗 */}
      <PlanNewModal open={newPersonPlan} handleOffPlanModal={handleOffPlanModal} toastFun={toastFun}/>
      {/* 新人成就弹窗 */}
      <AchievementNewPersonModal open={achiveShow} setAchiveShow={setAchiveShow} handleToPoints={handleToPoints}/>

      {/* 视频弹窗 */}
      <NewPersonVideo getStartShow={getStartShow} currentStart={currentStart} setGetStartShow={setGetStartShow} videoLink={videoLink} videoTitle={videoTitle}/>
    </div>
  );
};

export default NewPersonBox;
