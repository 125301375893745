import { createSlice } from '@reduxjs/toolkit'
import i18n from 'i18next';
// import utils from '../utils/utils'
const languageSlice = createSlice({
  name: 'language',
  language:'en',
  initialState: {
    status:false,
    loading:false,
    subScriptData:{},
    sidebarCountData:{},
    bannerShow:false,
    bannerTitle:'Error reminder',
    bannerDesc:'',
    initialPath: "", // 初始路径
    hasReturnedOnce: false, // 是否已经返回过一次
    public:{
      status:true
    },
  },
  
  reducers: {
    changeLanguageData(state, action) {
      window.language = action.payload.language;
      i18n.changeLanguage(action.payload.language);
      state.language = action.payload.language;
    },
    changePersonLevel(state,action){
      if (Object.values(action.payload).length > 0) {
        state.status = true;
        state.loading = true;
      }
      if (action.payload?.slideData) {
        state.sidebarCountData = action.payload.slideData
      }
      if (action.payload?.infoData) {
        state.subScriptData = action.payload.infoData
      }
    },
    changeBannerData(state,action){
      if (action.payload?.bannerData) {
        state.bannerShow = action.payload.bannerData.show;
        // state.bannerTitle = action.payload.bannerData.title;
        state.bannerDesc = action.payload.bannerData.desc;
      }
    },
    changeSourcingData(state,action){
      if (action.payload?.sourcing) {
        state.public = {status:action.payload.sourcing.show};
      }
    },
    setInitialPath: (state, action) => {
      state.initialPath = action.payload;
    },
    setHasReturnedOnce: (state, action) => {
      state.hasReturnedOnce = action.payload;
    },
  }
})

export const { changeLanguageData,changePersonLevel,changeBannerData,changeSourcingData,setInitialPath, setHasReturnedOnce } = languageSlice.actions
export default languageSlice.reducer