import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Tabs,
  Filters,
  Button,
  ChoiceList,
  Checkbox,
  EmptyState,
  BlockStack,
  Link,
  Badge,
  TextField,
  Pagination,
  Modal,
  Toast,
  Layout,
  SkeletonBodyText,
  Spinner,
  Tooltip,
  Banner,
  Select,
  Text,
  InlineStack,
  Box,
} from "@shopify/polaris";

import {
  reqPostageOrderList,
  reqEditPostageOrderNote,
  reqPostageOrderPayPal,
  reqPostageOrderWallet,
  reqPostPaymentProcess,
} from "../../../../api";
import utils from '../../../../utils/utils'
import DatePickerComponent from "../../../../components/DatePickerComponent";
import PostagePayPalComponent from "../../../../components/PostagePayPalComponent";
import PostagePaymentComponent from "../../../../components/PostagePaymentComponent";
import SymbolTake from "../../../../components/SymbolTake";

import "../index.css";
import "../../NoSourceOrders/index.css";
import intl from "../../../../i18n/intl";
import { store } from "../../../../store";
import { changeBannerData } from "../../../../slice";

const searchIcon = (
  <div className="Polaris-Icon">
    <svg
      viewBox="0 0 20 20"
      className="Polaris-Icon__Svg"
      focusable="false"
      aria-hidden="true"
    >
      <path d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm9.707 4.293-4.82-4.82a5.968 5.968 0 0 0 1.113-3.473 6 6 0 0 0-12 0 6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414z"></path>
    </svg>
  </div>
);

class ShippingDifference extends Component {
  state = {
    //搜索停顿
    delay: 0,
    page: 1,
    size: 50,
    hasPrevious: false,
    hasNext: true,
    totalCount: 0,
    products: [],

    //第一次请求
    oneloading: true,
    //页面动作
    loadingPage: false,
    //表格数据
    table: [],
    //所选择的ID
    tableInputID: [],
    tableInputChecked: [],

    //其他
    selected: 0,
    queryValue: "",
    PaymentStatus: [],
    productKeywordsStatus: "DSM order number", // 产品关键词筛选项

    //全选
    checked: false,

    // 新增字段
    queryValueNew: "", // 新搜索框的搜索值
    editNoteKey: "", // 编辑订单备注弹窗开关
    editNoteId: 0, // 编辑订单备注ID
    editNoteContent: "", // 编辑订单备注内容
    editNoteActive: false, // 编辑订单备注弹窗开关
    editNoteLoading: false, // 编辑订单备注Save按钮loading状态
    showFilters: true, // 是否显示筛选选项
    search_start_day: "", // 搜索框开始日期
    search_end_day: "", // 搜索框结束日期
    loadingQueryBtn: false, // search按钮是否loading
    createdAt: [], // 时间
    toWalletActive: false, // 打开钱包提示弹窗
    handleWalletActiveLoading: false, // 钱包确定支付按钮
    BulkShippingCheckOrder: [], // 选中的批量订单
    BulkShippingCheckOrderId: [], // 选中的批量订单id
    BulkShippingCheckOrderPrice: 0, // 选中的批量订单价格

    // PayPal弹窗数据
    isPayPalOpen: false,
    payData: {}, //支付所需数据
    PayPalCheckoutLoading: false, // 订单号是否生成的loading状态
    createPayPalOrderId: 0, // 生成的订单号

    // 支付方式弹窗数据
    isPaymentOpen: false,
    paymentData: {}, // 钱包所需数据
    walletMoney: 0, // 钱包余额

    // 支付记录数据
    payLogId: 0, // 执行支付记录ID
    operationNumber: 0,
    currentExecutionsNumber: 0,

    // 未支付订单数量
    unpaidOrderCount: 0,
    toPage:''
  };

  //进入页面调用接口
  componentDidMount() {
    this.GetAppDashboardData(true);
  }

  //获取数据接口
  GetAppDashboardData = async (getStatus) => {
    if (getStatus === true) {
      this.setState({
        loadingPage: false,
        loadingQueryBtn: true,
      });
    }
    let paymentArr = [];
    this.state.PaymentStatus.includes("Unpaid") && paymentArr.push(1);
    this.state.PaymentStatus.includes("Pending") && paymentArr.push(2);
    this.state.PaymentStatus.includes("Paid") && paymentArr.push(3);
    let timeArr = [];
    timeArr[0] = this.state.search_start_day;
    timeArr[1] = this.state.search_end_day;
    const params = {
      page: this.state.page,
      size: this.state.size,
      order_number: this.state.queryValueNew,
      payment_status: paymentArr,
      created_at: timeArr,
    };
    const response = await reqPostageOrderList(params);

    if (response.code === "200") {
      var getData = response.data;
      // //多选使用
      var tableInputCheckedArr = [];
      // //表格ID
      var tableInputIDArr = [];

      for (var i = 0; i < getData.data.length; i++) {
        tableInputCheckedArr.push(false);
        tableInputIDArr.push(getData.data[i].id);
      }

      // 更新分页数据
      const maxPage = Math.ceil(getData.total / this.state.size);

      var hasPrevious = false;
      var hasNext = false;
      //如果当前页面大于1 且 小于 总分页   上下都为开启
      if (this.state.page > 1 && this.state.page < maxPage) {
        hasPrevious = true;
        hasNext = true;
        //如果当前页面小于等于1 且 小于总分页 下关闭 上开启
      } else if (this.state.page <= 1 && this.state.page < maxPage) {
        hasPrevious = false;
        hasNext = true;
        //如果当前页面大于1 且 大于等于 总分页   下开启 上关闭
      } else if (this.state.page > 1 && this.state.page >= maxPage) {
        hasPrevious = true;
        hasNext = false;
        // 否则都关闭
      } else if (this.state.page <= 1 && this.state.page >= maxPage) {
        hasPrevious = false;
        hasNext = false;
      }
      this.setState(
        {
          hasPrevious: hasPrevious,
          hasNext: hasNext,
          totalCount: getData.total,
          loadingPage: true,
          checked: false,
          oneloading: false,
          tableInputChecked: tableInputCheckedArr,
          tableInputID: tableInputIDArr,
          table: getData.data,
          walletMoney: Number(getData.wallet_money).toFixed(2),
          // isShowSelectAllBtn,
          unpaidOrderCount: getData.summary.unpaid_count,
          loadingQueryBtn: false,
        },
        () => {
          this.updateTableContent();
        }
      );
    } else {
      this.setState(
        {
          loadingQueryBtn: false,
        },
        () => {
          this.toggleToast(response.msg);
        }
      );
    }
  };

  //更新列表数据
  updateTableContent() {
    const products = this.state.table.map((item, key) => {
      //备注 (非异常订单才显示)
      var EditNote = (
        <Tooltip content={item.note !== "" ? item.note : ""}>
          <span style={{position:'relative',display:'inline-block',width:'20px',height:'20px'}}>
          <img
            style={{cursor:'pointer',position:'absolute',left:'-10px',top:'-10px'}}
            onClick={() => this.handleEditNoteModal(key, item.id, item.note)}
            src="/flow.png"
            alt="dropshipman"
            height={36}
          />
          </span>
          
          {/* <Button
            onClick={() => this.handleEditNoteModal(key, item.id, item.note)}
          >
            Edit note
          </Button> */}
        </Tooltip>
      );

      var paymentBadge = "";
      var paymentBadgeStatus = "";
      //订单状态
      var payment_status_res = {
        1: "Unpaid",
        2: "Pending",
        3: "Paid",
        4: "Refunded",
      };
      paymentBadge = payment_status_res[item.payment_status]
        ? payment_status_res[item.payment_status]
        : "unknown";
      paymentBadgeStatus =
        payment_status_res[item.payment_status] === "Unpaid"
          ? "warning"
          : payment_status_res[item.payment_status] === "Pending"
          ? "default"
          : payment_status_res[item.payment_status] === "Paid"
          ? "success"
          : "critical";

      //批量选择按钮
      var Checkbox_div = (
        <div>
          <Checkbox
            checked={this.state.tableInputChecked[key]}
            onChange={this.handleChangeChecked.bind(this, key)}
          />
        </div>
      );
      var totalContent = (
        <div className="OrderDivclassStyle" key={"key" + key}>
          <div className="OrderTopDIV shipping_difference">
            <InlineStack align="space-between">
              <InlineStack align="space-between" blockAlign="center">
                {Checkbox_div}
                <div style={{ fontSize: 14, margin: "0 16px" }}>
                  <Link
                    onClick={() => {
                      utils.routeListen('/admin/order_list?filter=dsm_order&q='+item.dsm_order_number.slice(4))
                      utils.routeJump(this,'/admin/order_list?filter=dsm_order&q='+item.dsm_order_number.slice(4))
                    }}
                  >
                    {item.dsm_order_number}
                  </Link>
                </div>
                <div style={{ fontSize: 14 }}>{item.created_at}</div>
              </InlineStack>
              <Badge tone={paymentBadgeStatus}>{paymentBadge}</Badge>
              <div>{EditNote}</div>
            </InlineStack>
          </div>
          <div className="shippingCordMarginDiv">
            <InlineStack align="space-between" blockAlign="center">
              <InlineStack align="space-between" blockAlign="center">
                <img src="/package.png" alt={item.dsm_order_number} />
                <p style={{ fontSize: 16, marginLeft: "16px" }}>
                  <Text variant="bodyMd" as="span" fontWeight="semibold">
                  {intl.get(item.title)}
                  </Text>
                </p>
              </InlineStack>
              <p style={{ fontSize: 16 }}>
                {intl.get("Cost:")}
                <Text
                  variant="bodyMd"
                  as="span"
                  fontWeight="semibold"
                >{`$${Number(item.amount).toFixed(2)} × 1`}</Text>
              </p>
              <p style={{ fontSize: 16 }}>
                {intl.get("Total")}:
                <SymbolTake price={Number(item.amount).toFixed(2)} />
                {/* <Text
                  variant="bodyMd"
                  as="span"
                  fontWeight="semibold"
                >{`$${Number(item.amount).toFixed(2)}`}</Text> */}
              </p>
            </InlineStack>
          </div>
          <div className="Polaris-Stack-left">
            <Box className='shipping_difference_pay' style={{ textAlign: "right", padding: "0 16px 16px 16px" }}>
              <div className="marginTop08">
                {intl.get("Total cost")}:
                <b>
                <SymbolTake price={item.amount} />
                  {/* <span>${Number(item.amount).toFixed(2)}</span> */}
                </b>
              </div>
              {item.payment_status === 1 && (
                <Button
                  variant="primary"
                  onClick={() =>
                    this.handlePayment(
                      item.id,
                      item.dsm_order_number,
                      item.amount
                    )
                  }
                >
                  {intl.get("Payment")}
                </Button>
              )}
            </Box>
          </div>
        </div>
      );

      return [totalContent];
    });
    // 更新图片列表数据
    if (products.length > 0) {
      this.setState({
        products: products,
        isEmpty: false,
      });
    } else {
      this.setState({
        products: products,
        isEmpty: true,
      });
    }
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    }));
  };

  // 订单备注弹窗控制
  handleEditNoteModal = (recordKey, id, note) => {
    this.setState({
      editNoteKey: recordKey,
      editNoteId: id,
      editNoteContent: note,
      editNoteActive: id !== 0,
      editNoteLoading:false
    });
  };

  //订单备注提交
  handleSaveNote = async () => {
    if (this.state.editNoteId === 0) {
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:"Something wrong"
      }}));
      return false;
    }

    this.setState({ editNoteLoading: true });
    let params = {
      id: this.state.editNoteId,
      note: this.state.editNoteContent,
    };

    const response = await reqEditPostageOrderNote(params);
    if (response.code === "200") {
      // 保存成功，更新到原数组中,并关闭弹窗
      if (this.state.editNoteKey !== "") {
        let data = this.state.table;
        data[this.state.editNoteKey].note = this.state.editNoteContent;
        this.setState(
          {
            table: data,
            editNoteLoading: false,
            editNoteActive: false,
          },
          () => {
            this.updateTableContent();
          }
        );
      }
      this.toggleToast('Saved successfully');
    } else {
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:response.msg
      }}));
      // this.toggleToast(response.msg);
    }
  };

  //切换卡片
  handleTabChange = (selectedTabIndex) => {
    this.setState(
      {
        selected: selectedTabIndex,
        loadingPage: false,
        showFilters: selectedTabIndex === 0,
        PaymentStatus: selectedTabIndex === 0 ? [] : ["Unpaid"],
      },
      () => {
        this.GetAppDashboardData(true);
      }
    );
  };

  //状态选择
  handleChangePaymentStatus = (selectedTabIndex) => {
    this.setState({
      PaymentStatus: selectedTabIndex,
      delay: 1,
    });
    let _this = this;
    setTimeout(() => {
      _this.setState({ delay: 0 },()=>{
        if (this.state.delay === 0) {
          //执行后台请求的代码
          this.GetAppDashboardData(true);
        }
      });
      
    }, 600);
  };

  //删除清空筛选条件
  handleRemovePaymentStatus = () => {
    this.setState({
      PaymentStatus: [],
      delay: 1,
    });
    let _this = this;
    setTimeout(() => {
      _this.setState({ delay: 0 },()=>{
        if (this.state.delay === 0) {
          //执行后台请求的代码
          this.GetAppDashboardData(true);
        }
      });
    }, 600);
  };
  handleQueryValueRemove = () => {
    this.setState({
      queryValue: "",
      delay: 0,
    });
    let _this = this;
    setTimeout(() => {
      _this.setState({ delay: 0 },()=>{
        if (this.state.delay === 0) {
          //执行后台请求的代码
          this.GetAppDashboardData(true);
        }
      });
    }, 600);
  };
  handleFiltersClearAll = () => {
    this.setState({
      PaymentStatus: [],
      delay: 1,
    });
    let _this = this;
    setTimeout(() => {
      _this.setState({ delay: 0 },()=>{
        if (this.state.delay === 0) {
          //执行后台请求的代码
          this.GetAppDashboardData(true);
        }
      });
    }, 600);
  };

  //搜索输入框
  handleFiltersQueryChange = (queryValue) => {
    this.setState({
      queryValue: queryValue,
      delay: 1,
    });
    let _this = this;
    setTimeout(() => {
      _this.setState({ delay: 0 },()=>{
        if (this.state.delay === 0) {
          //执行后台请求的代码
          this.GetAppDashboardData(true);
        }
      });
    }, 600);
  };

  //开启弹窗
  ModalOpen = (ModalOpen) => {
    if (ModalOpen === "toWalletActive" || ModalOpen === "PayPal CREDIT") {
      let table = this.state.table;
      let tableInputChecked = this.state.tableInputChecked;
      let checkArr = [];
      let checkArrId = [];
      let checkPrice = 0;
      table.forEach((item, key) => {
        if (tableInputChecked[key] && item.payment_status === 1) {
          // 选中的 且状态是unpaid的才push到数组中
          checkArr.push(table[key].dsm_order_number);
          checkArrId.push(table[key].id);
          checkPrice += Number(table[key].amount);
        }
      });
      this.setState(
        {
          BulkShippingCheckOrder: checkArr,
          BulkShippingCheckOrderId: checkArrId,
          BulkShippingCheckOrderPrice: checkPrice.toFixed(2),
        },
        () => {
          if (
            this.state.BulkShippingCheckOrder.length === 0 &&
            this.state.BulkShippingCheckOrderId.length === 0
          ) {
            this.toggleToast("No payable orders", false);
            return;
          }
          if (ModalOpen === "PayPal CREDIT") {
            this.setState(
              {
                isPayPalOpen: true,
              },
              () => {
                this.setState(
                  {
                    payData: {
                      orderArr: this.state.BulkShippingCheckOrder,
                      totalPrice: this.state.BulkShippingCheckOrderPrice,
                    },
                  },
                  () => {
                    this.handleCheckoutOrder(
                      this.state.BulkShippingCheckOrderId
                    );
                  }
                );
              }
            );
          } else {
            this.setState({
              [ModalOpen]: true,
            });
          }
        }
      );
    } else {
      this.setState({
        [ModalOpen]: true,
      });
    }
  };

  //关闭弹窗
  ModalClose = (ModalClose) => {
    this.setState({
      [ModalClose]: false,
    });
  };

  //点击选中
  handleChangeChecked(key, event) {
    var tableInputCheckedArray = this.state.tableInputChecked;
    tableInputCheckedArray[key] = !tableInputCheckedArray[key];
    //判断选中的数量
    var SelectedQuantity = 0;
    for (var i = 0; i < tableInputCheckedArray.length; i++) {
      if (tableInputCheckedArray[i] === true) {
        SelectedQuantity += 1;
      }
    }
    var checked = false;
    if (SelectedQuantity === 0) {
      checked = false;
    } else {
      checked = true;
    }
    this.setState(
      {
        tableInputChecked: tableInputCheckedArray,
        checked: checked,
      },
      () => {
        this.updateTableContent();
      }
    );
  }

  //全选按钮
  handleChangeCheckbox = () => {
    //判断当先是否是选中的  还是已经选   还是全部没选 获取选中的数量跟未选中的数量
    var SelectedQuantity = 0;
    var UnselectedQuantity = 0;
    var tableInputCheckedArray = this.state.tableInputChecked;
    for (var i = 0; i < tableInputCheckedArray.length; i++) {
      if (tableInputCheckedArray[i] === false) {
        UnselectedQuantity += 1;
      } else {
        SelectedQuantity += 1;
      }
    }
    //判断如果 全部选中的话  就全部关闭
    if (
      SelectedQuantity === tableInputCheckedArray.length &&
      UnselectedQuantity === 0
    ) {
      for (i = 0; i < tableInputCheckedArray.length; i++) {
        tableInputCheckedArray[i] = false;
      }
      this.setState(
        {
          tableInputChecked: tableInputCheckedArray,
          checked: false,
        },
        () => {
          this.updateTableContent();
        }
      );
    } else {
      for (i = 0; i < tableInputCheckedArray.length; i++) {
        tableInputCheckedArray[i] = true;
      }
      this.setState(
        {
          tableInputChecked: tableInputCheckedArray,
          checked: true,
        },
        () => {
          this.updateTableContent();
        }
      );
    }
  };

  // input change
  handleInputChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  // 关键词+日期 搜索框
  handleTextFieldChange = (val) => {
    if (val.length <= 200) {
      this.setState({ queryValueNew: val }, () => {
        if (val.length <= 0) {
          setTimeout(() => {
            this.GetAppDashboardData(true);
          }, 600);
        }
      });
    }
  };

  // 产品关键词筛选
  handleChangeSelectedProductKeywords = (value) => {
    this.setState({
      productKeywordsStatus: value,
    });
  };

  // 接收日期组件回调
  getDatePickerCallback = (type, val) => {
    if (type === "start") {
      this.setState({
        search_start_day: val,
      });
    } else {
      this.setState({
        search_end_day: val,
      });
    }
  };

  // 清空search input内容
  handleClearSearchInputCallback = () => {
    this.setState(
      {
        queryValueNew: "",
      },
      () => {
        this.handleRequestCallback();
      }
    );
  };

  // 处理搜索请求
  handleRequestCallback = () => {
    this.GetAppDashboardData(true);
  };

  //Payment按钮 订单号 订单价格
  handlePayment = (id, orderNumber, price) => {
    this.setState({
      isPaymentOpen: true,
      paymentData: {
        id: id,
        order: orderNumber,
        price: Number(price).toFixed(2),
        walletMoney: this.state.walletMoney,
      },
    });
  };

  //钱包确定下单
  handleWalletActive = async (ids) => {
    //判断钱包的钱是否够用
    if (+this.state.walletMoney < +this.state.BulkShippingCheckOrderPrice) {
      this.toggleToast("Insufficient balance");
    } else {
      this.setState({
        handleWalletActiveLoading: true,
      });
      let params = {
        id: JSON.stringify(ids),
      };

      const response = await reqPostageOrderWallet(params);
      this.setState({
        handleWalletActiveLoading: false,
        toWalletActive: false,
      });

      this.ModalClose("toWalletActive");
      if (response.code === "200") {
        this.toggleToast('Payment processing', false);
        this.GetAppDashboardData(true);

        // 启动定时器查询支付状态
        if (response.data) {
          this.setState(
            {
              payLogId: response.data,
            },
            () => {
              var importTimer = setInterval(
                function () {
                  this.GetPaymentProcess().then(function (response) {
                    if (response === 200) {
                      clearInterval(importTimer);
                    }
                  });
                }.bind(this),
                3000
              );
            }
          );
        }
      } else {
        this.toggleToast(response.msg);
      }
    }
  };

  // 生成订单号
  handleCheckoutOrder = async (id) => {
    this.setState({ PayPalCheckoutLoading: true });

    let params = {
      id: JSON.stringify(id),
    };

    const response = await reqPostageOrderPayPal(params);
    this.setState({ PayPalCheckoutLoading: false });

    if (response.code === "200") {
      this.setState(
        {
          createPayPalOrderId: response.data,
        },
        () => {
          this.setState({
            payData: {
              ...this.state.payData,
              orderId: this.state.createPayPalOrderId,
            },
          });
        }
      );
    } else {
      this.toggleToast(response.msg);
    }
  };

  // 关闭PayPal支付弹窗回调
  closeModalCallback = (val, type) => {
    type === "PayPal"
      ? this.setState({ isPayPalOpen: val })
      : this.setState({ isPaymentOpen: val });
  };

  // 支付方式 支付按钮回调
  payCallback = (type, order, price, id = null) => {
    if (type === "PayPal") {
      this.setState(
        {
          isPayPalOpen: true,
          isPaymentOpen: false,
          payData: {
            orderArr: [order],
            totalPrice: price,
            orderId: [id],
          },
        },
        () => {
          this.handleCheckoutOrder([id]);
        }
      );
    } else {
      this.setState({
        toWalletActive: true,
        isPaymentOpen: false,
        BulkShippingCheckOrderId: [id],
        BulkShippingCheckOrder: [order],
        BulkShippingCheckOrderPrice: price,
      });
    }
  };

  // 查询支付的状态
  GetPaymentProcess = async () => {
    const params = { id: this.state.payLogId };
    const response = await reqPostPaymentProcess(params);
    if (response.code === "200") {
      let getData = response.data;
      this.setState({
        operationNumber: getData.job === 0 ? getData.total : 0,
        currentExecutionsNumber: getData.job === 0 ? getData.success : 0,
      });

      if (getData.job === 1) {
        // 结束
        setTimeout(() => {
          // 刷新列表
          this.GetAppDashboardData(true);
        }, 1000);

        return 200;
      }
      return 201;
    } else {
      this.setState({
        payLogId: 0,
        operationNumber: 0,
        currentExecutionsNumber: 0,
      });
      return 200;
    }
  };

  // 支付完成刷新页面
  refreshPageCallback = () => {
    this.GetAppDashboardData(true);
  };

  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={() => this.toggleToast("")}
        error={this.state.showToastIserror}
        timeout={3000}
      />
    ) : null;

    const {
      editNoteActive,
      editNoteLoading,
      editNoteContent,
      toWalletActive,
      showFilters,
      walletMoney,
      BulkShippingCheckOrder,
      BulkShippingCheckOrderPrice,
      BulkShippingCheckOrderId,
      payData,
      isPayPalOpen,
      PayPalCheckoutLoading,
      isPaymentOpen,
      paymentData,
      handleWalletActiveLoading,
      operationNumber,
      currentExecutionsNumber,
      unpaidOrderCount,
      search_start_day,
      search_end_day,
      loadingQueryBtn,
    } = this.state;

    //头部tabs
    const tabsArray = [
      {
        id: "1",
        content: intl.get("All"),
      },
      {
        id: "2",
        content: intl.get("Unpaid") + "(" + unpaidOrderCount + ")",
      },
    ];
    const filters = [
      {
        key: "PaymentStatus",
        label: intl.get("Payment status"),
        filter: (
          <ChoiceList
            title={intl.get("Payment status")}
            titleHidden
            choices={[
              { label: intl.get("Paid"), value: 'Paid' },
              { label: intl.get("Pending"), value: 'Pending' },
              { label: intl.get("Unpaid"), value: 'Unpaid' }
            ]}
            selected={this.state.PaymentStatus || []}
            onChange={this.handleChangePaymentStatus}
            allowMultiple
          />
        ),
        shortcut: true,
      },
    ];

    const appliedFilters = [];

    if (!isEmpty(this.state.PaymentStatus)) {
      const key = "PaymentStatus";
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, this.state.PaymentStatus),
        onRemove: this.handleRemovePaymentStatus,
      });
    }

    var headings = "";
    //判断是否有批量选择
    if (this.state.checked === true) {
      //选中的数量
      var SelectedQuantity = 0;
      var tableInputCheckedArray = this.state.tableInputChecked;
      for (var i = 0; i < tableInputCheckedArray.length; i++) {
        if (tableInputCheckedArray[i] === true) {
          SelectedQuantity += 1;
        }
      }

      headings = (
        <div className="batchClick_container">
          <div  style={{display:'flex',alignItems:'center'}}>
            <div className="headings_div__3">
              <Checkbox
                label=""
                labelHidden
                checked={
                  this.state.checked
                    ? SelectedQuantity === this.state.totalCount ||
                      SelectedQuantity === 50
                      ? true
                      : "indeterminate"
                    : false
                }
                onChange={this.handleChangeCheckbox}
              />
            </div>
            <span
              className="Polaris-CheckableButton__Label"
              onClick={this.handleChangeCheckbox}
            >
              {SelectedQuantity + " selected"}
            </span>
          </div>

            <div className="bulk_button" style={{margin:'0 8px'}}>
              <Button onClick={() => this.ModalOpen("toWalletActive")}>
                {intl.get("Credits Payment")}
              </Button>
            </div>

            <div className="bulk_button">
              <Button onClick={() => this.ModalOpen("PayPal CREDIT")}>
                {intl.get("PayPal Checkout")}
              </Button>
            </div>
        </div>
      );
    } else {
      headings = (
        <div className="batchClick_container">
          <div  style={{display:'flex',alignItems:'center'}}>
            <div className="headings_div__3">
              <Checkbox
                label=""
                labelHidden
                checked={this.state.checked}
                onChange={this.handleChangeCheckbox}
              />
            </div>
            <span
              className="Polaris-CheckableButton__Label"
              onClick={this.handleChangeCheckbox}
            >
              {intl.get("Select all")}
            </span>
          </div>
        </div>
      );
    }

    //循环判断是否需要展示多选按钮
    if (this.state.loadingPage === false || this.state.products.length === 0) {
      headings = "";
    }
    //第一次请求为空 后续都有转动
    const loadingMarkup = this.state.oneloading ? (
      ""
    ) : (
      <div className="loadingCenter">
        <Spinner size="large" color="teal" />
      </div>
    );
    //是否请求完成 否则转动或者空
    var pageContent = "";

    var pageNoContent = "";
    //分页
    var footerContentMsg = "";
    if (this.state.totalCount > 0) {
      const startNum = (this.state.page - 1) * this.state.size + 1;
      const endNum =
        this.state.page * this.state.size > this.state.totalCount
          ? this.state.totalCount
          : this.state.page * this.state.size;
      footerContentMsg = (
        <div>
          {intl.get(
            "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
            startNum,
            endNum,
            this.state.totalCount,
            Math.ceil( this.state.totalCount / 50)
          ) }
        </div>
      );
    }

    var pagePagination = "";
    if (this.state.loadingPage === true) {
      if (this.state.products.length === 0) {
        //如果没有数据 多选隐藏
        pageNoContent = (
          <div className="emptyWithImg">
            <EmptyState
              heading={intl.get("No results found")}
              image="/empty.svg"
            >
              <p style={{ fontSize: "14px", opacity: "0", marginBottom: "0" }}>
                {intl.get(
                  "We will sync your order here when the order is created"
                )}
              </p>
            </EmptyState>
          </div>
        );
      } else {
        //分页
        pagePagination = (
          <div className="Polaris-New-Pagination">
            <Pagination
              label={footerContentMsg}
              hasPrevious={this.state.hasPrevious}
              onPrevious={() => {
                this.setState({ page: Number(this.state.page) - 1 }, () => {
                  this.GetAppDashboardData(true);
                });
                if (document) {
                  if (document?.documentElement || document?.body) {
                    document.documentElement.scrollTop = document.body.scrollTop = 0;
                  }
                }
              }}
              hasNext={this.state.hasNext}
              onNext={() => {
                this.setState({ page: Number(this.state.page) + 1 }, () => {
                  this.GetAppDashboardData(true);
                });
                if (document) {
                  if (document?.documentElement || document?.body) {
                    document.documentElement.scrollTop = document.body.scrollTop = 0;
                  }
                }
              }}
            />
             <div>
          <input
            className="Page_input"
            type="text"
            value={this.state.toPage}
            maxLength={"8"}
            onChange={(e) => {
              if (Number.isInteger(Number(e.target.value))) {
                if ((Number(e.target.value) > Math.ceil(this.state.totalCount / 50) || Number(e.target.value) <= 0) && e.target.value !== '') {
                  this.setState({
                    toPage: 1,
                  });
                }else{
                this.setState({
                  toPage: e.target.value,
                });
              }
              }
            }}
          />
          <span
            className="Page_Jump"
            onClick={() => {
              let pageCount = Math.ceil(this.state.totalCount / 50)
              if (pageCount === 1) return false;
              let page = 1;
              if (this.state.toPage <= pageCount) {
                page = this.state.toPage;
              }
              if (
                pageCount > 1 &&
                this.state.toPage <= pageCount
              ) {
                page = this.state.toPage;
              }
              if (page === '') {
                return
              }

              this.setState(
                {
                  page: page,
                },
                () => {
                  this.GetAppDashboardData(true);
                }
              );
              if (document) {
                if (document?.documentElement || document?.body) {
                  document.documentElement.scrollTop = document.body.scrollTop = 0;
                }
              }
            }}
          >
            {intl.get("Go to")}
          </span>
        </div>
          </div>
        );
        pageContent = this.state.products;
      }
    } else {
      pageContent = loadingMarkup;
    }

    // 编辑备注弹窗
    const editOrderNoteModal = (
      <Modal
        open={editNoteActive}
        onClose={() => this.handleEditNoteModal("", 0, "")}
        title={intl.get("Edit order note")}
        primaryAction={{
          content: intl.get("Save"),
          onAction: this.handleSaveNote,
          loading: editNoteLoading,
        }}
        secondaryActions={[
          {
            content: intl.get("Cancel"),
            onAction: () => this.handleEditNoteModal("", 0, ""),
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label={intl.get("Order note")}
            value={editNoteContent}
            onChange={this.handleInputChange.bind(this, "editNoteContent")}
            helpText={intl.get("This note only display on Dropshipman.")}
          />
        </Modal.Section>
      </Modal>
    );

    // [钱包] 订单付款提醒弹窗
    const walletForOrderModal = (
      <Modal
        open={toWalletActive}
        onClose={() => this.ModalClose("toWalletActive")}
        title={intl.get("Order") + ": " + BulkShippingCheckOrder}
        primaryAction={{
          content: intl.get("Yes"),
          onAction: () => this.handleWalletActive(BulkShippingCheckOrderId),
          loading: handleWalletActiveLoading,
        }}
      >
        <Modal.Section>
          <p>
            {intl.get("Current credit balance")} {`$${walletMoney} USD`},{" "}
            {intl.get("order total price")}{" "}
            {`$${BulkShippingCheckOrderPrice} USD`}.{" "}
            {intl.get("Are you sure to pay by credit balance?")}
          </p>
        </Modal.Section>
      </Modal>
    );

    const FilterContent = (
      <Box className="content_list_tab">
        <Tabs
          tabs={tabsArray}
          selected={this.state.selected}
          onSelect={this.handleTabChange}
        ></Tabs>
          <div className="datepick_filters content_list_tab_filters" style={{padding:'0 16px'}}>
            <Select
              label="Search type"
              labelHidden
              options={[
                {
                  label: intl.get("DSM order number"),
                  value: intl.get("DSM order number"),
                },
              ]}
              onChange={this.handleChangeSelectedProductKeywords}
              value={this.state.productKeywordsStatus}
            />
            <div className="search_input_style">
              <TextField
                label=""
                value={this.state.queryValueNew}
                onChange={this.handleTextFieldChange}
                prefix={searchIcon}
                autoComplete="off"
                placeholder={intl.get("Filter items")}
              />
            </div>
            <DatePickerComponent
              searchStartDay={search_start_day}
              searchEndDay={search_end_day}
              queryValueNew={this.state.queryValueNew}
              loading={loadingQueryBtn}
              datePickerCallback={this.getDatePickerCallback}
              clearSearchInputCallback={this.handleClearSearchInputCallback}
              requestCallback={this.handleRequestCallback}
            />
            {/* </div> */}
          </div>
          <section style={{padding:"0 16px 16px"}}>
          {showFilters ? (
            <Filters
              queryValue={this.state.queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={this.handleFiltersQueryChange}
              onQueryClear={this.handleQueryValueRemove}
              onClearAll={this.handleFiltersClearAll}
              hideQueryField
            ></Filters>
          ) : null}
          {headings}
          </section>
          {pageNoContent}
        
      </Box>
    );

    const PostagePayPalModal = (
      <PostagePayPalComponent
        open={isPayPalOpen}
        loading={PayPalCheckoutLoading}
        payData={payData}
        closeModalCallback={this.closeModalCallback}
        refreshPageCallback={this.refreshPageCallback}
      />
    );

    const PostagePayment = (
      <PostagePaymentComponent
        open={isPaymentOpen}
        payData={paymentData}
        closeModalCallback={this.closeModalCallback}
        payCallback={this.payCallback}
      />
    );

    // 支付进度显示
    const paymentTips = operationNumber !== 0 && (
      <div style={{ margin: "2rem 0" }}>
        <Banner
          title={intl.get("{{num}} orders are being paid.",currentExecutionsNumber +
          "/" +
          operationNumber) 
          }
          tone="info"
        ></Banner>
      </div>
    );

    //最终界面
    const LastPageContent = this.state.oneloading ? (
      <div>
        <Layout>
        <Layout.Section>
        <Card>
        <BlockStack gap={400}>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        <SkeletonBodyText lines={3}/>
        </BlockStack>
        </Card>
      </Layout.Section>
        </Layout>
      </div>
    ) : (
      <Layout>
        <Layout.Section>
        <div className="shipping_difference_orders">
          {/* 筛选内容 */}
          {FilterContent}
         
          {/* 支付进度显示 */}
          {paymentTips}
          <BlockStack gap={400}>{pageContent}</BlockStack>
          {/* 分页 */}
          {pagePagination}
          {/* 提示弹窗 */}
          {toastMarkup}
          {/* [钱包] 订单付款提醒弹窗 */}
          {walletForOrderModal}
          {/* 编辑备注弹窗 */}
          {editOrderNoteModal}
          {/* 邮费PayPal支付弹窗 */}
          {PostagePayPalModal}
          {/* 邮费支付方式弹窗 */}
          {PostagePayment}
        </div>
        </Layout.Section>
      </Layout>
    );

    return LastPageContent;

    function disambiguateLabel(key, value) {
      switch (key) {
        case "PaymentStatus":
          return value.map((val) => `${val}`).join(" , ");
        default:
          return value;
      }
    }

    function isEmpty(value) {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else {
        return value === "" || value == null;
      }
    }
  }
}

export default withRouter(ShippingDifference);
