import {
  Page,
  Card,
  Layout,
  IndexTable,
  BlockStack,
  Box,
  Text,
  useIndexResourceState,
  Pagination,
  SkeletonPage,
  SkeletonBodyText,
  EmptyState,
  Tooltip,
} from "@shopify/polaris";

import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import intl from "../../../i18n/intl";
import _ from "lodash";
import utils from "../../../utils/utils";
import { useHistory, withRouter } from "react-router-dom";
import {
  reqGetPointsLogInvitationList,
  reqGetPointsLogInvitationExport,
} from "../../../api";
import ChangeLoading from "../../../components/ChangeLoading";
// import AppBridge from "../../../utils/appBridge";
import Tooltips from "../Dashboard/components/Tooltips";

const Index = () => {
  const router = useHistory();

  const [initPageData, setInitPageData] = useState({
    isInit: true,
    pageLoading: false,
  });
  const [searchParam, setSearchParam] = useState({
    page: 1,
    size: 20,
  });
  const [pageData, setPageData] = useState({
    list: [],
  });
  const getHistoryPage = async (param) => {
    let params = {
      ...param,
    };
    try {
      let res = await reqGetPointsLogInvitationList(params);
      if (res.code === "200") {
        setPageData(res.data);
      }
      setInitPageData({
        isInit: false,
        pageLoading: false,
      });
    } catch (error) {}
  };
  useEffect(() => {
    let isMount = false;
    let url = new URLSearchParams(window.location.search);
    let record_id = url.get("id");
    setSearchParam((prevValue) => ({ ...prevValue, inventory_id: record_id }));
    // if (AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar("Points", "/admin/points");
    // }
    if (!isMount) {
      getHistoryPage(searchParam);
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 总条数
  const pageMsg = () => {
    let total = pageData?.count ? pageData?.count : 0;
    const { page, size } = searchParam;
    const startNum = (page - 1) * size + 1;
    const endNum = page * size > total ? total : page * size;
    return intl.get(
      "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
      startNum,
      endNum,
      total,
      Math.ceil(total / 50)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (page > 1 && page <= maxPageNum) {
      return initPageData.pageLoading ? false : true;
    } else if (page === 1) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, initPageData]);

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (_.inRange(page, 1, maxPageNum)) {
      return initPageData.pageLoading ? false : true;
    } else if (page === maxPageNum) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, initPageData]);

  // 上一页
  const handlePrev = () => {
    setInitPageData((prevValue) => ({ ...prevValue, pageLoading: true }));
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) - 1;
      getHistoryPage(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 下一页
  const handleNext = () => {
    setInitPageData((prevValue) => ({ ...prevValue, pageLoading: true }));
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) + 1;
      getHistoryPage(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState([]);
  // 列表数据
  const rowMarkup = pageData?.list.map((item, index) => {
    return (
      <IndexTable.Row id={index} key={index} position={index}>
        <IndexTable.Cell>
          <Box className="history_table_item">
          <Tooltip content={item.store_url}>
          <Text>
              <a
                style={{ color: "rgba(0, 122, 255, 1)" }}
                href={"https://" + item.store_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.store_url.slice(0,20)}{item.store_url.length > 20 ? '...':''}
              </a>
            </Text>
          
        </Tooltip>
            
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Box className="history_table_item">
            <Text>{item.install_date}</Text>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Box className={"history_table_item"}>
            <Text alignment="center">{item.status}</Text>
          </Box>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Box className="history_table_item">
            <Text alignment="center">{item.points}</Text>
          </Box>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  const formatNumber = (value) => {
    value += "";
    const list = value.split(".");
    const prefix = list[0].charAt(0) === "-" ? "-" : "";
    let num = prefix ? list[0].slice(1) : list[0];
    let result = "";
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }

    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
  };

  // csv 导出
  const handleExport = async () => {
    let params = {
      inventory_id: searchParam.inventory_id,
    };
    let res = await reqGetPointsLogInvitationExport(params);
    if (res.code === "200") {
      var csv = convertToCSV(res.data.list);
      downloadCSV(csv);
    }
  };
  const convertToCSV = (data) => {
    var csv = "";
    for (var i = 0; i < data.length; i++) {
      var row = data[i];
      for (var j = 0; j < row.length; j++) {
        var val = row[j] === null ? "" : row[j].toString();
        if (
          val.indexOf(",") !== -1 ||
          val.indexOf('"') !== -1 ||
          val.indexOf("\n") !== -1
        ) {
          val = '"' + val.replace(/"/g, '""') + '"';
        }
        if (j > 0) {
          csv += ",";
        }
        csv += val;
      }
      csv += "\n";
    }
    return csv;
  };

  const downloadCSV = (csv) => {
    try {
    var link = document.createElement("a");
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    link.download = "Referrals_History_" + new Date().getTime() + ".csv";
    document.body.appendChild(link);
    link.click();
    document?.body?.removeChild(link);
  } catch (error) {
      
  }
  };

  
  const EmptyProductList = (
    <div className="emptyWithImg">
      <EmptyState
        heading={intl.get("Sorry, no results found.")}
        image="/empty.svg"
      ></EmptyState>
    </div>
  );
  const skeletonPage = (
    <SkeletonPage fullWidth={false}>
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap={400}>
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
  return (
    <div className="history_log referral_table" style={{ position: "relative" }}>
      {initPageData.isInit ? (
        skeletonPage
      ) : (
        <Page
          backAction={
            false
              ? undefined
              : {
                  content: "",
                  onAction: () => {
                    utils.routeListen("/admin/points");
                    utils.routeJump1(
                      router,
                      "/admin/points"
                    );
                  },
                }
          }
          title={<div style={{ fontSize: 20 }}>{intl.get("Referrals")}</div>}
        >
          <Layout>
            <Layout.Section>
              <BlockStack gap={400}>
                <Box  className="referral_box_content">
                  <Box className="referral_box">
                    <Box className="referral_box_item">
                      <span>Total Referrals</span>
                      <strong>{pageData?.summary?.total}</strong>
                    </Box>
                    <Box className="referral_box_item">
                      <Box style={{ display: "flex" }}>
                        <span>Valid Referrals</span>
                        <Tooltips
                          text={intl.get(
                            "Referred users who install Dropshipman for the first time and do not uninstall it within 24 hours"
                          )}
                        />
                      </Box>
                      <strong>{pageData?.summary?.valid}</strong>
                    </Box>
                    <Box className="referral_box_item">
                      <span>Earn Points</span>
                      <strong>{formatNumber(pageData?.summary?.earned_points)}</strong>
                    </Box>
                  </Box>
                </Box>
                {pageData?.list.length > 0 ? (
                  <Box>
                    <Card>
                      <Box className="history_table_top">
                        <Box className="history_table_title">
                          Referrals History
                        </Box>
                        <Box className="history_table_export">
                          {pageData?.list.length > 0 && (
                            <span onClick={() => handleExport()}>
                              {" "}
                              <img
                                src="/novice/download_export.png"
                                alt="/dropshipman"
                              />{" "}
                              {intl.get("Export")}
                            </span>
                          )}
                        </Box>
                      </Box>
                      <Box className="history_table">
                        <IndexTable
                          itemCount={pageData?.list.length}
                          selectedItemsCount={
                            allResourcesSelected
                              ? "All"
                              : selectedResources.length
                          }
                          selectable={false}
                          onSelectionChange={handleSelectionChange}
                          headings={[
                            { title: "Store URL" },
                            { title: "Installation Date" },
                            { title: "Status" },
                            { title: "Points" },
                          ]}
                          pagination={{
                            hasNext: true,
                            onNext: () => {},
                          }}
                        >
                          {rowMarkup}
                        </IndexTable>
                      </Box>
                    </Card>
                    <div className="pageination_container">
                      <Pagination
                        label={pageMsg()}
                        hasPrevious={hasPrevious}
                        hasNext={hasNext}
                        onPrevious={handlePrev}
                        onNext={handleNext}
                      />
                    </div>
                  </Box>
                ) : (
                  <Box>
                    <Card>{EmptyProductList}</Card>
                  </Box>
                )}
              </BlockStack>
            </Layout.Section>
          </Layout>
        </Page>
      )}
      {initPageData.pageLoading && <ChangeLoading />}
    </div>
  );
};

export default withRouter(Index);
