import React, { memo } from "react";
import { Modal} from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";
const NotSourcingCompleteModal = memo(({ open, setOrderPaymentData,setPaymentData }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={() =>
          setOrderPaymentData((prevValue) => ({
            ...prevValue,
            isOneNotSourcing: false,
          }))
        }
        title={intl.get("Order not sourcing complete")}
        primaryAction={{
          content: intl.get("Continue to pay"),
          onAction: () => {
            setOrderPaymentData((prevValue) => ({
              ...prevValue,
              isOneNotSourcing: false,
              paymentModalActive: false,
            }))
            setPaymentData((prevValue) => ({
              ...prevValue,
              isShowBulkPaymentModal: true,
            }))
          }
        }}
        secondaryActions={[
          {
            content: intl.get("Cancel"),
            onAction: () =>
              setOrderPaymentData((prevValue) => ({
                ...prevValue,
                isOneNotSourcing: false,
              })),
          },
        ]}
      >
        <Modal.Section>
           
            <p>
             {intl.get("Some of the skus in the order are in the process of sourcing, we suggest you wait until the sourcing is complete before paying. Or you can continue to pay and we will automatically split the order into 2 orders and pay for the sourced sku first.")}
            </p>
           
        </Modal.Section>
      </Modal>
    </div>
  );
});

export default NotSourcingCompleteModal;
