import React, { Component } from "react";
import {
  TopBar,
  Card,
  ActionList,
  Navigation,
  AppProvider,
  Frame,
  Icon,
  Toast,
  Box,
  Button,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import "./index.css";
import { withRouter } from "react-router-dom";
import {
  // HomeMajor,
  // OrderIcon,
  // SettingsMajor,
  // ProductIcon,
  // ProductListIcon,
  // NotificationIcon,
  // AnalyticsMajor,
  // ShipmentMajor,
  // EyeglassesIcon,
  // InventoryIcon,
  // SearchIcon,
  // ChatMajor,
  // CashDollarMajor,
  // PackageIcon,
  // WalletIcon,
  AppExtensionIcon,
  CaretDownIcon,
  GlobeIcon,
  // ProfileMinor,
} from "@shopify/polaris-icons";

import AppBridge from "../../../utils/appBridge";

import translations from "@shopify/polaris/locales/en.json";

// import ProductsClass from '../Products/ProductsClass'
// import ImportLIstClass from "../ImportList/components/ImportListClass"; // 2023.3 产品页面改版
import ProductSelectionClass from "../ProductSelection/ProductSelectionClass";
import ProductDetailClass from "../ProductSelection/Details/DetailsClass";
import SourcingDetailClass from "../ProductSelection/SourcingDetail/SourcingDetailClass";
import ProductManagementClass from "../ProductManagement/ProductManagementClass";
import VariantDetailClass from "../ProductManagement/ProductDetail/VariantDetailClass";
import SearchImageClass from "../FindProducts/SearchImage/SearchImage";
// import FindTrendyProductDetailClass from "../FindProducts/TrendyProductDetail/ProductDetail";
import FreightCalculationClass from "../FreightCalculation/FreightCalculation";
// import ShopifyProductsClass from "../ShopifyProducts/ShopifyProductClass";
// import MappingClass from '../Products/Mapping/MappingClass'
// import ProductReplaceSourceClass from '../Products/ProductReplaceSource/ProductReplaceSourceClass'
import DashboardClass from "../Dashboard/components/DashboardClass";
// import OrdersClass from '../Orders/components/OrdersClass'
// import DropshipmanOrdersClass from '../DropshipmanOrders/components/DropshipmanOrdersClass'
// import NoSourceOrdersClass from '../NoSourceOrders/components/NoSourceOrdersClass'
// import ShippingDifferenceClass from "../ShippingDifference/components/ShippingDifferenceClass";
// import DropshipmanOrdersTracking from "../DropshipmanOrdersTracking/components/DropshipmanOrdersTracking";
import ShoppingCartClass from "../ShoppingCart/components/ShoppingCartClass";
import NotificationsClass from "../Notifications/components/NotificationsClass";
// import PrintOnDemandClass from "../PrintOnDemand/components/PrintOnDemandClass";
import LandingPageClass from "../LandingPageBuilder/components/LandingPageClass";
// import GlassesStoreClass from "../GlassesStore/components/GlassesStoreClass";
import CustomServiceClass from "../CustomService/components/CustomServiceClass"; //一阶段
import PartnerShipClass from "../PartnerShip/components/PartnerShipClass"; //一阶段
// import SiteOptimizationClass from '../SiteOptimization/components/SiteOptimizationClass';//一阶段
// import WebsiteSpeedClass from "../WebsiteSpeed/components/GlassesStoreClass";

// import AnalysisClass from '../Analysis/components/AnalysisClass'
import SettingProfileClass from "../Settings/Profile/ProfileClass";
import SettingBillingClass from "../Settings/Billing/BillingClass";
import SettingLanguageClass from "../Settings/Language/LanguageClass";
import SettingNotificationClass from "../Settings/Notification/NotificationClass";
import SettingImportingRulesClass from "../Settings/ImportingRules/ImportingRulesClass";
import SettingPricingRulesClass from "../Settings/PricingRules/PricingRulesClass";
// import SettingShippingClass from '../Settings/Shipping/ShippingClass'
import SettingWalletClass from "../Settings/Wallet/WalletClass";
import OtherConfigClass from "../Settings/OtherConfig/OtherConfigClass";
// import AliExpressAffiliateClass from '../Settings/AliExpressAffiliate/AliExpressAffiliateClass'
import AddressManagementClass from "../Settings/AddressManagement/AddressManagementClass";
// import ContactWayClass from "../Settings/ContactWay/ContactWayClass";
// import OrderPaymentClass from '../Settings/OrderPayment/OrderPaymentClass'
import OrderPaymentClass from "../Settings/OrderPayment/OrderPayment";

import AuthorizationUpdate from "../../../components/AuthorizationUpdate";
import NeedUpdateInfo from "../../../components/NeedUpdateInfo";
// import NoviceGuidance from "../../../components/NoviceGuidance";
// import NoviceModal from "../../../components/NoviceGuidance/NoviceModal";

// import SourcingSquareClass from "../Sourcing/SourcingSquare/components/SourcingSquareClass";

import CustomPackagingClass from "../CustomPackaging/components/CustomPackagingClass";
import PackagingDetailClass from "../CustomPackaging/components/PackageDetail/PackageDetail";
import RedeemProductsClass from "../RedeemProducts/components/RedeemProductsClass";
import RedeemProductsDetailClass from "../RedeemProducts/components/RedeemProductsDetail/RedeemDetail";
import InventoryClass from "../Inventory/components/InventoryClass";
import PricingClass from "../Pricing/components/PricingClass";
import MessagesClass from "../Messages/components/MessagesClass";
import RecommendedAppsClass from "../RecommendedApps/RecommendedAppsClass";
import SheetContactUs from "../../../components/ContactUsSheetExample/SheetExample";
import ShoppingCartButton from "../../../components/ShoppingCartButton";
// import CouponButton from "../../../components/CouponButton";
// import LoloyalButton from "../../../components/LoloyalButton";
// import WalletBalanceButton from "../../../components/WalletBalanceButton";
import CouponList from "../Coupon/components/CouponList";
import PurchaseClass from "../Purchase/PurchaseClass";
import InventoryPurchaseClass from "../InventoryPurchase/PurchaseClass";
import HistoryLogClass from "../HistoryLog/HistoryLogClass";
import PointsPageClass from "../PointsPage/PointsPageClass";
import PointsLogClass from "../PointsLog/PointsLogClass";
import PointsCouponClass from "../PointsCoupon/PointsCouponClass";
import PointsPurchaseClass from "../PointsPurchase/PointsPurchaseClass";
import PointsBlackPurchaseClass from "../PointsBlackPurchase/PointsPurchaseClass";
import PointsReferralsLogClass from "../PointsReferralsLog/PointsReferralsLogClass";

import InventoryHistoryLogClass from "../InventoryHistoryLog/HistoryLogClass";
import PartnersClass from "../Partners/components/PartnersClass";
import AffiliateClass from "../AffiliatePage/components/AffiliateClass";
// import FeatureRequestsClass from "../FeatureRequests/components/FeatureRequestsClass";
import intro from "intro.js";
import "intro.js/minified/introjs.min.css";

import intl from "../../../i18n/intl";
import utils from "../../../utils/utils";
import { changeLanguageData, changePersonLevel } from "../../../slice";
import { store } from "../../../store";
// import DesignIcon from '../../../assets/DesignIcon'
// import PartnerIcon from '../../../assets/PartnerIcon'
// import ExpertIcon from '../../../assets/ExpertIcon'
import Pop from "../../../PopMessagesModal";
import "../../Admin/Products/index.css";
import "../../Admin/Products/components/ProductInfo/index.css";
import "../../Admin/Inventory/components/AddInventoryModal/index";
import {
  reqGetCurrentStoreInfo,
  reqGetSideBarCount,
  reqGoogleAnalytics,
  reqPostBaseAction,
  reqPostDsmPopup,
  reqPostSwitchVersionOrDomain,
} from "../../../api";
// import { withTranslation } from "react-i18next";

// 新用户客户指引
// import OnBoarding from "../../../components/OnBoarding";
// import AllotSaleModal from '../../../components/AllotSaleModal'
import PremuimPlanModal from "../SubscriptionPaid/components/PremuimPlanModal"
import { connect } from "react-redux";
import ProductsImport from "../Analysis/ProductsImport/ProductsImport";
import ChatOnlinePlatformInit from "../../../components/ChatOnlinePlatformInit";
import RetentionPlanModal from "../../../components/RetentionPlanModal";
// import PermissionUpgrade from "../../../components/PermissionUpgrade";
import OrderListClass from "../OrderList/components/OrderListClass";
import SubscriptionPaidClass from "../SubscriptionPaid/components/SubscriptionPaidClass";
import UpgradePlanModal from "../SubscriptionPaid/components/UpgradePlanModal";
import CommonModal from "../../../components/CommonModal";
import HomeMajor from "../SubscriptionPaid/components/icons/HomeIcon";
import SearchIcon from "../SubscriptionPaid/components/icons/SearchProductIcon";
import ProductListIcon from "../SubscriptionPaid/components/icons/ImportListIcon";
// import ProductIcon from "../SubscriptionPaid/components/icons/StoreProductIcon";
import OrderIcon from "../SubscriptionPaid/components/icons/OrderListIcon";
// import ShipmentMajor from "../SubscriptionPaid/components/icons/OrderTrackingIcon";
import PackageIcon from "../SubscriptionPaid/components/icons/BrandingIcon";
import InventoryIcon from "../SubscriptionPaid/components/icons/PrivateStorageIcon";
// import DesignIcon from "../SubscriptionPaid/components/icons/PrintOnDemandIcon";
import LandPageIcon from "../SubscriptionPaid/components/icons/LandPageIcon";
// import ExpertIcon from "../SubscriptionPaid/components/icons/DropshipmanExpertsIcon";
import PartnerIcon from "../SubscriptionPaid/components/icons/PartnersIcon";
// import ChatMajor from "../SubscriptionPaid/components/icons/GuideIcon";
import SettingsMajor from "../SubscriptionPaid/components/icons/SettingsIcon";
import NotifyIcon from "../SubscriptionPaid/components/icons/NotifyIcon";
import UserIcon from "../SubscriptionPaid/components/icons/UserIcon";
import TopMaoIcon from "../SubscriptionPaid/components/icons/TopMaoIcon";
import HelpIcon from "../SubscriptionPaid/components/icons/HelpIcon";
import OutLogIcon from "../SubscriptionPaid/components/icons/OutLogIcon";
// import ScreenIcon from "../SubscriptionPaid/components/icons/ScreenIcon";
import FenxiaoIcon from "../SubscriptionPaid/components/icons/FenxiaoIcon";
// import DomainIcon from "../SubscriptionPaid/components/icons/DomainIcon";
import UpgradeWhiteModal from "../SubscriptionPaid/components/UpgradeWhiteModal";
import BlackRaffleShowModal from "../BlackFriday/components/BlackRaffleShowModal";
import BlackDiscountModal from "../BlackFriday/components/BlackDiscountModal"
import BlackDiscountNineModal from "../BlackFriday/components/BlackDiscountNineModal"
import BlackFeedbackModal from "../BlackFriday/components/BlackFeedbackModal"
import HomeLoading from "../HomeLoading";
import AdditionalBenefitsModal from "../SubscriptionPaid/components/AdditionalBenefitsModal";
import BannerTip from "../../../components/BannerTip";
import { DSM_WEBSITE_URL } from "../../../utils/constants";
import BlackFridayClass from "../BlackFriday/components/BlackFridayClass"
import SidebarGuidanceSheet from "../Dashboard/components/SidebarGuidanceSheet"
// import TopDrawerItem from "../BlackFriday/components/TopDrawerItem";
// import BlackGoButton from "../BlackFriday/components/BlackGoButton";
// import BlackGoModal from "../BlackFriday/components/BlackGoModal";
class FrameClass extends Component {
  state = {
    //选择页面
    pageName: this.props.name,
    loadingSildeData: true,

    productCount: "",
    ordersCount: "",
    aliOrderCount: "",
    dsmOrderCount: "",
    // noSourceOrderCount: '',
    shippingDifferenceCount: "",
    messageCount: 0,
    userDomain: "",
    shopOwer: "Admin",
    shopOwerNamePrefix: "A",
    isAuthToAliExpress: false,
    aliExpressUserNick: "",
    authAliExpressModalActive: false,

    mobileNavigationActive: false,
    isSecondaryMenuOpen: false,
    isSecondaryLanaguageOpen: false,

    needSetPasswordActive: false,
    appAccount: "",

    selectedStore: "",
    userStores: [],

    //语言
    defaultLanguage: "English",
    options: [
      { label: "English", value: "en" },
      { label: "Français", value: "fr" },
      { label: "Deutsch", value: "de" },
      { label: "Español", value: "es" },
      { label: "Nederland", value: "nl" },
      { label: "Italiano", value: "it" },
      { label: "Portugal", value: "pt-pt" },
      { label: "Portugal(Brasil)", value: "pt-br" },
    ],

    isHasPopReminder: false,
    cartNumber: 0, // 购物车sku数量

    onBoardingStatus: false,
    showUserGuide: false,
    stepOne_UserProductPreferences: false, // 步骤一：记录用户感兴趣产品分类
    // allotSaleModalVisible: 0,     //是否显示随机分配业务员弹窗
    allotSaleModalVisible: false,

    loadingVersion: false, // 切换店铺或者跳转到站外

    chromePlugins: {}, // 谷歌插件检测

    shopify_permission_upgrade: false, // Shopify接口权限升级
    upgradeModal: false, //升级会员限制
    isGrade: 0, //当前会员等级
    bili_grade:0, //套餐等级
    platformInit: "",
    isWhitePop: false, //白名单用户福利弹窗
    additionalPop: false, //增值弹窗
    noviceGuide: {}, //新手引导步骤数据
    onBoarding: false, //是否用户 开启新手引导
    noviceCategory: [], //偏好分类
    noviceFinsh: false, //弹窗
    is_affiliate: false,
    isNewTypeUser: false, //订阅活动折扣弹窗
    user_discounts: {
      event_active_time: "",
      event_discounts: 0.8,
      re_time: 0,
      event_type: 0,
      use_days:''
    },
    extraServices: true, //是否折叠
    noviceNum: 1,
    topDrawer: false, //抽屉展开
    suspension: false, //悬浮
    blackGoModal: false, //黑五弹窗
    customer_service: {
      branding: 0,
      private: 0,
    },
    four_pop: false, //四周年弹窗
    premium_pop:false,//升级高级会员成功弹窗
    black_friday_feed:{
      show1:false,
      show2:false,
      open:false
    },
    new_person_side: false,
    new_person_step:'',
    new_person_data:[],
    retentionPlanData:{}
  };
 

  //进入页面调用接口
  componentDidMount() {
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
    let upgrade_now = false;
    let _store = store.getState().languageReducer;
    let setLanguage = window.language;
    if (setLanguage) {
      let langeuageData = this.state.options;
      for (let index = 0; index < langeuageData.length; index++) {
        if (langeuageData[index].value === setLanguage) {
          this.setState({
            defaultLanguage: langeuageData[index].label,
          });
        }
      }
    }
    if (upgrade_now) {
      // 重定向到升级公告页面
      utils.routeJump(this, "/upgrade/notify");
      utils.routeListen("/upgrade/notify");
    } else {
      if (!_store.status || window.location.pathname === "/admin/dashboard") {
        setTimeout(() => {
          this.getPageData();
        }, 0);
        this.refreshFrame(false);
      } else {
        this.getPageDataCache(_store);
        this.refreshFrame(false);
      }
    }
  }
  // logDelta({name, id, delta}) {
  //   // console.log(`${name} matching ID ${id} changed by ${delta}`);
  //   console.log(`${delta}`);
  // }
  // 组件渲染完成，再次修改渲染内容
  UNSAFE_componentWillReceiveProps(nextProps) {
    store.subscribe(() => {
      let _store = store.getState().languageReducer.subScriptData;
      this.setState({
        bili_grade: _store.level,
      });
    });
  }

  // 刷新组件
  refreshFrame = (setLanguage = true) => {
    // console.info('S_SHOP_OWNER', window.S_SHOP_OWNER);
    // console.info('S_SHOP_DOMAIN', window.S_SHOP_DOMAIN);
    // console.info('S_USER_TOKEN', window.S_USER_TOKEN);
    // console.info('S_EMAIL', window.S_EMAIL);

    let owerName = utils.getStore("S_SHOP_OWNER") || window.S_SHOP_OWNER;
    let owerNamePrefix = "";
    if (owerName) {
      let nameArray = owerName.split(" ");
      for (let i = 0; i < nameArray.length; i++) {
        owerNamePrefix += nameArray[i].charAt(0);
      }
    } else {
      owerName = "Admin";
      owerNamePrefix = "A";
    }

    // 当前店铺
    let currentStore = utils.getStore("S_SHOP_DOMAIN") || window.S_SHOP_DOMAIN;
    let storeName = "Store-admin";
    if (currentStore) {
      storeName = currentStore.replace(".myshopify.com", "", currentStore);
      storeName = storeName.replace(
        storeName[0],
        storeName[0].toUpperCase(),
        storeName
      );
      let TK_Store = utils.getStore("S_STORE_NAME") || window.S_STORE_NAME;
      storeName = TK_Store ? TK_Store : storeName;
    }

    this.setState(
      {
        userDomain: currentStore,
        selectedStore: storeName,
        shopOwer: owerName,
        shopOwerNamePrefix: owerNamePrefix,
      },
      () => {
        if (setLanguage) {
          this.handleCallBackSetlanguage();
        }
      }
    );
  };

  // 保存语言回调
  handleCallBackSetlanguage = () => {
    // 默认语言
    let defaultLanguage = "English";
    let setLanguage = window.language;
    if (setLanguage) {
      let langeuageData = this.state.options;
      for (let index = 0; index < langeuageData.length; index++) {
        if (langeuageData[index].value === setLanguage) {
          defaultLanguage = langeuageData[index].label;
        }
      }
    }

    // 更换刷新下语言包组件 redux
    this.props.dispatch(changeLanguageData({ language: setLanguage }, true));

    this.setState({
      defaultLanguage: defaultLanguage,
    });
  };

  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    }));
  };

  getPageDataCache = (person) => {
    let extra_service = window?.E_XTRA;
    if (extra_service === "undefined" && extra_service === "null") {
      extra_service = this.state.extraServices;
    }
    let top_drawer = window.TOP_DRAWER; //抽屉
    let suspension = window.SUSPENSION; //悬浮按钮
    if (top_drawer === "undefined" && top_drawer === "null") {
      top_drawer = this.state.topDrawer;
    }
    if (suspension === "undefined" && suspension === "null") {
      suspension = this.state.suspension;
    }
    if (Object.values(person?.sidebarCountData).length > 0) {
      this.setState({
        isWhitePop: false,
        additionalPop: false,
        isNewTypeUser: false,
        extraServices: extra_service,
        topDrawer: top_drawer,
        suspension: suspension,
      });
      const { state } = this.props?.location;
      this.setState({
        isGrade: person?.subScriptData?.level,
        bili_grade: person?.subScriptData?.level,
      });
      var getData = person?.sidebarCountData;
      window.E_XTRA = extra_service;
      window.S_EMAIL = getData.email;
      window.REVIEWS = getData.review; // 是否在Shopify app市场评论标识 0未评论 1已评论
      window.TOP_DRAWER = top_drawer;
      window.SUSPENSION = suspension;
      // 是否需要Shopify升级权限
      let shopify_permission_upgrade = false;
      // 是否需要更新密码
      let need_change_password = getData.is_change === 0 ? true : false;
      // 客户引导
      let on_boarding_status = getData.onBoarding ? true : false;
      // let show_user_guide = getData.onBoarding ? true : false;
      let stepOne_UserProductPreferences = getData.preference ? false : true;
      // let on_boarding_status = true;
      let show_user_guide = false;
      // 显示自动绑定的销售信息
      let allotSaleModalVisible = false;
      // 通知信息
      let isHasPopReminder = false;
      if (shopify_permission_upgrade) {
        need_change_password = false;
        on_boarding_status = false;
        show_user_guide = false;
        stepOne_UserProductPreferences = false;
        allotSaleModalVisible = false;
        isHasPopReminder = false;
      }
      this.setState({
        loadingSildeData: false,
        productCount: getData.productCount,
        ordersCount: getData.ordersCount,
        // aliOrderCount: getData.aliCount,
        dsmOrderCount: getData.dsmCount,
        // noSourceOrderCount: getData.unknowOrderCount,
        shippingDifferenceCount: getData.postageOrderCount,
        pricingClick: false,
        messageCount: getData.messageCount,
        isAuthToAliExpress: getData.isAuthToAliExpress === 1 ? true : false, // 1 已授权(正常)
        aliExpressUserNick: getData.aliExpressUserNick,
        appAccount: getData.account,
        needSetPasswordActive: need_change_password,
        userStores: getData.stores,
        isHasPopReminder: isHasPopReminder,
        // cartNumber: getData.cartNumber,
        onBoardingStatus: on_boarding_status,
        showUserGuide: show_user_guide,
        stepOne_UserProductPreferences: stepOne_UserProductPreferences,
        allotSaleModalVisible: allotSaleModalVisible,
        chromePlugins: getData.chrome_plugins,
        shopify_permission_upgrade: shopify_permission_upgrade,
      });
      if (
        state?.homePageFlag &&
        localStorage.getItem("homeViewAuthorizationPageFlag") === "1"
      ) {
        document.getElementById("DropShipManProfile").scrollIntoView(false);
        localStorage.setItem("homeViewAuthorizationPageFlag", "2");
      }
    }
  };
  // 获取弹窗数据
  getDsmPupop = async () => {
    let res = await reqPostDsmPopup();
    if (res.code === "200") {
      let getData = res.data;
      if (!getData?.is_store_pop) {
        return;
      }
      this.setState({
        user_discounts: getData?.user_discounts,
        is_affiliate: getData?.is_affiliate,
        retentionPlanData:getData?.new_pops,
        affiliate_banner: !getData.affiliate_register, // 订阅折扣活动弹窗
        isWhitePop: getData?.is_drainage_pop, //白名单用户福利弹窗
        additionalPop: getData?.is_first_subscribe_pop, //增值福利弹窗
        isHasPopReminder: getData.pop, //站内信弹窗
        // suspension: getData.all_discounts.black_box, // 悬浮按钮
        blackGoModal: getData.all_discounts.black_pop, // 黑五弹窗
        four_pop: getData?.all_discounts.four_pop,
        premium_pop:getData?.is_senior_uid_pop,
        black_friday_feed:{
          show1:getData.all_discounts.black_first_pop,
          show2:getData.all_discounts.black_second_pop,
          open:false
        }
      });
      // window.SUSPENSION = getData.all_discounts.black_box;
      // window.TOP_DRAWER = false;
      // if (true) {
      // 每次进入APP页面 5分钟后显示
      if (getData.all_discounts.black_top) {
        setTimeout(() => {
          this.setState({
            topDrawer: getData.all_discounts.black_top, // 顶部抽屉
            // topDrawer:true, // 顶部抽屉
          });
          window.TOP_DRAWER = getData.all_discounts.black_top;
        }, 300000);
      } else {
        window.TOP_DRAWER = getData.all_discounts.black_top;
      }
      // store.subscribe(() => {
      let person = store.getState().languageReducer.subScriptData;
      this.setState({
        bili_grade: person.level,
      });
      let nowTime = new Date().getTime();
      if (
        person?.level <= 0 &&
        getData.user_discounts?.event_active_pop &&
        nowTime < getData.user_discounts?.event_active_time * 1000
      ) {
        this.setState({ isNewTypeUser: true });
      }
      // });
    }
  };

  // 记录日常操作数据
  handleBaseAction = async (type = "") => {
    const params = {
      type: type,
    };
    await reqPostBaseAction(params);
  };

  // 获取公共数据
  getPageData = async () => {
    let _store = store.getState().languageReducer;
    this.getPageDataCache(_store);
    const params = {};
    this.setState({
      platformInit: <ChatOnlinePlatformInit refresh={this.refreshFrame} />,
    });
    const res = await reqGetCurrentStoreInfo(params);

    if (res.code === "200") {
      this.props.dispatch(changePersonLevel({ infoData: res.data }, true));
    }
    const response = await reqGetSideBarCount(params);

    const { state } = this.props?.location;
    if (response.code === "200") {
      // console.log(store.getState().languageReducer);
      window.hideLoadingState()
      var getData = response.data;

      // if (getData.onBoarding === 0) {
      this.getDsmPupop();
      // }
      // let user_guide = {
      //         "step": 1,
      //         "step_info": {
      //             "1": 0,
      //             "2": 0,
      //             "3": 0,
      //             "4": 0,
      //             "5": 0
      //         }
      //       }
      this.setState({
        onBoarding: getData.onBoarding === 1 ? true : false,
        noviceGuide: getData.user_guide,
        noviceCategory: getData.category,
        extraServices: getData.onBoarding === 1 ? true : false,
      });
      // if (getData?.is_first_subscribe_pop) {
      //   this.setState({
      //     additionalPop: true,
      //   });
      // }
      // if (getData?.is_drainage_pop) {
      //   this.setState({
      //     isWhitePop: true,
      //   });
      // }
      store.subscribe(() => {
        let person = store.getState().languageReducer.subScriptData;
        if (person) {
          this.setState({
            isGrade: person?.level,
            bili_grade: person?.level,
          });
        }
      });
      this.props.dispatch(changePersonLevel({ slideData: getData }, true));
      // utils.setStore('S_EMAIL',getData.email);
      // utils.setStore('stores',getData.stores);
      window.E_XTRA = getData.onBoarding ? true : false;
      window.S_EMAIL = getData.email;
      window.REVIEWS = getData.review; // 是否在Shopify app市场评论标识 0未评论 1已评论
      // 是否需要Shopify升级权限
      let shopify_permission_upgrade =
        getData.permission_upgrade === 1 ? false : true;
      // 是否需要更新密码
      let need_change_password = getData.is_change === 0 ? true : false;
      // 客户引导
      let on_boarding_status = getData.onBoarding ? true : false;
      let show_user_guide = getData.onBoarding ? true : false;
      let stepOne_UserProductPreferences = getData.preference ? false : true;
      // 显示自动绑定的销售信息
      let allotSaleModalVisible =
        getData.allotSaleModalVisible === 0 ? false : true;

      if (shopify_permission_upgrade) {
        need_change_password = false;
        on_boarding_status = false;
        show_user_guide = false;
        stepOne_UserProductPreferences = false;
        allotSaleModalVisible = false;
      }
      window.CART_NUMBER = getData.cartNumber;
      this.setState({
        loadingSildeData: false,
        productCount: getData.productCount,
        ordersCount: getData.ordersCount,
        // aliOrderCount: getData.aliCount,
        dsmOrderCount: getData.dsmCount,
        // noSourceOrderCount: getData.unknowOrderCount,
        shippingDifferenceCount: getData.postageOrderCount,
        pricingClick: false,
        messageCount: getData.messageCount,
        isAuthToAliExpress: getData.isAuthToAliExpress === 1 ? true : false, // 1 已授权(正常)
        aliExpressUserNick: getData.aliExpressUserNick,
        appAccount: getData.account,
        needSetPasswordActive: need_change_password,
        userStores: getData.stores,
        cartNumber: getData.cartNumber,
        onBoardingStatus: on_boarding_status,
        showUserGuide: show_user_guide,
        stepOne_UserProductPreferences: stepOne_UserProductPreferences,
        allotSaleModalVisible: allotSaleModalVisible,
        chromePlugins: getData.chrome_plugins,
        shopify_permission_upgrade: shopify_permission_upgrade,
      });

      if (
        state?.homePageFlag &&
        localStorage.getItem("homeViewAuthorizationPageFlag") === "1"
      ) {
        document.getElementById("DropShipManProfile").scrollIntoView(false);
        localStorage.setItem("homeViewAuthorizationPageFlag", "2");
      }
    } else {
      if (response.code === "40400") {
        /**
         * 外嵌模式下，授权失败直接跳转登录页面
         */
        if (!AppBridge.exist()) {
          utils.removeStore("S_AUTHORIZATION");
          utils.setStore("S_SHOP_DOMAIN", "");
          utils.setStore("S_USER_TOKEN", "");
          utils.setStore("S_STORE_NAME", "");
          utils.setStore("S_SHOP_OWNER", "");
          this.props.history.push("/login");
        }
      } else if (response.code === "40401") {
        window.location.href = window.location.origin + '/tiktok/dashboard'
      }
      this.setState({ loadingSildeData: false });
    }
  };

  /**
   * (1) Shopify站内跳转站外访问
   * (2) app内多店铺切换
   *
   * @param {string} selectDomain 切换店铺选择的域名(***.myshopify.com)
   */
  handleSwitchVersionOrDomain = async (selectDomain = "") => {
    const params = {};
    if (selectDomain) {
      params.selected = selectDomain;
    }

    this.setState({ loadingVersion: true });
    const response = await reqPostSwitchVersionOrDomain(params);
    this.setState({ loadingVersion: false });

    if (response.code === "200") {
      if (response.data) {
        try {
          if (selectDomain) {
            window.location.href = response.data;
            return;
          }

          const w = window.open("about:blank");
          w.location.href = response.data;
        } catch (error) {
          this.toggleToast("Switch authorization error");
          console.error("error:", error);
        }
      } else {
        this.toggleToast("Switch authorization error");
      }
    } else {
      this.toggleToast(response.msg);
    }
  };

  // [回调]更新购物车数量
  handleCallBackModifyCartNumber = (data) => {
    window.CART_NUMBER = data.number;
    this.setState({ cartNumber: data.number });
  };

  setMobileNavigationActive = () => {
    this.setState(({ mobileNavigationActive }) => ({
      mobileNavigationActive: !mobileNavigationActive,
    }));
  };

  toggleMobileNavigationActive = () => {
    this.setMobileNavigationActive();
  };

  toggleIsUserMenuOpen = () => {
    this.setState(({ isUserMenuOpen }) => ({
      isUserMenuOpen: !isUserMenuOpen,
    }));
  };

  toggleIsSecondaryMenuOpen = () => {
    this.setState(({ isSecondaryMenuOpen }) => ({
      isSecondaryMenuOpen: !isSecondaryMenuOpen,
    }));
  };

  toggleIsSecondaryLanaguageOpen = () => {
    this.setState(({ isSecondaryLanaguageOpen }) => ({
      isSecondaryLanaguageOpen: !isSecondaryLanaguageOpen,
    }));
  };

  // 速卖通授权
  handleAuthModalChange = () => {
    if (this.state.isAuthToAliExpress) {
      return false;
    }
    this.setState({
      authAliExpressModalActive: true,
    });
  };

  // 回调关闭弹窗
  callBackHandleAuthModalClosed = (data) => {
    if (data.type === "updateInfo") {
      this.setState({ needSetPasswordActive: false });
    } else {
      this.setState({
        authAliExpressModalActive: false,
      });
    }
  };

  // Log out
  handleLogout = () => {
    utils.removeStore("S_AUTHORIZATION");
    utils.setStore("S_SHOP_DOMAIN", "");
    utils.setStore("S_USER_TOKEN", "");
    utils.setStore("S_STORE_NAME", "");
    utils.setStore("S_SHOP_OWNER", "");

    // window.location.href = "/login";

    setTimeout(() => {
      window.location.href = DSM_WEBSITE_URL + "login";
    }, 1500);
  };

  // 多店铺切换
  handleStoreChange = (id, name, store_name) => {
    // 存储当前用户信息，为了兼容谷歌插件，不然拿不到用户的信息
    utils.setStore("S_SHOP_DOMAIN", name);
    utils.setStore("S_USER_TOKEN", id);
    utils.setStore("S_STORE_NAME", store_name);

    window.S_SHOP_DOMAIN = name;
    window.S_USER_TOKEN = id;
    window.S_STORE_NAME = store_name;

    // 获取重新授权链接
    this.handleSwitchVersionOrDomain(name);
  };

  handleNovice = (val) => {
    this.setState({
      noviceNum: val,
      noviceFinsh: true,
    });
  };
  // 激活用户指引弹窗
  openUserGuide = () => {
    //Guide菜单点击gtag
    reqGoogleAnalytics("click", "guide_click");
    intro()
      .setOptions({
        steps: [
          {
            title: "(1/4) Find and list prodcuts ",
            element: document.querySelector(
              `.Polaris-Navigation__PrimaryNavigation .Polaris-Navigation__Section:nth-child(2)`
            ),
            intro:
              "Here you can search for the products you want, make product sourcing requests, and list items to your Shopify store.",
          },
          {
            title: "(2/4) Oder fulfillment",
            element: document.querySelector(
              `.Polaris-Navigation__PrimaryNavigation .Polaris-Navigation__Section:nth-child(3)`
            ),
            intro: "You can check your orders status and make payments here.",
          },
          {
            title: "(3/4) Customized services",
            element: document.querySelector(`.extra_service_content > ul`),
            intro:
              "Here you can customize your own brand packaging, gifts, labeling, and utilize private warehousing services.",
          },
          {
            title: "(4/4) Credit, coupons, cart",
            element: document.querySelector(`.dsm_info_tip`),
            intro:
              "Here you can manage your credit, coupons, and shopping cart.",
          },
        ],
        nextLabel: "Next",
        skipLabel: "Skip",
        doneLabel: "Got it",
        showBullets: false,
        exitOnOverlayClick: false,
      })
      .oncomplete(() => {
        this.setState({ extraServices: false });
        this.getDsmPupop();
      })
      .onexit(() => {
        this.setState({ extraServices: false });
        this.getDsmPupop();
      })
      .start();

    // this.setState({ showUserGuide: true })
  };

  // 关闭用户指引弹窗
  closeOnBoarding = () => {
    this.setState({
      showUserGuide: false,
      stepOne_UserProductPreferences: false,
    });
  };
  handleOffExtra = (e) => {
    e.stopPropagation();
    this.setState({ extraServices: false });
    window.E_XTRA = false;
  };
  handleOpenExtra = (e) => {
    e.stopPropagation();
    this.setState({ extraServices: true });
    window.E_XTRA = true;
  };
  // 关闭用户选择产品分类偏好弹窗
  closeUserProductPreferences = () => {
    this.setState({ stepOne_UserProductPreferences: false });
  };

  // 选中切换语言
  handleChangeLanugae = (ctx, value) => {
    this.props.dispatch(changeLanguageData({ language: value }, true));
    this.setState({ defaultLanguage: ctx });
  };

  // 关闭Shopify升级弹窗
  closeShopifyPermissionUpgrade = () => {
    this.setState({ shopify_permission_upgrade: false });
  };

  // 物流追踪限制
  handleOrderTrackingUpgrade = () => {
    // this.props.history.push("/admin/orders_tracking");
    utils.routeJump(this, "/admin/order_list");
    utils.routeListen("/admin/order_list");
  };
  // Branding限制
  handleBrandingUpgrade = () => {
    // let person = store.getState().languageReducer.subScriptData;
    // if (person?.level > 0) {
    // this.props.history.push("/admin/customService/custom-packaging");
    utils.routeJump(this, "/admin/customService/custom-packaging");
    utils.routeListen("/admin/customService/custom-packaging");
    // } else {
    // this.setState({ upgradeModal: true });
    // }
  };
  handleTopDrawer = async () => {
    document
      .querySelector(".top_drawer_content")
      .classList.add("top_drawer_contents");
    const params = {
      type: "dsm_black_top",
    };
    setTimeout(() => {
      this.setState(
        {
          topDrawer: false,
        },
        () => {
          document.querySelector(".top_drawer_content") &&
            document
              .querySelector(".top_drawer_content")
              .classList.remove("top_drawer_contents");
        }
      );
    }, 450);
    await reqPostBaseAction(params);
    window.TOP_DRAWER = false;
  };
  handleSuspension = async (e) => {
    e.stopPropagation();
    this.setState({
      suspension: false,
    });
    const params = {
      type: "dsm_black_box",
    };
    await reqPostBaseAction(params);
    window.SUSPENSION = false;
  };
  handleBlackGoModal = () => {
    this.setState({
      blackGoModal: false,
    });
  };

  handleBlackFeedbackModal = (val) => {
    if (val === 1) {
      this.setState({
        black_friday_feed:{
          show1:false,
          show2:false,
          open:true
        }
      })
    }
    if (val === 2) {
      this.setState({
        black_friday_feed:{
          show1:false,
          show2:true,
          open:false
        }
      })
    }
    if (val === 3) {
      this.setState({
        black_friday_feed:{
          show1:false,
          show2:false,
          open:false
        }
      })
      let sendMsg = `I’m sorry, but I’m not satisfied with your features and services.`

      try {
        // 填充信息并激活对话弹窗
        // window.Intercom('showNewMessage', sendMsg)
        window.Willdesk.show();
        window.Willdesk.chat(sendMsg);
      } catch (error) {
        console.info(error)
      }
    }
    if (val === 4) {
      this.setState({
        black_friday_feed:{
          show1:false,
          show2:false,
          open:false
        }
      })
      this.toggleToast('Submitted successfully')
    }
    if (val === 5) {
      this.setState({
        black_friday_feed:{
          show1:false,
          show2:false,
          open:false
        }
      })
      utils.routeListen("/admin/subscribe");
      utils.routeJump(this, "/admin/subscribe");
    }
    if (val === 6) {
      this.setState({
        black_friday_feed:{
          show1:false,
          show2:false,
          open:false
        }
      })
      this.toggleToast('Submitted successfully')
    }
    if (val === 7) {
      this.setState({
        black_friday_feed:{
          show1:true,
          show2:false,
          open:false
        }
      })
    }
    if (val === 8) {
      this.setState({
        black_friday_feed:{
          show1:false,
          show2:false,
          open:false
        }
      })
    }
  };

  handleNewPersonSide = (val,step,list)=>{
    this.setState({
      new_person_side:val,
      new_person_step:step,
      new_person_data:list
    })
  }
  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={() => this.toggleToast("")}
        error={this.state.showToastIserror}
        duration={3000}
      />
    ) : null;
    const {
      pageName,
      mobileNavigationActive,
      ordersCount,
      isUserMenuOpen,
      userDomain,
      shopOwer,
      shopOwerNamePrefix,
      // aliOrderCount,
      // dsmOrderCount,
      // noSourceOrderCount,
      // shippingDifferenceCount,
      messageCount,
      isSecondaryLanaguageOpen,
      userStores,
      cartNumber,
      // showUserGuide,
      // stepOne_UserProductPreferences,
      // loadingSildeData,
      defaultLanguage,
      // allotSaleModalVisible,
      selectedStore,
      isSecondaryMenuOpen,
      loadingVersion,
      black_friday_feed
      // shopify_permission_upgrade,
      // onBoarding,
      // noviceGuide,
      // noviceCategory,
      // isAuthToAliExpress,aliExpressUserNick,
    } = this.state;
    var pageContent = "";
    var matchesDashboard = false;
    // var matchesProduct = false
    var matchesImportList = false;
    var matchesFindProducts = false;
    // var matchesShopifyProduct = false;
    // var matchesOrders = false
    // var matchesDropshipmanOrders = false
    var matchesOrderList = false;
    // var matchesNoSourceOrders = false
    // var matchesShippingDifference = false;
    // var matchesNotifications = false
    // var matchesPrintOnDemand = false;
    var matchesLandingPage = false;
    // var matchesWebsiteOptimization = false;
    // var matchesWebsiteSpeed = false;
    // var matchesCustomService = false;
    // var matchesPartnerShip = false;
    // var matchesWebsiteOptimization = false;
    // var matchesOrderTracking = false;
    // var matchesAnalysis = false
    var matchesSettings = false;
    // var matchesDropshipmanProduct = false
    var matchesCustomPackaging = false;
    // var matchesSourcing = false;
    var matchesInventory = false;
    // var matchesWallet = false
    var recommendedApps = false;
    var matchesPartners = false;
    var matchesAffiliate = false;
    // var matchesFeatureRequests = false;
    // var matchesPricing = false;
    // var matchesMessages = false;

    // 定义帮助文档链接
    let helpCenterText = intl.get("Dropshipman");
    let helpCenterLink = "https://www.dropshipman.com/blog";
    // 匹配相应的页面数据
    switch (pageName) {
      case "dashboard":
        matchesDashboard = true;
        pageContent = <DashboardClass blackGoModal={this.state.blackGoModal} blackData={this.state.black_friday_feed}/>;
        // helpCenterText = intl.get("Dropshipman");
        // helpCenterLink = "https://www.dropshipman.com/blog";
        helpCenterText = '';
        helpCenterLink = "";
        break;
        
      case "product-selection":
        matchesFindProducts = true;
        pageContent = (
          <ProductSelectionClass chromePlugins={this.state.chromePlugins} handleNewPersonSide={this.handleNewPersonSide}/>
        );
        helpCenterText = '';
        helpCenterLink = "";
        break;
      case "products_detail":
        matchesFindProducts = true;
        pageContent = (
          <ProductDetailClass
            callBackFun={this.handleCallBackModifyCartNumber}
          />
        );
        helpCenterText = '';
        helpCenterLink = "";
        break;
      case "sourcing_detail":
        matchesFindProducts = true;
        pageContent = (
          <SourcingDetailClass history={this.props.history}/>
        );
        helpCenterText = '';
        helpCenterLink = "";
        break;
      // case "find_products":
      //   matchesFindProducts = true;
      //   pageContent = (
      //     <FindProductsClass chromePlugins={this.state.chromePlugins} handleNewPersonSide={this.handleNewPersonSide}/>
      //   );
      //   helpCenterText = '';
      //   helpCenterLink = "";
      //   break;

      case "search_image":
        matchesFindProducts = true;
        pageContent = <SearchImageClass />;
        break;
      // case "find_products_detail":
      //   matchesFindProducts = true;
      //   pageContent = (
      //     <FindProductDetailClass
      //       callBackFun={this.handleCallBackModifyCartNumber}
      //     />
      //   );
      //   helpCenterText = '';
      //   helpCenterLink = "";
      //   break;
      // case "trendy-find_products_detail":
      //   matchesFindProducts = true;
      //   pageContent = (
      //     <FindTrendyProductDetailClass
      //       callBackFun={this.handleCallBackModifyCartNumber}
      //     />
      //   );
      //   helpCenterText = '';
      //   helpCenterLink = "";
      //   break;
      case "calculate_freight":
        pageContent = <FreightCalculationClass />;
        break;

      // case "recommended_category":
      //   matchesFindProducts = true;
      //   pageContent = <RecommendedCategoryClass type={this.props.type} />;
      //   break;
      // case "trendy-products":
      //   matchesFindProducts = true;
      //   pageContent = <TrendyProductsClass />;
      //   break;
      // case "new-products":
      //   matchesFindProducts = true;
      //   pageContent = <HolidayProductsClass />;
      //   break;

      // case 'products':
      //   matchesProduct = true
      //   pageContent = <ProductsClass />
      //   helpCenterText = intl.get('import products')
      //   helpCenterLink = 'https://www.dropshipman.com/blogs/blog/how-to-import-product-to-shopify'
      //   break

      
      case "product-management":
        matchesImportList = true;
        pageContent = (
          <ProductManagementClass callBackFun={this.handleCallBackModifyCartNumber} handleNewPersonSide={this.handleNewPersonSide} />
        );
          helpCenterText = '';
          helpCenterLink = "";
        break;
      case "variant-detail":
        matchesImportList = true;
        pageContent = (
          <VariantDetailClass/>
        );
        helpCenterText = '';
        helpCenterLink = "";
        break;
        
      // case "import_list":
      //   matchesImportList = true;
      //   pageContent = (
      //     <ImportLIstClass callBackFun={this.handleCallBackModifyCartNumber} handleNewPersonSide={this.handleNewPersonSide} />
      //   );
      //     helpCenterText = '';
      //     helpCenterLink = "";
      //   break;

      // case 'mapping':
      //   matchesProduct = true
      //   pageContent = <MappingClass shopifyProductId={this.props.shopifyProductId} />
      //   break

      // case 'change_supplier':
      //   matchesProduct = true
      //   pageContent = <ProductReplaceSourceClass shopifyProductId={this.props.shopifyProductId} />
      //   break

      // case "shopifyProducts":
      //   matchesShopifyProduct = true;
      //   pageContent = <ShopifyProductsClass />;
      //   helpCenterText = intl.get("linking products");
      //   helpCenterLink =
      //     "https://www.dropshipman.com/blogs/blog/how-to-migrate-from-oberlo-to-dropshipman#how_to_migrate";
      //   break;

      // case 'dropshipman_products':
      //   matchesDropshipmanProduct = true
      //   pageContent = <DropShipManProductsClass callBackFun={this.handleCallBackModifyCartNumber} />
      //   break

      // case "sourcing":
      //   matchesSourcing = true;
      //   pageContent = (
      //     <SourcingClass chromePlugins={this.state.chromePlugins} handleNewPersonSide={this.handleNewPersonSide}/>
      //   );
      //   // helpCenterText = intl.get("sourcing");
      //   // helpCenterLink =
      //   //   "https://www.dropshipman.com/blog/how-to-use-dropshipmans-sourcing-feature";
      //   helpCenterText = '';
      //   helpCenterLink = "";
      //   break;

      // case "sourcingsquare":
      //   pageContent = <SourcingSquareClass />;
      //   helpCenterText = intl.get("sourcing square");
      //   break;

      case "custom-service":
        // matchesCustomService = true;
        pageContent = <CustomServiceClass />;
        helpCenterText = "";
        helpCenterLink = "";
        break;
      case "partner-ship":
        // matchesPartnerShip = true;
        pageContent = <PartnerShipClass />;
        helpCenterText = "";
        helpCenterLink = "";
        break;
      // case "site-optimization":
      //   matchesWebsiteOptimization = true;
      //   pageContent = <SiteOptimizationClass />;
      //   helpCenterText = "";
      //   helpCenterLink = "";
      //   break;
      case "custom_packaging":
        matchesCustomPackaging = true;
        pageContent = <CustomPackagingClass />;
        break;

      case "custom_package_detail":
        matchesCustomPackaging = true;
        pageContent = (
          <PackagingDetailClass
            callBackFun={this.handleCallBackModifyCartNumber}
          />
        );
        break;
      case "redeem_products":
        pageContent = (
          <RedeemProductsClass />
        );
        break;
      case "redeem_detail":
        matchesCustomPackaging = true;
        pageContent = (
          <RedeemProductsDetailClass />
        );
        break;
      case "subscribe":
        pageContent = <SubscriptionPaidClass />;
        break;

      case "inventory":
        matchesInventory = true;
        pageContent = <InventoryClass history={this.props.history} />;
        helpCenterText = intl.get("inventory");
        helpCenterLink =
          "https://www.dropshipman.com/blog/how-to-wholesale-products-and-warehousing-with-dropshipman";
        break;

      //重构后的菜单重定向
      case "order_list":
        matchesOrderList = true;
        pageContent = <OrderListClass handleNewPersonSide={this.handleNewPersonSide}/>;
        // helpCenterText = intl.get("order list");
        helpCenterText = '';
        helpCenterLink = "";
        break;

      // case 'orders':
      //   matchesOrders = true
      //   pageContent = <OrdersClass />
      //   helpCenterText = intl.get('place orders')
      //   helpCenterLink = 'https://www.dropshipman.com/blogs/blog/how-to-place-orders-via-dropshipman'
      //   break

      // case 'dropshipman_orders':
      //   matchesDropshipmanOrders = true
      //   pageContent = <DropshipmanOrdersClass />
      //   break

      // case 'no_source_orders':
      //   matchesNoSourceOrders = true
      //   pageContent = <NoSourceOrdersClass />
      //   break

      // case "shipping_difference":
      //   matchesShippingDifference = true;
      //   pageContent = <ShippingDifferenceClass />;
      //   break;

      // case "dropshipman_tracking":
      //   matchesOrderTracking = true;
      //   pageContent = <DropshipmanOrdersTracking />;
      //   break;

      case "notifications":
        // matchesNotifications = true
        pageContent = <NotificationsClass />;
        helpCenterText = intl.get("notifications");
        helpCenterLink =
          "https://www.dropshipman.com/blog/how-the-notification-is-generated";
        break;
      case "Landing Page Builder":
        pageContent = <LandingPageClass />;
        helpCenterText = "";
        helpCenterLink = "";
        matchesLandingPage = true;
        break;
      // case "Print On Demand":
      //   pageContent = <PrintOnDemandClass />;
      //   helpCenterText = "";
      //   helpCenterLink = "";
      //   matchesPrintOnDemand = true;
      //   break;
      // case "glasses_store":
      //   matchesWebsiteOptimization = true;
      //   pageContent = <GlassesStoreClass />;
      //   helpCenterText = "";
      //   helpCenterLink = "";
      //   break;
      // case "page_speed":
      //   pageContent = <WebsiteSpeedClass />;
      //   helpCenterText = "";
      //   helpCenterLink = "";
      //   break;
      case 'black_friday':
        pageContent = <BlackFridayClass />;
        helpCenterText = '';
        helpCenterLink = '';
        break;
      // case 'analysis':
      //   matchesAnalysis = true
      //   pageContent = <AnalysisClass />
      //   break
      case "settings_billing":
        matchesSettings = true;
        pageContent = (
          <SettingBillingClass callBackFun={this.handleCallBackSetlanguage} />
        );
        helpCenterText = "";
        helpCenterLink = "";
        break;
      case "settings_language":
        matchesSettings = true;
        pageContent = (
          <SettingLanguageClass callBackFun={this.handleCallBackSetlanguage} />
        );
        helpCenterText = "";
        helpCenterLink = "";
        break;
      case "settings_profile":
        matchesSettings = true;
        pageContent = (
          <SettingProfileClass callBackFun={this.handleCallBackSetlanguage} />
        );
        helpCenterText = intl.get("settings");
        helpCenterLink =
          "https://www.dropshipman.com/blog/brief-for-configuration-dropshipman";
        break;

      case "settings_notification":
        matchesSettings = true;
        pageContent = <SettingNotificationClass />;
        helpCenterText = intl.get("settings");
        helpCenterLink =
          "https://www.dropshipman.com/blog/brief-for-configuration-dropshipman";
        break;

      case "settings_import":
        matchesSettings = true;
        pageContent = <SettingImportingRulesClass />;
        helpCenterText = intl.get("settings");
        helpCenterLink =
          "https://www.dropshipman.com/blog/brief-for-configuration-dropshipman";
        break;

      case "settings_price":
        matchesSettings = true;
        pageContent = <SettingPricingRulesClass />;
        helpCenterText = intl.get("settings");
        helpCenterLink =
          "https://www.dropshipman.com/blog/brief-for-configuration-dropshipman";
        break;

      // case 'settings_shipping':
      //   matchesSettings = true
      //   pageContent = <SettingShippingClass />
      //   helpCenterText = intl.get('settings')
      //   helpCenterLink = 'https://www.dropshipman.com/blog/brief-for-configuration-dropshipman'
      //   break

      case "settings_wallet":
        // matchesWallet = true
        pageContent = <SettingWalletClass />;
        break;

      case "settings_config":
        matchesSettings = true;
        pageContent = <OtherConfigClass />;
        helpCenterText = intl.get("settings");
        helpCenterLink =
          "https://www.dropshipman.com/blog/brief-for-configuration-dropshipman";
        break;

      // case 'settings_affiliate':
      //   matchesSettings = true
      //   pageContent = <AliExpressAffiliateClass />
      //   break

      case "settings_address":
        matchesSettings = true;
        pageContent = <AddressManagementClass />;
        break;

      // case "settings_contact_way":
      //   matchesSettings = true;
      //   pageContent = <ContactWayClass />;
      //   break;

      case "settings_payment":
        matchesSettings = true;
        pageContent = <OrderPaymentClass />;
        break;

      case "pricing":
        // matchesPricing = true;
        pageContent = <PricingClass />;
        break;

      case "messages":
        // matchesMessages = true;
        pageContent = <MessagesClass />;
        break;
      case "recommended_apps":
        recommendedApps = true;
        helpCenterText = "";
        helpCenterLink = "";
        pageContent = <RecommendedAppsClass />;
        break;

      case "shopping_cart":
        // matchesDropshipmanOrders = true;
        pageContent = (
          <ShoppingCartClass
            callBackFun={this.handleCallBackModifyCartNumber}
          />
        );
        break;

      case "products_import":
        // matchesAnalysis = true
        pageContent = <ProductsImport />;
        break;

      case "Coupon":
        pageContent = <CouponList />;
        break;
      case "custom-purchase":
        pageContent = <PurchaseClass />;
        break;
      case "custom-purchase-inventory":
        pageContent = <InventoryPurchaseClass />;
        break;
      case "custom-history":
        pageContent = <HistoryLogClass />;
        break;
      case "custom-inventory-history":
        pageContent = <InventoryHistoryLogClass />;
        break;
      case "custom-points":
        pageContent = <PointsPageClass />;
        break;
      case "custom-points-history":
        pageContent = <PointsLogClass />;
        break;
      case "custom-points-coupon":
        pageContent = <PointsCouponClass />;
        break;
      case "custom-points-purchase":
        pageContent = <PointsPurchaseClass />;
        break;
      case "custom-black-friday":
        pageContent = <PointsBlackPurchaseClass />;
        break;
      case "custom-referrals-history":
        pageContent = <PointsReferralsLogClass />;
        break;
        
      case "partner":
        matchesPartners = true;
        pageContent = <PartnersClass />;
        break;
      case "affiliate-program":
        matchesAffiliate = true;
        pageContent = <AffiliateClass />;
        break;
      // case "feature-requests":
      //   matchesFeatureRequests = true;
      //   pageContent = <FeatureRequestsClass />;
      //   break;

      default:
    }

    // const skipToContentRef = useRef(null);

    const searchResultsMarkup = (
      <Card>
        <ActionList
          items={[
            { content: intl.get("Shopify help center") },
            { content: intl.get("Community forums") },
          ]}
        />
      </Card>
    );

    // 菜单栏头部右侧下拉选项
    let topRightBar = [];

    topRightBar.push(
      {
        content: (
          <div style={{ width: "120px", display: "flex" }}>
            <UserIcon />{" "}
            <span style={{ marginLeft: "10px" }}>{intl.get("Profile")}</span>
          </div>
        ),
        onAction: () => {
          utils.routeListen("/admin/settings/profile");
          utils.routeJump(this, "/admin/settings/profile");
        },
      },
      {
        content: (
          <div style={{ width: "120px", display: "flex" }}>
            <TopMaoIcon />{" "}
            <span style={{ marginLeft: "10px" }}>{intl.get("Plan")}</span>
          </div>
        ),
        onAction: () => {
          utils.routeListen("/admin/subscribe");
          utils.routeJump(this, "/admin/subscribe");
        },
      },
      {
        content: (
          <div style={{ width: "120px", display: "flex" }}>
            <HelpIcon />{" "}
            <span style={{ marginLeft: "10px" }}>
              {intl.get("Help center")}
            </span>
          </div>
        ),
        url: "https://help.dropshipman.com/",
        external: true,
      }
      // {
      //   content: 'Message',
      //   badge: { content: String(messageCount), status: 'attention' },
      //   onAction: () => this.props.history.push('/admin/messages'),
      // },
      // {
      //   content: <div style={{ width: '120px' }}>{intl.get('Go to AliExpress')}</div>,
      //   url: 'https://s.click.aliexpress.com/e/_9HYGsC',
      //   external: true,
      // }
    );

    if (!AppBridge.exist()) {
      topRightBar.push({
        content: (
          <div style={{ width: "120px", display: "flex" }}>
            <OutLogIcon />{" "}
            <span style={{ marginLeft: "10px" }}>{intl.get("Log out")}</span>
          </div>
        ),
        onAction: () => this.handleLogout(),
      });
    }
    //  else {
    //   topRightBar.push({
    //     content: <div>{intl.get('Switch to full screen')}</div>,
    //     onAction: () => this.handleSwitchVersionOrDomain(),
    //   })
    // }

    const userMenuMarkup = (
      <TopBar.UserMenu
        actions={[
          {
            items: [
              {
                content: (
                  <div>
                    <strong>{intl.get("Account")}: </strong>
                    {shopOwer}
                  </div>
                ),
              },
            ],
          },
          {
            items: topRightBar,
          },
        ]}
        // name={shopOwer}
        // detail="Jaded Pixel"
        initials={shopOwerNamePrefix}
        open={isUserMenuOpen}
        onToggle={this.toggleIsUserMenuOpen}
      />
    );

    // 多店铺切换
    let storeOptions = [];
    let selectStoreId = utils.getStore("S_USER_TOKEN") || window.S_USER_TOKEN;
    if (userStores.length > 0) {
      for (let i = 0; i < userStores.length; i++) {
        let set_name = "";
        let icon = "/shopify.svg";
        let palt = "shopify";
        switch (userStores[i].plat) {
          case 1:
            set_name = userStores[i].name;
            icon = "/shopify.svg";
            palt = "shopify";
            break;
          case 2:
            set_name = userStores[i].name;
            icon = "/third_party.svg";
            palt = "woocommerce";
            break;
          case 3:
            set_name = userStores[i].store_name;
            icon = "/tk_icon.svg";
            palt = "tiktok";
            break;
          default:
        }

        storeOptions.push({
          content: set_name,
          prefix: (
            <img
              style={{ maxHeight: "16px" }}
              className={userStores[i].plat === 2 ? "mt-2px" : ""}
              src={icon}
              alt={palt}
            />
          ),
          onAction: () =>
            this.handleStoreChange(
              userStores[i].id,
              userStores[i].name,
              userStores[i].store_name
            ),
          disabled: userStores[i].id === selectStoreId ? true : false,
        });
      }
    }

    if (storeOptions.length === 0) {
      storeOptions.push({
        content: userDomain,
        onAction: () => {},
        disabled: true,
      });
    }

    //多语言切换 language onAction
    let languageOptions = [];
    for (let i = 0; i < this.state.options.length; i++) {
      languageOptions.push({
        content: this.state.options[i].label,
        onAction: () => {
          this.handleChangeLanugae(
            this.state.options[i].label,
            this.state.options[i].value
          );
          window.language = this.state.options[i].value;
        },
      });
    }

    // select store
    const storeItem = userStores.find((item) => {
      return item.store_name.toLowerCase() === selectedStore.toLowerCase();
    });
    let icon = "/shopify.svg";
    switch (storeItem?.plat) {
      case 1:
        icon = "/shopify.svg";
        break;
      case 2:
        icon = "/third_party.svg";
        break;
      case 3:
        icon = "/tk_icon.svg";
        break;
      default:
    }
    const secondaryMenuMarkup = (
      <TopBar.Menu
        activatorContent={
          <div className="select_store">
            <img src={icon} alt="dropshipman" />
            <div className="store">{selectedStore}</div>
            <Icon source={CaretDownIcon} color="base" />
          </div>
        }
        open={isSecondaryMenuOpen}
        onOpen={this.toggleIsSecondaryMenuOpen}
        onClose={this.toggleIsSecondaryMenuOpen}
        actions={[
          {
            items: storeOptions,
          },
        ]}
        key={1}
      />
    );

    //select Language
    const secondaryLanguageMarkup = (
      <TopBar.Menu
        activatorContent={
          <div className="select_language">
            <Icon source={GlobeIcon} />
            <div className="language">{defaultLanguage}</div>
            <Icon source={CaretDownIcon} color="base" />
          </div>
        }
        open={isSecondaryLanaguageOpen}
        onOpen={this.toggleIsSecondaryLanaguageOpen}
        onClose={this.toggleIsSecondaryLanaguageOpen}
        actions={[
          {
            items: languageOptions,
          },
        ]}
        key={2}
      />
    );
    const secondaryMessageMarkup = (
      <TopBar.Menu
        activatorContent={
          <div
            className="secondaryMessageMarkup"
            style={{ position: "relative" }}
            onClick={() => {
              utils.routeJump(this, "/admin/messages");
              utils.routeListen("/admin/messages");
            }}
          >
            <span
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                zoom: 0.5,
                background: "#f00",
                padding: "2px",
                width: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "30px",
                color: "#fff",
                borderRadius: "50%",
              }}
            >
              {String(messageCount)}
            </span>
            <NotifyIcon />
          </div>
        }
        key={3}
      />
    );

    const secondaryAdvertisementMarkup = (
      <TopBar.Menu
        activatorContent={
          <div
            className="secondaryAdvertisementMarkup secondaryAdvertisementMarkup_pagefly"
            style={{ position: "relative", height: "36px" }}
          >
            <a
              href="https://apps.shopify.com/gempages?utm_source=dropshipman&utm_medium=partner&utm_campaign=integration"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <img width={20} src="/blackFriday/Gempages_new_logo.webp" alt="Dropshipman" />
              </span>
              <span className="page_fly_mid">Focused Page Builder</span>
              <span>
                <strong>Try now</strong>
              </span>
            </a>
          </div>
        }
        key={4}
      />
    );

    let topBarSecondaryMenu = [];
    let person = store.getState().languageReducer.subScriptData;
    if (!AppBridge.exist()) {
      if (
        person.shopify_level !== "Development" &&
        person.shopify_level !== "Developer Preview" &&
        person.shopify_level !== "staff" &&
        person.shopify_level !== "staff_business"
      ) {
        topBarSecondaryMenu = [
          secondaryAdvertisementMarkup,
          secondaryMessageMarkup,
          secondaryLanguageMarkup,
          secondaryMenuMarkup,
        ];
      } else {
        topBarSecondaryMenu = [
          secondaryAdvertisementMarkup,
          secondaryMessageMarkup,
          secondaryLanguageMarkup,
          secondaryMenuMarkup,
        ];
      }
    } else {
      if (
        person.shopify_level !== "Development" &&
        person.shopify_level !== "Developer Preview" &&
        person.shopify_level !== "staff" &&
        person.shopify_level !== "staff_business"
      ) {
        topBarSecondaryMenu = [
          // secondaryAdvertisementMarkup,
          secondaryMessageMarkup,
          secondaryLanguageMarkup,
          secondaryMenuMarkup,
        ];
      } else {
        topBarSecondaryMenu = [
          // secondaryAdvertisementMarkup,
          secondaryMessageMarkup,
          secondaryLanguageMarkup,
          secondaryMenuMarkup,
        ];
      }
      // console.log(secondaryScreenMarkup);
      // 调整下样式
      let classObj = document.querySelector(
        ".DropShipMan .Polaris-TopBar__SecondaryMenu"
      );
      if (classObj) {
        classObj.style.minWidth = "unset";
        // classObj.style.display = 'unset'
        classObj.style.justifyContent = "unset";
        classObj.style.width = "unset";
      }
    }

    const topBarMarkup = (
      <TopBar
        showNavigationToggle
        userMenu={userMenuMarkup}
        searchResults={searchResultsMarkup}
        secondaryMenu={topBarSecondaryMenu}
        onNavigationToggle={this.toggleMobileNavigationActive}
      />
    );

    const lists = () => {
      let list = [
        // {
        //   label: intl.get("Custom Service"),
        //   icon: PackageIcon,
        //   url: "/admin/customService",
        //   onClick: () => this.props.history.push("/admin/customService"),
        //   matches: matchesCustomService,
        // },
        // {
        //   label: intl.get("Partner Ship"),
        //   icon: PackageIcon,
        //   url: "/admin/partnerShip",
        //   onClick: () => this.props.history.push("/admin/partnerShip"),
        //   matches: matchesPartnerShip,
        // },
        // {
        //   label: intl.get("Site Optimization"),
        //   icon: PackageIcon,
        //   url: "/admin/websiteOptimization",
        //   onClick: () => this.props.history.push("/admin/websiteOptimization"),
        //   matches: matchesWebsiteOptimization,
        // },
        {
          label: intl.get("Custom Packaging"),
          icon: PackageIcon,
          url: "/admin/customService/custom-packaging",
          onClick: () => this.handleBrandingUpgrade(),
          matches: matchesCustomPackaging,
          badge: <img src="/sub/sub_head_light.png" alt={"Custom Packaging"} />,
        },
        {
          label: intl.get("Private Storage"),
          icon: InventoryIcon,
          url: "/admin/customService/inventory",
          onClick: () => {
            utils.routeJump(this, "/admin/customService/inventory");
            utils.routeListen("/admin/customService/inventory");
          },
          matches: matchesInventory,
          badge: <img src="/sub/sub_head_light.png" alt={"Private Storage"} />,
        },
        // {
        //   label: intl.get('Wallet'),
        //   icon: WalletIcon,
        //   url: '/admin/wallet',
        //   onClick: () => this.props.history.push('/admin/wallet'),
        //   matches: matchesWallet,
        // },
        // {
        //   label: intl.get('Notifications'),
        //   icon: NotificationIcon,
        //   url: '/admin/notifications',
        //   onClick: () => this.props.history.push('/admin/notifications'),
        //   matches: matchesNotifications,
        // },
        {
          label: intl.get("Smart AI SEO"),
          icon: LandPageIcon,
          url: "/admin/customService/landing-page-build",
          onClick: () => {
            utils.routeJump(this, "/admin/customService/landing-page-build");
            utils.routeListen("/admin/customService/landing-page-build");
          },
          matches: matchesLandingPage,
          // badge: <span style={{ color: '#f5520d', fontSize: '12px', height: '20px', lineHeight: '24px', fontWeight: 500 }}>New</span>,
        },
        // {
        //   label: intl.get("Print On Demand"),
        //   icon: DesignIcon,
        //   url: "/admin/customService/print-on-demand?source=DSM",
        //   onClick: () => {
        //     utils.routeJump(
        //       this,
        //       "/admin/customService/print-on-demand?source=DSM"
        //     );
        //     utils.routeListen("/admin/customService/print-on-demand");
        //   },
        //   matches: matchesPrintOnDemand,
        //   // badge: <span style={{ color: '#f5520d', fontSize: '12px', height: '20px', lineHeight: '24px', fontWeight: 500 }}>New</span>,
        // },
        // {
        //   label: intl.get("Site Optimization"),
        //   icon: ExpertIcon,
        //   url: "/admin/websiteOptimization",
        //   onClick: () =>{
        //     utils.routeJump(this,'/admin/websiteOptimization')
        //   utils.routeListen('/admin/websiteOptimization')},
        //   matches: matchesWebsiteOptimization,
        //   matchPaths: [
        //     '/admin/websiteOptimization/website-speed',
        //     '/admin/websiteOptimization/website-build'
        //   ],
        // },
      ];
      return list;
    };

    const navigationMarkup = (
      <div onClick={(e) => e.preventDefault()}>
        <Navigation location="/">
          <Navigation.Section
            items={[
              {
                label: intl.get("Home"),
                icon: HomeMajor,
                url: "/admin/dashboard",
                onClick: () => {
                  utils.routeJump(this, "/admin/dashboard");
                  utils.routeListen("/admin/dashboard");
                },
                matches: matchesDashboard,
              },
            ]}
          />
          <Navigation.Section
            separator
            items={[
              {
                label: intl.get("Product Selection"),
                icon: SearchIcon,
                url:  (this.state.bili_grade === 2 || this.state.bili_grade === 3 || this.state.bili_grade === 5) ? "/admin/product_selection?select_tab=1" : "/admin/product_selection?select_tab=0",
                onClick: () => {
                  //  this.props.history.push("/admin/find_products")
                  if (this.state.bili_grade === 2 || this.state.bili_grade === 3 || this.state.bili_grade === 5) {
                    utils.routeListen("/admin/product_selection?select_tab=1");
                  utils.routeJump(this, "/admin/product_selection?select_tab=1");
                  }else {
                    utils.routeListen("/admin/product_selection?select_tab=0");
                    utils.routeJump(this, "/admin/product_selection?select_tab=0");
                  }
                },
                matches: matchesFindProducts,
                matchPaths: [
                  "/admin/product_selection/product-info",
                  // "/admin/customService/calculate-shipping",
                ],
              },
              // {
              //   label: intl.get("Find Products"),
              //   icon: SearchIcon,
              //   url: "/admin/find_products",
              //   onClick: () => {
              //     //  this.props.history.push("/admin/find_products")
              //     utils.routeListen("/admin/find_products");
              //     utils.routeJump(this, "/admin/find_products");
              //   },
              //   matches: matchesFindProducts,
              //   matchPaths: [
              //     "/admin/find_products/product-info",
              //     // "/admin/customService/calculate-shipping",
              //   ],
              // },
              // {
              //   label: intl.get("Sourcing"),
              //   icon: EyeglassesIcon,
              //   url: "/admin/sourcing",
              //   onClick: () => {
              //     utils.routeJump(this, "/admin/sourcing");
              //     utils.routeListen("/admin/sourcing");
              //   },
              //   matches: matchesSourcing,
              // },
              // {
              //   label: intl.get('Product List'),
              //   icon: ProductListIcon,
              //   url: '/admin/import-list',
              //   onClick: () => this.props.history.push('/admin/import-list'),
              //   matches: matchesProduct,
              //   subNavigationItems: [
              //     {
              //       label: intl.get('AliExpress'),
              //       url: '/admin/import-list',
              //       onClick: () => this.props.history.push('/admin/import-list'),
              //       matches: matchesProduct,
              //     },
              //     {
              //       label: intl.get('Dropshipman'),
              //       url: '/admin/import-list',
              //       onClick: () => this.props.history.push('/admin/import-list'),
              //       matches: matchesDropshipmanProduct,
              //     },
              //   ],
              // },
              {
                label: intl.get("Product Center"),
                icon: ProductListIcon,
                url: "/admin/product_management?selectedView=0",
                onClick: () => {
                  utils.routeJump(this, "/admin/product_management?selectedView=0");
                  utils.routeListen("/admin/product_management?selectedView=0");
                },
                matches: matchesImportList,
              },
              {
                label: intl.get("Orders"),
                icon: OrderIcon,
                url: "/admin/order_list",
                onClick: () => {
                  utils.routeJump(this, "/admin/order_list");
                  utils.routeListen("/admin/order_list");
                },
                matches: matchesOrderList,
                badge: String(ordersCount),
              },
              // {
              //   label: intl.get("Store Products"),
              //   icon: ProductIcon,
              //   url: "/admin/shopifyProducts",
              //   onClick: () =>{
              //     utils.routeListen('/admin/shopifyProducts')
              //     utils.routeJump(this,'/admin/shopifyProducts')
              //     },
              //   matches: matchesShopifyProduct,
              // },
            ]}
          />
          {/* <Navigation.Section
            separator
            items={[
              {
                label: intl.get("Orders"),
                icon: OrderIcon,
                url: "/admin/order_list",
                onClick: () => {
                  utils.routeJump(this,'/admin/order_list')
                  utils.routeListen('/admin/order_list')
                  },
                matches: matchesOrderList,
                badge: String(ordersCount),
                // subNavigationItems: [
                //   // 订单重构后路由-
                //   {
                //     label: intl.get("Order List"),
                //     url: "/admin/order_list",
                //     onClick: () => {
                //       utils.routeJump(this,'/admin/order_list')
                //       utils.routeListen('/admin/order_list')
                //       },
                //     matches: matchesOrderList,
                //     badge: String(dsmOrderCount),
                //   },
                //   // 订单重构前路由 start
                //   // {
                //   //   label: intl.get('All'),
                //   //   url: '/admin/order_list',
                //   //   onClick: () => this.props.history.push('/admin/order_list'),
                //   //   matches: matchesOrders,
                //   //   badge: String(aliOrderCount),
                //   // },
                //   // {
                //   //   label: intl.get('Dropshipman'),
                //   //   url: '/admin/order_list',
                //   //   onClick: () => this.props.history.push('/admin/order_list'),
                //   //   matches: matchesDropshipmanOrders,
                //   //   badge: String(dsmOrderCount),
                //   // },
                //   // 订单重构前路由 end

                //   // {
                //   //   label: intl.get('No source'),
                //   //   url: '/admin/no_source_orders',
                //   //   onClick: () => this.props.history.push('/admin/no_source_orders'),
                //   //   matches: matchesNoSourceOrders,
                //   //   badge: String(noSourceOrderCount),
                //   // },
                //   // {
                //   //   label: (
                //   //     <span style={{ whiteSpace: "pre" }}>
                //   //       {intl.get("Additional payment")}
                //   //     </span>
                //   //   ),
                //   //   url: "/admin/shipping_difference",
                //   //   onClick: () =>{
                //   //     utils.routeJump(this,'/admin/shipping_difference')
                //   //     utils.routeListen('/admin/shipping_difference')
                //   //     },
                //   //   matches: matchesShippingDifference,
                //   //   badge: String(shippingDifferenceCount),
                //   // },
                // ],
              },
              //物流订单追踪
              // {
              //   label: intl.get("Order Tracking"),
              //   icon: ShipmentMajor,
              //   url: "/admin/orders_tracking",
              //   onClick: () => this.handleOrderTrackingUpgrade(),
              //   matches: matchesOrderTracking,
              //   badge: (
              //     <img
              //       src="/sub/sub_head_light.png"
              //       alt={"Order Tracking"}
              //     />
              //   ),
              // },
              // {
              //   label: intl.get('Reports'),
              //   icon: AnalyticsMajor,
              //   url: '/admin/analysis',
              //   onClick: () => this.props.history.push('/admin/analysis'),
              //   matches: matchesAnalysis,
              // },
            ]}
          /> */}
          {!this.state.extraServices && (
            <div
              className="extra_service"
              onClick={(e) => this.handleOpenExtra(e)}
            >
              {intl.get("More Services")}{" "}
              <Icon source={ChevronDownIcon} tone="base" />
            </div>
          )}
          {this.state.extraServices && (
            <div
              className="extra_service"
              onClick={(e) => this.handleOffExtra(e)}
            >
              {intl.get("More Services")}{" "}
              <Icon source={ChevronUpIcon} tone="base" />
            </div>
          )}
          {this.state.extraServices && (
            <div className="extra_service_content">
              {" "}
              <Navigation.Section separator items={lists()} />
              <Navigation.Section
                separator
                items={[
                  {
                    label: intl.get("Partners"),
                    icon: PartnerIcon,
                    url: "/admin/partnerShip/partners",
                    onClick: () => {
                      utils.routeJump(this, "/admin/partnerShip/partners");
                      utils.routeListen("/admin/partnerShip/partners");
                    },
                    matches: matchesPartners,
                  },
                  {
                    label: intl.get("Affiliate Program"),
                    icon: FenxiaoIcon,
                    url: "/admin/partnerShip/affiliate",
                    onClick: () => {
                      utils.routeJump(this, "/admin/partnerShip/affiliate");
                      utils.routeListen("/admin/partnerShip/affiliate");
                    },
                    matches: matchesAffiliate,
                  },
                  {
                    label: intl.get("Recommended Apps"),
                    icon: AppExtensionIcon,
                    url: "/admin/recommended-apps",
                    onClick: () => {
                      utils.routeJump(this, "/admin/recommended-apps");
                      utils.routeListen("/admin/recommended-apps");
                    },
                    matches: recommendedApps,
                  },
                ]}
              />
            </div>
          )}

          <div className="subscribe_tab">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  this.state.bili_grade > 0
                    ? "/sub/plus_icon.png"
                    : "/sub/sub_head.png"
                }
                alt="dropshipman"
              />
              <span>
                {this.state.bili_grade === 0 && intl.get("Free")}
                {this.state.bili_grade === 1 && intl.get("Pro")}
                {this.state.bili_grade >= 2 &&
                  this.state.bili_grade <= 3 &&
                  intl.get("Plus")}
                {this.state.bili_grade === 4 && intl.get("Basic")}{" "}
                {this.state.bili_grade === 5 && intl.get("Premium")}{" "}
                {intl.get("Plan")}
              </span>
              &nbsp;
              <a
                href="/admin/subscribe"
                target="_self"
                style={{
                  borderColor:
                    this.state.bili_grade > 0 ? "#FFC148" : "#FFC148",
                  color: this.state.bili_grade > 0 ? "#FFC148" : "#FFC148",
                }}
                onClick={() => {
                  utils.routeJump(this, "/admin/subscribe");
                  utils.routeListen("/admin/subscribe");
                }}
              >
                {this.state.bili_grade > 0
                  ? intl.get("Details")
                  : intl.get("Upgrade")}
              </a>
            </div>
          </div>

          <div style={{ flex: "1 0 auto" }}></div>
          <Navigation.Section
            items={[
              // {
              //   label: intl.get("Guide"),
              //   icon: ChatMajor,
              //   onClick: this.openUserGuide,
              // },
              {
                label: intl.get("Settings"),
                icon: SettingsMajor,
                url: "/admin/settings/profile",
                onClick: () => {
                  utils.routeJump(this, "/admin/settings/profile");
                  utils.routeListen("/admin/settings/profile");
                },
                matches: matchesSettings,
                matchPaths: [
                  "/admin/settings/profile",
                  "/admin/settings/notification",
                  "/admin/settings/import",
                  "/admin/settings/price",
                  "/admin/settings/config",
                  "/admin/settings/address",
                  "/admin/settings/payment",
                  "/admin/settings/billing",
                  "/admin/settings/language",
                ],
              },
            ]}
          />
        </Navigation>
      </div>
    );

    // const logo = {
    //     width: 124,
    //     topBarSource: "/logo.png?v=1234",
    //     contextualSaveBarSource: "/logo.png?v=1234",
    //     url: "/admin/dashboard",
    //     accessibilityLabel: "DropShipMan AliExpress Dropshipping",
    //   }
    const logo = {
      topBarSource: "/logo_white.png?v=1234",
      width: 124,
      url: "/admin/dashboard",
      accessibilityLabel: "DropShipMan AliExpress Dropshipping",
    };
    
    return (
      <div className="DropShipMan make_item_text" id="DropShipManProfile">
        {!store.getState().languageReducer?.loading ? (
          <HomeLoading />
        ) : (
          <AppProvider i18n={translations}>
            <Frame
              //头部
              topBar={!AppBridge.exist() && topBarMarkup}
              // 抽屉
              logo={!AppBridge.exist() && logo}
              //左边
              navigation={!AppBridge.exist() && navigationMarkup}
              //手机端
              showMobileNavigation={mobileNavigationActive}
              onNavigationDismiss={this.toggleMobileNavigationActive}
              // skipToContentTarget={skipToContentRef.current}
            >
              {/* 初始化在线聊天工具 */}
              {this.state.platformInit}
              <BannerTip />
              {/* {((window.location.pathname !== "/admin/black-friday" && window.location.pathname !== "/admin/subscribe") && !this.state.topDrawer) && <TopDrawerItem handleTopDrawer={()=>this.handleTopDrawer()}/>} */}
              {/* 页面内容 */}
              {pageContent}
              {/* {((window.location.pathname !== "/admin/black-friday" && window.location.pathname !== "/admin/subscribe") && this.state.suspension) &&<BlackGoButton handleSuspension={(e)=>this.handleSuspension(e)}/>} */}
              {/* {((window.location.pathname !== "/admin/black-friday" && window.location.pathname !== "/admin/subscribe")) &&<BlackGoModal open={this.state.blackGoModal} handleBlackGoModalOff={()=>this.handleBlackGoModal()}></BlackGoModal>} */}
              {/* 速卖通授权弹窗 */}
              <AuthorizationUpdate
                show={this.state.authAliExpressModalActive}
                onClose={this.callBackHandleAuthModalClosed}
              />

              {/* 默认平台生成的账户提醒修改默认密码 */}
              <NeedUpdateInfo
                show={this.state.needSetPasswordActive}
                email={this.state.appAccount}
                onClose={this.callBackHandleAuthModalClosed}
              />

            {/* 侧边栏引导 */}
            <SidebarGuidanceSheet toastFun={this.toggleToast} new_person_side={this.state.new_person_side} new_person_step={this.state.new_person_step} handleNewPersonSide={this.handleNewPersonSide}/>
              {/* 底部帮助中心链接 */}
              {pageName !== "Coupon" && (
                <SheetContactUs
                  helpCenterText={helpCenterText}
                  helpCenterLink={helpCenterLink}
                />
              )}
              <div className="dsm_info_tip">
                {/* <WalletBalanceButton /> */}
                {/* 悬浮优惠券按钮 */}
                {/* {pageName !== "Coupon" && <CouponButton />} */}
                {/* <LoloyalButton /> */}
                {/* 悬浮购物车按钮 */}
                {pageName !== "shopping_cart" && (
                  <ShoppingCartButton cartNumber={cartNumber} />
                )}
              </div>
              {/* [新用户] 客户指引 */}
              {/* {!loadingSildeData && (
                <OnBoarding
                  show={showUserGuide}
                  onClose={this.closeOnBoarding}
                  stepOne={stepOne_UserProductPreferences}
                  closeUserGuide={this.closeUserProductPreferences}
                  onBoardingStatus={this.state.onBoardingStatus}
                />
              )} */}

              {/* 随机分配业务员 */}
              {/* {allotSaleModalVisible && <AllotSaleModal allotSaleModalVisible={allotSaleModalVisible} />} */}

              {toastMarkup}

              {/* 切换店铺加载样式 (Shopify跳转站外) */}
              {loadingVersion && (
                <div className="loading-version">
                  <img src="/loading.gif" alt="Dropshipman" />
                </div>
              )}
              {/* 升级为会员弹窗 */}
              <UpgradePlanModal
                upgradeOpen={this.state.upgradeModal}
                setUpgradeOpen={() => this.setState({ upgradeModal: false })}
              />

              {/* //新手引导 */}
              {/* {onBoarding && (
                <NoviceGuidance
                  noviceGuide={noviceGuide}
                  setUpgradeOpen={(e) =>this.handleNovice(e)}
                  noviceCategory={noviceCategory}
                  setState={() => this.setState({ onBoarding: false })}
                ></NoviceGuidance>
              )} */}
              {/* <NoviceModal
                upgradeOpen={this.state.noviceFinsh}
                noviceNum={this.state.noviceNum}
                setUpgradeOpen={() => {
                  this.setState({ noviceFinsh: false });
                  if (!AppBridge.exist()) {
                    this.openUserGuide();
                  }
                }}
              ></NoviceModal> */}
              {/* 2024黑五活动弹窗 */}
              <BlackRaffleShowModal open={this.state.blackGoModal} handleOff={()=>this.handleBlackGoModal()}/>

              {/* 黑五补充弹窗 */}
              <BlackDiscountModal upgradeOpen={black_friday_feed.show1} handleBlackFeedbackModal={this.handleBlackFeedbackModal}/>
              <BlackDiscountNineModal upgradeOpen={black_friday_feed.show2} handleBlackFeedbackModal={this.handleBlackFeedbackModal}/>
              {/* 黑五补充弹窗 挽留弹窗 */}
              <BlackFeedbackModal upgradeOpen={black_friday_feed.open} handleBlackFeedbackModal={this.handleBlackFeedbackModal}/>
              {/* 弹窗优先级 */}
              

              

              {/* 白名单新用户进入app 发放福利活动弹窗 2*/}
              <UpgradeWhiteModal
                upgradeOpen={this.state.isWhitePop}
                setUpgradeOpen={() => {
                  this.setState({ isWhitePop: false });
                  this.getDsmPupop();
                }}
              />

              {/* 增值福利 3 */}
              <AdditionalBenefitsModal
                upgradeOpen={this.state.additionalPop}
                type={2}
                setUpgradeOpen={() => {
                  this.setState({ additionalPop: false });
                  this.getDsmPupop();
                }}
              />

              {/* 速卖通全局信息弹窗 4*/}
              <Pop
                name={pageName}
                pop={this.state.isHasPopReminder}
                getDsmPupop={() => this.getDsmPupop()}
                setClose={() => this.setState({ isHasPopReminder: false })}
              />
              <PremuimPlanModal open={this.state.premium_pop} onClose={() => { 
                this.setState({ premium_pop: false })
                this.getDsmPupop();
              }} />

              {/* 用户留存套餐弹窗 */}
              {(this.state.retentionPlanData.is_newbie_pop || this.state.retentionPlanData.is_notify_pop || this.state.retentionPlanData.is_ordinary_pop || this.state.retentionPlanData.is_reinstall_pop)  ? <RetentionPlanModal 
              toastFun={this.toggleToast}
              open={(this.state.retentionPlanData.is_newbie_pop || this.state.retentionPlanData.is_notify_pop || this.state.retentionPlanData.is_ordinary_pop || this.state.retentionPlanData.is_reinstall_pop)}
              retentionPlanData={this.state.retentionPlanData} 
              onClose={() => { this.setState({ retentionPlanData:{
                is_newbie_pop:false,
                is_notify_pop:false,
                is_ordinary_pop:false,
                is_reinstall_pop:false,
              } })}}
              handleBaseAction={this.handleBaseAction}
              userDiscounts={this.state.user_discounts}
              /> : ''}
              
              {/* 4周年套餐活动 */}
              {pageName !== "subscribe" && <CommonModal
                level={this.state.bili_grade}
                open={this.state.four_pop}
                width={460}
                content={
                  <Box className="fourth_content">
                    <Box className="fourth_content_modal_img">
                    {this.state.bili_grade === 0 ? <img src="/sub/fourth_fourty.png" alt="Dropshipman" />: <img src="/sub/fourth_modal_bg.png" alt="Dropshipman" />}
                    </Box>
                    <Box className="fourth_content_modal_box">
                    <Box className="fourth_content_mid">
                      {this.state.bili_grade === 0 ? (
                        <strong>40%</strong>
                      ) : (
                        <strong>$ 40</strong>
                      )}
                      {this.state.bili_grade === 0 ? (
                        <span>Off All Packages</span>
                      ) : (
                        <span>coupons for everyone</span>
                      )}
                    </Box>
                    <Box className="fourth_content_p">
                      Valid from July 20 to August 20
                    </Box>
                    <Box className="fourth_content_button">
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (this.state.bili_grade === 0) {
                            utils.routeListen("/admin/subscribe");
                            utils.routeJump(this, "/admin/subscribe");
                          } else {
                            utils.routeListen("/admin/coupon");
                            utils.routeJump(this, "/admin/coupon");
                          }
                        }}
                      >
                        <span style={{ marginRight: 10, fontSize: 18 }}>
                          Claim the{" "}
                          {this.state.bili_grade === 0
                            ? "discount"
                            : " coupons"}
                        </span>
                        <span style={{ fontSize: 24 }}>👈</span>
                      </Button>
                    </Box>
                    </Box>
                  </Box>
                }
                onClose={() => this.setState({ four_pop: false })}
              />}
            </Frame>
          </AppProvider>
        )}
      </div>
    );
  }
}
// const mapStateToProps = state => {
//   return {
//     subScriptData: state.languageReducer.subScriptData,
//     languageReducer: state.languageReducer,
//   }
// }
// export default connect()(withRouter(FrameClass), withTranslation(FrameClass));
export default connect()(withRouter(FrameClass));
