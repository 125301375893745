import {
  Autocomplete,
  Box,
  Button,
  InlineStack,
  Popover,
  TextField,
  Tooltip,
  Icon,
  Scrollable,
  Badge,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import {
  SearchIcon,
  CheckIcon,
  PlusCircleIcon,
} from "@shopify/polaris-icons";
import { reqPostProductManagementVariantsSave } from "../../../../api";
const Index = ({
  listData,
  setListData,
  keys,
  organtioanOptions,
  setoOrgantionOptions,
  type,
  attributeData,
  setAttributeData,
  boxRef,
  toastFun,
  handleSelectionChange
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const getOpt = useCallback(
    (val) => {
      if (!val) return [];

      const list = [];
      const searchCategory = (Collections) => {
        Collections.forEach((item) => {
          if (item.title.includes(val)) {
            list.push({ title: item.title, id: item.id });
          }
          //   if (category.children?.length) searchCategory(category.children);
        });
      };
      let item = attributeData.collection;
      switch (type) {
        case "collection":
          item = attributeData.collection;
          break;
        case "type":
          item = attributeData.type;
          break;
        case "tags":
          item = attributeData.tags;
          break;
        default:
          break;
      }
      searchCategory(item);
      return list;
    },
    [type,attributeData]
  ); // 输入框值变化时，调用函数生成选项
  const [isNew,setIsNew] = useState(false)
  // 输入框输入
  const updateText = useCallback(
    (value) => {
      if (value.length > 50) {
        return
      }
      setInputValue(value);

      // 如果输入为空，直接设置空选项
      if (!value.trim()) {
        setOptions([]);
        return;
      }

      // 调用 getOpt 一次，避免重复调用
      const filteredOptions = getOpt(value);
      filteredOptions.forEach((item) => {
        if (item.title === value) {
          setIsNew(false)
        } else {
          setIsNew(true)
        }
      });
      setOptions(filteredOptions);
    },
    [getOpt]
  );
  // 选择值变化时
  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.id.match(selectedItem);
        });
        return matchedOption && matchedOption.title;
      });

      setSelectedOptions(selected);
      setInputValue(selectedValue[0] || "");
    },
    [options]
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const saveDetailVariants = async (data) => {
    let params = {
      record_id: [listData.list[keys].id],
      information: {
        ...data,
      },
    };
    try {
      let res = await reqPostProductManagementVariantsSave(params);
      if (res.code === "200") {
        toastFun('Save successfully')
        setSaveLoading(false);
        handleSelectionChange('page',false,0)
      } else {
        setSaveLoading(false);
      }
    } catch (error) {}
  };
  //   选中collection\tag\type
  const handleCollectionChange = (value) => {
    if (saveLoading) return;
    setSaveLoading(true);

    // 父级列表
    const listFather = [...listData.list];
    // 当前子项数据
    const listItem = { ...listData.list[keys] };

    // 确定需要操作的列表字段
    let itemListKey;
    switch (type) {
      case "collection":
        itemListKey = "select_collections";
        break;
      case "type":
        itemListKey = "select_product_type";
        break;
      case "tags":
        itemListKey = "select_tags";
        break;
      default:
        return;
    }

    // 获取当前子项对应的列表数据
    let itemList = listItem[itemListKey] ? [...listItem[itemListKey]] : [];

    // 处理选中和反选逻辑

    if (itemList.includes(value)) {
      // 反选：移除已存在的值
      itemList = itemList.filter((item) => item !== value);
    } else {
      // 选中：添加新值
      itemList.push(value);
    }

    // 根据类型处理保存逻辑
    let savePayload = {};
    switch (type) {
      case "collection":
        savePayload = { collections: itemList };
        break;
      case "type":
        if (itemList.length > 0) {
          itemList = [value];
        }
        savePayload = { product_type: itemList.length > 0 ? itemList[0] : "" }; // 保证类型只有一个值
        break;
      case "tags":
        savePayload = { tag: itemList };
        break;
      default:
        return;
    }

    saveDetailVariants(savePayload)
      .then(() => {
        // 更新当前子项数据
        listItem[itemListKey] = itemList;
        listItem['is_update'] = 1;
        listFather[keys] = listItem;

        // 更新父级列表数据
        setListData({
          ...listData,
          list: listFather,
        });
      })
      .catch((error) => {
        console.error("Failed to save:", error);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  //   当前展示的collection集合
  const collectionShow = useCallback(() => {
    let listItem = listData.list[keys]; // 当前子项数据
    let list = [];
    let currentList = attributeData.collection ? attributeData.collection : [];
    let currentSelect = listItem.select_collections
      ? listItem.select_collections
      : [];
    switch (type) {
      case "collection":
        currentList = attributeData.collection ? attributeData.collection : [];
        currentSelect = listItem.select_collections
          ? listItem.select_collections
          : [];
        break;
      case "type":
        currentList = attributeData.type ? attributeData.type : [];
        currentSelect = listItem.select_product_type
          ? listItem.select_product_type
          : [];
        break;
      case "tags":
        currentList = attributeData.tags ? attributeData.tags : [];
        currentSelect = listItem.select_tags ? listItem.select_tags : [];
        break;
      default:
        break;
    }

    for (let i = 0; i < currentList.length; i++) {
        if (currentSelect.includes(currentList[i].id)) {
          list.push({ title: currentList[i].title, id: currentList[i].id });
        }
      
    }

    return list;
  }, [listData,type,attributeData,keys]);

  // 当前显示的内容名称
  const organizationName = () => {
    let text = "Collections";
    switch (type) {
      case "collection":
        text = "Collections";
        break;
      case "type":
        text = "Type";
        break;
      case "tags":
        text = "Tags";
        break;
      default:
        break;
    }
    return text;
  };

  // 当前展示内容项的弹窗
  const currentKeys = () => {
    let index = "";
    switch (type) {
      case "collection":
        index = organtioanOptions.currentCollection;
        break;
      case "type":
        index = organtioanOptions.currentProductType;
        break;
      case "tags":
        index = organtioanOptions.currentTags;
        break;
      default:
        break;
    }
    return index;
  };
  // 当前要操作的内容项
  const handleShowOrganization = (key) => {
    switch (type) {
      case "collection":
        setoOrgantionOptions((prevValue) => ({
          ...prevValue,
          currentCollection: key,
          currentProductType: "",
          currentTags: "",
        }));
        break;
      case "type":
        setoOrgantionOptions((prevValue) => ({
          ...prevValue,
          currentCollection: "",
          currentProductType: key,
          currentTags: "",
        }));
        break;
      case "tags":
        setoOrgantionOptions((prevValue) => ({
          ...prevValue,
          currentCollection: "",
          currentProductType: "",
          currentTags: key,
        }));
        break;
      default:
        break;
    }
  };
  // 新增标签
  const addNewProductAttr = (value) => {
    // 父级列表
    let listFather = [...listData.list];
    // 当前子项数据
    let listItem = { ...listData.list[keys] };

    // 确定需要操作的列表字段
    let itemListKey;
    switch (type) {
      case "type":
        itemListKey = "select_product_type";
        break;
      case "tags":
        itemListKey = "select_tags";
        break;
      default:
        return;
    }

    // 获取当前子项对应的列表数据
    let itemList = [...listItem[itemListKey]];

    // 处理选中和反选逻辑
    if (itemList.includes(value)) {
      itemList = itemList.filter((item) => item !== value); // 反选：移除已存在的值
    } else {
      itemList.push(value); // 选中：添加新值
    }

    switch (type) {
      case "type":
        let typeData = attributeData.type;
        typeData.push({ id: value, title: value });
        itemList = [value]
        setAttributeData({ ...attributeData, type: typeData });
        saveDetailVariants({ product_type: itemList[0] });
        break;
      case "tags":
        let tagData = attributeData.tags;
        tagData.push({ id: value, title: value });
        setAttributeData({ ...attributeData, tags: tagData });
        saveDetailVariants({ tag: itemList });
        break;
      default:
        return;
    }
    setInputValue("");
    setoOrgantionOptions((prevValue) => ({
      ...prevValue,
      currentCollection: "",
      currentProductType: "",
      currentTags: "",
    }));
    // 更新当前子项数据
    listItem[itemListKey] = itemList;
    listFather[keys] = listItem;

    // 更新父级列表数据
    setListData({
      ...listData,
      list: listFather,
    });
  };
  // 输入框查询
  const textField = (
    <TextField
      placeholder="Search segments"
      type="text"
      value={inputValue}
      onChange={updateText}
      prefix={<Icon source={SearchIcon} tone="base" />}
      autoComplete="off"
      clearButton="auto"
      onClearButtonClick={() => updateText("")}
    />
  );
  const currentShowBox = () => {
    let currentBoxList = attributeData?.collection
      ? attributeData.collection
      : [];
    let currentBoxSelect = listData.list[keys].select_collections
      ? listData.list[keys].select_collections
      : [];
      try {
    switch (type) {
      case "collection":
        currentBoxList = attributeData?.collection
          ? attributeData.collection
          : [];
        currentBoxSelect = listData.list[keys].select_collections
          ? listData.list[keys].select_collections
          : [];
        break;
      case "type":
        currentBoxList = attributeData?.type ? attributeData.type : [];
        currentBoxSelect = listData.list[keys].select_product_type
          ? listData.list[keys].select_product_type
          : [];
        break;
      case "tags":
        currentBoxList = attributeData?.tags ? attributeData.tags : [];
        currentBoxSelect = listData.list[keys].select_tags
          ? listData.list[keys].select_tags
          : [];
        break;
      default:
        break;
    }
    } catch (error) {
          console.log(error)
    }
    return {
      currentBoxList,
      currentBoxSelect,
    };
  };
  
  return (
    <Popover
      active={keys === currentKeys()}
      activator={
        <Button
          variant="tertiary"
          disclosure={keys === currentKeys() ? "up" : "down"}
          onClick={(e) => {
            e.stopPropagation();
            if (keys === currentKeys()) {
              handleShowOrganization('');
            }else {
              handleShowOrganization(keys);
            }
          }}
        >
          <Box className="organization_box" >
            {keys === currentKeys() ? <InlineStack blockAlign="center">
              {collectionShow().length > 0 ? (
                  <span>
                    {collectionShow().length > 0 ? (
                      <span className="organization_box_text">
                        {collectionShow()
                          .map((it) => it.title)
                          .join(", ")}
                      </span>
                    ) : (
                      <span>{organizationName()}</span>
                    )}
                  </span>
              ) : (
                <span>{organizationName()}</span>
              )}
            </InlineStack>: <InlineStack blockAlign="center">
              {collectionShow().length > 0 ? (
                <Tooltip
                  width={300}
                  content={
                    <Box style={{ padding: "0 4px 4px",maxWidth: "300px" }}>
                      <Box
                        style={{
                          fontWeight: "650",
                          fontSize: "14px",
                          marginBottom: "4px",
                        }}
                      >
                        {organizationName()}
                      </Box>
                      <InlineStack gap={100} wrap={true}>
                        {collectionShow()
                          .slice(0, 10)
                          .map((it, ind) => (
                            <Badge key={ind}>{it.title}</Badge>
                          ))}
                        {collectionShow().length > 10 ? <Badge>...</Badge> : ""}
                      </InlineStack>
                    </Box>
                  }
                >
                  <span>
                    {collectionShow().length > 0 ? (
                      <span className="organization_box_text">
                        {collectionShow()
                          .map((it) => it.title)
                          .join(", ")}
                      </span>
                    ) : (
                      <span>{organizationName()}</span>
                    )}
                  </span>
                </Tooltip>
              ) : (
                <span>{organizationName()}</span>
              )}
            </InlineStack>}
          </Box>
        </Button>
      }
      autofocusTarget="first-node"
      onClose={(e) => {
        setoOrgantionOptions((prevValue) => ({
          ...prevValue,
          currentCollection: "",
          currentProductType: "",
          currentTags: "",
        }));
      }}
    >
      <Box ref={boxRef}>
        <Box className="">
          <InlineStack wrap={false} direction={"row"}>
            <Box
              className="product_selection_cate_content_box"
              style={{ width: "300px", height: "300px" }}
            >
              <Box className="product_selection_cate_content_search">
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={textField}
                />
              </Box>
              {type !== "collection" &&
              options.length === 0 &&
              inputValue !== "" ? (
                <Scrollable style={{ height: "250px", background: "#fff" }}>
                  <Box className="product_selection_cate_content_list">
                    <Box
                      className={"product_selection_cate_content_item"}
                      onClick={() => {
                        addNewProductAttr(inputValue);
                      }}
                    >
                      <InlineStack blockAlign="center" wrap={false}>
                        <Box>
                          <Icon source={PlusCircleIcon} tone="base" />
                        </Box>
                        <Box className="item_text_adds">{inputValue}</Box>
                      </InlineStack>
                    </Box>
                  </Box>
                </Scrollable>
              ) : (
                <Scrollable style={{ height: "250px", background: "#fff" }}>
                  {options.length > 0 ? (
                    <Box className="product_selection_cate_content_list">
                      {type !== "collection" && (isNew && inputValue !=='') &&<Box
                      className={"product_selection_cate_content_item"}
                      onClick={() => {
                        addNewProductAttr(inputValue);
                      }}
                    >
                      <InlineStack blockAlign="center" wrap={false}>
                        <Box>
                          <Icon source={PlusCircleIcon} tone="base" />
                        </Box>
                        <Box className="item_text_adds">{inputValue}</Box>
                      </InlineStack>
                    </Box>}
                      {options.map((item, ind) => (
                        <Box
                          key={ind}
                          className={"product_selection_cate_content_item"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCollectionChange(item.id);
                          }}
                        >
                          <InlineStack
                            align="space-between"
                            blockAlign="center"
                            wrap={false}
                          >
                            <Box  className="item_text_add">{item.title}</Box>
                            {currentShowBox()?.currentBoxSelect?.length > 0 ? <Box>
                              {currentShowBox()?.currentBoxSelect?.includes(
                                item.id
                              ) ? (
                                <span>
                                  <Icon source={CheckIcon} tone="base" />
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </Box>:''}
                          </InlineStack>
                        </Box>
                      ))}
                    </Box>
                  ) : currentShowBox()?.currentBoxList?.length > 0? (
                    <Box className="product_selection_cate_content_list">
                      {currentShowBox()?.currentBoxList?.map((item, ind) => (
                        <Box
                          key={ind}
                          className={"product_selection_cate_content_item"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCollectionChange(item.id);
                          }}
                        >
                          <InlineStack
                            align="space-between"
                            blockAlign="center"
                          >
                            <Box className="item_text_add">{item.title}</Box>
                            <Box>
                              {currentShowBox()?.currentBoxSelect?.includes(
                                item.id
                              ) ? (
                                <InlineStack gap={100} blockAlign="center">
                                  <span>
                                    <Icon source={CheckIcon} tone="base" />
                                  </span>
                                 
                                </InlineStack>
                              ) : (
                                <span>
                                </span>
                              )}
                            </Box>
                          </InlineStack>
                        </Box>
                      ))}
                    </Box>
                  ):''}
                </Scrollable>
              )}
            </Box>
          </InlineStack>
        </Box>
      </Box>
    </Popover>
  );
};

export default Index;
