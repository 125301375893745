import React, { Component } from 'react';

import FrameComponents from "../../FrameComponents"; 

import "./index.css";

class dashboard extends Component {

  state = {};

  //进入页面调用接口
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-recommended-category';
  }

  render() {
    return (
      <div>
        <FrameComponents name="recommended_category" type={this.props.match.params.type} />
      </div>
    );
  }
}

export default dashboard;