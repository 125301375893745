import React, { Component} from "react";
import { withRouter } from 'react-router-dom';
import { 
  Layout, Page, Card, Button, DatePicker, SkeletonBodyText,
  Toast, Checkbox, Link, ExceptionList, Thumbnail, EmptyState, Pagination,Modal,Tabs,Filters,ChoiceList,Badge,ActionList,Popover, BlockStack
  // Popover, ActionList,
} from "@shopify/polaris";
import utils from '../../../../utils/utils'
import { EditIcon } from '@shopify/polaris-icons';

import OverrideProductModalComponet from './OverrideProductModal';
import UpdatePriceModalComponet from './UpdatePriceModal';
import UpdateInventoryModalComponet from './UpdateInventoryModal';

import { 
  // reqGetNotificationList, 
  // reqPostNotifyAction,
  reqGetBatchProcess,
  reqGetAliExpressAffiliateLink, 
  reqGetDropshipmanNotificationList, 
  reqPostDropshipmanNotifyAction 
} from '../../../../api';

import "../index.css";

import intl from '../../../../i18n/intl'

class NotificationsClass extends Component {
  state = {
    // 有效的操作产品数量
    EffectiveProductCount: 0,

    // 是否正在批量操作
    isBulkOperationsRequest: false,
    BulkOperationType: '',  // 批量操作类型 
    BulkOperationTipsActive: false,  // 弹窗
    BulkOperationTipTitle:'',
    BulkOperationTipMessage: '',
    BulkButtonLoading: false,

    isSearching: '0',
    page: 1,
    size: 10,
    hasPrevious : false,
    hasNext : true,
    total: 0,

    //弹窗
    showToast: false,  //弹窗显示
    showToastText:'',  //弹窗文字
    showToastIserror:false, //弹窗是否错误

    loadingPage: false,
    
    queryValue: '',  // 关键词内容搜索
    filterStatus: [],  // 选择产品类型搜索
    //时间筛选
    isSearchDate: false,
    month: new Date().getMonth()-1,
    year: new Date().getFullYear(),
    selectedDates: {
      start: new Date(),
      end: new Date()
    },
    
    productsData: [],  // 列表数据
    productList: <tr></tr>,

    // 显示页面产品总数量
    ShowProductNumber: 0,

    // 是否全选
    checked: false,
    // 选中产品个数
    checkedProductCount: 0,

    // 选择数据库所有记录
    selectAllMoreChecked: false,

    tabSelected: 0,
    OverrideProductModal: '',
    UpdatePriceModal: '',
    UpdateInventoryModal: '',
    popoverActive: false,

    symbol:''
  };

  //进入页面调用接口
  componentDidMount() {
    this.getProductList();
  }

  // 子组件内容改变，通过父组件更新渲染 
  // childJsonData 子组件返回的Json数据：[组件key,子组件名称,由传给子组件的主要内容(array)]
  childContentChangesParentUpdate =(childJsonData)=>{
    if(childJsonData !== '' && childJsonData !== undefined){

      // console.info(childJsonData);
      // let recordKey = childJsonData.recordKey;
      let childType = childJsonData.childType;
      let data = childJsonData.data;

      let _pageData = this.state.productsData;
      
      if(childType === 'inventory'){
        // 修改当前页面所有 库存记录 - 更新变量的值
        for(let i=0; i<_pageData.length; i++){
          if(_pageData[i].productChangeStatus === 4){
            _pageData[i].isSettingAutoUpdate = data.selected;
          }
        }

      }else if(childType === 'cost'){
        // 修改当前页面所有 价格更新 - 更新变量的值
        for(let i=0; i<_pageData.length; i++){
          if(_pageData[i].productChangeStatus === 3){
            _pageData[i].isSettingAutoUpdate = data.selected;
          }
        }

      }else if(childType === 'reload'){
        this.getProductList(true);
        return false;

      }else{
        return false;
      }

      this.setState({
        productsData: _pageData
      });

      setTimeout(()=>{
        this.updatePageDetail();
      },300);
    }
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  getProductList = async(isSearch=false) =>{
    if(isSearch){
      this.setState({
        isSearching: '1'
      });
    }else{
      this.setState({
        loadingPage: true,
      });
    }
    
    const params = {
      q: this.state.queryValue,
      page: this.state.page,
      size: this.state.size,
      selectProductStatus: JSON.stringify(this.state.filterStatus),
      isSearchDate: this.state.isSearchDate?1:0,
      start: (new Date(this.state.selectedDates.start).getFullYear()) + "/" + (new Date(this.state.selectedDates.start).getMonth()+1) + "/" + (new Date(this.state.selectedDates.start).getDate()),
      end: (new Date(this.state.selectedDates.end).getFullYear()) + "/" + (new Date(this.state.selectedDates.end).getMonth()+1) + "/" + (new Date(this.state.selectedDates.end).getDate()),
    };

    // let apiUri = '';
    // if (this.state.tabSelected === 0) {
    //   apiUri = reqGetNotificationList;

    // } else {
    //   apiUri = reqGetDropshipmanNotificationList;
    // }

    // const response = await apiUri(params)
    const response = await reqGetDropshipmanNotificationList(params)
    if (response.code==='200') {
      var getData = response.data;
      // console.info(getData);

      //更新状态
      this.setState({
        loadingPage:false,
        productsData: getData.result,
        total:getData.count,
        isSearching: '0'
      },()=>{
        // 更新列表数据
        this.updatePageDetail();
      });

      // 更新分页数据
      if(this.state.page > 1){
        this.setState({
          hasPrevious:true
        });
      }else{
        this.setState({
          hasPrevious:false
        });
      }

      const maxPage =  Math.ceil(response.data.count / this.state.size);
      if(this.state.page < maxPage){
        this.setState({
          hasNext:true
        });
      }else{
        this.setState({
          hasNext:false
        });
      }
    }else{
      this.toggleToast(response.msg);
      this.setState({
        loadingPage: false,
        productsData: [],
        isSearching: '0'
      });
    }
  }

  // 更新列表数据
  updatePageDetail(){
    let data = this.state.productsData;
    let isCheckedAll = false;
    let checkedProductCount = 0;
    let productListContent = <tr></tr>;

    if(data.length > 0){
      productListContent = data.map((item,key) => {
        let trHtml = '';

        if(item.checked === 1){
          checkedProductCount++;
        }

        let showImageUrl = '/default_image.png';
        if(item.imageUrl !== ''){
          showImageUrl = item.imageUrl;
        }

        // 状态类型 / 产品状态文本 / 操作 / 操作显示文本
        let _changeStatusType = '';
        let _changeStatusText = '';
        let onclickAction = '';
        let _actionText = '';
        switch(Number(item.productChangeStatus)){
          case 1:
            _changeStatusType = '';
            _changeStatusText = intl.get("Product Unavailable");
            onclickAction = this.handleOverrideProductModal.bind(this,key);
            _actionText = intl.get("Override product");
            break;
          case 2:
            _changeStatusType = 'warning';
            _changeStatusText = intl.get("Variant Unavailable");
            onclickAction = this.handleOverrideProductModal.bind(this,key);
            _actionText = intl.get("Override product");
            break;
          case 3:
            _changeStatusType = 'attention';
            _changeStatusText = intl.get("Cost Change");
            onclickAction = this.handleUpdatePriceModal.bind(this,key);
            _actionText = intl.get("Update Price");
            break;
          case 4:
            _changeStatusType = '';
            _changeStatusText = intl.get("Inventory Change");
            onclickAction = this.handleUpdateInventoryModal.bind(this,key);
            _actionText = intl.get("Check updated");
            break;
          default:
            _changeStatusType = '';
            _changeStatusText = '';
            onclickAction = ()=>{alert('Something wrong')};
            _actionText = intl.get("Action");
        }

        let productTitle = '';
        let actionButton = '--';

        if (this.state.tabSelected === 0) {
          productTitle = (
            <Button
            variant='plain'
              onClick={()=>this.handleGetPromotionLink(item.OriginalUrl)}
            >
              {item.title}
            </Button>
          );

          actionButton = (
            <Link onClick={onclickAction}>
              <ExceptionList
                items={[
                  {
                    icon: EditIcon,
                    description: _actionText,
                  },
                ]}
              />
            </Link>
          );
        } else {
          productTitle = (
            <Button
            variant='plain'
              onClick={()=>
              {
                utils.routeListen('/admin/find_products/product-info')
                utils.routeJump(this,'/admin/find_products/product-info?filter='+item.OriginalUrl)
                }
            }
            >
              {item.title}
            </Button>
          )
        }

        trHtml = (
          <tr key={key}>
            <td>
              <Checkbox
                label=''
                labelHidden
                checked={item.checked}
                onChange={this.handleSingleCheckboxChange.bind(this,key)}
              />
            </td>
            <td>
              <Thumbnail
                source={showImageUrl}
                alt="product title"
              />
            </td>
            <td className="Product_Title">
              {/* <a href={item.OriginalUrl} target="_blank" className="Polaris-Link" rel="noopener noreferrer">
                {item.title}
              </a> */}
              {productTitle}
              
            </td>
            <td className="Update_Time">
              {item.updateTime}
            </td>
            <td className={(item.productChangeStatus===1)?"Change_Status Product_Unavailable":"Change_Status"}>
              <Badge tone={_changeStatusType}>{ _changeStatusText }</Badge>
            </td>
            <td className="Action">
              {actionButton}
            </td>
          </tr>
        );

        return (
          trHtml
        );
      });
    }

    // 判断是否全选
    if(data.length === checkedProductCount && checkedProductCount !== 0){
      isCheckedAll = true;
    }

    this.setState({
      productList: productListContent,
      ShowProductNumber: data.length,
      checked: isCheckedAll,
      checkedProductCount: checkedProductCount,
    });
  }


  // 全选 CheckBox  change
  handleAllCheckboxChange = (value) =>{
    let _data = this.state.productsData;
    let setCheckedValue = value?1:0;

    for(let i=0; i<_data.length; i++){
      _data[i].checked = setCheckedValue;
    }

    if(!value && this.state.selectAllMoreChecked){
      this.setState({
        selectAllMoreChecked: false
      });
    }
    
    this.setState({
      checked: value,
      productsData: _data
    },()=>{
      this.updatePageDetail();
    });
  }

  // checkbox 单选
  handleSingleCheckboxChange = (RecordKey,value) =>{
    let _data = this.state.productsData;
    _data[RecordKey].checked = (value===true)?1:0;

    if(!value && this.state.selectAllMoreChecked){
      this.setState({
        selectAllMoreChecked: false
      });
    }
    
    this.setState({
      productsData: _data
    },()=>{
      this.updatePageDetail();
    });
  }

  // 选择所有的产品（该用户所有数据，含未展示的）
  handleSelectAllMoreChecked = (isChecked) =>{
    this.setState({
      selectAllMoreChecked: isChecked
    });
  }

  // tabs 切换
  handleTabChange = (index) =>{
    this.setState({
      tabSelected: index
    },()=>{
      setTimeout(() => {
        this.getProductList(true)
      }, 600);
    });
  }

  // filter 产品状态选项选择
  handleFilterStatusChange = (status) =>{
    this.setState({
      filterStatus: status,
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getProductList(true)
      }, 600);
    });
  }

  // filter 产品状态选项移除
  handleFilterStatusRemove = () =>{
    this.setState({
      filterStatus: [],
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getProductList(true)
      }, 600);
    });
  }

  // filter 清除所有选项选择
  handleFiltersClearAll = () =>{
    this.setState({
      queryValue: '',
      filterStatus: [],
      selectedDates:{
        start: new Date(),
        end: new Date()
      },
      isSearchDate: false,
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getProductList(true)
      }, 600);
    });
  }

  // 搜索输入
  handleFiltersQueryChange = (value) =>{
    this.setState({
      queryValue: value,
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getProductList(true)
      }, 600);
    });
  }

  // 清空搜索内容
  handleQueryValueRemove =()=>{
    this.setState({
      queryValue: '',
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getProductList(true)
      }, 600);
    });
  }

  //时间插件(选择年份)
  handleMonthChange = (month, year) => {
    this.setState({
      month:month,
      year:year
    });
  }
  //时间插件(选择时间)
  setSelectedDates = (selectedDates) => {
    this.setState({
      selectedDates:selectedDates,
      isSearchDate: true,
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getProductList(true)
      }, 600);
    });
  }

  // 清空搜索时间
  handleFiltersDateRemove =()=>{
    this.setState({
      selectedDates:{
        start: new Date(),
        end: new Date()
      },
      isSearchDate: false,
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getProductList(true)
      }, 600);
    });
  }

  // 产品覆盖弹窗
  handleOverrideProductModal = (recordKey) =>{
    let data = this.state.productsData;
    let OverrideModal = (
      <OverrideProductModalComponet
        show={true}
        fatherKey={recordKey}
        shopifyProductTableId={data[recordKey].shopifyProductTableId}
        notifyProductTableId={data[recordKey].id}
        title={data[recordKey].title}
        image={data[recordKey].imageUrl}
        stockVariants={data[recordKey].VariantStock}
        EditOnShopify={data[recordKey].EditOnShopify}
        useInProduct={false}
      />
    );

    this.setState({
      OverrideProductModal: OverrideModal
    });
  }

  // 价格更新弹窗
  handleUpdatePriceModal = (recordKey) =>{
    let data = this.state.productsData;
    let PriceModal = (
      <UpdatePriceModalComponet
        notificationProductTableId={data[recordKey].id}
        shopifyProductTableId={data[recordKey].shopifyProductTableId}
        show={true}
        fatherKey={recordKey}
        title={data[recordKey].title}
        image={data[recordKey].imageUrl}
        stockVariants={data[recordKey].VariantStock}
        EditOnShopify={data[recordKey].EditOnShopify}
        variants={data[recordKey].productVariants}
        isSettingAutoUpdate={data[recordKey].isSettingAutoUpdate}
        isUpdateSuccess={data[recordKey].isUpdateSuccess}
        isCurrencyConvert={data[recordKey].isCurrencyConvert}
        symbol={data[recordKey].symbol}
        childUpdate={this.childContentChangesParentUpdate}
      />
    );

    this.setState({
      UpdatePriceModal: PriceModal
    });
  }

  // 更新库存弹窗（查看）
  handleUpdateInventoryModal = (recordKey) =>{
    let data = this.state.productsData;
    let InventoryModal = (
      <UpdateInventoryModalComponet
        notificationProductTableId={data[recordKey].id}
        shopifyProductTableId={data[recordKey].shopifyProductTableId}
        show={true}
        fatherKey={recordKey}
        title={data[recordKey].title}
        image={data[recordKey].imageUrl}
        stockVariants={data[recordKey].VariantStock}
        EditOnShopify={data[recordKey].EditOnShopify}
        variants={data[recordKey].productVariants}
        isSettingAutoUpdate={data[recordKey].isSettingAutoUpdate}
        isUpdateSuccess={data[recordKey].isUpdateSuccess}
        childUpdate={this.childContentChangesParentUpdate}
      />
    );

    this.setState({
      UpdateInventoryModal: InventoryModal
    });
  }

  // 更多操作 more action
  togglePopoverActive = () =>{
    this.setState(({ popoverActive }) => ({ popoverActive: !popoverActive }));
  }

  // 批量操作提示弹窗
  handleBulkOperationModal =(type)=>{
    let _title = type;
    let _message = type;
    let _more = this.state.selectAllMoreChecked?'+':'';

    // 统计操作类型的数量
    let data = this.state.productsData;
    let effectiveProductCount = 0;
    for(let i=0; i<data.length; i++){
      switch(type){
        case 'updateCost':
          _title = intl.get("Update Cost");
          if(data[i].checked===1 && data[i].productChangeStatus===3){
            effectiveProductCount++;
          }
          break;
        case 'updateInventory':
          _title = intl.get("Update Inventory");
          if(data[i].checked===1 && data[i].productChangeStatus===4){
            effectiveProductCount++;
          }
          break;
        case 'setZero':
          _title = intl.get("Set Quantity to Zero for Unavailable Product or Variants");
          if(data[i].checked===1 && data[i].productChangeStatus===1){
            effectiveProductCount++;
          }
          if(data[i].checked===1 && data[i].productChangeStatus===2){
            effectiveProductCount++;
          }
          break;
        case 'unPublish':
          _title = intl.get("Unpublish Unavailable Product");
          if(data[i].checked===1 && data[i].productChangeStatus===1){
            effectiveProductCount++;
          }
          break;
        case 'delProduct':
          _title = intl.get("Delete Unavailable Product");
          if(data[i].checked===1 && data[i].productChangeStatus===1){
            effectiveProductCount++;
          }
          break;
        case 'delVariant':
          _title = intl.get("Delete Unavailable Variant");
          if(data[i].checked===1 && data[i].productChangeStatus===2){
            effectiveProductCount++;
          }
          break;
        case 'ignore':
          _title = intl.get("Ignore Notification");
          if(data[i].checked===1){
            effectiveProductCount++;
          }
          break;
        default:
          effectiveProductCount = 0;
      }
    }

    if(effectiveProductCount < 2){
      _message = effectiveProductCount + ' ' + intl.get("notification record of this operation will be affected"); 
    }else{
      _message = effectiveProductCount + _more + ' ' + intl.get("notification records of this operation will be affected"); 
    }

    this.setState({
      BulkOperationType: type,
      BulkOperationTipTitle: _title,
      BulkOperationTipMessage: _message,
      EffectiveProductCount: effectiveProductCount
    },()=>{
      this.setState(({ BulkOperationTipsActive }) => ({ BulkOperationTipsActive: !BulkOperationTipsActive }));
    });
  }

  // 批量操作确认
  handleBulkOperationDone = async()=>{
    let params = '';
    let type = this.state.BulkOperationType;
    let getData = this.state.productsData;
    let data = [];

    for(let i=0; i<getData.length; i++){
      if(type === 'updateCost'){
        if(getData[i].checked===1 && getData[i].productChangeStatus===3){
          data.push(getData[i].id);
        }

      }else if(type === 'updateInventory'){
        if(getData[i].checked===1 && getData[i].productChangeStatus===4){
          data.push(getData[i].id);
        }

      }else if(type === 'setZero'){
        if(getData[i].checked===1 && getData[i].productChangeStatus===1){
          data.push(getData[i].id);
        }
        if(getData[i].checked===1 && getData[i].productChangeStatus===2){
          data.push(getData[i].id);
        }

      }else if(type === 'unPublish' || type === 'delProduct'){
        if(getData[i].checked===1 && getData[i].productChangeStatus===1){
          data.push(getData[i].id);
        }

      }else if(type === 'delVariant'){
        if(getData[i].checked===1 && getData[i].productChangeStatus===2){
          data.push(getData[i].id);
        }

      }else if(type === 'ignore'){
        if(getData[i].checked === 1){
          data.push(getData[i].id);
        }
      }
    }

    if(this.state.selectAllMoreChecked && data.length===0){  // 如果是选择用户所有数据，当前页面数据没有 也发送到到后台去
      data = [0,1];
    }else{
      if(data.length===0){
        this.toggleToast("No notification  update");
        return false;
      }
    }

    this.setState({
      BulkButtonLoading: true,
      isBulkOperationsRequest: true
    });

    // 设置请求参数
    params = {
      type: type,
      isBatch: this.state.selectAllMoreChecked?2:1,  // 1 页面记录批量操作 2：用户所有该类型的数据批量操作
      data: JSON.stringify(data),
      status: JSON.stringify(this.state.filterStatus),
    };

    // let apiUri = '';
    // if (this.state.tabSelected === 0) {
    //   apiUri = reqPostNotifyAction;
    // } else {
    //   apiUri = reqPostDropshipmanNotifyAction;
    // }

    // const response = await apiUri(params);
    const response = await reqPostDropshipmanNotifyAction(params);
    this.setState({
      BulkButtonLoading: false,
      isBulkOperationsRequest: false
    });
    
    if (response.code === "200") {

      this.handleBulkOperationModal('closed');
      this.toggleToast(response.msg, false);

      if(type !== 'ignore'){
        // 启动监听，并设置一直加载状态
        this.setState({
          isSearching: '1'
        });
        var bulkTimer = setInterval(function() { 
          this.GetBatchOperationProcess().then(function (response) {
            if(response === 200){
              clearInterval(bulkTimer);
            }
          })
        }.bind(this),2000);
      } else {
        // 重新加载数据
        this.getProductList(true);
      }

    }else{
      this.handleBulkOperationModal('closed');
      this.toggleToast(response.msg);
    }
  }

  // 监听批量操作是否完成
  GetBatchOperationProcess = async() =>{
    const params = {type: 2};
    const response = await reqGetBatchProcess(params);
    if (response.code === "200") {
      let result = response.data;
      if(result.isBatchLoading === 0){  // 结束
        setTimeout(() => {
          // 更新列表
          this.getProductList(true);
        }, 500);
        return 200
      }
    }else{
      this.toggleToast(response.msg);
      return 200
    }
  }

  // 跳转速卖通推广链接
  handleGetPromotionLink = async(link) => {
    if (!link) {
      return false;
    }
    let params = {
      link: link
    }
    const response = await reqGetAliExpressAffiliateLink(params)
    if (response.code === '200') {
      // 链接跳转
      const w=window.open('about:blank');
      w.location.href = response.data;

    }else{
      this.toggleToast(response.msg); 
    }
  }
  

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const {
      OverrideProductModal,UpdatePriceModal, UpdateInventoryModal,isSearching, productList,ShowProductNumber,checkedProductCount,tabSelected,
      EffectiveProductCount,isBulkOperationsRequest,BulkOperationTipsActive,BulkOperationTipTitle,BulkOperationTipMessage,BulkButtonLoading
    } = this.state;

    const TabSelectArray = [
      // {
      //   id: "tab-product-all",
      //   content: intl.get("AliExpress"),
      //   accessibilityLabel: "All Product Status",
      // },
      {
        id: "tab-dropshipman-all",
        content: intl.get("Dropshipman"),
        accessibilityLabel: "All Dropshipman Product Status",
      }
    ];

    const BulkOperationTipsModal = (
      <Modal 
        open={BulkOperationTipsActive} 
        onClose={()=>this.handleBulkOperationModal('closed')} 
        title={BulkOperationTipTitle}
        primaryAction={{
          content: intl.get("Done"),
          onAction: this.handleBulkOperationDone,
          disabled: (EffectiveProductCount>0)?false:true,
          loading: BulkButtonLoading
        }} 
        secondaryActions={[{
          content: intl.get("Cancel"),
          onAction: ()=>this.handleBulkOperationModal('closed')
        }]}
      >
          <Modal.Section>
             
              <p style={{whiteSpace:'pre-wrap'}}>
                {BulkOperationTipMessage}
              </p>
             
          </Modal.Section>
      </Modal>)

    const initSkeletonPage = <div>
     <Layout>
      <Layout.Section>
      <Card>
      <BlockStack gap={400}>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      </BlockStack>
      </Card>
    </Layout.Section>
      </Layout>
      </div>

  
    var headings = "";
    //判断是否有批量选择
    if(ShowProductNumber !== 0){
      if(checkedProductCount !== 0){

        // 全选本页面数据，如果数据库总量大于本页面显示数量，显示可选这全部数据
        let selectAllDataButton = <div></div>;

        if (this.state.checked) {
          if(this.state.total > checkedProductCount){

            if (this.state.selectAllMoreChecked) {
              selectAllDataButton = (
                <div className="Select_More">
                  <Button onClick={this.handleSelectAllMoreChecked.bind(this,false)} plain textAlign="left" >
                    {intl.get("All notifications in your store selected. Undo")}
                  </Button>
                </div>
              );

            } else {
              selectAllDataButton = (
                <div className="Select_More">
                  <Button onClick={this.handleSelectAllMoreChecked.bind(this,true)} plain textAlign="left" >
                    {/* {'Select all '+this.state.size +'+ notifications'} */}
                    {intl.get("Select all {{size}}+ notifications", this.state.size)}
                  </Button>
                </div>
              );
            }
          }
        }

        headings = (
          <div className="headings_div__1 notifications">
            <div className="headings_div__2">

              <div className="Polaris-ButtonGroup Polaris-ButtonGroup--segmented" data-buttongroup-segmented="true">

                <div className="Polaris-ButtonGroup__Item">
                  <div className="Polaris-CheckableButton Polaris-CheckableButton__CheckableButton--selectMode Polaris-CheckableButton__CheckableButton--selected">
                    <div className="headings_div__3">
                      <Checkbox
                        label=''
                        labelHidden
                        checked={this.state.checked?true:'indeterminate'}
                        onChange={this.handleAllCheckboxChange}
                      />
                    </div>
                    <span className="Polaris-CheckableButton__Label" onClick={this.handleAllCheckboxChange}>
                      {this.state.selectAllMoreChecked?checkedProductCount+'+ '+intl.get("selected"):checkedProductCount+ ' '+intl.get("selected")}
                    </span>
                  </div>
                </div>

                <div className="Polaris-ButtonGroup__Item">
                  <div className="Polaris-BulkActions__BulkActionButton">
                    <Button 
                      onClick={()=>this.handleBulkOperationModal('ignore')}
                      disabled={isBulkOperationsRequest}
                    >
                      {intl.get("Ignore")}
                    </Button>
                  </div>

                </div>

                {/* 用户速卖通产品支持修改操作 */}
                { tabSelected === 0 && (
                  <div className="Polaris-ButtonGroup__Item">
                    <div className="Polaris-BulkActions__BulkActionButton">
                      <Popover 
                        active={this.state.popoverActive} 
                        activator={
                          <Button onClick={this.togglePopoverActive} disabled={isBulkOperationsRequest} disclosure>
                            {intl.get("More actions")}
                          </Button>
                        } 
                        onClose={this.togglePopoverActive}
                      >
                        <ActionList
                          items={[
                            {
                              content: intl.get("Update Cost"),
                              onAction: ()=>this.handleBulkOperationModal('updateCost'),
                            },
                            {
                              content: intl.get("Update Inventory"),
                              onAction: ()=>this.handleBulkOperationModal('updateInventory'),
                            },
                            {
                              content: intl.get("Set Quantity to Zero for Unavailable Product or Variants"),
                              onAction: ()=>this.handleBulkOperationModal('setZero'),
                            },
                            {
                              content: intl.get("Unpublish Unavailable Product"),
                              onAction: ()=>this.handleBulkOperationModal('unPublish'),
                            },
                            {
                              content: intl.get("Delete Unavailable Product"),
                              onAction: ()=>this.handleBulkOperationModal('delProduct'),
                            },
                            {
                              content: intl.get("Delete Unavailable Variant"),
                              onAction: ()=>this.handleBulkOperationModal('delVariant'),
                            },
                          ]}
                        />
                      </Popover>
                    </div>
                  </div>
                )}

              </div>
            </div>
            {selectAllDataButton}
          </div>
        )
      }
    }

    var footerContentMsg ='';
    if(this.state.total > 0){
      const startNum = (((this.state.page - 1) * this.state.size) + 1);
      const endNum   = (this.state.page * this.state.size>this.state.total)?this.state.total:this.state.page * this.state.size;
      // footerContentMsg = <div className="Pagination_Result__Footer">{"Showing " + startNum + " - "+ endNum + " of " + this.state.total + " results"}</div>;
      footerContentMsg = <div>{intl.get("Showing {{startNum}} - {{endNum}} of {{total}} results", startNum, endNum, this.state.total)}</div>;
    }

    const PaginationSettings = this.state.loadingPage ? '' :
      (ShowProductNumber !== 0) ?
        <div className="Polaris-New-Pagination">
          <Pagination
            label={footerContentMsg}
            hasPrevious = {this.state.hasPrevious}
            onPrevious={() => {
              this.setState(({page:this.state.page - 1}),()=>{
                  this.getProductList(true);
              });
            }}
            hasNext = {this.state.hasNext}
            onNext={() => {
              this.setState(({page:this.state.page + 1}),()=>{
                  this.getProductList(true);
              });
            }}
          />
        </div> : '';

    const filters = [
      {
        key: 'filterStatus',
        label: intl.get("Product status"),
        filter: (
          <ChoiceList
            title={intl.get("Product status")}
            titleHidden
            choices={[
              {label: intl.get("Product Unavailable"), value: 'Product Unavailable'},
              {label: intl.get("Variant Unavailable"), value: 'Variant Unavailable'},
              {label: intl.get("Cost Changes"), value: 'Cost Changes'},
              {label: intl.get("Inventory Changes"), value: 'Inventory Changes'},
            ]}
            selected={this.state.filterStatus || []}
            onChange={this.handleFilterStatusChange}
          />
        ),
        shortcut: true,
        hideClearButton: false,
      },
      {
        key: 'filterDate',
        label: intl.get("Date"),
        filter: (
          <DatePicker 
            month={this.state.month} 
            year={this.state.year} 
            onChange={this.setSelectedDates} 
            onMonthChange={this.handleMonthChange} 
            selected={this.state.selectedDates} 
            allowRange={true} 
            disableDatesAfter={new Date()} 
            multiMonth={true} 
          />
        ),
        shortcut: true,
        hideClearButton: false,
      },
    ];

    const appliedFilters = [];
    if (!isEmpty(this.state.filterStatus)) {
      const key = 'filterStatus';
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, this.state.filterStatus),
        onRemove: this.handleFilterStatusRemove,
      });
    }
    if (!isEmpty(this.state.selectedDates) && this.state.isSearchDate) {
      const key = 'filterDate';
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, this.state.selectedDates),
        onRemove: this.handleFiltersDateRemove,
      });
    }

    const EmptyProductList = (ShowProductNumber===0) ? (
      <div className="emptyWithImg">
        <EmptyState
          heading={intl.get("Notification List is Empty")}
          image="/empty.svg"
        >
          <p style={{fontSize:'14px'}}>{intl.get("We will notify you about changes to product(s) availability or price")}</p>
        </EmptyState>
      </div>
    ) : '';

    // 表头的显示
    const tableTheadTrContent = (headings !== '') ? (
      <tr>
        <th colSpan="6" style={{textAlign:'left',padding:'13px 0px 12px 17px'}}>
          {headings}
        </th>
      </tr>
    ) : (
      <tr>
        <th colSpan="2" style={{textAlign:'left',padding:'20px 0px 20px 25px'}}>
          <Checkbox
            label={intl.get("All")}
            labelHidden
            checked={false}
            onChange={this.handleAllCheckboxChange}
          />
        </th>
        <th>{intl.get("Product")}</th>
        <th>{intl.get("Update time")}</th>
        <th>{intl.get("Change status")}</th>
        <th>{intl.get("Action")}</th>
      </tr>
    );

    const ProductListTable = (ShowProductNumber > 0) ? (
      <section className="Notifications_Table" style={{position:'relative'}}>
        <Card>
          <table style={{width:'100%'}}>
            <thead>
              {tableTheadTrContent}
            </thead>
            <tbody>
            
              {productList}

            </tbody>
          </table>
        </Card>
        <div className="Notifications__Loading" style={{display: (isSearching==='1')?'block':'none'}}>
          <div className="Notifications__Loading_Image">
            <img style={{width:'32px',height:'32px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />
          </div>
        </div>
      </section>
    ) : ''

    const pageContent = this.state.loadingPage ? initSkeletonPage : <section>
      <Layout>
        <Layout.Section>
          <Card>
            <Tabs
              tabs={TabSelectArray}
              selected={this.state.tabSelected}
              onSelect={this.handleTabChange}
            >
                 
                <Filters
                  queryValue={this.state.queryValue}
                  queryPlaceholder={intl.get("Search")}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={this.handleFiltersQueryChange}
                  onQueryClear={this.handleQueryValueRemove}
                  onClearAll={this.handleFiltersClearAll}
                />
                
                {/* 数据为空显示 */}
                <div style={{position:'relative',height:'800px'}}>

                  {EmptyProductList}

                  <div className="Product__Loading" style={{display: (isSearching==='1' && ShowProductNumber===0)?'block':'none'}}>
                    <div className="Product__Loading_Image">
                      <img style={{width:'32px',height:'32px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />
                    </div>
                  </div>
                </div>

                 

              {/* 产品列表 */}
              {ProductListTable}
              
            </Tabs>
          </Card>
          
          {/* 分页 */}
          {PaginationSettings}

        </Layout.Section>
      </Layout>
    </section>

    return (
      <div>
        <Page 
          title={this.state.loadingPage ? '' : intl.get("Notifications")}
          fullWidth 
        >
          {toastMarkup}

          {/* 页面内容 */}
          {pageContent}
          
          {/* 产品覆盖弹窗 */}
          {OverrideProductModal}
          {/* 产品价格更新弹窗 */}
          {UpdatePriceModal}
          {/* 产品库存更新弹窗 */}
          {UpdateInventoryModal}
          {/* 批量操作弹窗提示 */}
          {BulkOperationTipsModal}
        </Page>
      </div>
    );

    function disambiguateLabel(key, value) {
      switch (key) {
        case 'filterDate':
          let startDate = (new Date(`${value.start}`).getFullYear()) + "/" + (new Date(`${value.start}`).getMonth()+1) + "/" + (new Date(`${value.start}`).getDate());
          let endDate = (new Date(`${value.end}`).getFullYear()) + "/" + (new Date(`${value.end}`).getMonth()+1) + "/" + (new Date(`${value.end}`).getDate());
          return intl.get("Date range:") + ` ${startDate} - ${endDate}`;
        case 'taggedWith':
          return intl.get("Tagged with") + ` ${value}`;
        default:
          return value;
      }
    }

    function isEmpty(value) {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else {
        return value === '' || value == null;
      }
    }
  }

}

export default withRouter(NotificationsClass);