import { Modal } from "@shopify/polaris";
import React, { useState } from "react";
import { reqPostProductManagementProductsDelete } from "../../../../api";

const Index = ({ 
    removeProduct,
    setRemoveProduct,
    selectedResources,
    import_status,
    allResourcesSelected,
    getPageData,
    getTabData,
    handleSelectionChange,
    toastFun
}) => {
  
  const [loading,setLoading] = useState(false);
  const handleClose = () => {
    setRemoveProduct((prev)=>({
      ...prev,
      open:false,
    }))
    setLoading(false)
  };
  const handleProductDelete = async()=>{
    setLoading(true)
    let params = {
      ids:selectedResources,
      import_status:import_status === 0 ? 1 : import_status === 1 ? 2 : 3,
      type:allResourcesSelected ? 1 : 0
    }
    try {
    let res = await reqPostProductManagementProductsDelete(params);
    if(res.code === '200'){
      setLoading(false)
      handleSelectionChange('page',false,0)
      toastFun('Removed Successfully')
      getPageData(import_status)
      getTabData(import_status)
      setRemoveProduct((prev)=>({
        ...prev,
        open:false,
      }))
    }
  } catch (error) {
      
  }
  }
  
  return (
    <div>
      <Modal
        open={removeProduct.open}
        onClose={handleClose}
        title={"Remove product" }
        secondaryActions={{
          onAction: () => {
            handleClose();
          },
          content: "Cancel",
        }}
        primaryAction={{
          onAction: () => {handleProductDelete()},
          loading: loading,
          destructive: true,
          content: "Remove",
        }}
      >
        <Modal.Section>
        <p style={{fontSize:'13px'}}>Are you sure to remove {selectedResources.length} {selectedResources.length > 1 ? 'products' : 'product'} from your product list?</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
