import {Modal} from "@shopify/polaris";
import React from "react";
import intl from "../../../../i18n/intl";
import { useHistory } from "react-router-dom";
import utils from "../../../../utils/utils"
const Index = ({open,onClose}) => {
  const router = useHistory()
  return (
    <div className="upgrade_new_feature">
      <Modal
        open={open}
        onClose={() => onClose()}
        title={intl.get('Plan reminder')}
        primaryAction={{
          content: intl.get("Go ti view"),
          onAction: () => {
            onClose()
            utils.routeListen('/admin/recommended-apps')
            utils.routeJump1(router,'/admin/recommended-apps')
          },
        }}
        secondaryActions={{
          content: intl.get("Close"),
          onAction: () => {
            onClose()
          },
        }}
      >
        <Modal.Section>
        <div style={{display:'flex',alignItems:'center'}}>
          <p style={{fontSize:'14px',marginLeft:'16px'}}>You are currently using Channelwill's package， please update your plan on the package
          page</p>
        </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
