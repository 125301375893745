import { Box, Checkbox, InlineStack, Modal } from '@shopify/polaris';
import React, { useState }  from 'react';
import { store } from '../../../../../store';
import { changePersonLevel } from '../../../../../slice';
import { reqPostBaseAction } from '../../../../../api';

const ReminderSuccessModal = ({reminderSuccessModal,setReminderSuccessModal})=> {
    const [checked, setChecked] = useState(false);
    const selectAgainClose = async () => {
        let getData = store.getState().languageReducer.subScriptData;
        if (checked) {
            try {
                const params = {
                  type: "expedite_sourcing",
                };
               let res = await reqPostBaseAction(params);
                if (res.code === "200") {
                  setReminderSuccessModal((prevValue)=>({
                      ...prevValue,
                      open:false
                  }))
                  store.dispatch(
                    changePersonLevel(
                      {
                        infoData: {
                          ...getData,
                          bfs_pop: { ...getData.bfs_pop, expedite_sourcing_pop: 0 },
                        },
                      },
                      true
                    )
                  );
                 
                }
              } catch (error) {}
        }else {
            setReminderSuccessModal((prevValue)=>({
                ...prevValue,
                open:false
            }))
       }
      };
    return (
            <Modal open={reminderSuccessModal.open} title="Sourcing expedited successfully" 
            onClose={()=>{setReminderSuccessModal((prevValue)=>({
                ...prevValue,
                open:false
            }))}}
            primaryAction={{content: 'Okay, I see', onAction: ()=>{selectAgainClose()}}}
            >
                <Modal.Section>
                    <Box>
                <InlineStack gap={200}>
                        <InlineStack wrap={false} gap={200}>
                            <Box>
                                <img src="/new-product/remind_success.png" alt="loading..." />
                            </Box>
                            <Box>
                                {/* <Box style={{fontWeight:650,color:"rgba(48, 48, 48, 1)",fontSize:'14px'}}>Expedited successfully</Box> */}
                                <p style={{color:"rgba(0, 0, 0, 1)",fontWight:450}}>Sourcing has been successfully expedited. Our product sourcing team will complete your request shortly!</p>
                            </Box>
                        </InlineStack>
                        
                        <Checkbox
                        label="Don't remind me next time"
                        checked={checked}
                        onChange={()=>{setChecked(!checked)}}
                        />
                         </InlineStack>
                    </Box>
                </Modal.Section>
                </Modal>
    );
}

export default ReminderSuccessModal;
