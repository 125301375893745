import dashboard from '../frontEnd/fr/dashboard.json'
import findProducts from '../frontEnd/fr/findProducts.json'
import common from '../frontEnd/fr/common.json'
import frameClass from '../frontEnd/fr/frameClass.json'
import sourcing from '../frontEnd/fr/sourcing.json'
import listProduct from '../frontEnd/fr/listProduct.json'
import orders from '../frontEnd/fr/orders.json'
import customService from '../frontEnd/fr/customService.json'
import siteOptimization from '../frontEnd/fr/siteOptimization.json'
import subscribe from '../frontEnd/fr/subscribe.json'
import setting from '../frontEnd/fr/setting.json'
import shoppingCart from '../frontEnd/fr/shoppingCart.json'
import message from '../frontEnd/fr/message.json'
import partnership from '../frontEnd/fr/partnership.json'
const fr =  {
    ...dashboard,
    ...findProducts,
    ...common,
    ...frameClass,
    ...sourcing,
    ...listProduct,
    ...orders,
    ...customService,
    ...siteOptimization,
    ...subscribe,
    ...setting,
    ...shoppingCart,
    ...message,
    ...partnership
};
export default fr;