import {
  ChoiceList,
  Filters,
  Box,
  Button,
  DatePicker,
  OptionList,
  TextField,
  InlineStack,
  useIndexResourceState,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect, useMemo } from "react";
import ProductTableBox from "./ProductTableBox";
import "./index.css";
import "../common/index.css";
const ProductFilterBox = ({
  selected,
  currentPath,
  queryParams,
  setQueryParams,
  getPageData,
  getTabData,
  listData,
  setListData,
  attributeData,
  setAttributeData,
  setProductStoreData,
  restartPolling,
  callBackFun,
  userPoints,
  setUserPoints,
  planData,
  setPlanData,
  setSourceLess,
  setEnoughPoint,
  setSourcingLimitModal,
  searchLoading,
  setSearchLoading,
  handleTogglePopup,
  setNoviceModal,
  setOtherImportParams,
  currentDsymbol
}) => {
  const currentDate = new Date();
  const [{ month, year }, setDate] = useState({
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
  }); // 添加日期
 // 使用 useMemo 缓存可选的订单列表，减少不必要的计算
 const selectableOrders = useMemo(() => {
  if (Number(selected) !== 2) {
    const filterCondition = ({ product_status, is_publish }) =>
      product_status !== 0 && is_publish !== 2;
    const filteredList = listData?.list?.filter(filterCondition);
    // 如果过滤后为空则回退到原始列表，否则用过滤结果
    return filteredList?.length === 0 ? listData?.list : filteredList;
  } else {
    const filter = ({ sourcing }) => sourcing !== 1;
    return listData?.list?.filter(filter);
  }
}, [listData?.list, selected]); // 明确依赖 listData.list 和 selected
const { selectedResources, allResourcesSelected, handleSelectionChange } =
useIndexResourceState(selectableOrders);
  const handleSourceChange = useCallback(
    (value) => {
      getPageData(selected, {
        source: value,
      });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  ); // 来源筛选项
  // 商品状态筛选
  const handleProductStatusChange = useCallback(
    (value) => {
      getPageData(selected, {
        product_status: value,
      });
      handleSelectionChange('page',false,0)
      // setProductStatus(value)
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 集合筛选
  const handleCollectionChange = useCallback(
    (value) =>{
      getPageData(selected, {
        collection: value,
      })
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 商品类型筛选
  const handleProductTypeChange = useCallback(
    (value) => {
      getPageData(selected, {
        product_type: value,
      });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 标签筛选
  const handleTagChange = useCallback(
    (value) => {
      getPageData(selected, {
        product_tag: value,
      });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 添加日期
  const handleAddDateChange = useCallback(
    (value) => {
      getPageData(selected, {
        start_date: value.start,
        end_date: value.end,
      });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
   
  // 添加日期
  const handleListedDateChange = useCallback(
    (value) => {
      getPageData(selected, {
        publish_start_date: value.start,
        publish_end_date: value.end,
      });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 月份改变
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );
  // 商品类型筛选移除
  const handleProductTypeRemove = useCallback(
    () => {
      getPageData(selected, {
        product_type: [],
      })
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 标签筛选移除
  const handleTagRemove = useCallback(
    () => {
      getPageData(selected, {
        product_tag: [],
      })
      handleSelectionChange('page',false,0)
    },
     
    [getPageData, selected,handleSelectionChange]
  );
  // 集合筛选移除
  const handleCollectionRemove = useCallback(
    () => {
      getPageData(selected, {
        collection: [],
      })
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 添加日期移除
  const handleAddDateRemove = useCallback(() => {
    getPageData(selected, {
      start_date: "",
      end_date: "",
    });
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange]);
  const handleListedDateRemove = useCallback(() => {
    getPageData(selected, {
      publish_start_date: "",
      publish_end_date: "",
    });
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange]);
  // 商品状态筛选移除
  const handleProductStatusRemove = useCallback(
    () => {
      handleSelectionChange('page',false,0)
      getPageData(selected, {
        product_status: [],
      })
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 来源筛选移除
  const handleSourceRemove = useCallback(
    () => {
      handleSelectionChange('page',false,0)
      getPageData(selected, {
        source: [],
      })
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 搜索
  const handleFiltersQueryChange = useCallback(
    (value) => {
      if (value.length > 225) {
        return;
      }
      
      setQueryParams((prev) => ({
        ...prev,
        q: value,
      }));
    },
    [setQueryParams]
  );
  // 搜索移除
  const handleQueryValueRemove = useCallback(() => {
    getPageData(selected, { page: 1, size: 20, q: "" });
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange]);

  // 总销售金额筛选
  const totalSaleChange = useCallback(
    (value) => {
      getPageData(selected, { sale_price_range: value });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  // 总销售金额移除
  const totalSaleRemove = useCallback(() => {
    getPageData(selected, { sale_price_range: [] });
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange]);

  // 销售数量筛选
  const totalSaleCountChange = useCallback(
    (value) => {
      getPageData(selected, { sale_count_range: value });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  const totalSaleCountRemove = useCallback(() => {
    getPageData(selected, { sale_count_range: [] });
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange]);
  // 价格筛选
  const priceChange = useCallback(
    (value) => {
      getPageData(selected, { price_range: value });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  const priceRemove = useCallback(() => {
    getPageData(selected, { price_range: [] });
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange]);

  // vender 筛选
  const vendorChange = useCallback(
    (value) => {
      // getPageData(selected,{search_vendor:value})
      setQueryParams((prev) => ({
        ...prev,
        search_vendor: value,
      }));
    },
    [setQueryParams]
  );
  const handleVender = (value) => {
    getPageData(selected, { search_vendor: value });
    handleSelectionChange('page',false,0)
  };
  const vendorRemove = useCallback(() => {
    getPageData(selected, { search_vendor: "" });
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange]);
  // status 筛选
  const statusChange = useCallback(
    (value) => {
      getPageData(selected, { search_status: value });
      handleSelectionChange('page',false,0)
    },
    [getPageData, selected,handleSelectionChange]
  );
  const statusRemove = useCallback(() => {
    getPageData(selected, { search_status: [] });
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange]);

  // 筛选清空
  const handleFiltersClearAll = useCallback(() => {
    if (Number(selected) === 0) {
      getPageData(selected, {
        page: 1,
        size: 20,
        import_status: 1,
        product_status: [],
        collection: [],
        product_type: [],
        product_tag: [],
        q: "",
        start_date: "",
        end_date: "",
        pid: "",
        source: [],
        sort_by: "",
        sort_order: "",
      });
    }
    if (Number(selected) === 1) {
      getPageData(selected, {
        page: 1,
        size: 20,
        collection: [],
        q: "",
        price_range: [],
        sale_count_range: [],
        sale_price_range: [],
        publish_start_date: "",
        publish_end_date: "",
        pid: "",
        source: [],
        sort_by: "",
        sort_order: "",
      });
    }
    if (Number(selected) === 2) {
      getPageData(selected, {
        page: 1,
        size: 20,
        search_vendor: "",
        is_next:'',
        search_status: [],
        searchTitle: "",
        sinceId: "",
        count: 0, // 将总产品数传入
      });
    }
    if (Number(selected) === 3) {
      getPageData(selected, {
        page: 1,
        size: 20,
        collection: [],
        q: "",
        pid: "",
        sort_by: "",
        sort_order: "",
      });
    }
    setSearchLoading(true);
    handleSelectionChange('page',false,0)
  }, [getPageData, selected,handleSelectionChange,setSearchLoading]);

  let filters = [];
  
  if (Number(selected) === 0) {
    filters = [
      {
        key: "source",
        label: "Source",
        filter: (
          <ChoiceList
            title="Source"
            titleHidden
            choices={[
              { label: "AliExpress", value: 1 },
              { label: "Dropshipman", value: 5 },
              // { label: "Woocommerce", value: 3 },
              { label: "Temu", value: 4 },
              // { label: "CJ", value: 5 },
            ]}
            selected={queryParams.source || []}
            onChange={handleSourceChange}
            allowMultiple
          />
        ),
        pinned: true,
        shortcut: true,
      },
      {
        key: "productStatus",
        label: "Product Status",
        filter: (
          <ChoiceList
            title="Product status"
            titleHidden
            choices={[
              { label: "For Sale", value: 1 },
              { label: "Offline", value: 2 },
              { label: "Re-edited", value: 5 },
              { label: "Sample Purchased", value: 6 },
              { label: "List Failed", value: 7 },
            ]}
            selected={queryParams.product_status || []}
            onChange={handleProductStatusChange}
            allowMultiple
          />
        ),
        pinned: true,
        shortcut: true,
      },
      {
        key: "collection",
        label: "Collections",
        filter: (
          <ChoiceList
            title="Collections"
            titleHidden
            choices={modifiedFilter(attributeData.collection)}
            selected={queryParams.collection || []}
            onChange={handleCollectionChange}
            allowMultiple
          />
        ),
        pinned: true,
        shortcut: true,
      },
      {
        key: "productType",
        label: "Type",
        filter: (
          <ChoiceList
            title="Type"
            titleHidden
            choices={modifiedFilter(attributeData.type)}
            selected={queryParams.product_type || []}
            onChange={handleProductTypeChange}
            allowMultiple
            />
          ),
          pinned: true,
          shortcut: true,
      },
      {
        key: "tag",
        label: "Tags",
        filter: (
          <ChoiceList
            title="Tags"
            titleHidden
            choices={modifiedFilter(attributeData.tags)}
            selected={queryParams.product_tag || []}
            onChange={handleTagChange}
            allowMultiple
            />
          ),
          pinned: true,
          shortcut: true,
      },
      {
        key: "addDate",
        label: "Date Added",
        filter: (
          <DatePicker
            month={month}
            year={year}
            onChange={handleAddDateChange}
            onMonthChange={handleMonthChange}
            selected={
              queryParams.start_date && queryParams.end_date
                ? { start: queryParams.start_date, end: queryParams.end_date }
                : undefined // 没有选择任何日期时传递 undefined
            }
            multiMonth
            allowRange
          />
        ),
        pinned: true,
      },
    ];
  }
  if (Number(selected) === 1) {
    filters = [
      {
        key: "source",
        label: "Source",
        filter: (
          <ChoiceList
            title="Source"
            titleHidden
            choices={[
              { label: "AliExpress", value: 1 },
              { label: "Dropshipman", value: 5 },
              // { label: "Woocommerce", value: 3 },
              { label: "Temu", value: 4 },
              // { label: "CJ", value: 5 },
            ]}
            selected={queryParams.source || []}
            onChange={handleSourceChange}
            allowMultiple
          />
        ),
        pinned: true,
        shortcut: true,
      },
      {
        key: "total_sales",
        label: "Total Sales",
        filter: (
          <OptionList
            onChange={totalSaleChange}
            options={[
              { label: symbolFun(0,'',currentDsymbol), value: 0 },
              { label: "Under "+symbolFun(10,'',currentDsymbol), value: 1 },
              { label: symbolFun(10,500,currentDsymbol), value: 2 },
              { label: symbolFun(500,2000,currentDsymbol), value: 3 },
              { label: symbolFun(2000,5000,currentDsymbol), value: 4 },
              { label: symbolFun(5000,10000,currentDsymbol), value: 5 },
              { label: "Over "+symbolFun(10000,'',currentDsymbol), value: 6 },
            ]}
            selected={queryParams.sale_price_range || []}
          />
        ),
        pinned: true,
      },
      {
        key: "sale_item",
        label: "Items Sold",
        filter: (
          <OptionList
            onChange={totalSaleCountChange}
            options={[
              { label: "0", value: 0 },
              { label: "Under 10", value: 1 },
              { label: "10-100", value: 2 },
              { label: "100-1000", value: 3 },
              { label: "1000-3000", value: 4 },
              { label: "3000-50000", value: 5 },
              { label: "Over 5000", value: 6 },
            ]}
            selected={queryParams.sale_count_range || []}
          />
        ),
        pinned: true,
      },
      {
        key: "price",
        label: "Price",
        filter: (
          <OptionList
            onChange={priceChange}
            options={[
              { label: "Under "+ symbolFun(10,'',currentDsymbol), value: 0 },
              { label: symbolFun(10,30,currentDsymbol), value: 1 },
              { label: symbolFun(30,60,currentDsymbol), value: 2 },
              { label: symbolFun(50,100,currentDsymbol), value: 3 },
              { label: symbolFun(100,300,currentDsymbol), value: 4 },
              { label: symbolFun(300,500,currentDsymbol), value: 5 },
              { label: "Over "+symbolFun(500,'',currentDsymbol), value: 6 },
            ]}
            selected={queryParams.price_range || []}
          />
        ),
        pinned: true,
      },
      {
        key: "collection",
        label: "Collections",
        filter: (
          <ChoiceList
            title="Collections"
            titleHidden
            choices={modifiedFilter(attributeData.collection)}
            selected={queryParams.collection || []}
            onChange={handleCollectionChange}
            allowMultiple
            />
          ),
          pinned: true,
          shortcut: true,
      },
      {
        key: "listedDate",
        label: "Listing Date",
        filter: (
          <DatePicker
            month={month}
            year={year}
            onChange={handleListedDateChange}
            onMonthChange={handleMonthChange}
            selected={
              queryParams.publish_start_date && queryParams.publish_end_date
                ? {
                    start: queryParams.publish_start_date,
                    end: queryParams.publish_end_date,
                  }
                : undefined // 没有选择任何日期时传递 undefined
            }
            multiMonth
            allowRange
          />
        ),
        pinned: true,
      },
    ];
  }
  if (Number(selected) === 2) {
    filters = [
      {
        key: "vender",
        label: "Vendor",
        filter: (
          <InlineStack gap={100} blockAlign="center">
            <TextField
              value={queryParams.search_vendor}
              onChange={vendorChange}
              autoComplete="off"
              labelHidden
            />
            <Button
              disabled={queryParams.search_vendor === ""}
              onClick={() => handleVender(queryParams.search_vendor)}
            >
              Apply
            </Button>
          </InlineStack>
        ),
        pinned: true,
        shortcut: true,
      },
      {
        key: "status",
        label: "Status",
        filter: (
          <OptionList
            onChange={statusChange}
            options={[
              { label: "Active", value: "ACTIVE" },
              // {label: 'ARCHIVED', value: 'ARCHIVED'},
              { label: "Draft", value: "DRAFT" },
            ]}
            selected={queryParams.search_status || []}
          />
        ),
        pinned: true,
      },
    ];
  }
  if (Number(selected) === 3) {
    filters = [
      {
        key: "collection",
        label: "Collections",
        filter: (
          <ChoiceList
            title="Collections"
            titleHidden
            choices={modifiedFilter(attributeData.collection)}
            selected={queryParams.collection || []}
            onChange={handleCollectionChange}
            allowMultiple
            />
          ),
          pinned: true,
          shortcut: true,
      },
    ];
  }

  const appliedFilters = [];
  if (!isEmpty(queryParams.source)) {
    const key = "source";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.source, attributeData,currentDsymbol),
      onRemove: handleSourceRemove,
    });
  }
  if (!isEmpty(queryParams.product_status)) {
    const key = "productStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.product_status, attributeData,currentDsymbol),
      onRemove: handleProductStatusRemove,
    });
  }
  if (!isEmpty(queryParams.collection)) {
    const key = "collection";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.collection, attributeData,currentDsymbol),
      onRemove: handleCollectionRemove,
    });
  }
  if (!isEmpty(queryParams.product_type)) {
    const key = "productType";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.product_type, attributeData,currentDsymbol),
      onRemove: handleProductTypeRemove,
    });
  }
  if (!isEmpty(queryParams.product_tag)) {
    const key = "tag";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.product_tag, attributeData,currentDsymbol),
      onRemove: handleTagRemove,
    });
  }

  if (queryParams.start_date && queryParams.end_date) {
    const key = "addDate";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams, attributeData,currentDsymbol),
      onRemove: handleAddDateRemove,
    });
  }
  if (queryParams.publish_start_date && queryParams.publish_end_date) {
    const key = "listedDate";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams, attributeData,currentDsymbol),
      onRemove: handleListedDateRemove,
    });
  }

  if (!isEmpty(queryParams.sale_price_range)) {
    const key = "total_sales";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.sale_price_range,attributeData,currentDsymbol),
      onRemove: totalSaleRemove,
    });
  }
  if (!isEmpty(queryParams.sale_count_range)) {
    const key = "sale_item";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.sale_count_range,attributeData,currentDsymbol),
      onRemove: totalSaleCountRemove,
    });
  }
  if (!isEmpty(queryParams.price_range)) {
    const key = "price";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.price_range,attributeData,currentDsymbol),
      onRemove: priceRemove,
    });
  }

  if (queryParams.search_vendor) {
    const key = "vender";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.search_vendor,attributeData,currentDsymbol),
      onRemove: vendorRemove,
    });
  }
  if (!isEmpty(queryParams.search_status)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryParams.search_status,attributeData,currentDsymbol),
      onRemove: statusRemove,
    });
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (queryParams.q !== "" && !searchLoading) {
        getPageData(selected, {
          page: 1,
          size: 20,
          pid: "",
          q: queryParams.q.trim(),
        });
        setSearchLoading(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);
  return (
    <Box>
      <Box className="product_filter">
        <Filters
          queryPlaceholder="Search products..."
          queryValue={queryParams.q}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleFiltersClearAll}
        >
          <Box paddingInlineStart="0">
            <Button
              // disabled={queryParams.q === ''}
              onClick={() => {
                getPageData(selected, {
                  page: 1,
                  size: 20,
                  pid: "",
                  q: queryParams.q.trim(),
                });
                setSearchLoading(true);
              }}
              size="micro"
              loading={searchLoading}
              icon={SearchIcon}
            >
              Search
            </Button>
          </Box>
        </Filters>
      </Box>
      <ProductTableBox
        handleTogglePopup={handleTogglePopup}
        currentPath={currentPath}
        requestParams={queryParams}
        listData={listData}
        setListData={setListData}
        getPageData={getPageData}
        getTabData={getTabData}
        attributeData={attributeData}
        setAttributeData={setAttributeData}
        setProductStoreData={setProductStoreData}
        selected={selected}
        restartPolling={restartPolling}
        callBackFun={callBackFun}
        loading={false}
        userPoints={userPoints}
        setUserPoints={setUserPoints}
        planData={planData}
        setPlanData={setPlanData}
        setSourceLess={setSourceLess}
        setEnoughPoint={setEnoughPoint}
        setSourcingLimitModal={setSourcingLimitModal}
        setNoviceModal={setNoviceModal}
        setOtherImportParams={setOtherImportParams}
        selectableOrders={selectableOrders}
        selectedResources={selectedResources}
        allResourcesSelected={allResourcesSelected} 
        handleSelectionChange={handleSelectionChange}
        setSearchLoading={setSearchLoading}
      />
    </Box>
  );
};
function disambiguateLabel(key, value, attributeData,currentDsymbol) {
  switch (key) {
    case "source":
      const source = [
        { label: "AliExpress", value: 1 },
        { label: "Dropshipman", value: 5 },
        // { label: "Woocommerce", value: 3 },
        { label: "Temu", value: 4 },
        // { label: "CJ", value: 5 },
      ];

      return (
        "Source: " +
        value
          ?.map(
            (val) =>
              source.find((status) => status.value === val)?.label || "Unknown"
          )
          .join(", ")
      );
    case "productStatus":
      const productStatus = [
        { label: "For Sale", value: 1 },
        { label: "Offline", value: 2 },
        { label: "Re-edited", value: 5 },
        { label: "Sample Purchased", value: 6 },
        { label: "List Failed", value: 7 },
      ];

      return (
        "Product status: " +
        value
          ?.map(
            (val) =>
              productStatus.find((status) => status.value === val)?.label ||
              "Unknown"
          )
          .join(", ")
      );
    case "collection":
      const collection = modifiedFilter(attributeData.collection);
      return value
        ?.map(
          (val) =>
            collection.find((status) => status.value === val)?.label ||
            "Unknown"
        )
        .join(", ");
    case "productType":
      const productType = modifiedFilter(attributeData.type);
      return value
        ?.map(
          (val) =>
            productType.find((status) => status.value === val)?.label ||
            "Unknown"
        )
        .join(", ");
    case "tag":
      const productTag = modifiedFilter(attributeData.tags);
      return value
        ?.map(
          (val) =>
            productTag.find((status) => status.value === val)?.label ||
            "Unknown"
        )
        .join(", ");
    case "addDate":
      let startDate = new Date(value.start_date);
      let endDate = new Date(value.end_date);

      if (value.start_date === value.end_date) {
        // Single day format
        return `Date Added: ${startDate.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })}`;
      } else {
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();

        // Base format without year
        const baseFormat = { month: "short", day: "numeric" };

        if (startYear === endYear) {
          // Same year - show year once at the end
          const startStr = startDate.toLocaleDateString("en-US", baseFormat);
          const endStr = endDate.toLocaleDateString("en-US", baseFormat);
          return `Date Added: ${startStr} - ${endStr}, ${startYear}`;
        } else {
          // Different years - show full date for both
          const fullFormat = { ...baseFormat, year: "numeric" };
          const startStr = startDate.toLocaleDateString("en-US", fullFormat);
          const endStr = endDate.toLocaleDateString("en-US", fullFormat);
          return `Date Added: ${startStr} - ${endStr}`;
        }
      }
    case "listedDate":
      let startDate1 = new Date(value.publish_start_date);
      let endDate1 = new Date(value.publish_end_date);

      if (value.publish_start_date === value.publish_end_date) {
        // Single day format
        return `Date Added: ${startDate1.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })}`;
      } else {
        const startYear = startDate1.getFullYear();
        const endYear = endDate1.getFullYear();

        // Base format without year
        const baseFormat = { month: "short", day: "numeric" };

        if (startYear === endYear) {
          // Same year - show year once at the end
          const startStr = startDate1.toLocaleDateString("en-US", baseFormat);
          const endStr = endDate1.toLocaleDateString("en-US", baseFormat);
          return `Listing Date: ${startStr} - ${endStr}, ${startYear}`;
        } else {
          // Different years - show full date for both
          const fullFormat = { ...baseFormat, year: "numeric" };
          const startStr = startDate1.toLocaleDateString("en-US", fullFormat);
          const endStr = endDate1.toLocaleDateString("en-US", fullFormat);
          return `Listing Date: ${startStr} - ${endStr}`;
        }
      }
    case "total_sales":
      const totalSales = [
        { label: symbolFun(0,'',currentDsymbol), value: 0 },
        { label: "Under "+symbolFun(10,'',currentDsymbol), value: 1 },
        { label: symbolFun(10,500,currentDsymbol), value: 2 },
        { label: symbolFun(500,2000,currentDsymbol), value: 3 },
        { label: symbolFun(2000,5000,currentDsymbol), value: 4 },
        { label: symbolFun(5000,10000,currentDsymbol), value: 5 },
        { label: "Over "+symbolFun(10000,'',currentDsymbol), value: 6 },
      ];
      
      return value
        ?.map(
          (val) =>
            totalSales.find((status) => status.value === val)?.label ||
            "Unknown"
        )
        .join(", ");
    case "sale_item":
      const saleItem = [
        { label: "0", value: 0 },
        { label: "Under 10", value: 1 },
        { label: "10-100", value: 2 },
        { label: "100-1000", value: 3 },
        { label: "1000-3000", value: 4 },
        { label: "3000-50000", value: 5 },
        { label: "Over 5000", value: 6 },
      ];

      return value
        ?.map(
          (val) =>
            saleItem.find((status) => status.value === val)?.label || "Unknown"
        )
        .join(", ");
    case "price":
      const priceData = [
        { label: "Under "+ symbolFun(10,'',currentDsymbol), value: 0 },
        { label: symbolFun(10,30,currentDsymbol), value: 1 },
        { label: symbolFun(30,60,currentDsymbol), value: 2 },
        { label: symbolFun(50,100,currentDsymbol), value: 3 },
        { label: symbolFun(100,300,currentDsymbol), value: 4 },
        { label: symbolFun(300,500,currentDsymbol), value: 5 },
        { label: "Over "+symbolFun(500,'',currentDsymbol), value: 6 },
      ];

      return value
        ?.map(
          (val) =>
            priceData.find((status) => status.value === val)?.label || "Unknown"
        )
        .join(", ");
    case "vender":
      return `Vendor: ${value}`;
    case "status":
      const statusData = [
        { label: "Active", value: "ACTIVE" },
        { label: "Draft", value: "DRAFT" },
      ];

      return (
        "Status: " +
        value
          ?.map(
            (val) =>
              statusData.find((status) => status.value === val)?.label ||
              "Unknown"
          )
          .join(", ")
      );

    default:
      return value;
  }
}
// 重置键值对
function modifiedFilter(data) {
  let modifiedData = [];
  if (data && data.length > 0) {
    modifiedData = data.map((item) => ({
      label: item.title.slice(0, 50),
      value: item.id,
    }));
  }

  return modifiedData;
}
const symbolFun = (value1,value2,currentDsymbol)=>{
  let text = '';
  if (currentDsymbol !== '' && value1 >=0) {
    text = currentDsymbol+value1;
  }
  if (currentDsymbol !== '' && value2) {
    text = currentDsymbol+value1 + '-' + currentDsymbol+value2;
  }
  return text;
}
function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}

export default ProductFilterBox;
