import React, { useCallback, useEffect, useState,useRef } from "react";
import {
  Button,
  // Card,
  Layout,
  BlockStack,
  Page,
  // ProgressBar,
  SkeletonBodyText,
  SkeletonPage,
  Toast,
} from "@shopify/polaris";
import {
  reqGetBlackFridayRaffle,
  reqGetSubcribeData,
  reqGetUserPoints,
  reqPostSubcribeData,
} from "../../../../api/index";
import { useHistory } from "react-router-dom";
// import CounponSuccess from "./CounponSuccess";
import BlackRaffleRuleModal from "./BlackRaffleRuleModal";
import BlackNotPointsModal from "./BlackNotPointsModal";
import BlackRaffleNotModal from "./BlackRaffleNotModal";
import BlackRaffleResultModal from "./BlackRaffleResultModal";
import BlackRafflelogModal from "./BlackRafflelogModal";
import Lotter from "./Lotter";
import utils from '../../../../utils/utils'
import intl from '../../../../i18n/intl'
import AppBridge from "../../../../utils/appBridge";
import { store } from "../../../../store";
import { changeBannerData } from "../../../../slice";
import TimeCloseBox from "../../../../components/TimeCloseBox"
const BlackFridayClass = () => {
  const router = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  
  const [subList, setSubList] = useState([
    {
      name: "PRO",
      dsc: "All necessary features for growing business",
      symbol: "$",
      title_icon: "/sub/fly.png",
      popular: "",
      price: "29.9",
      oldPrice: "$ 49",
      date: "Monthly",
      isFree: false,
      type: 1,
      button: "7-day free trial",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 29.9,
      loading: false,
      disabled: false,
      level: 1,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_active_time: 0,
      },
      dscList: [
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "299 Points",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "30 Sourcing Requests",
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "1000 Dropshipman Products",
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "1000 AliExpress Products",
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "1000 Temu Products",
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Automated Order Sourcing",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Automated Fulfillment",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Automated Product Pricing",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
      ],
    },
    {
      name: "PLUS",
      dsc: "Powerful features for scaling your business",
      symbol: "$",
      title_icon: "/sub/huojian.png",
      popular: "Recommend",
      price: "49.9",
      oldPrice: "$ 79",
      date: "Monthly",
      isFree: true,
      type: 1,
      button: "7-day free trial",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 29.9,
      loading: false,
      disabled: false,
      level: 2,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_active_time: 0,
      },
      dscList: [
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "499 Points",
          strong: true,
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "60 Sourcing Requests",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "5000 Droshipman Products",
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "5000 AliExpress Products",
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "5000 Temu Products",
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Automated Order Sourcing",
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Automated Product Pricing",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Automated Fulfillment",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Order Tracking",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Trending Products",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/blackFriday/sub_yes.png",
          icon_next: "/sub/ok_black_icon.png",
          p: "Remove China Label",
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
      ],
    },
  ]); //套餐列表
  const [currentTabData, setCurrentTabData] = useState([
    {
      title: "Points Raffle",
    },
    {
      title: "More Rewards",
    },
  ]); //黑五切换tab
  const [currentTab, setCurrentTab] = useState("Points Raffle"); //黑五切换tab
  const [pageLoading, setPageLoading] = useState(false); // 初始化
  const [raffleModal,setRaffleModal] = useState(false);//规则声明弹窗
  const [raffleLog,setRaffleLog] = useState(false)
  // 积分不足弹窗
  const [pointsNotEnough, setPointsNotEnough] = useState(false);
  // 未中奖弹窗
  const [raffleNotWin, setRaffleNotWin] = useState(false);
  // 抽奖结果数据
  const [raffleResult, setRaffleResult] = useState({
    open: false,
    level:0,
    record_id:'',
  });
  const handleTabToggle = (val) => {
    setCurrentTab(val);
    setRotation(-45);
    spinRef.current = -45;
    setSpinning(false);
    setRotateTimes(0);
  };
 // 获取当前时间
 const getCurrentTime = () => {
  const timestamp = Date.parse(new Date()) / 1000;
  return timestamp;
};
  const initData = () => {
    handleGetDiscount();
    getUserPoints();
    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  };
  // 获取积分
  const [userPoints,setUserPoints] = useState({})
  const getUserPoints = async ()=>{
    let res = await reqGetUserPoints()
    if(res?.code === '200') {
      setUserPoints(res?.data)
    }
  }
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      setPageLoading(true);
      if (getCurrentTime() >= 1733457600) {
        utils.routeListen('/admin/dashboard')
        utils.routeJump1(router,'/admin/dashboard')
      }else {
        initData();
      }
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line
  }, []);
  // 获取折扣数据
  const handleGetDiscount = async () => {
    let res = await reqGetSubcribeData();
    if (res.code === "200") {
      if (res.data.bill_level === 0 && res.data.level === 0) {
        setCurrentTab("30% OFF");
        setCurrentTabData([
          {
            title: "Points Raffle",
          },
          {
            title: "30% OFF",
          },
          {
            title: "More Rewards",
          },
        ]);
      } else {
        setCurrentTab("Points Raffle");
        setCurrentTabData([
          {
            title: "Points Raffle",
          },
          {
            title: "30% OFF",
          },
          {
            title: "More Rewards",
          },
        ]);
      }

      let currentSub = subList.map((it, k) => {
        let discount = it;
        for (let j = 0; j < res.data.package.length; j++) {
          if (k === j) {
            if (k === 0) {
              if (res.data.trial_days > 0 && (res.data.bill_level === 0 && res.data.level === 0)) {
                discount = {
                  ...discount,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                };
              }else if (res.data.bill_level === 1 || res.data.level === 1) {
                discount = {
                  ...discount,
                  button: "Current Plan",
                  disabled: true,
                  loading: false,
                };
              }else if (res.data.bill_level === 2 || res.data.level === 2) {
                discount = {
                  ...discount,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                };
              } else if (res.data.trial_days === 0) {
                discount = {
                  ...discount,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                };
              }
              discount = { ...discount, ...res.data.package[1] };
            }
            if (k === 1) {
              if (res.data.trial_days > 0 && (res.data.bill_level <2 && res.data.level < 2)) {
                discount = {
                  ...discount,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                };
              }if (res.data.bill_level === 2 || res.data.level === 2) {
                discount = {
                  ...discount,
                  button: "Current Plan",
                  disabled: true,
                  loading: false,
                };
              } else if (res.data.trial_days === 0)  {
                discount = {
                  ...discount,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                };
              }
              
              discount = { ...discount, ...res.data.package[2] };
            }
          }
        }
        return discount;
      });
      setSubList(currentSub);
    }
  };

  // 套餐订阅
  // 升级套餐
  const handleMageUpgrade = async (id,disabled) => {
    if (disabled) {
      return
    }
    let list = subList.map((item, ind) => {
      if (ind === id) {
        return { ...item, loading: true };
      } else {
        return { ...item, disabled: true };
      }
    });
    setSubList(list);
    try {
      let params = {
        package_id: id + 1,
      };
      let res = await reqPostSubcribeData(params);
      if (res.code === "200") {
        if (res?.data?.id !== "") {
          toggleToast(res.msg);
            let list1 = subList.map((item, ind) => {
              if (ind === id) {
                return { ...item, loading: false };
              } else {
                return { ...item, disabled: false };
              }
            });
            setSubList(list1);
            window.top.location.href = res.data.url;
          setShowToast(false);
        }
      } else {
        handleGetDiscount();
        let list2 = subList.map((item, ind) => {
          if (ind === id) {
            return { ...item, loading: false };
          } else {
            return { ...item, disabled: false };
          }
        });
        setSubList(list2);
        toggleToast(res.msg);
      }
    } catch (error) {}
  };
 
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toggleActive = useCallback(
    () => setShowToast((showToast) => !showToast),
    []
  );
  const handleGoDetail = () => {
    utils.routeListen('/admin/subscribe')
utils.routeJump1(router,'/admin/subscribe')
  };


  const [rotation, setRotation] = useState(-45);//初始旋转值
  const [spinning, setSpinning] = useState(false);
  const [rotateTimes,setRotateTimes] = useState(0)
  const spinRef = useRef(rotation);
  // A:2美金,B:5美金,C:10美金,D:PRO,E:PLUS,F:鼠标,G:背包,H:无
  const prizeMap = {//奖品对应角度范围
    "1-44": "G",
    "46-89": "A",
    "91-134": "F",
    "136-179": "B",
    "181-224": "C",
    "226-269": "D",
    "271-314": "E",
    "316-359": "H"
  }
  const  prizes = [
    {
    id: 1,
    text: 'Mouse',
    coupon:'',
    plan:'',
    image: '/blackFriday/black5_mouse.png',
    },
    {
    id: 2,
    coupon:'$2',
    text: '',
    plan:'',
    image: '/blackFriday/black5_coupon.png',
    },
    {
      id: 3,
      text: 'backpack',
      coupon:'',
      plan:'',
      image: '/blackFriday/black5_bag.png',
    },
    {
      id: 4,
      text: 'No Luck',
      coupon:'',
      plan:'',
      image: '',
    },
    {
      id: 5,
      text: '',
      plan:'Free <strong>Plus</strong> plan',
      coupon:'',
      image: '/blackFriday/black5_light.png',
    },
    {
      id: 6,
      text: '',
      plan:'Free <strong>Pro</strong> plan',
      coupon:'',
      image: '/blackFriday/black5_light.png',
    },
    {
      id: 7,
      coupon:'$10',
      text: '',
      plan:'',
      image: '/blackFriday/black5_coupon.png',
    },
    {
      id: 8,
      coupon:'$5',
      text: '',
      plan:'',
      image: '/blackFriday/black5_coupon.png',
    },
]

 // 模拟请求接口返回中奖结果
//  const fetchPrizeResult = () => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       const randomNumber = Math.floor(Math.random() * 8) + 1;  // 模拟随机中奖结果
//       resolve(randomNumber);
//     }, 200);  // 模拟接口延迟2秒
//   });
// };
  const spinWheel = async() => {
    if (userPoints.available_points < 100) {
      setPointsNotEnough(true);
      return;
    }
    if (spinning) return;
    setSpinning(true);
    try {
    const spinDegrees = 360 * 5; // 2000 to 4000 degrees spin
    setRotation((prevRotation) => prevRotation + spinDegrees);
    let startRotation = rotation + spinDegrees;
    // console.log('start:'  + startRotation);
    
    // Simulate spin end (timeout based on animation duration)
    const res = await reqGetBlackFridayRaffle();  // 接口返回中奖的索引
    // let res = await fetchPrizeResult()
    if (res.code === '200') {
      setUserPoints((prevValue)=>({
        ...prevValue,
        available_points:Number(userPoints.available_points) - 100
      }))
      let possibleRangeArr =  ['316-359'];
      // for (const [key, value] of Object.entries(prizeMap)) {
      //   if (value === res.data.reward_key) {
      //     possibleRangeArr = [key];  // 找到对应的键后更新数组
      //     break;  // 立即退出循环
      //   }
      // }
      Object.entries(prizeMap)
        .map(([key, value]) => {
          if (value === res.data.reward_key) {
            possibleRangeArr = [key]
            return key;
          }
          return key;
        })
        .filter(Boolean);
    // console.log(possibleRangeArr);
    const randomRangeStr =
      possibleRangeArr[Math.floor(Math.random() * possibleRangeArr.length)];
    // console.log(randomRangeStr);
    // 当前的旋转度数：
    // console.log(rotation);
    //获取随机旋转度数：
    const randomRangeArr = randomRangeStr.split("-");
    const randomAngle = Math.floor(
      Math.random() *
        (randomRangeArr[1] / 1 - 5 - (randomRangeArr[0] / 1 + 5)) +
        randomRangeArr[0] / 1
    );
    // console.log(randomAngle);
    let rotatesTimes = rotateTimes + 1;
      setRotateTimes(rotatesTimes)
      // setRotation(randomAngle);
      let finalRotation = randomAngle + spinDegrees + 360* rotatesTimes;
      if (startRotation >= finalRotation) {
        finalRotation = finalRotation + 360 * (Math.ceil((startRotation - finalRotation) / 360)) + 360;
      }
      setTimeout(() => {
        requestAnimationFrame(() => {
          setRotation(finalRotation);
        });
      }, 0);
     
      // }, 1000); // Adjust time to match animation duration
    setTimeout(() => {
      setSpinning(false);
      if (res.data.reward_key === 'H') {
        setRaffleNotWin(true)
      }else {
        setRaffleResult({open:true,level:res.data.reward_key,record_id:res.data.award_id,})
      }
    }, 5000); // Adjust time to match animation duration
  }else if (res.code === '429') {
    toggleToast('Frequency too fast')
    setTimeout(() => {
      setSpinning(false);
     
    }, 5000); 
  } else {
    store.dispatch(changeBannerData({bannerData:{
      show:true,
      desc:res.msg
    }}));
    setTimeout(() => {
      setSpinning(false);
     
    }, 5000); 
  }
  } catch (error) {
    setTimeout(() => {
      setSpinning(false);
     
    }, 5000); 
  }
  };
  // const spinWheel = async() => {
  //   if (userPoints.available_points < 100) {
  //     setPointsNotEnough(true);
  //     return;
  //   }
  //   if (spinning) return;
  //   setSpinning(true);
  //   const spinDegrees = 360 * 5; // 5圈
  //   spinRef.current += spinDegrees; // 更新旋转角度
  //   try {
  //   // console.log('start:'  + startRotation);
    
  //   // Simulate spin end (timeout based on animation duration)
  //   const res = await reqGetBlackFridayRaffle();  // 接口返回中奖的索引
  //   // let res = await fetchPrizeResult()
  //   if (res.code === '200') {
  //     const prizeKey = res.data.reward_key; // 获取中奖的键

  //       // 计算最终的旋转角度
  //       const finalRotation = calculateFinalRotation(spinDegrees, prizeKey);
  //        // 设置旋转角度
        
         
  //        requestAnimationFrame(() => {
  //         setRotation(finalRotation);
  //       });
  //     setUserPoints((prevValue)=>({
  //       ...prevValue,
  //       available_points:Number(userPoints.available_points) - 100
  //     }))
      
  //   // console.log(randomRangeStr);
  //   // 当前的旋转度数：
  //   // console.log(rotation);
  //   //获取随机旋转度数：
   
  //     // setRotation(randomAngle);
  //     // let finalRotation = randomAngle + spinDegrees + 360* rotatesTimes;
  //     // if (startRotation >= finalRotation) {
  //     //   finalRotation = finalRotation + 360 * (Math.ceil((startRotation - finalRotation) / 360)) + 360;
  //     // }
  //     // setTimeout(() => {
  //     //   setRotation(finalRotation);
  //     // }, 0);
     
  //     // }, 1000); // Adjust time to match animation duration
  //   setTimeout(() => {
  //     setSpinning(false);
  //     if (res.data.reward_key === 'H') {
  //       setRaffleNotWin(true)
  //     }else {
  //       setRaffleResult({open:true,level:res.data.reward_key,record_id:res.data.award_id,})
  //     }
  //   }, 5000); // Adjust time to match animation duration
  // }else if (res.code === '429') {
  //   toggleToast('Frequency too fast')
  //   setTimeout(() => {
  //     setSpinning(false);
     
  //   }, 5000); 
  // } else {
  //   store.dispatch(changeBannerData({bannerData:{
  //     show:true,
  //     desc:res.msg
  //   }}));
  //   setTimeout(() => {
  //     setSpinning(false);
     
  //   }, 5000); 
  // }
  // } catch (error) {
  //   setTimeout(() => {
  //     setSpinning(false);
     
  //   }, 5000); 
  // }
  // };

  // const calculateFinalRotation = (spinDegrees, prizeKey) => {
  //   // 根据奖品计算最终的旋转角度
  //   // 这里可以包含你根据奖品的逻辑
  //   const randomRangeStr = getPossibleRange(prizeKey);
  //   const randomRangeArr = randomRangeStr.split("-");
  //   const randomAngle = Math.floor(
  //     Math.random() *
  //       (randomRangeArr[1] / 1 - 5 - (randomRangeArr[0] / 1 + 5)) +
  //       randomRangeArr[0] / 1
  //   );
  //   // console.log(randomAngle);
  //   let rotatesTimes = rotateTimes + 1;
  //     setRotateTimes(rotatesTimes)
  //     let startRotation = spinRef.current;
  //     let finalRotation = randomAngle + spinDegrees + 360* rotatesTimes;
  //     if (startRotation >= finalRotation) {
  //       finalRotation = finalRotation + 360 * (Math.ceil((startRotation - finalRotation) / 360)) + 360;
  //     }

      
  //   return finalRotation;
  // };

  // const getPossibleRange = (prizeKey) => {
  //   // 逻辑获取对应奖品的角度范围
  //   let possibleRangeArr =  ['316-359'];
  //     // for (const [key, value] of Object.entries(prizeMap)) {
  //     //   if (value === res.data.reward_key) {
  //     //     possibleRangeArr = [key];  // 找到对应的键后更新数组
  //     //     break;  // 立即退出循环
  //     //   }
  //     // }
  //     Object.entries(prizeMap)
  //       .map(([key, value]) => {
  //         if (value === prizeKey) {
  //           possibleRangeArr = [key]
  //           return key;
  //         }
  //         return key;
  //       })
  //       .filter(Boolean);
  //   // console.log(possibleRangeArr);
   
  //   // 示例：
  //   return possibleRangeArr[Math.floor(Math.random() * possibleRangeArr.length)]; // 根据 prizeKey 返回的范围
  // };

  const handleRaffleLog = ()=>{
    setRaffleLog(true)
  }

  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={toggleActive}
      error={showToastIserror}
      duration={1200}
    />
  ) : null;
  let skeletonPage = (
    <SkeletonPage fullWidth={false} primaryAction>
    <Layout>
          <Layout.Section>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
        </Layout.Section>
          </Layout>
    </SkeletonPage>
  );

  let pageData = (
    <Page
    title={<div style={{fontSize:'20px'}} >{intl.get("BFCM 2024 Campaign")}</div>}
    backAction={{
      content: "BFCM 2024 Campaign",
      onAction: () => {
        utils.routeListen("/admin/dashboard");
        utils.routeJump1(router, "/admin/dashboard");
      },
    }}
    >
      <div className="black_friday_content">
        <div className="black_friday_content_footer">
          <div className={AppBridge.exist() ? "black_friday_content_top_sticky_app" : "black_friday_content_top_sticky"}>
        <div className="black_friday_content_top_banner">
          <div className="black_friday_content_top_banner_box">
          <div className="black_friday_content_top_banner_box_title">BFCM 2024</div>
          <div className="black_friday_content_top_banner_box_desc">Boost your Black Friday sales success! </div>
          <div className="black_friday_content_top_banner_box_time top_drawer_content_desc_time">
           <p>Valid: Nov 1 - Dec 5</p>
           <div><TimeCloseBox /></div>
          </div>
          <div className="black_friday_content_top_banner_box_img">
          <img src="/blackFriday/top_fixed_off.png" alt="Dropshipman"/>
          </div>
          </div>
        </div>
          <div className="friday_tab_content">
            <div className="friday_tab">
              {currentTabData.map((item, ind) => (
                <div
                  onClick={() => handleTabToggle(item.title)}
                  key={ind}
                  className={
                    currentTab === item.title
                      ? "friday_tab_item friday_tab_item_col"
                      : "friday_tab_item"
                  }
                >
                  {item.title}
                </div>
              ))}
            </div>
          
          </div>
          </div>
          <div className="black_friday_content_footer_content">
            {currentTab === "30% OFF" && (
              <div className="black_friday_subscribe">
                <div className="black_friday_subscribe_list">
                  {subList.map((item, ind) => (
                    <div key={ind} className="black_friday_subscribe_item">
                      <div className="friday_subscribe_type">
                        <span className="friday_subscribe_type_name">
                          {item.name}
                        </span>
                        {item.popular && (
                          <span className="friday_subscribe_type_recommend">
                            {item.popular}
                          </span>
                        )}
                      </div>
                      <div className="friday_subscribe_price">
                        <span className="friday_subscribe_symbol">
                          {item.symbol}
                        </span>
                        <span className="friday_subscribe_new_price">
                          {item.money > 0
                            ? Math.ceil(item.money * item.discount * 100) / 100
                            : item.money}
                        </span>
                        <span className="friday_subscribe_old_price">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 14.5L12 18.5M12 18.5L16 14.5M12 18.5V6.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                          $ {item.money}
                        </span>
                      </div>
                      <div className="friday_subscribe_discount">
                        <span className="friday_subscribe_discount_num">
                          {parseFloat((1 - item.discount) * 100).toFixed(0)}% off
                        </span>
                        <span className="friday_subscribe_discount_text">
                          {" "}
                          the first month
                        </span>
                      </div>
                      <div className="friday_subscribe_button">
                        <Button
                          disabled={item.disabled}
                          loading={item.loading}
                          onClick={() => {
                            handleMageUpgrade(ind,item.disabled);
                          }}
                        >
                          {item.button}
                        </Button>
                      </div>
                      <div className="friday_subscribe_dsc">
                        <ul>
                          {item.dscList.map((it, ind) => (
                            <li key={ind}>
                              <img src={it.icon} alt="dropshipman"></img>
                              <p>{it.p}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="friday_subscribe_details">
                        <span onClick={() => handleGoDetail()}>
                          View details
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {currentTab === "Points Raffle" && (
              <div className="black_friday_points">
                <div className="black_friday_points_top">
                  <div>Points per entry: 100  <span className="black_friday_points_top_line">&nbsp;|</span>  </div>
                  <div>
                  <img src="/blackFriday/icon_points.png" alt="Dropshipman" />
                    <span> Available points: {userPoints?.available_points}</span>
                    </div>
                  <div className="black_friday_points_line" onClick={()=>{
                     utils.routeListen("/admin/points");
                     utils.routeJump1(router, "/admin/points");
                  }}>Earn more points → </div>
                </div>
                <div className="black_friday_points_bottom">
                  <div className="black_friday_points_bottom_prize_draw">
                    <div className="prize_draw_content">
                    {/* <div className="prize_draw_content_box"></div> */}
                    <div className="wheel" style={{ transform: `rotateZ(${rotation}deg)` }}>
                    {prizes.map((prize, index) => (
                      <div key={index} className={`wheel-segment segment-${index}`}>
                        {prize.text !== '' && <div className={prize.image === '' ?  "wheel_item_text wheel_item_text_text" : "wheel_item_text"}>
                          {prize.image !== '' && <img src={prize.image} alt="Dropshipman" />}
                          <span>{prize.text}</span>
                        </div>}
                        {prize.coupon !== '' && <div className="wheel_item_coupon">
                          {prize.image !== '' && <img src={prize.image} alt="Dropshipman" />}
                          <span>{prize.coupon}</span>
                        </div>}
                        {prize.plan !== '' && <div className="wheel_item_plan">
                           {prize.image !== '' && <img src={prize.image} alt="Dropshipman" />}
                          <span dangerouslySetInnerHTML={{ __html: prize.plan }}></span>
                        </div>}
                      </div>
                    ))}
                  </div>
                  <div className="wheel_pointer">
                  <img src="/blackFriday/black5_dsm.png" alt="Dropshipman"/>
                  </div>
                    </div>
                    <div className="prize_draw_content_tip">
                      <div className="prize_draw_content_arrow">
                      <img src="/blackFriday/point_icon.png" alt="Dropshipman"/>
                      </div>
                      <div>
                        <Button onClick={spinWheel}>Spin</Button>
                      </div>
                      <div className="prize_draw_content_rule">
                        <span onClick={()=>setRaffleModal(true)}>Raffle Rules</span>
                        <span onClick={()=>handleRaffleLog()}>Prize Records</span>
                      </div>
                    </div>
                  </div>
                  <div className="black_friday_points_rotating-text">
                  <div className='rotating-text'>
                  <Lotter pageLoading={pageLoading}/>
                </div>
                </div>
                </div>
              </div>
            )}
            {currentTab === "More Rewards" && (
              <div className="black_friday_cooperate">
                <div className="black_friday_cooperate_content">
                  <div className="black_friday_cooperate_list">
                    {moreEvent.map((item, ind) => (
                      <div key={ind} className="black_friday_cooperate_item">
                        <div className="black_friday_cooperate_item_top">
                          <div className="black_friday_cooperate_item_top_icon">
                            {" "}
                            <img src={item.icon} alt="dropshipman" />
                          </div>
                          <div className="black_friday_cooperate_item_top_title">
                            {item.title}
                          </div>
                        </div>
                        <div className="black_friday_cooperate_item_mid">
                          {item.dsc}
                        </div>
                        <div className="black_friday_cooperate_item_bottom">
                        <a href={item.link} target='_blank' rel="noopener noreferrer"><span>{item.button}</span></a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {toastMarkup}
      {/* <CounponSuccess
        open={tips}
        handleOff={() => setTips(false)}
        text={tipText}
      /> */}
      {/* 中奖提示 */}
      <BlackRaffleResultModal raffleResult={raffleResult} handleOff={()=>setRaffleResult(false)}/>
      {/* 未中奖提示 */}
      <BlackRaffleNotModal open={raffleNotWin} handleOff={() => setRaffleNotWin(false)}/>
      {/* 无积分提示 */}
      <BlackNotPointsModal open={pointsNotEnough} handleOff={() => setPointsNotEnough(false)}/>
      {/* 抽奖规则 */}
      <BlackRaffleRuleModal open={raffleModal} handleBlackRuleModalOff={() => setRaffleModal(false)}/>
        {/* 抽奖记录 */}
      <BlackRafflelogModal open={raffleLog} handleOff={() => setRaffleLog(false)}/>
    </Page>
  );
  let contentPage = pageLoading ? skeletonPage : pageData;
  return contentPage;
};
const moreEvent = [
  {
    icon: "/blackFriday/more_event_24_0.png",
    title: "PageFly Page Builder",
    dsc: "40% Off all plans",
    button: "View Details",
    link:'https://pagefly.io/?ref=n93ysRBS&target=app-listing'
  },
  {
    icon: "/blackFriday/more_event_24_1.png",
    title: "UpPromote Affiliate & Referral",
    dsc: "20% off on all paid plans for new users during Black Friday & Cyber Monday!",
    button: "View Details",
    link:'https://partners.secomapp.com/apps/20off/affiliate/BFCM2024_PARTNERS'
  },
  {
    icon: "/blackFriday/more_event_24_2.png",
    title: "GemPages",
    dsc: "Free BFCM Landing Page & CRO Cookbook. 10 Free Pre-designed CRO Templates. 20% Discount on the Optimized Plan.",
    button: "View Details",
    link:'https://gempages.net/pages/bfcm-landing-page?utm_source=dropshipman&utm_medium=referral&utm_campaign=bfcm2024'
  },
  {
    icon: "/blackFriday/more_event_24_3.png",
    title: "BSS: Product Labels & Badges",
    dsc: "DSM2024 - Offer 25% off for first 3 months all paid plan.",
    button: "View Details",
    link:'https://apps.shopify.com/product-labels-by-bss?utm_source=dsm&utm_medium=bfcm2024&utm_campaign=partnership2024'
  },
  {
    icon: "/blackFriday/more_event_24_4.png",
    title: "SearchPie SEO & Speed optimize",
    dsc: "50% OFF ALL PLAN",
    button: "View Details",
    link:'https://partners.secomapp.com/apps/searchpie/DSMSP'
  },
  {
    icon: "/blackFriday/more_event_24_5.png",
    title: "Searchanise Search & Filter",
    dsc: "20% off on all yearly plans (for new users)",
    button: "View Details",
    link:'https://apps.shopify.com/searchanise?utm_source=partners&utm_medium=dropshipman&utm_campaign=BFCM24'
  },
  {
    icon: "/blackFriday/more_event_24_6.png",
    title: "AIOD - BFCM Discount Manager",
    dsc: "15 Day Free Trial",
    button: "View Details",
    link:'https://apps.shopify.com/all-in-one-automatic-discounts?utm_source=bfcmprogram2024&utm_medium=dropshipman&utm_campaign=partnership'
  },
  {
    icon: "/blackFriday/more_event_24_7.png",
    title: "BOGOS: Free Gift, Discounts & Bundles",
    dsc: "20% OFF First 3 Months",
    button: "View Details",
    link:'https://app.freegifts.io/core/promotion?coupon=BG_BFCM_2024&redirect_to=https%3A%2F%2Fapps.shopify.com%2Ffreegifts%3Futm_source%3DDropshipman%26utm_medium%3DApp%26utm_campaign%3DBFCM'
  },
  {
    icon: "/blackFriday/more_event_24_8.png",
    title: "Consentmo GDPR Compliance",
    dsc: "20% Off for All Paid Plans",
    button: "View Details",
    link:'https://bit.ly/3Nn4RcT'
  },
  {
    icon: "/blackFriday/more_event_24_9.png",
    title: "iCart Cart Drawer Cart Upsell",
    dsc: "Lifetime 20% OFF on monthly bill and yearly bill for lifetime",
    button: "View Details",
    link:'https://icart.identixweb.com/icart/?app_discount=YfeGuwzayySxtiYQAPd9bnk2cdwIeowpf1S2e&action=hyperlink&content=bfcm-shopify-apps-2023&term=icart-cart-drawer-cart-upsell'
  },
  {
    icon: "/blackFriday/more_event_24_10.png",
    title: "Synctrack PayPal Tracking Sync",
    dsc: "30% OFF for 3 months",
    button: "View Details",
    link:'https://apps.shopify.com/synctrack?utm_campaign=partner&utm_source=dropshipman&utm_medium=bfcm2024'
  },
  {
    icon: "/blackFriday/more_event_24_11.png",
    title: "Adoric Popup & Email Popups",
    dsc: "30% off on all paid plans for 6 months for new users",
    button: "View Details",
    link:'https://apps.shopify.com/adoric-popups?utm_source=DSM_BFCM_Campaign&utm_medium=partnership&utm_campaign=DSM_BFCM_Campaign'
  },
  {
    icon: "/blackFriday/more_event_24_12.png",
    title: "AOV Bundle Upsell",
    dsc: "40% off in 3 months",
    button: "View Details",
    link:'https://apps.shopify.com/aov-bundle-upsell?utm_source=DSM&utm_medium=BFCM2024&utm_campaign=bundle_partnership'
  },
  {
    icon: "/blackFriday/more_event_24_13.png",
    title: "Omega Facebook Pixel Ad Report",
    dsc: "30% 3 months ",
    button: "View Details",
    link:'https://apps.shopify.com/facebook-multi-pixels?utm_campaign=partner&utm_source=dropshipman&utm_medium=bfcm2024'
  },
];

export default BlackFridayClass;
