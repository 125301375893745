import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  // Image,
  Page,
  Button,
  Icon,
  Tabs,
  Layout,
  Select,
  TextField,
  ButtonGroup,
  Tooltip,
  Filters,
  ChoiceList,
  Checkbox,
  Pagination,
  Modal,
  Toast,
  Banner,
  EmptyState,
  Box,
  InlineStack
} from "@shopify/polaris";
import { SearchIcon,QuestionCircleIcon,PlayCircleIcon } from "@shopify/polaris-icons";
import DatePicker from "react-datepicker";
import intl from "../../../../i18n/intl";
import OrderItem from "./OrderItem";
import OrderFulfillment from "./OrderFulfillment";
import BulkPaymentModal from "./Modal/BulkPaymentModal";
import ArchiveOrderModal from "./Modal/ArchiveOrderModal";
import BatchSourcingOrderModal from "./Modal/BatchSourcingOrderModal";
import OneSourcingOrderModal from "./Modal/OneSourcingOrderModal";
import SourcingOrderTipModal from "./Modal/SourcingOrderTipModal";
import PayForOrderModal from "./Modal/PayForOrderModal";
import WalletForOrderModal from "./Modal/WalletForOrderModal";
import SearchLoading from "../../../../components/SearchLoading";
import DlocalPayTipModal from "./Modal/DlocalPayTipModal";
import OrderInventoryModal from "./Modal/OrderInventoryModal";
import dayjs from "dayjs";
import "./index.css";
import { useLocation, withRouter } from "react-router-dom";
import { useGetState } from "ahooks";
import JSZip from "jszip";
import FileSaver from "file-saver";
import {
  reqGetOrderListData,
  reqPostBaseAction,
  reqPostConnectOrderId,
  reqPostPaymentProcess,
  reqPostSetOrderArchivedConfig,
  reqPostUpdateOrderBatchSourcing,
  reqWalletTransaction,
} from "../../../../api";
import LoadingPage from "../../ImportList/components/LoadingPage";
import _ from "lodash";
import NotSourcingCompleteModal from "./Modal/NotSourcingCompleteModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Axios from "axios";
import utils from "../../../../utils/utils";
import { store } from '../../../../store';
import { changePersonLevel } from "../../../../slice";
import NoviceOrderModal from '../../../../components/NoviceGuidance/NoviceOrderModal'
import PayoneerBalancesConnect from "../../../../components/PayoneerBalancesConnect";
import PayoneerBalancesOrderSelect from "../../../../components/PayoneerBalancesOrderSelect";
import PayoneerBalancesOrderConfirm from "../../../../components/PayoneerBalancesOrderConfirm";
import PayoneerBalancesAgain from "../../../../components/PayoneerBalancesAgain";
import DropshipmanOrdersTrackingList from "../../DropshipmanOrdersTracking/components/DropshipmanOrdersTrackingList";
import ShippingDifferenceList from "../../ShippingDifference/components/ShippingDifferenceList";


import AppBridge from "../../../../utils/appBridge";
import { connect, useSelector} from "react-redux";
function disambiguateLabel(key, value) {
  switch (key) {
    case "paymentStatus":
      return `${value}`;
    case "fulfillmentStatus":
      return `${value}`;
    default:
      return value.toString();
  }
}

function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}

const OrderListClass = ({handleNewPersonSide}) => {
  const [selectedTab, setSelectedTab] = useState(0); //记录当前选择tab
  const [batchChecked, setBatchChecked] = useState(false); //记录是否批量选中
  const [isInit, setIsInit] = useState(true); // 是否显示骨架屏
  const [listLoading, setListLoading] = useState(false); //列表刷新
  const [paymentStatus, setPaymentStatus] = useState([]); //支付状态筛选值
  const [fulfillmentStatus, setFulfillmentStatus] = useState([]); //根据状态搜索
  const [productWork, setProductWork] = useState("keyword"); //根据订单类型搜索
  const [loading, setLoading] = useState(false); // 搜索loading
  const [merchantBanner, setMerchantBanner] = useState(false); // 横幅显示
  const [orderPageData, setOrderPageData] = useGetState({}); //页面数据
  const [orderItemPageData, setOrderItemPageData] = useGetState([]); //页面数据
  const [noviceModal,setNoviceModal] = useState(false);//引导视频
  const [upgradeOpen,setUpgradeOpen] = useState(false);
  const [showGuide,setShowGuide] = useState(false)//侧边展示
  const [syncData, setSyncData] = useState({
    SyncStoreOrdersModalOpen: false, //显示弹窗
    asyncStoreOrderTotal: 0, //显示订单数量
    disabledSync: false,
    SyncBtnLoading: false, //加载状态
    showToast: false,
    isSyncFinish: false,
    SyncCompletedData: {}, //完成同步订单数据
    showSyncBanner: false, //是否显示横幅
    finishAsyncStoreOrderCount: 0,
    synchroStatus: 1, //同步数量及状态
    synchroCount: 0,
    SyncCompletedModalOpen: false, //同步成功
  });
  const [toPage,setToPage] = useState('')
  const [
    shopifyOrderRefundModalData,
    setShopifyOrderRefundModalData,
    getShopifyOrderRefundModalData,
  ] = useGetState({
    shopifyOrderRefundModal: false, //shopify已退款订单批量支付提醒
    shopifyRefundOrderData: {}, //shopify退款订单数据
  });
  const appliedFilters = [];
  const [searchParam, setSearchParam, getSearchParam] = useGetState({
    tab: "All",
    payment_status: [],
    fulfillment_status: [],
    search_type: "keyword",
    query: "",
    start: "",
    end: "",
    page: 1,
    size: 50,
    sort_by: "",
  }); //请求参数
  const [searchButton, setSearchButton] = useState({
    isDisableSearch: true,
    isDisableClear: true,
    isSearching: "0",
  });
  //订单支付操作需要数据
  const [paymentData, setPaymentData, getPaymentData] = useGetState({
    toPayOrderId: "",
    toPayOrderName: "",
    toPayOrderType: 0, // 订单类型：1 shopify 2 礼品包装 3 产品批发
    toPayActive: false,
    createPayPalOrderId: 0,
    toPayCouponId: 0,
    isPoints: false, //是否使用积分
    toPayCouponMoney: 0,
    isBatchToPay: false, //批量付款
    batchPayOrderIds: [],
    handleWalletActiveLoading: false, //钱包确定支付按钮
    toCheckoutLoading: false, //钱包支付加载状态
    payLogId: 0, // 执行支付记录ID
    operationNumber: 0,
    currentExecutionsNumber: 0,
    noFreeShippingTipsActive: false, //不包邮订单提示物流选择
    noFreeShippingOrder: "",
    // 提示订单地址异常不能付款
    addressFailedModal: false,
    addressFailedOrder: "",
    setOrderStatusIds: [], // 更改订单地址异常状态
    addressFailedLoading: false,
    isShowBulkPaymentModal: false, //批量支付弹窗
    totalPrice: 0, //价格钱包支付计算产品总价
    totalShippingCost: 0, //运费钱包支付计算产品总价
    isNotSourcing: false, //订单sku未sourcing完成弹窗
    user_cpf:'',//巴西税号
  });
  const history = useHistory();
  const [payoneerConnectOpen, setPayoneerConnectOpen] = useState(false);//payoneer 连接弹窗
  const [payoneerBalances,setPayoneerBalances] = useState({
    data:{},
    open:false,
    ids:[],
    coupon_id:0,
    isPoints:false
  })
  const language = useSelector(state => state.languageReducer.language);
 let defalutData = useMemo(()=>{
    let data = [
      {
        id: "all-order-list",
        content: intl.get("All"),
        title: "All",
        accessibilityLabel: "All orderList",
      },
      {
        id: "pending-sourcing-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Pending Sourcing`) + "(" + 0 + ")"}
        {
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('Orders are in the process of sourcing.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
        }
      </div>,
        title: "Pending Sourcing",
        accessibilityLabel: "Pending Sourcing orderList",
      },
      {
        id: "unpaid-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Unpaid`) + "(" + 0 + ")"}
        {
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('Orders pending payment.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
        }
      </div>,
        title: `Unpaid`,
        accessibilityLabel: "Unpaid orderList",
      },
      {
        id: "in-processing-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`In Processing`) + "(" + 0 + ")"}
        {
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('The order has been paid and is being processed by DSM.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
        }
      </div>,
        title: `In Processing`,
        accessibilityLabel: "In processing orderList",
      },
      {
        id: "fulfilled-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Fulfilled`) + "(" + 0 + ")"}
        {
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('DSM has completed order fulfillment and the order is in transit.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
        }
      </div>,
        title: `Fulfilled`,
        accessibilityLabel: "Fulfilled orderList",
      },
      {
        id: "issues-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Issues`) + "(" + 0 + ")"}
        {
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('Order information is abnormal.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
        }
      </div>,
        title: `Issues`,
        accessibilityLabel: "Issues orderList",
      },
      {
        id: "archived-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Archived`) + "(" + 0 + ")"}
        
      </div>,
        title: `Archived`,
        accessibilityLabel: "Archived orderList",
      },
      // eslint-disable-next-line
    ]
    if (language) {
      data = [
        {
          id: "all-order-list",
          content: intl.get("All"),
          title: "All",
          accessibilityLabel: "All orderList",
        },
        {
          id: "pending-sourcing-order-list",
          content:<div className="remarkcontainer">
          {intl.get(`Pending Sourcing`) + "(" + 0 + ")"}
          {
            <div className="remarkarea">
              <Tooltip preferredPosition="above" content={intl.get('Orders are in the process of sourcing.')}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          }
        </div>,
          title: "Pending Sourcing",
          accessibilityLabel: "Pending Sourcing orderList",
        },
        {
          id: "unpaid-order-list",
          content:<div className="remarkcontainer">
          {intl.get(`Unpaid`) + "(" + 0 + ")"}
          {
            <div className="remarkarea">
              <Tooltip preferredPosition="above" content={intl.get('Orders pending payment.')}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          }
        </div>,
          title: `Unpaid`,
          accessibilityLabel: "Unpaid orderList",
        },
        {
          id: "in-processing-order-list",
          content:<div className="remarkcontainer">
          {intl.get(`In Processing`) + "(" + 0 + ")"}
          {
            <div className="remarkarea">
              <Tooltip preferredPosition="above" content={intl.get('The order has been paid and is being processed by DSM.')}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          }
        </div>,
          title: `In Processing`,
          accessibilityLabel: "In processing orderList",
        },
        {
          id: "fulfilled-order-list",
          content:<div className="remarkcontainer">
          {intl.get(`Fulfilled`) + "(" + 0 + ")"}
          {
            <div className="remarkarea">
              <Tooltip preferredPosition="above" content={intl.get('DSM has completed order fulfillment and the order is in transit.')}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          }
        </div>,
          title: `Fulfilled`,
          accessibilityLabel: "Fulfilled orderList",
        },
        {
          id: "issues-order-list",
          content:<div className="remarkcontainer">
          {intl.get(`Issues`) + "(" + 0 + ")"}
          {
            <div className="remarkarea">
              <Tooltip preferredPosition="above" content={intl.get('Order information is abnormal.')}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          }
        </div>,
          title: `Issues`,
          accessibilityLabel: "Issues orderList",
        },
        {
          id: "archived-order-list",
          content:<div className="remarkcontainer">
          {intl.get(`Archived`) + "(" + 0 + ")"}
          
        </div>,
          title: `Archived`,
          accessibilityLabel: "Archived orderList",
        },
        // eslint-disable-next-line
      ]
    }
    return data;
  },[language]);
  const [tabs,setTabs] = useState(defalutData)
  // 获取列表数据
  const getOrderListData = async (params) => {
    try {
      setListLoading(true);
      
      let res = await reqGetOrderListData({
        ...params,
        start: params.start ? dayjs(params.start).format("YYYY-MM-DD") : "",
        end: params.end ? dayjs(params.end).format("YYYY-MM-DD") : "",
      });
      setLoading(true);
      if (res.code === "200") {
        setPaymentData((prevValue)=>({
          ...prevValue,
          user_cpf:res.data.user_cpf,
          is_br:res.data.is_br
        }))
        setShowGuide(res.data.show_guide === 1)
        let person = store.getState().languageReducer?.subScriptData;
        if (Object.values(person).length > 0) {
          if (person?.bfs_pop?.is_infringement_pop) {
            setMerchantBanner(false)
          }else{
            setMerchantBanner(true)
          }
        }
        history.replace("/admin/order_list");
        setListLoading(false);
       
        setOrderPageData(res.data);
        if (!_.isEqual( res.data.summary ,orderPageData.summary)) {
          setTabs([
          {
            id: "all-order-list",
            content: intl.get("All"),
            title: "All",
            accessibilityLabel: "All orderList",
          },
          {
            id: "pending-sourcing-order-list",
            content:<div className="remarkcontainer">
            {intl.get(`Pending Sourcing`) + "(" + res.data.summary?.pendingSourcingCount + ")"}
              <div className="remarkarea">
                <Tooltip preferredPosition="above" content={intl.get('Orders are in the process of sourcing.')}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
          </div>,
            title: "Pending Sourcing",
            accessibilityLabel: "Pending Sourcing orderList",
          },
          {
            id: "unpaid-order-list",
            content:<div className="remarkcontainer">
            {intl.get(`Unpaid`) + "(" + res.data.summary?.unpaidCount + ")"}
              <div className="remarkarea">
                <Tooltip preferredPosition="above" content={intl.get('Orders pending payment.')}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
          </div>,
            title: "Unpaid",
            accessibilityLabel: "Unpaid orderList",
          },
          {
            id: "in-processing-order-list",
            content:<div className="remarkcontainer">
            {intl.get(`In Processing`) + "(" + res.data.summary?.inProcessingCount + ")"}
              <div className="remarkarea">
                <Tooltip preferredPosition="above" content={intl.get('The order has been paid and is being processed by DSM.')}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
          </div>,
            title: "In Processing",
            accessibilityLabel: "In processing orderList",
          },
          {
            id: "fulfilled-order-list",
            content:<div className="remarkcontainer">
            {intl.get(`Fulfilled`)}
              <div className="remarkarea">
                <Tooltip preferredPosition="above" content={intl.get('DSM has completed order fulfillment and the order is in transit.')}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
          </div>,
            title: "Fulfilled",
            accessibilityLabel: "Fulfilled orderList",
          },
          {
            id: "issues-order-list",
            content:<div className="remarkcontainer">
            {intl.get(`Issues`) + "(" + res.data.summary?.issuesCount + ")"}
              <div className="remarkarea">
                <Tooltip preferredPosition="above" content={intl.get('Order information is abnormal.')}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
          </div>,
            title: "Issues",
            accessibilityLabel: "Issues orderList",
          },
          {
            id: "archived-order-list",
            content:<div className="remarkcontainer">
            {intl.get(`Archived`) + "(" + res.data.summary?.archivedCount + ")"}
            
          </div>,
            title: "Archived",
            accessibilityLabel: "Archived orderList",
          },
        ])
        }
        
        setSearchButton((prevValue) => ({
          ...prevValue,
          isSearching: "0",
        }));
        setIsInit(false);
        setLoading(false);
      } else {
        setListLoading(false);
        setSearchButton((prevValue) => ({
          ...prevValue,
          isDisableSearch: false,
          isSearching: "0",
        }));
        setIsInit(false);
        setLoading(false);
      }
    } catch (error) {}
  };
  const router = useLocation();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsInit(true);
      // if(AppBridge && AppBridge.exist()) {
      //   AppBridge.setTitleBar('Orders')
      // }
      // 从首页跳转过来
      let params = getSearchParam();

      // 从站内信
      let url = new URLSearchParams(router.search);
      let dsm_num = url.get("order_number");
      let from = url.get("from");
      let qV = url.get("q");
      let filter = url.get("filter");
      let nums = url.get("num");
      if (Number(dsm_num) > 0 && from === "message") {
        setSearchParam((prevValue) => ({
          ...prevValue,
          tab: "Issues",
        }));
        setSelectedTab(5);
        params = {
          ...getSearchParam(),
          tab: "Issues",
        };
      }

      if (Number(nums) === 1) {
        setSearchParam((prevValue) => ({
          ...prevValue,
          tab: "Unpaid",
        }));
        setSelectedTab(2);
        params = {
          ...getSearchParam(),
          tab: "Unpaid",
        };
      }

      // 从邮费商品页面跳转过来
      if (qV && filter) {
        setSearchParam((prevValue) => ({
          ...prevValue,
          query: qV,
          search_type: filter,
        }));
        setProductWork(filter);
        setSearchButton((prevValue) => ({
          ...prevValue,
          isDisableSearch: false,
          isDisableClear: false,
          isSearching: "0",
        }));
        params = {
          ...getSearchParam(),
          query: qV,
          search_type: filter,
        };
      }
      getOrderListData(params);

      //   let params = new URLSearchParams(window.location.search);
      // let order_id = params.get("id") ? params.get("id") : 0;
      // let table_id = params.get("sid") ? params.get("sid") : 0;
      // if (order_id || table_id) {
      //   this.setState({
      //     selected: 0,
      //     selectedStatus: [],
      //     PaymentStatus: [],
      //     FulfillmentStatus: [],
      //     search_order_id: order_id,
      //     search_table_id: table_id
      //   },()=>{
      //     this.GetSearchSaved();

      //     // 清除浏览器链接上的参数
      //     try {
      //       var currentUrl = window.location.href;
      //       if (currentUrl.indexOf("?") !== -1) {
      //         currentUrl = currentUrl.replace(/(\?|#)[^'"]*/, '');
      //         if (!!(window.history && window.history.pushState)) {
      //           window.history.pushState({}, 0, currentUrl);
      //         }
      //       }
      //     } catch (error) {
      //       // console.warn(error);
      //     }
      //   })

      // } else {
      //   this.GetSearchSaved();
      // }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);
  const getDefalutData = (data)=>{
      setTabs([
      {
        id: "all-order-list",
        content: intl.get("All"),
        title: "All",
        accessibilityLabel: "All orderList",
      },
      {
        id: "pending-sourcing-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Pending Sourcing`) + "(" + orderPageData.summary?.pendingSourcingCount + ")"}
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('Orders are in the process of sourcing.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
      </div>,
        title: "Pending Sourcing",
        accessibilityLabel: "Pending Sourcing orderList",
      },
      {
        id: "unpaid-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Unpaid`) + "(" + orderPageData.summary?.unpaidCount + ")"}
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('Orders pending payment.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
      </div>,
        title: "Unpaid",
        accessibilityLabel: "Unpaid orderList",
      },
      {
        id: "in-processing-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`In Processing`) + "(" + orderPageData.summary?.inProcessingCount + ")"}
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('The order has been paid and is being processed by DSM.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
      </div>,
        title: "In Processing",
        accessibilityLabel: "In processing orderList",
      },
      {
        id: "fulfilled-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Fulfilled`)}
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('DSM has completed order fulfillment and the order is in transit.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
      </div>,
        title: "Fulfilled",
        accessibilityLabel: "Fulfilled orderList",
      },
      {
        id: "issues-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Issues`) + "(" + orderPageData.summary?.issuesCount + ")"}
          <div className="remarkarea">
            <Tooltip preferredPosition="above" content={intl.get('Order information is abnormal.')}>
              <Icon source={QuestionCircleIcon} backdrop={true} />
            </Tooltip>
          </div>
      </div>,
        title: "Issues",
        accessibilityLabel: "Issues orderList",
      },
      {
        id: "archived-order-list",
        content:<div className="remarkcontainer">
        {intl.get(`Archived`) + "(" + orderPageData.summary?.archivedCount + ")"}
        
      </div>,
        title: "Archived",
        accessibilityLabel: "Archived orderList",
      },
    ])
  }
    useEffect(() => {
      let isMount = false;
      if (!isMount) {
        getDefalutData(defalutData);
      }
      return () => {
        isMount = true;
      };
      // eslint-disable-next-line
    },[defalutData]);
  // 支付状态筛选 平台 dsm shopify
  const handleChangePaymentStatus = (value) => {
    setPaymentStatus(value);
    setSearchParam((prevValue) => {
      const params = { ...prevValue, payment_status: value };
      getOrderListData({ ...prevValue, payment_status: value });
      return params;
    });
  };
  // 完成状态筛选
  const handleChangeFulfillmentStatus = (value) => {
    setFulfillmentStatus(value);
    setSearchParam((prevValue) => {
      const params = { ...prevValue, fulfillment_status: value };
      getOrderListData({
        ...prevValue,
        fulfillment_status: value,
      });
      return params;
    });
  };
  const queryJsonStringRef = useRef({
    productKeyWord: "", //订单搜索类型
    filterItem: "", //订单编号搜索
    start: "", //开始时间
    end: "", //结束时间
    tab: "", //tab切换搜索
    page: "", //当前页
    size: "", //每页条数
  }); //订单列表查询字符串
 const hideInfringementBanner = async () => {
  let person = store.getState().languageReducer?.subScriptData;
  if (Object.values(person).length > 0) {
    let useInfo = {...person,bfs_pop:{...person.bfs_pop,is_infringement_pop:true}}
    store.dispatch(changePersonLevel({ infoData: useInfo }, true));
  }
    const params = {
      type: 'dsm_infringement'
    }
    await reqPostBaseAction(params);
    }
  const tabArray = [
    {
      id: "Pending Sourcing",
      content: intl.get("Orders are in the process of sourcing."),
    },
    {
      id: "Unpaid",
      content: intl.get("Orders pending payment."),
    },
    {
      id: "In Processing",
      content: intl.get("The order has been paid and is being processed by DSM."),
    },
    {
      id: "Fulfilled",
      content:
      intl.get("DSM has completed order fulfillment and the order is in transit."),
    },
    {
      id: "Issues",
      content: intl.get("Order information is abnormal."),
    },
    {
      id: "Archived",
      content: "",
    },
    // eslint-disable-next-line
  ]; //tab数组

  const filters = [
    {
      key: "paymentStatus",
      label: intl.get("Payment status"),
      filter: (
        <ChoiceList
          title={intl.get("Payment status")}
          titleHidden
          choices={[
            { label: intl.get("Settled(Shopify)"), value: "Settled(Shopify)" },
            { label: intl.get("Pending(Shopify)"), value: "Pending(Shopify)" },
            { label: intl.get("Unsettled(Shopify)"), value: "Unsettled(Shopify)" },
            {
              label: intl.get("Partially paid(Shopify)"),
              value: "Partially paid(Shopify)",
            },
            {
              label: intl.get("Refunded(Shopify)"),
              value: "Refunded(Shopify)",
            },
            {
              label: intl.get("Partially Refunded(Shopify)"),
              value: "Partially Refunded(Shopify)",
            },
            { label: intl.get("Paid(DSM)"), value: "Paid(DSM)" },
            { label: intl.get("Unpaid(DSM)"), value: "Unpaid(DSM)" },
            {
              label: intl.get("Partially paid(DSM)"),
              value: "Partially paid(DSM)",
            },
            { label: intl.get("Refunded(DSM)"), value: "Refunded(DSM)" },
            {
              label: intl.get("Partially Refunded(DSM)"),
              value: "Partially Refunded(DSM)",
            },
          ]}
          selected={paymentStatus || []}
          onChange={handleChangePaymentStatus}
          // onChange={(value) => setPaymentStatus(value)}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "fulfillmentStatus",
      label: intl.get("Fulfillment status"),
      filter: (
        <ChoiceList
          title={intl.get("Fulfillment status")}
          titleHidden
          choices={[
            {
              label: intl.get("Unfulfilled(Shopify)"),
              value: "Unfulfilled(Shopify)",
            },
            {
              label: intl.get("Fulfilled(Shopify)"),
              value: "Fulfilled(Shopify)",
            },
            { label: intl.get("Unfulfilled(DSM)"), value: "Unfulfilled(DSM)" },
            { label: intl.get("Fulfilled(DSM)"), value: "Fulfilled(DSM)" },
          ]}
          selected={fulfillmentStatus || []}
          onChange={handleChangeFulfillmentStatus}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ]; //状态搜索Filters

  if (!isEmpty(paymentStatus)) {
    const key = "paymentStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, paymentStatus),
      onRemove: () => handleChangePaymentStatus([]),
    });
  }

  if (!isEmpty(fulfillmentStatus)) {
    const key = "fulfillmentStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, fulfillmentStatus),
      onRemove: () => handleChangeFulfillmentStatus([]),
    });
  }
  const handleClearAll = () => {
    handleChangePaymentStatus([]);
    handleChangeFulfillmentStatus([])
  }
  const checkedBtnContentNode = (type) => {
    return (
      <>
        {(type === 0 || type === 2 || type === 5) && (
          <div className="bulk_button" style={{margin:'0 8px'}}>
            <Button
              variant='primary'
              onClick={() => {
                handleBatchPayPalCheckut();
              }}
            >
             {intl.get("Bulk Payment")}
            </Button>
          </div>
        )}
        {type !== 1 && type !== 5 && type !== 6 && (
          <div className="bulk_button" style={{margin:'0 8px'}}>
            <Button onClick={() => handleBulkOperationModal(true, true)}>
            {intl.get("Archive orders")}
            </Button>
          </div>
        )}
        {type === 6 && (
          <div className="bulk_button" style={{margin:'0 8px'}}>
            <Button onClick={() => handleBulkOperationModal(false, true)}>
            {intl.get("Unarchive orders")}
            </Button>
          </div>
        )}
        {type === 400 && (
          <div className="bulk_button">
            <Button onClick={() => handleOpenShippingLabelLimit()}>
            {intl.get("Download shipping label")}
            </Button>
          </div>
        )}
         {(type === 0 || type === 1) && (
          <div className="bulk_button"  style={{margin:'0 8px'}}>
            <Button onClick={() => handleBulkSourcingRequest(true, true)}>
            {intl.get("Bulk Sourcing Request")}
            </Button>
          </div>
        )}
      </>
    );
  }; //根据切换不同tab，动态设置附带按钮

 
  /**
   * 全选按钮状态
   * true：全选 / indeterminate: 部分选中 / false：未选择
   * @returns {Object} { checked: 选中状态, num: 选中数量 ids:选中数据}
   */
  const checkStatus = useMemo(() => {
    const checkStatusList = _.filter(
      orderPageData?.list,
      (item) => item.checked === 1
    );
    let dsm_id = [];
    let shopify_id = [];
    let order_type = [];
    for (let i = 0; i < checkStatusList.length; i++) {
      if (checkStatusList[i].checked === 1) {
        dsm_id.push(checkStatusList[i].dsm_record);
        shopify_id.push(checkStatusList[i].shopify_record);
        order_type.push(checkStatusList[i].type);
      }
    }
    if (_.isEmpty(checkStatusList)) {
      return {
        checked: 0,
        num: checkStatusList.length,
        dsm_id,
        shopify_id,
        order_type,
      };
    } else if (
      checkStatusList.length > 0 &&
      checkStatusList.length === orderPageData?.list.length
    ) {
      return {
        checked: 1,
        num: checkStatusList.length,
        dsm_id,
        shopify_id,
        order_type,
      };
    }
    return {
      checked: "indeterminate",
      num: checkStatusList.length,
      dsm_id,
      shopify_id,
      order_type,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPageData?.list]);
  const handleTabChange = (selectedTabIndex) => {
    if (checkStatus.checked !== false) {
      checkedBtnContentNode(selectedTabIndex);
    }
    queryJsonStringRef.current.tab =
      selectedTabIndex === 0 ? "" : tabArray[selectedTabIndex - 1].id;
    setSelectedTab(selectedTabIndex);
    setToPage('')
    setProductWork("keyword");
    setSearchParam((data) => {
      const params = {
        ...data,
        tab: tabs[selectedTabIndex].title,
        payment_status: [],
        fulfillment_status: [],
        search_type: "keyword",
        query: "",
        start: "",
        end: "",
        page: 1,
        size: 50,
        sort_by: "",
      };
      setPaymentStatus([]);
      setFulfillmentStatus([]);
      if (data.tab !== tabs[selectedTabIndex].title) {
        getOrderListData(params);
      }
      return params;
    });
  }; //tab选中切换事件
  const handleCommonChildCheck = (newChecked) => {
    const checkList = [...orderPageData?.list];
    _.forEach(checkList, (item) => {
      if (checkStatus.checked === "indeterminate") {
        item.checked = 1;
      } else if (newChecked) {
        item.checked = 1;
      } else {
        item.checked = 0;
      }
    });
    setOrderPageData((prevValue) => ({
      ...prevValue,
      list: checkList,
    }));
    // setDataSource(checkList)
  }; //批量选中后，子元素也全部选中，反之同理
  const handleBatchCheckBox = (newChecked) => {
    handleCommonChildCheck(newChecked);
    setBatchChecked(newChecked);
  }; //批量选择事件

  const handleSingleCheckBox = (type, dsm_id, shopify_id, isChecked) => {
    const data = orderPageData?.list;
    const result = data.map((item) => {
      if (
        (item.dsm_record === dsm_id && dsm_id !== 0) ||
        (item.shopify_record === shopify_id && shopify_id !== 0)
      ) {
        return { ...item, checked: isChecked === 0 ? 1 : 0 };
      }
      return item;
    });
    result.filter((item) => item.checked === 1).length === 0
      ? setBatchChecked(false)
      : setBatchChecked(true); //如果没有任意一个item项目被选中则取消全选
    setOrderPageData((prevValue) => ({
      ...prevValue,
      list: result,
    }));
  }; //order item项选中事件

  const handleToggle = (dsm_id, shopify_id, isToggle, ind) => {
    const data = { ...orderPageData };
    const result = data.list.map((item, key) => {
      if (key === ind) {
        return { ...item, show: !isToggle };
      }
      return item;
    });
    setOrderPageData((prevValue) => ({
      ...prevValue,
      list: result,
    }));
    // setDataSource(result)
  }; //Collapsible切换事件

  const handleProductKeyWordsChange = (value) => {
    queryJsonStringRef.current.productKeyWord = value;
    setProductWork(value);
    setSearchParam((prevValue) => {
      const params = { ...prevValue, search_type: value };
      return params;
    });
  }; //订单搜索类型change

  const shopify_level = useSelector(state => state.languageReducer.subScriptData.shopify_level);
  const shopifyLevel = ()=>{
    let flag = true;
    if (shopify_level === 'Development' || shopify_level === 'Developer Preview' || shopify_level === 'staff' || shopify_level === 'staff_business') {
      flag = false
    }
    return flag;
  }
  // 总条数
  const pageMsg = () => {
    let total = orderPageData?.count ? orderPageData?.count : 0;
    const { page, size } = searchParam;
    const startNum = (page - 1) * size + 1;
    const endNum = page * size > total ? total : page * size;
    return intl.get(
      "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
      startNum,
      endNum,
      total,
      Math.ceil(total / 50)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const { page, size } = searchParam;
    let total = orderPageData?.count ? orderPageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (page > 1 && page <= maxPageNum) {
      return loading ? false : true;
    } else if (page === 1) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPageData?.count, searchParam, loading]);

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const { page, size } = searchParam;
    let total = orderPageData?.count ? orderPageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (_.inRange(page, 1, maxPageNum)) {
      return loading ? false : true;
    } else if (page === maxPageNum) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPageData?.count, searchParam, loading]);

  // 上一页
  const handlePrev = () => {
    setToPage('')
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) - 1;
      getOrderListData(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 下一页
  const handleNext = () => {
    setToPage('')
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) + 1;
      getOrderListData(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // [PayPal/钱包 批量下单] 数据检测
  const handleBatchPayPalCheckut = (PaymentType) => {
    // isBatchToPay
    // batchPayOrderIds
    // 查询符合条件的DSM订单ID
    let data = orderPageData.list;

    let ids = [];
    let name = [];

    // (type=1) shopify订单，不包邮的需要提示选择物流方式
    let isPass = true;
    let noFreeShippingOrderName = [];
    let allAddressTrue = true;
    let orderAddressFailedNames = [];

    //金额
    var totalPrice = 0;
    let totalShippingCost = 0;
    // let itemsCount = 0;
    let notSourcing = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].checked === 1) {
        if (
          data[i].dsm_payment_status === "Unpaid" ||
          data[i].dsm_payment_status === "Partially paid"
        ) {
          ids.push(data[i].dsm_record);
          name.push(data[i].dsm_order_name);
          totalPrice +=
            Number(data[i].product_cost) + parseFloat(data[i].ioss_vat);

          // 如果存在未sourcing完成sku的则提示弹窗
          if (
            data[i].order_detail.no_source.length > 0 ||
            data[i].order_detail.pending.length > 0
          ) {
            notSourcing = true;
          }

          // 订单地址检测
          if (data[i].address_failed === 1) {
            allAddressTrue = false;
            orderAddressFailedNames.push(data[i].dsm_order_name);
          }

          // (shopify订单 type=1 \ 自定义订单 type=4) 不是包邮，且没有运费，需要提示选择物流方式
          if (data[i].type === 1 || data[i].type === 4) {
            let shippingCost = 0;
            // let details = orderPageData.list;
            // for (let j = 0; j < details.length; j++) {
            let itemShippingCost = parseFloat(data[i].shipping_cost);
            if (itemShippingCost > 0) {
              shippingCost += itemShippingCost;
            } else {
              handleOrderShippingTips(true, data[i].dsm_order_name);
              return;
            }
            // }

            if (shippingCost <= 0) {
              isPass = false;
              noFreeShippingOrderName.push(data[i].dsm_number);
            }
            // 统计订单的运费
            totalShippingCost += shippingCost;
            
          }
          
          // 计算订单详情数量 gtag
          // let item_details = data[i].details
          // for (let k = 0; k < item_details.length; k++) {
          //   let itemArray = item_details[k]['item']
          //   for (let q = 0; q < itemArray.length; q++) {
          //     itemsCount += itemArray[q]['quantity']
          //   }
          // }
        }
      }
    }
    totalPrice += totalShippingCost
    // 批量数据 gtag
    // let batchData = {
    //   domain: utils.getStore('S_SHOP_DOMAIN') || window.S_SHOP_DOMAIN,
    //   value: totalPrice + totalShippingCost,
    //   currency: 'USD',
    //   order_id: name,
    //   total_items: itemsCount,
    // }
    // this.handleTrackGoogleManager('Dropshipman bulk place order', batchData)

    // 不包邮订单，提示用户选择运输方式

    if (!isPass) {
      handleOrderShippingTips(true, noFreeShippingOrderName.join(","));
      return;
    }
    // 判断选择的订单地址异常情况
    if (!allAddressTrue) {
      handleAddressFailedTips(true, ids, orderAddressFailedNames.join(","));
      return;
    }

    if (ids.length > 0) {
      setPaymentData((prevValue) => ({
        ...prevValue,
        isBatchToPay: true,
        batchPayOrderIds: ids,
      }));
      let flag = false;
      for (let i = 0; i < data.length; i++) {
        if (ids.includes(data[i].dsm_record)) {
          if (
            data[i].shopify_financial_status === "refunded" ||
            data[i].shopify_financial_status === "partially_refunded"
          ) {
            flag = true;
          }
        }
      }
      if (flag) {
        setPaymentData((prevValue) => ({
          ...prevValue,
          isBatchToPay: true,
          batchPayOrderIds: ids,
        }));
        setShopifyOrderRefundModalData((prevValue) => ({
          ...prevValue,
          shopifyOrderRefundModal: true,
          shopifyRefundOrderData: {
            name,
            PaymentType,
            totalPrice,
            totalShippingCost,
          },
        }));
      } else {
        if (notSourcing) {
          setPaymentData((prevValue) => ({
            ...prevValue,
            isNotSourcing: true,
            totalPrice,
            totalShippingCost,
          }));
        } else {
          setPaymentData((prevValue) => ({
            ...prevValue,
            isShowBulkPaymentModal: true,
            totalPrice,
            totalShippingCost,
          }));
        }
        //  handlePayForOrderModal(
        //     0,
        //     0,
        //     name.join(','),
        //     0,
        //     true,
        //     PaymentType,
        //     totalPrice,
        //     totalShippingCost
        //   )
      }
    } else {
      toggleToast("No payable orders");
    }
  };

  // [单个/批量] 订单提示地址异常无法付款
  const handleAddressFailedTips = (bool, ids = [], orderName = "") => {
    if (bool && ids.length === 0) {
      // 处理批量
      let data = orderPageData.list;
      let name = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].checked === 1 && data[i].address_failed === 1) {
          ids.push(data[i].dsm_record);
          name.push(data[i].dsm_order_name);
        }
      }
      orderName = name.length > 0 ? name.join(",") : "";
    }
    setPaymentData((prevValue) => ({
      ...prevValue,
      addressFailedModal: bool,
      addressFailedOrder: orderName,
      setOrderStatusIds: ids,
    }));
  };

  // 订单提示选择物流方式
  const handleOrderShippingTips = (bool, orderName = "") => {
    setPaymentData((prevValue) => ({
      ...prevValue,
      noFreeShippingTipsActive: bool,
      noFreeShippingOrder: orderName,
    }));
  };
  const [dlocalTip,setDlocalTip] = useState(false);
  // [PayPal] Checkout 弹窗 PaymentType 支付类型 1:PayPal 2:钱包
  const handlePayForOrderModal = (
    recordKey,
    id,
    name,
    type,
    bool,
    PaymentType,
    totalPrice = 0,
    shippingCosts = 0,
    CouponId = 0,
    CouponMoney = 0,
    isPoints
  ) => {
    setShopifyOrderRefundModalData((prevValue) => ({
      ...prevValue,
      shopifyOrderRefundModal: false,
    }));
    setPaymentData((prevValue) => ({
      ...prevValue,
      handleWalletActiveLoading: false,
      toCheckoutLoading: false,
    }));
    // if (bool && id !== 0) {
    //   let orderData = orderPageData?.list
    //   let item_num = 0
    //   let data_details = orderData[recordKey]['order_detail']
    //   let ShippingFee = 0
    //   for (let k = 0; k < data_details.length; k++) {
    //     ShippingFee += Number(data_details[k]['shipping_cost'])
    //     for (let i = 0; i < data_details[k]['item'].length; i++) {
    //       item_num += data_details[k]['item'][i]['quantity']
    //     }
    //   }
    //   let data = {
    //     domain: utils.getStore('S_SHOP_DOMAIN') || window.S_SHOP_DOMAIN,
    //     value:
    //       Number(orderData[recordKey]['totalPrice']) +
    //       parseFloat(orderData[recordKey].ioss_vat) +
    //       ShippingFee,
    //     currency: 'USD',
    //     order_id: orderData[recordKey]['dsm_number'],
    //     total_items: item_num,
    //   }
    //   this.handleTrackGoogleManager('Dropshipman place order', data)
    // }

    // 增加判断如果订单(shopify订单 type=1)
    if (type === 1 || type === 4) {
      // 订单类型 1 shopify 2 礼品包装 3 产品批发 4 自定义订单
      let data = orderPageData?.list;
      // 不是包邮，且没有运费，需要提示选择物流方式

      let shippingCost = Number(data[recordKey]?.shipping_cost);
      // 如果存在没有选择运费的直接反馈

      if (shippingCost <= 0) {
        handleOrderShippingTips(true, name);
        return;
      }
    }

    switch (PaymentType) {
      case 1:
        setPaymentData((prevValue) => ({
          ...prevValue,
          toPayOrderId: id,
          toPayOrderName: name,
          toPayOrderType: type,
          toPayActive: bool,
          createPayPalOrderId: 0,
          toPayCouponId: CouponId,
          isPoints,
          toPayCouponMoney: CouponMoney,
        }));
        if (bool) {
          handleCheckoutOrder(PaymentType, {
            isBatchToPay: getPaymentData().isBatchToPay,
            batchPayOrderIds: getPaymentData().batchPayOrderIds,
            toPayOrderId: id,
            toPayCouponId: CouponId,
            isPoints
          });
        } else {
          // 如果是批量下单
          if (paymentData.isBatchToPay) {
            setPaymentData((prevValue) => ({
              ...prevValue,
              isBatchToPay: false,
              batchPayOrderIds: [],
            }));
          }
        }
        break;
      case 2:
        setPaymentData((prevValue) => ({
          ...prevValue,
          toPayOrderId: id,
          toPayOrderName: name,
          toPayOrderType: type,
          toWalletActive: bool, //打开钱包提示弹窗
          totalPriceWallet: parseFloat(Number(totalPrice).toFixed(2)), //钱包支付订单金额
          createPayPalOrderId: 0,
          toPayCouponId: CouponId,
          isPoints,
          toPayCouponMoney: CouponMoney,
        }));
        if (bool) {
        } else {
          // 如果是批量下单
          if (paymentData.isBatchToPay) {
            setPaymentData((prevValue) => ({
              ...prevValue,
              isBatchToPay: false,
              batchPayOrderIds: [],
            }));
          }
        }
        break;
      case 3:
        break;
      default:
        break;
    }
  };
  // checkout order
  const handleCheckoutOrder = async (PaymentType, paymentData) => {
    let ids = [];
    if (paymentData.isBatchToPay) {
      // 批量
      ids = paymentData.batchPayOrderIds;
    } else {
      ids = paymentData.toPayOrderId;
    }
    setPaymentData((prevValue) => ({
      ...prevValue,
      toCheckoutLoading: true,
    }));
    let params = {
      id: JSON.stringify(ids),
      b: paymentData.isBatchToPay ? 1 : 0,
      coupon_id: paymentData.toPayCouponId,
      is_point:paymentData.isPoints ? 1 : 0
    };

    const response = await reqPostConnectOrderId(params);
    setPaymentData((prevValue) => ({
      ...prevValue,
      toCheckoutLoading: false,
    }));
    if (response.code === "200") {
      // toggleToast(response.msg, false);

      setPaymentData((prevValue) => ({
        ...prevValue,
        createPayPalOrderId: response.data,
      }));
    } else {
      toggleToast('Fail to activate');
      handlePayForOrderModal(0, 0, "", 0, false, PaymentType);
    }
  };
  // shopify订单批量支付弹窗
  const handleShopifyOrderRefund = (data) => {
    handlePayForOrderModal(
      0,
      0,
      data.name.join(","),
      0,
      true,
      data.PaymentType,
      data.totalPrice,
      data.totalShippingCost
    );
  };

  // 订单地址异常状态转换为待支付操作
  // 继续付款
  const handleAddressFailedOrderToUnpaid = () => {
    let data = orderPageData.list;

    let ids = [];
    let name = [];

    //金额
    var totalPrice = 0;
    let totalShippingCost = 0;
    // let itemsCount = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].checked === 1) {
        if (
          data[i].dsm_payment_status === "Unpaid" ||
          data[i].dsm_payment_status === "Partially paid"
        ) {
          ids.push(data[i].dsm_record);
          name.push(data[i].dsm_order_name);
          totalPrice +=
            Number(data[i].product_cost) + parseFloat(data[i].ioss_vat);

          // (shopify订单 type=1 \ 自定义订单 type=4) 不是包邮，且没有运费，需要提示选择物流方式
          if (data[i].type === 1 || data[i].type === 4) {
            let shippingCost = 0;
            let itemShippingCost = parseFloat(data[i].shipping_cost);
            if (itemShippingCost > 0) {
              shippingCost += itemShippingCost;
            }
            // 统计订单的运费
            totalShippingCost += shippingCost;
          }
        }
      }
    }
    totalPrice += totalShippingCost
    if (ids.length > 0) {
      setPaymentData((prevValue) => ({
        ...prevValue,
        isBatchToPay: true,
        addressFailedModal: false,
        batchPayOrderIds: ids,
        isShowBulkPaymentModal: true,
        totalPrice,
        totalShippingCost,
      }));
    }
  };
  const handleWalletActive = async (PaymentType) => {
    // 谷歌分析函数
    // this.handleTrackGoogleManager('Dropshipman pay order', {
    //   domain: utils.getStore('S_SHOP_DOMAIN') || window.S_SHOP_DOMAIN,
    //   value: this.state.totalPriceWallet,
    //   currency: 'USD',
    // })

    //判断钱包的钱是否够用
    if (
      orderPageData.wallet_money <
      paymentData.totalPriceWallet - paymentData.toPayCouponMoney
    ) {
      this.toggleToast("Insufficient balance");
    } else {
      setPaymentData((prevValue) => ({
        ...prevValue,
        handleWalletActiveLoading: true,
      }));
      // this.setState({
      //   handleWalletActiveLoading: true,
      // })
      let ids = [];
      if (paymentData.isBatchToPay) {
        // 批量
        ids = paymentData.batchPayOrderIds;
      } else {
        ids = paymentData.toPayOrderId;
      }

      let params = {
        id: JSON.stringify(ids),
        b: paymentData.isBatchToPay ? 1 : 0,
        coupon_id: paymentData.toPayCouponId,
        is_point:paymentData.isPoints ? 1 : 0
      };

      const response = await reqWalletTransaction(params);
      setPaymentData((prevValue) => ({
        ...prevValue,
        toWalletActive: false,
        toPayCouponId: 0,
        isPoints:false,
        toPayCouponMoney: 0,
      }));

      if (response.code === "200") {
        setPaymentData((prevValue) => ({
          ...prevValue,
          handleWalletActiveLoading: false,
        }));
        toggleToast('Payment processing');
        // this.getDropshipmanOrderList()
        getOrderListData(getSearchParam());

        // 启动定时器查询支付状态
        if (response.data) {
          setPaymentData((prevValue) => ({
            ...prevValue,
            payLogId: response.data,
          }));
          var importTimer = setInterval(() => {
            GetPaymentProcess().then(function (response) {
              if (response === 200) {
                clearInterval(importTimer);
              }
            });
          }, 3000);
        }
      } else {
        toggleToast(response.msg);
        setPaymentData((prevValue) => ({
          ...prevValue,
          handleWalletActiveLoading: false,
        }));
      }
      // 创建PayPal订单有误，关闭弹窗
      handlePayForOrderModal(0, 0, "", 0, false, PaymentType);
    }
  };

  // 查询支付的状态
  const GetPaymentProcess = async () => {
    const params = { id: paymentData.payLogId };
    const response = await reqPostPaymentProcess(params);
    if (response.code === "200") {
      let getData = response.data;
      setPaymentData((prevValue) => ({
        ...prevValue,
        operationNumber: getData.job === 0 ? getData.total : 0,
        currentExecutionsNumber: getData.job === 0 ? getData.success : 0,
      }));

      if (getData.job === 1) {
        // 结束
        setTimeout(() => {
          // 刷新列表
          // this.getDropshipmanOrderList()
          getOrderListData(getSearchParam());
        }, 1000);

        return 200;
      }
      return 201;
    } else {
      setPaymentData((prevValue) => ({
        ...prevValue,
        payLogId: 0,
        operationNumber: 0,
        currentExecutionsNumber: 0,
      }));

      return 200;
    }
  };

  const [archivedData, setArchivedData, getArchivedData] = useGetState({
    setArchived: false,
    deleteOrderActive: false,
    ArchivedTipsActive: false,
    archiveConfigloading: false,
  });
  // 订单归档或解档操作
  const handleArchiveConfigRequest = async () => {
    // 获取选中的DSM订单ID
    let data = orderPageData?.list;
    let dsm_ids = [];
    let shopify_ids = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].checked === 1) {
        dsm_ids.push(data[i].dsm_record);
        shopify_ids.push(data[i].shopify_record);
      }
    }
    

    if (dsm_ids.length === 0 && shopify_ids === 0) {
      let errorMsg = getArchivedData().setArchived
        ? "No orders"
        : "No orders";
      if (archivedData.deleteOrderActive) {
        errorMsg = "No orders";
      }
      toggleToast(errorMsg);
      return false;
    }
    setArchivedData((prevValue) => ({
      ...prevValue,
      archiveConfigloading: true,
    }));
    // this.setState({ archiveConfigloading: true })

    const params = {
      action: archivedData.setArchived ? 1 : 0,
      ids: JSON.stringify(dsm_ids),
      sids: JSON.stringify(shopify_ids),
      // del: archivedData.deleteOrderActive ? 1 : 0,
    };
    const response = await reqPostSetOrderArchivedConfig(params);
    setArchivedData((prevValue) => ({
      ...prevValue,
      archiveConfigloading: false,
    }));
    // this.setState({ archiveConfigloading: false })

    if (response.code === "200") {
      toggleToast('Archived successfully');
      handleBulkOperationModal(
        false,
        false,
        archivedData.deleteOrderActive ? "del" : "archived"
      );
      // this.getDropshipmanOrderList(true)
      getOrderListData(getSearchParam());
    } else {
      toggleToast(response.msg);
    }
  };

  // 确认弹窗
  const handleBulkOperationModal = (setArchived, isOpen, type = "archived") => {
    // let setParams = {}
    if (type === "del") {
      // setParams.deleteOrderActive = isOpen
      setArchivedData((prevValue) => ({
        ...prevValue,
        deleteOrderActive: isOpen,
      }));
    } else {
      // setParams.setArchived = setArchived
      // setParams.ArchivedTipsActive = isOpen
      setArchivedData((prevValue) => ({
        ...prevValue,
        setArchived: setArchived,
        ArchivedTipsActive: isOpen,
      }));
    }

    // this.setState(setParams)
  };

  // 面单弹窗(批量)
  const handleOpenShippingLabelLimit = () => {
      setListLoading(true);
      let data = orderPageData?.list;
      let dsm_ids = [];
      let dsm_name = [];
      let shopify_name = [];
      let shippingLabelData = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].checked === 1 && data[i]?.shipping_label !== '') {
          dsm_ids.push(data[i].dsm_record);
          dsm_name.push(data[i].dsm_order_name);
          shopify_name.push(data[i].shopify_order_name);
          shippingLabelData.push({url:data[i]?.shipping_label})
        }
      }
      if (shippingLabelData.length > 0) {
        // 批量下载pdf
        downloadZip(shippingLabelData,dsm_name,shopify_name);
        setTimeout(() => {
          setListLoading(false);
        }, 1500);
      } else {
        toggleToast('data is empty')
        setListLoading(false);
      }
  };

   // 批量sourcing
   const [searchSourcingData, setSearchSourcingData] = useState({
    setArchived: false,
    deleteOrderActive: false,
    ArchivedTipsActive: false,
    archiveConfigloading: false,
    data:[],
    oneId:'',
    setArchivedOne: false,
    deleteOrderActiveOne: false,
    ArchivedTipsActiveOne: false,
    archiveConfigloadingOne: false,
    ids:[],
    sourcingTip:false
  });
  // 批量sourcing
   const handleBulkSourcingRequest = (setArchived, isOpen) =>{
    let data = orderPageData?.list;
    let currentData = []
    let not_data = [];//找不到货源
    let shopify_ids = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].checked === 1) {
        currentData.push(data[i])
      }
    }
    if (currentData.length === 0) {
      toggleToast('No orders');
      return
    }
    for (let i = 0; i < currentData.length; i++) {
      if (currentData[i].order_detail.no_source.length > 0) {
        shopify_ids.push(currentData[i].shopify_record)
      }else {
        not_data.push(currentData[i].order_id)
      }
    }
    // if (not_data.length > 0) {
    //   setSearchSourcingData((prevValue)=>({
    //     ...prevValue,
    //     sourcingTip:true,
    //     isBluk:true
    //   }))
    //   return
    // }
    if (shopify_ids.length === 0) {
      setSearchSourcingData((prevValue)=>({
        ...prevValue,
        sourcingTip:true,
        isBluk:true
      }))
      return
    }
    setSearchSourcingData((prevValue) => ({
      ...prevValue,
      setArchived: setArchived,
      ArchivedTipsActive: isOpen,
      archiveConfigloading: false,
      data:orderPageData?.list,
    }));
  }
  // 单个
  const handleOneSourcingRequest = (setArchived, isOpen,id) =>{
    setSearchSourcingData((prevValue) => ({
      ...prevValue,
      setArchivedOne: setArchived,
      ArchivedTipsActiveOne: isOpen,
      archiveConfigloadingOne: false,
      oneId:id,
    }));
  }
  // 订单找货批量
  const handleBulkSourcingConfigRequest = async () => {
    // 获取选中的DSM订单ID
    let data = orderPageData?.list;
    let currentData = []
    let not_data = [];//找不到货源
    let shopify_ids = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].checked === 1) {
        currentData.push(data[i])
      }
    }
    if (currentData.length === 0) {
      toggleToast('No orders');
      return
    }
    for (let i = 0; i < currentData.length; i++) {
      if (currentData[i].order_detail.no_source.length !== 0) {
        shopify_ids.push(currentData[i].shopify_record)
      }else {
        not_data.push(currentData[i].order_id)
      }
    }
    if (shopify_ids.length === 0) {
      setSearchSourcingData((prevValue)=>({
        ...prevValue,
        sourcingTip:true,
        isBluk:true
      }))
      return
    }
    setSearchSourcingData((prevValue) => ({
      ...prevValue,
      archiveConfigloading: true,
    }));
    const params = {
      shopify_order_ids: shopify_ids.join(','),
    };

    const response = await reqPostUpdateOrderBatchSourcing(params);
    setSearchSourcingData((prevValue) => ({
      ...prevValue,
      archiveConfigloading: false,
      ids:[]
    }));
    // this.setState({ archiveConfigloading: false })

    if (response.code === "200") {
      const checkList = orderPageData?.list;
      checkList.map(item => {
        let data = item;
         if (shopify_ids.includes(item?.shopify_record)) {
           
         data?.order_detail.no_source?.map(it=>{
             it.is_sourcing = 2 ;
             data.order_detail.pending.push(it)
             return it;
           })
         data.order_detail.no_source = []
       }
       return data;
       })
       setOrderPageData((prevValue) => ({
         ...prevValue,
         list: checkList,
       }));
      toggleToast('Sourcing successfully');
      setSearchSourcingData((prevValue) => ({
        ...prevValue,
        setArchived: false,
        ArchivedTipsActive: false,
        archiveConfigloading: false,
        data:orderPageData?.list,
      }));
      //   getOrderListData(getSearchParam());
    } else {
      setSearchSourcingData((prevValue)=>({
        ...prevValue,
        archiveConfigloading: false,
        ids:[],
        setArchived:false,
        sourcingTip:true,
        isBluk:true
      }))
    }
  };
  // 订单找货单个
  const handleOneSourcingConfigRequest = async () => {
    // 获取选中的DSM订单ID
    
    if (searchSourcingData.oneId === '') {
      toggleToast('No orders');
      return
    }
    
    
    setSearchSourcingData((prevValue) => ({
      ...prevValue,
      archiveConfigloadingOne: true,
    }));
    const params = {
      shopify_order_ids: searchSourcingData.oneId,
    };
   
    const response = await reqPostUpdateOrderBatchSourcing(params);
    
    setSearchSourcingData((prevValue) => ({
      ...prevValue,
      archiveConfigloadingOne: false,
      ids:[...prevValue.ids,searchSourcingData.oneId]
    }));

    if (response.code === "200") {
      const checkList = orderPageData?.list;
      checkList.map(item => {
        let data = item;
         if (item?.shopify_record === searchSourcingData.oneId) {
           
         data?.order_detail.no_source?.map(it=>{
             it.is_sourcing = 2 ;
             data.order_detail.pending.push(it)
             return it;
           })
         data.order_detail.no_source = []
       }
       return data;
       })
       
       setOrderPageData((prevValue) => ({
         ...prevValue,
         list: checkList,
       }));
      toggleToast('Sourcing successfully');
      handleOneSourcingRequest(
        false,
        false,
        ''
      );
    } else {
      setSearchSourcingData((prevValue)=>({
        ...prevValue,
        archiveConfigloadingOne: false,
        ids:[],
        setArchivedOne:false,
        sourcingTip:true,
        isBluk:false
      }))
    }
  };

  const downloadZip = (fileArr,dsm_name,shopify_name) => {
    const zip = new JSZip();
    const promises = []; //用于存储多个promise
    fileArr.forEach((item,ind) => {
      const promise = new Promise((resolve, reject) => {
        // 实现下载单个文件
        Axios({
          url: item.url,
          method: "GET",
          responseType: "blob",
          ContentType: "application/pdf",
        })
          .then((res) => {
            //request请求的状态
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }).then((res) => {
        let text = shopify_name[ind] && 'Shopify'+ shopify_name[ind] + '-' 
        // 单个文件下载成功，拿到内容命名后转为二进制存储
        let fileName = text + dsm_name[ind];
        // 在此处可以设置压缩包文件夹  zip.file(文件夹名/文件名, 文件内容 ,{配置项});
        zip.file(fileName + ".pdf", res.data, { binary: true });
      });
      // 将单个promise存到数组中，后续使用promise.all判断整体状态
      promises.push(promise);
    });
    Promise.all(promises).then(() => {
      // 将压缩包里的文件压缩，返回一个promise实例，成功结果res返回的就是压缩包
      zip
        .generateAsync({
          type: "blob",
          compression: "DEFLATE", // STORE：默认不压缩 DEFLATE：需要压缩
          compressionOptions: {
            level: 9, // 压缩等级1~9    1压缩速度最快，9最优压缩方式
          },
        })
        .then((res) => {
          FileSaver.saveAs(res, "dropshipman_shipping_label.zip"); // 利用file-saver保存文件
        });
    });
  };

  // 搜索关键词
  const handleSearchValue = (value) => {
    if (value.length > 200) {
      return;
    }
    setSearchParam((prevValue) => ({
      ...prevValue,
      query: value,
    }));
    setSearchButton((prevValue) => ({
      ...prevValue,
      isDisableSearch: false,
      isDisableClear: false,
    }));
    // this.setState({
    //   queryValue: value,
    //   isDisableSearch: false,
    //   isDisableClear: false,
    // })
  };

  // 搜索时间
  const changeSearchDate = (type, date) => {
    let no_allow_search = true;

    switch (type) {
      case "start":
        setSearchParam((prevValue) => ({
          ...prevValue,
          start: date,
        }));
        if (searchParam.end !== "") {
          // if (!dayjs(date).isSameOrBefore(dayjs(this.state.end))) {
          //   this.toggleToast('Start time should be less than the end time', true);
          //   return;
          // }
          no_allow_search = false;
        }
        break;

      case "end":
        setSearchParam((prevValue) => ({
          ...prevValue,
          end: date,
        }));
        // 判断结束时间不能小于开始时间
        if (searchParam.start !== "") {
          // if (!dayjs(this.state.start).isSameOrBefore(dayjs(date))) {
          //   this.toggleToast('End time should be greater than the start time', true);
          //   return;
          // }
          no_allow_search = false;
        }

        break;
      default:
    }

    if (searchParam.query !== "") {
      no_allow_search = false;
    }

    // setSearchParam((prevValue)=>({
    //   ...prevValue,
    //   isDisableSearch:no_allow_search,
    //   isDisableClear:false
    // }))
    setSearchButton((prevValue) => ({
      ...prevValue,
      isDisableSearch: no_allow_search,
      isDisableClear: false,
    }));
    // params.isDisableSearch = no_allow_search
    // params.isDisableClear = false
    // this.setState(params)
  };

  // 执行搜索请求
  const handleSearchAction = () => {
    //执行后台请求的代码
    setSearchButton((prevValue) => ({
      ...prevValue,
      // isDisableSearch:false,
      isSearching: "1",
    }));
    getOrderListData(getSearchParam());
    // this.getDropshipmanOrderList(true)
  };

  // 清除所有筛选条件
  const handleFiltersClearAll = () => {
    setSearchParam((prevValue) => ({
      ...prevValue,
      query: "",
      payment_status: [],
      fulfillment_status: [],
    }));
    setFulfillmentStatus([]);
    setPaymentStatus([]);
    setTimeout(() => {
      getOrderListData(getSearchParam());
    }, 600);
  };
  // 清除搜索内容
  const handleClearSearch = () => {
    setSearchParam((prevValue) => ({
      ...prevValue,
      start: "",
      end: "",
    }));
    setSearchButton((prevValue) => ({
      ...prevValue,
      isDisableSearch: true,
      isDisableClear: true,
    }));
    handleFiltersClearAll();
  };
  const handleSelectChange = (value) => {
    setSearchParam((prevValue) => {
      const params = {
        ...prevValue,
        sort_by: value,
      };
      getOrderListData(params);
      return params;
    });
  };
  // 未授权弹窗 payoneer
  const callPayoneerConnectClosed = () => {
    setPayoneerConnectOpen(false);
  };
  // 关闭横幅
  const handleCancelBanner = ()=>{
    setMerchantBanner(false)
    hideInfringementBanner()
  }
  const [payoneerConfirm,setPayoneerConfirm] = useState({
    data:{},
    open:false
  })
    // 获取确认支付数据
  const  handlePayoneerConfirm = (data)=>{
    setPayoneerConfirm((prevValue)=>({
      ...prevValue,
      data,
      open:true
    }))
  }
  //关闭确认支付弹窗
  const handlePayoneerConfirmClose = ()=>{
    getOrderListData(getSearchParam());
  }

  const [payoneerAgainOpen,setPayoneerAgainOpen] = useState(false)//
  // 刷新数据
 const callPayoneerAgainClosed = ()=>{
    getOrderListData(getSearchParam());
    setPayoneerAgainOpen(false)
  }

  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  const handleButtonClick = useCallback(
    (index,shopifyLevels) => {
      if (activeButtonIndex === index) return;
      let person = store.getState().languageReducer.subScriptData;
      if (!shopifyLevels()) {
        setActiveButtonIndex(index);
      }else {
        if (((person?.level < 2 && index === 1) || (person?.level === 4 && index === 1))) {
          // 标识错误，直接返回
            setUpgradeOpen(true)
            return;
          // utils.routeListen('/admin/dashboard')
          // utils.routeJump1(router,'/admin/dashboard')
        }
        setActiveButtonIndex(index);
      }
      
    },
    [activeButtonIndex],
  );
  const [cancelInventory,setCancelInventory] = useState({
    id:'',
    detail_id:'',
    open:false
  });
  const handleCancelInventory = (id,detail_id) => {
    setCancelInventory({id,detail_id,open:true})
  }
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;

  // 支付进度显示
  let paymentTips = <div></div>;
  if (paymentData.operationNumber !== 0) {
    paymentTips = (
      <div style={{ marginBottom: "2rem" }}>
        <Banner
          title={
            paymentData.currentExecutionsNumber +
            "/" +
            paymentData.operationNumber +
            " orders are being paid."
          }
          tone="info"
          // onDismiss={() => {}}
        ></Banner>
      </div>
    );
  }

  // 订单地址异常支付提醒
  let addressFailedTips = (
    <Modal
      open={paymentData.addressFailedModal}
      onClose={() => handleAddressFailedTips(false)}
      title={intl.get("Incorrect customer information")}
      primaryAction={{
        content: intl.get("Cancel"),
        onAction: () => handleAddressFailedTips(false),
      }}
      secondaryActions={[
        {
          content: intl.get("Continue to pay"),
          onAction: () => handleAddressFailedOrderToUnpaid(),
          loading: paymentData.addressFailedLoading,
        },
      ]}
    >
      <Modal.Section>
        <p>
         {intl.get("The customer information for order")}{" "}
          <span style={{ fontWeight: "500" }}>
            {paymentData.addressFailedOrder}
          </span>{" "}
         {intl.get("is incorrect. This may affect the normal delivery of the order and we suggest correcting the information before paying.")}
        </p>
      </Modal.Section>
    </Modal>
  );
  // shopify已退款订单批量支付提醒
  let shopifyRefundOrder = (
    <Modal
      open={shopifyOrderRefundModalData.shopifyOrderRefundModal}
      onClose={() =>
        setShopifyOrderRefundModalData((prevValue) => ({
          ...prevValue,
          shopifyOrderRefundModal: false,
        }))
      }
      title={intl.get("Orders have been refunded")}
      primaryAction={{
        content: intl.get("Okay"),
        onAction: () =>
          handleShopifyOrderRefund(
            getShopifyOrderRefundModalData().shopifyRefundOrderData
          ),
      }}
    >
      <Modal.Section>
        <p>
         {intl.get("Some of the orders you have selected have been refunded by customers in Shopify, please confirm before paying.")}
        </p>
      </Modal.Section>
    </Modal>
  );

  // 不包邮订单提示用户选择物流方式
  const noFreeShippingTipsModal = (
    <Modal
      open={paymentData.noFreeShippingTipsActive}
      title={intl.get("Shipping method")}
      onClose={() => handleOrderShippingTips(false)}
      secondaryActions={[
        {
          content: intl.get("Get it"),
          onAction: () => handleOrderShippingTips(false),
        },
      ]}
    >
      <Modal.Section>
         
          <p>
            {intl.get("Please choose a shipping method for order")}
            <b>{paymentData.noFreeShippingOrder}</b>
            {"."}
          </p>
         
      </Modal.Section>
    </Modal>
  );

  const pageContent = (
    <div className="order_list">
      <div className="merchant_banner" style={{display:merchantBanner ? 'block' : 'none'}}>
        <Banner
          title=""
          tone="info"
          onDismiss={() => handleCancelBanner()}
        >
          <p>
           {intl.get("Dropshipman does not sell any counterfeit or fake products and conducts all transactions in compliance with local laws. If any of your product sourcing requests involve branded or patented designs, please contact the corresponding brand owners for authorization. Dropshipman bears no responsibility for non-compliant requests in this regard; all liabilities must be assumed by the merchant themselves")}</p>
        </Banner>
      </div>
      <Page fullWidth 
      title={
        AppBridge.exist() && !showGuide ? undefined : <div style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}>
          <h1 className="new_ui_title_h1" style={{fontWeight:'600',marginRight:'8px'}}>Orders</h1>
          {showGuide && <div onClick={()=>handleNewPersonSide(true,3)}  style={{display:'flex',alignItems:'center',cursor:'pointer',background:"rgba(255, 255, 255, 1)",padding:'6px 12px',borderRadius:'8px',fontSize:'12px'}}>
            <img width={20} height={20} src='/blackFriday/icon_points.png' alt='dsmshipman' />
          <span style={{marginLeft:'5px'}}>Earn 1000 Points worth $10</span> 
          </div>}
        </div>
      }
      >
        {/* <div className='novice_content' style={{marginBottom:'16px'}}>
          <div className="novice_left Polaris-Header-Title" style={{fontSize:"20px"}}>{intl.get("Order List")}</div>
          <div className='novice_right'>
              <img  height={60} src='/novice/small_video.png' alt='dropshipman' onClick={()=>setNoviceModal(true)}/>
            </div>
        </div> */}
        <Box className={`order_button_group_tab order_button_group_tab-${language}`}>
        <Box className='order_button_group_tab_item'>
        <ButtonGroup variant="segmented">
          <Button pressed={activeButtonIndex === 0} onClick={()=>handleButtonClick(0,shopifyLevel)}>
          {intl.get("Order List")}
          </Button>
          <Button pressed={activeButtonIndex === 1} onClick={()=>handleButtonClick(1,shopifyLevel)}>
          <InlineStack blockAlign="center">
          {shopifyLevel() && <img height={16} style={{verticalAlign:'bottom',marginRight:4}} src='/sub/sub_head_light.png' alt={'Auto Fulfillment'} />}
          {intl.get("Order Tracking")}
          </InlineStack>
          </Button>
          <Button pressed={activeButtonIndex === 2} onClick={()=>handleButtonClick(2,shopifyLevel)}>
          {intl.get("Additional Payment")}
          </Button>
        </ButtonGroup>
        </Box>
        {activeButtonIndex === 0 && <Box className='order_button_group'>
        <Box className='order_button_group_item'>
        <Button onClick={()=>setNoviceModal(true)}>
          <InlineStack blockAlign='center'>
          <Icon
          source={PlayCircleIcon}
          tone="base"
        /> {intl.get("Watch Tutorial")}
        </InlineStack>
        </Button>
        </Box>
        
        <OrderFulfillment
          syncLogData={
            Object.values(orderPageData).length > 0 ? orderPageData : {}
          }
          setSyncList={setSyncData}
          getOrderListData={getOrderListData} //获取页面列表数据
          getSearchParam={getSearchParam} //当前页面tabdata
          setOrderPageData={setOrderPageData}
        />
        </Box>}
        
        </Box>
        {/* 点击跳转Insurance App listing页面安装链接 */}
        {/* <div className="Insurance_installation">
          <a
            href="https://apps.shopify.com/shipwill-shipping-insurance?utm_source=DSM&utm_medium=order-page&utm_campaign=recommendation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/Insurance_installation.png" />
          </a>
        </div> */}
        {/* 支付进度 */}
        {paymentTips}
        {toastMarkup}
        {/* 同步订单banner */}
        {(activeButtonIndex === 0 && syncData.showSyncBanner) && (
          <div style={{ marginBottom: "16px" }}>
            <Banner
              title={
                syncData.isSyncFinish
                  ? intl.get("Order synchronization completed")
                  : intl.get("Orders are in sync...")
              }
              tone="info"
              onDismiss={
                syncData.isSyncFinish
                  ? () => {
                      // this.setState({
                      //   showSyncBanner: false
                      // })
                      setSyncData((prevValue) => {
                        const params = {
                          ...prevValue,
                          showSyncBanner: false,
                        };
                        return params;
                      });
                    }
                  : () => {}
              }
            >
              {syncData.isSyncFinish ? (
                <p className="SyncBanner">
                  <span>{Number(syncData.SyncCompletedData?.all) - Number(syncData.SyncCompletedData?.fail)}</span>{" "}
                  {intl.get("orders have been synchronized successfully")},
                  {" "}<span>{syncData.SyncCompletedData?.fail}</span>{" "}
                  {intl.get("orders failed to synchronize.")}
                </p>
              ) : (
                <p>
                  {syncData?.asyncStoreOrderTotal -
                    syncData?.finishAsyncStoreOrderCount}
                  /{syncData?.asyncStoreOrderTotal}
                </p>
              )}
            </Banner>
          </div>
        )}
       {activeButtonIndex === 0 && <Layout>
          <Layout.Section>
            <Box className="content_list_tab">
              <Tabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={handleTabChange}
              >
              </Tabs>
              <section className="content_list_tab_filters" style={{padding:"8px 16px"}}>
                    {/* 数据搜索区域 */}
                    <div className="search_container">
                      {/* 根据 keyword 搜索 */}
                      <div className="search_keyword">
                        {/* Product keyWords 下拉搜索框 */}
                        <div className="search_item">
                          <Select
                            labelHidden
                            options={[
                              { label:intl.get("Product keywords"), value: "keyword" },
                              { label: intl.get("DSM order number"), value: "dsm_order" },
                              {
                                label: intl.get("Shopify order number"),
                                value: "shopify",
                              },
                            ]}
                            onChange={handleProductKeyWordsChange}
                            value={productWork}
                          />
                        </div>

                        {/* Filter items 搜索框 */}
                        <div className="search_item filter_input">
                          <TextField
                            prefix={<Icon source={SearchIcon} />}
                            placeholder="Search with product keywords or order number"
                            autoComplete="off"
                            onChange={handleSearchValue}
                            value={searchParam.query}
                          />
                        </div>

                        {/* 时间搜索框 */}
                        <div className="dateSearchArea search_item">
                          <div className="Polaris-TextField startDateInput">
                            <DatePicker
                              placeholderText="Start"
                              selectsStart
                              startDate={searchParam.start}
                              endDate={searchParam.end}
                              maxDate={searchParam.end}
                              selected={searchParam.start}
                              onChange={(date) =>
                                changeSearchDate("start", date)
                              }
                              className="Polaris-TextField__Input"
                            />
                          </div>
                          <div className="timearrow">
                            <img src="/timearrow.png" alt="" />
                          </div>
                          <div className="Polaris-TextField endDateInput">
                            <DatePicker
                              placeholderText="End"
                              selectsEnd
                              startDate={searchParam.start}
                              endDate={searchParam.end}
                              minDate={searchParam.start}
                              selected={searchParam.end}
                              onChange={(date) => changeSearchDate("end", date)}
                              className="Polaris-TextField__Input"
                            />
                          </div>
                        </div>

                        {/* 搜索按钮组 */}
                        <div className="search_btnGroup search_item">
                          <ButtonGroup>
                            <Button
                              variant="primary"
                              onClick={handleSearchAction}
                              disabled={searchButton.isDisableSearch}
                              loading={searchButton.isSearching === "1"}
                            >
                            {intl.get("Search")}
                            </Button>
                            <Button
                              onClick={handleClearSearch}
                              disabled={searchButton.isDisableClear}
                            >
                              {intl.get("Clear")}
                            </Button>
                          </ButtonGroup>
                        </div>
                        {(selectedTab === 2 ||
                          selectedTab === 3 ||
                          selectedTab === 4) && (
                          <div className="search_selects">
                            <Select
                              label="Sort by :"
                              labelInline
                              options={[
                                {
                                  label:intl.get("DSM order number from high to low"),
                                  value: "dsm",
                                },
                                {
                                  label:
                                  intl.get("Shopify order number from high to low"),
                                  value: "shopify",
                                },
                              ]}
                              onChange={handleSelectChange}
                              value={getSearchParam().sort_by}
                            />
                          </div>
                        )}
                      </div>

                      {/* 根据 status 搜索 */}
                      <div className="search_status">
                        <Filters
                          filters={filters}
                          hideQueryField
                          appliedFilters={appliedFilters}
                          onClearAll={handleClearAll}
                        />
                        
                      </div>
                    </div>

                    {/* 批量勾选 */}
                    {
                      selectedTab !== 3 &&
                      orderPageData?.list?.length > 0 && (
                        <div className="batchClick_container">
                          <div
                            className=""
                            style={{display:'flex',alignItems:'center'}}
                            onClick={() => handleBatchCheckBox(!batchChecked)}
                          >
                            <div className="headings_div__3">
                              <Checkbox
                                labelHidden
                                checked={checkStatus.checked}
                                onChange={handleBatchCheckBox}
                              />
                            </div>
                            <span className="Polaris-CheckableButton__Label Polaris-Button__Content">
                              {checkStatus.checked !== 0 &&
                                  Object.values(orderPageData).length > 0 &&
                                  orderPageData?.list.filter(
                                    (item) => item.checked === 1
                                  ).length > 0
                                    ?  intl.get("{{num}} selected",orderPageData?.list.filter(
                                      (item) => item.checked === 1
                                      ).length) : intl.get("Select all")
                              }
                            </span>
                          </div>
                          {checkStatus.checked !== 0 &&
                            checkedBtnContentNode(selectedTab)}
                        </div>
                      )}
              </section>
            </Box>

            {/* 订单列表项 */}
            {_.isEmpty(orderPageData.list) && (
              <div
                className="emptyWithImg order_empt"
                style={{ background: "#fff",borderRadius: "10px" }}
              >
                <EmptyState
                  heading={intl.get("No results found")}
                  image="/empty.svg"
                ></EmptyState>
              </div>
            )}
            {!_.isEmpty(orderPageData.list) && (
              <>
                <div className="ImportListSearchLoading">
                  {listLoading && <SearchLoading />}
                  <OrderItem
                    handleCancelInventory={handleCancelInventory}
                    setListLoading={setListLoading}
                    getOrderListData={getOrderListData} //获取页面数据
                    getSearchParam={getSearchParam} //当前页面tabdata
                    setOrderPageData={setOrderPageData} //页面操作显示设置
                    handlePayForOrderModal={handlePayForOrderModal} //支付弹窗
                    handleOrderShippingTips={handleOrderShippingTips} //订单物流选择提示
                    handleAddressFailedTips={handleAddressFailedTips} //地址检测
                    content={orderPageData.list} //订单列表数据源
                    payoneerAuth={orderPageData.payoneer_auth}//payoneer授权
                    setPayoneerConnectOpen={setPayoneerConnectOpen}
                    setPayoneerBalances={setPayoneerBalances}
                    walletMoney={orderPageData.wallet_money}
                    handleToggle={handleToggle} //展开组件回调
                    handleSingleCheckBox={handleSingleCheckBox} //单选回调
                    selectedTab={selectedTab} //如果tab_index为1则不显示checkbox
                    paymentData={paymentData}
                    setPaymentData={setPaymentData}
                    setOrderItemPageData={setOrderItemPageData}
                    handleOneSourcingRequest={handleOneSourcingRequest}
                    searchSourcingData={searchSourcingData}
                  />
                </div>
              </>
            )}

            {/* 批量支付弹窗 */}
            <BulkPaymentModal
              handlePayForOrderModal={handlePayForOrderModal}
              paymentData={paymentData}
              setDlocalTip={setDlocalTip}
              payoneerAuth={orderPageData.payoneer_auth}//payoneer授权
              setPayoneerConnectOpen={setPayoneerConnectOpen}
              setPayoneerBalances={setPayoneerBalances}
              setIsShowBulkPaymentModal={() => {
                setPaymentData((prevValue) => ({
                  ...prevValue,
                  isShowBulkPaymentModal: false,
                }))
                setOrderItemPageData([])
              }
              }
              orderIds={ orderItemPageData.length > 0 ? orderItemPageData :
                Object.values(orderPageData).length > 0
                  && orderPageData?.list.filter((item) => item.checked === 1)
              }
              walletMoney={orderPageData.wallet_money}
            />
          {/* payoneer未授权 */}
          <PayoneerBalancesConnect 
          open={payoneerConnectOpen}
          callPayoneerConnectClosed={callPayoneerConnectClosed}
          />
           {/* 选择payoneer支付账户 */}
        <PayoneerBalancesOrderSelect 
          datas={payoneerBalances} 
          setPayoneerBalances={setPayoneerBalances}
          handlePayoneerConfirm={handlePayoneerConfirm}
          />
           {/* 确认付款 */}
           <PayoneerBalancesOrderConfirm
           handlePayoneerConfirmClose={handlePayoneerConfirmClose}
          data={payoneerConfirm.data}
          open={payoneerConfirm.open}
          setPayoneerAgainOpen={setPayoneerAgainOpen}
          setPayoneerConfirm={setPayoneerConfirm}
          />
          <PayoneerBalancesAgain 
          open={payoneerAgainOpen} 
          callPayoneerAgainClosed={callPayoneerAgainClosed}
          />

            <PayForOrderModal
              paymentData={paymentData}
              handlePayForOrderModal={handlePayForOrderModal}
              getOrderListData={getOrderListData} //获取页面数据
              getSearchParam={getSearchParam} //当前页面tabdata
            />
            {/* 钱包支付 */}
            <WalletForOrderModal
              paymentData={paymentData}
              handleWalletActive={handleWalletActive}
              walletMoney={orderPageData.wallet_money}
              handlePayForOrderModal={handlePayForOrderModal}
            />
            {/* 订单支付 未sourcing完成sku订单提示 */}
            <NotSourcingCompleteModal
              open={paymentData.isNotSourcing}
              setPaymentData={setPaymentData}
            />

            {/* 已退款订单批量支付提醒 */}
            {shopifyRefundOrder}
            {/* 不包邮订单提示用户选择物流方式 */}
            {noFreeShippingTipsModal}
            {/* 订单地址异常支付提醒 */}
            {addressFailedTips}
            {/* 批量归档弹窗 */}
            <ArchiveOrderModal
              archivedData={archivedData}
              setArchivedData={setArchivedData}
              handleArchiveConfigRequest={handleArchiveConfigRequest}
              handleBulkOperationModal={handleBulkOperationModal}
            />
            {/* 批量找货弹窗 */}
            <BatchSourcingOrderModal
              archivedData={searchSourcingData}
              setArchivedData={setSearchSourcingData}
              handleArchiveConfigRequest={handleBulkSourcingConfigRequest}
              handleBulkOperationModal={handleBulkSourcingRequest}
            />

            {/* 单个找货弹窗 */}
            <OneSourcingOrderModal
              archivedData={searchSourcingData}
              setArchivedData={setSearchSourcingData}
              handleArchiveConfigRequest={handleOneSourcingConfigRequest}
              handleBulkOperationModal={handleOneSourcingRequest}
            />
            {/* 找货提示 */}
            
            {/* dlocal支付完成提示 */}
            <DlocalPayTipModal setDlocalTip={setDlocalTip} dlocalTip={dlocalTip} getOrderListData={()=>getOrderListData(getSearchParam())} />
            <OrderInventoryModal 
              cancelInventory={cancelInventory} 
              setCancelInventory={setCancelInventory}
              getOrderListData={getOrderListData} //获取页面数据
              getSearchParam={getSearchParam} //当前页面tabdata
            />
            <SourcingOrderTipModal
              archivedData={searchSourcingData}
              setArchivedData={setSearchSourcingData}
            />
            {/* 分页区域 */}
            {!_.isEmpty(orderPageData.list) && (
              <div className="pageination_container">
                <Pagination
                  label={pageMsg()}
                  hasPrevious={hasPrevious}
                  hasNext={hasNext}
                  onPrevious={handlePrev}
                  onNext={handleNext}
                />
                  <div>
                  <input
                    className="Page_input"
                    type="text"
                    value={toPage}
                    maxLength={"8"}
                    onChange={(e) => {
                      if (Number.isInteger(Number(e.target.value))) {
                        if ((e.target.value > Math.ceil(orderPageData?.count / 50) || e.target.value <= 0) && e.target.value !== "") {
                          setToPage(1)
                        }else{
                       setToPage(e.target.value)
                        }
                      }
                    }}
                  />
                  <span
                    className="Page_Jump"
                    onClick={() => {
                      if (Math.ceil(orderPageData?.count / 50) === 1) return false;
                      let page = 1;
                      if (Math.ceil(orderPageData?.count / 50) >= toPage) {
                        page = toPage;
                      }
                      if (
                        Math.ceil(orderPageData?.count / 50) > 1 &&
                        Math.ceil(orderPageData?.count / 50) <= toPage
                      ) {
                        page = toPage;
                      }
                      if (page === '') {
                        return
                      }
                      setSearchParam((prevValue) => {
                        const params = { ...prevValue,page:page }
                        getOrderListData(params)
                        return params
                      })
                      if (document) {
                        if (document?.documentElement || document?.body) {
                          document.documentElement.scrollTop = document.body.scrollTop = 0;
                        }
                      }
                    }}
                  >
                   {intl.get("Go to")}
                  </span>
                </div>
              </div>
            )}
          </Layout.Section>
        </Layout>}
        {activeButtonIndex === 1 && <DropshipmanOrdersTrackingList />}
        {activeButtonIndex === 2 && <ShippingDifferenceList />}
      </Page>
      {/* 升级会员 */}
      <Modal
        open={upgradeOpen}
        onClose={() =>{
          setUpgradeOpen(false)
          }}
        title={intl.get('Upgrade  your plan')}
        primaryAction={{
          content: intl.get("Upgrade Plan"),
          onAction: () => {
            setUpgradeOpen(false)
            utils.routeListen('/admin/subscribe')
            utils.routeJump1(history,'/admin/subscribe')
          },
        }}
        secondaryActions={[
          {
            content: intl.get("Cancel"),
            onAction: () => {
              setUpgradeOpen(false)
              },
          },
        ]}
      >
        <Modal.Section>
        <p style={{margin:"24px",fontSize:'16px'}}>{intl.get('This feature requires upgrading to a more advanced plan to access, please upgrade your plan.')}</p>
        </Modal.Section>
      </Modal>
      {/* 功能视频介绍 */}
      <NoviceOrderModal upgradeOpen={noviceModal} setUpgradeOpen={()=>setNoviceModal(false)}/>
    </div>
  );
  return isInit ? (
    <Page fullWidth>
      <LoadingPage />
    </Page>
  ) : (
    pageContent
  );
};

export default connect()(withRouter(OrderListClass));
