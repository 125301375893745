import React, { useState, useEffect } from "react";
import { Modal,  Checkbox, Toast, Banner } from "@shopify/polaris";
import "./index.css";
import OptionsAutocomplete from "./components/OptionsAutocomplete";
import {
  reqPostUpdateOrderPaymentConfig,
  reqGetOrderPaymentConfig,
} from "../../../../../../api";
import intl from "../../../../../../i18n/intl";
const AutoFulfillmentModal = ({ open, setSwitchChecked,setOrderPageData }) => {
  const [isShowToast, setIsShowToast] = useState(false); //是否展示 toast 提示
  const [toastCtx, setToastCtx] = useState("");
  const [errorToast, setErrorToast] = useState(false);
  const [shippingDataSource, setShippingDataSource] = useState([]);
  const [userShippingDataSource, setUserShippingDataSource] = useState([]); //物流数据源
  const [autoComplete, setAutoComplete] = useState();
  const [autoPay, setAutoPay] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveInput,setSaveInput]=useState(false);
  const [bannerData,setBannerData] = useState({
    first:false,
    second:false
  })
  const handleDoneChange = async () => {
    if (!autoComplete) {
      //未选中钱包支付
      setBannerData((prevValue)=>({
        ...prevValue,
        first:true,
        second:false
      }))
      // setToastCtx("Please set the wallet as the default payment method first");
      return;
    }
    if (!autoPay) {
      setBannerData((prevValue)=>({
        ...prevValue,
        first:false,
        second:true
      }))
      // setToastCtx('Please set the default shipping method first')
      return;
    }
    if ((userShippingDataSource[0]?.child[0]?.shipping_methods === '')) {
      setBannerData((prevValue)=>({
        ...prevValue,
        first:false,
        second:true
      }))
      // setToastCtx('Please set the default shipping method first')
      setSaveInput(true)
      return;
    }
    try {
      const res = await reqPostUpdateOrderPaymentConfig({
        auto_pay: autoPay ? 1 : 0,
        auto_complete: autoComplete ? 1 : 0,
        userShippingData: JSON.stringify(userShippingDataSource),
      });
      setSaveLoading(true);
      if (res.code === "200" || res.code === 200) {
        // success
        setIsShowToast(true);
        setToastCtx(intl.get('Set up successfully'));
        setOrderPageData((prevValue)=>({
          ...prevValue,
          auto_pay:1
        }))
        setTimeout(() => {
          setSaveLoading(false);
          setSwitchChecked();
        }, 1000);
        setErrorToast(false)
      } else {
        setOrderPageData((prevValue)=>({
          ...prevValue,
          auto_pay:0
        }))
        setSaveLoading(false);
        setToastCtx(res.msg);
        setIsShowToast(true);
        setErrorToast(true)
      }
    } catch (error) {}
  };

  const initData = async () => {
    setIsLoading(true);
    setBannerData((prevValue)=>({
      ...prevValue,
      first:false,
      second:false
    }))
    try {
      const res = await reqGetOrderPaymentConfig();
      const { auto_complete, auto_pay, shippingData, userShipping } = res.data;
      if (userShipping.length < 10) {
        userShipping.push({
          id: 0,
          country_code: "",
          country_name: "",
          is_del: 0,
          child: [
            {
              shipping_methods: "",
              shipping_name: "",
            },
          ],
        });
      }
      setIsShowToast(false);
      setAutoComplete(auto_complete === 1);
      setAutoPay(auto_pay === 1);
      setShippingDataSource(shippingData);
      setUserShippingDataSource(userShipping);
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    } catch (error) {}
  };

  const handleChangeUserShipping = (newData) => {
    // removeDataRef.current = oldData
    setUserShippingDataSource(newData);
  };

  useEffect(() => {
    if (open) {
      initData();
    }
  }, [open]);

  const closeToast = () => {
    setIsShowToast(false);
  };

  const checkBoxLabelNode = (type) => {
    return !type ? (
      <>
        <div style={{ fontWeight: "bold" }}>
          {intl.get("Set wallet payment as the default payment method")}
        </div>
        <div>
        {intl.get("When there is an order fulfilled by Dropshipman, the default payment method is wallet payment.")}
        </div>
      </>
    ) : (
      <>
        <div style={{ fontWeight: "bold" }}>
        {intl.get("Set the default shipping method")}
        </div>
        <div>
        {intl.get("Set the default shipping method, when there is a payable order, the set shipping method will be selected first.")}
        </div>
      </>
    );
  };

  const mainContentNode = () => {
    return (
      <>
        <div className="country_option_div">
          <div>{intl.get("Country/Region")}</div>
          <div>{intl.get("Options (UP to 5)")}</div>
        </div>
        <OptionsAutocomplete
          shippingDataSource={shippingDataSource}
          saveInput={saveInput}
          setSaveInput={setSaveInput}
          userShippingDataSource={userShippingDataSource}
          handleChangeUserShipping={handleChangeUserShipping}
          setShippingDataSource={(data) => setShippingDataSource(data)}
        />
      </>
    );
  };

  const toastMarkup = isShowToast ? (
    <Toast
      content={toastCtx}
      onDismiss={closeToast}
      error={errorToast}
      duration={2000}
    />
  ) : null;

  return (
    <Modal
      size="large"
      // loading={isLoading}
      open={open}
      onClose={()=>{
        setSwitchChecked()
        setIsShowToast(false)
        setBannerData((prevValue)=>({
          ...prevValue,
          first:false,
          second:false
        }))
      }}
      title={intl.get("Auto Fulfillment")}
      primaryAction={{
        content: intl.get("Done"),
        onAction: handleDoneChange,
        loading: saveLoading,
        disabled: isLoading,
      }}
      secondaryActions={[
        {
          content: intl.get("Cancel"),
          onAction: ()=>{
            setSwitchChecked()
            setIsShowToast(false)
          },
        },
      ]}
    >
      <div style={{minHeight:'300px',maxHeight:'550px',overflowX:'hidden',overflowY:'auto'}}>
      <Modal.Section>
      {bannerData.first && <Banner
        title={intl.get("Default payment method")}
        tone="warning"
        onDismiss={() => {
          setBannerData((prevValue)=>({
            ...prevValue,
            first:false,
            second:false
          }))
        }}
      >
        <p>
        {intl.get("Please set the wallet as the default payment method first")}
        </p>
      </Banner>}
      {bannerData.second && <Banner
        title={intl.get("Default shipping method")}
        tone="warning"
        onDismiss={() => {
          setBannerData((prevValue)=>({
            ...prevValue,
            first:false,
            second:false
          }))
        }}
      >
        <p>
        {intl.get("Please set the default shipping method first")}
        </p>
      </Banner>}
          <div>
            <Checkbox
              label={checkBoxLabelNode(0)}
              // checked={walletChecked}
              checked={autoComplete}
              onChange={(newChecked) => setAutoComplete(newChecked)}
            />
          </div>

          <div>
            <Checkbox
              label={checkBoxLabelNode(1)}
              // checked={defaultChecked}
              checked={autoPay}
              onChange={(newChecked) => setAutoPay(newChecked)}
            />
          </div>
          {mainContentNode()}
          {toastMarkup}
         
      </Modal.Section>
      </div>
    </Modal>
  );
};

export default AutoFulfillmentModal;
