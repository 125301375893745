import { Box,Link,Modal } from '@shopify/polaris';
import React from 'react';
// import {
//     PlayCircleIcon
//   } from '@shopify/polaris-icons';
const Index = ({open,setOpen})=> {
    return (
        <Box>
            <Modal open={open} title="Import products from AliExpress" 
            onClose={()=>{setOpen(false)}}
            primaryAction={{content: 'Install Google Extension to Import Product', onAction: ()=>{
                window.open("https://chromewebstore.google.com/detail/dropshipman-aliexpress-pr/bobhjedlciclpchpelkgiojkmmfgnihg", "_blank");
            }}}
            // secondaryActions={[{
            //     content: "Watch video tutorial",
            //     onAction: ()=>{
                    
            //     },
            //     icon: PlayCircleIcon
            // }]}
            >
                <Modal.Section>
                <Box className="import_ali_modal_content">
                Importing AliExpress products requires the installation of the Dropshipman Google Extension. Please follow these steps:
                <p>1. Download and install the&nbsp;<Link url="https://chromewebstore.google.com/detail/dropshipman-aliexpress-pr/bobhjedlciclpchpelkgiojkmmfgnihg" external>Dropshipman Google Extension.</Link></p>
                <p>2. Log in to the Dropshipman Google Extension using your Dropshipman account.</p>
                <p>3. Visit the AliExpress product details page, and click the "Add to my product list" button to collect the product. &nbsp;<Link url="https://www.aliexpress.com/" external>https://www.aliexpress.com/</Link>.</p>
                <p>4. View imported products in Product Center.</p>
                </Box>
                </Modal.Section>
            </Modal>
        </Box>
    );
}

export default Index;
