import {Modal} from "@shopify/polaris";
import React, { useState } from "react";
import { reqGetPersonInventoryListDataRemove } from "../../../../../api";
const Index = ({removeData,setRemoveData,resetCallback}) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const handleSave = async () => {
        setSaveLoading(true);
        let res = await reqGetPersonInventoryListDataRemove({inventory_id:removeData.id})
        if (res.code === '200') {
          setRemoveData((prevValue)=>({...prevValue,open:false}))
          resetCallback()
        }
      };
  return (
    <div className="upgrade_new_feature">
      <Modal
        open={removeData.open}
        onClose={() => 
          {
            setRemoveData((prevValue)=>({...prevValue,open:false}))
            setSaveLoading(false)
          }}
        title={'Remove Inventory'}
        primaryAction={{
          content: "Apply",
          loading:saveLoading,
          onAction: () => {
            handleSave()
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setRemoveData((prevValue)=>({...prevValue,open:false}))
              setSaveLoading(false)
            }
          },
        ]}
      >
        <Modal.Section>
        <p style={{fontSize:'14px'}}>{'Are you sure you want to remove this inventory?'}</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
