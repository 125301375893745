import {
  IndexTable,
  useIndexResourceState,
  Text,
  Badge,
  InlineStack,
  Box,
  Button,
  Tooltip,
  Popover,
  Card,
  Link,
  EmptyState,
  Icon,
  BlockStack,
} from "@shopify/polaris";
import {
  DeleteIcon,
  ChatIcon,
  MegaphoneFilledIcon,
  QuestionCircleIcon,
} from "@shopify/polaris-icons";
import React, { useEffect, useMemo, useState } from "react";
import ReminderSuccessModal from "./ReminderSuccessModal";
import "./index.css";
import intl from "../../../../../i18n/intl";
import _ from "lodash";
import utils from "../../../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { store } from "../../../../../store";
import { reqGetSourcingExpedite } from "../../../../../api";
import DeleteSourcingModal from "./DeleteSourcingModal";

const Index = ({
  listData,
  setListData,
  loading,
  requestParams,
  setRequestParams,
  getListData,
  toastFun,
  selectedType,
}) => {
  const router = useHistory();
  // 加急成功提醒弹窗
  const [reminderSuccessModal, setReminderSuccessModal] = useState({
    open: false,
  });
  const [deleteSourcingModal, setDeleteSourcingModal] = useState({
    // 移除source
    open: false,
    id: [],
    platform: "",
    loading: false,
    count: 0,
  });
  const handleSourcingDelete = (item) => {
    setDeleteSourcingModal((prevValue) => ({
      ...prevValue,
      open: true,
      id: item ? [item.id] : selectedResources,
      platform: requestParams.platform,
      count: item ? [item.id].length : selectedResources.length,
    }));
  };

  const handleReminder = async (item) => {
    let getData = store.getState().languageReducer.subScriptData;
    let params = {
      id: item.id,
      platform: requestParams.platform,
    };

    let res = await reqGetSourcingExpedite(params);
    if (res.code === "200") {
      if (getData.bfs_pop.expedite_sourcing_pop) {
        setReminderSuccessModal((prevValue) => ({
          ...prevValue,
          open: true,
        }));
      } else {
        toastFun("Expedited successfully");
      }
      let list = listData.list;
      list.map((it, i) => {
        if (it.id === item.id) {
          it.expedite_sign = 2;
          return it;
        } else {
          return it;
        }
      });

      setListData((prevValue) => ({
        ...prevValue,
        list,
      }));
    } else {
      toastFun("Network error");
    }
  };

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(listData.list);

  const promotedBulkActions = [
    {
      icon: DeleteIcon,
      destructive: true,
      content: "Deleted " + selectedResources.length + " souring request",
      onAction: () => handleSourcingDelete(""),
    },
  ];
  const bulkActions = [];

  const [headingTable, setHeadingTable] = useState([
    { title: "ID" },
    { title: "Product or title" },
    { title: "Date" },
    { title: "Type" },
    { title: "Status" },
    { title: "details" },
    { title: "Actions" },
  ]);
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      let list = [
        { title: "ID" },
        { title: "Product or title" },
        { title: "Date" },
        { title: "Type" },
        { title: "Status" },
        { title: "details" },
        { title: "Actions" },
      ];
      if (requestParams.platform === 2) {
        list = [
          { title: "Order" },
          { title: "ID" },
          { title: "Product or title" },
          { title: "Date" },
          { title: "Status" },
          { title: "details" },
          { title: "Actions" },
        ];
      }
      setHeadingTable(list);
    }
    return () => {
      isMount = true;
    };
  }, [requestParams]);
  const getYMDStart = (timeStamp) => {
    const dt = new Date(timeStamp * 1000);

    // 月份名称
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Spt",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = months[dt.getMonth()];
    const day = dt.getDate();
    const year = dt.getFullYear();

    // 返回格式为 "Month Day, Year"
    return `${month} ${day}, ${year}`;
  };

  // 总条数
  const pageMsg = () => {
    let total = listData?.count ? listData?.count : 0;
    const { page, size } = requestParams;
    const startNum = listData?.count > 0 ? (page - 1) * size + 1 : 0;
    const endNum = page * size > total ? total : page * size;
    return intl.get(
      "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
      startNum,
      endNum,
      total,
      Math.ceil(total / 20)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const { page, size } = requestParams;
    let total = listData?.count ? listData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (page > 1 && page <= maxPageNum) {
      return loading ? false : true;
    } else if (page === 1) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData?.count, requestParams, loading]);

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const { page, size } = requestParams;
    let total = listData?.count ? listData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (_.inRange(page, 1, maxPageNum)) {
      return loading ? false : true;
    } else if (page === maxPageNum) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData?.count, requestParams, loading]);

  // 上一页
  const handlePrev = () => {
    setRequestParams((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) - 1;
      getListData(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 下一页
  const handleNext = () => {
    setRequestParams((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) + 1;
      getListData(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  const showFailedReasonActive = (key) => {
    let data = listData.list;
    let old = data[key].failed_active;
    data[key].failed_active = old ? 0 : 1;
    setListData((prevValue) => ({
      ...prevValue,
      list: data,
    }));
  };
  const handleGoList = (type, item) => {
    if (type === "contact") {
      let sendPreMsg =
        "Hi, I've got a question about sourcing request " + item?.show_id;
      if (item?.show_id === "") {
        sendPreMsg =
          "Hello, I would like to get more sourcing times. Can you help me?";
      }
      try {
        // 填充信息并激活对话弹窗
        window.Willdesk.show();
        window.Willdesk.chat(sendPreMsg);
      } catch (error) {
        console.info(error);
      }
    }
    if (type === "") {
      let importStatus = 1;
      if (item.publish_status === 0) {
        importStatus = 1;
      }
      if (item.publish_status === 1) {
        importStatus = 2;
      }
      if (item.publish_status === 3) {
        importStatus = 3;
      }
      utils.routeListen("/admin/product_management?selectedView=0");
      utils.routeJump1(
        router,
        "/admin/product_management?selectedView=0&product_id=" +
          item.user_pro_id +
          "&import_status=" +
          importStatus
      );
    }
  };

  // 状态重置
  const resetStatus = (item, ind) => {
    let text = "";
    if (selectedType[0] === "My quote") {
      switch (item.status) {
        case 1:
          text = <Badge tone="info">{"Pending"}</Badge>;
          break;
        case 2:
          text = <Badge tone="info">{"Pending"}</Badge>;
          break;
        case 4:
          text = <Badge tone="info">{"Pending"}</Badge>;
          break;
        case 3:
          text = (
            <InlineStack>
              <Button
                variant="plain"
                disclosure={item?.failed === 1 ? true : false}
                onClick={() => {
                  showFailedReasonActive(ind);
                }}
              >
                <Badge tone="critical">
                  <InlineStack blockAlign="center">
                    {intl.get("Failed")}
                    {item.fail_reason !== "" && (
                      <span className="reset_source_status">
                        <Tooltip content={item.fail_reason}>
                          <Icon source={QuestionCircleIcon} tone="base" />
                        </Tooltip>
                      </span>
                    )}
                  </InlineStack>
                </Badge>
              </Button>
            </InlineStack>
          );
          break;

        case 99:
          text = (
            <Popover
              active={item.failed_active === 1 ? true : false}
              preferredAlignment="left"
              activator={
                <div className="">
                  <Button
                    variant="plain"
                    disclosure={item.failed === 1 ? true : false}
                    onClick={() => {}}
                  >
                    <Badge tone="success">{intl.get("Success")}</Badge>
                  </Button>
                </div>
              }
              onClose={() => {}}
            >
              <Card>
                <p style={{ maxWidth: "200px" }}>
                  {intl.get("Please check the product in List Products.")}
                </p>
              </Card>
            </Popover>
          );
          break;
        default:
          break;
      }
    } else {
      switch (item.status) {
        case 0:
          text = <Badge tone="info">{"Pending"}</Badge>;
          break;
        case 3:
          text = <Badge tone="info">{"Pending"}</Badge>;
          break;
        case 4:
          text = <Badge tone="info">{"Pending"}</Badge>;
          break;
        case 2:
          text = (
            <InlineStack>
              <Button
                variant="plain"
                disclosure={item?.failed === 1 ? true : false}
                onClick={() => {
                  showFailedReasonActive(ind);
                }}
              >
                <Badge tone="critical"> <InlineStack blockAlign="center">
                    {intl.get("Failed")}
                    {item.fail_reason !== "" && (
                      <span className="reset_source_status">
                        <Tooltip content={item.fail_reason}>
                          <Icon source={QuestionCircleIcon} tone="base" />
                        </Tooltip>
                      </span>
                    )}
                  </InlineStack></Badge>
              </Button>
            </InlineStack>
          );
          break;

        case 1:
          text = (
            <Popover
              active={item.failed_active === 1 ? true : false}
              preferredAlignment="left"
              activator={
                <div className="">
                  <Button
                    variant="plain"
                    disclosure={item.failed === 1 ? true : false}
                    onClick={() => {}}
                  >
                    <Badge tone="success">{intl.get("Success")}</Badge>
                  </Button>
                </div>
              }
              onClose={() => {}}
            >
              <Card>
                <p style={{ maxWidth: "200px" }}>
                  {intl.get("Please check the product in List Products.")}
                </p>
              </Card>
            </Popover>
          );
          break;
        default:
          break;
      }
    }

    return text;
  };
  const [tipsView, setTipsView] = useState("");
  // 按钮状态重置
  const buttonGroup = (item) => {
    let btn = (
      <Box>
        <InlineStack gap={100}>
          {requestParams.platform === 1 && (
            <Box className="action_btn_delete">
              <Button
                variant="tertiary"
                onClick={() => handleSourcingDelete(item)}
              >
                Delete
              </Button>
            </Box>
          )}
        </InlineStack>
      </Box>
    );
    if (selectedType[0] === "My quote") {
      if (Number(item.status) === 3) {
        btn = (
          <Box>
            <InlineStack gap={100}>
              <Box className="action_btn_go">
                <Button
                  variant="tertiary"
                  icon={ChatIcon}
                  onClick={() => handleGoList("contact", item)}
                >
                  Contact us
                </Button>
              </Box>
              {requestParams.platform === 1 && (
                <Box className="action_btn_delete">
                  <Button
                    variant="tertiary"
                    onClick={() => handleSourcingDelete(item)}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </InlineStack>
          </Box>
        );
      }
      if (Number(item.status) === 99) {
        btn = (
          <Box>
            <InlineStack gap={100}>
              {item.request_type === 2 && (
                <Box className="action_btn_go action_btn_view">
                  <Button onClick={() => handleGoList("", item)}>
                    View in List
                  </Button>
                </Box>
              )}
              {requestParams.platform === 1 && (
                <Box className="action_btn_delete">
                  <Button
                    variant="tertiary"
                    onClick={() => handleSourcingDelete(item)}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </InlineStack>
          </Box>
        );
      }
      if (
        (Number(item.status) !== 99 || Number(item.status) !== 3) &&
        item.expedite_sign
      ) {
        btn = (
          <Box>
            <InlineStack gap={100}>
              <Box className="action_btn_go">
                <Button
                  variant="tertiary"
                  icon={MegaphoneFilledIcon}
                  disabled={item.expedite_sign === 2}
                  onClick={() => {
                    handleReminder(item);
                  }}
                >
                  {item.expedite_sign === 2 ? "Expedited" : "Expedite Sourcing"}
                </Button>
              </Box>
              {requestParams.platform === 1 && (
                <Box className="action_btn_delete">
                  <Button
                    variant="tertiary"
                    onClick={() => handleSourcingDelete(item)}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </InlineStack>
          </Box>
        );
      }
    } else {
      if (Number(item.status) === 2) {
        btn = (
          <Box>
            <InlineStack gap={100}>
              <Box className="action_btn_go">
                <Button
                  variant="tertiary"
                  icon={ChatIcon}
                  onClick={() => handleGoList("contact", item)}
                >
                  Contact us
                </Button>
              </Box>
            </InlineStack>
          </Box>
        );
      }
      if (item.status === 1) {
        btn = (
          <Box>
            <InlineStack gap={100}></InlineStack>
          </Box>
        );
      }
      if ((item.status !== 1 || item.status !== 2) && item.expedite_sign) {
        btn = (
          <Box>
            <InlineStack gap={100}>
              <Box className="action_btn_go">
                <Button
                  variant="tertiary"
                  icon={MegaphoneFilledIcon}
                  disabled={item.expedite_sign === 2}
                  onClick={() => {
                    handleReminder(item);
                  }}
                >
                  {item.expedite_sign === 2 ? "Expedited" : "Expedite Sourcing"}
                </Button>
              </Box>
            </InlineStack>
          </Box>
        );
      }
    }

    return btn;
  };
  const rowMarkup1 = listData?.list.map((item, ind) => (
    <IndexTable.Row
      id={item.id}
      key={ind}
      selected={selectedResources.includes(item.id)}
      position={item.id}
    >
      <IndexTable.Cell>
        <Link
          dataPrimaryLink
          url={""}
          monochrome={true}
          removeUnderline
          onClick={() => console.log(``)}
        >
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {item.show_id}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Box className="sourcing_table_title_image">
          <InlineStack
            gap="300"
            wrap={false}
            align="flex-start"
            direction={"row"}
            blockAlign="center"
          >
            <Box className="sourcing_table_title_img">
              <img
                src={
                  item.all_images
                    ? item.all_images.split(",")[0]
                    : "/default_image.png"
                }
                alt={item.title}
              />
            </Box>
            <Box className="sourcing_table_title_text" title={item.title}>
              {item?.originalId &&
              item?.originalId !== "" &&
              item?.originalId !== 0 ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    utils.routeListen(
                      `/admin/product_selection/product-info?filter=${item?.originalId}&tag=home&category=&management=&source=list&favorite=0`
                    );
                    utils.routeJump1(
                      router,
                      `/admin/product_selection/product-info?filter=${item?.originalId}&tag=home&category=&management=&source=list&favorite=0`
                    );
                  }}
                  className="ml-6 ml-al-6"
                >
                  {item.title}
                </span>
              ) : (
                <span title={item.title}>{item.title}</span>
              )}
            </Box>
          </InlineStack>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell>{getYMDStart(item.created_at)}</IndexTable.Cell>
      <IndexTable.Cell>
        <Badge>
          {item.request_type === 1 ? intl.get("Exist") : intl.get("Explore")}
        </Badge>
      </IndexTable.Cell>
      <IndexTable.Cell>{resetStatus(item, ind)}</IndexTable.Cell>
      <IndexTable.Cell>
        <Box
          onMouseEnter={() => setTipsView(ind)}
          onMouseLeave={() => setTipsView("")}
        >
          <Popover
            active={tipsView === ind}
            activator={
              <span className="sourcing_table_view_more">View more</span>
            }
            autofocusTarget="first-node"
            onClose={() => {}}
          >
            <Box style={{ width: "320px", padding: "10px" }}>
              <BlockStack gap={200}>
                <Box>
                  <Box className="tips_title">
                    <strong>Product name</strong>
                  </Box>
                  <Box>{item.title}</Box>
                </Box>
                <Box>
                  <Box className="tips_title">
                    <strong>Product URL</strong>
                  </Box>
                  <Box>
                    <Link url={item.product_url} external>
                      {item.product_url}
                    </Link>
                  </Box>
                </Box>
                <Box>
                  <Box className="tips_title">
                    <strong>Target price</strong>
                  </Box>
                  <Box>${item.target_price}</Box>
                </Box>
                <Box>
                  <Box className="tips_title">
                    <strong>Product image</strong>
                  </Box>
                  <Box>
                    <InlineStack gap={200}>
                      {item?.all_images?.split(",").map((it, k) => (
                        <span key={k}>
                          <img
                            style={{ borderRadius: "8px" }}
                            width={40}
                            height={40}
                            src={it ? it : "/default_image.png"}
                            alt={"images"}
                          />
                        </span>
                      ))}
                    </InlineStack>
                  </Box>
                </Box>
              </BlockStack>
            </Box>
          </Popover>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell>{buttonGroup(item)}</IndexTable.Cell>
    </IndexTable.Row>
  ));
  const rowMarkup2 = listData?.list.map((item, ind) => (
    <IndexTable.Row
      id={item.id}
      key={ind}
      selected={selectedResources.includes(item.id)}
      position={item.id}
    >
      <IndexTable.Cell>
        <Link
          dataPrimaryLink
          url={""}
          monochrome={true}
          removeUnderline
          onClick={() => console.log(``)}
        >
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {item.plat_order_name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text variant="bodyMd" fontWeight="bold" as="span">
          {item.show_id}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Box className="sourcing_table_title_image">
          <InlineStack
            gap="300"
            wrap={false}
            align="flex-start"
            direction={"row"}
            blockAlign="center"
          >
            <Box className="sourcing_table_title_img">
              <img
                src={item.image_url ? item.image_url : "/default_image.png"}
                alt={item.product_title}
              />
            </Box>
            <Box
              className="sourcing_table_title_text"
              title={item.product_title}
            >
              {item?.originalId &&
              item?.originalId !== "" &&
              item?.originalId !== 0 ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    utils.routeListen("/admin/product_selection/product-info");
                    utils.routeJump1(
                      router,
                      "/admin/product_selection/product-info",
                      {
                        product_name: "",
                        winning: 0,
                        category_id: "",
                        min_price: "",
                        max_price: "",
                        favorite: 0,
                        record_id: item?.originalId,
                        source: "list",
                      }
                    );
                  }}
                  className="ml-6 ml-al-6"
                >
                  {item.product_title}
                </span>
              ) : (
                <span title={item.product_title}>{item.product_title}</span>
              )}
            </Box>
          </InlineStack>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell>{getYMDStart(item.created_at)}</IndexTable.Cell>
      <IndexTable.Cell>{resetStatus(item)}</IndexTable.Cell>
      <IndexTable.Cell>
        <Box
          onMouseEnter={() => setTipsView(ind)}
          onMouseLeave={() => setTipsView("")}
        >
          <Popover
            active={tipsView === ind}
            activator={
              <span className="sourcing_table_view_more">View more</span>
            }
            autofocusTarget="first-node"
            onClose={() => {}}
          >
            <Box style={{ width: "320px", padding: "10px" }}>
              <BlockStack gap={200}>
                <Box>
                  <Box className="tips_title">
                    <strong>Product name</strong>
                  </Box>
                  <Box>{item.product_title}</Box>
                </Box>
                <Box>
                  <Box className="tips_title">
                    <strong>Product URL</strong>
                  </Box>
                  <Box>
                    <Link url={item.product_url} external>
                      {item.product_url}
                    </Link>
                  </Box>
                </Box>
                <Box>
                  <Box className="tips_title">
                    <strong>Target price</strong>
                  </Box>
                  <Box>${item.target_price}</Box>
                </Box>
                <Box>
                  <Box className="tips_title">
                    <strong>Product image</strong>
                  </Box>
                  <Box>
                    <InlineStack gap={200}>
                      {item?.image_url?.split(",").map((it, k) => (
                        <span key={k}>
                          <img
                            style={{ borderRadius: "8px" }}
                            width={40}
                            height={40}
                            src={it ? it : "/default_image.png"}
                            alt={"images"}
                          />
                        </span>
                      ))}
                    </InlineStack>
                  </Box>
                </Box>
              </BlockStack>
            </Box>
          </Popover>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell>{buttonGroup(item)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  const emptyStateMarkup = !listData.list.length ? (
    <EmptyState
      heading="There is no sourcing history at the moment"
      image="/new-product/source_table_empty.png"
    >
      <p style={{ color: "rgba(97, 97, 97, 1)" }}>
        Get any products you want through sourcing request
      </p>
    </EmptyState>
  ) : undefined;
  return (
    <IndexTable
      resourceName={resourceName}
      itemCount={listData.list.length}
      selectedItemsCount={
        allResourcesSelected ? "All" : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
      headings={headingTable}
      bulkActions={bulkActions}
      promotedBulkActions={promotedBulkActions}
      selectable={Number(requestParams.platform) === 1}
      emptyState={emptyStateMarkup}
      pagination={{
        label: listData.list.length > 0 ? pageMsg() : "",
        hasPrevious: hasPrevious,
        onPrevious: () => {
          handlePrev();
        },
        hasNext: hasNext,
        onNext: () => {
          handleNext();
        },
      }}
    >
      {requestParams.platform === 1 ? rowMarkup1 : rowMarkup2}
      <ReminderSuccessModal
        reminderSuccessModal={reminderSuccessModal}
        setReminderSuccessModal={setReminderSuccessModal}
      />
      {/* 移除source */}
      <DeleteSourcingModal
        deleteSourcingModal={deleteSourcingModal}
        getListData={getListData}
        toastFun={toastFun}
        setDeleteSourcingModal={setDeleteSourcingModal}
        handleSelectionChange={handleSelectionChange}
      />
    </IndexTable>
  );
};

export default Index;
