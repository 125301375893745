import React, { Component } from 'react';

import {
  Modal, BlockStack, ChoiceList, Banner, Toast, List
} from "@shopify/polaris";

import { reqPostApplyRules } from '../../../../../api';

import intl from '../../../../../i18n/intl'

class ApplyRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.show ? true : false,
      selectedUpdateType: ['price'],
      selectedUpdateScope: ['shop'],

      //弹窗
      showToast: false,  //弹窗显示
      showToastText:'',  //弹窗文字
      showToastIserror:false, //弹窗是否错误
      applyButtonLoading: false,
    };
  }

  componentDidMount() {}

  // 组件渲染完成，再次修改渲染内容
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      active: this.props.show ? true : false,
      selectedUpdateType: ['price'],
      selectedUpdateScope: ['shop'],

      //弹窗
      showToast: false,  //弹窗显示
      showToastText:'',  //弹窗文字
      showToastIserror:false, //弹窗是否错误
      applyButtonLoading: false,
    });
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };


  handleApplyRules = async() =>{

    this.setState({applyButtonLoading: true});
      const params = {
        type: JSON.stringify(this.state.selectedUpdateType),
        scope: JSON.stringify(this.state.selectedUpdateScope)
      };
      const response = await reqPostApplyRules(params);
      this.setState({applyButtonLoading: false});
      if (response.code === "200") {

        this.toggleToast(response.msg, false);
        this.handleChange();

        // 父级重新显示更新数据
        this.props.callBackFun({count: response.data.implement_number});
        
      }else{
        this.toggleToast(response.msg);
      }
    
  }

  handleChange = () =>{
    this.setState(({ active }) => ({ active: !active }));
  }

  handleChoiceChange = (type,value) =>{
    if(type === 'type'){
      this.setState({
        selectedUpdateType: value,
      });
    }

    if(type === 'scope'){
      this.setState({
        selectedUpdateScope: value,
      });
    }
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const { active,selectedUpdateType ,selectedUpdateScope,applyButtonLoading } = this.state;

    return (
      <div>
        <Modal
          open={active}
          onClose={this.handleChange}
          title={intl.get("Apply for existing AliExpress/Dropshipman products")}
          primaryAction={{
            content: intl.get("Apply"),
            onAction: this.handleApplyRules,
            loading: applyButtonLoading
          }}
          secondaryActions={[
            {
              content: intl.get("Cancel"),
              onAction: this.handleChange,
            },
          ]}
        >
          <Modal.Section>
            <Banner
              tone="warning"
              title="Note:"
            >
              <List type="bullet">
                <List.Item><b>{intl.get("Add shipping fee")}</b> {intl.get("is a paid feature. Please check the pricing list.")}</List.Item>
                <List.Item>{intl.get("Depending on the number of products, the update might take up to several hours.")}</List.Item>
              </List>
            </Banner>
            
            <div style={{padding:'1rem'}}></div>
            <BlockStack >
              <BlockStack>
                <ChoiceList
                  title={intl.get("Select the update type")}
                  choices={[
                    {label: intl.get("Price"), value: 'price'},
                    {label: intl.get("Compared at Prices"), value: 'compared_at_prices'},
                    {label: intl.get("Prices and Compared at Prices"), value: 'all_prices'},
                  ]}
                  selected={selectedUpdateType}
                  onChange={(value)=>this.handleChoiceChange('type',value)}
                />
              </BlockStack>
              <BlockStack>
                <ChoiceList
                  title={intl.get("Select the update scope")}
                  choices={[
                    {label: intl.get("Shop"),value: 'shop'},
                    {label: intl.get("Not imported"), value: 'not_imported'},
                    {label: intl.get("Shop and not imported"), value: 'all_products'},
                  ]}
                  selected={selectedUpdateScope}
                  onChange={(value)=>this.handleChoiceChange('scope',value)}
                />
              </BlockStack>
            </BlockStack>

          </Modal.Section>
        </Modal>

        {toastMarkup}
      </div>
    );
  }
}

export default ApplyRules;
