import React from 'react';

const Index =()=> {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.872192 4.36905C0.000244141 6.08035 0.000244141 8.32056 0.000244141 12.801V19.201C0.000244141 23.6814 0.000244141 25.9216 0.872192 27.6329C1.63918 29.1382 2.86302 30.362 4.36832 31.129C6.07961 32.001 8.31982 32.001 12.8002 32.001H13.5627V20.6259H9.50024V15.9205H13.5627V12.3342C13.5627 8.2545 15.9514 6.00098 19.6062 6.00098C21.3567 6.00098 23.1877 6.3189 23.1877 6.3189V10.3249H21.1701C19.1826 10.3249 18.5627 11.5797 18.5627 12.867V15.9205H23.0003L22.2909 20.6259H18.5627V32.001H19.2002C23.6807 32.001 25.9209 32.001 27.6322 31.129C29.1375 30.362 30.3613 29.1382 31.1283 27.6329C32.0002 25.9216 32.0002 23.6814 32.0002 19.201V12.801C32.0002 8.32056 32.0002 6.08035 31.1283 4.36905C30.3613 2.86376 29.1375 1.63991 27.6322 0.872924C25.9209 0.000976562 23.6807 0.000976562 19.2002 0.000976562H12.8002C8.31982 0.000976562 6.07961 0.000976562 4.36832 0.872924C2.86302 1.63991 1.63918 2.86376 0.872192 4.36905Z" fill="#1B1F26" fillOpacity="0.72"/>
            </svg>
           )}

export default Index;
