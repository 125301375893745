import React, { Component } from "react";
import {
  Toast,
  Modal,
} from "@shopify/polaris";

import { reqGetShopifyReauthorizeLinks } from "../../api";

import "./index.css";
import { store } from "../../store";
import { changeBannerData } from "../../slice";

class PermissionUpgrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalActive: false,
      title: 'Authorization Upgrade',
      description: 'Due to the upgrade of Shopify licensing service, please re-authorize Dropshipman to ensure your service is working properly.',
      buttonText: 'Upgrade',
      buttonloading: false,
      get_error: false,
    };
  }

  componentDidMount () {
    this.setState({
      modalActive: this.props.show
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      modalActive: nextProps.show
    })
  }

  // 获取授权链接
  getReAuthUrl = async () => {
    this.setState({ buttonloading: true });
    const response = await reqGetShopifyReauthorizeLinks({});
    let params = {};
    let jump_url = '';
    params.buttonloading = false;
    if (response.code === "200") {
      jump_url = response.data.url;
      if (jump_url) {
        try {
          this.hanleJumpUrl(jump_url);
        } catch (error) {
          // const w = window.open('about:blank')
          window.top.location.href = jump_url;
          // console.error('error:', error);
        }
        this.props.onClose();
        params.modalActive = false;
      } else {
        params.get_error = true;
        store.dispatch(changeBannerData({bannerData:{
          show:true,
          desc:'Failed to obtain authorization link.'
        }}));
        // this.toggleToast('Failed to obtain authorization link.', true);
      }
    }

    if (jump_url === '') {
      // this.toggleToast('Failed to obtain authorization link.', true);
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:'Failed to obtain authorization link.'
      }}));
      params.get_error = true;
    }

    this.setState(params);
  }

  /**
   * 模拟点击跳转
   * @param {*} url 
   */
  hanleJumpUrl = (url) => {
    try {
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('style', 'display:none');  
    a.setAttribute('target', '_blank');  
    document.body.appendChild(a);
    a.click();
    a?.parentNode?.removeChild(a);
  } catch (error) {
      
  }
  }

  handleModalActive = (bool) => {
    this.setState({
      modalActive: bool
    }, (bool) => {
      if (!bool) {
        this.props.onClose();
      }
    });
  }

  // 提示弹窗
  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  }

  render() {
    const { modalActive, title, description, buttonText, buttonloading } = this.state;

    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={()=>this.toggleToast('')}
        error={this.state.showToastIserror}
      />
    ) : null;

    const pageContent = (
      <Modal
        open={modalActive}
        onClose={() => this.handleModalActive(false)}
        title={title}
        primaryAction={{
          content: buttonText,
          onAction: this.getReAuthUrl,
          loading: buttonloading
        }}
      >
        <Modal.Section>
           
            {description}
           
        </Modal.Section>
      </Modal>
    )
    
    return (
      <>
          {pageContent}

          {toastMarkup}
      </>
    );
  }
}

export default PermissionUpgrade;