import React from 'react';
import {Banner, Box} from '@shopify/polaris';
import './index.css'
import { changeBannerData } from '../../slice';
import { store } from '../../store';
import { connect ,useSelector} from "react-redux";
const BannerTip =() => {
  const someStateValue = useSelector(state => state.languageReducer.bannerShow);
    return (
      <Box>
      {someStateValue && <Box className="banner-tip">
      <Banner
      title={store.getState().languageReducer.bannerTitle}
      tone="warning"
      onDismiss={() => {
        store.dispatch(changeBannerData({bannerData:{
          show:false
        }}));
      }}
    >
      <p>
      {store.getState().languageReducer.bannerDesc}
      </p>
    </Banner>
    </Box>}
    </Box>
    );
}

export default connect()(BannerTip);