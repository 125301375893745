import {
  IndexTable,
  Badge,
  InlineStack,
  Box,
  Button,
  Tooltip,
  Popover,
  Modal,
  Icon,
  BlockStack,
  Thumbnail,
  Spinner,
  ActionList,
  Toast,
  Select,
} from "@shopify/polaris";
import {
  DeleteIcon,
  WandIcon,
  MenuHorizontalIcon,
  EditIcon,
  ColorNoneIcon,
  ViewIcon,
  QuestionCircleIcon,
  NoteIcon,
} from "@shopify/polaris-icons";
import SeoantIcon from "../../SubscriptionPaid/components/icons/SeoantIcon"
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./index.css";
import _ from "lodash";
import utils from "../../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { store } from "../../../../store";
import AiUpdateModal from "../common/AiUpdateModal";
// import AiUpdateModalShopify from "../common/AiUpdateModalShopify";
import InitPageContent from "./InitPageContent";
import InitNotPageContent from "./InitNotPageContent";
import CollectionPopover from "../common/CollectionPopover";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Pagination, Thumbs } from "swiper";
import RemoveProductModal from "../modal/RemoveProductModal";
import DeleteProductModal from "../modal/DeleteProductModal";
import RestoreProductModal from "../modal/RestoreProductModal";
import { changeBannerData, setInitialPath } from "../../../../slice";
import NotImportedBulkEditAction from "../modal/NotImportedBulkEditAction";
import AiPointsLessModal from "../modal/AiPointsLessModal";
import AddCartModal from "../modal/AddCartModal";
import BulkSourcingModal from "../modal/BulkSourcingModal";
import CommonTableFilterSkeletonList from "../component/CommonTableFilterSkeletonList";
import {
  reqGetProductManagementProductsShopifyDetail,
  reqPostProductManagementProductsAiTitle,
  reqPostProductManagementProductsAliexpressImport,
  reqPostProductManagementProductsTitle,
  reqPostProductManagementProductsUploadShopify,
  reqPostProductManagementVariantsSave,
  reqPostSourcingGoodsRequestSave,
  reqShopifyNewStoreBatchSourcing,
} from "../../../../api";
import { useSelector } from "react-redux";
import TextColor from "./TextColor";
import AppBridge from "../../../../utils/appBridge";
// import { useAppBridge } from "@shopify/app-bridge-react";
const Index = ({
  requestParams,
  listData,
  setListData,
  loading,
  attributeData,
  setAttributeData,
  currentPath,
  selected,
  getPageData,
  getTabData,
  setProductStoreData,
  restartPolling,
  callBackFun,
  userPoints,
  setUserPoints,
  setPlanData,
  planData,
  setSourceLess,
  setEnoughPoint,
  setSourcingLimitModal,
  handleTogglePopup,
  setNoviceModal,
  setOtherImportParams,
  selectableOrders,
  selectedResources,
  allResourcesSelected,
  handleSelectionChange,
  setSearchLoading,
}) => {
  const router = useHistory();
  // const shopify = useAppBridge();// 最新版modal
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [sortData, setSortData] = useState({
    price: "asc",
    cost: "desc",
    sale_amount: "desc",
    sale_count: "desc",
    time: "desc",
  });

  const selectNums = () => {
    let text = " Product";
    if (selectedResources.length > 1) {
      text = " Products";
    }
    return text;
  };
  const listFun = () => {
    let text = "List " + selectedResources.length + selectNums();
    let offline = [];
    for (let i = 0; i < listData?.list?.length; i++) {
      if (listData?.list[i].product_status === 2) {
        offline.push(listData?.list[i].id);
      }
    }
    const commonElements = selectedResources.filter((item) =>
      offline.includes(item)
    );
    if (commonElements.length > 0) {
      text = `List ${
        selectedResources.length - commonElements.length
      } Products (Exclude ${commonElements.length} offline products)`;
    }
    if (
      commonElements.length > 0 &&
      commonElements.length === selectedResources.length
    ) {
      text = "List Products";
    }
    if (allResourcesSelected) {
      text = "List Products (Select up to 300)";
    }
    return text;
  };
  const createCommonHandler = () => {
    const filteredList = listData?.list?.filter(
      (item) => item.product_status > 2 && item.product_status < 5
    );
    let isErrorProduct = [];
    for (let i = 0; i < filteredList?.length; i++) {
      if (selectedResources.includes(filteredList[i].id)) {
        isErrorProduct.push(filteredList[i].id);
      }
    }
    const filteredLength = filteredList?.length;
    const selectedSet = new Set(selectedResources);
    const selectedLength = selectedResources.length;

    return () => {
      let flag = true;
      if (filteredList) {
        for (const item of filteredList) {
          const isSelected = selectedSet.has(item.id);
          if (isSelected && selectedLength <= isErrorProduct.length)
            flag = false;
          if (!isSelected && selectedLength >= filteredLength) flag = true;
        }
      }
      return flag;
    };
  };

  const [removeFun, filineFun] = Array(2).fill(createCommonHandler());
  let promotedBulkActions = [
    {
      icon: DeleteIcon,
      destructive: true,
      content: "Remove " + selectedResources.length + selectNums(),
      onAction: () => {
        setRemoveProduct((prevValue) => ({
          ...prevValue,
          id: selectedResources,
          open: true,
          type: allResourcesSelected,
        }));
      },
    },

    filineFun && {
      variant: "primary",
      content: listFun(),
      onAction: () => {
        if (listData.upload_reminder) {
          handleToStoreSubmit(selectedResources);
        } else {
          setProductStoreData((prev) => ({
            ...prev,
            toStore: selectedResources,
            open: true,
            fun: handleSelectionChange,
            type: allResourcesSelected ? 1 : 0,
          }));
        }
      },
    },
  ];
  let bulkActionsPc = [
    {
      content: "Edit Types",
      onAction: () => {
        handleBulkShow("type");
      },
    },
    {
      content: "Edit Tags",
      onAction: () => {
        handleBulkShow("tags");
      },
    },
    {
      content: "Edit Collections",
      onAction: () => {
        handleBulkShow("collections");
      },
    },
  ];
  let bulkActions = [
    {
      icon: DeleteIcon,
      destructive: true,
      content: "Remove " + selectedResources.length + selectNums(),
      onAction: () => {
        setRemoveProduct((prevValue) => ({
          ...prevValue,
          id: selectedResources,
          open: true,
          type: allResourcesSelected,
        }));
      },
    },
    {
      content: "Edit Types",
      onAction: () => {
        handleBulkShow("type");
      },
    },
    {
      content: "Edit Tags",
      onAction: () => {
        handleBulkShow("tags");
      },
    },
    {
      content: "Edit Collections",
      onAction: () => {
        handleBulkShow("collections");
      },
    },
    filineFun && {
      variant: "primary",
      content: listFun(),
      onAction: () => {
        if (listData.upload_reminder) {
          handleToStoreSubmit(selectedResources);
        } else {
          setProductStoreData((prev) => ({
            ...prev,
            toStore: selectedResources,
            open: true,
            fun: handleSelectionChange,
            type: allResourcesSelected ? 1 : 0,
          }));
        }
      },
    },
  ];
  if (!removeFun() && Number(selected) === 0) {
    promotedBulkActions = [
      {
        icon: DeleteIcon,
        destructive: true,
        content: "Remove " + selectedResources.length + selectNums(),
        onAction: () => {
          setRemoveProduct((prevValue) => ({
            ...prevValue,
            id: selectedResources,
            open: true,
            type: allResourcesSelected,
          }));
        },
      },
    ];
    bulkActionsPc = [];
    bulkActions = [
      {
        icon: DeleteIcon,
        destructive: true,
        content: "Remove " + selectedResources.length + selectNums(),
        onAction: () => {
          setRemoveProduct((prevValue) => ({
            ...prevValue,
            id: selectedResources,
            open: true,
            type: allResourcesSelected,
          }));
        },
      },
    ];
  }

  if (selected === 1) {
    promotedBulkActions = [
      {
        icon: DeleteIcon,
        destructive: true,
        content: "Remove " + selectedResources.length + selectNums(),
        onAction: () => {
          setRemoveProduct((prevValue) => ({
            ...prevValue,
            id: selectedResources,
            open: true,
            type: allResourcesSelected,
          }));
        },
      },
    ];
    bulkActionsPc = [];
    bulkActions = [
      {
        icon: DeleteIcon,
        destructive: true,
        content: "Remove " + selectedResources.length + selectNums(),
        onAction: () => {
          setRemoveProduct((prevValue) => ({
            ...prevValue,
            id: selectedResources,
            open: true,
            type: allResourcesSelected,
          }));
        },
      },
    ];
  }
  if (selected === 2) {
    promotedBulkActions = [
      {
        content: "Sourcing " + selectedResources.length + selectNums(),
        onAction: () => {
          handleSourcing(selectedResources);
        },
      },
    ];
    bulkActionsPc = [];
    bulkActions = [
      {
        content: "Sourcing " + selectedResources.length + selectNums(),
        onAction: () => {
          handleSourcing(selectedResources);
        },
      },
    ];
  }
  if (selected === 3) {
    promotedBulkActions = [
      {
        icon: DeleteIcon,
        destructive: true,
        content: "Remove " + selectedResources.length + selectNums(),
        onAction: () => {
          setRemoveProduct((prevValue) => ({
            ...prevValue,
            id: selectedResources,
            open: true,
            type: allResourcesSelected,
          }));
        },
      },
      {
        content: "Restore " + selectedResources.length + selectNums(),
        onAction: () => {
          setRestoreProduct((prevValue) => ({
            ...prevValue,
            id: selectedResources,
            open: true,
            type: allResourcesSelected,
          }));
        },
      },
    ];
    bulkActionsPc = [];
    bulkActions = [
      {
        icon: DeleteIcon,
        destructive: true,
        content: "Remove " + selectedResources.length + selectNums(),
        onAction: () => {
          setRemoveProduct((prevValue) => ({
            ...prevValue,
            id: selectedResources,
            open: true,
            type: allResourcesSelected,
          }));
        },
      },
      {
        content: "Restore " + selectedResources.length + selectNums(),
        onAction: () => {
          setRestoreProduct((prevValue) => ({
            ...prevValue,
            id: selectedResources,
            open: true,
            type: allResourcesSelected,
          }));
        },
      },
    ];
  }

  const headingTable =
    Number(selected) === 0
      ? [
          { id: "1", title: <span></span> },
          { id: "2", title: <span>{"Product"}</span> },
          {
            id: "3",
            title: <span>Price / Profit</span>,
            defaultSortDirection:
              sortData.price === "asc" ? "ascending" : "descending",
          },
          {
            id: "4",
            title: <span>Product Cost</span>,
            defaultSortDirection:
              sortData.cost === "asc" ? "ascending" : "descending",
          },
          {
            id: "51",
            title: (
              <Tooltip
                content="Shipping costs are unavailable for products added from AliExpress or Temu. Please check the respective platforms for shipping information."
                hasUnderline
              >
                <span>{"Shipping Fee"}</span>
              </Tooltip>
            ),
          },
          { id: "5", title: <span>{"Product Organization"}</span> },
          {
            id: "6",
            title: <span>{"Date Added"}</span>,
            defaultSortDirection:
              sortData.time === "asc" ? "ascending" : "descending",
          },
          { id: "7", title: <span>{"Source"}</span> },
          { id: "8", title: <span>{"Action"}</span> },
        ]
      : Number(selected) === 1
      ? [
          { id: "1", title: <span></span> },
          { id: "2", title: <span>{"Product"}</span> },
          { id: "3", title: <span>{"Source"}</span> },
          {
            id: "4",
            title: <span>{"Total Sales"}</span>,
            defaultSortDirection:
              sortData.sale_amount === "asc" ? "ascending" : "descending",
          },
          {
            id: "5",
            title: (
              <Tooltip content="Items Sold">
                <span>{"Items Sold"}</span>
              </Tooltip>
            ),
            defaultSortDirection:
              sortData.sale_count === "asc" ? "ascending" : "descending",
          },
          {
            id: "6",
            title: <span>Selling Price</span>,
            defaultSortDirection:
              sortData.price === "asc" ? "ascending" : "descending",
          },
          {
            id: "7",
            title: <span>{"Product Cost"}</span>,
            defaultSortDirection:
              sortData.cost === "asc" ? "ascending" : "descending",
          },
          {
            id: "51",
            title: (
              <Tooltip
                content="Shipping costs are unavailable for products added from AliExpress or Temu. Please check the respective platforms for shipping information."
                hasUnderline
              >
                <span>{"Shipping Fee"}</span>
              </Tooltip>
            ),
          },
          {
            id: "8",
            title: <span>{"Listing Date"}</span>,
            defaultSortDirection:
              sortData.time === "asc" ? "ascending" : "descending",
          },
          { id: "9", title: <span>{"Collections"}</span> },
          { id: "10", title: <span>{"Action"}</span> },
        ]
      : Number(selected) === 2
      ? [
          { id: "1", title: <span></span> },
          { id: "2", title: <span>{"Product"}</span> },
          { id: "3", title: <span>{"Status"}</span> },
          { id: "4", title: <span>{"Sourcing Status"}</span> },
          { id: "5", title: <span>{"Vendor"}</span> },
          { id: "6", title: <span>{"Action"}</span> },
        ]
      : [
          { id: "1", title: <span></span> },
          { id: "2", title: <span>{"Product"}</span> },
          {
            id: "3",
            title: <span>Price / Profit</span>,
            defaultSortDirection:
              sortData.price === "asc" ? "ascending" : "descending",
          },
          {
            id: "4",
            title: <span>Product Cost</span>,
            defaultSortDirection:
              sortData.cost === "asc" ? "ascending" : "descending",
          },
          {
            id: "51",
            title: (
              <Tooltip
                content="Shipping costs are unavailable for products added from AliExpress or Temu. Please check the respective platforms for shipping information."
                hasUnderline
              >
                <span>{"Shipping Fee"}</span>
              </Tooltip>
            ),
          },
          { id: "5", title: <span>{"Collections"}</span> },
          {
            id: "6",
            title: <span>{"Date Deleted"}</span>,
            defaultSortDirection:
              sortData.time === "asc" ? "ascending" : "descending",
          },
          { id: "7", title: <span>{"Source"}</span> },
          { id: "8", title: <span>{"Action"}</span> },
        ];

  const getYMDStart = (timeStamp) => {
    const dt = new Date(timeStamp * 1000);

    // 月份名称
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = months[dt.getMonth()];
    const day = dt.getDate();
    const year = dt.getFullYear();

    // 返回格式为 "Month Day, Year"
    return `${month} ${day}, ${year}`;
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = date.getUTCFullYear();
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    let hours = date.getUTCHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // 转换为12小时制，0转为12
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");

    return `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
  }

  // 总条数
  const pageMsg = () => {
    let total = 0;
    if (Number(selected) === 2) {
      total = listData.count ? listData.count : 0;
    } else {
      total = listData?.total ? listData?.total : 0;
    }
    const { page, size } = requestParams;
    const startNum = total > 0 ? (page - 1) * size + 1 : 0;
    const endNum = page * size > total ? total : page * size;
    let text = `Showing ${startNum} - ${endNum} of ${total} results (Total ${Math.ceil(
      total / size
    )} Page)`;
    if (Number(selected) !== 2) {
      text = (
        <div>
          Showing {startNum} - {endNum} of {total} results
          <div  className="per_page_footer">
            ,{" "}
            at most{" "}
            <div className="per_page_select">
              {" "}
              <Select
                options={[
                  { label: 10, value: 10 },
                  { label: 20, value: 20 },
                  { label: 30, value: 30 },
                  { label: 50, value: 50 },
                ]}
                onChange={(value) => {
                  const calculatedTotalPages = Math.ceil(total / Number(value));
                  if (page > calculatedTotalPages) {
                     // 如果当前页码超出范围，重置为第一页
                    getPageData(selected, { size: Number(value),page:1 });
                  }else {
                    getPageData(selected, { size: Number(value) });
                  }
                  setSearchLoading(true);
                }}
                value={requestParams.size}
              />
            </div>
            per page
          </div>
        </div>
      );
    }
    return text;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const { page, size } = requestParams;
    let total = 0;
    if (Number(selected) === 2) {
      total = listData.count ? listData.count : 0;
    } else {
      total = listData?.total ? listData?.total : 0;
    }
    const maxPageNum = _.ceil(total / size);
    if (page > 1 && page <= maxPageNum) {
      return loading ? false : true;
    } else if (page === 1) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, requestParams, loading]);

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const { page, size } = requestParams;
    let total = 0;
    if (Number(selected) === 2) {
      total = listData.count ? listData.count : 0;
    } else {
      total = listData?.total ? listData?.total : 0;
    }
    const maxPageNum = _.ceil(total / size);
    if (_.inRange(page, 1, maxPageNum)) {
      return loading ? false : true;
    } else if (page === maxPageNum) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, requestParams, loading]);

  // 上一页
  const handlePrev = () => {
    if (Number(selected) === 2) {
      getPageData(selected, {
        sinceId: listData?.list ? listData?.list[0].since_id : 0,
        is_next:0,
        page: Number(requestParams.page) - 1,
      });

    } else {
      getPageData(selected, { page: Number(requestParams.page) - 1 });
    }
    setSearchLoading(true);
    handleSelectionChange("page", false, 0);
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 下一页
  const handleNext = () => {
    if (Number(selected) === 2) {
      getPageData(selected, {
        sinceId: listData?.list
          ? listData?.list[listData?.list.length - 1].since_id
          : 0,
        page: Number(requestParams.page) + 1,
        is_next:1,
      });
    } else {
      getPageData(selected, { page: Number(requestParams.page) + 1 });
    }
    setSearchLoading(true);
    handleSelectionChange("page", false, 0);
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 速卖通导入刷新
  const [loadingRefresh, setLoadingRefresh] = useState("");
  // 处理添加产品事件
  const handleAddProduct = async (value, record_id, country_code) => {
    // 输入框为空
    setLoadingRefresh(record_id);
    const params = {
      encoded: value,
      country_code,
      record_id,
      is_point: 0,
    };
    try {
      const response = await reqPostProductManagementProductsAliexpressImport(
        params
      );
      if (response.code === "200") {
        setLoadingRefresh("");
        setListData((prev) => {
          let list = prev.list;
          let data = list.map((item) => {
            if (item.id === record_id) {
              item.product_status = 0;
            }
            return item;
          });
          return { ...prev, list: data };
        });
        getPageData(selected);
        getTabData(selected);
        handleSelectionChange("page", false, 0);
      } else {
      }
    } catch (error) {}
  };
  // ai优化积分不足
  const [enoughPointsData, setEnoughPointsData] = useState(false);
  // ai优化标题
  const [aiOptimizedData, setAiOptimizedData] = useState({
    id: "", // 需要优化的产品id
    title: "", // 需要优化的产品标题
    description: "", // 需要优化的产品描述
    open: false, // 是否显示ai优化弹窗
    currentLoading: false, // 正在优化项
    againLoading: false,
    applyLoading: false,
    is_gpt: false,
  });

  // ai优化标题,优化结果展示弹窗
  const handleAiOptimized = async (id, title, description, is_gpt) => {
    if (userPoints.userPointsData.available_points < 20 && !is_gpt) {
      setEnoughPointsData(true);
      return;
    }
    // if (AppBridge.exist()) {
    //     document.getElementById('ai_title_modal').show()
    //   }
    setAiOptimizedData((prevValue) => ({
      ...prevValue,
      id,
      open: true,
      currentLoading: true,
      againLoading: true,
      applyLoading: true,
      is_gpt,
    }));

    let params = {
      record_id: id,
      title,
    };
    try {
      let res = await reqPostProductManagementProductsAiTitle(params);
      if (res.code === "200") {
        // setListData((prev) => {
        //   let list = prev.list;
        //   let data = list.map((item) => {
        //     if (item.id === id) {
        //       item.is_gpt = true;
        //     }
        //     return item;
        //   });
        //   return { ...prev, list: data };
        // });
        let text = res.data.optimized_title.slice(0, 255);
        let index = 0;
        let currentTitle = "";
        const intervalId = setInterval(() => {
          if (index < text.length) {
            currentTitle += text.charAt(index);
            setAiOptimizedData((prev) => ({
              ...prev,
              title: currentTitle,
              currentLoading: false,
            }));

            index++;
          } else {
            setAiOptimizedData((prev) => ({
              ...prev,
              againLoading: false,
              applyLoading: false,
            }));
            clearInterval(intervalId);
          }
        }, 100);

        if (!is_gpt) {
          setUserPoints((prevValue) => ({
            ...prevValue,
            userPointsData: {
              ...prevValue.userPointsData,
              available_points:
                Number(prevValue.userPointsData.available_points) - 20,
            },
          }));
        }
      }
      setAiOptimizedData((prevValue) => ({
        ...prevValue,
        currentLoading: false,
      }));
    } catch (error) {}
  };

  // ai再次优化标题
  const handleAiOptimizedAgain = async (id, title, description) => {
    setAiOptimizedData((prevValue) => ({
      ...prevValue,
      id,
      againLoading: true,
      applyLoading: true,
    }));

    let params = {
      record_id: id,
      title,
    };
    try {
      let res = await reqPostProductManagementProductsAiTitle(params);
      if (res.code === "200") {
        let text = res.data.optimized_title.slice(0, 255);
        let index = 0;
        let currentTitle = "";
        const intervalId = setInterval(() => {
          if (index < text.length) {
            currentTitle += text.charAt(index);
            setAiOptimizedData((prev) => ({
              ...prev,
              title: currentTitle,
              currentLoading: false,
            }));

            index++;
          } else {
            setAiOptimizedData((prev) => ({
              ...prev,
              againLoading: false,
              applyLoading: false,
            }));
            clearInterval(intervalId);
          }
        }, 100);
      }
    } catch (error) {}
  };

  // ai标题优化完成，保存
  const handleAiOptimizedSuccess = async (id, title) => {
    if (title === "") {
      return;
    }
    let params = { record_id: id, title: title.slice(0, 255) };
    setAiOptimizedData((prevValue) => ({
      ...prevValue,
      id,
      applyLoading: true,
    }));
    try {
      let res = await reqPostProductManagementProductsTitle(params);
      if (res.code === "200") {
        setListData((prevValue) => {
          const list = [...prevValue.list];
          const index = list.findIndex((item) => item.id === id);
          list[index].title = title;
          list[index].is_update = 1;
          list[index].is_gpt = true;
          return { ...prevValue, list };
        });
        setAiOptimizedData((prevValue) => ({
          ...prevValue,
          id: "",
          title: "",
          open: false,
          applyLoading: false,
        }));
        if (AppBridge.exist()) {
          document.getElementById("ai_title_modal").hide();
          // shopify.modal.show('ai_title_modal')
        }
        toastFun("Saved successfully");
      }
      setAiOptimizedData((prevValue) => ({
        ...prevValue,
        id: "",
        title: "",
        applyLoading: false,
      }));
    } catch (error) {}

    // 更新列表数据
    // getListData(requestParams);
  };

  // 点击上架产品
  const handleListProduct = (id) => {
    setProductStoreData((prevValue) => ({
      ...prevValue,
      open: true,
      type: 0,
      toStore: [id],
      fun: handleSelectionChange,
    }));
    handleSelectionChange("page", false, 0);
  };

  // 头部排序
  const handleSortHeading = (index, direction) => {
    let params = {
      price: "desc",
      cost: "desc",
      addDate: "desc",
      sale_amount: "desc",
      sale_count: "desc",
      listDate: "desc",
    };
    if (Number(selected) === 0) {
      let sort_by =
        index === 2
          ? "price"
          : index === 3
          ? "cost"
          : index === 6
          ? "time"
          : "";
      let sort_order = direction === "descending" ? "asc" : "desc";

      setSortData({ ...params, [sort_by]: sort_order });
      getPageData(selected, {
        sort_by,
        sort_order,
      });
    }
    if (Number(selected) === 1) {
      let sort_by =
        index === 3
          ? "sale_amount"
          : index === 4
          ? "sale_count"
          : index === 5
          ? "price"
          : index === 6
          ? "cost"
          : index === 8
          ? "time"
          : "";
      let sort_order = direction === "descending" ? "asc" : "desc";
      setSortData({ ...params, [sort_by]: sort_order });
      getPageData(selected, {
        sort_by,
        sort_order,
      });
    }
    if (Number(selected) === 3) {
      let sort_by =
        index === 2
          ? "price"
          : index === 3
          ? "cost"
          : index === 6
          ? "time"
          : "";
      let sort_order = direction === "descending" ? "asc" : "desc";
      setSortData({ ...params, [sort_by]: sort_order });
      getPageData(selected, {
        sort_by,
        sort_order,
      });
    }
  };

  const [organtioanOptions, setoOrgantionOptions] = useState({
    currentCollection: "",
    currentProductType: "",
    currentTags: "",
  });
  //  删除产品

  const [removeProduct, setRemoveProduct] = useState({
    openKey: "",
    open: false,
    import_status: 0,
    type: false,
    modal: false,
    action: false,
    id: "",
  });

  // 恢复产品、
  const [restoreProduct, setRestoreProduct] = useState({
    openKey: "",
    open: false,
    import_status: 0,
    type: false,
    modal: false,
    action: false,
    id: [],
  });

  // 批量操作type、tag\collections
  const [activeAction, setActiveAction] = useState("");
  const [actionData, setActionData] = useState([]);
  const handleBulkShow = (type) => {
    // 按照类型展示弹窗
    setActiveAction(type);
    if (type === "collections") {
      setActionData(modifiedFilter(attributeData.collection));
    }
    if (type === "type") {
      setActionData([]);
    }
    if (type === "tags") {
      setActionData([]);
    }
  };
  // 关闭弹窗事件
  const onCloseBulk = () => {
    setActiveAction("");
  };
  // 保存变体更新
  // const saveDetailVariants = async (data) => {
  //   // console.log(data);

  //   let params = {
  //     record_id: selectedResources,
  //     information: {
  //       ...data,
  //     },
  //   };
  //   try {
  //   let res = await reqPostProductManagementVariantsSave(params);
  //   if (res.code === "200") {
  //     handleSelectionChange('page',false,0)
  //   }
  //   } catch (error) {}
  // };
  const saveDetailVariantsFilter = async (data) => {
    // console.log(data);
    let ids = listData?.list
      .filter(
        (item) =>
          selectedResources.includes(item.id) && item.product_status === 1
      )
      .map((item) => item.id);
    if (ids.length === 0) {
      return;
    }
    let params = {
      record_id: ids,
      information: {
        ...data,
      },
    };
    try {
      let res = await reqPostProductManagementVariantsSave(params);
      if (res.code === "200") {
        toastFun("Saved Successfully");
        handleSelectionChange("page", false, 0);
      }
    } catch (error) {}
  };
  // 保存
  const handleSaveBulk = (type, data) => {
    if (selectedResources.length > 0) {
      setListData((prevValue) => {
        let newData = [...prevValue.list];
        newData.forEach((item) => {
          if (
            type === "collections" &&
            selectedResources.includes(item.id) &&
            item.product_status === 1
          ) {
            item.select_collections = data;
            item.is_update = 1;
          }
          if (
            type === "type" &&
            selectedResources.includes(item.id) &&
            item.product_status === 1
          ) {
            item.select_product_type = data;
            item.is_update = 1;
          }
          if (
            type === "tags" &&
            selectedResources.includes(item.id) &&
            item.product_status === 1
          ) {
            item.select_tags = data;
            item.is_update = 1;
          }
        });
        return {
          ...prevValue,
          list: newData,
        };
      });
      switch (type) {
        case "collections":
          saveDetailVariantsFilter({ collections: data });
          break;
        case "type":
          let typeData = attributeData.type;
          let typeList = data.map((item) => {
            return { id: item, title: item };
          });
          // 合并数组并去重
          let result = [
            ...typeData,
            ...typeList.filter(
              (item2) => !typeData.some((item1) => item1.title === item2.title)
            ),
          ];
          setAttributeData({ ...attributeData, type: result });
          saveDetailVariantsFilter({ product_type: data[0] });
          break;
        case "tags":
          let tagData = attributeData.tags;
          let tagList = data.map((item) => {
            return { id: item, title: item };
          });
          // 合并数组并去重
          let result2 = [
            ...tagData,
            ...tagList.filter(
              (item2) => !tagData.some((item1) => item1.title === item2.title)
            ),
          ];
          setAttributeData({ ...attributeData, tags: result2 });
          saveDetailVariantsFilter({ tag: data });
          break;
        default:
          return;
      }
    }
  };

  // 产品上传店铺 批量
  const handleToStoreSubmit = async (ids) => {
    let offline = [];
    for (let i = 0; i < listData?.list.length; i++) {
      if (listData?.list[i].product_status !== 1) {
        offline.push(listData?.list[i].id);
      }
    }
    const commonElements = ids.filter((item) => !offline.includes(item));
    try {
      let params = {
        type: allResourcesSelected ? 1 : 0,
        ids: JSON.stringify(commonElements),
        auto_publish: listData.auto_publish ? 1 : 0,
        is_inventory: listData.auto_inventory ? 1 : 0,
        upload_reminder: 1,
      };
      let res = await reqPostProductManagementProductsUploadShopify(params);
      if (res.code === "200") {
        let list = listData?.list.map((item) => {
          if (commonElements.includes(item.id)) {
            return {
              ...item,
              is_publish: 2,
            };
          } else {
            return item;
          }
        });
        // 导入、上传产品轮询
        const needImportShopifyIdsArray = list
          ?.filter((item) => item.is_publish === 2)
          ?.map((item) => item.id);
        const needImportAliIdsArray = list
          ?.filter((item) => item.product_status === 0)
          ?.map((item) => item.id);
        if (
          needImportShopifyIdsArray.length > 0 ||
          needImportAliIdsArray.length > 0
        ) {
          restartPolling({
            shopify_ids: JSON.stringify(needImportShopifyIdsArray),
            product_ids: JSON.stringify(needImportAliIdsArray),
          });
        }
        setListData((prev) => ({
          ...prev,
          list,
        }));
      }
      toastFun("Product is listing");
      handleSelectionChange("page", false, 0);
    } catch (error) {}
  };

  // 处理默认事件

  const formatProfit = (value, symbol) => {
    const numericValue = Number(value);
    let num =
      numericValue >= 0
        ? `${symbol}${numericValue}`
        : `-${symbol}${-numericValue}`;
    return num;
  };
  // 重置价格展示
  const resetPriceShow = (item, type) => {
    let data = item?.price_range;
    let price = data?.min_price;
    let cost = data?.min_cost;
    let profit = "";
    let nodeText = "";
    if (data) {
      if (parseFloat(data.min_price) === parseFloat(data.max_price)) {
        price = `${data.symbol + parseFloat(data.min_price)}`;
      }
      if (parseFloat(data.min_price) < parseFloat(data.max_price)) {
        price = `${data.symbol + parseFloat(data.min_price)} - ${
          data.symbol + parseFloat(data.max_price)
        }`;
      }
      if (parseFloat(data.min_cost) === parseFloat(data.max_cost)) {
        cost = `${data.symbol + parseFloat(data.min_cost)}`;
      }
      if (parseFloat(data.min_cost) < parseFloat(data.max_cost)) {
        cost = `${data.symbol + parseFloat(data.min_cost)} - ${
          data.symbol + parseFloat(data.max_cost)
        }`;
      }
      if (
        parseFloat(data.min_cost) > 0 &&
        parseFloat(data.min_price) === parseFloat(data.max_price) &&
        parseFloat(data.min_cost) === parseFloat(data.max_cost) &&
        parseFloat(data.min_shipping_fee) === parseFloat(data.max_shipping_fee)
      ) {
        profit = formatProfit(
          parseFloat(
            data.min_price - data.min_cost - data.min_shipping_fee
          ).toFixed(2),
          data.symbol
        );
      }
      if (
        parseFloat(data.min_cost) > 0 &&
        parseFloat(data.min_price) === parseFloat(data.max_price) &&
        parseFloat(data.min_cost) < parseFloat(data.max_cost) &&
        parseFloat(data.min_shipping_fee) === parseFloat(data.max_shipping_fee)
      ) {
        if (
          parseFloat(
            data.min_price - data.min_cost - data.min_shipping_fee
          ).toFixed(2) < 0
        ) {
          profit = `${formatProfit(
            parseFloat(
              data.min_price - data.min_cost - data.min_shipping_fee
            ).toFixed(2),
            data.symbol
          )} to ${formatProfit(
            parseFloat(
              data.max_price - data.max_cost - data.max_shipping_fee
            ).toFixed(2),
            data.symbol
          )}`;
        } else {
          profit = `${formatProfit(
            parseFloat(
              data.min_price - data.min_cost - data.min_shipping_fee
            ).toFixed(2),
            data.symbol
          )} - ${formatProfit(
            parseFloat(
              data.max_price - data.max_cost - data.max_shipping_fee
            ).toFixed(2),
            data.symbol
          )}`;
        }
      }
      if (
        parseFloat(data.min_cost) > 0 &&
        parseFloat(data.min_price) < parseFloat(data.max_price)
      ) {
        if (
          parseFloat(
            data.min_price - data.min_cost - data.min_shipping_fee
          ).toFixed(2) < 0
        ) {
          profit = `${formatProfit(
            parseFloat(
              data.min_price - data.min_cost - data.min_shipping_fee
            ).toFixed(2),
            data.symbol
          )} to ${formatProfit(
            parseFloat(
              data.max_price - data.max_cost - data.max_shipping_fee
            ).toFixed(2),
            data.symbol
          )}`;
        } else {
          profit = `${formatProfit(
            parseFloat(
              data.min_price - data.min_cost - data.min_shipping_fee
            ).toFixed(2),
            data.symbol
          )} - ${formatProfit(
            parseFloat(
              data.max_price - data.max_cost - data.max_shipping_fee
            ).toFixed(2),
            data.symbol
          )}`;
        }
      }

      nodeText = (
        <Box>
          <InlineStack blockAlign="center">
            <Tooltip
              width={300}
              content={
                <Box
                  style={{
                    padding: "8px",
                    fontWeight: 500,
                    background: "rgb(243, 243, 243)",
                    color: "rgba(0, 91, 211, 1)",
                  }}
                >
                  <Box>
                    Price ={" "}
                    {listData?.get_shipping_fee
                      ? `(Product Cost  ${
                          data.price_formula + " " + data.price_factor
                        }) + Shipping Fee`
                      : `Product Cost  ${
                          data.price_formula + " " + data.price_factor
                        }`}
                  </Box>
                </Box>
              }
              hasUnderline
            >
              <span>{price}</span>
            </Tooltip>
            {selected === 0 ? (
              <span
                onClick={() => {
                  utils.routeListen(
                    "/admin/product_management/product/" + item.id
                  );
                  utils.routeJump1(
                    router,
                    "/admin/product_management/product/" + item.id
                  );
                  store.dispatch(
                    setInitialPath(
                      currentPath || "/admin/product_management?selectedView=0"
                    )
                  );
                }}
              >
                <Icon source={EditIcon} tone="base" />
              </span>
            ) : (
              ""
            )}
          </InlineStack>
          {selected !== 1 ? (
            <Tooltip
              width={300}
              content={
                <Box
                  style={{
                    padding: "8px",
                    fontWeight: 500,
                    background: "rgb(243, 243, 243)",
                    color: "rgba(0, 91, 211, 1)",
                  }}
                >
                  <Box>
                    Profit = Price - Product Cost
                    {parseFloat(data.min_shipping_fee) > 0
                      ? ` - Shipping Fee`
                      : ""}
                  </Box>
                </Box>
              }
              hasUnderline
            >
              <span
                style={{
                  color:
                    parseFloat(
                      data.min_price - data.min_cost - data.min_shipping_fee
                    ).toFixed(2) >= 0
                      ? "rgba(7, 148, 85, 1)"
                      : "rgba(142, 31, 11, 1)",
                }}
              >
                {profit}
              </span>
            </Tooltip>
          ) : (
            ""
          )}
        </Box>
      );
      if (type === "cost") {
        nodeText = (
          <Box>
            <span>{cost}</span>
          </Box>
        );
      }
    }
    return nodeText;
  };

  // 添加产品到购物车
  const [cartData, setCartData] = useState({
    open: false,
    record_id: "",
  });
  const addProductToCart = (item) => {
    setCartData({
      open: true,
      record_id: item.id,
    });
  };

  const plan_level = useSelector(
    (state) => state.languageReducer.subScriptData
  );
  // sourcing产品
  const handleSourcing = (ids, user_product_id = "") => {
    setSourceLess((prev) => ({
      ...prev,
      isSource: true,
      sourceBulk: ids,
      user_product_id,
      func: handleSelectionChange,
    }));
    setSourceProductId(user_product_id);
    setBulkSourcingData(ids);
    if (
      plan_level.bill_level === 0 ||
      plan_level.level === 0 ||
      plan_level.bill_level === 4 ||
      plan_level.level === 4
    ) {
      if (ids.length > 1) {
        setSourcingLimitModal(true);
        return;
      }
    }
    if (
      (plan_level.bill_level === 0 ||
        plan_level.level === 0 ||
        plan_level.bill_level === 4 ||
        plan_level.level === 4) &&
      user_product_id === ""
    ) {
      setSourcingLimitModal(true);
      return;
    }
    if (planData.sourcingLimit >= planData.sourcingDay) {
      if (
        (plan_level.bill_level === 5 || plan_level.level === 5) &&
        userPoints.userPointsData.available_points < 200
      ) {
        setEnoughPoint(true);
        return;
      } else {
        setSourcingLimitModal(true);
      }
    } else {
      if (ids.length + planData.sourcingLimit > planData.sourcingDay) {
        if (
          (plan_level.bill_level === 5 || plan_level.level === 5) &&
          ids.length * 200 > userPoints.userPointsData.available_points
        ) {
          setEnoughPoint(true); //达到上限
          return;
        } else {
          setSourcingLimitModal(true); // 次数不足
        }
      } else {
        // setEnoughPoint(true)
        // setSourcingLimitModal(true)
        setBatchSourcingActive(true);
      }
    }
  };

  // 批量sourcing产品
  const [batchSourcingActive, setBatchSourcingActive] = useState(false);
  const [batchSourcingLoading, setBatchSourcingLoading] = useState(false);
  const [bulkSourcingData, setBulkSourcingData] = useState([]);
  const [sourceProductId, setSourceProductId] = useState("");
  const handleBatchSourcingActive = (val) => {
    setBatchSourcingActive(val);
    setSourceProductId("");
  };
  const handleBatchSourcingRequest = async () => {
    const params = {
      shopify_product_ids: bulkSourcingData.join(","),
      is_point: 0,
    };
    setBatchSourcingLoading(true);
    try {
      const response = await reqShopifyNewStoreBatchSourcing(params);
      if (response.code === "200") {
        toastFun("Sourcing initiated");
        setPlanData((prevValue) => ({
          ...prevValue,
          sourcingLimit: prevValue.sourcingLimit + bulkSourcingData.length,
        }));
        getPageData(selected);
      } else {
        // 不满足sourcing条件时，弹窗提示
        store.dispatch(
          changeBannerData({
            bannerData: {
              show: true,
              desc: response.msg,
            },
          })
        );
        getPageData(selected);
      }
      handleSelectionChange("page", false, 0);
      setBatchSourcingActive(false);
      setBatchSourcingLoading(false);
    } catch (error) {}
  };
  // 单个source产品
  const handleSourcingRequest = async () => {
    if (sourceProductId === "") {
      return;
    }
    const params = {
      shopify_product_id: bulkSourcingData.join(","),
      user_product_id: sourceProductId,
      is_point: 0,
    };
    setBatchSourcingLoading(true);
    try {
      const response = await reqPostSourcingGoodsRequestSave(params);
      if (response.code === "200") {
        toastFun("Sourcing initiated");
        setPlanData((prevValue) => ({
          ...prevValue,
          sourcingLimit: prevValue.sourcingLimit + 1,
        }));
        getPageData(selected);
      } else {
        // 不满足sourcing条件时，弹窗提示
        store.dispatch(
          changeBannerData({
            bannerData: {
              show: true,
              desc: response.msg,
            },
          })
        );
        getPageData(selected);
      }
      setSourceProductId("");
      handleSelectionChange("page", false, 0);
      setBatchSourcingActive(false);
      setBatchSourcingLoading(false);
    } catch (error) {}
  };

  const resetCollection = (item) => {
    let text = "";
    let currentList = attributeData.collection ? attributeData.collection : [];
    let currentSelect = item.select_collections ? item.select_collections : [];
    let list = [];
    for (let i = 0; i < currentList.length; i++) {
      if (
        currentSelect.includes(currentList[i].id) ||
        currentSelect.includes(String(currentList[i].id))
      ) {
        list.push(currentList[i].title);
      }
    }
    text = list;
    return text;
  };

  const [popImage, setPopImage] = useState("");

  const boxRef = useRef(null);

  const handleClickOutside = (event) => {
    // 检查点击是否发生在盒子之外
    if (boxRef.current && boxRef.current.contains(event.target)) {
      return;
    } else {
      setoOrgantionOptions({
        currentCollection: "",
        currentProductType: "",
        currentTags: "",
      });
    }
  };

  useEffect(() => {
    // 添加全局点击事件监听
    document.addEventListener("mousedown", handleClickOutside);

    // 清除监听事件
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // action 按钮组重置
  // const actionBtnFun = ()=>{
  //   let content = ''
  //   return content;
  // }
  const [seoModalData, setSeoModalData] = useState("");
  // SEO集成
  const handleSeoAudit = async (ProductId) => {
    let person = store.getState().languageReducer.subScriptData;
    let params = {
      website: person.domain,
      data_id: ProductId,
      action: "product",
    };
    try {
      await fetch(
        `https://api-admin.seoant.com/open-api/v1/coop-seo/jumpOnPage`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(params),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.code === 200) {
            if (res.data.status === 1) {
              window.open(res.data.jump_url, "_blank");
            } else {
              let seoModal = (
                <Modal
                  // large
                  open={true}
                  onClose={() => setSeoModalData("")}
                >
                  <div className="seo_ant_modal">
                    <div className="seo_ant_modal_title">
                      {
                        "SEO audit needs SEOAnt installation, deeply integrated with Dropshipman."
                      }{" "}
                      <span></span>
                    </div>
                    <div className="seo_ant_modal_banner">
                      <img
                        src="/seoExperts/seo_modal_banner.png"
                        alt="dropshipman"
                      />
                    </div>
                    <div className="seo_ant_modal_button">
                      <Button onClick={() => setSeoModalData("")}>
                        {"Cancel"}
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          window.open(
                            res.data.jump_url
                              ? res.data.jump_url
                              : "https://share.channelwill.com/app/116548bae341884wGT",
                            "_blank"
                          );
                        }}
                      >
                        {"Try It For Free Now"}
                      </Button>
                    </div>
                  </div>
                </Modal>
              );
              setSeoModalData(seoModal);
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (err) {
      console.warn("Error:", err);
    }
  };
  const handlePageDesign = () => {
    window.open("https://pagef.ly/1uckx9", "_blank");
  };
  const handleShopifyDetail = async (id) => {
    try {
      let res = await reqGetProductManagementProductsShopifyDetail({
        record_id: id,
      });
      if (res.code === "200") {
        if (res.data) {
          window.open(res.data, "_blank");
        }
      }
    } catch (error) {}
  };
  const [toast, setToast] = useState(false); // 提示
  const [toastError, setToastError] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  const toggleToast = useCallback(() => setToast((toast) => !toast), []);

  const toastMarkup = toast ? (
    <Toast content={toastMsg} onDismiss={toggleToast} error={toastError} />
  ) : null;

  const toastFun = (msg, error) => {
    setToastError(error);
    setToastMsg(msg);
    setToast(false);
    toggleToast();
  };
  const rowMarkup1 = listData?.list?.map((item, ind) => {
    // 是否是正常产品
    let isNoNormal =
      item.product_status === 0 ||
      (item.product_status > 2 && item.product_status < 5);
    return (
      <IndexTable.Row
        id={item.id}
        key={ind}
        selected={selectedResources.includes(item.id)}
        position={item.id}
        onClick={() => {
          if (removeProduct.openKey === item.id) {
            return;
          }
          if (
            organtioanOptions.currentCollection !== "" ||
            organtioanOptions.currentProductType !== "" ||
            organtioanOptions.currentTags !== ""
          ) {
            return;
          }
          if (
            item.product_status !== 0 &&
            item.id !== "" &&
            !(item.product_status > 2 && item.product_status < 5)
          ) {
            utils.routeListen("/admin/product_management/product/" + item.id);
            utils.routeJump1(
              router,
              "/admin/product_management/product/" + item.id
            );
            store.dispatch(
              setInitialPath(
                currentPath || "/admin/product_management?selectedView=0"
              )
            );
          }
        }}
        disabled={item.product_status === 0 || item.is_publish === 2}
      >
        <IndexTable.Cell className="product_cell_01">
          <Popover
            activator={
              <Box
                onMouseEnter={() => {
                  setPopImage(item.id);
                }}
              >
                <Thumbnail
                  source={item.image_url ? item.image_url : NoteIcon}
                  size="small"
                  alt="Black choker necklace"
                />
              </Box>
            }
            active={popImage === item.id}
            autofocusTarget="first-node"
            onClose={() => {
              setPopImage("");
            }}
          >
            <Box
              style={{ width: "220px", background: "rgb(249, 249, 247)" }}
              onMouseLeave={() => {
                setPopImage("");
              }}
            >
              {item.image_url ? (
                <Swiper
                  style={{
                    "--swiper-pagination-color": "rgba(48, 48, 48, 1)",
                  }}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  pagination={{ clickable: true }} // 配置分页点
                  modules={[Pagination, Thumbs]}
                  className="productSwiper"
                >
                  {item?.all_images?.slice(0, 5).map((it, k) => (
                    <SwiperSlide key={k}>
                      <img
                        style={{ width: "200px", height: "200px" }}
                        src={it.src}
                        alt="dropshipman"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                ""
              )}
            </Box>
          </Popover>
        </IndexTable.Cell>
        <IndexTable.Cell className="product_cell_02">
          <Box
            className="product_cell_02_box product_cell_02_box_height"
            onMouseEnter={() => {
              setPopImage("");
            }}
          >
            <BlockStack gap={100}>
              <Box
                className={
                  item.original_url !== ""
                    ? "product_cell_02_title_link product_cell_02_title"
                    : "product_cell_02_title"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.original_url !== "") {
                    if (
                      item.product_status !== 0 &&
                      item.id !== "" &&
                      !(item.product_status > 2 && item.product_status < 5)
                    ) {
                      utils.routeListen(
                        "/admin/product_management/product/" + item.id
                      );
                      utils.routeJump1(
                        router,
                        "/admin/product_management/product/" + item.id
                      );
                      store.dispatch(
                        setInitialPath(
                          currentPath ||
                            "/admin/product_management?selectedView=0"
                        )
                      );
                      // utils.routeListen(`/admin/product_selection/product-info?filter=${item?.original_id}&tag=home&category=&management=list&source=${item?.so_id ? "list" : ""}&favorite=0`);
                      // utils.routeJump1(router, `/admin/product_selection/product-info?filter=${item?.original_id}&tag=home&category=&management=list&source=${item?.so_id ? "list" : ""}&favorite=0`);
                      //   return
                    }
                    // window.open(item.original_url, "_blank");
                  }
                }}
              >
                {item.title
                  ? item.title
                  : item.ali_product_id
                  ? "aliExpress product id: " + item.ali_product_id
                  : ""}
              </Box>

              {item.is_update ? (
                <Box className="re_edited">
                  <span>{"Re-edited"}</span>
                </Box>
              ) : (
                ""
              )}
              {item.is_go ? (
                <Box>
                  <Badge>{"Sample Purchased"}</Badge>
                </Box>
              ) : (
                ""
              )}
            </BlockStack>
            {isNoNormal ? (
              ""
            ) : (
              <Box className="product_cell_02_ai">
                {item.id === aiOptimizedData.id &&
                aiOptimizedData.currentLoading ? (
                  <Spinner
                    accessibilityLabel="Small spinner example"
                    size="small"
                  />
                ) : (
                  <Tooltip content="AI Title Generator">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAiOptimized(
                          item.id,
                          item.title,
                          item.Description,
                          item.is_gpt
                        );
                      }}
                    >
                      <Icon source={WandIcon} tone="base" />
                    </span>
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell className="product_cell_03 product_cell_03_price_cost">
          {isNoNormal ? (
            "-"
          ) : (
            <Box
              className="product_cell_03_price"
              onMouseEnter={() => {
                setPopImage("");
              }}
            >
              {resetPriceShow(item, "price")}
            </Box>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell className="product_cell_04 product_cell_03_price_cost">
          {isNoNormal ? (
            "-"
          ) : (
            <Box
              className="product_cell_04_cost"
              onMouseEnter={() => {
                setPopImage("");
              }}
            >
              {resetPriceShow(item, "cost")}
            </Box>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell className="product_cell_03 product_cell_03_price_cost">
          {isNoNormal ? (
            "-"
          ) : (
            <Box
              className="product_cell_03_price"
              onMouseEnter={() => {
                setPopImage("");
              }}
            >
              {item?.price_range?.min_shipping_fee &&
              item?.price_range?.min_shipping_fee !== 0 &&
              item?.price_range?.min_shipping_fee !== "0.00" ? (
                <span>
                  {" "}
                  {item?.price_range?.symbol}
                  {item?.price_range?.min_shipping_fee ===
                  item?.price_range?.max_shipping_fee
                    ? item?.price_range?.min_shipping_fee
                    : item?.price_range?.min_shipping_fee +
                      " to " +
                      item?.price_range?.max_shipping_fee}
                </span>
              ) : (
                "-"
              )}
            </Box>
          )}
        </IndexTable.Cell>
        {isNoNormal ? (
          <IndexTable.Cell className="product_cell_05">-</IndexTable.Cell>
        ) : (
          <IndexTable.Cell className="product_cell_05">
            <Box
              onMouseEnter={() => {
                setPopImage("");
              }}
              className={
                item?.select_collections && item?.select_collections?.length > 0
                  ? "product_cell_05_org"
                  : "product_cell_05_org product_cell_05_org_disabled"
              }
            >
              <CollectionPopover
                boxRef={boxRef}
                listData={listData}
                setListData={setListData}
                attributeData={attributeData}
                setAttributeData={setAttributeData}
                keys={ind}
                type={"collection"}
                handleSelectionChange={handleSelectionChange}
                organtioanOptions={organtioanOptions}
                setoOrgantionOptions={setoOrgantionOptions}
                toastFun={toastFun}
              />
            </Box>
            <Box
              onMouseEnter={() => {
                setPopImage("");
              }}
              style={{ margin: "5px 0" }}
              className={
                item?.select_product_type &&
                item?.select_product_type?.length > 0
                  ? "product_cell_05_org"
                  : "product_cell_05_org product_cell_05_org_disabled"
              }
            >
              <CollectionPopover
                boxRef={boxRef}
                listData={listData}
                setListData={setListData}
                attributeData={attributeData}
                setAttributeData={setAttributeData}
                keys={ind}
                type={"type"}
                handleSelectionChange={handleSelectionChange}
                organtioanOptions={organtioanOptions}
                setoOrgantionOptions={setoOrgantionOptions}
                toastFun={toastFun}
              />
            </Box>
            <Box
              onMouseEnter={() => {
                setPopImage("");
              }}
              className={
                item?.select_tags && item?.select_tags?.length > 0
                  ? "product_cell_05_org"
                  : "product_cell_05_org product_cell_05_org_disabled"
              }
            >
              <CollectionPopover
                boxRef={boxRef}
                listData={listData}
                setListData={setListData}
                attributeData={attributeData}
                setAttributeData={setAttributeData}
                keys={ind}
                type={"tags"}
                handleSelectionChange={handleSelectionChange}
                organtioanOptions={organtioanOptions}
                setoOrgantionOptions={setoOrgantionOptions}
                toastFun={toastFun}
              />
            </Box>
          </IndexTable.Cell>
        )}
        <IndexTable.Cell className="product_cell_06">
          {isNoNormal ? (
            "-"
          ) : (
            <Box
              onMouseEnter={() => {
                setPopImage("");
              }}
              className="product_cell_06_date"
            >
              <Tooltip
                content={formatTimestamp(item.created_time)}
                hasUnderline
              >
                <span> {getYMDStart(item.created_time)}</span>
              </Tooltip>
            </Box>
          )}
        </IndexTable.Cell>
        {item.product_status > 2 && item.product_status < 5 ? (
          <IndexTable.Cell className="product_cell_07">
            <InlineStack gap={200} blockAlign="center">
              <Box
                onMouseEnter={() => {
                  setPopImage("");
                }}
                style={{ color: "rgba(142, 31, 11, 1)" }}
              >
                Import failed
              </Box>
              <Box
                onMouseEnter={() => {
                  setPopImage("");
                }}
                className="product_cell_07_source"
              >
                <Button
                  loading={loadingRefresh === item.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddProduct(
                      item.ali_product_id,
                      item.id,
                      item.country_code
                    );
                  }}
                >
                  Refresh
                </Button>
              </Box>
            </InlineStack>
          </IndexTable.Cell>
        ) : (
          <IndexTable.Cell className="product_cell_07">
            {item.product_status === 0 ? (
              ""
            ) : (
              <Box
                onMouseEnter={() => {
                  setPopImage("");
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.original_url !== "") {
                    if (
                      item.product_source_from === 2 ||
                      item.product_source_from === 5
                    ) {
                      // utils.routeListen(
                      //   "/admin/product_management/product/" + item.id
                      // );
                      // utils.routeJump1(
                      //   router,
                      //   "/admin/product_management/product/" + item.id
                      // );
                      // store.dispatch(
                      //   setInitialPath(
                      //     currentPath || "/admin/product_management?selectedView=0"
                      //   )
                      // );
                      utils.routeListen(
                        `/admin/product_selection/product-info?filter=${
                          item?.original_id
                        }&tag=home&category=&management=list&source=${
                          item?.so_id ? "list" : ""
                        }&favorite=0`
                      );
                      utils.routeJump1(
                        router,
                        `/admin/product_selection/product-info?filter=${
                          item?.original_id
                        }&tag=home&category=&management=list&source=${
                          item?.so_id ? "list" : ""
                        }&favorite=0`
                      );
                      return;
                    }
                    window.open(item.original_url, "_blank");
                  }
                }}
                className={
                  item.original_url !== ""
                    ? "product_cell_02_title_link product_cell_02_title product_cell_07_source"
                    : "product_cell_02_title product_cell_07_source"
                }
              >
                {item.source === "DSM" ? "Dropshipman" : item.source}
              </Box>
            )}
          </IndexTable.Cell>
        )}
        {item.product_status > 2 && item.product_status < 5 ? (
          <IndexTable.Cell className="product_cell_08">
            <Box
              onMouseEnter={() => {
                setPopImage("");
              }}
              className={"list_button"}
            >
              <InlineStack gap={300}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOtherImportParams((prev) => ({
                      ...prev,
                      open: true,
                      type: "AliExpress",
                      country_code: item.country_code,
                      record_id: item.id,
                      al_id: item.ali_product_id,
                    }));
                  }}
                >
                  Re-import
                </Button>
                <Button
                  variant="plain"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRemoveProduct((prevValue) => ({
                      ...prevValue,
                      id: [item.id],
                      import_status: 1,
                      modal: true,
                    }));
                  }}
                >
                  Remove
                </Button>
              </InlineStack>
            </Box>
          </IndexTable.Cell>
        ) : (
          <IndexTable.Cell className="product_cell_08">
            {item.is_publish === 2 || item.product_status === 0 ? (
              <InlineStack gap={200}>
                <Button
                  variant="tertiary"
                  onMouseEnter={() => {
                    setPopImage("");
                  }}
                >
                  <InlineStack gap={100} blockAlign="center">
                    <span>
                      {item.is_publish === 2 ? "Listing..." : "Importing..."}
                    </span>
                    <Spinner
                      accessibilityLabel="Small spinner example"
                      size="small"
                    />
                  </InlineStack>
                </Button>
              </InlineStack>
            ) : (
              <InlineStack
                gap={300}
                onMouseEnter={() => {
                  setPopImage("");
                }}
              >
                {item.product_status === 2 ? (
                  <Tooltip
                    content={
                      "This product has been offline, not supported for listing"
                    }
                  >
                    <Button variant="primary" disabled={true}>
                      Offline
                    </Button>
                  </Tooltip>
                ) : (
                  <Box
                    className={"list_button"}
                    onMouseEnter={() => {
                      setPopImage("");
                    }}
                  >
                    {" "}
                    <Button
                      variant="primary"
                      disabled={item.product_status !== 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (listData.upload_reminder) {
                          handleToStoreSubmit([item.id]);
                        } else {
                          handleListProduct(item.id);
                        }
                      }}
                    >
                      List Product
                    </Button>
                  </Box>
                )}
                {item.original_id !== "" && (
                  <Box
                    style={{ marginLeft: "8px" }}
                    onMouseEnter={() => {
                      setPopImage("");
                    }}
                  >
                    <Button
                      variant="plain"
                      disabled={item.product_status !== 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        addProductToCart(item);
                      }}
                    >
                      Buy a sample
                    </Button>
                  </Box>
                )}
                <Popover
                  active={removeProduct.openKey === item.id}
                  onMouseEnter={() => {
                    setPopImage("");
                  }}
                  activator={
                    <Button
                      variant="tertiary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRemoveProduct((prevValue) => ({
                          ...prevValue,
                          openKey: item.id,
                        }));
                      }}
                    >
                      <Icon source={MenuHorizontalIcon} tone="base" />
                    </Button>
                  }
                  autofocusTarget="first-node"
                  onClose={(e) => {
                    setRemoveProduct((prevValue) => ({
                      ...prevValue,
                      openKey: "",
                    }));
                  }}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[
                      {
                        content: "Remove",
                        onAction: (e) => {
                          setRemoveProduct((prevValue) => ({
                            ...prevValue,
                            id: [item.id],
                            import_status:
                              Number(selected) === 0
                                ? 1
                                : Number(selected) === 1
                                ? 2
                                : 3,
                            type: 0,
                            modal: true,
                          }));
                        },
                      },
                    ]}
                  />
                </Popover>
              </InlineStack>
            )}
          </IndexTable.Cell>
        )}
      </IndexTable.Row>
    );
  });
  const rowMarkup2 = listData?.list?.map((item, ind) => (
    <IndexTable.Row
      id={item.id}
      key={ind}
      selected={selectedResources.includes(item.id)}
      onClick={() => {
        if (removeProduct.openKey === item.id) {
          return;
        }
      }}
      disabled={item.product_status === 0 || item.is_publish === 2}
    >
      <IndexTable.Cell className="product_cell_01">
        <Popover
          activator={
            <Box
              onMouseEnter={() => {
                setPopImage(item.id);
              }}
            >
              <Thumbnail
                source={item.image_url ? item.image_url : NoteIcon}
                size="small"
                alt="Black choker necklace"
              />
            </Box>
          }
          active={popImage === item.id}
          autofocusTarget="first-node"
          onClose={() => {
            setPopImage("");
          }}
        >
          <Box
            style={{ width: "220px", background: "rgb(249, 249, 247)" }}
            onMouseLeave={() => {
              setPopImage("");
            }}
          >
            {item.image_url ? (
              <Swiper
                style={{
                  "--swiper-pagination-color": "rgba(48, 48, 48, 1)",
                }}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={{ clickable: true }} // 配置分页点
                modules={[Pagination, Thumbs]}
                className="productSwiper"
              >
                {item?.all_images?.slice(0, 5).map((it, k) => (
                  <SwiperSlide key={k}>
                    <img
                      style={{ width: "200px", height: "200px" }}
                      src={it.src}
                      alt="dropshipman"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              ""
            )}
          </Box>
        </Popover>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_02">
        <Box
          className="product_cell_02_box product_cell_02_box_height"
          onMouseEnter={() => {
            setPopImage("");
          }}
        >
          <BlockStack gap={100}>
            <Box className={"product_cell_02_title"}>{item.title}</Box>
          </BlockStack>
          {item.is_publish === 1 ? (
            <Box
              className="product_cell_02_ai2"
              onClick={(e) => {
                e.stopPropagation();
                if (item.id) {
                  handleShopifyDetail(item.id);
                }
              }}
            >
              <Tooltip content="Preview on Online Store">
                <Icon source={ViewIcon} tone="base" />
              </Tooltip>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_03">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (item.original_url !== "") {
              if (
                item.product_source_from === 2 ||
                item.product_source_from === 5
              ) {
                // utils.routeListen(
                //   "/admin/product_management/product/" + item.id
                // );
                // utils.routeJump1(
                //   router,
                //   "/admin/product_management/product/" + item.id
                // );
                // store.dispatch(
                //   setInitialPath(
                //     currentPath || "/admin/product_management?selectedView=0"
                //   )
                // );
                utils.routeListen(
                  `/admin/product_selection/product-info?filter=${
                    item?.original_id
                  }&tag=home&category=&management=list&source=${
                    item?.so_id ? "list" : ""
                  }&favorite=0`
                );
                utils.routeJump1(
                  router,
                  `/admin/product_selection/product-info?filter=${
                    item?.original_id
                  }&tag=home&category=&management=list&source=${
                    item?.so_id ? "list" : ""
                  }&favorite=0`
                );
                return;
              }
              window.open(item.original_url, "_blank");
            }
          }}
          className={
            item.original_url !== ""
              ? "product_cell_02_title_link product_cell_02_title product_cell_07_source"
              : "product_cell_02_title product_cell_07_source"
          }
        >
          {item.source === "DSM" ? "Dropshipman" : item.source}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_04">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
        >
          ${item.sale_amount}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className=".product_cell_06">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
        >
          {item.sale_count}
        </Box>
      </IndexTable.Cell>

      <IndexTable.Cell className="product_cell_03 product_cell_03_price_cost">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          className="product_cell_03_price"
        >
          {" "}
          {resetPriceShow(item, "price")}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_05 product_cell_03_price_cost">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          className="product_cell_03_cost"
        >
          {" "}
          {resetPriceShow(item, "cost")}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_03 product_cell_03_price_cost">
        <Box
          className="product_cell_03_price"
          onMouseEnter={() => {
            setPopImage("");
          }}
        >
          {item?.price_range?.min_shipping_fee &&
          item?.price_range?.min_shipping_fee !== 0 &&
          item?.price_range?.min_shipping_fee !== "0.00" ? (
            <span>
              {" "}
              {item?.price_range?.symbol}
              {item?.price_range?.min_shipping_fee ===
              item?.price_range?.max_shipping_fee
                ? item?.price_range?.min_shipping_fee
                : item?.price_range?.min_shipping_fee +
                  " to " +
                  item?.price_range?.max_shipping_fee}
            </span>
          ) : (
            "-"
          )}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_06">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          className="product_cell_06_date"
        >
          <Tooltip content={formatTimestamp(item.created_time)} hasUnderline>
            <span> {getYMDStart(item.created_time)}</span>
          </Tooltip>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_05">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          className="product_cell_05_collection"
        >
          {resetCollection(item).length > 0 ? (
            <Tooltip
              width={300}
              content={
                <Box style={{ padding: "0 4px 4px", maxWidth: "300px" }}>
                  <Box
                    style={{
                      fontWeight: "650",
                      fontSize: "14px",
                      marginBottom: "4px",
                    }}
                  >
                    Collections
                  </Box>
                  <InlineStack gap={100} wrap={true}>
                    {resetCollection(item)
                      .slice(0, 10)
                      .map((it, ind) => (
                        <Badge key={ind}>{it}</Badge>
                      ))}
                    {resetCollection(item).length > 10 ? (
                      <Badge>...</Badge>
                    ) : (
                      ""
                    )}
                  </InlineStack>
                </Box>
              }
            >
              <span className="organization_box_text">
                {resetCollection(item)
                  .map((it) => it)
                  .join(", ")}
              </span>
            </Tooltip>
          ) : (
            "-"
          )}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_08">
        {item.is_publish === 2 || item.product_status === 0 ? (
          <InlineStack>
            <Button
              variant="tertiary"
              onMouseEnter={() => {
                setPopImage("");
              }}
            >
              <InlineStack gap={100} blockAlign="center">
                <span>
                  {item.is_publish === 2 ? "Listing..." : "Importing..."}
                </span>
                <Spinner
                  accessibilityLabel="Small spinner example"
                  size="small"
                />
              </InlineStack>
            </Button>
          </InlineStack>
        ) : (
          <InlineStack gap={300}>
            <Button
              onMouseEnter={() => {
                setPopImage("");
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (item.edit_shopify) {
                  window.open(item.edit_shopify, "_blank");
                }
              }}
              icon={EditIcon}
            >
              Edit on Shopify
            </Button>
            {item.source === "DSM" && item.original_id !== "" && (
              <Button
                variant="plain"
                disabled={item.product_status !== 1}
                onClick={(e) => {
                  e.stopPropagation();
                  addProductToCart(item);
                }}
              >
                Buy a sample
              </Button>
            )}
            <Popover
              onMouseEnter={() => {
                setPopImage("");
              }}
              active={removeProduct.openKey === item.id}
              activator={
                <Button
                  variant="tertiary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRemoveProduct((prevValue) => ({
                      ...prevValue,
                      openKey: item.id,
                    }));
                  }}
                >
                  <Icon source={MenuHorizontalIcon} tone="base" />
                </Button>
              }
              autofocusTarget="first-node"
              onClose={() => {
                setRemoveProduct((prevValue) => ({
                  ...prevValue,
                  openKey: "",
                }));
              }}
            >
              <ActionList
                actionRole="menuitem"
                items={[
                  {
                    icon: SeoantIcon,
                    content: "Seo Audit",
                    onAction: () => handleSeoAudit(item.id),
                  },
                  {
                    icon: ColorNoneIcon,
                    content: "Page Design",
                    onAction: handlePageDesign,
                  },
                ]}
              />
            </Popover>
          </InlineStack>
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));
  const rowMarkup3 = listData?.list?.map((item, ind) => (
    <IndexTable.Row
      id={item.id}
      key={ind}
      selected={selectedResources.includes(item.id)}
      position={item.id}
      disabled={item.sourcing === 1}
      onClick={() => {}}
    >
      <IndexTable.Cell className="product_cell_01">
        <Thumbnail
          source={item?.imageUrl?.length > 0 ? item?.imageUrl[0] : NoteIcon} // 使用 item.imageUrl 作为图片地址
          size="small"
          alt="Black choker necklace"
        />
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_02">
        <Box className="product_cell_02_box product_cell_02_box_height2">
          <Box
            className="product_cell_02_title"
            onClick={() => {
              // window.open("http://www.baidu.com/", "_self");
            }}
          >
            {item.title}
          </Box>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_03">
        <Box className="product_cell_07_source">
          <Badge tone={item.status === "ACTIVE" ? "success" : "info"}>
            {item.status === "ACTIVE" ? "Active" : "Draft"}
          </Badge>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_04">
        <Box>
          {item.sourcing <= 2 ? (
            <TextColor content={item.sourcing} />
          ) : item.sourcing > 2 ? (
            <Badge tone="critical">
              {" "}
              <InlineStack blockAlign="center">
                {"Failed"}
                {item.fail_reason !== "" && (
                  <span className="reset_source_status">
                    <Tooltip content={item.fail_reason}>
                      <Icon source={QuestionCircleIcon} tone="base" />
                    </Tooltip>
                  </span>
                )}
              </InlineStack>
            </Badge>
          ) : (
            ""
          )}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_05">
        <Box>{item.vendor}</Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_08">
        <InlineStack gap={300}>
          <Box className={"list_button"}>
            <Button
              disabled={item.sourcing === 1}
              variant="primary"
              onClick={() => handleSourcing([item.id], item.user_product_id)}
            >
              {item.sourcing === 2 || item.sourcing === 3
                ? "Re-sourcing"
                : "Sourcing"}
            </Button>
          </Box>
          <Button
            variant="plain"
            onClick={() => {
              window.open(item.link, "_blank");
            }}
          >
            Edit on Shopify
          </Button>
          <Popover
            active={removeProduct.openKey === item.id}
            activator={
              <Button
                variant="tertiary"
                onClick={() => {
                  setRemoveProduct((prevValue) => ({
                    ...prevValue,
                    openKey: item.id,
                  }));
                }}
              >
                <Icon source={MenuHorizontalIcon} tone="base" />
              </Button>
            }
            autofocusTarget="first-node"
            onClose={() => {
              setRemoveProduct((prevValue) => ({
                ...prevValue,
                openKey: "",
              }));
            }}
          >
            <ActionList
              actionRole="menuitem"
              items={[
                {
                  icon: SeoantIcon,
                  content: "Seo Audit",
                  onAction: () => handleSeoAudit(item.id),
                },
              ]}
            />
          </Popover>
        </InlineStack>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));
  const rowMarkup4 = listData?.list?.map((item, ind) => (
    <IndexTable.Row
      id={item.id}
      key={ind}
      selected={selectedResources.includes(item.id)}
      position={item.id}
      onClick={() => {
        if (organtioanOptions.currentCollection !== "") {
          // setCurrentCollection('')
        }
      }}
    >
      <IndexTable.Cell className="product_cell_01">
        <Popover
          activator={
            <Box
              onMouseEnter={() => {
                setPopImage(item.id);
              }}
            >
              <Thumbnail
                source={item.image_url ? item.image_url : NoteIcon}
                size="small"
                alt="Black choker necklace"
              />
            </Box>
          }
          active={popImage === item.id}
          autofocusTarget="first-node"
          onClose={() => {
            setPopImage("");
          }}
        >
          <Box
            style={{ width: "220px", background: "rgb(249, 249, 247)" }}
            onMouseLeave={() => {
              setPopImage("");
            }}
          >
            <Swiper
              style={{
                "--swiper-pagination-color": "rgba(48, 48, 48, 1)",
              }}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              pagination={{ clickable: true }} // 配置分页点
              modules={[Pagination, Thumbs]}
              className="productSwiper"
            >
              {item?.all_images?.slice(0, 5).map((it, k) => (
                <SwiperSlide key={k}>
                  <img
                    style={{ width: "200px", height: "200px" }}
                    src={it.src}
                    alt="dropshipman"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Popover>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_02">
        <Box
          className="product_cell_02_box product_cell_02_box_height"
          onMouseEnter={() => {
            setPopImage("");
          }}
        >
          <BlockStack gap={100}>
            <Box
              className={"product_cell_02_title"}
              onClick={(e) => {
                e.stopPropagation();
                if (item.original_url !== "") {
                  if (
                    item.product_source_from === 2 ||
                    item.product_source_from === 5
                  ) {
                    // utils.routeListen(
                    //   "/admin/product_management/product/" + item.id
                    // );
                    // utils.routeJump1(
                    //   router,
                    //   "/admin/product_management/product/" + item.id
                    // );
                    // store.dispatch(
                    //   setInitialPath(
                    //     currentPath || "/admin/product_management?selectedView=0"
                    //   )
                    // );
                    // utils.routeListen(`/admin/product_selection/product-info?filter=${item?.original_id}&tag=home&category=&management=list&source=${item?.so_id ? "list" : ""}&favorite=0`);
                    // utils.routeJump1(router, `/admin/product_selection/product-info?filter=${item?.original_id}&tag=home&category=&management=list&source=${item?.so_id ? "list" : ""}&favorite=0`);
                    return;
                  }
                  // window.open(item.original_url, "_blank");
                }
              }}
            >
              {item.title}
            </Box>
          </BlockStack>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_03 product_cell_03_price_cost">
        <InlineStack blockAlign="center">
          <Box
            onMouseEnter={() => {
              setPopImage("");
            }}
            className="product_cell_03_price"
          >
            {resetPriceShow(item, "price")}
          </Box>
        </InlineStack>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_04 product_cell_03_price_cost">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          className="product_cell_04_cost"
        >
          {resetPriceShow(item, "cost")}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_03 product_cell_03_price_cost">
        <Box
          className="product_cell_03_price"
          onMouseEnter={() => {
            setPopImage("");
          }}
        >
          {item?.price_range?.min_shipping_fee &&
          item?.price_range?.min_shipping_fee !== 0 &&
          item?.price_range?.min_shipping_fee !== "0.00" ? (
            <span>
              {" "}
              {item?.price_range?.symbol}
              {item?.price_range?.min_shipping_fee ===
              item?.price_range?.max_shipping_fee
                ? item?.price_range?.min_shipping_fee
                : item?.price_range?.min_shipping_fee +
                  " to " +
                  item?.price_range?.max_shipping_fee}
            </span>
          ) : (
            "-"
          )}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_05">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          className="product_cell_05_collection"
        >
          {resetCollection(item).length > 0 ? (
            <Tooltip
              width={300}
              content={
                <Box style={{ padding: "0 4px 4px", maxWidth: "300px" }}>
                  <Box
                    style={{
                      fontWeight: "650",
                      fontSize: "14px",
                      marginBottom: "4px",
                    }}
                  >
                    Collections
                  </Box>
                  <InlineStack gap={100} wrap={true}>
                    {resetCollection(item)
                      .slice(0, 10)
                      .map((it, ind) => (
                        <Badge key={ind}>{it}</Badge>
                      ))}
                    {resetCollection(item).length > 10 ? (
                      <Badge>...</Badge>
                    ) : (
                      ""
                    )}
                  </InlineStack>
                </Box>
              }
            >
              <span className="organization_box_text">
                {resetCollection(item)
                  .map((it) => it)
                  .join(", ")}
              </span>
            </Tooltip>
          ) : (
            "-"
          )}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_06">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          className="product_cell_06_date"
        >
          <Tooltip content={formatTimestamp(item.created_time)} hasUnderline>
            <span> {getYMDStart(item.created_time)}</span>
          </Tooltip>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_07">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (item.original_url !== "") {
              if (
                item.product_source_from === 2 ||
                item.product_source_from === 5
              ) {
                // utils.routeListen(
                //   "/admin/product_management/product/" + item.id
                // );
                // utils.routeJump1(
                //   router,
                //   "/admin/product_management/product/" + item.id
                // );
                // store.dispatch(
                //   setInitialPath(
                //     currentPath || "/admin/product_management?selectedView=0"
                //   )
                // );
                utils.routeListen(
                  `/admin/product_selection/product-info?filter=${
                    item?.original_id
                  }&tag=home&category=&management=list&source=${
                    item?.so_id ? "list" : ""
                  }&favorite=0`
                );
                utils.routeJump1(
                  router,
                  `/admin/product_selection/product-info?filter=${
                    item?.original_id
                  }&tag=home&category=&management=list&source=${
                    item?.so_id ? "list" : ""
                  }&favorite=0`
                );
                return;
              }
              window.open(item.original_url, "_blank");
            }
          }}
          className={
            item.original_url !== ""
              ? "product_cell_02_title_link product_cell_02_title product_cell_07_source"
              : "product_cell_02_title product_cell_07_source"
          }
        >
          {item.source === "DSM" ? "Dropshipman" : item.source}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="product_cell_08">
        <Box
          onMouseEnter={() => {
            setPopImage("");
          }}
          className={"list_button"}
        >
          <InlineStack gap={300}>
            {item.product_status === 0 ? (
              <Button>
                <InlineStack gap={100}>
                  <span>Importding...</span>
                  <Spinner
                    accessibilityLabel="Small spinner example"
                    size="small"
                  />
                </InlineStack>
              </Button>
            ) : (
              <Button
                onMouseEnter={() => {
                  setPopImage("");
                }}
                onClick={() => {
                  setRestoreProduct((prevValue) => ({
                    ...prevValue,
                    id: [item.id],
                    open: true,
                    type: false,
                  }));
                }}
              >
                Restore
              </Button>
            )}

            <Button
              onMouseEnter={() => {
                setPopImage("");
              }}
              variant="plain"
              onClick={() => {
                setRemoveProduct((prevValue) => ({
                  ...prevValue,
                  id: [item.id],
                  import_status: 3,
                  type: 0,
                  modal: true,
                }));
              }}
            >
              Remove
            </Button>
          </InlineStack>
        </Box>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));
  let rowMarkup = "";
  if (Number(selected) === 0) {
    rowMarkup = rowMarkup1;
  }
  if (Number(selected) === 1) {
    rowMarkup = rowMarkup2;
  }
  if (Number(selected) === 2) {
    rowMarkup = rowMarkup3;
  }
  if (Number(selected) === 3) {
    rowMarkup = rowMarkup4;
  }
  // 定义参数有效性检查工具函数
  const hasValidParams = (params) => {
    if (!params) return false;

    // 需要验证的非空字符串参数
    const stringParams = [
      "q",
      "start_date",
      "end_date",
      "publish_start_date",
      "publish_end_date",
      "search_vendor",
    ];

    // 需要验证的非空数组参数
    const arrayParams = [
      "source",
      "product_status",
      "collection",
      "product_type",
      "product_tag",
      "price_range",
      "sale_count_range",
      "sale_price_range",
      "search_status",
    ];

    // 检查字符串参数
    const hasValidString = stringParams.some(
      (key) => params[key] !== undefined && params[key] !== ""
    );

    // 检查数组参数
    const hasValidArray = arrayParams.some((key) => params[key]?.length > 0);

    return hasValidString || hasValidArray;
  };

  const emptyStateMarkup =
    Object.values(listData).length > 0 ? (
      <Box>
        {listData?.list?.length === 0 && (
          <Box>
            {hasValidParams(requestParams) ? (
              <InitNotPageContent data={requestParams} />
            ) : (
              <InitPageContent
                selected={selected}
                setNoviceModal={setNoviceModal}
              />
            )}
          </Box>
        )}
      </Box>
    ) : (
      <CommonTableFilterSkeletonList />
    );

  const sortableFun = () => {
    let arr = [];
    if (Number(selected) === 0) {
      arr = [false, false, true, true, false, false, true, false, false];
    }
    if (Number(selected) === 1) {
      arr = [
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        false,
        true,
        false,
        false,
      ];
    }
    if (Number(selected) === 3) {
      arr = [false, false, true, true, false, false, true, false, false];
    }
    return arr;
  };
  // const shopify_level = useSelector(state => state.languageReducer.subScriptData);
  const widthPage = useScreenWidth();
  return (
    <Box className="product_table_box">
      <IndexTable
        resourceName={resourceName}
        itemCount={
          selectableOrders?.length
            ? selectableOrders.length
            : listData?.list?.length
            ? listData?.list?.length
            : 0
        }
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        hasMoreItems
        paginatedSelectAllText={
          hasValidParams(requestParams)
            ? ""
            : allResourcesSelected
            ? "Select " + selectedResources.length + "+ product"
            : "Select All"
        }
        paginatedSelectAllActionText={
          hasValidParams(requestParams)
            ? ""
            : Number(selected) === 2
            ? ""
            : allResourcesSelected
            ? "Select All"
            : "Select All"
        }
        onSelectionChange={handleSelectionChange}
        headings={headingTable}
        bulkActions={widthPage <= 600 ? bulkActions : bulkActionsPc}
        promotedBulkActions={widthPage <= 600 ? [] : promotedBulkActions}
        // sortDirection={requestParams.sort_order === 'asc' ? 'ascending' : 'descending'}
        defaultSortDirection="descending"
        // selectable={Number(selected) === 2 ? (Number(selected) === 2 && !(shopify_level.level <1 || shopify_level.bill_level <1 || shopify_level.bill_level === 4 || shopify_level.level === 4)) : true}
        sortable={sortableFun()}
        emptyState={emptyStateMarkup}
        onSort={handleSortHeading}
        pagination={
          listData?.list?.length > 0
            ? {
                label: listData?.list?.length > 0 ? pageMsg() : "",
                hasPrevious: hasPrevious,
                onPrevious: () => {
                  handlePrev();
                },
                hasNext: hasNext,
                onNext: () => {
                  handleNext();
                },
              }
            : undefined
        }
      >
        {rowMarkup}
      </IndexTable>

      {/* ai优化 标题 */}

      <AiUpdateModal
        userPoints={userPoints}
        aiOptimizedData={aiOptimizedData}
        setAiOptimizedData={setAiOptimizedData}
        handleAiOptimizedAgain={handleAiOptimizedAgain}
        handleAiOptimizedSuccess={handleAiOptimizedSuccess}
      />

      {/* 产品移除 */}
      <RemoveProductModal
        toastFun={toastFun}
        removeProduct={removeProduct}
        setRemoveProduct={setRemoveProduct}
        import_status={selected}
        handleSelectionChange={handleSelectionChange}
        getPageData={getPageData}
        getTabData={getTabData}
      />
      {/* 批量产品移除 */}
      <DeleteProductModal
        toastFun={toastFun}
        removeProduct={removeProduct}
        selectedResources={selectedResources}
        allResourcesSelected={allResourcesSelected}
        setRemoveProduct={setRemoveProduct}
        handleSelectionChange={handleSelectionChange}
        import_status={selected}
        getPageData={getPageData}
        getTabData={getTabData}
      />

      {/* 恢复产品 */}
      {/* 批量恢复产品 */}
      <RestoreProductModal
        toastFun={toastFun}
        listData={listData}
        handleTogglePopup={handleTogglePopup}
        restoreProduct={restoreProduct}
        selectedResources={selectedResources}
        allResourcesSelected={allResourcesSelected}
        setRestoreProduct={setRestoreProduct}
        handleSelectionChange={handleSelectionChange}
        import_status={selected}
        getPageData={getPageData}
        getTabData={getTabData}
      />
      {/* 批量操作 */}
      {activeAction !== "" && (
        <NotImportedBulkEditAction
          type={activeAction}
          open={activeAction}
          attributeData={attributeData}
          setAttributeData={setAttributeData}
          onCloseBulk={onCloseBulk}
          option={actionData}
          getOption={() => {}}
          handleSelectionChange={handleSelectionChange}
          handleSaveBulk={handleSaveBulk}
        />
      )}

      {/* ai优化积分不足 */}
      <AiPointsLessModal
        userPoints={userPoints}
        enoughPointsData={enoughPointsData}
        onClose={() => setEnoughPointsData(false)}
      />

      {/* 添加购物车 */}
      {cartData.record_id !== "" && (
        <AddCartModal
          callBackFun={callBackFun}
          cartData={cartData}
          onClose={() =>
            setCartData({
              open: false,
              record_id: "",
            })
          }
          toastFun={toastFun}
        />
      )}

      {/* 批量sourcing */}
      <BulkSourcingModal
        batchSourcingLoading={batchSourcingLoading}
        batchSourcingActive={batchSourcingActive}
        sourceProductId={sourceProductId}
        handleBatchSourcingActive={handleBatchSourcingActive}
        handleSourcingRequest={handleSourcingRequest}
        handleBatchSourcingRequest={handleBatchSourcingRequest}
        bulkSourcingData={bulkSourcingData}
      />

      {seoModalData}
      {toastMarkup}
    </Box>
  );
};
// 重置键值对
function modifiedFilter(data) {
  let modifiedData = [];
  if (data && data?.length > 0) {
    modifiedData = data.map((item) => ({
      label: item?.title ? item?.title : item?.label,
      value: item?.id ? item?.id : item?.value,
    }));
  }
  return modifiedData;
}
// 获取屏幕宽度
const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
};
export default Index;
