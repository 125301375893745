import React from 'react';

const Index =()=> {
        return (
            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2123 13.1228V19.5222L20.3204 16.3332L14.2123 13.1228Z" fill="#1B1F26" fillOpacity="0.72"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.00725 4.60072C0.111084 6.35955 0.111084 8.66198 0.111084 13.2668V19.8446C0.111084 24.4495 0.111084 26.7519 1.00725 28.5107C1.79554 30.0578 3.05337 31.3156 4.60048 32.1039C6.3593 33.0001 8.66173 33.0001 13.2666 33.0001H19.8443C24.4492 33.0001 26.7516 33.0001 28.5105 32.1039C30.0576 31.3156 31.3154 30.0578 32.1037 28.5107C32.9999 26.7519 32.9999 24.4495 32.9999 19.8446V13.2668C32.9999 8.66198 32.9999 6.35955 32.1037 4.60072C31.3154 3.05362 30.0576 1.79578 28.5105 1.00749C26.7516 0.111328 24.4492 0.111328 19.8443 0.111328H13.2666C8.66173 0.111328 6.3593 0.111328 4.60048 1.00749C3.05337 1.79578 1.79554 3.05362 1.00725 4.60072ZM26.7301 9.7564C27.4094 10.4456 27.6301 12.0151 27.6301 12.0151C27.6301 12.0151 27.8564 13.8595 27.861 15.7013V17.4271C27.861 19.2726 27.6358 21.1144 27.6358 21.1144C27.6358 21.1144 27.4155 22.6856 26.7358 23.3748C25.9584 24.1937 25.0985 24.2775 24.6132 24.3248C24.5611 24.3298 24.5133 24.3345 24.4704 24.3396C21.3087 24.5673 16.5559 24.5757 16.5559 24.5757C16.5559 24.5757 10.6769 24.5233 8.86681 24.3493C8.77949 24.3326 8.67343 24.3196 8.55329 24.3049C7.97886 24.2348 7.08231 24.1252 6.37069 23.3756C5.69555 22.6853 5.47482 21.1158 5.47482 21.1158C5.47482 21.1158 5.24995 19.2702 5.24995 17.4284V15.7026C5.24995 13.8569 5.47496 12.0151 5.47496 12.0151C5.47496 12.0151 5.691 10.4456 6.37083 9.7564C7.14481 8.94066 8.00139 8.85563 8.48772 8.80736C8.54206 8.80196 8.59178 8.79703 8.63624 8.79158C11.7996 8.5596 16.5461 8.5596 16.5461 8.5596H16.5544C16.5544 8.5596 21.303 8.5596 24.4647 8.79158C24.5075 8.79669 24.5552 8.80136 24.6073 8.80645C25.0916 8.8538 25.9528 8.93802 26.7301 9.7564Z" fill="#1B1F26" fillOpacity="0.72"/>
            </svg>
           )}

export default Index;
