import React, { Component } from 'react';

import FrameComponents from "../../FrameComponents"; 

import "./index.css";

class Index extends Component {

  state = {};

  //进入页面调用接口
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-products-detail';
  }

  render() {
    return (
      <div>
        <FrameComponents name="products_detail" /> 
      </div>
    );
  }
}

export default Index;
