import { Box, EmptyState } from '@shopify/polaris';
import React from 'react';

const ErrorIdPage = ()=> {
    return (
        <Box style={{height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <EmptyState
          heading="There's no page at this address"
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
        <p>
            Check the URL and try again, or use the search bar to find what you need.
        </p>
        </EmptyState>
      </Box>
    );
}

export default ErrorIdPage;
