import dashboard from '../frontEnd/nl/dashboard.json'
import findProducts from '../frontEnd/nl/findProducts.json'
import common from '../frontEnd/nl/common.json'
import frameClass from '../frontEnd/nl/frameClass.json'
import sourcing from '../frontEnd/nl/sourcing.json'
import listProduct from '../frontEnd/nl/listProduct.json'
import orders from '../frontEnd/nl/orders.json'
import customService from '../frontEnd/nl/customService.json'
import siteOptimization from '../frontEnd/nl/siteOptimization.json'
import subscribe from '../frontEnd/nl/subscribe.json'
import setting from '../frontEnd/nl/setting.json'
import shoppingCart from '../frontEnd/nl/shoppingCart.json'
import message from '../frontEnd/nl/message.json'
import partnership from '../frontEnd/nl/partnership.json'
const nl =  {
    ...dashboard,
    ...findProducts,
    ...common,
    ...frameClass,
    ...sourcing,
    ...listProduct,
    ...orders,
    ...customService,
    ...siteOptimization,
    ...subscribe,
    ...setting,
    ...shoppingCart,
    ...message,
    ...partnership
};
export default nl;