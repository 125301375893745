import React , { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link ,Icon} from "@shopify/polaris";
import AppBridge from '../../utils/appBridge';
import './index.css';
import utils from '../../utils/utils'
import {
  CartSaleIcon
} from '@shopify/polaris-icons';
class ShoppingCartButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: this.props.cartNumber,
    };
  }

  //进入页面调用接口 (传入组件数据发生变化时 ，接受新数据重新渲染)
  UNSAFE_componentWillReceiveProps (nextProps) {
    if(nextProps){
      this.setState({
        number: window.CART_NUMBER ? window.CART_NUMBER : nextProps.number,
      });
    }
  }

  /**
   * 判断链接跳转
   * 
   * 说明：如果 AppBridge实例存在代表Shopify内嵌模式，否在代表Shopify站外访问
   * 
   * @param {string} uri 路由，没有携带域名
   */
  handleJumpLink = (uri) => {
    if (AppBridge.exist()) {
      // this.props.history.push(uri);
      utils.routeListen(uri)
      utils.routeJump(this,uri)

    } else {
      try {
        // 获取当前域名
        // let localHost = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

        // const w = window.open('about:blank');
        // w.location.href = localHost + uri;
        utils.routeListen(uri)
        utils.routeJump(this,uri)
      } catch (error) {
        utils.routeListen(uri)
        utils.routeJump(this,uri)
      }
    }
  }

  render() {
    const { number } = this.state;

    // 购物车按钮形状：0 圆形 / 1 方形
    // let cart_button_shape = 0;
    // 购物车icon图标
    // let choose_icon = 6;

    let cartbar_position = {right:'26px',bottom: '100px'};
    let button_shape_pixel = '16';

    // let cartbar_icon_color = '#ffffff';
    // let cartbar_color = '#e62d05';
    let cartbar_number_circle_color = 'rgba(245, 34, 45, 1)';
    let cartbar_text_color = 'rgb(255, 255, 255)';

    // var userSelectIcon = '';
    // var selectIconStyle = {display:'inline-block',verticalAlign:'middle',fill:'currentColor'};
    
    // switch (choose_icon) {
    //   case 1:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 37 40"><path fill={`${cartbar_icon_color}`} d="M36.5 34.8L33.3 8h-5.9C26.7 3.9 23 .8 18.5.8S10.3 3.9 9.6 8H3.7L.5 34.8c-.2 1.5.4 2.4.9 3 .5.5 1.4 1.2 3.1 1.2h28c1.3 0 2.4-.4 3.1-1.3.7-.7 1-1.8.9-2.9zm-18-30c2.2 0 4.1 1.4 4.7 3.2h-9.5c.7-1.9 2.6-3.2 4.8-3.2zM4.5 35l2.8-23h2.2v3c0 1.1.9 2 2 2s2-.9 2-2v-3h10v3c0 1.1.9 2 2 2s2-.9 2-2v-3h2.2l2.8 23h-28z"></path></svg>;
    //     break;
    //   case 2:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 1024 1024"><path fill={`${cartbar_icon_color}`} d="M960 923.733333l-64-576c-2.133333-17.066667-14.933333-27.733333-32-27.733333H768c0-140.8-115.2-256-256-256S256 179.2 256 320H160c-17.066667 0-29.866667 12.8-32 27.733333l-64 576c0 8.533333 2.133333 17.066667 8.533333 25.6 6.4 6.4 14.933333 10.666667 23.466667 10.666667h832c8.533333 0 17.066667-4.266667 23.466667-10.666667 6.4-6.4 8.533333-14.933333 8.533333-25.6zM512 128c106.666667 0 192 85.333333 192 192H320c0-106.666667 85.333333-192 192-192zM132.266667 896l57.6-512H256v96c0 17.066667 14.933333 32 32 32s32-14.933333 32-32V384h384v96c0 17.066667 14.933333 32 32 32s32-14.933333 32-32V384h68.266667l57.6 512H132.266667z"></path></svg>;
    //     break;
    //   case 3:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 1024 1024"><path fill={`${cartbar_icon_color}`} d="M734.188679 523.727698a47.62566 47.62566 0 0 1 0-95.212679 47.62566 47.62566 0 0 1 0 95.212679z m-444.377358 0a47.62566 47.62566 0 0 1 0-95.212679 47.62566 47.62566 0 0 1 0 95.212679z m31.744-269.795019c0-105.008302 85.436377-190.444679 190.444679-190.444679s190.444679 85.436377 190.444679 190.444679v126.956679H321.555321v-126.956679z m571.334037 126.956679h-126.956679v-126.956679C765.932679 113.702642 652.230038 0 512 0 371.769962 0 258.067321 113.702642 258.067321 253.932679v126.956679H131.110642L67.622642 1015.711396h888.754716l-63.488-634.822038z"></path></svg>;
    //     break;
    //   case 4:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 1024 1024"><path fill={`${cartbar_icon_color}`} d="M351.330182 828.741921c-35.143405 0-63.614853 28.470425-63.614853 63.612807 0 35.150568 28.470425 63.615876 63.614853 63.615876 35.150568 0 63.620993-28.465309 63.620993-63.615876C414.951175 857.213369 386.514519 828.741921 351.330182 828.741921L351.330182 828.741921 351.330182 828.741921zM796.647457 828.741921c-35.149545 0-63.614853 28.470425-63.614853 63.612807 0 35.150568 28.465309 63.615876 63.614853 63.615876 35.150568 0 63.615876-28.465309 63.615876-63.615876C860.263333 857.213369 831.826678 828.741921 796.647457 828.741921L796.647457 828.741921 796.647457 828.741921zM932.118871 189.273784c-16.253172-18.35709-38.58376-28.312836-62.886259-28.312836L231.894999 160.960948l-2.260483-12.947893c-5.566786-46.279023-46.311769-82.47541-92.750427-82.47541l-40.012296 0c-17.59575 0-31.810496 14.214746-31.810496 31.80845 0 17.588587 14.21577 31.80538 31.810496 31.80538l40.012296 0c13.836123 0 27.961842 12.595876 29.833469 28.086685l30.728862 177.425945 53.946656 379.825678c5.596462 46.279023 45.899376 82.632999 91.736331 82.632999l517.133927 0c17.58961 0 31.810496-14.219863 31.810496-31.807427 0-17.588587-14.219863-31.807427-31.810496-31.807427L343.128383 733.50793c-13.550621 0-26.879184-12.536524-28.66076-27.290553l-5.28026-37.120432 504.664941-31.207769c45.803185 0 86.107123-36.290531 91.514273-80.884169l50.127677-286.624996C959.21602 239.750409 950.694949 210.230072 932.118871 189.273784L932.118871 189.273784zM932.118871 189.273784"></path></svg>;
    //     break;
    //   case 5:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 1024 1024"><path fill={`${cartbar_icon_color}`} d="M384 938.666667a42.666667 42.666667 0 1 1-42.666667-42.666667 42.713333 42.713333 0 0 1 42.666667 42.666667z m341.333333-42.666667a42.666667 42.666667 0 1 0 42.666667 42.666667 42.713333 42.713333 0 0 0-42.666667-42.666667z m244.16-587.506667L825.72 632a85.413333 85.413333 0 0 1-78 50.666667H295.406667l-5.946667 35.653333a42.666667 42.666667 0 0 0 42.086667 49.68H789.333333a21.333333 21.333333 0 0 1 0 42.666667H331.546667a85.333333 85.333333 0 0 1-84.166667-99.333334l6.426667-38.58a42.893333 42.893333 0 0 0-1.333334-19.56L90.913333 128H21.333333a21.333333 21.333333 0 0 1 0-42.666667h85.333334a21.333333 21.333333 0 0 1 20.386666 15.06L174.933333 256h760.446667a37.333333 37.333333 0 0 1 34.113333 52.493333zM927.173333 298.666667H188.066667l105.02 341.333333h454.666666a42.713333 42.713333 0 0 0 38.993334-25.333333z"></path></svg>;
    //     break;
    //   case 6:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 1024 1024" ><path fill={`${cartbar_icon_color}`} d="M725.333333 768C677.973333 768 640 805.973333 640 853.333333 640 900.266667 678.4 938.666667 725.333333 938.666667 772.266667 938.666667 810.666667 900.266667 810.666667 853.333333 810.666667 805.973333 772.266667 768 725.333333 768M42.666667 85.333333 42.666667 170.666667 128 170.666667 281.6 494.506667 223.573333 599.04C217.173333 610.986667 213.333333 625.066667 213.333333 640 213.333333 686.933333 251.733333 725.333333 298.666667 725.333333L810.666667 725.333333 810.666667 640 316.586667 640C310.613333 640 305.92 635.306667 305.92 629.333333 305.92 627.2 306.346667 625.493333 307.2 624.213333L345.6 554.666667 663.466667 554.666667C695.466667 554.666667 723.626667 536.746667 738.133333 510.72L890.88 234.666667C893.866667 227.84 896 220.586667 896 213.333333 896 189.866667 876.8 170.666667 853.333333 170.666667L222.293333 170.666667 182.186667 85.333333M298.666667 768C251.306667 768 213.333333 805.973333 213.333333 853.333333 213.333333 900.266667 251.733333 938.666667 298.666667 938.666667 345.6 938.666667 384 900.266667 384 853.333333 384 805.973333 345.6 768 298.666667 768Z" /></svg>;
    //     break;
    //   case 7:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 1024 1024"><path fill={`${cartbar_icon_color}`} d="M397.792 877.728q0 29.728-21.728 51.424t-51.424 21.728-51.424-21.728-21.728-51.424 21.728-51.424 51.424-21.728 51.424 21.728 21.728 51.424zM909.792 877.728q0 29.728-21.728 51.424t-51.424 21.728-51.424-21.728-21.728-51.424 21.728-51.424 51.424-21.728 51.424 21.728 21.728 51.424zM982.912 256l0 292.576q0 13.728-9.44 24.288t-23.136 12.288l-596.576 69.728q7.424 34.272 7.424 40 0 9.152-13.728 36.576l525.728 0q14.848 0 25.728 10.848t10.848 25.728-10.848 25.728-25.728 10.848l-585.152 0q-14.848 0-25.728-10.848t-10.848-25.728q0-6.272 4.576-18.016t9.152-20.576 12.288-22.848 8.864-16.864l-101.152-470.272-116.576 0q-14.848 0-25.728-10.848t-10.848-25.728 10.848-25.728 25.728-10.848l146.272 0q9.152 0 16.288 3.712t11.136 8.864 7.424 14.016 4.576 14.848 3.136 16.864 2.56 14.848l686.272 0q14.848 0 25.728 10.848t10.848 25.728z"></path></svg>;
    //     break;
    //   case 8:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 1024 1024"><path fill={`${cartbar_icon_color}`} d="M230.4 561.152h505.856c26.624 0 51.2-15.36 62.464-37.888l126.976-253.952c4.096-9.216 7.168-20.48 7.168-30.72 0-37.888-31.744-69.632-69.632-69.632H270.336l-7.168-98.304c-1.024-10.24-9.216-19.456-20.48-19.456H112.64c-11.264 0-20.48 9.216-20.48 20.48s9.216 20.48 20.48 20.48h110.592l25.6 350.208c1.024 10.24-6.144 33.792-12.288 41.984L174.08 568.32c-15.36 21.504-17.408 49.152-6.144 72.704 12.288 22.528 34.816 36.864 61.44 36.864h585.728c11.264 0 20.48-9.216 20.48-20.48s-9.216-20.48-20.48-20.48H229.376c-10.24 0-20.48-5.12-24.576-15.36-5.12-10.24-4.096-21.504 2.048-29.696l23.552-30.72z m632.832-351.232c15.36 0 28.672 13.312 28.672 28.672 0 4.096-1.024 9.216-3.072 12.288L761.856 504.832c-5.12 9.216-14.336 15.36-25.6 15.36H261.12l8.192-11.264c12.288-16.384 21.504-48.128 20.48-68.608l-16.384-230.4h589.824zM649.216 735.232c-64.512 0-117.76 53.248-117.76 118.784S584.704 972.8 649.216 972.8s117.76-53.248 117.76-118.784-53.248-118.784-117.76-118.784z m0 196.608c-41.984 0-76.8-34.816-76.8-77.824s34.816-77.824 76.8-77.824 76.8 34.816 76.8 77.824-34.816 77.824-76.8 77.824zM259.072 735.232c-64.512 0-117.76 53.248-117.76 118.784S194.56 972.8 259.072 972.8s117.76-53.248 117.76-118.784-53.248-118.784-117.76-118.784z m0 196.608c-41.984 0-76.8-34.816-76.8-77.824s34.816-77.824 76.8-77.824 76.8 34.816 76.8 77.824-34.816 77.824-76.8 77.824z"></path></svg>;
    //     break;
    //   case 9:
    //     userSelectIcon = <svg aria-hidden="true" focusable="false" role="presentation" style={{...selectIconStyle}} viewBox="0 0 1024 1024"><path fill={`${cartbar_icon_color}`} d="M949 202.5c-8.9-10.2-21.7-16.1-35.2-16.1H270.5l-9.1-54.2c-3.7-22.4-23.1-38.9-45.9-38.9H110.8c-25.8 0-46.6 20.8-46.6 46.6s20.8 46.6 46.6 46.6h65.3l86.6 519.7 2.1 5.8 2.5 7 5.6 8.3 4.4 5.2 9 6 5.2 3c5.4 2.2 11.1 3.5 17.1 3.5h512.1c25.7 0 46.6-20.8 46.6-46.6 0-25.7-20.8-46.6-46.6-46.6H348l-7.7-46.6h526.9c23.2 0 42.8-17 46.1-39.9l46.6-325.9c1.9-13.2-2.1-26.8-10.9-36.9z m-88.9 77l-13.3 93.1H681v-93.1h179.1z m-225.6 0v93.1H494.8v-93.1h139.7z m0 139.7v93.1H494.8v-93.1h139.7zM448.3 279.5v93.1H308.6l-6.9 1.4-15.7-94.5h162.3z m-139 139.7h139v93.1H324.8l-15.5-93.1zM681 512.3v-93.1h159.1l-13.3 93.1H681zM378.4 791.6c38.6 0 69.8 31.3 69.8 69.8 0 38.6-31.3 69.8-69.8 69.8-38.6 0-69.8-31.3-69.8-69.8s31.3-69.8 69.8-69.8z m419 0c38.6 0 69.8 31.3 69.8 69.8 0 38.6-31.3 69.8-69.8 69.8-38.6 0-69.8-31.3-69.8-69.8s31.3-69.8 69.8-69.8z"></path></svg>;
    //     break;
    //   default: 
    //     userSelectIcon = '';
    // }

    // 当购物车数量超过99时，显示99+
    let hasMoreClass = (number > 99) ? ' more' : '';

    // 圆形按钮
    const RoundButton = (
      <Link onClick={() => this.handleJumpLink('/admin/shopping_cart')}>
        <div className='cartbar_position' style={{...{position:'fixed',zIndex:'2147483644'},...cartbar_position}}>
          <div className="hoverButton_Round hoverButton_Round_cart hoverButton_Rounds" style={{backgroundColor:`rgba(255, 255, 255, 1)`,padding:`${button_shape_pixel}px`}}>
            {/* {userSelectIcon} */}
            <Icon
            source={CartSaleIcon}
            tone="base"
          />
            <div className="total_Quantity_Circle_Round" style={{display:(number > 0) ? 'block' : 'none'}}>
              <div className={"total_Quantity_Round" + hasMoreClass} style={{backgroundColor:`${cartbar_number_circle_color}`}}>
                <span style={{color:`${cartbar_text_color}` }}>{(number > 99) ? '99+' : number}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )

    // 方形按钮
    // const SquareButton = (
    //   <Link onClick={() => this.handleJumpLink('/admin/shopping_cart')}>
    //     <div className='cartbar_position' style={{...{position:'fixed',zIndex:'2147483644'},...cartbar_position}}>
    //       <div className="hoverButton_Square" style={{backgroundColor:`${cartbar_color}`,padding:`${button_shape_pixel}px`}}>
    //         {userSelectIcon}
    //         <div className="total_Quantity_Circle_Square" style={{display:(number > 0) ? 'block' : 'none'}}>
    //           <div className={"total_Quantity_Square" + hasMoreClass} style={{backgroundColor:`${cartbar_number_circle_color}`}}>
    //             <span style={{color:`${cartbar_text_color}`}}>{(number > 99) ? '99+' : number}</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </Link>
    // )

    const hoverButton =  RoundButton;

    return (
      <div>
        {/* 悬浮购物车按钮 */}
        {hoverButton}
      </div>
    )
  }
}

export default withRouter(ShoppingCartButton);

