import React, { Component } from 'react';

import {
  TextField,  Select
} from "@shopify/polaris";

// import {
//   MobilePlusMajor,XSmallIcon
// } from '@shopify/polaris-icons';

import intl from '../../../../../i18n/intl'

class CostRangeRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordKey: props.recordKey,
      
      Start_Range_Value: props.Start_Range_Value,
      End_Range_Value: props.End_Range_Value,

      Factor_Value: props.Factor_Value,
      Factor_Mark: props.Factor_Mark,

      isComparedPriceMarkup: props.isComparedPriceMarkup,
      ComparedPrice_Factor_Value: props.ComparedPrice_Factor_Value,
      ComparedPrice_Factor_Mark: props.ComparedPrice_Factor_Mark,

      isEndRow: props.isEndRow,   // 最后一行记录没有删除 按钮
      diabledFiled: props.isEndRow?true:false,
    };
  }

  componentDidMount() {
    
  }

  // 组件渲染完成，再次修改渲染内容
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      recordKey: nextProps.recordKey,
      
      Start_Range_Value: nextProps.Start_Range_Value,
      End_Range_Value: nextProps.End_Range_Value,

      Factor_Value: nextProps.Factor_Value,
      Factor_Mark: nextProps.Factor_Mark,

      isComparedPriceMarkup: nextProps.isComparedPriceMarkup,
      ComparedPrice_Factor_Value: nextProps.ComparedPrice_Factor_Value,
      ComparedPrice_Factor_Mark: nextProps.ComparedPrice_Factor_Mark,

      isEndRow: nextProps.isEndRow,   // 最后一行记录没有删除 按钮
      diabledFiled: nextProps.isEndRow?true:false,
    });
  }

  // select change
  handleSelectChange = (value,id)=> {
    this.setState({
      [id]: value
    },()=>{
      this.CostRangeDataChangeHandle();
    });
  }

  // input change
  handleInputChange = (value,id)=> {
    if(id==='Factor_Value' || id==='ComparedPrice_Factor_Value'){
      if(Number(value) < 0 || Number(value) === 0){
        value = 1;
      }
    }
    this.setState({
      [id]: value,
    },()=>{
      if(id === "End_Range_Value"){
        if(this.state.diabledFiled){
          if(value > this.state.Start_Range_Value){  // 范围值判断
            this.setState({diabledFiled: false});
            this.props.handleCostRangeError('',false);

          }else{  // 提示范围值 后值小于前值 不合理
            this.props.handleCostRangeError(intl.get("Cost range end value must be greater than the starting value."),true);
          }
          
        }
      }
      this.CostRangeDataChangeHandle();
    });
  }

  // 内容修改后，传递给父组件更新数据
  CostRangeDataChangeHandle = () =>{
    let data = {
      recordKey: this.state.recordKey,
      Start_Range_Value: this.state.Start_Range_Value,
      End_Range_Value: this.state.End_Range_Value,
      Factor_Value: this.state.Factor_Value,
      Factor_Mark: this.state.Factor_Mark,
      ComparedPrice_Factor_Value: this.state.ComparedPrice_Factor_Value,
      ComparedPrice_Factor_Mark: this.state.ComparedPrice_Factor_Mark,
      isEndRow: this.state.isEndRow,
    }
    this.props.changeCostRangeData(data);
  }

  // 删除行记录
  deleteRow = () =>{
    this.props.deleteTableTrRow(this.state.recordKey);
  }


  render() {
    const { 
      Start_Range_Value, End_Range_Value, Factor_Value, Factor_Mark,
      isComparedPriceMarkup, ComparedPrice_Factor_Value, ComparedPrice_Factor_Mark,
      isEndRow, diabledFiled
    } = this.state;


    return (
      
        <tr>
          <td>
            <div className="PriceRule_Cost_Range_Item">
              <TextField 
                id="Start_Range_Value"
                type="number"
                label={intl.get("Start Range")}
                labelHidden 
                value={String(Start_Range_Value)}
                min={0}
                step={0.01}
                onChange={this.handleInputChange} 
                // prefix="$"
              />
              <div className="Cost_Range_Connector">-</div>
              <TextField 
                id="End_Range_Value"
                type="number"
                label={intl.get("End_Range")}
                labelHidden 
                value={String(End_Range_Value)}
                min={0}
                step={0.01}
                onChange={this.handleInputChange} 
                // prefix="$"
              />
            </div>
          </td>
            
          <td>
            <div className="PriceRule_Cost_Range_Item Mark_Number">
              {/* <div className="showMark" style={{padding:'7px 4px 7px 0'}}>
                <Icon source={(Factor_Mark==="1")?XSmallIcon:MobilePlusMajor} />
              </div> */}
              <TextField 
                id="Factor_Value"
                type="number"
                label={intl.get("Price Factor")}
                labelHidden
                onChange={this.handleInputChange}
                disabled={diabledFiled}
                value={String(Factor_Value)}
                step={0.01}
                connectedLeft={
                  <Select
                    id="Factor_Mark"
                    label={intl.get("Price Factor Mark")}
                    labelHidden
                    options={[{label:'✖',value:'1'},{label:'✚',value:'2'}]}
                    onChange={this.handleSelectChange}
                    disabled={diabledFiled}
                    value={Factor_Mark}
                  />
                }
              />
            </div>
            
          </td>
          <td>
            <div className="PriceRule_Cost_Range_Item Mark_Number" style={{opacity:isComparedPriceMarkup?'1':'0'}}>
              <TextField 
                  id="ComparedPrice_Factor_Value"
                  type="number"
                  label={intl.get("Compared Price Factor")}
                  labelHidden
                  onChange={this.handleInputChange}
                  value={String(ComparedPrice_Factor_Value)}
                  disabled={diabledFiled}
                  step={0.01}
                  connectedLeft={
                    <Select
                      id="ComparedPrice_Factor_Mark"
                      label={intl.get("Compared Price Factor Mark")}
                      labelHidden
                      options={[{label:'✖',value:'1'},{label:'✚',value:'2'}]}
                      onChange={this.handleSelectChange}
                      value={ComparedPrice_Factor_Mark}
                      disabled={diabledFiled}
                    />
                  }
                />
              </div>
          
          </td>
          <td >
            <div 
              className="PriceRule_Delete" 
              style={{display:isEndRow?'none':'block',cursor:isEndRow?'default':'pointer'}} 
              onClick={ this.deleteRow }
            >
              X
            </div>
          </td>
        </tr>
    );
    
  }
}

export default CostRangeRows;
