import React, { memo } from "react";
import { Modal } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";

const ArchiveOrderModal = memo(
  ({ archivedData, handleBulkOperationModal, handleArchiveConfigRequest }) => {
    return (
      <div>
        <Modal
          open={archivedData.ArchivedTipsActive}
          onClose={() => handleBulkOperationModal(false, false)}
          title={"Bulk order sourcing"}
          primaryAction={{
            content: intl.get("Apply"),
            onAction: handleArchiveConfigRequest,
            loading: archivedData.archiveConfigloading,
          }}
          secondaryActions={[
            {
              content: intl.get("Cancel"),
              onAction: () => handleBulkOperationModal(false, false),
            },
          ]}
        >
          <Modal.Section>
            {intl.get(`Are you sure you want to source the selected ${archivedData.data?.filter((item)=>{return item.checked === 1}).length} ${archivedData.data?.filter((item)=>{return item.checked === 1}).length > 1 ? 'orders' : 'order'}? Once confirmed, we'll start sourcing for the pending sourcing orders.`)}
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default ArchiveOrderModal;
