import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Page,
  Card,
  Layout,
  SkeletonBodyText,
  Button,
  Toast,
  SkeletonPage,
  Select,
  Thumbnail,
  Modal,
  RadioButton,
  FormLayout,
  TextField,
  BlockStack,
  Badge,
  ButtonGroup,
  Spinner,
  Text,
  InlineStack,
  Box,
} from "@shopify/polaris";

import ShopifyCountriesConfig from '../../../assets/ShopifyCountries';

import {
  reqPostRedeemCreateOrder, reqGetUserAddress, reqGetShopifyProvinceData, reqPostUpdateUserAddress, reqPostCartShoppigData,reqGetBlackFridayRaffleProduct
} from '../../../api';
import utils from '../../../utils/utils'
import './index.css';
import intl from '../../../i18n/intl';
import { store } from '../../../store';
import { changeBannerData } from '../../../slice';

class RedeemedOrder extends Component {
  state = {
    //页面动作
    loadingPage: false,
    isSearching: false,
    loadingProvinceData: false,

    // 购物车预下单sku数据
    orderData: [],

    // 用户地址部分
    addressLoading: false,
    userAddresses: [],
    selectedAddress: '',
    selectedAddressKey: -1,
    addressListActive: false,
    editAddressModalActive: false,
    provinceLabelText: 'Province',
    provinceData: [],

    editRecordId: 0,  // 地址列表记录ID
    firstName: '',
    lastName: '',
    lastNameError: '',
    company: '',
    address1: '',
    address1Error: '',
    address2: '',
    country: '',
    countryError: '',
    countryCode: '',
    province: '',
    provinceCode: '',
    city: '',
    cityError: '',
    zipCode: '',
    zipCodeError: '',
    phone: '',
    phoneError: '',
    email: '',
    emailError: '',
    option1: '',
    option1Error: '',
    saveAddressLoading: false,

    // 订单总额(细项)
    subtotal: 0,
    iossVAT: 0,

    // 订单备注
    orderNote: '',
    createOrderLoading: false,

    // 订单运输方式
    shippingMethodsData: [], // 包含sku的长宽高信息，用于获取运输方式（订单运输记录表信息，根据仓库划分）
    selectShippingMethod: [],
    shippingMethodLoading: false,
    selectShippingMethodModalActive: false,
    selectShippingMethodModalContent: '',  // 选择物流方式弹窗内容

    dropshipmanOrderId: 0,  // 生成的DSM订单ID
    errorShopping:true,
  };

  //进入页面调用接口
  componentDidMount() {
    this.initPageData();
  }

  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  initPageData = async () => {
    let url = new URLSearchParams(window.location.search);
    let record_id = url.get("record_id")
    let params = {
      lottery_id:record_id
    }
    try {
      let res = await reqGetBlackFridayRaffleProduct(params);
      
      if (res.code === '200') {
        let preOrderData = [{...res.data.variants[0],quantity:1}];
      let skuTotalPrice = 0;
      if (preOrderData) {
        for (let i = 0; i < preOrderData.length; i++) {
          skuTotalPrice += (preOrderData[i].price * Number(preOrderData[i].quantity));
        }
      }

      this.setState({
        orderData: preOrderData,
        subtotal: skuTotalPrice,
      });

      // 如果是自定义订单，需要先获取用户的地址回来 0 自定义订单（4） 1 批发产品（3） 2 礼品包装（2）
        this.getUserManageAddress(true);
      }
      

    } catch (error) {
      console.log(error);
    }
  }

  // 获取用户地址列表
  getUserManageAddress = async () => {
    try {

      this.setState({ addressLoading: true });
      const response = await reqGetUserAddress({});
      let defaultCountry = 'US';

      if (response.code === '200') {
        let addressData = response.data.list;
        let userSelectAddress = '';
        let selectKey = -1;

        if (addressData.length > 0) {
          for (let i = 0; i < addressData.length; i++) {
            if (addressData[i].is_default === 1) {
              userSelectAddress = addressData[i];
              selectKey = i;
              break;
            }
          }
          if (!userSelectAddress) {
            userSelectAddress = addressData[0];
            selectKey = 0;
          }
        }
        defaultCountry = response.data.country_code;

        this.setState({
          userAddresses: addressData,
          selectedAddress: userSelectAddress,
          selectedAddressKey: selectKey,
          addressLoading: false
        }, () => {
          // 根据用户地址，获取运输方式
          if (addressData.length > 0) {
            setTimeout(() => {
              this.getShippingMethods();
            }, 1500);
          }
        });
      } else {
        this.setState({ addressLoading: false });
        this.toggleToast(response.msg);
      }

      // 初始化国家信息
      this.initShopifyCountriesData(defaultCountry);

    } catch (error) {
      console.log(error);
    }
  }

  // 初始化国家，身份数据
  initShopifyCountriesData = (shopifyCountryCode) => {
    let countryName = '';
    let provinceKeyText = '';
    for (let i = 0; i < ShopifyCountriesConfig.length; i++) {
      if (ShopifyCountriesConfig[i].value === shopifyCountryCode) {
        countryName = ShopifyCountriesConfig[i].label;
        provinceKeyText = ShopifyCountriesConfig[i].province_label;
        break;
      }
    }
    this.setState({
      countryCode: shopifyCountryCode,
      country: countryName,
      provinceLabelText: provinceKeyText,
    }, () => {
      // 获取是否有省份数据
      this.getShopifyProvinceData(shopifyCountryCode);
    });
  }

  // 获取shopify省份数据
  getShopifyProvinceData = async (code = '') => {
    try {

      const params = {
        c: code,
      }
      this.setState({ loadingProvinceData: true });
      const response = await reqGetShopifyProvinceData(params)
      if (response.code === '200') {
        let getData = response.data;

        // 修改地址时，初始化省份
        let init_province = this.state.province ? this.state.province : '';
        let init_provinceCode = this.state.provinceCode ? this.state.provinceCode : '';

        if (getData.length > 0) {
          if (init_province === '') {
            init_province = getData[0].label;
          }

          if (init_provinceCode === '') {
            init_provinceCode = getData[0].value;
          }
        } else {
          init_province = '';
          init_provinceCode = '';
        }

        this.setState({
          loadingProvinceData: false,
          provinceData: getData,
          province: init_province,
          provinceCode: init_provinceCode,
        });
      } else {
        this.toggleToast(response.msg);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 获取运输方式列表
  getShippingMethods = async () => {
    try {
      let cartList = this.state.orderData.map((item)=>{
        return {...item,id:0}
      });
      const params = {
        cart: JSON.stringify(cartList),
        address: JSON.stringify(this.state.selectedAddress),
      }

      this.setState({ shippingMethodLoading: true });
      const response = await reqPostCartShoppigData(params);
      if (response.code === '200') {
        let getData = response.data;

        // 根据仓库 区分
        let shippingMethods = response.data.shipping_methods;
        let selectShipping = [];

          let singlePageShippingRes = shippingMethods.shipping_result;
          for (let j = 0; j < singlePageShippingRes.length; j++) {
            if (singlePageShippingRes[j].selected === 1) {  // 选中的物流方式
              selectShipping.push(singlePageShippingRes[j]);
              break;
            }
          }

        this.setState({
          shippingMethodLoading: false,
          shippingMethodsData: shippingMethods,
          selectShippingMethod: selectShipping,
          iossVAT: getData.vat,
          errorShopping:selectShipping.length > 0 ? true : false,
        });

      } else {
        this.setState({ shippingMethodLoading: false,errorShopping:false });
        this.toggleToast(response.msg);
      }

    } catch (error) {
      console.log(error);
    }
  }

  // 选择运输方式弹窗
  handleSelectShippingModal = (recordKey, bool) => {
    let shippingMethodTable = '';
    if (bool) {
      this.updateShippingMethodTable(recordKey, false);
      return
    }
    this.setState({
      selectShippingMethodModalActive: bool,
      selectShippingMethodModalContent: shippingMethodTable,
    });
  }

  // 更换运输方式
  handleShippingMethodChange = (recordKey, key1) => {
    let shippingMethods = this.state.shippingMethodsData;
    let selectShippingMethodArr = this.state.selectShippingMethod;

    // 赋值
    let selectRes = shippingMethods.shipping_result;
    selectShippingMethodArr[recordKey] = shippingMethods.shipping_result[key1];

    for (let i = 0; i < selectRes.length; i++) {
      if (i === key1) {
        shippingMethods.shipping_result[i].selected = 1;
      } else {
        shippingMethods.shipping_result[i].selected = 0;
      }
    }

    this.setState({
      shippingMethodsData: shippingMethods,
      selectShippingMethod: selectShippingMethodArr,
      errorShopping:selectShippingMethodArr.length > 0 ? true : false,
    }, () => {
      this.updateShippingMethodTable(recordKey, true);
    });
  }

  // 更新运输方式表格选中状态
  updateShippingMethodTable = (recordKey, isUpdate = false) => {
    let data = this.state.shippingMethodsData;
    let _shippingTableData = data.shipping_result;

    let shippingMethodTable = '';
    let shippingTrTow = '';

    if (_shippingTableData.length > 0) {
      shippingTrTow = _shippingTableData.map((item, key) => {
        let shippingTrHtml = (
          <tr key={key}>
            <td>
              <RadioButton
                label={item.ShippingMethod}
                checked={item.selected}
                name="shipping_method"
                onChange={() => this.handleShippingMethodChange(recordKey, key)}
              />
            </td>
            <td>{item.DeliveryTime}</td>
            <td>US${item.BeforeConvertCost} {item.currency_code}</td>
          </tr>
        )

        return (
          shippingTrHtml
        );
      });

      shippingMethodTable = (
        <table style={{ width: '100%', marginTop: '1rem' }}>
          <thead>
            <tr style={{ textAlign: 'left' }}>
              <th className="table_th">{intl.get("Shipping Method")}</th>
              <th className="table_th">{intl.get("Estimated Delivery Time")}</th>
              <th className="table_th">{intl.get("Shipping Cost")}</th>
            </tr>
          </thead>

          <tbody>
            {shippingTrTow}
          </tbody>
        </table>
      )
    } else {
      shippingMethodTable = <div className="shipping_none">{intl.get("This Supplier/Shipping Company does not deliver to your selected Country/Region")}</div>
    }

    let param = {
      selectShippingMethodModalContent: shippingMethodTable,
    }
    if (!isUpdate) {
      param.selectShippingMethodModalActive = true;
    }
    this.setState(param);
  }


  // 订单描述
  handleOrderNoteChange = (value, field) => {
    this.setState({
      [field]: value
    })
  }

  // 返回购物车列表页面
  handleReturnBackShoppingCart = () => {
    // 注销当前组件变量

    // this.setState({});

    // 已经成功下单
    if (this.state.dropshipmanOrderId !== 0) {
      utils.routeListen('/admin/shopping_cart')
      utils.routeJump(this,'/admin/shopping_cart')
      return false
    }

   
  }

  // 通过购物车下单
  handleCreateOrderByCart = async () => {
    try {

      // 订单类型
      
      // 自定义订单
        // 检测是否选择运费
        let shipping_methods = this.state.shippingMethodsData.shipping_result;
        let select_shipping = this.state.selectShippingMethod;
        if (shipping_methods.length === 0) {
          // this.toggleToast('The shipping method cannot be empty', true);
          store.dispatch(changeBannerData({bannerData:{
            show:true,
            desc:intl.get(`The shipping method cannot be empty`)
          }}));
          return false;
        }
        if (select_shipping.length === 0) {
          store.dispatch(changeBannerData({bannerData:{
            show:true,
            desc:intl.get(`Please select the shipping method`)
          }}));
          // this.toggleToast('Please select the shipping method', true);
          return false;
        }

        // 检测地址是否为空
        if (!this.state.selectedAddress) {
          store.dispatch(changeBannerData({bannerData:{
            show:true,
            desc:intl.get(`The delivery address cannot be empty`)
          }}));
          // this.toggleToast('The delivery address cannot be empty', true);
          return false;
        }
        let cartList = this.state.orderData.map((item)=>{
          return {...item,id:0}
        });
      const params = {
        type: 5,
        cart: cartList,
        note: this.state.orderNote,
        address: JSON.stringify(this.state.selectedAddress),
        shipping: JSON.stringify(this.state.shippingMethodsData),
      };
      this.setState({ createOrderLoading: true });

      const response = await reqPostRedeemCreateOrder(params);

      if (response.code === '200') {
        this.toggleToast('Added successfully', false);
        utils.routeListen('/admin/redeem_products?selected=1')
        utils.routeJump(this,'/admin/redeem_products?selected=1')
        // utils.routeJump(this,'/admin/order_list',{
        //   num: 1,
        //   type: ['Wait to pay'],
        // })
        //  跳转到订单页面
        return;

      } else {
        this.toggleToast(response.msg);
      }

      this.setState({ createOrderLoading: false });

    } catch (error) {
      console.log(error);
    }
  }


  // 操作地址提示弹窗
  handleOperatePrompt = (recordId, bool, type) => {
    this.setState({
      editRecordId: recordId,
      operateAddressModalActive: bool,
      operateType: type
    })
  }

  // 用户地址列表弹窗开关
  handleUserAddressChange = (bool) => {
    this.setState({
      addressListActive: bool
    })
  }

  // 地址重新选择
  handleSelectNewAddress = (recordKey) => {
    let data = this.state.userAddresses;
    this.setState({
      selectedAddress: data[recordKey],
      selectedAddressKey: recordKey,
    }, () => {
      // 关闭弹窗
      this.handleUserAddressChange(false);
      // 重新更新物流运输方式
      this.getShippingMethods();
    })
  }

  // 新增、修改地址信息
  handleEditAddress = (recordKey = 0, recordId, bool) => {
    if (recordId === 0) { // 新增
      this.setState({
        editAddressModalActive: bool
      });

    } else {
      let data = this.state.userAddresses;
      this.setState({
        editAddressModalActive: bool,
        editRecordId: bool ? data[recordKey].id : 0,
        firstName: bool ? data[recordKey].first_name : '',
        lastName: bool ? data[recordKey].last_name : '',
        lastNameError: '',
        company: bool ? data[recordKey].company : '',
        address1: bool ? data[recordKey].address1 : '',
        address1Error: '',
        address2: bool ? data[recordKey].address2 : '',
        country: bool ? data[recordKey].country : '',
        countryError: '',
        countryCode: bool ? data[recordKey].country_code : '',
        province: bool ? data[recordKey].province : '',
        provinceCode: bool ? data[recordKey].province_code : '',
        city: bool ? data[recordKey].city : '',
        cityError: '',
        zipCode: bool ? data[recordKey].zip : '',
        zipCodeError: '',
        phone: bool ? data[recordKey].phone : '',
        email: bool ? data[recordKey].email : '',
        option1: bool ? data[recordKey].option1 : '',
        phoneError: '',
        emailError: '',
        option1Error: ''
      }, () => {
        if (bool) {
          // 关闭用户地址列表
          this.handleUserAddressChange(false);
          // 获取是否有省份数据
          this.getShopifyProvinceData(data[recordKey].country_code);
        }
      });
    }
  }

  // 校验收货地址信息
  verifyAddress(address) {
    if (address) {
      let errorObj = {};
      if (!address.last_name) {
        errorObj.lastNameError = intl.get('Last name is required');
      }
      if (!address.address1) {
        errorObj.address1Error = intl.get('Address is required');
      }
      if (!address.country) {
        errorObj.countryError = intl.get('Please select a country');
      }
      if (!address.zip) {
        errorObj.zipCodeError = intl.get('Postal code is required');
      }
      if (!address.city) {
        errorObj.cityError = intl.get('City is required');
      }
      if (address.email) {
        let reg = /^[.a-zA-Z0-9_-]+@[.a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!address.email.match(reg)) {
          errorObj.emailError = intl.get('Wrong email number format');
        }
      }
      // if (!address.phone) {
      //   errorObj.phoneError = 'Phone number is required';
      // }
      // if (!address.email) {
      //   errorObj.emailError = 'Email is required';
      // }
      // 巴西税号
      if (address.country_code === 'BR' && !address.option1) {
        errorObj.option1Error = intl.get('CPF required');
      }
      // 挪威税号
      if (address.country_code === 'NO' && !address.option1) {
        errorObj.option1Error = intl.get('VOEC required');
      }

      let isTrue = true;

      if (Object.keys(errorObj).length !== 0) {
        isTrue = false;
        this.setState(errorObj);
      }

      return isTrue;

    } else {
      this.setState({
        lastNameError: intl.get('Last name is required'),
        address1Error: intl.get('Address is required'),
        countryError: intl.get('Please select a country'),
        zipCodeError: intl.get('Postal code is required'),
        cityError: intl.get('City is required'),
        // emailError: 'Email is required',
        // phoneError: 'Phone number is required'
      });

      return false;
    }
  }

  // 编辑用户地址信息
  handleAddressInputChange = (value, field) => {
    if (field === "countryCode") {
      let countryName = '';
      let provinceKeyText = '';
      for (let i = 0; i < ShopifyCountriesConfig.length; i++) {
        if (ShopifyCountriesConfig[i].value === value) {
          countryName = ShopifyCountriesConfig[i].label;
          provinceKeyText = ShopifyCountriesConfig[i].province_label;
          break;
        }
      }
      if (value !== this.state.countryCode) {
        // 当前国家与之前不一样，获取省份数据
        this.getShopifyProvinceData(value);
      }

      this.setState({
        [field]: value,
        country: countryName,
        provinceLabelText: provinceKeyText,
        province: '',
        provinceCode: '',
        countryError: '',
        city: '',
        cityError: ''
      });

    } else if (field === "provinceCode") {
      let array = this.state.provinceData;
      let provinceText = '';
      for (let j = 0; j < array.length; j++) {
        if (array[j].value === value) {
          provinceText = array[j].label;
          break;
        }
      }
      this.setState({
        [field]: value,
        province: provinceText
      })

    } else if (field === 'lastName') {
      this.setState({
        [field]: value,
        lastNameError: ''
      })

    } else if (field === 'address1') {
      this.setState({
        [field]: value,
        address1Error: ''
      })

    } else if (field === 'city') {
      this.setState({
        [field]: value,
        cityError: ''
      })

    } else if (field === 'zipCode') {
      this.setState({
        [field]: value,
        zipCodeError: ''
      })

    } else if (field === 'email') {
      this.setState({
        [field]: value,
        emailError: '',
      })

    } else if (field === 'phone') {
      this.setState({
        [field]: value,
        phoneError: '',
      })

    } else if (field === 'option1') {
      let err_msg = '';
      // 挪威的税号检测
      if (this.state.countryCode === 'NO') {
        if (value !== '') {
          // 只支持输入以2或3开头的7位纯数字
          let regex = /^([23]\d{6})$/;
          if (!regex.test(value)) {
            err_msg = intl.get('Only supports input of 7-digit plain numbers starting with 2 or 3');
          }
        } else {
          err_msg = intl.get('VOEC is required for orders destined to Norway');
        }
      }
      this.setState({
        [field]: value,
        option1Error: err_msg,
      })

    } else {
      this.setState({
        [field]: value
      })
    }
  }

  // 添加/编辑地址 保存
  saveAddressData = async (type) => {
    try {

      if (type === '') {
        return false;
      }

      const {
        firstName, lastName, address1, address2, phone, city, province, provinceCode, country, countryCode, company, zipCode, email, option1
      } = this.state;

      let params = {
        type: type
      };

      if (type === 'add' || type === 'update') {
        let isAllow = true;
        let shippingAddress = {
          first_name: firstName,
          last_name: lastName,
          address1: address1,
          address2: address2,
          phone: phone,
          city: city,
          province: province,
          province_code: provinceCode,
          country: country,
          country_code: countryCode,
          company: company,
          zip: zipCode,
          email: email,
          option1: option1,
        };

        // 检验地址
        isAllow = this.verifyAddress(shippingAddress);

        if (!isAllow) {
          return false;
        }

        params.id = this.state.editRecordId;
        params.address = shippingAddress;

      } else {
        params.id = this.state.editRecordId;
      }

      this.setState({ saveAddressLoading: true });
      const responseData = await reqPostUpdateUserAddress(params);

      this.setState({
        saveAddressLoading: false,
        editRecordId: 0
      });

      if (responseData.code === "200") {
        if (type === 'del') {
          this.toggleToast('Deleted successfully');
        }else if (type === 'add') {
          this.toggleToast('Add successfully');
        }else if (type === 'set' ) {
          this.toggleToast('Set up successfully');
        }else if (type === 'update' ) {
          this.toggleToast('Saved successfully');
        }else {
          this.toggleToast('Saved successfully');
        }
        // 关闭弹窗
        if (type === 'add' || type === 'update') {
          this.handleEditAddress(0, 1, false);
        }

        if (type === 'del' || type === 'set') {
          this.handleOperatePrompt(0, false, '');
        }
        // 重新获取列表
        this.getUserManageAddress();

      } else if (responseData.code === '505') {  // 反馈地址检测错误
        let getData = responseData.data;
        this.setState({
          saveDataLoading: false,
          lastNameError: getData?.last_name,
          address1Error: getData?.address1,
          countryError: getData?.country,
          cityError: getData?.city,
          phoneError: getData?.phone,
          zipCodeError: getData?.zip,
          emailError: getData?.email,
          option1Error: getData?.option1
        });
        // this.toggleToast(responseData.msg);

      } else {
        // this.toggleToast(responseData.msg);
      }

    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    const {
      orderData, iossVAT,  createOrderLoading, userAddresses, selectedAddress, selectedAddressKey, addressLoading, addressListActive,
      firstName, lastName, company, address1, address2, countryCode, provinceCode, city, zipCode, phone, provinceLabelText, provinceData,
      lastNameError, address1Error, countryError, zipCodeError, cityError, editAddressModalActive, editRecordId, saveAddressLoading,
      selectShippingMethod, shippingMethodLoading, selectShippingMethodModalActive, selectShippingMethodModalContent,
      phoneError, email, emailError, option1, option1Error, loadingProvinceData,errorShopping
    } = this.state;

    const initSkeletonPage = (
      <div>
        <SkeletonPage>
        <Layout>
          <Layout.Section>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
        </Layout.Section>
          </Layout>
        </SkeletonPage>
      </div>
    );

    // 待下单sku列表
    const skuList = orderData.map((item, key) => {
      let imageUrl = '/default_image.png';
      if (item.sku_image !== '' && (typeof item.sku_image) !== 'object') {
        imageUrl = item.sku_image;
      }

      return (
        <div className="Orders_Detail_Item_Content prepaid_order" key={key}>
          <div className="Orders_Detail_Item_Box">
            <div className="Orders_Variant_Box">
              <div className="Variant_Image">
                <Thumbnail
                  source={imageUrl}
                  alt="product title"
                />
              </div>
              <div className="Orders_Detail_SKU">
                <div className="order_variant_title" title={item.title}>
                  <span>{item.title}</span>
                </div>
                <div className="">
                  {item.variant_title}
                </div>
                <div className="">
                  {item.sku}
                </div>
              </div>
            </div>
            <div className="Orders_Detail_Cost">{intl.get("Cost")}: <span>{0 + 'x' + 1}</span></div>
            <div className="Orders_Detail_Total">{intl.get("Total")}: <span>0</span></div>
          </div>
        </div>
      )
    });

    // 用户地址列表
    let userAddressesList = '';
    if (userAddresses.length > 0) {
      userAddressesList = userAddresses.map((item, key) => {
        let defaultStatus = (item.is_default === 1) ? (
          <div style={{ marginTop: '7px' }}>
            <Badge tone='info' progress="complete">Default address</Badge>
          </div>
        ) : '';

        let singleArress = (
          <Modal.Section key={key}>
            <BlockStack gap={400}>
              <BlockStack>
                <div>{item.first_name + ' ' + item.last_name}</div>
                <div>{item.address1 + ' ' + item.address2}</div>
                <div>{item.city + ' ' + item.province + ' ' + item.country}</div>
                <div>{item.zip} </div>
                <div>{item.phone}</div>
                <div>{item.email}</div>
                <div>{item.company} </div>
                {defaultStatus}
              </BlockStack>
              <BlockStack>
                <ButtonGroup>
                  <Button onClick={() => this.handleSelectNewAddress(key)} disabled={(selectedAddressKey === key)}>{(selectedAddressKey === key) ? 'Selected' : 'Select'}</Button>
                  <Button variant='plain' onClick={() => this.handleEditAddress(key, item.id, true)}>{intl.get("edit")}</Button>
                </ButtonGroup>
              </BlockStack>
            </BlockStack>
          </Modal.Section>
        );

        return [
          singleArress
        ];
      });
    } else {
      userAddressesList = <Modal.Section><div>{intl.get("Please add an address first.")}</div></Modal.Section>;
    }

    // 用户选择的地址
    let userSelectAddress = selectedAddress ? (
      <div>
        <div>{selectedAddress.first_name + ' ' + selectedAddress.last_name}</div>
        <div>{selectedAddress.address1 + ' ' + selectedAddress.address2}</div>
        <div>{selectedAddress.city + ' ' + selectedAddress.province + ' ' + selectedAddress.country}</div>
        <div>{selectedAddress.zip} </div>
        <div>{selectedAddress.phone}</div>
        <div>{selectedAddress.email}</div>
        <div>{selectedAddress.company}</div>
      </div>
    ) : (
      <div>
        {intl.get("Please add an address first.")}
      </div>
    );

    let allShippingFee = 0;

    // 运输方式展示
    let shippingMethodShow = '';
    if (selectShippingMethod.length > 0) {
      shippingMethodShow = selectShippingMethod.map((it, kk) => {
        // 计算运费
        allShippingFee += it.BeforeConvertCost;

        return (
          <Box
            key={kk}
            
          >
            <Box style={{display:"flex",justifyContent:'space-between',marginBottom:10}}>
            <Text variant="headingMd" as="h2">{'Shipping method'}</Text>
            <Button variant='plain' 
                  onClick={() => this.handleSelectShippingModal(kk, true)}
                  disabled={addressLoading || shippingMethodLoading || (selectShippingMethod.length === 0)}
                  >
                  {intl.get("Select shipping method")}
                  </Button>
          </Box>
            <Box>
              <Box>{intl.get("Shipping company")}: <span className='prepaid_order_shipping'>{it.ShippingMethod}</span></Box>
              <Box>{intl.get("Estimated delivery time")}: <span className='prepaid_order_shipping'>{it.DeliveryTime}</span></Box>
              <Box>{intl.get("Shipping Cost")}: <span className='prepaid_order_shipping'>{'US$' + it.BeforeConvertCost.toFixed(2)}</span></Box>
            </Box>
          </Box>
        )
      });
    } else {
      shippingMethodShow = (
        <Box
          // title="Shipping method"
          // actions={[
          //   {
          //     content: 'Select shipping method',
          //     onAction: () => { },
          //     disabled: addressLoading || shippingMethodLoading || (selectShippingMethod.length === 0)
          //   }
          // ]}
        >
          <Box style={{display:"flex",justifyContent:'space-between',margin:"16px 0"}}>
            <Text variant="headingMd" as="h2">{intl.get("Shipping method")}</Text>
            <Button variant='plain' 
                  disabled={addressLoading || shippingMethodLoading || (selectShippingMethod.length === 0)}
                  >
                  {intl.get("Select shipping method")}
            </Button>
          </Box>
          {!errorShopping && <Box style={{marginBottom:'10px'}}>
          <Text variant="headingMd" as="h2">{intl.get("Order summary")}</Text>
          <div style={{ color: 'red' }}>{intl.get("Get no shipping methods.")}</div>
          </Box>}
        </Box>
      )
    }


    // 订单总额
    let totalPrcie = parseFloat(iossVAT + allShippingFee);

    const pageContent = this.state.loadingPage ? initSkeletonPage : (
      <section>
        <Page
          title={intl.get('Redeemed Order')}
          backAction={
            {
              content: intl.get('Redeemed Order'),
              onAction: ()=>{
                utils.routeListen('/admin/black-friday')
                utils.routeJump(this,'/admin/black-friday')
              }
            }
          }
        >
          <Layout>
            <Layout.Section>
              <div style={{ "paddingTop": "10px" }}></div>
              <BlockStack gap={400}>
              {/* 地址模块 (自定义订单) */}
              {
                  <Card>
                    <Box style={{display:"flex",justifyContent:'space-between',marginBottom:"16px"}}>
                    <Text variant="headingMd" as="h2">{intl.get('Adderss')}</Text>
                    <InlineStack align='center'>
                    <Button variant='plain' 
                      onClick={() => this.handleEditAddress(0, 0, true)}
                      disabled={addressLoading}
                      >
                    + {intl.get('Add new address')}
                      </Button>
                      <span style={{width:"16px"}}></span>
                      <Button variant='plain' 
                      onClick={() => this.handleUserAddressChange(true)}
                      disabled={(addressLoading || (userAddresses.length === 0))}
                      >
                    {intl.get('Select other address')}
                      </Button>
                      </InlineStack>
                    </Box>
                    {userSelectAddress}
                  </Card>
              }
              <Card>
              {shippingMethodShow}
              </Card>

              {/* 订单sku模块 */}
              <Box style={{maxHeight:'350px',overflowY:'auto',overflowX:'hidden'}}>
              <Card>
              <Text variant="headingMd" as="h6">{intl.get('Products')}</Text>
                <Box >
                  {/* sku列表 */}
                  {skuList}
                </Box>

                {/* 运费物流方式模块 (自定义订单) */}
               

                

               
              </Card>
              </Box>
              <Card>
                <Text variant="headingMd" as="h2">{intl.get("Order summary")}</Text>
                <Box style={{ "padding": "16px 0" }}>
                
                  <div>
                  {
                      (
                        <InlineStack align='space-between'>
                            <div className='prepaid_order_breakdown_item'>{intl.get("Shipping Fee")}</div>
                            <div>{'$' + allShippingFee.toFixed(2) + ' USD'}</div>
                        </InlineStack>
                      )
                    }
                    {
                      (
                        <InlineStack align='space-between'>
                            <div className='prepaid_order_breakdown_item'>{intl.get("VAT")}</div>
                            <div>{'$' + iossVAT.toFixed(2) + ' USD'}</div>
                        </InlineStack>
                      )
                    }
                    {
                      (
                        <InlineStack align='space-between'>
                            <div className='prepaid_order_breakdown_item'>{intl.get("Product Cost")}</div>
                            <div>{'$' + 0 + ' USD'}</div>
                        </InlineStack>
                      )
                    }
                   

                    <div className='min_space'></div>
                    <InlineStack align='space-between'>
                        <Text variant="headingMd" as="h2">Total</Text>
                        <div className='prepaid_order_total'>{'$' + totalPrcie.toFixed(2) + ' USD'}</div>
                    </InlineStack>
                  </div>
                </Box>
              <InlineStack align='end'>
                  <Button variant='primary' 
                  onClick={()=> this.handleCreateOrderByCart()}
                  disabled={((allShippingFee === 0) || shippingMethodLoading)}
                  loading={createOrderLoading}
                  >
                  {intl.get("Continue")}
                  </Button>
              </InlineStack>
              </Card>
              </BlockStack>
            </Layout.Section>
          </Layout>
        </Page>
      </section>
    )

    // 是否需要检测税费
    let isNeedCheckTaxCode = false;

    // 税号文本
    let taxCodeText = 'Tax code';
    switch (countryCode) {
      case 'BR':
        // 巴西税号特称
        taxCodeText = 'CPF';
        isNeedCheckTaxCode = true;
        break;
      case 'NO':
        // 挪威税号特称
        taxCodeText = 'VOEC';
        isNeedCheckTaxCode = true;
        break;
      case 'CL':
        isNeedCheckTaxCode = true;
        break;
      default:
    }

    // 添加、编辑地址窗口
    let addOrEditAddressModal = (
      <Modal
        open={editAddressModalActive}
        onClose={() => this.handleEditAddress(0, editRecordId, false)}
        title={(editRecordId === 0) ? intl.get('Add new address') : intl.get('Edit address')}
        primaryAction={{
          content: intl.get('Save'),
          onAction: () => this.saveAddressData((editRecordId === 0) ? intl.get('add') : intl.get('update')),
          loading: saveAddressLoading
        }}
        secondaryActions={[
          {
            content: intl.get('Cancel'),
            onAction: () => this.handleEditAddress(0, editRecordId, false),
          }
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <FormLayout.Group>
              <TextField id="firstName" label="First name" onChange={this.handleAddressInputChange} value={firstName} maxLength={50} />
              <TextField id="lastName" label="Last name" onChange={this.handleAddressInputChange} value={lastName} error={lastNameError} maxLength={50} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="company" label="Company" onChange={this.handleAddressInputChange} value={company} maxLength={255} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="address1" label="Address" onChange={this.handleAddressInputChange} value={address1} error={address1Error} maxLength={255} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="address2" label="Apartment, suite, etc. (optional)" onChange={this.handleAddressInputChange} value={address2} maxLength={255} />
            </FormLayout.Group>

            <FormLayout.Group>
              <Select
                id="countryCode"
                label={intl.get("Country/region")}
                options={ShopifyCountriesConfig}
                onChange={this.handleAddressInputChange}
                value={countryCode}
                error={countryError}
              />
              {(provinceData.length > 0 && !loadingProvinceData) && (
                <Select
                  id="provinceCode"
                  label={provinceLabelText}
                  options={provinceData}
                  onChange={this.handleAddressInputChange}
                  value={provinceCode}
                />
              )}
              {
                loadingProvinceData && <div style={{ textAlign: 'center', marginTop: '30px' }}><Spinner size="small" /></div>
              }
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField id="city" label={intl.get("City")} onChange={this.handleAddressInputChange} value={city} error={cityError} maxLength={50} />
              <TextField id="zipCode" label={intl.get("ZIP/Postal code")} onChange={this.handleAddressInputChange} value={zipCode} error={zipCodeError} maxLength={30} />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField id="phone" label={intl.get("Phone")} onChange={this.handleAddressInputChange} value={phone} error={phoneError} maxLength={30} />
              <TextField id="email" type="email" label={intl.get("Email")} onChange={this.handleAddressInputChange} value={email} error={emailError} maxLength={50} />
            </FormLayout.Group>

            {/* 巴西税费 */}
            {isNeedCheckTaxCode && (
              <FormLayout.Group>
                <TextField
                  id="option1"
                  label={taxCodeText}
                  onChange={this.handleAddressInputChange}
                  value={option1}
                  error={option1Error}
                  maxLength={30}
                />
              </FormLayout.Group>
            )}
          </FormLayout>
        </Modal.Section>
      </Modal>
    );

    return (
      <div>
        {/* 页面内容 */}
        {pageContent}

        {/* 地址选择弹窗 */}
        <Modal
          open={addressListActive}
          onClose={() => {this.handleUserAddressChange(false)}}
          title={intl.get("Select an address")}
          // primaryAction={{
          //   content: 'Add new address',
          //   onAction: () => {},
          // }}
          secondaryActions={[
            {
              content: intl.get('Cancel'),
              onAction: () => {this.handleUserAddressChange(false)},
            },
          ]}
        >
          {userAddressesList}
        </Modal>

        {/* 地址添加/编辑弹窗 */}
        {addOrEditAddressModal}

        {/* 选择运输方式弹窗 */}
        <Modal
          open={selectShippingMethodModalActive}
          onClose={() => this.handleSelectShippingModal(0, false)}
          title={intl.get("Select shipping method")}
          primaryAction={[
            {
              content: intl.get('Done'),
              onAction: () => this.handleSelectShippingModal(0, false),
            },
          ]}
          size='large'
        >
          <Modal.Section>
            {selectShippingMethodModalContent}
          </Modal.Section>
        </Modal>

        {toastMarkup}
      </div>
    );
  }
}

export default withRouter(RedeemedOrder);
