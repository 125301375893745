import {
  BlockStack,
  Box,
  Button,
  Icon,
  InlineStack,
  Popover,
  Tooltip,
} from "@shopify/polaris";
import React, { useEffect, useRef, useState } from "react";
import {
  EditIcon,
  SelectIcon,
  PlusCircleIcon,
  QuestionCircleIcon,
} from "@shopify/polaris-icons";
import StartRating from "stars-rating";
import DetailIcon from "../Icon/DetailIcon";
import FavoriteIcon from "../Icon/FavoriteIcon";
import FavoriteIconHover from "../Icon/FavoriteIconHover";
import CountrySelectList from "./CountrySelectList";
import { useHistory } from "react-router-dom";
import utils from "../../../../utils/utils";
import {
  reqPostFavorite,
  reqPostFindProductAdd,
  reqPostProductFreight,
} from "../../../../api";
// import AppBridge from "../../../../utils/appBridge";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazyload";
import { setInitialPath } from "../../../../slice";
import { store } from "../../../../store";
const ProductItem = ({
  keys,
  item,
  showId,
  setShowId,
  setListData,
  listData,
  requestParams,
  getListData,
  handleTogglePopup,
  planData,
  setPlanData,
  userPoints,
  setUserPoints,
  setSourcingLimitModal,
  setEnoughPoint,
  toastFun,
  isSmilar,
  setCollectFirst,
  currentPath,
  getSurveyData
}) => {
  // console.log(item);
  const router = useHistory();
  const [country, setCountry] = useState(item?.country_code);
  const [isFavorite, setIsFavorite] = useState(false);
  const plan_level = useSelector(
    (state) => state.languageReducer.subScriptData
  );

  const handleCollection = async (id, fav) => {
    // 收藏商品
    setCollectFirst(false)
    if (isFavorite) {
      return;
    }
    try {
      setIsFavorite(true);
      let params = {
        record_id: id,
        tag: requestParams.winning === 0 ? "home" : "winning",
        is_favorite: fav === 0 ? 1 : 0,
      };
      let res = await reqPostFavorite(params);
      if (res.code === "200") {
        getSurveyData()
        if (listData.first_favorite) {
          handleTogglePopup();
        }
        
        let list = listData.list;
        for (let i = 0; i < list.length; i++) {
          if (list[i].record_id === id) {
            list[i].favorite = fav === 0 ? 1 : 0;
          }
        }
        // let num = list.filter((item) => item.favorite === 1).length;
        setListData((prevValue) => ({
          ...prevValue,
          fav_count: fav === 0 ? prevValue.fav_count + 1 : prevValue.fav_count - 1,
          first_favorite: 0,
          list: list,
        }));
        if (requestParams.favorite === 1) {
          let param = {...requestParams}
          if (param.category_id === 0) {
            param.category_id = "";
          }
          getListData(param);
        }
        toastFun(fav === 0 ? "Added to Favorites" : "Removed from Favorites")
      }
      setTimeout(() => {
        setIsFavorite(false);
      }, 1500);
    } catch (error) {
      setTimeout(() => {
        setIsFavorite(false);
      }, 1500);
    }
  };
  const [countryLoading, setCountryLoading] = useState(false);
  // 物流方式选择
  const handleCountrySelect = async (value) => {
    if (countryLoading) {
      setShowId("");
      return;
    }
    setCountryLoading(true);
    try {
      let params = {
        receiving_country: value,
        pid: item.pid,
        type: "list",
      };
      let res = await reqPostProductFreight(params);
      if (res.code === "200") {
        setCountry(value);
        let list = listData.list;
        if (list[keys]?.pid === item.pid) {
          list[keys].shipping_method =
            res.data.logistic_list[0]?.ShippingMethod;
          list[keys].shipping_fee =
            res.data.logistic_list[0]?.BeforeConvertCost;
          list[keys].estimated_time = res.data.logistic_list[0]?.DeliveryTime;
          list[keys].estimated_profit = res.data.estimated_profit;
        }
      }
      setShowId("");
      setCountryLoading(false);
    } catch (error) {
      setCountryLoading(false);
    }
  };

  const [addLoading, setAddLoading] = useState(false);
  const handleEditProduct = (dsm_pid) => {
    utils.routeListen("/admin/product_management?selectedView=0");
    utils.routeJump1(router, "/admin/product_management?selectedView=0&ids=" + dsm_pid);
  };
  // 产品导入
  const handleProductImport = async (productId, cj_pid, isPoint) => {
    if (plan_level.bill_level === 5 || plan_level.level === 5) {
      if (userPoints.userPointsData.available_points < 200 && planData.productLimit >= planData.productDay) {
        setEnoughPoint(true);
        return;
      }
    }
    if (planData.productLimit >= planData.productDay) {
      setUserPoints((prevValue) => ({
        ...prevValue,
        userPointsRedeem: {
          ...prevValue.userPointsRedeem,
          record_id: productId,
          pid: cj_pid,
          tag: requestParams.winning === 0 ? "home" : "winning",
          keys,
        },
      }));
      setSourcingLimitModal(true);
      return;
    }
    try {
      setAddLoading(true);
      let params = {
        record_id: productId,
        pid: cj_pid,
        tag: requestParams.winning === 0 ? "home" : "winning",
        is_point: isPoint,
      };
      let res = await reqPostFindProductAdd(params);
      if (res.code === "200") {
        getSurveyData()
        let list = listData.list;
        list[keys].dsm_pid = res.data.id;
        list[keys].is_green = 1;
        toastFun('Added successfully');
        setPlanData((prev) => ({
          ...prev,
          productLimit: planData.productLimit + 1,
        }));
        if (listData?.first_import) {
          handleTogglePopup("add");
        }
        setListData((prevValue) => ({
          ...prevValue,
          first_import: 0,
          list: list,
        }));
      }
      setTimeout(() => {
        setAddLoading(false);
      }, 1500);
    } catch (error) {}
  };
  
  const handleJumpToDetail = (record_id, linkPage, cateId) => {
    let list = listData.list;
    requestParams.prev_id = list[(keys-1)]?.record_id ? list[(keys-1)]?.record_id : '';
    requestParams.next_id = list[(keys+1)]?.record_id ? list[(keys+1)]?.record_id : '';
    utils.routeListen(`/admin/product_selection/product-info?filter=${record_id}&tag=${requestParams?.winning=== 0 ? 'home' : 'winning'}&category=${requestParams?.category_id}&management=&source=&favorite=${requestParams.favorite}`);
    utils.routeJump1(router, `/admin/product_selection/product-info?filter=${record_id}&tag=${requestParams?.winning=== 0 ? 'home' : 'winning'}&category=${requestParams?.category_id}&management=&source=&favorite=${requestParams.favorite}`);
   
    if (!isSmilar) {
      store.dispatch(setInitialPath(currentPath || "/admin/product_selection?select_tab=0"));
    }
  };
  const planCurrent = () => {
    let flag = false;
    if (
      requestParams?.winning === 1 &&
      (plan_level.bill_level === 2 ||
        plan_level.bill_level === 3 ||
        plan_level.bill_level === 5 ||
        plan_level.level === 2 ||
        plan_level.level === 3 ||
        plan_level.level === 5)
    ) {
      flag = true;
    }
    if (requestParams?.winning === 0) {
      flag = true;
    }
    return flag;
  };
  const planCurrent2 = () => {
    let flag = false;
    if (
      requestParams?.winning === 1 ||
      (plan_level.bill_level === 2 ||
        plan_level.bill_level === 3 ||
        plan_level.bill_level === 5 ||
        plan_level.level === 2 ||
        plan_level.level === 3 ||
        plan_level.level === 5)
    ) {
      flag = true;
    }
    return flag;
  };
  const containerRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      // 如果点击的目标不包含在指定类名中
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !event.target.classList.contains("product_selection_country_content")
      ) {
        // 在这里执行你的逻辑，比如关闭弹窗或菜单
        setShowId("");
      }else {
        
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // 当前选中区域的索引
  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };
  return (
    <Box className="commone_content_list_item">
      <Box className="commone_content_list_item_top">
        <Box
          className="product_tp_img"
          onClick={() => {
            if (!planCurrent()) {
              return;
            }
            handleJumpToDetail(item.record_id, 2, "1");
          }}
        >
          <Box className="product_img">
            {isSmilar ? <img
              src={
                item?.product_image
                  ? item?.product_image[activeIndex]
                  : "/new-product/defalut_product.png"
              }
              alt="DropShipMan"
            /> : <LazyLoad
              once
              // scroll={true}
              scrollContainer=".scrollableBox"
              placeholder={
                <img
                  width={56}
                  height={56}
                  className="defalut_product_loading"
                  style={{ borderRadius: "10px" }}
                  src={"/new-product/product_empty_image.jpg"}
                  alt="DropShipMan"
                />
              }
            >
            <img
              src={
                item?.product_image
                  ? item?.product_image[activeIndex]
                  : "/new-product/defalut_product.png"
              }
              alt="DropShipMan"
            />
            </LazyLoad>}
            <div className="product_img_box">
          {item?.product_image.map((_, index) => (
            <div
              key={index}
              className={`product_img_region ${activeIndex === index ? "product_img_active" : ""}`}
              onMouseEnter={() => handleMouseEnter(index)}
            ></div>
          ))}
        </div>
          </Box>
          
          {item?.product_image && item?.product_image.length > 1 && <Box className="product_dots">
        {item?.product_image.slice(0,5).map((_, index) => (
          <span
            key={index}
            className={`product_dots_item ${activeIndex === index ? "product_dots_active" : ""}`}
            onClick={() => setActiveIndex(index)}
          ></span>
        ))}
      </Box>}
        </Box>
        <Box className="product_btn_mask">
          <Box className="product_tp_detail">
            <Tooltip
              width={220}
              content={
                <Box style={{ width: "220px" }}>
                   <Box>
                    Warehouse:
                    <br /> <strong> China</strong>
                  </Box>
                  <Box  style={{
                      borderTop: "1px solid rgba(235, 235, 235, 1)",
                      borderBottom: "1px solid rgba(235, 235, 235, 1)",
                      padding: "8px 0",
                      margin: "10px 0",
                    }}>
                    Shipping Method:
                    <br /> <strong> {item.shipping_method}</strong>
                  </Box>
                  <Box>
                    Estimated Delivery Time: <br />{" "}
                    <strong> {item?.estimated_time}</strong>
                  </Box>
                </Box>
              }
            >
              <span className="product_tp_detail_icon">
                <Icon source={DetailIcon}></Icon>
              </span>
            </Tooltip>
          </Box>
          <Box
            className="product_tp_collection"
            onClick={() => {
              if (!planCurrent()) {
                return;
              }
              handleCollection(item.record_id, item.favorite);
            }}
          >
            {item.favorite === 0 ? (
              <span className="product_tp_favorite_icon">
                <span className="product_tp_favorite_icon_show">
                  <Icon source={FavoriteIcon}></Icon>
                </span>
                <span className="product_tp_favorite_icon_hover">
                  <Icon source={FavoriteIconHover}></Icon>
                </span>
              </span>
            ) : (
              <span
                className={
                  "product_tp_favorite_icon product_tp_favorite_icon_active"
                }
              >
                <Icon source={FavoriteIconHover}></Icon>
              </span>
            )}
          </Box>
        </Box>
      </Box>
      <Box className="commone_content_list_item_bottom">
        <BlockStack>
        <Box className="product_title" title={item.title}>{item.title}</Box>
        <Box className="product_price_btn">
          <InlineStack wrap={false} blockAlign="center" direction={"row"}>
            <Box className="btn_price">${item.price}</Box>
            <Box className="btn_currency">
              <Popover
                active={showId === item.record_id}
                activator={
                  <Button
                    loading={countryLoading}
                    onClick={(e) => {
                      if (!planCurrent()) {
                        return;
                      }
                      e.stopPropagation();
                      if (showId === item.record_id) {
                        setShowId("");
                      } else {
                        setShowId(item.record_id);
                      }
                    }}
                  >
                    <InlineStack align="space-between">
                      <p style={{ marginRight: "10px" }}>
                        <img
                          src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`}
                          alt="loading..."
                        />
                        <span style={{ marginLeft: "5px" }}>{country}</span>
                      </p>
                      <span>
                        <Icon source={SelectIcon}></Icon>
                      </span>
                    </InlineStack>
                  </Button>
                }
                autofocusTarget="first-node"
                onClose={() => {}}
              >
                <CountrySelectList
                  containerRef={containerRef}
                  setShowId={setShowId}
                  handleCountrySelect={handleCountrySelect}
                  setCountry={setCountry}
                  country={country}
                />
              </Popover>
            </Box>
          </InlineStack>
        </Box>
        <Box className="product_invent">
          <InlineStack wrap={false} blockAlign="center">
            <span className="item_text">Inventory:&nbsp;</span> {item.inventory} &nbsp;
            <Tooltip content="Supplier stock levels fluctuate with market changes. If you need to confirm quantities, please contact your dedicated manager.">
              <span>
              <Icon source={QuestionCircleIcon} tone="base" />
              </span>
            </Tooltip>
          </InlineStack>
        </Box>
        <Box className="product_shopping">
        <span className="item_text">Shipping Fee:</span> {item.shipping_fee ? "$" + item.shipping_fee : "-"}
        </Box>
        {planCurrent2() && <Box className="expected_profit">
          <span className="item_text">Expected Profit:</span> ${item.estimated_profit}
        </Box>}
        {planCurrent2() && <Box className="product_Lists"><span className="item_text">Lists:</span> {item.listed_num}</Box>}
        {planCurrent2() && <Box className="product_Review">
          <span className="item_text">Review:</span>&nbsp;{" "}
          <Box className="agent_content_box_rating" style={{margin:0}}>
            <StartRating
              value={5}
              color1="rgba(253, 173, 32, 1)"
              color2="rgba(253, 176, 34, 1)"
              half={true}
              count={1}
              edit={false}
              size={16}
            />
            <span>
              {item?.rating}/5.0 ({item?.review_count})
            </span>
          </Box>
        </Box>}
        </BlockStack>
        <Box className="product_btn">
          <InlineStack blockAlign="center">
            {item.dsm_pid ? (
              <Button
                variant="plain"
                icon={EditIcon}
                onClick={() => {
                  if (!planCurrent()) {
                    return;
                  }
                  handleEditProduct(item.pid);
                }}
              >
                Edit on Product Center
              </Button>
            ) : (
              <Button
                icon={PlusCircleIcon}
                loading={addLoading}
                onClick={() => {
                  if (!planCurrent()) {
                    return;
                  }
                  
                  handleProductImport(item.record_id, item.pid, 0);
                }}
              >
                Add to product list
              </Button>
            )}
          </InlineStack>
        </Box>
       {item.is_green ? <Box className="is_add_product">
          <img src="/new-product/added.png" alt="Dropshipman" />
        </Box>: ""}
      </Box>
    </Box>
  );
};

export default ProductItem;
