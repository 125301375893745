import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./index.css";
import { Button, Modal, OptionList, Toast } from "@shopify/polaris";
import { getPayonnerAuthBalancesRecharge } from "../../api";
import intl from "../../i18n/intl";
const PayoneerSelect = ({
  open,
  num,
  data,
  handlePayoneerSelect,
  handlePayoneerConfirm,
}) => {
  const [selected, setSelected] = useState([]); //选择余额类型
  const [selectLoading, setSelectLoading] = useState(false); //选择余额类型加载状态
  const handleOff = () => {
    handlePayoneerSelect();
    setSelected([]);
    setSelectLoading(false);
  };
  const options = () => {
    let option = [];
    for (let i = 0; i < data.length; i++) {
      option.push({
        label: data[i].currency + " (" + data[i].available_balance + ")",
        value: data[i].id,
      });
    }
    return option;
  };
  const onChangeSelected = (selected) => {
    setSelected(selected);
  };
  const handleRecharge = async () => {
    let flag = false;
    if (data.length > 0 && selected.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === selected[0]) {
          if (
            Number(num) > Number(data[i].available_balance) &&
            data[i].currency === "USD"
          ) {
            flag = true;
          }
        }
      }
    }
    if (selected.length === 0) {
      toggleToast("Please select balance.");
      return;
    }
    if (flag) {
      toggleToast("Balance is insufficient.");
      return;
    }
    let params = {
      type: 3, //3 Payoneer; 1 PayPal; 2 Stripe
      balance_id: selected[0],
      money: num,
    };
    setSelectLoading(true);
    let res = await getPayonnerAuthBalancesRecharge(params);
    if (res.code === "200") {
      handleOff();
      handlePayoneerConfirm(res.data);
    } else {
      toggleToast(res.msg);
    }
    setSelectLoading(false);
  };
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
  return (
    <div>
      <Modal open={open} onClose={() => handleOff()}>
        <div className="payoneer_select_modal">
          <div className="payoneer_select_icon">
            <img src="/payoneer/pay_icon.png" alt="dropshipman" />{"  "}
            {intl.get("Payoneer")}
          </div>
          {/* <div className='payoneer_select_head'>Account</div> */}
          {/* <div className='payoneer_select_email'><span>Zpilin@163.com</span></div> */}
          <div className="payoneer_select_amount payoneer_select_border">
            <span>{intl.get("Amount")}</span>
            <span style={{ fontWeight: "bold", fontSize: 20 }}>USD {Number(num).toFixed(2)}</span>
          </div>
          {data.length <= 0 ? (
            <div className="payoneer_select_balances payoneer_select_border">
              <span style={{margin:'10px'}}>{intl.get("Select Available Balances")}</span>
            </div>
          ) : (
            <div className="payoneer_select_balances payoneer_select_border">
              <OptionList
                title={intl.get("My Available Balances")}
                onChange={onChangeSelected}
                options={options()}
                selected={selected}
              />
            </div>
          )}
          <div className="payoneer_select_button">
            <Button onClick={() => handleOff()}>{intl.get("Cancel")}</Button>
            <Button
              variant='primary'
              loading={selectLoading}
              // disabled={selected.length === 0}
              onClick={() => handleRecharge()}
            >
              {intl.get("Next")}
            </Button>
          </div>
        </div>
      </Modal>
      {toastMarkup}
    </div>
  );
};

export default withRouter(PayoneerSelect);
