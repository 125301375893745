import {
  BlockStack,
  Box,
  Card,
  InlineStack,
  SkeletonBodyText,
} from "@shopify/polaris";
import React from "react";

const Index = () => {
  return (
      <BlockStack gap="300" direction="cloumn">
        <Box className="product_commone_content_list">
          {list.map((item, ind) => (
            <Box className="product_commone_content_list_item" key={ind}>
              <Card padding={0}>
                <Box className="commone_content_list_item">
                  <Box className="commone_content_list_item_top">
                    <Box className="product_tp_img">
                      <Box className="product_img" style={{
                            
                            background: "rgb(227, 227, 227)",
                          }}>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="commone_content_list_item_bottom">
                    <BlockStack gap={200}>
                    <SkeletonBodyText lines={1}/>
                      <InlineStack
                        align="space-between"
                        blockAlign="center"
                      >
                        <Box style={{ width: "30%" }}>
                        <SkeletonBodyText lines={1}/>
                        </Box>
                        <Box style={{ width: "50%" }}>
                        <SkeletonBodyText lines={1}/>
                        </Box>
                      </InlineStack>
                      <Box>
                     <SkeletonBodyText lines={1}/>
                      </Box>
                      <Box>
                     <SkeletonBodyText lines={1}/>
                      </Box>
                      <Box>
                     <SkeletonBodyText lines={1}/>
                      </Box>
                      <Box style={{ width: "80%" }}>
                      <SkeletonBodyText lines={1}/>
                      </Box>
                    </BlockStack>
                  </Box>
                </Box>
              </Card>
            </Box>
          ))}
        </Box>
      </BlockStack>
  );
};
const list = [
  1, 2, 3, 4, 5, 6, 
];

export default Index;
