import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Page, Card, Layout,SkeletonBodyText,DataTable,FormLayout,Toast,Pagination,Select,SkeletonPage,Spinner,TextField,
  Checkbox,EmptySearchResult, BlockStack, InlineStack, Button, Box,
} from "@shopify/polaris";
import utils from '../../../utils/utils'
import shippingCountryArray from '../../../assets/CountryArray';
import { reqPostCalculateFreight } from '../../../api';
import './index.css';
import AppBridge from '../../../utils/appBridge';
import intl from "../../../i18n/intl";
class FreightCalculation extends Component {
  state = {
    //页面动作
    initPage: true,
    loadingPage: false,
    isTurningPage: false,
    page: 1,
    size: 10,
    total: 0,
    hasPrevious: false,
    hasNext: true,
    //弹窗
    showToast: false,  //弹窗显示
    showToastText:'',  //弹窗文字
    showToastIserror:false, //弹窗是否错误

    // 筛选参数
    country: '',
    ship_from: '',
    weight: '',
    long_parameter: '',
    wide_parameter: '',
    high_parameter: '',
    postcode: '',
    commodity_type: false,

    countryError: '',
    weightError: '',

    // ship from 仓库
    shipFromWarehouse: [
      // {
      //   label: 'select a warehouse',
      //   value: ''
      // },
      {
        label: 'China',
        value: 'CN'
      },
      // {
      //   label: 'Brazil',
      //   value: 'BR'
      // },
      // {
      //   label: 'United States',
      //   value: 'US'
      // },
    ],
    // ship to 国家数组
    shippingCountryConfigArray: [{label:intl.get('Select a country'),value:''}],
    // 结果数据
    pageData: [],
  };

  //进入页面调用接口
  componentDidMount() {
    // if(AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar('Freight calculation','/admin/customService')
    // }
    // shipping to 数组追加一个选项
    let shippingToArr = this.state.shippingCountryConfigArray;
    for(let i = 0; i < shippingCountryArray.length; i++){
      shippingToArr.push({"label":shippingCountryArray[i].label,"value":shippingCountryArray[i].value});
    }
    // 延迟一秒钟
    setTimeout(()=>{
      this.setState({
        initPage: false,
        shippingCountryConfigArray: shippingToArr,
      });
    },1000);
  }

  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 获取列表数据
  getPageData = async(isTurnPage=false) =>{
    let errorList = {};
    if(!this.state.country){
      errorList.countryError = intl.get('Please select a country');
    }
    if(this.state.weight === '' || this.state.weight === '0'){
      errorList.weightError = intl.get('Please enter a valid product weight.');
    }
    if(Object.keys(errorList).length > 0){
      this.setState(errorList);
      return;
    }

    this.setState({
      loadingPage: true,
      isTurningPage: isTurnPage,
      hasPrevious: false,
      hasNext:false
    });

    const params = {
      page: this.state.page,
      size: this.state.size,
      country: this.state.country,
      ship_from: this.state.ship_from,
      weight: this.state.weight,
      long_parameter: this.state.long_parameter,
      wide_parameter: this.state.wide_parameter,
      high_parameter: this.state.high_parameter,
      postcode: this.state.postcode,
      commodity_type: this.state.commodity_type ? 1 : 0,
    };

    const response = await reqPostCalculateFreight(params);
    if(response.code === '200'){
      this.setState({
        loadingPage: false,
        isTurningPage: false,
        pageData: response.data,
        total: response.count
      });

      // 更新分页数据
      if(this.state.page > 1){
        this.setState({
          hasPrevious:true
        });
      }else{
        this.setState({
          hasPrevious:false
        });
      }

      const maxPage =  Math.ceil(response.count / this.state.size);
      if(this.state.page < maxPage){
        this.setState({
          hasNext:true
        });
      }else{
        this.setState({
          hasNext:false
        });
      }

    }else{
      this.setState({
        loadingPage: false,
        isTurningPage: false,
      });
      this.toggleToast(response.msg);
    }
  }

  handleInputChange = (value,field) => {
    this.setState({
      [field]: value,
      page: 1
    },()=>{
      let setField = '';
      if(field === 'country'){
        setField = 'countryError';
      }
      if(field === 'weight'){
        setField = 'weightError';
      }

      if(setField){
        this.setState({ [setField]: '' });
      }
    })
  }

  // 重置表单数据
  handleResetFromData = () => {
    this.setState({
      country: '',
      ship_from: '',
      weight: '',
      long_parameter: '',
      wide_parameter: '',
      high_parameter: '',
      postcode: '',
      commodity_type: false,
      countryError: '',
      weightError: '',
      pageData: [],
      total: 0
    });
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    const {
      pageData,commodity_type,country,ship_from,weight,long_parameter,wide_parameter,high_parameter,postcode,shippingCountryConfigArray,shipFromWarehouse,countryError,weightError
    } = this.state;

    const initSkeletonPage = (
         <SkeletonPage fullWidth={false}>
         <Layout>
      <Layout.Section>
      <Card>
      <BlockStack gap={400}>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      <SkeletonBodyText lines={3}/>
      </BlockStack>
      </Card>
    </Layout.Section>
      </Layout>
        </SkeletonPage>
    )

    const emptyStateMarkup = (!this.state.loadingPage && (this.state.total === 0)) && (
      <div className="empty_search_result">
        <EmptySearchResult
          title={intl.get('No shipping method yet')}
          description={intl.get('Try changing the filters')}
          withIllustration
        />
      </div>
    );

    const loadingSpinner = this.state.loadingPage && (
      <div className="calculation_freight_spinner">
        <Spinner accessibilityLabel="Spinner loading" size="large" />
      </div>
    )

    // 运输方式列表输出
    let tableRows = [];
    if(!this.state.loadingPage && pageData.length > 0){
      tableRows = pageData.map((item,key)=>{
        return [
          key+1,
          item.ShippingMethod,
          item.DeliveryTime,
          `US$${item.BeforeConvertCost} USD`
        ]
      })
    }

    var footerContentMsg = '';
    if(this.state.total > 0){
      const startNum = (((this.state.page - 1) * this.state.size) + 1);
      const endNum   = (this.state.page * this.state.size > this.state.total) ? this.state.total : this.state.page * this.state.size;
      footerContentMsg = <div>{intl.get("Showing {{startNum}} - {{endNum}} of {{total}} results", startNum, endNum, this.state.total)}</div>;
    }

    const PaginationSettings = (this.state.total === 0 || (!this.state.isTurningPage && this.state.loadingPage)) ? '' : (
      (!this.state.isTurningPage || this.state.loadingPage) ? (
        <div className="Polaris-New-Pagination">
          <Pagination
            label={footerContentMsg}
            hasPrevious = {this.state.hasPrevious}
            onPrevious={() => {
              this.setState(({page:this.state.page - 1}),()=>{
                  this.getPageData(true);
              });
            }}
            hasNext = {this.state.hasNext}
            onNext={() => {
              this.setState(({page:this.state.page + 1}),()=>{
                  this.getPageData(true);
              });
            }}
          />
        </div>
      ) : ''
    );

    const pageContent = this.state.initPage ? initSkeletonPage : (
      <section>
        <Page
          fullWidth={false}
          backAction={AppBridge.exist() ? undefined : {content: 'Find products', onAction: () =>{
            utils.routeListen('/admin/customService')
            utils.routeJump(this,'/admin/customService')
            }}}
          title={AppBridge.exist() ? "" : <div style={{fontSize:'20px'}} >{intl.get("Freight calculation")}</div>}
        >
          <Layout>
            <Layout.Section>
              <BlockStack gap={400}>
              <Card>
                <FormLayout>
                  <FormLayout.Group>
                    <Box className="select-box">
                    <Select 
                      label={intl.get("Ship from")} 
                      id="ship_from" 
                      options={shipFromWarehouse}
                      onChange={this.handleInputChange}
                      value={ship_from}
                    />
                    <Select
                      id="country"
                      label={intl.get("Ship to")}
                      options={shippingCountryConfigArray}
                      onChange={this.handleInputChange}
                      value={country}
                      error={countryError}
                    />
                    <TextField label={intl.get("Weight(g)")} id="weight" type="number" value={weight} onChange={this.handleInputChange} error={weightError} />
                    </Box>
                  </FormLayout.Group>
                  <FormLayout.Group>
                  <Box className="select-box">
                    <TextField label={intl.get("Long(cm)")} id="long_parameter" type="number" value={long_parameter} onChange={this.handleInputChange} />
                    <TextField label={intl.get("Width(cm)")} id="wide_parameter" type="number" value={wide_parameter} onChange={this.handleInputChange} />
                    <TextField label={intl.get("Height(cm)")} id="high_parameter" type="number" value={high_parameter} onChange={this.handleInputChange} />
                    </Box>
                  </FormLayout.Group>
                  <FormLayout.Group>
                  <Box className="select-box">
                    <TextField label={intl.get("Zip code")} id="postcode" value={postcode} onChange={this.handleInputChange} />
                    <div></div>
                    <div></div>
                    </Box>
                  </FormLayout.Group>
                  <Checkbox
                    id="commodity_type"
                    label={intl.get("Contains batteries/magnets or liquids")}
                    checked={commodity_type}
                    onChange={this.handleInputChange}
                  />
                </FormLayout>
                <InlineStack align='end'>
                  <Button onClick={this.handleResetFromData}>{intl.get("Reset")}</Button>
                  <span style={{display:'inline-block',width:'16px'}}></span>
                  <Button variant='primary' onClick={()=>this.getPageData(false)}>{intl.get("Calculate")}</Button>
                </InlineStack>
              </Card>

              <Card>
                <DataTable
                  columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'numeric',
                  ]}
                  headings={[
                    intl.get('No')+".",
                    intl.get('Shipping method'),
                    intl.get('Delivery time'),
                    intl.get('Shipping Cost'),
                  ]}
                  rows={tableRows}
                />
                {loadingSpinner}
                {PaginationSettings}
                {emptyStateMarkup}
              </Card>
              </BlockStack>
            </Layout.Section>
          </Layout>
        </Page>
      </section>
    )

    return (
      <div>
        {/* 页面内容 */}
     
        {pageContent}
        {toastMarkup}
      </div>
    );
  }
}

export default withRouter(FreightCalculation);