import React, { Component } from 'react';

import FrameComponents from "../FrameComponents";

import "./index.css";

class dashboard extends Component {
    // 进入组件调用
    componentDidMount() {
        // 设置页面标题
        document.title = 'DropshipMan-coupon';
    }

    render() {

        return (
            <div>
                <FrameComponents name="Coupon" />
            </div>
        );

    }
}

export default dashboard;
