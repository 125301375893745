import React, { Component } from 'react';
import FrameComponents from "../FrameComponents"; 
import "./index.css";

class LandingPage extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan - Landing Page Builder';
  }

  render() {
    return (
      <div>
        <FrameComponents name="Landing Page Builder"/> 
      </div>
    )
  }
}

export default LandingPage;