/* 国家数组 */
const countryArrayConfig = [
    {
        "label":"Afghanistan",
        "value":"AF",
        "mobile_prefix":"+93"
    },
    {
        "label":"Aland Islands",
        "value":"ALA",
        "mobile_prefix":"+358"
    },
    {
        "label":"Albania",
        "value":"AL",
        "mobile_prefix":"+355"
    },
    {
        "label":"Alderney",
        "value":"GBA",
        "mobile_prefix":"+44"
    },
    {
        "label":"Algeria",
        "value":"DZ",
        "mobile_prefix":"+213"
    },
    {
        "label":"American Samoa",
        "value":"AS",
        "mobile_prefix":"+1684"
    },
    {
        "label":"Andorra",
        "value":"AD",
        "mobile_prefix":"+376"
    },
    {
        "label":"Angola",
        "value":"AO",
        "mobile_prefix":"+244"
    },
    {
        "label":"Anguilla",
        "value":"AI",
        "mobile_prefix":"+1264"
    },
    {
        "label":"Antigua and Barbuda",
        "value":"AG",
        "mobile_prefix":"+1268"
    },
    {
        "label":"Argentina",
        "value":"AR",
        "mobile_prefix":"+54"
    },
    {
        "label":"Armenia",
        "value":"AM",
        "mobile_prefix":"+374"
    },
    {
        "label":"Aruba",
        "value":"AW",
        "mobile_prefix":"+297"
    },
    {
        "label":"Ascension Island",
        "value":"ASC",
        "mobile_prefix":"+247"
    },
    {
        "label":"Australia",
        "value":"AU",
        "mobile_prefix":"+61"
    },
    {
        "label":"Austria",
        "value":"AT",
        "mobile_prefix":"+43"
    },
    {
        "label":"Azerbaijan",
        "value":"AZ",
        "mobile_prefix":"+994"
    },
    {
        "label":"Bahamas",
        "value":"BS",
        "mobile_prefix":"+1242"
    },
    {
        "label":"Bahrain",
        "value":"BH",
        "mobile_prefix":"+973"
    },
    {
        "label":"Bangladesh",
        "value":"BD",
        "mobile_prefix":"+880"
    },
    {
        "label":"Barbados",
        "value":"BB",
        "mobile_prefix":"+1246"
    },
    {
        "label":"Belarus",
        "value":"BY",
        "mobile_prefix":"+375"
    },
    {
        "label":"Belgium",
        "value":"BE",
        "mobile_prefix":"+32"
    },
    {
        "label":"Belize",
        "value":"BZ",
        "mobile_prefix":"+501"
    },
    {
        "label":"Benin",
        "value":"BJ",
        "mobile_prefix":"+229"
    },
    {
        "label":"Bermuda",
        "value":"BM",
        "mobile_prefix":"+1441"
    },
    {
        "label":"Bhutan",
        "value":"BT",
        "mobile_prefix":"+975"
    },
    {
        "label":"Bolivia",
        "value":"BO",
        "mobile_prefix":"+591"
    },
    {
        "label":"Bosnia and Herzegovina",
        "value":"BA",
        "mobile_prefix":"+387"
    },
    {
        "label":"Botswana",
        "value":"BW",
        "mobile_prefix":"+267"
    },
    {
        "label":"Brazil",
        "value":"BR",
        "mobile_prefix":"+55"
    },
    {
        "label":"Bulgaria",
        "value":"BG",
        "mobile_prefix":"+359"
    },
    {
        "label":"Burkina Faso",
        "value":"BF",
        "mobile_prefix":"+226"
    },
    {
        "label":"Burundi",
        "value":"BI",
        "mobile_prefix":"+257"
    },
    {
        "label":"Cambodia",
        "value":"KH",
        "mobile_prefix":"+855"
    },
    {
        "label":"Cameroon",
        "value":"CM",
        "mobile_prefix":"+237"
    },
    {
        "label":"Canada",
        "value":"CA",
        "mobile_prefix":"+1"
    },
    {
        "label":"Cape Verde",
        "value":"CV",
        "mobile_prefix":"+238"
    },
    {
        "label":"Caribbean Netherlands",
        "value":"BQ",
        "mobile_prefix":"+599"
    },
    {
        "label":"Cayman Islands",
        "value":"KY",
        "mobile_prefix":"+345"
    },
    {
        "label":"Central African Republic",
        "value":"CF",
        "mobile_prefix":"+236"
    },
    {
        "label":"Chad",
        "value":"TD",
        "mobile_prefix":"+235"
    },
    {
        "label":"Chile",
        "value":"CL",
        "mobile_prefix":"+56"
    },
    {
        "label":"Christmas Island",
        "value":"CX",
        "mobile_prefix":"+61"
    },
    {
        "label":"Cocos (Keeling) Islands",
        "value":"CC",
        "mobile_prefix":"+61"
    },
    {
        "label":"Colombia",
        "value":"CO",
        "mobile_prefix":"+57"
    },
    {
        "label":"Comoros",
        "value":"KM",
        "mobile_prefix":"+269"
    },
    {
        "label":"Congo, The Democratic Republic Of The",
        "value":"ZR",
        "mobile_prefix":"+243"
    },
    {
        "label":"Congo, The Republic of Congo",
        "value":"CG",
        "mobile_prefix":"+242"
    },
    {
        "label":"Cook Islands",
        "value":"CK",
        "mobile_prefix":"+682"
    },
    {
        "label":"Costa Rica",
        "value":"CR",
        "mobile_prefix":"+506"
    },
    {
        "label":"Cote D'Ivoire",
        "value":"CI",
        "mobile_prefix":"+225"
    },
    {
        "label":"Croatia (local name: Hrvatska)",
        "value":"HR",
        "mobile_prefix":"+385"
    },
    {
        "label":"Curacao",
        "value":"CW",
        "mobile_prefix":"+599"
    },
    {
        "label":"Cyprus",
        "value":"CY",
        "mobile_prefix":"+357"
    },
    {
        "label":"Czech Republic",
        "value":"CZ",
        "mobile_prefix":"+420"
    },
    {
        "label":"Denmark",
        "value":"DK",
        "mobile_prefix":"+45"
    },
    {
        "label":"Djibouti",
        "value":"DJ",
        "mobile_prefix":"+253"
    },
    {
        "label":"Dominica",
        "value":"DM",
        "mobile_prefix":"+1767"
    },
    {
        "label":"Dominican Republic",
        "value":"DO",
        "mobile_prefix":"+1849"
    },
    {
        "label":"Ecuador",
        "value":"EC",
        "mobile_prefix":"+593"
    },
    {
        "label":"Egypt",
        "value":"EG",
        "mobile_prefix":"+20"
    },
    {
        "label":"El Salvador",
        "value":"SV",
        "mobile_prefix":"+503"
    },
    {
        "label":"Equatorial Guinea",
        "value":"GQ",
        "mobile_prefix":"+240"
    },
    {
        "label":"Eritrea",
        "value":"ER",
        "mobile_prefix":"+291"
    },
    {
        "label":"Estonia",
        "value":"EE",
        "mobile_prefix":"+372"
    },
    {
        "label":"Ethiopia",
        "value":"ET",
        "mobile_prefix":"+251"
    },
    {
        "label":"Falkland Islands (Malvinas)",
        "value":"FK",
        "mobile_prefix":"+500"
    },
    {
        "label":"Faroe Islands",
        "value":"FO",
        "mobile_prefix":"+298"
    },
    {
        "label":"Fiji",
        "value":"FJ",
        "mobile_prefix":"+679"
    },
    {
        "label":"Finland",
        "value":"FI",
        "mobile_prefix":"+358"
    },
    {
        "label":"France",
        "value":"FR",
        "mobile_prefix":"+33"
    },
    {
        "label":"French Guiana",
        "value":"GF",
        "mobile_prefix":"+594"
    },
    {
        "label":"French Polynesia",
        "value":"PF",
        "mobile_prefix":"+689"
    },
    {
        "label":"Gabon",
        "value":"GA",
        "mobile_prefix":"+241"
    },
    {
        "label":"Gambia",
        "value":"GM",
        "mobile_prefix":"+220"
    },
    {
        "label":"Georgia",
        "value":"GE",
        "mobile_prefix":"+995"
    },
    {
        "label":"Germany",
        "value":"DE",
        "mobile_prefix":"+49"
    },
    {
        "label":"Ghana",
        "value":"GH",
        "mobile_prefix":"+233"
    },
    {
        "label":"Gibraltar",
        "value":"GI",
        "mobile_prefix":"+350"
    },
    {
        "label":"Greece",
        "value":"GR",
        "mobile_prefix":"+30"
    },
    {
        "label":"Greenland",
        "value":"GL",
        "mobile_prefix":"+299"
    },
    {
        "label":"Grenada",
        "value":"GD",
        "mobile_prefix":"+1473"
    },
    {
        "label":"Guadeloupe",
        "value":"GP",
        "mobile_prefix":"+590"
    },
    {
        "label":"Guam",
        "value":"GU",
        "mobile_prefix":"+1671"
    },
    {
        "label":"Guatemala",
        "value":"GT",
        "mobile_prefix":"+502"
    },
    {
        "label":"Guernsey",
        "value":"GGY",
        "mobile_prefix":"+44"
    },
    {
        "label":"Guinea",
        "value":"GN",
        "mobile_prefix":"+224"
    },
    {
        "label":"Guinea-Bissau",
        "value":"GW",
        "mobile_prefix":"+245"
    },
    {
        "label":"Guyana",
        "value":"GY",
        "mobile_prefix":"+592"
    },
    {
        "label":"Haiti",
        "value":"HT",
        "mobile_prefix":"+509"
    },
    {
        "label":"Honduras",
        "value":"HN",
        "mobile_prefix":"+504"
    },
    {
        "label":"Hong Kong,China",
        "value":"HK",
        "mobile_prefix":"+852"
    },
    {
        "label":"Hungary",
        "value":"HU",
        "mobile_prefix":"+36"
    },
    {
        "label":"Iceland",
        "value":"IS",
        "mobile_prefix":"+354"
    },
    {
        "label":"India",
        "value":"IN",
        "mobile_prefix":"+91"
    },
    {
        "label":"Indonesia",
        "value":"ID",
        "mobile_prefix":"+62"
    },
    {
        "label":"Iraq",
        "value":"IQ",
        "mobile_prefix":"+964"
    },
    {
        "label":"Ireland",
        "value":"IE",
        "mobile_prefix":"+353"
    },
    {
        "label":"Israel",
        "value":"IL",
        "mobile_prefix":"+972"
    },
    {
        "label":"Italy",
        "value":"IT",
        "mobile_prefix":"+39"
    },
    {
        "label":"Jamaica",
        "value":"JM",
        "mobile_prefix":"+1876"
    },
    {
        "label":"Japan",
        "value":"JP",
        "mobile_prefix":"+81"
    },
    {
        "label":"Jersey",
        "value":"JEY",
        "mobile_prefix":"+44"
    },
    {
        "label":"Jordan",
        "value":"JO",
        "mobile_prefix":"+962"
    },
    {
        "label":"Kazakhstan",
        "value":"KZ",
        "mobile_prefix":"+7"
    },
    {
        "label":"Kenya",
        "value":"KE",
        "mobile_prefix":"+254"
    },
    {
        "label":"Kiribati",
        "value":"KI",
        "mobile_prefix":"+686"
    },
    {
        "label":"Korea",
        "value":"KR",
        "mobile_prefix":"+82"
    },
    {
        "label":"Kosovo",
        "value":"KS",
        "mobile_prefix":"+383"
    },
    {
        "label":"Kuwait",
        "value":"KW",
        "mobile_prefix":"+965"
    },
    {
        "label":"Kyrgyzstan",
        "value":"KG",
        "mobile_prefix":"+996"
    },
    {
        "label":"Lao People's Democratic Republic",
        "value":"LA",
        "mobile_prefix":"+856"
    },
    {
        "label":"Latvia",
        "value":"LV",
        "mobile_prefix":"+371"
    },
    {
        "label":"Lebanon",
        "value":"LB",
        "mobile_prefix":"+961"
    },
    {
        "label":"Lesotho",
        "value":"LS",
        "mobile_prefix":"+266"
    },
    {
        "label":"Liberia",
        "value":"LR",
        "mobile_prefix":"+231"
    },
    {
        "label":"Libya",
        "value":"LY",
        "mobile_prefix":"+218"
    },
    {
        "label":"Liechtenstein",
        "value":"LI",
        "mobile_prefix":"+423"
    },
    {
        "label":"Lithuania",
        "value":"LT",
        "mobile_prefix":"+370"
    },
    {
        "label":"Luxembourg",
        "value":"LU",
        "mobile_prefix":"+352"
    },
    {
        "label":"Macau,China",
        "value":"MO",
        "mobile_prefix":"+853"
    },
    {
        "label":"Macedonia",
        "value":"MK",
        "mobile_prefix":"+389"
    },
    {
        "label":"Madagascar",
        "value":"MG",
        "mobile_prefix":"+261"
    },
    {
        "label":"Malawi",
        "value":"MW",
        "mobile_prefix":"+265"
    },
    {
        "label":"Malaysia",
        "value":"MY",
        "mobile_prefix":"+60"
    },
    {
        "label":"Maldives",
        "value":"MV",
        "mobile_prefix":"+960"
    },
    {
        "label":"Mali",
        "value":"ML",
        "mobile_prefix":"+223"
    },
    {
        "label":"Malta",
        "value":"MT",
        "mobile_prefix":"+356"
    },
    {
        "label":"Marshall Islands",
        "value":"MH",
        "mobile_prefix":"+692"
    },
    {
        "label":"Martinique",
        "value":"MQ",
        "mobile_prefix":"+596"
    },
    {
        "label":"Mauritania",
        "value":"MR",
        "mobile_prefix":"+222"
    },
    {
        "label":"Mauritius",
        "value":"MU",
        "mobile_prefix":"+230"
    },
    {
        "label":"Mayotte",
        "value":"YT",
        "mobile_prefix":"+262"
    },
    {
        "label":"Mexico",
        "value":"MX",
        "mobile_prefix":"+52"
    },
    {
        "label":"Micronesia",
        "value":"FM",
        "mobile_prefix":"+691"
    },
    {
        "label":"Moldova",
        "value":"MD",
        "mobile_prefix":"+373"
    },
    {
        "label":"Monaco",
        "value":"MC",
        "mobile_prefix":"+377"
    },
    {
        "label":"Mongolia",
        "value":"MN",
        "mobile_prefix":"+976"
    },
    {
        "label":"Montenegro",
        "value":"MNE",
        "mobile_prefix":"+382"
    },
    {
        "label":"Montserrat",
        "value":"MS",
        "mobile_prefix":"+1664"
    },
    {
        "label":"Morocco",
        "value":"MA",
        "mobile_prefix":"+212"
    },
    {
        "label":"Mozambique",
        "value":"MZ",
        "mobile_prefix":"+258"
    },
    {
        "label":"Myanmar",
        "value":"MM",
        "mobile_prefix":"+95"
    },
    {
        "label":"Namibia",
        "value":"NA",
        "mobile_prefix":"+264"
    },
    {
        "label":"Nauru",
        "value":"NR",
        "mobile_prefix":"+674"
    },
    {
        "label":"Negara Brunei Darussalam",
        "value":"BN",
        "mobile_prefix":"+673"
    },
    {
        "label":"Nepal",
        "value":"NP",
        "mobile_prefix":"+977"
    },
    {
        "label":"Netherlands",
        "value":"NL",
        "mobile_prefix":"+31"
    },
    {
        "label":"Netherlands Antilles",
        "value":"AN",
        "mobile_prefix":"+599"
    },
    {
        "label":"New Caledonia",
        "value":"NC",
        "mobile_prefix":"+687"
    },
    {
        "label":"New Zealand",
        "value":"NZ",
        "mobile_prefix":"+64"
    },
    {
        "label":"Nicaragua",
        "value":"NI",
        "mobile_prefix":"+505"
    },
    {
        "label":"Niger",
        "value":"NE",
        "mobile_prefix":"+227"
    },
    {
        "label":"Nigeria",
        "value":"NG",
        "mobile_prefix":"+234"
    },
    {
        "label":"Niue",
        "value":"NU",
        "mobile_prefix":"+683"
    },
    {
        "label":"Norfolk Island",
        "value":"NF",
        "mobile_prefix":"+672"
    },
    {
        "label":"Northern Mariana Islands",
        "value":"MP",
        "mobile_prefix":"+1670"
    },
    {
        "label":"Norway",
        "value":"NO",
        "mobile_prefix":"+47"
    },
    {
        "label":"Oman",
        "value":"OM",
        "mobile_prefix":"+968"
    },
    {
        "label":"Pakistan",
        "value":"PK",
        "mobile_prefix":"+92"
    },
    {
        "label":"Palau",
        "value":"PW",
        "mobile_prefix":"+680"
    },
    {
        "label":"Palestine",
        "value":"PS",
        "mobile_prefix":"+970"
    },
    {
        "label":"Panama",
        "value":"PA",
        "mobile_prefix":"+507"
    },
    {
        "label":"Papua New Guinea",
        "value":"PG",
        "mobile_prefix":"+675"
    },
    {
        "label":"Paraguay",
        "value":"PY",
        "mobile_prefix":"+595"
    },
    {
        "label":"Peru",
        "value":"PE",
        "mobile_prefix":"+51"
    },
    {
        "label":"Philippines",
        "value":"PH",
        "mobile_prefix":"+63"
    },
    {
        "label":"Poland",
        "value":"PL",
        "mobile_prefix":"+48"
    },
    {
        "label":"Portugal",
        "value":"PT",
        "mobile_prefix":"+351"
    },
    {
        "label":"Puerto Rico",
        "value":"PR",
        "mobile_prefix":"+1"
    },
    {
        "label":"Qatar",
        "value":"QA",
        "mobile_prefix":"+974"
    },
    {
        "label":"Reunion",
        "value":"RE",
        "mobile_prefix":"+262"
    },
    {
        "label":"Romania",
        "value":"RO",
        "mobile_prefix":"+40"
    },
    {
        "label":"Russian Federation",
        "value":"RU",
        "mobile_prefix":"+7"
    },
    {
        "label":"Rwanda",
        "value":"RW",
        "mobile_prefix":"+250"
    },
    {
        "label":"Saint Barthelemy",
        "value":"BLM",
        "mobile_prefix":"+590"
    },
    {
        "label":"Saint Kitts and Nevis",
        "value":"KN",
        "mobile_prefix":"+1869"
    },
    {
        "label":"Saint Lucia",
        "value":"LC",
        "mobile_prefix":"+1758"
    },
    {
        "label":"Saint Martin",
        "value":"MAF",
        "mobile_prefix":"+590"
    },
    {
        "label":"Saint Vincent and the Grenadines",
        "value":"VC",
        "mobile_prefix":"+1784"
    },
    {
        "label":"Samoa",
        "value":"WS",
        "mobile_prefix":"+685"
    },
    {
        "label":"San Marino",
        "value":"SM",
        "mobile_prefix":"+378"
    },
    {
        "label":"Sao Tome and Principe",
        "value":"ST",
        "mobile_prefix":"+239"
    },
    {
        "label":"Saudi Arabia",
        "value":"SA",
        "mobile_prefix":"+966"
    },
    {
        "label":"Senegal",
        "value":"SN",
        "mobile_prefix":"+221"
    },
    {
        "label":"Serbia",
        "value":"SRB",
        "mobile_prefix":"+381"
    },
    {
        "label":"Seychelles",
        "value":"SC",
        "mobile_prefix":"+248"
    },
    {
        "label":"Sierra Leone",
        "value":"SL",
        "mobile_prefix":"+232"
    },
    {
        "label":"Singapore",
        "value":"SG",
        "mobile_prefix":"+65"
    },
    {
        "label":"Sint Maarten (Netherlands)",
        "value":"SX",
        "mobile_prefix":"+599"
    },
    {
        "label":"Slovakia (Slovak Republic)",
        "value":"SK",
        "mobile_prefix":"+421"
    },
    {
        "label":"Slovenia",
        "value":"SI",
        "mobile_prefix":"+386"
    },
    {
        "label":"Solomon Islands",
        "value":"SB",
        "mobile_prefix":"+677"
    },
    {
        "label":"Somalia",
        "value":"SO",
        "mobile_prefix":"+252"
    },
    {
        "label":"South Africa",
        "value":"ZA",
        "mobile_prefix":"+27"
    },
    {
        "label":"South Georgia and the South Sandwich Islands",
        "value":"SGS",
        "mobile_prefix":"+500"
    },
    {
        "label":"South Sudan",
        "value":"SS",
        "mobile_prefix":"+211"
    },
    {
        "label":"Spain",
        "value":"ES",
        "mobile_prefix":"+34"
    },
    {
        "label":"Sri Lanka",
        "value":"LK",
        "mobile_prefix":"+94"
    },
    {
        "label":"St. Pierre and Miquelon",
        "value":"PM",
        "mobile_prefix":"+508"
    },
    {
        "label":"Suriname",
        "value":"SR",
        "mobile_prefix":"+597"
    },
    {
        "label":"Swaziland",
        "value":"SZ",
        "mobile_prefix":"+268"
    },
    {
        "label":"Sweden",
        "value":"SE",
        "mobile_prefix":"+46"
    },
    {
        "label":"Switzerland",
        "value":"CH",
        "mobile_prefix":"+41"
    },
    {
        "label":"Taiwan,China",
        "value":"TW",
        "mobile_prefix":"+886"
    },
    {
        "label":"Tajikistan",
        "value":"TJ",
        "mobile_prefix":"+992"
    },
    {
        "label":"Tanzania",
        "value":"TZ",
        "mobile_prefix":"+255"
    },
    {
        "label":"Thailand",
        "value":"TH",
        "mobile_prefix":"+66"
    },
    {
        "label":"Timor-Leste",
        "value":"TLS",
        "mobile_prefix":"+670"
    },
    {
        "label":"Togo",
        "value":"TG",
        "mobile_prefix":"+228"
    },
    {
        "label":"Tonga",
        "value":"TO",
        "mobile_prefix":"+676"
    },
    {
        "label":"Trinidad and Tobago",
        "value":"TT",
        "mobile_prefix":"+1868"
    },
    {
        "label":"Tunisia",
        "value":"TN",
        "mobile_prefix":"+216"
    },
    {
        "label":"Turkey",
        "value":"TR",
        "mobile_prefix":"+90"
    },
    {
        "label":"Turkmenistan",
        "value":"TM",
        "mobile_prefix":"+993"
    },
    {
        "label":"Turks and Caicos Islands",
        "value":"TC",
        "mobile_prefix":"+1649"
    },
    {
        "label":"Tuvalu",
        "value":"TV",
        "mobile_prefix":"+688"
    },
    {
        "label":"Uganda",
        "value":"UG",
        "mobile_prefix":"+256"
    },
    {
        "label":"Ukraine",
        "value":"UA",
        "mobile_prefix":"+380"
    },
    {
        "label":"United Arab Emirates",
        "value":"AE",
        "mobile_prefix":"+971"
    },
    {
        "label":"United Kingdom",
        "value":"UK",
        "mobile_prefix":"+44"
    },
    {
        "label":"United States",
        "value":"US",
        "mobile_prefix":"+1"
    },
    {
        "label":"Uruguay",
        "value":"UY",
        "mobile_prefix":"+598"
    },
    {
        "label":"Uzbekistan",
        "value":"UZ",
        "mobile_prefix":"+998"
    },
    {
        "label":"Vanuatu",
        "value":"VU",
        "mobile_prefix":"+678"
    },
    {
        "label":"Vatican City State (Holy See)",
        "value":"VA",
        "mobile_prefix":"+379"
    },
    {
        "label":"Venezuela",
        "value":"VE",
        "mobile_prefix":"+58"
    },
    {
        "label":"Vietnam",
        "value":"VN",
        "mobile_prefix":"+84"
    },
    {
        "label":"Virgin Islands (British)",
        "value":"VG",
        "mobile_prefix":"+1284"
    },
    {
        "label":"Virgin Islands (U.S.)",
        "value":"VI",
        "mobile_prefix":"+1"
    },
    {
        "label":"Wallis And Futuna Islands",
        "value":"WF",
        "mobile_prefix":"+681"
    },
    {
        "label":"Yemen",
        "value":"YE",
        "mobile_prefix":"+967"
    },
    {
        "label":"Zambia",
        "value":"ZM",
        "mobile_prefix":"+260"
    },
    {
        "label":"Zanzibar",
        "value":"EAZ",
        "mobile_prefix":"+24"
    },
    {
        "label":"Zimbabwe",
        "value":"ZW",
        "mobile_prefix":"+263"
    }
]

  export default countryArrayConfig;