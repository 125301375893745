import {
  Page,
  Card,
  Layout,
  BlockStack,
  Box,
  SkeletonPage,
  SkeletonBodyText,
  EmptyState,
  Button,
  Toast,
  TextField,
  Icon
} from "@shopify/polaris";

import React, { useEffect, useState } from "react";
import "./index.css";
import intl from "../../../i18n/intl";
import utils from "../../../utils/utils";
import { useHistory, withRouter } from "react-router-dom";
import { reqGetUserPoints, reqGetUserPointsCoupon } from "../../../api";
import ChangeLoading from "../../../components/ChangeLoading";
// import AppBridge from "../../../utils/appBridge";
import RedeemCouponModal from "./RedeemCouponModal";
import PointsIcon from "../PointsPage/component/PointsIcon";

const Index = () => {
  const router = useHistory();
  
  const [initPageData,setInitPageData] = useState({
    isInit:true,
    pageLoading:false,
  })
 
  const [pageData, setPageData] = useState({
    "detail": data,
    "count": 4
})
const formatNumber = (value) => {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }

  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
};
  const getHistoryPage = async ()=>{
   
    try {
      let res = await reqGetUserPoints();
      if (res.code === '200') {
        setPageData({...res.data,detail:data});
      }
      setInitPageData({
        isInit:false,
        pageLoading:false,
        
      })
    } catch (error) {
      }
    
  }
  
  useEffect(() => {
    let isMount = false;
    // if(AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar('Points','/admin/points')
    // }
    if (!isMount) {
      getHistoryPage()
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 输入框正整数匹配
  const positiveInteger = (val) => {
    let re = /^[1-9]\d*$/;
    return re.test(val);
  };
  
  // 兑换数量修改
  const onChangePointNum = (val,ind,max)=>{
    if (val < 1) {
      return;
    }
    let need = Math.floor((pageData?.available_points / max))
    
    if (need < val) {
      let list = pageData.detail.map((item,k)=>{
        if (k === ind ) {
          return {...item,num: need}
        }else {
          return item
        }
      })
      setPageData({...pageData,detail:list})
      toggleToast('Insufficient points')
      return
    }
   let list = pageData.detail.map((item,k)=>{
    if (k === ind ) {
      return {...item,num: val}
    }else {
      return item
    }
  })
  setPageData({...pageData,detail:list})
    
  }
  // 减
  // const onChangePointNumMinus = (val,ind,max)=>{
  //   let list = pageData.detail.map((item,k)=>{
  //     if (k === ind) {
  //       if (item.num > 1) {
  //         return {...item,num:Number(item.num) - 1}
  //       }else {
  //         return {...item}
  //       }
  //     }else {
  //       return item
  //    }
  //   })
  //   setPageData({...pageData,detail:list})
  // }
  // 加
  // const onChangePointNumPlus = (val,ind,max)=>{
  //   let need = Math.floor((pageData?.available_points / max))
  //   if (need < (val+1)) {
  //     toggleToast('Insufficient points')
  //     return
  //   }
  //   let list = pageData.detail.map((item,k)=>{
  //     if (k === ind) {
  //       return {...item,num:Number(item.num) + 1}
  //     }else {
  //       return item
  //    }
  //   })
  //   setPageData({...pageData,detail:list})
  // } 

  const [redeemData,setRedeemData] = useState({
    open:false,
    current:1,
    value:1,
    coupon:1,
    need:80
  })
  // 兑换
  const handleRedeem = (ind,val,coupon,need)=>{
    let maxNum = Math.floor((pageData?.available_points / need));
    if (maxNum < val) {
      toggleToast('Insufficient points')
      return
    }
    setRedeemData({
      open:true,
      current:ind,
      value:val,
      need,
      coupon:coupon
    })
  }
  const resetCallback = async()=>{
    let res = await reqGetUserPointsCoupon({type:redeemData.current,num:redeemData.value})
    if (res.code === '200') {
      setRedeemData({
        open:false,
        current:1,
        value:1,
        coupon:1,
        need:80
      })
      setPageData({...pageData,available_points:pageData.available_points - (redeemData.value * redeemData.need),detail:data})
     
      toggleToast('Successfully redeemed')
    }else {
      toggleToast('Redeemed failed')
    }
  }
  


  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;
 
  const EmptyProductList = (
    <div className="emptyWithImg">
      <EmptyState
        heading={intl.get("Sorry, no results found.")}
        image="/empty.svg"
      >
      </EmptyState>
    </div>
  );
  const skeletonPage =  <SkeletonPage fullWidth={false}>
  <Layout>
   <Layout.Section>
   <Card>
   <BlockStack gap={400}>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   </BlockStack>
   </Card>
 </Layout.Section>
   </Layout>
 </SkeletonPage>
  return (
    <div className="history_log" style={{position:'relative'}}>
      {initPageData.isInit ? skeletonPage : <Page
        backAction={
          false
            ? undefined
            : {
                content: "",
                onAction: () => {
                  utils.routeListen("/admin/points");
                  utils.routeJump1(
                    router,
                    "/admin/points"
                  );
                },
              }
        }
        secondaryActions={
          <div
            style={{display:'flex',alignItems:'center'}}
            onClick={() => {
              utils.routeListen("/admin/coupon");
              utils.routeJump1(
                router,
                "/admin/coupon"
              );
            }}
          >
            <img width={28}  src="/sub/coupon_center.png" alt="Dropshipman" />
            <span style={{marginLeft:10,cursor:'pointer',color:'rgba(0, 122, 255, 1)',fontSize:'16px'}}>
            {intl.get("Coupon Center")}
            </span>
          </div>
        } 
        title={<div style={{ fontSize: 20 }}>{intl.get("Redeem Coupons")} </div>}
      >
        <Layout>
          <Layout.Section>
            <BlockStack gap={400}>
              {pageData?.detail.length > 0 ? <Box>
                <Card>
                  <Box className="history_table_top">
                    <Box className="history_table_title">
                      <img src="/sub/points_icon.png" alt="Dropshipman" />
                       <span style={{fontSize:14,fontWeight:400}}>Remaining points: {formatNumber(pageData?.available_points)}</span></Box>
                    
                  </Box>
                  <Box className="redeem_coupons_list">
                  {pageData?.detail.map((item,ind)=><Box key={ind} className="redeem_coupons_list_item">
                    <Box className="redeem_coupons_list_item_top">
                      {/* <Box className="redeem_coupons_list_item_img">
                        <img src={item.img} alt="Dropshipman" />
                      </Box> */}
                      <Box className="redeem_coupons_list_item_bg" style={{backgroundImage:`url(/buildShopify/redeem_coupon_page_bg.png)`}}>
                    <Box className="redeem_coupons_list_item_title">
                      <strong>{item.title}</strong>
                    </Box>
                    <Box  className="redeem_coupons_list_item_desc">
                      <span>{item.desc}</span>
                    </Box>
                    <Box className="redeem_coupons_list_item_icon">
                      <img src="/buildShopify/redeem_coupon_page_icon.png" alt="Dropshipman" />
                      <span>Dropshiman coupons </span>
                    </Box>
                    </Box>
                    </Box>
                    <Box  className="redeem_coupons_list_item_input">
                      <Box className="redeem_points_input">
                      <TextField
                        type="number"
                        value={item.num}
                        onChange={(value)=>onChangePointNum(value,ind,item.need)}
                        onBlur={(e)=>{
                          if (!positiveInteger(e.target.value) || e.target.value === '') {
                            onChangePointNum(1,ind,item.need)
                          }
                        }}
                        autoComplete="off"
                      />
                        {/* <button onClick={()=>onChangePointNumMinus(item.num,ind,item.need)}>-</button>
                        <input type="text" value={item.num} onInput={(e)=>{
                          if (positiveInteger(e.target.value) || e.target.value === '') {
                            onChangePointNum(e.target.value,ind,item.need)
                          }
                        }}
                        onBlur={(e)=>{
                          if (e.target.value === '') {
                            onChangePointNum(1,ind,item.need)
                          }
                        }}
                        />
                        <button onClick={()=>onChangePointNumPlus(item.num,ind,item.need)}>+</button> */}
                      </Box>
                      <Box className="redeem_points_num">
                      <span className="points_item_icon"><Icon
                      source={PointsIcon}
                      tone="base"
                    /></span>
                      <span> {item.need * item.num} Points</span>
                      </Box>
                    </Box>
                    <Box  className="redeem_coupons_list_item_button">
                      <Button disabled={pageData?.available_points < Number(item.need)} loading={item.loading} onClick={()=>handleRedeem(ind,item.num,item.coupon,item.need)}>Redeem</Button>
                    </Box>
                  </Box> )} 
                  </Box>                  
                </Card>
                
              </Box>:<Box>
                <Card>
                  {EmptyProductList}
                </Card>
                </Box>}
            </BlockStack>
          </Layout.Section>
        </Layout>
        <RedeemCouponModal redeemData={redeemData} setRedeemData={setRedeemData} resetCallback={resetCallback}/>
        {toastMarkup}
      </Page>}
      {initPageData.pageLoading && <ChangeLoading />}
    </div>
  );
};
const data = [
  {
    title:'$1 USD',
    coupon:1,
    desc:'Order over $10 USD',
    num:1,
    loading:false,
    need:80,
    points:'80 Points',
    img:'/sub/redeem_coupon_page_bg.png' 
  },
  {
    title:'$2 USD',
    coupon:2,
    desc:'Order over $20 USD',
    num:1,
    loading:false,
    need:160,
    points:'160 Points',
    img:'/sub/coupon_points2.png' 
  },
  {
    title:'$5 USD',
    coupon:5,
    desc:'Order over $40 USD',
    num:1,
    loading:false,
    need:400,
    points:'400 Points',
    img:'/sub/coupon_points3.png' 
  },
  {
    title:'$10 USD',
    coupon:10,
    desc:'Order over $80 USD',
    num:1,
    loading:false,
    need:900,
    points:'900 Points',
    img:'/sub/coupon_points4.png' 
  },
  {
    title:'$20 USD',
    coupon:20,
    desc:'Order over $150 USD',
    num:1,
    loading:false,
    need:1800,
    points:'1800 Points',
    img:'/sub/coupon_points5.png' 
  },
  {
    title:'$30 USD',
    coupon:30,
    desc:'Order over $200 USD',
    num:1,
    loading:false,
    need:2600,
    points:'2600 Points',
    img:'/sub/coupon_points6.png' 
  },
]

export default withRouter(Index);
