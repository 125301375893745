import React, { Component } from 'react';
import {AppProvider, Layout, Page, Frame} from '@shopify/polaris';
import './index.css';
import translations from '@shopify/polaris/locales/en.json';

class UpgradeNotify extends Component {

  state = {};

  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'Service is suspended for version release';
  }

  render() {
    return (
      <div className="privacypolicypage">
        <AppProvider i18n={translations}>
          <Frame >
            <Page>
              <Layout>
                <Layout.Section>
                  <div className="web_notice">
                    <div
                      className="mom"
                      style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        background: '#fff',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '16px',
                        padding: '40px 25px 0'
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          top: '50%',
                          textAlign: 'center',
                          fontSize: '26px',
                          lineHeight: '1.5'
                        }}
                      >
                        Service is suspended for version release
                      </h1>
                      <div className='content'>
                        Dear valued user, the major upgraded version
                        of Dropshipman is currently being released. In order to ensure that
                        data is not lost, the server has temporarily suspended its services.
                        We will resume services as soon as possible after the release is
                        completed. Thank you for your support and understanding!
                      </div>
                    </div>
                  </div>

                </Layout.Section>
              </Layout>
            </Page>
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default UpgradeNotify;
