import React, { Component } from 'react';

import {
  Page, Card, Layout, SkeletonBodyText, Modal, Button, List, BlockStack, Toast, DropZone, Banner, Thumbnail,
  Text,
  // Checkbox
} from "@shopify/polaris";

import {
  DeleteIcon
} from '@shopify/polaris-icons';

import NavTabs from "../components/NavTabs";
import Zmage from 'react-zmage';
import 'react-zmage/lib/zmage.css';

import { reqGetCarrierAuthUrl, reqGetUserSettings, reqPostUpdateUserSettings, reqPostUploadImage, reqPostCreateOrUpdateService } from '../../../../api';

import intl from '../../../../i18n/intl'
import utils from '../../../../utils/utils';
import './index.css'
import SettingPrompt from '../../../../components/SettingPrompt';
import AppBridge from '../../../../utils/appBridge';
import { store } from '../../../../store';
import { changeBannerData } from '../../../../slice';

class ProfileClass extends Component {

  state = {
    //弹窗
    showToast: false,  //弹窗显示
    showToastText: '',  //弹窗文字
    showToastIserror: false, //弹窗是否错误
    carrierServicebox: false,

    loadingPage: true,
    loadingCarBox: false,
    carrierState: 0,
    saveDataLoading: false,
    disabledSaveData: true,
    carrierStateUrl: '', //运费接口授权链接

    files: [],  // 正在上传的图片数组
    rejectedFiles: [],
    successImages: [],
    modalShow: false,
    nextUrl: '',// 成功上传的数组

    isPreview:false, //预览状态
    imageUrlErr:false,
    imageUrl:'',
  };

  //进入页面调用接口
  componentDidMount() {
    this.getUserSettingsData();
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  getUserSettingsData = async () => {
    const params = {
      type: 'config'
    };
    const response = await reqGetUserSettings(params);
    if (response.code === '200') {
      var result = response.data.userSetting;
      let array = [];
      if (result.logo_url !== '') {
        array.push(result.logo_url);
      }
      this.setState({
        successImages: array,
        loadingPage: false,
        // disabledSaveData: false,
        carrierState: result.carrier_auth,
        carrierServicebox: result.active ? true : false,
      });
    } else {
      this.toggleToast(response.msg);
    }
  }

  //生成授权链接
  getCarrierAuthorUrl = async () => {
    const params = {};
    const response = await reqGetCarrierAuthUrl(params);
    if (response.code === '200') {

      window.location.href = response.data;

    } else {
      this.toggleToast(response.msg);
    }
  }


  //处理checkbox的更改事件
  handleClickChecked = () => {
    this.setState({
      carrierServicebox: !this.state.carrierServicebox,
      loadingCarBox: true,
    }, () => {
      this.createOrDelCarrierService(this.state.carrierServicebox);
    });
  }

  //创建或更新服务
  createOrDelCarrierService = async (isCreate = true) => {
    const params = {
      type: isCreate ? 1 : 2,
    }
    const response = await reqPostCreateOrUpdateService(params);
    this.setState({
      loadingCarBox: false,
    });

    if (response.code === '200') {
      this.toggleToast(response.msg, false);
    } else {
      this.toggleToast(response.msg);
    }
  }



  // save setting
  saveData = async (isUpdate = false) => {
    if (!isUpdate) {
      this.setState({
        saveDataLoading: true
      });
    }
    const params = {
      type: 'config',
      url: JSON.stringify(this.state.successImages),
    };
    const responseData = await reqPostUpdateUserSettings(params);

    this.setState({
      saveDataLoading: false
    });
    if (responseData.code === "200") {

      if (!isUpdate) {
        this.toggleToast('Saved successfully');
        this.setState({
          disabledSaveData: true
        })
      }
    } else {
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:responseData.msg
      }}));
      // this.toggleToast(responseData.msg);
    }
  };

  // 图片上传处理
  handleDrop = (droppedFiles, acceptedFiles, rejectedFiles) => {
    try {
      const res = utils.sizeFilter(acceptedFiles[0].size)
      
      // const fileType = acceptedFiles[0].type
      const fileType = acceptedFiles[0].name
      //判断文件上传规则
      if (res > 5 || fileType.indexOf('jpg') === -1 & fileType.indexOf('png') === -1) {
        this.setState(({
          imageUrlErr: true,
          imageUrl:intl.get('The image size should not exceed 5M, and must a JPG or PNG')
        }))
        // this.toggleToast('The image size should not exceed 5M, and must a JPG or PNG', false);
        return false
      }
    } catch (error) {
      // this.toggleToast('The image size should not exceed 5M, and must a JPG or PNG', false);
      return false
    }

    // 正在上传图片时
    if (this.state.files.length > 0) {
      this.toggleToast(intl.get("Image is uploading"), false);
      this.setState(({
        imageUrlErr: false,
      }))
      return;
    }

    this.setState({
      files: [...this.state.files, ...acceptedFiles],
      rejectedFiles: rejectedFiles
    }, () => {
      // 上传图片
      if (acceptedFiles.length > 0) {
        var imageData = [];
        for (var i = 0; i < acceptedFiles.length; i++) {
          const filesImg = acceptedFiles[i];
          // let authToken = utils.getStore('S_AUTHORIZATION');
          // if ((!AppBridge.exist() && ['development'].includes(process.env.NODE_ENV)) || authToken) {
          //   request.headers['Authorization'] = 'ShopifyAdmin ' + authToken
          //   return request;
          // }
          // let formData = new FormData();
          // formData.append('file',filesImg);
          // var request = new XMLHttpRequest();
          // request.open("POST", "http://project15-1.cn/api/v1/uploadImage");
          // request.setRequestHeader('Authorization', 'ShopifyAdmin ' + authToken);
          // request.onreadystatechange = function () {
          //   if(request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          //   }
          // }
          // request.send(formData);
          // 开启FileReader
          var reader = new FileReader();
          reader.readAsDataURL(filesImg); // 读取文件保存在result中
          reader.onload = (e) => {
            var getRes = e.target.result; // 读取的结果在result中
            imageData.push(
              {
                'name': filesImg.name,
                'image': getRes,
                'type': filesImg.type,
              }
            );
          }
        }

        // 延时请求，预防拿不到图片数据
        setTimeout(() => {
          this.uploadImages(imageData);
        }, 2000);
      }
    })
  }

  // 图片上传
  uploadImages = async (imageData) => {
    const params = {
      data: imageData,
    }

    const response = await reqPostUploadImage(params)
    if (response.code === '200') {
      this.setState({
        disabledSaveData: false
      })
      let successArr = this.state.successImages;
      let getData = response.data;
      if (getData.length > 0) {
        for (let index = 0; index < getData.length; index++) {
          successArr.push(getData[index]);
        }
        this.setState({
          successImages: successArr,
          files: [],
          rejectedFiles: []
        }, () => {
          // 更新到数据库
          setTimeout(() => {
            this.saveData(true);
          }, 600);
        })
      }
      this.setState(({
        imageUrlErr: false,
      }))
      // this.toggleToast(response.msg, false);
    } else {
    
      // this.toggleToast(response.msg);
      this.setState({
        files: [],
        rejectedFiles: [],
        imageUrlErr: true,
        imageUrl:response.msg
      });
    }
  }

  // 移除图片链接
  removeImage = (recordKey) => {
    let data = this.state.successImages;
    data.splice(recordKey, 1);
    this.setState({
      successImages: data,
      disabledSaveData: false,
    });
  }
  handleUrlChange = () => {
    if (this.state.nextUrl !== '') {
      this.saveData(true);
      setTimeout(() => {
        utils.routeListen(this.state.nextUrl)
        utils.routeJump(this,this.state.nextUrl)
      }, 200);
    }
  };
  handleUrlClose = () => {
    if (this.state.nextUrl !== '') {
      utils.routeListen(this.state.nextUrl)
      utils.routeJump(this,this.state.nextUrl)
    }
  }
  handleClose = () => {
    this.setState({
      modalShow: false
    })
  }
  
  //图片预览
  handlePreviewImageChange=()=>{
    this.setState({
      isPreview : true
    })
  }

  //关闭logo预览
  handleClosePreviewImage=()=>{
    this.setState({
      isPreview : false
    })
  }
  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} 
    duration={3000} /> : null;

    const {
      saveDataLoading, disabledSaveData, files, rejectedFiles, successImages,imageUrlErr,imageUrl
    } = this.state;

    // 加载页
    const initSkeletonPage = (
      <div className="SettingsPage__Content">
        <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
      </div>
    )

    const hasError = rejectedFiles.length > 0;
    const fileUpload = !files.length && <DropZone.FileUpload actionTitle="Add logo" />;

    // 图片正在上传状态
    const uploadedFiles = files.length > 0 && (
      <div className="config_logo">
        {files.map((file, index) => (
          <div style={{ position: 'relative' }} key={index}>
            <Thumbnail
              alt={file.name}
              source={window.URL.createObjectURL(file)}
              size="large"
            />
            {/* <div>
              {file.name} <Caption>{file.size} bytes</Caption>
            </div> */}
            <div className="Product__Loading">
              <div className="Sourcing_Request__Loading_Image">
                <img style={{ width: '25px', height: '25px', verticalAlign: 'middle' }} src='/loading.gif' alt="loading" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );

    // 图片logo上传成功展示
    const successImagesFiles = successImages.length > 0 && (
      <div className="config_logo">
        {successImages.map((it, kk) => (
          <div className="Sourcing_Image_Item" style={{ position: 'relative' }} key={kk}>
            {/* <Thumbnail
              alt="product image"
              source={it}
              size="large"
            /> */}
           <div className='imagePreview'>
              <Zmage src={it} alt='' />
            </div>

            <div className="Sourcing__Loading">
              <div className="Config_Logo__Image">
                <div className="image_remove__button" >
                  <Button icon={DeleteIcon} onClick={() => this.removeImage(kk)}></Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );

    //图片上传成功点击预览
    const previewImagesFiles = (
        <div style={{maxHeight: '300px'}}>
          <Modal
            open={this.state.isPreview}
            onClose={this.handleClosePreviewImage}
          >
            <Modal.Section>
               
                {
                  successImages.map((img,key)=>(
                    <div className='imagePreview' key={key} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                      <img
                        src={img}
                        alt="brand_logo"
                        preset="auto"
                        
                      />
                    </div>
                  ))
                }
               
            </Modal.Section>
          </Modal>
        </div>
    )

    // 图片上传错误
    const errorMessage = hasError && (
      <Banner
        title={intl.get("The following images couldn't be uploaded:")}
        tone="warning"
      >
        <List type="bullet">
          {rejectedFiles.map((file, index) => (
            <List.Item key={index}>
              {intl.get(`{{file}} is not supported. File type must be .jpg, .jpeg, .png.`,file.name)}
            </List.Item>
          ))}
        </List>
      </Banner>
    );

    const pageContent = this.state.loadingPage ? initSkeletonPage : (
      <div className="SettingsPage__Content">
        <section className="Panel_Content">
          <Card>
            <Text variant="headingMd" as="h6">
            {intl.get("Custom packaging")}
            </Text>
            <BlockStack>
              <div style={{ width: 114, height: 114 }}>
                {(successImages.length > 0) ? (
                  successImagesFiles
                ) : (
                  <DropZone
                    accept="image/*"
                    type="image"
                    onDrop={this.handleDrop}
                    disabled={(files.length > 0) ? true : false}
                  >
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                )}
              </div>
              <div>
                <p>{intl.get("Dimensions: 1200px by 1200px.")}</p>
                <p>{intl.get("Must be a .jpg or .png")}</p>

                <div className="space">
                  {errorMessage}
                </div>
                {imageUrlErr && <Banner
                title={intl.get("Upload image")}
                tone="warning"
                onDismiss={() => {
                  this.setState(({
                    imageUrlErr: false
                  }))
                }}
              >
                <p>
                  {imageUrl}
                </p>
              </Banner>}
              </div>
            </BlockStack>
          </Card>

          {/* 以下为 carrierState  */}
          {/* {this.state.carrierState ? (
            <Card
              sectioned
              title={intl.get("Carrier service")}
            >
              <Banner
                title="You have authorized successfully."
                status="success"
                onDismiss={() => { }}
              />
              <div className="out_checkbox">
                <Checkbox
                  label="Show the DropshipMan carrier service on check out page."
                  disabled={this.state.loadingCarBox}
                  checked={this.state.carrierServicebox}
                  onChange={this.handleClickChecked}
                />
              </div>
            </Card>
          ) : (
            <Card
              sectioned
              title={intl.get("Carrier service")}
            >
              <div className="out_banner">
                <Banner
                  title={intl.get("Please meets one of the following requirements to create a carrier service:")}
                  status="warning"
                >
                  <List>
                    <List.Item>
                      <p>{intl.get("Your store on the Advanced Shopify plan or higher.")}</p>
                    </List.Item>
                    <List.Item>
                      <p>{intl.get("Your store's Shopify plan is paid annually.")}</p>
                    </List.Item>
                  </List>

                  <Button plain onClick={this.getCarrierAuthorUrl}>{intl.get("Please upgrade our Shopify scope")}</Button>
                </Banner>
              </div>
            </Card>
          )} */}
        </section>
      </div>
    )

    return (

      <div className="Setting__Page_Content">
        <Page
          title={AppBridge.exist() ? '' : intl.get("Settings")}
          primaryAction={
            // AppBridge.exist() ? '' : 
            {
              content: intl.get("Save"),
              onAction: () => this.saveData(false),
              loading: saveDataLoading,
              disabled: disabledSaveData
            }
          }
          separator
        >

          <section className="SettingsPage__Box setting_clearfix">
            <NavTabs selected="Other config" saveData={this.saveData} loading={saveDataLoading} disabled={disabledSaveData}/>

            {pageContent}

 			{/* 图片预览弹窗 */}
            {previewImagesFiles}
          </section>
          {toastMarkup}
          <div className="space"></div>

        </Page>
        <SettingPrompt disabledSaveData={disabledSaveData} url='/admin/settings/config' saveData={this.saveData} save={false}/>
      </div>
    );

  }
}

export default ProfileClass;