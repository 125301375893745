import {Modal} from "@shopify/polaris";
import React, { useState } from "react";
const Index = ({open,setOpen,type}) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const handleSave = () => {
        setOpen(false);
        setSaveLoading(false)
        let sendPreMsg = "Hi! I've reached my limit of 300 sourcing requests on my Premium subscription. Would it be possible to get additional requests?";
        if (type === 'product') {
          sendPreMsg = "Hi! I've reached my limit of 100000 products on my Premium subscription. Would it be possible to get additional requests?";
        }
        if (type === "aliexpress") {
          sendPreMsg = "Hi! I've reached my limit of 100000 AliExpress Products on my Premium subscription. Would it be possible to get additional requests?";
        }
        try {
          // 填充信息并激活对话弹窗
          window.Willdesk.show();
          window.Willdesk.chat(sendPreMsg);
        } catch (error) {
          console.info(error);
        }
      };
     let contentFun = ()=>{
      let text = 'Insufficient sourcing requests'
      if (type === "sourcing") {
         text = 'Insufficient sourcing requests'
      }
      if (type === "product") {
        text = 'Monthly Import Limit Reached'
     }
     if (type === "aliexpress") {
      text = 'Insufficient AliExpress product requests'
   }
   return text
     }
  return (
    <div className="upgrade_new_feature">
      <Modal
        open={open}
        onClose={() => 
          {
            setOpen(false)
            setSaveLoading(false)
          }}
        title={contentFun()}
        primaryAction={{
          content: "Contact Us",
          loading:saveLoading,
          onAction: () => {
            handleSave()
          },
        }}
      >
        <Modal.Section>
        {type === "sourcing" && <p style={{fontSize:'14px'}}>{`You've used up your monthly limit of 300 sourcing requests. Please contact our customer service for additional quota!`}</p>}
        {type === "product" && <p style={{fontSize:'14px'}}>{`You have reached your monthly limit of 100,000 products. Contact support to upgrade your quota.`}</p>}
        {type === "aliexpress" && <p style={{fontSize:'14px'}}>{`You've used up your monthly limit of 100000 aliexpress products. Please contact our customer service for additional quota!`}</p>}
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
