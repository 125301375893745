import dashboard from '../frontEnd/pt-br/dashboard.json'
import findProducts from '../frontEnd/pt-br/findProducts.json'
import common from '../frontEnd/pt-br/common.json'
import frameClass from '../frontEnd/pt-br/frameClass.json'
import sourcing from '../frontEnd/pt-br/sourcing.json'
import listProduct from '../frontEnd/pt-br/listProduct.json'
import orders from '../frontEnd/pt-br/orders.json'
import customService from '../frontEnd/pt-br/customService.json'
import siteOptimization from '../frontEnd/pt-br/siteOptimization.json'
import subscribe from '../frontEnd/pt-br/subscribe.json'
import setting from '../frontEnd/pt-br/setting.json'
import shoppingCart from '../frontEnd/pt-br/shoppingCart.json'
import message from '../frontEnd/pt-br/message.json'
import partnership from '../frontEnd/pt-br/partnership.json'
const ptB =  {
    ...dashboard,
    ...findProducts,
    ...common,
    ...frameClass,
    ...sourcing,
    ...listProduct,
    ...orders,
    ...customService,
    ...siteOptimization,
    ...subscribe,
    ...setting,
    ...shoppingCart,
    ...message,
    ...partnership
};
export default ptB;