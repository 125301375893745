import React, { useState, useEffect } from 'react';
import {
  Modal,
  // ChoiceList,
  InlineStack,
  RadioButton
} from "@shopify/polaris";
import SymbolTake from '../SymbolTake'
import intl from '../../i18n/intl';

const PostagePayPalComponent = (props) => {
  const { open, payData, closeModalCallback, payCallback } = props
  const [paymentSelect, setPaymentSelect] = useState([]) // 单选框选中的值
  const [btnDisabled, setBtnDisabled] = useState(true) // 单选框选中的值

  // 支付方式选择
  const handlePayTpye = (value) => {
    setPaymentSelect(value)
    setBtnDisabled(false)
  }

  // 关闭弹窗
  const closeModal = () => {
    closeModalCallback(!open, 'Wallet')
    setPaymentSelect([])
    setBtnDisabled(true)
  }

  // 支付按钮事件
  const handlePay = (type, order, price, id) => {
    payCallback(type, order, price, id)
  }

  useEffect(() => {
    if(!open) {
      setPaymentSelect([])
      setBtnDisabled(true)
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      title={intl.get('Order') + ':' + payData?.order}
      primaryAction={{
        content: intl.get('Pay'),
        onAction: () => handlePay(paymentSelect[0], payData?.order, payData?.price, payData?.id),
        disabled: btnDisabled
      }}
      footer={
        <InlineStack>
          <img style={{marginRight:8}} width={20} src="/sub/points_icon.png" alt="Dropshipman" />
            <span>You'll receive <strong>{Number(payData?.price).toFixed(0)} points </strong> once you complete this payment.</span>
          </InlineStack>
      }
    >
      <Modal.Section>
        <div style={{ lineHeight: "28px" }}>
          <InlineStack align='space-between'>
              <span style={{ fontWeight: '700' }}>{intl.get('Total cost')}</span>
              {/* <span>{`$${payData?.price} USD`}</span> */}
              <span>
              <SymbolTake price={payData?.price} /> USD
              </span>
          </InlineStack>

          
        </div>
      </Modal.Section>

      <Modal.Section>
      <div className="pay_modal_coupn">
            <div style={{ fontSize: "16px" }}>
            {intl.get('Please choose a payment method')}
            </div>
            <div className="radio_container">
              <div
                className={`${
                  paymentSelect.includes('Wallet')
                    ? "dsm_container_border dsm_container"
                    : "dsm_container"
                } `}
              >
                <div className="dsm_radioLabel">
                      <div>
                        <img src="/dsm_pay.png" alt="dropshipman" />
                      </div>
                      <div>
                        <div style={{ fontWeight: "550" }}>
                        {intl.get('DSM Credit Balance')}
                        </div>
                        <div style={{ color: "rgba(12, 81, 50, 1)" }}>${payData?.walletMoney} USD</div>
                      </div>
                    </div>
                <RadioButton
                  id="Wallet"
                  name="dsm_pay"
                  disabled={payData?.walletMoney <= 0}
                  checked={paymentSelect.length > 0 && paymentSelect.includes('Wallet')}
                  onChange={()=>handlePayTpye(['Wallet'])}
                />
              </div>
              <div
                className={`${
                  paymentSelect.includes('PayPal')
                    ? "pal_container_border pal_container"
                    : "pal_container"
                } `}
              >
                <div className="pay_radioLabel">
                      <div>
                        <img src="/pay_pal.png" alt="dropshipman" />
                      </div>
                      <div style={{ fontWeight: "550" }}>{intl.get('PayPal')}</div>
                    </div>
                <RadioButton
                  id="PayPal"
                  name="pal_pay"
                  checked={paymentSelect.length > 0 && paymentSelect.includes('PayPal')}
                  onChange={()=>handlePayTpye(['PayPal'])}
                />
              </div>
            </div>
          </div>
          <InlineStack align='space-between'>
              <span style={{ fontWeight: '700' }}>{intl.get('Actual payment')}</span>
              <span>
                {/* {`$${payData?.price} USD`} */}
                <SymbolTake price={payData?.price} /> USD
                </span>
          </InlineStack>
          
      </Modal.Section>
    </Modal>
  );
}

export default PostagePayPalComponent;