import { Box, Icon, InlineStack, Modal, TextField } from '@shopify/polaris';
import React, { useState } from 'react';
import {
    MagicIcon
  } from '@shopify/polaris-icons';

const AiUpdateModal = ({aiOptimizedData,setAiOptimizedData,handleAiOptimizedAgain,handleAiOptimizedSuccess,userPoints})=> {
  const [error, setError] = useState(false)
    const handleOff = ()=>{
        setAiOptimizedData((prevValue)=>({
            ...prevValue,
            id:'',
            title:'',
            open:false,
            currentLoading:false,
            againLoading:false,
            applyLoading:false,
        }))
        setError(false)
    }
    const changeAiOptimizedAgain = (value)=>{
      setError(false)
      if (value === '') {
        setError(true)
      }
      if (value.length > 255) {
        return
      }
      setAiOptimizedData((prevValue)=>({
        ...prevValue,
        title:value,
      }))
    }
    
    return (
        <Modal
        open={aiOptimizedData.open}
        onClose={()=>{
          if (aiOptimizedData.againLoading || aiOptimizedData.applyLoading) {
            return
          }
          handleOff()
        }}
        title={"AI Title Generator"}
        primaryAction={{
          content: 'Apply',
          loading:aiOptimizedData.applyLoading,
          disabled:aiOptimizedData.againLoading,
          onAction: ()=>{
            if (aiOptimizedData.title === '') {
              setError(true)
              return
            }
            handleAiOptimizedSuccess(aiOptimizedData.id,aiOptimizedData.title)
          },
        }}
        secondaryActions={aiOptimizedData.applyLoading ? [] : [
          {
            content: 'Generate again',
            // loading:aiOptimizedData.againLoading,
            disabled:aiOptimizedData.applyLoading || aiOptimizedData.againLoading,
            onAction: ()=>handleAiOptimizedAgain(aiOptimizedData.id,aiOptimizedData.title),
          },
        ]}
        footer={aiOptimizedData.is_gpt ? '' : <div>
          Cost: 20 Points • Balance: {userPoints?.userPointsData?.available_points} Points
        </div>}
      >
        <Modal.Section>
          <Box className="ai_modal_optimized_box">
            <InlineStack blockAlign='flex-start'>
                <span className='ai_modal_optimized_icon'>
                <Icon source={MagicIcon} />
                </span>
                <Box className='ai_modal_optimized_text'>
                <TextField
                  value={aiOptimizedData.title}
                  onChange={changeAiOptimizedAgain}
                  disabled={aiOptimizedData.applyLoading || aiOptimizedData.againLoading}
                  error={error ? 'Please enter title' : null}
                  multiline={4}
                  autoComplete="off"
                />
                </Box>
            </InlineStack>
          </Box>
        </Modal.Section>
      </Modal>
    );
}

export default AiUpdateModal;
