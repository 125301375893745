import React, { Component } from 'react';

import {AppProvider, Frame, FormLayout, TextField, Button, Page, Toast, Banner, Layout, Icon, EmptyState, Card, 
  SkeletonPage,SkeletonBodyText, BlockStack,   
} from '@shopify/polaris';

import {
  ViewIcon,EyeCheckMarkIcon
} from '@shopify/polaris-icons';

import { reqPostVerifyLink,reqPostResetPasswordSave } from '../../../api';

import './index.css';
import translations from '@shopify/polaris/locales/en.json';

class ResetPassword extends Component {

  state = {
    loadingPage: true,
    hidePassword: true,
    hideConfirmPassword: true,

    Email:'',
    Password: '',
    ConfirmPassword: '',

    emailError: '',
    passwordError: '',
    confirmPasswordError: '',

    requestLoading: false,

    resetInfoLogId: 0,  // 重置记录表ID
    ResetPasswordSuccess: false,  // 重置密码成功
    isValidLink: true,  // 重置密码链接是否有效
    InvalidMsg: 'Please confirm that the reset password link is correct.',  // 链接无效原因信息

    ResetPasswordError: '',  // 错误返回信息

    // 提示弹窗
    showToast: false, // 提示弹窗是否显示
    showToastText: '', // 提示内容
    showToastIserror: false, // 是否为错误提示
  };


  //进入页面调用接口
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let email = params.get("e");
    let code = params.get("c");
    window.hideLoadingState()
    if(email && code){
      this.VerifyResetLink(email,code);
    }else{
      this.setState({
        loadingPage: false,
        isValidLink: false,
      });
    }
  }

  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 重置密码链接验证
  VerifyResetLink = async(email,code) =>{
    const params = {
      e: email,
      c: code
    };
    const response = await reqPostVerifyLink(params)
    if (response.code === '200') {
      this.setState({ 
        loadingPage: false,
        resetInfoLogId: response.data.record,
        Email: response.data.email
      });
    } else {
      this.setState({
        loadingPage: false,
        isValidLink: false,
        InvalidMsg: response.msg
      });
    }
  }

  // 重置密码提交
  ResetPasswordSave = async() => {
    let isAllow = true;
    if(!this.checkInputEmail(this.state.Email)){
      isAllow = false;
    }
    if(!this.checkInputPassword(this.state.Password)){
      isAllow = false;
    }
    if(!this.checkInputConfirmPassword(this.state.ConfirmPassword)){
      isAllow = false;
    }

    if(!isAllow){
      return false;
    }

    const params = {
      id: this.state.resetInfoLogId,
      email: this.state.Email,
      password: this.state.Password,
      confirmPassword: this.state.ConfirmPassword,
    };
    this.setState({ requestLoading: true });

    const response = await reqPostResetPasswordSave(params)
    if (response.code === '200') {
      this.setState({
        requestLoading: false,
        ResetPasswordSuccess: true,
      });
    } else {
      this.setState({
        requestLoading: false,
        ResetPasswordError: response.msg
      });
    }
  }

  // 密码显示与隐藏
  handleDisplaySwitch = (type) =>{
    if(type === 'hideConfirmPassword'){
      this.setState(({ hideConfirmPassword }) => ({ hideConfirmPassword: !hideConfirmPassword }));
    } else {
      this.setState(({ hidePassword }) => ({ hidePassword: !hidePassword }));
    }
  }

  // email 格式验证
  checkInputEmail(email){
    let errorMsg = '';
    let bool = false;
    if(email === undefined || email === ''){
      errorMsg = 'Please enter your account email';
    }

    if(errorMsg === ''){
      let reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      bool = reg.test(email);
      errorMsg = bool ? '' : 'Invalid email address';
    }
    this.setState({ emailError: errorMsg });
    return bool;
  }

  // password 密码校验
  checkInputPassword(password){
    let errorMsg = '';
    let bool = true;
    if(password === undefined || password === ''){
      bool = false;
      errorMsg = 'Please set your password';
    }

    // 注册时校验密码格式
    if(errorMsg === ''){
      // 1.The password must be 8 - 24 characters long
      // 2.The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number

      if(password.length < 8 || password.length > 24){
        errorMsg = 'The password must be 8 - 24 characters long';
        bool = false;
      } else {
        let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
        bool = reg.test(password);
        errorMsg = bool ? '' : 'The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number';
      }
    }

    this.setState({ passwordError: errorMsg });
    return bool;
  }

  // 两次密码校验
  checkInputConfirmPassword(password){
    let errorMsg = '';
    let bool = false;
    if(password === ''){
      errorMsg = 'Please enter confirm password';
    }

    if(errorMsg === ''){
      if(password === this.state.Password){
        bool = true;
      }
      errorMsg = bool ? '' : 'Confirm password doesn\'t match';
    }
    this.setState({ confirmPasswordError: errorMsg });
    return bool;
  }

  handleInputChange = (value,field) =>{
    this.setState({
      [field]: value
    },()=>{
      setTimeout(()=>{
        switch(field){
          case 'Email':
            this.checkInputEmail(value);
            break;

          case 'Password':
            this.checkInputPassword(value);
            break;

          case 'ConfirmPassword':
            if(value.length >= 2){
              this.checkInputConfirmPassword(value);
            }
            break;

          default:
        }
      },600);
    });
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    const {
      hidePassword,hideConfirmPassword,ResetPasswordSuccess,ResetPasswordError,isValidLink,InvalidMsg,passwordError,confirmPasswordError,Email,Password,emailError,
      ConfirmPassword,requestLoading,
    } = this.state;

    const initSkeletonPage = (
      <SkeletonPage breadcrumbs={true}>
        <Layout>
          <Layout.Section>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
        </Layout.Section>
          </Layout>
      </SkeletonPage>
    )

    const ResetPasswordSuccessBanner = ResetPasswordSuccess && (
      <Banner
        title="Password reset is successful, you can log in to Dropshipman now"
        action={{content: 'Sign in',url: '/login'}}
        tone="success"
      >
      </Banner>
    )

    const ResetPasswordErrorBanner = ResetPasswordError && (
      <Banner
        title={ResetPasswordError}
        tone="critical"
      >
      </Banner>
    )

    const InvalidDisplay = !isValidLink && (
      <Card sectioned>
        <EmptyState
          heading="Invalid link"
          action={{content: 'Sign in',url:'/login'}}
          secondaryAction={{content: 'Sign up', url: '/register'}}
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <p>{InvalidMsg}</p>
        </EmptyState>
      </Card>
    )

    const pageContent = this.state.loadingPage ? initSkeletonPage : (
      <Page
        title="Reset Password"
        subtitle="Please follow the instructions to update your personal password"
        breadcrumbs={[{content: 'Back to sign in', url: '/login'}]}
      >
        <Layout>
          <Layout.Section>
            {/* 重置密码成功提示 */}
            {ResetPasswordSuccessBanner}

            {/* 错误提示 */}
            {ResetPasswordErrorBanner}

            {/* 重置链接无效提示 */}
            {InvalidDisplay}
          </Layout.Section>

          {isValidLink && (
            <Layout.AnnotatedSection
              title="Information confirmed"
              description="Enter the account email and reset password."
            >
              <Card sectioned>
                <FormLayout>
                  <TextField
                    id="Email"
                    type="email" 
                    label="Account email" 
                    onChange={this.handleInputChange}
                    value={Email}
                    error={emailError}
                    readOnly
                  />

                  <TextField
                    id="Password"
                    type={hidePassword ? "password" : "text"}
                    label="New password" 
                    onChange={this.handleInputChange} 
                    minLength={8}
                    maxLength={24}
                    value={Password}
                    error={passwordError}
                    suffix={
                      <div className="reset_password" onClick={()=>this.handleDisplaySwitch('hidePassword')}>
                        <Icon source={hidePassword ? ViewIcon : EyeCheckMarkIcon} />
                      </div>
                    }
                  />
                  <TextField
                    id="ConfirmPassword"
                    type={hideConfirmPassword ? "password" : "text"}
                    label="Confirm password" 
                    onChange={this.handleInputChange}
                    minLength={8}
                    maxLength={24}
                    value={ConfirmPassword}
                    error={confirmPasswordError}
                    suffix={
                      <div className="reset_password" onClick={()=>this.handleDisplaySwitch('hideConfirmPassword')}>
                        <Icon source={hideConfirmPassword ? ViewIcon : EyeCheckMarkIcon} />
                      </div>
                    }
                  />
                  <Button 
                    variant='primary'
                    onClick={this.ResetPasswordSave}
                    loading={requestLoading}
                    disabled={ResetPasswordSuccess}
                  >
                    Confirm
                  </Button>
                </FormLayout>
              </Card>
            </Layout.AnnotatedSection>
          )}
        </Layout>
      </Page>
    )

    return (
      <AppProvider i18n={translations}>
        <Frame>
          
          {pageContent}
          
          {toastMarkup}

        </Frame>
      </AppProvider>
    );
  }
}

export default ResetPassword;