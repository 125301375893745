import React, { Component } from 'react';

import FrameComponents from "../../FrameComponents";

class index extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-settings-payment';
  }

  render() {
    return (
      <div>
        <FrameComponents name="settings_payment" />
      </div>
    );
  }
}

export default index;
