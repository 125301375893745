import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Layout,
  Card,
  Tabs,
  TextField,
  ButtonGroup,
  Button,
  Collapsible,
  Pagination,
  Tooltip,
  Icon,
  SkeletonBodyText,
  Spinner,
  Select,
  Toast,
  EmptyState,
  BlockStack,
  Box,
  Modal
} from "@shopify/polaris"
import {
  QuestionCircleIcon
} from '@shopify/polaris-icons'
// import TrackMessageModal from './TrackMessageModal/TrackMessageModal';
// import CustomerInfoModal from './CustomerInfoModal';
// import OrderTrackingModal from './OrderTrackingModal';
import OrderTracking from '../../DropshipmanOrders/components/OrderTracking'
import OrderShippingAddress from '../../DropshipmanOrders/components/ShippingAddress'
import {
  reqGetOrderTrackingList,
  reqGetOrderTrackingGoodInfos,
  // reqGetOrderTrackingMessages,
  // reqGetOrderTrackingMessageUpdate,
  reqGetOrderTracking
} from '../../../../api';
import utils from '../../../../utils/utils'
import { withRouter } from 'react-router-dom';
import intl from '../../../../i18n/intl'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../index.css'
import _ from 'lodash'
import dayjs from 'dayjs';
// import { store } from '../../../../store';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useMemo } from 'react';
import { useSelector} from "react-redux";
const DropshipmanOrdersTracking = (props) => {
  const [summary, setSummmary] = useState({
    under_stocking:0,
    under_quality:0,
    waiting_logistics:0,
    transit:0,
    out_for_delivery:0,
    delivered:0,
    expired:0,
    failed_attempt:0,
  })
  const language = useSelector(state => state.languageReducer.language);
  const defalutData =  useMemo(()=>{
    let data = [
      {
        id: 'tab-under-stocking',
        content: (
          <div className='remarkcontainer'>
            {intl.get("Under stocking") + '(' + 0 + ')'}
            <div className='remarkarea'>
              <Tooltip preferredPosition='above' content={intl.get("The order is in stock, estimated to take 1-3 days.")}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          </div>
        ),
        accessibilityLabel: "Under stocking",
      },
      {
        id: 'tab-under-quality-inspection',
        content: (
          <div className='remarkcontainer'>
            {intl.get("Under quality inspection") + '(' + 0 + ')'}
            <div className='remarkarea'>
              <Tooltip preferredPosition='above' content={intl.get("The order is under quality control, estimated to take 1-3 days.")}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          </div>
        ),
        accessibilityLabel: "Under quality inspection",
      },
      {
        id: 'tab-waiting-for-logistics-collection',
        content: (
          <div className='remarkcontainer'>
            {intl.get("Waiting for logistics collection") + '(' + 0 + ')'}
            <div className='remarkarea'>
              <Tooltip preferredPosition='above' content={intl.get("Order quality inspection and packing completed, waiting for logistics collection.")}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          </div>
        ),
        accessibilityLabel: "Waiting for logistics collection",
      },
      {
        id: 'tab-Transit',
        content: intl.get("Transit") + '(' + 0 + ')',
        accessibilityLabel: "Transit",
      },
      {
        id: 'tab-out-for-delivery',
        content: intl.get("Out for delivery") + '(' + 0 + ')',
        accessibilityLabel: "Out for delivery",
      },
      {
        id: 'tab-delivered',
        content: intl.get("Delivered") + '(' + 0 + ')',
        accessibilityLabel: "Delivered",
      },
      {
        id: 'tab-expired',
        content: intl.get("Expired") + '(' + 0 + ')',
        accessibilityLabel: "Expired",
      },
      {
        id: 'tab-failed-attempt',
        content: intl.get("Failed Attempt") + '(' + 0 + ')',
        accessibilityLabel: "Failed Attempt",
      }
    ]
    if (language) {
     data = [
        {
          id: 'tab-under-stocking',
          content: (
            <div className='remarkcontainer'>
              {intl.get("Under stocking") + '(' + 0 + ')'}
              <div className='remarkarea'>
                <Tooltip preferredPosition='above' content={intl.get("The order is in stock, estimated to take 1-3 days.")}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
            </div>
          ),
          accessibilityLabel: "Under stocking",
        },
        {
          id: 'tab-under-quality-inspection',
          content: (
            <div className='remarkcontainer'>
              {intl.get("Under quality inspection") + '(' + 0 + ')'}
              <div className='remarkarea'>
                <Tooltip preferredPosition='above' content={intl.get("The order is under quality control, estimated to take 1-3 days.")}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
            </div>
          ),
          accessibilityLabel: "Under quality inspection",
        },
        {
          id: 'tab-waiting-for-logistics-collection',
          content: (
            <div className='remarkcontainer'>
              {intl.get("Waiting for logistics collection") + '(' + 0 + ')'}
              <div className='remarkarea'>
                <Tooltip preferredPosition='above' content={intl.get("Order quality inspection and packing completed, waiting for logistics collection.")}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
            </div>
          ),
          accessibilityLabel: "Waiting for logistics collection",
        },
        {
          id: 'tab-Transit',
          content: intl.get("Transit") + '(' + 0 + ')',
          accessibilityLabel: "Transit",
        },
        {
          id: 'tab-out-for-delivery',
          content: intl.get("Out for delivery") + '(' + 0 + ')',
          accessibilityLabel: "Out for delivery",
        },
        {
          id: 'tab-delivered',
          content: intl.get("Delivered") + '(' + 0 + ')',
          accessibilityLabel: "Delivered",
        },
        {
          id: 'tab-expired',
          content: intl.get("Expired") + '(' + 0 + ')',
          accessibilityLabel: "Expired",
        },
        {
          id: 'tab-failed-attempt',
          content: intl.get("Failed Attempt") + '(' + 0 + ')',
          accessibilityLabel: "Failed Attempt",
        }
      ]
    }
    return data;
  },[language])
  const [tabs,setTabs] = useState(defalutData)
  const getDefalutData = (data)=>{
    setTabs([
      {
        id: 'tab-under-stocking',
        content: (
          <div className='remarkcontainer'>
            {intl.get("Under stocking") + '(' + summary.under_stocking + ')'}
            <div className='remarkarea'>
              <Tooltip preferredPosition='above' content={intl.get("The order is in stock, estimated to take 1-3 days.")}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          </div>
        ),
        accessibilityLabel: "Under stocking",
      },
      {
        id: 'tab-under-quality-inspection',
        content: (
          <div className='remarkcontainer'>
            {intl.get("Under quality inspection") + '(' + summary.under_quality + ')'}
            <div className='remarkarea'>
              <Tooltip preferredPosition='above' content={intl.get("The order is under quality control, estimated to take 1-3 days.")}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          </div>
        ),
        accessibilityLabel: "Under quality inspection",
      },
      {
        id: 'tab-waiting-for-logistics-collection',
        content: (
          <div className='remarkcontainer'>
            {intl.get("Waiting for logistics collection") + '(' + summary.waiting_logistics + ')'}
            <div className='remarkarea'>
              <Tooltip preferredPosition='above' content={intl.get("Order quality inspection and packing completed, waiting for logistics collection.")}>
                <Icon source={QuestionCircleIcon} backdrop={true} />
              </Tooltip>
            </div>
          </div>
        ),
        accessibilityLabel: "Waiting for logistics collection",
      },
      {
        id: 'tab-Transit',
        content: intl.get("Transit") + '(' + summary.transit + ')',
        accessibilityLabel: "Transit",
      },
      {
        id: 'tab-out-for-delivery',
        content: intl.get("Out for delivery") + '(' + summary.out_for_delivery + ')',
        accessibilityLabel: "Out for delivery",
      },
      {
        id: 'tab-delivered',
        content: intl.get("Delivered") + '(' + summary.delivered + ')',
        accessibilityLabel: "Delivered",
      },
      {
        id: 'tab-expired',
        content: intl.get("Expired") + '(' + summary.expired + ')',
        accessibilityLabel: "Expired",
      },
      {
        id: 'tab-failed-attempt',
        content: intl.get("Failed Attempt") + '(' + summary.failed_attempt + ')',
        accessibilityLabel: "Failed Attempt",
      }
    ])
}
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getDefalutData(defalutData);
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line
  },[defalutData]);
  const [showTrackingModal, setShowTrackingModal] = useState(false) //是否打开物流弹窗
  // const [showMessageModal, setShowMessageModal] = useState(false) //是否留言信息弹窗
  const [isLoading, setIsLoading] = useState(true) //数据是否处于加载状态
  const [goodsLoading, setGoodsLoading] = useState(true) //订单详情数据加载状态
  const [disQueryBtn, setDisQueryBtn] = useState(true) //是否禁用查询、清除按钮
  const [hasPrevious, setHasPrevious] = useState(false) //是否开启上一页
  const [hasNext, setHasNext] = useState(true) //是否开启下一页
  const [startDate, setStartDate] = useState() // 支付开始时间
  const [endDate, setEndDate] = useState()  // 支付结束时间
  const [tabSelected, setTabSelected] = useState(0) //当前tab
  const [collOpenKey, setCollOpenKey] = useState(0) //标记当前展开的是哪个coll
  const [isSpinner, setIsSpinner] = useState(false) //搜索时loading
  const [orderTrackingLoading, setOrderTrackingLoading] = useState(false) //
  const [orderNumValue, setOrderNumValue] = useState('')  //订单number
  // const [orderTrackingMsgLoading, setOrderTrackingMsgLoading] = useState(false)
  const [trackingListDataSource, setTrackingListDataSource] = useState([]) //订单追踪列表数据源
  const [orderGoodInfos, setOrderGoodInfos] = useState([]) //订单详情数据
  const [filter, setFilter] = useState('dsm');
  const [trackingModalData, setTrackingModalData] = useState(null)
  // const [messageModalData, setMessageModalData] = useState([]) //留言数据源
  // const [orderId, setOrderId] = useState('')  //当前订单Id
  const [OrderNumber, setOrderNumber] = useState('')  //当前订单编码
  const [activeToast, setActiveToast] = useState(false);
  const [toastCtx, setToastCtx] = useState('')
  const [error, setError] = useState(false)
  const [upgradeOpen,setUpgradeOpen] = useState(false);
  const pageRef = useRef(1) //当前页
  const sizeRef = useRef(50) //每页条数
  const [toPage,setToPage] = useState('')
  const [total, setTotal] = useState(0) //列表总数
  const queryStringRef = useRef({ //查询字符串
    filter: filter, //搜索类型
    search: '', //订单编号
    start: '',  //开始时间
    end: '',  //结束时间
    order_status: 'under_stocking',  //Tag切换
    page: pageRef.current, //当前页
    size: sizeRef.current, //每页条数
  })
  // const [total, setTotal] = useState(0) //列表总数
  
  const firstLoadRef = useRef(true) //是否第一次加载接口
  const toggleActive = (active) => {
    setActiveToast(!active)
  }
  
  const toastMarkup = activeToast ? (
    <Toast content={toastCtx} onDismiss={toggleActive} error={error} duration={2500} />
  ) : null;
  const router = useHistory()
  const initData = useCallback(async () => { // 数据初始化
    // if (window.under_stocking === undefined) {
    //   queryStringRef.current.render = 1
    // } else {
    //   queryStringRef.current.render = 0
    // }
    if (firstLoadRef.current) {
      queryStringRef.current.render = 1
    } else {
      queryStringRef.current.render = 0
    }
    setHasPrevious(false)
    setHasNext(false)
    
    // 首页跳转过来携带的参数
    const { query } = props.history.location
    if(Boolean(query?.num)) {
      setTabSelected(query?.num)
      switch (query.num) {
        case 3:
          queryStringRef.current.order_status = 'transit'
          break;
        case 5:
          queryStringRef.current.order_status = 'delivered'
          break;
        case 7:
          queryStringRef.current.order_status = 'failed_attempt'
          break;
        default:
          break;
      }
    }
    // let person = store.getState().languageReducer.subScriptData;
  
    // if (person?.level >= 2) {
    //   // 标识错误，直接返回
    //     setUpgradeOpen(true)
    //   // utils.routeListen('/admin/dashboard')
    //   // utils.routeJump1(router,'/admin/dashboard')
    // }
    const response = await reqGetOrderTrackingList(queryStringRef.current)
    if (response.code === '200') {
      
      setTotal(response.data.count)
      setTrackingListDataSource(response.data.data)
      
      if (firstLoadRef.current) {
        window.under_stocking = response.data.summary.under_stocking
        window.under_quality = response.data.summary.under_quality
        window.waiting_logistics = response.data.summary.waiting_logistics
        window.transit = response.data.summary.transit
        window.out_for_delivery = response.data.summary.out_for_delivery
        window.delivered = response.data.summary.delivered
        window.expired = response.data.summary.expired
        window.failed_attempt = response.data.summary.failed_attempt
      } else {
        switch (queryStringRef.current.order_status) {
          case 'under_stocking':
            window.under_stocking = response.data.count
            break;
          case 'under_quality':
            window.under_quality = response.data.count
            break;
          case 'waiting_logistics':
            window.waiting_logistics = response.data.count
            break;
          case 'transit':
            window.transit = response.data.count
            break;
          case 'out_for_delivery':
            window.out_for_delivery = response.data.count
            break;
          case 'delivered':
            window.delivered = response.data.count
            break;
          case 'expired':
            window.expired = response.data.count
            break;
          case 'failed_attempt':
            window.failed_attempt = response.data.count
            break;
          default:
            break;
        }
      }
      setSummmary((prevValue)=>({
        ...prevValue,
          under_stocking:window.under_stocking,
          under_quality:window.under_quality,
          waiting_logistics:window.waiting_logistics,
          transit:window.transit,
          out_for_delivery:window.out_for_delivery,
          delivered:window.delivered,
          expired:window.expired,
          failed_attempt:window.failed_attempt,
      }))
      setTabs([
        {
          id: 'tab-under-stocking',
          content: (
            <div className='remarkcontainer'>
              {intl.get("Under stocking") + '(' + window.under_stocking + ')'}
              <div className='remarkarea'>
                <Tooltip preferredPosition='above' content={intl.get("The order is in stock, estimated to take 1-3 days.")}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
            </div>
          ),
          accessibilityLabel: "Under stocking",
        },
        {
          id: 'tab-under-quality-inspection',
          content: (
            <div className='remarkcontainer'>
              {intl.get("Under quality inspection") + '(' + window.under_quality + ')'}
              <div className='remarkarea'>
                <Tooltip preferredPosition='above' content={intl.get("The order is under quality control, estimated to take 1-3 days.")}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
            </div>
          ),
          accessibilityLabel: "Under quality inspection",
        },
        {
          id: 'tab-waiting-for-logistics-collection',
          content: (
            <div className='remarkcontainer'>
              {intl.get("Waiting for logistics collection") + '(' + window.waiting_logistics + ')'}
              <div className='remarkarea'>
                <Tooltip preferredPosition='above' content={intl.get("Order quality inspection and packing completed, waiting for logistics collection.")}>
                  <Icon source={QuestionCircleIcon} backdrop={true} />
                </Tooltip>
              </div>
            </div>
          ),
          accessibilityLabel: "Waiting for logistics collection",
        },
        {
          id: 'tab-Transit',
          content: intl.get("Transit") + '(' + window.transit + ')',
          accessibilityLabel: "Transit",
        },
        {
          id: 'tab-out-for-delivery',
          content: intl.get("Out for delivery") + '(' + window.out_for_delivery + ')',
          accessibilityLabel: "Out for delivery",
        },
        {
          id: 'tab-delivered',
          content: intl.get("Delivered") + '(' + window.delivered + ')',
          accessibilityLabel: "Delivered",
        },
        {
          id: 'tab-expired',
          content: intl.get("Expired") + '(' + window.expired + ')',
          accessibilityLabel: "Expired",
        },
        {
          id: 'tab-failed-attempt',
          content: intl.get("Failed Attempt") + '(' + window.failed_attempt + ')',
          accessibilityLabel: "Failed Attempt",
        }
      ])
      if (pageRef.current > 1) {
        setHasPrevious(true)
      } else {
        setHasPrevious(false)
      }

      const maxPage = Math.ceil(response.data.count / sizeRef.current);
      if (pageRef.current < maxPage) {
        setHasNext(true)
      } else {
        setHasNext(false)
      }
    } else {
      setToastCtx(response.msg)
      setError(true)
    }
    setTimeout(() => {
      setIsLoading(false)
      setIsSpinner(false)
    }, 500)
    // eslint-disable-next-line
  }, [props,router])

  const getTrackModalData = async (orderId) => { // 获取订单物流追踪数据源
    const params = {
      id: orderId
    }
    const response = await reqGetOrderTracking(params)
   
    if (response.code === '200') {
      setTrackingModalData(response.data)
      setOrderTrackingLoading(false)
    } else {
      setToastCtx(response.msg)
      setError(true)
    }
  }

  // const getMessageModalData = async (orderId) => { // 获取订单物流追踪留言数据源
  //   const params = {
  //     id: orderId
  //   }
  //   const response = await reqGetOrderTrackingMessages(params)
  //   if (response.code === '200') {
  //     setMessageModalData(response.data)
  //     setOrderTrackingMsgLoading(false)
  //   } else {
  //     setToastCtx(response.msg)
  //     setError(true)
  //   }
  // }

  // const updateMessage = async (orderId) => {
  //   const params = {
  //     id: orderId
  //   }
  //   const response = await reqGetOrderTrackingMessageUpdate(params)
  //   if (response.code !== '200') {
  //     setToastCtx(response.msg)
  //     setError(true)
  //   }
  //   queryStringRef.current = { //查询字符串
  //     filter: filter, //搜索类型
  //     search: '', //订单编号
  //     start: '',  //开始时间
  //     end: '',  //结束时间
  //     order_status: queryStringRef.current.order_status,  //Tag切换
  //     page: 1, //当前页
  //     size: 50, //每页条数
  //   }
  //   firstLoadRef.current = false
  //   initData()
  // }

  useEffect(() => { // 页面加载时调用
    let isMount = false;
    
    if (!isMount) {
     
     
      firstLoadRef.current = true // 标识是首次进来当前页面，接口参数 render: 1
      setIsLoading(true)
      initData()
    }
    return ()=> isMount = true;
    // eslint-disable-next-line
  }, [])

  const handleTabChange = (tabIndex) => { //切换tab
    setIsSpinner(true)
    switch (tabIndex) {
      case 0:
        queryStringRef.current.order_status = 'under_stocking'
        break;
      case 1:
        queryStringRef.current.order_status = 'under_quality'
        break;
      case 2:
        queryStringRef.current.order_status = 'waiting_logistics'
        break;
      case 3:
        queryStringRef.current.order_status = 'transit'
        break;
      case 4:
        queryStringRef.current.order_status = 'out_for_delivery'
        break;
      case 5:
        queryStringRef.current.order_status = 'delivered'
        break;
      case 6:
        queryStringRef.current.order_status = 'expired'
        break;
      case 7:
        queryStringRef.current.order_status = 'failed_attempt'
        break;
      default:
        break;
    }
    firstLoadRef.current = false
    initData()
    setTabSelected(tabIndex)
  }

  const handleOrderNumberChange = (value) => {  //更新orderNum值
    _.isEmpty(value) ? setDisQueryBtn(true) : setDisQueryBtn(false)
    setOrderNumValue(value)
  }

  const handleLookProductInfo = async (mark, select) => { //点击订单标题展开商品信息
    if (select === undefined || !select) {
      select = true
    } else { 
      select = false
    }
    setCollOpenKey(mark)
    trackingListDataSource.forEach(item => {
      if (item.record === mark) {
        item.select = select
      } else {
        item.select = false
        setGoodsLoading(true)
      }
    })
    setTrackingListDataSource(trackingListDataSource)
    if (select) {
      setGoodsLoading(true)
      const params = {
        id: mark
      }
      const response = await reqGetOrderTrackingGoodInfos(params)
      if (response.code === '200') {
        setOrderGoodInfos(response.data)
      } else {
        setToastCtx(response.msg)
        setError(true)
      }
      setTimeout(() => {
        setGoodsLoading(false)
      }, 500)
    }
  }

  const openTrackingModal = (orderId, orderNumber) => { //点击展示物流追踪弹窗
    setOrderTrackingLoading(true)
    setOrderNumber(orderNumber)
    setShowTrackingModal(true)
    getTrackModalData(orderId)
  }

  const closeTrackingModalFn = () => {  //回调关闭物流追踪弹窗
    setShowTrackingModal(false)
  }

  // const openMessageModal = (orderId, unReadCount, orderNumber) => { //点击展示Message留言弹窗
  //   setOrderTrackingMsgLoading(true)
  //   // setOrderId(orderId)
  //   setOrderNumber(orderNumber)
  //   setShowMessageModal(true)
  //   getMessageModalData(orderId)
  //   if (unReadCount > 0) {
  //     updateMessage(orderId)
  //   }
  // }

  // const closeMessageModalFn = () => { //回调关闭Message弹窗
  //   setShowMessageModal(false)
  // }

  const handleFilterTypeChange = useCallback((value) => setFilter(value), []) // 选择搜索订单类型

  const options = [
    { label: intl.get('DSM order number'), value: 'dsm' },
    { label: intl.get('Shopify order number'), value: 'shopify' },
  ]

  const changeStartDate = (date) => { // 设置开始时间 
    date || endDate ? setDisQueryBtn(false) : setDisQueryBtn(true)
    // if (date > endDate) {
    //   return setStartDate('')
    // }
    setStartDate(date)
  }

  const changeEndDate = (date) => { //设置结束时间
    date || startDate ? setDisQueryBtn(false) : setDisQueryBtn(true)
    // if (date < startDate) {
    //   return setEndDate('')
    // }
    setEndDate(date)
  }

  const dataSearch = () => { //根据筛选条件查询数据源
    setIsSpinner(true)
    if (startDate) {
      const start_time = dayjs(startDate).format('YYYY-MM-DD')
      queryStringRef.current.start = start_time
    } else {
      queryStringRef.current.start = ''
    }
    if (endDate) {
      const end_time = dayjs(endDate).format('YYYY-MM-DD')
      queryStringRef.current.end = end_time
    } else {
      queryStringRef.current.end = ''
    }
    if (!_.isEmpty(orderNumValue)) {
      queryStringRef.current.search = orderNumValue
    }
    if (filter) {
      queryStringRef.current.filter = filter
    }
    firstLoadRef.current = false
    initData()
  }

  const clearSearch = () => { // 清空筛选条件
    setDisQueryBtn(true)
    setIsSpinner(true)
    setOrderNumValue('')
    setStartDate('')
    setEndDate('')
    setFilter('dsm')
    queryStringRef.current = { //查询字符串
      filter: 'dsm', //搜索类型
      search: '', //订单编号
      start: '',  //开始时间
      end: '',  //结束时间
      order_status: queryStringRef.current.order_status,  //Tag切换
      page: 1, //当前页
      size: 50, //每页条数
    }
    firstLoadRef.current = true
    initData()
  }

  const loadingPage = (
         <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
  )

  const EmptyOrderTrackContent = (
      <Card>
    <div className="emptyWithImg emptyOrderTrack" style={{minHeight:'600px'}}>
        <EmptyState
          heading={intl.get("No results found")}
          image="/empty.svg"
        >
        </EmptyState>
    </div>
      </Card>
  )

  //分页统计
  const getFooterContentMsg = () => {
    if (total > 0) {
      const startNum = (((pageRef.current - 1) * sizeRef.current) + 1);
      const endNum = (pageRef.current * sizeRef.current > total) ? total : pageRef.current * sizeRef.current
      return (
        <div>{intl.get(
          "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
          startNum,
          endNum,
          total,
          Math.ceil(total / 50)
        )}</div>
      )
    }
  }

  const pageContent = (
        <Layout>
          <Layout.Section>
            {/* tab区域 */}
            <Box className="content_list_tab">
              <Tabs
                tabs={tabs}
                selected={tabSelected}
                onSelect={handleTabChange}
              >
              </Tabs>
                   
                  <div className='orderTarckSearchArea'>
                    <div>
                      <Select
                        options={options}
                        onChange={handleFilterTypeChange}
                        value={filter}
                      />
                    </div>
                    <div className='orderNumInput'>
                      <TextField
                        placeholder={intl.get('Please enter order number')}
                        value={orderNumValue}
                        onChange={handleOrderNumberChange}
                        autoComplete="off"
                        maxLength={30}
                      />
                    </div>
                    <div className='order_tracking_time'>
                    <div className='Polaris-TextField startDateInput'>
                      <DatePicker
                        placeholderText='Start'
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        selected={startDate}
                        onChange={(date) => changeStartDate(date)} 
                        className='Polaris-TextField__Input'
                      />
                    </div>
                    <div className='timearrow'>
                      <img src="/timearrow.png" alt="" />
                    </div>
                    <div className='Polaris-TextField endDateInput'>
                      <DatePicker
                        placeholderText='End'
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        selected={endDate}
                        onChange={(date) => changeEndDate(date)} 
                        className='Polaris-TextField__Input'
                      />
                    </div>
                    </div>
                    <div className='searchBtn'>
                      <ButtonGroup>
                        <Button
                          variant='primary'
                          onClick={dataSearch}
                          disabled={disQueryBtn}
                        >
                          {intl.get("Search")}
                        </Button>
                        <Button
                          onClick={clearSearch}
                          disabled={disQueryBtn}
                        >
                          {intl.get("Clear")}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                   
            </Box>
            {
              isSpinner ?
                <div style={{ textAlign: 'center', marginTop:'15px',minHeight:"600px" }}>
                  <Spinner size="large" />
                </div>
                :
                (
                  trackingListDataSource.length > 0 ? (<>
                    {/* 标题展示区域 */}
                    <BlockStack gap={400}>
                    <Box style={{marginTop:16}}>
                    <Card>
                        <div className='tracking_row tracking_title'>
                          <div className='tracking_row-col-1'>
                          {intl.get("DSM order number")}
                          </div>
                          <div className='tracking_row-col-1'>
                          {intl.get("Shopify order number")}
                          </div>
                          <div className='tracking_row-col-1'>
                          {intl.get("Payment Date")}
                          </div>
                          <div className='tracking_row-col-1'>
                          {intl.get("Customer Information")}
                          </div>
                          <div className='tracking_row-col-1'>
                            {intl.get("Last update time")}
                          </div>
                          <div className='tracking_row-col-1'>
                            {intl.get("Tracking number")}
                          </div>
                          <div className='tracking_row-col-2 trackTitle'>
                            {intl.get("Tracking")}
                          </div>
                          {/* <div className='tracking_row-col-1'>
                            Message
                          </div> */}
                        </div>
                    </Card>
                    </Box>
                    {/* 数据展示区域 */}
                    {
                      trackingListDataSource.map((item, key) => {
                        return (
                          <Card key={key}>
                               
                              <div className='tracking_row'>
                                <div className='tracking_row-col-1'>
                                  <Button variant='plain'  onClick={() => handleLookProductInfo(item.record, item.select)}>{item.order_number}</Button>
                                </div>
                                <div className='tracking_row-col-1'>
                                  {item.shopify_order_number}
                                </div>
                                <div className='tracking_row-col-1'>
                                  {item.payment_date}
                                </div>
                                <div className='tracking_row-col-1'>
                                  <OrderShippingAddress
                                    id={item.record}
                                    sid={0}
                                    type={item.type}
                                    countryCode={item.customer}
                                    paymentStatus={'Paid'}
                                    reloadData={() => { }}
                                  />
                                </div>
                                <div className='tracking_row-col-1'>
                                  {item.update_time}
                                </div>
                                <div className='tracking_row-col-1'>
                                  {item.tracking_number}
                                </div>
                                <div onClick={() => openTrackingModal(item.record, item.order_number)} className='tracking_row-col-2 trackTitle'>
                                  <img src='/trackcar.svg' alt='trackcar' />
                                </div>
                                {/* <div className='messageoperation tracking_row-col-1  ' onClick={() => openMessageModal(item.record, item.unread_count, item.order_number)}>
                                  <img src='/trackinfo.svg' alt='trackinfo' />
                                  {
                                    item.unread_count > 0 && <span>{item.unread_count}</span>
                                  }
                                </div> */}
                              </div>
                               
                            <div className='collapsibleContainer'>
                              <Collapsible
                                open={
                                  (collOpenKey === item.record)
                                  &&
                                  item.select
                                }
                                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                                expandOnPrint
                              >
                                {
                                  goodsLoading ? (<div style={{ padding: '10px' }}>
                                    <Spinner size="small" />
                                  </div>) :
                                    orderGoodInfos.map((item, key) => {
                                      return <div className='collapsibleContent' key={key}>
                                        <div>
                                          <img src={item.image_url} alt="" />
                                          <div>
                                            <p>{item.title}</p>
                                            <p>{item.variant_title}</p>
                                            <p>{item.sku}</p>
                                          </div>
                                          <div className='cost'>
                                          {intl.get("Cost")}：
                                            <span>
                                              ${item.price} x {item.quantity}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    })
                                }
                              </Collapsible>
                            </div>
                          </Card>
                        )
                      })
                    }
                    </BlockStack>
                    {/* 分页区域 */}
                    <div className='pagecontainer'>
                      <Pagination
                        label={getFooterContentMsg()}
                        hasPrevious={hasPrevious}
                        onPrevious={() => {
                          pageRef.current = Number(pageRef.current) - 1
                          queryStringRef.current.page = pageRef.current

                          firstLoadRef.current = false
                          initData()
                          setToPage('')
                          if (document) {
                            if (document?.documentElement || document?.body) {
                              document.documentElement.scrollTop = document.body.scrollTop = 0;
                            }
                          }
                        }}
                        hasNext={hasNext}
                        onNext={() => {
                          pageRef.current =Number(pageRef.current) + 1
                          queryStringRef.current.page = pageRef.current

                          firstLoadRef.current = false
                          initData()
                          setToPage('')
                          if (document) {
                            if (document?.documentElement || document?.body) {
                              document.documentElement.scrollTop = document.body.scrollTop = 0;
                            }
                          }
                        }}
                      />
                         <div>
                  <input
                    className="Page_input"
                    type="text"
                    value={toPage}
                    maxLength={"8"}
                    onChange={(e) => {
                      if (Number.isInteger(Number(e.target.value))) {
                        if ((e.target.value > Math.ceil(total / 50) || e.target.value <= 0) && e.target.value !== "") {
                          setToPage(1)
                        }else{
                       setToPage(e.target.value)
                        }
                      }
                    }}
                  />
                  <span
                    className="Page_Jump"
                    onClick={() => {
                      if (Math.ceil(total / 50) === 1) return false;
                      let page = 1;
                      if (Math.ceil(total / 50) >= toPage) {
                        page = toPage;
                      }
                      if (
                        Math.ceil(total / 50) > 1 &&
                        Math.ceil(total / 50) <= toPage
                      ) {
                        page = toPage;
                      }
                      if (page === '') {
                        return
                      }
                      queryStringRef.current.page = page

                      firstLoadRef.current = false;
                      initData()
                      if (document) {
                        if (document?.documentElement || document?.body) {
                          document.documentElement.scrollTop = document.body.scrollTop = 0;
                        }
                      }
                    }}
                  >
                  {intl.get("Go to")}
                  </span>
                </div>
                    </div>
                  </>): EmptyOrderTrackContent
                )
            }
          </Layout.Section>
           {/* 物流追踪弹窗 */}
      <OrderTracking
        show={showTrackingModal} //打开
        handleShowTracking={closeTrackingModalFn} //关闭
        orderTrackingData={trackingModalData} //物流数据
        orderTrackingLoading={orderTrackingLoading}
        orderNumber={OrderNumber}
      />

      {/* 留言弹窗 */}
      {/* <TrackMessageModal
        showMessageModal={showMessageModal} //打开
        closeMessageModalFn={closeMessageModalFn} //关闭
        messageModalData={messageModalData} //留言数据
        orderNumber={OrderNumber}
        orderTrackingMsgLoading={orderTrackingMsgLoading}
      /> */}
      <div className="upgrade_new_feature">
      <Modal
        open={upgradeOpen}
        onClose={() =>{
          setUpgradeOpen(false)
          utils.routeListen('/admin/dashboard')
          utils.routeJump1(router,'/admin/dashboard')
          }}
        title={intl.get("Upgrade  your plan")}
        primaryAction={{
          content: intl.get("Upgrade Plan"),
          onAction: () => {
            setUpgradeOpen(false)
            utils.routeListen('/admin/subscribe')
            utils.routeJump1(router,'/admin/subscribe')
          },
        }}
        secondaryActions={[
          {
            content: intl.get("Cancel"),
            onAction: () => {
              setUpgradeOpen(false)
              utils.routeListen('/admin/dashboard')
              utils.routeJump1(router,'/admin/dashboard')
              },
          },
        ]}
      >
        <Modal.Section>
        <p style={{margin:"24px",fontSize:'16px'}}>{intl.get('This feature requires upgrading to a more advanced plan to access, please upgrade your plan.')}</p>
        </Modal.Section>
      </Modal>
    </div>
      {/* toast提示 */}
      {toastMarkup}
        </Layout>
  )

  return (
    <div>
      {
        isLoading ? loadingPage : pageContent
      }
    </div>
  )
}

export default withRouter(DropshipmanOrdersTracking);
