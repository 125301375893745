import {
  BlockStack,
  Box,
  Card,
  InlineStack,
  ProgressBar,
} from "@shopify/polaris";
import React from "react";

const Index = () => {
  return (
        <Card padding={200}>
        <BlockStack gap={200}>
        <BlockStack gap={200}>
          <Box style={{borderBottom: "1px solid #ebebeb",padding:'0 0 10px'}}>
            <BlockStack gap={200}>
              <InlineStack gap={500} align="space-between">
                <Box style={{ flex: 1 }}>
                  <ProgressBar size="large" />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar size="large" />
                </Box>
              </InlineStack>
              <InlineStack gap={200}>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </BlockStack>
        <Box>
          <BlockStack gap={500} >
        {list.map((item,ind)=><InlineStack key={ind} wrap={false} gap={200}>
          <Box style={{width:"20px"}}>
          <ProgressBar/>
          </Box>
          <Box style={{width:"5%"}}>
          <ProgressBar/>
          </Box>
          <Box style={{width:"30%"}}>
          <ProgressBar/>
          </Box>
          <Box style={{width:"10%"}}>
          <ProgressBar/>
          </Box>
          <Box style={{width:"10%"}}>
          <ProgressBar/>
          </Box>
          <Box style={{width:"10%"}}>
          <ProgressBar/>
          </Box>
          <Box style={{width:"10%"}}>
          <ProgressBar/>
          </Box>
          <Box style={{width:"20%"}}>
          <ProgressBar/>
          </Box>
        </InlineStack>)}
        </BlockStack>
        </Box>
        </BlockStack>
        
      </Card>
  );
};
const list = [1,2,3,4,5]

export default Index;
