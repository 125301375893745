import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonPage,
  Toast,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { NotificationIcon } from "@shopify/polaris-icons";
import "./new_dash.css";
import { reqGetNewDashboardData, reqPostBaseAction, reqPostCardDismiss } from "../../../../api";
import PropertyBox from "./PropertyBox";
import ManagerBox from "./ManagerBox";
import NewPersonBox from "./NewPersonBox";
import FulfilledOrders from "./FulfilledOrders";
import AbilityBox from "./AbilityBox";
import AppRecommendation from "../../../../components/AppRecommendation";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import utils from "../../../../utils/utils";
import TopDrawerPrizesItem from "../../BlackFriday/components/TopDrawerPrizesItem"
import { useSelector } from "react-redux";
const DashboardClass = ({blackGoModal ,blackData}) => {
  const router = useHistory();
  const [initPage, setInitPage] = useState(false); //页面初始化
  const [pageData, setPageData] = useState(); //首页数据
  const [newPerson, setNewPerson] = useState(false); //新人引导
  const [achiveBanner, setAchiveBanner] = useState(false); //成就banner
  const [hasSale, setHasSale] = useState(false); //是否展示销售
  const [isPartners, setIsPartners] = useState(false); //是否展示推荐app
  const [blackBanner,setBlackBanner] = useState(false);//是否展示黑五活动
  const plan_level = useSelector(
      (state) => state.languageReducer.subScriptData
    );
  // 获取页面数据
  const getData = async () => {
    setInitPage(true);
    let url = new URLSearchParams(window.location.search);
    let points = url.get("points")
    let params = {}
    if (points) {
      params.points = 1
    }
    let res = await reqGetNewDashboardData(params);
    if (res.code === "200") {
      setPageData(res.data);
      if (res.data?.user_guide?.show_survey) {
        window.onBoarding(plan_level)
      }
      if (res.data.reached_orders > 0) {
        setAchiveBanner(true);
      }
      if (res.data.onBoarding) {
        setNewPerson(true);
      }
      if (
        Object.values(res.data.sale_info).length > 0 ||
        Object.values(res.data.agent_info).length > 0
      ) {
        setHasSale(true);
      }
      if (res.data.app_recommend > 0) {
        setTimeout(() => {
          setIsPartners(true);
        }, 1000);
      }
      if (res.data.black_banner_pop) {
        setBlackBanner(res.data.black_banner_pop)
      }
    }
    setInitPage(false);
  };

  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getData();
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   
 const handleTopDrawer = async () => {
    let res = await reqPostBaseAction({ type: "dsm_black_top" });
    if (res.code === "200") {
      setBlackBanner(false)
    }
  };
  // 关闭banner 方法
  const handleCloseBanner = async (val) => {
    let res = await reqPostCardDismiss({type:val})
    if (res.code === '200') {
      setAchiveBanner(false);
      toastFun('Saved successfully', false)
    }else {
      toastFun('Saved failed', false)
    }
  };

  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  const toastFun = (msg, error) => {
    setToastError(error);
    setToastMsg(msg);
    setToast(false);
    toggleToast();
  };
  const toggleToast = useCallback(() => setToast((toast) => !toast), []);

  const toastMarkup = toast ? (
    <Toast content={toastMsg} onDismiss={toggleToast} error={toastError} />
  ) : null;
  const initSkeletonPage = (
    <div style={{ padding: "0 2rem" }}>
      <SkeletonPage fullWidth={false}>
        <Layout>
          <Layout.Section>
            <Card>
              <BlockStack gap={400}>
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </div>
  );

  let owerName = utils.getStore("S_SHOP_OWNER") || window.S_SHOP_OWNER;
  const pageContent = (
    <Page>
      <Box className="dashboard_page_box">
      
        {/* 标题 */}
        <Box className="dashboard_title dashboard_title_mobile">
          <Box style={{display:'flex',alignItems:'center',flexWrap:'wrap', fontWeight: 650,fontSize:'16px'}}>
          <h1 style={{fontWeight: 650,fontSize:'16px',marginRight:"8px"}}>👋 Hi {owerName !== "" ? owerName : ""}, Welcome to Dropshipman 
            <span className="dashboard_title_mobile_span"> <span className="dashboard_title_mobile_span_line" onClick={()=>{
              utils.routeListen("/admin/recommended-apps");
              utils.routeJump1(router, "/admin/recommended-apps")
              }} style={{cursor:'pointer',color:'#676BFB',fontWeight:650}} >Channelwill</span>
              &nbsp;to unlock 10x profits.</span></h1>
          
          </Box>
          
          <Box className="dashboard_title_button">
            <p onClick={()=>{ 
              utils.routeListen("/admin/messages");
              utils.routeJump1(router, "/admin/messages")}
              }>
              <Icon source={NotificationIcon} />
              <span>{pageData?.unread_count} notifications</span>
            </p>
            <p onClick={()=>{ 
              utils.routeListen("/admin/subscribe");
              utils.routeJump1(router, "/admin/subscribe")}
              }>Current Plan: {
                pageData?.level === 1 ? "✈️ PRO" : pageData?.level === 2 ? "🚀 PLUS" : pageData?.level === 4 ? "🚚 BASIC" : pageData?.level === 5 ? "🛸 PREMIUM" : pageData?.level === 3 ? "🚀 Advanced" : '🚗 FREE'
              }</p>
          </Box>
        </Box>
        <BlockStack gap={400}>
        {(!blackGoModal && (!blackData.show1 && !blackData.show2 && !blackData.open)) && blackBanner && (
              <TopDrawerPrizesItem handleTopDrawer={handleTopDrawer}/>
              )}
          {/* 横幅 */}
          {achiveBanner && (
            <Box className="dashboard_banner">
              <Banner
                hideIcon={true}
                title={`Congratulations on reaching ${pageData?.reached_orders} orders in the past 30 days!`}
                onDismiss={() => {
                  handleCloseBanner(1);
                }}
                // action={{ content: "Upgrade now", url: "/admin/subscribe" }}
              >
                <p style={{ minHeight: "40px" }}>
                  Congratulations! Your store's recent orders have reached{" "}
                  {pageData?.reached_orders}, marking a new milestone. We
                  recommend selecting Dropshipman {pageData?.recommend_plan}{" "}
                  plan to help elevate your dropshipping business to the next
                  level.
                </p>
                <Box className="dashboard_banner_button" style={{marginTop:'10px'}}>
                  <Button
                    size="slim"
                    onClick={() => {
                      utils.routeListen("/admin/subscribe");
                      utils.routeJump1(router, "/admin/subscribe");
                    }}
                  >
                    Upgrade now
                  </Button>
                </Box>
              </Banner>
            </Box>
          )}
          {/* 订单统计 */}
          {pageData?.dsm_paid > 0 && (
            <Box className="dashboard_order_statistics">
              <FulfilledOrders />
            </Box>
          )}
          {/* 新人引导模块 */}
          {newPerson && (
            <Box className="dashboard_new_person">
              <NewPersonBox
                router={router}
                toastFun={toastFun}
                data={pageData?.user_guide}
                newPerson={newPerson}
                setNewPerson={setNewPerson}
                setPageData={setPageData}
              />
            </Box>
          )}
          {/* 资产卡片 */}
          <Box className="dashboard_property">
            <PropertyBox pageData={pageData} router={router} />
          </Box>
          {/* 销售或者Agent角色 */}
          {hasSale && (
            <Box className="dashboard_sale">
              <ManagerBox pageData={pageData} toastFun={toastFun} handleCloseBanner={handleCloseBanner} setHasSale={setHasSale}/>
            </Box>
          )}

          {/* 用户分层 功能卡片 */}
          <Box className="dashboard_user_layer">
            <AbilityBox
              router={router}
              data={pageData}
              toastFun={toastFun}
              setPageData={setPageData}
            />
          </Box>
          {/* 推荐APP列表 */}
          {isPartners ? (<Box className="dashboard_recommend_app">
              <AppRecommendation
                show={isPartners}
                setIsPartners={() => setIsPartners(false)}
                history={router}
              />
          </Box>): '' }
         
        </BlockStack>
      </Box>
    </Page>
  );
  return (
    <div>
      {initPage ? initSkeletonPage : pageContent}
      {toastMarkup}
    </div>
  );
};

export default DashboardClass;
