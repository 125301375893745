import {
  Box,
  Text,
  IndexTable,
  useIndexResourceState,
  Icon,
  InlineStack,
  Tooltip,
  Thumbnail,
  TextField,
  EmptyState,
} from "@shopify/polaris";
import { CalculatorIcon, EditIcon, NoteIcon} from "@shopify/polaris-icons";

import _ from "lodash";
import React, { useEffect, useMemo,  useState } from "react";
import ChangeVariantModal from "../../modal/ChangeVariantModal";
import ChangeComparePriceModal from "../../modal/ChangeComparePriceModal";
import ChangeSellingPriceModal from "../../modal/ChangeSellingPriceModal";
import ChangeVariantAttributeModal from "../../modal/ChangeVariantAttributeModal";
import DeleteProductVariantModal from "../../modal/DeleteProductVariantModal";
import ProductShippingMethodsModal from "../../modal/ProductShippingMethodsModal";
const Index = ({ detailData, setDetailData, setIsInput,toastFun, shippingMethodData,
  setShippingMethodData }) => {
  const [currentSort, setCurrentSort] = useState("descending");
  // 修改表头值
  const [headData, setHeadData] = useState({
    open: false,
    attr: "",
    option1: "",
    option2: "",
    option3: "",
  });
  const [filteredData, setFilteredData] = useState([]); // 当前筛选后的数据
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
  useIndexResourceState(filteredData);
  const handleHeadDataChange = (opt, value) => {
    setHeadData({
      ...headData,
      attr: value,
      [opt]: value,
      open: true,
    });
  };
  let heading = [
    { id: "1", title: <span>{"SKU"}</span> },

    {
      id: "2",
      title: <span>{"Selling price"}</span>,
    },
    {
      id: "4",
      title: <span>{"Compared price"}</span>,
    },
    {
      id: "5",
      title: <span>{"Inventory"}</span>,
      defaultSortDirection:
        currentSort === "descending" ? "descending " : "ascending",
    },
    { id: "6", title: <span>{"Profit"}</span> },
    { id: "7", title: <span>{"Cost"}</span> },
    { id: "8", title: <InlineStack gap={100} blockAlign="center" wrap="nowrap">
    <span>{"Shipping"}</span>
    <span
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (detailData?.product?.is_publish) {
          return
        }
        handleShippingFeeChange();
      }}
    >
      <Icon source={EditIcon} />
    </span>
  </InlineStack> },
  ];

  let bulkActions = [
    {
      content: "Change Compared price",
      onAction: () => handleComparedPriceChange("compared_price"),
    },
  ];
  if (heading.length === 7) {
    const data = detailData?.product?.options;
    if (data.option_one.name !== "") {
      heading = [
        { id: "1", title: <span>{"SKU"}</span> },

        {
          id: "31",
          title: (
            <InlineStack gap={100} blockAlign="center" wrap="nowrap">
              <span className="wrap_head_text">{data.option_one.name}</span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (detailData?.product?.is_publish) {
                    return
                  }
                  handleHeadDataChange("option1", data.option_one.name);
                }}
              >
                <Icon source={EditIcon} />
              </span>
            </InlineStack>
          ),
        },
        {
          id: "2",
          title: <span>{"Selling price"}</span>,
        },
        {
          id: "4",
          title: <span>{"Compared price"}</span>,
        },
        {
          id: "5",
          title: <span>{"Inventory"}</span>,
          defaultSortDirection:
            currentSort === "descending" ? "descending " : "ascending",
        },
        { id: "6", title: <span>{"Profit"}</span> },
        { id: "7", title: <span>{"Cost"}</span> },
        { id: "8", title:<InlineStack gap={100} blockAlign="center" wrap="nowrap">
        <span>{"Shipping"}</span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (detailData?.product?.is_publish) {
              return
            }
            handleShippingFeeChange();
          }}
        >
          <Icon source={EditIcon} />
        </span>
      </InlineStack>  },
      ];
      bulkActions = [
        {
          content: "Change " + data.option_one.name,
          onAction: () => handleAttributeChange("option_one"),
        },
        {
          content: "Change Compared price",
          onAction: () => handleComparedPriceChange("compared_price"),
        },
      ];
    }
    if (data.option_two.name !== "") {
      heading = [
        { id: "1", title: <span>{"SKU"}</span> },

        {
          id: "31",
          title: (
            <InlineStack
              gap={100}
              blockAlign="center"
              wrap="nowrap"
            >
              <span  className="wrap_head_text">{data.option_one.name}</span>
              <span style={{ cursor: "pointer" }} onClick={() => {
                if (detailData?.product?.is_publish) {
                  return
                }
                handleHeadDataChange("option1", data.option_one.name);
              }}>
                <Icon source={EditIcon} />
              </span>
            </InlineStack>
          ),
        },
        {
          id: "32",
          title: (
            <InlineStack gap={100} blockAlign="center" wrap="nowrap">
              <span  className="wrap_head_text">{data.option_two.name}</span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (detailData?.product?.is_publish) {
                    return
                  }
                  handleHeadDataChange("option2", data.option_two.name);
                }}
              >
                <Icon source={EditIcon} />
              </span>
            </InlineStack>
          ),
        },
        {
          id: "2",
          title: <span>{"Selling price"}</span>,
        },
        {
          id: "4",
          title: <span>{"Compared price"}</span>,
        },
        {
          id: "5",
          title: <span>{"Inventory"}</span>,
          defaultSortDirection:
            currentSort === "descending" ? "descending " : "ascending",
        },
        { id: "6", title: <span>{"Profit"}</span> },
        { id: "7", title: <span>{"Cost"}</span> },
        { id: "8", title: <InlineStack gap={100} blockAlign="center" wrap="nowrap">
        <span>{"Shipping"}</span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (detailData?.product?.is_publish) {
              return
            }
            handleShippingFeeChange();
          }}
        >
          <Icon source={EditIcon} />
        </span>
      </InlineStack> },
      ];
      bulkActions = [
        {
          content: "Change " + data.option_one.name,
          onAction: () => handleAttributeChange("option_one"),
        },
        {
          content: "Change " + data.option_two.name,
          onAction: () => handleAttributeChange("option_two"),
        },
        {
          content: "Change Compared price",
          onAction: () => handleComparedPriceChange("compared_price"),
        },
      ];
    }
    if (data.option_three.name !== "") {
      heading = [
        { id: "1", title: <span>{"SKU"}</span> },

        {
          id: "31",
          title: (
            <InlineStack gap={100} blockAlign="center" wrap="false">
              <span  className="wrap_head_text">{data.option_one.name}</span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (detailData?.product?.is_publish) {
                    return
                  }
                  handleHeadDataChange("option1", data.option_one.name);
                }}
              >
                <Icon source={EditIcon} />
              </span>
            </InlineStack>
          ),
        },
        {
          id: "32",
          title: (
            <InlineStack gap={100} blockAlign="center" wrap="false">
              <span className="wrap_head_text">{data.option_two.name}</span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (detailData?.product?.is_publish) {
                    return
                  }
                  handleHeadDataChange("option2", data.option_two.name);
                }}
              >
                <Icon source={EditIcon} />
              </span>
            </InlineStack>
          ),
        },
        {
          id: "33",
          title: (
            <InlineStack gap={100} blockAlign="center" wrap="false">
              <span className="wrap_head_text">{data.option_three.name}</span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (detailData?.product?.is_publish) {
                    return
                  }
                  handleHeadDataChange("option3", data.option_three.name);
                }}
              >
                <Icon source={EditIcon} />
              </span>
            </InlineStack>
          ),
        },
        {
          id: "2",
          title: <span>{"Selling price"}</span>,
        },
        {
          id: "4",
          title: <span>{"Compared price"}</span>,
        },
        {
          id: "5",
          title: <span>{"Inventory"}</span>,
          defaultSortDirection:
            currentSort === "descending" ? "descending " : "ascending",
        },
        { id: "6", title: <span>{"Profit"}</span> },
        { id: "7", title: <span>{"Cost"}</span> },
        { id: "8", title: <InlineStack gap={100} blockAlign="center" wrap="nowrap">
        <span>{"Shipping"}</span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (detailData?.product?.is_publish) {
              return
            }
            handleShippingFeeChange();
          }}
        >
          <Icon source={EditIcon} />
        </span>
      </InlineStack> },
      ];
      bulkActions = [
        {
          content: "Change " + data.option_one.name,
          onAction: () => handleAttributeChange("option_one"),
        },
        {
          content: "Change " + data.option_two.name,
          onAction: () => handleAttributeChange("option_two"),
        },
        {
          content: "Change " + data.option_three.name,
          onAction: () => handleAttributeChange("option_three"),
        },
        {
          content: "Change Compared price",
          onAction: () => handleComparedPriceChange("compared_price"),
        },
      ];
    }
  }

  const sortData = (text) => {
    setIsInput(true);
    if (text === "descending") {
      let sortedVariants1 = [...detailData.variants].sort(
        (a, b) => b.inventory - a.inventory
      );
      setDetailData((prev) => ({
        ...prev,
        variants: sortedVariants1,
      }));
    } else {
      let sortedVariants2 = [...detailData.variants].sort(
        (a, b) => a.inventory - b.inventory
      );
      setDetailData((prev) => ({
        ...prev,
        variants: sortedVariants2,
      }));
    }
  };
  // 打开表头编辑
  const handleSortHeading = (index, direction) => {
    if (detailData?.product?.is_publish) {
      return
    }
    if (index === 4 || index === 5 || index === 6) {
      let text = currentSort === "descending" ? "ascending" : "descending";
      sortData(text);
      setCurrentSort(text);
    }
  };
  const [selectedTags, setSelectedTags] = useState([]); // 当前选中的标签
// 使用 useMemo 缓存计算后的 filteredData，避免不必要的 effect 执行
const filteredDataMemo = useMemo(() => {
  if (!detailData?.variants) return [];
  
  if (selectedTags.includes('all_variants_+') || selectedTags.length === 0) {
    return detailData.variants;
  }

  return detailData.variants.filter(item => {
    const itemTags = new Set([item.option_one, item.option_two, item.option_three]);
    return selectedTags.some(t => itemTags.has(t));
  });
}, [detailData?.variants, selectedTags]);

// 将 filteredData 状态提升到父组件或用 useMemo 替代
// 如果必须用 state，可以这样同步：
useEffect(() => {
  setFilteredData(filteredDataMemo);
}, [filteredDataMemo]);

// 单独处理 tag list 的更新
useEffect(() => {
  if (detailData?.variants) {
    getTagList(detailData.variants);
  }
}, [detailData?.variants]);
  
  const [tagsList, setTagsList] = useState([]); //标签列表
 
  
  // const getTagList = (data) => {
  //   // 为每个选项创建独立的容器
  //   const output = {
  //     option_one: [],
  //     option_two: [],
  //     option_three: [],
  //   };

  //   // 遍历数据填充容器
  //   _.forEach(data, (item) => {
  //     if (item.option_one) {
  //       output.option_one.push({ field: "option_one", name: item.option_one });
  //     }
  //     if (item.option_two) {
  //       output.option_two.push({ field: "option_two", name: item.option_two });
  //     }
  //     if (item.option_three) {
  //       output.option_three.push({
  //         field: "option_three",
  //         name: item.option_three,
  //       });
  //     }
  //   });

  //   // 对每个选项独立去重
  //   const uniqueLists = _.mapValues(output, (list) => _.uniqBy(list, "name"));

  //   // 合并结果并保持选项顺序
  //   const combinedList = [
  //     ...uniqueLists.option_one,
  //     ...uniqueLists.option_two,
  //     ...uniqueLists.option_three,
  //   ];
  //   console.log(combinedList);
  //   //    let arr = _.sortBy(combinedList, "option");
  //   // let arr2 = selectedTags
  //   // const nameSet = new Set(arr.map(item => item.name));
  //   // const arr3 = arr2.filter(name => nameSet.has(name));
  //   setSelectedTags(arr3)
  //   setTagsList(_.sortBy(combinedList, "option")); // 输出结果再去重
  // };

  const getTagList = (data) => {
    const optionsOrder = ['option_one', 'option_two', 'option_three'];
    
    // 使用对象收集各选项数据
    const optionsData = optionsOrder.reduce((acc, option) => {
        acc[option] = [];
        return acc;
    }, {});

    // 遍历数据填充各选项
    _.forEach(data, (item) => {
        optionsOrder.forEach(option => {
            const value = item[option];
            value && optionsData[option].push({ field: option, name: value });
        });
    });

    // 去重处理并保持原始顺序
    const uniqueOptionsData = _.mapValues(optionsData, list => 
        _.uniqBy(list, 'name')
    );

    // 按原始选项顺序合并列表
    const combinedList = optionsOrder.flatMap(option => uniqueOptionsData[option]);

    // 过滤有效选中标签并更新状态
    const validNames = new Set(combinedList.map(item => item.name));
    setSelectedTags(prev => prev.filter(name => validNames.has(name)));
    setTagsList(combinedList);
};

  // 处理标签点击事件
  const handleTagClick = (tag) => {
    let list = detailData?.variants;
    handleSelectionChange("page", false, 0);
  
    // 处理 All 标签的特殊情况
    if (tag === 'all_variants_+') {
      if (selectedTags.includes('all_variants_+')) {
        setSelectedTags([]);
        // handleSelectionChange("page", false, 0);
      }else {
        // handleSelectionChange("all", true);
        setSelectedTags(['all_variants_+']);
      }
      setFilteredData(list);
      return;
    }
    let selectedTagList = selectedTags.filter(item => item !== 'all_variants_+')
    // 检查当前标签是否已选中
    const isSelected = selectedTagList.includes(tag);
  
    // 创建新的选中数组
    let newSelectedTags = isSelected
      ? selectedTagList.filter(t => t !== tag)  // 移除标签
      : [...selectedTagList, tag];              // 添加标签
    
    setSelectedTags(newSelectedTags);
    // 根据新选中的标签过滤数据
    if (newSelectedTags.length === 0) {
      setFilteredData(list);
    } else {
      
      const filtered = list.filter(item =>{ 
        const itemTags = new Set([
          item.option_one,
          item.option_two,
          item.option_three
        ]);
        // 检查所有选中标签是否都存在于当前项的标签中
        return newSelectedTags.some(t => itemTags.has(t));
        // newSelectedTags.includes(item.option_one  || item.option_two  || item.option_three)
      });
      setFilteredData(filtered);
      // handleSelectionChange("all", true);
    }
    
  };
  
  
  // 更新筛选数据的增删改操作子项
  const updateData = (ids, updatedItem) => {
    let list = detailData?.variants;
    setIsInput(true);
    if (ids === "all") {
      // 当应用所有变体时
      const updatedFilteredData = filteredData.map((item) => {
        return { ...item, ...updatedItem };
      });
      setFilteredData(updatedFilteredData);
      const updatedVariant = list.map((item) => {
        return { ...item, ...updatedItem };
      });
      setDetailData((prev) => ({
        ...prev,
        variants: updatedVariant,
      }));
      // setSelectedTags([])
      handleSelectionChange("page", false, 0);
      return;
    }

    if (selectedTags.length > 0 || ids.length > 0) {
      const updatedFilteredData = filteredData.map((item) =>
        ids.includes(item.id) ? { ...item, ...updatedItem } : item
      );
      setFilteredData(updatedFilteredData);
    }

    // 同步更新原数组
    // 如果有筛选标签，重新应用筛选
    const updatedVariant = list.map((item) =>
      ids.includes(item.id) ? { ...item, ...updatedItem } : item
    );
    setDetailData((prev) => ({
      ...prev,
      variants: updatedVariant,
    }));
    // setSelectedTags([])
    handleSelectionChange("page", false, 0);
  };
  // 更新表头
  const updateHeadData = (type, updatedItem) => {
    let list = detailData?.product?.options;
    if (type === "option1") {
      heading = [
        { id: "1", title: <span>{"SKU"}</span> },

        {
          id: "31",
          title: (
            <Tooltip content="data.option_one.name">
              <span>{updatedItem.option1}</span>
            </Tooltip>
          ),
        },
        {
          id: "2",
          title: <span>{"Selling price"}</span>,
        },
        {
          id: "4",
          title: <span>{"Compared price"}</span>,
        },
        {
          id: "5",
          title: <span>{"Inventory"}</span>,
        },
        { id: "6", title: <span>{"Profit"}</span> },
        { id: "7", title: <span>{"Cost"}</span> },
        { id: "8", title: <span>{"Shipping"}</span> },
      ];
      list.option_one.name = updatedItem.option1;
    }
    if (type === "option2") {
      heading = [
        { id: "1", title: <span>{"SKU"}</span> },

        {
          id: "31",
          title: (
            <Tooltip content="data.option_one.name">
              <span>{list.option_one.name}</span>
            </Tooltip>
          ),
        },
        {
          id: "32",
          title: (
            <Tooltip content="data.option_two.name">
              <span>{updatedItem.option2}</span>
            </Tooltip>
          ),
        },
        {
          id: "2",
          title: <span>{"Selling price"}</span>,
        },
        {
          id: "4",
          title: <span>{"Compared price"}</span>,
        },
        {
          id: "5",
          title: <span>{"Inventory"}</span>,
        },
        { id: "6", title: <span>{"Profit"}</span> },
        { id: "7", title: <span>{"Cost"}</span> },
        { id: "8", title: <span>{"Shipping"}</span> },
      ];
      list.option_two.name = updatedItem.option2;
    }
    if (type === "option3") {
      heading = [
        { id: "1", title: <span>{"SKU"}</span> },

        {
          id: "31",
          title: (
            <Tooltip content="data.option_one.name">
              <span>{list.option_one.name}</span>
            </Tooltip>
          ),
        },
        {
          id: "32",
          title: (
            <Tooltip content="data.option_two.name">
              <span>{list.option_two.name}</span>
            </Tooltip>
          ),
        },
        {
          id: "33",
          title: (
            <Tooltip content="data.option_three.name">
              <span>{updatedItem.option3}</span>
            </Tooltip>
          ),
        },
        {
          id: "2",
          title: <span>{"Selling price"}</span>,
        },
        {
          id: "4",
          title: <span>{"Compared price"}</span>,
        },
        {
          id: "5",
          title: <span>{"Inventory"}</span>,
        },
        { id: "6", title: <span>{"Profit"}</span> },
        { id: "7", title: <span>{"Cost"}</span> },
        { id: "8", title: <span>{"Shipping"}</span> },
      ];
      list.option_three.name = updatedItem.option3;
    }
    const updatedFilteredData = { ...list };
    // 同步更新原数组
    // 如果有筛选标签，重新应用筛选
    setIsInput(true);
    setDetailData((prev) => ({
      ...prev,
      options: updatedFilteredData,
    }));
  };

  // 修改属性值
  const [attributeData, setAttributeData] = useState({
    open: false,
    attr: "",
  });
  const handleAttributeChange = (value) => {
    setAttributeData({
      ...attributeData,
      attr: value,
      open: true,
    });
  };

  // 修改对比价格
  const [comparedPriceData, setComparedPriceData] = useState({
    open: false,
    attr: "",
  });
  const handleComparedPriceChange = (value) => {
    setComparedPriceData({
      ...comparedPriceData,
      attr: value,
      open: true,
    });
  };
  // 修改销售价格

  const [salePriceData, setSalePriceData] = useState({
    open: false,
    id: "",
    attr: "",
    shipping_fee: "",
    cost: "",
    selling_price: "",
    samrt_price: "",
  });
  const handleSalePriceChange = (value) => {
    if (selectedResources.length === 1) {
      let data = detailData.variants.find(
        (item) => item.id === selectedResources[0]
      );
      setSalePriceData({
        id: data.id,
        attr: value,
        shipping_fee: data.shipping_fee,
        cost: data.before_convert_cost,
        selling_price: data.price,
        samrt_price: "",
        open: true,
      });
    } else {
      setSalePriceData((prev)=>({
        ...prev,
        attr: value,
        open: true,
      }));
    }
  };

  // 删除变体
  const [deleteVariantData, setDeleteVariantData] = useState({
    open: false,
    id: [],
  });
  const handleDeleteVariant = () => {
    if (selectedResources.length === 0) {
      return;
    }
    if (selectedResources.length === detailData.variants.length) {
      toastFun('At least 1 variant')
      return
    }
    setDeleteVariantData((prev)=>({
      ...prev,
      open: true,
      id: selectedResources,
    }))
  }


  // 物流方式选择修改
  const [shippingFeeData, setShippingFeeData] = useState({
    open: false,
    
  });
  const handleShippingFeeChange = (value) => {
    setShippingFeeData({
      open: true,
    });
  }

  // 修改对比价格

  // 更新列表项修改
  const onChangeVariantList = (type, value, ids) => {
    

    if (type === "option1" || type === "option2" || type === "option3") {
      if (value.length > 30) {
        return;
      }
      let opt = "option_one";
      if (type === "option2") {
        opt = "option_two";
      }
      if (type === "option3") {
        opt = "option_three";
      }
      updateData(ids, { [opt]: value });
      return;
    }
    if (value.length > 15) {
      return;
    }
    const regex = /^$|^\d+(\.\d{0,2})?$/;

    // 仅允许符合正则的值更新状态
    if (!(regex.test(value) || value === "")) {
      return;
    }
    updateData(ids, { [type]: value });
  };

  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const promotedBulkActions = [
    {
      content: "Delete variant",
      destructive:true,
      onAction: () => handleDeleteVariant(),
    },
    {
      content: "Change selling price",
      onAction: () => handleSalePriceChange("selling price"),
    },
  ];

  

  const currentShowAttribute = (item) => {
    const data = detailData?.product?.options;
    let node = <IndexTable.Cell></IndexTable.Cell>;
    let currentShow = [];
    if (data.option_one.name !== "") {
      currentShow.push(data.option_one.name);
    }
    if (data.option_two.name !== "") {
      currentShow.push(data.option_one.name);
    }
    if (data.option_three.name !== "") {
      currentShow.push(data.option_one.name);
    }

    if (currentShow.length > 0) {
      node = currentShow.map((it, ind) => {
        let value = "";
        if (ind === 0) {
          value = item.option_one;
        } else if (ind === 1) {
          value = item.option_two;
        } else if (ind === 2) {
          value = item.option_three;
        }
        return (
          <IndexTable.Cell key={ind} className={"variant_cell_attribute"}>
            <TextField
              error={value === ""}
              value={value}
              onChange={(value) => {
                onChangeVariantList("option" + (ind + 1), value, [item.id]);
              }}
              autoComplete="off"
            />
          </IndexTable.Cell>
        );
      });
    }
    return node;
  };
  const sortableFun = () => {
    const data = detailData?.product?.options;
    let arr = [];
    if (data.option_one.name !== "") {
      arr = [false, false, false, false, true, false, false];
    }
    if (data.option_two.name !== "") {
      arr = [false, false, false, false, false, true, false, false];
    }
    if (data.option_three.name !== "") {
      arr = [false, false, false, false, false, false, true, false, false];
    }
    return arr;
  };
  
  const rowMarkup = filteredData.map((item, ind) => (
    <IndexTable.Row
      id={item.id}
      key={ind}
      selected={selectedResources.includes(item.id)}
      position={ind}
      disabled={detailData?.product?.is_publish}
      onClick={() => {}}
    >
      <IndexTable.Cell className="variant_cell_01">
        <Box className="variant_cell_sku">
          <Thumbnail
            source={item.image_url ? item.image_url : NoteIcon}
            alt={item.image_alt}
          />
          <p>{item.sku}</p>
        </Box>
      </IndexTable.Cell>
      {currentShowAttribute(item)}
      <IndexTable.Cell className="variant_cell_02">
        <Box className="variant_cell_selling">
          <TextField
            prefix={detailData.store.symbol}
            error={item.price === ""}
            value={item.price}
            onChange={(value) => {
              if (detailData?.product?.is_publish) {
                return
              }
              onChangeVariantList("price", value, [item.id]);
            }}
            onBlur={() => {
              if (item.price === "") {
                if (detailData?.product?.is_publish) {
                  return
                }
                onChangeVariantList("price", 0, [item.id]);
              }
            }}
            autoComplete="off"
          />
          <Box
            className="suffix_calculatorIcon"
            onClick={() => {
              if (detailData?.product?.is_publish) {
                return
              }
              setSalePriceData((prev)=>({
                ...prev,
                attr: "selling price",
                selling_price: item.price,
                shipping_fee: item.shipping_fee,
                cost:detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost,
                samrt_price: item.recommend_price,
                id: item.id,
                open: true,
              }));
            }}
          >
            <Tooltip content="Change Selling price with calculator">
            <Icon source={CalculatorIcon} />
            </Tooltip>
          </Box>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="variant_cell_03">
        <TextField
          error={item.compared_price === ""}
          value={item.compared_price}
          onChange={(value) => {
            if (detailData?.product?.is_publish) {
              return
            }
            onChangeVariantList("compared_price", value, [item.id]);
          }}
          onBlur={() => {
            if (detailData?.product?.is_publish) {
              return
            }
            if (item.compared_price === "") {
              onChangeVariantList("compared_price", 0, [item.id]);
            }
          }}
          prefix={detailData.store.symbol}
          autoComplete="off"
        />
      </IndexTable.Cell>
      <IndexTable.Cell className="variant_cell_04">
        <TextField
          disabled={true}
          value={item.inventory}
          onChange={() => {}}
          autoComplete="off"
        />
      </IndexTable.Cell>
      <IndexTable.Cell className="variant_cell_05">
        <Box
          className={
            calculateProfit({
              selling_price: item.price,
              cost: detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost,
              shipping_fee: item.shipping_fee,
            }) > 0
              ? "color_profit"
              : calculateProfit({
                  selling_price: item.price,
                  cost: detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost,
                  shipping_fee: item.shipping_fee,
                }) < 0
              ? "color_loss"
              : ""
          }
        >
          <TextField
            // prefix={detailData.store.symbol}
            disabled={true}
            value={
              calculateProfit({
                selling_price: item.price,
                cost:detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost,
                shipping_fee: item.shipping_fee,
              }) > 0
                ? detailData.store.symbol +
                  calculateProfit({
                    selling_price: item.price,
                    cost: detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost,
                    shipping_fee: item.shipping_fee,
                  })
                : calculateProfit({
                  selling_price: item.price,
                  cost: detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost,
                  shipping_fee: item.shipping_fee,
                }) <0 ? '-'+ detailData.store.symbol +  (-calculateProfit({
                  selling_price: item.price,
                  cost: detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost,
                  shipping_fee: item.shipping_fee,
                })) : detailData.store.symbol + calculateProfit({
                    selling_price: item.price,
                    cost: detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost,
                    shipping_fee: item.shipping_fee,
                  })
            }
            onChange={() => {}}
            autoComplete="off"
          />
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className="variant_cell_06">
        <TextField
          disabled={true}
          prefix={detailData.store.symbol}
          value={detailData.store.currencyCode === 'USD' ? item.before_convert_cost : item.cost}
          onChange={() => {}}
          autoComplete="off"
        />
      </IndexTable.Cell>
      <IndexTable.Cell className="variant_cell_07">
        <TextField
          disabled={true}
          prefix={detailData.store.symbol}
          value={item.shipping_fee ? item.shipping_fee : '-'}
          onChange={() => {}}
          autoComplete="off"
        />
      </IndexTable.Cell>
    </IndexTable.Row>
  ));
  
  return (
    <Box className="product_variant_content">
      <Box className="product_variant_content_top">
        <Text variant="headingSm" as="h6">
          Price & Variants
        </Text>
        <Box className="product_quickly_select">
          <Box className="quickly_select">Quickly Select</Box>
          <Box className="quickly_select_content">
            <InlineStack gap={100}>
              <span
                className={
                  selectedTags[0] === "all_variants_+"
                    ? "quickly_select_sp act_sp"
                    : "quickly_select_sp"
                }
                onClick={() => handleTagClick("all_variants_+")}
              >
                All
              </span>
              {_.map(tagsList, (tag, index) => (
                <span
                  className={
                    selectedTags.includes(tag.name)
                      ? "quickly_select_sp act_sp"
                      : "quickly_select_sp"
                  }
                  onClick={() => handleTagClick(tag.name)}
                  key={index}
                >
                  {tag.name}
                </span>
              ))}
            </InlineStack>
          </Box>
        </Box>
      </Box>
      <Box className="product_variant_table">
          <IndexTable
            resourceName={resourceName}
            itemCount={filteredData.length}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            sortable={sortableFun()}
            selectable={detailData?.product?.is_publish === 0}
            onSort={handleSortHeading}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            onSelectionChange={handleSelectionChange}
            headings={heading}
            emptyState={<EmptyState
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
              <p>Not found result.</p>
            </EmptyState>}
            defaultSortDirection="descending"
          >
            {rowMarkup}
          </IndexTable>
          
      </Box>
      {/* 修改表头 */}
      <ChangeVariantAttributeModal
        headData={headData}
        setHeadData={setHeadData}
        updateHeadData={updateHeadData}
      />
      {/* 修改变体 */}
      <ChangeVariantModal
        attributeData={attributeData}
        onSelectionChange={handleSelectionChange}
        setAttributeData={setAttributeData}
        selectedResources={selectedResources}
        updateData={updateData}
      />
      {/* 修改对比价格 */}
      <ChangeComparePriceModal
        comparedPriceData={comparedPriceData}
        setComparedPriceData={setComparedPriceData}
        onSelectionChange={handleSelectionChange}
        selectedResources={selectedResources}
        updateData={updateData}
        detailData={detailData}
      />
      {/* 修改销售价格 */}
      <ChangeSellingPriceModal
        salePriceData={salePriceData}
        detailData={detailData}
        setSalePriceData={setSalePriceData}
        onSelectionChange={handleSelectionChange}
        selectedResources={selectedResources}
        updateData={updateData}
      />
      {/* 删除变体 */}
      <DeleteProductVariantModal 
      deleteVariantData={deleteVariantData} 
      setDeleteVariantData={setDeleteVariantData}
      handleSelectionChange={handleSelectionChange}
      detailData={detailData}
      setIsInput={setIsInput}
      selectedResources={selectedResources}
      setFilteredData={setFilteredData}
      setDetailData={setDetailData}
      toastFun={toastFun}
      />

      {/* 物流方式选择 */}
      {shippingFeeData.open && <ProductShippingMethodsModal 
      shippingFeeData={shippingFeeData} 
      handleSelectionChange={handleSelectionChange}
      shippingMethodData={shippingMethodData}
      setShippingMethodData={setShippingMethodData}
      setShippingFeeData={setShippingFeeData}
      detailData={detailData}
      setIsInput={setIsInput}
      setDetailData={setDetailData}
      toastFun={toastFun}
      />}
      
    </Box>
  );
};
const calculateProfit = (salePriceData) => {
  // 转换为浮点数并设置默认值
  const sellingPrice = parseFloat(salePriceData.selling_price) || 0;
  const cost = parseFloat(salePriceData.cost) || 0;
  const shippingFee = parseFloat(salePriceData.shipping_fee) || 0;

  // 计算结果并保留两位小数
  const profit = sellingPrice - cost - shippingFee;
  return profit.toFixed(2);
};
export default Index;
