import { Modal, RadioButton } from "@shopify/polaris";
import React, { useEffect,useState }  from "react";

const ShippingMethodsModal = ({shippingMethodData,setShippingMethodData,shippingModal,setShippingModal,detailData,setDetailData}) => {
  const [shippingList,setShippingList] = useState(shippingMethodData)// 当前默认数据
  const handleChange = (recordKey) => {
   // 创建新的数组来更新状态
   const updatedList = shippingList.map((item, index) => ({
    ...item, // 保持原有属性
    selected: index === recordKey ? 1 : 0, // 选中当前项，取消其他项的选中状态
  }));
  if (detailData || setDetailData) {
    let ship_list = detailData?.store?.currencyCode === 'USD' ? updatedList.filter(item=>item.selected)[0]?.BeforeConvertCost : updatedList.filter(item=>item.selected)[0]?.Cost ;
  let ship_service = updatedList.filter(item=>item.selected)[0]?.service_name;
  setDetailData((prevValue) => {
    let list = prevValue.variants.map((item,ind)=>{
      return {
        ...item,
        shipping_fee: ship_list
      }
    });
    return {
      ...prevValue,
      variants:list,
      shipping: {
        ...prevValue.shipping,
        service_name:ship_service,
      },
    }
  });
  }
  
  setShippingList(updatedList); // 更新状态
  };
  useEffect(()=>{
    let isMount = false;
    if (!isMount && shippingModal.open) {
      setShippingList(shippingMethodData)
    }
    return ()=>{
      isMount = true
    }
  },[shippingMethodData,shippingModal])

  return (
    <div>
      <Modal
        open={shippingModal.open}
        onClose={()=>{setShippingModal((prev)=>({...prev,open:false,loading:false}))}}
        title={"Shipping methods"}
        primaryAction={{
          content: "Done",
          loading: shippingModal.loading,
          onAction: () => {
            setShippingMethodData(shippingList)
            setTimeout(() => {
              setShippingModal((prev)=>({...prev,open:false,loading:false}))
            }, 500);
          },
        }}
        large={true}
      >
        <div style={{ padding: "0 2rem", minHeight: "350px" }}>
          <div
            className="Prodcut_Shipping_Table"
            style={{ position: "relative" }}
          >
           <table style={{width:'100%',marginTop:'1rem'}}>
            <thead>
              <tr style={{background: '#f5f5f5'}}>
                <th className="table_th">{"Shipping Method"}</th>
                <th className="table_th">{"Estimated Delivery Time"}</th>
                <th className="table_th">{"Shipping Cost"}</th>
              </tr>
            </thead>

            <tbody>
            {shippingList.map((item,ind)=><tr key={ind}>
          <td>
            <RadioButton
              label={item.ShippingMethod}
              checked={item.selected}
              name="shipping_method"
              onChange={()=>{handleChange(ind)}}
            />
          </td>
          <td>{item.DeliveryTime}</td>
          <td>US${item.BeforeConvertCost} {item.currency_code}</td>
        </tr>)}
            </tbody>
          </table>

            
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShippingMethodsModal;
