import React, { Component } from 'react';

import {
  Page, Card, Layout,  SkeletonBodyText, Toast,  FormLayout, TextField,  Banner, BlockStack
  //  Heading, Stack, Select,Collapsible,
} from "@shopify/polaris";
import utils from '../../../../utils/utils'
// import Switch from "react-switch";
import NavTabs from "../components/NavTabs";
import { reqGetUserSettings, reqPostUpdateUserSettings } from '../../../../api';
import './index.css'

import intl from '../../../../i18n/intl'
import SettingPrompt from '../../../../components/SettingPrompt';
import Switch from "react-switch";
import AppBridge from '../../../../utils/appBridge';
import { store } from '../../../../store';
import { changeBannerData } from '../../../../slice';
class NotificationClass extends Component {

  state = {
    // NotificationSwitch: true,

    // Situation_Product_Select: '2',
    // Situation_Variant_Select: '2',
    // Situation_Cost_Select: '3',
    // Situation_Inventory_Select: '2',

    // Notify_Product_NoAvaliable: true,
    // Notify_Variant_NoAvaliable: true,
    // Notify_Cost_Changes: true,
    // Notify_Inventory_Changes: true,

    // Variants_Inventory: 100,

    // UserEmail: '',
    // notifyReportTime: [],  // 通知report时间设置
    // timeZone: '',

    Notify_Customers: true,
    Tracking_Url: '',
    Customer_Tracking_Url: '',

    //弹窗
    showToast: false,  //弹窗显示
    showToastText: '',  //弹窗文字
    showToastIserror: false, //弹窗是否错误

    loadingPage: true,
    saveDataLoading: false,
    disabledSaveData: true,
    modalShow: false,
    nextUrl: '',
    is_virtual_tracking_number: true,  // 是否生成订单虚拟物流单号
    synchronize:0,//选择1
    only_synchronize:0,//选择2
    switchValue:false,//Switch切换
  };

  //进入页面调用接口
  componentDidMount() {
    this.getUserSettingsData();
  }
  componentDidUpdate() {
    let mutation;
    let callback;
    let setting = document.querySelector('#settings_class');
    if (setting) {
      callback = (mutations) => {
        mutations.forEach((item, index) => {
          if (item.attributeName === 'aria-checked') {
            this.setState({
              disabledSaveData: false
            })
            return
          } else if (item.type === 'characterData') {
            // this.setState({
            //   disabledSaveData: false,
            // })
          }
        })
      }
    }
    const config = {
      attributes: true,
      attributeOldValue: true,
      characterData: true,
      characterDataOldValue: true,
      childList: true,
      subtree: true,
      // attributeFilter:['t']
    }
    mutation = new MutationObserver(callback)
    // 开始检测
    mutation.observe(setting, config)
    // 停止检测
    if (!this.state.disabledSaveData) {
      mutation.disconnect()
    }
  }
  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  getUserSettingsData = async () => {
    const params = { type: 'notification' };
    const response = await reqGetUserSettings(params)
    if (response.code === '200') {

      var result = response.data.userSetting;

      this.setState({
        // NotificationSwitch: (result.notification_switch === 1) ? true : false,

        // Situation_Product_Select: String(result.Situation_Product_Select),
        // Situation_Variant_Select: String(result.Situation_Variant_Select),
        // Situation_Cost_Select: String(result.Situation_Cost_Select),
        // Situation_Inventory_Select: String(result.Situation_Inventory_Select),

        // Notify_Product_NoAvaliable: (result.Notify_Product_NoAvaliable === 1) ? true : false,
        // Notify_Variant_NoAvaliable: (result.Notify_Variant_NoAvaliable === 1) ? true : false,
        // Notify_Cost_Changes: (result.Notify_Cost_Changes === 1) ? true : false,
        // Notify_Inventory_Changes: (result.Notify_Inventory_Changes === 1) ? true : false,
        // Variants_Inventory: result.Variants_Inventory,

        Notify_Customers: (result.notify_customers > 0) ? true : false,
        Tracking_Url: result.tracking_url,
        Customer_Tracking_Url:result.custom_tracking_url !== '' ? result.custom_tracking_url : result.tracking_url,
        UserDomain: result.domain,
        // UserEmail: result.email,
        // notifyReportTime: result.notifyReportTime,
        // timeZone: result.timeZone,
        is_virtual_tracking_number: (result.virtual_tracking_number === 1) ? true : false,
        synchronize: result.notification_switch,
        only_synchronize: result.notification_switch,
        switchValue:(result.notify_customers > 0) ? true : false,
        loadingPage: false,
        // disabledSaveData: false
      });

    } else {
      this.toggleToast(response.msg);
    }

  }

  onChangeNotification = (type,value)=>{
    if (type === 'synchronize' && value === 1) {
      this.setState({
        Tracking_Url:this.state.Tracking_Url
      })
    }
    if (type === 'synchronize' && value === 2) {
      this.setState({
        Customer_Tracking_Url:this.state.Customer_Tracking_Url
      })
    }
    this.setState({
      disabledSaveData: false
    })
    if (type === 'synchronize' && value === this.state.synchronize) {
      this.setState({
        [type]:0
      })
    }else if (type === 'synchronize') {
      this.setState({
        [type]:value
      })
    }
    if (type === 'only_synchronize' && value === this.state.only_synchronize) {
      this.setState({
        [type]:0
      })
    }else if (type === 'only_synchronize') {
      this.setState({
        [type]:value
      })
    }
  }
  onChangeSwitch = (value)=>{
    // if (!value) {
    //   this.setState({
    //     only_synchronize:0
    //   })
    // }
    
    this.setState({
      switchValue:value
    })
  }
  // save setting
  saveData = async () => {
    this.setState({
      saveDataLoading: true
    });
    let params = {
        type: 'notification',
  
        // NotificationSwitch: this.state.NotificationSwitch ? 1 : 0,
  
        // Situation_Product_Select: this.state.Situation_Product_Select,
        // Situation_Variant_Select: this.state.Situation_Variant_Select,
        // Situation_Cost_Select: this.state.Situation_Cost_Select,
        // Situation_Inventory_Select: this.state.Situation_Inventory_Select,
  
        // Notify_Product_NoAvaliable: this.state.Notify_Product_NoAvaliable ? 1 : 0,
        // Notify_Variant_NoAvaliable: this.state.Notify_Variant_NoAvaliable ? 1 : 0,
        // Notify_Cost_Changes: this.state.Notify_Cost_Changes ? 1 : 0,
        // Notify_Inventory_Changes: this.state.Notify_Inventory_Changes ? 1 : 0,
        // Variants_Inventory: this.state.Variants_Inventory,
  
        // UserEmail: this.state.UserEmail,
        // notifyReportTime: JSON.stringify(this.state.notifyReportTime),
  
        Notify_Customers:this.state.switchValue ? 1 : 0,
        
       
        // is_virtual_tracking_number: 0,
        Notification_Switch:2,
        Tracking_Url:this.state.Customer_Tracking_Url
      };
    const responseData = await reqPostUpdateUserSettings(params);
    if (responseData.code === "200") {
      this.setState({
        disabledSaveData: true
      })
      this.setState({
        saveDataLoading: false
      });
      this.toggleToast('Saved successfully', false);
    } else {
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:responseData.msg
      }}));
      // this.toggleToast(responseData.msg, true);
    }
  };

  // Switch 开关
  // SetSwitch = () => {
  //   const state = this.state;
  //   var status = state.NotificationSwitch ? false : true;

  //   this.setState({
  //     NotificationSwitch: status
  //   });
  // };

  // checkbox change
  handleCheckboxChange = (value, id) => {
    // let bool = false;
    // switch (id) {
    //   case 'Is_Send_Email':
    //   case 'Monday':
    //   case 'Tuesday':
    //   case 'Wednesday':
    //   case 'Thursday':
    //   case 'Friday':
    //   case 'Saturday':
    //   case 'Sunday':
    //     bool = true;
    //     break;
    //   default:
    //     bool = false;
    // }

    // if (bool) {
    //   let data = this.state.notifyReportTime;
    //   data[id] = value ? 1 : 0;
    //   this.setState({
    //     notifyReportTime: data
    //   });
    // } else {
      this.setState({
        [id]: value
      });
    // }
  }

  // select change
  // handleSelectChange = (value, id) => {
  //   if (id === 'Notify_Hour') {
  //     let data = this.state.notifyReportTime;
  //     data[id] = value;
  //     this.setState({
  //       notifyReportTime: data
  //     });
  //   } else {
  //     this.setState({
  //       [id]: value
  //     });
  //   }
  // }

  // input change
  handleInputChange = (value, id) => {
    this.setState({
      Customer_Tracking_Url: value,
      disabledSaveData: false
    });
  }
  handleUrlChange = () => {
    if (this.state.nextUrl !== '') {
      this.saveData();
      setTimeout(() => {
         utils.routeListen(this.state.nextUrl)
      }, 200);
    }
  };
  handleUrlClose = () => {
    if (this.state.nextUrl !== '') {
              utils.routeJump(this,this.state.nextUrl)
    }
  }
  handleClose = () => {
    this.setState({
      modalShow: false
    })
  }
  
  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;

    const { 
      // NotificationSwitch, Situation_Product_Select, Situation_Variant_Select, Situation_Cost_Select, Situation_Inventory_Select,
      // Notify_Product_NoAvaliable, Notify_Variant_NoAvaliable, Notify_Cost_Changes, Notify_Inventory_Changes, Variants_Inventory, UserEmail, notifyReportTime, timeZone,
      // Tracking_Url,
      Customer_Tracking_Url,
      saveDataLoading, disabledSaveData,switchValue,
      // synchronize,
    } = this.state;
    const ShopifyAdminUrl = 'https://' + this.state.UserDomain + '/admin/email_templates/shipping_confirmation/edit';

    const initSkeletonPage = <div className="SettingsPage__Content">
       <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout></div>

    const pageContent = this.state.loadingPage ? initSkeletonPage : <div className="SettingsPage__Content">

      <section className="Panel_Content">
        <Card>
        <h2 style={{fontSize:16,fontWeight:700}}>
        {intl.get("Notification of Order Tracking")}
        </h2>
          <FormLayout>
            {/* <section>
              <div className="Notify__Title notify_settings_step_title">
                {intl.get("1. Synchronize the tracking number with Shopify upon its generation.")}
              </div>
              <div className="Notify_Customers notify_settings_step_section">
                <RadioButton
                  id="synchronize"
                  label={intl.get("Synchronize the full tracking of orders, including stock preparation, quality inspection, shipment, and logistics tracking.(Only supporting Dropshipman Express)")}
                  checked={synchronize === 1}
                  onChange={(e)=>this.onChangeNotification('synchronize',1)}
                />
                 <div style={{margin:"10px 0"}}>
                 <Banner
                  title=""
                  tone="info"
                >
                  <p>{intl.get("The full tracking number is generated and synchronized with Shopify upon payment completion, resulting in an automatic status update to Fulfilled. The tracking information can only be accessed through")} <a style={{color: "#4584FE"}} href={'https://parcelpanel.com?ref=tQmHsdZJ'} target='_blank' rel="noopener noreferrer">Parcel Panel</a> or <a style={{color: "#4584FE"}} href={'https://www.trackingmore.com/track'} target='_blank' rel="noopener noreferrer">TrackingMore.</a></p>
                </Banner>
                </div>
              </div>
             
              <div className="Notify_Customers notify_settings_step_section">
                <RadioButton
                  id="only_synchronize"
                  label={intl.get("Only synchronize the logistics tracking of orders, without synchronizing the tracking of stock preparation, quality inspection, shipment, etc.")}
                  checked={synchronize === 2}
                  onChange={(e)=>this.onChangeNotification('synchronize',2)}
                />
                <div style={{margin:"10px 0"}}>
                <Banner
                  title=""
                  tone="info"
                >
                  <p>{intl.get("The logistics tracking number is generated only after the order is shipped, and is automatically synced with Shopify, updating the order status to Fulfilled. The tracking information can be accessed through all logistics platforms.")}</p>
                </Banner>
                </div>
              </div>
            </section> */}

            <section>
              <div className="Notify__Title notify_settings_step_title">
                <div style={{ display: 'inline-block' }}>
                {intl.get("1. Email notification to customers when order is fulfilled or tracking number is generated.")}
                <div style={{display:"inline-block",marginTop: '8px',verticalAlign:'sub',marginLeft: "8px"}}>
                <Switch
                onChange={(nextChecked) =>this.onChangeSwitch(nextChecked)}
                checked={switchValue}
                width={50}
                height={22}
                onColor='#303030'
                offColor="#DADADA"
                uncheckedIcon={<div className="switchBtn"></div>}
              />
                </div>
                </div>
              </div>
              <div className='notify_settings_step_section_email' style={{marginBottom:"16px"}}>
             {intl.get("Change the Shopify's email notification template in")} <a style={{color: "#4584FE",display:'inline-flex'}} href={ShopifyAdminUrl} target='_blank' rel="noopener noreferrer">Shopify admin <img width={20} src='/share_icon.png' alt='dsmshipman'></img> </a>.
              </div>
              {/* <div className='notify_settings_step_section_email'> */}
                {/* <div className='notify_settings_step_track'><strong>a.</strong> Select the tracking number type for the order</div>
                <div className="Notify_Customers">
                <RadioButton
                  id="only_synchronize1"
                  label={intl.get("Full tracking (including stocking, quality inspection, shipment, and logistics tracking of the order).")}
                  checked={only_synchronize === 1}
                  onChange={(e)=>this.onChangeNotification('only_synchronize',1)}
                />
                 <div style={{margin:"10px 0"}}>
                 <Banner
                  title=""
                  status="info"
                  // onDismiss={() => {}}
                >
                  <p>The full tracking process can only be accessed through Parcel Panel or TrackingMore You can <Link>download</Link> and configure the tracking page on <Link url={ShopifyAdminUrl}>{intl.get("Parcel Panel")}</Link>.</p>
                </Banner>
                </div>
              </div> */}
              {/* <div className="Notify_Customers">
                <RadioButton
                  id="only_synchronize2"
                  label={intl.get("Shipping tracking (only the shipping tracking of the order, without tracking of stocking, quality inspection, shipment, etc.).")}
                  checked={only_synchronize === 2}
                  onChange={(e)=>this.onChangeNotification('only_synchronize',2)}
                />
              </div> */}
              <div className="Notify__Title notify_settings_step_title">
              {intl.get("2. Shipping tracking URL")}
              </div>
              <div style={{margin:"16px 0 16px 16px"}}>
              {/* <div className="Notify__Title">
                <div style={{ display: 'inline-block' }}>{intl.get("Add custom tracking URL")}</div>
                <div style={{ display: 'inline-block', cursor: 'pointer', width: '1.6rem' }} >
                  <Tooltip content={intl.get("Please note: We will dynamically add a tracking code to the end of this URL. If you want to use URL http://www.17track.net/en/track?nums=1234567 enter only http://www.17track.net/en/track?nums=")} preferredPosition="above">
                    <Icon source={QuestionCircleIcon} backdrop={true} />
                  </Tooltip>
                </div>
              </div> */}
             
              <div>
              <TextField
                  id="Tracking_Url"
                  label={intl.get("Add custom tracking URL")}
                  labelHidden
                  value={Customer_Tracking_Url}
                  onChange={this.handleInputChange}
                  // disabled={synchronize === 2 ? false : true}
                />
              </div>
              </div>
              {/* </div> */}
             
              <div style={{margin:"10px 0 10px 16px"}}>
                 <Banner
                  title=""
                  tone="info"
                  // onDismiss={() => {}}
                >
                  <p>
                 {intl.get("In the absence of a set shipping tracking URL, a default")} <a style={{color: "#4584FE"}} href={'https://www.trackingmore.com/track'} target='_blank' rel="noopener noreferrer">TrackingMore</a> {intl.get("shipping tracking URL will be assigned. Additionally, you can customize your tracking page using")} <a style={{color: "#4584FE"}} href={'https://parcelpanel.com?ref=tQmHsdZJ'} target='_blank' rel="noopener noreferrer">Parcel Panel</a>, {intl.get("which will automatically sync your configured shipping tracking URL.")}</p>
                </Banner>
                </div>
            </section>

            {/* <section>
              <div className="Notify__Title">
                {intl.get("3 Change the Shopify’s email notification template in")} <Link url={ShopifyAdminUrl} external>{intl.get("Shopify admin")}</Link>.
              </div>
              <div>
                {intl.get("Read how to do this in Help Center")}
              </div>
            </section> */}

            {/* <section>
              <div className="Notify__Title">
                {intl.get("4. When an order is successfully placed and a virtual tracking number is generated, the tracking number is synchronized to the customer.")}
              </div>
              <div className="Notify_Customers">
                <Checkbox
                  id="is_virtual_tracking_number"
                  label={intl.get("Sync to my customers")}
                  checked={is_virtual_tracking_number}
                  onChange={this.handleCheckboxChange}
                />
              </div>
            </section> */}
          </FormLayout>
        </Card>

      </section>
    </div>


    return (
      <div className={AppBridge.exist() ? "Setting__Page_Content Content_within" : "Setting__Page_Content Content_outside"} id='settings_class'>
        <Page
          // breadcrumbs={[{content: 'Settings', url: '/admin/settings/index'}]}
          title={AppBridge.exist() ? '' : intl.get("Settings")}
          primaryAction={
            // AppBridge.exist() ? '' : 
            {
              content: intl.get("Save"),
              onAction: this.saveData,
              loading: saveDataLoading,
              disabled: disabledSaveData
            }
          }
          separator
        >

          <section className="SettingsPage__Box setting_clearfix">

            <NavTabs selected="Notification" saveData={this.saveData} loading={saveDataLoading} disabled={disabledSaveData}/>

            {pageContent}

          </section>

          {toastMarkup}

        </Page>
        {/* 未保存拦截弹窗 */}
        <SettingPrompt disabledSaveData={disabledSaveData} url='/admin/settings/notification' saveData={this.saveData}/>
        
      </div>
    );

  }
}

export default NotificationClass;
