import axios from 'axios';
import qs from 'qs';
import { getSessionToken } from '@shopify/app-bridge-utils';
import utils from '../utils/utils';
import AppBridge from '../utils/appBridge';
import { changePersonLevel } from '../slice';
import { store } from '../store';

/**
 * ajax请求拦截器，追加header，授权信息
 */
axios.interceptors.request.use(function (request) {

  if (request.url.indexOf('/shopify/app') !== -1) {
    return request;
  }

  // 控制Dismiss 显示 | 隐藏
  // let num = 0
  // if (!localStorage.getItem('homePageIsShowDismissBtn') && num === 0) {
  //   num = 1
  //   localStorage.setItem('homePageIsShowDismissBtn', JSON.stringify({
  //     topBtn: true,
  //     bottomBtn: true,
  //   }))
  // }

  if (AppBridge.app) {
    return AppBridge.app && getSessionToken(AppBridge.app).then((token) => {
      if (request.headers && process.env.NODE_ENV !== "development") {
        request.headers['Authorization'] = `ShopifyBridge ${token}`
      }
      return request;
    });

  } else {
    /**
     * 如果是开发环境或者通过后台生成用户链接访问
     */
    let authToken = utils.getStore('S_AUTHORIZATION');

    if ((!AppBridge.exist() && ['development'].includes(process.env.NODE_ENV)) || authToken) {
      request.headers['Authorization'] = 'ShopifyAdmin ' + authToken
      return request;
    }
  }

  return request;

}, function (error) {
  return Promise.reject(error);
});


/**
 * ajax 请求
 * 
 * @param {*} url 
 * @param {*} data 
 * @param {*} type 
 * @returns 
 */
export function ajax (url, data = {}, type = 'GET') {

  type = type.toUpperCase();

  return new Promise((resolve, reject) => {
    let promise;
    // let timeStr = new Date().getTime();
    let header;
    // let header = {
    //   'Authorization': utils.getStore('S_AUTHORIZATION'),
    //   'usertoken' : utils.getStore('S_USER_TOKEN'),
    //   'shopdomain': utils.getStore('S_SHOP_DOMAIN'),
    //   'timestr'   : timeStr,
    //   'sign'      : utils.getSign(utils.getStore('S_SHOP_DOMAIN'),timeStr),
    // }

    if (type === 'GET') {
      promise = axios.get(url, {
        params: data,
        headers: header
      })
    }

    // POST 请求
    else if (type === 'POST') {
      promise = axios.post(url, qs.stringify(data), {
        headers: header
      })
    }
     // POSTFORM 请求
     else if (type === 'POSTFORM') {
      promise = axios.post(url, data, {
        headers: header
      })
    }

    // FILE 请求
    else if (type === 'FILE') {
      promise = axios.post(url, data, {
        headers: header
      })
    }
    // PUT 请求
    else if (type === 'PUT') {
      promise = axios.put(url, data, {
        headers: header
      })
    }
    // DELETE 请求
    else if (type === 'DELETE') {
      promise = axios.delete(url, {data}, {
        headers: header
      })
    }

    // 请求成功
    promise.then(response => {
      let responseData = response.data;
      if (responseData.code === '10000' || responseData.code === 10000) {
        store.dispatch(changePersonLevel({infoData:responseData},true))
      }
      // 是否验证成功
      if (0) {
        if (responseData.code === '40400') {
          // utils.removeStore('S_AUTHORIZATION');
          // utils.setStore('S_SHOP_DOMAIN','');
          // utils.setStore('S_USER_TOKEN','');

          // window.location.href='/login';
          // return false;
        }
      }

      resolve(response.data)
    })
  })
}

export function ajaxWithoutAuth (url, data = {}, type = 'GET') {

  type = type.toUpperCase();

  return new Promise((resolve, reject) => {
    let promise;
    if (type === 'GET') {
      promise = axios.get(url, {
        params: data
      })
    }

    // POST 请求
    else if (type === 'POST') {
      promise = axios.post(url, qs.stringify(data))
    }

    // 请求成功
    promise.then(response => {
      resolve(response.data)
    })
  })
}