import React,{useEffect} from 'react'
import FrameComponents from '../FrameComponents';

const OrderList = () => {
  useEffect(()=>{
    document.title = 'DropshipMan-order-list';
  },[])
  return (
    <div>
      <FrameComponents name='order_list'/>
    </div>
  )
}

export default OrderList
