import {
  Page,
  Card,
  Layout,
  IndexTable,
  Thumbnail,
  TextField,
  BlockStack,
  Box,
  Link,
  Button,
  InlineStack,
  Text,
  EmptyState,
  SkeletonPage,
  SkeletonBodyText,
  Toast,
  useIndexResourceState,
} from "@shopify/polaris";
import 'react-zmage/lib/zmage.css';
import React, { useEffect, useState } from 'react';
import './index.css';
import intl from '../../../i18n/intl';

import utils from '../../../utils/utils';
import { useHistory, withRouter } from "react-router-dom";
import { reqGetRedeemVariants} from "../../../api";
import RedeemedOrder from "./component/RedeemedOrder";
import RedemProductOkModal from "../RedeemProducts/components/Modal/RedemProductOkModal"
import RedemProductLessModal from "../RedeemProducts/components/Modal/RedemProductLessModal"
const PointsPurchaseClass = ()=> {
  const router = useHistory();
  const [initPageData,setInitPageData] = useState({
    isInit:true,
    pageLoading:false
  })
 
  const [purchasePageData,setPurchaseData] = useState({});

  const getPurchasePage = async ()=>{
    let url = new URLSearchParams(window.location.search);
    let record_id = url.get("record_id")
    let params = {
      record_id:record_id
    }
    try {
      let res = await reqGetRedeemVariants(params);
      if (res.code === '200') {
        let list = res.data.variants.map((item)=>{
          return {...item,quantity:1}
        });
        let data = {...res.data,variants:list}
        setPurchaseData(data);
      }
      setInitPageData({
        isInit:false,
        pageLoading:false
      })
    } catch (error) {
      
    }
    
  }
  const formatNumber = (value) => {
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }

    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  };
  useEffect(() => {
    let isMount = false;
    // if(AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar('Redeem Products','/admin/redeem_products')
    // }
    if (!isMount) {
      getPurchasePage()
    }
    return () => {
      isMount = true;
    };
  }, []);



  // 总金额
  const totalPriceFun = (data)=>{
    let num = 0;
    let variants =[];
    if (data) {
      data.forEach((item)=>{
        if (selectedResources.includes(item.id)) {
          variants.push(item)
        } 
      })
    }
    
    if (variants.length > 0) {
      variants.forEach((item)=>{
        num += Number(item.point) * Number(item.quantity)
    })
    }
    
    return num;
  }


  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;

  const {selectedResources, allResourcesSelected, handleSelectionChange} =
  useIndexResourceState(purchasePageData?.variants);
  // 页面更新

  // 修改库存数量
  const onChangeQuantity = (value, recordKey) => {
    if (value > 999) {
      return
    }
    let data = purchasePageData.variants;
    let num = Number(value);
    data[recordKey].quantity = num;
    setPurchaseData((prevValue)=>({
      ...prevValue,
      variants:data
    }))
  }
  const onChangeBulrQuantity = (value, recordKey) => {
    let data = purchasePageData.variants;
    let num = Number(value);
    if (num < 1) {
      num = Number(1)
    }
    if (num > 100) {
      num = 100;
    }
    data[recordKey].quantity = num;
    setPurchaseData((prevValue)=>({
      ...prevValue,
      variants:data
    }))
  }

  // 下单地址选择窗口
  const [redeemOrderData,setRedeemOrderData] = useState({
    isShow:false,
    data:[]
  });
  // 充足、不足积分的弹窗
  const [isEnough,setIsEnough] = useState({
    isOk:false,
    isLess:false,
  });
  
  // 创建订单
  const handleCreateOrder = (listData) => {
    let variants =[];
    if (listData) {
      listData.forEach((item)=>{
        if (selectedResources.includes(item.id)) {
          variants.push(item)
        } 
      })
    }
    setRedeemOrderData((prevValue)=>({
      ...prevValue,
      data:variants
    }))
    if (purchasePageData.user_point.available_points <= totalPriceFun(listData)) {
      setIsEnough({
        isOk:false,
        isLess:true,
      })
    }else {
      setIsEnough({
        isOk:true,
        isLess:false,
      })
    }
    
  }

  // 窗口返回
  const handleRedeemOrderBack = () => {
    setRedeemOrderData((prevValue)=>({
      ...prevValue,
      isShow:false,
    }))
  }
 
  const EmptyProductList = (
    <div className="emptyWithImg">
       <EmptyState
        heading={intl.get("Sorry, no results found.")}
        image="/empty.svg"
      >
      </EmptyState>
    </div>
  );
  

  const skeletonPage =  <SkeletonPage fullWidth={false}>
  <Layout>
   <Layout.Section>
   <Card>
   <BlockStack gap={400}>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   </BlockStack>
   </Card>
 </Layout.Section>
   </Layout>
 </SkeletonPage>
    // 列表数据
    const rowMarkup = Object.values(purchasePageData).length > 0 && (purchasePageData?.variants.map((item, index) => {
      let imageUrl = '/default_image.png';
      if (item.sku_image !== '' && (typeof item.sku_image) !== 'object') {
        imageUrl = item.sku_image;
      }
     
      return (
        <IndexTable.Row
          id={item.id}
          key={item.id}
          selected={selectedResources.includes(item.id)}
          position={index}
        >
          <IndexTable.Cell>
          <Link
            dataPrimaryLink
            url={''}
            onClick={() => console.log()}
          >
            <Thumbnail source={imageUrl} alt={item.title} />
          </Link>
         
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div className="Inventory_Product">
              <div className="Inventory_Product__Title shopping_cart" title={item.title}><span>{item.title}</span></div>
              <div className="Inventory_Variant_Title">{item.variant_title}</div>
              <div className="Inventory_Variant_Title">{item.sku}</div>
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div>{formatNumber(Number(item.point)) + ' Points'}</div>
          </IndexTable.Cell>

          <IndexTable.Cell>
            <div>
              <BlockStack>
                <span>{formatNumber(Number(item.point) * Number(item.quantity)) + ' Points  *'}</span>
                <TextField
                  type='number'
                  onChange={(value) => {onChangeQuantity(value,index)}}
                  onBlur={(e) => {onChangeBulrQuantity(e.target.value, index)}}
                  value={String(item.quantity)}
                  min={1}
                />
              </BlockStack>
            </div>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    },
    ));
   
    const resourceName = {
      singular: 'id',
      plural: 'orders',
    };
    
    const pageConteent = initPageData.isInit ? skeletonPage : (  <Page
      backAction={
        false ? undefined : {
        content:'',
        onAction: () => {
          utils.routeListen('/admin/redeem_products')
          utils.routeJump1(router,'/admin/redeem_products')
        }
      }}
      title={<div style={{fontSize:20,display:'flex',alignItems:'center',flexWrap:'wrap'}}>{intl.get('Purchase')} <span style={{fontSize:14,fontWeight:400,marginLeft:30}}>Remaining points: {purchasePageData?.user_point?.available_points ? formatNumber(purchasePageData?.user_point?.available_points) : 0}</span></div>}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap={400}>
          
        <Box className="points_redeem_select_product">
          <Card>
        {Object.values(purchasePageData).length > 0 ? <Box style={{maxHeight:'380px',overflowY:'auto',overflowX:'hidden'}}>
            <IndexTable
                  resourceName={resourceName}
                  itemCount={purchasePageData?.variants.length}
                  selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: '' },
                    { title: 'Sku' },
                    { title: 'Cost' },
                    { title: 'Quantity' },
                  ]}
                >
                {rowMarkup}
                  
                </IndexTable>
            </Box>:EmptyProductList}
            </Card>
        </Box>
        <Box>
        <Card>
           <Text variant="headingMd" as="h2">{intl.get("Order")}</Text>
            <BlockStack>
              <InlineStack align='space-between'>
              <Box>
                <Text variant="headingMd" as="h2">{intl.get("Subtotal")}:</Text>
              </Box>
              <Box>
                <div className='prepaid_order_total'>{formatNumber(totalPriceFun(purchasePageData?.variants)) + ' Points'}</div>
              </Box>
              </InlineStack>
              <Box style={{ marginTop: '10px' }}>
              <InlineStack align='end'>
              <Button variant='primary' disabled={selectedResources.length === 0} onClick={()=>{handleCreateOrder(purchasePageData?.variants)}}>{intl.get('Continue')}</Button>
              </InlineStack>
              </Box>
            </BlockStack>
          </Card>
        </Box>
        </BlockStack>
        </Layout.Section>
        </Layout>
        </Page>
    )
    return (
        <div className='purchase_list'>
         {!redeemOrderData.isShow ? pageConteent : <RedeemedOrder orderData={redeemOrderData.data} callBackFun={()=>handleRedeemOrderBack}/>}
          <RedemProductOkModal 
          setRedeemOrderData={setRedeemOrderData} 
          open={isEnough.isOk} 
          setIsEnough={setIsEnough}
          needNum={totalPriceFun(purchasePageData?.variants)}
          currentPoints={purchasePageData?.user_point?.available_points}
          />
          <RedemProductLessModal 
          open={isEnough.isLess} 
          setIsEnough={setIsEnough}
          needNum={totalPriceFun(purchasePageData?.variants)}
          currentPoints={purchasePageData?.user_point?.available_points}
          />
         {toastMarkup}
        </div>
    );
}


export default withRouter(PointsPurchaseClass);
