import React,{Component} from 'react'
import FrameComponents from '../FrameComponents';
import './index.css'
class dashboard extends Component {
  // 进入组件调用
  state = {
    name: 'custom-inventory-history'
  }
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-inventory_history';
  }
 
  render() {
    return (
      <div>
        <FrameComponents name={this.state.name} /> 
      </div>
    );
    
  }
}

export default dashboard;

