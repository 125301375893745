import { Box, Button } from '@shopify/polaris';
import React, { useState } from 'react';
import PremiumPlanModal from '../Modal/PremiumPlanModal';
import { connect, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import './index.css';
import utils from '../../../../../utils/utils';
const ListItemOne  = ({listData})=> {
  const router = useHistory();
    // 是否会员
    const [pladData,setPlanData] = useState({
        upgradeOpen:false,
    });
    const person = useSelector((state) => state.languageReducer.subScriptData);
    const handlePurchase = (record_id)=>{
      if (person.level !== 5) {
        setPlanData((prevValue)=>({
          ...prevValue,
          upgradeOpen:true,
      }))
      return
      }
      utils.routeListen('/admin/customService/purchase?record_id='+record_id)
      utils.routeJump1(router,'/admin/customService/purchase?record_id='+record_id)
    }

    // 跳转至详情页
    const handleDetail = (record_id)=>{
        utils.routeListen('/admin/customService/custom-packaging/packaging-info?filter='+record_id)
        utils.routeJump1(router,'/admin/customService/custom-packaging/packaging-info?filter='+record_id)
    }
    return (
        <div>
            <Box className="all_packing_list">
          {listData.map((item,ind)=><Box key={ind} className="all_packing_list_item">
            <Box className="all_packing_list_item_img" onClick={()=>handleDetail(item.record_id)}>
              <img src={item.product_image} alt="Dropshipman" />
            </Box>
            <Box className="all_packing_list_item_box">
              <Box className="all_packing_list_item_text" title={item.title}>{item.title}</Box>
              <Box className="all_packing_list_item_price"><span>$</span> {item.price}</Box>
            </Box>
            <Box className="all_packing_list_item_button"><Button variant="primary" onClick={()=>handlePurchase(item.record_id)}>Purchase</Button></Box>
          </Box>)}
          </Box>
          <PremiumPlanModal upgradeOpen={pladData.upgradeOpen} setUpgradeOpen={()=>{
            setPlanData((prevValue)=>({
              ...prevValue,
              upgradeOpen:false,
          }))
          }}/>
        </div>
    );
}

export default connect()(withRouter(ListItemOne));
