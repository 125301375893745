import React, { Component } from 'react';

import FrameComponents from "../../FrameComponents"; 

import "./index.css";
import { connect } from 'react-redux';

class dashboard extends Component {

  state = {};

  //进入页面调用接口
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-trendy-products';
  }

  render() {
    return (
      <div>
        <FrameComponents name="trendy-products" />
      </div>
    );
  }
}

export default connect()(dashboard);