import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
// import '@shopify/polaris/styles.css';
// import '@shopify/polaris/dist/styles.css' @6.1.0;
import "@shopify/polaris/build/esm/styles.css"; //@7.0.0
import Router from "./Router";
import * as serviceWorker from "./serviceWorker";
// import MyEditor from './components/Editor/CKEditor';
import {Provider} from "react-redux"
import { store } from './store';
import reportWebVitals from './reportWebVitals'
// import {AppProvider} from '@shopify/polaris';
import {onCLS, onLCP} from 'web-vitals';
import { reqGetCls } from "./api";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
function sendToAnalytics(metric) {
  // Replace with whatever serialization method you prefer.
  // Note: JSON.stringify will likely include more data than you need.
  // const body = JSON.stringify(metric);
  let url = window.location.href;
  let value = metric.value;
  // console.log(metric);

  
  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
  if (Number(metric.value) > 0.09) {
    // (navigator.sendBeacon && navigator.sendBeacon(API_DOMAIN+'/api/v1/black/cls?url='+url+'&cls='+value, body)) ||
    if (process.env.REACT_APP_ENV === 'pre') {
      reqGetCls({url, cls:value});
    }
  }
  
}


const App = () => {
  useEffect(() => {
    onCLS(sendToAnalytics, { reportAllChanges: true });
    onLCP(sendToAnalytics, { reportAllChanges: true });
  }, []); // 空依赖数组确保只运行一次
  return (<DndProvider backend={HTML5Backend} context={window}>
    <Provider store={store}>
     <BrowserRouter>
      {/* <AppProvider> */}
      <Router />
      </BrowserRouter>
      {/* </AppProvider> */}
    </Provider>
    </DndProvider>
  );
};
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
}
