import React, { Component } from 'react';

import {AppProvider,  Layout , Page, Button } from '@shopify/polaris';

import translations from '@shopify/polaris/locales/en.json';
// https://cdn.shopifycloud.com/web/assets/6933fb5939cd87752c68648f5db4d540.svg
import notFoundImg from './images/6933fb5939cd87752c68648f5db4d540.svg';
import './index.css';

import intl from '../../../i18n/intl'

class PageNotFound extends Component {
  componentDidMount() {
    if (window?.hideLoadingState) {
      window.hideLoadingState()
    }
  }
  render() {

    const pageContent = <div style={{background:'#fff'}}>
        <Layout>
          <Layout.Section variant='oneThird'>

              <div className="page-not-found">
                  <div className="not-found-title">
                      {intl.get("The page you’re looking for couldn’t be found")}
                  </div>
                  <div className="not-found-msg">
                      {intl.get("Check the web address and try again, or try navigating to the page from Drop Ship Home.")}
                  </div>
                  <Button variant='primary' size="large" url="/admin/dashboard">{intl.get("Go to Drop Ship Home")}</Button>
              </div>

          </Layout.Section>

          <Layout.Section variant='oneThird'>
              <div className="not-img">
                <img src={notFoundImg} alt="page not found" className="not-found-img"></img>
              </div>
          </Layout.Section>
        </Layout></div>;
    
    return (
      <AppProvider i18n={translations}>
          <Page>
            { pageContent }
          </Page>
      </AppProvider>
    );
  }
}

export default PageNotFound;
