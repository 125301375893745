import { BlockStack, Box, Card, InlineStack, ProgressBar, SkeletonBodyText } from '@shopify/polaris';
import React  from 'react';

const MarketEmpty = ()=> {
    return (
        <Box className="market_empty">
            <Box>
                <Box className="market_empty_container">
                    <Box className="market_empty_box market_empty_boxs" >
                        <Card padding={0}>
                        <Box className="market_empty_box_top" style={{background:"url(/new-product/collect_empty.png)"}}></Box>
                        <Box className="market_empty_box_bottom">
                            <BlockStack gap={100}>
                            <ProgressBar size="small"/>
                            <ProgressBar size="small"/>
                            <InlineStack align='space-between' blockAlign='center'>
                                <Box style={{width:"30%"}}>
                                <ProgressBar />
                            </Box>
                            <Box  style={{width:"50%"}}>
                            <ProgressBar />
                            </Box>
                            </InlineStack>
                            <Box style={{height:"12px"}}>
                            <SkeletonBodyText lines={1}/>
                            </Box>
                            <Box style={{width:"80%"}}>
                                <ProgressBar />
                            </Box>
                            </BlockStack>
                        </Box>
                        </Card>
                        <Box className="market_empty_boxs_icon">
                        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.3 1.40039C21.4598 1.40039 22.4 2.34059 22.4 3.50039V10.5004C22.4 11.6602 21.4598 12.6004 20.3 12.6004C19.1402 12.6004 18.2 11.6602 18.2 10.5004V3.50039C18.2 2.34059 19.1402 1.40039 20.3 1.40039Z" fill="#303030"/>
                        <path d="M32.9849 7.61544C33.805 8.43554 33.805 9.76519 32.9849 10.5853L30.1849 13.3853C29.3648 14.2054 28.0352 14.2054 27.2151 13.3853C26.395 12.5652 26.395 11.2355 27.2151 10.4154L30.0151 7.61544C30.8352 6.79534 32.1648 6.79534 32.9849 7.61544Z" fill="#303030"/>
                        <path d="M13.3848 27.2154C14.205 28.0354 14.2051 29.365 13.385 30.1852L10.5852 32.9854C9.76512 33.8055 8.43547 33.8056 7.61532 32.9855C6.79517 32.1655 6.79509 30.8359 7.61514 30.0157L10.415 27.2155C11.235 26.3954 12.5647 26.3953 13.3848 27.2154Z" fill="#303030"/>
                        <path d="M10.5 22.4004C11.6598 22.4004 12.6 21.4602 12.6 20.3004C12.6 19.1406 11.6598 18.2004 10.5 18.2004L3.49999 18.2004C2.3402 18.2004 1.39999 19.1406 1.39999 20.3004C1.39999 21.4602 2.34019 22.4004 3.49999 22.4004L10.5 22.4004Z" fill="#303030"/>
                        <path d="M12.6849 12.6853C11.8648 13.5054 10.5352 13.5054 9.71505 12.6853L4.81505 7.78531C3.99495 6.96521 3.99495 5.63557 4.81505 4.81547C5.63515 3.99536 6.9648 3.99536 7.7849 4.81547L12.6849 9.71547C13.505 10.5356 13.505 11.8652 12.6849 12.6853Z" fill="#303030"/>
                        <path d="M16.0151 16.0155C16.5776 15.4531 17.4095 15.2567 18.1641 15.5082L39.1641 22.5082C39.8545 22.7384 40.3766 23.3097 40.5438 24.018C40.711 24.7264 40.4996 25.4708 39.9849 25.9854L34.4699 31.5004L39.9849 37.0155C40.805 37.8356 40.805 39.1652 39.9849 39.9853C39.1648 40.8054 37.8352 40.8054 37.0151 39.9853L31.5 34.4702L25.9849 39.9853C25.4703 40.4999 24.7259 40.7114 24.0175 40.5442C23.3092 40.3769 22.7379 39.8549 22.5078 39.1644L15.5078 18.1645C15.2563 17.4099 15.4527 16.578 16.0151 16.0155Z" fill="#303030"/>
                        <path d="M20.3 1.40039C21.4598 1.40039 22.4 2.34059 22.4 3.50039V10.5004C22.4 11.6602 21.4598 12.6004 20.3 12.6004C19.1402 12.6004 18.2 11.6602 18.2 10.5004V3.50039C18.2 2.34059 19.1402 1.40039 20.3 1.40039Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M32.9849 7.61544C33.805 8.43554 33.805 9.76519 32.9849 10.5853L30.1849 13.3853C29.3648 14.2054 28.0352 14.2054 27.2151 13.3853C26.395 12.5652 26.395 11.2355 27.2151 10.4154L30.0151 7.61544C30.8352 6.79534 32.1648 6.79534 32.9849 7.61544Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.3848 27.2154C14.205 28.0354 14.2051 29.365 13.385 30.1852L10.5852 32.9854C9.76512 33.8055 8.43547 33.8056 7.61532 32.9855C6.79517 32.1655 6.79509 30.8359 7.61514 30.0157L10.415 27.2155C11.235 26.3954 12.5647 26.3953 13.3848 27.2154Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.5 22.4004C11.6598 22.4004 12.6 21.4602 12.6 20.3004C12.6 19.1406 11.6598 18.2004 10.5 18.2004L3.49999 18.2004C2.3402 18.2004 1.39999 19.1406 1.39999 20.3004C1.39999 21.4602 2.34019 22.4004 3.49999 22.4004L10.5 22.4004Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.6849 12.6853C11.8648 13.5054 10.5352 13.5054 9.71505 12.6853L4.81505 7.78531C3.99495 6.96521 3.99495 5.63557 4.81505 4.81547C5.63515 3.99536 6.9648 3.99536 7.7849 4.81547L12.6849 9.71547C13.505 10.5356 13.505 11.8652 12.6849 12.6853Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.0151 16.0155C16.5776 15.4531 17.4095 15.2567 18.1641 15.5082L39.1641 22.5082C39.8545 22.7384 40.3766 23.3097 40.5438 24.018C40.711 24.7264 40.4996 25.4708 39.9849 25.9854L34.4699 31.5004L39.9849 37.0155C40.805 37.8356 40.805 39.1652 39.9849 39.9853C39.1648 40.8054 37.8352 40.8054 37.0151 39.9853L31.5 34.4702L25.9849 39.9853C25.4703 40.4999 24.7259 40.7114 24.0175 40.5442C23.3092 40.3769 22.7379 39.8549 22.5078 39.1644L15.5078 18.1645C15.2563 17.4099 15.4527 16.578 16.0151 16.0155Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </Box>
                    </Box>
                    <Box className="market_empty_box">
                        <Card padding={0}>
                        <Box className="market_empty_box_top"></Box>
                        <Box className="market_empty_box_bottom"></Box>
                        </Card>
                    </Box>
                    <Box className="market_empty_box">
                        <Card padding={0}>
                        <Box className="market_empty_box_top"></Box>
                        <Box className="market_empty_box_bottom"></Box>
                        </Card>
                    </Box>
                </Box>
                <Box style={{margin:"16px 0 10px",color:"rgba(0, 0, 0, 1)",fontSize:'16px',lineHeight:"24px",fontWeight:"650",textAlign:'center'}}>Hover Product Image and <br />
                    Tap “heart icon” to Add your products to favorites</Box>
                    <Box  style={{color:"rgba(118, 118, 118, 1)",fontSize:'13px',lineHeight:"20px",fontWeight:"450",textAlign:'center'}}>
                    You can collect products you are interested in to facilitate the management of your <br /> own selections
                    </Box>
            </Box>
        </Box>
    );
}

export default MarketEmpty;
