import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup
} from "@shopify/polaris";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';

import intl from '../../i18n/intl';
import './index.css'

const DatePickerComponent = (props) => {
  /**
   * searchStartDay 开始日期的值
   * searchEndDay 结束日期的值
   * queryValueNew 搜索框的值
   * loading 搜索按钮loading
   * datePickerCallback 日期选择回调
   * clearSearchInputCallback 清空搜索框回调
   * requestCallback 请求接口回调
   */
  const { searchStartDay, searchEndDay, queryValueNew, loading, datePickerCallback, clearSearchInputCallback, requestCallback } = props
  const [startDate, setStartDate] = useState(searchStartDay ? new Date(searchStartDay) :  undefined) // 支付开始时间
  const [endDate, setEndDate] = useState(searchEndDay ? new Date(searchEndDay) :  undefined)  // 支付结束时间
  const [disQueryBtn, setDisQueryBtn] = useState(true) //是否禁用查询按钮
  const [disClearBtn, setDisClearBtn] = useState(true) //是否禁用清除按钮
  const [loadingQueryBtn, setLoadingQueryBtn] = useState(loading) //是否loading查询按钮

  const changeStartDate = (date) => { // 设置开始时间 
    setDisQueryBtn(false)
    setDisClearBtn(false)
    setStartDate(date)
    datePickerCallback('start', Boolean(date) ? dayjs(date).format('YYYY-MM-DD') : '')
  }

  const changeEndDate = (date) => { //设置结束时间
    setDisQueryBtn(false)
    setDisClearBtn(false)
    setEndDate(date)
    datePickerCallback('end', Boolean(date) ? dayjs(date).format('YYYY-MM-DD') : '')
  }

  // Search按钮事件
  const handleSearch = async () => {
    requestCallback()
    setLoadingQueryBtn(true)
  }

  // Clear按钮事件
  const handleClear = () => {
    clearSearchInputCallback()
    datePickerCallback('start', '')
    datePickerCallback('end', '')
    setStartDate()
    setEndDate()
    setDisQueryBtn(true)
    setDisClearBtn(true)
  }

  useEffect(() => {
    let isMount = true
    // 当输入框有值时 更改按钮状态
    if(isMount) {
      if(!Boolean(queryValueNew)) {
        if((startDate || endDate)) {
          setDisQueryBtn(false)
          setDisClearBtn(false)
        } else {
          setDisQueryBtn(true)
          setDisClearBtn(true)
        }
      } else {
        setDisQueryBtn(false)
        setDisClearBtn(false)
      }
    }
    return () => {
     isMount = false
    }
  }, [queryValueNew, startDate, endDate])

  useEffect(() => {
    let isMount = true
    // 当输入框有值时 更改按钮状态
    if(isMount) {
      setLoadingQueryBtn(loading)
    }
    return () => {
     isMount = false
    }
  }, [loading])

  return (
    <div className="datepick_orders_search_date">
      <div className="datepick_orders_select_date">
        <div className='Polaris-TextField startDateInput'>
          <DatePicker
            className='Polaris-TextField__Input'
            placeholderText='Start'
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            selected={startDate}
            onChange={(date) => changeStartDate(date)}
          />
        </div>
        <div className='timearrow'>
          <img src="/timearrow.png" alt="" />
        </div>
        <div className='Polaris-TextField endDateInput'>
          <DatePicker
            className='Polaris-TextField__Input'
            placeholderText='End'
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            selected={endDate}
            onChange={(date) => changeEndDate(date)} 
          />
        </div>
      </div>
      <div className='searchBtn'>
        <ButtonGroup>
          <Button
            variant='primary'
            onClick={handleSearch}
            loading={loadingQueryBtn}
            disabled={disQueryBtn}
          >
            {intl.get("Search")}
          </Button>
          <Button
            onClick={handleClear}
            disabled={disClearBtn}
          >
            {intl.get("Clear")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default DatePickerComponent;
