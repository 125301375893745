import { Box } from '@shopify/polaris';
import React from 'react';
import OrderItm from './OrderItm';

const ListItemOne  = ({listData,handleSaveData,setPageData,handlePaymentButton,getSearchParam,getOrderListData,setListLoading,getUserPoints})=> {
    return (
        <div>
          <Box className="order_packing_list">
          <OrderItm 
          listData={listData} 
          setPageData={setPageData} 
          handleSaveData={handleSaveData} 
          handlePaymentButton={handlePaymentButton}
          getSearchParam={getSearchParam}
          getOrderListData={getOrderListData}
          getUserPoints={getUserPoints}
          setListLoading={setListLoading}
          />
          </Box>
        </div>
    );
}

export default ListItemOne;
