import { Banner, Box, Card, Modal, Page, Tabs, Toast, Tooltip } from "@shopify/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MoneyIcon, ViewIcon } from "@shopify/polaris-icons";
import ProductFilterBox from "./component/ProductFilterBox";
import CommonTableFilterSkeleton from "./component/CommonTableFilterSkeleton";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom";
import AppBridge from "../../../utils/appBridge";
import utils from "../../../utils/utils";
import intl from "../../../i18n/intl";
import NewPersonProductModal from "./common/NewPersonProductModal";
import AliexpressProductImportModal from "./common/AliexpressProductImportModal";
import {
  reqGetProductManagementProducts,
  reqGetProductManagementProductsAttributes,
  reqGetProductManagementProductsException,
  reqGetProductManagementProductsList,
  reqGetProductManagementProductsQuestionnaire,
  reqGetProductManagementProductsUploadStatus,
  reqGetShopifyNewProductList,
  reqGetSubcribeData,
  reqGetUserPoints,
  reqGetUserSubcribe,
  reqPostSourcingGoodsRequestSave,
  reqPostSubcribeData,
  reqShopifyNewStoreBatchSourcing,
} from "../../../api";
import { useSelector } from "react-redux";
import EnoughQuotaModal from "../ProductSelection/SourcingRequest/component/EnoughQuotaModal";
import EnoughPointsModal from "../ProductSelection/SourcingRequest/component/EnoughPointsModal";
import PersonalAgentModal from "../SubscriptionPaid/components/PersonalAgentModal";
// import TrustooIcon from "../SubscriptionPaid/components/icons/TrustooIcon"
import NoviceImportModal from "../../../components/NoviceGuidance/NoviceImportModal";
import SearchLoading from "../../../components/SearchLoading";
import StoreProductNewModal from "./modal/StoreProductNewModal";
import FirstTimeTips from "./modal/FirstTimeTips";
import { store } from "../../../store";
import { changeBannerData } from "../../../slice";
import './common/index.css'
const ProductManagementClass = ({ callBackFun }) => {
  const [selected, setSelected] = useState(0);
  const [initPage, setInitPage] = useState(true);
  const router = useHistory();
  const [searchLoading,setSearchLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [noviceModal, setNoviceModal] = useState(false); //引导视频
  const [isNewrStore,setIsNewrStore] = useState(false);// 是否新人 店铺产品
  const [temuModal,setTemuModal] = useState(false);//temu弹窗
  const plan_level = useSelector(
    (state) => state.languageReducer.subScriptData
  );
  const [queryParams,setQueryParams] = useState({
    page: 1,
    size: 20,
    
  });
  const [fialedData,setFialedData] = useState({
    ids:[],
    id_name:[]
  })
  const [currentDsymbol,setCurrentDsymbol] = useState('');
   const targetRef = useRef(null); // 用于获取目标元素的引用
   const targetRef2 = useRef(null); // 用于获取目标元素的引用
    const [showPopup, setShowPopup] = useState(false); // 控制弹出框显示
    const [typeTarget, setTypeTarget] = useState("list");
    const [popupStyle, setPopupStyle] = useState({}); // 动态弹出框样式
      const handleTogglePopup = (val) => {
        const targetElement = targetRef.current;
        const targetElement2 = targetRef2.current;
        
        if (targetElement && val === 'targetRef1_tips') {
          const rect = targetElement.getBoundingClientRect();
          setTypeTarget('not')
          // 计算弹出框的位置
          const style = {
            left: rect.left + window.scrollX - 20 + "px", // 加上滚动偏移
            top: rect.bottom + window.scrollY + 10 + "px", // 在目标元素下方
          };
    
          setPopupStyle(style); // 设置弹出框位置
          setShowPopup((prev) => !prev); // 显示或隐藏弹出框
        }else {
          const rect = targetElement2.getBoundingClientRect();
          setTypeTarget('list')
          // 计算弹出框的位置
          const style = {
            left: rect.left + window.scrollX - 20 + "px", // 加上滚动偏移
            top: rect.bottom + window.scrollY + 10 + "px", // 在目标元素下方
          };
          setPopupStyle(style); // 设置弹出框位置
          setShowPopup((prev) => !prev); // 显示或隐藏弹出框
        }
      };
    
      useEffect(()=>{
        let isMount = false;
        if (!isMount && showPopup) {
          setTimeout(() => {
            setShowPopup(false)
          }, 4000);
        }
        return () => {
          isMount = true;
        };
      },[showPopup])
  //   切换导航
  
  const [tabData,setTabData] = useState({});
  
  const getTabData = async () => {
    try {
      if (!initPage) {
        setSearchLoading(true);
      }
      let res = await reqGetProductManagementProducts();
      if (res.code === "200") {
       
        setTabData(res.data)
      }
      setSearchLoading(false);
      setInitPage(false);
    } catch (error) {}
  };
  
  const [attributeData, setAttributeData] = useState({}); // 获取属性值、标签
  const getAttributeList = async () => {
    try {
      let res = await reqGetProductManagementProductsAttributes();
      if (res.code === "200") {
        setAttributeData(res.data);
      }
    } catch (error) {}
  };
  const questionPopTime = useRef(null);
  // 产品费力度调查
  const getUserProductSurvey = async () => {
    try {
      let res = await reqGetProductManagementProductsQuestionnaire({event:'product_management'});
      if (res.code === "200") {
        if (!res.data) {
          window.onProductManagement(plan_level)
        }
      }
    } catch (error) {}
  };
  const [listData, setListData] = useState({
  });
  const dayShow = (isoDate) => {
    // 1. 创建Date对象
    const date = new Date(isoDate);

    const year = date.getFullYear(); // 2025
    let month = date.getMonth() + 1; // 月份从0开始需+1 → 2
    let day = date.getDate(); // 1
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    // 3. 组合成 Y-M-D 格式（自动去除前导零）
    return `${year}-${month}-${day}`;
  };
  // 获取列表数据
  const getPageData = (selectedView, data) => {
    
    setQueryParams((prev)=>{
      getNewData(selectedView,{...prev,...data})
       // 更新 queryParams 状态（确保 setQueryParams 能获得最新数据）
      return {...prev,...data}
    });
  };
  const getNewData = async (selectedView, data) => {
    try {
      const {  pathname } = router.location;
      // const urlParams = new URLSearchParams(search);
      
      if (Number(selectedView) !== 2) {
        // 合并现有的 queryParams 与传入的 data，data 优先级更高
        let mergedParams ={...data};
        // 准备 API 请求参数
        let requestParams = { ...mergedParams };
  
        // 根据 selectedView 设置 import_status
        switch (Number(selectedView)) {
          case 0:
            requestParams.import_status = 1;
            break;
          case 1:
            requestParams.import_status = 2;
            break;
          case 3:
            requestParams.import_status = 3;
            break;
          default:
            break;
        }
        // 如果 source 存在且为数组，则将其转换为逗号分隔的字符串
        if (requestParams.source && Array.isArray(requestParams.source) && requestParams.source.length > 0) {
          requestParams.source = requestParams.source.join(',');
        }
        if (requestParams.product_status && Array.isArray(requestParams.product_status) && requestParams.product_status.length > 0) {
          requestParams.product_status = requestParams.product_status.join(',');
        }
        if (requestParams.product_type && Array.isArray(requestParams.product_type) && requestParams.product_type.length > 0) {
          requestParams.product_type = requestParams.product_type.join(',');
        }
        if (requestParams.product_tag && Array.isArray(requestParams.product_tag) && requestParams.product_tag.length > 0) {
          requestParams.product_tag = requestParams.product_tag.join(',');
        }
        if (requestParams.collection && Array.isArray(requestParams.collection) && requestParams.collection.length > 0) {
          requestParams.collection = requestParams.collection.join(',');
        }
        if (requestParams.sale_count_range && Array.isArray(requestParams.sale_count_range) && requestParams.sale_count_range.length > 0) {
          requestParams.sale_count_range = requestParams.sale_count_range.join(',');
        }
        if (requestParams.sale_price_range && Array.isArray(requestParams.sale_price_range) && requestParams.sale_price_range.length > 0) {
          requestParams.sale_price_range = requestParams.sale_price_range.join(',');
        }
        if (requestParams.price_range && Array.isArray(requestParams.price_range) && requestParams.price_range.length > 0) {
          requestParams.price_range = requestParams.price_range.join(',');
        }
        // 格式化日期字段，如果不为空则调用 dayShow 方法
        if (requestParams.start_date) {
          requestParams.start_date = dayShow(requestParams.start_date);
        }
        if (requestParams.end_date) {
          requestParams.end_date = dayShow(requestParams.end_date);
        }
        if (requestParams.publish_start_date) {
          requestParams.publish_start_date = dayShow(requestParams.publish_start_date);
        }
        if (requestParams.publish_end_date) {
          requestParams.publish_end_date = dayShow(requestParams.publish_end_date);
        }
  
        // 请求产品列表接口
        let response = await reqGetProductManagementProductsList(requestParams);
        if (response.code === "200") {
          const responseData = response.data;
          setListData(responseData);
          // setListData({list:[]});
          if (currentDsymbol === '' && responseData.list.length > 0) {
            setCurrentDsymbol(responseData.list[0]?.price_range?.symbol);
          }
          // ali导入失败状态的产品
         
          // store list导入失败的产品

          // 根据返回数据确定需要轮询的产品 id 数组
          const needImportShopifyIdsArray = responseData?.list
            ?.filter((item) => item.is_publish === 2)
            ?.map((item) => item.id) || [];
          const needImportAliIdsArray = responseData?.list
            ?.filter((item) => item.product_status === 0)
            ?.map((item) => item.id) || [];
          
          if (needImportShopifyIdsArray.length > 0 || needImportAliIdsArray.length > 0) {
            restartPolling({
              shopify_ids: JSON.stringify(needImportShopifyIdsArray),
              product_ids: JSON.stringify(needImportAliIdsArray),
            });
          }
          if (needImportShopifyIdsArray.length === 0 && needImportAliIdsArray.length === 0 && Number(selectedView) === 0) {
            getProductStatus()
          }else {
            setFialedData((prev)=>({
              ...prev,
              product_fail_count:0,
              shopify_fail_count:0
            }))
          }
        }else {
          setListData({})
        }
        
        questionPopTime.current = setTimeout(() => {
          getUserProductSurvey()
        }, 180000);
        setSearchLoading(false)
        // 更新 URL 中的 selectedView 参数
        // 创建新的 URLSearchParams 对象（而不是修改原有参数）
          const newUrlParams = new URLSearchParams();
          // 只添加 selectedView 参数
          newUrlParams.set("selectedView", selectedView);
        const newPath = newUrlParams.toString() ? `${pathname}?${newUrlParams.toString()}` : pathname;
        setCurrentPath(newPath);
        utils.routeListen(newPath);
        window.history.replaceState(null, "", newPath);
      } else {
        clearPolling();
        // 当 selectedView 为 2 的情况，使用另一套参数请求数据
        const params = {
          search_title: data.q || "",
          since_id: data.sinceId || 0,
          size: 10,
          is_next:data?.is_next !=='' ? data?.is_next : '',
          count: 0, // 将总产品数传入
          search_vendor: data.search_vendor || "",
        };
        if (data?.search_status && Array.isArray(data?.search_status) && data?.search_status.length > 0) {
          params.search_status = data?.search_status.join(',');
        }
        // let reqParams = {...data};
        let res = await reqGetShopifyNewProductList(params);
        if (res.code === "200") {
          if (res.data.is_quest && res.data.result.length > 0) {
            setIsNewrStore(true)
          }
          setListData({
            count: res.data.count,
            is_agreement: res.data.is_agreement,
            list:res.data.result,
          });
        }else {
          setListData({})
        }
        setSearchLoading(false)
      }
    } catch (error) {
      console.error("Error in getPageData:", error);
    }
  };
  
  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    if (questionPopTime.current) clearTimeout(questionPopTime.current);
    // setShowPopup(false);
    if (AppBridge.exist()) {
      if (Number(selectedTabIndex) === 0) {
        utils.routeListen(`/admin/product_management?selectedView=0`);
        utils.routeJump1(router, `/admin/product_management?selectedView=0`);
      } else {
        utils.routeListen(
          `/admin/product_management?selectedView=${selectedTabIndex}`
        );
        utils.routeJump1(
          router,
          `/admin/product_management?selectedView=${selectedTabIndex}`
        );
      }
    } else {
      window.history.pushState(null, "", `?selectedView=${selectedTabIndex}`);
    }
    if (Number(selectedTabIndex) !== 0) {
      clearPolling();
    }
    setInitPage(true);
    // setListData({ list: [] });
    if (Number(selectedTabIndex) === 0) {
      setQueryParams({
        page: 1,
        size: 20,
        import_status: 1,
        product_status: [],
        collection: [],
        product_type: [],
        product_tag: [],
        q: "",
        start_date: "",
        end_date: "",
        pid: "",
        sort_by: "",
        sort_order: "",
        source: [],
      })
    }
    if (Number(selectedTabIndex) === 1) {
      setQueryParams({
        page: 1,
        size: 20,
        collection: [],
        q: "",
        price_range:[],
        sale_count_range:[],
        sale_price_range:[],
        publish_start_date:'',
        publish_end_date:'',
        pid: "",
        sort_by: "",
        sort_order: "",
        source: [],
      })
    }
    if (Number(selectedTabIndex) === 2) {
      setQueryParams({
        page: 1,
        size:10,
        search_vendor:'',
        search_status:[],
        is_next:'',
        q: "",
        sinceId: "",
        count: 0, // 将总产品数传入
      })
    }
    if (Number(selectedTabIndex) === 3) {
      setQueryParams({
        page: 1,
        size: 20,
        collection: [],
        q: "",
        pid: "",
        sort_by: "",
        sort_order: "",
      })
    }
    // handleTogglePopup('targetRef2_tips')
    setListData({})
    getPageData(selectedTabIndex);
    getTabData(selectedTabIndex);
    // next
  };
  

  // 产品上传店铺
  const [productStoreData, setProductStoreData] = useState({
    open: false,
    type: 0,
    toStore: [],
  });

  // 产品上传店铺轮询
  const [isPolling, setIsPolling] = useState(true); // 轮询开关
  const timerRef = useRef(null); // 保存定时器引用
  const pollingCountRef = useRef(0); // 保存最新轮询次数
  const pollingTimeRef = useRef(5000); // 保存最新轮询时间

  // 获取产品状态的
  const getProductStatus = async (list) => {
    try {
      const res = await reqGetProductManagementProductsException();
      if (res.code === "200") {
        setFialedData((prev)=>({
          ...prev,
          product_fail_count:res.data.product_fail_count,
          shopify_fail_count:res.data.shopify_fail_count
        }))
      }
    } catch (error) {
      
    }
  };
 
  const tabSelect = useCallback(() => {
    let tab = [
      {
        id: "all-customers-1",
        content: "Not Listed(0)",
        accessibilityLabel: "All customers",
        panelID: "all-customers-content-1",
      },
      {
        id: "accepts-marketing-1",
        content: "Listed(0)",
        panelID: "accepts-marketing-content-1",
      },
      {
        id: "repeat-customers-1",
        content: (
          <Box className="sotre_product_tips">
            <Tooltip
              content="Sync and display all products in your Shopify store, including those imported from Dropshipman and products brought in from other platforms."
              hasUnderline
            >
              <span>Store Products</span>
            </Tooltip>
          </Box>
        ),
        panelID: "repeat-customers-content-1",
      },
      {
        id: "prospects-1",
        content: (
          <Box className="delete_product_tips">
            <Tooltip
              content="Products successfully listed in your store but later deleted from the store admin."
              hasUnderline
            >
              <span>Deleted(0)</span>
            </Tooltip>
          </Box>
        ),
        panelID: "prospects-content-1",
      },
    ]
    if (tabData) {
      tab = [
        {
          id: "all-customers-1",
          content:<span className="targetRef1_tips" ref={targetRef}>Not Listed({tabData?.not_imported_count})</span>,
          accessibilityLabel: "All customers",
          panelID: "all-customers-content-1",
        },
        {
          id: "accepts-marketing-1",
          content: <span className="targetRef2_tips" ref={targetRef2}>Listed({tabData?.imported_count})</span>,
          panelID: "accepts-marketing-content-1",
        },
        {
          id: "repeat-customers-1",
          content: (
            <Box className="sotre_product_tips" 
            >
              <Tooltip
                content="Sync and display all products in your Shopify store, including those imported from Dropshipman and products brought in from other platforms."
                hasUnderline={Number(selected) !== 2}
                hoverDelay={Number(selected) !== 2 ? 700 : 2000}
              >
                <span>Store Products</span>
              </Tooltip>
            </Box>
          ),
          panelID: "repeat-customers-content-1",
        },
        {
          id: "prospects-1",
          content: (
            <Box className="delete_product_tips">
              <Tooltip
                content="Products successfully listed in your store but later deleted from the store admin."
                hasUnderline={Number(selected) !== 3}
                hoverDelay={Number(selected) !== 3 ? 700 : 2000}
              >
                <span>Deleted({tabData?.delete_count})</span>
              </Tooltip>
            </Box>
          ),
          panelID: "prospects-content-1",
        },
      ]
    }
    return tab;
  }, [selected,tabData]);  
  // 处理轮询逻辑
  const startPolling = (data) => {
    try {
      if (timerRef.current) clearTimeout(timerRef.current);
      if (questionPopTime.current) clearTimeout(questionPopTime.current);
      timerRef.current = setTimeout(async () => {
        const response = await reqGetProductManagementProductsUploadStatus(
          data
        );
        const currentCount = pollingCountRef.current + 1; // 获取最新轮询次数
        pollingCountRef.current = currentCount;
        if (response.code === "200") {
          if (response.data.product_success === 1 || response.data.shopify_success === 1) {
            getPageData(selected);
            getTabData(selected);
            setSelected(selected);
            getUserProductSurvey()
            toastFun('Listed successfully')
            if (response.data.shopify_success === 1 && tabData?.imported_count === 0) {
              handleTogglePopup('targetRef2_tips')
             }
            clearPolling();
            return;
          }
        }
        // 根据轮询次数调整轮询时间
        let nextPollingTime;
        if (currentCount < 10) {
          nextPollingTime = 5000; // 前10次，5秒一次
        } else if (currentCount < 20) {
          nextPollingTime = 10000; // 10~20次，10秒一次
        } else if (currentCount < 40) {
          nextPollingTime = 20000; // 20~30次，20秒一次
        } else {
          clearPolling();
          return;
        }

        pollingTimeRef.current = nextPollingTime; // 更新最新轮询时间

        // 继续轮询
        if (isPolling) startPolling(data);
      }, pollingTimeRef.current);
    } catch (error) {}
  };
  // 停止轮询
  const clearPolling = () => {
    clearTimeout(timerRef.current);
    timerRef.current = null;
    setIsPolling(false);
    pollingCountRef.current = 0;
    pollingTimeRef.current = 5000;
  };

  // 开启轮询
  const restartPolling = (data) => {
    setIsPolling(true);
    startPolling(data);
  };
  const [otherImportParams, setOtherImportParams] = useState({
    // 导入其他平台产品数据
    open: false,
    type: "",
    record_id:0,
    al_id:''
  });

  // 会员限制
  const [planData, setPlanData] = useState({
    sourcingLimit: 0, // 当前sourcing次数
    sourcingDay: 3, // sourcing限制次数
    productLimit: 0, // 当前产品次数
    productDay: 10, // 日产品次数
    aliexpressLimit: 0, // 当前速卖通导入次数
    aliexpressDay: 10, // 速卖通x限制导入次数
  });
  // 积分剩余
  const [userPoints, setUserPoints] = useState({
    userPointsData: {
      available_points: 0,
      total_points: 0,
    },
    userPointsRedeem: {
      open: false,
      id: 0,
      isDetail: false,
      is_new: 0,
      isPoints: false,
    },
  });

  // 获取积分
  const getUserPoints = async () => {
    try {
      let res = await reqGetUserPoints();
      if (res.code === "200") {
        setUserPoints((prevValue) => ({
          ...prevValue,
          userPointsData: res.data,
        }));
        
      }
    } catch (error) {}
  };

 
  //   获取套餐功能次数
  const getUserPlan = async () => {
    let response = await reqGetUserSubcribe();
    if (response.code === "200") {
      setPlanData((prevValue) => ({
        ...prevValue,
        sourcingLimit:
          Object.values(response?.data?.limit).length > 0
            ? response?.data?.limit?.sourcing +
              response?.data?.limit?.sourcing_job
            : 0,
        sourcingDay: response?.data?.day?.sourcing_num,
        productLimit: Number(response?.data?.limit?.dsm),
        productDay: Number(response?.data?.day?.dsm_product_num),
        aliexpressLimit:
          Object.values(response?.data?.limit).length > 0
            ? Number(
                response?.data?.limit?.aliexpress +
                  response?.data?.limit?.aliexpress_job
              )
            : 10,
        aliexpressDay: response?.data?.day?.ali_product_num,
      }));
    }
  };

  // 获取当前时间
  // const getCurrentTime = () => {
  //   const timestamp = Date.parse(new Date()) / 1000;
  //   return timestamp;
  // };

  // 高级套餐
  const [premiumData, setPremiumData] = useState({
    open: false,
    agent_list: [],
  });
  const handlePremiumOff = () => {
    setPremiumData((prevValue) => ({
      ...prevValue,
      open: false,
    }));
  };
  // 升级套餐
  const handleMageUpgrade = async (
    id,
    val,
    email = "",
    social = "",
    am_id = ""
  ) => {
    try {
      let param = {
        package_id: id,
        email: email,
        social: social,
        am_id: am_id,
      };
      if (val && val !== "") {
        param.remark = val;
      }
      let res = await reqPostSubcribeData(param);
      if (res.code === "200") {
        if (res?.data?.id !== "") {
          if (id !== 0) {
            window.top.location.href = res.data.url;
            toastFun(res.msg);
          }
        }
      } else {
        toastFun(intl.get("Something wrong"));
      }
    } catch (error) {}
  };
  const [subList, setSubList] = useState(defalutDataSub.subList); //套餐列表

  const getSubscribeList = async () => {
    try {
      let res = await reqGetSubcribeData();
      if (res.code === "200") {
        setPremiumData((prevValue) => ({
          ...prevValue,
          agent_list: res.data.agent_list,
        }));
        let currentSub = subList.map((it, k) => {
          let discount = it;
          for (let j = 0; j < res.data.package.length; j++) {
            if (k === j) {
              discount = { ...discount, ...res.data.package[j] };
            }
          }
          return {
            ...discount,
            user_discounts: res.data.user_discounts,
            level: res.data.level,
            pay_level: res.data.bill_level,
            first_purchase: res.data.first_purchase,
            is_zt: res.data.is_zt,
          };
        });

        switch (res.data.bill_level) {
          case 0:
            let list0 = currentSub.map((item, ind) => {
              let user_discounts = item.user_discounts;

              let discount = item.discount;
              let discount_time = item.discount_time;

              if (res.data.bill_level === ind) {
                return {
                  ...item,
                  button: "Current Plan",
                  disabled: true,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                let data = item;
                if (res.data.trial_days > 0 && ind !== 3) {
                  data = {
                    ...data,
                    button: "7-day free trial",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                } else {
                  data = {
                    ...data,
                    button: "Upgrade",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                }
                return data;
              }
            });
            setSubList(list0);
            break;
          case 1:
            let list1 = currentSub.map((item, ind) => {
              let user_discounts = item.user_discounts;

              let discount = item.discount;
              let discount_time = item.discount_time;

              if (res.data.bill_level === ind) {
                return {
                  ...item,
                  button: "Current Plan",
                  disabled: true,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                let data = item;
                if (
                  res.data.trial_days > 0 &&
                  ((ind >= 2 && ind < 3) || ind === 4)
                ) {
                  data = {
                    ...data,
                    button: "7-day free trial",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                } else if (
                  res.data.trial_days <= 0 &&
                  ((ind >= 2 && ind < 3) || ind === 4)
                ) {
                  data = {
                    ...data,
                    button: "Upgrade",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                } else {
                  data = {
                    ...data,
                    button: "Downgrade",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                }
                return data;
              }
            });
            setSubList(list1);
            break;
          case 2:
            let list3 = currentSub.map((item, ind) => {
              let user_discounts = item.user_discounts;

              let discount = item.discount;
              let discount_time = item.discount_time;

              if (res.data.bill_level === ind) {
                return {
                  ...item,
                  button: "Current Plan",
                  disabled: true,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                let data = item;
                if (res.data.trial_days > 0 && (ind === 1 || ind === 4)) {
                  data = {
                    ...data,
                    button: "7-day free trial",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                } else if (res.data.trial_days <= 0 && ind === 4) {
                  data = {
                    ...data,
                    button: "Upgrade",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                } else {
                  data = {
                    ...data,
                    button: "Downgrade",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                }
                return data;
              }
            });
            setSubList(list3);
            break;
          case 4:
            let list4 = currentSub.map((item, ind) => {
              let user_discounts = item.user_discounts;

              let discount = item.discount;
              let discount_time = item.discount_time;

              if (ind === 3) {
                return {
                  ...item,
                  button: "Current Plan",
                  disabled: true,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                let data = item;
                if (
                  res.data.trial_days > 0 &&
                  (ind === 1 || ind === 2 || ind === 4)
                ) {
                  data = {
                    ...data,
                    button: "7-day free trial",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                } else if (res.data.trial_days <= 0 && ind >= 1 && ind !== 3) {
                  data = {
                    ...data,
                    button: "Upgrade",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                } else {
                  data = {
                    ...data,
                    button: "Downgrade",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                }
                return data;
              }
            });
            setSubList(list4);
            break;
          case 5:
            let list5 = currentSub.map((item, ind) => {
              let user_discounts = item.user_discounts;
              let discount = item.discount;
              let discount_time = item.discount_time;

              if (ind === 4) {
                return {
                  ...item,
                  button: "Current Plan",
                  disabled: true,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                let data = item;
                if (
                  res.data.trial_days > 0 &&
                  (ind === 1 || ind === 2 || ind === 3)
                ) {
                  data = {
                    ...data,
                    button: "7-day free trial",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                } else {
                  data = {
                    ...data,
                    button: "Downgrade",
                    disabled: false,
                    loading: false,
                    user_discounts,
                    discount,
                    discount_time,
                  };
                }
                return data;
              }
            });
            setSubList(list5);
            break;
          default:
            break;
        }
        //   setLoading(false);
      } else {
        //   setLoading(false);
      }
    } catch (error) {}
  };

  // 次数不足
  const [sourcingLimitModal, setSourcingLimitModal] = useState(false);

  // 达到上限
  const [enoughPoint, setEnoughPoint] = useState(false);

  const handleSubmit = (val) => {
    if (val) {
      if (val === 5) {
        setPremiumData((prevValue) => ({
          ...prevValue,
          open: true,
        }));
        setSourcingLimitModal(false);
      }
      if (val === 6) {
        setUserPoints((prevValue) => ({
          ...prevValue,
          userPointsRedeem: {
            ...prevValue.userPointsRedeem,
            isPoints: true,
          },
        }));

        
        if (sourceLess.isSource) {
          if (sourceLess.sourceBulk.length > 0 && sourceLess?.user_product_id === '') {
            handleBatchSourcingRequest(sourceLess.sourceBulk,true)
          }else {
            handleSourcingRequest(sourceLess?.sourceBulk,true)
          }
          
        }else {
          setSourcingLimitModal(false);
          setOtherImportParams((prevValue) => ({
            ...prevValue,
            open: true,
            type: "AliExpress",
          }));
        }
        
      }
      if (val < 5) {
        handleMageUpgrade(val);
      }
    }
  };
  

  // sourcing次数不足
  const [sourceLess, setSourceLess] = useState({
    isSource: false,
    sourceNum: 0,
    sourceBulk: [],
    user_product_id:'',
    func:''
  });

  const planDataRef = useRef(planData);
  const userPointsRef = useRef(userPoints);

  // 在状态更新时同步更新 `useRef`
  useEffect(() => {
    planDataRef.current = planData;
  }, [planData]);

  useEffect(() => {
    userPointsRef.current = userPoints;
  }, [userPoints]);
  // 点击aliexpress 1688 导入产品的按钮 及 这2个按钮触发弹窗里的add product
  const handleOtherOnChange = (type, open,val) => {
    const currentPlanData = planDataRef.current;
    const currentUserPoints = userPointsRef.current;
    if (open) {
      if (plan_level.level === 5 || plan_level.bill_level === 5) {
        if (
          currentUserPoints.userPointsData.available_points < 150 &&
          currentPlanData.aliexpressLimit >= currentPlanData.aliexpressDay
        ) {
          setEnoughPoint(true);
          return;
        }
      }
      if (currentPlanData.aliexpressLimit >= currentPlanData.aliexpressDay) {
        setSourcingLimitModal(true);
        return;
      }
    }
    setOtherImportParams((prevValue) => ({ ...prevValue, open, type }));

    // 当open是false 证明请求成功
   
    if (!open && (type === "AliExpress" || type === "Aliexpress")) {
      
      if (val) {
        setListData((prev)=>{
          let list = prev.list;
         let data = list.map((item)=>{
            if (item.id === val) {
              item.product_status = 0
            }
            return item
          })
          return { ...prev, list:data }
        })
      }
      getPageData(selected);
    }
  };
  // 批量source
  const handleBatchSourcingRequest = async (ids,isPoints) => {
      const params = {
        shopify_product_ids: ids.join(","),
        is_point: isPoints ? 1 : 0,
      };
      try {
        const response = await reqShopifyNewStoreBatchSourcing(params);
        if (response.code === "200") {
          toastFun("Sourcing initiated");
          handleSoureOff(false);
          setPlanData((prevValue)=>({
            ...prevValue,
            sourcingLimit: prevValue.sourcingLimit + ids.length,
          }))
          if (userPoints.userPointsRedeem.isPoints) {
            setUserPoints((prevValue)=>({
              ...prevValue,
              userPointsData:{
                ...prevValue.userPointsData,
                available_points:Number(prevValue.userPointsData.available_points) - (200 * ids.length)
              }
            }))
          }
          getPageData(selected);
          if (sourceLess?.func) {
            sourceLess?.func('page',false,0)
          }
        } else {
          // 不满足sourcing条件时，弹窗提示
          store.dispatch(
            changeBannerData({
              bannerData: {
                show: true,
                desc: response.msg,
              },
            })
          );
        }
        
      } catch (error) {}
    };

    // 单个source产品
      const handleSourcingRequest = async (ids,isPoints) => {
        if (sourceLess?.user_product_id === '') {
          return
        }
        const params = {
          shopify_product_id: ids.join(","),
          user_product_id:sourceLess?.user_product_id,
          is_point: isPoints ? 1 : 0,
        };
        try {
          const response = await reqPostSourcingGoodsRequestSave(params);
          if (response.code === "200") {
            toastFun("Sourcing initiated");
            handleSoureOff(false);
            setPlanData((prevValue)=>({
              ...prevValue,
              sourcingLimit: prevValue.sourcingLimit + ids.length,
            }))
            if (userPoints.userPointsRedeem.isPoints) {
              setUserPoints((prevValue)=>({
                ...prevValue,
                userPointsData:{
                  ...prevValue.userPointsData,
                  available_points:Number(prevValue.userPointsData.available_points) - (200 * ids.length)
                }
              }))
            }
            getPageData(selected);
          } else {
            // 不满足sourcing条件时，弹窗提示
            store.dispatch(
              changeBannerData({
                bannerData: {
                  show: true,
                  desc: response.msg,
                },
              })
            );
            getPageData(selected);
          }
          if (sourceLess?.func) {
            sourceLess?.func('page',false,0)
          }
        } catch (error) {}
      };
      const handleSoureOff = (value)=>{
        setSourcingLimitModal(value)
        setSourceLess((prevValue)=>({
          ...prevValue,
          isSource:false,
          sourceNum:0,
          sourceBulk:[],
          user_product_id:'',
          func:''
        }))
      }

  //   获取数据 更新数据
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      setInitPage(true);
      const { search } = router.location;
      let url = new URLSearchParams(search);
      let selectedView = url.get("selectedView") ? url.get("selectedView") : 0;
      let product_id = url.get("ids") ? url.get("ids") : '';
      if (selectedView && !isNaN(Number(selectedView))) {
        // 获取select_tab参数 重新设置tab
        if (Number(selectedView) <= 0 || Number(selectedView) > 3) {
          setSelected(0);
          url.set("selectedView", 0);
          window.history.replaceState(null, "", window.location.pathname);
        } else {
          setSelected(Number(selectedView));
          url.set("selectedView", selectedView);
          window.history.pushState(null, "", `?selectedView=${selectedView}`);
        }
      } else {
        if (
          selectedView &&
          (Number(selectedView) <= 0 || Number(selectedView) > 3)
        ) {
          setSelected(0);
          url.set("selectedView", 0);
          window.history.replaceState(null, "", window.location.pathname);
        }
      }
      
      let query = {}
      if (Number(selectedView) === 0) {
        query = {
          page: 1,
          size: 20,
          import_status: 1,
          product_status: [],
          collection: [],
          product_type: [],
          product_tag: [],
          q: "",
          start_date: "",
          end_date: "",
          pid:product_id,
          sort_by: "",
          sort_order: "",
          source: [],
        }
      }
      if (Number(selectedView) === 1) {
        query = {
          page: 1,
          size: 20,
          collection: [],
          q: "",
          sale_count_range:[],
          sale_price_range:[],
          price_range:[],
          publish_start_date:'',
          publish_end_date:'',
          pid: product_id,
          sort_by: "",
          sort_order: "",
          source: [],
        }
        
      }
      if (Number(selectedView) === 2) {
        query =  {
          page: 1,
          size: 10,
          search_vendor:'',
          search_status:[],
          is_next:'',
          q: "",
          sinceId: "",
          count: 0, // 将总产品数传入
        }
      }
      if (Number(selectedView) === 3) {
        query = {
          page: 1,
          size: 20,
          collection: [],
          q: "",
          pid:'',
        }
      }
      let id = url.get("import");
      let isPoints = url.get("isPoints");
      if (isPoints) {
        setUserPoints((prevValue)=>({
          ...prevValue,
          userPointsRedeem:{...prevValue.userPointsRedeem,isPoints:true}
        }))
        setOtherImportParams((prevValue) => ({ ...prevValue, open:true, type:"AliExpress" }))
      }else {
        if (id && Number(id) === 1) {
          handleOtherOnChange("AliExpress",true)
        }
      }
      getPageData(selectedView,query);
      getTabData(selectedView);
      getAttributeList();
      getSubscribeList(); // 获取订阅列表
      getUserPoints(); // 获取用户积分
      getUserPlan(); // 获取套餐权益次数
    }
    return () => {
      isMount = true;
      clearPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // const handleViewDetail = () => {
    
  //     let sendMsg = `Hello, I encountered an unknown error during the listing process for the following products. Please forward this to your technical team for resolution.`;

  //     try {
  //       // 填充信息并激活对话弹窗
  //       // window.Intercom('showNewMessage', sendMsg)
  //       window.Willdesk.show();
  //       window.Willdesk.chat(sendMsg);
  //     } catch (error) {
  //       console.info(error);
  //     }
  // };

  const [toast, setToast] = useState(false); // 提示
  const [toastError, setToastError] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  const toggleToast = useCallback(() => setToast((toast) => !toast), []);

  const toastMarkup = toast ? (
    <Toast content={toastMsg} onDismiss={toggleToast} error={toastError} />
  ) : null;

  const toastFun = (msg, error) => {
    setToastError(error);
    setToastMsg(msg);
    setToast(false);
    toggleToast();
  };

  const TemuModal = (
    <Modal
      size="large"
      open={temuModal}
      onClose={()=>setTemuModal(false)}
      title={intl.get("Import products from TEMU")}
    >
      <Modal.Section>
        <div className="temu_content_modal">
          <div>
         {intl.get("Importing TEMU products requires the installation of the Dropshipman Google Extension. Please follow these steps:")}
          <p> {intl.get("1. Download and install the")} <a style={{color: "#4584FE",display:'inline-flex'}} href={'https://chromewebstore.google.com/detail/dropshipman-aliexpresstem/bobhjedlciclpchpelkgiojkmmfgnihg'} target='_blank' rel="noopener noreferrer"> {intl.get("Dropshipman Google Extension.")} <img width={20} height={20} src='/share_icon.png' alt='dsmshipman'></img> </a> </p>
          <p> {intl.get("2. Log in to the Dropshipman Google Extension using your Dropshipman account.")}</p>
          <p> {intl.get("3. Visit the TEMU product details page, and click the \"Add to my product list\" button to collect the product.")} <a style={{color: "#4584FE",display:'inline-flex'}} href={'https://www.temu.com/'} target='_blank' rel="noopener noreferrer">https://www.temu.com/<img width={20} height={20} src='/share_icon.png' alt='dsmshipman'></img> </a></p>
          <p style={{textAlign:'center',marginTop:'10px'}}>
            <img style={{width:'100%'}} src='/temu/image_banner.png' alt='dsmshipman' />
          </p>
          </div>
        </div>
      </Modal.Section>
    </Modal>)
  const contentPage = (
    <Page
      fullWidth={true}
      title="Product Center"
      secondaryActions={[
        {
          icon: MoneyIcon,
          content: "Smart price setting",
          accessibilityLabel: "Smart price setting",
          onAction: () => {
            utils.routeListen("/admin/settings/price");
            utils.routeJump1(router, "/admin/settings/price");
          },
        },
        {
          content: "Add AliExpress product",
          onAction: () => {
            handleOtherOnChange('AliExpress',true)
            
          },
        },
        {
          content: "Import products from TEMU",
          onAction: () => {
            setTemuModal(true)
          },
        },
        {
          content: "Import Ali product reviews",
          onAction: () => {
            window.open(
              "https://apps.shopify.com/sealapps-product-review?locale=zh-CN&show_store_picker=1&utm_campaign=DSM&utm_content=2164b757c48accfqZu&utm_medium=inapp&utm_source=DSM",
              "_blank"
            );
          },
        },
        {
          icon: ViewIcon,
          content: "Watch Tutorial",
          onAction: () => {
            setNoviceModal(true);
          },
        },
      ]}
    >
      {fialedData.shopify_fail_count > 0 ? <Box style={{marginBottom: "20px"}}>
      <Banner
      title="Failed to List Product"
      tone="warning"
      secondaryAction={{content: 'Filter Products', onAction: () => {
        getPageData(selected,{product_status:[7]})
      }}}
      onDismiss={() => { setFialedData({...fialedData,shopify_fail_count:0})}}
    >
      <p>
      {fialedData.shopify_fail_count}&nbsp;{fialedData.shopify_fail_count > 1 ? 'products' : 'product'}&nbsp;failed to list because of missing variant values. Use quick filters, fill in the blanks, and attempt listing again.
      </p>
    </Banner>
      </Box>:''}
      {/* {fialedData.product_fail_count > 0 ? <Box style={{marginBottom: "20px"}}>
      <Banner
      title="Error in Listing Product"
      tone="warning"
      secondaryAction={{content: 'Contact customer service', onAction: () => {
        handleViewDetail()
      }}}
      onDismiss={() => {
        setFialedData({...fialedData,product_fail_count:0})
      }}
    >
      <p>
      There was an unknown error during the product listing process. Kindly contact our online customer service and submit your feedback according to the provided instructions. We will look into the issue and resolve it as soon as possible.
      </p>
    </Banner>
      </Box>:""} */}
      {initPage ? (
        <CommonTableFilterSkeleton />
      ) : (
        <Card roundedAbove="sm" padding="0">
          <Tabs
            tabs={tabSelect()}
            selected={selected}
            onSelect={handleTabChange}
          ></Tabs>
          <ProductFilterBox
            searchLoading={searchLoading}
            handleTogglePopup={handleTogglePopup}
            setSearchLoading={setSearchLoading}
            restartPolling={restartPolling}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            getPageData={getPageData}
            getTabData={getTabData}
            listData={listData}
            setListData={setListData}
            selected={selected}
            currentPath={currentPath}
            setCurrentPath={setCurrentPath}
            attributeData={attributeData}
            setAttributeData={setAttributeData}
            setProductStoreData={setProductStoreData}
            callBackFun={callBackFun}
            userPoints={userPoints}
            setUserPoints={setUserPoints}
            setPlanData={setPlanData}
            planData={planData}
            setSourceLess={setSourceLess}
            setEnoughPoint={setEnoughPoint}
            setSourcingLimitModal={setSourcingLimitModal}
            setNoviceModal={setNoviceModal}
            setOtherImportParams={setOtherImportParams}
            currentDsymbol={currentDsymbol}
          />
        </Card>
      )}
      {toastMarkup}
      {/* 次数不够 */}
      <EnoughQuotaModal
        type={sourceLess.isSource ? "sourcing" : "aliexpress"}
        sourceLess={sourceLess}
        handleSubmit={handleSubmit}
        sourcingLimitModal={sourcingLimitModal}
        userPoints={userPoints}
        subList={subList}
        setSourcingLimitModal={handleSoureOff}
      />
      {/* 会员上限且积分不够 */}
      <EnoughPointsModal
        type={sourceLess.isSource ? "sourcing" : "aliexpress"}
        open={enoughPoint}
        setOpen={setEnoughPoint}
      />
      {/* agent选择 */}
      <PersonalAgentModal
        premiumData={premiumData}
        handlePremiumOff={handlePremiumOff}
        handleMageUpgrade={handleMageUpgrade}
      />

      {/* 产品上架提示、 */}
      <NewPersonProductModal
        restartPolling={restartPolling}
        listData={listData}
        detail={false}
        setListData={setListData}
        productStoreData={productStoreData}
        setProductStoreData={setProductStoreData}
      />

      {/* 速卖通产品导入 */}
      <AliexpressProductImportModal
        data={otherImportParams}
        userPoints={userPoints}
        setPlanData={setPlanData}
        setUserPoints={setUserPoints}
        onChange={handleOtherOnChange}
      />

      {/* 功能视频介绍 */}
      <NoviceImportModal
        upgradeOpen={noviceModal}
        setUpgradeOpen={() => setNoviceModal(false)}
      />

      {/* 新人引导 */}
      <StoreProductNewModal isNewrStore={isNewrStore} setIsNewrStore={setIsNewrStore}/>
      {TemuModal}

      {/* 弹出框 */}
      {showPopup && (
          <FirstTimeTips typeTarget={typeTarget} popupStyle={popupStyle} setShowPopup={setShowPopup} />
        )}
    </Page>
  );

  return <div style={{position:'relative'}} className="product_management_page">
    {contentPage}
    {searchLoading && <SearchLoading />}
    </div>;
};

const defalutDataSub = {
  subList: [
    {
      name: "FREE",
      dsc: intl.get("All essential startup features"),
      symbol: "$",
      title_icon: "/sub/free_cart.png",
      popular: "",
      price: "0.00",
      oldPrice: "",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      button: "Downgrade",
      link: "",
      id: 0,
      source: 3,
      aliexpress_product: 10,
      dsm_product: 10,
      discount: 0,
      discount_time: 0,
      money: 0,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
    },
    {
      name: "PRO",
      dsc: intl.get("All necessary features for growing business"),
      symbol: "$",
      title_icon: "/sub/fly.png",
      popular: "",
      price: "29.9",
      oldPrice: "49",
      date: intl.get("Monthly"),
      isFree: false,
      type: 1,
      button: "7-day free trial",
      link: "",
      id: 1,
      discount: 0,
      dsm_product: 1000,
      aliexpress_product: 1000,
      discount_time: 0,
      money: 29.9,
      source: 30,
      loading: false,
      disabled: false,
      level: 1,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
    },
    {
      name: "PLUS",
      dsc: intl.get("Powerful features for scaling your business"),
      symbol: "$",
      title_icon: "/sub/huojian.png",
      popular: intl.get("Highly Recommend"),
      price: "49.9",
      oldPrice: "79",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      id: 2,
      source: 60,
      dsm_product: 5000,
      aliexpress_product: 5000,
      button: "7-day free trial",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 49.9,
      loading: false,
      disabled: false,
      level: 2,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
    },
    {
      name: "Basic",
      dsc: intl.get("All the basics for you to start a new business"),
      symbol: "$",
      title_icon: "/sub/cart.png",
      popular: "",
      price: "9.9",
      oldPrice: "19",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      source: 10,
      dsm_product: 300,
      aliexpress_product: 300,
      id: 4,
      button: "Downgrade",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 9.9,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
    },
    {
      name: "PREMIUM",
      dsc: intl.get(
        "All Plus features, unlimited product imports, 1999 points, and Personal Agent VIP Services"
      ),
      symbol: "$",
      title_icon: "/sub/ufo.png",
      popular: "",
      price: "149",
      oldPrice: "199",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      source: 300,
      dsm_product: 100000,
      aliexpress_product: 100000,
      id: 5,
      button: "Downgrade",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 149,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
      agent: [
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
      ],
    },
  ],
};
export default withRouter(ProductManagementClass);
