import React, { memo } from "react";
import { Modal } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";

const ArchiveOrderModal = memo(
  ({ archivedData, handleBulkOperationModal, handleArchiveConfigRequest }) => {
    return (
      <div>
        <Modal
          open={archivedData.ArchivedTipsActiveOne}
          onClose={() => handleBulkOperationModal(false, false,'')}
          title={"Order Sourcing Request"}
          primaryAction={{
            content: intl.get("Apply"),
            onAction: handleArchiveConfigRequest,
            loading: archivedData.archiveConfigloadingOne,
          }}
          secondaryActions={[
            {
              content: intl.get("Cancel"),
              onAction: () => handleBulkOperationModal(false, false,''),
            },
          ]}
        >
          <Modal.Section>
            {intl.get("Are you sure you want to source the order? Once confirmed, we'll start sourcing for the await sourcing sku.")}
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default ArchiveOrderModal;
