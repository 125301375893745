import { BlockStack, Box, Button, Collapsible, Spinner, Tabs } from '@shopify/polaris';
import React, { useState } from 'react';
import PremiumPlanModal from '../Modal/PremiumPlanModal';
import Description from '../Description'
import Variants from '../Variants'
import { connect, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import './index.css';
import utils from '../../../../../utils/utils';
import ToolTag from "../../../../../components/ToolTag"
import { reqGetPersonInventoryOrderListVariants } from '../../../../../api';
import PreviewImage from '../../../../../components/PreviewImage';
const ListItemOne  = ({listData,setPageData})=> {
  const router = useHistory();
    // 是否会员
    const [pladData,setPlanData] = useState({
        upgradeOpen:false,
    });
    const person = useSelector((state) => state.languageReducer.subScriptData);
   const priceFun = (val1, val2) => {
      let price = 0;
      if (Number(val1) > 0 && Number(val2) > 0) {
        if (Number(val1) === Number(val2)) {
          price = Number(val1);
        }
        if (Number(val2) > Number(val1)) {
          price = Number(val1) + "-" + Number(val2);
        }
      }
      return price;
    };
       // 产品销售状态
    const productSaleStatusFun = (val) => {
      let text = "For Sale";
      switch (val) {
        case 1:
          text = "For Sale";
          break;
        case 2:
          text = "Offline";
          break;
        default:
          text = "For Sale";
          break;
      }
      return text;
    };
    const tabFun = (val,data)=>{
      let list = [
        {
          id: "accepts-marketing-1",
          content: "Description",
          panelID: "accepts-marketing-content-1",
        },
        {
          id: "repeat-customers-1",
          content: `Variants (${data.variant_count})`,
          panelID: "repeat-customers-content-1",
        },
      ]
     
      return list
    }
    const handleTabChange = async (val,id)=>{
      let list = listData.map((item,ind)=>{
        if (id === item.id) {
          return {...item,selected:val};
        }else {
          return item;
        }
      })
      setPageData((prevValue)=>({
        ...prevValue,
        list:list,
      }))
      let params = {
        product_id:id
      }
      if (val === 1) {
        let res = await reqGetPersonInventoryOrderListVariants(params)
        if (res.code === '200') {
        let lists = list.map((item,ind)=>{
            if (id === item.id) {
              return {...item,selected:val,Variants:res.data};
            }else {
              return item;
            }
          })
          setPageData((prevValue)=>({
            ...prevValue,
            list:lists,
          }))
      }
      }
      
    }
    const [previewData,setPreviewData] = useState({
      showPreview:false,
      previewIndex:0,
      previewList: [],
  });
  const handleSelectImage = ()=>{}
const handlePreview = (item, ind) => {
  const list = [
    {
      keyIndex: ind,
      src: item.image_url,
    },
  ];
  setPreviewData((prevValue)=>
      ({ ...prevValue,showPreview: true, previewIndex: ind, previewList: list }));
};
     //   点击title展开详情
  const handleTitleShow = (ind) => {
    let list = listData.map((item, k) => {
      if (k === ind) {
       return {
        ...item,
        collapsibleShow: item.collapsibleShow === 1 ? 0 : 1,
       }
      }else {
        return item
      }
    });
    setPageData((prevValue)=>({
      ...prevValue,
      list:list,
    }))
  };
  const handlePreorder = (id)=>{
    if (person.level !== 5) {
      setPlanData((prevValue)=>({
        ...prevValue,
        upgradeOpen:true,
    }))
    return
    }
    utils.routeListen('/admin/customService/pur_inventory?record_id='+id)
    utils.routeJump1(router,'/admin/customService/pur_inventory?record_id='+id)
  }
    return (
        <div>
           <BlockStack gap="500">
          {listData.map((item, ind) => (
            <Box className={item.checked ?  "list_item_card list_item_card_checked" : "list_item_card"} key={ind}>
            
              <Box className="list_item_top">
                <Box className="list_item_top_img">
                  <div
                    className="image_box_overlay "
                    onClick={() => handleSelectImage(item)}
                  >
                    <div className="image_preview_action">
                      <div
                        className="image_preview__svg"
                        onClick={(event) => {
                          event.stopPropagation();
                          handlePreview(item, ind);
                        }}
                      >
                        <svg
                          viewBox="64 64 896 896"
                          data-icon="eye"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill="#fff"
                            d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                          ></path>
                        </svg>
                      </div>
                      <img
                        src={item.image_url}
                        className="list_item_image"
                        alt="product"
                      />
                    </div>
                  </div>
                </Box>
                <Box className="list_item_top_title">
                  <Box
                    onClick={() => handleTitleShow(ind)}
                    title={item.title}
                  >
                    {item.title}
                  </Box>
                </Box>
                <Box className="list_item_top_cost">
                  <Box>
                    Cost: ${priceFun(item.min_cost, item.max_cost)}
                  </Box>
                  <Box>
                    Price: ${priceFun(item.min_price, item.max_price)}
                  </Box>
                </Box>
                <Box className="list_item_top_status_imoprt">
                <span
                style={{
                  display: "inline-flex",
                  maxWidth: "100%",
                  minWidth: "68px",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "16px",
                  padding: "2px 8px",
                  backgroundColor: 'rgba(205, 254, 225, 1)',
                  borderRadius: "16px",
                  color: '#202333',
                  outline: " 1px solid transparent",
                }}
              >
              {'Listed'}
              </span>
                </Box>
                <Box className="list_item_top_status_sale">
                  <ToolTag
                    content={productSaleStatusFun(item.product_status)}
                  />
                </Box>
                <Box className="list_item_top_status_button">
                  {/* 
                  如果产品是未发布则 显示
                  product_status：0 正常 1商品删除 2商品下架 3商品无库存
                  is_publish ：0:未发布 1:已发布 2:正在发布中 3线上店铺删除
                   */}
                    <Box style={{ marginRight: "16px" }}>
                        <Button
                          variant="primary"
                          disabled={item.product_status !== 1}
                          onClick={() => {handlePreorder(item.id)
                          }}
                        >
                          Preorder
                        </Button>
                    </Box>
                </Box>
              </Box>
              <Collapsible
                open={
                  item.collapsibleShow === 1
                }
                id={"basic-collapsible" + ind}
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
                <Box className="product_info_content">
                  <Tabs
                    tabs={tabFun(item.selected,item)}
                    selected={item.selected}
                    onSelect={(val) => handleTabChange(val, item.id, item)}
                  ></Tabs>
                  <Box>
                    {item.selected === 0 && (
                     <Description
                        product={listData}
                        current={ind}
                        item={item}
                      />
                    )}
                    
                    {item.selected === 1  && 
                      Object.values(item.Variants).length > 0 ? <div>
                        <Variants
                          data={item.Variants}
                          listData={listData}
                        />
                      </div> :
                       <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Spinner accessibilityLabel="loading" size="large" />
                       </div>
                      }
                  </Box>
                </Box>
              </Collapsible>
            </Box>
          ))}
        </BlockStack>

        {/* 图片预览 */}
          <PreviewImage
        isShow={previewData.showPreview}
        index={previewData.previewIndex}
        imageList={previewData.previewList}
        onClose={() => {setPreviewData((prevValue)=>({...prevValue,showPreview:false}))
        }}
      />
      <PremiumPlanModal upgradeOpen={pladData.upgradeOpen} setUpgradeOpen={()=>{
            setPlanData((prevValue)=>({
              ...prevValue,
              upgradeOpen:false,
          }))
          }}/>
        </div>
    );
}


export default connect()(withRouter(ListItemOne));
