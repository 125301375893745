import {Modal} from "@shopify/polaris";
import React, { useState } from "react";
const Index = ({setRedeemOrderData,open,setIsEnough,needNum,currentPoints}) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const handleSave = async () => {
        setSaveLoading(true);
        setRedeemOrderData((prevValue)=>({...prevValue,isShow:true}))
        setIsEnough((prevValue)=>({...prevValue,isOk:false}))
        setTimeout(() => {
          setSaveLoading(false)
        }, 3000);
      };
      const formatNumber = (value) => {
        value += '';
        const list = value.split('.');
        const prefix = list[0].charAt(0) === '-' ? '-' : '';
        let num = prefix ? list[0].slice(1) : list[0];
        let result = '';
        while (num.length > 3) {
          result = `,${num.slice(-3)}${result}`;
          num = num.slice(0, num.length - 3);
        }
    
        if (num) {
          result = num + result;
        }
        return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
      };
  return (
    <div className="upgrade_new_feature">
      <Modal
        open={open}
        onClose={() => 
          {
            setIsEnough((prevValue)=>({...prevValue,isOk:false}))
            setSaveLoading(false)
          }}
        title={'Redeem Products'}
        primaryAction={{
          content: "Done",
          loading:saveLoading,
          onAction: () => {
            handleSave()
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setIsEnough((prevValue)=>({...prevValue,isOk:false}))
              setSaveLoading(false)
            }
          },
        ]}
      >
        <Modal.Section>
        <p style={{fontSize:'14px'}}>{`The selected item requires ${formatNumber(needNum)} points. Are you sure you want to proceed? (Remaining points: ${formatNumber(currentPoints)})`}</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
