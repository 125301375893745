import {
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import utils from "../../../../utils/utils";
import { XIcon, ChevronUpIcon, ChevronDownIcon } from "@shopify/polaris-icons";
import { reqPostCardDismiss } from "../../../../api";
const AbilityBox = ({ router, data, setPageData, toastFun }) => {
  const abilityList = {
    level1: [
      {
        title: "Find Trending Products to Sell",
        dsc: "Dropshipman offers over 1 million premium products in 13 major categories and 150 subcategories, tailored for your trending and niche product needs.",
        isShow: true,
        button: "Find  products",
        icon: "/dashboard/ability_icon1.png",
        link: "/admin/product_selection?select_tab=0",
        id: 1,
      },
      {
        title: "Automatic Pricing for Products",
        dsc: "Enhance pricing efficiency by 80% with automatic pricing rules that apply seamlessly during product import.",
        isShow: true,
        button: "Set Pricing Rules",
        link: "/admin/settings/price",
        id: 2,
        icon: "/dashboard/ability_icon2.png",
      },
      {
        title: "AI Product Title and Description Optimization",
        dsc: "One-click AI optimization transforms your product titles and descriptions into unique, search-friendly content.",
        isShow: true,
        button: "Optimize Now",
        link: "/admin/import-list",
        id: 3,
        icon: "/dashboard/ability_icon3.png",
      },
      {
        title: "Reduce Costs by 50% with Points",
        dsc: "Earn points at Dropshipman by completing beginner tasks, referrals, order fulfillment, and credit recharges. Use these points to offset fulfillment costs and redeem products, saving up to 50%.",
        isShow: true,
        button: "Get Points",
        link: "/admin/points",
        id: 4,
        icon: "/dashboard/ability_icon4.png",
      },
      {
        title: "Dropshipman Plans for Your Success",
        dsc: "With Dropshipman plans, you gain access to product selection, automated sourcing, and a dedicated manager to boost your dropshipping success.",
        isShow: true,
        button: "Choose a plan",
        link: "/admin/subscribe",
        id: 5,
        icon: "/dashboard/ability_icon5.png",
      },
      {
        title: "Dropshipman Academy",
        dsc: "Dropshipman Academy offers courses on product selection, advertising, and social media marketing to accelerate your business success.",
        isShow: true,
        button: "Consult Us",
        link: "",
        id: 6,
        icon: "/dashboard/ability_icon6.png",
      },
    ],
    level2: [
      {
        title: "Product Sourcing Request",
        dsc: "For any product you're looking for, launch a sourcing request on Dropshipman, and our team will assist you with sourcing and provide competitive offers.",
        isShow: true,
        button: "Sourcing Request",
        icon: "/dashboard/ability_icon7.png",
        link: "/admin/sourcing",
        id: 1,
      },
      {
        title: "Automatic Order Fulfillment",
        dsc: "Simply set Dropshipman Credits as your default payment and choose a shipping method, and your orders will be automatically paid and fulfilled, boosting efficiency by 20%.",
        isShow: true,
        button: "Set up now",
        icon: "/dashboard/ability_icon8.png",
        link: "/admin/settings/payment",
        id: 2,
      },
      {
        title: "Import Products from AliExpress and Temu",
        dsc: "Easily collect your desired products from AliExpress and Temu with one click, accessing all product details and supporting publication to your store.",
        isShow: true,
        button: " Import Now",
        icon: "/dashboard/ability_icon9.png",
        link: "/admin/import-list",
        id: 3,
      },

      {
        title: "Reduce Costs by 50% with Points",
        dsc: "Earn points at Dropshipman by completing beginner tasks, referrals, order fulfillment, and credit recharges. Use these points to offset fulfillment costs and redeem products, saving up to 50%.",
        isShow: true,
        button: "Get Points",
        link: "/admin/points",
        id: 4,
        icon: "/dashboard/ability_icon4.png",
      },
      {
        title: "Dropshipman Plans for Your Success",
        dsc: "With Dropshipman plans, you gain access to product selection, automated sourcing, and a dedicated manager to boost your dropshipping success.",
        isShow: true,
        button: "Choose a plan",
        link: "/admin/subscribe",
        id: 5,
        icon: "/dashboard/ability_icon5.png",
      },
      {
        title: "Dropshipman Academy",
        dsc: "Dropshipman Academy offers courses on product selection, advertising, and social media marketing to accelerate your business success.",
        isShow: true,
        button: "Consult Us",
        link: "",
        id: 6,
        icon: "/dashboard/ability_icon6.png",
      },

      {
        title: "Customize Your Store's Tracking Page",
        dsc: "Customize your store’s tracking page using Parcel Panel Tracking, giving customers real-time access to order tracking and boosting satisfaction.",
        isShow: true,
        button: "Customize it now",
        link: "",
        target: "https://apps.shopify.com/parcelpanel?source=dropshipman",
        icon: "/dashboard/ability_icon10.png",
        id: 7,
      },
      {
        title: "Auto-Manage Returns and Exchanges",
        dsc: "Automate the management of returns and exchanges in your store with Parcel Panel Return, including return labels and policies, to enhance efficiency and customer satisfaction.",
        isShow: true,
        button: "Set it now",
        link: "",
        target:
          "https://apps.shopify.com/parcel-panel-returns-exchanges?source=dropshipman",
        icon: "/dashboard/ability_icon11.png",
        id: 8,
      },
      {
        title: "Boost Your Dropshipping Store with PageFly",
        dsc: "Build high-converting, stunning product pages in minutes with PageFly. Drag, drop, and customize your store with ease—no coding required.",
        isShow: true,
        button: "Get PageFly free",
        link: "",
        target:
          "https://pagefly.io?ref=n93ysRBS&target=app-listing",
        icon: "/dashboard/ability_icon17.png",
        id: 9,
      },
    ],
    level3: [
      {
        title: "Custom Brand Packaging",
        dsc: "Customize your own brand packaging and thank-you cards to build and enhance your brand image, increasing brand value and customer loyalty.",
        isShow: true,
        button: "Customize Now",
        icon: "/dashboard/ability_icon12.png",
        link: "/admin/customService/custom-packaging",
        id: 1,
      },
      {
        title: "Stock Up for Faster Fulfillment",
        dsc: "Dropshipman offers private storage services to help you stock up in advance, allowing for faster order fulfillment and reducing processing time by 3-5 days.",
        isShow: true,
        button: "Stock Up Now",
        icon: "/dashboard/ability_icon13.png",
        link: "/admin/customService/inventory",
        id: 2,
      },
      {
        title: "Personal Agent for Your Success",
        dsc: "Benefit from a dedicated Personal Agent with 5+ years of experience, providing tailored services such as brand packaging design, optimal logistics recommendations, quality factory sourcing, and product photography to enhance your dropshipping success.",
        isShow: true,
        button: "View Details",
        link: "/admin/subscribe?link=premium",
        icon: "/dashboard/ability_icon15.png",
        id: 3,
      },
      {
        title: "Reduce Costs by 50% with Points",
        dsc: "Earn points at Dropshipman by completing beginner tasks, referrals, order fulfillment, and credit recharges. Use these points to offset fulfillment costs and redeem products, saving up to 50%.",
        isShow: true,
        button: "Get Points",
        link: "/admin/points",
        icon: "/dashboard/ability_icon4.png",
        id: 4,
      },
      {
        title: "Save 5% on Payments with Dropshipman Credits",
        dsc: "Enjoy secure, automated payment services with Dropshipman Credits, helping you save a minimum of 5% on payment costs.",
        isShow: true,
        button: "View Credits",
        link: "/admin/wallet",
        icon: "/dashboard/ability_icon14.png",
        id: 5,
      },
      {
        title: "Customize Your Store's Tracking Page",
        dsc: "Customize your store’s tracking page using Parcel Panel Tracking, giving customers real-time access to order tracking and boosting satisfaction.",
        isShow: true,
        button: "Customize it now",
        link: "",
        target: "https://apps.shopify.com/parcelpanel?source=dropshipman",
        icon: "/dashboard/ability_icon11.png",
        id: 6,
      },
      {
        title: "Build Your Customer Loyalty System",
        dsc: "Build a customer loyalty framework with Loloyal, utilizing points and referrals to increase retention and customer loyalty.",
        isShow: true,
        button: "View Details",
        link: "",
        target:
          "https://apps.shopify.com/loloyal?source=dropshipman",
        icon: "/dashboard/ability_icon16.png",
        id: 7,
      },
      {
        title: "Boost Your Dropshipping Store with PageFly",
        dsc: "Build high-converting, stunning product pages in minutes with PageFly. Drag, drop, and customize your store with ease—no coding required.",
        isShow: true,
        button: "Get PageFly free",
        link: "",
        target:
          "https://pagefly.io?ref=n93ysRBS&target=app-listing",
        icon: "/dashboard/ability_icon17.png",
        id: 8,
      },
    ],
    level4: [
      {
        title: "Personal Agent for Your Success",
        dsc: "Benefit from a dedicated Personal Agent with 5+ years of experience, providing tailored services such as brand packaging design, optimal logistics recommendations, quality factory sourcing, and product photography to enhance your dropshipping success.",
        isShow: true,
        button: "View Details",
        link: "/admin/subscribe?link=premium",
        icon: "/dashboard/ability_icon15.png",
        id: 1,
      },
      {
        title: "Stock Up for Faster Fulfillment",
        dsc: "Dropshipman offers private storage services to help you stock up in advance, allowing for faster order fulfillment and reducing processing time by 3-5 days.",
        isShow: true,
        button: "Stock Up Now",
        icon: "/dashboard/ability_icon13.png",
        link: "/admin/customService/inventory",
        id: 2,
      },
      {
        title: "Custom Brand Packaging",
        dsc: "Customize your own brand packaging and thank-you cards to build and enhance your brand image, increasing brand value and customer loyalty.",
        isShow: true,
        button: "Customize Now",
        icon: "/dashboard/ability_icon12.png",
        link: "/admin/customService/custom-packaging",
        id: 3,
      },
      {
        title: "Customize Your Store's Tracking Page",
        dsc: "Customize your store’s tracking page using Parcel Panel Tracking, giving customers real-time access to order tracking and boosting satisfaction.",
        isShow: true,
        button: "Customize it now",
        link: "",
        target: "https://apps.shopify.com/parcelpanel?source=dropshipman",
        icon: "/dashboard/ability_icon11.png",
        id: 4,
      },
      {
        title: "Build Your Customer Loyalty System",
        dsc: "Build a customer loyalty framework with Loloyal, utilizing points and referrals to increase retention and customer loyalty.",
        isShow: true,
        button: "View Details",
        link: "",
        target:
          "https://apps.shopify.com/loloyal?source=dropshipman",
        icon: "/dashboard/ability_icon16.png",
        id: 5,
      },
    ],
  };
  const [relAbilityList, setRelAbilityList] = useState([]); //当前展示的数组

  useEffect(() => {
    let isMount = false;
    if (!isMount && data?.func_services.length > 0) {
      let list = [];
      let list_key = "level1";
      switch (data.user_rank) {
        case 1:
          list_key = "level1";
          break;
        case 2:
          list_key = "level2";
          break;
        case 3:
          list_key = "level3";
          break;
        case 4:
          list_key = "level4";
          break;
        default:
          break;
      }

      list = abilityList[list_key]
        .filter((item) => data?.func_services.includes(item.id))
        .slice(0, 5);
      setRelAbilityList(list);
    }
    if (!isMount && data?.func_services.length === 0) {
      setRelAbilityList([]);
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOffCard = async (id) => {
    try {
      let res = await reqPostCardDismiss({ type: 4, key: id });
      if (res.code === "200") {
        let list1 = data.func_services.filter((num) => num !== id)
          ? data.func_services.filter((num) => num !== id)
          : [];
        setPageData((prevValue) => ({
          ...prevValue,
          func_services: list1,
        }));
        toastFun("Saved successfully");
      }
    } catch (error) {}
  };

  const handleViewDetail = (data) => {
    if (data.link) {
      utils.routeListen(data.link);
      utils.routeJump1(router, data.link);
    }
    if (data.target) {
      window.open(data.target, "_blank");
    }
    if (data.button === "Consult Us") {
      let sendMsg = `Hi, I would like to inquire about Dropshipman Academy`;

      try {
        // 填充信息并激活对话弹窗
        // window.Intercom('showNewMessage', sendMsg)
        window.Willdesk.show();
        window.Willdesk.chat(sendMsg);
      } catch (error) {
        console.info(error);
      }
    }
  };
  const handleShow = (id) => {
    let list = relAbilityList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isShow: !item.isShow,
        };
      } else {
        return item;
      }
    });
    setRelAbilityList(list);
  };
  return (
    <div>
      <Box className="ability_box">
        <Box className="ability_box_content ability_box_content_pc">
          <BlockStack gap={400}>
            {relAbilityList.map((item, ind) => (
              <Card key={ind}>
                <Box className="ability_box_content_item">
                  <Box className="ability_box_content_item_close">
                    <Button
                      variant="tertiary"
                      onClick={() => handleOffCard(item.id)}
                    >
                      <Tooltip content="Dismiss">
                        <Icon source={XIcon} tone="base" />
                      </Tooltip>
                    </Button>
                  </Box>

                  <Box className="ability_box_content_item_l">
                    <Box className="ability_box_content_item_l_title">
                      {item.title}
                    </Box>
                    <Box className="ability_box_content_item_l_dsc">
                      {item.dsc}
                    </Box>
                    <Box className="ability_box_content_item_l_button">
                      <Button onClick={() => handleViewDetail(item)}>
                        {item.button}
                      </Button>
                    </Box>
                  </Box>
                  <Box className="ability_box_content_item_r">
                    <LazyLoad once>
                      {" "}
                      <img
                        width={168}
                        height={123}
                        src={item.icon}
                        alt="Dropshipman"
                      />
                    </LazyLoad>
                  </Box>
                </Box>
              </Card>
            ))}
          </BlockStack>
        </Box>
        <Box className="ability_box_content ability_box_content_mobile">
          <BlockStack gap={400}>
            {relAbilityList.map((item, ind) => (
              <Card key={ind}>
                <Box
                  className="ability_box_content_item"
                  style={{ display: "block" }}
                >
                  <Box
                    className="ability_box_content_item_close_button"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItem: "center",
                    }}
                  >
                    <Box className="ability_box_content_item_l_title">
                      {item.title}
                    </Box>
                    <Box style={{ marginRight: "8px" }}>
                      <Button
                        variant="tertiary"
                        onClick={() => handleOffCard(item.id)}
                      >
                        <Tooltip content="Dismiss">
                          <Icon source={XIcon} tone="base" />
                        </Tooltip>
                      </Button>
                    </Box>
                    {item.isShow ? (
                      <Button
                        variant="tertiary"
                        onClick={() => {
                          handleShow(item.id);
                        }}
                      >
                        <Icon source={ChevronUpIcon} tone="base" />
                      </Button>
                    ) : (
                      <Button
                        variant="tertiary"
                        onClick={() => {
                          handleShow(item.id);
                        }}
                      >
                        <Icon source={ChevronDownIcon} tone="base" />
                      </Button>
                    )}
                  </Box>

                  <Collapsible
                    open={item.isShow}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <Box className="ability_box_content_item_l">
                      <Box className="ability_box_content_item_l_dsc">
                        {item.dsc}
                      </Box>
                      <Box className="ability_box_content_item_l_button">
                        <Button onClick={() => handleViewDetail(item)}>
                          {item.button}
                        </Button>
                      </Box>
                    </Box>
                    <Box className="ability_box_content_item_r">
                      <LazyLoad once>
                        {" "}
                        <img
                          width={168}
                          height={123}
                          src={item.icon}
                          alt="Dropshipman"
                        />
                      </LazyLoad>
                    </Box>
                  </Collapsible>
                </Box>
              </Card>
            ))}
          </BlockStack>
        </Box>
      </Box>
    </div>
  );
};

export default AbilityBox;
