import { Box, Button } from '@shopify/polaris';
import React, { useState } from 'react';
import PremiumPlanModal from '../Modal/PremiumPlanModal';
import { connect} from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import './index.css';
import utils from '../../../../../utils/utils';
const ListItemOne  = ({listData})=> {
  const router = useHistory();
    // 是否会员
    const [pladData,setPlanData] = useState({
        upgradeOpen:false,
    });
    const handlePurchase = (record_id)=>{
      utils.routeListen('/admin/points_purchase?record_id='+record_id)
      utils.routeJump1(router,'/admin/points_purchase?record_id='+record_id)
    }

    // 跳转至详情页
    const handleDetail = (record_id)=>{
        utils.routeListen('/admin/redeem_products/product-info?filter='+record_id)
        utils.routeJump1(router,'/admin/redeem_products/product-info?filter='+record_id)
    }
    const formatNumber = (value) => {
      value += "";
      const list = value.split(".");
      const prefix = list[0].charAt(0) === "-" ? "-" : "";
      let num = prefix ? list[0].slice(1) : list[0];
      let result = "";
      while (num.length > 3) {
        result = `,${num.slice(-3)}${result}`;
        num = num.slice(0, num.length - 3);
      }
  
      if (num) {
        result = num + result;
      }
      return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
    };
    return (
        <div>
            <Box className="all_packing_list all_packing_list_redeem">
          {listData.map((item,ind)=><Box key={ind} className="all_packing_list_item all_packing_list_item_rdeem">
            <Box className="all_packing_list_item_img" onClick={()=>handleDetail(item.record_id)}>
              <img src={item?.product_image ? item?.product_image[0] : ''} alt="Dropshipman" />
            </Box>
            <Box className="all_packing_list_item_box">
              <Box className="all_packing_list_item_text" title={item.title}>{item.title}</Box>
              <Box className="all_packing_list_item_price"><img src="/sub/points_icon.png" alt="Dropshipman" /> {item.min_point === item.max_point ? formatNumber(item.min_point) : formatNumber(item.min_point) + ' - ' + formatNumber(item.max_point)} Points</Box>
            </Box>
            <Box className="all_packing_list_item_button"><Button variant="primary" onClick={()=>handlePurchase(item.record_id)}>Redeem</Button></Box>
          </Box>)}
          </Box>
          <PremiumPlanModal upgradeOpen={pladData.upgradeOpen} setUpgradeOpen={()=>{
            setPlanData((prevValue)=>({
              ...prevValue,
              upgradeOpen:false,
          }))
          }}/>
        </div>
    );
}

export default connect()(withRouter(ListItemOne));
