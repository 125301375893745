import React from "react";
import { useEffect, useState } from "react";
import "./index.css"
const Index = () => {
   
  const [timers, setTimers] = useState([]); // 定时器数组

  const [timeData,setTimeData] = useState({
    day:"00",
    hour: "23",
    min: "59",
    sec: "59",
  })

  const handleDis = (time) => {
    
    
    if (time) {
      const timer = setInterval(() => {
        setTimeData((prevValue)=>({
            ...prevValue,
            ...discountSubTime(time)
        }))
      }, 1000);
      let timerArr = timers;
      timerArr.push(timer);
      setTimers(timerArr);
    }
  };
  // 清空所有定时器
  const clearAllTimers = () => {
   
    timers.forEach((timer) => {
      clearInterval(timer);
    });
    setTimers([]);
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount) {

      handleDis(1733457600);
    }
    return () => {
      isMount = true;
      clearAllTimers();
    };
    // eslint-disable-next-line
  }, []);
  const discountSubTime = (endTime) => {
    const padZero = (num) => (num < 10 ? "0" + num : num); // 格式化函数，给小于10的数添加前导0

  const nowTime = new Date(); // 当前时间
  const remainingSeconds = Math.max(0, Math.floor((endTime * 1000 - nowTime.getTime()) / 1000)); 
  // 计算剩余秒数，确保不会出现负值

  const day = Math.floor(remainingSeconds / (60 * 60 * 24)); // 天数
  const hour = Math.floor((remainingSeconds % (60 * 60 * 24)) / (60 * 60)); // 小时数
  const min = Math.floor((remainingSeconds % (60 * 60)) / 60); // 分钟数
  const sec = remainingSeconds % 60; // 秒数

  return {
    day: padZero(day),
    hour: padZero(hour),
    min: padZero(min),
    sec: padZero(sec),
  };
  };
 
  
  return (
    <div className="time_close_box">
    <div style={{ padding: "4px 0" }}>
        <div className="sub_paid_time_detail">
        <div className="sub_paid_time_detail_content">
            <div className="sub_paid_time_detail_list_modal">
            <span className="sub_paid_time_detail_item_modal black_discount_modal_time">
                {
                timeData.day
                }
            </span>
            <span style={{ color: "#fff", fontSize: 18 }}>︰</span>
            <span className="sub_paid_time_detail_item_modal black_discount_modal_time">
                {
                timeData.hour
                }
            </span>
            <span style={{ color: "#fff", fontSize: 18 }}>︰</span>
            <span className="sub_paid_time_detail_item_modal black_discount_modal_time">
                {
                timeData.min
                }
            </span>
            <span style={{ color: "#fff", fontSize: 18 }}>︰</span>
            <span className="sub_paid_time_detail_item_modal black_discount_modal_time">
                {
                timeData.sec
                }
            </span>
            </div>
        </div>
        </div>
    
    </div>
    </div>
  );
};

export default Index;
