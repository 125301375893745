import React from 'react';

const Home =()=> {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect width="20" height="20" rx="4" fill="#7126EF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.22278 12.4396C7.47483 11.6815 7.47483 10.4524 8.22278 9.69429C8.97073 8.93619 10.1834 8.93619 10.9313 9.69429C11.5511 10.3225 11.6573 11.2742 11.2499 12.0132L12.0483 12.8823C12.242 13.0932 12.2303 13.4233 12.0223 13.6196C11.8142 13.816 11.4885 13.8042 11.2948 13.5933L10.5244 12.7546C9.79336 13.1768 8.84649 13.0718 8.22278 12.4396ZM8.95071 10.4321C8.60478 10.7827 8.60478 11.3512 8.95071 11.7018C9.29663 12.0524 9.85749 12.0524 10.2034 11.7018C10.5493 11.3512 10.5493 10.7827 10.2034 10.4321C9.85749 10.0815 9.29663 10.0815 8.95071 10.4321Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.43494 2.81337C6.51059 2.33249 6.87552 1.96926 7.25005 2.00206C7.62458 2.03486 7.86687 2.45127 7.79122 2.93215C7.73899 3.26417 7.54886 3.5401 7.31287 3.66833C7.38176 4.03138 7.53065 4.42653 7.75951 4.85377C7.90262 5.1209 8.07152 5.39396 8.26624 5.67294C8.82135 5.51301 9.40483 5.43304 10.0167 5.43304C10.6185 5.43304 11.1929 5.51041 11.7398 5.66514C11.9322 5.38888 12.0993 5.11842 12.2411 4.85377C12.47 4.42653 12.6189 4.03138 12.6877 3.66833C12.4518 3.5401 12.2616 3.26417 12.2094 2.93215C12.1337 2.45127 12.376 2.03486 12.7506 2.00206C13.1251 1.96926 13.49 2.33249 13.5657 2.81337C13.6367 3.26501 13.4273 3.65979 13.0913 3.73328C13.0176 4.25238 12.8635 4.73882 12.6288 5.19263C12.5179 5.40719 12.3902 5.62078 12.2459 5.83341C12.5184 5.93796 12.7837 6.06297 13.0417 6.20846C13.9583 6.7254 14.6806 7.44462 15.2083 8.36612C15.7361 9.28763 16 10.3271 16 11.4846C16 12.6421 15.7333 13.6844 15.2 14.6116C14.6667 15.5387 13.9444 16.2607 13.0333 16.7777C12.1222 17.2946 11.1167 17.5531 10.0167 17.5531C8.91667 17.5531 7.90833 17.2946 6.99167 16.7777C6.075 16.2607 5.34722 15.5387 4.80833 14.6116C4.26944 13.6844 4 12.6421 4 11.4846C4 10.3271 4.26944 9.28763 4.80833 8.36612C5.34722 7.44462 6.075 6.7254 6.99167 6.20846C7.24147 6.06758 7.49807 5.94591 7.76149 5.84342C7.61438 5.62749 7.48448 5.41055 7.37179 5.19263C7.13715 4.73882 6.98298 4.25238 6.9093 3.73328C6.5733 3.65979 6.3639 3.26501 6.43494 2.81337ZM10.0182 15.5553C11.123 15.5553 12.0076 15.1829 12.6718 14.4379C13.336 13.6929 13.6681 12.7085 13.6681 11.4846C13.6681 10.2475 13.336 9.25971 12.6718 8.52139C12.0076 7.78308 11.123 7.41392 10.0182 7.41392C8.9002 7.41392 8.00909 7.77975 7.34486 8.51142C6.68064 9.24308 6.34853 10.2342 6.34853 11.4846C6.34853 12.7218 6.68064 13.7096 7.34486 14.4479C8.00909 15.1862 8.9002 15.5553 10.0182 15.5553Z" fill="white"/>
            </svg>    
        )}

export default Home;
