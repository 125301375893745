import React from 'react';

const Index =()=> {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.872192 4.36905C0.000244141 6.08035 0.000244141 8.32056 0.000244141 12.801V19.201C0.000244141 23.6814 0.000244141 25.9216 0.872192 27.6329C1.63918 29.1382 2.86302 30.362 4.36832 31.129C6.07961 32.001 8.31982 32.001 12.8002 32.001H19.2002C23.6807 32.001 25.9209 32.001 27.6322 31.129C29.1375 30.362 30.3613 29.1382 31.1283 27.6329C32.0002 25.9216 32.0002 23.6814 32.0002 19.201V12.801C32.0002 8.32056 32.0002 6.08035 31.1283 4.36905C30.3613 2.86376 29.1375 1.63991 27.6322 0.872924C25.9209 0.000976562 23.6807 0.000976562 19.2002 0.000976562H12.8002C8.31982 0.000976562 6.07961 0.000976562 4.36832 0.872924C2.86302 1.63991 1.63918 2.86376 0.872192 4.36905ZM19.0526 8.00098C19.3495 10.5156 20.7519 12.0129 23.1796 12.1665V14.9884C21.7694 15.1227 20.5312 14.662 19.0917 13.7982V19.0771C19.0917 25.7958 11.7675 27.8882 8.82999 23.0796C6.93937 19.989 8.10148 14.5469 14.1679 14.3357V17.3207C13.9042 17.3645 13.6327 17.421 13.3632 17.49C13.1601 17.5417 12.9589 17.6005 12.7655 17.6663C11.412 18.1173 10.6542 18.9812 10.8651 20.488C11.2792 23.3771 16.5761 24.2313 16.1347 18.5876V8.0105L19.0526 8.00098Z" fill="#1B1F26" fillOpacity="0.72"/>
</svg>
           )}

export default Index;
