import React, { memo, useEffect, useState } from 'react'
import {
  Page, Card, Checkbox, Layout, 
  SkeletonBodyText,  Toast, Text, BlockStack,
  Banner
} from '@shopify/polaris'
import NavTabs from "../components/NavTabs"
import OptionsAutocomplete from '../../OrderList/components/Modal/AutoFulfillmentModal/components/OptionsAutocomplete'
import intl from "../../../../i18n/intl"
import { reqGetOrderPaymentConfig, reqPostUpdateOrderPaymentConfig } from '../../../../api'
import SettingPrompt from '../../../../components/SettingPrompt';
import UpgradePlanModal from '../../SubscriptionPaid/components/UpgradePlanModal';
import { store } from '../../../../store'
import AppBridge from '../../../../utils/appBridge'
import { changeBannerData } from '../../../../slice'
import { connect, useSelector } from 'react-redux'
import './index.css'
const OrderPayment = memo(() => {
  const [shippingDataSource, setShippingDataSource] = useState([])
  const [userShippingDataSource, setUserShippingDataSource] = useState([])
  const [loadingPage, setLoadingPage] = useState(true)//页面加载
  const [focus,setFocus] = useState(false)
  const [saveLoading,setSaveLoading] = useState(false)// 保存状态
  const [saveDisabled,setSaveDisabled] = useState(true);//禁止保存，未修改状态
  const [autoComplete, setAutoComplete] = useState()
  const [autoPay, setAutoPay] = useState();
  const [upgradeModal,setUpgradeModal] = useState(false);//升级付费弹窗
  const [bannerData,setBannerData] = useState({
    first:false,
    second:false
  })
  // const removeDataRef = useRef([])
  const initData = async () => {
    setLoadingPage(true)
    const res = await reqGetOrderPaymentConfig()
    if (res.code === '200' || res.code === 200) {
      const { auto_complete, auto_pay, shippingData, userShipping } = res.data
      if (userShipping.length < 10) {
        userShipping.push({
          id: 0,
          country_code: "",
          country_name: "",
          is_del: 0,
          child: [{
            shipping_methods: "",
            shipping_name: ""
          }],
        })
      }
      setAutoComplete(auto_complete === 1)
      setAutoPay(auto_pay === 1)
      setShippingDataSource(shippingData)
      setUserShippingDataSource(userShipping)
      setTimeout(() => {
        setLoadingPage(false)
      }, 660)
    }
  }

  const handleChangeUserShipping = (newData) => {
    let person = store.getState().languageReducer.subScriptData;
    if (person.level === 0 || person.level === 4) {
      setUpgradeModal(true);
      return
    }
    // removeDataRef.current = oldData
    setSaveDisabled(false)
    setUserShippingDataSource(newData)
  }
  const handleSetShippingDataSource = (newData)=>{
    let person = store.getState().languageReducer.subScriptData;
    if (person.level === 0 || person.level === 4) {
      setUpgradeModal(true);
      return
    }
    // removeDataRef.current = oldData
    setSaveDisabled(false)
   setShippingDataSource(newData)
  }

  const handleSave = async () => {
    try {
    setSaveLoading(true)
    const res = await reqPostUpdateOrderPaymentConfig({
      auto_pay: autoPay ? 1 : 0,
      auto_complete: autoComplete ? 1 : 0,
      userShippingData: JSON.stringify(userShippingDataSource)
    })
    if (res.code === '200' || res.code === 200) {
      // success
      setSaveDisabled(true)
      setSaveLoading(false)
      toggleToast('Saved successfully')
    }else{
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:res.msg
      }}));
      // toggleToast(res.msg,true)
    }
    } catch (error) {
    }
  }
  // checkbox change
  const	handleCheckboxChange = (value, id) => {
    let person = store.getState().languageReducer.subScriptData;
    if (person.level === 0 || person.level === 4) {
      setUpgradeModal(true);
      return
    }
    setSaveDisabled(false)
    let userData = userShippingDataSource;
    switch (id) {
      case 'SetAutoPay':
        setAutoPay(value)
        if (!value) {
          setBannerData((prevValue)=>({
            ...prevValue,
            first:true,
            second:false
          }))
          return;
        }
            if (userData &&  userData[0].is_del === 0 && userData[0].child[0]?.shipping_methods === '') {
              setFocus(true)
              setBannerData((prevValue)=>({
                ...prevValue,
                first:false,
                second:true
              }))
            }
        break;
        case 'SetAutoCompleteOrder':
          setAutoComplete(value)
          // 检测是否选择设置自动付款
          if (!autoPay) {
            setBannerData((prevValue)=>({
              ...prevValue,
              first:true,
              second:false
            }))
            // toggleToast('Please set the wallet as the default payment method first', false);
            return;
          }
          // 检测是否设置默认物流方式
          
          // for (let index = 0; index < userData.length; index++) {
            if (userData &&  userData[0].is_del === 0 && userData[0].child[0]?.shipping_methods === '') {
              setFocus(true)
              setBannerData((prevValue)=>({
                ...prevValue,
                first:false,
                second:true
              }))
              // toggleToast('Please set the default shipping method first', false);
            }

          // }
    
          // if (hasSetShipping) {
          //   setFocus(true)
          //   toggleToast('Please set the default shipping method first', false);
          //   return;
          // }
      break;
      
      default:
        break;
    }
	
	};

  const shopify_level = useSelector(state => state.languageReducer.subScriptData.shopify_level);
  const level = useSelector(state => state.languageReducer.subScriptData.level);
  const shopifyLevel = ()=>{
    let flag = true;
    if ((level === 0 || level === 4) && (shopify_level === 'Development' || shopify_level === 'Developer Preview' || shopify_level === 'staff' || shopify_level === 'staff_business')) {
      flag = false
    }
    return flag;
  }
  useEffect(() => {
    initData()
  }, [])
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
  const initSkeletonPage = (
    <div className="SettingsPage__Content">
      <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
    </div>
  )

  const contentNode = (
    <section className="SettingsPage__Box">
      <NavTabs selected="Order payment" saveData={handleSave} loading={saveLoading} disabled={saveDisabled}/>
      {
        loadingPage ? initSkeletonPage : (
          <div className="SettingsPage__Content">
            {!shopifyLevel() ? <section className="Panel_Content Panel_Content_level">
              <BlockStack gap={400}>
              <Card>
                <div style={{display:'flex'}}>
                <Text variant="headingMd" as="h2">
                {intl.get("Automatic payment (Dropshipman order)")}
                
              </Text>
              <div style={{width:'112px',marginLeft:'5px', display:'flex',alignItems:'center',padding:"2px 6px",background:"#202333",color:"#fff",borderRadius:"16px"}}>
                    <span style={{lineHeight:1}}><img style={{width:16}} src="/crown.png" alt="Dropshipman" /></span>
                    <span style={{fontSize:12}}>Advanced plan</span>
                </div>
              </div>
              {bannerData.first && <Banner
        title={intl.get("Default payment method")}
        tone="warning"
        onDismiss={() => {
          setBannerData((prevValue)=>({
            ...prevValue,
            first:false,
            second:false
          }))
        }}
      >
        <p>
        {intl.get("Please set the credits as the default payment method first")}
        </p>
      </Banner>}
      {bannerData.second && <Banner
        title={intl.get("Default shipping method")}
        tone="warning"
        onDismiss={() => {
          setBannerData((prevValue)=>({
            ...prevValue,
            first:false,
            second:false
          }))
        }}
      >
        <p>
        {intl.get("Please set the default shipping method first")}
        </p>
      </Banner>}
                <section className="Import_Checkbox">
                  <Checkbox
                    id="SetAutoPay"
                    label={intl.get(
                      "Set credits payment as the default payment method"
                    )}
                    checked={autoPay}
                    onChange={newChecked => {
                      handleCheckboxChange(newChecked,'SetAutoPay')
                    }}
                  />
                  <div className="Import_Checkbox_Note">
                    {intl.get(
                      "When there is an order fulfilled by Dropshipman, the default payment method is credits payment."
                    )}
                  </div>
                </section>
                <section className="Import_Checkbox">
                  <Checkbox
                    id="SetAutoCompleteOrder"
                    label={intl.get(
                      "Set up automatic payments for Dropshipman orders."
                    )}
                    checked={autoComplete}
                    onChange={newChecked =>{
                      handleCheckboxChange(newChecked,'SetAutoCompleteOrder')
                    } }
                  />

                  <div className="Import_Checkbox_Note">
                    {intl.get(
                      "When there is an order fulfilled by Dropshipman, and the default logistics method has been set for the order, and the balance in the credits is sufficient to pay the order, the system automatically completes the payment of the order without manual payment."
                    )}
                  </div>
                </section>
              </Card>

              <Card>
              <Text variant="headingMd" as="h2">
              {intl.get("Default logistics settings")}
              </Text>
                <div className='country_option_div'>
                  <div>{intl.get("Country/Region")}</div>
                  <div>{intl.get("Options (UP to 5)")}</div>
                </div>
                <br />
                <OptionsAutocomplete
                  focus={focus}
                  setFocus={setFocus}
                  shippingDataSource={shippingDataSource}
                  userShippingDataSource={userShippingDataSource}
                  handleChangeUserShipping={handleChangeUserShipping}
                  setShippingDataSource={(data) => handleSetShippingDataSource(data)}
                />
              </Card>
              </BlockStack>
            </section> : <section className="Panel_Content">
              <BlockStack gap={400}>
              <Card>
                <Text variant="headingMd" as="h2">
                {intl.get("Automatic payment (Dropshipman order)")}
                <img style={{verticalAlign:'bottom',marginLeft:'5px'}} src="/crown.png" alt="Order Tracking"></img>
              </Text>
              {bannerData.first && <Banner
        title={intl.get("Default payment method")}
        tone="warning"
        onDismiss={() => {
          setBannerData((prevValue)=>({
            ...prevValue,
            first:false,
            second:false
          }))
        }}
      >
        <p>
        {intl.get("Please set the credits as the default payment method first")}
        </p>
      </Banner>}
      {bannerData.second && <Banner
        title={intl.get("Default shipping method")}
        tone="warning"
        onDismiss={() => {
          setBannerData((prevValue)=>({
            ...prevValue,
            first:false,
            second:false
          }))
        }}
      >
        <p>
        {intl.get("Please set the default shipping method first")}
        </p>
      </Banner>}
                <section className="Import_Checkbox">
                  <Checkbox
                    id="SetAutoPay"
                    label={intl.get(
                      "Set credits payment as the default payment method"
                    )}
                    checked={autoPay}
                    onChange={newChecked => {
                      handleCheckboxChange(newChecked,'SetAutoPay')
                    }}
                  />
                  <div className="Import_Checkbox_Note">
                    {intl.get(
                      "When there is an order fulfilled by Dropshipman, the default payment method is credits payment."
                    )}
                  </div>
                </section>
                <section className="Import_Checkbox">
                  <Checkbox
                    id="SetAutoCompleteOrder"
                    label={intl.get(
                      "Set up automatic payments for Dropshipman orders."
                    )}
                    checked={autoComplete}
                    onChange={newChecked =>{
                      handleCheckboxChange(newChecked,'SetAutoCompleteOrder')
                    } }
                  />

                  <div className="Import_Checkbox_Note">
                    {intl.get(
                      "When there is an order fulfilled by Dropshipman, and the default logistics method has been set for the order, and the balance in the credits is sufficient to pay the order, the system automatically completes the payment of the order without manual payment."
                    )}
                  </div>
                </section>
              </Card>

              <Card>
              <Text variant="headingMd" as="h2">
              {intl.get("Default logistics settings")}
              </Text>
                <div className='country_option_div'>
                  <div>{intl.get("Country/Region")}</div>
                  <div>{intl.get("Options (UP to 5)")}</div>
                </div>
                <br />
                <OptionsAutocomplete
                  focus={focus}
                  setFocus={setFocus}
                  shippingDataSource={shippingDataSource}
                  userShippingDataSource={userShippingDataSource}
                  handleChangeUserShipping={handleChangeUserShipping}
                  setShippingDataSource={(data) => handleSetShippingDataSource(data)}
                />
              </Card>
              </BlockStack>
            </section>}
          </div>)
      }

    </section>)

  return (
    <div className={AppBridge.exist() ? "Setting__Page_Content Content_within" : "Setting__Page_Content Content_outside"} id="settings_class">
      <Page
        title={AppBridge.exist() ? '' : intl.get("Settings")}
        primaryAction={
          // AppBridge.exist() ? '' :
          {
          content: intl.get("Save"),
          onAction: () => handleSave(),
          disabled:saveDisabled,
          loading:saveLoading
        }}
        separator
      >
        {contentNode}
        {toastMarkup}
         {/* 升级为会员弹窗 */}
         <UpgradePlanModal upgradeOpen={upgradeModal} setUpgradeOpen={()=>setUpgradeModal(false)}/>
        {/* 未保存拦截弹窗 */}
				<SettingPrompt
					disabledSaveData={saveDisabled}
					url="/admin/settings/payment"
					saveData={handleSave}
				/>
      </Page>
    </div>
  )
})

export default connect()(OrderPayment)
