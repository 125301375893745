import React, { Component } from 'react';
import { AppProvider, Frame, Loading } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { reqGetUserAuthInstall } from "../../../api";
// import utils from '../../../utils/utils'
class install extends Component {
  state = {
    errorMessage: ''
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    this.UserAuthInstall(params);
  }

  /**
   * 通过从Shopify进入App的链接参数，后端判断当前用户的安装状态
   * 
   * @param {*} par Get请求链接上的参数对象
   */
  UserAuthInstall = async (par) => {
    try {
      const params = {
        'shop': par.get("shop"),
        'hmac': par.get("hmac"),
        'host': par.get("host"),
        'locale': par.get("locale"),
        'session': par.get("session"),
        'timestamp': par.get("timestamp")
      };
  
      /**
       * 返回code说明
       *  301 代表用户安装
       *  302 代表用户进入app
       */
      const response = await reqGetUserAuthInstall(params);
      switch (response.code) {
        case 301:
          window.location.href = response.url;
          break;

        case 302:
          this.props.history.push(response.url);
          // utils.routeListen(response.url)
          // utils.routeJump(this,response.url)
          break;

        default:
          this.setState({ errorMessage: response.msg });
      }

    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { errorMessage } = this.state;

    const showError = (errorMessage !== '') && (
      <div style={
        {
          padding: '2rem',
          textAlign: 'center',
          fontSize: '1.6rem',
          fontWeight: 'bold',
          color: '#b74a4a'
        }}
      >
        {errorMessage}
      </div>
    )

    return (
        <AppProvider i18n={translations}>
          <div style={{height: '100px'}}>
            <Frame>
              <Loading />
              {showError}
            </Frame>
          </div>
        </AppProvider>
      )
  }
}

export default install;