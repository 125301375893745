import React from 'react';
import {
  Modal,
} from "@shopify/polaris";

import { PayPalButton } from "react-paypal-button-v2";

import { getSessionToken } from '@shopify/app-bridge-utils';

import AppBridge from "../../utils/appBridge";
import { API_DOMAIN } from '../../utils/constants';
import utils from '../../utils/utils';

import intl from '../../i18n/intl';

const PostagePayPalComponent = (props) => {
  const { open, loading, payData, closeModalCallback, refreshPageCallback } = props

  const closeModal = () => {
    closeModalCallback(!open, 'PayPal')
  }

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      title={<div style={{display: 'flex', flexWrap: 'wrap'}}>
        <p style={{width: '100%'}}>{intl.get("Order") + ':' + payData?.orderArr}</p>
        <p>{`${intl.get("Total price")}:$${payData?.totalPrice}USD`}</p>
      </div>}
      secondaryActions={{
        content: intl.get('Cancel'),
        onAction: () => closeModal(),
      }}
      loading={loading}
    >
      <Modal.Section>
        {(!loading && (payData?.orderId !== 0)) ? (
          <div style={{ margin: 'auto', minHeight: '50px', maxWidth: '200px', position: 'relative' }}>
            <PayPalButton
              createOrder={(data, actions) => {
                let OrderId = payData?.orderId;
                return OrderId;
              }}
              catchError={(error) => {
                console.warn('error', error);
              }}
              // 交易完成
              onApprove={async (data, actions) => {
                let timeStr = new Date().getTime();

                let sessionToken = '';
                if (AppBridge.exist()) {
                  sessionToken = await getSessionToken(AppBridge.app);
                  sessionToken = 'ShopifyBridge ' + sessionToken;
                } else {
                  sessionToken = 'ShopifyAdmin ' + utils.getStore('S_AUTHORIZATION');
                }

                let shopDomain = utils.getStore('S_SHOP_DOMAIN') || window.S_SHOP_DOMAIN;

                let header = {
                  'Authorization': sessionToken,
                  'usertoken': utils.getStore('S_USER_TOKEN') || window.S_USER_TOKEN,
                  'shopdomain': shopDomain,
                  'timestr': timeStr,
                  'sign': utils.getSign(shopDomain, timeStr),
                  'content-type': 'application/json'
                }

                return fetch(API_DOMAIN + "/api/v1/capture-paypal-transaction", {
                  method: 'post',
                  headers: header,
                  body: JSON.stringify({
                    orderID: data.orderID
                  })
                }).then(function (res) {
                  return res.json();
                }).then(function (details) {
                  if (details.code === '200') {
                    alert('Transaction successfully!');
                  } else {
                    alert('Sorry, your transaction could not be processed.');
                  }

                  // 关闭弹窗
                  closeModal();

                  // 刷新页面
                  refreshPageCallback();
                });
              }}
              style={{
                label: 'paypal',
                size: '30'
              }}
              options={{
                clientId: (API_DOMAIN === 'https://api.dropshipman.com') ? "AcHhJoi0eq-ynRib-_Z0LoUnwNcjS4EiHu9K9yptCSTOlGcCFWFtZI27e0o3gre_RWnRtuubqg-uRwVB" : 'sb',
              }}
            />
          </div>
        ) : null}
      </Modal.Section>
    </Modal>
  )
}

export default PostagePayPalComponent;