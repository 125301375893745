import React from 'react';

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
// 组件文档：https://github.com/xiaolin/react-image-gallery

import './index.css';

export default function ImageGalleryShow(props) {

  let imagesArray = props.images;
  const originalHeight = props.originalHeight
  const originalWidth = props.originalWidth
  const showThumbnails = props.showThumbnails
  const isshowFullscreenButton = props.isshowFullscreenButton

  // 全局配置
  const globalConfig = {
    showPlayButton: false,
    autoPlay: false,
    thumbnailPosition: 'bottom',  // 缩略图位置 top, right, bottom, left
    showThumbnails:!showThumbnails ? false : true,
    useBrowserFullscreen: false,
    onErrorImageURL: '/default_image.png',  // 图片地址有误的时候，显示默认图
    slideOnThumbnailOver: false,  // 鼠标经过缩略图切换图片
    lazyLoad: true,
    showFullscreenButton:!isshowFullscreenButton ? false : true, //是否显示全屏缩略图按钮
  }

  let imageGalleryCarousel = '';

  if (imagesArray.length > 0) {
    let images = [];
    for (let i=0; i<imagesArray.length; i++) {
      images.push({
        original: imagesArray[i],
        originalHeight:originalHeight === undefined ? '':'200px',
        originalWidth:originalWidth === undefined ? '':'200px',
        
        thumbnail: imagesArray[i],
        thumbnailClass: 'image-carousel-thumbnail'
      })
    }
    imageGalleryCarousel = <ImageGallery items={images} {...globalConfig} />

  } else {
    imageGalleryCarousel = <center><img src='/default_image.png' alt="" /></center>
  }

  return (
    <div>
      {imageGalleryCarousel}
    </div>
  );
}