import React from 'react';

const Index =()=> {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5586 16.0014C11.5586 13.552 13.5513 11.5593 16.0007 11.5593C18.45 11.5593 20.4428 13.552 20.4428 16.0014C20.4428 18.4508 18.45 20.4435 16.0007 20.4435C13.5513 20.4435 11.5586 18.4508 11.5586 16.0014Z" fill="#1B1F26" fillOpacity="0.72"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.22812 10.6709C6.22812 8.22153 8.22084 6.2288 10.6702 6.2288H21.3313C23.7806 6.2288 25.7734 8.22153 25.7734 10.6709V21.3319C25.7734 23.7813 23.7806 25.774 21.3313 25.774H10.6702C8.22084 25.774 6.22812 23.7813 6.22812 21.3319V10.6709ZM9.78173 16.0014C9.78173 19.4307 12.5723 22.2204 16.0007 22.2204C19.4291 22.2204 22.2196 19.4307 22.2196 16.0014C22.2196 12.5721 19.4291 9.78248 16.0007 9.78248C12.5723 9.78248 9.78173 12.5721 9.78173 16.0014ZM22.6639 10.6709C23.3999 10.6709 23.9965 10.0743 23.9965 9.33827C23.9965 8.60228 23.3999 8.00564 22.6639 8.00564C21.9279 8.00564 21.3313 8.60228 21.3313 9.33827C21.3313 10.0743 21.9279 10.6709 22.6639 10.6709Z" fill="#1B1F26" fillOpacity="0.72"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.13159 32.001L24.9 31.9841C28.809 31.9805 32.0047 28.7796 32.0002 24.8696L31.9834 7.10123C31.9789 3.19218 28.7779 -0.00257416 24.8689 0.000979524L7.1005 0.0178595C3.19145 0.0223016 -0.00330658 3.22328 0.000247102 7.13233L0.0171271 24.9007C0.0215692 28.8098 3.22255 32.0045 7.13159 32.001ZM10.6702 27.5509H21.3313C24.7597 27.5509 27.5502 24.7612 27.5502 21.3319V10.6709C27.5502 7.2416 24.7597 4.45196 21.3313 4.45196H10.6702C7.2418 4.45196 4.45128 7.2416 4.45128 10.6709V21.3319C4.45128 24.7612 7.2418 27.5509 10.6702 27.5509Z" fill="#1B1F26" fillOpacity="0.72"/>
            </svg>
           )}

export default Index;
