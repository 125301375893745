import React, { useState, memo, useEffect } from "react";
import {
  Checkbox,
  EmptyState,
  Modal,
  Thumbnail,
  Toast,
} from "@shopify/polaris";
import {
  reqGetPackagingInventory,
  reqPostUpdateOrderPackaging,
} from "../../../../../../api";
import { useGetState } from "ahooks";
import intl from "../../../../../../i18n/intl";
import utils from '../../../../../../utils/utils'
import _ from "lodash";
import { useHistory } from "react-router-dom";
const PackingOrderModal = memo(({ item, open, setisShowPackingOrderModal }) => {
  const [relevancyOrderData, setRelevancyOrderData, getRelevancyOrderData] =
    useGetState({
      relevancyLoading: false,
      relevancyOrderStatus: "",
      relevancyData: [],
      packageId: "",
      saveLoading: false,
    });
  const history = useHistory();
  // 关联包装sku数据
  const handlePackagingData = (data) => {
    setRelevancyOrderData((prevValue) => {
      const params = {
        ...prevValue,
        relevancyLoading: true,
        relevancyOrderStatus: data.dsm_payment_status,
      };
      return params;
    });
    getPackagingData(data);
  };

  // 获取订单礼品包装
  const getPackagingData = async (data) => {
    let res = await reqGetPackagingInventory({ id: data.dsm_record });
    if (res.code === "200") {
      setRelevancyOrderData((prevValue) => {
        const params = {
          ...prevValue,
          packageId: data.dsm_record,
          relevancyData: res.data,
          relevancyLoading: false,
        };
        return params;
      });
    } else {
      // toggleToast(res.msg, true);
    }
  };

  // 关联包装弹窗
  const handleRelevancyPackingSave = async () => {
    if (getRelevancyOrderData().packageId) {
      setRelevancyOrderData((prevValue) => {
        const params = {
          ...prevValue,
          saveLoading: true,
        };
        return params;
      });
      let param = {
        id: relevancyOrderData.packageId,
        packaging: relevancyOrderData.relevancyData,
      };
      try {
        let res = await reqPostUpdateOrderPackaging(param);
        if (res.code === "200") {
          setRelevancyOrderData((prevValue) => {
            const params = {
              ...prevValue,
              saveLoading: false,
            };
            return params;
          });
          setisShowPackingOrderModal(false);
          // this.setState({});
          toggleToast("Saved successfully", false);
        } else {
          setRelevancyOrderData((prevValue) => {
            const params = {
              ...prevValue,
              saveLoading: false,
            };
            return params;
          });
          // toggleToast(res.msg, true);
        }
      } catch (error) {}
    }
  };
 
  useEffect(() => {
    let isMount = true;
    if (open && isMount) {
      handlePackagingData(item);
    }
    return () => (isMount = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]); //数据初始化


  const checkStatus = () => {
    const checkStatusList = _.filter(
      relevancyOrderData.relevancyData,
      (item) => item.checked === 1
    );
    if (_.isEmpty(checkStatusList)) {
      return {
        checked: 0,
      };
    } else if (
      checkStatusList.length > 0 &&
      checkStatusList.length === relevancyOrderData.relevancyData.length
    ) {
      return {
        checked: 1,
      };
    }else {
      return {
        checked: "indeterminate",
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleBatchCheckBox = (newChecked) => {
    handleCommonChildCheck(newChecked);
  }; //批量选择事件
  const handleCommonChildCheck = (newChecked) => {
    
    const checkList = [...relevancyOrderData.relevancyData];
    _.forEach(checkList, (item) => {
      if (checkStatus().checked === "indeterminate" && newChecked) {
        item.checked = 1;
      } else if (newChecked) {
        item.checked = 1;
      } else {
        item.checked = 0;
      }
    });
    setRelevancyOrderData((prevValue) => ({
      ...prevValue,
      relevancyData: checkList,
    }));
    // setDataSource(checkList)
  }; //批量选中后，子元素也全部选中，反之同理

   // 关联包装复选
   const handleChangeCheckBox = (ind) => {
    let list = relevancyOrderData.relevancyData;
    let status = list[ind].checked;
    list[ind].checked = status === 1 ? 0 : 1;

    setRelevancyOrderData((prevValue) => {
      const params = {
        ...prevValue,
        relevancyData: list,
      };
      return params;
    });
  };
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);


  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
  return (
    <div style={{ margin: "0 0" }}>
      {toastMarkup}
      <Modal
        open={open}
        onClose={() => setisShowPackingOrderModal(false)}
        title={intl.get("Packaging Select")}
        loading={relevancyOrderData.relevancyLoading}
        primaryAction={
          (relevancyOrderData.relevancyOrderStatus === "Unpaid" || relevancyOrderData.relevancyOrderStatus === "Partially paid") &&
          relevancyOrderData.relevancyData.length > 0 && {
            content: intl.get("Save"),
            onAction: () => handleRelevancyPackingSave(),
            disabled: relevancyOrderData.relevancyLoading,
            loading: relevancyOrderData.saveLoading,
          }
        }
        secondaryActions={
          [
            {
              content: intl.get("Close"),
              onAction: () => setisShowPackingOrderModal(false),
              disabled: relevancyOrderData.relevancyLoading,
            },
          ]
        }
      >
        <Modal.Section>
          <div className="packaging_checkbox_item">
            <div>
              <Checkbox
                disabled={relevancyOrderData.relevancyOrderStatus !== "Unpaid" && relevancyOrderData.relevancyOrderStatus !== "Partially paid" }
                checked={checkStatus().checked}
                onChange={(val) =>{handleBatchCheckBox(val)}}
              />
            </div>
            <div style={{width:60,marginRight:'10px'}}></div>
            <div className="packaging_checkbox_item_checkbox"><strong>Packing</strong></div>
            <div className="packaging_checkbox_item_checkbox_quantity"><strong>Quantity Used</strong></div>
            <div className="packaging_checkbox_item_checkbox_available"><strong>Avaliable</strong></div>
          </div>
          {relevancyOrderData.relevancyData.length > 0 ? (
            <div className="packaging_checkbox_list">
              {/* 是否支付状态 未支付则展示复选框 ，支付完成则不展示复选框*/}
              {(relevancyOrderData.relevancyOrderStatus === "Unpaid" || relevancyOrderData.relevancyOrderStatus === "Partially paid")
                ? relevancyOrderData.relevancyData.map((item, ind) => (
                  <div key={ind} className="packaging_checkbox_item">
                    <div>
                      <Checkbox
                        checked={item.checked === 1 ? true : false}
                        onChange={() => handleChangeCheckBox(ind)}
                      />
                    </div>
                    <div style={{marginRight:'10px'}}>
                      <Thumbnail source={item.sku_image} alt="dropshipman" />
                    </div>
                    <div className="packaging_checkbox_item_checkbox">
                      <p className="packaging_checkbox_item_checkbox_title">
                        {item.title}
                      </p>
                      <p>{item.option_one}</p>
                      <p>{item.option_two}</p>
                      <p>{item.option_three}</p>
                    </div>
                    <div className="packaging_checkbox_item_checkbox_quantity">1</div>
                    <div className="packaging_checkbox_item_checkbox_available">{item.available}</div>
                  </div>
                ))
                : relevancyOrderData.relevancyData.map((item, ind) => (
                  item.checked === 1 && (<div key={ind} className="packaging_checkbox_item">
                    <div>
                      <Checkbox
                        checked={item.checked === 1 ? true : false}
                        disabled={true}
                        onChange={() => handleChangeCheckBox(ind)}
                      />
                    </div>
                      {item.checked === 1 && (
                        <div style={{marginRight:'10px'}}>
                          <Thumbnail
                            source={item.sku_image}
                            alt="dropshipman"
                          />
                        </div>
                      )}
                      {item.checked === 1 && (
                        <div className="packaging_checkbox_item_checkbox">
                          <p className="packaging_checkbox_item_checkbox_title">
                            {item.title}
                          </p>
                          <p>{item.option_one}</p>
                      <p>{item.option_two}</p>
                      <p>{item.option_three}</p>
                        </div>
                      )}
                      <div className="packaging_checkbox_item_checkbox_quantity">1</div>
                      <div className="packaging_checkbox_item_checkbox_available">{item.available}</div>
                    </div>)
                  ))
                  }
            </div>
          ) : (
            <div className="emptyWithImg packagingImage">
              <EmptyState image="/empty.svg">
                <p style={{ fontSize: "14px", marginBottom: "0" }}>
                  {intl.get("No package available,")}
                  <span
                    onClick={() =>{
                      utils.routeListen('/admin/customService/custom-packaging')
                      utils.routeJump1(history,'/admin/customService/custom-packaging')
                      }
                    }
                    style={{ color: "#4584FE", cursor: "pointer" }}
                  >
                    {intl.get("to order")}
                  </span>
                </p>
              </EmptyState>
            </div>
          )}
        </Modal.Section>
      </Modal>
    </div>
  );
});

export default PackingOrderModal;
