import React, { Component } from 'react';

import { Checkbox, Modal, Toast, Badge, Text } from "@shopify/polaris";

import { reqGetAliAuthor } from '../../api';

// import utils from '../../utils/utils';
import intl from '../../i18n/intl'
import './index.css'

class AuthorizationUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authorizeModalShow: (props.show===undefined)?false:props.show,

            //弹窗
            showToast: false,  //弹窗显示
            showToastText:'',  //弹窗文字
            showToastIserror:false, //弹窗是否错误

            Buttonloading: false,
            checked: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            authorizeModalShow: (nextProps.show===undefined)?false:nextProps.show,

            //弹窗
            showToast: false,  //弹窗显示
            showToastText:'',  //弹窗文字
            showToastIserror:false, //弹窗是否错误

            Buttonloading: false,
            checked: false
        });
    }

    //进入页面调用接口
    componentDidMount () {}

    //获取 授权链接，并跳转
    getAuthorizedUrl = async () => {
        this.setState({ 
            Buttonloading : true
        });
        const params = {};
        const responseData = await reqGetAliAuthor(params)
        if (responseData.code==='200') {
            this.setState({
                Buttonloading : false,
                authorizeModalShow: false
            },()=>{
                // console.info(responseData.data);
                // 修改setting profile父组件 授权按钮状态
                try {
                    if(typeof(this.props.fuc) === "function") {
                        this.props.fuc();
                    }
                } catch(e) {}
                
                try {
                    const w=window.open('about:blank');
                    w.location.href = responseData.data;
                } catch (error) {
                    window.location.href = responseData.data;
                }
            });

        } else {
            this.toggleToast(responseData.msg);
        }
    }

    //提示弹窗
    toggleToast = (showToastText, showToastIserror=false) => {
        this.setState(({ showToast }) => ({
          showToast: !showToast,
          showToastText: showToastText,
          showToastIserror: showToastIserror
        }));
    };

    handleChange = () => {
        // this.setState(({ authorizeModalShow }) => ({ authorizeModalShow: !authorizeModalShow }));
        this.setState({
            authorizeModalShow:!this.state.authorizeModalShow,
        },()=>{
            this.props.onClose({ type:'authorizeAliExpress' });
        });
    };

    handleCheckboxChange = ()=>{
        this.setState(({ checked }) => ({ checked: !checked }));
    }

    render() {
        const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
        const {
            state: { authorizeModalShow,Buttonloading,checked },
        } = this;

        return (
            <div>
                <Modal
                    open={authorizeModalShow}
                    title={intl.get("AliExpress authorization guide")}
                    onClose={this.handleChange}
                    primaryAction={{
                        content: intl.get("Authorization update"),
                        onAction: this.getAuthorizedUrl,
                        loading: Buttonloading,
                        disabled:checked?false:true
                    }}
                >
                    <Modal.Section>
                        <section className="auth_tips_content">
                            <div style={{marginBottom:'10px'}}>
                                <Badge tone="info">{intl.get("read before authorization")}</Badge>
                            </div>
                            
                            <div className="auth_step">
                                {intl.get("1. Sign in to AliExpress account and confirm to join Dropshipping Center. As shown below.")}
                            </div>
                            
                            <img src="/join_dropshipping.jpg" alt="dropshipping" />
                            <div style={{padding:'5px 0 10px 5px'}}>
                                {intl.get("Click")}{' '}
                                <a target="_blank" className="Polaris-Link" style={{textDecoration:'underline'}} href="https://login.aliexpress.com/" rel="noopener noreferrer" data-polaris-unstyled="true"> 
                                  {intl.get("here")}{' '}
                                </a>
                                {intl.get("to Sign in.")}
                            </div>

                            <div className="auth_step">
                                {intl.get("2. If you did not join, click")}{' '}
                                <a target="_blank" className="Polaris-Link" style={{textDecoration:'underline'}} href="https://ds.aliexpress.com/" rel="noopener noreferrer" data-polaris-unstyled="true"> 
                                  {intl.get("here")}{' '}
                                </a>
                                {intl.get("to join at first please.")}
                            </div>

                            <div className="make_sure">
                                <Checkbox
                                    label={intl.get("Make sure you have joined in Dropshipping Center.")}
                                    checked={checked}
                                    onChange={this.handleCheckboxChange}
                                />
                            </div>

                            <Text  variant="bodyMd" as="span" color="subdued">{intl.get("If you do not perform this step, we will not be able to obtain product data normally.")}</Text>
                        </section>
                    </Modal.Section>
                </Modal>
                { toastMarkup }
            </div>
        );
    }
}

export default AuthorizationUpdate;