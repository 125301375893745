import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Page, Card, Layout, SkeletonBodyText, FormLayout, TextField, BlockStack, Badge, Toast, Button, Modal, Icon, InlineStack,
  Text,
  // Link, Banner,
} from "@shopify/polaris";

import {
  ViewIcon, EyeCheckMarkIcon,
} from '@shopify/polaris-icons';

import AppBridge from '../../../../utils/appBridge';

import NavTabs from "../components/NavTabs";
import AuthorizationUpdate from '../../../../components/AuthorizationUpdate';
import { reqGetUserSettings, reqGetDisconnect, reqPostAppAccountAction, reqPostDisConnetPayPal } from '../../../../api';

import { API_DOMAIN } from '../../../../utils/constants';
import SettingPrompt from '../../../../../src/components/SettingPrompt';
import intl from '../../../../i18n/intl';
import utils from '../../../../utils/utils';
import { changeBannerData, changeLanguageData } from '../../../../slice';
import PermissionUpgrade from '../../../../components/PermissionUpgrade';
import NeedUpdateInfo from '../../../../components/NeedUpdateInfo';
import './index.css';
import { store } from '../../../../store';

class ProfileClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShopOwner: '',
      UserEmail: '',
      isFocusEmail: false,

      AccountName: '',
      AccountEmail: '',
      ResetPasswordActive: false,
      hidePassword: true,
      hideNewPassword: true,
      hideConfirmPassword: true,
      oldPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      resetPasswordLoading: false,

      ChangeAccountEmailActive: false,
      sendCodeLoading: false,
      verifyLoading: false,
      NewEmailAddress: '',
      newEmailAddressError: '',
      VerifyCode: '',
      verifyCodeError: '',

      StoreName: '',
      // AliExpressId: '',
      // isAuth: true,
      // isExpire: false,
      isEffective: true,
      // ExpireTime: 0,

      //弹窗
      showToast: false,  //弹窗显示
      showToastText: '',  //弹窗文字
      showToastIserror: false, //弹窗是否错误

      loadingPage: true,
      saveDataLoading: false,
      disabledSaveData: true,
      accountNameError:'',
      AuthButtonDisable: false,
      disconnectActive: false,

      // PayPal账户数据
      PayPalUserData: {
        email: '',
        status: 0,
        is_effective: 0,
        authUrl: ''
      },
      // Payoneer 账户数据
      PayoneerData: {
        account_id: '',
        email: '',
        expire_time: 0,
        status: 0
      },

      disconnectType: 0,  // 取消类型：1：速卖通 2：PayPal

      //语言设置
      languageOptions: [
        { label: 'English', value: 'en' },
        // {label: '中文', value: 'zh'},
        { label: 'français', value: 'fr' },
        { label: 'Português', value: 'pt' },
        { label: 'Español', value: 'es' },
        { label: 'italiano', value: 'it' },
        { label: 'Deutsche', value: 'de' }
      ],
      selected: 'en',
      isLanguageChange: false,
      shopify_permission_upgrade: false,
      need_to_upgrade_permission: false,
      needSetPasswordActive: false,
      payonnerButton: false,//按钮加载状态
      payonnerLink: '',//授权链接
      payonnerOff: false,//取消授权
      payonnerOffLoading: false,//取消授权加载状态

      secondsLeft: 0,
      isSending: false,
    };
    // 计时器
    this.timer = null;
  }

  //进入页面调用接口
  componentDidMount() {
    this.getUserSettingsData();
  }
  componentWillUnmount = () => {
    clearInterval(this.timer);
    this.setState = (state, callback) => {
      return;
    };
  }
  componentDidUpdate() {
    let mutation;
    let callback;
    let setting = document.querySelector('#settings_class');
    if (setting) {
      callback = (mutations) => {
        mutations.forEach((item, index) => {
          // if (item.type === 'characterData') {
          //   this.setState({
          //     disabledSaveData: false
          //   })
          // }
        })
      }
    }
    const config = {
      attributes: true,
      // attributesOldValue: true,
      characterData: true,
      // characterDataOldValue: true,
      childList: true,
      subtree: true
    }
    mutation = new MutationObserver(callback)
    // 开始检测
    mutation.observe(setting, config)
    // 停止检测
    if (!this.state.disabledSaveData) {
      mutation.disconnect()
    }
  }
  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    if (showToastText === '') {
      this.setState({
        showToast: false
      })
    }else {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  }
  };

  getUserSettingsData = async () => {
    const params = { type: 'profile' };
    const response = await reqGetUserSettings(params);

    // var language = utils.getStore('language'); // 获取localStorage中的语言设置
    // if (!language) {
    //   const lang = (navigator.languages && navigator.languages[0]) || navigator.language; // 获取浏览器语言
    //   language = lang.split('-')[0]; // 语言（简写）
    // }

    if (response.code === '200') {
      var result = response.data.userSetting;
      this.setState({
        ShopOwner: result.shop_owner,
        UserEmail: result.email,

        AccountName: result.userName,
        AccountEmail: result.userAccount,

        StoreName: result.domain,
        // AliExpressId: result.AliExpressId,
        // isAuth: (result.isAuth === 1) ? true : false,
        // isExpire: (result.isExpire === 1) ? true : false,
        isEffective: (result.isEffective === 1) ? true : false,
        // ExpireTime: result.expire_time,
        PayPalUserData: result.PayPalInfo,
        PayoneerData: result.Payoneer,
        selected: result.language, // 语言设置

        loadingPage: false,
        need_to_upgrade_permission: (result.shopify_permission_upgrade === 0) ? true : false,
        needSetPasswordActive: (result.change_pass === 0 && !AppBridge.exist()) ? true : false
      });

    } else {
      this.toggleToast(response.msg);
    }
  }

  // save setting
  saveData = async () => {
    if (this.state.AccountName === '') {
      // this.toggleToast('Please enter account name', true);
      this.setState({ accountNameError: intl.get('Please enter account name') });
      return false;
    }
    this.setState({ accountNameError: '' });
    this.setState({ saveDataLoading: true });
    const params = {
      t: 'info',
      name: this.state.AccountName,
      language: this.state.selected
    };

    const responseData = await reqPostAppAccountAction(params);

    this.setState({ saveDataLoading: false });
    this.props.dispatch(changeLanguageData({ language: this.state.selected, isLanguageChange: true }))

    // 选择的语言
    window.language = this.state.selected;

    if (responseData.code === "200") {
      this.toggleToast('Saved successfully');
      this.setState({
        disabledSaveData: true
      })
      if (this.state.isLanguageChange) { // 修改语言，则刷新框架组件
        this.props.callBackFun();
      }
    } else {
      // this.toggleToast(responseData.msg, true);
      store.dispatch(changeBannerData({bannerData:{
        show:true,
        desc:responseData.msg
      }}));
    }
  };

  // 重置密码 / 更换登录邮箱 弹窗控制
  handleAccountModal = (type, bool) => {
    if (type === 'password') {
      if (this.timer) {
        clearInterval(this.timer);
        this.setState({ isSending: false });
      }
      this.setState({
        ResetPasswordActive: bool,
        OldPassword: '',
        NewPassword: '',
        ConfirmPassword: '',
        hidePassword: true,
        hideNewPassword: true,
        hideConfirmPassword: true,
        oldPasswordError: '',
        newPasswordError: '',
        confirmPasswordError: '',
        VerifyCode: '',
        verifyCodeError: '',
        timer:null
      });
    }

    if (type === 'change_account') {
      if (this.timer) {
        clearInterval(this.timer);
        this.setState({ isSending: false });
      }
      this.setState({
        ChangeAccountEmailActive: bool,
        NewEmailAddress: '',
        newEmailAddressError: '',
        VerifyCode: '',
        verifyCodeError: '',
        timer:null
      })
    }
  }

  // 密码显示与隐藏
  handleDisplaySwitch = (field) => {
    switch (field) {
      case 'hidePassword':
        this.setState(({ hidePassword }) => ({ hidePassword: !hidePassword }));
        break;
      case 'hideNewPassword':
        this.setState(({ hideNewPassword }) => ({ hideNewPassword: !hideNewPassword }));
        break;
      case 'hideConfirmPassword':
        this.setState(({ hideConfirmPassword }) => ({ hideConfirmPassword: !hideConfirmPassword }));
        break;
      default:
    }
  }

  // email 格式验证
  checkInputEmail(email) {
    let errorMsg = '';
    let bool = false;
    if (email === undefined || email === '') {
      errorMsg = intl.get('Please enter new account email');
    }

    if (errorMsg === '') {
      let reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      bool = reg.test(email);
      errorMsg = bool ? '' : intl.get('Invalid email address');
    }
    this.setState({ newEmailAddressError: errorMsg });
    return bool;
  }

  // password 密码校验
  checkInputPassword(password) {
    let errorMsg = '';
    let bool = true;
    if (password === undefined || password === '') {
      bool = false;
      errorMsg = intl.get('Please enter a new password.');
    }

    // 注册时校验密码格式
    if (errorMsg === '') {
      // 1.The password must be 8 - 24 characters long
      // 2.The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number

      if (password.length < 8 || password.length > 24) {
        errorMsg = intl.get('Password must be at least 8 characters long, including uppercase and lowercase letters, and numbers.');
        bool = false;
      } else {
        let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
        bool = reg.test(password);
        errorMsg = bool ? '' : intl.get('Password must be at least 8 characters long, including uppercase and lowercase letters, and numbers.');
      }
    }

    this.setState({ newPasswordError: errorMsg });
    return bool;
  }

  // 两次密码校验
  checkInputConfirmPassword(password) {
    let errorMsg = '';
    let bool = false;
    if (password === '') {
      errorMsg = intl.get('The password cannot be empty');
    }

    if (errorMsg === '') {
      if (password === this.state.NewPassword) {
        bool = true;
      }
      errorMsg = bool ? '' : intl.get("Confirm password doesn't match");
    }
    this.setState({ confirmPasswordError: errorMsg });
    return bool;
  }

  // 重置密码
  ResetPasswordSave = async () => {
    let isAllow = true;
    if (this.state.NewPassword === '' || this.state.NewPassword === undefined) {
      this.setState({ newPasswordError: intl.get('Please enter a new password.') });
      isAllow = false;
    }
    if (this.state.VerifyCode === '' || this.state.VerifyCode === undefined) {
      this.setState({ verifyCodeError: intl.get('Please enter the verification code.') });
      isAllow = false;
    }
    if (!this.checkInputPassword(this.state.NewPassword)) {
      isAllow = false;
    }
    if (!isAllow) {
      return false;
    }

    const params = {
      t: 'password',
      new: this.state.NewPassword,
      code: this.state.VerifyCode
    };

    this.setState({ resetPasswordLoading: true });
    const response = await reqPostAppAccountAction(params)
    this.setState({ resetPasswordLoading: false });

    if (response.code === '200') {
      this.toggleToast('Password updated', false);
      // 重新登录
      // this.logoutToLogin();

      // 关闭弹窗，清除定时器
      this.handleAccountModal('password', false);
      if (this.timer) {
        clearInterval(this.timer);
      }
    } else {
      if (response.code === '502') {
        this.setState({ verifyCodeError: response.msg });
        return false;
      }

      this.toggleToast(response.msg);
    }
  }

  /**
   * 登出重新登录
   * 
   * 说明：如果是Shopify内嵌模式，跳转到站外
   * 
   * @returns
   */
  logoutToLogin = () => {
    if (AppBridge.exist()) {
      try {
        // 获取当前域名
        let localHost = '';

        if (API_DOMAIN === 'https://api.dropshipman.com') {
          localHost = 'https://app.dropshipman.com';
        }

        if (API_DOMAIN === 'https://test-api.dropshipman.com') {
          localHost = 'https://test-app.dropshipman.com';
        }

        if (localHost) {
          const w = window.open('about:blank');
          w.location.href = localHost + '/login';
        }

      } catch (error) {
        this.setState({ loadingPage: true }, () => {
          this.getUserSettingsData();
        })
      }

    } else {
      utils.removeStore('S_AUTHORIZATION');
      utils.setStore('S_SHOP_DOMAIN', '');
      utils.setStore('S_USER_TOKEN', '');
      window.location.href = '/login';
    }

    return true;
  }

  // 60秒计时器
  startCountdown = () => {
    
    this.timer = setInterval(() => {
      if (this.state.secondsLeft > 0) {
        this.setState(prevState => ({ secondsLeft: prevState.secondsLeft - 1 }));
      } else {
        clearInterval(this.timer);
        this.setState({ isSending: false });
      }
    }, 1000);
  };

  // 发送邮箱验证码
  sendVerificationCode = async (type) => {
    
    if (type === 'email') {
      if (!this.checkInputEmail(this.state.NewEmailAddress)) {
        return false;
      }
    }
    let isAllow = true;
    if (type === 'password') {
      if (this.state.NewPassword === '' || this.state.NewPassword === undefined) {
        this.setState({ newPasswordError: intl.get('Please enter a new password.') });
        isAllow = false;
      }
      if (!this.checkInputPassword(this.state.NewPassword)) {
        isAllow = false;
      }
    }
    if (!isAllow) {
      return false;
    }

    const params = {
      t: 'code',
      email: this.state.NewEmailAddress,
      action: type
    };
    this.setState({ sendCodeLoading: true });
    const response = await reqPostAppAccountAction(params)
    this.setState({ sendCodeLoading: false });

    if (response.code === '200') {
      this.toggleToast('Code sent');
      setTimeout(() => {
        this.toggleToast('');
      }, 2000);
      // 启动计时器
      this.setState({
        isSending: true,
        secondsLeft: 60
      }, () => {
        this.startCountdown();
      })
    } else {
      if (response.code === '503') {
        this.setState({ newEmailAddressError: response.msg });
        return false;
      }
      if (response.code === '505') {
        this.setState({ verifyCodeError: response.msg });
        return false;
      }
      this.toggleToast(response.msg);
    }
  }

  // 验证邮箱验证码
  VerifyCodeRequest = async () => {
    let isAllow = true;
    if (!this.checkInputEmail(this.state.NewEmailAddress)) {
      isAllow = false;
    }

    if (this.state.VerifyCode === '') {
      isAllow = false;
      this.setState({ verifyCodeError: intl.get('Verify code cannot be empty.') });
    }

    if (!isAllow) {
      return false;
    }

    const params = {
      t: 'change',
      email: this.state.NewEmailAddress,
      code: this.state.VerifyCode
    };
    this.setState({ verifyLoading: true });
    const response = await reqPostAppAccountAction(params)
    this.setState({ verifyLoading: false });

    if (response.code === '200') {
      this.toggleToast('Email updated', false);
      this.logoutToLogin();
    } else {
      if (response.code === '502') {
        this.setState({ verifyCodeError: response.msg });
        return false;
      }
      this.toggleToast(response.msg);
    }
  }

  // checkbox change
  handleCheckboxChange = (value, id) => {
    this.setState({
      [id]: value
    });
  }

  // input change
  handleInputChange = (value, id) => {
    if (id=== 'AccountName') {
      this.setState({ accountNameError: '' });
    }
    this.setState({
      [id]: value,
    }, () => {
      setTimeout(() => {
        switch (id) {
          case 'NewPassword':
            // this.checkInputPassword(value);
            this.setState({ newPasswordError: '' });
            break;
          case 'ConfirmPassword':
            if (value.length >= 2) {
              this.checkInputConfirmPassword(value);
            }
            break;
          case 'OldPassword':
            if (value !== '') {
              this.setState({ oldPasswordError: '' });
            }
            break;
          case 'NewEmailAddress':
            // this.checkInputEmail(value);
            this.setState({ newEmailAddressError: '' });
            break;
          case 'VerifyCode':
            if (value !== '') {
              this.setState({ verifyCodeError: '' });
            }
            break;
          default:
        }
      }, 100);
    });
    if (id === 'AccountName') {
      this.setState({
        disabledSaveData: false
      })
    }
  }

  // 失焦校验重置密码
  checkNewPasswordByBlur()
  {
    this.checkInputPassword(this.state.NewPassword);
  }

  // 语言设置
  handleSelectChange = (value) => {
    this.setState({
      selected: value,
      isLanguageChange: true
    });
  }

  // handle auth modal 速卖通用户授权弹窗
  handleAuthModalChange = () => {
    this.setState({
      authorizeModalActive: true,
    });
  };

  // 回调关闭弹窗
  callBackHandleAuthModalClosed = () => {
    this.setState({
      authorizeModalActive: false,
      needSetPasswordActive: false
    });
  };

  // 授权按钮disabled
  handleAuthButtonDisable = () => {
    this.setState({
      AuthButtonDisable: true,
      authorizeModalActive: false
    });
  }

  // 取消授权弹窗提示
  handleDisconnectModal = (type, bool) => {
    // this.setState(({ disconnectActive }) => ({ disconnectActive: !disconnectActive }));
    this.setState({
      disconnectType: type,
      disconnectActive: bool,
    });
  }

  // 取消授权
  handleDisconnectAuth = async () => {
    const { disconnectType } = this.state;
    if (disconnectType === 0) {
      this.toggleToast('Something wrong.');
      return;
    }

    this.setState({ AuthButtonDisable: true });

    const params = {};
    var response;
    if (disconnectType === 1) {
      response = await reqGetDisconnect(params)
    } else {
      response = await reqPostDisConnetPayPal(params)
    }

    if (response.code === '200') {
      this.setState({ AuthButtonDisable: false, loadingPage: true });
      this.handleDisconnectModal(0, false);
      // 刷新页面信息
      this.getUserSettingsData();

    } else {
      this.setState({ AuthButtonDisable: false });
      this.toggleToast(response.msg);
    }
  }


  // 关闭Shopify升级弹窗
  closeShopifyPermissionUpgrade = () => {
    this.setState({ shopify_permission_upgrade: false })
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} duration={2000} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const {
      ShopOwner, UserEmail, saveDataLoading, disabledSaveData, StoreName, disconnectActive, AccountName, AccountEmail,
      ResetPasswordActive, ChangeAccountEmailActive, hideNewPassword, NewPassword,accountNameError,
      newPasswordError, resetPasswordLoading, NewEmailAddress, newEmailAddressError, VerifyCode, verifyCodeError, sendCodeLoading,
      verifyLoading, disconnectType, shopify_permission_upgrade, need_to_upgrade_permission, needSetPasswordActive, isSending, secondsLeft,
      // hidePassword, ConfirmPassword, hideConfirmPassword, OldPassword, oldPasswordError, confirmPasswordError,
      // isFocusEmail, isAuth, isEffective, isExpire, AliExpressId, ExpireTime,
    } = this.state;

    let timerText = '';
    if (isSending) {
      timerText = '(' + secondsLeft + ')';
    }

    const initSkeletonPage = <div className="SettingsPage__Content">
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap={400}>
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </div>

    // let AliAccountStatus = '';
    // let AliAccountAction = '';
    // let showAuthMessageActive = false;
    // let authWarningMsg = '';
    // if (isAuth) {  // 是否授权
    //   if (isEffective) { // 是否有效

    //     if (!isExpire) {  // 是否过期
    //       AliAccountStatus = <Badge status="success">{intl.get("Active")}</Badge>
    //       AliAccountAction = <Button size="slim" onClick={() => this.handleDisconnectModal(1, true)}>{intl.get("Disconnect")}</Button>

    //     } else {
    //       AliAccountStatus = <Badge status="attention">{intl.get("Expired")}</Badge>
    //       AliAccountAction = <Button size="slim" primary={true} onClick={this.handleAuthModalChange} disabled={this.state.AuthButtonDisable}>{intl.get("Reauthorize")}</Button>
    //       showAuthMessageActive = true;
    //       authWarningMsg = intl.get("Authorization information has expired, please re-authorize.");
    //     }
    //   } else {
    //     AliAccountStatus = <Badge status="warning">{intl.get("Invalid")}</Badge>
    //     AliAccountAction = <Button size="slim" primary={true} onClick={this.handleAuthModalChange} disabled={this.state.AuthButtonDisable}>{intl.get("Update authorization")}</Button>
    //     showAuthMessageActive = true;
    //     authWarningMsg = intl.get("Currently authorized information, can't get data from AliExpress API, make sure you have joined in Dropshipping Center and update authorization.");
    //   }

    // } else {
    //   AliAccountStatus = <Badge status="info">{intl.get("No authorization")}</Badge>
    //   AliAccountAction = <Button size="slim" primary={true} onClick={this.handleAuthModalChange} disabled={this.state.AuthButtonDisable}>{intl.get("Authorize")}</Button>
    //   showAuthMessageActive = true;
    //   authWarningMsg = intl.get("You have not authorized AliExpress, please authorize for a better experience.");
    // }

    const disconnectModal = (
      <Modal
        open={disconnectActive}
        onClose={() => this.handleDisconnectModal(0, false)}
        title={(disconnectType === 1) ? intl.get("Cleanup authorized from AliExpress") : intl.get('Cleanup authorized from PayPal')}
        primaryAction={{
          content: intl.get("Disconnect"),
          onAction: this.handleDisconnectAuth,
          loading: this.state.AuthButtonDisable
        }}
        secondaryActions={[
          {
            content: intl.get("Cancel"),
            onAction: () => this.handleDisconnectModal(0, false),
          },
        ]}
      >
        <Modal.Section>
          <p>
            {(disconnectType === 1) ? intl.get("Are you sure cleanup authorized from AliExpress?") : intl.get('Are you sure cleanup authorized from PayPal?')}
          </p>
        </Modal.Section>
      </Modal>
    )

    // 重置密码弹窗
    const ResetPasswordModal = (
      <Modal
        open={ResetPasswordActive}
        onClose={() => this.handleAccountModal('password', false)}
        title={intl.get("Reset password")}
        primaryAction={{
          content: intl.get('Apply'),
          onAction: this.ResetPasswordSave,
          loading: resetPasswordLoading
        }}
        secondaryActions={[
          {
            content: intl.get('Cancel'),
            onAction: () => this.handleAccountModal('password', false),
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <div>
             {intl.get("Please enter a new password and get a verification code from email")}  <span style={{ color: 'blue' }}>{AccountEmail}</span>.
            </div>

            <div className='Reset_Password_Input'>
              <TextField
                id="NewPassword"
                type={hideNewPassword ? "password" : "text"}
                label={intl.get("New Password")}
                value={NewPassword}
                onChange={this.handleInputChange}
                onBlur={() => this.checkNewPasswordByBlur()}
                minLength={8}
                maxLength={24}
                error={newPasswordError}
                suffix={
                  <div className="reset_password" onClick={() => this.handleDisplaySwitch('hideNewPassword')}>
                    <Icon source={hideNewPassword ? ViewIcon : EyeCheckMarkIcon} />
                  </div>
                }
              />
            </div>

            <TextField
              id="VerifyCode"
              label={intl.get("Verification code")}
              value={VerifyCode}
              onChange={this.handleInputChange}
              maxLength={10}
              error={verifyCodeError}
              connectedRight={
                <Button
                  // variant='primary'
                  onClick={() => this.sendVerificationCode('password')}
                  loading={sendCodeLoading}
                  disabled={isSending}
                >
                  {intl.get("send Code") + timerText}
                </Button>
              }
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
    );


    // 更新登录账号email弹窗
    const ChangeAccountEmailModal = (
      <Modal
        open={ChangeAccountEmailActive}
        onClose={() => this.handleAccountModal('change_account', false)}
        title={intl.get("Change account email")}
        primaryAction={{
          content: intl.get('Verify'),
          onAction: this.VerifyCodeRequest,
          loading: verifyLoading,
          disabled: (VerifyCode === '') ? true : false
        }}
        secondaryActions={[
          {
            content: intl.get('Cancel'),
            onAction: () => this.handleAccountModal('change_account', false),
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              id="NewEmailAddress"
              type="email"
              label={intl.get("New email address")}
              onChange={this.handleInputChange}
              value={NewEmailAddress}
              error={newEmailAddressError}
              connectedRight={
                <Button
                  // variant='primary'
                  onClick={() => this.sendVerificationCode('email')}
                  loading={sendCodeLoading}
                  disabled={isSending}
                >
                  {intl.get("send Code") + timerText}
                </Button>
              }
            />
            <div>
              {intl.get("We will send a verification code to the new email address. Please copy it and enter below to verify your mailbox.")}
            </div>
            <TextField
              id="VerifyCode"
              label={intl.get("Verify code")}
              value={VerifyCode}
              onChange={this.handleInputChange}
              maxLength={10}
              helpText={intl.get('Get code from verification email and enter here to get verified')}
              error={verifyCodeError}
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
    )

    const pageContent = this.state.loadingPage ? initSkeletonPage : <div className="SettingsPage__Content">
      <section className="Panel_Content">
        <BlockStack gap={400}>
          <Card>
           <Text variant="headingMd" as="h2">
           {intl.get("Account info")}
              </Text>
            <FormLayout>
              <TextField
                id="AccountName"
                label={intl.get("Account name")}
                value={AccountName}
                error={accountNameError}
                onChange={this.handleInputChange}
              />

              <div>
                <div>{intl.get("Account email (log in email)")}</div>

                <div style={{ padding: '10px 0' }}>
                  <Badge tone="info"> {AccountEmail} </Badge>

                  <div style={{ display: 'inline-block', paddingLeft: '15px' }}>
                    <Button variant='plain' onClick={() => this.handleAccountModal('change_account', true)}>{intl.get('Change')}</Button>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div>{intl.get("Account password")}</div>
                <div style={{ paddingLeft: '15px' }}>
                  <Button variant='plain' onClick={() => this.handleAccountModal('password', true)}>{intl.get('Reset')}</Button>
                </div>
              </div>

              {/* <Select
              label={intl.get("Language")}
              options={languageOptions}
              onChange={this.handleSelectChange}
              value={selected}
            /> */}
            </FormLayout>
          </Card>

          <Card>
            <Text variant="headingMd" as="h2">
           {intl.get("Current store info")}
              </Text>
            <FormLayout>
              <InlineStack>
                <div>{intl.get("Shop owner:")}&nbsp;</div>
                <div>{ShopOwner} </div>
              </InlineStack>

              <InlineStack>
                <div>{intl.get("Store email:")}&nbsp;</div>
                <div>  {UserEmail}</div>
              </InlineStack>
            </FormLayout>
          </Card>

          <Card>
            <Text variant="headingMd" as="h2">
           {intl.get("Shopify store")}
              </Text>
            <div className="Profile_Table">
              <table>
                <thead>
                  <tr>
                    <th>{intl.get("Shopify domain name")}</th>
                    <th style={{ textAlign: 'left' }}>{intl.get("Status")}</th>
                    <th>{intl.get("Api health")}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr><td style={{ padding: '10px' }}></td><td></td></tr>
                  <tr>
                    <td>{StoreName}</td>
                    <td>
                      <Badge tone={(StoreName !== '') ? "success" : "info"}>{(StoreName !== '') ? intl.get("Active") : intl.get("Not installed")}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {need_to_upgrade_permission ? (
                        <Button size="slim" variant='primary' onClick={() => this.setState({ shopify_permission_upgrade: true })}>{intl.get("Permission upgrade")}</Button>
                      ) : (
                        <div style={{ lineHeight: '12px' }}>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z" fill="#007F5F" />
                          </svg>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </BlockStack>
      </section>

      {/* 速卖通用户授权 */}
      <AuthorizationUpdate show={this.state.authorizeModalActive} fuc={this.handleAuthButtonDisable} onClose={this.callBackHandleAuthModalClosed} />
    </div>

    return (

      <div className={AppBridge.exist() ? "Setting__Page_Content Content_within" : "Setting__Page_Content Content_outside"} id="settings_class">
        
        <Page
          title={AppBridge.exist() ? '' : intl.get("Settings")}
          primaryAction={
            // AppBridge.exist() ? '' : 
            {
              content: intl.get("Save"),
              onAction: this.saveData,
              loading: saveDataLoading,
              disabled: disabledSaveData
            }
          }
          separator
        >

          <section className="SettingsPage__Box">

            <NavTabs selected="Profile" saveData={this.saveData} loading={saveDataLoading} disabled={disabledSaveData} />
            {pageContent}

          </section>

          {toastMarkup}
          {/* 速卖通取消绑定弹窗 */}
          {disconnectModal}
          {/* 账户重置密码弹窗 */}
          {ResetPasswordModal}
          {/* 账户更换登录邮箱弹窗 */}
          {ChangeAccountEmailModal}

          {/* Shopify权限升级提醒弹窗 */}
          {need_to_upgrade_permission && (
            <PermissionUpgrade
              show={shopify_permission_upgrade}
              onClose={this.closeShopifyPermissionUpgrade}
            />
          )}

          {/* 默认平台生成的账户提醒修改默认密码 */}
          <NeedUpdateInfo
            show={needSetPasswordActive}
            email={AccountEmail}
            onClose={this.callBackHandleAuthModalClosed}
          />

          <div className="space"></div>
        </Page>
{/* 未保存拦截弹窗 */}
<SettingPrompt disabledSaveData={disabledSaveData} url='/admin/settings/profile' saveData={this.saveData} />
      </div>
    );

  }
}

export default connect()(ProfileClass);