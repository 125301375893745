import React from 'react';

const Home =()=> {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.93257 0.899898L9.99992 0.899965L10.0673 0.899898C10.3219 0.899517 10.587 0.89912 10.8279 0.947041C11.7998 1.14036 12.5595 1.9001 12.7528 2.87199C12.8008 3.11291 12.8004 3.37794 12.8 3.63262V16.3673C12.8004 16.622 12.8008 16.887 12.7528 17.1279C12.5595 18.0998 11.7998 18.8596 10.8279 19.0529C10.587 19.1008 10.3219 19.1004 10.0673 19.1L9.99992 19.1L9.93258 19.1C9.67789 19.1004 9.41286 19.1008 9.17194 19.0529C8.20005 18.8596 7.44031 18.0998 7.24699 17.1279C7.19907 16.887 7.19947 16.622 7.19985 16.3673L7.19992 16.3V3.69997L7.19985 3.63262C7.19947 3.37794 7.19907 3.11291 7.24699 2.87199C7.44031 1.9001 8.20005 1.14036 9.17194 0.947041C9.41286 0.89912 9.67789 0.899517 9.93257 0.899898ZM9.57892 3.00724C9.44228 3.03554 9.33549 3.14233 9.30719 3.27897C9.30657 3.28404 9.30431 3.30491 9.30266 3.35307C9.30006 3.42916 9.29992 3.53022 9.29992 3.69997V16.3C9.29992 16.4697 9.30006 16.5708 9.30266 16.6469C9.30431 16.695 9.30657 16.7159 9.30719 16.721C9.33549 16.8576 9.44228 16.9644 9.57892 16.9927C9.58399 16.9933 9.60487 16.9956 9.65302 16.9972C9.72911 16.9998 9.83017 17 9.99992 17C10.1697 17 10.2707 16.9998 10.3468 16.9972C10.395 16.9956 10.4158 16.9933 10.4209 16.9927C10.5575 16.9644 10.6643 16.8576 10.6926 16.721C10.6933 16.7159 10.6955 16.695 10.6972 16.6469C10.6998 16.5708 10.6999 16.4697 10.6999 16.3V3.69997C10.6999 3.53022 10.6998 3.42916 10.6972 3.35307C10.6955 3.30491 10.6933 3.28404 10.6926 3.27897C10.6643 3.14233 10.5576 3.03554 10.4209 3.00724C10.4158 3.00662 10.395 3.00436 10.3468 3.00271C10.2707 3.00011 10.1697 2.99997 9.99992 2.99997C9.83017 2.99997 9.72911 3.00011 9.65302 3.00271C9.60486 3.00436 9.58399 3.00662 9.57892 3.00724Z" fill="#B5B5B5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.63257 8.5999L3.69992 8.59997L3.76726 8.5999C4.02194 8.59952 4.28697 8.59912 4.52789 8.64704C5.49978 8.84036 6.25952 9.6001 6.45284 10.572C6.50076 10.8129 6.50036 11.0779 6.49998 11.3326L6.49992 11.4V16.3L6.49998 16.3673C6.50036 16.622 6.50076 16.887 6.45284 17.1279C6.25952 18.0998 5.49978 18.8596 4.52789 19.0529C4.28697 19.1008 4.02194 19.1004 3.76726 19.1L3.69992 19.1L3.63257 19.1C3.37789 19.1004 3.11286 19.1008 2.87194 19.0529C1.90005 18.8596 1.14031 18.0998 0.946992 17.1279C0.89907 16.887 0.899467 16.622 0.899848 16.3673V11.3326C0.899467 11.0779 0.89907 10.8129 0.946992 10.572C1.14031 9.6001 1.90005 8.84036 2.87194 8.64704C3.11286 8.59912 3.37789 8.59952 3.63257 8.5999ZM3.27892 10.7072C3.14228 10.7355 3.03549 10.8423 3.00719 10.979C3.00657 10.984 3.00431 11.0049 3.00266 11.0531C3.00006 11.1292 2.99992 11.2302 2.99992 11.4V16.3C2.99992 16.4697 3.00006 16.5708 3.00266 16.6469C3.00431 16.695 3.00657 16.7159 3.00719 16.721C3.03549 16.8576 3.14228 16.9644 3.27892 16.9927C3.28398 16.9933 3.30485 16.9956 3.35302 16.9972C3.42911 16.9998 3.53017 17 3.69992 17C3.86967 17 3.97072 16.9998 4.04681 16.9972C4.09498 16.9956 4.11585 16.9933 4.12092 16.9927C4.25755 16.9644 4.36434 16.8576 4.39264 16.721C4.39326 16.7159 4.39552 16.695 4.39717 16.6469C4.39977 16.5708 4.39992 16.4697 4.39992 16.3V11.4C4.39992 11.2302 4.39977 11.1292 4.39717 11.0531C4.39552 11.0049 4.39326 10.984 4.39264 10.979C4.36434 10.8423 4.25755 10.7355 4.12091 10.7072C4.11584 10.7066 4.09497 10.7044 4.04681 10.7027C3.97072 10.7001 3.86967 10.7 3.69992 10.7C3.53017 10.7 3.42911 10.7001 3.35302 10.7027C3.30486 10.7044 3.28399 10.7066 3.27892 10.7072Z" fill="#B5B5B5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.2999 4.39997L16.2326 4.3999C15.9779 4.39952 15.7129 4.39912 15.4719 4.44704C14.5001 4.64036 13.7403 5.4001 13.547 6.37199C13.4991 6.61291 13.4995 6.87794 13.4999 7.13262V16.3673C13.4995 16.622 13.4991 16.887 13.547 17.1279C13.7403 18.0998 14.5001 18.8596 15.4719 19.0529C15.7129 19.1008 15.9779 19.1004 16.2326 19.1L16.2999 19.1L16.3673 19.1C16.6219 19.1004 16.887 19.1008 17.1279 19.0529C18.0998 18.8596 18.8595 18.0998 19.0528 17.1279C19.1008 16.887 19.1004 16.622 19.1 16.3673V7.13263C19.1004 6.87794 19.1008 6.61291 19.0528 6.37199C18.8595 5.4001 18.0998 4.64036 17.1279 4.44704C16.887 4.39912 16.6219 4.39952 16.3673 4.3999L16.2999 4.39997ZM15.6072 6.77897C15.6355 6.64233 15.7423 6.53554 15.8789 6.50724C15.884 6.50662 15.9049 6.50436 15.953 6.50271C16.0291 6.50011 16.1302 6.49997 16.2999 6.49997C16.4697 6.49997 16.5707 6.50011 16.6468 6.50271C16.695 6.50436 16.7158 6.50662 16.7209 6.50724C16.8576 6.53554 16.9643 6.64233 16.9926 6.77897C16.9933 6.78404 16.9955 6.80491 16.9972 6.85307C16.9998 6.92916 16.9999 7.03022 16.9999 7.19997V16.3C16.9999 16.4697 16.9998 16.5708 16.9972 16.6469C16.9955 16.695 16.9933 16.7159 16.9926 16.721C16.9643 16.8576 16.8575 16.9644 16.7209 16.9927C16.7158 16.9933 16.695 16.9956 16.6468 16.9972C16.5707 16.9998 16.4697 17 16.2999 17C16.1302 17 16.0291 16.9998 15.953 16.9972C15.9049 16.9956 15.884 16.9933 15.8789 16.9927C15.7423 16.9644 15.6355 16.8576 15.6072 16.721C15.6066 16.7159 15.6043 16.695 15.6027 16.6469C15.6001 16.5708 15.5999 16.4697 15.5999 16.3V7.19997C15.5999 7.03022 15.6001 6.92916 15.6027 6.85307C15.6043 6.80492 15.6066 6.78404 15.6072 6.77897L15.6066 6.78168C15.6071 6.7794 15.6072 6.77922 15.6072 6.77897Z" fill="#B5B5B5"/>
            </svg>
        )
    }
export default Home;
