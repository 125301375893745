import React, { Component } from 'react';

import FrameComponents from "../FrameComponents"; 

class dashboard extends Component {
  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan-partner';
  }
 
  render() {
    return (
      <div>
        <FrameComponents name="partner"/> 
      </div>
    );
  }
}

export default dashboard; 
