import {
  BlockStack,
Button,
ButtonGroup,
Card,
EmptyState,
FormLayout,
Icon,
Layout,
Link,
Page,
SkeletonBodyText,
SkeletonPage,
Tabs,
Text,
Toast,

} from "@shopify/polaris";
import React, { Component } from "react";


import { ChevronLeftIcon, ChevronRightIcon  } from "@shopify/polaris-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { withRouter } from "react-router-dom";
import utils from "../../../../../utils/utils";
import { connect } from "react-redux";
import { reqGetCustomPackagingDetail} from "../../../../../api";
// import ReviewsList from "./components/ReviewsList";
import { store } from "../../../../../store";
import PremiumPlanModal from '../Modal/PremiumPlanModal';
// import AppBridge from "../../../../../utils/appBridge";

class ProductDetailClass extends Component {
state = {
  pageData: {}, //页面数据
  loadingPage: false, //页面加载状态
  fromPage: "", //来源页面
  skuImage: "", //主视角图片
  defaultImage: "", //商品默认图片
  defaultPrice: "", //商品默认价格
  indexPrice: "", //商品价格
  thumbsSwiper:null,//轮播器
  title:'Find Products',//标题
  productAddSuccess:false, // 是否不再显示导入成功弹窗
  selectAgain:false, // 选择不再弹窗
  chargeError: false,
  tag:'home',//当前标签页
  skuData: [], //sku列表数据
  variantData: {}, //变体链接数据
  disableAddToCart: true,//添加购物车按钮状态
  addToCartLoading: false,//添加购物车按钮状态
  // 选中添加购物车的sku信息
  addCartItem: {
    id: 0,
    vid: 0,
  },
  minimum_quantity:0,//当前库存
  reviewCount: 0, // DSM产品评论数量
  selectTab: 0,
  linkPage:'', //链接页面
  cateId: '', //分类ID
  minPrice:'',
  maxPrice:'',
  listProduct:'',//来源列表页面
  linkTab:0,
  reviewData:{}, //评论数据
  reviewReq:false,// 是否已经调起评论
  upgradeOpen:false,//是否显示升级弹窗
};


 //进入页面调用接口
 componentDidMount() {
  // if(AppBridge && AppBridge.exist()) {
  //   AppBridge.setTitleBar('Custom Packaging','/admin/customService')
  // }
  let params = new URLSearchParams(window.location.search);
  let id = params.get("filter");
  let fromType = params.get("type");
  let holiday = params.get("holiday");
  let linkPage = params.get("linkPage");
  let cateId  = params.get("cateId");
  let minPrice = params.get('minPrice');
  let maxPrice = params.get('maxPrice');
  let listProduct = params.get('listProduct');
  let tab = params.get('tab');
  let tag = params.get('tag');
  if (id) {
    this.setState(
      {
        search_product_id: id,
        tag: tag ? tag : "home",
        fromPage: fromType ? fromType : "",
        holidayLink: holiday ? true : false,
        linkPage: linkPage ? linkPage : "",
        cateId: cateId ? cateId : "",
        minPrice: minPrice ? minPrice : '',
        listProduct: listProduct ? listProduct : '',
        linkTab: tab ? Number(tab) : 0,
        maxPrice: maxPrice ? maxPrice : '',
      },
      () => {
        this.getPageData();
      }
    );
  } else {
    this.getPageData();
  }
}

// 获取列表数据
getPageData = async () => {
  this.setState({
    loadingPage: true,
  });
  const params = {
    record_id: this.state.search_product_id,
  };
  const response = await reqGetCustomPackagingDetail(params);
  if (response.code === "200") {
    const { data } = response;
    
    this.setState({
      loadingPage: false,
      pageData: data.detail,
      minimum_quantity:data.minimum_quantity,
      options: data.detail.options,
      skuImage: data.detail.image_list[0],
      default: data.detail.image_url,
      skuData: data.detail.options,
      indexPrice: data.detail.sell_price,
      defaultPrice: data.detail.sell_price,
      defaultImage: data.detail.image_list[0],
      variantData: data.detail.variants,
    });
  } else {
    this.setState({
      loadingPage: false,
      pageData: {},
    });
    this.toggleToast(response.msg,true);
  }
};

// 预下单
handleToPurchase = (id) => {
  let person = store.getState().languageReducer.subScriptData;
  if (person.level !== 5) {
    this.setState({
      upgradeOpen:true,
    })
  return
  }
  utils.routeJump(this, "/admin/customService/purchase?record_id=" + id);
};



//   切换轮播图片
handleThumbSwiper = () => {
  this.setState({ skuImage: "" });
};

// 鼠标移入
mouseEnter = () => {
  this.setState({
    magnifierOff: true,
  });
  let big = document.querySelector(".picture_big");
  let mask = document.querySelector(".detail_mask");
  mask.style.display = "block";
  big.style.display = "block";
};
// mouse remove
mouseLeave = () => {
  this.setState({
    magnifierOff: false,
  });
  let big = document.querySelector(".picture_big");
  let mask = document.querySelector(".detail_mask");
  mask.style.display = "none";
  big.style.display = "none";
};
// mouse move
mouseMove = (event) => {
  let e = event.nativeEvent;
  // console.log('e x y',e.nativeEvent);
  this.calculationBlock(e.offsetX, e.offsetY);
};
// calculation params
calculationBlock(offsetx, offsety) {
  let pre_img = document.querySelector(".product_info_swiper");
  let bigImg = document.querySelector(".picture_big img");
  let mask = document.querySelector(".detail_mask");
  let offsetX = offsetx - 50; // 鼠标在盒子中的位置 - mask宽的一半
  let offsetY = offsety - 50; // 鼠标在盒子中的位置 - mask高的一半
  /*block position*/
  // 防止鼠标移动过快导致计算失误，只要小于或大于对应值，直接设置偏移量等于最小或最大值
  // left 取值为 大于 0, 小于 盒子的宽 - mask的宽
  var maskMax = pre_img.offsetWidth - mask.offsetWidth;
  if (offsetX <= 0) {
    offsetX = 0;
  }
  if (offsetX >= maskMax) {
    offsetX = maskMax;
  }
  if (offsetY <= 0) {
    offsetY = 0;
  }
  if (offsetY >= maskMax) {
    // offsetY = small.offsetHeight - mask.offsetHeight;
    offsetY = maskMax;
  }
  // 移动mask
  mask.style.left = offsetX + "px";
  mask.style.top = offsetY + "px";
  /*计算图片放大位置*/
  // 右侧大图片，等比例移动
  // 大图片走的距离 / mask 走的距离 = 大图片 / 小图片
  var bigX = (offsetX * 900) / 300; //大图/小图
  var bigY = (offsetY * 900) / 300;
  //大图的移动(方向相反，取负值)
  bigImg.style.left = -bigX + "px";
  bigImg.style.top = -bigY + "px";
}

//   属性选择
handleSelect = (val, ind) => {
  let data = this.state.skuData;
  let variant = this.state.variantData;
  let variantIndex = "";
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].value_arr.length > 0) {
        if (data[i].selected === val && i === ind) {
          data[i].selected = "";
          this.setState({
            skuData: data,
            indexPrice: this.state.defaultPrice,
            skuImage: this.state.defaultImage,
            currentInventory:this.state.currentInventory
          });
          return;
        }
        for (let j = 0; j < data[i].value_arr.length; j++) {
          if (data[i].value_arr[j].name === val) {
            if (val && i === ind) {
              data[i].selected = val;
            }
          }
        }
      }
      // variant[data[i].selected]
      //是否全选了
      if (variant[data[0].selected]?.id) {
        variantIndex = data[0]?.selected + "-";
      } else {
        variantIndex += data[i]?.selected + "-";
      }
    }
  }
  
  if (variant[variantIndex.slice(0, variantIndex.length - 1)]) {
    this.setState({
      skuImage:
        variant[variantIndex.slice(0, variantIndex.length - 1)]?.sku_image !==
          "" &&
        variant[variantIndex.slice(0, variantIndex.length - 1)]?.sku_image !==
          null
          ? variant[variantIndex.slice(0, variantIndex.length - 1)]?.sku_image
          : this.state.defaultImage,
      indexPrice:
        variant[variantIndex.slice(0, variantIndex.length - 1)]?.price,
      currentInventory: variant[variantIndex.slice(0, variantIndex.length - 1)]?.sku_inventory
    });
  }else{
    this.setState({
      currentInventory:0
    })
  }
  this.setState({
    skuData: data,
  });
};

returnPageLink = ()=>{
  let link =  utils.routeJump(this, "/admin/customService/custom-packaging");
  return {
      link,
  }
}

toggleToast = (showToastText, showToastIserror = false) => {
  this.setState(({ showToast }) => ({
    showToast: !showToast,
    showToastText: showToastText,
    showToastIserror: showToastIserror,
  }));
};
render() {
  const { 
    pageData,thumbsSwiper,skuImage,indexPrice,
    skuData ,reviewCount
  } = this.state;


  const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} duration={2000} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;
  // DSM 描述+评论显示
  let showTabs = [
      {
        id: "all-description",
        content: "Description",
        accessibilityLabel: "All description",
        panelID: "all-description-content",
      },
    ];
    if (reviewCount > 0) {
      showTabs.push({
        id: "all-reviews",
        content: "Reviews (" + reviewCount + ")",
        accessibilityLabel: "All reviews",
        panelID: "all-reviews-content",
      });
    }
  let skuOptionSelect = "";
  if (skuData.length > 0) {
    // 如果是颜色
    skuOptionSelect = (
      <div className="product_sku_attr_box">
        <div className="product_sku_attr_list">
          {skuData.slice(0, 1).map((item, ind) => (
            <div className="" key={ind}>
              <span className="product_sku_attr_title branding_sku_attr_title">{item.name}: {item.selected !== '' ? '('+item.selected+')' : ''}</span>
              {item.value_arr.length > 0 && (
                <div className="product_sku_attr_item_list">
                  {item.value_arr.map((it, k) => (
                    it.image !== '' ? <span
                      className={
                        item.selected === it.name 
                          ? "item_value item_value_img item_value_select item_value_select_img"
                          : "item_value item_value_img"
                      }
                      onClick={() => this.handleSelect(it.name, ind)}
                      key={k}
                    >
                     <img style={{maxWidth:'100%'}} src={it.image} alt={it.name} />
                    
                    </span> : <span
                      className={
                        item.selected === it.name
                          ? "item_value item_value_select"
                          : "item_value"
                      }
                      onClick={() => this.handleSelect(it.name, ind)}
                      key={k}
                    >
                     {it.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ))}
          {skuData.slice(1, 3).map((item, ind) => (
            <div className="" key={ind}>
              <span className="product_sku_attr_title branding_sku_attr_title">{item.name}</span>
              {item.value_arr.length > 0 && (
                <div className="product_sku_attr_item_list">
                  {item.value_arr.map((it, k) => (
                    <span
                      className={
                        item.selected === it.name
                          ? "item_value item_value_select"
                          : "item_value"
                      }
                      style={{
                        color:"rgb(32, 34, 35)",
                        pointerEvents: "auto",
                      }}
                      onClick={() =>this.handleSelect(it.name, ind + 1)
                      }
                      key={k}
                    >
                      {it.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  
  const initSkeletonPage = (
    <div>
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <Card>
              <BlockStack gap={400}>
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </div>
  );
const EmptyProductList = (
<div style={{ minHeight: "600px" }}>
  <EmptyState image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
    <p>{"Get no detail"}</p>
  </EmptyState>
</div>
);

const pageContent = this.state.loadingPage ? (
initSkeletonPage
) : ( <Page backAction={{
content: "Custom Packaging",
onAction: () => this.returnPageLink().link
}}
title={'Custom Packaging'}>
<Layout >
{toastMarkup}
<Layout.Section>
  {Object.values(pageData).length > 0 ? 
  <section>
    <BlockStack gap={400}>
      <Card>
        <section className="Product_Detail_Content">
          <div className="Product_Detail_Img detail_image">
            <div
              className="product_info_swiper"
              onMouseEnter={this.mouseEnter}
              onMouseLeave={this.mouseLeave}
              onMouseMove={this.mouseMove}
            >
              <Swiper
                style={{
                  "--swiper-navigation-color": "#FFF",
                  "--swiper-pagination-color": "#FFF",
                }}
                spaceBetween={20}
                navigation={false}
                onNavigationNext={() =>
                  this.setState({ skuImage: "" })
                }
                onNavigationPrev={() =>
                  this.setState({ skuImage: "" })
                }
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2 swiper-no-swiping"
              >
                <img
                  src={
                    skuImage !== ""
                      ? skuImage
                      : pageData.image_list &&
                        pageData.image_list[0]
                  }
                  alt="dropshipman"
                />
                <div className="detail_mask"></div>
              </Swiper>
            </div>
            <div className="picture_big">
              <img
                src={
                  skuImage !== ""
                    ? skuImage
                    : pageData.image_list && pageData.image_list[0]
                }
                alt="dropshipman"
              />
            </div>
            <div className="details_product">
              <div className="prev">
                <Icon source={ChevronLeftIcon} tone="base" />
              </div>
              <div className="next">
                <Icon source={ChevronRightIcon} tone="base" />
              </div>
              <Swiper
                onSwiper={() =>
                  this.setState({ thumbsSwiper: null })
                }
                onClick={() => this.handleThumbSwiper()}
                spaceBetween={20}
                freeMode={true}
                observer={true}
                observeParents={true}
                slidesPerView={"auto"}
                watchSlidesProgress={true}
                navigation={{ prevEl: `.prev`, nextEl: `.next` }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {pageData.image_list &&
                  pageData.image_list
                    .slice(0, 20)
                    .map((image_url, ind) => (
                      <SwiperSlide
                        onClick={() =>
                          this.setState({ skuImage: image_url })
                        }
                        className={
                          image_url === skuImage
                            ? "product_swiper_thumbs_hover"
                            : "product_swiper_thumbs"
                        }
                        key={ind}
                      >
                        <img src={image_url} alt="dropshipman" />
                      </SwiperSlide>
                    ))}
              </Swiper>
            </div>
          </div>

          <div className="Product_Detail_Box detail branding_detail">
            <FormLayout>
              {pageData.product_link ? (
                <Link
                  url={pageData.product_link}
                  external
                  removeUnderline
                >
                  <Text variant="headingMd" as="h2">
                    {pageData.title}
                  </Text>
                </Link>
              ) : (
                <Text variant="headingMd" as="h2">
                  {pageData.title}
                </Text>
              )}

              {/* 价格显示 */}

              <div className="Product__Attributes price brading_detail_price">
              {indexPrice !== "" && <span>$</span>}
                {indexPrice !== ""
                  ? indexPrice
                  : pageData.sell_price}
              </div>

              {/* sku属性 */}
              {skuOptionSelect}

              <div className="Product__Attributes">
                <div className="Product__Attributes_Content">
                  <span style={{fontSize:16,fontWeight:500}}>MOQ {this.state.minimum_quantity}</span>
                  </div>
              </div>

              <div className="branding_purchase_btn">
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      onClick={()=>this.handleToPurchase(pageData.record_id)}
                    >
                      {"Purchase"}
                    </Button>
                  </ButtonGroup>
              </div>
            </FormLayout>
          </div>
        </section>
      </Card>

      <Card>
          <Tabs
            tabs={showTabs}
            selected={0}
            onSelect={(index) =>
              this.setState({ selectTab: index })
            }
          >
            <div className="detail_product_cj"
              dangerouslySetInnerHTML={{
                __html: pageData.description,
              }}
            ></div>
          </Tabs>
        </Card>
    </BlockStack>
  </section> : <Card>{EmptyProductList}</Card>}
</Layout.Section>
</Layout>
</Page>)
  return (
    <div>
     {pageContent}
     <PremiumPlanModal upgradeOpen={this.state.upgradeOpen} setUpgradeOpen={()=>{
           this.setState({upgradeOpen:false})
          }}/>
    </div>
  );
}
}

export default connect()(withRouter(ProductDetailClass));
