import React, { Component } from 'react';
import {
    Modal
} from "@shopify/polaris";
import { Prompt, withRouter } from 'react-router-dom';
import intl from '../../../src/i18n/intl'
import utils from '../../utils/utils'
class SettingPrompt extends Component {
  state = {
    disabledSaveData: true,
    modalShow: false,
    nextUrl: '',
    currentUrl: '',
    saveData: null,
    save:true,
  }
  UNSAFE_componentWillReceiveProps = (prev) => {
    this.setState({
      disabledSaveData: prev.disabledSaveData,
      currentUrl: prev.url,
      saveData: prev.saveData,
      save:prev.save
    })
  }
  handleUrlChange = () => {
    if (this.state.nextUrl !== '') {
      if(this.state.save){
        this.state.saveData();
      }else{
        this.state.saveData(false);
      }
      if (!this.state.disabledSaveData) {
          utils.routeListen(this.state.nextUrl)
          utils.routeJump(this,this.state.nextUrl)
      }
    }

  };
  handleUrlClose = () => {
    if (this.state.nextUrl !== '') {
      utils.routeListen(this.state.nextUrl)
      utils.routeJump(this,this.state.nextUrl)
    }
  }
  handleClose = () => {
    this.setState({
      modalShow: false
    })
  }
  render() {
    const { disabledSaveData, currentUrl } = this.state
    return (
      <div>
        <Prompt when={!disabledSaveData}
          message={(loc) => {
            if (loc.pathname !== currentUrl && !disabledSaveData) {
              this.setState({
                modalShow: true,
                nextUrl: loc.pathname
              })
            }
            return this.state.modalShow
          }} />
        <Modal
          open={this.state.modalShow}
          onClose={this.handleClose}
          title={intl.get('Edited info not saved')}
          primaryAction={{
            content: intl.get('Save'),
            onAction:this.handleUrlChange,
          }}
          secondaryActions={[
            {
              content:intl.get('Discard') ,
              onAction: this.handleUrlClose,
            },
          ]}
        >
          <Modal.Section>
             
              <p>
                {intl.get('Your edited information has not been saved, and will not be saved after leaving.')}
              </p>
             
          </Modal.Section>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SettingPrompt);
