import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  Box,
  BlockStack,
  ProgressBar,
  InlineStack,
} from "@shopify/polaris";
import React from "react";

const DetailSheletonPage = () => {
  return (
      <SkeletonPage primaryAction fullWidth={false}>
      <Layout>
      <Box className="Polaris-Layout__Section">
      <Card>
          <BlockStack gap={200}>
            <Box style={{width:"20%"}}>
            <SkeletonDisplayText size="medium" />
            </Box>
            <BlockStack gap={200}>
            <SkeletonBodyText />
          </BlockStack>
          <BlockStack gap={200}>
                   <Box>
                     <BlockStack gap={500} >
                   {list.map((item,ind)=><InlineStack key={ind} wrap={false} gap={200}>
                     <Box style={{width:"20px"}}>
                     <ProgressBar/>
                     </Box>
                     <Box style={{width:"5%"}}>
                     <ProgressBar/>
                     </Box>
                     <Box style={{width:"30%"}}>
                     <ProgressBar/>
                     </Box>
                     <Box style={{width:"10%"}}>
                     <ProgressBar/>
                     </Box>
                     <Box style={{width:"10%"}}>
                     <ProgressBar/>
                     </Box>
                     <Box style={{width:"10%"}}>
                     <ProgressBar/>
                     </Box>
                     <Box style={{width:"10%"}}>
                     <ProgressBar/>
                     </Box>
                     <Box style={{width:"20%"}}>
                     <ProgressBar/>
                     </Box>
                   </InlineStack>)}
                   </BlockStack>
                   </Box>
            </BlockStack>
            </BlockStack>
          </Card>
      
        </Box>
        <Layout.Section>
        <BlockStack gap={200}>
          
          <Card>
          <BlockStack gap={200}>
          <SkeletonBodyText />
          <SkeletonBodyText />
          <SkeletonBodyText />
          </BlockStack>
          </Card>
          <Card>
          <BlockStack gap={200}>
          <SkeletonBodyText />
          <SkeletonBodyText />
          </BlockStack>
          </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
        <BlockStack gap={200}>
          {/* <Card>
          <BlockStack gap={200}>
          <SkeletonBodyText />
          <SkeletonBodyText />
          <SkeletonBodyText />
          </BlockStack>
          </Card> */}
          <Card>
          <BlockStack gap={200}>
          <SkeletonBodyText />
          <SkeletonBodyText />
          </BlockStack>
          </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
      </SkeletonPage>
  );
};
const list = [1,2,3,4,5,6,7,8,9,11]
export default DetailSheletonPage;
