import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { FormLayout, Modal, Toast, Badge, TextField, Icon, Text } from "@shopify/polaris";

import {
    ViewIcon,EyeCheckMarkIcon
} from '@shopify/polaris-icons';

import { reqPostAppAccountAction } from '../../api';

// import utils from '../../utils/utils';
import intl from '../../i18n/intl'
import './index.css'

class NeedUpdateInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ModalShow: (props.show===undefined)?false:props.show,
            accountEmail: props.email,

            hideNewPassword: true,
            hideConfirmPassword: true,
            newPasswordError: '',
            confirmPasswordError:'',

            //弹窗
            showToast: false,  //弹窗显示
            showToastText:'',  //弹窗文字
            showToastIserror:false, //弹窗是否错误

            Buttonloading: false,
            noAllowToRequest: true,

            browser:'' //TextField组件 兼容处理
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            ModalShow: (nextProps.show===undefined)?false:nextProps.show,
            accountEmail: nextProps.email,

            hideNewPassword: true,
            hideConfirmPassword: true,
            newPasswordError: '',
            confirmPasswordError:'',

            //弹窗
            showToast: false,  //弹窗显示
            showToastText:'',  //弹窗文字
            showToastIserror:false, //弹窗是否错误

            Buttonloading: false,
            noAllowToRequest: true,

        });
    }

    //进入页面调用接口
    componentDidMount () {
        const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
        const browser = userAgent.includes('Edg')?  'Edg':''
        this.setState({browser})
    }

    // 重置密码确认，并跳转
    resetPasswordRequest = async () => {
        let isAllow = true;
        if(!this.checkInputPassword(this.state.NewPassword)){
            isAllow = false;
        }
        if(!this.checkInputConfirmPassword(this.state.ConfirmPassword)){
            isAllow = false;
        }
        if(!isAllow){
            return false;
        }

        const params = {
            t: 'one',
            new: this.state.NewPassword,
            confirm: this.state.ConfirmPassword,
        };
        this.setState({ Buttonloading : true });
        const responseData = await reqPostAppAccountAction(params)
        if (responseData.code==='200') {
            this.setState({
                Buttonloading : false,
                ModalShow: false
            },()=>{
                this.props.onClose({type:'updateInfo'});
                // this.props.history.push('/admin/dashboard');
            });

            this.toggleToast(responseData.msg);
        } else {
            this.toggleToast(responseData.msg);
        }
    }

    //提示弹窗
    toggleToast = (showToastText, showToastIserror=false) => {
        this.setState(({ showToast }) => ({
          showToast: !showToast,
          showToastText: showToastText,
          showToastIserror: showToastIserror
        }));
    };

    handleChange = () => {
        // this.setState(({ ModalShow }) => ({ ModalShow: !ModalShow }));
        this.setState({
            ModalShow:!this.state.ModalShow,
        },()=>{
            this.props.onClose({type:'updateInfo'});
        });
    };

    // input change
    handleInputChange = (value,id)=> {
        this.setState({
            [id]: value,
        });
    }

    handleInputBlur=(value,id)=>{
        id === 'NewPassword' ? value = value.NewPassword: value = value.ConfirmPassword
        return ()=>{
            this.setState({
                [id]: value,
                },()=>{
                    setTimeout(()=>{
                        switch(id){
                        case 'NewPassword':
                            this.checkInputPassword(value);
                            break;
                        case 'ConfirmPassword':
                            this.checkInputConfirmPassword(value);
                            break;
                        default:
                        }
                    },600);
                });
        }
    }

    // 密码显示与隐藏
    handleDisplaySwitch = (field) =>{
        switch(field){
        case 'hidePassword':
            this.setState(({ hidePassword }) => ({ hidePassword: !hidePassword }));
            break;
        case 'hideNewPassword':
            this.setState(({ hideNewPassword }) => ({ hideNewPassword: !hideNewPassword }));
            break;
        case 'hideConfirmPassword':
            this.setState(({ hideConfirmPassword }) => ({ hideConfirmPassword: !hideConfirmPassword }));
            break;
        default:
        }
    }

    // email 格式验证
    checkInputEmail(email){
        let errorMsg = '';
        let bool = false;
        if(email === undefined || email === ''){
            errorMsg = intl.get('Please enter new account email');
        }

        if(errorMsg === ''){
            let reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
            bool = reg.test(email);
            errorMsg = bool ? '' : intl.get('Invalid email address');
        }
        this.setState({ newEmailAddressError: errorMsg });
        return bool;
    }

    // password 密码校验
    checkInputPassword(password){
        let errorMsg = '';
        let bool = true;
        if(password === undefined || password === ''){
            bool = false;
            errorMsg = intl.get('Please set your new password');
        }

        // 注册时校验密码格式
        if(errorMsg === ''){
            // 1.The password must be 8 - 24 characters long
            // 2.The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number

            if(password.length < 8 || password.length > 24){
                errorMsg = intl.get('The password must be 8 - 24 characters long');
                bool = false;
            } else {
                let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
                bool = reg.test(password);
                errorMsg = bool ? '' : 'The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number';
            }
        }

        this.setState({ newPasswordError: errorMsg });
        return bool;
    }

    // 两次密码校验
    checkInputConfirmPassword(password){
        let errorMsg = '';
        let bool = false;
        if(password === '' || password === undefined){
            errorMsg = intl.get('The password cannot be empty');
        }

        if(errorMsg === ''){
            if(password === this.state.NewPassword){
                bool = true;
            }
            errorMsg = bool ? '' : 'Confirm password doesn\'t match';
        }
        this.setState({ 
            confirmPasswordError: errorMsg,
            noAllowToRequest: bool ? false : true
         });
        return bool;
    }

    render() {
        const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
        const {
            state: { ModalShow,Buttonloading,accountEmail,noAllowToRequest,
                hideNewPassword,NewPassword, newPasswordError,hideConfirmPassword,ConfirmPassword,confirmPasswordError,
            },
        } = this;

        return (
            <div>
                <Modal
                    open={ModalShow}
                    title={intl.get("Please set your password!")}
                    onClose={this.handleChange}
                    primaryAction={{
                        content: intl.get("Set password"),
                        onAction: this.resetPasswordRequest,
                        loading: Buttonloading,
                        disabled: noAllowToRequest
                    }}
                >
                    <Modal.Section>
                        <section className="auth_tips_content">
                            <div className="title">{intl.get("Please note that:")}</div>

                            <div className="auth_step">
                                <div className="step_text">{intl.get("1. Our login system is separated from Shopify. This means you can log in on the official website. We have generated the platform account of Dropshipman for you, as follows")}</div>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                                <Badge tone="info">{accountEmail}</Badge>
                            </div>

                            <div className="auth_step">
                                <div className="step_text">
                                {intl.get("2. This account will not affect your access to Dropshipman from Shopify Apps, and you can also use your account password to log in from Dropshipman's official website. Don't hesitate to reach out if you need help!")}
                                </div>
                            </div>

                            <div className="auth_step">
                                <div className="step_text">{intl.get("3. Please set your password so that you can log in later.")}</div>
                                <div style={{padding:'2rem'}}>
                                    <FormLayout>
                                        <TextField 
                                            id="NewPassword"
                                            type={hideNewPassword ? "password" : "text"}
                                            label={intl.get("New Password")} 
                                            value={NewPassword} 
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleInputBlur({NewPassword},'NewPassword')}
                                            minLength={8}
                                            maxLength={24}
                                            error={newPasswordError}
                                            suffix={
                                                this.state.browser === 'Edg' ? ("") : (<div className="reset_password" onClick={()=>this.handleDisplaySwitch('hideNewPassword')}>
                                                <Icon source={hideNewPassword ? ViewIcon : EyeCheckMarkIcon}/>
                                            </div>)
                                                
                                            }
                                        />
                                        <TextField 
                                            id="ConfirmPassword"
                                            type={hideConfirmPassword ? "password" : "text"}
                                            label={intl.get("Confirm Password")} 
                                            value={ConfirmPassword} 
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleInputBlur({ConfirmPassword},'ConfirmPassword')}
                                            minLength={8}
                                            maxLength={24}
                                            error={confirmPasswordError}
                                            suffix={
                                                this.state.browser === 'Edg' ? ("") : (<div className="reset_password" onClick={()=>this.handleDisplaySwitch('hideConfirmPassword')}>
                                                <Icon source={hideConfirmPassword ? ViewIcon : EyeCheckMarkIcon}/>
                                            </div>)
                                            }
                                        />
                                    </FormLayout>
                                </div>
                            </div>

                            <Text  variant="bodyMd" as="span" color="subdued">{intl.get("If you encounter any problems, please contact us.")}</Text>
                        </section>
                    </Modal.Section>
                </Modal>
                { toastMarkup }
            </div>
        );
    }
}

export default withRouter(NeedUpdateInfo);