import React, { Component } from 'react';

import {AppProvider, Frame, TextField, Button, Toast, Banner } from '@shopify/polaris';

import { reqPostBindingStore } from '../../../api';

import './index.css';
import translations from '@shopify/polaris/locales/en.json';
import utils from '../../../utils/utils';

class BindingStore extends Component {

  state = {
    domain: '',
    domainError: '',
    bindingStoreLoading: false,
    returnErrorMsg: '',

    // 提示弹窗
    showToast: false, // 提示弹窗是否显示
    showToastText: '', // 提示内容
    showToastIserror: false, // 是否为错误提示
  };

  //进入页面调用接口
  componentDidMount() {
    // 清除浏览器链接上的参数
    // try {
    //   var currentUrl = window.location.href;
    //   if (currentUrl.indexOf("?") !== -1) {
    //     currentUrl = currentUrl.replace(/(\?|#)[^'"]*/, '');
    //     if (!!(window.history && window.history.pushState)){
    //       window.history.pushState({}, 0, currentUrl);
    //     }

    //     setTimeout(() => {
    //       this.handleGooleTag();
    //     },2000);
    //   }
    // } catch (error) {
    //   console.warn(error);
    // }
    window.hideLoadingState()
  }

  // 谷歌分析
  // handleGooleTag = () => {
  //   try {
  //     window.gtag('event', 'conversion', {'send_to': 'AW-749999345/y-k4CIuay4sDEPGp0OUC'});
  //   } catch(e) {
  //     console.log(e)
  //   }
  // }

  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  BindingStoreRequest = async() => {
    if(!this.checkInputDomain(this.state.domain)){
      return false;
    }
    const params = {
      shop: this.state.domain,
      c: ''
    };

    this.setState({ bindingStoreLoading: true });
    const response = await reqPostBindingStore(params)
    this.setState({ bindingStoreLoading: false });
    if (response.code==='200') {
      // 后端返回重定向链接
      window.location.href= response.data;

    } else if(response.code === '404') {
      this.setState({returnErrorMsg: response.msg});
      // this.toggleToast(response.msg,true);
    } else{
      this.setState({domainError: response.msg});
    }
  }

  // 退出登录
  logoutRequest = () => {
    utils.removeStore('S_AUTHORIZATION');
    utils.setStore('S_SHOP_DOMAIN','');
    utils.setStore('S_USER_TOKEN','');
    window.location.href='/login';
    return false;
  }

  handleInputChange = (value,field) => {
    if(value !== ''){
      value = value.replace('.myshopify.com', '');
    }
    this.setState({
      [field]: value,
      returnErrorMsg: ''
    },()=>{
      setTimeout(()=>{
        this.checkInputDomain(value);
      },500);
    });
  }

  // 域名前缀检测
  checkInputDomain(domain){
    let isTrue = true;
    let errorMsg = '';
    if(domain === undefined || domain === ''){
      isTrue = false;
      errorMsg = 'Please enter the website domain';

    } else {
      let pat = new RegExp("^[A-Za-z0-9-]+$");
      if(!pat.test(domain)){
        isTrue = false;
      }else{
          let first = domain.charAt(0);
          let last = domain.charAt(domain.length-1);
          if (last === '-' || first === '-') {
            isTrue = false;
          }
      }
      // 长度不能少于2
      if (isTrue && domain.length <= 2) {
        isTrue = false;
      }

      if(!isTrue){
        errorMsg = 'Please make sure to fill in English letter, number and hyphen only for the website domain';
      }
    }
    this.setState({ domainError: errorMsg });
    return isTrue;
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    const { 
      domain, domainError, bindingStoreLoading, returnErrorMsg
    } = this.state;

    // 请求错误显示
    const RequestErrorMSG = returnErrorMsg && (
      <div className="binding_error">
        <Banner tone="warning"><p>{returnErrorMsg}</p></Banner>
      </div>
    );

    return (
      <AppProvider i18n={translations}>
        <Frame>
          <section className="dropshipman_linking_store">
            <div className="linking_store_content">
              
              <div className="linking_form">

                <div className="linking_logo">
                  <img src="/logo.png?v=456" alt="dropshipman logo" />
                  <div className="linking_sub">Enter your shopify website domain</div>
                </div>
                
                <div className="linking_input">
                  <TextField
                    id="domain"
                    type="text"
                    label="Enter your shopify website domain" 
                    labelHidden
                    placeholder="your-domain"
                    onChange={this.handleInputChange} 
                    value={domain}
                    suffix={<div className="suffix">.myshopify.com</div>}
                    error={domainError}
                  />
                </div>

                {RequestErrorMSG}

                <div className="linking_button">
                  <Button
                    variant='primary'
                    onClick={this.BindingStoreRequest}
                    loading={bindingStoreLoading}
                  >
                    Add store
                  </Button>

                  <Button
                    onClick={this.logoutRequest}
                    loading={false}
                  >
                    Log out
                  </Button>
                </div>
              </div>
            </div>

            <div className="linking_store_other">
              <div className="text">
                Connect your Shopify store to Dropshipman
              </div>
            </div>
          </section>

          {toastMarkup}
        </Frame>
      </AppProvider>
    );
  }
}

export default BindingStore;
