import {
  OptionList,
  Button,
  Card,
  Popover,
  Box,
  Icon,
  Collapsible,
  InlineStack,
} from "@shopify/polaris";
import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CalendarIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@shopify/polaris-icons";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
import { reqGetOrderStatistics } from "../../../../api";
import intl from "../../../../i18n/intl";
import Tooltips from "./Tooltips";
import ReactECharts from "echarts-for-react";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/lines";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";

export default function FulfilledOrders(props) {
  const language = useSelector((state) => state.languageReducer.language);
  let defalutData = useMemo(() => {
    let data = {
      orderStaticsTable: [
        {
          date: "10/1",
          Sales: 0,
          Costs: 0,
          Profits: 0,
          orders: 0,
        },
        {
          date: "10/2",
          Sales: 0,
          Costs: 0,
          Profits: 0,
          orders: 0,
        },
        {
          date: "10/3",
          Sales: 0,
          Costs: 0,
          Profits: 0,
          orders: 0,
        },
        {
          date: "10/4",
          Sales: 0,
          Costs: 0,
          Profits: 0,
          orders: 0,
        },
        {
          date: "10/5",
          Sales: 0,
          Costs: 0,
          Profits: 0,
          orders: 0,
        },
        {
          date: "10/6",
          Sales: 0,
          Costs: 0,
          Profits: 0,
          orders: 0,
        },
        {
          date: "10/7",
          Sales: 0,
          Costs: 0,
          Profits: 0,
          orders: 0,
        },
      ],
      orderStatics: {
        costs: 0,
        orders: 0,
        profits: 0,
        sales: 0,
      },
    };
    if (language) {
      data = {
        orderStaticsTable: [
          {
            date: "10/1",
            Sales: 0,
            Costs: 0,
            Profits: 0,
            orders: 0,
          },
          {
            date: "10/2",
            Sales: 0,
            Costs: 0,
            Profits: 0,
            orders: 0,
          },
          {
            date: "10/3",
            Sales: 0,
            Costs: 0,
            Profits: 0,
            orders: 0,
          },
          {
            date: "10/4",
            Sales: 0,
            Costs: 0,
            Profits: 0,
            orders: 0,
          },
          {
            date: "10/5",
            Sales: 0,
            Costs: 0,
            Profits: 0,
            orders: 0,
          },
          {
            date: "10/6",
            Sales: 0,
            Costs: 0,
            Profits: 0,
            orders: 0,
          },
          {
            date: "10/7",
            Sales: 0,
            Costs: 0,
            Profits: 0,
            orders: 0,
          },
        ],
        orderStatics: {
          costs: 0,
          orders: 0,
          profits: 0,
          sales: 0,
        },
      };
    }
    return data;
  }, [language]);
  const [orderData, setOrderData] = useState(defalutData);
  const [selected, setSelected] = useState(["3"]);
  const [currentDate, setCurrentDate] = useState("Last 30 days");
  const [getStartClo, setGetStartClo] = useState(true); //
  const options = [
    { label: intl.get("Today"), value: "1" },
    { label: intl.get("Last 7 days"), value: "2" },
    { label: intl.get("Last 30 days"), value: "3" },
    { label: intl.get("Last 90 days"), value: "4" },
    { label: intl.get("Last 365 days"), value: "5" },
  ];
  const [opction, setOpction] = useState({
    title: {
      // text: 'Stacked Line'
    },
    tooltip: {
      trigger: "axis",
    },
    color: ["rgb(41, 171, 228)", "rgb(132, 203, 234)"],

    legend: {
      bottom: "0%",
      itemWidth: 16,
      itemHeight: 1,

      data: [
        {
          name: "Sales",
          icon: "path://M5 10c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75s-.336.75-.75.75h-8.5c-.414 0-.75-.336-.75-.75",
        },
        {
          name: "Costs",

          icon: "path://path://M6 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z M11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z M17 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z",
        },
      ],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      containLabel: true,
    },
    toolbox: {
      // feature: {
      //   saveAsImage: {}
      // }
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisTick: {
        show: false,
      },
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },

    yAxis: {
      // type: "value",
      // min:5,
      // show:true,
      // dataMin:5,
      offset: 10,
    },
    series: [
      {
        name: "Sales",
        type: "line",
        smooth: true,
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Costs",
        type: "line",
        smooth: true,
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  const getOrderData = async (value) => {
    let params = {
      type: value,
    };
    let res = await reqGetOrderStatistics(params);
    if (res.code === "200") {
      setOrderData((prevValue) => ({
        ...prevValue,
        orderStaticsTable: res.data.order_statistics_table,
        orderStatics: res.data.order_statistics,
      }));
      let data = res.data.order_statistics_table;
      const dates = data.map((item) => item.date);
      const sales = data.map((item) => item.Sales);
      const costs = data.map((item) => item.Costs);
      setOpction({
        ...opction,
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false,
          },

          data: dates,
        },
        series: [
          {
            name: "Sales",
            type: "line",
            smooth: true,
            data: sales,
          },
          {
            type: "line",
            smooth: true,
            name: "Costs",
            lineStyle: {
              color: "rgb(132, 203, 234)", // 线的颜色
              width: 2, // 线宽
              type: "dotted", // 线的类型，默认是'solid'，可以设置为'dashed'或'dotted'等
            },
            data: costs,
          },
        ],
      });
    }
  };
  const getDefalutData = (data) => {
    const svg = document.querySelector(".recharts-surface");
    if (svg) {
      svg.setAttribute(
        "viewBox",
        `0 0 ${svg.getAttribute("width")} ${
          Number(svg.getAttribute("height")) + 15
        }`
      );
    }
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getDefalutData();
    }
    return () => {
      isMount = true;
    };
  }, [orderData]);
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getOrderData("3");
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSelectChange = (val) => {
    setSelected(val);
    getOrderData(val[0]);
    if (val[0] === "1") {
      setCurrentDate("Today");
    }
    if (val[0] === "2") {
      setCurrentDate("Last 7 days");
    }
    if (val[0] === "3") {
      setCurrentDate("Last 30 days");
    }
    if (val[0] === "4") {
      setCurrentDate("Last 90 days");
    }
    if (val[0] === "5") {
      setCurrentDate("Last 365 days");
    }
  };
  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length && payload[0].dataKey === "Sales") {
  //     return (
  //       <div className="product-tooltip product-tooltips">
  //         <span>{payload[0].payload.date}</span>
  //         <p className="product_label">
  //           <span
  //             className={"product_tip_day"}
  //             style={{ display: "flex", alignItems: "center" }}
  //           >
  //             {intl.get("Orders")}:{" "}
  //             <span>
  //               {" "}
  //               {payload[0].payload.orders ? payload[0].payload.orders : 0}
  //             </span>
  //           </span>
  //           <span style={{ display: "flex", alignItems: "center" }}>
  //             {intl.get("Sales")}:{" "}
  //             <span
  //               className={
  //                 payload[0].payload.Sales < 0
  //                   ? "product_tip_num product_tip_num_less"
  //                   : "product_tip_num"
  //               }
  //             >
  //               {" "}
  //               ${" "}
  //               {payload[1].payload.Sales
  //                 ? parseFloat(payload[1].payload.Sales.toFixed(2))
  //                 : 0}
  //             </span>
  //           </span>
  //           <span style={{ display: "flex", alignItems: "center" }}>
  //             {intl.get("Costs")}:{" "}
  //             <span
  //               className={
  //                 payload[0].payload.Costs < 0
  //                   ? "product_tip_num product_tip_num_less"
  //                   : "product_tip_num"
  //               }
  //             >
  //               {" "}
  //               ${" "}
  //               {payload[1].payload.Costs
  //                 ? parseFloat(payload[1].payload.Costs.toFixed(2))
  //                 : 0}
  //             </span>
  //           </span>
  //           <span style={{ display: "flex", alignItems: "center" }}>
  //             {intl.get("Profits")}:{" "}
  //             <span
  //               className={
  //                 payload[0].payload.profits < 0
  //                   ? "product_tip_num product_tip_num_less"
  //                   : "product_tip_num product_tip_nums"
  //               }
  //             >
  //               {" "}
  //               ${" "}
  //               {payload[1].payload.profits
  //                 ? parseFloat(payload[1].payload.profits.toFixed(2))
  //                 : 0}
  //             </span>
  //           </span>
  //         </p>
  //       </div>
  //     );
  //   }
  //   if (active && payload && payload.length && payload[1].dataKey === "Costs") {
  //     return (
  //       <div className="product-tooltip product-tooltips">
  //         <span>{payload[1].payload.date}</span>
  //         <p className="product_label">
  //           <span
  //             className={"product_tip_day"}
  //             style={{ display: "flex", alignItems: "center" }}
  //           >
  //             {intl.get("Orders")}:{" "}
  //             <span>
  //               {" "}
  //               {payload[1].payload.orders ? payload[1].payload.orders : 0}
  //             </span>
  //           </span>
  //           <span style={{ display: "flex", alignItems: "center" }}>
  //             {intl.get("Sales")}:{" "}
  //             <span
  //               className={
  //                 payload[1].payload.Sales < 0
  //                   ? "product_tip_num product_tip_num_less"
  //                   : "product_tip_num"
  //               }
  //             >
  //               {" "}
  //               ${" "}
  //               {payload[1].payload.Sales
  //                 ? parseFloat(payload[1].payload.Sales.toFixed(2))
  //                 : 0}
  //             </span>
  //           </span>
  //           <span style={{ display: "flex", alignItems: "center" }}>
  //             {intl.get("Costs")}:{" "}
  //             <span
  //               className={
  //                 payload[1].payload.Costs < 0
  //                   ? "product_tip_num product_tip_num_less"
  //                   : "product_tip_num"
  //               }
  //             >
  //               {" "}
  //               ${" "}
  //               {payload[1].payload.Costs
  //                 ? parseFloat(payload[1].payload.Costs.toFixed(2))
  //                 : 0}
  //             </span>
  //           </span>
  //           <span style={{ display: "flex", alignItems: "center" }}>
  //             {intl.get("Profits")}:{" "}
  //             <span
  //               className={
  //                 payload[1].payload.profits < 0
  //                   ? "product_tip_num product_tip_num_less"
  //                   : "product_tip_num product_tip_nums"
  //               }
  //             >
  //               {" "}
  //               ${" "}
  //               {payload[1].payload.profits
  //                 ? parseFloat(payload[1].payload.profits.toFixed(2))
  //                 : 0}
  //             </span>
  //           </span>
  //         </p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };
  // csv 导出
  // const handleExport = async () => {
  //   let params = {
  //     type: selected[0],
  //   };
  //   let res = await getOrderStatisticsCsv(params);
  //   if (res.code === "200") {
  //     var csv = convertToCSV(res.data);
  //     downloadCSV(csv);
  //   }
  // };
  // const convertToCSV = (data) => {
  //   var csv = "";
  //   for (var i = 0; i < data.length; i++) {
  //     var row = data[i];
  //     for (var j = 0; j < row.length; j++) {
  //       var val = row[j] === null ? "" : row[j].toString();
  //       if (
  //         val.indexOf(",") !== -1 ||
  //         val.indexOf('"') !== -1 ||
  //         val.indexOf("\n") !== -1
  //       ) {
  //         val = '"' + val.replace(/"/g, '""') + '"';
  //       }
  //       if (j > 0) {
  //         csv += ",";
  //       }
  //       csv += val;
  //     }
  //     csv += "\n";
  //   }
  //   return csv;
  // };

  // const downloadCSV = (csv) => {
  //   var link = document.createElement("a");
  //   link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
  //   link.download = "data.csv";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = () => {
    setPopoverActive(!popoverActive);
  };

  return (
    <div className="fulfilled_orders_content">
      <div className="fulfilled_orders_content_top">
        {/* <div className="fulfilled_orders_content_top_title">{intl.get("Fulfilled Orders")}</div>
        <div className="fulfilled_orders_content_top_tips">
        <Tooltips text={intl.get('Displayed are Shopify orders fulfilled through Dropshipman, excluding manual, stock, and packaging orders')}/>
          </div> */}
        <div className="fulfilled_orders_content_select">
          <Popover
            active={popoverActive}
            activator={
              <Button icon={CalendarIcon} onClick={togglePopoverActive}>
                {currentDate}
              </Button>
            }
            autofocusTarget="first-node"
            onClose={togglePopoverActive}
          >
            <OptionList
              onChange={handleSelectChange}
              actionRole="menuitem"
              options={options}
              selected={selected}
            />
          </Popover>
        </div>
      </div>
      <Card>
        <div className="fulfilled_orders_content_lines">
          <div className="fulfilled_orders_content_line_mobile">
            <div className="fulfilled_orders_content_line_item">
              <InlineStack wrap={false} direction={"row"}>
                <div
                  className="fulfilled_orders_content_line_title"
                  style={{ paddingLeft: "24px" }}
                >
                  <div
                    style={{
                      fontWeight: 550,
                      marginBottom: "8px",

                      width: "max-content",
                      borderBottom: "2px dotted rgba(204, 204, 204, 1)",
                    }}
                  >
                    <Tooltips
                      width={244}
                      title={intl.get("Orders")}
                      text={
                        <Box style={{ padding: "8px", width: "244px" }}>
                          <Box>
                            <strong>Orders</strong>
                          </Box>
                          <Box>
                            Total Shopify orders fulfilled by Dropshipman
                          </Box>
                        </Box>
                      }
                    ></Tooltips>
                  </div>
                  <span
                    className="fulfilled_orders_content_line_num"
                    style={{ fontSize: "20px", fontWeight: 650 }}
                  >
                    {orderData.orderStatics?.orders}
                  </span>
                </div>
                <div className="fulfilled_orders_content_line_title">
                  <div
                    style={{
                      fontWeight: 550,
                      marginBottom: "8px",
                      fontSize: "13px",
                      width: "max-content",
                      borderBottom: "2px dotted rgba(204, 204, 204, 1)",
                    }}
                  >
                    <Tooltips
                      width={244}
                      title={intl.get("Sales")}
                      text={
                        <Box style={{ padding: "8px", width: "244px" }}>
                          <Box>
                            <strong>Sales</strong>
                          </Box>
                          <Box>
                            Sales from store orders fulfilled via Dropshipman
                          </Box>
                        </Box>
                      }
                    ></Tooltips>
                  </div>
                  <span
                    className="fulfilled_orders_content_line_num"
                    style={{ fontSize: "20px", fontWeight: 650 }}
                  >
                    $ {orderData.orderStatics?.sales.toFixed(2)}
                  </span>
                </div>
                <div className="fulfilled_orders_content_line_title">
                  <div
                    style={{
                      fontWeight: 550,
                      marginBottom: "8px",
                      fontSize: "13px",
                      width: "max-content",
                      borderBottom: "2px dotted rgba(204, 204, 204, 1)",
                    }}
                  >
                    <Tooltips
                      width={244}
                      title={intl.get("Costs")}
                      text={
                        <Box style={{ padding: "8px", width: "244px" }}>
                          <Box>
                            <strong>Costs</strong>
                          </Box>
                          <Box>
                            The cost of store orders fulfilled via Dropshipman,
                            including product costs, shipping fees, and taxes.
                          </Box>
                        </Box>
                      }
                    ></Tooltips>
                  </div>
                  <span
                    className="fulfilled_orders_content_line_num"
                    style={{ fontSize: "20px", fontWeight: 650 }}
                  >
                    $ {orderData.orderStatics?.costs.toFixed(2)}
                  </span>
                </div>
                <div className="fulfilled_orders_content_line_title">
                  <div
                    style={{
                      fontWeight: 550,
                      marginBottom: "8px",
                      fontSize: "13px",
                      width: "max-content",
                      borderBottom: "2px dotted rgba(204, 204, 204, 1)",
                    }}
                  >
                    <Tooltips
                      width={244}
                      title={intl.get("Profits")}
                      text={
                        <Box style={{ padding: "8px", width: "244px" }}>
                          <Box>
                            <strong>Profits</strong>
                          </Box>
                          <Box>Profit from orders fulfilled by Dropshipman</Box>
                          <Box
                            style={{
                              padding: "6px 7px 6px 7px",
                              borderRadius: "4px",
                              backgroundColor: "rgba(243, 243, 243, 1)",
                              color: "rgba(0, 91, 211, 1)",
                              marginTop: "5px",
                            }}
                          >
                            Profits=Sales-Cost
                          </Box>
                        </Box>
                      }
                    ></Tooltips>
                  </div>
                  <span
                    className="fulfilled_orders_content_line_num"
                    style={{
                      fontSize: "20px",
                      fontWeight: 650,
                      color:
                        orderData.orderStatics?.profits >= 0
                          ? "#303030"
                          : "#f00",
                    }}
                  >
                    $ {orderData.orderStatics?.profits.toFixed(2)}
                  </span>
                </div>
                {/* <div className="fulfilled_orders_content_line_title">
              <span
                style={{
                  fontWeight: 550,
                  marginBottom: "16px",
                  fontSize: "13px",
                  width: "max-content",
                }}
              >
                {intl.get("Unfulfilled")}
              </span>
              {props?.unfulfilledCount > 0 ? (
                <span
                  style={{ fontSize: "20px", fontWeight: 650 }}
                  className="fulfilled_orders_content_line_title_unful fulfilled_orders_content_line_title_unfuls"
                  onClick={() => {
                    utils.routeListen("/admin/order_list");
                    utils.routeJump1(router, "/admin/order_list");
                  }}
                >
                  {props?.unfulfilledCount}{" "}
                  <img src="/novice/unful_right.png" alt="dropshipman" />
                </span>
              ) : (
                <span
                  style={{ fontSize: "20px", fontWeight: 650 }}
                  className="fulfilled_orders_content_line_title_unful"
                >
                  0
                </span>
              )}
            </div> */}
              </InlineStack>
            </div>

            <div className="fulfilled_orders_content_line_arrowup">
              {getStartClo ? (
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setGetStartClo(false);
                  }}
                >
                  <Icon source={ChevronUpIcon} tone="base" />
                </Button>
              ) : (
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setGetStartClo(true);
                  }}
                >
                  <Icon source={ChevronDownIcon} tone="base" />
                </Button>
              )}
            </div>
          </div>

          <Collapsible
            open={getStartClo}
            id="basic-collapsible"
            transition={{
              duration: "500ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            {/* <div className="fulfilled_orders_content_line_export">
          <InlineStack wrap={false} direction={"row"}>
            <span
              className="fulfilled_orders_content_line_export_span"
              onClick={() => handleExport()}
            >
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.018 1.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366.706.36 1.28.933 1.64 1.639.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.057-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .852 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.76 3.76 0 0 1-1.64-1.638c-.226-.445-.32-.925-.365-1.471-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47.36-.706.933-1.28 1.639-1.64.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043"></path><path fillRule="evenodd" d="M8.25 11a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.749.749 0 1 1-1.06 1.06l-1.13-1.128a2.75 2.75 0 0 1-4.09-2.402m2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5"></path><path d="M5 5.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75"></path><path d="M5.75 8a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5z"></path></svg>
            </span>
            </InlineStack>
          </div> */}
            <div className="fulfilled_orders_content_line">
              <ReactECharts
                style={{ height: "400px" }}
                echarts={echarts}
                option={opction}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
                onChartReady={() => {}}
                onEvents={() => {}}
                opts={{ renderer: "canvas" }}
              />
              {/* <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={orderData.orderStaticsTable}
                margin={{
                  top: 5,
                  right: 30,
                  left: 0,
                  bottom: -16,
                }}
              >
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip content={CustomTooltip} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey={intl.get("Sales")}
                  stroke="rgb(75, 167, 227)"
                />
                <Line
                  type="monotone"
                  dataKey={intl.get("Costs")}
                  stroke="rgb(157, 199, 231)"
                />
              </LineChart>
            </ResponsiveContainer> */}
            </div>
          </Collapsible>
        </div>
      </Card>
    </div>
  );
}
