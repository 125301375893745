import React, { useState, useEffect } from "react";
import { Modal, Banner, Icon, Box } from "@shopify/polaris";

import { AlertCircleIcon } from "@shopify/polaris-icons";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

import intl from "../../../../../../i18n/intl";
import "./index.css";

const SyncStoreOrdersModal = (props) => {
  /**
   * open 弹窗开关
   * datePickerCallback 日期选择回调
   * closeModalCallback 关闭弹窗回调
   */
  const {
    searchStartDay,
    searchEndDay,
    open,
    loading,
    closeModalCallback,
    syncCallback,
  } = props;
  const [startDate, setStartDate] = useState(
    searchStartDay ? new Date(searchStartDay) : undefined
  ); // 支付开始时间
  const [endDate, setEndDate] = useState(
    searchEndDay ? new Date(searchEndDay) : undefined
  ); // 支付结束时间
  const [errorStatus, setErrorStatus] = useState(false); // 日期选择是否出错
  const [minDate, setMinDate] = useState(); // 最小日期值
  const [maxDate, setMaxDate] = useState(); // 最大日期值
  const [showCalendar, setShowCalendar] = useState("bottom-end"); // 日历展示方向

  useEffect(() => {
    let isMount = true;
    // 监听屏幕变化 控制日历方向
    function reSize() {
      if (window.innerWidth < 769) {
        setShowCalendar("top-end");
      } else {
        setShowCalendar("bottom-end");
      }
    }
    if (isMount && open) {
      // 每次打开清空状态
      setStartDate();
      setEndDate();
      setMaxDate(new Date());
      window.addEventListener("resize", reSize);
    }
    return () => {
      isMount = false;
      window.removeEventListener("resize", reSize);
    };
  }, [open]);

  // 关闭弹窗
  const closeModal = () => {
    closeModalCallback(!open);
    setErrorStatus(false);
    setStartDate();
    setEndDate();
    setMaxDate(new Date());
  };

  // 同步订单处理
  const handleChange = async () => {
    if (startDate && endDate) {
      syncCallback(startDate, endDate);
    } else {
      setErrorStatus(true);
    }
  };

  const changeStartDate = (date) => {
    // 设置开始时间
    setStartDate(date);
    date && setMaxDate(new Date(date.getTime() + 29 * 24 * 3600000));
    endDate && setErrorStatus(false);
  };

  const changeEndDate = (date) => {
    //设置结束时间
    setEndDate(date);
    date && setMinDate(new Date(date.getTime() - 29 * 24 * 3600000));
    startDate && setErrorStatus(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      title={intl.get("Sync Store Orders")}
      primaryAction={{
        content: intl.get("Sync Now"),
        loading: loading,
        onAction: () => handleChange(),
      }}
      secondaryActions={[
        {
          content: intl.get("Cancel"),
          onAction: () => closeModal(),
        },
      ]}
    >
      <div
        className={`${
          errorStatus
            ? `sync_store_order_modal_contain errorStatus`
            : `sync_store_order_modal_contain`
        }`}
      >
        <Modal.Section>
          <Box style={{minHeight:'350px'}}>
              <Banner tone="info">
                <p>
                  {intl.get(
                    "Only store orders that have been paid in the last 30 days are supported to be synchronized."
                  )}
                </p>
              </Banner>
              <div className="datepick_orders_search_date datepick_order_all">
                <span>{intl.get("Date Range")}</span>
                <div
                  className={`${
                    errorStatus
                      ? `datepick_orders_select_date errorBackground`
                      : `datepick_orders_select_date`
                  }`}
                >
                  <div
                    className={`${
                      errorStatus
                        ? `Polaris-TextField startDateInput errorBorder`
                        : `Polaris-TextField startDateInput`
                    }`}
                  >
                    <DatePicker
                      className={"Polaris-TextField__Input"}
                      placeholderText="Start"
                      popperPlacement={showCalendar}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      minDate={minDate}
                      maxDate={maxDate}
                      selected={startDate}
                      onChange={(date) => changeStartDate(date)}
                      onCalendarOpen={() => {
                        if (endDate) {
                          if (
                            dayjs(endDate).isBefore(
                              dayjs(
                                new Date(
                                  new Date().getTime() - 29 * 24 * 3600000
                                )
                              )
                            )
                          ) {
                            setMinDate(endDate);
                          } else {
                            setMinDate(
                              new Date(new Date().getTime() - 29 * 24 * 3600000)
                            );
                          }
                          setMaxDate(endDate);
                        } else {
                          setMinDate(
                            new Date(new Date().getTime() - 29 * 24 * 3600000)
                          );
                          setMaxDate(new Date());
                        }
                      }}
                    />
                  </div>
                  <div
                    className={`${
                      errorStatus ? `timearrow errorBorder` : `timearrow`
                    }`}
                  >
                    <img src="/timearrow.png" alt="" />
                  </div>
                  <div
                    className={`${
                      errorStatus
                        ? `Polaris-TextField endDateInput errorBorder`
                        : `Polaris-TextField endDateInput`
                    }`}
                  >
                    <DatePicker
                      className="Polaris-TextField__Input"
                      placeholderText="End"
                      popperPlacement={showCalendar}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate ? startDate : minDate}
                      maxDate={maxDate}
                      selected={endDate}
                      onChange={(date) => changeEndDate(date)}
                      onCalendarOpen={() => {
                        !startDate &&
                          setMinDate(
                            new Date(new Date().getTime() - 29 * 24 * 3600000)
                          );
                        setMaxDate(new Date());
                      }}
                    />
                    <span><img style={{verticalAlign:'bottom'}} src="/calendar.png" alt="dropshipman" /></span>
                  </div>
                  {errorStatus && (
                    <div className="tipContent">
                      <Icon source={AlertCircleIcon} color="critical" />
                      <span className="dateRequired">
                        {intl.get("Date required")}
                      </span>
                    </div>
                  )}
                
                </div>
              </div>
          </Box>
        </Modal.Section>
      </div>
    </Modal>
  );
};

export default SyncStoreOrdersModal;
