import {Button, ChoiceList, Modal, TextField, Toast} from "@shopify/polaris";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
import intl from "../../../../i18n/intl";
const UpgradePlanModal = ({upgradeOpen,setUpgradeOpen,handleMageUpgrade}) => {
    // const router  = useHistory()
    const [loading,setLoading] = useState(false)
    const [selected, setSelected] = useState(['']);
    const handleChange = useCallback((value) => setSelected(value), []);
    const [textareaValue,setTextareaValue] = useState('')
    const [textErr,setTextErr] = useState(false)
    const [textareaValueErr,setTextareaValueErr] = useState('feed_other')
    const onChangeValue = (val)=>{
      setTextareaValue(val)
      setTextErr(false)
      if (val === '') {
        setTextareaValueErr('feed_other feed_other_err')
      }else{
        setTextareaValueErr('feed_other')
      }
    }
    
    const handleSubmit = ()=>{
      let text = selected[0];
      if (text === '') {
        toggleToast('Select a reason',false)
        return
      }

      if (text === 'Other' && textareaValue === '') {
        // toggleToast('Please provide your feedback',true)
        setTextErr(true)
        setTextareaValueErr('feed_other feed_other_err')
        return
      }
      if (text === 'Other' && textareaValue !== '') {
        text = textareaValue
      }
      handleMageUpgrade(0,text)
      handleOff()
    }
    const handleOff = ()=>{
      setLoading(false)
      setTextareaValue('')
      setSelected([''])
      setUpgradeOpen()
      setTextErr(false)
      setTextareaValueErr('feed_other')
    }
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;
  return (
    <div className="upgrade_new_feature">
      <Modal
        open={upgradeOpen}
        onClose={() =>handleOff()}
      >
        <Modal.Section>
        <div className="feed_option_modal">
          <div className="feed_option_emajo">
          <img src="/sub/feed_emajo.png" alt="dropshipman"/>
          </div>
          <div className="feed_option_dsc">
         {intl.get("We are very sorry to lose your subscription. Before you officially cancel, could you please let us know the main reason for your cancellation?")}
          </div>
          <div className="feed_option_select">
            <div className="feed_option_option">
            <ChoiceList
              choices={[
                {label: intl.get('The package did not help my business.'), value: 'The package did not help my business'},
                {label: intl.get('It lacks the features I need.'), value: 'It lacks the features I need'},
                {label: intl.get('The user experience is poor.'), value: 'The user experience is poor'},
                {label: intl.get('The package price is too expensive.'), value: 'The package price is too expensive'},
                {label: intl.get('I am just trying it out.'), value: 'I am just trying it out'},
                {label: intl.get('Other'), value:'Other'},
              ]}
              selected={selected}
              onChange={handleChange}
            />
            {selected.indexOf('Other') !== -1 && <div className={textareaValueErr}>
            <TextField
            label={intl.get("Please enter your feedback here, we would greatly appreciate it.")}
            value={textareaValue}
            error={textErr && intl.get('Please provide your feedback')}
            onChange={onChangeValue}
            multiline={4}
            maxLength={300}
            autoComplete="off"
          />
              {/* <textarea name="feed" value={textareaValue} onChange={(e)=>onChangeValue(e.target.value)} cols="30" maxLength={300} rows="5" placeholder="Please enter your feedback here, we would greatly appreciate it."></textarea> */}
              <span className="feed_num">{textareaValue.length}/300</span>
            </div>}
            </div>
          </div>
          <div className="feed_option_button">
          <Button onClick={()=>handleOff()}>{intl.get("Cancel")}</Button>
          <Button loading={loading} onClick={()=>handleSubmit()}  variant="primary">{intl.get("Submit")}</Button>
          </div>
        </div>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </div>
  );
};

export default UpgradePlanModal;
