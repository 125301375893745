import {
  Box,
  Button,
  Card,
  EmptyState,
  Icon,
  InlineStack,
  Modal,
  Popover,
  RadioButton,
  Scrollable,
} from "@shopify/polaris";
import { SelectIcon } from "@shopify/polaris-icons";
import React, { useEffect, useRef, useState } from "react";
import CountrySelectAllList from "../../ProductSelection/component/CountrySelectAllList";
import { reqGetProductManagementProductsFreight } from "../../../../api";
const Index = ({
  shippingFeeData,
  setShippingFeeData,
  handleSelectionChange,
  detailData,
  setDetailData,
  setIsInput,
  shippingMethodData,
  setShippingMethodData,
  toastFun,
}) => {
  const containerRef = useRef(null); // 获取整个组件的ref
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("US");
  const [showId, setShowId] = useState(""); // 是否展示国家列表
  const handleClose = () => {
    setLoading(false);
    setShippingFeeData((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const [shippingList, setShippingList] = useState([]); // 当前默认数据
  const handleChange = (recordKey) => {
    // 创建新的数组来更新状态
    const updatedList = shippingList.map((item, index) => ({
      ...item, // 保持原有属性
      selected: index === recordKey ? 1 : 0, // 选中当前项，取消其他项的选中状态
    }));

    setShippingList(updatedList); // 更新状态
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount && shippingFeeData.open) {
      setShippingList(shippingMethodData);
      setCountry(detailData.shipping.shipping_to_country);
    }
    return () => {
      isMount = true;
    };
  }, [detailData,shippingMethodData, shippingFeeData]);

  const [countryLoading, setCountryLoading] = useState(false);
  // 物流方式选择
  const handleCountrySelect = async (value) => {
    if (detailData?.product?.is_publish) {
      return;
    }
    if (countryLoading) {
      return;
    }
    setCountryLoading(true);
    setShowId(false);
    try {
      let params = {
        // receiving_country: value,
        record_id: detailData.record_id,
        ship_to_country: value,
        send_to_country: detailData.shipping.send_goods_country,
      };
      let res = await reqGetProductManagementProductsFreight(params);
      if (res.code === "200") {
        setCountry(value);
        setShippingList(res.data);
      }

      setCountryLoading(false);
    } catch (error) {
      setCountryLoading(false);
    }
  };
  const handleShippingSave = () => {
    setLoading(true);

    try {
      setTimeout(() => {
        setLoading(false);
        setIsInput(true);
        if (detailData || setDetailData) {
          let ship_list =
            detailData?.store?.currencyCode === "USD"
              ? shippingList.filter((item) => item.selected)[0]
                  ?.BeforeConvertCost
              : shippingList.filter((item) => item.selected)[0]?.Cost;
          let ship_service = shippingList.filter((item) => item.selected)[0]
            ?.service_name;
          setDetailData((prevValue) => {
            let list = prevValue.variants.map((item, ind) => {
              return {
                ...item,
                shipping_fee: ship_list,
              };
            });
            return {
              ...prevValue,
              variants: list,
              shipping: {
                ...prevValue.shipping,
                service_name: ship_service,
                shipping_to_country:country,
                shipping_fee:ship_list
              },
            };
          });
        }
        setShippingMethodData(shippingList);
        handleSelectionChange("page", false, 0);
        handleClose();
        toastFun("Saved Successfully");
      }, 3000);

    } catch (error) {}
  };
  const shippingSelect = shippingList.filter((item) => item.selected);
  let currentShipfee =
    detailData?.store?.currencyCode === "USD"
      ? shippingSelect[0]?.BeforeConvertCost
      : shippingSelect[0]?.Cost;

  return (
    <div>
      <Modal
        open={shippingFeeData.open}
        onClose={handleClose}
        title={"Change Shipping Methods"}
        secondaryActions={{
          onAction: () => {
            handleClose();
          },
          content: "Cancel",
        }}
        primaryAction={{
          onAction: () => {
            handleShippingSave();
          },
          loading: loading,
          content: "confirm",
        }}
      >
        <Modal.Section>
          <Box className='detail_shipping_methods'>
          <Card>
          <Box className="shipping_info_content" style={{marginBottom:'16px'}}>
            <InlineStack gap={200}>
              {/* <Box><span>Size:</span>13x18cm</Box>
              <Box><span>Weight:</span>700g-768g</Box> */}
              <Box>
                <span>Estimated delivery: </span>
                {shippingSelect[0]?.DeliveryTime
                  ? shippingSelect[0]?.DeliveryTime
                  : "-"}
              </Box>|
              <Box>
                <span>Ship from: </span>China
              </Box>|
              <Box>
                <span>Shipping fee: </span>
                {currentShipfee
                  ? detailData?.store?.symbol + currentShipfee
                  : "-"}
              </Box>
            </InlineStack>
          </Box>
          <Box>
            <InlineStack blockAlign="center" direction={"row"} gap={200}>
              <Box className="btn_currency">
                <Popover
                  active={showId}
                  activator={
                    <Button
                      loading={countryLoading}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showId) {
                          setShowId(false);
                        } else {
                          setShowId(true);
                        }
                      }}
                    >
                      <InlineStack align="space-between">
                        <p
                          style={{
                            marginRight: "10px",
                            color: "rgba(48, 48, 48, 1)",
                            fontSize: "13px",
                            fontWeight: "550",
                          }}
                        >
                          <img
                            src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`}
                            alt="..."
                          />
                          <span style={{ marginLeft: "5px" }}>Ship to</span>
                          <span style={{ marginLeft: "5px" }}>{country}</span>
                        </p>
                        <span>
                          <Icon source={SelectIcon}></Icon>
                        </span>
                      </InlineStack>
                    </Button>
                  }
                  autofocusTarget="first-node"
                  onClose={() => {
                    setShowId(false);
                  }}
                >
                  <CountrySelectAllList
                    containerRef={containerRef}
                    setShowId={() => {}}
                    handleCountrySelect={handleCountrySelect}
                    setCountry={() => {}}
                    country={country}
                  />
                </Popover>
              </Box>
              {shippingSelect.length > 0 && (
                <InlineStack align="space-between">
                  {shippingSelect[0].ShippingMethod}
                </InlineStack>
              )}
            </InlineStack>
          </Box>
          <Box style={{marginTop:'16px'}}>
          <Card padding={0}>
          <Scrollable style={{height: '300px'}}>
              <div
                className="Prodcut_Shipping_Table Prodcut_Shipping_Table_detail"
                style={{ position: "relative" }}
              >
                <table style={{ width: "100%" }}>
                  <thead >
                    <tr>
                      <th style={{fontWeight:'450'}}>{"Shipping Method"}</th>
                      <th style={{fontWeight:'450'}}>{"Estimated Delivery Time"}</th>
                      <th style={{fontWeight:'450'}}>{"Shipping Cost"}</th>
                    </tr>
                  </thead>

                 {shippingList.length > 0 ? <tbody>
                    {shippingList.map((item, ind) => (
                      <tr key={ind}>
                        <td>
                          <RadioButton
                            label={item.ShippingMethod}
                            checked={item.selected}
                            name="shipping_method"
                            onChange={() => {
                              handleChange(ind);
                            }}
                          />
                        </td>
                        <td>{item.DeliveryTime}</td>
                        <td>
                          {detailData?.store?.symbol} {detailData?.store?.currencyCode === "USD" ? item.BeforeConvertCost : item.Cost} {detailData?.store?.currencyCode}
                        </td>
                      </tr>
                    ))}
                  </tbody>: ''}
                </table>
                {shippingList.length === 0 ? <Box style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <EmptyState
                    heading="No Shipping method"
                    image='/product_management/detail_variant_not.png'
                  >
                  </EmptyState>
                    </Box>:''}
              </div>
              </Scrollable>
            </Card>
            </Box>
          </Card>
          </Box>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
