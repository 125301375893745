import React, { Component } from 'react';

import {AppProvider,  Layout , Page, Button } from '@shopify/polaris';

// import OnPageSEOTabs from '../Tabs';
import errorImg from './images/500-internal-server-error.png';
import './index.css';
import translations from '@shopify/polaris/locales/en.json';
import intl from '../../../i18n/intl'

class Error extends Component {
  componentDidMount() {
    if (window?.hideLoadingState) {
      window.hideLoadingState()
    }
  }
  render() {
    return (
      <div style={{background:'#fff'}}>
      <AppProvider i18n={translations}>
          <Page>
            <Layout>
              <Layout.Section variant='oneThird'>
                <div className="not-img">
                  <img src={errorImg} alt="page not found" className="error-img"></img>
                </div>
              </Layout.Section>

              <Layout.Section variant='oneThird'>
                <div className="page-error">
                  <div className="not-found-title">
                      {intl.get("Sorry, Internal Server Error")}
                  </div>
                  <div className="not-found-msg">
                      {intl.get("Please try navigating to the page from Drop Ship Home and try again.")}
                  </div>
                  <Button variant='primary' size="large" url='/admin/dashboard'>{intl.get("Go to Drop Ship Home")}</Button>
                </div>
              </Layout.Section>
            </Layout>
          </Page>
      </AppProvider>
      </div>
    );
  }
}

export default Error;
