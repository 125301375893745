import { Box,Link,Modal } from '@shopify/polaris';
import React from 'react';
// import {
//     PlayCircleIcon
//   } from '@shopify/polaris-icons';
const Index = ({open,setOpen})=> {
    return (
        <Box>
            <Modal open={open} title="Import products from Temu" 
            onClose={()=>setOpen(false)}
            primaryAction={{content: 'Install Google Extension to Import Product', onAction: ()=>{
                window.open("https://chromewebstore.google.com/detail/dropshipman-aliexpress-pr/bobhjedlciclpchpelkgiojkmmfgnihg", "_blank");
            }}}
            // secondaryActions={[{
            //     content: "Watch video tutorial",
            //     onAction: ()=>{},
            //     icon: PlayCircleIcon
            // }]}
            >
                <Modal.Section>
                    <div className="import_temu_content_modal">
                    <div>
                {"Importing TEMU products requires the installation of the Dropshipman Google Extension. Please follow these steps:"}
                    <p> {"1. Download and install the"} <Link url="https://chromewebstore.google.com/detail/dropshipman-aliexpress-pr/bobhjedlciclpchpelkgiojkmmfgnihg" external> {"Dropshipman Google Extension."} </Link> </p>
                    <p> {"2. Log in to the Dropshipman Google Extension using your Dropshipman account."}</p>
                    <p> {"3. Visit the TEMU product details page, and click the \"Add to my product list\" button to collect the product."} <Link url="https://www.temu.com/" external>https://www.temu.com/</Link></p>
                    <p style={{textAlign:'center',marginTop:'10px'}}>
                    <img style={{width:'100%'}} src='/temu/image_banner.png' alt='dsmshipman' />
                    </p>
                    </div>
                </div>
                </Modal.Section>
            </Modal>
        </Box>
    );
}

export default Index;
