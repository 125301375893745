import {Modal} from "@shopify/polaris";
import React, { useState } from "react";

const Index = ({redeemData,setRedeemData,resetCallback}) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const handleSave = async () => {
        setSaveLoading(true);
        resetCallback();
        setTimeout(() => {
          setSaveLoading(false);
        }, 2000);
      };
  return (
    <div className="upgrade_new_feature">
      <Modal
        open={redeemData.open}
        onClose={() => 
          {
            setRedeemData((prevValue)=>({...prevValue,open:false}))
            setSaveLoading(false)
          }}
        title={'Redeem Coupon'}
        primaryAction={{
          content: "Done",
          loading:saveLoading,
          onAction: () => {
            handleSave()
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setRedeemData((prevValue)=>({...prevValue,open:false}))
              setSaveLoading(false)
            }
          },
        ]}
      >
        <Modal.Section>
        <p style={{fontSize:'14px'}}>{`Are you sure you want to redeem ${redeemData.value} $${redeemData.coupon} discount coupons? This will cost ${redeemData.need * redeemData.value} points.`}</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
