import {Modal} from "@shopify/polaris";
import React from "react";

const UpgradePlanModal = ({upgradeOpen,setUpgradeOpen}) => {
  return (
    <div className="novice_video_modal">
      <Modal
      size="large"
        open={upgradeOpen}
        onClose={() => setUpgradeOpen()}
      >
       <div className="plan_grade_content">
       <div className="novice_video_title">How to fulfill orders? </div>
       <div className="novice_video_modal_content">
       <iframe style={{borderRadius:'20px'}} title='dropshipman' src={'https://www.youtube.com/embed/1OiyRfrMF74?rel=0&autoplay=1&loop=1'} width="880" height="500" frameBorder="0" allowFullScreen="allowfullscreen" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
       </div>
       </div>
      </Modal>
    </div>
  );
};

export default UpgradePlanModal;
