import React, { Component} from "react";
import { withRouter } from 'react-router-dom';
import {
  Layout, Page, Card, SkeletonBodyText, Toast, EmptyState, SkeletonPage, BlockStack
} from "@shopify/polaris";
import utils from '../../../../utils/utils'
import {
  reqGetPartnerBannersList,
} from '../../../../api';

import SlideShow from "../../../../components/SlideShow";
import AppPartners from "../../../../components/AppPartners";

import "../index.css";
import intl from '../../../../i18n/intl';
import AppBridge from "../../../../utils/appBridge";

class PartnersClass extends Component {
  state = {
    //弹窗
    showToast: false,  //弹窗显示
    showToastText:'',  //弹窗文字
    showToastIserror:false, //弹窗是否错误

    loadingBanner: true,
    bannerData: [],  // 轮播图

    loadingAppList: true,
    emptyOrErrorOnAppList: false,  // App推荐列表：没有数据或者请求报错
  };

  //进入页面调用接口
  componentDidMount () {
    this.getBannerList();
    // if(AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar('Partners','/admin/partnerShip')
    // }
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 获取数据
  getBannerList = async () => {
    const params = {};
    const response = await reqGetPartnerBannersList(params)

    if (response.code === '200') {
      this.setState({
        loadingBanner: false,
        bannerData: response.data
      });
      
    } else {
      this.setState({
        loadingBanner: false,
      });
    }
  }

  // App列表请求回调
  AppListCallBack = (data) => {
    this.setState({
      loadingAppList: false,
      emptyOrErrorOnAppList: data.result
    });
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const {
      loadingBanner, bannerData, emptyOrErrorOnAppList
    } = this.state;

    // banner初始化页面
    const initBanenrSkeletonPage = (
      <SkeletonPage>
      <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
    </SkeletonPage>
    )

    // 页面空数据显示
    const EmptyContent = (!loadingBanner && emptyOrErrorOnAppList && bannerData.length === 0) && (
      <Card sectioned>
        <EmptyState
          heading="Best is being prepared..."
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <p>{intl.get("The good partners are coming soon.")}</p>
        </EmptyState>
      </Card>
    )

    /* 轮播图 */
    const bannerList = <SlideShow images={bannerData} />

    /* App推荐列表 */

    return loadingBanner ? initBanenrSkeletonPage : (
      <div>
       <Page
          title={AppBridge.exist() ? "" : <div style={{fontSize:'20px'}} >{intl.get('Partners')}</div>}
          backAction={AppBridge.exist() ? undefined : {content: 'Partners', onAction: () =>{
              utils.routeListen('/admin/partnerShip')
              utils.routeJump(this,'/admin/partnerShip')
            }}}
          // subtitle={intl.get("Let the top partners' apps help your business grow")}
          fullWidth={false}
        >
          <Layout>
            <Layout.Section>
              {/* 轮播图 */}
              <div className="partner_silde">
              {bannerList}
              </div>
              {/* App推荐列表 */}
              {/* {loadingAppPartners} */}
              <AppPartners show={true} callBackFun={this.AppListCallBack} />

              {/* 页面数据空时默认显示 */}
              {EmptyContent}
            </Layout.Section>
          </Layout>
        </Page>

        {toastMarkup}
      </div>
    );
  }
}

export default withRouter(PartnersClass);