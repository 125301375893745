import React, { Component } from "react";

import intl from '../../i18n/intl'

import {
  FormLayout,
  TextField,
  Scrollable,
  Spinner,
  Toast,
  Modal,
} from "@shopify/polaris";

import { reqGetThisUserEmail, reqInsertContactUs, reqPostBaseAction } from "../../api";

import utils from "../../utils/utils";

import "./index.css";

class GetGoodReviews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sheetActive: false,
      name: "",
      email: "",
      defaultEmail: "",
      title: "",
      description: "",

      //弹窗
      showToast: false, //弹窗显示
      showToastText: "", //弹窗文字
      showToastIserror: false, //弹窗是否错误

      //页面动作
      loading: false,
      loadingPage: true,
      Buttonloading: false,

      //保存星级
      StarRating:0,
      clickStar: 0,
      StarDesc: '',

      //弹出框
      modalStatus: false,

      // 显示引导评价Modal
      showModal: false,
    };
  }

  componentDidMount () {
    if (this.props.show && this.props.checkTime) {  // 激活弹窗触发
      let isAllowToShow = true;
      // 弹窗1天内只弹一次
      let lastTime = this.props.showTime;
      if (lastTime) {
        let nowTime = new Date().getTime();
        lastTime = Number(lastTime) ? Number(lastTime) + 86400*1000 : 0;
        if (nowTime < lastTime) {  // 时间未过期
          isAllowToShow = false;
          // 调用父级函数，设置激活评价邮件变量为false
          this.props.callBack();
        }
      }
      this.setState({showModal: isAllowToShow});
    }

    let userEmail = window.S_EMAIL;
    if(!userEmail){
      this.getEamilList();
    } else {
      let shopOwner = utils.getStore("S_SHOP_OWNER") || window.S_SHOP_OWNER;
      this.setState({
        email: userEmail,
        name: shopOwner ? shopOwner : ''
      });
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let setStateParams = {};

    let shopOwner = utils.getStore("S_SHOP_OWNER") || window.S_SHOP_OWNER;

    setStateParams.showModal = nextProps.show;
    setStateParams.email     = window.S_EMAIL ? window.S_EMAIL : '';
    setStateParams.name      = shopOwner ? shopOwner : '';
    setStateParams.StarRating = 0;

    if (nextProps.show && nextProps.checkTime) {  // 激活弹窗触发
      // 弹窗1天内只弹一次
      let lastTime = nextProps.showTime;
      if (lastTime) {
        let nowTime = new Date().getTime();
        lastTime = Number(lastTime) ? Number(lastTime) + 86400*1000 : 0;
        if (nowTime < lastTime) {  // 时间未过期
          setStateParams.showModal = false;
          // 调用父级函数，设置激活评价弹窗变量为false
          this.props.callBack();
        }
      }
    }

    // Dashboard 已升级套餐的用户（未在shopify应用市场评价） 评论弹窗控制（弹窗过的记录已存表记录，不会继续触发）

    this.setState(setStateParams);
  }

  //获取邮箱
  getEamilList = async () => {
    const params = {};
    const responseData = await reqGetThisUserEmail(params);
    if (responseData.code === "200") {
      window.S_EMAIL = responseData.data.email;

      this.setState({
        email: responseData.data.email,
        name: utils.getStore("S_SHOP_OWNER") || window.S_SHOP_OWNER,
        defaultEmail: responseData.data.email,
        loadingPage: true,
        loading: false
      });
    }
  }

  handleOpenSheet = () => {
    this.setState({ sheetActive: true });
  }

  handleCloseSheet = () => {
    this.setState({ sheetActive: false });
  };

  //点击保存
  handleCloseSheetInsert = () => {
    if (this.state.name === "") {
      this.toggleToast("Name not empty");
      return "";
    } else if (this.state.description === "") {
      this.toggleToast("Description not empty");
      return "";
    }
    //调用修改数据
    this.updateEamilList();
  };

  //修改邮箱按钮
  updateEamilList = async () => {
    this.setState({
      Buttonloading: true
    });
    const params = {
      name: this.state.name,
      email: this.state.email ? this.state.email : this.state.defaultEmail,
      title: "评价:" + this.state.clickStar + "星",
      description: this.state.description
    };
    const responseData = await reqInsertContactUs(params);
    if (responseData.code === "200") {
      this.toggleToast(responseData.msg);
    } else {
      this.toggleToast(responseData.msg);
    }
    this.setState({
      Buttonloading: false,
      sheetActive: false
    });
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  }

  handleToggleSheet = () => {
    const {
      state: { sheetActive },
      handleCloseSheet,
      handleOpenSheet
    } = this;

    sheetActive ? handleCloseSheet() : handleOpenSheet();
  };

  handleChange = field => {
    return value => this.setState({ [field]: value });
  };

  // 星星点击事件
  handleStarClick = (star) => {
    if (star < 5) {
      this.setState({ clickStar: star });
      this.handleShowModal();
      this.handleOpenSheet();

    } else {
      try {
        const w=window.open('about:blank');
        w.location.href = 'https://apps.shopify.com/aliexpress-dropshipping-master?#modal-show=ReviewListingModal';
      } catch (error) {
        // 异常关闭弹窗
        this.handleShowModal();
      }
    }
  }

  // 鼠标经过星星
  mouseOverChange = (star) => {
    let starDescArray = [
      'Very bad',
      'Not so good',
      'General',
      'Good',
      'Very nice'
    ];

    this.setState({
      StarRating: star,
      StarDesc: starDescArray[star-1]
    })
  }

  // 鼠标离开星星
  mouseOutChange = () => {
    this.setState({
      StarRating: 0,
      StarDesc: ''
    })
  }

  handleShowModal = () => {
    // 控制弹窗弹出时间，1天内弹一次
    if (this.props.checkTime && this.state.showModal) {
      // 记录弹窗时间到数据库
      this.handleBaseAction('review');
    }

    // Dashboard 已升级套餐的用户，评论弹窗控制，状态已存表记录

    // 控制弹窗显示/关闭
    this.setState(({ showModal }) => ({ showModal: !showModal }));

    // 调用父级函数，设置激活评价邮件变量为false
    this.props.callBack();
  }

  // 记录日常操作数据
  handleBaseAction = async (type = '') => {
    const params = {
      type: type
    }
    await reqPostBaseAction(params);
  }
  
  render() {
    const {
      state: { sheetActive, name, description,email, StarRating, StarDesc, clickStar, showModal, Buttonloading},
      handleCloseSheet,
      handleChange,
      handleCloseSheetInsert
    } = this;

    const lightStar = 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUgMTJMMS4zNzYgOWMtLjY4NC0uNTQ3LS4zODQtMS42NDcuNDg0LTEuNzcxTDcgN2wyLjE5NS01LjUwM2EuOS45IDAgMDExLjYxIDBMMTMgN2w1LjE0LjIyOWMuODY4LjEyNCAxLjE2OCAxLjIyNC40ODQgMS43NzFMMTUgMTJsLjk4OSA1Ljg1N2ExIDEgMCAwMS0xLjQzNyAxLjAzNkwxMCAxNmwtNC41NTIgMi44OTJhMSAxIDAgMDEtMS40MzctMS4wMzZMNSAxMnoiIGZpbGw9IiNmZTlhMDIiLz48L3N2Zz4=';
    const darkStar  = 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUgMTJMMS4zNzYgOWMtLjY4NC0uNTQ3LS4zODQtMS42NDcuNDg0LTEuNzcxTDcgN2wyLjE5NS01LjUwM2EuOS45IDAgMDExLjYxIDBMMTMgN2w1LjE0LjIyOWMuODY4LjEyNCAxLjE2OCAxLjIyNC40ODQgMS43NzFMMTUgMTJsLjk4OSA1Ljg1N2ExIDEgMCAwMS0xLjQzNyAxLjAzNkwxMCAxNmwtNC41NTIgMi44OTJhMSAxIDAgMDEtMS40MzctMS4wMzZMNSAxMnoiIGZpbGw9IiM1QzVGNjIiLz48L3N2Zz4=';

    const loadingMarkup = this.state.loading ? (
      <div className="loadingCenter">
        <Spinner size="large" color="teal" />
      </div>
    ) : (
      ""
    );

    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={()=>this.toggleToast('')}
        error={this.state.showToastIserror}
        duration={3000}
      />
    ) : null;

    
    //输出星级
    var StarRatingArray = [];
    for(var i=0;i < clickStar; i ++){
      StarRatingArray.push(<span key={i} className="StarRating" data-index="0" data-forhalf="★">★</span>)
    }
    var StarRatingSpan = StarRatingArray.map(function(item){
        return item
    })
    const pageData = <div>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label className="Polaris-Label__Text">{intl.get("Star rating：")}  {StarRatingSpan}</label>
          </div>
        </div>
        <div style={{padding:'.5rem 0'}}></div>
        <TextField
          label={intl.get("Name")}
          onChange={handleChange("name")}
          value={name}
        />
        <div style={{padding:'.5rem 0'}}></div>
        <TextField
            label={intl.get("Email")}
            onChange={handleChange('email')}
            value={email}
        />  
        <div style={{padding:'.5rem 0'}}></div>
        <TextField
          label={intl.get("Message")}
          onChange={handleChange("description")}
          value={description}
          multiline
        />
    </div>

    // 留言弹窗
    const messageModalContent = this.state.loadingPage && (
      <Modal
        open={sheetActive}
        onClose={handleCloseSheet}
        title={intl.get("Your feedback is important to us")}
        primaryAction={{
          content: intl.get("Submit"),
          onAction: handleCloseSheetInsert,
          loading: Buttonloading
        }}
      >
        <Modal.Section>
           
            <Scrollable style={{ padding: "1.6rem", height: "100%" }}>
              <FormLayout>
                {loadingMarkup}
                {pageData}
              </FormLayout>
            </Scrollable>
           
        </Modal.Section>
      </Modal>
    );

    // 引导评论弹窗
    const pageContent = (
      <section>
        {showModal && (
          <>
            <div className="Good-Reviews__Container">
              <div className="Good-Reviews-Modal-Dialog">
                <div className="Good-Reviews-Modal-Dialog__Modal Good-Reviews-Modal-Dialog--sizeLarge">
                  <div className="Good-Reviews-Modal-Dialog-Header">
                    <div className="Good-Reviews-Modal-CloseButton" onClick={this.handleShowModal}>
                      <span className="Good-Reviews-Icon">
                        <svg viewBox="0 0 20 20" className="Good-Reviews-Icon__Svg" focusable="false" aria-hidden="true"><path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"></path></svg>
                      </span>
                    </div>
                  </div>

                  <div className="Good-Reviews-Modal-Header__Title">
                    <div className="Good-Reviews-Logo">
                      <img src="/dropshipman-logo.png" style={{width: '60px',height: '60px'}} alt="dropshipman" />
                      {this.props.heading_title ?? 'Plan Upgrade Completed!'}
                    </div>
                  </div>

                  <div className="Good-Reviews-Modal-Subtitle">
                    {this.props.subtitle ?? 'Would you mind letting us know what you think about this app?'}
                    <br />
                    {this.props.prompt_title ?? 'Your valuable review will motivate us to improve.'}
                  </div>

                  <div className="Good-Reviews-Star__Content">
                    <div className="Good-Reviews-Star-Box">
                      <div className="Good-Reviews-Star-Item" onClick={ ()=>this.handleStarClick(1) } onMouseOver={ () => this.mouseOverChange(1) } onMouseOut={ this.mouseOutChange }>
                        <img src={(StarRating >= 1 || StarRating ===0 ) ? lightStar : darkStar} className="Good-Reviews-Star" alt='star' />
                      </div>
                      <div className="Good-Reviews-Star-Item" onClick={ ()=>this.handleStarClick(2) }  onMouseOver={ () => this.mouseOverChange(2) } onMouseOut={ this.mouseOutChange }>
                        <img src={(StarRating >= 2 || StarRating ===0 ) ? lightStar : darkStar} className="Good-Reviews-Star" alt='star' />
                      </div>
                      <div className="Good-Reviews-Star-Item" onClick={ ()=>this.handleStarClick(3) }  onMouseOver={ () => this.mouseOverChange(3) } onMouseOut={ this.mouseOutChange }>
                        <img src={(StarRating >= 3 || StarRating ===0 ) ? lightStar : darkStar} className="Good-Reviews-Star" alt='star' />
                      </div>
                      <div className="Good-Reviews-Star-Item" onClick={ ()=>this.handleStarClick(4) }  onMouseOver={ () => this.mouseOverChange(4) } onMouseOut={ this.mouseOutChange }>
                        <img src={(StarRating >= 4 || StarRating ===0 ) ? lightStar : darkStar} className="Good-Reviews-Star" alt='star' />
                      </div>
                      <div className="Good-Reviews-Star-Item" onClick={ ()=>this.handleStarClick(5) }  onMouseOver={ () => this.mouseOverChange(5) } onMouseOut={ this.mouseOutChange }>
                        <img src={(StarRating >= 5 || StarRating ===0 ) ? lightStar : darkStar} className="Good-Reviews-Star" alt='star' />
                      </div>
                    </div>
                    
                    {StarDesc && <div className="Good-Reviews-Star-Description">
                      {StarDesc}
                    </div>}

                  </div>
                  <div className="Good-Reviews-ClickText">
                    Click the stars to drop a quick rating
                  </div>

                </div>
                
              </div>
            </div>
            <div className="Good-Reviews-Backdrop"></div>
          </>
        )}
      </section>
    )
    
    return (
      <div className="footerRating">
        <div className="footerRating_child">
          
          {pageContent}

          {/* 评论留言弹窗 */}
          {messageModalContent}

          {toastMarkup}
        </div>
      </div>
    );
  }
}

export default GetGoodReviews;