import { Box, Button, Modal } from "@shopify/polaris";
import React from "react";
const Index = ({ 
  isNewrStore,
    setIsNewrStore
}) => {
  const onClose = ()=>{
    setIsNewrStore(false)
  }
  return (
    <div>
      <Modal
        open={isNewrStore}
        onClose={onClose}
      >
        <Box>
          <img width="100%" src="/product_management/product_newer.png" alt="logo" />
        </Box>
        <Box style={{padding:'16px'}}>
          <Box style={{fontSize:'20px',fontWeight:650,lineHeight:'24px',marginBottom:'16px'}}>Switch your store's fulfillment to Dropshipman: Expand delivery channels and reduce operational costs</Box>
        <Box className='ai-points-less-modal' >
          <Box style={{fontSize:'13px',fontWeight:650}}>Dropshipman Sourcing & Fulfillment Benefits:</Box>
        <li>Custom product sourcing at competitive prices</li>
        <li>Efficient and reliable fulfillment process with guaranteed order completion</li>
        </Box>
        <Box style={{textAlign:"right"}}>
          <Button variant="primary" onClick={onClose}>Got it</Button>
          </Box>
          </Box>
      </Modal>
    </div>
  );
};

export default Index;
