import {
  Filters,
  Button,
  Box,
  ChoiceList,
  Popover,
  ButtonGroup,
  OptionList,
  InlineStack,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@shopify/polaris-icons";
import intl from "../../../../../i18n/intl";
const Index = ({selectedType, selected, requestParams, setRequestParams, getListData }) => {
  const emptyFilterState = {
    query: {
      label: "Search",
      value: "",
    },
    filterStatus: {
      label: "Sourcing status",
      value: [],
    },
  };

  const handleFilterChange = (key) => (value) => {
    if (key === "filterStatus") {
      getListData({page: 1,
        size: 20,sourcing_status: value });
    }
  };

  const handleFilterRemove = (key) => {
    if (key === "filterStatus") {
      getListData({page: 1,
        size: 20, sourcing_status: [] });
    }
  };

  const handleFiltersQueryChange = (value) => {
    setRequestParams((prevValue) => ({
      ...prevValue,
      q: value,
    }));
  };

  const handleQueryValueRemove = () => {
    setRequestParams((prevValue) => ({
      ...prevValue,
      q: "",
    }));
    getListData({page: 1,
      size: 20, q: "" });
  };

  const handleFiltersClearAll = () => {
    Object.entries(emptyFilterState).forEach(([key]) =>
      handleFilterRemove(key)
    );
  };

  const filters = [
    {
      key: "filterStatus",
      label: "Sourcing status",
      filter: (
        <ChoiceList
          title="Sourcing status"
          titleHidden
          choices={[
            { label: intl.get("Pending"), value: "Pending" },
            { label: intl.get("Success"), value: "Success" },
            { label: intl.get("Failed"), value: "Failed" },
          ]}
          selected={requestParams.sourcing_status || []}
          onChange={handleFilterChange("filterStatus")}
        />
      ),
    },
  ];

  const [searchLoading, setSearchLoading] = useState(false);
  const appliedFilters = [];
  if (!isEmpty(requestParams.sourcing_status)) {
    const key = "filterStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, requestParams.sourcing_status),
      onRemove: handleFilterRemove,
    });
  }

  const handleSaveFilters = () => {
    setSearchLoading(true);
    getListData({page: 1,
      size: 20, q: requestParams?.q.trim() });
    setTimeout(() => {
      setSearchLoading(false);
    }, 1500);
  };

  const [active, setActive] = useState(null);
  const toggleActive = (id) => () => {
    setActive((activeId) => (activeId !== id ? id : null));
  };

  const handleChange =(value) => {
    setRequestParams((prevValue) => ({
      ...prevValue,
      search_from: value[0],
    }))
    if (requestParams.q !== '') {
      getListData({page: 1,
        size: 20, search_from: value[0] });
    }

  }
  const searchType1 = [
    {value: 1, label: 'Product Title'},
    {value: 2, label: 'Sourcing ID'},
  ] 
  const searchType2 = [
    {value: 1, label: 'Product Title'},
    {value: 2, label: 'Sourcing ID'},
    {value: 3, label: 'Order Number'},
  ] 

   const handleKeyDown = (event) => {
      if (event.key === "Enter") {
       if (requestParams.q !== "" && !searchLoading) {
        setSearchLoading(true)
        getListData({page:1,size:20,q: requestParams?.q.trim()})
        setTimeout(() => {
          setSearchLoading(false);
        }, 1500);
       }
     }
     
    };
  
    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown);
  
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestParams]);
  return (
    <Box>
      <Filters
        queryValue={requestParams.q}
        queryPlaceholder="Filter Sourcing"
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFiltersClearAll}
      >
        <Box paddingInlineStart="200">
          <InlineStack gap={200}>
          {/* <Button onClick={()=>{getListData({q:'',search_from:[]})}}>Clear</Button> */}
          <ButtonGroup variant="segmented">
            <Button
              // disabled={requestParams.q === ""}
              onClick={handleSaveFilters}
              loading={searchLoading}
            >
              Search
            </Button>
            <Popover
              active={active === "popover2"}
              preferredAlignment="right"
              activator={
                <Button
                  onClick={toggleActive("popover2")}
                  icon={ChevronDownIcon}
                  accessibilityLabel="Other save actions"
                />
              }
              autofocusTarget="first-node"
              onClose={toggleActive("popover2")}
            >
             <OptionList
                onChange={handleChange}
                options={selectedType[0] === "My quote" ? searchType1 : searchType2}
                selected={[requestParams.search_from]}
              />
            </Popover>
          </ButtonGroup>
            </InlineStack>
          
        </Box>
      </Filters>
    </Box>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }
  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0 || value[1] === 0;
    } else {
      return value === "" || value === 0 || value == null;
    }
  }
};
export default Index;
