import {
  BlockStack,
  Box,
  Card,
  InlineStack,
  ProgressBar,
} from "@shopify/polaris";
import React from "react";
import CommonFilterSkeletonList from "./CommonFilterSkeletonList";
const CommonFilterSkeleton = ({type}) => {
  return (
    <div>
      <Card padding={0}>
        <BlockStack gap={200}>
          <Box style={{ padding: "10px"}}>
           {type === "winning" ?<InlineStack gap={200} blockAlign="center">
              <Box style={{width:"15%"}}>
                <ProgressBar size="large" />
              </Box>
              <Box style={{flex:1}}>
              <BlockStack gap={200}>
              <InlineStack gap={500} align="space-between">
                <Box style={{ flex: 1 }}>
                  <ProgressBar size="large" />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar size="large" />
                </Box>
              </InlineStack>
              <InlineStack gap={200}>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
              </InlineStack>
            </BlockStack>
              </Box>
            </InlineStack>: <BlockStack gap={200}>
              <InlineStack gap={500} align="space-between">
                <Box style={{ flex: 1 }}>
                  <ProgressBar size="large" />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar size="large" />
                </Box>
              </InlineStack>
              <InlineStack gap={200}>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
                <Box style={{ width: "85px" }}>
                  <ProgressBar />
                </Box>
              </InlineStack>
            </BlockStack> }
           
          </Box>
         <CommonFilterSkeletonList />
        </BlockStack>
      </Card>
    </div>
  );
};


export default CommonFilterSkeleton;
