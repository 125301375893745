import React, { useEffect, useState } from 'react'
import Zmage from 'react-zmage'
import 'react-zmage/lib/zmage.css'

/**
 * 图片预览组件
 * @param {Boolean} isShow 弹窗是否显示
 * @param {Number} index 需要显示的图片索引
 * @param {Array} imageList 图片列表
 * @param {Function} onClose 关闭回调
 */
const PreviewImage = ({ isShow, index, imageList, onClose }) => {
	const [active, setActive] = useState(false)

	useEffect(() => {
		setActive(isShow)
	}, [isShow])

	const pageContent = active && (
		<Zmage
			id="show_image_list"
			alt="Preview"
			set={imageList}
			defaultPage={index}
			browsing={active}
			onBrowsing={onClose}
		/>
	)

	return pageContent
}

export default PreviewImage
