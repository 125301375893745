import {
  Autocomplete,
  Box,
  Card,
  Icon,
  InlineStack,
  Scrollable,
  TextField,
} from "@shopify/polaris";
import React, { useCallback, useMemo, useState } from "react";
import { CheckIcon, SearchIcon } from "@shopify/polaris-icons";
const CountrySelectList = ({ handleCountrySelect,country ,setShowId,containerRef}) => {
  const deselectedOptions = useMemo(
    () => [
      { value: "US", label: "United States" },
      {
        value: "GB",
        label: "United Kingdom",
      },
      { value: "CA", label: "Canada" },
      {
        value: "FR",
        label: "France",
      },
      { value: "AU", label: "Australia" },
      { value: "PK", label: "Pakistan" },
      { value: "BR", label: "Brazil" },
      { value: "DE", label: "Germany" },
      { value: "BD", label: "Bangladesh" },
    ],
    []
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(deselectedOptions);

  const updateText = useCallback(
    (value) => {
      if (value !== "") {
        const englishRegex = /^[a-zA-Z\s.,'?!-]*$/;
        if (!englishRegex.test(value)) {
          return;
        }
      }
      setInputValue(value);
      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");

      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      setSelectedOptions(selected);
      setInputValue(selectedValue[0] || "");
    },
    [options]
  );

  const textField = (
    <TextField
      placeholder="Search segments"
      type="text"
      value={inputValue}
      onChange={updateText}
      prefix={<Icon source={SearchIcon} tone="base" />}
      autoComplete="off"
      clearButton="auto"
      onClearButtonClick={() => updateText("")}
    />
  );

  
  return (
    <Box className="product_selection_country_content" ref={containerRef}>
      <Box style={{ width: "300px", height: "auto" }}>
        <Card padding={0}>
          <Box className="product_selection_cate_content_search">
            <Autocomplete
              options={options}
              selected={selectedOptions}
              onSelect={updateSelection}
              textField={textField}
            />
          </Box>
          <Scrollable style={{ height: "300px", background: "#fff" }}>
            <Box className="product_selection_cate_content_list">
              {options.map((item, ind) => (
                <Box
                  key={ind}
                  className={item.value === country ? "product_selection_cate_content_item cat_active" : "product_selection_cate_content_item"}
                  onClick={() => {
                    if (item.value === country) {
                      return
                    }
                    handleCountrySelect(item.value)
                  }}
                >
                  <InlineStack align="space-between" blockAlign="center">
                    <Box>
                      <InlineStack blockAlign="center" wrap={false}>
                        <img
                          src={`https://flagcdn.com/w20/${item.value.toLowerCase()}.png`}
                          alt="loading..."
                        />
                        <span style={{ marginLeft: "8px" }}>{item.label}</span>
                      </InlineStack>
                    </Box>
                    <Box>
                      {item.value === country ? (
                        <span>
                          <Icon source={CheckIcon} tone="base" />
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </Box>
                  </InlineStack>
                </Box>
              ))}
            </Box>
          </Scrollable>
        </Card>
      </Box>
    </Box>
  );
};

export default CountrySelectList;
