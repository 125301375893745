import React from "react";

const Index = (props) => {
  let bgColor = "#AFF2A4";
  let color = 'rgba(32,34,35,1)'
  switch (props.content) {
    case "Paid":
      bgColor = "rgb(187 233 179)";
      break;
    case "Unpaid":
      bgColor = "#E62D04";
      color = '#fff'
      break;
    case "Fulfilled":
      bgColor = "#54EFF9";
      break;
    case "Unfulfilled":
      bgColor = "#EEEBEB";
      break;
    case 'Refund (Shopify)':
      bgColor = "#fbc5bc";
      break
    case "Refunded":
      bgColor = "#fbc5bc";
      break;
    case "Refund":
      bgColor = "#fbc5bc";
      break;
    case "In processing":
      bgColor = "#b8e9ef";
      break;
    case "Partial Refunded":
      bgColor = "#fbc5bc";
      break;
    case "Partially_refunded":
      bgColor = "#fbc5bc";
      break;
    case "Partially refund":
      bgColor = "#fbc5bc";
      break;
    case "Pending":
      bgColor = "#EEEBEB";
      break;
    case "Partial Paid":
      bgColor = "#E62D04";
      color = '#fff'
      break;
    case "In sourcing":
      bgColor = "rgba(224, 240, 255, 1)";
      break;
    case "Await Sourcing":
      bgColor = "rgba(255, 214, 164, 1)";
      break;
    case "Sourcing Failed":
      color = 'rgba(142, 31, 11, 1)'
      bgColor = "rgb(254, 218, 217)";
      break;
    case "Pending Sourcing":
      bgColor = "#FF3B3B";
      color = '#fff'
      break;
    default:
      break;
  }

  return (
    <span
      style={{
        display: "inline-flex",
        maxWidth: "100%",
        minWidth: "68px",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "26px",
        padding: "2px 8px",
        backgroundColor: bgColor,
        borderRadius: "16px",
        color: color,
        outline: " 1px solid transparent",
      }}
    >
      {props.content === 'Paid' ? 'Settled' : props.content}
    </span>
  );
};

export default Index;
