import { Modal } from "@shopify/polaris";
import React, { useState } from "react";
import { reqPostProductManagementProductsRestore } from "../../../../api";
import utils from "../../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

// 恢复产品
const Index = ({ 
    restoreProduct,
    setRestoreProduct,
    import_status,
    getPageData,
    getTabData,
    handleSelectionChange,
    handleTogglePopup,
    toastFun,
    listData
}) => {
  const router = useHistory()
  const handleClose = () => {
    setRestoreProduct((prev)=>({
      ...prev,
      open:false,
    }))
    setLoading(false)
  };
  const [loading,setLoading] = useState(false);
  const handleProductDelete = async()=>{
    setLoading(true)
    let params = {
      ids:restoreProduct.id,
    }
    try {
    let res = await reqPostProductManagementProductsRestore(params);
    if(res.code === '200'){
      if (res.data.is_pop) {
        handleTogglePopup('targetRef2_tips')
      }
      toastFun('Restore successfully')
      setLoading(false)
      if (listData.list.length === restoreProduct.id.length) {
          utils.routeListen(`/admin/product_management?selectedView=0`);
          utils.routeJump1(router, `/admin/product_management?selectedView=0`);
      }
      handleSelectionChange('page',false,0)
      getPageData(import_status)
      getTabData(import_status)
      setRestoreProduct((prev)=>({
        ...prev,
        open:false,
      }))
    }
  } catch (error) {
      
  }
  }
  
  return (
    <div>
      <Modal
        open={restoreProduct.open}
        onClose={handleClose}
        title={restoreProduct.id.length > 1 ? 'Bulk restore products' : "Restore products"}
        secondaryActions={{
          onAction: () => {
            handleClose();
          },
          content: "Cancel",
        }}
        primaryAction={{
          onAction: () => {handleProductDelete()},
          loading: loading,
          content: "Restore",
        }}
      >
        <Modal.Section>
        <div>
          {restoreProduct.id.length > 1
            ? (
              <div style={{fontSize:'12px'}}>Are you sure you want to restore the selected products? Restored products will be displayed in the <strong>Not Listed</strong></div>
              ) 
            : (
              <div style={{fontSize:'12px'}}>Are you sure you want to restore this product? Restored products will be displayed in the <strong>Not Listed</strong></div>
              )}
        </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
