import {Modal} from "@shopify/polaris";
import React, { useState } from "react";
const Index = ({open,setIsEnough,needNum,currentPoints}) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const handleSave = async () => {
        setSaveLoading(true);
        setIsEnough((prevValue)=>({...prevValue,isLess:false}))
      };
      const formatNumber = (value) => {
        value += '';
        const list = value.split('.');
        const prefix = list[0].charAt(0) === '-' ? '-' : '';
        let num = prefix ? list[0].slice(1) : list[0];
        let result = '';
        while (num.length > 3) {
          result = `,${num.slice(-3)}${result}`;
          num = num.slice(0, num.length - 3);
        }
    
        if (num) {
          result = num + result;
        }
        return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
      };
  return (
    <div className="upgrade_new_feature">
      <Modal
        open={open}
        onClose={() => 
          {
            setIsEnough((prevValue)=>({...prevValue,isLess:false}))
            setSaveLoading(false)
          }}
        title={'Insufficient Points'}
        primaryAction={{
          content: "OK",
          loading:saveLoading,
          onAction: () => {
            handleSave()
          },
        }}
        
      >
        <Modal.Section>
        <p style={{fontSize:'14px'}}>{`The item you selected requires ${formatNumber(needNum)} points, but you don’t have enough points. Please choose other items. (Remaining points: ${formatNumber(currentPoints)})`}</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
