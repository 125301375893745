import dashboard from '../frontEnd/en/dashboard.json'
import findProducts from '../frontEnd/en/findProducts.json'
import common from '../frontEnd/en/common.json'
import frameClass from '../frontEnd/en/frameClass.json'
import sourcing from '../frontEnd/en/sourcing.json'
import listProduct from '../frontEnd/en/listProduct.json'
import orders from '../frontEnd/en/orders.json'
import customService from '../frontEnd/en/customService.json'
import siteOptimization from '../frontEnd/en/siteOptimization.json'
import subscribe from '../frontEnd/en/subscribe.json'
import setting from '../frontEnd/en/setting.json'
import shoppingCart from '../frontEnd/en/shoppingCart.json'
import message from '../frontEnd/en/message.json'
import partnership from '../frontEnd/en/partnership.json'
const en =  {
    ...dashboard,
    ...findProducts,
    ...common,
    ...frameClass,
    ...sourcing,
    ...listProduct,
    ...orders,
    ...customService,
    ...siteOptimization,
    ...subscribe,
    ...setting,
    ...shoppingCart,
    ...message,
    ...partnership
};
export default en;