import {
  Button,
  Sheet,
  Scrollable,
  Text,
  Box,
  Badge,
  Collapsible,
  Icon,
  InlineStack,
} from "@shopify/polaris";
import { XIcon, ArrowLeftIcon,PlayCircleIcon } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import NewStartNone from "../../SubscriptionPaid/components/icons/NewStartNone";
import NewStartBlack from "../../SubscriptionPaid/components/icons/NewStartBlack";
import PlanNewModal from "./PlanNewModal";
import AchievementNewPersonModal from "./AchievementNewPersonModal";
import NewPersonVideo from "./NewPersonVideo";
import { reqPostGuideList, reqPostGuidePoints } from "../../../../api";
import utils from "../../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const SheetExample = ({new_person_side,handleNewPersonSide,new_person_step,toastFun}) => {
  const router = useHistory();
  const data2 =  [
    {
      title: "Choose a plan to get started",
      dsc: `Try Dropshipman free for 7 days to get started, and earn 200 points for completing this task.`,
      video: "",
      isAction: true,
      stepMask: "/dashboard/new_24_image1.png",
      coupon: false,
      button: "Choose a plan to start",
      icon:'',
      button2: "",
      vd: 0,
      task: 0,
      points: 200,
    },
    {
      title: "Find Products & List Products through Dropshipman",
      dsc: "Watch the video on finding products with Dropshipman to earn 100 points, and list a product from Dropshipman to your store to earn an extra 200 points.",
      video:
        "https://www.youtube.com/embed/nSWr8lEwlVI?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/dashboard/new_24_image2.png",
      coupon: false,
      button: "Watch the video",
      button2: "Find Product",
      icon:PlayCircleIcon,
      vd: 0,
      task: 0,
      points: 300,
    },
    {
      title: "Submit sourcing request with Dropshipman",
      dsc: "Watch the video on how to submit a sourcing request on Dropshipman and earn 150 points.",
      video:
        "https://www.youtube.com/embed/kNUnbKidLNU?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/dashboard/new_24_image3.png",
      coupon: false,
      button: "Watch the video",
      button2: "",
      icon:PlayCircleIcon,
      vd: 0,
      task: 0,
      points: 150,
    },
    {
      title: "Orders fulfilled by Dropshipman",
      dsc: "Watch the video on how Dropshipman fulfills your orders to earn 150 points.",
      video:
        "https://www.youtube.com/embed/1OiyRfrMF74?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/dashboard/new_24_image4.png",
      coupon: false,
      button: "Watch the video",
      button2: "",
      icon:PlayCircleIcon,
      vd: 0,
      task: 0,
      points: 150,
    },
    {
      title: "Archive dropshipping success with Dropshipman ",
      dsc: "Join Dropshipman’s dropshipping success program to earn 200 points.",
      video:
        "https://www.youtube.com/embed/1OiyRfrMF74?rel=0&autoplay=1&loop=1",
      isAction: false,
      stepMask: "/dashboard/new_24_image5.png",
      coupon: false,
      button: "Join Program",
      button2: "",
      icon:'',
      vd: 0,
      task: 0,
      points: 200,
    },
  ];
  const [newerStartData, setNewerStartData] = useState(data2);
  const [currentStart, setCurrentStart] = useState(1);//
  const [currentNextIndex, setCurrentNextIndex] = useState('');//当前展示的某个步骤
  const [sheetActive, setSheetActive] = useState(false);//侧边栏展开
  const [isStep,setIsStep] = useState(true);//是否展示某个步骤
  const [newPersonPlan,setNewPersonPlan] = useState(false);// 新人引导订阅弹窗
  const [getStartShow,setGetStartShow] = useState(false); //引导视频是否显示
  const [videoLink,setVideoLink] = useState("");// 视频链接
  const [videoTitle,setVideoTitle] = useState("");// 视频步骤标题
  const [achiveShow,setAchiveShow] = useState(false);//成就弹窗开关

  const handleNext = useCallback(() => {
    if (currentNextIndex < 5) {
      if (currentStart === 4 && !newerStartData[0].coupon) {
        setCurrentStart(0)
        setCurrentNextIndex(1)
      }
      if (currentStart === 3  && !newerStartData[0].coupon) {
        setCurrentStart(4)
        setCurrentNextIndex(0)
      }
      if (currentStart === 2  && !newerStartData[3].coupon) {
        setCurrentStart(3)
        setCurrentNextIndex(4)
        return
      }
      if (currentStart === 2  && !newerStartData[4].coupon) {
        setCurrentStart(4)
        setCurrentNextIndex(0)
        return
      }
      if (currentStart === 1  && !newerStartData[2].coupon) {
        setCurrentStart(2)
        setCurrentNextIndex(3)
        return
      }
      if (currentStart === 1  && !newerStartData[3].coupon) {
        setCurrentStart(3)
        setCurrentNextIndex(4)
        return
      }
      if (currentStart === 1  && !newerStartData[4].coupon) {
        setCurrentStart(4)
        setCurrentNextIndex(0)
        return
      }
      
    }
  }, [currentStart, currentNextIndex,newerStartData]);
  const toggleSheetActive = () => {
      setSheetActive((sheetActive) => !sheetActive)
      handleNewPersonSide(!new_person_side)
    };
const getGuideData = async (val,step) =>{
  
  try {
    let res = await reqPostGuideList();
    setSheetActive(val)
    if (res.code === '200') {
      let step_info = res.data;
      let lists = data2;
      lists = data2.map((item, ind) => {
        if (
          Object.values(step_info).length > 0 &&
          step_info[ind + 1].task === 1 &&
          step_info[ind + 1].video === 1
        ) {
          return { ...item, coupon: true,task:step_info[ind + 1].task,vd:step_info[ind + 1].video };
        } else {
          return { ...item, task:step_info[ind + 1].task,vd:step_info[ind + 1].video };
        }
      });
      setNewerStartData(lists)
      if ( step && step < 5 && step !== '') {
        setIsStep(step > 0)
        
        if (step === 4 && !lists[0].coupon) {
          setCurrentStart(4)
          setCurrentNextIndex(0)
        }
        if (step === 4 && !lists[1].coupon) {
          setCurrentStart(4)
          setCurrentNextIndex(1)
        }
        if (step === 4 && !lists[2].coupon) {
          setCurrentStart(4)
          setCurrentNextIndex(2)
        }
        if (step === 4 && !lists[3].coupon) {
          setCurrentStart(4)
          setCurrentNextIndex(3)
        }
        if (step === 3  && !lists[4].coupon) {
          setCurrentStart(3)
          setCurrentNextIndex(4)
        }
        if (step === 3  && !lists[0].coupon) {
          setCurrentStart(3)
          setCurrentNextIndex(0)
        }
        if (step === 3  && !lists[1].coupon) {
          setCurrentStart(3)
          setCurrentNextIndex(1)
        }
        if (step === 3  && !lists[2].coupon) {
          setCurrentStart(3)
          setCurrentNextIndex(2)
        }
        if (step === 2  && !lists[3].coupon) {
          setCurrentStart(2)
          setCurrentNextIndex(3)
          return
        }
        if (step === 2  && !lists[4].coupon) {
          setCurrentStart(2)
          setCurrentNextIndex(4)
          return
        }
        if (step === 2  && !lists[0].coupon) {
          setCurrentStart(2)
          setCurrentNextIndex(0)
          return
        }
        if (step === 2  && !lists[1].coupon) {
          setCurrentStart(2)
          setCurrentNextIndex(1)
          return
        }
       
        if (step === 1  && !lists[2].coupon) {
          setCurrentStart(1)
          setCurrentNextIndex(2)
          return
        }
        if (step === 1  && !lists[3].coupon) {
          setCurrentStart(1)
          setCurrentNextIndex(3)
          return
        }
        if (step === 1  && !lists[4].coupon) {
          setCurrentStart(1)
          setCurrentNextIndex(4)
          return
        }
        if (step === 1  && !lists[0].coupon) {
          setCurrentStart(1)
          setCurrentNextIndex(0)
          return
        }
      }
     
    }
    
  } catch (error) {
    
  }
 
}
    
useEffect(() => {
    let isMount = false;
    if (!isMount && new_person_side) {
      
        getGuideData(new_person_side,new_person_step)
    }
    return () => isMount = true;
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [new_person_side,new_person_step]);


const handleToPoints = (title) => {
  if(title){
    
 
  if (router.location.pathname === "/admin/product_selection" && title === 'Find Product') {
    toggleSheetActive()
    return
  }
  if (title === 'Find Product') {
    utils.routeListen("/admin/product_selection?select_tab=0");
    utils.routeJump1(router, "/admin/product_selection?select_tab=0");
  }
  if (title === 'Sourcing Request') {
    utils.routeListen("/admin/product_selection?select_tab=2");
    utils.routeJump1(router, "/admin/product_selection?select_tab=2");
  }
  
  
  if (title === "Choose a plan to start") {
    setNewPersonPlan(true)
  }
  if (title === "Join Program") {
    setAchiveShow(true)
  }
  if (title === "Dropshipman credits") {
    if (newerStartData[currentStart].coupon) {
      utils.routeListen("/admin/wallet");
      utils.routeJump1(router, "/admin/wallet");
    }else {
      openCoupon(4,"/admin/wallet");
    }
  }
  if (title === "Point") {
    if (newerStartData[currentStart].coupon) {
      utils.routeListen("/admin/points");
      utils.routeJump1(router, "/admin/points");
    }else {
      openCoupon(4,"/admin/points");
    }
  }
  if (title === "Coupons") {
    if (newerStartData[currentStart].coupon) {
      utils.routeListen("/admin/coupon");
      utils.routeJump1(router, "/admin/coupon");
    }else {
      openCoupon(4,"/admin/coupon");
    }
  }
  if (title === "Dropshipman Discord Community") {
    if (newerStartData[currentStart].coupon) {
     window.open('https://scord.com/invite/d5kEscjueC','_blank')
    }else {
      openCoupon(4,"https://discord.com/invite/d5kEscjueC",'_blank');
    }
  }
}
};

const handleOffPlanModal = ()=>{
  setNewPersonPlan(false)
}
 // 打开引导视频并调用接口
const openGuideVideo = (ind, url, vd,title) => {
  
  setGetStartShow(true)
  setVideoLink(url)
  setCurrentStart(ind)
  setVideoTitle(title)
  // 如果没有领取优惠券则调用接口
  if (vd === 0) {
    openCoupon(ind);
  }
};
const openCoupon = async (ind,link,blank='') => {
  let params = {
    step: ind + 1,
  };

  let res = await reqPostGuidePoints(params);
  
  if (res.code === "200") {
    toastFun(res.msg);
    // if (res?.data?.video) {
    //   this.setState({
    //     availablePoints:Number(this.state.availablePoints) + res?.data?.points
    //   })
    //   setPageData((prevValue)=>({
    //     ...prevValue,
    //     available_points:Number(prevValue.available_points) + res?.data?.points
    //   }))
    // }
    let list = newerStartData.map((item, k) => {
      if (k === ind) {
        return { ...item, coupon: res?.data?.task && res?.data?.video ,vd:1};
      } else {
        return item;
      }
    });
    setNewerStartData(list)
    if (link && ind === 4 && !blank) {
      utils.routeListen(link);
      utils.routeJump1(router, link);
    }
    if (link && ind === 4 && blank) {
      window.open('https://discord.com/invite/d5kEscjueC','_blank')
    }
  }
};
// 切换步骤
const handleItemToggle = (ind) => {
  setCurrentStart(ind);
};

  return (
    <div>
      {sheetActive ? <Sheet
        open={sheetActive}
        onClose={()=>toggleSheetActive()}
        accessibilityLabel="Quick start"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              borderBottom: "1px solid #DFE3E8",
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
              width: "100%",
            }}
          >
             {isStep ? <Button variant="tertiary" onClick={()=>{setIsStep(false)}}>
              <InlineStack>
             <span >
                <Icon source={ArrowLeftIcon} />
              </span>
              <Text variant="headingMd" as="h2">
                Quick start
              </Text>
              </InlineStack>
            </Button> :  <Text variant="headingMd" as="h2">
                Quick start
              </Text>}

            <Button
              accessibilityLabel="Cancel"
              icon={XIcon}
              onClick={()=>toggleSheetActive()}
              variant="plain"
            />
          </div>
          <Scrollable style={{ padding: "1rem", height: "100%" }}>
            <Box>
              <Badge tone="info">
                {newerStartData.filter((item) => item.coupon).length}/5
                Completed.
              </Badge>
            </Box>
            {!isStep ? <Box className="quick_start_content">
              {newerStartData.map((item, ind) => (
                <Box key={ind} className="quick_start_content_box">
                  <Box className="quick_start_content_box_img"></Box>
                  <Box className="quick_start_content_box_dsc">
                    <Box
                      className="quick_start_content_box_title"
                      style={{
                        padding: "0 16px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                      onClick={() => handleItemToggle(ind)}
                    >
                      {item.coupon ? <NewStartBlack /> : <NewStartNone />}
                      <Box className="">
                        <Text as="p" variant="bodyMd">
                          {item.title}
                        </Text>
                      </Box>
                    </Box>
                    <Box className="quick_start_content_box_points">
                      <span>
                        <img
                          width={16}
                          src="/sub/points_icon.png"
                          alt="Dropshipman"
                        />
                        {item.points} Points
                      </span>
                    </Box>
                    <Collapsible
                      open={Number(currentStart) === Number(ind)}
                      id="basic-collapsible"
                      transition={{
                        duration: "200ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                        <Box className="quick_start_content_right">
                      <Box className="quick_start_content_box_text">
                        {item.dsc}
                      </Box>
                      <Box className="quick_start_content_box_button">
                      {(item.button !== "" && !(Number(ind) === 0 && item.coupon)) && (<Button
                          variant="primary"
                          onClick={() =>{
                            if (item.button === 'Watch the video') {
                            openGuideVideo(ind, item.video, item.vd,item.title)
                          }else { handleToPoints(item.button)
                          }
                        }
                          }
                        >
                          {item.button}
                        </Button>)}
                        {item?.button2 && <Button
                          onClick={() => 
                            {
                                handleToPoints(item.button2)
                            }
                          }
                        >
                          {item.button2}
                        </Button>}
                      </Box>
                      </Box>
                    </Collapsible>
                  </Box>
                </Box>
              ))}
            </Box> : <Box className="quick_start_content">
                <Box className="quick_start_content_box">
                  <Box className="quick_start_content_box_img">
                    <img src={newerStartData[currentStart].stepMask} alt="Dropshipman" />
                  </Box>
                  <Box className="quick_start_content_box_dsc">
                    <Box
                      className="quick_start_content_box_title"
                      style={{
                        padding: "0 16px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                      onClick={() => {}}
                    >
                      {newerStartData[currentStart].coupon ? <NewStartBlack /> : <NewStartNone />}
                      <Box className="">
                        <Text as="p" variant="bodyMd">
                          {newerStartData[currentStart].title}
                        </Text>
                      </Box>
                    </Box>
                    <Box className="quick_start_content_box_points">
                      <span>
                        <img
                          width={16}
                          src="/sub/points_icon.png"
                          alt="Dropshipman"
                        />
                        {newerStartData[currentStart].points} Points
                      </span>
                    </Box>
                    <Collapsible
                      open={true}
                      id="basic-collapsible"
                      transition={{
                        duration: "200ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                        <Box className="quick_start_content_right">
                      <Box className="quick_start_content_box_text">
                        {newerStartData[currentStart].dsc}
                      </Box>
                      <Box className="quick_start_content_box_button">
                        {newerStartData[currentStart].icon ? <Button icon={newerStartData[currentStart].icon} variant="primary" onClick={() =>{
                          let item = newerStartData[currentStart];
                            if (item.button === 'Watch the video') {
                            openGuideVideo(currentStart, item.video, item.vd,item.title)
                          }else { handleToPoints(item.button)
                          }
                        }
                          }>
                          {newerStartData[currentStart].button}
                        </Button>:<Button variant="primary" onClick={() =>{
                          let item = newerStartData[currentStart];
                            if (item.button === 'Watch the video') {
                            openGuideVideo(currentStart, item.video, item.vd,item.title)
                          }else { handleToPoints(item.button)
                          }
                        }
                          }>
                          {newerStartData[currentStart].button}
                        </Button>}
                        {newerStartData[currentStart].button2 && <Button onClick={() => 
                            {
                                handleToPoints(newerStartData[currentStart].button2)
                            }
                          }>{newerStartData[currentStart].button2}</Button>}
                      </Box>
                      </Box>
                    </Collapsible>
                  </Box>
                </Box>
                {newerStartData[currentStart].coupon && (!newerStartData[0].coupon || !newerStartData[1].coupon || !newerStartData[2].coupon || !newerStartData[3].coupon || !newerStartData[4].coupon) ? <Box  className="next_step_box">
                    <Box className="next_step_title">Next Step</Box>
                    <Box className="next_step_button">
                        <Button onClick={()=>handleNext()}>{newerStartData[currentNextIndex].title}</Button>
                    </Box>
                </Box>:''}
            </Box>}
          </Scrollable>
        </div>
      </Sheet>:''}
      {/* 新人订阅弹窗 */}
      <PlanNewModal open={newPersonPlan} handleOffPlanModal={handleOffPlanModal} toastFun={toastFun}/>
      {/* 新人成就弹窗 */}
      <AchievementNewPersonModal open={achiveShow} setAchiveShow={setAchiveShow} handleToPoints={handleToPoints} />

      {/* 视频弹窗 */}
      <NewPersonVideo getStartShow={getStartShow} currentStart={currentStart} setGetStartShow={setGetStartShow} videoLink={videoLink} videoTitle={videoTitle}/>
    </div>
  );
};
export default SheetExample;
