import React, { Component} from "react";
import {
  Page,
  Card,
  Layout,
  SkeletonBodyText,
  Button,
  Pagination,
  Toast,
  ChoiceList,
  Tabs,
  Filters,
  SkeletonPage,
  EmptyState,
  BlockStack,
  Select,
  Link,
  Spinner,
  Text,
} from "@shopify/polaris";

import LazyLoad from 'react-lazyload';

import './index.css'

import { reqGetDropshipmanProductLibraryList } from '../../../../../api';

class DetailClass extends Component {
  state = {
    //页面动作
    loadingPage: false,
    isSearching: '0',
    page: 1,
    size: 60,
    total: 0,
    hasPrevious: false,
    hasNext: true,
    ShowCountNumber: 0,

    // 列表原始数据
    pageData: [],
    // DIV列表数据
    ListDivHtml: <div></div>,

    tabSelected: 0,
    queryValue: '',  // 关键词内容搜索
    filterStatus: [],  // 产品评分筛选
    filterShipFrom: [], // Categories 筛选
    sortBy: '',

    // 定义tab
    SetTabSelectArray: [
      {
        label: 'All',
        value: '',
      },
      {
        label: 'US Amazon Outlet',
        value: 'us-amazon-outlet',
      },
      // {
      //   label: 'US Amazon hot sell',
      //   value: 'us-amazon-hot',
      // },
      {
        label: 'Brazil warehouse',
        value: 'brazil-warehouse',
      },
      {
        label: '1688 Popular products',
        value: '1688-popular-products',
      },
      {
        label: 'AliExpress hot products',
        value: 'aliexpress-hot-products',
      },
    ]
  }

  //进入页面调用接口
  componentDidMount() {
    let selectCategories = this.props.categories;
    
    // 过滤其他参数传参
    if (selectCategories && selectCategories.indexOf("?cat=") !== -1) {
      selectCategories = selectCategories.replace('?cat=','');
    } else {
      selectCategories = null;
    }

    let cateType = selectCategories ? [selectCategories] : [];
    let isSearch = cateType.length > 0 ? true : false;

    this.setState({
      filterShipFrom: cateType
    },()=>{
      this.getPageData(isSearch);
    })
  }

  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 获取列表数据
  getPageData = async(isSearch=false) =>{
    if(isSearch){
      this.setState({
        isSearching:'1',
        hasPrevious : false,
        hasNext : false,
      });
    }else{
      this.setState({
        loadingPage: true,
        hasPrevious : false,
        hasNext : false,
      });
    }
    const params = {
      q: this.state.queryValue,
      page: this.state.page,
      size: this.state.size,
      rating: JSON.stringify(this.state.filterStatus),
      sort: this.state.sortBy,
      categories: JSON.stringify(this.state.filterShipFrom)
    };
    const response = await reqGetDropshipmanProductLibraryList(params);
    if(response.code==='200'){
      let getData = response.data;

      // 追加产品一级类目
      if (!isSearch) {
        let categoryData = getData.categoryData;
        if(categoryData.length > 0){
          let setTabs = this.state.SetTabSelectArray;
          for (let index = 0; index < categoryData.length; index++) {
            setTabs.push({
              label: categoryData[index].label,
              value: categoryData[index].value,
            });
          }
        }
      }
      
      
      this.setState({
        loadingPage: false,
        pageData: getData.result,
        total: getData.count,
        ShowCountNumber: getData.count,
        isSearching: '0',
      },()=>{
        // 更新列表数据
        this.updatePageDetail();
      });

      // 更新分页数据
      if(this.state.page > 1){
        this.setState({
          hasPrevious:true
        });
      }else{
        this.setState({
          hasPrevious:false
        });
      }

      const maxPage =  Math.ceil(response.data.count / this.state.size);
      if(this.state.page < maxPage){
        this.setState({
          hasNext:true
        });
      }else{
        this.setState({
          hasNext:false
        });
      }

    }else{
      this.toggleToast(response.msg);
      this.setState({
        loadingPage: false,
        pageData: [],
        isSearching: '0',
      });
    }
  }

  // 更新列表信息
  updatePageDetail(){
    let data = this.state.pageData;
    let listHtmlContent = <div></div>;
    let appInstallUrl = 'https://apps.shopify.com/aliexpress-dropshipping-master?surface_detail=dropshipman&surface_inter_position=1&surface_intra_position=3&surface_type=search';

    if(data.length > 0){
      listHtmlContent = data.map((item,key) => {
        let imageUrl = '/default_image.png';
        if(item.image_url !== '' && (typeof item.image_url) !=='object'){
          imageUrl = item.image_url;
        }

        return (
          <div className="Product_Card_Tkabk find_product" key={key}>
            <Card>
              <div className="Product_Image_Wrapper Product_Cover_Tkabk" style={{width:'246px',height:'246px'}}>
                <Link onClick={()=>this.handleJumpToDetail(item.id)} external>
                  {/* <div className="lazyload-wrapper"> */}
                  <LazyLoad once>
                    <img src={imageUrl} alt="" />
                  </LazyLoad>
                  {/* </div> */}
                </Link>
              </div>

                 
                <BlockStack vertical={true} spacing="extraTight">
                  <div className="Product_Title_tkabk" title={item.title}>
                    {item.title}
                  </div>
                  <Text  variant="bodyMd" as="span" fontWeight="semibold">{item.price +' USD'}</Text>
                  {/* <div className="Product_Discount_Row_tkabk">
                    <TextStyle variation="subdued">
                      <span className="Product_Compare_at_price_tkabk">
                        $49.97 USD
                      </span>
                    </TextStyle>
                    <Badge status="warning">-45%</Badge>
                  </div> */}
                </BlockStack>

                <BlockStack vertical={true} spacing="extraTight">
                  <BlockStack distribution="equalSpacing">
                    <span className="Product_Rating_tkabk">
                      <svg viewBox="0 0 20 20">
                        <path d="M5.2 18a.8.8 0 0 1-.792-.914l.743-5.203-2.917-2.917a.8.8 0 0 1 .434-1.355l4.398-.733 2.218-4.435a.8.8 0 0 1 1.435.008l2.123 4.361 4.498.801a.8.8 0 0 1 .425 1.353l-2.917 2.917.744 5.203a.8.8 0 0 1-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 0 1 5.2 18z"></path>
                      </svg>
                      {item.rating+' ('+item.evaluation_count+')'}
                    </span>
                    <span className="Product_Orders_tkabk">
                      <svg viewBox="0 0 20 20">
                        <path d="M11 1a1 1 0 1 0-2 0v7.586L7.707 7.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414L11 8.586V1z"></path>
                        <path d="M3 14V3h4V1H2.5A1.5 1.5 0 0 0 1 2.5v15A1.5 1.5 0 0 0 2.5 19h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 17.5 1H13v2h4v11h-3.5c-.775 0-1.388.662-1.926 1.244l-.11.12A1.994 1.994 0 0 1 10 16a1.994 1.994 0 0 1-1.463-.637l-.111-.12C7.888 14.664 7.275 14 6.5 14H3z"></path>
                      </svg> 
                      {' '+item.order_count}
                    </span>
                  </BlockStack>
                  <div className="Place_Order_Button">
                    <Button 
                      url={appInstallUrl}
                      fullWidth
                      external
                    >
                      Import to Shopify
                    </Button>
                  </div>
                </BlockStack>
                 
            </Card>
          </div>
        );
      });
    }

    this.setState({
      ListDivHtml: listHtmlContent,
      ShowCountNumber: data.length,
    });
  }


  // 跳转到产品详情页面
  handleJumpToDetail = (id) => {
    // 调用父级组件的方法
    this.props.fatherFun({
      type: 'detail',
      id: id
    });
  }

  // tabs 切换
  handleTabChange = (index) =>{
    let tabs = this.state.SetTabSelectArray;
    let categories = [];
    let cateStr = tabs[index].value;

    if (cateStr) {
      categories.push(cateStr);
    }

    this.setState({
      tabSelected: index,
      filterShipFrom: categories,
      page: 1,
    },()=>{
      // url 增加cat参数信息
      this.handleBrowserLink(cateStr);

      setTimeout(() => {
        this.getPageData(true);

        // 调用父级组件的方法
        this.props.fatherFun({
          type: 'list',
          cat: 1
        });
      },600);
    });
  }

  // select change
  handleSelectChange = (value) =>{
    this.setState({
      sortBy: value,
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getPageData(true);
      }, 600);
    })
  }

  // filter 产品评星
  handleFilterStatusChange = (status) =>{
    this.setState({
      filterStatus: status,
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getPageData(true);
      }, 600);
    });
  }

  // filter 选项移除
  handleFilterStatusRemove = () =>{
    this.setState({
      filterStatus: [],
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getPageData(true);
      }, 600);
    });
  }

  // filter ship from
  handleChangefilterShipFrom = (selectValue) =>{
    this.setState({
      filterShipFrom: selectValue,
      page: 1,
    },()=>{
      // url 增加cat参数信息
      this.handleBrowserLink(selectValue.join(''));

      setTimeout(() => {
        this.getPageData(true);
      }, 600);
    });
  }

  // 移除 ship from
  handleFilterShipFromRemove = () =>{
    this.setState({
      tabSelected: 0,
      filterShipFrom: [],
      page: 1,
    },()=>{
      // url 移除cat参数信息
      this.handleBrowserLink('');

      setTimeout(() => {
        this.getPageData(true);
      }, 600);
    });
  }

  // filter 清除所有选项选择
  handleFiltersClearAll = () =>{
    this.setState({
      tabSelected: 0,
      queryValue: '',
      filterStatus: [],
      filterShipFrom: [],
      sortBy: '',
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getPageData(true);
      }, 600);
    });
  }

  // 搜索输入
  handleFiltersQueryChange = (value) =>{
    this.setState({
      queryValue: value,
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getPageData(true);
      }, 600);
    });
  }

  // 清空搜索内容
  handleQueryValueRemove =()=>{
    this.setState({
      queryValue: '',
      page: 1,
    },()=>{
      setTimeout(() => {
        this.getPageData(true);
      }, 600);
    });
  }

  // 浏览器url请求参数处理
  handleBrowserLink = (cateStr) => {
    try {
      var currentUrl = window.location.href;
      currentUrl = currentUrl.replace(/(\?|#)[^'"]*/, '');
      if (cateStr) {
        currentUrl = currentUrl + '?cat='+ cateStr;
      }
      
      if (!!(window.history && window.history.pushState)){
        window.history.pushState({}, 0, currentUrl);
      }
    } catch (error) {
      // console.warn(error);
    }
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} duration={1500} /> : null;
    const {
      isSearching,ShowCountNumber,ListDivHtml,SetTabSelectArray
    } = this.state;

    const initSkeletonPage = (
      <div>
        <SkeletonPage fullWidth={true}>
        <Layout>
          <Layout.Section>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
        </Layout.Section>
          </Layout>
        </SkeletonPage>
      </div>
    )

    const EmptyProductList = (ShowCountNumber===0) ? (
      <div className="emptyWithImg">
        <EmptyState
          heading="No products found"
          image="/empty.svg"
        >
          <p style={{fontSize:'14px',opacity:'1',marginBottom:'0'}}>Products are on the way, so stay tuned</p>
        </EmptyState>
      </div>
    ) : '';

    const sectionMarkup = (isSearching === '1' && ShowCountNumber===0) && (
      <div style={{textAlign:'center',padding:'4rem'}}>
        <Spinner
          accessibilityLabel="Loading form field"
          hasFocusableParent={false}
        />
      </div>
    )
    
    // 生成 tab 菜单栏
    let TabSelectArray = [];
    TabSelectArray = SetTabSelectArray.map((it,kk) => {
      return {
        id: "tab-" + it.value + '-' + kk,
        content: it.label,
        accessibilityLabel: it.label + " products",
      }
    });

    const sortOptions = [
      {label: 'Order count (descending)', value: 'order_desc'},
      {label: 'Rating (descending)', value: 'rating_desc'},
      {label: 'Created time (ascending)', value: 'created_asc'},
      {label: 'Created time (descending)', value: 'created_desc'},
    ];

    const filters =  [
      {
        key: 'filterShipFrom',
        label: 'Ship from',
        filter: (
          <ChoiceList
            title='Ship from'
            titleHidden
            choices={[
              {label: 'Brazil', value: 'brazil-warehouse'},
              {label: 'United States', value: 'ship-from-usa'},
            ]}
            selected={this.state.filterShipFrom || []}
            onChange={this.handleChangefilterShipFrom}
          />
        ),
        shortcut: true,
      },
      {
        key: 'filterStatus',
        label: 'AliExpress ratings',
        filter: (
          <ChoiceList
            title='Ratings'
            titleHidden
            choices={[
              {label:'4+', value:'4'},
              {label:'4.5+', value: '4.5'}
            ]}
            selected={this.state.filterStatus || []}
            onChange={this.handleFilterStatusChange}
          />
        ),
        shortcut: true,
      }
    ]

    const appliedFilters = [];
    if (!isEmpty(this.state.filterShipFrom)) {
      const key = 'filterShipFrom';
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, this.state.filterShipFrom),
        onRemove: this.handleFilterShipFromRemove,
      });
    }

    if (!isEmpty(this.state.filterStatus)) {
      const key = 'filterStatus';
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, this.state.filterStatus),
        onRemove: this.handleFilterStatusRemove,
      });
    }

    var footerContentMsg ='';
    if(this.state.total > 0){
      const startNum = (((this.state.page - 1) * this.state.size) + 1);
      const endNum   = (this.state.page * this.state.size>this.state.total)?this.state.total:this.state.page * this.state.size;
      footerContentMsg = <div>{"Showing " + startNum + " - "+ endNum + " of " + this.state.total + " results"}</div>;
    }

    const PaginationSettings = this.state.loadingPage ? '' :
      (ShowCountNumber !== 0) ?
        <div className="Polaris-New-Pagination">
          <Pagination
            label={footerContentMsg}
            hasPrevious = {this.state.hasPrevious}
            onPrevious={() => {
              this.setState(({page:this.state.page - 1}),()=>{
                  this.getPageData(true);
              });
            }}
            hasNext = {this.state.hasNext}
            onNext={() => {
              this.setState(({page:this.state.page + 1}),()=>{
                  this.getPageData(true);
              });
            }}
          />
        </div> : '';

    // 控制列表两个产品之间间距显示
    let smallSpacingClass = (ShowCountNumber <= 4) ? 'small_spacing' : '';

    const pageContent = this.state.loadingPage ? initSkeletonPage : (
      <section>
        <Page
          title='Dropshipman dropshipping'
          fullWidth 
        >
          <Layout>
            <Layout.Section>
              <div style={{"paddingTop":"1rem"}}></div>
              <Card>
                <Tabs
                  tabs={TabSelectArray}
                  selected={this.state.tabSelected}
                  onSelect={this.handleTabChange}
                >
                     
                    <div style={{display: 'flex',flexWrap:'wrap'}}>
                      <div style={{flex: 1}}>
                        <Filters
                          queryValue={this.state.queryValue}
                          queryPlaceholder='Search title'
                          filters={filters}
                          appliedFilters={appliedFilters}
                          onQueryChange={this.handleFiltersQueryChange}
                          onQueryClear={this.handleQueryValueRemove}
                          onClearAll={this.handleFiltersClearAll}
                        />
                      </div>
                      <div style={{paddingLeft: '0.4rem'}}>
                        <Select
                          labelInline
                          label="Sort by"
                          options={sortOptions}
                          value={this.state.sortBy}
                          onChange={this.handleSelectChange}
                        />
                      </div>
                    </div>

                    {/* 数据为空显示 */}
                    {(isSearching==='1') ? (
                      sectionMarkup
                    ) : (
                      <div style={{position:'relative'}}>

                        {EmptyProductList}

                        <div className="Product__Loading" style={{display: (isSearching==='1' && ShowCountNumber===0)?'block':'none'}}>
                          <div className="Product__Loading_Image">
                            <img style={{width:'32px',height:'32px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />
                          </div>
                        </div>
                      </div>
                    )}
                    

                     

                </Tabs>
              </Card>

              {/* Start: Find products 列表 */}
              <div style={{position:'relative'}}>

                <section className="Product_Resource_Wrapper">
                  <div className="Border_Mask Left"></div>
                  <div className="Border_Mask Right"></div>
                  <div className="Border_Mask Bottom"></div>

                  <div className={"Product_Resource_Container " + smallSpacingClass}>
                    {/* 列表 div 对象 */}
                    {ListDivHtml}
                  </div>
                </section>

                <div className="Product__Loading" style={{display: (isSearching==='1' && ShowCountNumber>0)?'block':'none'}}>
                  <div className="Product__Loading_Image">
                    <img style={{width:'32px',height:'32px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />
                  </div>
                </div>
              </div>
              {/* End: 列表 */}

              {/* 分页 */}
              {PaginationSettings}

              <div className="space"></div>

            </Layout.Section>
          </Layout>
        </Page>
      </section>
    )

    return (
      <div>
        {/* 页面内容 */}
        {pageContent}

        {toastMarkup}
      </div>
    );

    function disambiguateLabel(key, value) {
      switch (key) {
        case 'taggedWith':
          return `Tagged with ${value}`;
        case 'filterStatus':
          return `Rating: ${value} +`;
        case 'filterShipFrom':
          return `Tags ${value}`;
        default:
          return value;
      }
    }

    function isEmpty(value) {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else {
        return value === '' || value == null;
      }
    }
    
  }
}

export default DetailClass;