import React, { memo } from "react";
import { Modal,    } from "@shopify/polaris";
import intl from "../../../../../../i18n/intl";

const NotSourcingCompleteModal = memo(
  ({ sourceData, SourcingOrderModalOpen, handleChangeSourcingOrder }) => {
    return (
      <div>
        <Modal
          open={sourceData.SourcingOrderActive}
          onClose={() => SourcingOrderModalOpen("", "", "", "")}
          title={intl.get("Sourcing")}
          primaryAction={{
            content: intl.get("Done"),
            onAction: () => handleChangeSourcingOrder(),
            loading: sourceData.SourcingOrderLoading,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: () => SourcingOrderModalOpen("", "", "", ""),
            },
          ]}
        >
          <Modal.Section>
          {intl.get("After sending a sourcing request, Dropshipman will search for products based on your requirements.")}
             
          </Modal.Section>
        </Modal>
      </div>
    );
  }
);

export default NotSourcingCompleteModal;
