import React, { useState, useEffect } from 'react';

// 奖品及其概率
// A:2美金,B:5美金,C:10美金,D:PRO,E:PLUS,F:鼠标,G:背包,H:无
const prizeList = [
  { prize: 'had won a $2 USD coupon.', probability: 0.5 },
  { prize: 'had won a $5 USD coupon.', probability: 0.2 },
  { prize: 'had won a $10 USD coupon.', probability: 0.1 },
  { prize: 'had won a 1 month Free Pro plan.', probability: 0.08 },
  { prize: 'had won a 1 month Free Plus plan.', probability: 0.02 },
  { prize: 'had won a laptop backpack.', probability: 0.05 },
  { prize: 'had won a Snail Wired Mouse.', probability: 0.05 },
];



  
  // 根据概率生成随机结果
const generateRandomResults = (list, count) => {
  const results = [];
  const weightArray = [];

  // 根据奖品的概率生成加权数组
  list.forEach((item) => {
    const times = Math.round(item.probability * 100);
    for (let i = 0; i < times; i++) {
      weightArray.push(item.prize);
    }
  });

  // 随机生成结果
  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * weightArray.length);
    results.push(weightArray[randomIndex]);
  }

  return results;
};

const LotteryMarquee = () => {
  const [results, setResults] = useState([]);
  const displayCount = 3; // 最大展示3个结果
  const [scrollIndex, setScrollIndex] = useState(0);
  const [textList,setTextList] = useState([])

  
  const randomWord = ()=> {
      let firstNames = [
        "Aaron", "Abby", "Adam", "Addison", "Adrian", "Aiden", "Alexa", "Alex", "Alice", "Amanda",
        "Amber", "Amelia", "Andy", "Angela", "Ann", "Anna", "Anthony", "April", "Ariana", "Arthur",
        "Ashley", "Ashton", "Austin", "Avery", "Bailey", "Barbara", "Bella", "Ben", "Bianca", "Bill",
        "Blake", "Bob", "Brandon", "Brayden", "Brianna", "Brittany", "Brooklyn", "Bryan", "Caleb", "Cameron",
        "Carl", "Carly", "Caroline", "Carter", "Casey", "Cassie", "Catherine", "Charles", "Charlie", "Charlotte",
        "Chase", "Chloe", "Chris", "Christine", "Cindy", "Clara", "Clayton", "Cliff", "Cole", "Connor",
        "Craig", "Crystal", "Curtis", "Cynthia", "Daisy", "Dale", "Dan", "Daniel", "Danny", "Darlene",
        "David", "Dean", "Debbie", "Dennis", "Derek", "Diana", "Diane", "Dominic", "Donna", "Drew",
        "Dylan", "Eddie", "Edgar", "Edward", "Elaine", "Eleanor", "Elena", "Eli", "Eliana", "Elias",
        "Elijah", "Elise", "Elizabeth", "Ella", "Ellen", "Ellie", "Emily", "Emma", "Eric", "Erica",
        "Erik", "Erin", "Ethan", "Eva", "Evan", "Evelyn", "Faith", "Felicia", "Finn", "Fiona",
        "Frank", "Gabby", "Gabriel", "Gavin", "George", "Georgia", "Gina", "Gloria", "Grace", "Grayson",
        "Greg", "Gregory", "Gwen", "Hailey", "Hank", "Hannah", "Harper", "Harry", "Hayden", "Heather",
        "Henry", "Holly", "Hope", "Hudson", "Ian", "Irene", "Isaac", "Isabel", "Isabella", "Ivy",
        "Jack", "Jackson", "Jacob", "Jade", "Jake", "James", "Jamie", "Jane", "Jared", "Jason",
        "Jasmine", "Jay", "Jayden", "Jean", "Jeff", "Jenna", "Jennifer", "Jenny", "Jeremy", "Jessica",
        "Jessie", "Jim", "Joan", "Joe", "Joel", "John", "Johnny", "Jordan", "Joseph", "Josh",
        "Joshua", "Joy", "Joyce", "Juan", "Judy", "Julia", "Julian", "Julie", "Justin", "Karen",
        "Katelyn", "Katherine", "Katie", "Kayla", "Keith", "Kelly", "Ken", "Kendra", "Kenneth", "Kevin",
        "Kim", "Kimberly", "Kirk", "Kris", "Kristen", "Kyle", "Landon", "Larry", "Laura", "Lauren",
        "Leah", "Leo", "Leon", "Leslie", "Liam", "Lily", "Linda", "Lindsey", "Logan", "Lori",
        "Lucas", "Lucy", "Luke", "Luna", "Lydia", "Mackenzie", "Madeline", "Madison", "Mae", "Maggie"
      ];
    //   let lastNames = [
    //     "Adams", "Allen", "Anderson", "Armstrong", "Arnold", "Atkins", "Bailey", "Baker", "Barnes", "Bell",
    //     "Bennett", "Black", "Blair", "Boone", "Bowman", "Boyd", "Bradley", "Brooks", "Brown", "Bryant",
    //     "Burns", "Burton", "Butler", "Campbell", "Carpenter", "Carr", "Carter", "Chavez", "Clark", "Cole",
    //     "Collins", "Cook", "Cooper", "Cox", "Craig", "Cruz", "Daniels", "Davis", "Dixon", "Duncan",
    //     "Edwards", "Elliott", "Ellis", "Evans", "Farmer", "Fisher", "Fleming", "Flores", "Ford", "Foster",
    //     "Fox", "Francis", "Franklin", "Frazier", "Freeman", "Garcia", "Garrett", "Gibson", "Glover", "Gomez",
    //     "Gonzalez", "Goodman", "Gordon", "Graham", "Grant", "Gray", "Green", "Griffin", "Hall", "Hamilton",
    //     "Hansen", "Harris", "Harrison", "Hayes", "Henderson", "Henry", "Hernandez", "Hicks", "Hill", "Hoffman",
    //     "Holland", "Holmes", "Howard", "Hudson", "Hughes", "Hunt", "Hunter", "Jackson", "James", "Jenkins",
    //     "Johnson", "Jones", "Jordan", "Keller", "Kelly", "Kennedy", "King", "Knight", "Lane", "Lawrence",
    //     "Lee", "Lewis", "Long", "Lopez", "Marshall", "Martin", "Martinez", "Mason", "Matthews", "Mendoza",
    //     "Meyer", "Miller", "Mitchell", "Moore", "Morgan", "Morris", "Murphy", "Myers", "Nelson", "Newman",
    //     "Nguyen", "Nichols", "Nixon", "Norris", "Oliver", "Olson", "Ortiz", "Owens", "Palmer", "Parker",
    //     "Patterson", "Payne", "Perry", "Peters", "Peterson", "Phillips", "Pierce", "Porter", "Powell", "Price",
    //     "Ramirez", "Reed", "Reyes", "Reynolds", "Rice", "Richards", "Richardson", "Riley", "Rivera", "Roberts",
    //     "Robertson", "Rodriguez", "Rogers", "Ross", "Russell", "Ryan", "Sanders", "Scott", "Shaw", "Shelton",
    //     "Simmons", "Smith", "Spencer", "Stanley", "Stephens", "Stevens", "Stewart", "Stone", "Sullivan", "Tate",
    //     "Taylor", "Thomas", "Thompson", "Torres", "Turner", "Vasquez", "Wagner", "Walker", "Wallace", "Ward",
    //     "Warren", "Watson", "Weaver", "Webb", "Wells", "West", "White", "Williams", "Willis", "Wood",
    //     "Wright", "Young", "Zimmerman", "Cunningham", "Moss", "Gordon", "Reed", "Mendez", "Dean", "Barnett"
    //   ];;
      // 随机生成50个英文名字
      let names = [];
      for(let i = 0; i < 200; i++) {
          let firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
        //   let lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
          names.push({ firstName: <span><span>{firstName.slice(0,3)}</span><span style={{textTransform:'lowercase'}}>{'*** '}</span></span> });
      }
      return names;
    }
  useEffect(() => {
    // 初始化生成50个随机奖品
    let isMount = false;
    if(!isMount){
        const initialResults = generateRandomResults(prizeList, 50);
        setResults(initialResults);
        setTextList(randomWord())
    }
    return () => {
        isMount = true;
    }
    //  eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setScrollIndex((prevIndex) => {
        // 如果到达最后一个，则重置为0并重新生成数据
        if (prevIndex >= results.length - displayCount) {
          const newResults = generateRandomResults(prizeList, 50);
          let list = results
          setResults([...list,...newResults]);
         
         // 重置索引
        }
        if (results.length >= 200) {
            clearInterval(interval)
            return
        }
        return prevIndex + 1; // 向下滚动
      });
    }, 2000); // 每2秒滚动一次

    return () => clearInterval(interval);
  }, [results]);

  return (
    <div className="lottery-container">
      <div className="marquee-window">
        <div className="marquee-content" style={{ transform: `translateY(-${scrollIndex * 50}px)` }}>
          {results.map((prize, index) => (
            <div key={index} className="marquee-item">
               {textList[index].firstName} {prize}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LotteryMarquee;
