/**
 * 搜索loading
 * 使用方式 ↓
 * import SearchLoading from '@/components/SearchLoading
 * <SearchLoading />
 */
import React from 'react';
import "./index.css";

const SearchLoading = () => {
  return (
    <div className="Search_Loading">
      <div className="Search_Loading_Image">
        <img width={32} src="/loading.gif" alt="" />
      </div>
    </div>
  );
};

export default SearchLoading;
