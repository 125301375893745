import {
  BlockStack,
  // Box,
  Button,
  Card,
  Collapsible,
  Layout,
  Page,
  SkeletonBodyText,
  Toast,
  Tooltip,
} from "@shopify/polaris";
import "swiper/css";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Carousel } from "react-responsive-carousel";
// import defalutData from "./subData";
import { connect, useSelector } from "react-redux";
import StartRating from "stars-rating";
import {
  reqPostSubcribeData,
  reqGetSubcribeData,
  reqGetCurrentStoreInfo,
} from "../../../../api";
import { store } from "../../../../store";
import { changePersonLevel } from "../../../../slice";
import TimeDiscount from "./icons/TimeDiscount";
import AdditionalBenefitsModal from "./AdditionalBenefitsModal";
import FeedbackOptionModal from "./FeedbackOptionModal";
// import AppBridge from "../../../../utils/appBridge";
import intl from "../../../../i18n/intl";
import PersonalAgentModal from "./PersonalAgentModal";
import ReminderPlanModal from "./ReminderPlanModal";
import { FOURTH_TEST_TIME } from "../../../../utils/constants";
import React, { useState, useEffect, useRef} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper/core";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import TimeCloseBox from "../../../../components/TimeCloseBox"
SwiperCore.use([Autoplay, Pagination]);
// 配置Swiper以使用分页器
// Import Swiper styles

const SubscriptionPaidClass = () => {
  const shopify_level = useSelector(
    (state) => state.languageReducer.subScriptData.shopify_level
  );
  const shopifyLevel = () => {
    let flag = true;
    if (
      shopify_level === "Development" ||
      shopify_level === "Developer Preview" ||
      shopify_level === "staff" ||
      shopify_level === "staff_business"
    ) {
      flag = false;
    }
    return flag;
  };
  const defalutData = {
    subList: [
      {
        name: "FREE",
        dsc: intl.get("All essential startup features"),
        symbol: "$",
        title_icon: "/sub/free_cart.png",
        popular: "",
        price: "0.00",
        oldPrice: "",
        date: intl.get("Monthly"),
        isFree: true,
        type: 1,
        button: "Downgrade",
        link: "",
        discount: 0,
        discount_time: 0,
        money: 0,
        loading: false,
        disabled: false,
        user_discounts: {
          event_active_time: '',
          event_basic_discounts: "",
          event_discounts: '',
          event_plus_discounts: '',
          event_premium_discounts: "",
          plus_active_time: '',
          premium_active_time: "",
        },
        level: 0,
        dscList: [
          // {
          //   icon: "/sub/ok_icon.png",
          //   icon_next: "/sub/ok_black_icon.png",
          //   tip_icon: "/sub/sub_tip.png",
          //   p: intl.get("{{sourcing_num}} sourcing requests per day", 3),
          //   tip_p: intl.get(
          //     "When you send a sourcing request, we will complete the product sourcing and quotation for you within 1-5 days."
          //   ),
          // },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("10 Dropshipman Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("10 AliExpress Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("10 Temu Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Unlimited Orders Fulfillment"),
            tip_p: "",
            tip_icon: "",
          },
        ],
        dscListNew: [
          // {
          //   icon: "/sub/ok_icon.png",
          //   icon_next: "/sub/ok_black_icon.png",
          //   tip_icon: "/sub/sub_tip.png",
          //   p: intl.get("Import 3 Dropshipman Product per day"),
          //   tip_p: intl.get(
          //     "When you send a sourcing request, we will complete the product sourcing and quotation for you within 1-5 days."
          //   ),
          // },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("10 Dropshipman Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("10 AliExpress Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("10 Temu Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Unlimited Orders Fulfillment"),
            tip_p: "",
            tip_icon: "",
          },
        ],
      },
      {
        name: "PRO",
        dsc: intl.get("All necessary features for growing business"),
        symbol: "$",
        title_icon: "/sub/fly.png",
        popular: "",
        price: "29.9",
        oldPrice: "49",
        date: intl.get("Monthly"),
        isFree: false,
        type: 1,
        button: "7-day free trial",
        link: "",
        discount: 0,
        discount_time: 0,
        money: 29.9,
        loading: false,
        disabled: false,
        level: 1,
        user_discounts: {
          event_active_time: '',
          event_basic_discounts: "",
          event_discounts: '',
          event_plus_discounts: '',
          event_premium_discounts: "",
          plus_active_time: '',
          premium_active_time: "",
        },
        dscList: [
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("299 Points"),
            strong: true,
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("30 Expert Sourcing Requests"),
            tip_p: intl.get(
              "After you submit a sourcing request, our expert sourcing specialists will conduct product searches and provide quotations within 1 to 3 days."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Access to 1M+ Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("24/7 VIP Support"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Dedicated Manager"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("1000 Dropshipman Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("1000 AliExpress Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("1000 Temu Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Unlimited Orders Fulfillment"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Order Sourcing"),
            // tip_p: intl.get(
            //   "When orders from your store are synced to Dropshipman and the suppliers of the products in the order are not from Dropshipman, the system will automatically initiate product sourcing for you."
            // ),
            tip_p: "",
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Bulk Product Sourcing"),
            tip_p: intl.get(
              "Supports you to initiate product sourcing for all the products in your store that are currently on sale with just one click, thereby changing the suppliers of the products to Dropshipman."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Fulfillment"),
            tip_p: intl.get(
              "You can set default payment methods and shipping channels, and when orders from your store are synced to Dropshipman, the payment will be automatically completed without the need for manual payment."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Product Pricing"),
            tip_p: '',
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Private Product Listing"),
            tip_p: intl.get(
              "Once you initiate product sourcing and privatize it, the product can only be sold by you and other customers will not be able to use the product that you have privatized."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          // {
          //   icon: "/sub/ok_icon.png",
          //   icon_next: "/sub/ok_black_icon.png",
          //   p: intl.get("Dedicated Manager"),
          //   tip_p: intl.get(
          //     "We assign you a dedicated sales consultant to provide personalized service and help you solve all problems related to product sourcing, order fulfillment, and after-sales service."
          //   ),
          //   tip_icon: "/sub/sub_tip.png",
          // },
          
        ],
        dscListNew: [
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("299 Points"),
            strong: true,
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("30 Expert Sourcing Requests"),
            tip_p: intl.get(
              "After you submit a sourcing request, our expert sourcing specialists will conduct product searches and provide quotations within 1 to 3 days."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Access to 1M+ Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("24/7 VIP Support"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Dedicated Manager"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("1000 Dropshipman Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("1000 AliExpress Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("1000 Temu Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Unlimited Orders Fulfillment"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Order Sourcing"),
            // tip_p: intl.get(
            //   "When orders from your store are synced to Dropshipman and the suppliers of the products in the order are not from Dropshipman, the system will automatically initiate product sourcing for you."
            // ),
            tip_p: "",
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Bulk Product Sourcing"),
            tip_p: intl.get(
              "Supports you to initiate product sourcing for all the products in your store that are currently on sale with just one click, thereby changing the suppliers of the products to Dropshipman."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Fulfillment"),
            tip_p: intl.get(
              "You can set default payment methods and shipping channels, and when orders from your store are synced to Dropshipman, the payment will be automatically completed without the need for manual payment."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Product Pricing"),
            tip_p: '',
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Private Product Listing"),
            tip_p: intl.get(
              "Once you initiate product sourcing and privatize it, the product can only be sold by you and other customers will not be able to use the product that you have privatized."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
        ],
      },
      {
        name: "PLUS",
        dsc: intl.get("Powerful features for scaling your business"),
        symbol: "$",
        title_icon: "/sub/huojian.png",
        popular: intl.get("Highly Recommend"),
        price: "49.9",
        oldPrice: "79",
        date: intl.get("Monthly"),
        isFree: true,
        type: 1,
        button: "7-day free trial",
        link: "",
        discount: 0,
        discount_time: 0,
        money: 49.9,
        loading: false,
        disabled: false,
        level: 2,
        user_discounts: {
          event_active_time: '',
          event_basic_discounts: "",
          event_discounts: '',
          event_plus_discounts: '',
          event_premium_discounts: "",
          plus_active_time: '',
          premium_active_time: "",
        },
        dscList: [
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("499 Points"),
            strong: true,
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("60 Expert Sourcing Requests"),
            tip_p: intl.get(
              "After you submit a sourcing request, our expert sourcing specialists will conduct product searches and provide quotations within 1 to 3 days."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Access to 1M+ Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("24/7 VIP Support"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Dedicated Manager"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("5000 Droshipman Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("5000 AliExpress Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("5000 Temu Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Unlimited Orders Fulfillment"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Order Sourcing"),
            tip_p: intl.get(
              "When orders from your store are synced to Dropshipman and the suppliers of the products in the order are not from Dropshipman, the system will automatically initiate product sourcing for you."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Bulk Product Sourcing"),
            tip_p: intl.get(
              "Supports you to initiate product sourcing for all the products in your store that are currently on sale with just one click, thereby changing the suppliers of the products to Dropshipman."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Fulfillment"),
            tip_p: intl.get(
              "You can set default payment methods and shipping channels, and when orders from your store are synced to Dropshipman, the payment will be automatically completed without the need for manual payment."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Order Tracking"),
            tip_p: intl.get(
              "From the moment your order is successfully placed, we create an order tracking process for you that includes order confirmation, product quality inspection, product shipment, and package transportation, allowing you to stay up-to-date with your order in real-time."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Winning Products"),
            tip_p: intl.get(
              "Best-selling products carefully selected and recommended by sourcing experts, helping you identify profitable opportunities."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Product Pricing"),
            tip_p: intl.get(
              "You can set price rules based on the price tiers of the products, and when importing products, the system will automatically set the prices of the products according to the price rules you have set."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Private Product Listing"),
            tip_p: intl.get(
              "Once you initiate product sourcing and privatize it, the product can only be sold by you and other customers will not be able to use the product that you have privatized."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Remove China Label"),
            tip_p: intl.get(
              "Before shipping the products, we will remove the Chinese characters and labels on the products to provide your customers with a better experience."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
        ],
        dscListNew: [
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("499 Points"),
            strong: true,
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("60 Expert Sourcing Requests"),
            tip_p: intl.get(
              "After you submit a sourcing request, our expert sourcing specialists will conduct product searches and provide quotations within 1 to 3 days."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Access to 1M+ Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("24/7 VIP Support"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Dedicated Manager"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("5000 Droshipman Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("5000 AliExpress Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("5000 Temu Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Unlimited Orders Fulfillment"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Order Sourcing"),
            tip_p: intl.get(
              "When orders from your store are synced to Dropshipman and the suppliers of the products in the order are not from Dropshipman, the system will automatically initiate product sourcing for you."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Bulk Product Sourcing"),
            tip_p: intl.get(
              "Supports you to initiate product sourcing for all the products in your store that are currently on sale with just one click, thereby changing the suppliers of the products to Dropshipman."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Fulfillment"),
            tip_p: intl.get(
              "You can set default payment methods and shipping channels, and when orders from your store are synced to Dropshipman, the payment will be automatically completed without the need for manual payment."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Order Tracking"),
            tip_p: intl.get(
              "From the moment your order is successfully placed, we create an order tracking process for you that includes order confirmation, product quality inspection, product shipment, and package transportation, allowing you to stay up-to-date with your order in real-time."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Winning Products"),
            tip_p: intl.get(
              "Best-selling products carefully selected and recommended by sourcing experts, helping you identify profitable opportunities."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Automated Product Pricing"),
            tip_p: intl.get(
              "You can set price rules based on the price tiers of the products, and when importing products, the system will automatically set the prices of the products according to the price rules you have set."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Private Product Listing"),
            tip_p: intl.get(
              "Once you initiate product sourcing and privatize it, the product can only be sold by you and other customers will not be able to use the product that you have privatized."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Remove China Label"),
            tip_p: intl.get(
              "Before shipping the products, we will remove the Chinese characters and labels on the products to provide your customers with a better experience."
            ),
            tip_icon: "/sub/sub_tip.png",
          },
        ],
      },
      {
        name: "Basic",
        dsc: intl.get("All the basics for you to start a new business"),
        symbol: "$",
        title_icon: "/sub/cart.png",
        popular: "",
        price: "9.9",
        oldPrice: "19",
        date: intl.get("Monthly"),
        isFree: true,
        type: 1,
        button: "Downgrade",
        link: "",
        discount: 0,
        discount_time: 0,
        money: 9.9,
        loading: false,
        disabled: false,
        user_discounts: {
          event_active_time: '',
          event_basic_discounts: "",
          event_discounts: '',
          event_plus_discounts: '',
          event_premium_discounts: "",
          plus_active_time: '',
          premium_active_time: "",
        },
        level: 0,
        dscList: [
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("99 Points"),
            strong: true,
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            tip_icon: "/sub/sub_tip.png",
            p: intl.get("10 Sourcing Requests"),
            tip_p: intl.get(
              "After you submit a sourcing request, our product sourcing team will conduct the search and provide a quotation within 1 to 5 days."
            ),
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Access to 1M+ Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("24/7 Support"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("300 Dropshipman Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("300 AliExpress Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("300 Temu Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Unlimited Orders Fulfillment"),
            tip_p: "",
            tip_icon: "",
          },
          // {
          //   icon: "/sub/ok_icon.png",
          //   icon_next: "/sub/ok_black_icon.png",
          //   p: intl.get("Order Auto Sourcing"),
          //   tip_p: "",
          //   tip_icon: "",
          // },
        ],
        dscListNew: [
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("99 Points"),
            strong: true,
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            tip_icon: "/sub/sub_tip.png",
            p: intl.get("10 Sourcing Requests"),
            tip_p: intl.get(
              "After you submit a sourcing request, our product sourcing team will conduct the search and provide a quotation within 1 to 5 days."
            ),
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Access to 1M+ Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("24/7 Support"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("300 Dropshipman Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("300 AliExpress Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("300 Temu Products"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Unlimited Orders Fulfillment"),
            tip_p: "",
            tip_icon: "",
          },
          // {
          //   icon: "/sub/ok_icon.png",
          //   icon_next: "/sub/ok_black_icon.png",
          //   p: intl.get("Order Auto Sourcing"),
          //   tip_p: "",
          //   tip_icon: "",
          // },
        ],
      },
      {
        name: "PREMIUM",
        dsc: intl.get(
          "All Plus features, unlimited product imports, 1999 points, and Personal Agent VIP Services"
        ),
        symbol: "$",
        title_icon: "/sub/ufo.png",
        popular: "",
        price: "149",
        oldPrice: "199",
        date: intl.get("Monthly"),
        isFree: true,
        type: 1,
        button: "Downgrade",
        link: "",
        discount: 0,
        discount_time: 0,
        money: 149,
        loading: false,
        disabled: false,
        user_discounts: {
          event_active_time: '',
          event_basic_discounts: "",
          event_discounts: '',
          event_plus_discounts: '',
          event_premium_discounts: "",
          plus_active_time: '',
          premium_active_time: "",
        },
        level: 0,
        dscList: [
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            tip_icon: "/sub/sub_tip.png",
            p: intl.get("300 Priority Expert Sourcing Requests"),
            tip_p: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Full Process Order Follow-up"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Personalized Trend Product Suggestions"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Custom Private Storage Solutions"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Recommended Chinese Factories & Sources"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Best Logistics Route Suggestions"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Rigorous Product Quality Control"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Custom Brand Packaging & Cards"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Custom Product Photography"),
            tip_p: "",
            tip_icon: "",
          },
        ],
        dscListNew: [
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            tip_icon: "/sub/sub_tip.png",
            p: intl.get("300 Priority Expert Sourcing Requests"),
            tip_p: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Full Process Order Follow-up"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Personalized Trend Product Suggestions"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Custom Private Storage Solutions"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Recommended Chinese Factories & Sources"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Best Logistics Route Suggestions"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Rigorous Product Quality Control"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Custom Brand Packaging & Cards"),
            tip_p: "",
            tip_icon: "",
          },
          {
            icon: "/sub/ok_icon.png",
            icon_next: "/sub/ok_black_icon.png",
            p: intl.get("Custom Product Photography"),
            tip_p: "",
            tip_icon: "",
          },
        ],
        agent: [
          {
            name: "Lucy Li",
            img_url: "/dashborard_sale.jpg",
            year: 5,
            rating: 4.9,
            tag: ["ssaaad", "adadadllss"],
          },
          {
            name: "Lucy Li",
            img_url: "/dashborard_sale.jpg",
            year: 5,
            rating: 4.9,
            tag: ["ssaaad", "adadadllss"],
          },
          {
            name: "Lucy Li",
            img_url: "/dashborard_sale.jpg",
            year: 5,
            rating: 4.9,
            tag: ["ssaaad", "adadadllss"],
          },
          {
            name: "Lucy Li",
            img_url: "/dashborard_sale.jpg",
            year: 5,
            rating: 4.9,
            tag: ["ssaaad", "adadadllss"],
          },
        ],
      },
    ],
    faq: {
      title: intl.get("Frequently Questions"),
      list1: [
        {
          title: intl.get(
            "Will fees be deducted during the 7-day trial period?"
          ),
          slide_down: "/sub/slide_down.png",
          slide_up: "/sub/slide_up.png",
          show: false,
          desc: intl.get(
            "The 7-day trial period is completely free. During the trial period, you can experience all the benefits of the plan you are trying out. If you find that the plan benefits do not meet your needs during the trial period, you can cancel at any time without paying any fees."
          ),
        },
        {
          title: intl.get(
            "Can I upgrade or downgrade after paying for the plan?"
          ),
          slide_down: "/sub/slide_down.png",
          slide_up: "/sub/slide_up.png",
          show: false,
          desc: intl.get(
            "After subscribing to a plan, you can freely upgrade or downgrade your plan. When you upgrade or downgrade, you will be charged based on the corresponding fees for the number of days you used the A plan and the B plan."
          ),
        },
      ],
      list2: [
        {
          title: intl.get(
            "Can I cancel or get a refund after successful subscription?"
          ),
          slide_down: "/sub/slide_down.png",
          slide_up: "/sub/slide_up.png",
          show: false,
          desc: intl.get(
            "After the 7-day trial period, the subscription bill will become effective and the system will automatically deduct the subscription fee. If you find that the plan does not meet your needs after the fee is deducted, you can cancel the plan and receive a refund at any time. The system will automatically calculate the number of days you have used and deduct the corresponding fee, and the remaining fee will be automatically refunded to you."
          ),
        },
      ],
    },
  }
  const [loading, setLoading] = useState(true);
  const [subList, setSubList] = useState(defalutData.subList); //套餐列表
  const [subFaq, setSubFaq] = useState(defalutData.faq); //faq列表
  const [reminderModal,setReminderModal] = useState(false);//组合套餐更换

  // faq折叠
  const handleToggleFaq = (k, type) => {
    switch (type) {
      case "list1":
        let list1 = subFaq.list1.map((item, ind) => {
          if (ind === k) {
            return { ...item, show: !item.show };
          } else {
            return item;
          }
        });
        setSubFaq({ ...subFaq, list1 });
        break;
      case "list2":
        let list2 = subFaq.list2.map((item, j) => {
          if (j === k) {
            return { ...item, show: !item.show };
          } else {
            return item;
          }
        });
        setSubFaq({ ...subFaq, list2 });
        break;
      default:
        break;
    }
  };

  const [isTime, setIsTime] = useState([]);
  const [discountsTime, setDiscountsTime] = useState({});
  const getSubscribeList = async () => {
    setLoading(true);
    let res = await reqGetSubcribeData();
    // res = codeData;
    // res = codeData2;
    if (res.code === "200") {
      setPremiumData((prevValue) => ({
        ...prevValue,
        agent_list: res.data.agent_list,
      }));
      handleDis(res.data.user_discounts.event_active_time);
      let currentSub = subList.map((it, k) => {
        let discount = it;
        for (let j = 0; j < res.data.package.length; j++) {
          if (k === j) {
            // if (k === 0) {
            //   discount.dscList[0].p = intl.get(
            //     "{{sourcing_num}} sourcing requests per day",
            //     res.data.package[0]?.limitation.sourcing_num
            //   );
            // }
            // if (k === 1) {
            //   discount.dscList[1].p = intl.get(
            //     "{{sourcing_num}} sourcing requests per day",
            //     res.data.package[1]?.limitation.sourcing_num
            //   );
            // }
            // if (k === 2) {
            //   discount.dscList[2].p = intl.get(
            //     "{{sourcing_num}} sourcing requests per day",
            //     res.data.package[2]?.limitation.sourcing_num
            //   );
            // }
            discount = { ...discount, ...res.data.package[j] };
          }
        }

        setDiscountsTime(res.data.user_discounts);
        setIsTime(res.data.package);
        getTimeData(res.data.user_discounts, res.data.package);
        if (getTimeDataPlus(res.data.user_discounts, res.data.package)) {
          setIsShowTime(true);
        }
        if (getTimeDataPro(res.data.user_discounts, res.data.package)) {
          setIsShowTime(true);
        }
        if (getTimeDataBasic(res.data.user_discounts, res.data.package)) {
          setIsShowTime(true);
        }
        if (getTimeDataPrrmium(res.data.user_discounts, res.data.package)) {
          setIsShowTime(true);
        }
        return {
          ...discount,
          user_discounts: res.data.user_discounts,
          level: res.data.level,
          pay_level: res.data.bill_level,
          first_purchase: res.data.first_purchase,
          is_zt: res.data.is_zt,
        };
      });

      switch (res.data.bill_level) {
        case 0:
          let list0 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
            
            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (res.data.trial_days > 0 && ind !== 3) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list0);
          break;
        case 1:
          let list1 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;

            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                ((ind >= 2 && ind < 3) || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (
                res.data.trial_days <= 0 &&
                ((ind >= 2 && ind < 3) || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list1);
          break;
        case 2:
          let list3 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;
           
            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (res.data.trial_days > 0 && (ind === 1 || ind === 4)) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (res.data.trial_days <= 0 && ind === 4) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list3);
          break;
        case 4:
          let list4 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;

            if (ind === 3) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                (ind === 1 || ind === 2 || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (res.data.trial_days <= 0 && ind >= 1 && ind !== 3) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list4);
          break;
        case 5:
          let list5 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;
            

            if (ind === 4) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                (ind === 1 || ind === 2 || ind === 3)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list5);
          break;
        default:
          break;
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000); 
      setShowToast(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setShowToast(false);
    }
  };

  
  // const [textList,setTextList] = useState([])
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      getSubscribeList();
      // setTextList(randomWord())
      setPageTitle("Plan");
    }
    return () => {
      isMount = true;
      clearAllTimers();
      setTimers([]);
    };
    // eslint-disable-next-line
  }, []);
  // 新增订阅轮播
  const addNew = () => {
    // if (data.section1.text2) {
    var words = document.querySelectorAll(".rotating-text .word");
    var currentWordIndex = -1;
    var rotateText = () => {
      if (words.length > 0) {
        if (currentWordIndex === words.length - 1) {
          currentWordIndex = -1;
        }
        currentWordIndex++;
        for (let i = 0; i < words.length; i++) {
          if (i === currentWordIndex) {
            words[i].style.top = "0";
            words[i].style.opacity = 1;
            words[i].style.transition = "all 0.5s";
            if (i > 0 && i < words.length - 1) {
              words[i - 1].style.top = "-100%";
              words[i - 1].style.opacity = 0;
              words[i + 1].style.top = "100%";
            }
            if (i === 0) {
              words[i + 1].style.top = "100%";
              words[words.length - 1].style.top = "-100%";
              words[words.length - 1].style.opacity = 0;
            }
            if (i === words.length - 1) {
              words[i - (words.length - 1)].style.top = "100%";
              words[i - 1].style.top = "-100%";
              words[i - 1].style.opacity = 0;
            }
          }
        }
      }
    };
    rotateText();
    setInterval(rotateText, 3000);
  };

  // const randomWord = ()=> {
  //   let firstNames = ["John", "Jane", "Mike", "Emily", "Michael", "Christopher", "Amanda", "David", "Emma", "Ryan", "Sarah", "Michelle", "Thomas", "Jennifer", "Robert", "William", "Nicole", "Matthew", "Christine", "Brian", "Karen", "Joseph", "Linda"];
  //   let lastNames = ["Doe", "Smith", "Johnson", "Brown", "Wilson", "Wilcox", "Robinson", "Patterson", "Harris", "Roberts", "Schmidt", "Wallace", "Thomas", "Cook", "Wright", "Hicks", "Baker"];

  //   // 随机生成50个英文名字
  //   let names = [];
  //   for(let i = 0; i < 50; i++) {
  //       let firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  //       let lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  //       let plan = [
  //         'PRO',
  //         'PLUS'
  //       ][Math.floor(Math.random() + 0.5)]
  //       names.push({ firstName: <span><span>{firstName.slice(0,3)}</span> <span style={{textTransform:'lowercase'}}>{'*** '+lastName}</span>{' has subscribed to '+ plan +' package. '}</span> });
  //   }
  //   return names;
  // }
  const premiumSectionRef = useRef(null);
  const location = useLocation();
  const [scrollTrigger, setScrollTrigger] = useState(false);
  useEffect(() => {
    // 获取URL参数
    let isMount = false;
    if (!isMount) {
      const params = new URLSearchParams(location.search);
      const link = params.get('link');
      if (link === 'premium' && premiumSectionRef.current && scrollTrigger) {
        // 滚动到带有ref的锚点位置
        premiumSectionRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center', // 滚动到视图中央
          inline: 'center'
        });
        
      }
    }
    return () => {
      isMount = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTrigger]);
  useEffect(() => {
    let isMount = false;
    if (!isMount && !loading) {
      addNew();
      setScrollTrigger(true)
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  // 设置标题
  const setPageTitle = (title) => {
    // if (AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar(title);
    // }
  };
  const [feedOptionModal, setFeedOptionModal] = useState(false);
  const handleFeed = () => {
    setFeedOptionModal(true);
  };
  // 升级套餐
  const handleMageUpgrade = async (
    id,
    val,
    email = "",
    social = "",
    am_id = ""
  ) => {
    let list = subList.map((item, ind) => {
      if (ind === id || id === 4 || id === 5) {
        return { ...item, loading: true };
      } else {
        return { ...item, disabled: true };
      }
    });
    setSubList(list);
    try {
      let params = {};
      let param = {
        package_id: id,
        email: email,
        social: social,
        am_id: am_id,
      };
      if (val && val !== "") {
        param.remark = val;
      }
      let res = await reqPostSubcribeData(param);
      if (res.code === "200") {
        if (res?.data?.id !== "") {
          if (id !== 0) {
            let list1 = subList.map((item, ind) => {
              if (ind === id || id === 4 || id === 5) {
                return { ...item, loading: false };
              } else {
                return item;
              }
            });
            toggleToast(res.msg);
            setSubList(list1);
            window.top.location.href = res.data.url;
          } else {
            //降级
            toggleToast(intl.get("Downgraded successfully"));
            getSubscribeList();
            const response = await reqGetCurrentStoreInfo(params);
            if (response.code === "200") {
              let getData = response.data;
              store.dispatch(changePersonLevel({ infoData: getData }, true));
            }
          }
          setShowToast(false);
        } else {
          toggleToast(intl.get("Downgraded successfully"));
          const response = await reqGetCurrentStoreInfo(params);
          if (response.code === "200") {
            let getData = response.data;
            store.dispatch(changePersonLevel({ infoData: getData }, true));
          }
          getSubscribeList();
          //降级
          setShowToast(false);
        }
      } else {
        getSubscribeList();
        toggleToast(intl.get("Something wrong"));
      }
    } catch (error) {}
  };

  // 高级套餐
  const [premiumData, setPremiumData] = useState({
    open: false,
    agent_list: [],
  });
  const handlePremiumOff = () => {
    setPremiumData((prevValue) => ({
      ...prevValue,
      open: false,
    }));
  };
 
  // 获取当前时间
  const getCurrentTime = () => {
    const timestamp = Date.parse(new Date()) / 1000;
    return timestamp;
  };

  const getYMDStart = (timeStamp) => {
    var dt = new Date(timeStamp);
    var m = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Spt",
      "Oct",
      "Nov",
      "Dec",
    ];
    // var w=["Monday","Tuseday","Wednesday","Thursday","Friday","Saturday","Sunday"];
    var d = ["st", "nd", "rd", "th"];
    var mn = dt.getMonth();
    // var wn= dt.getDay();
    var dn = dt.getDate();
    var dns;
    if (dn % 10 < 1 || dn % 10 > 3) {
      dns = d[3];
    } else {
      dns = d[(dn % 10) - 1];
      if (dn === 11 || dn === 12) {
        dns = d[3];
      }
    }
    return m[mn] + " " + dn + dns + " to ";
  };
  const getYMDEnd = (timeStamp) => {
    var dt = new Date(timeStamp);
    var m = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Spt",
      "Oct",
      "Nov",
      "Dec",
    ];
    // var w=["Monday","Tuseday","Wednesday","Thursday","Friday","Saturday","Sunday"];
    var d = ["st", "nd", "rd", "th"];
    var mn = dt.getMonth();
    // var wn= dt.getDay();
    var dn = dt.getDate();
    var dns;
    if (dn % 10 < 1 || dn % 10 > 3) {
      dns = d[3];
    } else {
      dns = d[(dn % 10) - 1];
      if (dn === 11 || dn === 12) {
        dns = d[3];
      }
    }
    return m[mn] + " " + dn + dns + " , " + dt.getFullYear();
  };
  const getTimeData = (userData, data) => {
    // 1. 全员套餐存在 闹钟无
    // 2. 全员套餐存在 闹钟套餐存在
    let flag = false;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        // 无活动
        if (
          getCurrentTime() > data[i].discount_time &&
          userData.event_active_time === ""
        ) {
          flag = false;
        }
        // 无闹钟
        if (
          getCurrentTime() < data[i].discount_time &&
          userData.event_active_time === ""
        ) {
          flag = false;
        }

        // 同时存在
        if (
          getCurrentTime() < data[i].discount_time &&
          getCurrentTime() < userData.event_active_time &&
          userData.event_active_time !== ""
        ) {
          if (data[i].discount >= userData.event_discounts) {
            flag = true;
          } else {
            flag = false;
          }
        }
        // 闹钟套餐
        if (
          getCurrentTime() > data[i].discount_time &&
          getCurrentTime() < userData.event_active_time &&
          userData.event_active_time !== "" && userData.event_basic_discounts === ''
        ) {
          flag = true;
        }
        // 黑五活动
        // if (getCurrentTime() < data[i].discount_time || getCurrentTime() < userData.event_active_time) {
        //   if (data[i].discount || userData.event_discounts) {
        //     black = true
        //   }
        // }
      }
    }
    return flag;
  };
  const [isShowTime,setIsShowTime] = useState(false)
  
  const getTimeDataPlus = (userData, data) => {
    // 1. 全员套餐存在 闹钟无
    // 2. 全员套餐存在 闹钟套餐存在
    let flag = false;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        // 无活动
        if (
          getCurrentTime() > data[i].discount_time &&
          userData.plus_active_time === ""
        ) {
          flag = false;
        }
        // 无闹钟
        if (
          getCurrentTime() < data[i].discount_time &&
          userData.plus_active_time === ""
        ) {
          flag = false;
        }

        // 同时存在
        if (
          getCurrentTime() < data[i].discount_time &&
          getCurrentTime() < userData.plus_active_time &&
          userData.plus_active_time !== ""
        ) {
          if (data[i].discount >= userData.event_plus_discounts) {
            flag = true;
          } else {
            flag = false;
          }
        }
        // 闹钟套餐
        if (
          getCurrentTime() > data[i].discount_time &&
          getCurrentTime() < userData.plus_active_time &&
          userData.plus_active_time !== ""
        ) {
          flag = true;
        }
        
      }
    }
    return flag;
  };
  const getTimeDataPro = (userData, data) => {
    // 1. 全员套餐存在 闹钟无
    // 2. 全员套餐存在 闹钟套餐存在
    let flag = false;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        // 无活动
        if (
          getCurrentTime() > data[i].discount_time &&
          userData.event_active_time === ""
        ) {
          flag = false;
        }
        // 无闹钟
        if (
          getCurrentTime() < data[i].discount_time &&
          userData.event_active_time === ""
        ) {
          flag = false;
        }

        // 同时存在
        if (
          getCurrentTime() < data[i].discount_time &&
          getCurrentTime() < userData.event_active_time &&
          userData.event_active_time !== ""
        ) {
          if (data[i].discount >= userData.event_discounts) {
            flag = true;
          } else {
            flag = false;
          }
        }
        // 闹钟套餐
        if (
          getCurrentTime() > data[i].discount_time &&
          getCurrentTime() < userData.event_active_time &&
          userData.event_active_time !== ""
        ) {
          flag = true;
        }
        
      }
    }
    
    return flag;
  };
  const getTimeDataBasic = (userData, data) => {
    // 1. 全员套餐存在 闹钟无
    // 2. 全员套餐存在 闹钟套餐存在
    let flag = false;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        // 无活动
        if (
          getCurrentTime() > data[i].discount_time &&
          userData.basic_active_time === ""
        ) {
          flag = false;
        }
        // 无闹钟
        if (
          getCurrentTime() < data[i].discount_time &&
          userData.basic_active_time === ""
        ) {
          flag = false;
        }

        // 同时存在
        if (
          getCurrentTime() < data[i].discount_time &&
          getCurrentTime() < userData.basic_active_time &&
          userData.basic_active_time !== ""
        ) {
          if (data[i].discount >= userData.event_basic_discounts) {
            flag = true;
          } else {
            flag = false;
          }
        }
        // 闹钟套餐
        if (
          getCurrentTime() > data[i].discount_time &&
          getCurrentTime() < userData.basic_active_time &&
          userData.basic_active_time !== ""
        ) {
          flag = true;
        }
        
      }
    }
    
    return flag;
  };

  const getTimeDataPrrmium = (userData, data) => {
    // 1. 全员套餐存在 闹钟无
    // 2. 全员套餐存在 闹钟套餐存在
    let flag = false;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        // 无活动
        if (
          getCurrentTime() > data[i].discount_time &&
          userData.plus_active_time === ""
        ) {
          flag = false;
        }
        // 无闹钟
        if (
          getCurrentTime() < data[i].discount_time &&
          userData.plus_active_time === ""
        ) {
          flag = false;
        }

        // 同时存在
        if (
          getCurrentTime() < data[i].discount_time &&
          getCurrentTime() < userData.plus_active_time &&
          userData.plus_active_time !== ""
        ) {
          if (data[i].discount >= userData.event_premium_discounts) {
            flag = true;
          } else {
            flag = false;
          }
        }
        // 闹钟套餐
        if (
          getCurrentTime() > data[i].discount_time &&
          getCurrentTime() < userData.plus_active_time &&
          userData.plus_active_time !== ""
        ) {
          flag = true;
        }
        
      }
    }
    
    return flag;
  };
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
  const initSkeletonPage = (
    <div>
      <Page breadcrumbs={true}>
        <Layout>
          <Layout.Section>
            <Card>
              <BlockStack gap={400}>
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );
  const [timers, setTimers] = useState([]); // 定时器数组
  const [contentDiscount, setContentDiscount] = useState("");
  const handleDis = (time) => {
    let content = "";
    const timer = setInterval(() => {
      discountSubTime(time);

      content = (
        <div className="sub_paid_time_detail">
          <div className="sub_paid_time_detail_content">
            <div style={{ marginRight: "16px" }}>
              <TimeDiscount />
            </div>
            <div
              className="sub_paid_time_detail_list sub_paid_time_detail_lists"
              style={{ backgroundImage: "url(/sub/time_bg.png)" }}
            >
              <span className="sub_paid_time_detail_item">
                {discountSubTime(time).hour}
              </span>
              {/* <span style={{color:"#fff",fontSize:18}}>︰</span> */}
              <span className="sub_paid_time_detail_item">
                {discountSubTime(time).min}
              </span>
              {/* <span style={{color:"#fff",fontSize:18}}>︰</span> */}
              <span className="sub_paid_time_detail_item">
                {discountSubTime(time).sec}
              </span>
            </div>
          </div>
        </div>
      );
      setContentDiscount(content);
    }, 1000);

    let timerArr = timers;
    timerArr.push(timer);
    setTimers(timerArr);
  };

  // 清空所有定时器
  const clearAllTimers = () => {
    timers.forEach((timer) => {
      clearInterval(timer);
    });
    setTimers([]);
  };
  const discountSubTime = (time) => {
    var day;
    var hour;
    var min;
    var sec;
    var nowTime = new Date(); //现在时间
    var lastTime = parseInt((time * 1000 - nowTime) / 1000); //剩余时间=未来时间-现在时间（未来时间距离现在的秒数）
    if (lastTime >= 0) {
      day = parseInt(lastTime / 60 / 60 / 24); //换算成天数，一天有86400秒
      hour = parseInt(lastTime / 60 / 60); //余数代表剩下的小时
      min = parseInt((lastTime / 60) % 60); //代表分钟
      sec = parseInt(lastTime % 60); //代表秒数
      //给不足10的数前面添加一个0
      if (day < 10) {
        day = "0" + day;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      // console.log(hour,min,sec);
      //  console.log(getCurrentTime());
    } else {
      clearAllTimers(); //清除时间
    }
    //  console.log(hour,min,sec);
    return {
      hour,
      min,
      sec,
    };
  };
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const handleOpenModal = () => {
    setUpgradeOpen(true);
  };
  const [currentUl1, setCurrentUl1] = useState(0);
  const [currentUl2, setCurrentUl2] = useState(0);
  const handleShowOption = (ind) => {
    let list = document.querySelectorAll(".subscript_section");
    if (ind === 2) {
      setCurrentUl1(ind);
      if (currentUl1 === ind) {
        list[ind].querySelector("ul").style.maxHeight = "288px";
        list[ind].querySelector("ul").style.overflow = "hidden";
        list[ind].querySelector(".shou_all_option .roate svg").style.transform =
          "rotate(180deg)";
        setCurrentUl1(0);
      } else {
        list[ind].querySelector("ul").style.maxHeight = "648px";
        list[ind].querySelector(".shou_all_option .roate svg").style.transform =
          "rotate(0deg)";
        setTimeout(() => {
          list[ind].querySelector("ul").style.overflow = "initial";
        }, 500);
      }
    }
    if (ind === 3) {
      setCurrentUl2(ind);
      if (currentUl2 === ind) {
        list[ind].querySelector("ul").style.maxHeight = "288px";
        list[ind].querySelector("ul").style.overflow = "hidden";
        list[ind].querySelector(".shou_all_option .roate svg").style.transform =
          "rotate(180deg)";
        setCurrentUl2(0);
      } else {
        list[ind].querySelector("ul").style.maxHeight = "648px";
        list[ind].querySelector(".shou_all_option .roate svg").style.transform =
          "rotate(0deg)";
        setTimeout(() => {
          list[ind].querySelector("ul").style.overflow = "initial";
        }, 500);
      }
    }
  };
  
  // 轮播组件全局配置
  const globalConfig = {
    autoPlay: true,
    interval: 5000,
    showArrows: true,
    // showIndicators: appList.length > 1 ? true : false,
    showStatus: false,
    infiniteLoop: true,
    showThumbs: false,
  };
  
  const pageContent = (
    <Page
      // breadcrumbs={[{ onAction: () => history.push("/admin/dashboard") }]}
      // title="Plan"
      fullWidth={false}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap={600}>
            <section className="sub_content_head">
              <div className="sub_content_head_title">
                {getCurrentTime() >= FOURTH_TEST_TIME &&
                getCurrentTime() <= 1733457600 ? (
                  <>
                    <div className="black5_top_banner" style={{overflow:"hidden"}}>
                      <div className="black5_top_banner_item_sub">
                        <div className="top_drawer_content_title_sub">
                          <h3> BFCM 2024</h3>
                          <p>
                            Save more with a <strong>30%</strong> discount on
                            subscription packages.
                          </p>
                        </div>
                        <div className="top_drawer_content_desc_time top_drawer_content_desc_time_sub">
                        <div className="top_drawer_content_time_sub">
                          <div className="time_sub_l"><span>Nov 1</span></div>
                          <div className="time_sub_line"></div>
                          <div className="time_sub_r"><span>Dec 5</span></div>
                        </div>
                        <TimeCloseBox />
                        </div>
                        <div className="raffle_bg_top_sub">
                          <img
                            src="/blackFriday/raffle_bg_top.png"
                            alt="dropshipman"
                          />
                        </div>
                        <div className="top_drawer_content_off_sub">
                          <img
                            src="/blackFriday/top_fixed_off.png"
                            alt="dropshipman"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h1>{intl.get("Take Your Business to the Next Level")} </h1>
                    <img
                      className="sub_banner_one"
                      src={"/sub/sub_banner_black.png"}
                      alt="dropshipman"
                    />
                    <img
                      className="sub_banner_two"
                      src={"/sub/small_banner_black.png"}
                      alt="dropshipman"
                    />
                  </>
                )}
                {/* <img className="sub_banner_one" src={getTimeDataTwo(discountsTime,isTime) ? "/blackFriday/top_black_banner_2.png" : "/blackFriday/top_black_banner_1.png" } alt="dropshipman" />
            <img className="sub_banner_two" src={getTimeDataTwo(discountsTime,isTime) ? "/blackFriday/top_black_banner_small_2.png" : "/blackFriday/top_black_banner_small_1.png" } alt="dropshipman" /> */}
                {/* <div className='rotating-text'>
            {textList.length > 0 &&<p>
              {textList.map((item,ind)=> <span className='word' key={ind}>
              {item.firstName}
                </span>
                )}
            </p>}
        </div> */}
              </div>
            </section>
            <section className="subscript_section_card">
              <section
                className={
                  getTimeData(discountsTime, isTime)
                    ? "subscript_section_content subscript_section_contents"
                    : "subscript_section_content"
                }
              >
                 {subList.slice(0, 1).map((item, ind) => (
                  <div
                    key={ind}
                    className={isShowTime
                        ? "subscript_sections subscript_section"
                        : "subscript_section subscript_section_none"
                    }
                  >
                    {item.popular !== "" && (
                      <div
                        className={
                          ind > 0 &&
                          item.level === 0 &&
                          item.user_discounts.event_discounts < 1 &&
                          getTimeData(discountsTime, isTime)
                            ? "subscript_popular subscript_popular_type"
                            : "subscript_popular"
                        }
                      >
                        {ind === 2 && (
                          <span>
                            <img src="/sub/light_star.png" alt="dropshipman" />
                          </span>
                        )}
                        {item.popular}
                      </div>
                    )}
                    <div className="subscript_section_item">
                      <div className="subscript_section_item_left">
                        <h3>
                          {item.name}{" "}
                          <img src={item.title_icon} alt="dropshipman" />
                        </h3>
                        <p className="subscript_section_dsc">{item.dsc}</p>
                        <div className="subscript_section_price">
                          {item.discount < 1 &&
                          getCurrentTime() >= item.discount_start &&
                          getCurrentTime() <= item.discount_time ? (
                            <span className="subscript_section_new_price">
                              {item.symbol}
                              {item.money > 0
                                ? (item.money * item.discount).toFixed(2)
                                : item.money}
                            </span>
                          ) : ind > 0 &&
                            item.level === 0 &&
                            item.user_discounts.event_discounts < 1 &&
                            getCurrentTime() <
                              item.user_discounts.event_active_time ? (
                            <span className="subscript_section_new_price">
                              {item.symbol}
                              {item.money > 0
                                ? Math.ceil(
                                    item.money *
                                      (ind === 2
                                        ? item.user_discounts
                                            .event_plus_discounts
                                        : item.user_discounts.event_discounts) *
                                      100
                                  ) / 100
                                : item.money}
                            </span>
                          ) : (
                            <span className="subscript_section_new_price">
                              {item.symbol}
                              {item.money > 0
                                ? item.money.toFixed(2)
                                : item.money}
                            </span>
                          )}
                          <div>
                            {ind > 0 &&
                            item.discount < 1 &&
                            getCurrentTime() >= item.discount_start &&
                            getCurrentTime() < item.discount_time ? (
                              <div>
                                {item.money !== 0 && (
                                  <span className="subscript_section_old_price">
                                    ${item.money}
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {ind > 0 &&
                            item.level === 0 &&
                            item.user_discounts.event_discounts < 1 &&
                            getCurrentTime() <
                              item.user_discounts.event_active_time ? (
                              <div>
                                {item.money !== 0 && (
                                  <span className="subscript_section_old_price">
                                    ${item.money}
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {ind > 0 &&
                          item.discount < 1 &&
                          getCurrentTime() >= item.discount_start &&
                          getCurrentTime() < item.discount_time ? (
                            <div className="throw_line">
                              <span className="subscript_section_discount_price">
                                {parseFloat((1 - item.discount) * 100).toFixed(
                                  0
                                )}
                                % off{" "}
                                <strong>
                                  <svg
                                    style={{
                                      fontSize: "20px",
                                      verticalAlign: "middle",
                                    }}
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect width="20" height="20" fill="white" />
                                    <path
                                      d="M8.47134 12.8168L11.8048 9.69179C11.9298 9.5746 12 9.41566 12 9.24992C12 9.08419 11.9298 8.92524 11.8048 8.80805C11.6798 8.69086 11.5102 8.62502 11.3334 8.62502C11.1566 8.62502 10.9871 8.69086 10.8621 8.80805L8.66668 10.8662V3.62499C8.66668 3.45923 8.59644 3.30026 8.47142 3.18306C8.34639 3.06585 8.17682 3 8 3C7.82318 3 7.65361 3.06585 7.52858 3.18306C7.40356 3.30026 7.33332 3.45923 7.33332 3.62499V10.8662L5.13793 8.80805C5.01292 8.69086 4.84337 8.62502 4.66658 8.62502C4.48979 8.62502 4.32025 8.69086 4.19524 8.80805C4.07023 8.92524 4 9.08419 4 9.24992C4 9.41566 4.07023 9.5746 4.19524 9.69179L7.52865 12.8168C7.59051 12.8748 7.66399 12.9209 7.74486 12.9524C7.82574 12.9838 7.91244 13 8 13C8.08756 13 8.17426 12.9838 8.25514 12.9524C8.33601 12.9209 8.40948 12.8748 8.47134 12.8168Z"
                                      fill="#F5520C"
                                    />
                                  </svg>
                                </strong>
                              </span>
                              <p className="subscript_section_date">
                                {ind > 0 &&
                                item.discount < 1 &&
                                getCurrentTime() >= item.discount_start &&
                                getCurrentTime() < item.discount_time ? (
                                  <span>{intl.get("THE FIRST MONTH")}</span>
                                ) : ind > 0 &&
                                  item.level === 0 &&
                                  getTimeData(discountsTime, isTime) ? (
                                  <span>{intl.get("THE FIRST MONTH")}</span>
                                ) : (
                                  <span>/{item.date}</span>
                                )}
                              </p>
                            </div>
                          ) : (
                            <>
                              {(!getTimeData(discountsTime, isTime) ||
                                ind === 0) && (
                                <div
                                  className="throw_line"
                                  style={{ marginLeft: 8 }}
                                >
                                  /month
                                </div>
                              )}
                            </>
                          )}

                          {ind > 0 &&
                          item.level === 0 &&
                          item.user_discounts.event_discounts < 1 &&
                          getCurrentTime() <
                            item.user_discounts.event_active_time ? (
                            <div className="throw_line">
                              <span className="subscript_section_discount_price">
                                {parseFloat(
                                  (1 -
                                    (ind === 2
                                      ? item.user_discounts.event_plus_discounts
                                      : item.user_discounts.event_discounts)) *
                                    100
                                ).toFixed(0)}
                                % off{" "}
                                <strong>
                                  <svg
                                    style={{
                                      fontSize: "20px",
                                      verticalAlign: "middle",
                                    }}
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect width="20" height="20" fill="white" />
                                    <path
                                      d="M8.47134 12.8168L11.8048 9.69179C11.9298 9.5746 12 9.41566 12 9.24992C12 9.08419 11.9298 8.92524 11.8048 8.80805C11.6798 8.69086 11.5102 8.62502 11.3334 8.62502C11.1566 8.62502 10.9871 8.69086 10.8621 8.80805L8.66668 10.8662V3.62499C8.66668 3.45923 8.59644 3.30026 8.47142 3.18306C8.34639 3.06585 8.17682 3 8 3C7.82318 3 7.65361 3.06585 7.52858 3.18306C7.40356 3.30026 7.33332 3.45923 7.33332 3.62499V10.8662L5.13793 8.80805C5.01292 8.69086 4.84337 8.62502 4.66658 8.62502C4.48979 8.62502 4.32025 8.69086 4.19524 8.80805C4.07023 8.92524 4 9.08419 4 9.24992C4 9.41566 4.07023 9.5746 4.19524 9.69179L7.52865 12.8168C7.59051 12.8748 7.66399 12.9209 7.74486 12.9524C7.82574 12.9838 7.91244 13 8 13C8.08756 13 8.17426 12.9838 8.25514 12.9524C8.33601 12.9209 8.40948 12.8748 8.47134 12.8168Z"
                                      fill="#F5520C"
                                    />
                                  </svg>
                                </strong>
                              </span>
                              <p className="subscript_section_date">
                                {ind > 0 &&
                                item.discount < 1 &&
                                getCurrentTime() >= item.discount_start &&
                                getCurrentTime() < item.discount_time ? (
                                  <span>{intl.get("THE FIRST MONTH")}</span>
                                ) : ind > 0 &&
                                  item.level === 0 &&
                                  getTimeData(discountsTime, isTime) ? (
                                  <span>{intl.get("THE FIRST MONTH")}</span>
                                ) : (
                                  <span>/{item.date}</span>
                                )}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {isShowTime ? (
                          <div
                            className="throw_line"
                            style={{ visibility: "hidden" }}
                          >
                            <span
                              className={
                                shopifyLevel()
                                  ? "subscript_section_discount_price"
                                  : "subscript_section_discount_price subscript_section_discount_price_level"
                              }
                            >
                              {parseFloat(
                                (1 -
                                  (ind + 1 === 2
                                    ? item.user_discounts.event_plus_discounts
                                    : item.user_discounts.event_discounts)) *
                                  100
                              ).toFixed(0)}
                              % OFF{" "}
                            </span>
                            <p className="subscript_section_date">
                              {ind + 1 > 0 &&
                              // item.discount < 1 &&
                              getCurrentTime() >= item.discount_start &&
                              getCurrentTime() < item.discount_time ? (
                                <span>{intl.get("THE FIRST MONTH")}</span>
                              ) : ind + 1 > 0 &&
                                item.level === 0 &&
                                getTimeData(discountsTime, isTime) ? (
                                <span>{intl.get("THE FIRST MONTH")}</span>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {ind + 4 > 0 &&
                        // item.discount < 1 &&
                        item.discount_time > 0 &&
                        getCurrentTime() >= item.discount_start &&
                        getCurrentTime() < item.discount_time ? (
                          <div
                            className="throw_line"
                            style={{ visibility: "hidden" }}
                          >
                            <span
                              className={
                                shopifyLevel()
                                  ? "subscript_section_discount_price"
                                  : "subscript_section_discount_price subscript_section_discount_price_level"
                              }
                            >
                              {parseFloat((1 - item.discount) * 100).toFixed(0)}
                              % off{" "}
                            </span>
                            <p className="subscript_section_date">
                              {ind + 4 > 0 &&
                              item.discount < 1 &&
                              getCurrentTime() >= item.discount_start &&
                              getCurrentTime() < item.discount_time ? (
                                <span>{intl.get("THE FIRST MONTH")}</span>
                              ) : ind + 1 > 0 &&
                                item.level === 0 &&
                                getTimeData(discountsTime, isTime) ? (
                                <span>{intl.get("THE FIRST MONTH")}</span>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {ind + 4 > 0 &&
                        // item.discount < 1 && item.user_discounts.event_basic_discounts !== '' &&
                        // item.discount < 1 &&
                        getCurrentTime() >= item.discount_start &&
                        getCurrentTime() < item.discount_time ? (
                          <p
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              color: "#303030",
                              // height: "30px",
                              visibility: "hidden",
                              marginBottom: "8px",
                            }}
                          >
                            {intl.get("Valid from")}{" "}
                            {getYMDStart(item.discount_start * 1000) +
                              " " +
                              getYMDEnd(item.discount_time * 1000)}
                          </p>
                        ) : (
                          <p
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                              color: "#303030",
                              // height: "30px",
                              marginBottom: "8px",
                              // display:!(item.discount < 1 && getCurrentTime() >= item.discount_start && getCurrentTime() < item.discount_time) ? 'none':''
                            }}
                          ></p>
                        )}
                        {isShowTime ? (
                          <div
                            className="sub_top_time"
                            style={{ visibility: "hidden",display: shopifyLevel() ? "" : "none" }}
                          >
                            {ind > 0 &&
                            item.level === 0 &&
                            item.user_discounts.event_discounts < 1 &&
                            getCurrentTime() <
                              item.user_discounts.event_active_time ? (
                              <div className="sub_paid_time_detail">
                                <div className="sub_paid_time_detail_content">
                                  <div
                                    style={{
                                      marginRight: "16px",
                                      width: "20px",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="/sub/vector.png"
                                      alt="dropshipman"
                                    />
                                  </div>
                                  <div className="sub_paid_time_detail_list sub_paid_time_detail_lists">
                                    <span className="sub_paid_time_detail_item">
                                      
                                    </span>
                                    <span className="sub_paid_time_detail_item">
                                      
                                    </span>
                                    <span className="sub_paid_time_detail_item">
                                      
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="sub_top_time"
                                style={{ visibility: "hidden",display: shopifyLevel() ? "" : "none" }}
                              >
                                {contentDiscount}
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          className={
                            item?.is_zt ? "subscript_section_button subscript_section_button_disabled" : "subscript_section_button"
                          }
                        >
                          <Button
                            variant={
                              item.button === "7-day free trial" ||
                              item.button === "Upgrade"
                                ? "primary"
                                : ""
                            }
                            disabled={item.disabled && !item?.is_zt}
                            loading={item.loading}
                            onClick={() => {
                              if (item?.is_zt) {
                                setReminderModal(true)
                              }else {
                                if (ind === 0) {
                                  handleFeed()
                                }else {
                                  handleMageUpgrade(0)
                                }
                              }
                            }}
                          >
                            {item.button}
                          </Button>
                        </div>
                        {
                          item?.first_purchase && (
                            <div
                              className={
                                ind === 2 && item?.first_purchase
                                  ? "shou_all_option shou_all_options"
                                  : "shou_all_option shou_all_options shou_all_option_line"
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              {ind === 2 && (
                                <img
                                  width={30}
                                  src="/sub/big_gift.png"
                                  alt="dropshipman"
                                />
                              )}{" "}
                              <span
                                className="additional_gift"
                              >
                                <strong>$200</strong> extra benefits
                              </span>
                            </div>
                          )
                          // <div className="shou_all_option shou_all_options shou_all_option_line" style={{display:'flex',justifyContent:'center'}}><span className="additional_gift"><strong>$200</strong> in additional benefits.</span></div>
                        }
                      </div>
                      <div className="line_shou"></div>
                      <div className="subscript_section_item_right">
                        <span>
                          <strong>FEATURES</strong>
                        </span>
                        {item.user_discounts.event_discounts < 1 &&
                        item.level === 0 &&
                        getCurrentTime() <
                          item.user_discounts.event_active_time &&
                        (item.user_discounts.event_discounts <= item.discount ||
                          item.discount === "") ? (
                          <ul>
                            {item.dscListNew.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  {/* <img src={it.icon_next} alt="dropshipman" /> */}
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            {item.dscList.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                  {/* <img src={it.icon} alt="dropshipman" /> */}
                                  {/* {<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z" fill="#007F5F"/>
                            </svg>} */}
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {subList.slice(3, 4).map((item, ind) => (
                  <div
                    key={ind}
                    className={
                      (item.user_discounts.event_discounts !== '' &&
                      item.user_discounts.event_discounts < 1 &&
                      item.level === 0) || isShowTime
                        ? "subscript_sections subscript_section"
                        : "subscript_section subscript_section_none"
                    }
                  >
                    <div className="subscript_section_item">
                      <h3>
                        {item.name}{" "}
                        <img src={item.title_icon} alt="dropshipman" />
                      </h3>
                      <p className="subscript_section_dsc">{item.dsc}</p>
                      <div className="subscript_section_price">
                        {item.discount < 1 &&
                        item.discount_time > 0 &&
                        getCurrentTime() >= item.discount_start &&
                        getCurrentTime() <= item.discount_time ? (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? (item.money * item.discount).toFixed(2)
                              : item.money}
                          </span>
                        ) :
                          item.level === 0 &&
                          item.user_discounts.event_basic_discounts !== '' &&
                          item.user_discounts.event_basic_discounts < 1 &&
                          getCurrentTime() <
                            item.user_discounts.basic_active_time ? (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? parseFloat(
                                  item.money *
                                    (item.user_discounts.event_basic_discounts
                                      ) *
                                    100
                                ).toFixed(0) / 100
                              : item.money}
                          </span>
                        ) : (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? item.money.toFixed(2)
                              : item.money}
                          </span>
                        )}
                        <div>
                          {
                          item.discount < 1 &&
                          getCurrentTime() >= item.discount_start &&
                          getCurrentTime() < item.discount_time ? (
                            <div style={{ position: "relative" }}>
                              {item.money !== 0 && (
                                <span className="subscript_section_old_price">
                                  ${item.money}/month
                                </span>
                              )}
                              <span
                                className="subscript_section_discount_price"
                                style={{
                                  position: "absolute",
                                  left: 10,
                                  width: "100px",
                                  top: "-20px",
                                  color: !shopifyLevel() && "#182FF9",
                                }}
                              >
                                <strong>No trial period</strong>
                              </span>
                            </div>
                          ) : item.user_discounts.event_basic_discounts === "" ? (
                            <div style={{ position: "relative" }}>
                              <span className="subscript_section_old_price">
                                ${item.oldPrice}/month
                              </span>
                              <span
                                className="subscript_section_discount_price"
                                style={{
                                  position: "absolute",
                                  left: 10,
                                  width: "100px",
                                  top: "-20px",
                                  color: !shopifyLevel() && "#182FF9",
                                }}
                              >
                                <strong>No trial period</strong>
                              </span>
                            </div>
                          ) : ''}
                          {
                          item.level === 0 &&
                          item.user_discounts.event_basic_discounts !== "" &&
                          item.user_discounts.event_basic_discounts < 1 &&
                          getCurrentTime() <
                            item.user_discounts.basic_active_time ? (
                            <div style={{ position: "relative" }}>
                              {item.money !== 0 && (
                                <span className="subscript_section_old_price">
                                  ${item.money}/month
                                </span>
                              )}
                              <span
                                className="subscript_section_discount_price"
                                style={{
                                  position: "absolute",
                                  left: 10,
                                  width: "100px",
                                  top: "-20px",
                                  color: !shopifyLevel() && "#182FF9",
                                }}
                              >
                                <strong>No trial period</strong>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {(isShowTime && item.user_discounts.basic_active_time === '') ||  (item.user_discounts.event_basic_discounts < 1 && item.user_discounts.event_basic_discounts !== '' &&
                      item.level === 0 &&
                      getCurrentTime() <
                        item.user_discounts.basic_active_time) ? (
                        <div
                          className="throw_line"
                          style={{ visibility: (isShowTime && item.user_discounts.basic_active_time === '') ? "hidden" : '' }}
                        >
                          <span
                            className={
                              shopifyLevel()
                                ? "subscript_section_discount_price"
                                : "subscript_section_discount_price subscript_section_discount_price_level"
                            }
                          >
                            {parseFloat(
                              (1 -
                                (item.user_discounts.event_basic_discounts)) *
                                100
                            ).toFixed(0)}
                            % OFF{" "}
                          </span>
                          <p className="subscript_section_date">
                            {
                            item.discount < 1 &&
                            getCurrentTime() >= item.discount_start &&
                            getCurrentTime() < item.discount_time ? (
                              <span>{intl.get("THE FIRST MONTH")}</span>
                            ) :
                              item.level === 0 &&
                              getTimeDataBasic(discountsTime, isTime) ? (
                              <span>{item.user_discounts.discount_month === 2 ? "The first 2 months" : intl.get("THE FIRST MONTH")}</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {(item.discount_time > 0 &&
                      getCurrentTime() >= item.discount_start &&
                      getCurrentTime() < item.discount_time) ? (
                        <div className="throw_line"  style={{ visibility: "hidden" }}>
                          <span
                            className={
                              shopifyLevel()
                                ? "subscript_section_discount_price"
                                : "subscript_section_discount_price subscript_section_discount_price_level"
                            }
                          >
                            {parseFloat((1 - item.discount) * 100).toFixed(0)}%
                            OFF{" "}
                          </span>
                          <p className="subscript_section_date">
                            {
                            item.discount < 1 &&
                            getCurrentTime() >= item.discount_start &&
                            getCurrentTime() < item.discount_time ? (
                              <span>{intl.get("THE FIRST MONTH")}</span>
                            ) :
                              item.level === 0 &&
                              getTimeDataBasic(discountsTime, isTime) ? (
                              <span>{intl.get("THE FIRST MONTH")}</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {
                      getCurrentTime() >= item.discount_start &&
                      getCurrentTime() < item.discount_time ? (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            color: "#303030",
                            // height: "30px",
                            marginBottom: "8px",
                            visibility:'hidden'
                          }}
                        >
                          {intl.get("Valid from")}{" "}
                          {getYMDStart(item.discount_start * 1000) +
                            " " +
                            getYMDEnd(item.discount_time * 1000)}
                        </p>
                      ) : (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            color: "#303030",
                            // height: "30px",
                            marginBottom: "8px",
                            // display:!(item.discount < 1 && getCurrentTime() >= item.discount_start && getCurrentTime() < item.discount_time) ? 'none':''
                          }}
                        ></p>
                      )}
                          
                      {isShowTime ||  (item.user_discounts.event_basic_discounts < 1 && item.user_discounts.event_basic_discounts !== '' &&
                      item.level === 0 &&
                      getCurrentTime() <
                        item.user_discounts.basic_active_time) ? (
                        <div
                          className="sub_top_time"
                          style={{ visibility: (isShowTime && item.user_discounts.event_basic_discounts === '') ? 'hidden' : shopifyLevel() ? "" :  "hidden",display: shopifyLevel() ? "" : "none" }}
                        >
                          {
                          item.level === 0 &&
                          getCurrentTime() <
                            item.user_discounts.basic_active_time ? (
                            <div className="sub_paid_time_detail">
                              <div className="sub_paid_time_detail_content">
                                <div
                                  style={{
                                    marginRight: "16px",
                                    width: "20px",
                                  }}
                                >
                                  <img
                                    style={{ width: "100%" }}
                                    src="/sub/vector.png"
                                    alt="dropshipman"
                                  />
                                </div>
                                <div className="sub_paid_time_detail_list sub_paid_time_detail_lists">
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.basic_active_time
                                      ).hour
                                    }
                                  </span>
                                  <strong>:</strong>
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.basic_active_time
                                      ).min
                                    }
                                  </span>
                                  <strong>:</strong>
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.basic_active_time
                                      ).sec
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="sub_top_time"
                              style={{ visibility: "hidden",display: shopifyLevel() ? "" : "none" }}
                            >
                              {contentDiscount}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className={
                          item?.is_zt ? "subscript_section_button subscript_section_button_disabled" : "subscript_section_button"
                        }
                      >
                        <Button
                          disabled={item.disabled && !item?.is_zt}
                          loading={item.loading}
                          onClick={() => {
                            if (item?.is_zt) {
                              setReminderModal(true)
                            }else { 
                              handleMageUpgrade(4)
                            }
                          }}
                        >
                          {item.button}
                        </Button>
                      </div>
                      {
                        item?.first_purchase && (
                          <div
                            className={
                              ind + 4 === 2 && item?.first_purchase
                                ? "shou_all_option shou_all_options"
                                : "shou_all_option shou_all_options shou_all_option_line"
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              className="additional_gift"
                            >
                              <strong>$200</strong> extra benefits
                            </span>
                          </div>
                        )
                        // <div className="shou_all_option shou_all_options shou_all_option_line" style={{display:'flex',justifyContent:'center'}}><span className="additional_gift"><strong>$200</strong> in additional benefits.</span></div>
                      }
                      <div className="line_shou"></div>
                      <div>
                        <span>
                          <strong>{intl.get("FEATURES")}</strong>
                        </span>
                        {item.user_discounts.event_basic_discounts < 1 &&
                        item.level === 0 &&
                        getCurrentTime() <
                          item.user_discounts.basic_active_time  ? (
                          <ul>
                            {item.dscListNew.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  {/* <img src={it.icon_next} alt="dropshipman" /> */}
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                            {/* <span
                                  className="subscript_section_it_tip_txt"
                                  style={{ width: "250px" }}
                                >
                                  {it?.tip_p}
                                </span> */}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            {item.dscList.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                  {/* <img src={it.icon} alt="dropshipman" /> */}
                                  {/* {<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z" fill="#007F5F"/>
                            </svg>} */}
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                            {/* <span
                                  className="subscript_section_it_tip_txt"
                                  style={{ width: "250px" }}
                                >
                                  {it?.tip_p}
                                </span> */}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {subList.slice(1, 2).map((item, ind) => (
                  <div
                    key={ind}
                    className={
                      (item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !=='' &&
                      item.level === 0 &&
                      getTimeDataPro(discountsTime, isTime)) || isShowTime
                        ? "subscript_sections subscript_section"
                        : "subscript_section subscript_section_none"
                    }
                  >
                   
                    <div className="subscript_section_item">
                      <h3>
                        {item.name}{" "}
                        <img src={item.title_icon} alt="dropshipman" />
                      </h3>
                      <p className="subscript_section_dsc">{item.dsc}</p>
                      <div className="subscript_section_price">
                        {item.discount < 1 &&
                        getCurrentTime() >= item.discount_start &&
                        getCurrentTime() <= item.discount_time ? (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? (item.money * item.discount).toFixed(2)
                              : item.money}
                          </span>
                        ) :
                          item.level === 0 &&
                          item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                          getCurrentTime() <
                            item.user_discounts.event_active_time ? (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? parseFloat(
                                  item.money *
                                    (item.user_discounts.event_discounts) *
                                    100
                                ).toFixed(0) / 100
                              : item.money}
                          </span>
                        ) : (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? item.money.toFixed(2)
                              : item.money}
                          </span>
                        )}
                        <div>
                          {
                          item.discount < 1 &&
                          getCurrentTime() >= item.discount_start &&
                          getCurrentTime() < item.discount_time ? (
                            <div>
                              {item.money !== 0 && (
                                <span className="subscript_section_old_price">
                                  ${item.money}/month
                                </span>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                display:
                                  item.level === 0 &&
                                  item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                                  getCurrentTime() <
                                    item.user_discounts.event_active_time &&
                                  "none",
                              }}
                            >
                              <span className="subscript_section_old_price">
                                ${item.oldPrice}/month
                              </span>
                            </div>
                          )}
                          {
                          item.level === 0 &&
                          item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                          getCurrentTime() <
                            item.user_discounts.event_active_time ? (
                            <div style={{ marginLeft: 0 }}>
                              {item.money !== 0 && (
                                <span className="subscript_section_old_price">
                                  ${item.money}/month
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {
                      (isShowTime && item.user_discounts.event_discounts === '') || ( item.discount < 1 &&
                      getCurrentTime() >= item.discount_start &&
                      getCurrentTime() < item.discount_time) ? (
                        <div className="throw_line" style={{visibility: isShowTime ? 'hidden' : ""}}>
                          <span
                            className={
                              shopifyLevel()
                                ? "subscript_section_discount_price"
                                : "subscript_section_discount_price subscript_section_discount_price_level"
                            }
                          >
                            {parseFloat((1 - item.discount) * 100).toFixed(0)}%
                            OFF{" "}
                            
                          </span>
                          <p className="subscript_section_date">
                            {
                            item.discount < 1 &&
                            getCurrentTime() >= item.discount_start &&
                            getCurrentTime() < item.discount_time ? (
                              <span>{intl.get("THE FIRST MONTH")}</span>
                            ) : 
                              item.level === 0 &&
                              getTimeDataPro(discountsTime, isTime) ? (
                              <span>{intl.get("THE FIRST MONTH")}</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      {
                      item.level === 0 &&
                      item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== ''  &&
                      getCurrentTime() <
                        item.user_discounts.event_active_time ? (
                        <div className="throw_line">
                          <span
                            className={
                              shopifyLevel()
                                ? "subscript_section_discount_price"
                                : "subscript_section_discount_price subscript_section_discount_price_level"
                            }
                          >
                            {parseFloat(
                              (1 -
                                (item.user_discounts.event_discounts)) *
                                100
                            ).toFixed(0)}
                            % OFF{" "}
                            <strong>
                              <svg
                                style={{
                                  fontSize: "20px",
                                  verticalAlign: "middle",
                                }}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect width="20" height="20" fill="white" />
                                <path
                                  d="M8.47134 12.8168L11.8048 9.69179C11.9298 9.5746 12 9.41566 12 9.24992C12 9.08419 11.9298 8.92524 11.8048 8.80805C11.6798 8.69086 11.5102 8.62502 11.3334 8.62502C11.1566 8.62502 10.9871 8.69086 10.8621 8.80805L8.66668 10.8662V3.62499C8.66668 3.45923 8.59644 3.30026 8.47142 3.18306C8.34639 3.06585 8.17682 3 8 3C7.82318 3 7.65361 3.06585 7.52858 3.18306C7.40356 3.30026 7.33332 3.45923 7.33332 3.62499V10.8662L5.13793 8.80805C5.01292 8.69086 4.84337 8.62502 4.66658 8.62502C4.48979 8.62502 4.32025 8.69086 4.19524 8.80805C4.07023 8.92524 4 9.08419 4 9.24992C4 9.41566 4.07023 9.5746 4.19524 9.69179L7.52865 12.8168C7.59051 12.8748 7.66399 12.9209 7.74486 12.9524C7.82574 12.9838 7.91244 13 8 13C8.08756 13 8.17426 12.9838 8.25514 12.9524C8.33601 12.9209 8.40948 12.8748 8.47134 12.8168Z"
                                  fill="#F5520C"
                                />
                              </svg>
                            </strong>
                          </span>
                          <p className="subscript_section_date">
                            {
                            item.discount < 1 &&
                            getCurrentTime() >= item.discount_start &&
                            getCurrentTime() < item.discount_time ? (
                              <span>{intl.get("THE FIRST MONTH")}</span>
                            ) :
                              item.level === 0 &&
                              getTimeDataPro(discountsTime, isTime) ? (
                              <span>{item.user_discounts.discount_month === 2 ? "The first 2 months" : intl.get("THE FIRST MONTH")}</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.discount < 1 &&
                      getCurrentTime() >= item.discount_start &&
                      getCurrentTime() < item.discount_time ? (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            color: "#303030",
                            // height: "30px",
                            marginBottom: "8px",
                          }}
                        >
                          Valid from{" "}
                          {getYMDStart(item.discount_start * 1000) +
                            " " +
                            getYMDEnd(item.discount_time * 1000)}
                        </p>
                      ) : (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            color: "#303030",
                            // height: "30px",
                            marginBottom: "8px",
                          }}
                        ></p>
                      )}
                      {isShowTime || (item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !== '' &&
                      item.level === 0 &&
                      getCurrentTime() <
                        item.user_discounts.event_active_time) ? (
                        <div
                          className="sub_top_time"
                          style={{ visibility: (isShowTime && item.user_discounts.event_discounts === '') ? 'hidden' : shopifyLevel() ? "" : "hidden",display: shopifyLevel() ? "" : "none"}}
                        >
                          {shopifyLevel() &&
                          item.level === 0 &&
                          item.user_discounts.event_discounts < 1 &&
                          getCurrentTime() <
                            item.user_discounts.event_active_time ? (
                            <div className="sub_paid_time_detail">
                              <div className="sub_paid_time_detail_content">
                                <div
                                  style={{
                                    marginRight: "16px",
                                    width: "20px",
                                  }}
                                >
                                  <img
                                    style={{ width: "100%" }}
                                    src="/sub/vector.png"
                                    alt="dropshipman"
                                  />
                                </div>
                                <div className="sub_paid_time_detail_list sub_paid_time_detail_lists">
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.event_active_time
                                      ).hour
                                    }
                                  </span>
                                  <strong>:</strong>
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.event_active_time
                                      ).min
                                    }
                                  </span>
                                  <strong>:</strong>
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.event_active_time
                                      ).sec
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="sub_top_time"
                              style={{ visibility: "hidden",display: shopifyLevel() ? "" : "none" }}
                            >
                              {contentDiscount}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={
                          item?.is_zt ? "subscript_section_button subscript_section_button_disabled" : "subscript_section_button"
                        }
                      >
                        <Button
                          disabled={item.disabled && !item?.is_zt}
                          loading={item.loading}
                          onClick={() => {
                            if (item?.is_zt) {
                              setReminderModal(true)
                            }else {
                              handleMageUpgrade(1)
                            }
                          }}
                        >
                          {item.button}
                        </Button>
                      </div>
                      {
                        item?.first_purchase && (
                          <div
                            className={"shou_all_option shou_all_options shou_all_option_line"
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              className="additional_gift"
                            >
                              <strong>$200</strong> extra benefits
                            </span>
                          </div>
                        )
                      }
                      <div className="line_shou"></div>
                      <div>
                        <span>
                          <strong>FEATURES</strong>
                        </span>
                        {item.user_discounts.event_discounts < 1 && item.user_discounts.event_discounts !==''  &&
                        item.level === 0 &&
                        getCurrentTime() <
                          item.user_discounts.event_active_time  ? (
                          <ul>
                            {item.dscListNew.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  {/* <img src={it.icon_next} alt="dropshipman" /> */}
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                            {/* <span
                                  className="subscript_section_it_tip_txt"
                                  style={{ width: "250px" }}
                                >
                                  {it?.tip_p}
                                </span> */}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            {item.dscList.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                  {/* <img src={it.icon} alt="dropshipman" /> */}
                                  {/* {<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z" fill="#007F5F"/>
                            </svg>} */}
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                            {/* <span
                                  className="subscript_section_it_tip_txt"
                                  style={{ width: "250px" }}
                                >
                                  {it?.tip_p}
                                </span> */}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                        <div style={{ background: "#fff", zIndex: 88 }}>
                          <div
                            className={"shou_all_option"}
                            style={{ fontSize: 16 }}
                            onClick={() => handleShowOption(2)}
                          >
                            {intl.get("View all features")}{" "}
                            <span className="roate">
                              <svg
                                width="13"
                                height="7"
                                viewBox="0 0 13 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.4998 7C11.2438 7 10.9878 6.902 10.7928 6.707L6.49976 2.414L2.20676 6.707C1.81576 7.098 1.18376 7.098 0.792762 6.707C0.401762 6.316 0.401762 5.684 0.792762 5.293L5.79276 0.292998C6.18376 -0.0980018 6.81576 -0.0980018 7.20676 0.292998L12.2068 5.293C12.5978 5.684 12.5978 6.316 12.2068 6.707C12.0118 6.902 11.7558 7 11.4998 7Z"
                                  fill=""
                                />
                              </svg>
                            </span>
                          </div>

                          {/* {(ind === 1 && item.first_purchase) && <div className="shou_all_option" style={{height:'25px'}}></div>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {subList.slice(2, 3).map((item, ind) => (
                  <div
                    key={ind + 1}
                    className={
                      (item.user_discounts.event_plus_discounts < 1 &&
                      item.user_discounts.event_plus_discounts !== '' &&
                      item.level === 0 &&
                      getTimeDataPlus(discountsTime, isTime)) || isShowTime
                        ? "subscript_sections subscript_section"
                        : "subscript_section subscript_section_none"
                    }
                  >
                    {shopifyLevel() && item.popular !== "" ? (
                      <div
                        className={
                          item.level === 0 &&
                          item.user_discounts.event_plus_discounts < 1 &&
                          item.user_discounts.event_plus_discounts !== '' &&
                          getTimeDataPlus(discountsTime, isTime)
                            ? "subscript_popular subscript_popular_type"
                            : "subscript_popular"
                        }
                      >
                          <span>
                            <img src="/sub/light_star.png" alt="dropshipman" />
                          </span>
                        {item.popular}
                      </div>
                    ) : (
                      <div
                        className={
                          item.level === 0 &&
                          item.user_discounts.event_plus_discounts < 1 &&
                          item.user_discounts.event_plus_discounts !== '' &&
                          getTimeDataPlus(discountsTime, isTime)
                            ? "subscript_popular subscript_popular_type"
                            : "subscript_popular"
                        }
                        style={{
                          background: "#202333",
                        }}
                      >
                          <span>
                            <img src="/sub/light_star.png" alt="dropshipman" />
                          </span>
                        {item.popular}
                      </div>
                    )}
                    <div className="subscript_section_item">
                      <h3>
                        {item.name}{" "}
                        <img src={item.title_icon} alt="dropshipman" />
                      </h3>
                      <p className="subscript_section_dsc">{item.dsc}</p>
                      <div className="subscript_section_price">
                        {item.discount < 1 &&
                        getCurrentTime() >= item.discount_start &&
                        getCurrentTime() <= item.discount_time ? (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? (item.money * item.discount).toFixed(2)
                              : item.money}
                          </span>
                        ) :
                          item.level === 0 &&
                          item.user_discounts.event_plus_discounts < 1 && item.user_discounts.event_plus_discounts !== '' &&
                          getCurrentTime() <
                            item.user_discounts.plus_active_time ? (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? parseFloat(
                                  item.money *
                                    (item.user_discounts.event_plus_discounts) *
                                    100
                                ).toFixed(0) / 100
                              : item.money}
                          </span>
                        ) : (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? item.money.toFixed(2)
                              : item.money}
                          </span>
                        )}
                        <div>
                          {
                          item.discount < 1 &&
                          getCurrentTime() >= item.discount_start &&
                          getCurrentTime() < item.discount_time ? (
                            <div>
                              {item.money !== 0 && (
                                <span className="subscript_section_old_price">
                                  ${item.money}/month
                                </span>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                display:
                                  item.level === 0 &&
                                  item.user_discounts.event_plus_discounts < 1 && item.user_discounts.event_plus_discounts !== '' &&
                                  getCurrentTime() <
                                    item.user_discounts.plus_active_time &&
                                  "none",
                              }}
                            >
                              <span className="subscript_section_old_price">
                                ${item.oldPrice}/month
                              </span>
                            </div>
                          )}
                          {
                          item.level === 0 &&
                          item.user_discounts.event_plus_discounts < 1 && item.user_discounts.event_plus_discounts !== '' &&
                          getCurrentTime() <
                            item.user_discounts.plus_active_time ? (
                            <div style={{ marginLeft: 0 }}>
                              {item.money !== 0 && (
                                <span className="subscript_section_old_price">
                                  ${item.money}/month
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {(isShowTime && item.user_discounts.event_plus_discounts==='') || (
                      item.discount < 1 &&
                      getCurrentTime() >= item.discount_start &&
                      getCurrentTime() < item.discount_time) ? (
                        <div className="throw_line" style={{visibility: isShowTime ? 'hidden' : ""}}>
                          <span
                            className={
                              shopifyLevel()
                                ? "subscript_section_discount_price"
                                : "subscript_section_discount_price subscript_section_discount_price_level"
                            }
                          >
                            {parseFloat((1 - item.discount) * 100).toFixed(0)}%
                            OFF{" "}
                            {/* <strong>
                              <svg
                                style={{
                                  fontSize: "20px",
                                  verticalAlign: "middle",
                                }}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect width="20" height="20" fill="white" />
                                <path
                                  d="M8.47134 12.8168L11.8048 9.69179C11.9298 9.5746 12 9.41566 12 9.24992C12 9.08419 11.9298 8.92524 11.8048 8.80805C11.6798 8.69086 11.5102 8.62502 11.3334 8.62502C11.1566 8.62502 10.9871 8.69086 10.8621 8.80805L8.66668 10.8662V3.62499C8.66668 3.45923 8.59644 3.30026 8.47142 3.18306C8.34639 3.06585 8.17682 3 8 3C7.82318 3 7.65361 3.06585 7.52858 3.18306C7.40356 3.30026 7.33332 3.45923 7.33332 3.62499V10.8662L5.13793 8.80805C5.01292 8.69086 4.84337 8.62502 4.66658 8.62502C4.48979 8.62502 4.32025 8.69086 4.19524 8.80805C4.07023 8.92524 4 9.08419 4 9.24992C4 9.41566 4.07023 9.5746 4.19524 9.69179L7.52865 12.8168C7.59051 12.8748 7.66399 12.9209 7.74486 12.9524C7.82574 12.9838 7.91244 13 8 13C8.08756 13 8.17426 12.9838 8.25514 12.9524C8.33601 12.9209 8.40948 12.8748 8.47134 12.8168Z"
                                  fill="#F5520C"
                                />
                              </svg>
                            </strong> */}
                          </span>
                          <p className="subscript_section_date">
                            {ind + 1 > 0 &&
                            item.discount < 1 &&
                            getCurrentTime() >= item.discount_start &&
                            getCurrentTime() < item.discount_time ? (
                              <span>{intl.get("THE FIRST MONTH")}</span>
                            ) : ind + 1 > 0 &&
                              item.level === 0 &&
                              getTimeDataPlus(discountsTime, isTime) ? (
                              <span>{item.user_discounts.discount_month === 2 ? "The first 2 months" : intl.get("THE FIRST MONTH")}</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      {
                      item.level === 0 &&
                      item.user_discounts.event_plus_discounts < 1 && item.user_discounts.event_plus_discounts !== ''  &&
                      getCurrentTime() <
                        item.user_discounts.plus_active_time ? (
                        <div className="throw_line">
                          <span
                            className={
                              shopifyLevel()
                                ? "subscript_section_discount_price"
                                : "subscript_section_discount_price subscript_section_discount_price_level"
                            }
                          >
                            {parseFloat(
                              (1 -
                                (item.user_discounts.event_plus_discounts)) *
                                100
                            ).toFixed(0)}
                            % OFF{" "}
                            <strong>
                              <svg
                                style={{
                                  fontSize: "20px",
                                  verticalAlign: "middle",
                                }}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect width="20" height="20" fill="white" />
                                <path
                                  d="M8.47134 12.8168L11.8048 9.69179C11.9298 9.5746 12 9.41566 12 9.24992C12 9.08419 11.9298 8.92524 11.8048 8.80805C11.6798 8.69086 11.5102 8.62502 11.3334 8.62502C11.1566 8.62502 10.9871 8.69086 10.8621 8.80805L8.66668 10.8662V3.62499C8.66668 3.45923 8.59644 3.30026 8.47142 3.18306C8.34639 3.06585 8.17682 3 8 3C7.82318 3 7.65361 3.06585 7.52858 3.18306C7.40356 3.30026 7.33332 3.45923 7.33332 3.62499V10.8662L5.13793 8.80805C5.01292 8.69086 4.84337 8.62502 4.66658 8.62502C4.48979 8.62502 4.32025 8.69086 4.19524 8.80805C4.07023 8.92524 4 9.08419 4 9.24992C4 9.41566 4.07023 9.5746 4.19524 9.69179L7.52865 12.8168C7.59051 12.8748 7.66399 12.9209 7.74486 12.9524C7.82574 12.9838 7.91244 13 8 13C8.08756 13 8.17426 12.9838 8.25514 12.9524C8.33601 12.9209 8.40948 12.8748 8.47134 12.8168Z"
                                  fill="#F5520C"
                                />
                              </svg>
                            </strong>
                          </span>
                          <p className="subscript_section_date">
                            {
                            item.discount < 1 &&
                            getCurrentTime() >= item.discount_start &&
                            getCurrentTime() < item.discount_time ? (
                              <span>{intl.get("THE FIRST MONTH")}</span>
                            ) :
                              item.level === 0 &&
                              getTimeDataPlus(discountsTime, isTime) ? (
                              <span>{item.user_discounts.discount_month === 2 ? "The first 2 months" : intl.get("THE FIRST MONTH")}</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {
                      item.discount < 1 &&
                      getCurrentTime() >= item.discount_start &&
                      getCurrentTime() < item.discount_time ? (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            color: "#303030",
                            // height: "30px",
                            marginBottom: "8px",
                          }}
                        >
                          Valid from{" "}
                          {getYMDStart(item.discount_start * 1000) +
                            " " +
                            getYMDEnd(item.discount_time * 1000)}
                        </p>
                      ) : (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            color: "#303030",
                            // height: "30px",
                            marginBottom: "8px",
                            // display:!(item.discount < 1 && getCurrentTime() >= item.discount_start && getCurrentTime() < item.discount_time) ? 'none':''
                          }}
                        ></p>
                      )}
                      {isShowTime || (item.user_discounts.event_plus_discounts < 1 && item.user_discounts.event_plus_discounts !== '' &&
                      item.level === 0 &&
                      getCurrentTime() <
                        item.user_discounts.plus_active_time) ? (
                        <div
                          className="sub_top_time"
                          style={{ visibility:  (isShowTime && item.user_discounts.event_plus_discounts === '' ) ? 'hidden' : shopifyLevel() ? "" : "hidden" ,display: shopifyLevel() ? "" : "none"}}
                        >
                          {shopifyLevel() &&
                          item.level === 0 &&
                          item.user_discounts.event_plus_discounts < 1 && item.user_discounts.event_plus_discounts !== '' &&
                          getCurrentTime() <
                            item.user_discounts.plus_active_time ? (
                            <div className="sub_paid_time_detail">
                              <div className="sub_paid_time_detail_content">
                                <div
                                  style={{
                                    marginRight: "16px",
                                    width: "20px",
                                  }}
                                >
                                  <img
                                    style={{ width: "100%" }}
                                    src="/sub/vector.png"
                                    alt="dropshipman"
                                  />
                                </div>
                                <div className="sub_paid_time_detail_list sub_paid_time_detail_lists">
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.plus_active_time
                                      ).hour
                                    }
                                  </span>
                                  <strong>:</strong>
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.plus_active_time
                                      ).min
                                    }
                                  </span>
                                  <strong>:</strong>
                                  <span className="sub_paid_time_detail_item">
                                    {
                                      discountSubTime(
                                        item.user_discounts.plus_active_time
                                      ).sec
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="sub_top_time"
                              style={{ visibility: "hidden",display: shopifyLevel() ? "" : "none" }}
                            >
                              {contentDiscount}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={
                          item?.is_zt ? "subscript_section_button subscript_section_button_disabled" : item.level === 0 &&
                          item.user_discounts.event_plus_discounts < 1 &&
                          getCurrentTime() <
                            item.user_discounts.plus_active_time &&
                          (item.user_discounts.event_plus_discounts <=
                            item.discount ||
                            item.discount === "") ? "subscript_section_button subscript_section_button_type"
                            : "subscript_section_button subscript_section_buttons"
                        }
                      >
                        <Button
                          variant={"primary"}
                          disabled={item.disabled && !item?.is_zt}
                          loading={item.loading}
                          onClick={() => {
                            if (item?.is_zt) {
                              setReminderModal(true)
                            }else {
                              handleMageUpgrade(2)
                            }
                          }}
                        >
                          {item.button}
                        </Button>
                      </div>
                      {
                        item?.first_purchase && (
                          <div
                            className={item?.first_purchase
                                ? "shou_all_option shou_all_options"
                                : "shou_all_option shou_all_options shou_all_option_line"
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                              <img
                                width={30}
                                src="/sub/big_gift.png"
                                alt="dropshipman"
                              />
                            <span
                              onClick={() => handleOpenModal()}
                              className="additional_gift"
                            >
                              <strong>$200</strong> extra benefits
                            </span>
                          </div>
                        )
                        // <div className="shou_all_option shou_all_options shou_all_option_line" style={{display:'flex',justifyContent:'center'}}><span className="additional_gift"><strong>$200</strong> in additional benefits.</span></div>
                      }
                      <div className="line_shou"></div>
                      <div>
                        <span>
                          <strong>FEATURES</strong>
                        </span>
                        {item.user_discounts.event_plus_discounts < 1 && item.user_discounts.event_plus_discounts !== '' &&
                        item.level === 0 &&
                        getCurrentTime() <
                          item.user_discounts.plus_active_time &&
                        (item.user_discounts.event_plus_discounts <= item.discount ||
                          item.discount === "") ? (
                          <ul>
                            {item.dscListNew.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  {/* <img src={it.icon_next} alt="dropshipman" /> */}
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                            {/* <span
                                  className="subscript_section_it_tip_txt"
                                  style={{ width: "250px" }}
                                >
                                  {it?.tip_p}
                                </span> */}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            {item.dscList.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                  {/* <img src={it.icon} alt="dropshipman" /> */}
                                  {/* {<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z" fill="#007F5F"/>
                            </svg>} */}
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                            {/* <span
                                  className="subscript_section_it_tip_txt"
                                  style={{ width: "250px" }}
                                >
                                  {it?.tip_p}
                                </span> */}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                        <div style={{ background: "#fff", zIndex: 88 }}>
                          <div
                            className={"shou_all_option"}
                            style={{ fontSize: 16 }}
                            onClick={() => handleShowOption(3)}
                          >
                            {intl.get("View all features")}{" "}
                            <span className="roate">
                              <svg
                                width="13"
                                height="7"
                                viewBox="0 0 13 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.4998 7C11.2438 7 10.9878 6.902 10.7928 6.707L6.49976 2.414L2.20676 6.707C1.81576 7.098 1.18376 7.098 0.792762 6.707C0.401762 6.316 0.401762 5.684 0.792762 5.293L5.79276 0.292998C6.18376 -0.0980018 6.81576 -0.0980018 7.20676 0.292998L12.2068 5.293C12.5978 5.684 12.5978 6.316 12.2068 6.707C12.0118 6.902 11.7558 7 11.4998 7Z"
                                  fill=""
                                />
                              </svg>
                            </span>
                          </div>

                          {/* {(ind === 1 && item.first_purchase) && <div className="shou_all_option" style={{height:'25px'}}></div>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            </section>
            <section className="subscript_section_card subscript_section_card_free" ref={premiumSectionRef} id="premium">
              <section
                className={
                  getTimeDataPrrmium(discountsTime, isTime)
                    ? "subscript_section_content subscript_section_contents"
                    : "subscript_section_content"
                }
              >
                {subList.slice(4, 5).map((item, ind) => (
                  <div
                    key={ind}
                    className={
                      item.user_discounts?.event_premium_discounts < 1 &&
                      item.user_discounts?.event_premium_discounts !== '' &&
                      item.level === 0 &&
                      getTimeDataPrrmium(discountsTime, isTime)
                        ? "subscript_sections subscript_section"
                        : "subscript_section subscript_section_none"
                    }
                  >
                    <div className="premium_item">
                      <div className="premium_item_name">
                        <h3>
                          {item.name}{" "}
                          <img src={item.title_icon} alt="dropshipman" />
                        </h3>
                        <p className="subscript_section_dsc">{item.dsc}</p>
                      </div>
                      <div className="subscript_section_price">
                        {item.discount < 1 &&
                        item.discount_time > 0 &&
                        getCurrentTime() >= item.discount_start &&
                        getCurrentTime() <= item.discount_time ? (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? (item.money * item.discount).toFixed(2)
                              : item.money}
                          </span>
                        ) :
                          item.level === 0 &&
                          item.user_discounts.event_premium_discounts < 1 && item.user_discounts.event_premium_discounts !== '' &&
                          getCurrentTime() <
                            item.user_discounts.premium_active_time ? (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0
                              ? parseFloat(
                                  item.money *
                                    item.user_discounts.event_premium_discounts *
                                    100
                                ).toFixed(0) / 100
                              : item.money}
                          </span>
                        ) : (
                          <span className="subscript_section_new_price">
                            {item.symbol}
                            {item.money > 0 ? item.money : item.money}
                          </span>
                        )}
                        <div>
                          {
                          item.discount < 1 &&
                          getCurrentTime() >= item.discount_start &&
                          getCurrentTime() < item.discount_time ? (
                            <div
                              style={{
                                position: "relative",
                                marginLeft: 8,
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  display: "inline-block",
                                }}
                              >
                              {"$199/month"} 
                              </span>
                            </div>
                          ) :  item.level === 0 && item.user_discounts.event_premium_discounts !== '' &&
                          item.user_discounts.event_premium_discounts < 1 &&
                          getCurrentTime() <
                            item.user_discounts.premium_active_time ? (
                            <div
                              style={{
                                position: "relative",
                                marginLeft: 8,
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  display: "inline-block",
                                }}
                              >
                                 {"$199/month"} 
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                position: "relative",
                                marginLeft: 8,
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  display: "inline-block",
                                }}
                              >
                                 {"$299/month" } 
                              </span>
                            </div>
                          )}
                          
                        </div>
                      </div>
                      <div
                        className={
                          item?.is_zt ? "subscript_section_button subscript_section_button_disabled" : "subscript_section_button"
                        }
                      >
                        <Button
                          variant={
                            item.button === "7-day free trial" ||
                            item.button === "Upgrade"
                              ? "primary"
                              : ""
                          }
                          disabled={item.disabled && !item?.is_zt}
                          loading={item.loading}
                          onClick={() => {
                            if (item?.is_zt) {
                              setReminderModal(true)
                            }else {
                              setPremiumData((prevValue) => ({
                                ...prevValue,
                                open: true,
                              }));
                            }
                          }}
                        >
                          {item.button}
                        </Button>
                      </div>
                    </div>
                    {item.level === 0 && item.user_discounts.event_premium_discounts !== '' &&
                      item.user_discounts.event_premium_discounts < 1 &&
                      getCurrentTime() <
                      item.user_discounts.premium_active_time && <div className="black_premium_discount">
                      <div className="black_premium_discount_l">
                      <div className="black_premium_discount_title">
                        <strong>{parseFloat((1 - item.user_discounts.event_premium_discounts) * 100).toFixed(0)}% OFF</strong>
                        <span>{item.user_discounts.discount_month === 2 ? "The first 2 months" : "The First  Month"}</span>
                      </div>
                      <div className="black_premium_discount_dsc">
                      Scale your dropshipping success 10X with professional, tailored solutions
                      </div>
                      </div>
                      {/* <div className="black_premium_discount_r">
                        <img src="/blackFriday/black_premium_bg.png" alt="Dropshipman" />
                      </div> */}
                    </div>}
                    <div className="subscript_section_item premium_item_box">
                      <div className="subscript_section_item_left">
                        <div className="agent_item_service">
                          Personal agent services
                        </div>
                        <div className="agent_list">
                          <Swiper
                            spaceBetween={30}
                            slidesPerView={1}
                            autoplay={{
                              delay: 5000,
                              disableOnInteraction: true,
                            }}
                            pagination={{ clickable: true }}
                            onSlideChange={() => {}}
                            onSwiper={(swiper) => {}}
                          >
                            {premiumData?.agent_list?.map((it, k) => (
                              <SwiperSlide key={k}>
                                <div className="agent_item">
                                  <div className="agent_left">
                                    <img src={it.avatar} alt="Dropshipman" />
                                  </div>
                                  <div className="agent_right">
                                    <div className="agent_right_name">
                                      {it.name}
                                    </div>
                                    {it.customer_rating > 0 && (
                                      <div
                                        className="agent_right_rating"
                                        style={{ position: "relative" }}
                                      >
                                        {" "}
                                        <StartRating
                                          value={it.customer_rating}
                                          color1="gray"
                                          color2="#ffd700"
                                          half={true}
                                          count={5}
                                          edit={false}
                                          size={16}
                                        />
                                        <span
                                          className="agent_right_rating_span"
                                          style={{
                                            position: "absolute",
                                            left: "107px",
                                            top: "1px",
                                          }}
                                        >
                                          {" "}
                                          {it.customer_rating}
                                        </span>
                                      </div>
                                    )}
                                    <div className="agent_right_year">
                                      <span>
                                        {it.service_years}{" "}
                                        {it.service_years < 1
                                          ? " year"
                                          : " years"}{" "}
                                        of service experience
                                      </span>
                                    </div>
                                    <div className="agent_right_tag">
                                      {" "}
                                      {it.service_tags.map((t, j) => (
                                        <span key={j}>{t}</span>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                      <div className="line_shou"></div>
                      <div className="subscript_section_item_right">
                        {/* <span><strong>FEATURES</strong></span> */}
                        {item.user_discounts.event_discounts < 1 &&
                        item.level === 0 &&
                        getCurrentTime() <
                          item.user_discounts.event_active_time &&
                        (item.user_discounts.event_discounts <= item.discount ||
                          item.discount === "") ? (
                          <ul>
                            {item.dscListNew.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  {/* <img src={it.icon_next} alt="dropshipman" /> */}
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                            {/* <span
                                  className="subscript_section_it_tip_txt"
                                  style={{ width: "250px" }}
                                >
                                  {it?.tip_p}
                                </span> */}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            {item.dscList.map((it, k) => (
                              <li key={k}>
                                <span className="subscript_section_price_it_img">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
                                      fill="#1A1C1D"
                                    />
                                  </svg>
                                  {/* <img src={it.icon} alt="dropshipman" /> */}
                                  {/* {<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z" fill="#007F5F"/>
                            </svg>} */}
                                </span>
                                {it?.tip_p ? (
                                  <Tooltip content={it?.tip_p}>
                                    <p
                                      className="subscript_section_price_it_p subscript_section_price_it_ps"
                                      style={{
                                        fontWeight: it?.strong ? 700 : 400,
                                      }}
                                    >
                                      {it.p}
                                      <span className="subscript_section_it_tip">
                                        {it.tip_icon !== "" && (
                                          <span>
                                            <img
                                              src={it.tip_icon}
                                              alt="dropshipman"
                                            />
                                            {/* <span
                                  className="subscript_section_it_tip_txt"
                                  style={{ width: "250px" }}
                                >
                                  {it?.tip_p}
                                </span> */}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className="subscript_section_price_it_p"
                                    style={{
                                      fontWeight: it?.strong ? 700 : 400,
                                    }}
                                  >
                                    {it.p}
                                  </p>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            </section>
            <section className="mg_content">
              <Card>
                <div className="banner_sub">
                  <span>
                    <img src="/sub/aixin.png" alt="dropshipman" />
                  </span>
                  <h3>
                    {intl.get("Trusted by")} <strong> 40000+ </strong>{" "}
                    {intl.get("Shopify Partners")}
                  </h3>
                </div>
                <div className="real_customers_conetnt">
                  <Carousel {...globalConfig}>
                    {contentList.map((item, ind) => (
                      <div className="section5_box" key={ind}>
                        {item.list.map((box) => (
                          <div key={box.id}>
                            <div>
                              <img
                                width={20}
                                height={20}
                                className="_comment_symbal"
                                src={box.symbal}
                                alt="dropshipman"
                              />
                            </div>
                            <div className="comment_list_dsc">{box.dsc}</div>
                            <div className="comment_list_star">
                              <img
                                height={22}
                                src={box.star}
                                alt="dropshipman"
                              />
                            </div>
                            <div className="comment_list">
                              <div>
                                <img src={box.avtar} alt="dropshipman" />
                              </div>
                              <div>
                                <div
                                  style={{
                                    textAlign: "left",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {box.author}
                                </div>
                                <div style={{ textAlign: "left" }}>
                                  {box.shopify_store}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </Carousel>
                </div>
              </Card>
            </section>
            <section className="mg_content">
              <Card>
                <div className="subscript_faq">
                  <h3>{intl.get("Frequently Questions")}</h3>
                  <div className="subscript_faq_content">
                    <div className="subscript_faq_list">
                      <BlockStack gap={400}>
                        {subFaq.list1.map((item, ind) => (
                          <Card key={ind}>
                            <div className="subscript_faq_item">
                              <div
                                className="subscript_faq_item_title"
                                onClick={() => handleToggleFaq(ind, "list1")}
                              >
                                <p>{item.title}</p>
                                {item.show ? (
                                  <img src={item.slide_up} alt="dropshipman" />
                                ) : (
                                  <img
                                    src={item.slide_down}
                                    alt="dropshipman"
                                  />
                                )}
                              </div>
                              <Collapsible
                                open={item.show}
                                id="basic-collapsible"
                                transition={{
                                  duration: "500ms",
                                  timingFunction: "ease-in-out",
                                }}
                                expandOnPrint
                              >
                                <p className="subscript_faq_item_desc">
                                  {item.desc}
                                </p>
                              </Collapsible>
                            </div>
                          </Card>
                        ))}
                      </BlockStack>
                    </div>
                    <div className="subscript_faq_list">
                      {subFaq.list2.map((item, ind) => (
                        <Card key={ind}>
                          <div className="subscript_faq_item">
                            <div
                              className="subscript_faq_item_title"
                              onClick={() => handleToggleFaq(ind, "list2")}
                            >
                              <p>{item.title}</p>
                              {item.show ? (
                                <img src={item.slide_up} alt="dropshipman" />
                              ) : (
                                <img src={item.slide_down} alt="dropshipman" />
                              )}
                            </div>
                            <Collapsible
                              open={item.show}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <p className="subscript_faq_item_desc">
                                {item.desc}
                              </p>
                            </Collapsible>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            </section>
          </BlockStack>
          <AdditionalBenefitsModal
            upgradeOpen={upgradeOpen}
            setUpgradeOpen={() => setUpgradeOpen(false)}
          />
          <FeedbackOptionModal
            upgradeOpen={feedOptionModal}
            setUpgradeOpen={() => setFeedOptionModal(false)}
            handleMageUpgrade={handleMageUpgrade}
          />
        </Layout.Section>
      </Layout>
      <PersonalAgentModal
        premiumData={premiumData}
        handlePremiumOff={handlePremiumOff}
        handleMageUpgrade={handleMageUpgrade}
      />
      <ReminderPlanModal open={reminderModal} onClose={setReminderModal}/>
    </Page>
  );

  return (
    <div>
      {loading ? initSkeletonPage : pageContent}
      {/* 提示 */}
      {toastMarkup}
    </div>
  );
};
const contentList = [
  {
    id: 1,
    list: [
      {
        avtar: "/sub/1.jpeg",
        id: 1,
        symbal: "/sub/vector_icon.png",
        author: "Thi Le",
        shopify_store: "United States",
        dsc: "The app is helpful. I like the process of importing and improving store I use AliExpress for most products.",
        star: "/sub/frame_star.png",
      },
      {
        avtar: "/sub/2.jpg",
        id: 2,
        symbal: "/sub/vector_icon.png",
        author: "Sara",
        shopify_store: "United Kingdom",
        dsc: "I am new to Dropshipman and was not sure what to do about editing an order. I used the app to do a size change on a order, and Angel was very helpful.",
        star: "/sub/frame_star.png",
      },
      {
        avtar: "/sub/3.jpeg",
        id: 3,
        symbal: "/sub/vector_icon.png",
        author: "Craig",
        shopify_store: "Germany",
        dsc: "I use the app to help me list items on aliexpress and also fulfill. Great app, customer service is also good.",
        star: "/sub/frame_star.png",
      },
    ],
  },
  {
    id: 2,
    list: [
      {
        avtar: "/sub/4.jpg",
        id: 4,
        symbal: "/sub/vector_icon.png",
        author: "Patrick",
        shopify_store: "United States",
        dsc: "This app is very helpful and helps me with finding the most popular items on the market to build my store.",
        star: "/sub/frame_star.png",
      },
      {
        avtar: "/sub/5.jpg",
        id: 5,
        symbal: "/sub/vector_icon.png",
        author: "Sophia",
        shopify_store: "United States",
        dsc: "This app is taking my online store to the next level! I am able to Set up my product just how I need them for my store, very quickly. This is a great experience!",
        star: "/sub/frame_star.png",
      },
      {
        avtar: "/sub/6.jpg",
        id: 6,
        symbal: "/sub/vector_icon.png",
        author: "Amelia",
        shopify_store: "Australia",
        dsc: "The App is very easy to use and functional. I installed it recently and have just started using it on my store.",
        star: "/sub/frame_star.png",
      },
    ],
  },
  {
    id: 3,
    list: [
      {
        avtar: "/sub/7.jpg",
        id: 7,
        symbal: "/sub/vector_icon.png",
        author: "Alexandre",
        shopify_store: "France",
        dsc: '"Easy-to-use! Instant chat-support available, very friendly and helpful. Will continue using DSM and can recommend it."',
        star: "/sub/frame_star.png",
      },
      {
        avtar: "/sub/8.jpg",
        id: 8,
        symbal: "/sub/vector_icon.png",
        author: "Flavio",
        shopify_store: "Italy",
        dsc: '"I used this app to transport or to import products to my shopify store, this app is really helpful in importing products to your store and the customer service are good"',
        star: "/sub/frame_star.png",
      },
      {
        avtar: "/sub/9.jpg",
        id: 9,
        symbal: "/sub/vector_icon.png",
        author: "Eshana",
        shopify_store: "India",
        dsc: "I love the app and its easy and simple to use. tried many i am really comfortable with the app and its team also amazing.",
        star: "/sub/frame_star.png",
      },
    ],
  },
  {
    id: 4,
    list: [
      {
        avtar: "/sub/10.jpg",
        id: 10,
        author: "Benjamin",
        symbal: "/sub/vector_icon.png",
        shopify_store: "United States",
        dsc: "I used this app to help me source and fulfill products and I love it so much. The customer service is also super fast and friendly!",
        star: "/sub/frame_star.png",
      },
      {
        avtar: "/sub/11.jpg",
        id: 11,
        symbal: "/sub/vector_icon.png",
        author: "Stefan",
        shopify_store: "Germany",
        dsc: "I love this app. Very good app no slow actions just a solid app would recommend anytime glad I found it.",
        star: "/sub/frame_star.png",
      },
      {
        avtar: "/sub/12.jpg",
        id: 12,
        symbal: "/sub/vector_icon.png",
        author: "Russell",
        shopify_store: "United States",
        dsc: "I used this app to help me to create a business. Dropshipping as well I was helped by a wonderful staff member to start my own business.",
        star: "/sub/frame_star.png",
      },
    ],
  },
];
// const codeData = {
//     "code": "200",
//     "msg": "success",
//     "data": {
//         "level": 0,
//         "bill_level": 0,
//         "bill_time": 0,
//         "trial_days": 7,
//         "trial_time": 0,
//         "package": [
//             {
//                 "name": "FREE",
//                 "money": 0,
//                 "discount": 1,
//                 "discount_start": 0,
//                 "discount_time": 0,
//                 "limitation": {
//                     "sourcing_num": 3,
//                     "ali_product_num": 10,
//                     "dsm_product_num": 10,
//                     "temu_product_num": 10,
//                     "coupon_num": 0,
//                     "salesman": false
//                 }
//             },
//             {
//                 "name": "PRO",
//                 "money": 29.9,
//                 "discount": 0.7,
//                 "discount_start": 0,
//                 "discount_time": 0,
//                 "limitation": {
//                     "sourcing_num": 30,
//                     "ali_product_num": 1000,
//                     "dsm_product_num": 1000,
//                     "temu_product_num": 1000,
//                     "coupon_num": 0,
//                     "salesman": true
//                 }
//             },
//             {
//                 "name": "PLUS",
//                 "money": 49.9,
//                 "discount": 0.7,
//                 "discount_start": 0,
//                 "discount_time": 0,
//                 "limitation": {
//                     "sourcing_num": 60,
//                     "ali_product_num": 5000,
//                     "dsm_product_num": 5000,
//                     "temu_product_num": 5000,
//                     "coupon_num": 5,
//                     "salesman": true
//                 }
//             },
//             {
//                 "name": "BASIC",
//                 "money": 9.9,
//                 "discount": 1,
//                 "discount_start": 0,
//                 "discount_time": 0,
//                 "limitation": {
//                     "sourcing_num": 10,
//                     "ali_product_num": 300,
//                     "dsm_product_num": 300,
//                     "temu_product_num": 300,
//                     "coupon_num": 0,
//                     "salesman": true
//                 }
//             },
//             {
//                 "name": "PREMIUM",
//                 "money": 199,
//                 "discount": 0.7,
//                 "discount_start": 0,
//                 "discount_time": 0,
//                 "limitation": {
//                     "sourcing_num": 300,
//                     "ali_product_num": 100000,
//                     "dsm_product_num": 100000,
//                     "temu_product_num": 100000,
//                     "coupon_num": 5,
//                     "salesman": true
//                 }
//             }
//         ],
//         "first_purchase": true,
//         "is_zt": false,
//         "user_discounts": {
//             "event_discounts": '',
//             "event_active_time": '',
//             "event_plus_discounts": 0.8,
//             "plus_active_time": 1736914261,
//             "event_basic_discounts": 0.8,
//             "basic_active_time":1736914261,
//             "event_premium_discounts": 0.8,
//             "premium_active_time": 1736914261
//         },
//         "agent_list": []
//     }
// };
// const codeData2 = {
//   "code": "200",
//   "msg": "success",
//   "data": {
//       "level": 0,
//       "bill_level": 0,
//       "bill_time": 0,
//       "trial_days": 7,
//       "trial_time": 0,
//       "package": [
//           {
//               "name": "FREE",
//               "money": 0,
//               "discount": 1,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 3,
//                   "ali_product_num": 10,
//                   "dsm_product_num": 10,
//                   "temu_product_num": 10,
//                   "coupon_num": 0,
//                   "salesman": false
//               }
//           },
//           {
//               "name": "PRO",
//               "money": 29.9,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 30,
//                   "ali_product_num": 1000,
//                   "dsm_product_num": 1000,
//                   "temu_product_num": 1000,
//                   "coupon_num": 0,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "PLUS",
//               "money": 49.9,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 60,
//                   "ali_product_num": 5000,
//                   "dsm_product_num": 5000,
//                   "temu_product_num": 5000,
//                   "coupon_num": 5,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "BASIC",
//               "money": 9.9,
//               "discount": 1,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 10,
//                   "ali_product_num": 300,
//                   "dsm_product_num": 300,
//                   "temu_product_num": 300,
//                   "coupon_num": 0,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "PREMIUM",
//               "money": 199,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 300,
//                   "ali_product_num": 100000,
//                   "dsm_product_num": 100000,
//                   "temu_product_num": 100000,
//                   "coupon_num": 5,
//                   "salesman": true
//               }
//           }
//       ],
//       "first_purchase": true,
//       "is_zt": false,
//       "user_discounts": {
//           "event_discounts": 0.7,
//           "event_active_time": 1737423776,
//           "event_plus_discounts": 0.7,
//           "plus_active_time": 1737423776,
//           "event_basic_discounts": '',
//           "basic_active_time": '',
//           "event_premium_discounts": 0.5,
//           "premium_active_time": 1737423776
//       },
//       "agent_list": [
//           {
//               "record_id": 21,
//               "name": "Alice Huang",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-16\/1723776339-22b99d484ee2ea4d5d41676b5397c369.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Professional",
//                   "Supportive"
//               ]
//           },
//           {
//               "record_id": 22,
//               "name": "Jenny Lin",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-13\/1723541851-18801030041b67ba4a09ad2387af21f9.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Experienced",
//                   "Trustworthy"
//               ]
//           },
//           {
//               "record_id": 24,
//               "name": "Sphoia Liu",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-11-20\/1732087667-c76ed97c1e228856ec2bb89fb2ac9080.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Reliable",
//                   "Attentive"
//               ]
//           },
//           {
//               "record_id": 26,
//               "name": "Amy Zhang",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-13\/1723541472-f557a5dd26c593311db6be5eac619689.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Dedicated",
//                   "Patient"
//               ]
//           }
//       ]
//   }
// }
export default connect()(SubscriptionPaidClass);
