import {Button, Modal} from "@shopify/polaris";
import React from "react";
import intl from "../../../../i18n/intl";
import { useSelector } from "react-redux";
const UpgradePlanModal = ({upgradeOpen,setUpgradeOpen,type}) => {
  const person = useSelector((state) => state.languageReducer?.subScriptData);
    const handlePageStatistics = () => {
      try {
        // 关闭弹窗
        setUpgradeOpen();
        // 跳转到pricing页面
        let sendMsg =
      `Hello, I have subscribed to ${Number(person.bill_level) === 5 ? 'PREMIUM' : 'PLUS'} plan and I would like to activate the $200 extra benefits.`;

      // 填充信息并激活对话弹窗
      // window.Intercom('showNewMessage', sendMsg)
      window.Willdesk.show();
      window.Willdesk.chat(sendMsg);
      } catch (error) {
        console.log(error);
      }
  }
  const addExtra = [
    {
      img:'/sub/image2.png',
      title:'LL: Loyalty Rewards Referrals',
      dsc:'Boost sales with loyalty rewards & referral programs.',
      price:'$ 0',
      oldPrice:'$ 69'
    },
    {
      img:'/sub/image_seo.png',
      title:'SEOAnt ‑ AI SEO Optimizer',
      dsc:'SEO booster with page speed, AI writer',
      price:'$ 0',
      oldPrice:'$ 30'
    },
    {
      img:'/sub/image_willdesk.png',
      title:'WD‑Live Chat, Helpdesk,Chatbot',
      dsc:'Drive sales with Livechat,Help Center',
      price:'$ 0',
      oldPrice:'$ 30'
    },
    {
      img:'/sub/image_rs.png',
      title:'RecomSale: Referral & Affiliate',
      dsc:'Boost your sales with affiliate, referral ',
      price:'$ 0',
      oldPrice:'$ 30'
    },
    {
      img:'/sub/image1.png',
      title:'Trustoo Product & Ali Reviews',
      dsc:'Import products reviews from Ali and Amazon',
      price:'$ 0',
      oldPrice:'$ 20'
    },
    {
      img:'/sub/image_pp.png',
      title:'Parcel Panel Returns &Exchange',
      dsc:'Manage the return process, improve customer satisfaction, and recapture revenue.',
      price:'$ 0',
      oldPrice:'$ 20'
    },
    {
      img:'/sub/image_csp.png',
      title:'Captain Shipping Protection',
      dsc:'Boost revenue with shipping protection',
      price:'$ 0',
      oldPrice:'$ 11'
    },
    
  ]
  return (
    <div className="upgrade_new_feature">
      <Modal
        size='large'
        open={upgradeOpen}
        onClose={() => setUpgradeOpen()}
      >
        <Modal.Section>
        <div className="additional_benefits_modal">
        <div className="additional_benefits_modal_head">
          <img src="/sub/big_gift.png" alt="dropshipman"/>
          <p><strong>$200</strong>{intl.get("extra benefits")}</p>
        </div>
        <div className="additional_benefits_modal_dsc">{type && type === 2  ? intl.get(`You have been granted one month of free access to the following application. Please activate your access.`) : intl.get(`By subscribing to the PLUS package, you can also enjoy a one-month free trial of all the following applications.`)}</div>
        <div className="additional_benefits_modal_content">
        {addExtra.map((item,ind)=><div key={ind} className="additional_benefits_modal_content_item">
        <div className="additional_benefits_modal_content_left">
          <img src={item.img} alt="dropshipman" />
        </div>
        <div className="additional_benefits_modal_content_right">
          <div className="additional_benefits_modal_title">{item.title}</div>
          <p className="additional_benefits_modal_ccontent_dsc">{item.dsc}</p>
          <div className="additional_benefits_modal_price">
            <span className="additional_benefits_modal_new_price">{item.price}</span>
            <span className="additional_benefits_modal_old_price">{item.oldPrice}</span>
          </div>
        </div>
        </div>)}
        </div>
        {type && type === 2 && <div className="feed_option_button additon_option_button">
          <Button onClick={() => setUpgradeOpen()}>{intl.get("No,THANKS")}</Button>
          <Button variant='primary' onClick={()=>handlePageStatistics()}>{intl.get("ACTIVATE")}</Button>
        </div>}
        </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default UpgradePlanModal;
