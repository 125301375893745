import { Box} from '@shopify/polaris';
import React from 'react';
import OrderItm from './OrderItm';

const ListItemOne  = ({listData,handleSaveData,setPageData,handlePaymentButton})=> {
    return (
        <div>
          <Box className="order_packing_list">
          <OrderItm listData={listData} setPageData={setPageData} handleSaveData={handleSaveData} handlePaymentButton={handlePaymentButton}/>
          </Box>
        </div>
    );
}

export default ListItemOne;
