import React from "react";

const Index = (props) => {
  let color = "#616161";
  let bgColor = "#0000000f";
  switch (props.content) {
    case "Paid":
      color = "rgba(8, 109, 84, 1)"
      bgColor = "rgba(180, 255, 168, 1)";
      break;
    case "Awaiting Shipment":
      color = "rgba(0, 82, 124, 1)"
      bgColor = "rgba(224, 240, 255, 1)";
      break;
    case "Fulfilled":
      color = "rgba(8, 109, 84, 1)"
      bgColor = "rgba(180, 255, 168, 1)";
      break;
    case "Awating Collection":
      color = "rgba(0, 82, 124, 1)"
      bgColor = "rgba(224, 240, 255, 1)";
      break;
    case "In Processing":
      color = "rgba(0, 82, 124, 1)"
      bgColor = "rgba(224, 240, 255, 1)";
      break;
    case "In processing":
      color = "rgba(0, 82, 124, 1)"
      bgColor = "rgba(224, 240, 255, 1)";
      break;
    case "Cancelled":
      color = "rgba(97, 97, 97, 1)"
      bgColor = "rgba(217, 217, 217, 1)";
      break;
    case "Refunded":
      color = "rgba(142, 31, 11, 1)"
      bgColor = "rgba(255, 208, 208, 1)";
      break;
    case "Refund":
      color = "rgba(142, 31, 11, 1)"
      bgColor = "rgba(255, 208, 208, 1)";
      break;
    case "Shipped":
      color = "rgba(0, 82, 124, 1)"
      bgColor = "rgba(224, 240, 255, 1)";
      break;
    case "Delivered":
      color = "rgba(0, 82, 124, 1)"
      bgColor = "rgba(224, 240, 255, 1)";
      break;
    case "Partial Refunded":
      color = "rgba(142, 31, 11, 1)"
      bgColor = "rgba(255, 208, 208, 1)";
      break;
    case "Partially refund":
      color = "rgba(142, 31, 11, 1)"
      bgColor = "rgba(255, 208, 208, 1)";
      break;
    case "Partially paid":
      color = "rgba(0, 0, 0, 1)"
      bgColor = "rgba(254, 236, 141, 1)";
      break;
    case "Cancel":
      color = "rgba(142, 31, 11, 1)"
      bgColor = "rgba(255, 208, 208, 1)";
      break;
    case "Partial Refund":
      color = "rgba(142, 31, 11, 1)"
      bgColor = "rgba(255, 208, 208, 1)";
      break;
    case "Not Listed":
      color = "#00527c"
      bgColor = "#e0f0ff";
      break;
    case "Not_listed":
      color = "#00527c"
      bgColor = "#e0f0ff";
      break;
    case "Listed":
      color = "#616161";
      bgColor = "#0000000f";
      break;
    case "For Sale":
      color = "#202333";
      bgColor = "rgba(205, 254, 225, 1)";
      break;
    case "On Selling":
      color = "#616161";
      bgColor = "#0000000f";
      break;
    case "Deleted":
      color = "#202333"
      bgColor = "rgb(255, 229, 134)";
      break;
    case "Unfulfilled":
      color = "rgba(0, 82, 124, 1)"
      bgColor = "rgba(224, 240, 255, 1)";
      break;  
    default:
      break;
  }

  return (
    <span
      style={{
        display: "inline-flex",
        maxWidth: "100%",
        minWidth: "68px",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "16px",
        padding: "2px 8px",
        backgroundColor:props.bgColor ? props.bgColor : bgColor,
        borderRadius: "16px",
        color:props.color ? props.color : color,
        outline: " 1px solid transparent",
      }}
    >
      {props.content}
    </span>
  );
};

export default Index;
