import {
  ActionList,
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Collapsible,
  Popover,
  Toast,
  Tooltip,
} from "@shopify/polaris";
import React, { useState } from "react";
import PreviewImage from "../../../../../components/PreviewImage";
import ToolTag from "../../../../../components/ToolTag";
import OrderShippingAddress from "../../../OrderList/components/Modal/ShippingAddress";
import OrderTracking from "../../../OrderList/components/Modal/OrderTracking";
import CancelRedemptionModal from "../Modal/CancelRedemptionModal";
import SymbolTake from "../../../../../components/SymbolTake";
import "./index.css";
import OrderNoteModal from "../../../../../components/OrderNoteModal";
import intl from "../../../../../i18n/intl";
import ShippingMethodsModal from "../../../OrderList/components/Modal/ShippingMethodsModal";
const OrderItm = ({
  listData,
  setPageData,
  handlePaymentButton,
  handleSaveData,
  getSearchParam,
  getOrderListData,
  setListLoading,
  getUserPoints
}) => {
  const [isShowShippingMethodsModal, setIsShowShippingMethodsModal] =
    useState(false); //记录物流方式弹窗是否显示
  const [orderId, setOrderId] = useState(1); //当前订单id
  const [orderPayStatus, setOrderPayStatus] = useState("");
  const [trackModal, setTrackModal] = useState(false); //记录物流轨迹弹窗是否显示

  const [previewData, setPreviewData] = useState({
    showPreview: false,
    previewIndex: 0,
    previewList: [],
  });
  const handleSelectImage = () => {};
  const handlePreview = (item, ind) => {
    const list = [
      {
        keyIndex: ind,
        src: item.image_url,
      },
    ];
    setPreviewData((prevValue) => ({
      ...prevValue,
      showPreview: true,
      previewIndex: ind,
      previewList: list,
    }));
  };
  const togglePopoverActive = (key) => {
    setPageData((prevValue) => {
      const lists = prevValue.list;
      for (let i = 0; i < lists.length; i++) {
        if (i === key && lists[i].number_show === 0) {
          lists[i].number_show = 1;
        } else {
          lists[i].number_show = 0;
        }
      }
      const params = { ...prevValue, list: lists };
      return params;
    });
  };

  // 总费用
  const total = (shippoing, vat) => {
    let count = parseFloat(shippoing) + parseFloat(vat);
    return count.toFixed(2);
  };

  // 单选
  const handleItemSelect = (val, id) => {
    let newList = listData.map((item, ind) => {
      if (item.dsm_record === id) {
        return { ...item, checked: val ? 1 : 0 };
      } else {
        return item;
      }
    });
    setPageData((prevValue) => ({
      ...prevValue,
      list: newList,
    })); //回调存储
  };

  const [itemData, setItemData] = useState({
    currentOrderId: "",
    orderNote: "",
    open: false,
  });
  // 备注操作
  const handleOrderNote = (id, note) => {
    setItemData((prevValue) => ({
      ...prevValue,
      currentOrderId: id,
      orderNote: note,
      open: true,
    }));
  };
  const handlePreOrderOpen = (id) => {
    let newList = listData.map((item, ind) => {
      if (item.dsm_record === id) {
        return { ...item, open: !item.open };
      } else {
        return item;
      }
    });
    setPageData((prevValue) => ({
      ...prevValue,
      list: newList,
    })); //回调存储
  };

  const [removeData, setRemoveData] = useState({
    open: false,
    id: "",
    loading: false,
  });
  // 取消积分订单
  const handleCancelRedemption = (id) => {
    setRemoveData((prevValue) => ({
      ...prevValue,
      open: true,
      id: id,
    }));
  };
  // 物流追踪弹窗
  const handleShowTracking = () => {
    setTrackModal(!trackModal);
  };

  const trackFun = (item) => {
    const track = [];
    for (let i = 0; i < item.tracking_numbers.length; i++) {
      track.push({
        content: item.tracking_numbers[i],
        url:
          "https://www.trackingmore.com/track/en/" +
          item.tracking_numbers[i] +
          "?source=dsm",
        external: true,
      });
    }
    return track;
  };
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
      duration={2000}
    />
  ) : null;
  return (
    <Box className="order_item_content">
      <BlockStack gap="500">
        {listData.map((item, ind) => (
          <Card key={ind}>
            <Box className="order_item_list">
              <Box className="order_item_list_box">
                <Box className="order_item_checkbox">
                  <Checkbox
                    checked={item.checked}
                    disabled={item.dsm_payment_status !== 'Partially paid'}
                    onChange={(val) => {
                      handleItemSelect(val, item.dsm_record);
                    }}
                  />
                </Box>
                <Box className="order_item_brand">
                  <Box className="order_item_brand_box">
                    <Box
                      className="order_item_brand_id"
                      onClick={() => handlePreOrderOpen(item.dsm_record)}
                    >
                      <img
                        width={20}
                        src={"/dropshipman-logo.png"}
                        alt="Dropshipman"
                      />
                      <span className="Polaris-Link">
                        {item?.dsm_order_name}
                      </span>
                    </Box>
                    <Box className="order_item_brand_date">
                      {item?.dsm_date}
                    </Box>
                    <Box className="order_item_brand_status">
                      {item?.dsm_payment_status !== "" && (
                        <ToolTag
                          content={item?.dsm_payment_status}
                        />
                      )}
                      {item?.dsm_shipment_status !== "" && (
                        <ToolTag content={item?.dsm_shipment_status} />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box className="order_item_btn_redeem">
                <Box
                  className="order_item_customer"
                  style={{ position: "relative" }}
                >
                  <div className="customer_btn">
                    <div
                      className="customer_warring"
                      style={{ display: "none" }}
                    >
                      <img src="/archived_warring.png" alt="" />
                    </div>
                    <div className="order_customer">
                      <OrderShippingAddress
                        id={item.dsm_record}
                        sid={item?.shopify_record}
                        type={item?.type}
                        countryCode={item?.country_code}
                        paymentStatus={item?.dsm_payment_status}
                        addressStatus={item?.address_failed}
                        getSearchParam={getSearchParam}
                        reloadData={getOrderListData}
                        setListLoading={setListLoading}
                      />
                    </div>
                  </div>
                </Box>
                <Box className="order_item_shipping order_item_shipping_redeem">
                    <Button
                      textAlign="left"
                      disclosure={"down"}
                      onClick={() => {
                        setIsShowShippingMethodsModal(true);
                        setOrderId(item.dsm_record);
                        setOrderPayStatus(item.dsm_payment_status);
                      }}
                    >
                      {item.dsm_shipping !== ""
                        ? item.dsm_shipping
                        : intl.get("Shipping methods")}
                    </Button>
                </Box>
                </Box>
                <Box className="order_item_button">
                  {(item.dsm_payment_status === "Paid" || item.dsm_payment_status === "Partially refund") && <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTrackModal(true);
                      setOrderId(item.dsm_record);
                    }}
                  >
                    <img
                      src="/order_tracking.png"
                      alt="dropshipman"
                      style={{
                        width: "42px",
                        height: "30px",
                        marginTop: "6px",
                      }}
                    />
                  </div>}
                  <div
                    onClick={() => {
                      handleOrderNote(item.dsm_record, item.dsm_note);
                    }}
                  >
                    <img
                      src={"/flow.png"}
                      alt="dropshipman"
                      height={28}
                      style={{ marginTop: "4px", cursor: "pointer" }}
                    />
                  </div>
                </Box>
              </Box>
              <Box className={item?.open && "order_item_collapsible"}>
                <Collapsible
                  open={item.open}
                  id="basic-collapsible"
                  transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                  }}
                  expandOnPrint
                >
                  <Box className={"order_item_collapsible_box"}>
                    {item?.order_detail?.dsm?.length > 0 &&
                      item?.order_detail?.dsm?.map((it, k) => (
                        <Box
                          key={k}
                          className="order_item_collapsible_box_item"
                        >
                          <Box className="collapsible_box_item_img">
                            <div
                              className="image_box_overlay "
                              onClick={() => handleSelectImage(it)}
                            >
                              <div className="image_preview_action">
                                <div
                                  className="image_preview__svg"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handlePreview(it, k);
                                  }}
                                >
                                  <svg
                                    viewBox="64 64 896 896"
                                    data-icon="eye"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    focusable="false"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                                    ></path>
                                  </svg>
                                </div>
                                <img
                                  src={it.image_url}
                                  className="list_item_image"
                                  alt="product"
                                />
                              </div>
                            </div>
                          </Box>
                          <Box className="collapsible_box_item_title">
                            <Box title={it?.title}>{it?.title}</Box>
                            <Box>{it?.variant_title}</Box>
                          </Box>
                          <Box className="collapsible_box_item_cost">
                            Cost: ${Number(it.price)} x {Number(it.quantity)}
                          </Box>
                          <Box className="collapsible_box_item_total">
                            Total: <SymbolTake price={(Number(it.price) * Number(it.quantity)).toFixed(
                              2
                            )} />
                            {/* $
                            {(Number(it.price) * Number(it.quantity)).toFixed(
                              2
                            )} */}
                          </Box>
                          <Box
                            className="collapsible_box_item_refund"
                            style={{ marginRight: "6px" }}
                          >
                            <Box style={{ marginTop: "5px" }}>
                              {it.dsm_refund_count > 0 && (
                                <Tooltip
                                  dismissOnMouseOut
                                  content={
                                    it.quantity > it.dsm_refund_count
                                      ? `Partially refund (DSM): ${it.dsm_refund_count}`
                                      : "Refund (DSM)"
                                  }
                                >
                                  <Badge tone={"critical"}>
                                    {it.quantity > it.dsm_refund_count
                                      ? `Partially refund (DSM): ${it.dsm_refund_count}`
                                      : "Refund (DSM)"}
                                  </Badge>
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                  <Box className="collapsible_box_item_bottom">
                    <Box className="collapsible_box_item_bottom_clo">
                      <strong>Product cost:</strong> 
                      <SymbolTake price={item?.product_cost} />
                      {/* ${item?.product_cost} */}
                    </Box>
                    <Box className="collapsible_box_item_bottom_clo">
                      <strong>Shipping cost:</strong> 
                      <SymbolTake price={item?.shipping_cost} />
                      {/* ${item?.shipping_cost} */}
                    </Box>
                    <Box className="collapsible_box_item_bottom_clo">
                      <strong>VAT:</strong> 
                      <SymbolTake price={item?.ioss_vat} />
                      {/* ${item?.ioss_vat} */}
                    </Box>
                    <Box className="collapsible_box_item_bottom_clo">
                      <strong>Total cost:</strong> 
                      <SymbolTake price={total(item?.shipping_cost, item.ioss_vat)} />
                      {/* $
                      {total(item?.shipping_cost, item.ioss_vat)} */}
                    </Box>
                    <Box className="collapsible_box_item_bottom_clo">
                      <div className="order_tracking_no">
                        <span>{intl.get("Tracking No")}:</span>
                        {item.tracking_numbers.length > 0 && (
                          <div style={{ marginLeft: "5px" }}>
                            <Popover
                              active={item.number_show === 1}
                              activator={
                                <Button
                                  variant="plain"
                                  onClick={() => togglePopoverActive(ind)}
                                  disclosure={
                                    item.number_show === 1 ? "up" : "down"
                                  }
                                >
                                  {item.tracking_numbers[0]}
                                </Button>
                              }
                              autofocusTarget="first-node"
                              onClose={() => togglePopoverActive(ind)}
                            >
                              <ActionList
                                actionRole="menuitem"
                                items={trackFun(item)}
                              />
                            </Popover>
                          </div>
                        )}
                      </div>
                    </Box>

                    <Box className="collapsible_box_item_bottom_clo_button">
                      {(item.dsm_payment_status === "Unpaid" ||
                        item.dsm_payment_status === "Partially paid") && (
                        <Box style={{marginRight:10}}>
                          <Button
                            onClick={() =>
                              handleCancelRedemption(item.dsm_record)
                            }
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}

                      {(item.dsm_payment_status === "Unpaid" ||
                        item.dsm_payment_status === "Partially paid") && (
                        <Box>
                          <Button
                            variant="primary"
                            onClick={() =>
                              handlePaymentButton([item.dsm_record])
                            }
                          >
                            Payment
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Collapsible>
              </Box>
            </Box>
          </Card>
        ))}
      </BlockStack>
      <PreviewImage
        isShow={previewData.showPreview}
        index={previewData.previewIndex}
        imageList={previewData.previewList}
        onClose={() => {
          setPreviewData((prevValue) => ({ ...prevValue, showPreview: false }));
        }}
      />

      {/* 订单备注弹窗 */}
      <OrderNoteModal
        itemData={itemData}
        content={listData}
        setPageData={setPageData}
        setOrderNote={setItemData}
      />
      {/* 物流方式弹窗 */}
      <ShippingMethodsModal
        orderId={orderId}
        getSearchParam={getSearchParam}
        getOrderListData={getOrderListData}
        open={isShowShippingMethodsModal}
        orderPayStatus={orderPayStatus}
        setIsShowShippingMethodsModal={() =>
          setIsShowShippingMethodsModal(false)
        }
      />

      {/* sku移除弹窗 */}
      {/* <CancelSkuModal
          currentOrderData={currentOrderData}
          getOrderListData={getListData}
          setCurrentOrderData={this.setCurrentOrderData}
          skuCount={2} 
        /> */}
      {/* 物流追踪弹窗 */}
      <OrderTracking
        show={trackModal}
        orderId={orderId}
        handleShowTracking={handleShowTracking}
      />
      {/* 取消积分订单 */}
      <CancelRedemptionModal
        removeData={removeData}
        setRemoveData={setRemoveData}
        resetCallback={() => {
          getOrderListData(getSearchParam);
          getUserPoints()
        }}
      />
      {/* 退款详情弹窗 销售绑定信息 */}
      {/* <RefundTipsModal
          open={isShowRefundTipsModal}
          datas={currentOrderData.data}
          setIsShowRefundTipsModal={() => this.setIsShowRefundTipsModal(false)}
        /> */}
      {toastMarkup}
    </Box>
  );
};

export default OrderItm;
