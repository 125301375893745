import { BlockStack, Box, Modal, TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
function addAndRound(values) {
  let sum = values.reduce((acc, val) => acc + (parseFloat(val) || 0), 0); // 累加并处理空值或非数字
  return sum.toFixed(2); // 返回保留两位小数的结果
}
const calculateProfit = (salePriceData) => {
  // 转换为浮点数并设置默认值
  const sellingPrice = parseFloat(salePriceData.selling_price) || 0;
  const cost = parseFloat(salePriceData.cost) || 0;
  const shippingFee = parseFloat(salePriceData.shipping_fee) || 0;

  // 计算结果并保留两位小数
  const profit = sellingPrice - cost - shippingFee;
  return profit.toFixed(2);
};
const Index = ({ 
  salePriceData,
  setSalePriceData,
  selectedResources,
  updateData,
  detailData
}) => {
  const [selllingValue,setSellingValue] = useState('');// selling price
  const [profitValue,setProfitValue] = useState('');// profit
  const handleClose = () => {
    setSellingValue('')
      setSalePriceData((prev)=>({
        ...prev,
        open: false,
        id: "",
        attr: "",
        shipping_fee: "",
        cost: "",
        selling_price: "",
        samrt_price: "",
        }))
    };
    useEffect(()=>{
      let isMount =false;
      if (!isMount) {
        setSellingValue(salePriceData.selling_price)
        let profit =  calculateProfit(salePriceData)
        setProfitValue(profit)
      }
    },[salePriceData])
   
    const handleChange = (value,type) => {
      if (value?.length > 15) {
          return;
      }
      const regex = /^$|^\d+(\.\d{0,2})?$/;

      // 仅允许符合正则的值更新状态
      if (!(regex.test(value) || value === "")) {
        return
      }
      
     
      if (salePriceData?.selling_price !== '') {
        if (type === 'selling') {
          setSellingValue(value)
          const profit = calculateProfit({selling_price:value , cost:salePriceData.cost , shipping_fee:salePriceData.shipping_fee});
          setProfitValue(profit)
      } else {
        const selling_price = addAndRound([value , salePriceData.cost , salePriceData.shipping_fee]);
        setSellingValue(selling_price)
        setProfitValue(value)
      }
      }else {
        if (type === 'selling') {
          setSellingValue(value)
      } else {
          setProfitValue(value)
      }
      }
      
    };
    const handleSubmit = () => {
      if (selllingValue === '') {
        return
      }
      let ids = salePriceData?.id !== '' ? [salePriceData?.id] : selectedResources;
      updateData(ids,{price:selllingValue})
      handleClose()
    };
    const handleAllSubmit = () => {
      if (selllingValue === '') {
        return
      }
      updateData('all',{price:selllingValue})
      handleClose()
  };
  const calculateProfitPercentage = (profitValue, salePriceData) => {
    // 转换为浮点数，空值或无效值默认转换为 0
    const cost = parseFloat(salePriceData?.cost) || 0;
    const shippingFee = parseFloat(salePriceData?.shipping_fee) || 0;
    const profit = parseFloat(profitValue) || 0;
  
    const totalCost = cost + shippingFee;
  
    // 避免除以 0 的情况
    if (totalCost === 0) return 0;
  
    // 计算利润百分比并取整
    return Math.round((profit / totalCost) * 100);
  };
  return (
    <div>
      <Modal
        open={salePriceData.open}
        onClose={handleClose}
        title="Change selling price"
        secondaryActions={{
          onAction: () => {
            console.log('Secondary action clicked',salePriceData);
            
            if (salePriceData?.id !== '') {
              handleAllSubmit()
            }else {
              handleClose()
            }
          },
          content: salePriceData?.id !== '' ? "Update all variants" : 'Cancel',
        }}
        primaryAction={{
          onAction: () => {
            handleSubmit()
          },
          content:salePriceData?.id !== '' ? "Update this variant" : 'Apply',
        }}
      >
        <Modal.Section>
          <Box>
        <TextField
        prefix={detailData.store.symbol}
        label="Selling price"
        value={selllingValue}
        onChange={(value)=>{handleChange(value,'selling')}}
        onBlur={()=>{
          if (selllingValue === '') {
            handleChange(0,'selling')
          }
        }}
        autoComplete="off"
        />
         {salePriceData.id !== '' && <Box style={{margin:'8px 0'}}><TextField
        prefix={detailData.store.symbol}
        label="Profit"
        value={profitValue}
        onChange={(value)=>{handleChange(value,'profit')}}
        onBlur={()=>{
          if (profitValue === '') {
            handleChange(0,'profit')
          }
        }}
        autoComplete="off"
        /></Box>}
        <Box>
          <BlockStack gap={200}>
        {salePriceData.id !== '' && <Box>Expected profit: {detailData.store.symbol} {profitValue} ({calculateProfitPercentage(profitValue,salePriceData)}%)</Box>}
        {salePriceData.id !== '' && <Box>Smart pricing recommendation: {detailData.store.symbol} {salePriceData.samrt_price ? salePriceData.samrt_price : '-'}</Box>}
        {salePriceData.id !== '' && <Box>Cost: Product cost - {detailData.store.symbol}{salePriceData.cost} | Shipping fee - {detailData.store.symbol} {salePriceData.shipping_fee}</Box>}
        </BlockStack>
        </Box>
        </Box>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
