import React, { Component} from "react";
import {
     TopBar, Navigation, AppProvider, Frame,
} from "@shopify/polaris";
import translations from '@shopify/polaris/locales/en.json';

import { 
    ProductIcon
} from '@shopify/polaris-icons';

import './index.css'

import ListClass from './components/List';
import DetailClass from './components/Detail';

class ProductListClass extends Component {
    state = {
      currentPage: 'list',
      locationPathName: '/products',
      mobileNavigationActive: false,

      // 详情页面参数id
      singleId: '',

      // 当前分类
      categories: '',
    }

    //进入页面调用接口
  componentDidMount() {
    // title 设置
    document.title = 'DropshipMan Dropshipping';

    let currentPathName = window.location.pathname;
    currentPathName = currentPathName + window.location.search;

    if(currentPathName.indexOf("?") !== -1){
      let searchParamer = window.location.search;

      // 过滤其他参数传参
      if (currentPathName.indexOf("?cat=") === -1) {
        currentPathName = '/products';
        searchParamer = '';

        try {
          // 访问产品详情
          var currentUrl = window.location.href;
          currentUrl = currentUrl.replace(/(\?|#)[^'"]*/, '');
          if (!!(window.history && window.history.pushState)){
            window.history.pushState({}, 0, currentUrl);
          } 
        } catch (error) {
          // console.warn(error);
        }
      }

      this.setState({
        locationPathName: currentPathName,
        categories: searchParamer.replace('?cat=','')
      });

    } else {
      let arr = currentPathName.split("/");
      // 移除数组空值
      arr = arr.filter(Boolean);

      if (arr.length > 1) {
        this.setState({
          currentPage: 'detail',
          singleId: arr[1],
          locationPathName: '/products'
        });
      }
    }
  }

  // 页面切换 (子组件调用)
  handleChildFunction = (data) => {
    if(data !== '' && data !== undefined){
      this.setState({ 
        currentPage: data.type ? data.type : 'list',
        singleId: data.id ? data.id : 0,
      });

      // 显示 section
      if (data.cat) {
        this.setState({ locationPathName : '/products'})
      }

      // 显示产品详情
      if(data.id){
        try {
          // 访问产品详情
          var currentUrl = window.location.href;
          currentUrl = currentUrl.replace(/(\?|#)[^'"]*/, '');
          currentUrl = currentUrl + '/' + data.id;
          if (!!(window.history && window.history.pushState)){
            window.history.pushState({}, 0, currentUrl);
          } 
        } catch (error) {
          // console.warn(error);
        }
      }
      
    } else {
      this.setState({ currentPage: 'list' });
    }
  }

  setMobileNavigationActive =()=>{
    this.setState(({ mobileNavigationActive }) => ({ mobileNavigationActive: !mobileNavigationActive }));
  }

  toggleMobileNavigationActive =()=>{
    this.setMobileNavigationActive();
  }

  render() {

    const { currentPage,locationPathName, mobileNavigationActive, singleId,categories } = this.state;

    let pageContent = '';

    switch (currentPage) {
      case 'list':
        pageContent = <ListClass fatherFun={this.handleChildFunction} categories={window.location.search || categories} />;

        break;
      case 'detail':
        pageContent = <DetailClass fatherFun={this.handleChildFunction} id={singleId} />;
        break;
      default:
    }

    const theme = {
      logo: {
        width: 124,
        topBarSource: '/logo.png?v=1630662955',
        contextualSaveBarSource: '/logo.png?v=1630662955',
        url: 'https://www.dropshipman.com/',
        accessibilityLabel: 'DropShipMan AliExpress Dropshipping',
      }
    };

    let matchAll = false;
    let matchAmazonOutlet = false;
    // let matchAmazonHot = false;
    let matchBrazilWarehouse = false;
    let match1688 = false;
    let matchAliExpressHot = false;

    if(locationPathName === '/products'){
      matchAll = true;
    }else if(locationPathName === '/products?cat=us-amazon-outlet'){
      matchAmazonOutlet = true;
    // }else if(locationPathName === '/products?cat=us-amazon-hot'){
    //   matchAmazonHot = true;
    }else if(locationPathName === '/products?cat=brazil-warehouse'){
      matchBrazilWarehouse = true;
    }else if(locationPathName === '/products?cat=1688-popular-products'){
      match1688 = true;
    }else if(locationPathName === '/products?cat=aliexpress-hot-products'){
      matchAliExpressHot = true;
    } else{
      matchAll = true;
    }

    const navigationMarkup = (
      <Navigation location='/'>
        <Navigation.Section
          items={[
            {
              label: 'Categories',
              icon: ProductIcon,
              url: '/products',
              subNavigationItems:
                [
                  {
                    label: 'All',
                    url: '/products',
                    matches: matchAll
                  },
                  {
                    label: 'US Amazon Outlet',
                    url: '/products?cat=us-amazon-outlet',
                    matches: matchAmazonOutlet
                  },
                  // {
                  //   label: 'US Amazon hot sell',
                  //   url: '/products?cat=us-amazon-hot',
                  //   matches: matchAmazonHot
                  // },
                  {
                    label: 'Brazil warehouse',
                    url: '/products?cat=brazil-warehouse',
                    matches: matchBrazilWarehouse
                  },
                  {
                    label: '1688 Popular products',
                    url: '/products?cat=1688-popular-products',
                    matches: match1688
                  },
                  {
                    label: 'AliExpress hot products',
                    url: '/products?cat=aliexpress-hot-products',
                    matches: matchAliExpressHot
                  },
                ]
            },
          ]}
        />
      </Navigation>
    );

    const secondaryMenuMarkup = (
      <TopBar.Menu
        activatorContent={
          <div className="user_secondary_menu">
            <a
              className="Polaris-Button Polaris-Button--primary"
              href="https://apps.shopify.com/aliexpress-dropshipping-master?surface_detail=dropshipman&surface_inter_position=1&surface_intra_position=3&surface_type=search"
              target="_blank"
              rel="noopener noreferrer"
            >
               Start free on Shopify
            </a>
          </div>
        }
        open={false}
        onOpen={()=>{}}
        onClose={()=>{}}
      />
    );

    const topBarMarkup = (
      <TopBar
        showNavigationToggle
        // userMenu={userMenuMarkup}
        // searchResults={searchResultsMarkup}
        secondaryMenu={secondaryMenuMarkup}
        onNavigationToggle={this.toggleMobileNavigationActive}
      />
    );

    

    return (
      <AppProvider
        i18n={translations}
        theme={theme}
      >
        <Frame
          //头部
          topBar={topBarMarkup}
          //左边
          navigation={navigationMarkup}
          
          //手机端
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={this.toggleMobileNavigationActive}
          // skipToContentTarget={skipToContentRef.current}
        >

          {pageContent}

        </Frame>
      </AppProvider>
    )
  }
}

export default ProductListClass;