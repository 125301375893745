import { MD5 } from "crypto-js";
import React, { Component } from "react";
// import ChatPlatformInformation from '../ChatPlatformInformation';
import { connect } from 'react-redux'
import "./index.css";
import { store } from "../../store";

// let num = 0;

class ChatOnlinePlatformInit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fromApp: 'Dropshipman',
            shopOwner: '',
            shopifyDomain: '',
            userEmail: '',
            storeName: '',
            userLevel: 0,
            reviewStar: 'no review',
            shopifyStoreVersion: '',
            createdAt: '',
            lastLogin: '',
            useDays: '',
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    // 获取数据
    getInitData = () => {
        const response = store.getState().languageReducer.subScriptData;

        if (response) {
            let getData = response;
            // 用户默认语言等
            window.language = getData.language;
            window.S_SHOP_DOMAIN = getData.domain;
            window.S_USER_TOKEN  = getData.session;
            window.S_SHOP_OWNER  = getData.owner;
            window.S_EMAIL       = getData.email;
            window.S_STORE_NAME  = getData.store_name;

            this.setState({
                shopOwner: getData.owner,
                shopifyDomain: getData.domain,
                userEmail: getData.email,
                storeName: getData.store_name,
                userLevel: getData.level,
                reviewStar: getData.review,
                shopifyStoreVersion: getData.shopify_level,
                createdAt: getData.created_at,
                useDays: getData.use_days,
                lastLogin: getData.lastlogin_time,

            }, () => {
                this.ChatOnlineToolInit();
                this.NpsInit();
                this.props.refresh();
            });

            if (window.__bl && window.__bl.setConfig) {
                window.__bl.setConfig({
                  setUsername:function(){
                      return getData.domain
                  },
                  uid: getData.domain
                })
            }
        } else {
            this.setState({
                name: 'dropshipman_visitor',
                user_id: 'dropshipman_visitor'
            }, () => {
                this.ChatOnlineToolInit();
            })
        }
    }

    NpsInit = async () => {
        setTimeout(() => {
            try {
                const accessTime = Math.ceil(new Date().getTime() / 1000);
                const accessToken = 'FJMKRGFW1PWYZIZLKHDFQWV5Q01CQGDM6XE432QQ'
                const sign = MD5(accessToken + accessTime).toString();
                const bootData = { 
                    accessType: 'DSMAPP',
                    accessToken: sign,
                    accessTime: accessTime, 
                    shop: this.state.shopifyDomain,
                    planDisplayName: this.state.shopifyStoreVersion,
                    days: this.state.useDays,
                    installTime: this.state.createdAt,
                    language: window.language == null ? 'en' : window.language,
                    attribute: 'In-product.Pop-up.Dashboard',
                    lastTime: this.state.lastLogin,
                    // 样式可以写在这里
                    style: {}
                  }

                //   console.log(bootData)

                if (Object.prototype.toString.call(window.NPS)  === '[object Object]') {
                    window.NPS.boot(bootData)
                } else {
                    let load = (window.NPSLoad = false)
                    Object.defineProperty(window, 'NPSLoad', {
                        get() {
                            return load
                        },
                        set(newVal) {
                            load = newVal
                            if (newVal) {
                                window.NPS.boot(bootData)
                            }
                            return load
                        },
                    })
                }

            } catch (error) {
                // console.warn(error);
            }
        }, 1000);
    }

    // 初始化聊天工具
    ChatOnlineToolInit = async () => {
            try {
                let visitorNo = new Date().getTime() + Math.ceil(Math.random()*1000);
                if (typeof window.Willdesk == 'object') {
                    window.Willdesk.boot({
                        user_name: this.state.shopOwner !== '' ? this.state.shopOwner : 'dropshipman_visitor',
                        user_email: this.state.userEmail !== '' ? this.state.userEmail : 'dropshipman_visitor',
                        user_id: 'dropshipman_' + this.state.shopifyDomain,
                        fromApp: this.state.fromApp,
                        shopUrl: this.state.shopifyDomain !== '' ? this.state.shopifyDomain : 'dropshipman_visitor_' + visitorNo,
                        // Usersname: this.state.storeName !== '' ? this.state.storeName : '',
                        shopifyPlan: this.state.shopifyStoreVersion,
                        plan: this.state.userLevel,
                        reviewName: this.state.storeName ? this.state.storeName : 'no review_name',
                        review: this.state.reviewStar,
                        first_name: this.state.storeName,
                    })
                  } else {
                      let load = window.WilldeskLoad = false
                      Object.defineProperty(window, 'WilldeskLoad', {
                      get () { return load },
                      set(newVal) {
                        load = newVal
                        if (newVal) { window.Willdesk.boot({
                            user_name: this.state.shopOwner !== '' ? this.state.shopOwner : 'dropshipman_visitor',
                            user_email: this.state.userEmail !== '' ? this.state.userEmail : 'dropshipman_visitor',
                            user_id: 'dropshipman_' + this.state.shopifyDomain,
                            fromApp: this.state.fromApp,
                            shopUrl: this.state.shopifyDomain !== '' ? this.state.shopifyDomain : 'dropshipman_visitor_' + visitorNo,
                            // Usersname: this.state.storeName !== '' ? this.state.storeName : '',
                            shopifyPlan: this.state.shopifyStoreVersion,
                            plan: this.state.userLevel,
                            reviewName: this.state.storeName ? this.state.storeName : 'no review_name',
                            review: this.state.reviewStar,
                            first_name: this.state.storeName,
                        }) }
                        return load
                      }
                    })
                  }
                
                // console.log(window.Willdesk.boot());
                // window.Intercom('boot', {
                //     app_id: 't6tndyrj',
                //     from_app: this.state.fromApp,
                    
                //     name: this.state.shopOwner !== '' ? this.state.shopOwner : 'dropshipman_visitor',
                //     email: this.state.userEmail !== '' ? this.state.userEmail : 'dropshipman_visitor',
                //     user_id: 'dropshipman_' + this.state.shopifyDomain,
                //     shop_name: this.state.shopifyDomain !== '' ? this.state.shopifyDomain : 'dropshipman_visitor_' + visitorNo,
                //     Usersname: this.state.storeName !== '' ? this.state.storeName : '',
                //     shopify_store_version: this.state.shopifyStoreVersion,
                //     plan: this.state.userLevel,
                //     review_name: this.state.storeName ? this.state.storeName : 'no review_name',
                //     review: this.state.reviewStar,
                // });

                // window.Intercom('hide');

                // window.Intercom('onUnreadCountChange', function (unreadCount) {
                //     num += 1;
                //     // 首次进入 一般都是页面刷新后初始化平台成功之后进入
                //     if(num === 1) {
                //         if(window.location.pathname === "/admin/dashboard") {
                //             setTimeout(() => {
                //                 document.getElementsByClassName('intercom-duq38k')[0].style.display = "block"
                //             },500);
                //         }
                //     } else {
                //         document.getElementsByClassName('intercom-duq38k')[0].style.display = "none"
                //     }
                // })

            } catch (error) {
                console.warn(error);
            }

    }

    // // 初始化聊天工具
    // ChatOnlineToolInit = async () => {
    //     setTimeout(() => {
    //         try {
    //             let visitorNo = new Date().getTime() + Math.ceil(Math.random()*1000);
                
    //             window.Intercom('boot', {
    //                 app_id: 't6tndyrj',
    //                 from_app: this.state.fromApp,
                    
    //                 name: this.state.shopOwner !== '' ? this.state.shopOwner : 'dropshipman_visitor',
    //                 email: this.state.userEmail !== '' ? this.state.userEmail : 'dropshipman_visitor',
    //                 user_id: 'dropshipman_' + this.state.shopifyDomain,
    //                 shop_name: this.state.shopifyDomain !== '' ? this.state.shopifyDomain : 'dropshipman_visitor_' + visitorNo,
    //                 Usersname: this.state.storeName !== '' ? this.state.storeName : '',
    //                 shopify_store_version: this.state.shopifyStoreVersion,
    //                 plan: this.state.userLevel,
    //                 review_name: this.state.storeName ? this.state.storeName : 'no review_name',
    //                 review: this.state.reviewStar,
    //             });

    //             window.Intercom('hide');

    //             window.Intercom('onUnreadCountChange', function (unreadCount) {
    //                 num += 1;
    //                 // 首次进入 一般都是页面刷新后初始化平台成功之后进入
    //                 if(num === 1) {
    //                     if(window.location.pathname === "/admin/dashboard") {
    //                         setTimeout(() => {
    //                             document.getElementsByClassName('intercom-duq38k')[0].style.display = "block"
    //                         },500);
    //                     }
    //                 } else {
    //                     document.getElementsByClassName('intercom-duq38k')[0].style.display = "none"
    //                 }
    //             })

    //         } catch (error) {
    //             console.warn(error);
    //         }

    //     }, 1000);
    // }

    render() {
        // const { fromApp, shopOwner } = this.state;

        return (
            <></>
            // <ChatPlatformInformation from_app={fromApp} name={shopOwner} />
        )
    }
}

export default connect()(ChatOnlinePlatformInit);