import { createApp } from "@shopify/app-bridge";
import { VITE_SHOPIFY_APP_KEY } from "../utils/constants";
import utils from "../utils/utils"
import {
  AppLink,
  Fullscreen,
  NavigationMenu,
  Modal,
  Button
  // TitleBar,
  // Button,
  // Redirect
} from "@shopify/app-bridge/actions";
import { store } from "../store";
// import intl from "../i18n/intl";
export default class AppBridge {
  /**
   * 检测实例
   * @returns
   */
  static exist() {
    return this.app !== undefined;
  }

  /**
   * 创建实例
   * @param {*} shop
   * @param {*} host
   */
  static set(shop, host) {
    const apiKey = VITE_SHOPIFY_APP_KEY;
    this.app = createApp({
      apiKey: apiKey,
      host: host,
      shop:shop
    });
    //保存数据
    window.DSMAppAuth = this.app;
    // 注册路由
    //保存数据
    let store_level = store.getState();
    this.routeSet(store_level);
    return this.app;
  }

  // 使用订阅模式弹窗
  static setBridgeModal(isOpen,data){
    
    try {
      console.log(isOpen,data);
    if (isOpen) {
    const app = this.app;
    const {title,message,size,cancelLoading,cancelLabel,saveLoading,saveLabel,cancelFunc,saveFunc} = data;
    const okButton = Button.create(app, {label: saveLabel});
    const cancelButton = Button.create(app, {label: cancelLabel});
    
    const modalOptions = {
      title: title,
      message: message,
      size: 'Medium',
      footer: {
        buttons: {
          primary: okButton,
          secondary: [cancelButton],
        },
      },
    };

    const myModal = Modal.create(app, modalOptions);
    okButton.set({label: saveLabel,loading: cancelLoading || saveLoading})
    cancelButton.set({label: cancelLabel,disabled: cancelLoading || saveLoading})
    
    okButton.subscribe(Button.Action.CLICK, () => {
      // Do something with the click action
      if (saveFunc) {
        saveFunc()
      }
      myModal.dispatch(Modal.Action.CLOSE)
    });
    cancelButton.subscribe(Button.Action.CLICK, () => {
      // Do something with the click action
      if (cancelFunc && cancelLabel === 'Generate again') {
        cancelFunc()
      }else {
        myModal.dispatch(Modal.Action.CLOSE)
      }
    });
    // 关闭弹窗前的操作
    myModal.subscribe(Modal.Action.CLOSE, () => {
      // Do something with the close event
      if (cancelLoading || saveLoading) {
        return
      }else {
        myModal.dispatch(Modal.Action.CLOSE)
      }
    });
    myModal.set({footer: {
      buttons: {
        primary: okButton,
        secondary: cancelLoading || saveLoading ? [] : [cancelButton],
      },
    },title,message,size}).dispatch(Modal.Action.OPEN);
    }else {

    }
  } catch (error) {
      
  }
    
    
  }

  // 创建顶部标题/按钮/面包屑
  // static setTitleBar(titleObj, url, func, btnState, secondTitle) {
  //   // console.log(titleObj, url, func, btnState);
  //   const app = this.app;
  //     let titleBlock = {
  //       title: titleObj,
  //     };
    
  //     if (titleObj === "Custom Packaging" && Boolean(func)) {
  //       const saveButton = Button.create(app, {
  //         label: intl.get('Upload logo'),
  //         // loading: !Boolean(func) || btnState,
  //       });
  //       saveButton.subscribe(Button.Action.CLICK, () => {
  //         // Do something with the click event
  //         func();
  //       });
  //       titleBlock = {
  //         title: titleObj,
  //         buttons: {
  //           primary: saveButton,
  //         },
  //       };
  //     }


  //       // 一级页面
  //       const firstUrl = {
  //         "/admin/dashboard": "Dashboard",
  //         "/admin/find_products": "Find Products",
  //         "/admin/customService": "Custom Service",
  //         "/admin/partnerShip": "Partnership",
  //         // "/admin/websiteOptimization": "Site Optimization",
  //     }
  //     // 二级页面
  //     const secUrl = [
  //         'Custom Packaging',
  //         'Private storage',
  //         'Print On Demand',
  //         'Landing Page Builder',
  //         'Freight calculation',
  //         'Affiliate Program',
  //         'Feature Requests',
  //         'Partners',
  //         // 'Website Pagespeed',
  //         // 'Website Build',
  //         'Trending Products',
  //         'Winning Products',
  //         'New Products',
  //     ]
  //     // 注册面包屑页面
  //     if(secUrl.includes(titleObj)) {
  //       const backUrl = url.indexOf("/admin/dashboard") > -1 ? "/admin/dashboard" : 
  //           ['/admin/find_products'].includes(url) ? "/admin/find_products" : 
  //           url.indexOf("/admin/find_products/trendy-products/trendy") > -1 ? "/admin/find_products/trendy-products/trendy" : 
  //           url.indexOf("/admin/find_products/recommended-category/winning") > -1 ? "/admin/find_products/recommended-category/winning" :
  //           url.indexOf("/admin/find_products/new-products") > -1 ? "/admin/find_products/new-products" :
  //           url.indexOf("/admin/customService") > -1 ? "/admin/customService" :
  //           // url.indexOf("/admin/websiteOptimization") > -1 ? "/admin/websiteOptimization" : 
  //           url.indexOf("/admin/partnerShip") > -1 ? "/admin/partnerShip" : "/admin/dashboard";
  //       const breadcrumb = Button.create(app, { label: firstUrl[backUrl] });
  //       breadcrumb.subscribe(Button.Action.CLICK, () => {
  //           app.dispatch(Redirect.toApp({ path: backUrl }));
  //       });
  //       titleBlock['breadcrumbs'] = breadcrumb;
  //   }
  //     const PageTitle = TitleBar.create(app, {title: 'Dashboard'});
  //     PageTitle.set(titleBlock);
  //     return app;
  // }

  //需要注册的页面调这个方法，然后传标题名称进来就行

  // 创建全屏
  static SetFullscreen(isFull = false) {
    try {
        if (Boolean(store.getState().languageReducer.public.status)) {
            const apiKey = VITE_SHOPIFY_APP_KEY;
            const host = window.DSMHost || utils.getStore('host');
            const app = window.DSMAppAuth ? window.DSMAppAuth : createApp({ apiKey, host });
            const fullscreen = Fullscreen.create(app);
            if(isFull) {
                fullscreen.dispatch(Fullscreen.Action.ENTER);
            } else {
                fullscreen.dispatch(Fullscreen.Action.EXIT);
            }
        }
    } catch(e) {
        console.log(e)
    }
}
  // 创建shopify侧边导航栏
  static routeSet(store_level) {
    let app = this.app;
    let person = store.getState().languageReducer.subScriptData;
    
    // 增加自定义shopify左侧导航栏
    // const Subcription = AppLink.create(app, {
    //   label: "Plan",
    //   destination: "/admin/subscribe",
    // });?select_tab=1
    const ProductSelection = AppLink.create(app, {
      label: "Product Selection",
      destination: (person?.level === 2 || person?.level=== 3 || person?.level === 5)  ? "/admin/product_selection?select_tab=1" : "/admin/product_selection?select_tab=0",
    });
    // const Sourcing = AppLink.create(app, {
    //   label: "Sourcing",
    //   destination: "/admin/sourcing",
    // });
    const ListProducts = AppLink.create(app, {
      label: "Product Center",
      destination: "/admin/product_management?selectedView=0",
    });
    const OrderList = AppLink.create(app, {
      label: "Orders",
      destination: "/admin/order_list",
    });
    const CustomService = AppLink.create(app, {
      label: "Custom Service",
      destination: "/admin/customService",
    });
    const PartnerShip = AppLink.create(app, {
      label: "Partnership",
      destination: "/admin/partnerShip",
    });
    const Settings = AppLink.create(app, {
      label: "Settings",
      destination: "/admin/settings",
    });
    const recommendedApps = AppLink.create(app, {
      label: "Recommended Apps",
      destination: "/admin/recommended-apps",
    });
    NavigationMenu.create(app, {
      items: [
        // Subcription,
        ProductSelection,
        // Sourcing,
        ListProducts,
        OrderList,
        CustomService,
        PartnerShip,
        // websiteOptimization,
        Settings,
        recommendedApps,
      ],
      // active: FindProducts,
    });
  }
}
