import React , { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './index.css';
import {  Modal,Toast } from '@shopify/polaris';
import { getPayonnerAuth } from '../../api';
import AppBridge from '../../utils/appBridge';
import intl from "../../i18n/intl";
const PayoneerBalancesConnect  = ({open,callPayoneerConnectClosed})=> {
   const [selectLoading, setSelectLoading] = useState(false);
   const handleOff = () =>{
    callPayoneerConnectClosed()
    setSelectLoading(false)
    }
    //payonner 授权
    const handlePayonnerAuth = async()=>{
    let params = {
      type:AppBridge.exist() ? 1 : 2
    }
    setSelectLoading(true)
    let res = await getPayonnerAuth(params)
    if (res.code === '200') {
        handleOff()
      window.open(res.data.authorization_url,'_blank')
    }
    setSelectLoading(false)
  }
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
        return (
            <div>
            <Modal
                open={open}
                title={intl.get("Account is not connected to Payoneer")}
                primaryAction={{
                    content: intl.get('Connect'),
                    onAction: () => {
                        handlePayonnerAuth()
                    },
                    loading: selectLoading,
                }}
                onClose={() => handleOff()}>
                        <div style={{padding:'24px',fontSize:"16px",minHeight:"120px",display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {intl.get("Your account is not yet linked to Payoneer. Please click \"Connect\" to link it.")}
                        </div>
                </Modal>
                {toastMarkup}
            </div>
        )
    }

export default withRouter(PayoneerBalancesConnect);

