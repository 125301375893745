import {Modal} from "@shopify/polaris";
import React from "react";
import intl from "../../../../i18n/intl";
const Index = ({open,onClose}) => {

  return (
    <div className="upgrade_new_feature">
      <Modal
        open={open}
        onClose={() => onClose()}
        title={intl.get('Subscription Successful')}
        primaryAction={{
          content: intl.get("OK"),
          onAction: () => {
            onClose()
          },
        }}
      >
        <Modal.Section>
        <div style={{display:'flex',alignItems:'center'}}>
          <img src="/sub/img_success.png" alt="Dropshipman" />
          <p style={{fontSize:'14px',marginLeft:'16px'}}>{intl.get('You have successfully subscribed to Premium. Your Personal Agent will reach out to you shortly!')}</p>
        </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
