import React, { useState, memo } from "react";
import {
  Card,
  Button,
  Checkbox,
  Link,
  Collapsible,
  Popover,
  ActionList,
  Icon,
  Toast,
  Tooltip,
  Modal,
  Badge,
  BlockStack,
  Box,
  Spinner,
} from "@shopify/polaris";
import OrderNoteModal from "../Modal/OrderNoteModal";
import CancelSkuModal from "../Modal/CancelSkuModal";
import ShippingMethodsModal from "../Modal/ShippingMethodsModal";
import OrderShippingAddress from "../Modal/ShippingAddress";
import RefundTipsModal from "../Modal/RefundTipsModal";
import OrderInvoiceModal from "../Modal/OrderInvoiceModal";
import OrderTracking from "../Modal/OrderTracking";
import OrderPaymentRefundTipModal from "../Modal/OrderPaymentRefundTipModal";
import NotSourcingOneCompleteModal from "../Modal/NotSourcingOneCompleteModal";
import UnknownSkuSourcing from "../Modal/UnknownSkuSourcing";
import SymbolTake from "../../../../../components/SymbolTake";
import utils from "../../../../../utils/utils";
import { LinkIcon, QuestionCircleIcon } from "@shopify/polaris-icons";
import ToolTag from "../ToolTag";
// import OrderPaymentModal from "../Modal/OrderPaymentModal";
import { useGetState } from "ahooks";
import {
  reqGetOrderBatchSourcingReason,
  // reqCouponPay,
  reqGetOrderInvoice,
  reqPostOrderInvoice,
  reqSourcingOrderRequest,
} from "../../../../../api";
import CustomerOrderNotesModal from "../Modal/CustomerOrderNotesModal";
import PackingOrderModal from "../Modal/PackingOrderModal";
import intl from "../../../../../i18n/intl";
import { connect, useSelector } from "react-redux";
// import BulkPaymentModal from "../Modal/BulkPaymentModal";
var num = 1;
var flag = true;
const OrderItem = memo(
  ({
    getOrderListData,
    getSearchParam,
    setOrderPageData,
    // handlePayForOrderModal,
    handleOrderShippingTips,
    // handleAddressFailedTips,
    content,
    walletMoney,
    handleToggle,
    handleSingleCheckBox,
    selectedTab,
    setListLoading,
    // payoneerAuth,
    // setPayoneerConnectOpen,
    // setPayoneerBalances,
    // paymentData,
    setPaymentData,
    setOrderItemPageData,
    handleOneSourcingRequest,
    searchSourcingData,
    handleCancelInventory,
  }) => {
    const [isShowOrderNoteModal, setIsShowOrderNoteModal] = useState(false); //记录订单备注弹窗是否显示
    const [noteShow, setNoteShow] = useState(false); //订单备注弹窗是否显示
    const [isShowPackingOrderModal, setisShowPackingOrderModal] =
      useState(false); //订单包装显示
    const [isShowShippingMethodsModal, setIsShowShippingMethodsModal] =
      useState(false); //记录物流方式弹窗是否显示
    const [trackModal, setTrackModal] = useState(false); //记录物流轨迹弹窗是否显示
    const [orderId, setOrderId] = useState(1); //当前订单id
    const [isShowRefundTipsModal, setIsShowRefundTipsModal] = useState(false); //记录退款详情弹窗是否显示
    // const [paymentModalActive, setPaymentModalActive] = useState(false); //支付弹窗是否显示
    const [orderNote, setOrderNote] = useState(""); //订单备注 dsm
    const [orderPayStatus, setOrderPayStatus] = useState("");
    const [orderNoteShopify, setOrderNoteShopify] = useState(""); //订单备注 shopify
    const [sourceFailed, setSourceFailed] = useState(false);
    const [sourceFailedTip, setSourceFailedTip] = useState("");
    const [sourceFailedId, setSourceFailedId] = useState(0);
    const handleFailedTip = async (id) => {
      if (sourceFailedId !== 0) {
        return;
      }
      try {
        setSourceFailed(true);
        setSourceFailedId(id);
        let res = await reqGetOrderBatchSourcingReason({ id });
        if (res.code === "200") {
          setSourceFailedTip(res.data);
        }
      } catch (error) {}
    };
    const [currentOrderData, setCurrentOrderData, getCurrentOrderData] =
      useGetState({
        data: {}, //当前订单数据
        orderSkuData: {}, // 删除退款订单sku数据
        orderDeleteModal: false, //移除sku弹窗
        orderData: {}, //订单支付数据
        removeCount: 1, //移除sku数量
        currentItem: [],
      });
    const [orderPaymentData, setOrderPaymentData, getOrderPaymentData] =
      useGetState({
        paymentModalActive: false,
        payment_select_key: 0, // key
        payment_dsm_order_id: "", //支付订单的id
        payment_dsm_number: "",
        payment_dsm_type: 0, //支付订单的类型
        payment_allTotalPrice: 0, //支付的总价
        payment_allShippingCost: 0, //运费
        payment_select: [], //支付方式选择
        CouponList: [], //优惠券方式选择
        CouponRadioValue: "t",
        wallet_money: "", //钱包
        CouponMoney: 0, //折扣金额
        CouponId: 0, //折扣id
        orderRefundModal: false, //存在退款提示
        fullRefund: false, //
        shopifyOrderRefundModal: false, //已退款订单批量支付提示
        isOneNotSourcing: false, //sku未sourcing完成弹窗
        addressFailedModal: false, //支付异常提醒
        addressFailedLoading: false,
        addressFailedOrder: "",
        setOrderStatusIds: [],
      });

    const togglePopoverActive = (key) => {
      setOrderPageData((prevValue) => {
        const lists = prevValue.list;
        for (let i = 0; i < lists.length; i++) {
          if (i === key && lists[i].number_show === 0) {
            lists[i].number_show = 1;
          } else {
            lists[i].number_show = 0;
          }
        }
        const params = { ...prevValue, list: lists };
        return params;
      });
    };
    // 订单付款
    const handleRefundPaymentMethodSelect = utils.debounce(
      (
        recordKey,
        id,
        name,
        type,
        totalPrice,
        shippingCost,
        shopify_order_status,
        currentItem
      ) => {
        setCurrentOrderData((prevValue) => ({
          ...prevValue,
          orderData: { recordKey, id, name, type, totalPrice, shippingCost },
        }));

        switch (shopify_order_status) {
          case "refunded":
            setOrderPaymentData((prevValue) => ({
              ...prevValue,
              orderRefundModal: true,
              fullRefund: true,
            }));
            // this.setState({
            //   orderRefundModal: true,
            //   fullRefund: true,
            // })
            break;
          case "partially_refunded":
            setOrderPaymentData((prevValue) => ({
              ...prevValue,
              orderRefundModal: true,
              fullRefund: false,
            }));
            // this.setState({
            //   orderRefundModal: true,
            //   fullRefund: false,
            // })
            break;
          default:
            handlePaymentMethodSelect(
              recordKey,
              id,
              name,
              type,
              totalPrice,
              shippingCost,
              currentItem
            );
            break;
        }
        return;
      },
      200
    );

    // 付款方式选择弹窗
    const handlePaymentMethodSelect = (
      recordKey,
      id,
      name,
      type,
      totalPrice,
      shippingCost,
      currentItem
    ) => {
      // 是否选择运输方式检测
      // console.log(recordKey,id,name,type,totalPrice,shippingCost);
      setOrderItemPageData([currentItem]);
      setOrderPaymentData((prevValue) => ({
        ...prevValue,
        orderRefundModal: false,
        shopifyOrderRefundModal: false,
      }));
      let isNotSourcing = false;
      let isPass = true;
      let allAddressTrue = true;
      // let response;
      // let list = [];
      if (type === 1 || type === 4) {
        // 订单类型 1 shopify 2 礼品包装 3 产品批发 4 自定义订单
        let data = content;
        // console.log(id, name, totalPrice, data[recordKey].shipping_cost);
        // 判断当前订单地址情况
        let address_failed = data[recordKey].address_failed;
        if (address_failed === 1) {
          allAddressTrue = false;
        }

        if (
          data[recordKey].order_detail.no_source.length > 0 ||
          data[recordKey].order_detail.pending.length > 0
        ) {
          isNotSourcing = true;
        }

        // 不是包邮，且没有运费，需要提示选择物流方式
        let shippingCost = data[recordKey].shipping_cost;

        if (shippingCost <= 0) {
          isPass = false;
        }
        // }
        // response = await reqCouponPay({
        //   money: totalPrice,
        //   order_id: id,
        // });
        // if (response.code === 200) {
        //   list = response.data;
        // }
      }
      if (!isPass) {
        handleOrderShippingTips(true, name);
        return;
      }
      // 判断选择的订单地址异常情况
      if (!allAddressTrue) {
        handleAddressFailedTips(true, [id], name);
        return;
      }

      // 如果有未完成sourcing的订单支付
      if (isNotSourcing) {
        setOrderPaymentData((prevValue) => ({
          ...prevValue,
          paymentModalActive: false,
          payment_select_key: recordKey, // key
          payment_dsm_order_id: id,
          payment_dsm_number: name,
          payment_dsm_type: type,
          payment_allTotalPrice: totalPrice,
          payment_allShippingCost: shippingCost,
          payment_select: [],
          CouponList: [],
          CouponRadioValue: "optional_not",
          wallet_money: walletMoney,
          isOneNotSourcing: true,
        }));
      } else {
        setOrderPaymentData((prevValue) => ({
          ...prevValue,
          paymentModalActive: true,
          payment_select_key: recordKey, // key
          payment_dsm_order_id: id,
          payment_dsm_number: name,
          payment_dsm_type: type,
          payment_allTotalPrice: totalPrice,
          payment_allShippingCost: shippingCost,
          payment_select: [],
          CouponList: [],
          CouponRadioValue: "optional_not",
          wallet_money: walletMoney,
        }));
        setPaymentData((prevValue) => ({
          ...prevValue,
          isShowBulkPaymentModal: true,
        }));
      }
    };

    // [单个/批量] 订单提示地址异常无法付款
    const handleAddressFailedTips = (bool, ids = [], orderName = "") => {
      if (bool && ids.length === 0) {
        // 处理批量
        let data = content;
        let name = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].checked === 1 && data[i].address_failed === 1) {
            ids.push(data[i].dsm_record);
            name.push(data[i].dsm_order_name);
          }
        }
        orderName = name.length > 0 ? name.join(",") : "";
      }
      setOrderPaymentData((prevValue) => ({
        ...prevValue,
        addressFailedModal: bool,
        addressFailedOrder: orderName,
        setOrderStatusIds: ids,
      }));
    };
    const handleAddressFailedOrderToUnpaid = () => {
      try {
        let list = [];
        if (getCurrentOrderData()?.orderData.id > 0) {
          setOrderPaymentData((prevValue) => ({
            ...prevValue,
            paymentModalActive: true,
            payment_select_key: getCurrentOrderData()?.orderData.recordKey, // key
            payment_dsm_order_id: getCurrentOrderData()?.orderData.id,
            payment_dsm_number: getCurrentOrderData()?.orderData.name,
            payment_dsm_type: getCurrentOrderData()?.orderData.type,
            payment_allTotalPrice: getCurrentOrderData()?.orderData.totalPrice,
            payment_allShippingCost:
              getCurrentOrderData()?.orderData.shippingCost,
            payment_select: [],
            CouponList: list,
            CouponRadioValue: "optional_not",
            wallet_money: walletMoney,
            addressFailedModal: false,
          }));
          setPaymentData((prevValue) => ({
            ...prevValue,
            isShowBulkPaymentModal: true,
          }));
        }
      } catch (error) {}
    };

    // 总费用
    const total = (product, shipping, vat, discount) => {
      let count =
        parseFloat(product) +
        parseFloat(shipping) +
        parseFloat(vat) -
        parseFloat(discount);
      return count.toFixed(2);
    };
    // 底部费用列表
    const cost = (item, key) => {
      let track = [];
      for (let i = 0; i < item.tracking_numbers.length; i++) {
        track.push({
          content: item.tracking_numbers[i],
          url:
            "https://www.trackingmore.com/track/en/" +
            item.tracking_numbers[i] +
            "?source=dsm",
          external: true,
        });
      }
      return (
        <div className="goods_footer">
          <div>
            <span>{intl.get("Product cost")}:&nbsp;</span>
            {/* <span style={{ fontWeight: 700 }}>
              {" "}
              $ {parseFloat(item.product_cost).toFixed(2)}
            </span> */}
            <SymbolTake price={parseFloat(item.product_cost).toFixed(2)} />
          </div>
          {item.vat !== 0 && (
            <div>
              <span>{intl.get("VAT")}:</span>
              {/* <span style={{ fontWeight: 700 }}>
                {" "}
                ${parseFloat(item.ioss_vat).toFixed(2)}
              </span> */}
              <SymbolTake price={parseFloat(item.ioss_vat).toFixed(2)} />
            </div>
          )}
          <div>
            <span>{intl.get("Shipping cost")}:&nbsp;</span>
            {/* <span style={{ fontWeight: 700 }}>
              {" "}
              ${parseFloat(item.shipping_cost).toFixed(2)}
            </span> */}
            <SymbolTake price={parseFloat(item.shipping_cost).toFixed(2)} />
          </div>
          <div>
            <span>{intl.get("Discount")}:&nbsp;</span>
            {/* <span style={{ fontWeight: 700 }}>
              {" "}
              ${parseFloat(item.coupon_money).toFixed(2)}
            </span> */}
            <SymbolTake price={parseFloat(item.coupon_money).toFixed(2)} />
          </div>
          <div style={{ position: "relative" }}>
            <span>{intl.get("Total cost")}:&nbsp;</span>
            {/* <span style={{ fontWeight: 700 }}>
              {" "}
              $
              {total(
                item.product_cost,
                item.ioss_vat,
                item.shipping_cost,
                item.coupon_money
              )}
             
            </span> */}
            <SymbolTake
              price={total(
                item.product_cost,
                item.ioss_vat,
                item.shipping_cost,
                item.coupon_money
              )}
            />
          </div>
          {(item.type === 1 || item.type === 4) && (
            <div className="order_tracking_no">
              <span>{intl.get("Tracking No")}:</span>
              {item.tracking_numbers.length > 0 && (
                <div style={{ marginLeft: "5px" }}>
                  <Popover
                    active={item.number_show === 1}
                    activator={
                      <Button
                        variant="plain"
                        onClick={() => togglePopoverActive(key)}
                        disclosure={item.number_show === 1 ? "up" : "down"}
                      >
                        {item.tracking_numbers[0]}
                      </Button>
                    }
                    autofocusTarget="first-node"
                    onClose={() => togglePopoverActive(key)}
                  >
                    <ActionList actionRole="menuitem" items={track} />
                  </Popover>
                </div>
              )}
            </div>
          )}
          {(item.dsm_payment_status === "Unpaid" ||
            item.dsm_payment_status === "Partially paid") && (
            <div className="order_tracking_no_button">
              <Button
                variant="primary"
                onClick={() =>
                  handleRefundPaymentMethodSelect(
                    // 8,
                    // 15,
                    // "#DSM1001",
                    // 2,
                    // 125,
                    // 0,
                    // ""
                    key,
                    item.dsm_record,
                    item.dsm_order_name,
                    item.type,
                    total(
                      item.product_cost,
                      item.ioss_vat,
                      item.shipping_cost,
                      item.coupon_money
                    ),
                    parseFloat(item?.shipping_cost),
                    item.shopify_financial_status,
                    item
                  )
                }
              >
                {intl.get("Payment")}
              </Button>
            </div>
          )}
        </div>
      );
    };

    const [invoicesData, setInvoicesData] = useState({}); //开票数据
    const [invoicesLoading, setInvoicesLoading] = useState(false); //获取开票数据加载状态
    const [invoicesActive, setInvoicesActive] = useState(false); //开票弹窗显示
    const [invoicesForm, setInvoicesForm, getInvoicesForm] = useGetState({
      companyName: "",
      companyNumber: "",
      companyAddress: "",
      inputNameTip: false,
      inputNumberTip: false,
      inputAddressTip: false,
    }); //开票表格填写数据
    // 开票弹窗
    const handleOpenInvoices = (id) => {
      setInvoicesLoading(true);
      setInvoicesActive(true);
      handleInvoices(id);
    };
    const handleChange = () => {
      setInvoicesActive(!invoicesActive);
      // if (!invoicesActive) {
      //   this.setState({
      //     inputTip: false,
      //   });
      // }
      flag = true;
      num = 1;
    };

    const handleInvoices = async (id) => {
      if (flag) {
        flag = false;
        let res = await reqGetOrderInvoice({ order_id: id });
        if (res.code === "200") {
          setInvoicesForm((prevValue) => ({
            ...prevValue,
            companyName: res.data.company_name,
            companyNumber: res.data.company_no,
            companyAddress: res.data.address,
            inputNameTip: false,
            inputNumberTip: false,
            inputAddressTip: false,
          }));
          setInvoicesData(res.data);
          setInvoicesActive(true);
          setInvoicesLoading(false);
          // this.setState({
          //   invoicesData: res.data,
          //   active: true,
          //   companyName: res.data.company_name,
          //   companyNumber: res.data.company_no,
          //   companyAddress: res.data.address,
          //   inputNameTip: false,
          //   inputNumberTip: false,
          //   inputAddressTip: false,
          //   invoicesLoading: false,
          // });
        } else {
          flag = true;
          // this.toggleToast(res.msg, true);
        }
      }
    };

    const shopify_level = useSelector(
      (state) => state.languageReducer.subScriptData.shopify_level
    );
    const shopifyLevel = () => {
      let flag = true;
      if (
        shopify_level === "Development" ||
        shopify_level === "Developer Preview" ||
        shopify_level === "staff" ||
        shopify_level === "staff_business"
      ) {
        flag = false;
      }
      return flag;
    };
    // 输入设置
    const onInvoices = (e, type) => {
      let text = e.target.value;
      switch (type) {
        case "name":
          setInvoicesForm((prevValue) => ({
            ...prevValue,
            companyName: text,
          }));
          if (text === "") {
            setInvoicesForm((prevValue) => ({
              ...prevValue,
              inputNameTip: true,
            }));
          } else {
            setInvoicesForm((prevValue) => ({
              ...prevValue,
              inputNameTip: false,
            }));
          }
          break;
        case "number":
          setInvoicesForm((prevValue) => ({
            ...prevValue,
            companyNumber: text,
          }));
          if (text === "") {
            setInvoicesForm((prevValue) => ({
              ...prevValue,
              inputNumberTip: true,
            }));
          } else {
            setInvoicesForm((prevValue) => ({
              ...prevValue,
              inputNumberTip: false,
            }));
          }
          break;
        case "address":
          setInvoicesForm((prevValue) => ({
            ...prevValue,
            companyAddress: text,
          }));
          if (text === "") {
            setInvoicesForm((prevValue) => ({
              ...prevValue,
              inputAddressTip: true,
            }));
          } else {
            setInvoicesForm((prevValue) => ({
              ...prevValue,
              inputAddressTip: false,
            }));
          }
          break;
        default:
          break;
      }
    };
    const onInvoicesSubmit = async () => {
      let params = {
        company_name: getInvoicesForm().companyName,
        company_no: invoicesForm.companyNumber,
        address: invoicesForm.companyAddress,
        order_id: invoicesData.dsm_order_id,
      };
      if (
        invoicesForm.companyName === undefined &&
        invoicesForm.companyNumber === undefined &&
        invoicesForm.companyAddress === undefined
      ) {
        setInvoicesForm((prevValue) => ({
          ...prevValue,
          inputNameTip: true,
          inputNumberTip: true,
          inputAddressTip: true,
        }));

        return;
      }
      if (
        invoicesForm.companyName === "" ||
        invoicesForm.companyName === undefined
      ) {
        setInvoicesForm((prevValue) => ({
          ...prevValue,
          inputNameTip: true,
        }));
        return;
      }
      if (
        invoicesForm.companyNumber === "" ||
        invoicesForm.companyNumber === undefined
      ) {
        setInvoicesForm((prevValue) => ({
          ...prevValue,
          inputNumberTip: true,
        }));
        return;
      }
      if (
        invoicesForm.companyAddress === "" ||
        invoicesForm.companyAddress === undefined
      ) {
        setInvoicesForm((prevValue) => ({
          ...prevValue,
          inputAddressTip: true,
        }));
        return;
      }
      if (num === 1) {
        num = 2;
        let res = await reqPostOrderInvoice(params);
        if (res.code === "200") {
          // this.handleChange();
          window.open(res.data.pdf_url);
          toggleToast("Download successfully", false);
          setTimeout(() => {
            num = 1;
          }, 1500);
        } else {
          // toggleToast(res.msg, true);
          num = 1;
        }
      }
    };
    // 面单弹窗(单个)
    // const handleOpenShippingLabel = (url) => {
    //   window.open(url, "_blank");
    // };

    // 物流追踪弹窗
    const handleShowTracking = () => {
      setTrackModal(!trackModal);
    };
    // 移除sku
    const handleOrderSkuDel = (item, data) => {
      setCurrentOrderData((prevValue) => ({
        ...prevValue,
        orderDeleteModal: true,
        orderSkuData: data,
        orderData: item,
        removeCount: data.quantity,
      }));
    };

    // sourcing
    const [sourceData, setSourceData, getSourceData] = useGetState({
      SourcingOrderActive: false,
      orderDetailsVariantId: "",
      SourcingOrderLoading: false,
    });
    // unknow类型的sku的sourcing
    const SourcingOrderModalOpen = (orderDetailsVariantId) => {
      setSourceData((prevValue) => ({
        ...prevValue,
        SourcingOrderActive: !getSourceData().SourcingOrderActive,
        orderDetailsVariantId: orderDetailsVariantId,
      }));
    };
    // 确认请求 (unknow类型的sku的sourcing)
    const handleChangeSourcingOrder = async () => {
      setSourceData((prevValue) => ({
        ...prevValue,
        SourcingOrderLoading: true,
      }));

      const params = {
        orderDetailsID: sourceData.orderDetailsVariantId,
      };
      const response = await reqSourcingOrderRequest(params);
      if (response.code === "200") {
        setSourceData((prevValue) => ({
          ...prevValue,
          SourcingOrderLoading: false,
          SourcingOrderActive: false,
        }));

        toggleToast(response.msg, false);
        getOrderListData(getSearchParam());
      } else {
        setSourceData((prevValue) => ({
          ...prevValue,
          SourcingOrderLoading: false,
        }));
        toggleToast(response.msg);
      }
    };

    // 新增
    // 单个sourcing
    // const [sourcingLoading,setSourcingLoading] = useState(false);
    // const [sourcingIdLoading,setSourcingIdLoading] = useState('')
    // const handleSourcingData = (id)=> {
    //   setSourcingIdLoading(id)
    //   setSourcingLoading(true)
    //   setTimeout(() => {
    //     setSourcingIdLoading('')
    //   setSourcingLoading(false)
    //   }, 2000);
    // }

    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };

    const costQuantityFun = (item, it) => {
      let text = "";
      if (item) {
        text =
          it.paid_quantity > 0 && item.order_scen === 2 && item.type === 1 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {it.paid_quantity === it.quantity ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: 5 }}>
                    {" "}
                    {intl.get("Cost")}：$0 x {it.paid_quantity} (Inventory
                    Deduction)
                  </span>
                  {item.dsm_payment_status === "Partially paid" && (
                    <Button
                      variant="plain"
                      onClick={() =>
                        handleCancelInventory(item.dsm_record, it.id)
                      }
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: 5 }}>
                    {" "}
                    {intl.get("Cost1")}：$0 x {it.paid_quantity} (Inventory
                    Deduction)
                  </span>
                  {item.dsm_payment_status === "Partially paid" && (
                    <Button
                      variant="plain"
                      onClick={() =>
                        handleCancelInventory(item.dsm_record, it.id)
                      }
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              )}
              {it.paid_quantity === it.quantity ? (
                ""
              ) : (
                <span>
                  {intl.get("Cost2")}：${it.price} x{" "}
                  {Number(it.quantity) - Number(it.paid_quantity)}
                </span>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                {intl.get("Cost")}：${it.price} x {it.quantity}
              </span>
            </div>
          );
      }
      return text;
    };
    const shopifyQuantityFun = (item, it) => {
      let text = "";
      if (item) {
        text =
          it.paid_quantity > 0 && item.order_scen === 2 && item.type === 1 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {it.paid_quantity === it.quantity ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Price: <SymbolTake price={it.shopify_price} shopifyPrice={true} /> x {it.paid_quantity}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                 Price: <SymbolTake price={it.shopify_price} shopifyPrice={true} /> x {it.paid_quantity}
                </div>
              )}
              {it.paid_quantity === it.quantity ? (
                ""
              ) : (
                <span>
                 Price: <SymbolTake price={it.shopify_price} shopifyPrice={true} /> x{" "}
                  {Number(it.quantity) - Number(it.paid_quantity)}
                </span>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
              Price: <SymbolTake price={it.shopify_price} shopifyPrice={true} /> x {it.quantity}
              </span>
            </div>
          );
      }
      return text;
    };

    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
      />
    ) : null;

    // 订单地址异常支付提醒
    let addressFailedTips = (
      <Modal
        open={orderPaymentData.addressFailedModal}
        onClose={() => handleAddressFailedTips(false)}
        title={intl.get("Incorrect customer information")}
        primaryAction={{
          content: intl.get("Cancel"),
          onAction: () => handleAddressFailedTips(false),
        }}
        secondaryActions={[
          {
            content: intl.get("Continue to pay"),
            onAction: () => handleAddressFailedOrderToUnpaid(),
            loading: getOrderPaymentData().addressFailedLoading,
          },
        ]}
      >
        <Modal.Section>
          <p>
            {intl.get("The customer information for order")}{" "}
            <span style={{ fontWeight: "500" }}>
              {orderPaymentData.addressFailedOrder}
            </span>{" "}
            {intl.get(
              "is incorrect. This may affect the normal delivery of the order and we suggest correcting the information before paying."
            )}
          </p>
        </Modal.Section>
      </Modal>
    );
    const itemContentNode = (content) => {
      return (
        content.length > 0 &&
        content.map((item, ind) => {
          return (
            <div
              style={{
                marginTop: "15px",
                minHeight: item.show ? "315px" : "111px",
              }}
              key={ind}
            >
              <Card>
                <BlockStack gap={400}>
                  <Box>
                    <div className="orderlist_container">
                      {selectedTab !== 3 && (
                        <div className="orderlist_checkbox">
                          <Checkbox
                            checked={item.checked}
                            onChange={() =>
                              handleSingleCheckBox(
                                item.type,
                                item.dsm_record,
                                item.shopify_record,
                                item.checked,
                                ind
                              )
                            }
                          />
                        </div>
                      )}
                      <div className="orderlist_content">
                        {(item.type === 1 || item.type === 0) && (
                          <div className="list_order_name_item">
                            <div className="list_order_name_title">
                              <span className="logo_img">
                                <img
                                  src="/shop-icon-shopify.png"
                                  alt="dropshipman"
                                />
                              </span>
                              <span
                                className="link"
                                style={{ margin: "0 5px" }}
                              >
                                <Link
                                  // external
                                  url=""
                                  onClick={() =>
                                    handleToggle(
                                      item.dsm_record,
                                      item.shopify_record,
                                      item.show,
                                      ind
                                    )
                                  }
                                >
                                  {item.shopify_order_name}
                                </Link>
                              </span>
                              <a
                                href={item.shopify_order_uri}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="a_target_link"
                              >
                                <Icon source={LinkIcon} color="base" />
                              </a>
                            </div>
                            <div className="list_order_name_date">
                              <span className="time">{item.shopify_date}</span>
                            </div>
                            <div>
                              <span className="status">
                                <ToolTag
                                  content={item.shopify_financial_status}
                                ></ToolTag>
                              </span>
                            </div>
                            <div>
                              <span className="status">
                                <ToolTag
                                  content={item.shopify_fulfillment_status}
                                ></ToolTag>
                              </span>
                            </div>
                          </div>
                        )}

                        {item.type !== 0 && (
                          <div
                            className="list_order_name_item"
                            style={{ marginBottom: "0" }}
                          >
                            <div className="list_order_name_title">
                              <span className="logo_img">
                                <img src="/favicon.ico" alt="" />
                              </span>
                              {/* 只有订单类型为1或者0时点击展开详情 */}
                              <span
                                className={`link ${
                                  item.type !== 0 && item.type !== 1
                                    ? "type_link"
                                    : ""
                                }`}
                                style={{
                                  color:
                                    item.type !== 0 && item.type !== 1
                                      ? "#2c6ecb"
                                      : "#202223",
                                }}
                                onClick={() =>
                                  item.type !== 0 && item.type !== 1
                                    ? handleToggle(
                                        item.dsm_record,
                                        item.shopify_record,
                                        item.show,
                                        ind
                                      )
                                    : ""
                                }
                              >
                                {item?.suffix !== ""
                                  ? item.dsm_order_name + item?.suffix
                                  : item.dsm_order_name}
                              </span>
                            </div>
                            <div className="list_order_name_date">
                              <span className="time">{item.dsm_date}</span>
                            </div>
                            <div>
                              <span className="status">
                                {/* <ToolTag
                                content={item.dsm_payment_status}
                              ></ToolTag> */}
                                {item.dsm_payment_status === "Paid" ? (
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      maxWidth: "100%",
                                      minWidth: "68px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      minHeight: "26px",
                                      padding: "2px 8px",
                                      backgroundColor: "rgb(187 233 179)",
                                      borderRadius: "16px",
                                      color: "rgba(32,34,35,1)",
                                      outline: " 1px solid transparent",
                                    }}
                                  >
                                    {item.dsm_payment_status}
                                  </span>
                                ) : !shopifyLevel() &&
                                  (item.dsm_payment_status === "Unpaid" ||
                                    item.dsm_payment_status ===
                                      "Pending Sourcing") ? (
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      maxWidth: "100%",
                                      minWidth: "68px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      minHeight: "26px",
                                      padding: "2px 8px",
                                      backgroundColor: "#202333",
                                      color: "#fff",
                                      borderRadius: "16px",
                                      outline: " 1px solid transparent",
                                    }}
                                  >
                                    {item.dsm_payment_status}
                                  </span>
                                ) : (
                                  <ToolTag
                                    content={item.dsm_payment_status}
                                  ></ToolTag>
                                )}
                              </span>
                            </div>
                            <div>
                              <span className="status">
                                <ToolTag
                                  content={item.dsm_shipment_status}
                                ></ToolTag>
                              </span>
                            </div>
                          </div>
                        )}
                        {item.type === 0 &&
                          item?.order_detail?.dsm.length === 0 &&
                          (item?.order_detail?.no_source.length > 0 ||
                            item?.order_detail?.pending.length > 0) && (
                            <div
                              className="list_order_name_item"
                              style={{ marginBottom: "0" }}
                            >
                              {item?.order_sourcing_record &&
                                item?.order_sourcing_record !== "" && (
                                  <div className="list_order_name_title">
                                    <span className="logo_img">
                                      <img src="/favicon.ico" alt="" />
                                    </span>
                                    {/* 只有订单类型为1或者0时点击展开详情 */}
                                    <span>{item?.order_sourcing_record}</span>
                                  </div>
                                )}
                              {item?.order_sourcing_created_at &&
                                item?.order_sourcing_created_at !== "" && (
                                  <div className="list_order_name_date">
                                    <span className="time">
                                      {item?.order_sourcing_created_at}
                                    </span>
                                  </div>
                                )}
                              {item?.order_sourcing_record &&
                                item?.order_sourcing_record !== "" && (
                                  <div>
                                    <span className="status">
                                      <span
                                        style={{
                                          display: "inline-flex",
                                          maxWidth: "100%",
                                          minWidth: "68px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          minHeight: "26px",
                                          padding: "2px 8px",
                                          backgroundColor: "#202333",
                                          borderRadius: "16px",
                                          color: "#fff",
                                          outline: " 1px solid transparent",
                                        }}
                                      >
                                        {intl.get("Pending Sourcing")}
                                      </span>
                                    </span>
                                  </div>
                                )}
                            </div>
                          )}
                      </div>

                      {/* 按钮区域 */}
                      <div className="button_container">
                        <div className="customer_btn">
                          <div
                            className="customer_warring"
                            style={{ display: "none" }}
                          >
                            <img src="/archived_warring.png" alt="" />
                          </div>
                          <div className="order_customer">
                            {item.type === 0 ||
                            item.type === 1 ||
                            item.type === 4 ? (
                              <OrderShippingAddress
                                id={item.dsm_record}
                                sid={item?.shopify_record}
                                type={item?.type}
                                countryCode={item?.country_code}
                                paymentStatus={item?.dsm_payment_status}
                                addressStatus={item?.address_failed}
                                getSearchParam={getSearchParam}
                                reloadData={getOrderListData}
                                setListLoading={setListLoading}
                              />
                            ) : (
                              <div className="No_Customer"></div>
                            )}
                          </div>
                        </div>

                        {(item.type === 1 || item.type === 4) && (
                          <div className="shipp_methods_text">
                            <Button
                              textAlign="left"
                              disclosure={"down"}
                              onClick={() => {
                                setIsShowShippingMethodsModal(true);
                                setOrderId(item.dsm_record);
                                setOrderPayStatus(item.dsm_payment_status);
                              }}
                            >
                              {item.dsm_shipping !== ""
                                ? item.dsm_shipping
                                : intl.get("Shipping methods")}
                            </Button>
                            {/* <Tooltip
                            preferredPosition="above"
                            dismissOnMouseOut
                            content={intl.get("Except for DSM, YunExpress and WANB, any order under the other shipping options that arrived 30+ days after the estimated shipping time is not eligible for aftersales service.")}
                          >
                            <span
                              style={{ display: "flex", marginLeft: "10px" }}
                            >
                              <Icon
                                source={CircleInformationMajor}
                                color="highlight"
                              />
                            </span>
                          </Tooltip> */}
                          </div>
                        )}

                        {item.type === 1 && (
                          <div style={{ marginLeft: 16 }}>
                            <Button
                              textAlign="left"
                              disclosure={"down"}
                              onClick={(e) => {
                                e.stopPropagation();
                                setisShowPackingOrderModal(true);
                                setCurrentOrderData((prevValue) => ({
                                  ...prevValue,
                                  data: item,
                                }));
                              }}
                            >
                              {intl.get("Packaging")}
                            </Button>
                          </div>
                        )}
                      </div>

                      <div className="iconBtn_container">
                        {/* 订单sourcing */}
                        {!searchSourcingData?.ids.includes(
                          item?.shopify_record
                        ) &&
                          item?.order_detail?.no_source?.length > 0 && (
                            <div>
                              <Button
                                onClick={() =>
                                  handleOneSourcingRequest(
                                    true,
                                    true,
                                    item.shopify_record
                                  )
                                }
                              >
                                Sourcing Request
                              </Button>
                            </div>
                          )}
                        {/* 物流轨迹 只有以下三个tab状态下才能查看物流轨迹 */}
                        {(item.dsm_payment_status === "Paid" ||
                          item.dsm_payment_status === "Partially refund") &&
                          (item.type === 1 || item.type === 4) && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setTrackModal(true);
                                setOrderId(item.dsm_record);
                              }}
                            >
                              <img
                                src="/order_tracking.png"
                                alt="dropshipman"
                                style={{
                                  width: "42px",
                                  height: "30px",
                                  marginTop: "6px",
                                }}
                              />
                            </div>
                          )}

                        <div
                          style={{ cursor: "pointer" }}
                          title={item.dsm_note}
                          onClick={() => {
                            item.type === 0
                              ? setNoteShow(true)
                              : setIsShowOrderNoteModal(true);
                            setOrderId(item.dsm_record);
                            setOrderNote(item.dsm_note);
                            setOrderNoteShopify(item.shopify_note);
                          }}
                        >
                          {((item.type === 0 && item.shopify_note !== "") ||
                            item.type !== 0) && (
                            <img
                              src="/flow.png"
                              alt="dropshipman"
                              height={36}
                              style={{ marginTop: "4px" }}
                            />
                          )}
                        </div>

                        {(item.type === 1 || item.type === 4) &&
                          (item.dsm_shipment_status === "Fulfilled" || item.dsm_shipment_status === "Delivered") && (
                            <div className="order_invices">
                              <img
                                onClick={() =>
                                  handleOpenInvoices(item.dsm_record)
                                }
                                src="/blackFriday/invoicesIcon.png"
                                alt="dropshipman"
                              />
                              <div className="order_note_tip">
                                {intl.get("Invoice")}
                                <span className="order_note_tip_down"></span>
                              </div>
                            </div>
                          )}
                        {/* 面单 */}
                        {/* {(item.type === 1 || item.type === 4) &&
                        item?.dsm_shipment_status === "Fulfilled" &&
                        item?.shipping_label !== "" && (
                          <div className="order_invices">
                            <img
                              onClick={() =>
                                handleOpenShippingLabel(item?.shipping_label)
                              }
                              src="/shopping_label.png"
                              alt="dropshipman"
                            />
                            <div className="order_note_tip">
                            {intl.get("Shipping label")}
                              <span className="order_note_tip_down"></span>
                            </div>
                          </div>
                        )} */}
                        {item.type !== 2 &&
                          item.is_back !== 1 &&
                          item.dsm_payment_status === "Paid" && (
                            <div className="refund_btn">
                              <Button
                                onClick={() => {
                                  setIsShowRefundTipsModal(true);
                                  setCurrentOrderData((prevValue) => ({
                                    ...prevValue,
                                    data: item,
                                  }));
                                }}
                              >
                                {intl.get("Refund")}
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>
                  </Box>

                  {/* Collapsible */}
                  <div className="collapsibleContainer">
                    <Collapsible
                      open={item.show}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <BlockStack gap={400}>
                        {item.type !== 0 && (
                          <Box style={{ marginTop: "10px" }}>
                            <div>
                              {item.type === 2 && (
                                <p>{intl.get("Custom packaging order")}</p>
                              )}
                              {item.type === 3 && (
                                <p>{intl.get("Wholesale order")}</p>
                              )}
                              {item.type === 4 && (
                                <p>{intl.get("Custom order")}</p>
                              )}
                            </div>
                            {item.order_detail?.dsm.length > 0 &&
                              item.order_detail?.dsm.map((it, k) => (
                                <div className="goods_container" key={k}>
                                  <div className="goods_content">
                                    <div>
                                      <img
                                        src={
                                          it.image_url !== ""
                                            ? it.image_url
                                            : "/default_image.png"
                                        }
                                        alt="dropshipman"
                                      />
                                    </div>
                                    <div>
                                      <p
                                        className="order_sku_title"
                                        title={it.title}
                                      >
                                        <Tooltip content={it.title}>
                                          {it.title}
                                        </Tooltip>
                                      </p>
                                      <p
                                        className="order_sku_sku"
                                        title={it.variant_title}
                                      >
                                        {it.variant_title}
                                      </p>
                                      <p
                                        className="order_sku_sku"
                                        title={it.sku}
                                      >
                                        {it.sku}
                                      </p>
                                      {item.type === 1 && <div className="shopify_price">
                                      {shopifyQuantityFun(item, it)}
                                      </div>}
                                    </div>
                                  </div>

                                  {costQuantityFun(item, it)}

                                  <div>
                                    {intl.get("Total")}：
                                    <SymbolTake price={it.total} fontWeight={450}/>
                                  </div>

                                  {/* {item.dsm_payment_status ===
                                "Partially refund" && (
                                <div
                                  className="Order_Detail_Sku_Refund"
                                  style={{ marginRight: "6px" }}
                                >
                                  {item.type === 1 && it.refund_count > 0 && (
                                    <Tooltip
                                      dismissOnMouseOut
                                      content={
                                        it.quantity > it.refund_count
                                          ? `Partially Refund (Shopify): ${it.refund_count}`
                                          : "Refund (Shopify)"
                                      }
                                    >
                                      <Badge status={"critical"}>
                                        {it.quantity > it.refund_count
                                          ? `Partially Refund (Shopify): ${it.refund_count}`
                                          : "Refund (Shopify)"}
                                      </Badge>
                                    </Tooltip>
                                  )}
                                  {item.type === 4 &&
                                    it.dsm_refund_count > 0 && (
                                      <Tooltip
                                        dismissOnMouseOut
                                        content={
                                          it.quantity > it.dsm_refund_count
                                            ? `Partially Refund (DSM): ${it.dsm_refund_count}`
                                            : "Refund (DSM)"
                                        }
                                      >
                                        <Badge status={"critical"}>
                                          {it.quantity > it.dsm_refund_count
                                            ? `Partially Refund (DSM): ${it.dsm_refund_count}`
                                            : "Refund (DSM)"}
                                        </Badge>
                                      </Tooltip>
                                    )}
                                </div>
                              )} */}

                                  {(item.type === 1 ||
                                    item.type === 4 ||
                                    item.type === 3) && (
                                    <div
                                      className="Order_Detail_Sku_Refund"
                                      style={{ marginRight: "6px" }}
                                    >
                                      <div>
                                        {it.refund_count > 0 && (
                                          <Tooltip
                                            dismissOnMouseOut
                                            content={
                                              it.quantity > it.refund_count
                                                ? intl.get(
                                                    `Partially Refund (Shopify)`
                                                  ) + `: ${it.refund_count}`
                                                : intl.get("Refund (Shopify)")
                                            }
                                          >
                                            <Badge tone={"critical"}>
                                              {it.quantity > it.refund_count
                                                ? intl.get(
                                                    `Partially Refund (Shopify)`
                                                  ) + `: ${it.refund_count}`
                                                : intl.get("Refund (Shopify)")}
                                            </Badge>
                                          </Tooltip>
                                        )}
                                      </div>
                                      <div style={{ marginTop: "5px" }}>
                                        {it.dsm_refund_count > 0 && (
                                          <Tooltip
                                            dismissOnMouseOut
                                            content={
                                              it.quantity > it.dsm_refund_count
                                                ? intl.get(
                                                    `Partially Refund (DSM)`
                                                  ) + `: ${it.dsm_refund_count}`
                                                : intl.get("Refund (DSM)")
                                            }
                                          >
                                            <Badge tone={"critical"}>
                                              {it.quantity > it.dsm_refund_count
                                                ? intl.get(
                                                    `Partially Refund (DSM)`
                                                  ) + `: ${it.dsm_refund_count}`
                                                : intl.get("Refund (DSM)")}
                                            </Badge>
                                          </Tooltip>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {item?.order_scen === 2 &&
                                  item?.order_detail?.no_source.length === 0 &&
                                  item?.order_detail?.pending.length === 0 &&
                                  (item?.dsm_payment_status === "Unpaid" ||
                                    item.dsm_payment_status ===
                                      "Partially paid") &&
                                  it?.paid_quantity === 0 ? (
                                    <div>
                                      <Button
                                        onClick={() =>
                                          handleOrderSkuDel(item, it)
                                        }
                                      >
                                        {intl.get("Cancel")}
                                      </Button>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              ))}
                          </Box>
                        )}

                        {item.order_detail?.pending.length > 0 && (
                          <Box style={{ marginTop: "16px" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "16px",
                              }}
                            >
                              <img
                                width={20}
                                src="/dropshipman-logo.png"
                                alt="dropshipman"
                              />
                              <p
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                {item.order_detail?.pending.length > 0 &&
                                  intl.get("Pending sourcing")}
                              </p>
                            </div>
                            {item.order_detail?.pending.length > 0 &&
                              item.order_detail?.pending.map((it, k) => (
                                <div key={k} className="goods_container">
                                  <div className="goods_content">
                                    <div>
                                      <img
                                        src={
                                          it.image_url !== ""
                                            ? it.image_url
                                            : "/default_image.png"
                                        }
                                        alt="dropshipman"
                                      />
                                    </div>
                                    <div>
                                      <p
                                        className="order_sku_title"
                                        title={it.title}
                                      >
                                        {it.title}
                                      </p>
                                      <p title={it.variant_title}>
                                        {it.variant_title}
                                      </p>
                                      <p title={it.sku}>{it.sku}</p>
                                    </div>
                                  </div>

                                  {costQuantityFun(item, it)}

                                  <div>
                                    {intl.get("Total")}: <SymbolTake price={it.total} />
                                  </div>
                                  <div>
                                    <div>
                                      {it.is_sourcing === 2 && (
                                        <ToolTag
                                          content={"In sourcing"}
                                        ></ToolTag>
                                      )}
                                      {it.is_sourcing === 0 && (
                                        <ToolTag
                                          content={"Await Sourcing"}
                                        ></ToolTag>
                                      )}
                                      {it.is_sourcing === 3 && (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ToolTag
                                            content={"Sourcing Failed"}
                                          ></ToolTag>
                                          <div style={{ marginLeft: "6px" }}>
                                            <Popover
                                              autofocusTarget="container"
                                              active={
                                                sourceFailed &&
                                                sourceFailedId === it.id
                                              }
                                              onClose={() => {
                                                setSourceFailed(false);
                                                setSourceFailedTip("");
                                                setSourceFailedId(0);
                                              }}
                                              activator={
                                                <div
                                                  onClick={() =>
                                                    handleFailedTip(it.id)
                                                  }
                                                >
                                                  <Icon
                                                    source={QuestionCircleIcon}
                                                    backdrop={true}
                                                  />
                                                </div>
                                              }
                                            >
                                              <div
                                                style={{
                                                  minWidth: "100px",
                                                  padding: 10,
                                                  minHeight: 40,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                {sourceFailedTip === "" ? (
                                                  <div
                                                    style={{ width: "20px" }}
                                                  >
                                                    <Spinner size="size" />
                                                  </div>
                                                ) : (
                                                  <div>{sourceFailedTip}</div>
                                                )}
                                              </div>
                                            </Popover>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* {it.is_sourcing === 2 && <Button loading={sourcingLoading && sourcingIdLoading === it.id} onClick={()=>handleSourcingData(it.id)}>Sourcing Request</Button>} */}
                                  </div>

                                  <div>{/* <Button>Sourcing</Button> */}</div>
                                </div>
                              ))}
                          </Box>
                        )}

                        {item.order_detail?.no_source.length > 0 && (
                          <Box style={{ marginTop: "16px" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "16px",
                              }}
                            >
                              <img
                                width={20}
                                src="/dropshipman-logo.png"
                                alt="dropshipman"
                              />
                              <p
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                {item.order_detail?.no_source.length > 0 &&
                                  intl.get("Not sourced")}
                              </p>
                            </div>
                            {item.order_detail?.no_source.length > 0 &&
                              item.order_detail?.no_source.map((it, k) => (
                                <div key={k} className="goods_container">
                                  <div className="goods_content">
                                    <div>
                                      <img
                                        src={
                                          it.image_url !== ""
                                            ? it.image_url
                                            : "/default_image.png"
                                        }
                                        alt="dropshipman"
                                      />
                                    </div>
                                    <div>
                                      <p
                                        className="order_sku_title"
                                        title={it.title}
                                      >
                                        {it.title}
                                      </p>
                                      <p className="order_sku_sku">
                                        {it.variant_title}
                                      </p>
                                      <p className="order_sku_sku">{it.sku}</p>
                                    </div>
                                  </div>

                                  {costQuantityFun(item, it)}

                                  <div>
                                    {intl.get("Total")}：<SymbolTake price={it.total} />
                                  </div>
                                  <div>
                                    {it.is_sourcing === 0 && (
                                      <ToolTag
                                        content={"Await Sourcing"}
                                      ></ToolTag>
                                    )}
                                    {/* {it.is_sourcing === 3 && <ToolTag content={"In sourcing"}></ToolTag>}
                            {it.is_sourcing === 4 && <ToolTag content={"Sourcing Failed"}></ToolTag>} */}
                                  </div>

                                  <div>
                                    {/* {it.is_sourcing === 2 && <Button loading={sourcingLoading && sourcingIdLoading === it.id} onClick={()=>handleSourcingData(it.id)}>Sourcing Request</Button>} */}
                                    {/* <Button
                                  onClick={() => SourcingOrderModalOpen(it.id)}
                                >
                                 {intl.get("Sourcing")}
                                </Button> */}
                                  </div>
                                </div>
                              ))}
                          </Box>
                        )}
                        {cost(item, ind)}
                      </BlockStack>
                    </Collapsible>
                  </div>
                </BlockStack>
              </Card>
            </div>
          );
        })
      );
    }; //主体内容模块

    return (
      <div>
        {/* 主内容 */}
        {itemContentNode(content ? content : [])}
        {toastMarkup}

        {/* 订单备注弹窗 */}
        <OrderNoteModal
          orderId={orderId}
          note={orderNote}
          content={content}
          setOrderPageData={setOrderPageData}
          open={isShowOrderNoteModal}
          setOrderNote={setOrderNote}
          setIsShowOrderNoteModal={() => setIsShowOrderNoteModal(false)}
        />
        {/* sourcing */}
        <UnknownSkuSourcing
          sourceData={sourceData}
          SourcingOrderModalOpen={SourcingOrderModalOpen}
          handleChangeSourcingOrder={handleChangeSourcingOrder}
        />
        {/* 订单备注展示 顾客*/}
        <CustomerOrderNotesModal
          open={noteShow}
          setOrderNote={setOrderNote}
          setCustomerOrderNotesModal={() => setNoteShow(false)}
          note={orderNoteShopify}
        />
        {/* 退款详情弹窗 销售绑定信息 */}
        <RefundTipsModal
          open={isShowRefundTipsModal}
          datas={getCurrentOrderData().data}
          setIsShowRefundTipsModal={() => setIsShowRefundTipsModal(false)}
        />
        {/* 品牌包装弹窗 */}
        <PackingOrderModal
          item={currentOrderData.data}
          orderId={orderId}
          open={isShowPackingOrderModal}
          setisShowPackingOrderModal={setisShowPackingOrderModal}
        />
        {/* sku移除弹窗 */}
        <CancelSkuModal
          setListLoading={setListLoading}
          currentOrderData={currentOrderData}
          getSearchParam={getSearchParam}
          getOrderListData={getOrderListData}
          setCurrentOrderData={setCurrentOrderData}
          skuCount={2} //skuCount sku数量 >1 移除多个
        />
        {/* 全额/部分退款订单支付提醒 */}
        <OrderPaymentRefundTipModal
          orderData={currentOrderData.orderData}
          orderPaymentData={orderPaymentData}
          setOrderPaymentData={setOrderPaymentData}
          handlePaymentMethodSelect={handlePaymentMethodSelect}
        />
        {/* 物流方式弹窗 */}
        <ShippingMethodsModal
          orderId={orderId}
          getSearchParam={getSearchParam}
          getOrderListData={getOrderListData}
          open={isShowShippingMethodsModal}
          orderPayStatus={orderPayStatus}
          setIsShowShippingMethodsModal={() =>
            setIsShowShippingMethodsModal(false)
          }
        />

        {/* 物流追踪弹窗 */}
        <OrderTracking
          show={trackModal}
          orderId={orderId}
          handleShowTracking={handleShowTracking}
        />

        {/* 支付弹窗 */}
        {/* <BulkPaymentModal
          paymentData={paymentData}
          setPayoneerBalances={setPayoneerBalances}
          setPayoneerConnectOpen={setPayoneerConnectOpen}
          setOrderPaymentData={setOrderPaymentData}
          payoneerAuth={payoneerAuth}
          handlePayForOrderModal={handlePayForOrderModal}
          setIsShowBulkPaymentModal={() =>
            setPaymentData((prevValue) => ({
              ...prevValue,
              isShowBulkPaymentModal: false,
            }))
          }
          orderIds={currentOrderData.currentItem}
          walletMoney={walletMoney}
        /> */}

        {/* <BulkPaymentModal
          data={getOrderPaymentData()}
          setPayoneerBalances={setPayoneerBalances}
          setPayoneerConnectOpen={setPayoneerConnectOpen}
          setOrderPaymentData={setOrderPaymentData}
          payoneerAuth={payoneerAuth}
          // setPaymentModalActive={setPaymentModalActive}
          callback={handlePayForOrderModal}
        />
         */}
        {/* 单笔支付订单sku未sourcing完成弹窗 */}
        <NotSourcingOneCompleteModal
          open={orderPaymentData.isOneNotSourcing}
          setPaymentData={setPaymentData}
          setOrderPaymentData={setOrderPaymentData}
        />
        {/* 支付地址异常 */}
        {addressFailedTips}
        {/* 开票弹窗 */}
        <OrderInvoiceModal
          active={invoicesActive}
          handleChange={handleChange}
          invoicesLoading={invoicesLoading}
          onInvoices={onInvoices}
          invoicesData={invoicesData}
          invoicesForm={invoicesForm}
          onInvoicesSubmit={onInvoicesSubmit}
        />
      </div>
    );
  }
);

export default connect()(OrderItem);
