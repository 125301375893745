import React, { useState, memo, useEffect } from "react";
import { Modal, RadioButton, Toast } from "@shopify/polaris";
import {
  reqGetOrderShippingMethods,
  reqPostUpdateOrderShippingMethods,
} from "../../../../../../api";
import { useGetState } from "ahooks";
import intl from "../../../../../../i18n/intl";

const ShippingMethodsModal = memo(
  ({
    orderId,
    open,
    setIsShowShippingMethodsModal,
    getSearchParam,
    getOrderListData,
    orderPayStatus
  }) => {
    const [loading, setLoading] = useState(false); //加载
    const [buttonLoading, setButtonLoading] = useState(false); //按钮加载
    const [shippingData, setShippingData, getShippingData] = useGetState([]);

    const handleShippingRadioChange = (key) => {
      // 单选按钮点击事件
      const result = shippingData.map((item, index) => {
        if (index === key) {
          return { ...item, selected: 1 };
        } else {
          return { ...item, selected: 0 };
        }
      });
      setShippingData(result);
    };
    // 关闭
    const handleOff = () => {
      setIsShowShippingMethodsModal();
      setShippingData([]);
    };
    const handleDoneChange = () => {
      //确认请求
      const data = getShippingData();
      // const id = data.filter(item => item.selected === 1)[0].id
      postUpdateOrderShippingMethods(data);
    };
    useEffect(() => {
      let isMount = true;
      if (open && isMount) {
        getData();
      }
      return () => (isMount = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]); //数据初始化

    const getData = () => {
      setLoading(true);
      initData(orderId);
    };

    const initData = async (orderId) => {
      let res = await reqGetOrderShippingMethods({ id: orderId });
      if (res.code === "200") {
        setShippingData(res.data);
        setLoading(false);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }; //接口请求
    // 保存
    const postUpdateOrderShippingMethods = async (data) => {
      let params = {
        id: orderId,
        shipping_result: data,
      };
      setButtonLoading(true);
      try {
        let res = await reqPostUpdateOrderShippingMethods(params);
        if (res.code === "200") {
          setButtonLoading(false);
          getOrderListData(getSearchParam);
          handleOff();
          toggleToast('Saved successfully')
        } else {
          setTimeout(() => {
            setButtonLoading(false);
          }, 1000);
        }
      } catch (error) {}
    };

    const titleContentNode = (
      <div className="shipping_methods_content">
        <div>{intl.get("Shipping Methods")}</div>
        {/* <div className="shipping_methods_content_tips">
        <Banner tone="info" title={intl.get("Except for DSM, YunExpress and WANB, any order under the other shipping options that arrived 30+ days after the estimated shipping time is not eligible for aftersales service.")}></Banner>
        </div> */}
      </div>
    );

    const emptyContentNode = (
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="/empty.svg"
            alt=""
            style={{ weight: "236px", height: "236px" }}
          />
        </div>
        <p style={{ textAlign: "center", marginTop: "25px", fontSize: "15px" }}>
        {intl.get(`No supported shipping method, please contact your dedicated manager
          for a consultation.`)}
        </p>
      </>
    );

    const shippingContentNode = (
      <>
        <table style={{ width: "100%" }}>
          <thead
            style={{ borderBottom: "1px solid #D8D8D8", tableLayout: "fixed" }}
          >
            <tr>
            <th align="left" style={{ width: "40%", paddingBottom: "8px" }}>
            {intl.get("Shipping Method")}
            </th>
            <th align="left" style={{ width: "30%", paddingBottom: "8px" }}>
            {intl.get("Estimated Delivery Time")}
            </th>
            <th align="left" style={{ width: "30%", paddingBottom: "8px" }}>
            {intl.get("Shipping Cost")}
            </th>
            </tr>
          </thead>
          <tbody
            style={{ maxHeight: "200px", overflowY: "auto", width: "100%" }}
          >
            {shippingData.length > 0 &&
              shippingData.map((item, key) => {
                return (
                  <tr style={{ borderBottom: "1px solid #D8D8D8" }} key={key}>
                    <td
                      align="left"
                      style={{ width: "40%", padding: "10px 0" }}
                    >
                      <RadioButton
                        label={item.ShippingMethod}
                        id={key}
                        name="shipping methods"
                        checked={item.selected === 1}
                        onChange={() => handleShippingRadioChange(key)}
                      />
                    </td>
                    <td
                      align="left"
                      style={{ width: "30%", padding: "10px 0" }}
                    >
                      {item.DeliveryTime}
                    </td>
                    <td
                      align="left"
                      style={{ width: "30%", padding: "10px 0" }}
                    >
                      US${item.BeforeConvertCost} {item.currency_code}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);
    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };
    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
      />
    ) : null;
    return (
      <div>
        <Modal
          size="large"
          open={open}
          loading={loading}
          onClose={() => handleOff()}
          title={titleContentNode}
          primaryAction={
            shippingData.length > 0 && {
              content: intl.get("Done"),
              onAction: handleDoneChange,
              loading: buttonLoading,
              disabled:orderPayStatus !== '' && (orderPayStatus === 'Paid' || orderPayStatus === 'Refund' || orderPayStatus === 'Partially refund')
            }
          }
          secondaryActions={
            [
              {
                content: intl.get("Close"),
                onAction: () => handleOff(),
              },
            ]
          }
        >
          <Modal.Section>
            {shippingData.length > 0 ? shippingContentNode : emptyContentNode}
          </Modal.Section>
        </Modal>
        {toastMarkup}
      </div>
    );
  }
);

export default ShippingMethodsModal;
