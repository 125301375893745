import React, { Component } from "react";
import { Modal } from "@shopify/polaris";
import { reqGetMessageList, reqPostUpdateMessageRead } from "./api";
import intl from "./i18n/intl";

class PopMessagesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalShow: false,
      messageList: [],
      //弹窗
      showToast: false, //弹窗显示
      showToastText: "", //弹窗文字
      showToastIserror: false, //弹窗是否错误
    };
  }

  //进入页面调用接口
  componentDidMount() {
    // console.log(this.props);
    // if(this.props.name !== 'messages' && this.pop){
    //     this.getMessageList();
    // }
  }

  // 再次渲染组件
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.name !== "messages" && nextProps.pop) {
      setTimeout(() => {
        this.getMessageList();
      }, 500);
    }
  }

  // 获取站内信数据
  getMessageList = async () => {
    let response = await reqGetMessageList({ pop: 1 });
    if (response.code === "200") {
      let PopList = [];
      response.data.result.map((item, key) => {
        if (item.is_read === 0 && item.pop_reminder === 1) {
          item.props_id = key;
          PopList.push(item);
        }
        return item.props_id;
      });
      this.setState({
        ModalShow: PopList.length > 0,
        messageList: PopList,
      });
    } else {
      this.toggleToast(response.msg);
    }
  };

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    }));
  };

  // 更新站内信（已读）
  updateMessage = async (id) => {
    const params = {
      id: id,
    };
    this.props.setClose();
    // 更新为已读
    await reqPostUpdateMessageRead(params);
  };

  // 弹窗控制
  handleChangeModal = () => {
    // 更新站内信（已读）
    this.props.getDsmPupop();

    // 站内信记录id数组
    let msg_ids = this.state.messageList.map((item) => {
      return item.id;
    });

    // 更新站内信（已读）
    this.updateMessage(msg_ids);

    this.setState({
      ModalShow: !this.state.ModalShow,
    });
  };

  render() {
    const {
      state: { ModalShow },
    } = this;
    return (
      <div>
        <Modal
          open={ModalShow}
          onClose={this.handleChangeModal}
          title={intl.get("")}
          secondaryActions={[
            {
              content: intl.get("Close"),
              onAction: this.handleChangeModal,
            },
          ]}
          size="large"
        >
          <Modal.Section>
            {this.state.messageList.map((item, key) => {
              return [
                <Modal.Section key={key}>
                  <p
                    style={{
                      fontSize: "2.3rem",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#000",
                      lineHeight: "2rem",
                      margin: "5px 0 30px",
                    }}
                  >
                    {item?.title}
                  </p>
                  <p
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      textAlign: "right",
                      color: "#637381",
                      lineHeight: "1.6rem",
                      margin: "5px 0",
                      marginBottom: "15px",
                    }}
                  >
                    {item.show_at}
                  </p>
                  <div
                    style={{
                      fontSize: "2rem",
                      lineHeight: 1.5,
                      minHeight: key >= 1 && "300px",
                    }}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                </Modal.Section>,
              ];
            })}
          </Modal.Section>
        </Modal>
      </div>
    );
  }
}

export default PopMessagesModal;
