import { Button } from '@shopify/polaris';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from "../../../../utils/utils"
import TimeCloseBox from "../../../../components/TimeCloseBox"
const TopDrawerItem = ({handleTopDrawer})=>{
    const router = useHistory()
    return (
        <div className='black5_top_banner'>
            <div className='black5_top_banner_item'>
            <div className='top_drawer_content_title'>
                <div className='top_drawer_content_logo'>
                <img className='box box_1' src="/blackFriday/bfcm_1.png" alt="dropshipman" />
                <img className='box box_2' src="/blackFriday/bfcm_2.png" alt="dropshipman" />
                </div>
                <div className='top_drawer_content_off'>
            <img src="/blackFriday/top_fixed_off.png" alt="dropshipman" />
            </div>
            </div>
            <div className='top_drawer_content_desc top_drawer_content_desc_time'>
                <p>
                {/* Boost your Black Friday sales success!   */}
                Valid: Nov 1 - Dec 5
                </p>
                <div><TimeCloseBox /></div>
            </div>
            <div className='black5_top_banner_item_button'>
                <Button onClick={()=>{
                    utils.routeListen('/admin/black-friday')
                    utils.routeJump1(router,'/admin/black-friday')
                }}>Claim Now</Button>
            </div>
            <div className='raffle_bg_top'>
                <img src="/blackFriday/raffle_bg_top.png" alt="dropshipman" />
            </div>
            <div className='black5_top_banner_item_close'><img onClick={()=>{handleTopDrawer()}} src="/blackFriday/top_close.png" alt="dropshipman" /></div>
           
            </div>

            {/* <div className='top_drawer_content_item'>
            <div className='top_drawer_content_img'><img src="/blackFriday/flower.png" alt="dropshipman" /></div>
            <div className='top_drawer_content_title'>2024 Black Friday Mega Event in Progress!</div>
            <div className='top_drawer_content_button'><Button onClick={()=>{utils.routeListen('/admin/black-friday')
 utils.routeJump1(router,'/admin/black-friday')}}>View details</Button></div>
            </div>
            <div className='top_drawer_content_close'><img onClick={()=>handleTopDrawer()} src="/blackFriday/top_close.png" alt="dropshipman" /></div> */}
        </div>
    );
}

export default TopDrawerItem;
