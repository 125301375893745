import React, { useState, memo } from "react";
import {
  Icon,
  Button,
  Toast,
  Banner,
  BlockStack,
  Modal,
  InlineStack,
} from "@shopify/polaris";
// import { QuestionCircleIcon } from "@shopify/polaris-icons";
import Switch from "react-switch";
import AutoFulfillmentModal from "../Modal/AutoFulfillmentModal";
import AoutoSourcingModal from "../Modal/AoutoSourcingModal";
import SyncStoreOrdersModal from "../Modal/SyncStoreOrdersModal";
import { useGetState } from "ahooks";
import {
  reqPostSyncProcess,
  reqPostSyncShopifyOrders,
  reqPostUpdateOrderPaymentConfig,
  reqPostUpdateSyncProcess,
  reqPostUpdateOrderSourcingConfig
} from "../../../../../api";
import dayjs from "dayjs";
import intl from "../../../../../i18n/intl";
import { CheckCircleIcon } from "@shopify/polaris-icons";
import { useEffect } from "react";
import {store} from '../../../../../store/index';
import UpgradePlanModal from '../../../SubscriptionPaid/components/UpgradePlanModal';
import { connect, useSelector} from "react-redux";
import LevelAction from "../../../../../components/LevelAction";
var aliNumSourcing = 1;
var dalayTime = 5 * 1000;
const OrderFulfillment = memo(
  ({ syncLogData, getSearchParam, getOrderListData,setOrderPageData ,setSyncList}) => {
    const [switchChecked, setSwitchChecked] = useState(false); //记录switch切换值
    const [switchSourcingOpen, setSwitchSourcingOpen] = useState(false); //记录switch切换值
    const [syncData, setSyncData, getSyncData] = useGetState({
      SyncStoreOrdersModalOpen: false, //显示弹窗
      asyncStoreOrderTotal: 0, //显示订单数量
      disabledSync: false,
      SyncBtnLoading: false, //加载状态
      showToast: false,
      isSyncFinish: false,
      SyncCompletedData: {}, //完成同步订单数据
      showSyncBanner: false, //是否显示横幅
      finishAsyncStoreOrderCount: 0,
      synchroStatus: 1, //同步数量及状态
      synchroCount: 0,
      SyncCompletedModalOpen: false, //同步成功
    });
    const [timers, setTimers] = useState([]) // 定时器数组
    const language = useSelector(state => state.languageReducer.language);
 	const [upgradeModal,setUpgradeModal] = useState(false);//升级会员限制
    // 进入页面判断是否需要轮询
    useEffect(() => {
      let isMount = true;
      if (isMount) {
        let syncLog =
          Object.values(syncLogData).length > 0 ? syncLogData.sync_log : [];
        if (Object.keys(syncLog).length) {
          if (syncLog.is_sync === 1) {
            setSyncData((prevValue) => {
              const params = {
                ...prevValue,
                isSyncFinish: false,
                showSyncBanner: true,
                disabledSync: true,
                finishAsyncStoreOrderCount: syncLog.surplus_count,
                asyncStoreOrderTotal: syncLog.all_count,
              };
              return params;
            });
            setSyncList((prevValue) => {
              const params = {
                ...prevValue,
                isSyncFinish: false,
                showSyncBanner: true,
                disabledSync: true,
                finishAsyncStoreOrderCount: syncLog.surplus_count,
                asyncStoreOrderTotal: syncLog.all_count,
              };
              return params;
            });
            setIntervalPolling(getSyncStoreOrderProcess,getUpdateSyncProcess,dalayTime,syncLog.record)
            
          } else {
            setSyncData((prevValue) => {
              const params = {
                ...prevValue,
                isSyncFinish: true,
                showSyncBanner: true,
                disabledSync: false,
                SyncCompletedData: {
                  all: syncLog.all_count,
                  dropshipman: syncLog.dsm_count,
                  noSource: syncLog.no_source_count,
                  fail: syncLog.fail_count,
                },
              };
              return params;
            });
            setSyncList((prevValue) => {
              const params = {
                ...prevValue,
                isSyncFinish: true,
                showSyncBanner: true,
                disabledSync: false,
                SyncCompletedData: {
                  all: syncLog.all_count,
                  dropshipman: syncLog.dsm_count,
                  noSource: syncLog.no_source_count,
                  fail: syncLog.fail_count,
                },
              };
              return params;
            });
            getUpdateSyncProcess(syncLog.record);
          }
        }
      }
      return () => {
        isMount = false;
        clearAllTimers()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncLogData]);

    // 清空所有定时器
  const clearAllTimers = () => {
    timers.forEach(timer => {
      clearInterval(timer)
    })
  }
   // 轮询公共方法
   const setIntervalPolling = (func,func2, duration, id) => {
    func(id)
    const timer = setInterval(() => {
      func(id).then((response) => {
        if (response === 200) {
          func2(id);
          clearInterval(timer);
        }
      })
    }, duration);
    let timerArr = timers
    timerArr.push(timer)
    setTimers(timerArr)
  }
    // 打开同步订单弹窗
    const handleSyncStoreOrdersModal = () => {
      setSyncData((prevValue) => ({
        ...prevValue,
        SyncStoreOrdersModalOpen: true,
      }));
      setSyncList((prevValue) => ({
        ...prevValue,
        SyncStoreOrdersModalOpen: true,
      }));
    };

    // 接收弹窗关闭回调
    const getCloseModalCallback = (val) => {
      setSyncData((prevValue) => ({
        ...prevValue,
        SyncStoreOrdersModalOpen: val,
      }));
      setSyncList((prevValue) => ({
        ...prevValue,
        SyncStoreOrdersModalOpen: val,
      }));
    };

    // 用户查看结果接口
    const getUpdateSyncProcess = async (record) => {
      const params = {
        record,
      };
      const res = await reqPostUpdateSyncProcess(params);
      if (res.code === "200") {
        setSyncData((prevValue) => {
          const params = {
            ...prevValue,
            disabledSync: false,
          };
          return params;
        });
        setSyncList((prevValue) => {
          const params = {
            ...prevValue,
            disabledSync: false,
          };
          return params;
        });
        getOrderListData(getSearchParam());
        // GetAppDashboardData(true)//获取数据
      }
    };

    // 同步订单轮询接口
    const getSyncStoreOrderProcess = async (record) => {
      const params = {
        record,
      };
      const res = await reqPostSyncProcess(params);
      if (res.code === "200") {
        aliNumSourcing = aliNumSourcing + 1;
        if (aliNumSourcing === 12) {
          dalayTime = 12 * 1000;
          clearAllTimers();
          setIntervalPolling(getSyncStoreOrderProcess,getUpdateSyncProcess,dalayTime,record)
        }else if (aliNumSourcing === 22) {
          dalayTime = 15 * 1000;
          clearAllTimers();
          setIntervalPolling(getSyncStoreOrderProcess,getUpdateSyncProcess,dalayTime,record)
        }if (aliNumSourcing >= 46) {
          dalayTime = 30 * 1000;
          clearAllTimers();
          setIntervalPolling(getSyncStoreOrderProcess,getUpdateSyncProcess,dalayTime,record)
        }
        // 更新剩余值
        if (res.data.is_sync === 0) {
          setSyncData((prevValue) => {
            const params = {
              ...prevValue,
              isSyncFinish: true, // 同步是否完成
              SyncCompletedData: {
                all: res.data.all_count,
                dropshipman: res.data.dsm_count,
                noSource: res.data.no_source_count,
                fail: res.data.fail_count,
              },
              disabledSync: false,
            };
            return params;
          });
          setSyncList((prevValue) => {
            const params = {
              ...prevValue,
              isSyncFinish: true, // 同步是否完成
              SyncCompletedData: {
                all: res.data.all_count,
                dropshipman: res.data.dsm_count,
                noSource: res.data.no_source_count,
                fail: res.data.fail_count,
              },
              disabledSync: false,
            };
            return params;
          });

          return 200;
        }
        setSyncData((prevValue) => {
          const params = {
            ...prevValue,
            disabledSync: true,
            isSyncFinish: false,
            finishAsyncStoreOrderCount: res.data.surplus_count,
          };
          return params;
        });
        setSyncList((prevValue) => {
          const params = {
            ...prevValue,
            disabledSync: true,
            isSyncFinish: false,
            finishAsyncStoreOrderCount: res.data.surplus_count,
          };
          return params;
        });
      } else {
        setSyncData((prevValue) => {
          const params = {
            ...prevValue,
            disabledSync: false,
          };
          return params;
        });
        setSyncList((prevValue) => {
          const params = {
            ...prevValue,
            disabledSync: false,
          };
          return params;
        });
        return 200;
      }
    };

    const handleSyncStoreOrders = async (start, end) => {
      const params = {
        start: dayjs(start).format("YYYY-MM-DD"),
        end: dayjs(end).format("YYYY-MM-DD"),
      };

      const res = await reqPostSyncShopifyOrders(params);
      if (res.code === "200") {
        setSyncData((prevValue) => {
          const params = {
            ...prevValue,
            SyncStoreOrdersModalOpen: false,
            asyncStoreOrderTotal: res.data.all_count,
            disabledSync: true,
            SyncBtnLoading: false,
            showToast: false,
          };
          toggleToast(
            intl.get(
              "Order syncing"
            ),
            false
          );
          return params;
        });
        setSyncList((prevValue) => {
          const params = {
            ...prevValue,
            SyncStoreOrdersModalOpen: false,
            asyncStoreOrderTotal: res.data.all_count,
            disabledSync: true,
            SyncBtnLoading: false,
            showToast: false,
          };
          toggleToast(
            intl.get(
              "Order syncing"
            ),
            false
          );
          return params;
        });
        if (res.data.all_count) {
          // 吊起轮询接口 并显示banner
          setSyncData((prevValue) => {
            const params = {
              ...prevValue,
              showSyncBanner: true,
              isSyncFinish: false,
            };
            return params;
          });
          setSyncList((prevValue) => {
            const params = {
              ...prevValue,
              showSyncBanner: true,
              isSyncFinish: false,
            };
            return params;
          });
          setIntervalPolling(getSyncStoreOrderProcess,getUpdateSyncProcess,dalayTime,res.data.record)
          // getSyncStoreOrderProcess(res.data.record);
          // let timer = setInterval(() => {
          //   getSyncStoreOrderProcess(res.data.record).then((response) => {
          //     if (response === 200) {
          //       getUpdateSyncProcess(res.data.record);
          //       clearInterval(timer);
          //     }
          //   });
          // }, dalayTime);
        } else {
          setSyncData((prevValue) => {
            const params = {
              ...prevValue,
              showSyncBanner: true,
              isSyncFinish: true,
              disabledSync: false,
              SyncCompletedData: {
                all: res.data.all_count,
                dropshipman: res.data.dsm_count,
                noSource: res.data.no_source_count,
                fail: res.data.fail_count,
              },
            };
            return params;
          });
          setSyncList((prevValue) => {
            const params = {
              ...prevValue,
              showSyncBanner: true,
              isSyncFinish: true,
              disabledSync: false,
              SyncCompletedData: {
                all: res.data.all_count,
                dropshipman: res.data.dsm_count,
                noSource: res.data.no_source_count,
                fail: res.data.fail_count,
              },
            };
            return params;
          });
          getOrderListData(getSearchParam());
        }
      }
    };

    // 同步订单回调
    const getSyncCallback = (start, end) => {
      setSyncData((prevValue) => {
        const params = {
          ...prevValue,
          SyncBtnLoading: true,
        };
        return params;
      });
      setSyncList((prevValue) => {
        const params = {
          ...prevValue,
          SyncBtnLoading: true,
        };
        return params;
      });
      handleSyncStoreOrders(start, end);
    };

    // 关闭同步订单成功弹窗
    const closeSyncCompletedModal = () => {
      setSyncData((prevValue) => {
        const params = {
          ...prevValue,
          SyncCompletedModalOpen: false,
        };
        return params;
      });
      setSyncList((prevValue) => {
        const params = {
          ...prevValue,
          SyncCompletedModalOpen: false,
        };
        return params;
      });
    };

    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);

    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };
    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
        duration={2000}
      />
    ) : null;

    //判断是否同步中
    var synchroContent = "";
    if (getSyncData()?.synchroStatus === 0) {
      synchroContent = (
        <div style={{ paddingTop: "2rem" }}>
          <Banner tone="info">
            <p>
              {syncData.synchroCount} {intl.get("Order in processing")}{" "}
              <img
                src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMjI5IDEuMTczYTkuMjUgOS4yNSAwIDEgMCAxMS42NTUgMTEuNDEyIDEuMjUgMS4yNSAwIDEgMC0yLjQtLjY5OCA2Ljc1IDYuNzUgMCAxIDEtOC41MDYtOC4zMjkgMS4yNSAxLjI1IDAgMSAwLS43NS0yLjM4NXoiIGZpbGw9IiM5MTlFQUIiLz48L3N2Zz4K"
                alt=""
                className="Polaris-Spinner Polaris-Spinner--sizeSmalltow"
                draggable="false"
                role="status"
                aria-label="Loading"
              ></img>
            </p>
          </Banner>
        </div>
      );
    }

    const SyncCompletedModalTitle = (
      <span className="SyncCompletedModalTitle">
        <Icon source={CheckCircleIcon} color="success" />
        {intl.get("Completed")}
      </span>
    );

    //同步完成弹窗
    const SyncCompletedModal = (
      <Modal
        open={syncData.SyncCompletedModalOpen}
        onClose={() => closeSyncCompletedModal()}
        title={SyncCompletedModalTitle}
        primaryAction={{
          content: intl.get("OK"),
          onAction: () => closeSyncCompletedModal(),
        }}
      >
        <Modal.Section>
          <div className="SyncCompletedModalContent">
            <BlockStack>
              <BlockStack>
                <p>
                  <span>{Number(syncData.SyncCompletedData?.all) - Number(syncData.SyncCompletedData?.fail)}</span>{" "}
                  {intl.get("orders have been synchronized successfully")}.
                </p>
              </BlockStack>
              <BlockStack>
                <p>
                {" "}<span>{syncData.SyncCompletedData.fail}</span>{" "}
                  {intl.get("orders failed to synchronize.")}
                </p>
              </BlockStack>
            </BlockStack>
          </div>
        </Modal.Section>
      </Modal>
    );
    const OrderPaymentConfigOff = async (newValue)=>{
      const res = await reqPostUpdateOrderPaymentConfig({
        auto_pay: 0,
        auto_complete: 0,
        userShippingData:''
      });
      if (res.code === '200') {
        if (!newValue) {
          toggleToast('Set up successfully')
        }
      }else {
        toggleToast(res.msg)
      }
    }
    const [saveLoading,setSaveLoading] = useState(false)
    const OrderSourcingPaymentConfigOff = async (newValue)=>{
      setSaveLoading(true)
      const res = await reqPostUpdateOrderSourcingConfig({
        checked: newValue ? 1 : 0,
      });
      if (res.code === '200') {
        if (newValue) {
          setOrderPageData((prevValue)=>({
            ...prevValue,
            auto_sourcing:1
          }))
        }else{
          setOrderPageData((prevValue)=>({
            ...prevValue,
            auto_sourcing:0
          }))
        }
        toggleToast('Set up successfully')
        setSwitchSourcingOpen(false)
      }else {
        toggleToast(res.msg)
      }
      setSaveLoading(false)
    }
    
    // 切换自动支付
    const handleToggleSwitch = (newValue)=>{
      let person = store.getState().languageReducer.subScriptData;
      if (person.level > 0 && person.level !== 4) {
        if (newValue) {
          // setOrderPageData((prevValue)=>({
          //   ...prevValue,
          //   auto_pay:1
          // }))
          setSwitchChecked(true)
          // toggleToast('Set up successfully')
        }else{
          setOrderPageData((prevValue)=>({
            ...prevValue,
            auto_pay:0
          }))
          OrderPaymentConfigOff(newValue)
          
        }
      }else{
        setUpgradeModal(true)
      }
     
    }

    // 自动找货
const handleToggleSourcingSwitch = (newValue)=>{
  
  let person = store.getState().languageReducer.subScriptData;
  if (person.level > 0 ) {
    if (newValue) {
      setSwitchSourcingOpen(true)
    }else{
      setOrderPageData((prevValue)=>({
        ...prevValue,
        auto_sourcing:0
      }))
      OrderSourcingPaymentConfigOff(newValue)
    }
    
  }else{
    setUpgradeModal(true)
  }
 
}

const shopify_level = useSelector(state => state.languageReducer.subScriptData.shopify_level);
  const level = useSelector(state => state.languageReducer.subScriptData.level);
  const shopifyLevel = ()=>{
    let flag = true;
    if (level === 0 && (shopify_level === 'Development' || shopify_level === 'Developer Preview' || shopify_level === 'staff' || shopify_level === 'staff_business')) {
      flag = false
    }
    return flag;
  }
  const shopifyLevels = ()=>{
    let flag = true;
    if ((level === 0 || level === 4) && (shopify_level === 'Development' || shopify_level === 'Developer Preview' || shopify_level === 'staff' || shopify_level === 'staff_business')) {
      flag = false
    }
    return flag;
  }
    return (
      <div>
        {toastMarkup}
        <div className={`auto_Fulfillment auto_Fulfillments auto_Fulfillment-${language}`} style={{margin:0}}>
        <div style={{marginRight:10,position:'relative'}}>
        <Button>
          <InlineStack blockAlign='center'>
          <div className="item mb_item" style={{fontSize:'12px',marginBottom:6,marginRight:10}}><img height={20} style={{verticalAlign:'bottom'}} src='/sub/sub_head_light.png' alt={'Auto Order Sourcing'} /> {intl.get("Auto Order Sourcing")}</div>
          <div className="item" >
            <Switch
              onChange={(nextChecked) =>{ 
                !shopifyLevel() ? handleToggleSourcingSwitch(true) :  handleToggleSourcingSwitch(nextChecked)
              }}
              checked={syncLogData?.auto_sourcing === 1}
              width={50}
              height={20}
              onColor='#303030'
              offColor="#DADADA"
              uncheckedIcon={<div className="switchBtn"></div>}
            />
            </div>
            </InlineStack>
            </Button>
            {!shopifyLevel()  && <LevelAction callBack={()=>handleToggleSourcingSwitch(true)} img={'16px'} borderRadius={'5px'} font={'12px'}/> }
            </div>
            <div style={{position:'relative'}}>
            <Button>
          <InlineStack blockAlign='center'>
          <div className="item mb_item" style={{fontSize:'12px',marginBottom:6,marginRight:10}}><img height={20} style={{verticalAlign:'bottom'}} src='/sub/sub_head_light.png' alt={'Auto Fulfillment'} /> {intl.get("Auto Fulfillment")}</div>
          <div className="item">
            <Switch
              onChange={(nextChecked) =>{
                !shopifyLevels() ? handleToggleSwitch(true) :  handleToggleSwitch(nextChecked)
              }}
              checked={syncLogData?.auto_pay === 1}
              width={50}
              height={20}
              onColor='#303030'
              offColor="#DADADA"
              uncheckedIcon={<div className="switchBtn"></div>}
            />
            </div>
            </InlineStack>
            </Button>
            {!shopifyLevels()  && <LevelAction callBack={()=>handleToggleSwitch(true)} img={'16px'} borderRadius={'5px'} font={'12px'}/> }
            </div>
          {/* <div className="item icon subscript_section_it_tip">
              <Icon source={QuestionCircleIcon} color="base" />
              <span className="subscript_section_it_tip_txt" style={{width:'250px'}}>
                Set the default payment method and default shipping method, when there are unpaid orders synchronized to DSM, the system will automatically complete the order payment and fulfillment.
                </span>
          </div> */}
          <div className="item btn sync_button">
            <Button
              variant="primary"
              disabled={syncData.disabledSync}
              onClick={() => handleSyncStoreOrdersModal()}
            >
              {intl.get("Sync Store Orders")}
            </Button>
          </div>

          <AutoFulfillmentModal
            open={switchChecked}
            setOrderPageData={setOrderPageData}
            setSwitchChecked={() => setSwitchChecked(false)}
          />
          <AoutoSourcingModal 
            open={switchSourcingOpen}
            setSwitchSourcingOpen={setSwitchSourcingOpen}
            saveLoading={saveLoading}
            handleSourcingConfigOff={OrderSourcingPaymentConfigOff}
          />

          {/* 手动同步订单弹窗 */}
          <SyncStoreOrdersModal
            searchStartDay={syncData.searchStartDay}
            searchEndDay={syncData.searchEndDay}
            open={syncData.SyncStoreOrdersModalOpen}
            loading={syncData.SyncBtnLoading}
            
            closeModalCallback={getCloseModalCallback}
            syncCallback={getSyncCallback}
          />
        </div>
        
         {/* 升级为会员弹窗 */}
         <UpgradePlanModal upgradeOpen={upgradeModal} setUpgradeOpen={()=>setUpgradeModal(false)}/>
        {synchroContent}
        {/* 同步完成弹窗 */}
        {SyncCompletedModal}
      </div>
    );
  }
);

export default connect()(OrderFulfillment);
