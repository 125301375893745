import React from "react";

const Index = (props) => {
  let color = "#616161";
  let bgColor = "#0000000f";
  let text = props.content;
  switch (props.content) {
   
    case 1:
      text = 'Pending'
      color = "rgba(0, 82, 124, 1)"
      bgColor = "rgba(224, 240, 255, 1)";
      break;
    case 2:
      text = 'Success'
      color = "rgba(12, 81, 50, 1)"
      bgColor = "rgba(205, 254, 225, 1)";
      break;
    case 'Failed':
      color = "rgba(142, 31, 11, 1)"
      bgColor = "rgba(254, 218, 217, 1)";
      break;
    case 0:
      text = 'Not sourced'
      color = "rgba(94, 66, 0, 1)"
      bgColor = "rgba(255, 214, 164, 1)";
      break;
    default:
      break;
  }

  return (
    <span
      style={{
        display: "inline-flex",
        maxWidth: "100%",
        minWidth: "68px",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "16px",
        padding: "2px 8px",
        backgroundColor:props.bgColor ? props.bgColor : bgColor,
        borderRadius: "16px",
        color:props.color ? props.color : color,
        outline: " 1px solid transparent",
      }}
    >
      {text}
    </span>
  );
};

export default Index;
