import { Modal } from '@shopify/polaris';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from '../../../../utils/utils'
const BlackRaffleRuleModal = ({open,handleOff})=> {
    const router = useHistory()
    return (
        <div>
        <Modal
            size='middle'
            open={open}
            title="Insufficient Points"
            primaryAction={{
                content: 'Earn Points', onAction: () => {
                    utils.routeListen('/admin/points')
                    utils.routeJump1(router,'/admin/points')
                }
            }}
            secondaryActions={{
                content: 'Cancel', onAction: () => handleOff()
            }}
            onClose={() => handleOff()}>
                <Modal.Section>
                <p>You don't have enough points to enter the raffle. Please earn more points to continue.</p>
            </Modal.Section>
            </Modal>
        </div>
    );
}

export default BlackRaffleRuleModal;
