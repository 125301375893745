import { Box, Button, Card } from '@shopify/polaris';
import React  from 'react';
const MarketEmpty = ({
    requestParams,
    priceRange,
    cateName,
    handleSourcingModal,
    setNoviceModal
})=> {
  
    const textFun = ()=>{
        let text = <Box className="market_empty_box_top_desc">There are no matching search results for <strong>"{requestParams.product_name}"</strong> at the moment. Please change the search keywords or souring the product you want. <Button variant="plain" onClick={()=>setNoviceModal(true)}>Learn more about sourcing request</Button></Box>;
        if (cateName !== "All Category") {
            text = <Box className="market_empty_box_top_desc">There are no products matching <strong>"{cateName}"</strong>, please filter again</Box> 
        }
        if (priceRange.currentMinPrice !== "" &&
            priceRange.currentMaxPrice !== "") {
            text = <Box className="market_empty_box_top_desc">There are no products matching <strong>"${priceRange.currentMinPrice}-${priceRange.currentMaxPrice}"</strong>, please filter again</Box> 
        }
        if ((cateName !== "All Category" && priceRange.currentMinPrice !== "" &&
            priceRange.currentMaxPrice !== "")) {
            text = <Box className="market_empty_box_top_desc">There are no products matching <strong>"{cateName} & ${priceRange.currentMinPrice}-${priceRange.currentMaxPrice}"</strong>, please filter again</Box> 
        }
        return text;
    } 
    const btnFun = ()=>{
        let btn =  <Box className="market_empty_box_top_button"><Button variant='primary' onClick={()=>{handleSourcingModal()}}>Submit a sourcing request</Button></Box>;
        if (cateName !== "All Category" || (priceRange.currentMinPrice !== "" &&
            priceRange.currentMaxPrice !== "")) {
            btn = ''
        }
        return btn;
    }
    return (
        <Box className="market_empty">
            <Box>
                <Box className="market_empty_container">
                    <Box className="market_empty_box">
                        <Card padding={0}>
                        <Box className="market_empty_box_top"></Box>
                        <Box className="market_empty_box_bottom"></Box>
                        </Card>
                    </Box>
                    <Box className="market_empty_box">
                        <Card padding={0}>
                        <Box className="market_empty_box_top"></Box>
                        <Box className="market_empty_box_bottom"></Box>
                        </Card>
                    </Box>
                    <Box className="market_empty_box">
                        <Card padding={0}>
                        <Box className="market_empty_box_top"></Box>
                        <Box className="market_empty_box_bottom"></Box>
                        </Card>
                    </Box>
                </Box>
                    {textFun()}
                    {btnFun()}
            </Box>

           
       
        </Box>
    );
}

export default MarketEmpty;
