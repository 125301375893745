import { Box, Button, Modal } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import intl from "../../../../i18n/intl";
import { reqGetSubcribeData, reqPostSubcribeData } from "../../../../api";
import PersonalAgentModal from "../../SubscriptionPaid/components/PersonalAgentModal";
import FeedBenefitsModal from "./FeedBenefitsModal"
const PlanNewBox = ({ open, handleOffPlanModal,toastFun }) => {
  const [planList, setPlanList] = useState([
    {
      name: "PLUS",
      id: 2,
      dsc: intl.get("Powerful features for scaling your business"),
      symbol: "$",
      title_icon: "/sub/huojian.png",
      popular: intl.get("Highly Recommend"),
      price: "49.9",
      oldPrice: "79",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      button: "7-day free trial",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 49.9,
      loading: false,
      disabled: false,
      level: 2,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },

      dscListNew: [
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("499 Points"),
          strong: true,
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("60 Expert Sourcing Requests"),
          tip_p: intl.get(
            "After you submit a sourcing request, our expert sourcing specialists will conduct product searches and provide quotations within 1 to 3 days."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Access to 1M+ Products"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("24/7 VIP Support"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Dedicated Manager"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("5000 Droshipman Products"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("5000 AliExpress Products"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("5000 Temu Products"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Unlimited Orders Fulfillment"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Automated Order Sourcing"),
          tip_p: intl.get(
            "When orders from your store are synced to Dropshipman and the suppliers of the products in the order are not from Dropshipman, the system will automatically initiate product sourcing for you."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Bulk Product Sourcing"),
          tip_p: intl.get(
            "Supports you to initiate product sourcing for all the products in your store that are currently on sale with just one click, thereby changing the suppliers of the products to Dropshipman."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Automated Fulfillment"),
          tip_p: intl.get(
            "You can set default payment methods and shipping channels, and when orders from your store are synced to Dropshipman, the payment will be automatically completed without the need for manual payment."
          ),
          tip_icon: "/sub/sub_tip.png",
        },

        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Order Tracking"),
          tip_p: intl.get(
            "From the moment your order is successfully placed, we create an order tracking process for you that includes order confirmation, product quality inspection, product shipment, and package transportation, allowing you to stay up-to-date with your order in real-time."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Winning Products"),
          tip_p: intl.get(
            "Best-selling products carefully selected and recommended by sourcing experts, helping you identify profitable opportunities."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Automated Product Pricing"),
          tip_p: intl.get(
            "You can set price rules based on the price tiers of the products, and when importing products, the system will automatically set the prices of the products according to the price rules you have set."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Private Product Listing"),
          tip_p: intl.get(
            "Once you initiate product sourcing and privatize it, the product can only be sold by you and other customers will not be able to use the product that you have privatized."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Remove China Label"),
          tip_p: intl.get(
            "Before shipping the products, we will remove the Chinese characters and labels on the products to provide your customers with a better experience."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
      ],
    },
    {
      name: "PRO",
      id: 1,
      dsc: intl.get("All necessary features for growing business"),
      symbol: "$",
      title_icon: "/sub/fly.png",
      popular: "",
      price: "29.9",
      oldPrice: "49",
      date: intl.get("Monthly"),
      isFree: false,
      type: 1,
      button: "7-day free trial",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 29.9,
      loading: false,
      disabled: false,
      level: 1,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      dscListNew: [
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("299 Points"),
          strong: true,
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("30 Expert Sourcing Requests"),
          tip_p: intl.get(
            "After you submit a sourcing request, our expert sourcing specialists will conduct product searches and provide quotations within 1 to 3 days."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Access to 1M+ Products"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("24/7 VIP Support"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Dedicated Manager"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("1000 Dropshipman Products"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("1000 AliExpress Products"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("1000 Temu Products"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Unlimited Orders Fulfillment"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Automated Order Sourcing"),
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Bulk Product Sourcing"),
          tip_p: intl.get(
            "Supports you to initiate product sourcing for all the products in your store that are currently on sale with just one click, thereby changing the suppliers of the products to Dropshipman."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Automated Fulfillment"),
          tip_p: intl.get(
            "You can set default payment methods and shipping channels, and when orders from your store are synced to Dropshipman, the payment will be automatically completed without the need for manual payment."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Automated Product Pricing"),
          tip_p: "",
          tip_icon: "/sub/sub_tip.png",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Private Product Listing"),
          tip_p: intl.get(
            "Once you initiate product sourcing and privatize it, the product can only be sold by you and other customers will not be able to use the product that you have privatized."
          ),
          tip_icon: "/sub/sub_tip.png",
        },
      ],
    },
    {
      name: "PREMIUM",
      id: 5,
      dsc: intl.get("All Plus features, 1999 points & VIP Services"),
      symbol: "$",
      title_icon: "/sub/ufo.png",
      popular: "",
      price: "149",
      oldPrice: "199",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      button: "Downgrade",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 149,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
      dscList: [
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          tip_icon: "/sub/sub_tip.png",
          p: intl.get("300 Priority Expert Sourcing Requests"),
          tip_p: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Full Process Order Follow-up"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Personalized Trend Product Suggestions"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Custom Private Storage Solutions"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Recommended Chinese Factories & Sources"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Best Logistics Route Suggestions"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Rigorous Product Quality Control"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Custom Brand Packaging & Cards"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Custom Product Photography"),
          tip_p: "",
          tip_icon: "",
        },
      ],
      dscListNew: [
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          tip_icon: "/sub/sub_tip.png",
          p: intl.get("300 Priority Expert Sourcing Requests"),
          tip_p: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Full Process Order Follow-up"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Personalized Trend Product Suggestions"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Custom Private Storage Solutions"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Recommended Chinese Factories & Sources"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Best Logistics Route Suggestions"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Rigorous Product Quality Control"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Custom Brand Packaging & Cards"),
          tip_p: "",
          tip_icon: "",
        },
        {
          icon: "/sub/ok_icon.png",
          icon_next: "/sub/ok_black_icon.png",
          p: intl.get("Custom Product Photography"),
          tip_p: "",
          tip_icon: "",
        },
      ],
      agent: [
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
      ],
    },
  ]);
  //  挽留套餐
  const [feedModal,setFeedModal] = useState(false);
  const getSubscribeList = async () => {
    let res = await reqGetSubcribeData();
    // res = subData
    
    if (res.code === "200") {
      setPremiumData((prevValue) => ({
        ...prevValue,
        agent_list: res.data.agent_list,
      }));
      let currentSub = planList.map((it, k) => {
        let discount = it;
        for (let j = 0; j < res.data.package.length; j++) {
          if (k === 0) {
            discount = { ...discount, ...res.data.package[2] };
          }
          if (k === 1) {
            discount = { ...discount, ...res.data.package[1] };
          }
          if (k === 2) {
            discount = { ...discount, ...res.data.package[4] };
          }
        }
        return {
          ...discount,
          user_discounts: res.data.user_discounts,
          level: res.data.level,
          pay_level: res.data.bill_level,
          first_purchase: res.data.first_purchase,
          is_zt: res.data.is_zt,
        };
      });
      switch (res.data.bill_level) {
        case 0:
          let list0 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
           
            if (res.data.bill_level === item.id) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              return {
                ...item,
                button: "Select Plan",
                disabled: false,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            }
          });
          setPlanList(list0);
          break;
        case 1:
          let list1 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
           
            if (res.data.bill_level === item.id) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              return {
                ...item,
                button: "Select Plan",
                disabled: false,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            }
          });
          setPlanList(list1);
          break;
        case 2:
          let list3 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
           
            if (res.data.bill_level === item.id) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              return {
                ...item,
                button: "Select Plan",
                disabled: false,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            }
          });
          setPlanList(list3);
          break;
        case 4:
          let list4 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
            
            if (res.data.bill_level === item.id) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              return {
                ...item,
                button: "Select Plan",
                disabled: false,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            }
          });
          setPlanList(list4);
          break;
        case 5:
          let list5 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            let discount = item.discount;
            let discount_time = item.discount_time;
            if (res.data.bill_level === item.id) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              return {
                ...item,
                button: "Select Plan",
                disabled: false,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            }
          });
          setPlanList(list5);
          break;
        default:
          break;
      }
    } else {
    }
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount && open) {
      getSubscribeList();
    }
    return () => {
      isMount = true;
    };
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // 获取当前时间
  const getCurrentTime = () => {
    const timestamp = Date.parse(new Date()) / 1000;
    return timestamp;
  };

  // const getTimeData = (userData, data) => {
  //   // 1. 全员套餐存在 闹钟无
  //   // 2. 全员套餐存在 闹钟套餐存在
  //   let flag = false;
  //   if (data) {
  //     for (let i = 0; i < data.length; i++) {
  //       // 无活动
  //       if (
  //         getCurrentTime() > data[i].discount_time &&
  //         userData.event_active_time === ""
  //       ) {
  //         flag = false;
  //       }
  //       // 无闹钟
  //       if (
  //         getCurrentTime() < data[i].discount_time &&
  //         userData.event_active_time === ""
  //       ) {
  //         flag = false;
  //       }

  //       // 同时存在
  //       if (
  //         getCurrentTime() < data[i].discount_time &&
  //         getCurrentTime() < userData.event_active_time &&
  //         userData.event_active_time !== ""
  //       ) {
  //         if (data[i].discount >= userData.event_discounts) {
  //           flag = true;
  //         } else {
  //           flag = false;
  //         }
  //       }
  //       // 闹钟套餐
  //       if (
  //         getCurrentTime() > data[i].discount_time &&
  //         getCurrentTime() < userData.event_active_time &&
  //         userData.event_active_time !== "" && userData.event_basic_discounts === ''
  //       ) {
  //         flag = true;
  //       }
        
  //       // 黑五活动
  //       // if (getCurrentTime() < data[i].discount_time || getCurrentTime() < userData.event_active_time) {
  //       //   if (data[i].discount || userData.event_discounts) {
  //       //     black = true
  //       //   }
  //       // }
  //     }
  //   }
    
  //   return flag;
  // };


  // 升级套餐
  const handleMageUpgrade = async (
    id,
    val,
    email = "",
    social = "",
    am_id = ""
  ) => {
    let list = planList.map((item, ind) => {
      if (item.id === id) {
        return { ...item, loading: true };
      } else {
        return { ...item, disabled: true };
      }
    });
    setPlanList(list);
    try {
      let param = {
        package_id: id,
        email: email,
        social: social,
        am_id: am_id,
      };
      if (val && val !== "") {
        param.remark = val;
      }
      let res = await reqPostSubcribeData(param);
      if (res.code === "200") {
        if (res?.data?.id !== "") {
          
          if (id !== 0) {
           
            let list1 = planList.map((item, ind) => {
              if (item.id === id || id === 4 || id === 5) {
                return { ...item, loading: false };
              } else {
                return item;
              }
            });
            setPlanList(list1);
            window.top.location.href = res.data.url;
            toastFun(res.msg);
          } 
        }
      } else {
        getSubscribeList();
        toastFun(intl.get("Something wrong"));
      }
    } catch (error) {}
  };
  // 高级套餐
  const [premiumData, setPremiumData] = useState({
    open: false,
    agent_list: [],
  });
  const handlePremiumOff = () => {
    setPremiumData((prevValue) => ({
      ...prevValue,
      open: false,
    }));
  };
  return (
    <div>
      <Modal
        size="large"
        open={open}
        title="Choose a plan to get started"
        onClose={() => {
          setFeedModal(true);
          handleOffPlanModal()
        }}
      >
        <Box className="new_plan_modal">
          <Box className="new_plan_content">
            <Box className="new_plan_content_top">
              <Box className="new_plan_content_list">
                {planList.slice(0,1).map((item, ind) => (
                  <Box key={ind} className="new_plan_content_list_item">
                    {item.id === 2 && (
                      <Box className="new_plan_content_list_item_reecomend">
                        Highly Recommend
                      </Box>
                    )}
                    <Box className="new_plan_content_list_item_day">
                      7 DAY FREE TRIAL
                    </Box>
                    <Box className="new_plan_content_list_item_price">
                      <strong>$0</strong>
                      <p>
                        <span>{item.name}</span>
                        <img
                          height={20}
                          src={item.title_icon}
                          alt="Dropshipman"
                        />
                      </p>
                    </Box>
                    <Box className="new_plan_content_list_item_dsc">
                      <p>
                        <strong>
                          7 Day Free Trial - No Contracts, Cancel Anytime.{" "}
                        </strong>
                        <span style={{color:'rgba(118, 118, 118, 1)'}}> Get Access To All Features! Then just</span>

                        {(item.user_discounts.event_plus_discounts !== "") &&
                        getCurrentTime() <
                          item.user_discounts.plus_active_time ? (
                          <strong style={{ margin: "0 5px" }}>
                            $
                            {parseFloat(
                              item.money *
                                (item.user_discounts.event_plus_discounts) *
                                100
                            ).toFixed(0) / 100}
                            /mo.
                          </strong>
                        ) : getCurrentTime() >= item.discount_start &&
                          getCurrentTime() <= item.discount_time && item.discount < 1 ? (
                          <strong style={{ margin: "0 5px" }}>
                            ${(item.money *  item.discount).toFixed(2)}/mo.
                          </strong>
                        ) : (
                          <strong style={{ margin: "0 5px" }}>
                            ${item.money}/mo.
                          </strong>
                        )}

                        <span style={{textDecoration:'line-through',color:'rgba(118, 118, 118, 1)'}}>${item.money}</span>
                      </p>
                    </Box>
                    <Box className="new_plan_content_list_item_button">
                      <Button
                        variant={item.id === 2 ? "primary" : "secondary"}
                        disabled={item.disabled}
                        loading={item.loading}
                        onClick={()=>{
                          if (item.id === 5) {
                            setPremiumData((prevValue) => ({
                              ...prevValue,
                              open: true,
                            }));
                            handleOffPlanModal()
                          }else {
                            handleMageUpgrade(item.id)
                          }
                         
                        }}
                      >
                       Select Plan
                      </Button>
                    </Box>
                    <Box className="new_plan_content_list_item_tip">
                      {item.dsc}
                    </Box>
                    <Box className="new_plan_content_list_item_feature">
                    {item.id === 5 ? "Personal agent services" : "Features"}
                    </Box>
                    <Box className="new_plan_content_list_item_ul">
                      <ul>
                        {item.dscListNew.map((it, j) => (
                          <li key={j}>{it.p}</li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                ))}
                 {planList.slice(1,2).map((item, ind) => (
                  <Box key={ind} className="new_plan_content_list_item">
                    {item.id === 2 && (
                      <Box className="new_plan_content_list_item_reecomend">
                        Highly Recommend
                      </Box>
                    )}
                    <Box className="new_plan_content_list_item_day">
                      7 DAY FREE TRIAL
                    </Box>
                    <Box className="new_plan_content_list_item_price">
                      <strong>$0</strong>
                      <p>
                        <span>{item.name}</span>
                        <img
                          height={20}
                          src={item.title_icon}
                          alt="Dropshipman"
                        />
                      </p>
                    </Box>
                    <Box className="new_plan_content_list_item_dsc">
                      <p>
                        <strong>
                          7 Day Free Trial - No Contracts, Cancel Anytime.{" "}
                        </strong>
                        <span style={{color:'rgba(118, 118, 118, 1)'}}> Get Access To All Features! Then just</span>

                        {(item.user_discounts.event_discounts !== '') &&
                        getCurrentTime() <
                          item.user_discounts.event_active_time ? (
                          <strong style={{ margin: "0 5px" }}>
                            $
                            {parseFloat(
                              item.money *
                                (item.user_discounts.event_discounts) *
                                100
                            ).toFixed(0) / 100}
                            /mo.
                          </strong>
                        ) : getCurrentTime() >= item.discount_start &&
                          getCurrentTime() <= item.discount_time && item.discount < 1 ? (
                          <strong style={{ margin: "0 5px" }}>
                            ${(item.money *  item.discount).toFixed(2)}/mo.
                          </strong>
                        ) : (
                          <strong style={{ margin: "0 5px" }}>
                            ${item.money}/mo.
                          </strong>
                        )}

                        <span style={{textDecoration:'line-through',color:'rgba(118, 118, 118, 1)'}}>${item.money}</span>
                      </p>
                    </Box>
                    <Box className="new_plan_content_list_item_button">
                      <Button
                        variant={item.id === 2 ? "primary" : "secondary"}
                        disabled={item.disabled}
                        loading={item.loading}
                        onClick={()=>{
                          if (item.id === 5) {
                            setPremiumData((prevValue) => ({
                              ...prevValue,
                              open: true,
                            }));
                            handleOffPlanModal()
                          }else {
                            handleMageUpgrade(item.id)
                          }
                         
                        }}
                      >
                       Select Plan
                      </Button>
                    </Box>
                    <Box className="new_plan_content_list_item_tip">
                      {item.dsc}
                    </Box>
                    <Box className="new_plan_content_list_item_feature">
                    {item.id === 5 ? "Personal agent services" : "Features"}
                    </Box>
                    <Box className="new_plan_content_list_item_ul">
                      <ul>
                        {item.dscListNew.map((it, j) => (
                          <li key={j}>{it.p}</li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                ))}
                 {planList.slice(2,3).map((item, ind) => (
                  <Box key={ind} className="new_plan_content_list_item">
                    {item.id === 2 && (
                      <Box className="new_plan_content_list_item_reecomend">
                        Highly Recommend
                      </Box>
                    )}
                    <Box className="new_plan_content_list_item_day">
                      7 DAY FREE TRIAL
                    </Box>
                    <Box className="new_plan_content_list_item_price">
                      <strong>$0</strong>
                      <p>
                        <span>{item.name}</span>
                        <img
                          height={20}
                          src={item.title_icon}
                          alt="Dropshipman"
                        />
                      </p>
                    </Box>
                    <Box className="new_plan_content_list_item_dsc">
                      <p>
                        <strong>
                          7 Day Free Trial - No Contracts, Cancel Anytime.{" "}
                        </strong>
                        <span style={{color:'rgba(118, 118, 118, 1)'}}> Get Access To All Features! Then just</span>

                        {(item.user_discounts.event_premium_discounts !== "") &&
                        getCurrentTime() <
                          item.user_discounts.premium_active_time ? (
                          <strong style={{ margin: "0 5px" }}>
                            $
                            {parseFloat(
                              item.money *
                                (item.user_discounts.event_premium_discounts) *
                                100
                            ).toFixed(0) / 100}
                            /mo.
                          </strong>
                        ) : getCurrentTime() >= item.discount_start &&
                          getCurrentTime() <= item.discount_time && item.discount < 1 ? (
                          <strong style={{ margin: "0 5px" }}>
                            ${(item.money *  item.discount).toFixed(2)}/mo.
                          </strong>
                        ) : (
                          <strong style={{ margin: "0 5px" }}>
                            ${item.money}/mo.
                          </strong>
                        )}

                        <span style={{textDecoration:'line-through',color:'rgba(118, 118, 118, 1)'}}>${item.money}</span>
                      </p>
                    </Box>
                    <Box className="new_plan_content_list_item_button">
                      <Button
                        variant={item.id === 2 ? "primary" : "secondary"}
                        disabled={item.disabled}
                        loading={item.loading}
                        onClick={()=>{
                          if (item.id === 5) {
                            setPremiumData((prevValue) => ({
                              ...prevValue,
                              open: true,
                            }));
                            handleOffPlanModal()
                          }else {
                            handleMageUpgrade(item.id)
                          }
                         
                        }}
                      >
                       Select Plan
                      </Button>
                    </Box>
                    <Box className="new_plan_content_list_item_tip">
                      {item.dsc}
                    </Box>
                    <Box className="new_plan_content_list_item_feature">
                    {item.id === 5 ? "Personal agent services" : "Features"}
                    </Box>
                    <Box className="new_plan_content_list_item_ul">
                      <ul>
                        {item.dscListNew.map((it, j) => (
                          <li key={j}>{it.p}</li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className="new_plan_content_bottom">
              <p>
                Cancel at any time｜Trusted by top brands running on Shopify &
                Shopify Plus｜24*7 Live customer support
              </p>
            </Box>
          </Box>
        </Box>
      </Modal>

      <PersonalAgentModal
        premiumData={premiumData}
        handlePremiumOff={handlePremiumOff}
        handleMageUpgrade={handleMageUpgrade}
      />
      <FeedBenefitsModal open={feedModal}  setFeedModal={setFeedModal}/>
    </div>
  );
};
// const subData = {
//   "code": "200",
//   "msg": "success",
//   "data": {
//       "level": 0,
//       "bill_level": 0,
//       "bill_time": 0,
//       "trial_days": 7,
//       "trial_time": 0,
//       "package": [
//           {
//               "name": "FREE",
//               "money": 0,
//               "discount": 1,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 3,
//                   "ali_product_num": 10,
//                   "dsm_product_num": 10,
//                   "temu_product_num": 10,
//                   "coupon_num": 0,
//                   "salesman": false
//               }
//           },
//           {
//               "name": "PRO",
//               "money": 29.9,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 30,
//                   "ali_product_num": 1000,
//                   "dsm_product_num": 1000,
//                   "temu_product_num": 1000,
//                   "coupon_num": 0,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "PLUS",
//               "money": 49.9,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 60,
//                   "ali_product_num": 5000,
//                   "dsm_product_num": 5000,
//                   "temu_product_num": 5000,
//                   "coupon_num": 5,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "BASIC",
//               "money": 9.9,
//               "discount": 1,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 10,
//                   "ali_product_num": 300,
//                   "dsm_product_num": 300,
//                   "temu_product_num": 300,
//                   "coupon_num": 0,
//                   "salesman": true
//               }
//           },
//           {
//               "name": "PREMIUM",
//               "money": 199,
//               "discount": 0.7,
//               "discount_start": 0,
//               "discount_time": 0,
//               "limitation": {
//                   "sourcing_num": 300,
//                   "ali_product_num": 100000,
//                   "dsm_product_num": 100000,
//                   "temu_product_num": 100000,
//                   "coupon_num": 5,
//                   "salesman": true
//               }
//           }
//       ],
//       "first_purchase": true,
//       "is_zt": false,
//       "user_discounts": {
//           "event_discounts": 0.8,
//           "event_plus_discounts": 0.8,
//           "event_basic_discounts": "",
//           "event_premium_discounts": "",
//           "event_active_time": 1734682694
//       },
//       "agent_list": [
//           {
//               "record_id": 21,
//               "name": "Alice Huang",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-16\/1723776339-22b99d484ee2ea4d5d41676b5397c369.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Professional",
//                   "Supportive"
//               ]
//           },
//           {
//               "record_id": 22,
//               "name": "Jenny Lin",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-13\/1723541851-18801030041b67ba4a09ad2387af21f9.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Experienced",
//                   "Trustworthy"
//               ]
//           },
//           {
//               "record_id": 24,
//               "name": "Sphoia Liu",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-11-20\/1732087667-c76ed97c1e228856ec2bb89fb2ac9080.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Reliable",
//                   "Attentive"
//               ]
//           },
//           {
//               "record_id": 26,
//               "name": "Amy Zhang",
//               "avatar": "http:\/\/test-api-erp-admin.dropshipman.com\/upload\/2024-08-13\/1723541472-f557a5dd26c593311db6be5eac619689.png",
//               "service_years": 5,
//               "customer_rating": 4.9,
//               "service_tags": [
//                   "Dedicated",
//                   "Patient"
//               ]
//           }
//       ]
//   }
// }
export default PlanNewBox;
