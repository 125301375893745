import React from 'react';

const Home =()=> {
        return (
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.98291 13.795H19.9994L19.0287 12.9457L19.9994 12.0963L19.0287 11.2469L19.9994 10.3975L19.0287 9.54815L19.9994 8.69878L19.0287 7.8494L19.9994 7.00003L19.0287 6.15065L19.9994 5.30127L19.0287 4.4519L19.9994 3.60252L19.0287 2.75315L19.9994 1.90377L19.0287 1.05439L19.9994 0.205017H3.98291V13.795Z" fill="#303030"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.9414 0.205017H4.36815V13.795H1.9414C0.869193 13.795 0 12.9258 0 11.8536V8.94149C0.000322721 8.94149 0.00064546 8.94149 0.000968218 8.94149C1.07317 8.94149 1.94237 8.07228 1.94237 7.00006C1.94237 5.92783 1.07317 5.05862 0.000968218 5.05862C0.00064546 5.05862 0.000322721 5.05862 0 5.05862V2.14645C0 1.07423 0.869194 0.205017 1.9414 0.205017Z" fill="#303030"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.9414 0.205017H4.36815V13.795H1.9414C0.869193 13.795 0 12.9258 0 11.8536V8.94149C0.000322721 8.94149 0.00064546 8.94149 0.000968218 8.94149C1.07317 8.94149 1.94237 8.07228 1.94237 7.00006C1.94237 5.92783 1.07317 5.05862 0.000968218 5.05862C0.00064546 5.05862 0.000322721 5.05862 0 5.05862V2.14645C0 1.07423 0.869194 0.205017 1.9414 0.205017Z" fill="white" fillOpacity="0.4"/>
            <path d="M8.81396 4.24719C8.81396 3.98051 9.03016 3.76431 9.29684 3.76431H11.0539C11.0539 3.76431 10.6252 3.9026 10.5285 4.26215C10.4317 4.62169 10.4455 5.14718 10.4455 5.14718V10.3744H8.81396V4.24719Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4482 10.364H11.2917C11.9816 10.364 12.5869 10.2264 13.1075 9.95106C13.6344 9.67575 14.0389 9.2878 14.3212 8.78722C14.6097 8.28664 14.754 7.71411 14.754 7.06962C14.754 6.41887 14.6097 5.84633 14.3212 5.35201C14.0389 4.85144 13.6375 4.46349 13.1169 4.18817C12.5963 3.91286 11.9879 3.76431 11.2917 3.76431H10.4482V5.23846L10.4488 5.14553H11.1882C11.7966 5.14553 12.2701 5.31447 12.6088 5.65236C12.9475 5.99025 13.1169 6.46267 13.1169 7.06962C13.1169 7.67657 12.9475 8.14586 12.6088 8.47749C12.2701 8.80912 11.7966 8.97494 11.1882 8.97494H10.4482V10.364Z" fill="url(#paint0_linear_616_3659)"/>
            <defs>
            <linearGradient id="paint0_linear_616_3659" x1="10.4482" y1="3.76431" x2="10.4482" y2="10.3744" gradientUnits="userSpaceOnUse">
            <stop offset="0.00407736" stopColor="white" stopOpacity="0.5"/>
            <stop offset="1" stopColor="white"/>
            </linearGradient>
            </defs>
            </svg>
            )}

export default Home;
