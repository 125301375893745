import { Box, InlineStack, Modal, Text, Tooltip } from "@shopify/polaris";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import utils from "../../../../utils/utils"
import { useSelector } from "react-redux";
import AppBridge from "../../../../utils/appBridge";
// ai优化积分不足
const Index = ({ 
  userPoints,
  enoughPointsData,
  onClose
}) => {
  const router = useHistory();
  const shopify_level = useSelector(state => state.languageReducer.subScriptData.level);
  const handleViewDetail = () => {
    
    let sendMsg = `Hi, I'm a Premium user and I was wondering if it would be possible to receive additional AI optimization credits?`;

    try {
      // 填充信息并激活对话弹窗
      // window.Intercom('showNewMessage', sendMsg)
      window.Willdesk.show();
      window.Willdesk.chat(sendMsg);
    } catch (error) {
      console.info(error);
    }
};
  return (
    <div>
      <Modal
        open={enoughPointsData}
        onClose={onClose}
        title="Insufficient Points"
        secondaryActions={{
          onAction: () => {
            if (shopify_level === 5) {
              handleViewDetail()
              return
            }
            if (AppBridge.exist()) {
              AppBridge.SetFullscreen(false);
            }
            utils.routeListen('/admin/points')
            utils.routeJump1(router,'/admin/points')
          },
          content: shopify_level === 5 ? 'Contact us' : "Earn Points",
        }}
        primaryAction={shopify_level === 5 ? undefined :{
          onAction: () => {
            if (AppBridge.exist()) {
              AppBridge.SetFullscreen(false);
            }
            utils.routeListen('/admin/subscribe')
            utils.routeJump1(router,'/admin/subscribe')
          },
          content:"Upgrade Plan",
        }}
      >
        <Modal.Section>
        <Box style={{border:'1px solid rgba(227, 227, 227, 1)',width:'max-content',background:'rgb(241, 241, 241)',borderRadius:'8px',padding:'6px'}}>
            <InlineStack blockAlign="center">
                <img src="/product_management/dsm_point.png" alt="Dropshipman" />
                <Tooltip content={`you have ${userPoints?.userPointsData?.available_points} points`} hasUnderline>
                <Text variant="bodyLg" fontWeight="bold" as="span">
                Points
                </Text>
            </Tooltip>
            <span style={{margin:'0 5px',color:'rgba(204, 204, 204, 1)'}}>|</span>
            <span style={{color:'rgba(0, 91, 211, 1)',fontSize:'13px',fontWeight:650}}>{userPoints?.userPointsData?.available_points}</span>
            </InlineStack>
        </Box>
        <Box className="ai-points-less-modal">
        Each AI optimization costs 20 points, but your current balance is insufficient.Ways to Earn Points:
        <li>Refer friends to Dropshipman (Free)</li>
        <li>Follow us on social media (Free)</li>
        <li>Purchase credits</li>
        <li>Place orders</li>
        <li>Complete starter tasks</li>
        <li>Subscribe to our plans
        </li>
        Need More Points?
        <li>Upgrade your plan for additional points</li>
        </Box>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
