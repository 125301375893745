import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Page, Card, Layout, SkeletonBodyText, BlockStack,Toast, Select, Text,
  // Link, Banner,
} from "@shopify/polaris";

import AppBridge from '../../../../utils/appBridge';

import NavTabs from "../components/NavTabs";
import { reqGetUserSettings,reqPostUpdateUserSettings } from '../../../../api';

import SettingPrompt from '../../../../components/SettingPrompt';
import intl from '../../../../i18n/intl';
import { changeLanguageData } from '../../../../slice';
import './index.css';

class ProfileClass extends Component {

  state = {
    ShopOwner: '',
    UserEmail: '',
    isFocusEmail: false,

    AccountName: '',
    AccountEmail: '',
    ResetPasswordActive: false,
    hidePassword: true,
    hideNewPassword: true,
    hideConfirmPassword: true,
    oldPasswordError: '',
    newPasswordError: '',
    confirmPasswordError: '',
    resetPasswordLoading: false,

    ChangeAccountEmailActive: false,
    sendCodeLoading: false,
    verifyLoading: false,
    NewEmailAddress: '',
    newEmailAddressError: '',
    VerifyCode: '',
    verifyCodeError: '',

    StoreName: '',
    // AliExpressId: '',
    // isAuth: true,
    // isExpire: false,
    isEffective: true,
    // ExpireTime: 0,

    //弹窗
    showToast: false,  //弹窗显示
    showToastText: '',  //弹窗文字
    showToastIserror: false, //弹窗是否错误

    loadingPage: true,
    saveDataLoading: false,
    disabledSaveData: true,

    AuthButtonDisable: false,
    disconnectActive: false,

    // PayPal账户数据
    PayPalUserData: {
      email: '',
      status: 0,
      is_effective: 0,
      authUrl: ''
    },
    // Payoneer 账户数据
    PayoneerData:{
      account_id:'',
      email:'',
      expire_time:0,
      status:0
    },

    disconnectType: 0,  // 取消类型：1：速卖通 2：PayPal

    //语言设置
    languageOptions: [
      { label: "English", value: "en" },
      { label: "Français", value: "fr" },
      { label: "Deutsch", value: "de" },
      { label: "Español", value: "es" },
      { label: "Nederland", value: "nl" },
      { label: "Italiano", value: "it" },
      { label: "Portugal", value: "pt-pt" },
      { label: "Portugal(Brasil)", value: "pt-br" },
    ],
    selected: 'en',
    isLanguageChange: false,
    shopify_permission_upgrade: false,
    need_to_upgrade_permission: false,
    needSetPasswordActive: false,
    payonnerButton:false,//按钮加载状态
    payonnerLink:'',//授权链接
    payonnerOff:false,//取消授权
    payonnerOffLoading:false,//取消授权加载状态
  };

  //进入页面调用接口
  componentDidMount() {
    this.getUserSettingsData();
  }
  componentWillUnmount = () => {
    this.setState = (state,callback)=>{
      return;
    };
  }
  componentDidUpdate() {
    let mutation;
    let callback;
    let setting = document.querySelector('#settings_class');
    if (setting) {
      callback = (mutations) => {
        mutations.forEach((item, index) => {
          if (item.type === 'characterData') {
            this.setState({
              disabledSaveData: false
            })
          }
        })
      }
    }
    const config = {
      attributes: true,
      // attributesOldValue: true,
      characterData: true,
      // characterDataOldValue: true,
      childList: true,
      subtree: true
    }
    mutation = new MutationObserver(callback)
    // 开始检测
    mutation.observe(setting, config)
    // 停止检测
    if (!this.state.disabledSaveData) {
      mutation.disconnect()
    }
  }
  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  getUserSettingsData = async () => {
    const params = { type: 'language' };
    const response = await reqGetUserSettings(params);

    // var language = utils.getStore('language'); // 获取localStorage中的语言设置
    // if (!language) {
    //   const lang = (navigator.languages && navigator.languages[0]) || navigator.language; // 获取浏览器语言
    //   language = lang.split('-')[0]; // 语言（简写）
    // }

    if (response.code === '200') {
      var result = response.data.userSetting;
      this.setState({
        selected: result.language, // 语言设置
        loadingPage: false,
      });

    } else {
      this.toggleToast(response.msg);
    }
  }

  // save setting
  saveData = async () => {
    this.setState({ saveDataLoading: true });
    const params = {
      type:'language',
      language: this.state.selected
    };

    const responseData = await reqPostUpdateUserSettings(params);

    this.setState({ saveDataLoading: false });
    this.props.dispatch(changeLanguageData({ language: this.state.selected, isLanguageChange: true }))

    // 选择的语言
    window.language = this.state.selected;

    if (responseData.code === "200") {
      this.toggleToast('Saved successfully');
      if (this.state.isLanguageChange) { // 修改语言，则刷新框架组件
        this.props.callBackFun();
      }
      this.setState({
        disabledSaveData: true
      })
      setTimeout(() => {
        this.setState({
          disabledSaveData: true
        })
      }, 500);
    } else {
      this.toggleToast(responseData.msg);
    }
  };

  // 语言设置
  handleSelectChange = (value) => {
    this.setState({
      selected: value,
      isLanguageChange: true
    });
  }


  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} duration={2000} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const {
     saveDataLoading, disabledSaveData,
      languageOptions, selected
    } = this.state;

    const initSkeletonPage = <div className="SettingsPage__Content">
       <Layout>
          <Layout.Section>
          <Card>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
          </Card>
        </Layout.Section>
          </Layout>
      </div>

    const pageContent = this.state.loadingPage ? initSkeletonPage : <div className="SettingsPage__Content">
      <section className="Panel_Content">
      <BlockStack gap={400}>
        <Card>
          <Text variant="headingMd" as="h2">
          {intl.get("Select your language")}
          </Text>
          <div style={{paddingTop:"10px",width:'50%'}}>
            <Select
              label={intl.get("Language")}
              options={languageOptions}
              onChange={this.handleSelectChange}
              value={selected}
            />
            </div>
        </Card>
        </BlockStack>
      </section>
      </div>

    return (

      <div className={AppBridge.exist() ? "Setting__Page_Content Content_within" : "Setting__Page_Content Content_outside"} id="settings_class">
        {/* 未保存拦截弹窗 */}
        <SettingPrompt disabledSaveData={disabledSaveData} url='/admin/settings/language' saveData={this.saveData} />
        <Page
          title={AppBridge.exist() ? '' : intl.get("Settings")}
          primaryAction={
            // AppBridge.exist() ? '' : 
            {
              content: intl.get("Save"),
              onAction: this.saveData,
              loading: saveDataLoading,
              disabled: disabledSaveData
            }
          }
          separator
        >

          <section className="SettingsPage__Box">
            <NavTabs selected="Language" saveData={this.saveData} loading={saveDataLoading} disabled={disabledSaveData}/>
            {pageContent}
          </section>

          {toastMarkup}
          <div className="space"></div>
        </Page>

      </div>
    );

  }
}

export default connect()(ProfileClass);