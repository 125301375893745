import React from 'react';

const index =()=> {
        return (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.74999 3C7.74999 2.58579 7.41421 2.25 6.99999 2.25C6.58578 2.25 6.24999 2.58579 6.24999 3L6.24999 7C6.24999 7.19891 6.32901 7.38968 6.46966 7.53033L8.46966 9.53033C8.76255 9.82322 9.23743 9.82322 9.53032 9.53033C9.82321 9.23744 9.82321 8.76256 9.53032 8.46967L7.74999 6.68934L7.74999 3Z" fill="#4A4A4A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7Z" fill="#4A4A4A"/>
            </svg>
            )}

export default index;
