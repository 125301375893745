import React, { memo, useEffect } from 'react'
import { Modal,  Toast } from '@shopify/polaris'
import intl from '../../../../../../i18n/intl';
import { useState } from 'react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { API_DOMAIN } from '../../../../../../utils/constants';
import utils from '../../../../../../utils/utils';
import AppBridge from '../../../../../../utils/appBridge';
import { PayPalButton } from 'react-paypal-button-v2';

const RefundTipsModal = memo(({paymentData,handlePayForOrderModal,getOrderListData,getSearchParam }) => {

  useEffect(() => {

    let isMount = false;
    if (!isMount ) {
   
    }
    return ()=>isMount = true
  }, []) //数据初始化


  const [showToast,setShowToast] = useState(false);
  const [showToastText,setShowToastText] = useState('');
  const [showToastIserror,setShowToastIserror] = useState(false);
  
    //提示弹窗
  const  toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast)
    setShowToastText(showToastText)
    setShowToastIserror(showToastIserror)
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
  return (
    <div>
      {toastMarkup}
      <Modal
        open={paymentData.toPayActive}
        onClose={() =>{ handlePayForOrderModal(0, 0, '', 0, false, 1)
  }
      }
        title={intl.get('Order') + ':' + paymentData.toPayOrderName}
        secondaryActions={{
          content: intl.get('Cancel'),
          onAction: () => handlePayForOrderModal(0, 0, '', 0, false, 1),
        }}
        loading={paymentData.toCheckoutLoading}
      >
        <Modal.Section>
          {!paymentData.toCheckoutLoading && paymentData.createPayPalOrderId !== 0 ? (
            <div
              style={{
                margin: 'auto',
                padding: '10px 10px 0 10px',
                minHeight: '50px',
                maxWidth: '200px',
              }}
            >
              <PayPalButton
                createOrder={(data, actions) => {
                  // return actions.order.create({
                  //   purchase_units: [{
                  //     amount: {
                  //       currency_code: "USD",
                  //       value: "0.01"
                  //     }
                  //   }],
                  //   // application_context: {
                  //   //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                  //   // }
                  // });

                  // let OrderId = '9MM647485G896503M'; 4NK36417YX573310B
                  // console.info('order_id',OrderId);

                  let OrderId = paymentData.createPayPalOrderId
                  return OrderId
                }}
                catchError={(error) => {
                  console.warn('error', error)
                }}
                // onSuccess={(data, actions) => {
                //   console.info('success',data);
                //   console.info(actions);
                // }}
                // onButtonReady={()=>{
                //   console.info('Ready');
                // }}
                // 交易完成
                onApprove={async (data, actions) => {
                  // Capture the funds from the transaction
                  let timeStr = new Date().getTime()

                  let sessionToken = ''
                  if (AppBridge.exist()) {
                    sessionToken = await getSessionToken(AppBridge.app)
                    sessionToken = 'ShopifyBridge ' + sessionToken
                  } else {
                    sessionToken = 'ShopifyAdmin ' + utils.getStore('S_AUTHORIZATION')
                  }

                  let shopDomain = utils.getStore('S_SHOP_DOMAIN') || window.S_SHOP_DOMAIN

                  let header = {
                    Authorization: sessionToken,
                    usertoken: utils.getStore('S_USER_TOKEN') || window.S_USER_TOKEN,
                    shopdomain: shopDomain,
                    timestr: timeStr,
                    sign: utils.getSign(shopDomain, timeStr),
                    'content-type': 'application/json',
                  }

                  return fetch(API_DOMAIN + '/api/v1/capture-paypal-transaction', {
                    method: 'post',
                    headers: header,
                    body: JSON.stringify({
                      orderID: data.orderID,
                    }),
                  })
                    .then(function (res) {
                      return res.json()
                    })
                    .then(function (details) {
                      if (details.code === '200') {
                        alert('Transaction successfully!')
                      } else {
                        alert('Sorry, your transaction could not be processed.')
                      }

                      // 关闭弹窗
                      handlePayForOrderModal(0, 0, '', 0, false, 1)

                      //  刷新页面
                      getOrderListData(getSearchParam())
                      // getDropshipmanOrderList()
                    })
                }}
                style={{
                  label: 'paypal',
                  size: '30',
                }}
                options={{
                  clientId:
                    API_DOMAIN === 'https://api.dropshipman.com'
                      ? 'AcHhJoi0eq-ynRib-_Z0LoUnwNcjS4EiHu9K9yptCSTOlGcCFWFtZI27e0o3gre_RWnRtuubqg-uRwVB'
                      : 'sb',
                }}
              />
            </div>
          ) : (
            ''
          )}
        </Modal.Section>
      </Modal>
    </div>
  )
})

export default RefundTipsModal
