import { Box, Card, Tooltip } from '@shopify/polaris';
import React from 'react';
import utils from "../../../../utils/utils";
const PropertyBox = ({pageData,router})=> {
        return (
            <div>
                <Box className="proper_list">
                    <Box className="proper_list_item">
                <Card>
                        <Box className="proper_list_item_l">
                            <img width={60} src="/dashboard/property_icon1.png" alt="Dropshipman" />
                        </Box>
                        <Box className="proper_list_item_r">
                            <p>
                            <Tooltip width={244} content={
                                <Box style={{padding: '8px',width: '244px'}}>
                                    <Box><strong>Dropshipman Credits</strong></Box>
                                    <Box>Top up Dropshipman Credits to cover fulfillment expenses.</Box>
                                </Box>
                            }>
                            <span>Dropshipman Credits</span>
                            </Tooltip>
                            </p>
                            <h3 onClick={()=>{
                                utils.routeListen("/admin/wallet");
                                utils.routeJump1(router, "/admin/wallet");
                            }}>$ {pageData?.wallet_money} USD</h3>
                        </Box>
                    </Card>
                    </Box>
                    <Box className="proper_list_item">
                    <Card>
                        <Box className="proper_list_item_l">
                            <img width={60} src="/dashboard/property_icon2.png" alt="Dropshipman" />
                        </Box>
                        <Box className="proper_list_item_r">
                            <p>
                            <Tooltip width={244} content={
                                <Box style={{padding: '8px',width: '244px'}}>
                                    <Box><strong>Points</strong></Box>
                                    <Box>Earn points through tasks, referrals, order spending, and subscriptions, redeemable for discounts or product exchanges.</Box>
                                    <Box style={{
                                         padding: "6px 7px 6px 7px",
                                         borderRadius: "4px",
                                         backgroundColor: "rgba(243, 243, 243, 1)",
                                         color: "rgba(0, 91, 211, 1)",
                                         marginTop:'5px'
                                    }}
                                    >100 points=1 usd</Box>
                                </Box>
                            }>
                            <span>Points</span>
                            </Tooltip>
                            </p>
                            <h3 onClick={()=>{
                                utils.routeListen("/admin/points");
                                utils.routeJump1(router, "/admin/points");
                            }}>{pageData?.available_points}</h3>
                        </Box>
                    </Card>
                    </Box>
                    <Box className="proper_list_item">
                    <Card>
                        <Box className="proper_list_item_l">
                            <img width={60} src="/dashboard/property_icon3.png" alt="Dropshipman" />
                        </Box>
                        <Box className="proper_list_item_r">
                            <p>
                            <Tooltip width={244} content={
                                <Box style={{padding: '8px'}}>
                                    <Box><strong>Coupons</strong></Box>
                                    <Box>Coupons can be used to offset order amounts.</Box>
                                </Box>
                            }>
                            <span>Coupons</span>
                            </Tooltip>
                            </p>
                            <h3 onClick={()=>{
                                utils.routeListen("/admin/coupon");
                                utils.routeJump1(router, "/admin/coupon");
                            }}>{pageData?.coupon_num}</h3>
                        </Box>
                    </Card>
                    </Box>
                </Box>
            </div>
        );
}

export default PropertyBox;
