import { Modal } from "@shopify/polaris";
import React, { useState } from "react";

const Index = ({ 
  deleteVariantData,
  handleSelectionChange,
  setDeleteVariantData,
  detailData,
  selectedResources,
  setFilteredData,
  setDetailData,
  setIsInput,
  toastFun
}) => {
  
  const [loading,setLoading] = useState(false);
  const handleClose = () => {
   
    setLoading(false)
    setDeleteVariantData((prev)=>({
      ...prev,
      open:false,
      id:[]
    }))
  };
  const handleProductDelete = ()=>{
    setLoading(true)
   
    try {
      setTimeout(() => {
        let list = detailData.variants.filter((item)=>!selectedResources.includes(item.id));
        setFilteredData(list);
        setDetailData((prev)=>({
          ...prev,
          variants:list
        }))
        setLoading(false)
        setIsInput(true)
        handleSelectionChange('page',false,0)
        handleClose()
        toastFun('Deleted Successfully')
      }, 3000);
      
     
  } catch (error) {
      
  }
  }
  
  return (
    <div>
      <Modal
        size="small"
        open={deleteVariantData.open}
        onClose={handleClose}
        title={"Confirm to delete variant" }
        secondaryActions={{
          onAction: () => {
            handleClose();
          },
          content: "Cancel",
        }}
        primaryAction={{
          onAction: () => {handleProductDelete()},
          loading: loading,
          destructive: true,
          content: "Delete",
        }}
      >
        <Modal.Section>
        <p style={{fontSize:'13px'}}>After deleting a variant, the selected variant will no longer appear in the list of available variants. Are you sure you want to delete the selected variant?</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
