import {
    Modal,
    TextField,
    Link,
    Toast,
    Autocomplete,
    InlineStack,
  } from "@shopify/polaris";
  import React, { useState, useEffect, useCallback } from "react";
  import intl from "../../../../i18n/intl";
  import {
    reqPostProductManagementProductsAliexpressImport,
  } from "../../../../api";
  import utils from "../../../../utils/utils";
  import BackgroundImagePosition from "../../../../components/BackgroundImagePosition";
  import { useHistory } from "react-router-dom";
  
  /**
   *
   * @param {Object} data
   * data.open 弹窗开关状态
   * data.type 1 导入 2删除 345批量操作collections, tags, product type
   * data.count 选中的数量
   * @param {Function} onChange
   * 弹窗回调
   *
   */
  const listData = [
    {
      value: "US",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="US" />{" "}
      <span style={{ marginLeft: 8 }}>United States</span>
    </div>,
    },
    {
      value: "AU",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="AU" />{" "}
      <span style={{ marginLeft: 8 }}>Australia</span>
    </div>,
    },
    {
      value: "BE",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="BE" />{" "}
      <span style={{ marginLeft: 8 }}>Belgium</span>
    </div>,
    },
    {
      value: "BR",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="BR" />{" "}
      <span style={{ marginLeft: 8 }}>Brazil</span>
    </div>,
    },
    {
      value: "CN",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="CN" />{" "}
      <span style={{ marginLeft: 8 }}>China</span>
    </div>,
    },
    {
      value: "FR",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="FR" />{" "}
      <span style={{ marginLeft: 8 }}>France</span>
    </div>,
    },
    {
      value: "DE",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="DE" />{" "}
      <span style={{ marginLeft: 8 }}>Germany</span>
    </div>,
    },
    {
      value: "IT",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="IT" />{" "}
      <span style={{ marginLeft: 8 }}>Italy</span>
    </div>,
    },
    {
      value: "PL",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="PL" />{" "}
      <span style={{ marginLeft: 8 }}>Poland</span>
    </div>,
    },
    {
      value: "RU",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="RU" />{" "}
      <span style={{ marginLeft: 8 }}>Russian Federation</span>
    </div>,
    },
    {
      value: "ES",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="ES" />{" "}
      <span style={{ marginLeft: 8 }}>Spain</span>
    </div>,
    },
    {
      value: "UK",
      label: <div style={{ display: "flex", alignItems: "center" }}>
      <BackgroundImagePosition country="UK" />{" "}
      <span style={{ marginLeft: 8 }}>United Kingdom</span>
    </div>,
    },
    
  ]
  const Index = ({ data, onChange,setPlanData,setUserPoints,userPoints}) => {
    const { open, type, from } = data;
    const [active, setActive] = useState(open); // 弹窗状态
    const [value, setValue] = useState(''); // 文本框的值
    const [error, setError] = useState(''); // 文本框是否错误
    const [loading, setLoading] = useState(false); // add 按钮loading
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState("US");
    const [options, setOptions] = useState(listData);
    const router  = useHistory()
    const handleChangeTextField = (newValue) => {
      if (newValue?.length > 500) {
        return;
      }
      setValue(newValue);
      setError('');
    };
    const [showToast, setShowToast] = useState(false);
    const [showToastText, setShowToastText] = useState("");
    const [showToastIserror, setShowToastIserror] = useState(false);
    //提示弹窗
    const toggleToast = (showToastText, showToastIserror = false) => {
      setShowToast(!showToast);
      setShowToastText(showToastText);
      setShowToastIserror(showToastIserror);
    };
  
    const toastMarkup = showToast ? (
      <Toast
        content={showToastText}
        onDismiss={() => toggleToast("")}
        error={showToastIserror}
      />
    ) : null;
  
    useEffect(() => {
      let isMount = false;
      if (data.open && !isMount) {
        setActive(data.open);
        if (data?.al_id) {
          setValue(data?.al_id)
        }
        if (data?.country_code) {
          setInputValue(data?.country_code)
        }
      }
      return () => {
        isMount = true;
      };
    }, [data]);
    // 弹窗关闭回调
    const handleChange = (val) => {
      setActive(false);
      onChange(type, false,val);
      setError('');
      setValue("");
      setInputValue('US')
      setOptions(listData)
      setSelectedOptions([])
    };
    // 弹窗关闭回调
    const handleChangeoff = () => {
      setActive(false);
      onChange("dropshipman", false);
      setError('');
      setValue("");
      setInputValue('US')
      setOptions(listData)
      setSelectedOptions([])
    };
  
    // 处理添加产品事件
    const handleAddProduct = async () => {
      // 输入框为空
      
      if (!value) {
        setError('AliExpress product URL or ID required.');
        return;
      }
      try {
        
      
      setLoading(true);
      setError('');
      const params = {
        encoded: value,
        country_code:inputValue,
        record_id:data.record_id ? data.record_id : 0,
        is_point:userPoints.userPointsRedeem.isPoints ? 1 : 0,
      };
        const response = await reqPostProductManagementProductsAliexpressImport(params);
        
        setLoading(false);
        if (response.code === "200") {
          handleChange(data.record_id ? data.record_id : 0);
          setPlanData((prev)=>({
            ...prev,
            productLimit:prev.aliexpressLimit + 1
          }))
          if (userPoints.userPointsRedeem.isPoints) {
            setUserPoints((prevValue)=>({
              ...prevValue,
              userPointsData:{
                ...prevValue.userPointsData,
                available_points:Number(prevValue.userPointsData.available_points) - 150
              }
            }))
          }
          
         
          toggleToast("Product is importing");
          if (from) {
            utils.routeListen("/admin/product_management?selectedView=1");
            utils.routeJump1(router, "/admin/product_management?selectedView=1");
          }
        } else {
          setError(response.msg)
        }
      } catch (error) {
        
      }
    };
  
    
   
  
    const updateText = (value) => {
        setInputValue(value);
      };
    const updateSelection = useCallback(
      (selected) => {
        selected.map((selectedItem) => {
          const matchedOption = options.find((option) => {
            return option.value.match(selectedItem);
          });
          return matchedOption && matchedOption.label;
        });
        setSelectedOptions(selected);
        setInputValue(selected[0] || "");
      },
      [options]
    );
   
    const countryFun = (val)=>{
      
      let text = '';
      options.map((item) => {
        if (val === item.value) {
            text =  <div style={{ display: "flex", alignItems: "center" }}>
              {item.label}
            </div>
        }
        return item;
    })
    return text;
  }
    const textField = (
      <div className="import_products_ali_express_country">
        <div className="import_products_ali_express_country_input">
          <Autocomplete.TextField
            onChange={(value) => {updateText(value)}}
            value={inputValue}
            placeholder="Search"
            autoComplete="off"
          />
        </div>
        <div  className="import_products_ali_express_country_show">{countryFun(inputValue)}</div>
      </div>
    );
    
    return (
      <>
        <Modal
          open={active}
          onClose={() => handleChangeoff()}
          title={"Add AliExpress Products"}
          primaryAction={{
            content: intl.get("Add Product"),
            onAction: () => handleAddProduct(),
            loading,
          }}
        >
          <Modal.Section>
            <p style={{ marginBottom: "1.5rem" }}>
              {type === "1688"
                ? intl.get(
                    "Through the 1688 product link or ID, you can add 1688 products and import them to your store. And orders for the product will be fulfilled by Dropshipman."
                  )
                : intl.get(
                    "Through the AliExpress product link or ID, you can add AliExpress products and import them to your store. And orders for the product will be fulfilled by Dropshipman."
                  )}
            </p>
            <TextField
              label={<div style={{fontWeight:600}}>URL/ID</div>}
              value={value}
              maxLength={500}
              error={
                error !== ""
                  ? error
                  : null
              }
              onChange={handleChangeTextField}
              placeholder={intl.get(
                "Paste {{type}} product link or ID to here",
                type
              )}
              autoComplete="off"
              showCharacterCount
            />
            <div style={{ marginTop:24 }}>
              <div style={{marginBottom:'5px',fontWeight:600}}>Region</div>
              <Autocomplete
                options={options}
                selected={selectedOptions}
                onSelect={updateSelection}
                textField={textField}
              />
            </div>
            <div style={{ textAlign: "left", marginTop: "5px" }}>
              <InlineStack gap={100}>
              <span>Aliexpress site:</span>
              <Link
                url={
                  type === "1688"
                    ? "https://www.1688.com"
                    : "https://www.aliexpress.com"
                }
                external
              >
                {type === "1688"
                  ? "https://www.1688.com"
                  : "www.aliexpress.com"}
              </Link>
              </InlineStack>
            </div>
          </Modal.Section>
        </Modal>
        {toastMarkup}
      </>
    );
  };
  
  export default Index;
  