import { Box, InlineStack, Modal, Scrollable, Text } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import {
    CartIcon
  } from '@shopify/polaris-icons';
import { store } from "../../../../store";
import { changeBannerData } from "../../../../slice";
import { reqGetProductManagementProductsDetail,reqPostCartProductAdd } from "../../../../api";
// ai优化积分不足
const Index = ({ 
    cartData,
    onClose,
    callBackFun,
    toastFun
}) => {
    const [detailData, setDetailData] = useState({
        skuImage: "",
        data: {},
        });
   const [detailVariant, setDetailVariant] = useState({
        skuData: [], //sku数据
        variantData: [], //变体数据
        addCartItem: {
          id: 0,
          vid: 0,
        }, //购物车按钮
        addToCartLoading: false,
        disableAddToCart: true, // 购物车按钮是否禁用
        defaultPrice: "", // 默认价格
        defaultImage: "", // 默认图片
        skuImage: "", // sku图片
        indexPrice: 0, // 当前价格
        currentInventory: "",
        currentProfit: "",
      });

      const handleSelect = (val, ind) => {
        let data = detailVariant.skuData;
        let variant = detailVariant.variantData;
        let isDisable = true;
        // 匹配购物车
        let setAddItem = detailVariant.addCartItem;
        let variantIndex = "";
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].value.length > 0) {
              if (data[i].selected === val && i === ind) {
                data[i].selected = "";
                setDetailVariant((prevValue) => ({
                  ...prevValue,
                  skuData: data,
                  indexPrice: detailVariant.defaultPrice,
                  disableAddToCart: true,
                  skuImage: detailVariant.defaultImage,
                  currentInventory: detailData.data?.inventory,
                }));
                setDetailData((prevValue) => ({
                  ...prevValue,
                  skuImage: detailVariant.defaultImage,
                }));
    
                // https://cc-west-usa.oss-accelerate.aliyuncs.com/214ffd62-070f-4218-8203-83da7b710b02.jpg?x-oss-process=image/resize,m_pad,w_400,h_400
                return;
              }
              for (let j = 0; j < data[i].value.length; j++) {
                if (data[i].value.includes(val)) {
                  if (val && i === ind) {
                    data[i].selected = val;
                    setDetailVariant((prevValue) => ({
                      ...prevValue,
                      disableAddToCart: true,
                    }));
                  }
                }
              }
            }
            //是否全选了
            if (variant[data[0].selected]?.id) {
              variantIndex = data[0]?.selected + "-";
            } else {
              variantIndex += data[i]?.selected + "-";
            }
          }
        }
        let varr = variant[variantIndex.slice(0, variantIndex.length - 1)];
        if (varr) {
          isDisable = false;
          setAddItem.record_id =  detailData.data?.record_id;
    
          setAddItem.dsm_vid = varr?.id;
          setDetailData((prevValue) => ({
            ...prevValue,
            skuImage:
              varr?.sku_image !== "" && varr?.sku_image !== null
                ? varr?.sku_image
                : detailVariant.defaultImage,
          }));
          setDetailVariant((prevValue) => ({
            ...prevValue,
            skuImage:
              varr?.sku_image !== "" && varr?.sku_image !== null
                ? varr?.sku_image
                : detailVariant.defaultImage,
            indexPrice: varr?.price,
            disableAddToCart: isDisable,
            currentInventory: varr?.sku_inventory,
          }));
        }
        setDetailVariant((prevValue) => ({
          ...prevValue,
          skuData: data,
          addCartItem: setAddItem,
        }));
      };

       // 添加产品到购物车
        const handleAddToShoppingCart = async () => {
          if (!detailVariant.disableAddToCart) {
            let data = detailVariant.addCartItem;
      
            if (!data.dsm_vid || !data.record_id) {
              toastFun("Something wrong");
            }
            setDetailVariant((prevValue) => ({
              ...prevValue,
              addToCartLoading: true,
            }));
            const params = {
              record_id:  detailData.data?.record_id,
              dsm_vid: data.dsm_vid,
              // cart: JSON.stringify(data),
              // type: "custom",
            };
      
            const response = await reqPostCartProductAdd(params);
            setDetailVariant((prevValue) => ({
              ...prevValue,
              addToCartLoading: false,
            }));
      
            if (response.code === "200") {
              toastFun("Added successfully");
              // 回调更新悬浮购物车按钮
              if (response.data !== 0) {
                callBackFun({ number: response.data.number });
              }
            } else {
              // this.toggleToast(response.msg);
              store.dispatch(
                changeBannerData({
                  bannerData: {
                    show: true,
                    desc: response.msg,
                  },
                })
              );
            }
          }
        };
        const [initPage, setInitPage] = useState(true); // 初始化页面
      // 获取详情数据
        const getDetailData = async (data) => {
          setInitPage(true)
          try {
            if (data) {
              let params = {
                record_id: data.record_id,
                // tag: (data?.winning === 1) ? "winning" : "home",
              };
              
              let res = await reqGetProductManagementProductsDetail(params);
              if (res.code === "200") {
                let pageData = res.data;
                setDetailVariant({
                  skuData: pageData?.options, //sku数据
                  variantData: pageData?.variants, //变体数据
                  addCartItem: {
                    id: 0,
                    vid: 0,
                  }, //购物车按钮
                  addToCartLoading: false,
                  disableAddToCart: true, // 购物车按钮是否禁用
                  defaultPrice: pageData?.sell_price, // 默认价格
                  defaultImage: pageData?.image_list[0], // 默认图片
                  skuImage: pageData?.image_list[0], // sku图片
                  indexPrice: pageData?.sell_price, // 当前价格
                  currentInventory: pageData?.inventory,
                  currentProfit: pageData?.estimated_profit, // 当前利润
                });
                setDetailData((prevValue) => ({
                  ...prevValue,
                  data: res.data,
                  skuImage:res.data.image_url,
                  skuImageList: res.data.image_list,
                }));
                
              }
              setInitPage(false)
            }else {
              setInitPage(false)
            }
          } catch (error) {
          }
        };
      useEffect(() => {
        let isMount = false;
        if (!isMount && cartData.open && cartData.record_id !== '') {
            getDetailData(cartData)
        }
        return () => {
            isMount = true;
        };
      }, [cartData]);
  return (
    <div>
      <Modal
        open={cartData.open}
        onClose={onClose}
        title="Select Sample SKU"
        loading={initPage}
        secondaryActions={{
          onAction: () => {
            onClose()
          },
          content: "Cancel",
        }}
        primaryAction={{
          onAction: () => {
            handleAddToShoppingCart()
          },
          icon:CartIcon,
          disabled:detailVariant.disableAddToCart,
          loading:detailVariant.addToCartLoading,
          content: "Add to Cart",
        }}
        footer={
            <p style={{fontSize:'16px'}}>Total: $
            {detailVariant?.indexPrice !== ""
              ? detailVariant?.indexPrice
              : detailVariant?.defaultPrice}</p>
        }
      >
        <Modal.Section>
        <Box className="product_modal_details">
            <InlineStack gap={200} wrap="nowrap">
            <Box className="product_modal_image">
                <img src={detailData.skuImage} alt="Dropshipman" />
            </Box>
            <Box className="product_modal_details_content">
                <Box className="product_detail_variant_title" title={detailData.data.title}>
                <Text variant="headingXl" as="h4">
                {detailData.data.title}
                </Text>
                </Box>
                <Scrollable shadow style={{height: '250px'}} focusable>
                 <Box className="product_detail_variant_sku">
                          <div className="product_sku_attr_box">
                            <div className="product_sku_attr_list">
                              {detailVariant.skuData.slice(0, 1).map((item, ind) => (
                                <div className="product_sku_attr_item" key={ind}>
                                  <span className="product_sku_attr_title">
                                    {item.name}&nbsp;{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item.selected ? "(" + item.selected + ")" : ""}
                                    </span>
                                  </span>
                                  {item.value.length > 0 && (
                                    <div className="product_sku_attr_item_list">
                                      {item.value.map((it, k) => (
                                        <span
                                          className={
                                            item.selected === it
                                              ? "item_value item_value_select"
                                              : "item_value"
                                          }
                                          onClick={() => handleSelect(it, ind)}
                                          key={k}
                                        >
                                          {it}
                                        </span>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                              {detailVariant.skuData.slice(1, 3).map((item, ind) => (
                                <div className="product_sku_attr_item" key={ind}>
                                  <span className="product_sku_attr_title">
                                    {item.name}&nbsp;{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item.selected ? "(" + item.selected + ")" : ""}
                                    </span>
                                  </span>
                                  {item.value.length > 0 && (
                                    <div className="product_sku_attr_item_list">
                                      {item.value.map((it, k) => (
                                        <span
                                          className={
                                            item.selected === it
                                              ? "item_value item_value_select"
                                              : "item_value"
                                          }
                                          style={{
                                            color: "rgb(32, 34, 35)",
                
                                            pointerEvents: "auto",
                                          }}
                                          onClick={() => handleSelect(it, ind + 1)}
                                          key={k}
                                        >
                                          {it}
                                        </span>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                  </Box>
                  </Scrollable>
            </Box>
            </InlineStack>
        </Box>
        </Modal.Section>
      </Modal>
    </div>
  );
};


export default Index;
