import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ActionList, Box, Button, Card, Collapsible, Icon, InlineStack, Popover } from "@shopify/polaris";
import CryptoJS from "crypto-js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import intl from "../../i18n/intl";

import {
  MenuHorizontalIcon,
  XIcon,
  CaretUpIcon,
  CaretDownIcon
} from '@shopify/polaris-icons';
import LazyLoad from 'react-lazyload';
import { reqPostCardDismiss } from "../../api";
import utils from '../../utils/utils'
/**
 * 调用中台Api参数
 */
const DSM_SECRET_KEY = "FJMKRGFW1PWYZIZLKHDFQWV5Q01CQGDM6XE432QQ";
const ACCESS_TYPE = "DSMAPP";

class AppRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show === undefined ? false : props.show,
      appList: partnerElite, // 推荐App列表数组
      popoverDelete:false,
      cloShow:true
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show === undefined ? false : nextProps.show,
    });
  }

  //进入页面调用接口
  componentDidMount() {
    if (this.props.show) {
      this.getDropshipmanAds();
    }
  }

  // 获取广告
  getDropshipmanAds = async () => {
    try {
      // 配置请求头
      let timestamp = String(new Date().valueOf());
      timestamp = timestamp.slice(0, 10);
      let DSM_TOKEN = CryptoJS.MD5(DSM_SECRET_KEY + timestamp);

      var setHeaders = new Headers();
      setHeaders.append("ACCESS-TYPE", ACCESS_TYPE);
      setHeaders.append("ACCESS-TOKEN", DSM_TOKEN);
      setHeaders.append("ACCESS-TIME", timestamp);
      setHeaders.append("Content-Type", "application/json");

      await fetch(
        "https://mpapi.channelwill.com/api/v1/middleground/getRecommend",
        {
          method: "GET",
          headers: setHeaders,
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (!response.data) return false;

          let ResData = response.data;
          let data = [];
          if (ResData.length > 0 && ResData[0]) {
            data = ResData[0].appInfo ? ResData[0].appInfo : [];
          }

          if (data.length > 0) {
            let ads = [];
            let item1 = [];
            let item2 = [];
            let item3 = [];
            let item4 = [];

            for (let i = 0; i < data.length; i++) {
              if (!data[i]) continue;

              if (i <= 5) {
                item1.push(data[i]);
              } else if (i <= 11) {
                item2.push(data[i]);
              } else if (i <= 17) {
                item3.push(data[i]);
              } else if (i <= 23) {
                item4.push(data[i]);
              }
            }

            if (item1.length > 0) {
              ads.push(item1);
            }
            if (item2.length > 0) {
              ads.push(item2);
            }
            if (item3.length > 0) {
              ads.push(item3);
            }
            if (item4.length > 0) {
              ads.push(item4);
            }

            this.setState({
              appList: ads,
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (err) {
      console.warn("Error:", err);
    }
  };
  handleClosePopover = async ()=>{
    let res = await reqPostCardDismiss({type:5})
    if (res.code === '200') {
      this.props.setIsPartners()
    }
  }
  render() {
    const {
      state: { show, appList },
    } = this;

    // 轮播组件全局配置
    const globalConfig = {
      autoPlay: true,
      interval: 5000,
      showArrows: true,
      showIndicators: appList.length > 1 ? true : false,
      showStatus: false,
      infiniteLoop: true,
      showThumbs: false,
    };

    let AppList = "";
    if (appList.length > 0) {
      AppList = appList.map((item, key) => {
        let single = item.map((it, ik) => {
          return (
            <div className="App_Recommend_Link" key={ik}>
              <a href={it.link} target="_blank" rel="noopener noreferrer">
                <div className="App_Recommend_Item">
                  <div className="Top">
                  <LazyLoad once><img width={50} height={50} src={it.logo} alt="DropShipMan" /></LazyLoad>
                    <div className="App_Name">
                      <div className="title">{it.title}</div>

                      <div className="star_box">
                        <span>{it.score.toFixed(1)}</span>
                        <img
                          src="/start.png?v=123"
                          alt="DropShipMan"
                          className="star"
                        />
                        <img
                          src="/start.png?v=123"
                          alt="DropShipMan"
                          className="star"
                        />
                        <img
                          src="/start.png?v=123"
                          alt="DropShipMan"
                          className="star"
                        />
                        <img
                          src="/start.png?v=123"
                          alt="DropShipMan"
                          className="star"
                        />
                        <img
                          src="/start.png?v=123"
                          alt="DropShipMan"
                          className="star"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="Bottom">
                    <span title={it.recommend}>{it.recommend}</span>
                  </div>
                </div>
              </a>
            </div>
          );
        });

        return (
          <div
            className="dropshipman-carousel-slide App_Recommend_content"
            key={key}
          >
            {single}
          </div>
        );
      });
    }

    const pageContent = show && AppList && (
      <Card>
        <Box>
        <div className="elite_partner">
            <div>{intl.get("Recommended Apps")}</div>
            <div>
              <InlineStack blockAlign="center" wrap={false}>
              <span
                className="elite_partner_span"
                style={{
                  appearance: "none",
                  display: "inline",
                  textAlign: "inherit",
                  padding: 0,
                  background: "none",
                  border: 0,
                  fontSize: "14px",
                  textDecoration: "none",
                  fontWeight:400,
                  cursor: "pointer",
                  marginRight:6
                }}
                onClick={() => {
                  utils.routeListen('/admin/partnerShip/partners')
                  utils.routeJump(this,"/admin/partnerShip/partners")
                }}
              >
               {intl.get("View More")}
              </span>
              <Popover
              active={this.state.popoverDelete}
              activator={<Button variant="tertiary" onClick={()=>this.setState({popoverDelete:true})}>
              <Icon
              source={MenuHorizontalIcon}
              tone="base"
            />
            </Button>}
              autofocusTarget="first-node"
              onClose={()=>this.setState({popoverDelete:false})}
              >
                <ActionList
                actionRole="menuitem"
                items={[{content:<div onClick={()=>this.handleClosePopover()} style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}><Icon
                  source={XIcon}
                  tone="base"
                />
               {intl.get("Dismiss")}</div>}]}
              />
            </Popover>
            <span>
            {this.state.cloShow ? (
              <Button
                variant="tertiary"
                onClick={() =>
                  {this.setState({cloShow:false})}
                }
              >
                <Icon source={CaretUpIcon} tone="base" />
              </Button>
            ) : (
              <Button
                variant="tertiary"
                onClick={() =>
                  {this.setState({cloShow:true})}
                }
              >
                <Icon source={CaretDownIcon} tone="base" />
              </Button>
            )}
            </span>
              </InlineStack>
            </div>
          </div>
        </Box>
        <Collapsible
                open={this.state.cloShow}
                id="basic-collapsible"
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
        <div className="Dropshipman_App_Recommend">
          <Carousel {...globalConfig}>{AppList}</Carousel>
        </div>
        </Collapsible>
      </Card>
    );

    return <div style={{minHeight:'460px' }}>{pageContent}</div>;
  }
}
const partnerElite = [
  [
    {
      type: 1,
      name: "Trustoo Product & Ali Reviews",
      title: "Trustoo",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/JyS5G15es2ZgxFMuNf6aSV9wlAUtAg5DYUZB4oOr.png",
      reviews: "5 stars",
      recommend:
        "The #1 Shopify review app. Turn customers into your best marketers!",
      comments: 1823,
      score: 5,
      is_recommend: 0,
      call_action: "Start free",
      discount: "Exclusive offer - 70% off",
      link: "https://share.channelwill.com/share?utm_source=channelwill&utm_medium=affiliate&utm_campaign=DSM&utm_content=212oMjT5Hfx",
    },
    {
      type: 1,
      name: "Broken link",
      title: "Broken Link 404/301 Redirect",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/JnvlXYRjMGBGQUdgcw32uoFoFOSxgOLvLfTEb8QF.png",
      reviews: "5 stars over 102",
      recommend: "SEO/Url Redirect, Auto-Fix 404 Errors, Bulk 301 Redirect",
      comments: 102,
      score: 4.6,
      is_recommend: 0,
      call_action: "Start free",
      discount: "",
      link: "https://share.channelwill.com/share?utm_source=DSM&utm_medium=affiliate&utm_campaign=share&utm_content=29TjthsO4dS",
    },
    {
      type: 2,
      name: "PageFly Page Buider",
      title: "PageFly Landing Page Builder",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/wL1OeCUcqbtRbYpKFCXBuwTbXWSQeqbgqZJABcZd.png",
      reviews: "5 stars",
      recommend:
        "Free no-code landing page builder that helps you drive more sales. Get App today.",
      comments: 7458,
      score: 4.9,
      is_recommend: 0,
      call_action: "Start FREE",
      discount: "",
      link: "http://www.pagef.ly/r2c3",
    },
    {
      type: 1,
      name: "Willdesk",
      title: "Live Chat,Help Center,FAQ Page",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/zUlMW5irJ2VWqqU3kl5HDhXvXIi2DGgJetbXqJiX.png",
      reviews: "5 stars",
      recommend: "Turn your customer service into a profit center",
      comments: 590,
      score: 5,
      is_recommend: 0,
      call_action: "Start free",
      discount: "",
      link: "https://apps.shopify.com/self-faq?from=DSM_gifts&utm_source=DSM&utm_medium=gifts&utm_campaign=referpartner",
    },
    {
      type: 1,
      name: "Parcel Panel Order Tracking",
      title: "Parcel Panel Order Tracking",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/ZkmRjzZIMQapIHgNpfcPxEKY4KzH0e99MqhZHcJK.png",
      reviews: "5 Star",
      recommend: 'Get free from answering "Where is my order?"',
      comments: 1025,
      score: 5,
      is_recommend: 0,
      call_action: "Start free",
      discount: "",
      link: "https://parcelpanel.com?ref=eW9qhcDe&DSM_Gifts",
    },
    {
      type: 1,
      name: "Loloyal Loyalty Program",
      title: "Loyalty, Rewards & Referrals",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/WphMSnOy1S7h4acc1CQuJir1hSShGCm89mYhOOdg.png",
      reviews: "5 stars over 46",
      recommend:
        "Loloyal: One click grow your sales with loyalty rewards & referrals program that actually work.",
      comments: 500,
      score: 4.9,
      is_recommend: 0,
      call_action: "Start free",
      discount: "",
      link: "https://affiliateapi.channelwill.com/share?utm_source=DSM&utm_medium=affiliate&utm_campaign=inapp&utm_content=33EPOx5R1nP",
    },
  ],
  [
    {
      type: 1,
      name: "PageStory Landing Page Builder",
      title: "PageStory",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/BgoNKUZEuuAQtsr00oC0K3NJ2eBt3CT3Caq1ifxr.jpg",
      reviews: "5 stars",
      recommend:
        "Effortless Page Builder: build stunning pages to boost your online store's conversion rate instantly",
      comments: 1,
      score: 5,
      is_recommend: 0,
      call_action: "Start Free",
      discount: "",
      link: "https://apps.shopify.com/pagestory?from=DSM_inapp&utm_source=DSM&utm_medium=integrationpage&utm_campaign=referpartner",
    },
    {
      type: 1,
      name: "TrackingMore \u2011 Order Tracking",
      title: "TrackingMore \u2011 Order Tracking",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/Acs4WXBHiWAH3Aet1Xlzp02HztQO1AxFDwLM7qR3.png",
      reviews: "5 stars",
      recommend:
        "Get tracking info from most couriers worldwide with unmatched accuracy and speed.",
      comments: 203,
      score: 4.8,
      is_recommend: 1,
      call_action: "Start free",
      discount: "",
      link: "https://apps.shopify.com/trackingmore?utm_source=dropshipman&utm_medium=page-card&utm_campaign=recommendation",
    },
    {
      type: 1,
      name: "SeoAnt",
      title: "SEOAnt \u2011 AI SEO Optimizer",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/tests/j2u8qJDkHiQ0cbI1vN051pCbdoL93dAfOs5wnUxQ.png",
      reviews: "5 star Reviews",
      recommend:
        "SEO booster with speed, Chatgpt AI writer, SEO image optimizer",
      comments: 771,
      score: 4.9,
      is_recommend: 0,
      call_action: "Start Free",
      discount: "",
      link: "https://apps.shopify.com/seo-master?utm_source=dsm&utm_medium=affiliate&utm_campaign=share&utm_content=118qPMmChck",
    },
    {
      type: 2,
      name: "Omega Two Owls Facebook Pixel",
      title: "Omega Two Owls Facebook Pixel",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/KFMc4eJP8JNVHKAZrhyOgNrZegek7Z06p80tLVUl.png",
      reviews: "678 over 4.9 stars",
      recommend:
        "Grab the last Facebook Pixel you need to scale smart with real-time insights.",
      comments: 678,
      score: 4.9,
      is_recommend: 0,
      call_action: "",
      discount: "",
      link: "https://apps.shopify.com/facebook-multi-pixels?surface_detail=dsminapp&surface_type=partner",
    },
    {
      type: 2,
      name: "Search Pie",
      title: "SearchPie: SEO Booster & Speed",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/5dpDFN9fnjyTKebztgTZ4dIBRBqqYu5dDhT4AKlq.png",
      reviews: "4.9 star over 3093+",
      recommend:
        "Add meta tags in bulk, speed up store & optimize key SEO factors. 24/7 Help Chat",
      comments: 3093,
      score: 4.9,
      is_recommend: 0,
      call_action: "Hit a try!",
      discount: "",
      link: " https://partners.secomapp.com/apps/searchpie/dsm",
    },
    {
      type: 2,
      name: "LAI Product Reviews",
      title: "LAI Product Reviews",
      logo: "https://cw-middleground.oss-us-west-1.aliyuncs.com/gUhFS2BYoW7dJ7R4Mbi3rHqj7chq1DaYd0CwY3rB.png",
      reviews: "5 stars",
      recommend:
        "AliExpress reviews, Amazon review, DSers(Oberlo), Walmart & many more!",
      comments: 1218,
      score: 4.9,
      is_recommend: 0,
      call_action: "Start Free!",
      discount: "",
      link: "https://apps.shopify.com/lai-reviews?utm_source=Dropshipman&utm_medium=in-app&utm_campaign=partnership&utm_content=app-recommendation",
    },
  ],
];
export default withRouter(AppRecommendation);
