import { EmptyState } from '@shopify/polaris';
import React from 'react';
import { Box } from '@shopify/polaris';

const Index = ({data})=> {
    const textFun = ()=>{
        let text = <p>No products match the criteria</p>
        if (data.q !== '') {
            text = <p>No results found for '<span style={{color:'rgb(48,48,48)', fontWeight:'650',maxWidth:'300px',wordWrap:'break-word',display:'inline-block'}}>{data.q}</span>'</p>
        }
        return text
    }
    return (
        <Box className="management_no_product_container">
            <EmptyState
                  heading=""
                  image="/product_management/init_sai_page.png"
                >
                 <Box className="management_no_product">
                    <Box className="management_no_product_text">{textFun()}</Box>
                 </Box>
                </EmptyState>
        </Box>
    );
}

export default Index;
