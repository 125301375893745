import {
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonPage,
  Toast,
  Button,
  BlockStack,
  Box,
  Text,
  Icon
} from "@shopify/polaris";
import {
  ExchangeIcon,
  // ThumbsUpMajor,
  TeamIcon,
} from '@shopify/polaris-icons';
import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import utils from '../../../../utils/utils'
import intl from "../../../../i18n/intl";
import AppBridge from "../../../../utils/appBridge";
const SubscriptionPaidClass = () => {
 const [loading,setLoading] = useState(false);
 const router = useHistory();
 useEffect(() => {
  setLoading(true);
  // if(AppBridge && AppBridge.exist()) {
  //   AppBridge.setTitleBar('Partnership')
  // }
  setTimeout(() => {
    setLoading(false);
  }, 1000);
}, []);
const data = [
  {
    title:intl.get('Affiliate Program'),
    dsc:intl.get('Earn 30% commission by promoting us'),
    image:'/buildShopify/Affiliate_Program.png',
    level:false,
    icon:ExchangeIcon,
    url:'/admin/partnerShip/affiliate'
  },
  // {
  //   title:'Feature Requests',
  //   dsc:'Any desired features and requirements can be provided',
  //   image:'/buildShopify/Feature_Requests.png',
  //   level:false,
  //   levelImage:'/buildShopify/level.png',
  //   icon:ThumbsUpMajor,
  //   url:'/admin/partnerShip/feature-requests'
  // },
  {
    title:intl.get('Partners'),
    dsc:intl.get('Let our partners empower you together.'),
    image:'/buildShopify/Partners.png',
    level:false,
    icon:TeamIcon,
    url:'/admin/partnerShip/partners'
  },
]
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);
  
  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;
  const initSkeletonPage = (
    <div>
      <SkeletonPage breadcrumbs={true}>
      <Layout>
          <Layout.Section>
          <BlockStack gap={400}>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          <SkeletonBodyText lines={3}/>
          </BlockStack>
        </Layout.Section>
          </Layout>
      </SkeletonPage>
    </div>
  );
  const pageContent = (
    <Page
      // breadcrumbs={[{ onAction: () => history.push("/admin/dashboard") }]}
      title={AppBridge.exist() ? "" : intl.get("Partnership")}
      fullWidth={false}
    >
      <Layout>
        <Layout.Section>
          <Box className='col_content'>
          <BlockStack gap={400}>
          {data.map((item,ind)=>
          <Card key={ind}>
            <Box className='col_box'>
          <Box className='col_box_left'>
          <Box className='col_box_left_top'>
            <span className="col_box_left_top_icon">
            <Icon
            source={item.icon}
            tone="base"
          />
          </span>
            <Text variant="headingMd" as="h6">
            {item.title}
          </Text>
          {item.level && <span className="col_box_left_top_level">
            <img src={item.levelImage} alt="level" />
            {intl.get("PRO")}
            </span>}
          </Box>
          <Text as="h6" variant="bodyMd">
        {item.dsc}
      </Text>
      <Button onClick={()=>{
utils.routeListen(item.url)
utils.routeJump1(router,item.url)
}}>{intl.get("View More")}</Button>
          </Box>
          <Box className='col_box_right'>
          <LazyLoad once>
            <img src={item.image} alt="dropshipman" />
          </LazyLoad>
          </Box>
          </Box>
        </Card>)}
          </BlockStack>
          </Box>
        </Layout.Section>
      </Layout>
    </Page>
  );
  return (
    <div>
      {loading ? initSkeletonPage : pageContent}
      {/* 提示 */}
      {toastMarkup}
    </div>
  );
};



export default SubscriptionPaidClass;
