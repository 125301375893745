import {
  IndexTable,
  Text,
  Box,
  Card,
  Button,
  Icon,
  Popover,
  ActionList,
} from "@shopify/polaris";
import { MenuHorizontalIcon } from "@shopify/polaris-icons";
import React, { useState } from "react";
import RemoveInventoryModal from "../Modal/RemoveInventoryModal"
import './index.css'
import { useHistory, withRouter } from "react-router-dom";
import utils from "../../../../../utils/utils"
import { connect, useSelector } from "react-redux";
import PremiumPlanModal from '../Modal/PremiumPlanModal';
const Index = ({listData,setPageData,resetCallback}) => {
  const router = useHistory();
 
  const ordersList = [
    {
      id: "1020",
      order: "#1020",
      date: "Jul 20 at 4:34pm",
      customer: "99",
      total: "22",
      open:false,
    },
    {
      id: "1019",
      order: "#1019",
      date: "Jul 20 at 3:46pm",
      customer: "99",
      total: "22",
      open:false,
    },
    {
      id: "1018",
      order: "#1018",
      date: "Jul 20 at 3.44pm",
      customer: "99",
      total: "22",
      open:false,
    },
  ]
  
  // action 开关
  const handleActionOpen = (id)=>{
    let list = listData.map(item=>{
      if(item.id === id){
        return {...item,open:!item.open}
      }else{
        return {...item,open:false}
      }
    })
    setPageData((prevValue)=>({...prevValue,list}))
  }

  const [removeData,setRemoveData] = useState({
    id:'',
    open:false,
    loading:false,
  })
    // 是否会员
    const [pladData,setPlanData] = useState({
        upgradeOpen:false,
    });
    const person = useSelector((state) => state.languageReducer.subScriptData);
  // action 操作
  const handleActionSelect = (id,value)=>{
    if (value === "Remove") {
      setRemoveData((prevValue)=>({
        ...prevValue,
        open:true,
        id:id,
      }))
    }
    if (value === "Purchase") {
      if (person.level !== 5) {
        setPlanData((prevValue)=>({
          ...prevValue,
          upgradeOpen:true,
      }))
      return
      }
      utils.routeListen('/admin/customService/purchase?record_id='+id)
      utils.routeJump1(router,'/admin/customService/purchase?record_id='+id)
    }
    if (value === "History") {
      utils.routeListen('/admin/customService/history_log?id='+id)
      utils.routeJump1(router,'/admin/customService/history_log?id='+id)
    }
  }

  const rowMarkup = listData.map(
    (item, index) => (
      <IndexTable.Row id={item.id} key={index} selectable={false} position={index}>
        <IndexTable.Cell>
          <Box className="packing_quantily_item packing_quantily_item_product">
         <Box className="packing_quantily_item_img">
          <img src={item.sku_image} alt="Dropshipman" />
         </Box>
         <Box className="packing_quantily_item_title" title={item.title}>{item.title}</Box>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Box  className="packing_quantily_item">{item.variant_title}</Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Box  className="packing_quantily_item">{item.cumulative_quantity}</Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Box  className="packing_quantily_item">
            <Text as="span">{item.available}</Text>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Box  className="packing_quantily_item">
            <Popover
              active={item.open}
              activator={
                <Button onClick={()=>handleActionOpen(item.id)} variant="tertiary">
                  <Icon source={MenuHorizontalIcon} tone="base" />
                </Button>
              }
              autofocusTarget="first-node"
              onClose={() => {handleActionOpen(item.id)}}
            >
              <ActionList
                actionRole="menuitem"
                items={[{ content: "Purchase",onAction:()=>{handleActionSelect(item.resource_id,"Purchase")} }, { content: "Remove",disabled:item.available !== 0, onAction:()=>{handleActionSelect(item.id,"Remove")}  }, { content: "History" ,onAction:()=>{handleActionSelect(item.id,"History")} }]}
              />
            </Popover>
          </Box>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };
  return (
    <div>
      <Box className="my_packing_list">
        <Card>
          <IndexTable
            resourceName={resourceName}
            itemCount={ordersList.length}
            selectable={false}
            headings={[
              { title: 'Packaging' },
              { title: 'SKU' },
              { title: 'Total Quantity' },
              { title: 'Available' },
              { title: 'Action' },
            ]}
          >
            {rowMarkup}
          </IndexTable>
        </Card>
      </Box>
      <RemoveInventoryModal resetCallback={resetCallback} removeData={removeData} setRemoveData={setRemoveData}/>
      <PremiumPlanModal upgradeOpen={pladData.upgradeOpen} setUpgradeOpen={()=>{
            setPlanData((prevValue)=>({
              ...prevValue,
              upgradeOpen:false,
          }))
          }}/>
    </div>
  );
};

export default connect()(withRouter(Index));
