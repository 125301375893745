import React, { Component } from 'react';

import { Layout, FooterHelp, Link } from '@shopify/polaris';
import intl from '../../i18n/intl';

class SheetExample extends Component {
    state = {};
    
  //进入页面调用接口
  componentDidMount () {}
    
  render() {

    const {helpCenterLink, helpCenterText} = this.props;
    return (
      <div>
          { helpCenterLink && helpCenterText !== '' && (<Layout>
            <Layout.Section>
                    <FooterHelp>{intl.get("Learn more about")} <Link url={helpCenterLink} external={true}>{helpCenterText}</Link> by  <Link url={'https://www.channelwill.com/'} external={true}>Channelwill</Link></FooterHelp>
            </Layout.Section>
                </Layout>)}
      </div>
    );
  }
}


export default SheetExample;