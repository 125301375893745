import React , { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './index.css';
import {  Modal} from '@shopify/polaris';
import intl from "../../i18n/intl";
const PayoneerBalancesConnect  = ({open,callPayoneerAgainClosed})=> {
   const [selectLoading, setSelectLoading] = useState(false);
   const handleOff = () =>{
    callPayoneerAgainClosed()
    setSelectLoading(false)
    }
    //payonner 授权
    const handlePayonnerAuth = async()=>{
    setSelectLoading(true)
    setTimeout(() => {
      callPayoneerAgainClosed();
      setSelectLoading(false)
    }, 1500);
  }
        return (
            <div>
            <Modal
                open={open}
                title={intl.get("Payment Confirmation")}
                primaryAction={{
                    content: intl.get('Confirm'),
                    onAction: () => {
                        handlePayonnerAuth()
                    },
                    loading: selectLoading,
                }}
                onClose={() => handleOff()}>
                        <div style={{padding:'24px',fontSize:"16px",minHeight:"100px",display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {intl.get("Please verify if the payment has been completed. If paid, please click \"Confirm\".")}
                        </div>
                </Modal>
            </div>
        )
    }

export default withRouter(PayoneerBalancesConnect);

