import React, { memo } from "react";
import { Icon, Modal, Toast } from "@shopify/polaris";
import { useState } from "react";
const RefundTipsModal = memo(({ follow, setFollow }) => {
 
  const [showToast, setShowToast] = useState(false);
  const [showToastText, setShowToastText] = useState("");
  const [showToastIserror, setShowToastIserror] = useState(false);

  //提示弹窗
  const toggleToast = (showToastText, showToastIserror = false) => {
    setShowToast(!showToast);
    setShowToastText(showToastText);
    setShowToastIserror(showToastIserror);
  };
  const toastMarkup = showToast ? (
    <Toast
      content={showToastText}
      onDismiss={() => toggleToast("")}
      error={showToastIserror}
    />
  ) : null;

  return (
    <div>
      {toastMarkup}
      <Modal
        open={follow.isOpen}
        loading={false}
        onClose={() => {
          setFollow((prevValue) => ({ ...prevValue, isOpen: false }));
        }}
        title={"Follow Social Media"}
        primaryAction={{
          content: "Close",
          onAction: () => {
            setFollow((prevValue) => ({ ...prevValue, isOpen: false }));
          },
        }}
      >
        <Modal.Section>
          <p>
          Follow Dropshipman on the social media links below, and send a screenshot to our customer service team. Upon verification, you'll earn 100 points for each follow.
          </p>
         <div className="follow_icon">
          {follow?.data?.map((item,ind)=> (item.name !== '' && item.url !== '') &&<div key={ind} className="follow_icon_item">
            <a href={item.url} target="_blank" rel="noopener noreferrer">
            <Icon
            source={item.icon}
            tone="base"
          />
          </a>
          </div>)}
         </div>
        </Modal.Section>
      </Modal>
    </div>
  );
});

export default RefundTipsModal;
