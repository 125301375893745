/**
 * 包含一些常量值的模块
 * 
 * API_URL          Api接口地址
 * SHOPIFY_APP_KEY  Shopify app key
 * APP_CRISP_TOKEN  CRISP 聊天工具标识前缀
 */
console.log(process.env.REACT_APP_ENV)

let API_URL = 'http://project15-1.cn';
let SHOPIFY_APP_KEY = 'dd0d65e6398697f5378279dd87d354c0';
let APP_CRISP_TOKEN = 'Dropshipman-Dev-';
let WEBSITE_URL = 'http://test-woo.dropshipman.com/';
let TEST_TIME = 1730390400;
if (process.env.REACT_APP_ENV === 'test') {
    API_URL = 'https://test-api.dropshipman.com';
    SHOPIFY_APP_KEY = 'dd0d65e6398697f5378279dd87d354c0';
    APP_CRISP_TOKEN = 'Dropshipman-Test-';
}

if (process.env.REACT_APP_ENV === 'pre') {
    API_URL = 'https://api.dropshipman.com';
    SHOPIFY_APP_KEY = '1252d9d2612d6b071896af7336148d30';
    APP_CRISP_TOKEN = 'Dropshipman-';
    WEBSITE_URL = 'https://www.dropshipman.com/';
    TEST_TIME = 1730390400
}

export const API_DOMAIN = API_URL // api base url
// export const API_DOMAIN = 'https://api.dropshipman.com' // api base url  正式线上
// export const API_DOMAIN = 'https://test-api.dropshipman.com' // api base url  测试服务线上

export const CRISP_TOKEN = APP_CRISP_TOKEN

export const VITE_SHOPIFY_APP_KEY = SHOPIFY_APP_KEY 
export const DSM_WEBSITE_URL = WEBSITE_URL
export const FOURTH_TEST_TIME = TEST_TIME