import {Modal} from "@shopify/polaris";
import React from "react";
import intl from "../../../../i18n/intl";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import utils from '../../../../utils/utils'
const UpgradePlanModal = ({num,importOpen,setImportOpen}) => {
    const router  = useHistory()
  return (
    <>
      <Modal
        open={importOpen}
        onClose={() => setImportOpen()}
        title={intl.get("Reach the list product limit")}
        primaryAction={Number(num) <=300 && {
          content: intl.get("Upgrade Plan"),
          onAction: () => {
            setImportOpen()
            utils.routeListen('/admin/subscribe')
            utils.routeJump1(router,'/admin/subscribe')
          },
        }}
        secondaryActions={[
          {
            content: intl.get("Cancel"),
            onAction: () => setImportOpen(),
          },
        ]}
      >
        <Modal.Section>
         {/* <p style={{margin:"10px",fontSize:'14px'}}>
         {Number(num) >300 ? intl.get("You've reached the monthly import limit. If you need any assistance, please contact our online customer service.") : `You've reached the monthly limit of importing ${num} Dropshipman products, Upgrade your plan for more imports.`} 
          </p> */}
          <p style={{margin:"10px",fontSize:'14px'}}>
            You've reached the monthly limit of importing {num} Dropshipman products, Upgrade your plan for more imports.
          </p>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default UpgradePlanModal;
