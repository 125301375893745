import React from 'react';

const Home =()=> {
        return (
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.85641 4.09899C7.50428 2.63366 5.34971 2.63366 3.99758 4.09899C2.60077 5.61273 2.60077 8.09929 3.99758 9.61303L11 17.2017L18.0024 9.61303C19.3992 8.09929 19.3992 5.61273 18.0024 4.09899C16.6502 2.63366 14.4957 2.63366 13.1435 4.09899L11.7716 5.58574C11.5729 5.80116 11.2931 5.92368 11 5.92368C10.7069 5.92368 10.4271 5.80116 10.2283 5.58574L8.85641 4.09899ZM2.45424 2.67488C4.63793 0.308366 8.21606 0.308366 10.3998 2.67488L11 3.32535L11.6002 2.67488C13.7839 0.308366 17.362 0.308366 19.5457 2.67488C21.6847 4.99297 21.6847 8.71905 19.5457 11.0371L12.2861 18.9045C11.5931 19.6555 10.4068 19.6555 9.71386 18.9045L2.45424 11.0371C0.315221 8.71905 0.315221 4.99297 2.45424 2.67488Z" fill=""/>
            </svg>
            
        )
    }
export default Home;
