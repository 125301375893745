import { Modal, TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";

const Index = ({ 
    headData,
    setHeadData,
    updateHeadData
}) => {
  
  const [value,setValue] = useState('');
  const handleClose = () => {// 关闭逻辑
    setValue('')
    setHeadData({
      open:false,
      attr:'',
      option1:'',
      option2:'',
      option3:'',
      })
  };
  const handleChange = (value) => {// 修改值
    if (value.length > 30) {
        return;
    }
    setValue(value)
  };
  const handleSubmit = () => {// 保存值
    if (value === '') {
      return;
    }
    if (headData.option1 !== '') {
      updateHeadData('option1',{option1:value})
    }
    if (headData.option2 !== '') {
      updateHeadData('option2',{option2:value})
    }
    if (headData.option3 !== '') {
      updateHeadData('option3',{option3:value})
    }
    handleClose()
  };
  useEffect(()=>{
    let isMount = false;
    if(!isMount && headData.open){// 打开弹窗默认值填充
        setValue(headData.attr)
    }
    return () => {
        isMount = true;
    }
  },[headData])
  let title = 'Change variant option1';
  if (headData.option2 !== '') {
    title = 'Change variant option2'
  }
  if (headData.option3 !== '') {
    title = 'Change variant option3'
  }
  return (
    <div>
      <Modal
        size="small"
        open={headData.open}
        onClose={handleClose}
        title={title}
        secondaryActions={{
          onAction: () => {
            handleClose();
          },
          content: "Cancel",
        }}
        primaryAction={{
          onAction: () => {handleSubmit()},
          content: "Save",
        }}
      >
        <Modal.Section>
        <TextField
        value={value}
        error={value === '' ? 'Please enter a option' : ''}
        onChange={handleChange}
        autoComplete="off"
        />
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
