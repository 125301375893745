import { Box, Button, Icon, Spinner } from '@shopify/polaris';
import React from 'react';
import {
    XIcon
  } from '@shopify/polaris-icons';
import './index.css';
const Index = ({width,loading,buttonLoading,title,content,open,onClose,cancel,disabled,save,button})=> {
    
    return (<Box className={open ? 'common_modal' : 'common_modal_show'}>
            <Box className={open ? "common_modal_mask custom-modal-enter" :"common_modal_mask custom-modal-exit"}>
                <Box className="common_modal_box" style={{width:width}}>
                    <Box className="common_modal_close"><span onClick={()=>onClose()}><Icon
                    source={XIcon}
                    tone="base"
                    /></span>
                    </Box>
                    {title && <Box className="common_modal_title">
                        <p>{title}</p>
                    </Box>}
                    <Box className="common_modal_content">
                        {loading ? <Box className="common_modal_content_loading">
                        <Spinner accessibilityLabel="Spinner example" size="large" />
                        </Box> : <Box className="common_modal_content_box">
                        {content}
                        </Box>}
                    </Box>
                    {button && <Box className="common_modal_button">
                        <Box className="common_modal_button_box">
                            <Button onClick={()=>onClose()}>{cancel}</Button>
                            <Button disabled={disabled}  loading={buttonLoading} variant='primary' onClick={()=>onClose()}>{save}</Button>
                        </Box>
                    </Box>}
                </Box>
            </Box>
        </Box>
    );
}

export default Index;
