import { Button, Modal } from '@shopify/polaris';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import utils from '../../../../utils/utils'
import { reqGetBlackFridayRafflePlan } from '../../../../api';
const BlackRaffleRuleModal = ({raffleResult,handleOff})=> {
    const router = useHistory()
    // A:2美金,B:5美金,C:10美金,D:PRO,E:PLUS,F:鼠标,G:背包,H:无
    const handlePlan = async(val)=>{
        let res = await reqGetBlackFridayRafflePlan({lottery_id:val})
        if (res.code ==='200') {
            window.top.location.href = res.data.url;
        }
        
    }
    const handleReedem = (id)=>{
        utils.routeListen('/admin/black_points_purchase?record_id='+id)
        utils.routeJump1(router,'/admin/black_points_purchase?record_id='+id)
        
    }
    return (
        <div>
        <Modal
            size='middle'
            open={raffleResult.open}
            onClose={() => handleOff()}>
                <div className='raffle_rules_modal' style={{margin:'24px'}}>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        <img src="/emojis_hua.png" alt="Dropshiman" />
                        <span style={{fontWeight:'700',fontSize:'20px',padding:'10px 0'}}>Congratulations!</span>
                    </div>
                    {(raffleResult.level === 'A' || raffleResult.level === 'B' || raffleResult.level === 'C') && <div style={{textAlign:'center'}}>
                    <p>Congratulations on winning a {raffleResult.level === 'A' ? '$2' : raffleResult.level === 'B' ? '$5' : raffleResult.level === 'C' ? '$10' : ''} coupon! It can be used for order payments and is valid for 2 months. You can check it in your Coupon Center.</p>
                    <div className='raffle_coupon_container'>
                    <img src="/blackFriday/black5_coupon_big.png" alt="Dropshiman" />
                    <span>{raffleResult.level === 'A' ? '$2' : raffleResult.level === 'B' ? '$5' : raffleResult.level === 'C' ? '$10' : ''}</span>
                    </div>
                    </div>}
                    {(raffleResult.level === 'A' || raffleResult.level === 'B' || raffleResult.level === 'C') && <div className='raffle_modal_footer'>
                        <Button onClick={() => handleOff()}>Close</Button>
                        <Button variant='primary' onClick={()=>{
                            utils.routeListen('/admin/coupon')
                            utils.routeJump1(router,'/admin/coupon')
                        }}>View in Coupon  Center</Button>
                    </div>}
                    {(raffleResult.level === 'D' || raffleResult.level === 'E') && <div style={{textAlign:'center'}}>
                    <p>Congratulations! You’ve won a 1-month free {raffleResult.level === 'D' ? 'Pro' : 'Plus'} plan valued at {raffleResult.level === 'D' ? '$29.9' : '$49.9'} USD. Please click the activate button to activate it.</p>
                    <div className='raffle_plan_container'>
                    <img src="/blackFriday/black5_light_big.png" alt="Dropshiman" />
                    <span>{raffleResult.level === 'D' ? 'Pro' : 'Plus'}</span>
                    </div>
                    </div>}
                    {(raffleResult.level === 'D' || raffleResult.level === 'E') && <div className='raffle_modal_footer'>
                        <Button onClick={() => handleOff()}>Close</Button>
                        <Button variant='primary' onClick={()=>{handlePlan(raffleResult.record_id)}}>Activate</Button>
                    </div>}
                    {(raffleResult.level === 'F' || raffleResult.level === 'G') && <div style={{textAlign:'center'}}>
                    <p>Congratulations! You have won a  {raffleResult.level === 'F' ?  'Snail Wired Mouse worth $32.6 USD': 'Laptop Backpack worth $28.2 USD' }. Click the claim button to receive it, fill in your shipping address, and pay for the shipping costs to complete the claim.</p>
                    <div className='raffle_plan_container'>
                    <img src={(raffleResult.level === 'F' ? "/blackFriday/black5_mouse.png" : "/blackFriday/black5_bag.png")} alt="Dropshiman" />
                    </div>
                    </div>}
                    {(raffleResult.level === 'F' || raffleResult.level === 'G') && <div className='raffle_modal_footer'>
                        <Button onClick={() => handleOff()}>Close</Button>
                        <Button variant='primary' onClick={()=>{handleReedem(raffleResult.record_id)}}>Claim</Button>
                    </div>}
                </div>
            </Modal>
        </div>
    );
}

export default BlackRaffleRuleModal;
