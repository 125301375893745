import {Modal} from "@shopify/polaris";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import utils from '../../../../utils/utils'
import intl from "../../../../i18n/intl";
const UpgradePlanModal = ({upgradeOpen,setUpgradeOpen}) => {
    const router  = useHistory()

  return (
    <div className="upgrade_new_feature">
      <Modal
        open={upgradeOpen}
        onClose={() => setUpgradeOpen()}
        title={intl.get('Upgrade  your plan')}
        primaryAction={{
          content: intl.get("Upgrade Plan"),
          onAction: () => {
            setUpgradeOpen()
            utils.routeListen('/admin/subscribe')
            utils.routeJump1(router,'/admin/subscribe')
          },
        }}
        secondaryActions={[
          {
            content: intl.get("Cancel"),
            onAction: () => setUpgradeOpen(),
          },
        ]}
      >
        <Modal.Section>
        <p style={{margin:"10px",fontSize:'14px'}}>{intl.get('This feature requires upgrading to a more advanced plan to access, please upgrade your plan.')}</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default UpgradePlanModal;
