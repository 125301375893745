import React from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// 组件文档：https://github.com/leandrowd/react-responsive-carousel

import './index.css';

export default function SlideShow(props) {

  let imagesArray = props.images;

  // 全局配置
  const globalConfig = {
    autoPlay: true,
    interval: 5000,
    showArrows: false,
    showIndicators: (imagesArray.length > 1) ? true : false,
    showStatus: false,
    infiniteLoop: true,
    showThumbs: false
  }

  let slideItems = '';
  if (imagesArray.length > 0) {
    slideItems = imagesArray.map((item, key)=>{
      return (
        <div className="dropshipman-carousel-slide" key={key}>
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            <img src={item.src} alt="" />
            {/* <p className="legend">Legend 1</p> */}
          </a>
        </div>
      )
    });
  }

  const SlideContent = slideItems ? (
    <div className='dropshipman-carousel-list'>
      <Carousel {...globalConfig}>
        {slideItems}
      </Carousel>
    </div>
  ) : (
    <div></div>
  )

  return (
    SlideContent
  );
}