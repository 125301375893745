import React from 'react';
import './index.css';
 
const DraggableScroll = ({ children }) => {
  
  return (
    <div
      className="draggable-scroll"
    >
      <div className="scroll-content">
        {children}
      </div>
    </div>
  );
};
 
export default DraggableScroll;