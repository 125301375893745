import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./index.css";
import { on } from '@all-in-one/cross-domain'
import { store } from "../../../store";
class Index extends Component {
  
  state = {
    shopify_domain: '',
    app: '518111167166087168', // 中台提供
    loading: true,
    searchPar: '',          // 参数
    dVersion: 'local',
}

UNSAFE_componentWillMount() {
  const locals = this.props.location;
  let searchPar = locals.search !== '' ? `&${locals.search.replace('?', '')}` : '';
  this.setState({
      searchPar,
      dVersion: process.env.REACT_APP_ENV
  })
}

  //进入页面调用接口
  componentDidMount() {
    try {
      this.setDomain();
      store.subscribe(() => {
          this.setDomain();
      })
      on("all-in-one", this.openWillDesk);
  } catch (error) {
      
  }
  }
  setDomain = () => {
    let _store = store.getState().languageReducer.subScriptData;
    this.setState({
        shopify_domain: _store.domain,
        email: _store.email,
    })
}
  // 调起客服
  openWillDesk = (msg) => {
    try {
        window.Willdesk.show();
        if (msg) {
            window.Willdesk.chat(msg);
        }
    } catch (e) { }
}

// 监听加载
onloadIframe = () => {
  this.setState({
      loading: false,
  })
}
  render() {
    const {
      shopify_domain,
      app,
      email,
      searchPar,
      dVersion,

  } = this.state;

   // 设置iframe
   const edEmail = btoa(email).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
   const urlDomain = dVersion === 'pre' ? 'https://aio-apps.channelwill.com' : 'https://aio-apps-test.channelwill.com';
   const src = `${urlDomain}/?shopify_domain=${shopify_domain}&app=${app}&email=${edEmail}&has_combo=true${searchPar}`;

    const pageContent = (
        <iframe id="all-in-one" className="h-screen w-full border-none" title="recommended-apps" src={src} onLoad={this.onloadIframe}></iframe>
    );

    return (
      <div style={{ background: 'rgb(241, 241, 241)' }}>
        {/* 页面内容 */}
        {pageContent}
      </div>
    );
  }
}

export default withRouter(Index);
