import React, { Component } from 'react';

import { AppProvider,   Layout, Page, Frame, Text } from "@shopify/polaris";

import './index.css';
import translations from '@shopify/polaris/locales/en.json';
import intl from '../../../i18n/intl'

class PrivacyPolicy extends Component {

  state = {};

  // 进入组件调用
  componentDidMount() {
    // 设置页面标题
    document.title = 'DropshipMan Privacy Policy';
  }

  render() {

    return (
      <div className="privacypolicypage">
      <AppProvider i18n={translations}>
        <Frame >
          <Page
            // breadcrumbs={[{content: 'Dashboard', url: '/admin/dashboard'}]}
            title={intl.get("Dropshipman Privacy Policy")}
          >
            <div className="space"></div>
            <Layout>
              <Layout.Section>
                <div className="privacypolicy">
                 
                  <p>
                  {intl.get("Dropshipman 'the App' provides Dropshipping Services (Dropshipping manage products and orders) 'the Service' to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected and used when you install or use the App in connection with your Shopify-supported store.")} 
                </p>
                 
                <div className="space"></div>
                 
                 <Text variant="headingMd" as="h2">{intl.get("Merchants Information the App Collects")}</Text>
                  <p>
                  {intl.get("When you install the App, we are automatically able to access the following personal information from your Shopify account once you have installed the App: Information about you and others who may access the App on behalf of your store, such as your name, email address, phone number. Additionally, we will collect Information, such as Products，Orders and Customers.")}
                  </p>
                 

                <div className="space"></div>
                 
                 <Text variant="headingMd" as="h2">{intl.get("How Do We Use Merchants Information?")}</Text>
                  <p>
                  {intl.get("We use the personal information we collect from you in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information relating to our services. We use the product and other information to provide merchants the services state above.")} 
                   </p>
                 


                <div className="space"></div>
                 
                 <Text variant="headingMd" as="h2">{intl.get("For Merchants in Europe")}</Text>
                  <p>
                  {intl.get("Your Rights If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.")}
                  </p>
                  <p>
                  {intl.get("Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you, or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.")}
                  </p>
                  <p>
                  {intl.get("Data Retention When you install the App, we will maintain your Information for our records unless and until you uninstall the App, a request will be sent to the App to delete this data.")}
                  </p>
                  <p>
                  {intl.get("Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.")}
                  </p>
                  <p>
                  {intl.get("Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@dropshipman.com")}
                  </p>
                 

                </div>
              </Layout.Section>
            </Layout>
      
            <div className="bigspace"></div>

          </Page>
        </Frame>
      </AppProvider>
      </div>
    );
  }
}

export default PrivacyPolicy;
