import React,{memo} from 'react'
import { Modal,    } from '@shopify/polaris'
import intl from "../../../../../../i18n/intl";
const CustomerOrderNotesModal = memo(({open, setCustomerOrderNotesModal,note,setOrderNote}) => {
  return (
    <div style={{margin:"0 0"}}>
      <Modal
        open={open}
        onClose={()=>{
          setCustomerOrderNotesModal()
          setOrderNote('')}
        }
        title={intl.get("Customer Order Notes")}
        primaryAction={{
          content: intl.get('Close'),
          onAction: setCustomerOrderNotesModal,
        }}
      >
        <Modal.Section>
           
            <p>
            {note}
            </p>
           
        </Modal.Section>
      </Modal>
    </div>
  )
})

export default CustomerOrderNotesModal