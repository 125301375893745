import { Box, Button, Card, InlineStack, Page, Toast,EmptyState } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { LinkIcon,EditIcon,PlusCircleIcon } from "@shopify/polaris-icons";
import PictureBox from "./component/PictureBox";
import VariantBox from "./component/VariantBox";
import DescriptReview from "./component/DescriptReview";
import SimilarProductBox from "./component/SimilarProductBox";
import EnoughQuotaModal from "../SourcingRequest/component/EnoughQuotaModal";
import EnoughPointsModal from "../SourcingRequest/component/EnoughPointsModal";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import intl from "../../../../i18n/intl";
import DetailSkeleton from "./component/DetailSkeleton";
import {
  reqGetProductManagementProductsQuestionnaire,
  reqGetProductSelectionDetail,
  reqGetProductSelectionRelatedDetail,
  reqGetProductSelectionReviewsDetail,
  reqGetSubcribeData,
  reqGetUserPoints,
  reqGetUserSubcribe,
  reqPostCartProductAdd,
  reqPostFindProductAdd,
  reqPostSubcribeData,
} from "../../../../api";
import PersonalAgentModal from "../../SubscriptionPaid/components/PersonalAgentModal";
import { store } from "../../../../store";
import { changeBannerData, setHasReturnedOnce, setInitialPath } from "../../../../slice";
import utils from "../../../../utils/utils";
import { useSelector } from "react-redux";
const DetailsClass = ({ callBackFun }) => {
  const [initPage, setInitPage] = useState(true); // 初始化页面
  const [detailData, setDetailData] = useState({
    skuImage: "",
    data: {},
  });
  const [filpLoading, setFilpLoading] = useState(true);
  const router = useHistory();
  const [detailParams, setDetailParams] = useState({
    prev_id: "",
    next_id: "",
  }); // 详情页参数
  const [detailItemParams, setDetailItemParams] = useState({// 推荐列表子项所需要参数
    prev_id: "",
    next_id: "",
  }); // 详情页参数
  const [relateId, setRelateId] = useState(0);

  const [detailVariant, setDetailVariant] = useState({
      skuData: [], //sku数据
      variantData: [], //变体数据
      addCartItem: {
        id: 0,
        vid: 0,
      }, //购物车按钮
      addToCartLoading: false,
      disableAddToCart: true, // 购物车按钮是否禁用
      defaultPrice: "", // 默认价格
      defaultImage: "", // 默认图片
      skuImage: "", // sku图片
      indexPrice: 0, // 当前价格
      currentInventory: "",
      currentProfit: "",
    });



  // 获取详情数据
  const getDetailData = async (data) => {
    setFilpLoading(false);
    try {
      if (data) {
        let params = {
          ...detailParams,
          ...data,
          record_id: data.record_id,
          tag: (data.winning === 1 || detailParams.winning === 1) ? "winning" : "home",
        };
        
        let res = await reqGetProductSelectionDetail(params);
        if (res.code === "200") {
          let pageData = res.data;
          setDetailVariant({
            skuData: pageData?.options, //sku数据
            variantData: pageData?.variants, //变体数据
            addCartItem: {
              id: 0,
              vid: 0,
            }, //购物车按钮
            addToCartLoading: false,
            disableAddToCart: true, // 购物车按钮是否禁用
            defaultPrice: pageData?.sell_price, // 默认价格
            defaultImage: pageData?.image_list[0], // 默认图片
            skuImage: pageData?.image_list[0], // sku图片
            indexPrice: pageData?.sell_price, // 当前价格
            currentInventory: pageData?.inventory,
            currentProfit: pageData?.estimated_profit, // 当前利润
          });
          setDetailData((prevValue) => ({
            ...prevValue,
            data: res.data,
            skuImage:res.data.image_url[0],
            skuImageList: res.data.image_url,
          }));
          getDetailReviewData({page:1,size:10, pid: res.data.pid });
          setRelateId(res.data.category_id);
          if (relateId !== res.data.category_id) {
            
            setDetailItemParams((prevValue) => ({
              ...prevValue,
              category_id: res.data.category_id,
              record_id: data.record_id,
              page: 1,
              size: 10,
              winning: (data.winning === 1 || detailParams.winning === 1 || res.data.winning_tag) ? 1 : 0,
            }))
            getDetailRelatedData({
              page: 1,
              size: 10,
              category_id: res.data.category_id,
              record_id: data.record_id,
              winning: (data.winning === 1 || detailParams.winning === 1 || res.data.winning_tag) ? 1 : 0,
            });
          }
        }
        setInitPage(false)
        setFilpLoading(true);
      }else {
        setInitPage(false)
      }
    } catch (error) {
      setFilpLoading(true);
    }
  };

  // 获取评论数据
  const [reviewData, setReviewData] = useState({
    count: 0,
    list: [],
  });
  const [reviewParams, setReviewParams] = useState({
    page: 1,
    size: 10,
    pid: "",
  });
  const [more, setMore] = useState(true); // 是否加载更多数据
  const getDetailReviewData = async (data) => {
    try {
      if (data) {
        let params = reviewParams;
        if (data.pid) {
          params.pid = data.pid;
          params.page = 1;
          params.size = 10;
        }
        setMore(true)
        setReviewParams(params);
        let res = await reqGetProductSelectionReviewsDetail(params);
        if (res.code === "200") {
          
          setReviewData((prev) => ({
            ...prev,
            list: res.data.list,
            count: res.data.count,
          }));
          if (res.data.list.length === 0 || res.data.list.length  === res.data.count) {
            setMore(false);
          }
        }
      }
    } catch (error) {}
  };

  const [relateData, setRelateData] = useState({
    list: [],
  });
  const [relateParams, setRelateParams] = useState({
    page: 1,
    size: 10,
    category_id: "",
  });
  // 获取相关推荐产品数据

  const getDetailRelatedData = async (data) => {
    try {
      if (data) {
        let params = { ...relateParams, ...data };
        if (data.category_id) {
          params.category_id = data.category_id;
        }
        setRelateParams(params);
        let res = await reqGetProductSelectionRelatedDetail(params);
        if (res.code === "200") {
          setRelateData({
            list: res.data.list,
          });
        }
      }
    } catch (error) {}
  };
// 用户满意度调查
    const getSurveyData = async () => {
      try {
        let res = await reqGetProductManagementProductsQuestionnaire({event:'product_selection'});
        if (res.code === "200") {
          if (!res.data) {
            window.onSurveyLoad(plan_level)
          }
        }
      } catch (error) {}
    };

  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      const { search } = router.location;
      let urlParams = new URLSearchParams(search);
      let filter = urlParams.get("filter");
      let tag = urlParams.get("tag");
      let source = urlParams.get("source");
      let management = urlParams.get("management");
      let favorite = urlParams.get("favorite");
      let category = urlParams.get("category");
      let state = {
        record_id:filter ? filter : '',
        tag: tag ? tag : 'home',
        category_id: category ? category : '',
        winning:tag ? tag === 'winning' ? 1 : 0 : 0,
        management: management ? management : '',
        source: source ? source : '',
        favorite: favorite ? favorite : 0
      }
      getSurveyData();
      getDetailData(state);
      setDetailParams(state);
      setDetailItemParams(state);
      getUserPoints();
      getUserPlan();
      getSubscribeList();
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line
  }, []);

  //   复制链接
  const copyClick = (text) => {
    let innerText = text;
    var myTagContent = document.createElement("input");
    document.body.appendChild(myTagContent);
    myTagContent.value = innerText;
    myTagContent.select();
    myTagContent.setAttribute("id", Math.random());
    document.execCommand("copy");
    myTagContent.remove();
    toastFun("Copy successfully", false);
  };

  // 会员限制
  const [planData, setPlanData] = useState({
    sourcingLimit: 0, // 当前sourcing次数
    sourcingDay: 3, // sourcing限制次数
    productLimit: 0, // 当前产品次数
    productDay: 10, // 日产品次数
  });
  // 积分剩余
  const [userPoints, setUserPoints] = useState({
    userPointsData: {
      available_points: 0,
      total_points: 0,
    },
    userPointsRedeem: {
      open: false,
      id: 0,
      isDetail: false,
      is_new: 0,
      isPoints: false,
    },
  });

  // 获取积分
  const getUserPoints = async () => {
    try {
      let res = await reqGetUserPoints();
      if (res.code === "200") {
        setUserPoints((prevValue) => ({
          ...prevValue,
          userPointsData: res.data,
        }));
      }
    } catch (error) {}
  };

  //   获取套餐功能次数
  const getUserPlan = async () => {
    let response = await reqGetUserSubcribe();
    if (response.code === "200") {
      setPlanData((prevValue) => ({
        ...prevValue,
        sourcingLimit:
          Object.values(response?.data?.limit).length > 0
            ? response?.data?.limit?.sourcing +
              response?.data?.limit?.sourcing_job
            : 0,
        sourcingDay: response?.data?.day?.sourcing_num,
        productLimit: Number(response?.data?.limit?.dsm),
        productDay: Number(response?.data?.day?.dsm_product_num),
      }));
    }
  };

  // 获取当前时间
  const getCurrentTime = () => {
    const timestamp = Date.parse(new Date()) / 1000;
    return timestamp;
  };
  const getTimeData = (userData, data) => {
    // 1. 全员套餐存在 闹钟无
    // 2. 全员套餐存在 闹钟套餐存在
    let flag = false;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        // 无活动
        if (
          getCurrentTime() > data[i].discount_time &&
          userData.event_active_time === ""
        ) {
          flag = false;
        }
        // 无闹钟
        if (
          getCurrentTime() < data[i].discount_time &&
          userData.event_active_time === ""
        ) {
          flag = false;
        }

        // 同时存在
        if (
          getCurrentTime() < data[i].discount_time &&
          getCurrentTime() < userData.event_active_time &&
          userData.event_active_time !== ""
        ) {
          if (data[i].discount >= userData.event_discounts) {
            flag = true;
          } else {
            flag = false;
          }
        }
        // 闹钟套餐
        if (
          getCurrentTime() > data[i].discount_time &&
          getCurrentTime() < userData.event_active_time &&
          userData.event_active_time !== ""
        ) {
          flag = true;
        }
        // 黑五活动
        // if (getCurrentTime() < data[i].discount_time || getCurrentTime() < userData.event_active_time) {
        //   if (data[i].discount || userData.event_discounts) {
        //     black = true
        //   }
        // }
      }
    }
    return flag;
  };
  // 高级套餐
  const [premiumData, setPremiumData] = useState({
    open: false,
    agent_list: [],
  });
  const handlePremiumOff = () => {
    setPremiumData((prevValue) => ({
      ...prevValue,
      open: false,
    }));
  };
  // 升级套餐
  const handleMageUpgrade = async (
    id,
    val,
    email = "",
    social = "",
    am_id = ""
  ) => {
    try {
      let param = {
        package_id: id,
        email: email,
        social: social,
        am_id: am_id,
      };
      if (val && val !== "") {
        param.remark = val;
      }
      let res = await reqPostSubcribeData(param);
      if (res.code === "200") {
        if (res?.data?.id !== "") {
          if (id !== 0) {
            window.top.location.href = res.data.url;
            toastFun(res.msg);
          }
        }
      } else {
        toastFun(intl.get("Something wrong"));
      }
    } catch (error) {}
  };
  const [subList, setSubList] = useState(defalutData.subList); //套餐列表

  const getSubscribeList = async () => {
    let res = await reqGetSubcribeData();
    if (res.code === "200") {
      setPremiumData((prevValue) => ({
        ...prevValue,
        agent_list: res.data.agent_list,
      }));
      let currentSub = subList.map((it, k) => {
        let discount = it;
        for (let j = 0; j < res.data.package.length; j++) {
          if (k === j) {
            discount = { ...discount, ...res.data.package[j] };
          }
        }
        getTimeData(res.data.user_discounts, res.data.package);
        return {
          ...discount,
          user_discounts: res.data.user_discounts,
          level: res.data.level,
          pay_level: res.data.bill_level,
          first_purchase: res.data.first_purchase,
          is_zt: res.data.is_zt,
        };
      });

      switch (res.data.bill_level) {
        case 0:
          let list0 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
            
            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (res.data.trial_days > 0 && ind !== 3) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list0);
          break;
        case 1:
          let list1 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
           
            let discount = item.discount;
            let discount_time = item.discount_time;
            

            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                ((ind >= 2 && ind < 3) || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (
                res.data.trial_days <= 0 &&
                ((ind >= 2 && ind < 3) || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list1);
          break;
        case 2:
          let list3 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
           
            if (res.data.bill_level === ind) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (res.data.trial_days > 0 && (ind === 1 || ind === 4)) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (res.data.trial_days <= 0 && ind === 4) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list3);
          break;
        case 4:
          let list4 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
            
            if (ind === 3) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                (ind === 1 || ind === 2 || ind === 4)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else if (res.data.trial_days <= 0 && ind >= 1 && ind !== 3) {
                data = {
                  ...data,
                  button: "Upgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list4);
          break;
        case 5:
          let list5 = currentSub.map((item, ind) => {
            let user_discounts = item.user_discounts;
            
            let discount = item.discount;
            let discount_time = item.discount_time;
            

            if (ind === 4) {
              return {
                ...item,
                button: "Current Plan",
                disabled: true,
                loading: false,
                user_discounts,
                discount,
                discount_time,
              };
            } else {
              let data = item;
              if (
                res.data.trial_days > 0 &&
                (ind === 1 || ind === 2 || ind === 3)
              ) {
                data = {
                  ...data,
                  button: "7-day free trial",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              } else {
                data = {
                  ...data,
                  button: "Downgrade",
                  disabled: false,
                  loading: false,
                  user_discounts,
                  discount,
                  discount_time,
                };
              }
              return data;
            }
          });
          setSubList(list5);
          break;
        default:
          break;
      }
      //   setLoading(false);
    } else {
      //   setLoading(false);
    }
  };

  // 次数不足
  const [sourcingLimitModal, setSourcingLimitModal] = useState(false);
  // 达到上限
  const [enoughPoint, setEnoughPoint] = useState(false);
  const handleSubmit = (val) => {
    if (val) {
      if (val === 5) {
        setPremiumData((prevValue) => ({
          ...prevValue,
          open: true,
        }));
        setSourcingLimitModal(false);
      }
      if (val === 6) {
        handleProductImport(
          userPoints.userPointsRedeem.record_id,
          userPoints.userPointsRedeem.pid,
          userPoints.userPointsRedeem.tag,
          userPoints.userPointsRedeem.keys
        );
        setSourcingLimitModal(false);
      }
      if (val < 5) {
        handleMageUpgrade(val);
      }
    }
  };
  // 产品导入 使用积分
  const handleProductImport = async (productId, cj_pid, tag, keys) => {
    try {
      let params = {
        record_id: productId,
        pid: cj_pid,
        tag,
        is_point: 1,
      };
      let res = await reqPostFindProductAdd(params);
      if (res.code === "200") {
        getSurveyData();
        setDetailData((prevValue)=>({
          ...prevValue,
          data:{...prevValue.data,dsm_pid:res.data.id}
        }))
        setPlanData((prevValue)=>({
          ...prevValue,
          productLimit: prevValue.productLimit + 1,
        }))
        setUserPoints((prevValue)=>({
          ...prevValue,
          userPointsData:{
            ...prevValue.userPointsData,
            available_points:Number(prevValue.userPointsData.available_points) - 150
          }
        }))
        
        toastFun("Added successfully");
      }
    } catch (error) {}
  };

  const onPrevious = (value) => {
    // 上一个商品
    setDetailParams((prevValue) => ({
      ...prevValue,
      record_id: value,
      prev_id: "",
      next_id: detailParams.record_id,
    }));
    getDetailData({
      record_id: value,
      prev_id: "",
      next_id: detailParams.record_id,
    });
  };
  const onNext = (value) => {
    // 下一个商品
    setDetailParams((prevValue) => ({
      ...prevValue,
      record_id: value,
      prev_id: detailParams.record_id,
      next_id: "",
    }));
    getDetailData({
      record_id: value,
      prev_id: detailParams.record_id,
      next_id: "",
    });
  };
   const plan_level = useSelector(
      (state) => state.languageReducer.subScriptData
    );
  const handleEditProduct = (dsm_pid) => {
    utils.routeListen("/admin/product_management?selectedView=0");
    utils.routeJump1(router, "/admin/product_management?selectedView=0&ids=" + dsm_pid);
  };
  const [addLoaing, setAddLoading] = useState(false); 
    // 产品导入
    const handleProductImportBottom = async (productId, cj_pid, isPoint) => {
      if (plan_level.bill_level === 5 || plan_level.level === 5) {
        if (userPoints.userPointsData.available_points < 150 && planData.productLimit >= planData.productDay) {
          setEnoughPoint(true);
          return;
        }
      }
      if (planData.productLimit >= planData.productDay) {
        setUserPoints((prevValue) => ({
          ...prevValue,
          userPointsRedeem: {
            ...prevValue.userPointsRedeem,
            record_id: productId,
            pid: cj_pid,
            tag: detailParams.winning === 0 ? "home" : "winning",
          },
        }));
        setSourcingLimitModal(true);
        return;
      }
      try {
        setAddLoading(true);
        let params = {
          record_id: productId,
          pid: cj_pid,
          tag: detailParams.winning === 0 ? "home" : "winning",
          is_point: isPoint,
        };
        let res = await reqPostFindProductAdd(params);
        if (res.code === "200") {
          getSurveyData();
          toastFun('Added successfully');
          setDetailData((prevValue)=>({
            ...prevValue,
            data:{...prevValue.data,dsm_pid:res.data.id}
          }))
          setPlanData((prevValue)=>({
            ...prevValue,
            productLimit: prevValue.productLimit + 1,
          }))
        }
        // setPlanData((prev) => ({
        //   ...prev,
        //   productLimit: planData.productLimit + 1,
        // }));
        
        setTimeout(() => {
          setAddLoading(false);
        }, 1500);
      } catch (error) {}
    };
  const handleSelect = (val, ind) => {
    let data = detailVariant.skuData;
    let variant = detailVariant.variantData;
    let isDisable = true;
    // 匹配购物车
    let setAddItem = detailVariant.addCartItem;
    let variantIndex = "";
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].value.length > 0) {
          if (data[i].selected === val && i === ind) {
            data[i].selected = "";
            setDetailVariant((prevValue) => ({
              ...prevValue,
              skuData: data,
              indexPrice: detailVariant.defaultPrice,
              disableAddToCart: true,
              skuImage: detailVariant.defaultImage,
              currentInventory: detailData.data?.inventory,
            }));
            setDetailData((prevValue) => ({
              ...prevValue,
              skuImage: detailVariant.defaultImage,
            }));

            // https://cc-west-usa.oss-accelerate.aliyuncs.com/214ffd62-070f-4218-8203-83da7b710b02.jpg?x-oss-process=image/resize,m_pad,w_400,h_400
            return;
          }
          for (let j = 0; j < data[i].value.length; j++) {
            if (data[i].value.includes(val)) {
              if (val && i === ind) {
                data[i].selected = val;
                setDetailVariant((prevValue) => ({
                  ...prevValue,
                  disableAddToCart: true,
                }));
              }
            }
          }
        }
        //是否全选了
        if (variant[data[0].selected]?.id) {
          variantIndex = data[0]?.selected + "-";
        } else {
          variantIndex += data[i]?.selected + "-";
        }
      }
    }
    let varr = variant[variantIndex.slice(0, variantIndex.length - 1)];
    if (varr) {
      isDisable = false;
      setAddItem.record_id =  detailData.data?.record_id;

      setAddItem.dsm_vid = varr?.id;
      setDetailData((prevValue) => ({
        ...prevValue,
        skuImage:
          varr?.sku_image !== "" && varr?.sku_image !== null
            ? varr?.sku_image
            : detailVariant.defaultImage,
      }));
      setDetailVariant((prevValue) => ({
        ...prevValue,
        skuImage:
          varr?.sku_image !== "" && varr?.sku_image !== null
            ? varr?.sku_image
            : detailVariant.defaultImage,
        indexPrice: varr?.price,
        disableAddToCart: isDisable,
        currentInventory: varr?.sku_inventory,
      }));
    }
    setDetailVariant((prevValue) => ({
      ...prevValue,
      skuData: data,
      addCartItem: setAddItem,
    }));
  };

  // 添加产品到购物车
  const handleAddToShoppingCart = async () => {
    if (!detailVariant.disableAddToCart) {
      let data = detailVariant.addCartItem;

      if (!data.dsm_vid || !data.record_id) {
        toastFun("Something wrong");
      }
      setDetailVariant((prevValue) => ({
        ...prevValue,
        addToCartLoading: true,
      }));
      const params = {
        record_id:  detailData.data?.record_id,
        dsm_vid: data.dsm_vid,
        // cart: JSON.stringify(data),
        // type: "custom",
      };

      const response = await reqPostCartProductAdd(params);
      setDetailVariant((prevValue) => ({
        ...prevValue,
        addToCartLoading: false,
      }));

      if (response.code === "200") {
        toastFun("Added successfully");
        // 回调更新悬浮购物车按钮
        if (response.data !== 0) {
          callBackFun({ number: response.data.number });
        }
      } else {
        // this.toggleToast(response.msg);
        store.dispatch(
          changeBannerData({
            bannerData: {
              show: true,
              desc: response.msg,
            },
          })
        );
      }
    }
  };

  const initialPath = useSelector((state) => state.languageReducer.initialPath);
  const hasReturnedOnce = useSelector((state) => state.languageReducer.hasReturnedOnce);

  useEffect(() => {
    // 如果没有记录初始路径，存储来源路径
    if (!initialPath) {
      store.dispatch(setInitialPath("/admin/product_selection?select_tab=0"));
    }
  }, [initialPath]);
  
  const handleGoBack = () => {
    if (!hasReturnedOnce) {
      // 如果尚未返回过，则返回上一页
     store.dispatch(setHasReturnedOnce(true));
     router.go(-1)
    } else {
      // 已返回过一次，直接跳转到初始路径
      store.dispatch(setHasReturnedOnce(false));
      utils.routeListen(initialPath)
      utils.routeJump1(router,initialPath)
    }
  };
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  const toggleToast = useCallback(() => setToast((toast) => !toast), []);
  
  
  const toastMarkup = toast ? (
    <Toast content={toastMsg} onDismiss={toggleToast} error={toastError} />
  ) : null;

  const toastFun = (msg, error) => {
    setToastError(error);
    setToastMsg(msg);
    setToast(false);
    toggleToast();
  };
  
  const emptyPage = (
    <div style={{ minHeight: "600px" }}>
    <EmptyState image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
      <p>{"Get no detail"}</p>
    </EmptyState>
  </div>
  )
  const pageContent = (
    <Box style={{}}>
      <Page
        title="Product Details"
        fullWidth={true}
        backAction={{
          content: "Product detail",
          onAction: () => {
            handleGoBack()
            
          },
        }}
        actionGroups={(detailParams?.source || Object.values(detailData.data).length === 0) ? []: [
          {
            title: "More actions",
            actions: [
              {
                content: "Copy link",
                icon: LinkIcon,
                accessibilityLabel: "Individual action label",
                onAction: () => {
                  copyClick(detailData?.data.share_url);
                },
              },
            ],
          },
        ]}
        pagination={(detailParams?.management || Object.values(detailData.data).length === 0) ? "" : !detailParams?.source && {
          hasPrevious: filpLoading && detailData?.data.prev_id,
          previousTooltip: "Previous(j)",
          onPrevious: () => {
            onPrevious(detailData?.data.prev_id);
          },
          nextTooltip: "Next(k)",
          onNext: () => {
            onNext(detailData?.data.next_id);
          },
          hasNext: filpLoading && detailData?.data.next_id,
        }}
      >
       { Object.values(detailData.data).length === 0 ?   <Card>{emptyPage}</Card> :  <Box className="product_detail_content">
          <InlineStack gap={400}>
            <Box className="product_variant_content">
              <Card>
                <InlineStack >
                  <Box className="product_variant_left">
                    <PictureBox
                      pageData={detailData.data}
                      detailData={detailData}
                      setDetailData={setDetailData}
                    />
                  </Box>
                  <Box className="product_variant_right">
                    <VariantBox
                      pageData={detailData.data}
                      setDetailData={setDetailData}
                      detailParams={detailParams}
                      planData={planData}
                      setPlanData={setPlanData}
                      userPoints={userPoints}
                      setUserPoints={setUserPoints}
                      premiumData={premiumData}
                      setPremiumData={setPremiumData}
                      handlePremiumOff={handlePremiumOff}
                      handleMageUpgrade={handleMageUpgrade}
                      setSourcingLimitModal={setSourcingLimitModal}
                      subList={subList}
                      setEnoughPoint={setEnoughPoint}
                      toastFun={toastFun}
                      callBackFun={callBackFun}
                      detailVariant={detailVariant}
                      setDetailVariant={setDetailVariant}
                      handleSelect={handleSelect}
                      handleAddToShoppingCart={handleAddToShoppingCart}
                    />
                  </Box>
                </InlineStack>
              </Card>
            </Box>
            <Box className="product_variant_description_review">
              <DescriptReview
                reviewData={reviewData}
                reviewParams={reviewParams}
                setReviewParams={setReviewParams}
                setReviewData={setReviewData}
                pageData={detailData.data}
                more={more} 
                setMore={setMore}
              />
            </Box>
            <SimilarProductBox
              relateData={relateData}
              getSurveyData={getSurveyData}
              requestParams={{ ...detailItemParams, favorite: 0 }}
              setRequestParams={setRelateParams}
              setDetailItemParams={setDetailItemParams}
              listData={relateData}
              setListData={setRelateData}
              setSourcingLimitModal={setSourcingLimitModal}
              planData={planData}
              userPoints={userPoints}
              setUserPoints={setUserPoints}
              setPlanData={setPlanData}
              setEnoughPoint={setEnoughPoint}
              toastFun={toastFun}
            />
          </InlineStack>
          <Box className="product_detail_bottom">
          <InlineStack
                      direction={"row"}
                      blockAlign="center"
                      gap={200}
                    >
                      <Button
                        disabled={detailVariant.disableAddToCart}
                        loading={detailVariant.addToCartLoading}
                        onClick={() => handleAddToShoppingCart()}
                      >
                        Add to cart
                      </Button>
                      { detailData.data?.dsm_pid ? (
                        <Button
                          variant="plain"
                          icon={EditIcon}
                          onClick={() => {
                            handleEditProduct( detailData.data?.pid);
                          }}
                        >
                          Edit on Product Center
                        </Button>
                      ) : detailParams?.source ? "" : (
                        <Button
                          variant="primary"
                          icon={PlusCircleIcon}
                          loading={addLoaing}
                          onClick={() => {
                            handleProductImportBottom(detailParams?.record_id,  detailData.data?.pid, 0);
                          }}
                        >
                          Add to product list
                        </Button>
                      )}
                    </InlineStack>
          </Box>
           
        </Box>}
      </Page>
    </Box>
  );
  return (
    <Box style={{ maxWidth: "1200px", margin: "0 auto" }}>
      {initPage ? <DetailSkeleton /> : pageContent}
      {toastMarkup}
      {/* agent选择 */}
      <PersonalAgentModal
        premiumData={premiumData}
        handlePremiumOff={handlePremiumOff}
        handleMageUpgrade={handleMageUpgrade}
      />
      {/* 次数不够 */}
      <EnoughQuotaModal
        type={"product"}
        handleSubmit={handleSubmit}
        sourcingLimitModal={sourcingLimitModal}
        userPoints={userPoints}
        subList={subList}
        setSourcingLimitModal={setSourcingLimitModal}
      />
      {/* 会员上限且积分不够 */}
      <EnoughPointsModal
        type={"product"}
        open={enoughPoint}
        setOpen={setEnoughPoint}
      />
    </Box>
  );
};

const defalutData = {
  subList: [
    {
      name: "FREE",
      dsc: intl.get("All essential startup features"),
      symbol: "$",
      title_icon: "/sub/free_cart.png",
      popular: "",
      price: "0.00",
      oldPrice: "",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      button: "Downgrade",
      link: "",
      id: 0,
      source: 3,
      aliexpress_product: 10,
      dsm_product:10,
      discount: 0,
      discount_time: 0,
      money: 0,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
    },
    {
      name: "PRO",
      dsc: intl.get("All necessary features for growing business"),
      symbol: "$",
      title_icon: "/sub/fly.png",
      popular: "",
      price: "29.9",
      oldPrice: "49",
      date: intl.get("Monthly"),
      isFree: false,
      type: 1,
      button: "7-day free trial",
      link: "",
      id: 1,
      discount: 0,
      dsm_product:1000,
      aliexpress_product: 1000,
      discount_time: 0,
      money: 29.9,
      source: 30,
      loading: false,
      disabled: false,
      level: 1,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
    },
    {
      name: "PLUS",
      dsc: intl.get("Powerful features for scaling your business"),
      symbol: "$",
      title_icon: "/sub/huojian.png",
      popular: intl.get("Highly Recommend"),
      price: "49.9",
      oldPrice: "79",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      id: 2,
      source: 60,
      dsm_product:5000,
      aliexpress_product: 5000,
      button: "7-day free trial",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 49.9,
      loading: false,
      disabled: false,
      level: 2,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
    },
    {
      name: "Basic",
      dsc: intl.get("All the basics for you to start a new business"),
      symbol: "$",
      title_icon: "/sub/cart.png",
      popular: "",
      price: "9.9",
      oldPrice: "19",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      source: 10,
      dsm_product:300,
      aliexpress_product: 300,
      id: 4,
      button: "Downgrade",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 9.9,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
    },
    {
      name: "PREMIUM",
      dsc: intl.get(
        "All Plus features, unlimited product imports, 1999 points, and Personal Agent VIP Services"
      ),
      symbol: "$",
      title_icon: "/sub/ufo.png",
      popular: "",
      price: "149",
      oldPrice: "199",
      date: intl.get("Monthly"),
      isFree: true,
      type: 1,
      source: 300,
      dsm_product:100000,
      aliexpress_product: 100000,
      id: 5,
      button: "Downgrade",
      link: "",
      discount: 0,
      discount_time: 0,
      money: 149,
      loading: false,
      disabled: false,
      user_discounts: {
        event_discounts: 1,
        event_plus_discounts: "",
        event_basic_discounts: "",
        event_active_time: 0,
      },
      level: 0,
      agent: [
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
        {
          name: "Lucy Li",
          img_url: "/dashborard_sale.jpg",
          year: 5,
          rating: 4.9,
          tag: ["ssaaad", "adadadllss"],
        },
      ],
    },
  ],
};

export default withRouter(DetailsClass);
