import {Modal} from "@shopify/polaris";
import React from "react";

// ai优化积分不足
const Index = ({ 
  batchSourcingActive,
  batchSourcingLoading,
  handleBatchSourcingRequest,
  handleBatchSourcingActive,
  handleSourcingRequest,
  sourceProductId
}) => {

  return (
    <div>
      <Modal
        open={batchSourcingActive}
        onClose={() => handleBatchSourcingActive(false)}
        title={sourceProductId !== '' ? "Sourcing Request" : 'Bulk Sourcing Request'}
        primaryAction={{
          content: 'Confirm',
          onAction: ()=>{
            if (sourceProductId !== '') {
              handleSourcingRequest()
            }else {
              handleBatchSourcingRequest()
            }
          },
          loading: batchSourcingLoading
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => handleBatchSourcingActive(false),
          },
        ]}
      >
        <Modal.Section>
        {sourceProductId !== '' ? "Are you sure you want to source this product? Once your request is processed, the product supplier will be changed to Dropshipman." : 'Are you sure to source these products in bulk? After we complete your request, the supplier of these products will change to Dropshipman.'}
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default Index;
