import {
  BlockStack,
  Box,
  Card,
  InlineStack,
  ProgressBar,
  SkeletonPage,
  SkeletonThumbnail,
} from "@shopify/polaris";
import React from "react";

const Index = () => {
  return (
    <SkeletonPage fullWidth primaryAction>
      <BlockStack gap="300">
        <Box>
          <Card padding={300}>
            <InlineStack gap={200}>
              <Box className="detail_top_left" style={{ width: "55%" }}>
                <InlineStack gap={200}>
                  <Box  className="detail_top_left_img_list" style={{ width: "10%" }}>
                    <BlockStack gap={300}>
                      <SkeletonThumbnail size="medium" />
                      <SkeletonThumbnail size="medium" />
                      <SkeletonThumbnail size="medium" />
                      <SkeletonThumbnail size="medium" />
                      <SkeletonThumbnail size="medium" />
                      <SkeletonThumbnail size="medium" />
                    </BlockStack>
                  </Box>
                  <Box  className="detail_top_left_img_big" style={{ flex: 1, height: "100%" }}>
                    <Card padding={0}>
                      <Box
                        style={{
                          height: "500px",
                          background: "rgb(227, 227, 227)",
                        }}
                      ></Box>
                    </Card>
                  </Box>
                </InlineStack>
              </Box>
              <Box  className="detail_top_right" style={{ flex: 1 }}>
                <BlockStack gap="500">
                  <ProgressBar size="large" />
                  <InlineStack gap={200}>
                    <Box className="detail_top_right_invent"  style={{ width: "100px" }}>
                      <ProgressBar />
                    </Box>
                    <Box  className="detail_top_right_invent"  style={{ width: "100px" }}>
                      <ProgressBar />
                    </Box>
                    <Box  className="detail_top_right_invent"  style={{ width: "100px" }}>
                      <ProgressBar />
                    </Box>
                  </InlineStack>
                  <InlineStack gap={100} align="space-between">
                    <Box className="detail_top_right_price" style={{ width: "20%" }}>
                      <ProgressBar />
                    </Box>
                    <Box className="detail_top_right_collect" style={{ width: "10%" }}>
                      <ProgressBar />
                    </Box>
                  </InlineStack>
                  <Box  className="detail_top_right_sku" style={{ width: "40%" }}>
                    <ProgressBar size="large" />
                  </Box>
                  <InlineStack gap={200}>
                    <Box className="detail_top_right_cart" style={{ width: "20%" }}>
                      <ProgressBar size="large" />
                    </Box>
                    <Box  className="detail_top_right_add" style={{ width: "30%" }}>
                      <ProgressBar size="large" />
                    </Box>
                  </InlineStack>
                  <Box  className="detail_top_right_ware" style={{ width: "50%" }}>
                    <ProgressBar size="small" />
                  </Box>

                  <InlineStack gap={200}>
                    <Box  className="detail_top_right_coutry" style={{ width: "20%" }}>
                      <ProgressBar size="large" />
                    </Box>
                    <Box className="detail_top_right_shipping" style={{ width: "40%" }}>
                      <ProgressBar size="large" />
                    </Box>
                  </InlineStack>
                  <Box className="detail_top_right_p1" style={{ width: "60%" }}>
                    <ProgressBar size="small" />
                  </Box>
                  <Box className="detail_top_right_p2" style={{ width: "50%" }}>
                    <ProgressBar size="small" />
                  </Box>
                  <Box  className="detail_top_right_p3" style={{ width: "30%" }}>
                    <ProgressBar size="small" />
                  </Box>
                  <Box  className="detail_top_right_wallet" style={{ width: "40%" }}>
                    <ProgressBar size="large" />
                  </Box>
                </BlockStack>
              </Box>
            </InlineStack>
          </Card>
        </Box>
        <InlineStack gap={500}>
          <Box  className="detail_top_right_desc" style={{ width: "70%" }}>
            <Card>
              <BlockStack gap={300}>
                <Box style={{ width: "20%" }}>
                  <ProgressBar size="large" />
                </Box>
                <BlockStack gap={200}>
                  <ProgressBar />
                  <ProgressBar />
                  <ProgressBar />
                  <ProgressBar />
                  <ProgressBar />
                  <ProgressBar />
                  <ProgressBar />
                  <ProgressBar />
                </BlockStack>
              </BlockStack>
            </Card>
          </Box>
          <Box  className="detail_top_right_review" style={{ flex: 1 }}>
            <Card>
              <BlockStack gap={300}>
                <Box style={{ width: "20%" }}>
                  <ProgressBar size="large" />
                </Box>
                <BlockStack gap={1000}>
                  <InlineStack gap={200}>
                    <Box style={{ width: "20%" }}>
                      <ProgressBar size="large" />
                    </Box>
                    <Box style={{ flex: 1 }}>
                      <BlockStack gap={200}>
                        <ProgressBar />
                        <ProgressBar size="small" />
                      </BlockStack>
                    </Box>
                  </InlineStack>
                  <InlineStack gap={200}>
                    <Box style={{ width: "20%" }}>
                      <ProgressBar size="large" />
                    </Box>
                    <Box style={{ flex: 1 }}>
                      <BlockStack gap={200}>
                        <ProgressBar />
                        <ProgressBar size="small" />
                      </BlockStack>
                    </Box>
                  </InlineStack>
                  <InlineStack gap={200}>
                    <Box style={{ width: "20%" }}>
                      <ProgressBar size="large" />
                    </Box>
                    <Box style={{ flex: 1 }}>
                      <BlockStack gap={200}>
                        <ProgressBar />
                        <ProgressBar size="small" />
                      </BlockStack>
                    </Box>
                  </InlineStack>
                </BlockStack>
              </BlockStack>
            </Card>
          </Box>
        </InlineStack>
      </BlockStack>
    </SkeletonPage>
  );
};

export default Index;
