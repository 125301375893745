import React, { Component } from 'react';

import { Modal, Toast ,Banner , Thumbnail, TextField,Checkbox,FormLayout} from '@shopify/polaris';

import { reqPostOverrideByAnother,reqGetNotifyProductVariants } from '../../../../api';

import intl from '../../../../i18n/intl'

class OverrideProductModal extends Component {

    constructor(props) {
      super(props);
      this.state = {
        ModalShow: (props.show===undefined)?false:props.show,
        fatherKey: props.fatherKey,
        shopifyProductTableId: props.shopifyProductTableId,
        notifyProductTableId: props.notifyProductTableId,
        productTitle: props.title,
        productImage: props.image,
        EditOnShopify: props.EditOnShopify,
        productStockVariants: props.stockVariants,
        useInProduct: props.useInProduct,  // 是否在产品页面调用
        overrideProductUrl: '',
        errorMsg: '',

        //弹窗
        showToast: false,  //弹窗显示
        showToastText:'',  //弹窗文字
        showToastIserror:false, //弹窗是否错误

        Buttonloading: false,
        ButtonDisabled: true,
        title: false,
        images: false
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        ModalShow: (nextProps.show===undefined)?false:nextProps.show,
        fatherKey: nextProps.fatherKey,
        shopifyProductTableId: nextProps.shopifyProductTableId,
        notifyProductTableId: nextProps.notifyProductTableId,
        productTitle: nextProps.title,
        productImage: nextProps.image,
        EditOnShopify: nextProps.EditOnShopify,
        productStockVariants: nextProps.stockVariants,
        useInProduct: nextProps.useInProduct,
        overrideProductUrl: '',
        errorMsg: '',

        //弹窗
        showToast: false,  //弹窗显示
        showToastText:'',  //弹窗文字
        showToastIserror:false, //弹窗是否错误

        Buttonloading: false,
        ButtonDisabled: true,
        title: false,
        images: false
      },()=>{
        this.getVariantsData();
      });
    }

    //进入页面调用接口
    componentDidMount () {
      this.getVariantsData();
    }

    // 获取variants列表
    getVariantsData = async() =>{
      const params = {
        nid: this.props.notifyProductTableId,
        sid: this.props.shopifyProductTableId,
        action: 'override'
      };
      const response = await reqGetNotifyProductVariants(params);
      if(response.code === '200'){
        let getData = response.data;
        this.setState({
          productStockVariants: getData.VariantStock,
        });

      }else{
        this.toggleToast(response.msg);
      }
    }

    //提示弹窗
    toggleToast = (showToastText, showToastIserror=false) => {
      this.setState(({ showToast }) => ({
        showToast: !showToast,
        showToastText: showToastText,
        showToastIserror: showToastIserror
      }));
    };

    handleInputChange = (value) =>{
      let error = '';
      let isDisabled = true;

      if(value !== ''){
        // 判断url的有效性
        error = intl.get("Please enter a valid AliExpress product URL or ID");
        let numberId = Number(value);
        if(!isNaN(numberId)){
          if(String(numberId).length > 6){
            isDisabled = false;
            error = '';
          }
          
        }else{
          if(value.indexOf('aliexpress.com/item/') !== -1 || value.indexOf('aliexpress.ru/item/') !== -1){
            isDisabled = false;
            error = '';
          }
        }
      }

      this.setState({
        overrideProductUrl:value,
        ButtonDisabled: isDisabled,
        errorMsg: error
      });
    }

    // 弹窗控制
    handleChangeModal = () =>{
      this.setState({
        ModalShow:!this.state.ModalShow,
      });
    }

    // 产品覆盖确认
    handleOverrideProductDone = async() =>{
      this.setState({
        Buttonloading: true
      });

      const params = {
        type: (this.state.useInProduct)?'product':'notification',
        sid: this.state.shopifyProductTableId,
        nid: this.state.notifyProductTableId,
        value: this.state.overrideProductUrl,
        title: this.state.title?1:0,
        images: this.state.images?1:0,
      };
      const response = await reqPostOverrideByAnother(params);

      this.setState({
        Buttonloading: false
      });

      if (response.code === "200") {
        this.toggleToast(response.msg, false);

        setTimeout(()=>{
          // 关闭弹窗
          this.handleChangeModal();
          // 跳转到产品页面
          if(this.state.useInProduct){  // 产品页面调用，直接刷新本页面即可
            window.location.reload();
          }else{
            if(response.data !== ''){
              window.location.href=response.data;
            }
          }
        },500);

      }else{
        this.setState({
          errorMsg: response.data
        });
        this.toggleToast(response.msg);
      }
    }

    handleCheckboxChange = (field,value) =>{
      this.setState({
        [field]: value
      });
    }

    render() {
        const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
        const {
            state: { ModalShow,productTitle,productImage,productStockVariants,EditOnShopify,useInProduct,overrideProductUrl,Buttonloading,ButtonDisabled,errorMsg },
        } = this;

        let _productStockVariants = '';
        try {
          if(productStockVariants !== undefined || productStockVariants !== ''){
            if(productStockVariants.variantCount > 0){
              // let _variantText = (productStockVariants.variantCount > 1)?' variants':' variant';
              // _productStockVariants = productStockVariants.stockCount + ' in stock for '+ productStockVariants.variantCount + _variantText;
              _productStockVariants = intl.get("{{productStockVariants.stockCount}} in stock for {{productStockVariants.variantCount}} variant", productStockVariants.stockCount, productStockVariants.variantCount);
            }
          }
        } catch (error) {}
        
        return (
            <div>
              <Modal 
                open={ModalShow} 
                onClose={this.handleChangeModal} 
                title={intl.get("Overriding Product")}
                primaryAction={{
                  content: intl.get("Override"),
                  onAction: this.handleOverrideProductDone,
                  disabled: ButtonDisabled,
                  loading: Buttonloading
                }} 
                secondaryActions={[{
                  content: intl.get("Cancel"),
                  onAction: this.handleChangeModal
                }]}
                size='large'
              >
                <Modal.Section>
                  <Banner
                    // title=""
                    tone="warning"
                  >
                     
                      <p style={{whiteSpace:'pre-wrap'}}>
                        {intl.get("Overriding will DELETE ALL of your existing product variants and replace them with variants from a new supplier:")}
                      </p>
                     
                  </Banner>

                  <div className="Original_Product_Box">
                    <div className="Original_Product_Image">
                      <Thumbnail
                        source={productImage}
                        alt="product"
                      />
                    </div>
                    <div className="Original_Product_Title" style={{width:useInProduct?'unset':'50%'}}>
                      <a href={EditOnShopify} target="_blank" className="Polaris-Link" rel="noopener noreferrer">
                        {productTitle}
                      </a>
                    </div>
                    <div className="Original_Product_Inventory">
                      {_productStockVariants}
                    </div>
                  </div>

                  <div style={{paddingTop:'5px'}}>
                    <FormLayout>
                      <TextField 
                        label={intl.get("Override With")}
                        value={overrideProductUrl}
                        placeholder={intl.get("Paste AliExpress Product Link Here")}
                        onChange={this.handleInputChange}
                        error={errorMsg}
                      />

                      <Checkbox
                        label={intl.get("Override Title and Description")}
                        checked={this.state.title}
                        onChange={this.handleCheckboxChange.bind(this,'title')}
                        helpText={intl.get("If you select this option, we will replace your existing product title and description with the title/description from the overriding product.")}
                      />

                      <Checkbox
                        label={intl.get("Override Images")}
                        checked={this.state.images}
                        onChange={this.handleCheckboxChange.bind(this,'images')}
                        helpText={intl.get("If you select this option, we will DELETE ALL of your existing product images and will replace them with the images from the overriding product.")}
                      />
                    </FormLayout>
                  </div>
                </Modal.Section>
              </Modal>

              { toastMarkup }
            </div>
        );
    }
}

export default OverrideProductModal;