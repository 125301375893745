import {
  Page,
  Card,
  Layout,
  IndexTable,
  BlockStack,
  Box,
  Text,
  useIndexResourceState,
  Pagination,
  SkeletonPage,
  SkeletonBodyText,
  EmptyState,
  Tooltip
} from "@shopify/polaris";

import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import intl from "../../../i18n/intl";
import _ from "lodash";
import utils from "../../../utils/utils";
import { useHistory, withRouter } from "react-router-dom";
import { reqGetPersonInventoryHistoryListData, reqGetPersonInventoryListDataExport } from "../../../api";
import ChangeLoading from "../../../components/ChangeLoading";
// import AppBridge from "../../../utils/appBridge";

const Index = () => {
  const router = useHistory();
  
  const [initPageData,setInitPageData] = useState({
    isInit:true,
    pageLoading:false,
  })
  const [searchParam,setSearchParam] = useState({
    page: 1,
    size: 20,
    inventory_id:'',
  });
  const [pageData, setPageData] = useState({
    detail:[]
  })
  const getHistoryPage = async (param)=>{
    let url = new URLSearchParams(window.location.search);
    let record_id = url.get("id")
    let params = {
      ...param,
      inventory_id:param.inventory_id !== '' ? param.inventory_id : record_id
    }
    try {
      let res = await reqGetPersonInventoryHistoryListData(params);
      if (res.code === '200') {
        setPageData(res.data);
      }
      setInitPageData({
        isInit:false,
        pageLoading:false,
        
      })
    } catch (error) {
      }
    
  }
  useEffect(() => {
    let isMount = false;
    let url = new URLSearchParams(window.location.search);
    let record_id = url.get("id")
    setSearchParam((prevValue)=>({...prevValue,inventory_id:record_id}))
    // if(AppBridge && AppBridge.exist()) {
    //   AppBridge.setTitleBar('Branding','/admin/customService')
    // }
    if (!isMount) {
      getHistoryPage(searchParam)
    }
    return () => {
      isMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   // 总条数
   const pageMsg = () => {
    let total = pageData?.count ? pageData?.count : 0;
    const { page, size } = searchParam;
    const startNum = (page - 1) * size + 1;
    const endNum = page * size > total ? total : page * size;
    return intl.get(
      "Showing {{startNum}} - {{endNum}} of {{total}} results (Total {{ceil}} Page)",
      startNum,
      endNum,
      total,
      Math.ceil(total / 50)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (page > 1 && page <= maxPageNum) {
      return initPageData.pageLoading ? false : true;
    } else if (page === 1) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, initPageData]);

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const { page, size } = searchParam;
    let total = pageData?.count ? pageData?.count : 0;
    const maxPageNum = _.ceil(total / size);
    if (_.inRange(page, 1, maxPageNum)) {
      return initPageData.pageLoading ? false : true;
    } else if (page === maxPageNum) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.count, searchParam, initPageData]);

  // 上一页
  const handlePrev = () => {
    setInitPageData((prevValue)=>({...prevValue,pageLoading:true}))
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) - 1;
      getHistoryPage(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };

  // 下一页
  const handleNext = () => {
    setInitPageData((prevValue)=>({...prevValue,pageLoading:true}))
    setSearchParam((prevValue) => {
      const params = { ...prevValue };
      params.page = Number(prevValue.page) + 1;
      getHistoryPage(params);
      return params;
    });
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  };
  const {selectedResources, allResourcesSelected, handleSelectionChange} =
  useIndexResourceState([]);
  // 列表数据
  const rowMarkup = pageData?.detail.map((item, index) => {
    return (
      <IndexTable.Row
        id={index}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Box className="history_table_item">
            <Text alignment="center">{item.created_at}</Text>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Box className="history_table_item">
        <Tooltip content={item.log_desc}>
          <div style={{display:'flex',justifyContent:'center'}}>
        <p>{item.log_desc.slice(0,50)}</p>{item.log_desc.length > 50 ? '...':''}
        </div>
        </Tooltip>
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell>
        <Box className={item.record_type > 0 ? "history_table_item" : 'history_table_item history_table_items'}>
            <Text alignment="center">{item.record_type > 0 ? '+' : '-'}{item.amount}</Text>
          </Box>
        </IndexTable.Cell>

        <IndexTable.Cell>
        <Box className="history_table_item">
            <Text alignment="center">{item.surplus_count}</Text>
          </Box>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  // csv 导出
  const handleExport = async ()=>{
    let params = {
      inventory_id: searchParam.inventory_id,
    };
    let res = await reqGetPersonInventoryListDataExport(params)
    if (res.code === '200') {
      var csv = convertToCSV(res.data);
      downloadCSV(csv);
    }
  }
  const convertToCSV = (data) => {
    var csv = '';
    for (var i = 0; i < data.length; i++) {
      var row = data[i];
      for (var j = 0; j < row.length; j++) {
        var val = row[j] === null ? '' : row[j].toString();
        if (val.indexOf(',') !== -1 || val.indexOf('"') !== -1 || val.indexOf('\n') !== -1) {
          val = '"' + val.replace(/"/g, '""') + '"';
        }
        if (j > 0) {
          csv += ',';
        }
        csv += val;
      }
      csv += '\n';
    }
    return csv;
  }

  const downloadCSV = (csv) => {
    try {
    var link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    link.download = 'Inventory_History_'+ new Date().getTime() +'.csv';
    document.body.appendChild(link);
    link.click();
    document?.body?.removeChild(link);
      
  } catch (error) {
      
  }
  }
  const EmptyProductList = (
    <div className="emptyWithImg">
      <EmptyState
        heading={intl.get("Sorry, no results found.")}
        image="/empty.svg"
      >
      </EmptyState>
    </div>
  );
  const skeletonPage =  <SkeletonPage fullWidth={false}>
  <Layout>
   <Layout.Section>
   <Card>
   <BlockStack gap={400}>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   <SkeletonBodyText lines={3}/>
   </BlockStack>
   </Card>
 </Layout.Section>
   </Layout>
 </SkeletonPage>
  return (
    <div className="history_log" style={{position:'relative'}}>
      {initPageData.isInit ? skeletonPage : <Page
        backAction={
          false
            ? undefined
            : {
                content: "",
                onAction: () => {
                  utils.routeListen("/admin/customService/custom-packaging");
                  utils.routeJump1(
                    router,
                    "/admin/customService/custom-packaging?selected=2"
                  );
                },
              }
        }
        title={<div style={{ fontSize: 20 }}>{intl.get("My Packing")}</div>}
      >
        <Layout>
          <Layout.Section>
            <BlockStack gap={400}>
              <Box>
                <Card>
                  <Box className="history_top">
                    <Box className="history_top_sku">
                    {pageData?.inventory && <Box  className="history_top_sku_img">
                        <img src={pageData?.inventory?.sku_image} alt="Dropshipman" />
                      </Box>}
                      <Box  className="history_top_sku_title">
                        <p>{pageData?.inventory?.title}</p>
                        <p>{pageData?.inventory?.variant_title}</p>
                      </Box>
                    </Box>
                    <Box  className="history_top_sku_inventory">
                      <Box>Current Inventory</Box>
                      <Box className='history_top_sku_inventory_availabel'><strong>{pageData?.inventory?.available}</strong></Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
              {pageData?.detail.length > 0 ? <Box>
                <Card>
                  <Box className="history_table_top">
                    <Box className="history_table_title">Inventory History</Box>
                    <Box className="history_table_export">
                    {pageData?.detail.length > 0 && <span onClick={()=>handleExport()}> <img src="/novice/download_export.png" alt="/dropshipman" /> {intl.get("Export")}</span>}
                      </Box>
                  </Box>
                  <Box className="history_table">
                    <IndexTable
                      itemCount={pageData?.detail.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      selectable={false}
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Date" },
                        { title: "Event" },
                        { title: "Inventory Changes" },
                        { title: "Remaining Quantity" },
                      ]}
                      pagination={{
                        hasNext: true,
                        onNext: () => {},
                      }}
                    >
                      {rowMarkup}
                    </IndexTable>
                  </Box>
                  
                </Card>
                <div className="pageination_container">
                <Pagination
                  label={pageMsg()}
                  hasPrevious={hasPrevious}
                  hasNext={hasNext}
                  onPrevious={handlePrev}
                  onNext={handleNext}
                />
               </div>
                
              </Box>:<Box>
                <Card>
                  {EmptyProductList}
                </Card>
                </Box>}
            </BlockStack>
          </Layout.Section>
        </Layout>
      </Page>}
      {initPageData.pageLoading && <ChangeLoading />}
    </div>
  );
};


export default withRouter(Index);
