import React, { useState, memo } from "react";
import { Autocomplete, Icon } from "@shopify/polaris";
import { XSmallIcon, DeleteIcon } from "@shopify/polaris-icons";
import countryArrayConfig from "../../../../../../../assets/CountryArray";
import dash from "lodash";
import intl from "../../../../../../../i18n/intl";
import { store } from "../../../../../../../store";
import { connect } from "react-redux";
const OptionsAutocomplete = memo(
  ({
    shippingDataSource,
    userShippingDataSource,
    handleChangeUserShipping,
    setShippingDataSource,
    saveInput,
    setSaveInput,
    focus,
    setFocus
  }) => {
    const [selectedShippingOptions, setSelectedShippingOptions] = useState([]);
    const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState(countryArrayConfig);
    const [currentShippingDataSource,setCurrentShippingDataSource] = useState(shippingDataSource)
    const updateCountryText = (value, parentKey) => {
      let person = store.getState().languageReducer.subScriptData;
      console.log(person);
      
      if (person.level === 0 || person.level === 4) {
        handleChangeUserShipping()
        return
      }
      //国家输入事件
      if (value === "") {
        setCountryOptions(countryArrayConfig);
        const data = [...userShippingDataSource];
        const result = data.map((item, index) => {
          if (index === parentKey) {
            setSaveInput && setSaveInput(false)
            return { ...item, country_name: value };
          }
          return item;
        });
        handleChangeUserShipping(result);
        return;
      }
      const filterRegex = new RegExp(value, "i");
      const resultOptions = countryArrayConfig.filter((option) =>
        option.label.match(filterRegex)
      );
      setCountryOptions(resultOptions);

      const data = [...userShippingDataSource];
      const result = data.map((item, index) => {
        if (index === parentKey) {
          setSaveInput && setSaveInput(false)
          return { ...item, country_name: value };
        }
        return item;
      });
      handleChangeUserShipping(result);
    };

    const selectionCountry = (selected, parentKey) => {
      let person = store.getState().languageReducer.subScriptData;
      if (person.level === 0 || person.level === 4) {
        handleChangeUserShipping()
        return
      }
      //国家选择事件
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = countryOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      setSelectedCountryOptions(selected);

      const data = [...userShippingDataSource];
      const result = data.map((item, index) => {
        if (index === parentKey) {
          setSaveInput && setSaveInput(false)
          return {
            ...item,
            country_code: selected[0],
            country_name: selectedValue[0],
          };
        }
        return item;
      });
      handleChangeUserShipping(result);
    };
    const updateShippingText = (value, childKey, parentKey) => {
      let person = store.getState().languageReducer.subScriptData;
      if (person.level === 0 || person.level === 4) {
        handleChangeUserShipping()
        return
      }
      //物流输入事件
        if (value === "") {
          const data = [...shippingDataSource];
          setCurrentShippingDataSource(data);
          const datas = [...userShippingDataSource];
          datas.forEach((item, pkey) => {
          if (pkey === parentKey) {
            setSaveInput && setSaveInput(false)
            item.child.forEach((citem, ckey) => {
              if (ckey === childKey) {
                citem.shipping_name = value;
              }
            });
          }
        });
        handleChangeUserShipping(datas);
        return;
      }
      value = value.replace('(','').replace(')','')
      const filterRegex = new RegExp(value, "i");
      const resultOptions = shippingDataSource.filter((option) =>
      option.label.match(filterRegex)
      );
      setCurrentShippingDataSource(resultOptions);
      setFocus && setFocus(false)
      const data = [...userShippingDataSource];
      data.forEach((item, pkey) => {
        if (pkey === parentKey) {
          setSaveInput && setSaveInput(false)
          item.child.forEach((citem, ckey) => {
            if (ckey === childKey) {
              citem.shipping_name = value;
            }
          });
        }
      });
      handleChangeUserShipping(data);
    };

    const updateShippingTextFocus = () => {
      //物流输入事件
          const data = [...shippingDataSource];
          setCurrentShippingDataSource(data);
    };

    const selectionShipping = (selected, parentKey, childKey) => {
      let person = store.getState().languageReducer.subScriptData;
      if (person.level === 0 || person.level === 4) {
        handleChangeUserShipping()
        return
      }
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = shippingDataSource.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });
      setSelectedShippingOptions(selected); //选择对应物流
      setSaveInput && setSaveInput(false)
      setFocus && setFocus(false)
      const data = [...userShippingDataSource];
      const result = data.map((item, index) => {
        let child_result = item.child;
        for (let i = 0; i < item.child.length; i++) {
          //判断该物流是否已经被设置过
          if (
            item.child.filter((item) => item.shipping_methods === selected[0])
              .length > 0
          ) {
            return item;
          }
          if (i === childKey && index === parentKey) {
            //获取用户设置好的物流value
            item.child[i].shipping_methods = selected[0];
            item.child[i].shipping_name = selectedValue[0];
            child_result = item.child;
          }
        }
        if (
          child_result.length <= 4 &&
          parentKey === index &&
          child_result.filter((item) => item.shipping_name === "").length === 0
        ) {
          //子节点超过五个停止push, 且不允许重复push
          child_result.push({ shipping_methods: "", shipping_name: "" });
        }
        item.child = child_result;
        return item;
      });
      if (
        childKey === 0 &&
        result.length < 10 &&
        parentKey + 1 === data.length
      ) {
        //限制最多push10次
        result.push({
          id: 0,
          is_del: 0,
          country_code: "",
          country_name: "",
          child: [{ shipping_methods: "", shipping_name: "" }],
        });
      }
      handleChangeUserShipping(result);
    }; //动态添加物流逻辑

    const handelDelParent = (parentKey) => {
      let person = store.getState().languageReducer.subScriptData;
      if (person.level === 0 || person.level === 4) {
        handleChangeUserShipping()
        return
      }
      const data = [...userShippingDataSource];
      const result = data.filter((item, index) => {
        if (parentKey === index) {
          return !item;
        } else {
          return item;
        }
      });
      let flag = true;
      for (let i = 0; i < result.length; i++) {
        if (
          result[i].id === 0 &&
          result[i].country_code === "" &&
          result[i].country_name === ""
        ) {
          flag = false;
        }
      }
      if (flag) {
        result.push({
          id: 0,
          country_code: "",
          country_name: "",
          is_del: 0,
          child: [
            {
              shipping_methods: "",
              shipping_name: "",
            },
          ],
        });
      }
      handleChangeUserShipping(result);
    }; //移除父节点

    const handelDelChild = (parentKey, childKey) => {
      let person = store.getState().languageReducer.subScriptData;
      if (person.level === 0 || person.level === 4) {
        handleChangeUserShipping()
        return
      }
      const data = [...userShippingDataSource];
      const result = data.map((item, index) => {
        if (index === parentKey) {
          let childList = [];
          if (childKey === 0 && item.child.length === 1) {
            //如果只剩余最后一个子节点，则移除整个父节点
            return handelDelParent(parentKey);
          } else {
            item.child.forEach((o, i) => {
              //移除子节点
              if (i !== childKey) {
                childList.push(o);
              }
            });
          }
          return { ...item, child: childList };
        }
        return item;
      });
      let flag = true;
      for (let i = 0; i < result.length; i++) {
        if (
          result[i]?.id === 0 &&
          result[i]?.country_code === "" &&
          result[i]?.country_name === ""
        ) {
          flag = false;
        }
      }
      if (flag) {
        result.push({
          id: 0,
          country_code: "",
          country_name: "",
          is_del: 0,
          child: [
            {
              shipping_methods: "",
              shipping_name: "",
            },
          ],
        });
      }
      handleChangeUserShipping(dash.compact(result));
    }; //移除子节点
    const autoCompleteContentNode = () => {
      //主体内容
      return (
        <>
          {userShippingDataSource !== undefined &&
            userShippingDataSource.map((parentItem, parentKey) => {
              return (
                <div className={`textField_container ${(saveInput && parentKey === 0) || (focus && focus) ? 'error_input': ''} `} key={parentKey}>
                  <div className={`textField_left `}>
                    <Autocomplete
                     listTitle={intl.get("Dropshipman support all country list")}
                      options={countryOptions}
                      selected={selectedCountryOptions}
                      onSelect={(selected) =>
                        selectionCountry(selected, parentKey)
                      }
                      textField={
                        <Autocomplete.TextField
                          value={parentItem.country_name}
                          onChange={(value) =>
                            updateCountryText(value, parentKey)
                          }
                          placeholder={intl.get("Country/Region")}
                        />
                      }
                    />
                  </div>
                  <div className="autoRight_container">
                    {parentItem.child.map.length > 0 &&
                      parentItem.child.map((item, childKey) => {
                        return (
                          <div className="textField_right" key={childKey}>
                            <Autocomplete
                              listTitle={intl.get("Dropshipman support shipping methods list")}
                              options={currentShippingDataSource}
                              selected={selectedShippingOptions}
                              onSelect={(selected) =>
                                selectionShipping(selected, parentKey, childKey)
                              }
                              textField={
                                <Autocomplete.TextField
                                  value={item.shipping_name}
                                  onFocus={(value) =>updateShippingTextFocus()}
                                  onChange={(value) =>
                                    updateShippingText(
                                      value,
                                      childKey,
                                      parentKey
                                    )
                                  }
                                  placeholder={intl.get("Shipping methods")}
                                />
                              }
                            />
                            {userShippingDataSource.length > 1 &&
                              item.shipping_name && (
                                <div
                                  className="XSmallIcon"
                                  onClick={() =>
                                    handelDelChild(parentKey, childKey)
                                  }
                                >
                                  <Icon
                                    source={XSmallIcon}
                                    color="inkLighter"
                                  />
                                </div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                  {userShippingDataSource.length > 1 &&
                    !(parentKey + 1 === userShippingDataSource.length) && (
                      <div
                        className="DeleteIcon"
                        onClick={() => handelDelParent(parentKey)}
                      >
                        <Icon source={DeleteIcon} color="inkLighter" />
                      </div>
                    )}
                </div>
              );
            })}
        </>
      );
    };

    return <>{autoCompleteContentNode()}</>;
  }
);
export default connect()(OptionsAutocomplete);
