import { Banner, Box, Checkbox, InlineStack, Modal } from '@shopify/polaris';
import React, { useState }  from 'react';
import { getPostCateSelect } from '../../../../api';

const CateFirstSelect = ({open,setOpen,toastFun,getListData})=> {
    const [cateSelectData,setCateSelectData] = useState([
        {
            "category_id": 1,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Computer & Office",
            "seo_category_name": "computer-office",
           
        },
        {
            "category_id": 39,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Bags & Shoes",
            "seo_category_name": "bags-shoes",
           
        },
        {
            "category_id": 70,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Jewelry & Watches",
            "seo_category_name": "jewelry-watches",
           
        },
        {
            "category_id": 108,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Health, Beauty & Hair",
            "seo_category_name": "health-beauty-hair",
           
        },
        {
            "category_id": 152,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Women's Clothing",
            "seo_category_name": "womens-clothing",
           
        },
        {
            "category_id": 192,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Sports & Outdoors",
            "seo_category_name": "sports-outdoors",
            
        },
        {
            "category_id": 235,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Home, Garden & Furniture",
            "seo_category_name": "home-garden-furniture",
           
        },
        {
            "category_id": 279,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Home Improvement",
            "seo_category_name": "home-improvement",
           
        },
        {
            "category_id": 312,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Automobiles & Motorcycles",
            "seo_category_name": "automobiles-motorcycles",
           
        },
        {
            "category_id": 356,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Toys, Kids & Babies",
            "seo_category_name": "toys-kids-babies",
           
        },
        {
            "category_id": 397,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Men's Clothing",
            "seo_category_name": "mens-clothing",
           
        },
        {
            "category_id": 441,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Consumer Electronics",
            "seo_category_name": "consumer-electronics",
          
        },
        {
            "category_id": 483,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Phones & Accessories",
            "seo_category_name": "phones-accessories",
           
        },
        {
            "category_id": 523,
            "pid": 0,
            select:0,
            "level": 1,
            "category_name": "Pet Supplies",
            "seo_category_name": "",
        }
    ])
    const [selectCateLoading,setSelectCateLoading] = useState(false);
    const [warningMessage,setWarningMessage] = useState('');
    // 偏好选择
 const handleChangeSelect = (value) => {
    setWarningMessage('')
    let list = cateSelectData.map((item, ind) => {
      if (item.category_id === value) {
        return { ...item, select: item?.select === 1 ? 0 : 1 };
      } else {
        return item;
      }
    });
    let len = list.filter((sl) => {
      return sl.select === 1;
    });
    if (len.length <= 5) {
     
      setCateSelectData(list);
    } else {
        setWarningMessage(`Category Limit Reached Maximum 5 categories allowed`)
      
    }
  };
  // 保存选择
 const onSubmitSelect = async () => {
    let len = cateSelectData.filter((sl) => {
      return sl.select === 1;
    });
    if (len.length > 0) {
      let ids = [];
      for (let i = 0; i < len.length; i++) {
        if (len[i].select === 1) {
          ids.push(len[i].category_id);
        }
      }
      let params = {
        cate_ids: ids,
      };
      
      try {
        setSelectCateLoading(true);
        
        let res = await getPostCateSelect(params);
        if (res.code === "200") {
            toastFun("Added successfully");
            setWarningMessage("");
            setOpen(false)
            getListData({category_id:ids[0]})
        }
      } catch (error) {
        console.log(error);
        
      }
    } else {
        setWarningMessage("Please select at least one category");
    }
    setSelectCateLoading(false);
  };

  // 取消
 const onCancelSelect = async () => {
    setOpen(false)
    setSelectCateLoading(false);
    setWarningMessage('')
    let list = cateSelectData.map((item, ind) => {
      return { ...item, select: 0 };
    });
    try {
      let params = {
        cate_ids: [],
      };
      let res = await getPostCateSelect(params);
      if (res.code === "200") {
        setCateSelectData(list);
        setOpen(false)
      }
    } catch (error) {
        setOpen(false)
    }
  };
    return (
        <div>
            <Modal
        open={open}
        title={"Select preferred categories for recommendations"}
        onClose={() => onCancelSelect()}
        primaryAction={{
          content: "confirm",
          onAction: () => onSubmitSelect(),
          loading:selectCateLoading
        }}
        secondaryActions={[
          {
            content: "Skip",
            onAction: () => onCancelSelect(),
          },
        ]}
      >
        <Box className="select_cate_content">
          {warningMessage !== "" && (
            <Banner
              title="Error reminder"
              tone="critical"
              onDismiss={() => {
                setWarningMessage("");
              }}
            >
              {warningMessage}
            </Banner>
          )}
          
          <Box className="product_select_cate_box">
            {cateSelectData.map((item, ind) => (
              <Box className="product_select_cate_box_item" key={ind}>
                <InlineStack blockAlign='center' align='space-between'>
                <Box>
                    {item.category_name}
                </Box>
                <Checkbox
                  checked={item.select}
                  onChange={() => handleChangeSelect(item.category_id)}
                />
                </InlineStack>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
        </div>
    );
}

export default CateFirstSelect;
