import {
  Autocomplete,
  BlockStack,
  Box,
  Checkbox,
  Icon,
  InlineStack,
  Modal,
  Spinner,
  Tag,
  Text,
  Tooltip,
} from "@shopify/polaris";
import React, { useMemo, useState } from "react";
import {
  QuestionCircleIcon,
  RefreshIcon,
  DeleteIcon,
  PlusCircleIcon,
} from "@shopify/polaris-icons";
import _ from "lodash";
import { reqPostProductManagementProductsCollectionsSync } from "../../../../../api";

// 重置键值对
function modifiedFilter(data) {
  let modifiedData = [];
  if (data && data?.length > 0) {
    modifiedData = data.map((item) => ({
      label: item?.title ? item?.title : item?.label,
      value: item?.id ? item?.id : item?.value,
    }));
  }
  return modifiedData;
}
const Index = ({
  detailData,
  setDetailData,
  attributeData,
  setAttributeData,
  toastFun,
  setIsInput,
}) => {
  const [collectionValue, setCollectionValue] = useState(""); // Collections 输入值
  const [tagsValue, setTagsValue] = useState(""); // Tags 输入值
  const [productTypeValue, setProductTypeValue] = useState(""); // Product type 输入值
  const [loading, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  // Collections 下拉列表数据
  const collectionsList = useMemo(() => {
    if (collectionValue) {
      return modifiedFilter(attributeData.collection).filter((item) => {
        return (
          item.label.toLowerCase().indexOf(collectionValue.toLowerCase()) > -1
        );
      });
    }
    return modifiedFilter(attributeData.collection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionValue, detailData, attributeData]);
  const handleCollectionChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsInput(true);
    let res = await reqPostProductManagementProductsCollectionsSync();
    if (res.code === "200") {
      setAttributeData((prev) => ({
        ...prev,
        collection: res.data,
      }));
      setIsRefresh(true);
      toastFun("Update Success");
    }
    setLoading(false);
  };
  // 删除 Collection
  const removeCollection = (item) => {
    const selected = _.filter(detailData.product?.collections, (id) => {
      return id !== item.value;
    });
    setIsInput(true);
    setDetailData((preState) => {
      let currentData = preState.product;
      let param = { ...currentData, collections: selected };
      const params = { ...preState, product: param };
      return params;
    });
  };

  //删除tags
  const [tagDelete, setTagDelete] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleRemoveTags = async () => {
    setDeleteLoading(true);
    const tagsList = modifiedFilter(attributeData.tags);
    const selectTags = [...detailData.product?.tags];
    let deleteData = deleteSelected;
    let list1 = tagsList.filter((item) => !deleteData.includes(item.value));
    let list2 = selectTags.filter((item) => !deleteData.includes(item));

    setIsInput(true);
    setTimeout(() => {
      setAttributeData((prev) => ({
        ...prev,
        tags: list1,
      }));
      setDetailData((preState) => {
        let currentData = preState.product;
        let param = { ...currentData, tags: list2 };
        const params = { ...preState, product: param, delTag: deleteData };
        return params;
      });
      setDeleteSelected([]);
      setTagDelete(false);
      toastFun("Deleted Successfully");
      setDeleteLoading(false);
    }, 2000);
  };
  const onChangeRemoveTags = (selectedItems) => {
    let selected = [];
    deleteSelected.map((item) => {
      selected.push(item);
      return item;
    });
    let flag = false;
    for (let i = 0; i < selected.length; i++) {
      if (selected[i] === selectedItems) {
        flag = true;
      }
    }
    if (flag) {
      selected = selected.filter((item) => item !== selectedItems);
    } else {
      selected.push(selectedItems);
    }
    setIsInput(true);
    setDeleteSelected(selected);
  };

  // Tags 下拉列表数据
  const tagsList = useMemo(() => {
    if (tagsValue) {
      return modifiedFilter(attributeData.tags).filter((item) => {
        return item.label.toLowerCase().indexOf(tagsValue.toLowerCase()) > -1;
      });
    }
    return modifiedFilter(attributeData.tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsValue, attributeData]);
  const [flagTags, setFlagTags] = useState(false);
  // 添加 tag
  const addTag = async () => {
    const tagsList = modifiedFilter(attributeData.tags);
    const selectTags = [...detailData.product?.tags];
    if (flagTags) {
      return;
    }
    tagsList.push({
      label: tagsValue,
      value: tagsValue,
    });
    selectTags.push(tagsValue);
    setAttributeData((prev) => ({
      ...prev,
      tags: tagsList,
    }));
    setDetailData((preState) => {
      let currentData = preState.product;
      let param = { ...currentData, tags: selectTags };
      const params = { ...preState, product: param };
      return params;
    });

    setTagsValue("");
  };
  // 删除 Tag
  const removeTag = async (item) => {
    const selected = _.filter(detailData.product?.tags, (id) => {
      return id !== item.value;
    });
    setIsInput(true);
    setDetailData((preState) => {
      let currentData = preState.product;
      let param = { ...currentData, tags: selected };
      const params = { ...preState, product: param };
      return params;
    });
  };

  // ProductType 下拉列表数据
  const productTypeList = useMemo(() => {
    if (productTypeValue) {
      return modifiedFilter(attributeData.type).filter((item) => {
        return (
          item.label.toLowerCase().indexOf(productTypeValue.toLowerCase()) > -1
        );
      });
    }
    return modifiedFilter(attributeData.type)
      ? modifiedFilter(attributeData.type)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTypeValue, attributeData]);
  const [flagType, setFlagType] = useState(false);
  // 添加 Product type
  const addProductType = async () => {
    if (flagType) {
      return;
    }
    const productTypeList = modifiedFilter(attributeData.type);
    productTypeList.push({
      label: productTypeValue,
      value: productTypeValue,
    });
    setAttributeData((prev) => ({
      ...prev,
      type: productTypeList,
    }));
    setIsInput(true);
    setDetailData((preState) => {
      let currentData = preState.product;
      let param = { ...currentData, product_type: [productTypeValue] };
      const params = { ...preState, product: param };
      return params;
    });
    setProductTypeValue("");
  };

  // 删除 Product Type
  const removeProductType = (item) => {
    const selected = _.filter(detailData.product?.product_type, (id) => {
      return id !== item.value;
    });
    setIsInput(true);
    setDetailData((preState) => {
      let currentData = preState.product;
      let param = { ...currentData, product_type: selected };
      const params = { ...preState, product: param };
      return params;
    });
  };

  //删除product type
  const [typeDelete, setTypeDelete] = useState(false);
  const [deleteTypeSelected, setDeleteTypeSelected] = useState([]);
  const [deleteTypeLoading, setDeleteTypeLoading] = useState(false);

  const handleRemoveType = async () => {
    setDeleteTypeLoading(true);
    const productTypeList = modifiedFilter(attributeData.type);
    let deleteData = deleteTypeSelected;
    let list1 = productTypeList.filter(
      (item) => !deleteData.includes(item.value)
    );

    setTimeout(() => {
      setAttributeData((prev) => ({
        ...prev,
        type: list1,
      }));
      setIsInput(true);
      setDetailData((preState) => {
        let currentData = preState.product;

        let param = {
          ...currentData,
          product_type: deleteData.includes(currentData.product_type[0])
            ? []
            : currentData.product_type,
        };
        const params = { ...preState, product: param, delType: deleteData };
        return params;
      });
      setTypeDelete(false);
      setDeleteTypeLoading(false);
      setDeleteTypeSelected([]);
      toastFun("Deleted Successfully");
      setDeleteTypeLoading(false);
    }, 2000);
  };
  const onChangeRemoveType = (selectedItems) => {
    let selected = [];
    deleteTypeSelected.map((item) => {
      selected.push(item);
      return item;
    });
    let flag = false;
    for (let i = 0; i < selected.length; i++) {
      if (selected[i] === selectedItems) {
        flag = true;
      }
    }
    if (flag) {
      selected = selected.filter((item) => item !== selectedItems);
    } else {
      selected.push(selectedItems);
    }
    setIsInput(true);
    setDeleteTypeSelected(selected);
  };
  return (
    <Box>
      <Text variant="headingSm" as="h6">
        Product Organization
      </Text>
      <Box style={{ marginTop: "16px" }}>
        <BlockStack gap={300}>
          <Box>
            <div
              className="collection-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span>{"Product type"}</span>
              {productTypeList.length > 0 && !detailData.product?.is_publish && (
                <span
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    setTypeDelete(!typeDelete);
                  }}
                >
                  <Icon source={DeleteIcon} tone="base" />
                </span>
              )}
            </div>
            <BlockStack gap={100}>
              <Autocomplete
                options={productTypeList}
                selected={
                  detailData.product?.product_type
                    ? detailData.product?.product_type
                    : []
                }
                onSelect={(options) => {
                  setIsInput(true);
                  setDetailData((preState) => {
                    let currentData = preState.product;
                    let param = { ...currentData, product_type: options };
                    const params = { ...preState, product: param };
                    return params;
                  });
                }}
                textField={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Autocomplete.TextField
                        onChange={(value) => {
                          if (value.length > 50) {
                            return;
                          }
                          setProductTypeValue(value);
                          const productTypeList =
                            attributeData.type.length > 0
                              ? modifiedFilter(attributeData.type)
                              : [];
                          let flag = false;
                          for (let i = 0; i < productTypeList.length; i++) {
                            if (productTypeList[i].value === value) {
                              flag = true;
                            }
                          }
                          if (flag) {
                            setFlagType(true);
                          } else {
                            setFlagType(false);
                          }
                        }}
                        // label={

                        // }
                        value={productTypeValue}
                        placeholder={"Search product type"}
                        disabled={detailData?.product?.is_publish}
                      />
                    </div>
                  </div>
                }
                actionBefore={
                  productTypeValue &&
                  !flagType && {
                    accessibilityLabel: "",
                    badge: {
                      tone: "new",
                      content: "New!",
                    },
                    content: "Add " + productTypeValue,
                    helpText: "",
                    icon: PlusCircleIcon,
                    wrapOverflow: true,
                    onAction: () => addProductType(),
                  }
                }
              />
              <InlineStack wrap="wrap" gap={100}>
                {_.map(modifiedFilter(attributeData.type), (item) => {
                  if (
                    _.includes(detailData.product?.product_type, item.value)
                  ) {
                    return (
                      <div
                        key={item.value}
                        className="inline-block mr-3 current_span"
                      >
                        <Tag
                          onRemove={() => {
                            removeProductType(item);
                            setProductTypeValue("");
                          }}
                          // disabled={data?.status}
                        >
                          {item.label}
                        </Tag>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </InlineStack>
            </BlockStack>
          </Box>
          <div>
            <div className="collection-label">
              <Box style={{ display: "flex", alignItems: "center" }}>
                <span>{"Collections"}</span>
                <div style={{ width: "1.6rem" }}>
                  <Tooltip
                    content={
                      "To create a new collection, go to Shopify Store > Products > Collections > Create Collections, then refresh this page"
                    }
                  >
                    <Icon source={QuestionCircleIcon} backdrop={true} />
                  </Tooltip>
                </div>
              </Box>
              <Tooltip content={isRefresh ? "Helper text：“Collection refreshed" : "Click to update collection."}>
                {!detailData.product?.is_publish && (
                  <div style={{ cursor: "pointer" }}>
                    {loading ? (
                      <span
                        className="chat_gpt_modal_item_spans"
                        style={{ marginTop: 6, width: 30 }}
                      >
                        <Spinner size="small" />
                      </span>
                    ) : (
                      <span
                        className="chat_gpt_modal_item_span"
                        style={{ marginTop: 6, width: 30 }}
                        onClick={(e) =>{ 
                          if (detailData?.product?.is_publish) {
                          return
                        } 
                        handleCollectionChange(e)
                      }}
                      >
                        <Icon source={RefreshIcon} tone="base" />
                      </span>
                    )}
                  </div>
                )}
              </Tooltip>
            </div>
            <Autocomplete
              listTitle={"Collections"}
              allowMultiple
              options={collectionsList}
              selected={detailData.product?.collections}
              onSelect={(options) => {
                setDetailData((preState) => {
                  setIsInput(true);
                  let currentData = preState.product;
                  let param = { ...currentData, collections: options };
                  const params = { ...preState, product: param };
                  return params;
                });
              }}
              textField={
                <Autocomplete.TextField
                  value={collectionValue}
                  placeholder={"Search Collections"}
                  disabled={detailData?.product?.is_publish}
                  onChange={(value) => {
                    if (value.length > 50) {
                      return;
                    }
                    setCollectionValue(value);
                  }}
                />
              }
              emptyState={
                <div style={{ textAlign: "left", marginLeft: "16px" }}>
                  {"there is no collections to select."}
                </div>
              }
            />
            <InlineStack wrap="wrap" gap={100}>
              {_.map(modifiedFilter(attributeData.collection), (item) => {
                if (_.includes(detailData.product?.collections, item.value)) {
                  return (
                    <div
                      key={item.value}
                      className="inline-block mr-3 current_span"
                    >
                      <Tag
                        onRemove={() => {
                          removeCollection(item);
                          setCollectionValue("");
                        }}
                        // disabled={data?.status}
                      >
                        {item.label}
                      </Tag>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </InlineStack>
          </div>

          <Box>
            <div
              className="collection-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span>{"Tags"}</span>
              {tagsList.length > 0 && !detailData.product?.is_publish && (
                <span
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    setTagDelete(!tagDelete);
                  }}
                >
                   
                  <Icon source={DeleteIcon} tone="base" />
                </span>
              )}
            </div>
            <BlockStack gap={100}>
              <Autocomplete
                listTitle={"Suggested Tags"}
                allowMultiple
                options={tagsList}
                selected={detailData.product?.tags}
                onSelect={(options) => {
                  setIsInput(true);
                  setDetailData((preState) => {
                    let currentData = preState.product;
                    let param = { ...currentData, tags: options };
                    const params = { ...preState, product: param };
                    return params;
                  });
                }}
                textField={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Autocomplete.TextField
                        onChange={(value) => {
                          if (value.length > 50) {
                            return;
                          }
                          setTagsValue(value.trim());
                          const tagsList = modifiedFilter(attributeData.tags);
                          let flag = false;
                          for (let i = 0; i < tagsList.length; i++) {
                            if (tagsList[i].value === value) {
                              flag = true;
                            }
                          }
                          if (flag) {
                            setFlagTags(true);
                          } else {
                            setFlagTags(false);
                          }
                        }}
                        value={tagsValue}
                        placeholder={""}
                        disabled={detailData?.product?.is_publish}
                      />
                    </div>
                  </div>
                }
                actionBefore={
                  tagsValue &&
                  !flagTags && {
                    accessibilityLabel: "",
                    badge: {
                      tone: "new",
                      content: "New!",
                    },
                    content: "Add " + tagsValue,
                    helpText: "",
                    icon: PlusCircleIcon,
                    wrapOverflow: true,
                    onAction: () => addTag(),
                  }
                }
              />
              <InlineStack wrap="wrap" gap={100}>
                {_.map(modifiedFilter(attributeData.tags), (item) => {
                  if (_.includes(detailData.product?.tags, item.value)) {
                    return (
                      <div
                        key={item.value}
                        className="inline-block mr-3 mb-2 current_span"
                      >
                        <Tag
                          onRemove={() => {
                            removeTag(item);
                            setTagsValue("");
                          }}
                          // disabled={data?.status}
                        >
                          {item.label}
                        </Tag>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </InlineStack>
            </BlockStack>
          </Box>
        </BlockStack>
      </Box>

      <Modal
        open={tagDelete}
        onClose={() => {
          setTagDelete(false);
          setDeleteLoading(false);
          setDeleteSelected([]);
        }}
        title={"Delete tags"}
        primaryAction={{
          content: "Delete",
          loading: deleteLoading,
          onAction: () => {
            handleRemoveTags();
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setTagDelete(false);
              setDeleteLoading(false);
              setDeleteSelected([]);
            },
          },
        ]}
      >
        <Modal.Section>
          {tagsList.map((item, ind) => (
            <div key={ind} className="tag_list_product_item">
              <Checkbox
                label={item.label}
                checked={deleteSelected.includes(item.value)}
                onChange={() => {
                  onChangeRemoveTags(item.value);
                }}
              />
            </div>
          ))}
        </Modal.Section>
      </Modal>
      <Modal
        open={typeDelete}
        onClose={() => {
          setTypeDelete(false);
          setDeleteTypeLoading(false);
          setDeleteTypeSelected([]);
        }}
        title={"Delete Product type"}
        primaryAction={{
          content: "Delete",
          loading: deleteTypeLoading,
          onAction: () => {
            handleRemoveType();
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setTypeDelete(false);
              setDeleteTypeLoading(false);
              setDeleteTypeSelected([]);
            },
          },
        ]}
      >
        <Modal.Section>
          {productTypeList.map((item, ind) => (
            <div key={ind} className="tag_list_product_item">
              <Checkbox
                label={item.label}
                checked={deleteTypeSelected.includes(item.value)}
                onChange={() => {
                  onChangeRemoveType(item.value);
                }}
              />
            </div>
          ))}
        </Modal.Section>
      </Modal>
    </Box>
  );
};

export default Index;
