import React, { Component } from 'react';

import { 
  Toast, Modal,Select, RadioButton, Icon
  // FormLayout
} from "@shopify/polaris";

import shippingCountryArray from '../../../../../../assets/CountryArray';

import { 
  reqPostFindProductDetailsFreight
} from '../../../../../../api';

import {
  ChevronDownIcon
} from '@shopify/polaris-icons';

import "./index.css";

import intl from '../../../../../../i18n/intl';

class ShippingMethodsModal extends Component {
  state = {
    show: this.props.show,
    shippingToCountryCode: this.props.countryCode,
    searchingShip: '0',
    isFirstSearch: true,
    shippingCountryConfigArray: [{"label":intl.get("Please select the country"),"value":""}],

    // 物流数组
    shippingMethodTableData: [],
    shippingTable: '',

    loadingPage: true,

    // 默认显示第一个物流方式
    selectedCountryName: '',
    selectedShippingCost: 0,
    selectedShippingMethod: '',
    selectedEstimatedTime: '',
  }

  // 组件渲染完成，再次修改渲染内容
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show,
      // shippingToCountryCode: nextProps.countryCode,
      searchingShip: '0',
      isFirstSearch: false,
      // 物流数组
      // shippingMethodTableData: [],
      // shippingTable: '',
    });
  }

  //进入页面调用接口
  componentDidMount() {
    // shipping to 数组追加一个选项
    let shippingToArr = this.state.shippingCountryConfigArray;
    let countryName = '';
    for(let i=0; i<shippingCountryArray.length; i++){
      shippingToArr.push({"label":shippingCountryArray[i].label,"value":shippingCountryArray[i].value});
      if(this.props.countryCode === shippingCountryArray[i].value){
        countryName = shippingCountryArray[i].label;
      }
    }

    this.setState({
      shippingCountryConfigArray: shippingToArr,
      shippingToCountryCode: this.props.countryCode,
      selectedCountryName: countryName
    },()=>{
      this.getShippingCosts();
    });
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror=false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };

  // 关闭弹窗
  handleShippingModal =()=>{
    this.setState({
      show: !this.state.show
    });
  }

  // 国家切换
  handleSelectChange =(value,id)=>{
    this.setState({
      [id]: value
    },()=>{
      this.getShippingCosts();

      // 获取国家名称
      setTimeout(()=>{
        let countryName = '';
        for(let i=0; i<shippingCountryArray.length; i++){
          if(value === shippingCountryArray[i].value){
            countryName = shippingCountryArray[i].label;
            break;
          }
        }
        this.setState({
          selectedCountryName: countryName
        })
      },500);
    });
  }

  // 根据 [收货国家] 相应的物流配送信息
  getShippingCosts = async() =>{
    if(this.state.shippingToCountryCode === '' || this.state.shippingToCountryCode === undefined){
      return;
    }

    this.setState({
      searchingShip: '1'
    });
    const params = {
      pid:this.props.pid,
      vid_arr:this.props.vidArr,
      // type:this.props.onebound ? this.props.onebound : 'detail',
      receiving_country: this.state.shippingToCountryCode,
    };
    // console.info(params);
    let apiRequest = reqPostFindProductDetailsFreight;
    // if(this.props.public){  // 对外开放的产品详情页面请求
    //   apiRequest = reqPostDropshipmanProductShipping;
    // } else{
    // }

    const response = await apiRequest(params);
    
    this.setState({
      searchingShip: '0',
      isFirstSearch: false,
      loadingPage: false,
    });
    if (response.code==='200') {
      this.setState({
        shippingMethodTableData: response.data
      },()=>{
        this.updateShippingTable();
      });

      // this.toggleToast(response.msg,false);
    }else{
      this.toggleToast(response.msg);
    }
  }

  // 处理选择物流方式
  handleSelectShippingMethod = (recordKey) =>{
    let _tableData = this.state.shippingMethodTableData;
    for(let i=0; i< _tableData.length; i++){
      if(i === recordKey){
        _tableData[i].selected = 1;
      }else{
        _tableData[i].selected = 0;
      }
    }
    this.setState({
      shippingMethodTableData: _tableData,
     
    },()=>{
      this.updateShippingTableShow()
    });
  }

  // 生成物流列表
  // 生成物流列表
  // 显示
  updateShippingTableShow(){
    let _shippingTableData = this.state.shippingMethodTableData;
    let shippingMethodTable = '';
    let shippingTrTow = '';

    if(_shippingTableData.length>0){
      shippingTrTow = _shippingTableData.map((item,key) => {

        let shippingTrHtml = '';
        shippingTrHtml = <tr key={key}>
          <td>
            <RadioButton
              label={item.ShippingMethod}
              checked={item.selected}
              name="shipping_method"
              onChange={this.handleSelectShippingMethod.bind(this,key)}
            />
          </td>
          <td>{item.DeliveryTime}</td>
          <td>US${item.BeforeConvertCost} {item.currency_code}</td>
        </tr>

        return (
          shippingTrHtml
        );
      });

      shippingMethodTable = (
          <table style={{width:'100%',marginTop:'1rem'}}>
            <thead>
              <tr style={{background: '#f5f5f5'}}>
                <th className="table_th">{intl.get("Shipping Method")}</th>
                <th className="table_th">{intl.get("Estimated Delivery Time")}</th>
                <th className="table_th">{intl.get("Shipping Cost")}</th>
              </tr>
            </thead>

            <tbody>
              {shippingTrTow}
            </tbody>
          </table>
        )
    }else{
      if(this.state.searchingShip==='0'){
        shippingMethodTable = <div className="shipping_none">{intl.get("This Supplier/Shipping Company does not deliver to your selected Country/Region.")}</div>
      }
    }

    this.setState({
      shippingTable: shippingMethodTable,
    });
  }
  updateShippingTable(){
    let _shippingTableData = this.state.shippingMethodTableData;
    let shippingMethodTable = '';
    let shippingTrTow = '';
    
    let _selectedShippingCost = 0;
    let _selectedShippingMethod = '';
    let _selectedEstimatedTime = '5 - 20 days';

    if(_shippingTableData.length>0){
      shippingTrTow = _shippingTableData.map((item,key) => {
        if (item.selected === 1) {
          _selectedShippingCost = item.BeforeConvertCost;
          _selectedShippingMethod = item.ShippingMethod;
          _selectedEstimatedTime = item.DeliveryTime;
        }

        let shippingTrHtml = '';
        shippingTrHtml = <tr key={key}>
          <td>
            <RadioButton
              label={item.ShippingMethod}
              checked={item.selected}
              name="shipping_method"
              onChange={this.handleSelectShippingMethod.bind(this,key)}
            />
          </td>
          <td>{item.DeliveryTime}</td>
          <td>US${item.BeforeConvertCost} {item.currency_code}</td>
        </tr>

        return (
          shippingTrHtml
        );
      });

      shippingMethodTable = (
          <table style={{width:'100%',marginTop:'1rem'}}>
            <thead>
              <tr style={{background: '#f5f5f5'}}>
                <th className="table_th">{intl.get("Shipping Method")}</th>
                <th className="table_th">{intl.get("Estimated Delivery Time")}</th>
                <th className="table_th">{intl.get("Shipping Cost")}</th>
              </tr>
            </thead>

            <tbody>
              {shippingTrTow}
            </tbody>
          </table>
        )
    }else{
      if(this.state.searchingShip==='0'){
        shippingMethodTable = <div className="shipping_none">{intl.get("This Supplier/Shipping Company does not deliver to your selected Country/Region.")}</div>
      }
    }

    this.setState({
      shippingTable: shippingMethodTable,
      selectedShippingCost: _selectedShippingCost,
      selectedShippingMethod: _selectedShippingMethod,
      selectedEstimatedTime: _selectedEstimatedTime
    });
  }

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={()=>this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    const {
      show,shippingToCountryCode,searchingShip,isFirstSearch,shippingCountryConfigArray,shippingTable,shippingMethodTableData,
      selectedShippingCost,selectedShippingMethod,selectedEstimatedTime,
      // selectedCountryName
    } = this.state;

    const ShippingModal = (
      <Modal
        open={show}
        onClose={this.handleShippingModal}
        title={intl.get("Shipping methods")}
        primaryAction={
          {
            content: intl.get("Done"),
            onAction: ()=>{
              this.updateShippingTable()
              this.handleShippingModal()
            },
          }
        }
        large={true}
      >
        <div style={{padding:'0 2rem',minHeight:'350px'}}>
          {/* <div>
            <FormLayout>
              <FormLayout.Group>
                <div>
                  <Select
                    id="shippingToCountryCode"
                    label={intl.get("Ship to")}
                    options={shippingCountryConfigArray}
                    onChange={this.handleSelectChange}
                    value={shippingToCountryCode}
                  />
                </div>
                <div className="shipping_search">
                  <img style={{width:'20px',height:'20px',verticalAlign:'middle',opacity: (searchingShip==='1' && isFirstSearch)?'1':'0'}} src='/loading.gif' alt="loading" />
                </div>
              </FormLayout.Group>
            </FormLayout> 
          </div> */}

          {/* Shipping选项table */}
          <div className="Prodcut_Shipping_Table" style={{position:'relative'}}>
            
            {shippingTable}

            <div className="Product__Loading" style={{display: (searchingShip==='1' && isFirstSearch===false)?'block':'none'}}>
              <div className="Product__Loading_Image" style={{top:'50%'}}>
                <img style={{width:'20px',height:'20px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />
              </div>
            </div>
          </div>

        </div>
      </Modal>
    )

    let showShippingContent = '';
    if (shippingMethodTableData.length > 0) {
      showShippingContent = (
        <div className='Show_Shipping_Result' onClick={this.handleShippingModal} style={{position:'relative', pointerEvents: searchingShip ==='1' ? 'none' : 'unset'}}>
          <div className='item'>
            <div className='option'>{intl.get('Shipping fee: US $') + " " + selectedShippingCost}</div>
            <div className='option'>{intl.get('Estimated delivery:')+ " "  + selectedEstimatedTime}</div>
            <div className='option'>{intl.get('Shipping method:') + " " + selectedShippingMethod}</div>
          </div>
          <div className='sign'>
            <Icon source={ChevronDownIcon} />
          </div>

          <div className="Product__Loading" style={{display: (searchingShip ==='1' && isFirstSearch === false) ? 'block':'none'}}>
            <div className="Product__Loading_Image" style={{top:'40%'}}>
              <img style={{width:'20px',height:'20px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />
            </div>
          </div>
        </div>
      )
    } else {
      showShippingContent = (
        <div className='Show_Shipping_Result_Empty' style={{position:'relative'}}>

          <div className='box'>
            <div className='img'><img src='/warn.png' alt='' /></div>
            <div>
              {intl.get('There are no supported shipping methods in the country you have selected.')}
            </div>
          </div>
          
          <div className="Product__Loading" style={{display: (searchingShip ==='1' && isFirstSearch === false) ? 'block':'none'}}>
            <div className="Product__Loading_Image" style={{top:'40%'}}>
              <img style={{width:'20px',height:'20px',verticalAlign:'middle'}} src='/loading.gif' alt="loading" />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="shipping_button Product_Detail_Shipping">
          { this.state.loadingPage ? (
            <div className="shipping_search">
              <img style={{width:'20px',height:'20px',verticalAlign:'middle',opacity: '1'}} src='/loading.gif' alt="loading" />
            </div>
            ) : (
              <div>
                <div className='Select_Shipping_Country'>
                  <div className='location'>
                    <img src='/location.png' alt='' />
                  </div>
                  <div className='select'>
                    <Select
                      id="shippingToCountryCode"
                      label={intl.get("Ship to")}
                      labelHidden
                      options={shippingCountryConfigArray}
                      onChange={this.handleSelectChange}
                      value={shippingToCountryCode}
                    />
                  </div>
                </div>

                {showShippingContent}

              </div>
            )
          }
        </div>
        
        {ShippingModal}
        {toastMarkup}
      </div>
    );
    
  }
}

export default ShippingMethodsModal;
