import { Modal } from '@shopify/polaris';
import React  from 'react';
import intl from "../../../../../i18n/intl";
import { getGetSourcingListCjDelete } from '../../../../../api';
const DeleteSourcingModal = ({deleteSourcingModal,setDeleteSourcingModal,getListData,toastFun,handleSelectionChange})=> {
    const handleBulkOperationModal = ()=>{
        setDeleteSourcingModal((prevValue)=>({
            ...prevValue,
            open: false,
            loading:false
        }))
    }
    const handleBulkOperationDone = async()=>{
        let params = {
            platform:deleteSourcingModal.platform,
            ids:deleteSourcingModal.id
         };
         setDeleteSourcingModal((prevValue)=>({
            ...prevValue,
            loading:true
        }))
         try {
            const response = await getGetSourcingListCjDelete(params);
         if (response.code === "200") {
            toastFun('Deleted successfully', false);
            // 重新获取数据
            getListData(true);
            handleSelectionChange('page',false,0)
          } else {
            
            toastFun(response.msg);
          }
          handleBulkOperationModal('closed');
         } catch (error) {
            handleBulkOperationModal('closed');
         }
         
    }
    return (
            <Modal
            open={deleteSourcingModal.open}
            onClose={() => handleBulkOperationModal()}
            title={intl.get('Delete sourcing request')}
            primaryAction={{
            content: intl.get("Done"),
            onAction: handleBulkOperationDone,
            disabled: (deleteSourcingModal.count > 0) ? false : true,
            loading: deleteSourcingModal.loading
            }}
            secondaryActions={[{
            content: intl.get("Cancel"),
            onAction: () => handleBulkOperationModal()
            }]}
        >
            <Modal.Section>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                {deleteSourcingModal.count} record will be deleted.
                </p>
            
            </Modal.Section>
        </Modal>
    );
}

export default DeleteSourcingModal;
