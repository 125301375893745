import { Layout, Box, Card, Tabs, Scrollable, InlineStack, EmptyState, Spinner } from "@shopify/polaris";
import React  from "react";
import StartRating from "stars-rating";
import AppBridge from "../../../../../utils/appBridge";
import { reqGetProductSelectionReviewsDetail } from "../../../../../api";
import InfiniteScroll from "react-infinite-scroll-component";
const DescriptReview = ({ 
  reviewData,
  pageData,
  reviewParams,
  setReviewParams,
  setReviewData,
  more,
  setMore
 }) => {
  const tabs1 = [
    {
      id: "all-customers-1",
      content: "Description ",
      accessibilityLabel: "All customers",
      panelID: "all-customers-content-1",
    },
  ];
  const tabs2 = [
    {
      id: "all-customers-1",
      content: reviewData.count > 0 ? "Buyer Review ("+reviewData.count+")" : "Buyer Review ("+0+")",
      accessibilityLabel: "All customers",
      panelID: "all-customers-content-1",
    },
  ];

   
      // 滚动加载
    const fetchMoreData = () => {
      let params = { ...reviewParams, page: reviewParams.page + 1 };
     
      if (reviewData.list.length === reviewData.count) {
        setMore(false)
        return
      }
      setTimeout(async () => {
        const res = await reqGetProductSelectionReviewsDetail(params);
        if (res.code === "200") {
          setReviewData((preValue) => ({
            ...preValue,
            list: preValue.list.concat(res.data.list),
          }));
          setReviewParams((preValue) => ({
            ...preValue,
            page: preValue.page + 1,
          }));
          if (res.data.list.length === 0 || res.data.list.length  === res.data.count) {
            setMore(false);
          }
        } else {
          setMore(false);
        }
      }, 1500);
    };
  return (
    <Box className={AppBridge.exist()? "product_review_box_iframe" : "product_review_box"}>
      <Layout>
        <Layout.Section>
          <Card padding={0}>
            <Tabs tabs={tabs1} selected={0}>
              {/* <Scrollable style={{ height: "540px", background: "#fff" }}> */}
                <p
                  className="descript_p"
                  dangerouslySetInnerHTML={{ __html: pageData.description }}
                ></p>
              {/* </Scrollable> */}
            </Tabs>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <Card padding={0}>
            <Tabs tabs={tabs2} selected={0}>
              <Scrollable style={{ height: "540px", background: "#fff" }} id="scrollableDiv2">
                 <InfiniteScroll
                  dataLength={reviewData.list.length}
                  next={(e) => fetchMoreData()}
                  hasMore={more}
                  scrollableTarget="scrollableDiv2"
                  loader={more && <h4 style={{textAlign:'center'}}><Spinner accessibilityLabel="Small spinner example" size="small" /></h4>}
                  refreshFunction={(e) => fetchMoreData()}
                  pullDownToRefresh
              >
              {reviewData.list.length > 0 ? <Box className="product_review">
                  {/* {reviewData.list.length > 0 && <Box className="agent_content_box_rating">
                    <StartRating
                      value={5}
                      color1="rgba(253, 173, 32, 1)"
                      color2="rgba(253, 176, 34, 1)"
                      half={true}
                      count={1}
                      edit={false}
                      size={16}
                    />
                    <span
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {pageData?.rating}/5.0 ({pageData?.review_count})
                    </span>
                  </Box>} */}
                  {reviewData.list.map((item,ind)=><Box className="product_review_item" key={ind}>
                      <InlineStack wrap={false} gap={100}>
                        <Box className="product_review_item_avt">
                          {item?.commentUser[0]}
                        </Box>
                        <Box  className="product_review_item_box">
                            <Box  className="product_review_item_name">
                                <InlineStack wrap={false} gap={100}>
                                <strong>{item?.commentUser}</strong>
                                <StartRating
                                value={item?.score}
                                color1="rgba(253, 173, 32, 1)"
                                color2="rgba(253, 176, 34, 1)"
                                half={true}
                                count={5}
                                edit={false}
                                size={16}
                                />
                                <span
                                style={{
                                    fontSize: 14,
                                }}
                                ></span>
                                </InlineStack>
                            </Box>
                            <Box className="product_review_item_day">
                                {item?.commentDate}
                            </Box>
                        </Box>
                        </InlineStack>
                      <Box className="product_review_item_desc">
                        <p>{item?.comment}</p>
                      </Box>
                  </Box>)}
                </Box>:
                <EmptyState
                heading="No review"
                image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
              >
               
              </EmptyState>}
              </InfiniteScroll>
              </Scrollable>
            </Tabs>
          </Card>
        </Layout.Section>
      </Layout>
    </Box>
  );
};


export default DescriptReview;
