import React from 'react';

const Home =()=> {
        return (
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.45424 2.67488C4.63793 0.308367 8.21606 0.308367 10.3998 2.67488L11 3.32535L11.6002 2.67488C13.7839 0.308367 17.362 0.308367 19.5457 2.67488C21.6847 4.99297 21.6847 8.71905 19.5457 11.0371L12.2861 18.9045C11.5931 19.6555 10.4068 19.6555 9.71386 18.9045L2.45424 11.0371C0.315221 8.71905 0.315221 4.99297 2.45424 2.67488Z" fill="#E3E3E3"/>
            </svg>
        )
    }
export default Home;
