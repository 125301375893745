import dashboard from '../frontEnd/pt-pt/dashboard.json'
import findProducts from '../frontEnd/pt-pt/findProducts.json'
import common from '../frontEnd/pt-pt/common.json'
import frameClass from '../frontEnd/pt-pt/frameClass.json'
import sourcing from '../frontEnd/pt-pt/sourcing.json'
import listProduct from '../frontEnd/pt-pt/listProduct.json'
import orders from '../frontEnd/pt-pt/orders.json'
import customService from '../frontEnd/pt-pt/customService.json'
import siteOptimization from '../frontEnd/pt-pt/siteOptimization.json'
import subscribe from '../frontEnd/pt-pt/subscribe.json'
import setting from '../frontEnd/pt-pt/setting.json'
import shoppingCart from '../frontEnd/pt-pt/shoppingCart.json'
import message from '../frontEnd/pt-pt/message.json'
import partnership from '../frontEnd/pt-pt/partnership.json'
const pt =  {
    ...dashboard,
    ...findProducts,
    ...common,
    ...frameClass,
    ...sourcing,
    ...listProduct,
    ...orders,
    ...customService,
    ...siteOptimization,
    ...subscribe,
    ...setting,
    ...shoppingCart,
    ...message,
    ...partnership
};
export default pt;