import React, { Component } from 'react';

import {
  Page, Card, Layout, FormLayout, Button, BlockStack, DatePicker, Popover, DataTable, Toast, SkeletonBodyText, EmptyState,Tabs
} from "@shopify/polaris";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  PieChart, Pie, Cell,
  ResponsiveContainer
} from "recharts";

import {
  CalendarIcon
} from '@shopify/polaris-icons';

import '../index.css';
import {
  reqGetAppProductsImportData,
} from '../../../../api';
import utils from "../../../../utils/utils";
import intl from '../../../../i18n/intl'
import {
  withRouter
} from 'react-router-dom';

class ProductsImport extends Component {

  state = {
    username: "", //用户名称
    //无法连续重复设置
    setDisabled: false,
    loadingPage: true,
    //时间筛选
    month: new Date().getMonth() - 1,
    year: new Date().getFullYear(),
    selectedDates: {
      start: new Date(new Date() - 29 * 86400 * 1000),
      end: new Date()
    },
    //时间弹窗
    popoverActive: false,
    //天 小时 互换弹窗
    popoverActiveChoiceList: false,
    //选择天 小时
    selectedChoiceList: ["Day"],
    //搜索日期按钮
    searchButtonLoading: false,
    // 导入
    import: null,
    reports: null,
    rows: null,
    type: 1,
    // 图表key值
    random: 0,
    sort: 'ASC',
    selected:0,
  };

  //进入页面调用接口
  componentDidMount() {

    this.GetAppAnalysisData(0);
    this.initData();
  }

  //获取数据接口
  GetAppAnalysisData = async (requestStatus) => {
    if (requestStatus === 1 || requestStatus === 2) {
      this.setState({
        searchButtonLoading: true,
      });
    }
    let id = 0
    switch (window.location.pathname) {
      case '/admin/reports_details/products_import':
        id = 1
        this.setState({
          type: 1,
          title: intl.get('Import products')
        })
        break;
      case '/admin/reports_details/products_sales':
        id = 2
        this.setState({
          type: 2,
          title: intl.get('Total sales')
        })
        break;
      case '/admin/reports_details/products_orders':
        id = 3
        this.setState({
          type: 3,
          title: intl.get('Total orders')
        })
        break;
      case '/admin/reports_details/products_sellings':
        id = 4
        this.setState({
          type: 4,
          title: intl.get('Top selling items')
        })
        break;
      default:
        id = 1
        break;
    }
    const params = {
      start: (new Date(this.state.selectedDates.start).getFullYear()) + "/" + (new Date(this.state.selectedDates.start).getMonth() + 1) + "/" + (new Date(this.state.selectedDates.start).getDate()),
      end: (new Date(this.state.selectedDates.end).getFullYear()) + "/" + (new Date(this.state.selectedDates.end).getMonth() + 1) + "/" + (new Date(this.state.selectedDates.end).getDate()),
      day: this.state.sort,
      type: id,
      index:this.state.selected
    };
    const response = await reqGetAppProductsImportData(params)
    if (response.code === '200') {
      let list = response.data.showList.map((item) => {
        return Object.values(item)
      }
      )
      //表格数据
      setTimeout(() => {
        this.setState({
          loadingPage: false,
          reports: response.data,
          rows: list,
          searchButtonLoading: false,
          random: Math.random()
        });

      }, 200);

      if (requestStatus === 1) {
        // this.toggleToast('The request failed, no data was found in the current query period', true);
        this.togglePopoverActive();
      }
    } else {
      setTimeout(() => {
        this.setState({
          searchButtonLoading: false,
        });
      }, 2000);
      // this.toggleToast('The request failed, no data was found in the current query period', true);
    }
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror
    }));
  };


  initData = () => {
    let username = utils.getStore("S_SHOP_OWNER") || window.S_SHOP_OWNER;
    this.setState({ username: username });
  };

  // input change
  handleInputChange = async (value, id) => {
    this.setState({
      [id]: value,
    }, () => {
      // 页面列表搜索
      if (id === 'searchKeyword') {
        setTimeout(() => {
          this.getProductList(true);
        }, 800);
      } else {
        this.setState({
          searchWarning: false,
          searchWarningMessage: ''
        });
      }
    });
  }

  //时间插件(选择年份)
  handleMonthChange = (month, year) => {
    this.setState({
      month: month,
      year: year
    });
  }
  //时间插件(选择时间)
  setSelectedDates = (selectedDates) => {
    this.setState({
      selectedDates: selectedDates
    });
  }
  //时间弹窗
  togglePopoverActive = () => {
    this.setState({
      popoverActive: !this.state.popoverActive
    });
  }
  //天 小时 互换
  togglePopoverActiveChoiceList = () => {
    this.setState({
      popoverActiveChoiceList: !this.state.popoverActiveChoiceList
    });
  }
  handleSort = () => {
    switch (this.state.sort) {
      case 'ASC':
        this.setState({
          sort: 'DESC'
        }, () => {
          this.GetAppAnalysisData()
        })
        break;
      case 'DESC':
        this.setState({
          sort: 'ASC'
        }, () => {
          this.GetAppAnalysisData()
        })
        break;
      default:
        break;
    }
  }

  //顶部tabs切换事件
  handleTabChange = (index) => {
    this.setState({
      selected: index,
    },()=>{
      setTimeout(() => {
        this.GetAppAnalysisData()
      }, 600);
    });
  }

  render() {
    const { reports, rows, type, random, title, selected } = this.state
    //顶部tabs选项
    const TabSelectArray = [
      {
        id: "tab_Dropshipman",
        content:"Dropshipman" ,
        accessibilityLabel: "In processing",
      },
      {
        id: "tab_AliExpress",
        content:"AliExpress",
        accessibilityLabel: "Success sourcingSquare",
      }
    ];

    const data = [
      { name: "Dropshipman", value: reports?.dropshipman },
      { name: "AliExpress", value: reports?.aliexpress },
    ];
    const lists = reports?.showList && reports?.showList.map((item, index) => {
      return [
        <div className='selling_index'><span>{index + 1}</span></div>,
        <div className='selling_title'>
          <img src={item.imageUrl} alt={item.title} />
          <p>{item.title}</p>
        </div>,
        <div className='selling__supplier'>{item.isSupplier}</div>,
        <div className='selling_number'>{item.productNumber}</div>,
        <div className='selling_totalsales'>{item.productTotalSales}</div>,
      ]
    })
    // 类型判断与饼图颜色选择
    let currentContent = '';
    let COLORS = [];
    switch (type) {
      case 1:
        currentContent = (
          <Layout.Section>
            <Card>
              <DataTable
                columnContentTypes={[
                  "text",
                  "numeric",
                  "numeric",
                  "numeric",
                ]}
                headings={[
                  intl.get("Day"),
                  intl.get("Dropshipman products"),
                  intl.get("Aliexpress products"),
                  intl.get("Total products"),
                ]}
                rows={rows ? rows : [[1, 2, 3, 4]]}
                totals={reports ? Object.values(reports.head) : ['']}
                sortable={[true, false, false, false]}
                defaultSortDirection="descending"
                initialSortColumnIndex={0}
                onSort={this.handleSort}
                footerContent={`Showing ${rows ? rows.length : 1} of ${rows ? rows.length : 1} results`}
              />
            </Card>
          </Layout.Section>
        )
        COLORS = ['#23c2db', '#ffdc4c']
        break;
      case 2:
        currentContent = (
          <Layout.Section>
            <Card>
              <Tabs tabs={TabSelectArray} selected={selected} onSelect={this.handleTabChange} />
              <DataTable
                columnContentTypes={[
                  "text",
                  // "numeric",
                  // "numeric",
                  "numeric",
                  "numeric",
                  "numeric",
                ]}
                headings={[
                  intl.get("Day"),
                  // intl.get("Dropshipman"),
                  // intl.get("Aliexpress"),
                  intl.get("Total sales"),
                  intl.get("Total cost"),
                  intl.get("Total profit")
                ]}
                // rows={rows ? rows : [[1, 2, 3, 4, 5, 6]]}
                rows={rows ? rows : [[1, 2, 3, 4]]}
                totals={reports ? Object.values(reports.head) : ['']}
                // sortable={[true, false, false, false, false, false]}
                sortable={[true, false, false, false]}
                defaultSortDirection="descending"
                initialSortColumnIndex={0}
                onSort={this.handleSort}
                footerContent={`Showing ${rows ? rows.length : 1} of ${rows ? rows.length : 1} results`}
              />
            </Card>
          </Layout.Section>
        )
        COLORS = ['#ffdc4c', '#ff974c']
        break;
      case 3:
        currentContent = (
          <Layout.Section>
            <Card>
              <DataTable
                columnContentTypes={[
                  "text",
                  "numeric",
                  "numeric",
                  "numeric",
                ]}
                headings={[
                  intl.get("Day"),
                  intl.get("Dropshipman orders"),
                  intl.get("AliExpress orders"),
                  intl.get("Total orders"),
                ]}
                rows={rows ? rows : [[1, 2, 3, 4]]}
                totals={reports ? Object.values(reports.head) : ['']}
                sortable={[true, false, false, false]}
                defaultSortDirection="descending"
                initialSortColumnIndex={0}
                onSort={this.handleSort}
                footerContent={`Showing ${rows ? rows.length : 1} of ${rows ? rows.length : 1} results`}
              />
            </Card>
          </Layout.Section>
        )
        COLORS = ['#ffdc4c', '#2d8bfe']
        break;
      case 4:
        currentContent = (
          <div className='_reports_details_sellings'>
            <Layout.Section>
              <Card>
                <DataTable
                  columnContentTypes={[
                    "text",
                    "numeric",
                    "numeric",
                    "numeric",
                    "numeric",
                  ]}
                  headings={[
                    intl.get("Sorting"),
                    intl.get("Product"),
                    intl.get("Supplier"),
                    intl.get("Total orders"),
                    intl.get("Total sales")
                  ]}
                  rows={lists ? lists : [[1, 2, 3, 4, 5]]}
                  sortable={[false, false, false, false, false]}
                  defaultSortDirection="descending"
                  footerContent={`Showing ${rows ? rows.length : 1} of ${rows ? rows.length : 1} results`}
                />
              </Card>
            </Layout.Section>
          </div>
        )
        break;
      default:
    }
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      return (

        <text
          x={x}
          y={y}
          fill="#000000"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {index === 1 ? intl.get('AliExpress') + ' ' : intl.get('Dropshipman') + ' '}
          {`${(percent * 100).toFixed(2)}%`}{" "}
          {index === 1 ? reports?.aliexpress : reports?.dropshipman}
        </text>
      );
    };

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="product-tooltip">
            <p className="product_label">
              <span className='product_tip_day'>{label}</span>
              <span className='product_tip_num'>
                {payload[0].stroke === '#23c2db' && <span style={{
                  background: "#23c2db"
                }}>
                </span>
                }
                {payload[0].stroke === '#ff974c' && <span style={{
                  background: "#ff974c"
                }}>
                </span>
                }
                {payload[0].stroke === '#5aaef3' && <span style={{
                  background: "#5aaef3"
                }}>
                </span>
                } {payload[0].dataKey}: {payload[0].value}</span>
            </p>
          </div>
        );
      }

      return null;
    };

    const CustomTooltip2 = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        var percent = ((payload[0].value / (reports?.dropshipman + reports?.aliexpress) * 100).toFixed(2)) + '%'
        return (
          <div className="product-tooltip">
            <p className="product_label">
              <span className='product_tip_day'>{label}</span>
              <span className='product_tip_num'>
                <span style={{
                  background: payload[0].payload.fill
                }}>
                </span>
                {payload[0].name + ' ' + percent + ' ' + payload[0].value}</span>
            </p>
          </div>
        );
      }

      return null;
    };
    const initSkeletonPage = (
      <Page breadcrumbs={[{ 
        onAction: () => {
            utils.routeListen('/admin/analysis')
            utils.routeJump(this,'/admin/analysis')
        }
       }]}
        title={title}
        fullWidth
      >
        <Layout.Section>
          <BlockStack>
            <div style={{ marginTop: '0.8rem'}}>{intl.get("Date range")}</div>
            <Popover
              active={this.state.popoverActive}
              activator={
                <Button onClick={this.togglePopoverActive} icon={CalendarIcon}>
                  {(new Date(this.state.selectedDates.start).getFullYear()) + "/" + (new Date(this.state.selectedDates.start).getMonth() + 1) + "/" + (new Date(this.state.selectedDates.start).getDate())} - {(new Date(this.state.selectedDates.end).getFullYear()) + "/" + (new Date(this.state.selectedDates.end).getMonth() + 1) + "/" + (new Date(this.state.selectedDates.end).getDate())}
                </Button>
              }
              onClose={this.togglePopoverActive}
            >
              <Card
                sectioned
                primaryFooterAction={{
                  content: intl.get("Apply"),
                  onAction: () => this.GetAppAnalysisData(1),
                  loading: this.state.searchButtonLoading
                }}
              >
                <FormLayout>
                  <DatePicker 
                  month={this.state.month} 
                  year={this.state.year} 
                  onChange={this.setSelectedDates} 
                  onMonthChange={this.handleMonthChange} 
                  selected={this.state.selectedDates} 
                  allowRange={true} 
                  disableDatesAfter={new Date()}
                  disableDatesBefore={new Date(new Date() - 30 * 86400 * 1000)}
                  multiMonth={true} />
                </FormLayout>

              </Card>
            </Popover>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card sectioned >
            <SkeletonBodyText lines={5} />
          </Card>
          <Card sectioned >
            <SkeletonBodyText lines={5} />
          </Card>
        </Layout.Section>
      </Page>
    )

    const pageContent = this.state.loadingPage ? initSkeletonPage : (
      <Page breadcrumbs={[
        {  
        onAction: () => {
          utils.routeListen('/admin/analysis')
          utils.routeJump(this,'/admin/analysis')
      }}
      ]} title={title} fullWidth>
        <Layout.Section>
          <BlockStack>
            <div style={{ marginTop: '0.8rem', marginLeft: "-19px" }}>{intl.get("Date range")}</div>
            <Popover
              active={this.state.popoverActive}
              activator={
                <Button onClick={this.togglePopoverActive} icon={CalendarIcon}>
                  {(new Date(this.state.selectedDates.start).getFullYear()) + "/" + (new Date(this.state.selectedDates.start).getMonth() + 1) + "/" + (new Date(this.state.selectedDates.start).getDate())} - {(new Date(this.state.selectedDates.end).getFullYear()) + "/" + (new Date(this.state.selectedDates.end).getMonth() + 1) + "/" + (new Date(this.state.selectedDates.end).getDate())}
                </Button>
              }
              onClose={this.togglePopoverActive}
            >
              <Card
                sectioned
                primaryFooterAction={{
                  content: intl.get("Apply"),
                  onAction: () => this.GetAppAnalysisData(1),
                  loading: this.state.searchButtonLoading
                }}
              >

                <FormLayout>
                  <DatePicker 
                  month={this.state.month} 
                  year={this.state.year} 
                  onChange={this.setSelectedDates} 
                  onMonthChange={this.handleMonthChange} 
                  selected={this.state.selectedDates} 
                  allowRange={true} 
                  disableDatesAfter={new Date()}
                  disableDatesBefore={new Date(new Date() - 30 * 86400 * 1000)}
                  multiMonth={true} />
                </FormLayout>

              </Card>
            </Popover>
          </BlockStack>
        </Layout.Section>
        {type !== 4 && <div className='layout_reports layout_reports_details'>
          <div className='_reports _reports_details'>
            <div className='details_left'>
              <div className='title'>{intl.get('Time Distribution')}</div>
              <ResponsiveContainer width='98%' height={440}>
              <LineChart 
                className="product_chart" 
                // width={960} 
                // height={440} 
                data={reports['timeDistribution']} 
                fill="#ff7300"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip content={CustomTooltip} />
                <Legend />
                {type === 1 && <Line key={random + 1} type="linear" legendType='circle' strokeWidth={2} connectNulls dataKey={'Products'} stroke="#23c2db" />}
                {type === 2 && <Line key={random + 1} type="linear" legendType='circle' strokeWidth={2} connectNulls dataKey={'Products'} stroke="#ff974c" />}
                {type === 3 && <Line key={random + 1} type="linear" legendType='circle' strokeWidth={2} connectNulls dataKey={'Orders'} stroke="#5aaef3" />}
              </LineChart>
              </ResponsiveContainer>
            </div>
            {(data[0].value + data[1].value) >= 1 ? <div className='details_right'>
              <div className='title'>{intl.get('Supplier distribution')}</div>
              <ResponsiveContainer width='98%' height={440}>
              <PieChart 
                // width={440} 
                // height={440}
              >
                <Legend verticalAlign="top" height={36} />
                <Tooltip content={CustomTooltip2} />
                <Pie
                  key={random}
                  legendType='circle'
                  data={data}
                  // cx={220}
                  // cy={180}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  // outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
              </ResponsiveContainer>
            </div> :
            <div>
              <div className='title'>{intl.get('Supplier distribution')}</div>
            <div className='_not' style={{
              border: '1px solid #DFD6D6',
              height: '440px',
            }}>

                <EmptyState
                  image="/empty.svg"
                >
                  <p style={{ fontSize: '16px', lineHeight: "20px", color: '#000000', marginBottom: '0' }}>{intl.get("The products you imported on Dropshipman have not yet generated sales.")}</p>
                </EmptyState>
                </div>
              </div>}

          </div>


          {currentContent}

        </div>
        }

        {type === 4 && currentContent}

      </Page>
    )

    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={() => this.toggleToast('')} error={this.state.showToastIserror} /> : null;
    //是否显示

    return (
      <div className='_report_content'>
        {pageContent}
        {toastMarkup}
      </div>
    );

  }
}

export default withRouter(ProductsImport);
